<ng-container *ngIf="inputs">
  <div class="row" >
    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('operator')" [form]="form"></app-question>
    </div>
  </div>

  <div class="row" >
    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('date_from')" [form]="form"></app-question>
    </div>

    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('date_to')" [form]="form"></app-question>
    </div>

    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('location_taxonomy_term_ids')" 
                    [form]="form"
                    (multiselectChanged)="handleSelectedLocationTaxonomyTermIds($event)"
      ></app-question>
    </div>

    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('location_ids')" [form]="form"></app-question>
    </div>
  </div>
</ng-container>
