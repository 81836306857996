import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { TranslateService } from '@ngx-translate/core';
import { DataTableComponent } from '../../../../shared/components/data-table/data-table.component';

@Component({
  selector: 'app-show-message-categories',
  templateUrl: './show-message-categories.component.html',
  styleUrls: ['./show-message-categories.component.css']
})
export class ShowMessageCategoriesComponent implements OnInit {

  public element: any;
  public apiEndPoint: string;
  public message_category_id = this.route.snapshot.params.id;
  public id: number;

  public dataTableConfig = {
    isMock: false,
    isActive: false,
    tableActions: [
      {
        name: this.translate.instant('components.table_actions.export_csv'),
        icon: 'ei-export',
        id: 'exportCSV'
      }
    ],
    rowActions: [
      {
        name: this.translate.instant('resources.campaigns.row_actions.details'),
        icon: 'eye',
        id: 'details'
      }
    ],
    columns: [
      { name: this.translate.instant('resources.message_categories.table.columns.id'), prop: 'id', sortByField: 'id', visible: true },
      { name: this.translate.instant('resources.message_categories.table.columns.name'), prop: 'name', sortByField: 'name', visible: true },
      { name: this.translate.instant('resources.message_categories.table.columns.description'), prop: 'description', sortByField: 'description', visible: true },
      { name: this.translate.instant('resources.message_categories.table.columns.category'), prop: 'category', sortByField: 'message_category.name', visible: true },
      { name: this.translate.instant('resources.message_categories.table.columns.created_at'), prop: 'created_at', sortByField: 'created_at', visible: true },
      { name: this.translate.instant('resources.message_categories.table.columns.priority'), prop: 'priority', sortByField: 'priority', visible: true },
      { name: this.translate.instant('resources.message_categories.table.columns.available_days'), prop: 'available_days', sortByField: 'available_days', visible: true },
      { name: this.translate.instant('resources.message_categories.table.columns.applies'), prop: 'applies', sortByField: 'applies', visible: true }
    ],
    requestData: {
      translationResource: 'resources.message_categories.table.columns.',
      apiEndPoint: `messages`,
      pageNumber: 0,
      sorting: {
        byProp: '',
        direction: '',
      },
      filtering: {
        message_category_id: this.message_category_id,
        order_by : 'available_from',
        order_sense: 'desc'
      },
      numberPerPage: 10
    },
    tableMapping: [
      { prop: 'id', type: 'number', apiProp: 'id' },
      { prop: 'name', type: 'text', apiProp: 'name' },
      { prop: 'description', type: 'text', apiProp: 'description' },
      { prop: 'category', type: 'text', apiProp: 'message_category.name' },
      { prop: 'created_at', type: 'date', apiProp: 'created_at'},
      { prop: 'priority', type: 'number', apiProp: 'priority'},
      { prop: 'available_days', type: 'number', apiProp: 'available_days'},
      { prop: 'applies', type: 'number', apiProp: 'applies' }
    ]
  };

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.dataTableConfig.isActive = true;
    this.getParams();
    this.resourcesService.getData({ apiEndPoint: this.apiEndPoint + '/' + this.id })
        .subscribe(res => {
          this.element = res;
        },
        error => {
          console.log('error:', error);
        });
  }

  getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'message_categories';
      }
    });
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }


}
