import { Component, Input } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../services/question-control.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sql-condition',
  templateUrl: './sql-condition.component.html',
  styleUrls: ['./sql-condition.component.css']
})
export class SqlConditionComponent {

  @Input('item') item: Condition;
  @Input('inputs') inputs: QuestionBase<any>[];
  @Input('form') form: UntypedFormGroup;

  constructor(private qcs: QuestionControlService,
              private router: Router) { }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any) {
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

}
