<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12">
          <div class="modal-header background-white">

            <div class="row flex-grow-1">
              <div class="col-md-12 ml-auto mr-auto">
                <h1 class="mrg-left-10 first-letter-upper">{{title}}</h1>
              </div>
            </div>


            <button class="modal-close mrg-right-15" (click)="closeModal()">
              <i class="ti-close"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="modal-body height-100 background-gray">
        <div class="row">
          <div class="col-md-12">
            <app-show-segments (segmentEmitter)="handleEmitter($event)" *ngIf="apiEndPoint === 'segments'"></app-show-segments>
            <app-show-vouchers *ngIf="apiEndPoint === 'vouchers'"></app-show-vouchers>
            <app-show-products-features *ngIf="apiEndPoint === 'features'"></app-show-products-features>
            <app-show-location-taxonomy-terms *ngIf="apiEndPoint === 'location_taxonomy_terms'"></app-show-location-taxonomy-terms>
            <app-show-message-categories *ngIf="apiEndPoint === 'message_categories'"></app-show-message-categories>
            <app-show-reports #show *ngIf="apiEndPoint === 'reports'"></app-show-reports>
            <app-show-synchros #show *ngIf="apiEndPoint === 'synchros'"></app-show-synchros>
            <app-form-rewards (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'rewards'"></app-form-rewards>
            <app-show-custom-entities #show (updateEmitter)="handleEmitter($event)" *ngIf="apiEndPoint === 'custom_entities'"></app-show-custom-entities>
          </div>
        </div>
      </div>

      <div class="modal-footer background-white align-content-center">

        <div *ngIf="mode !== 'show'" class="col-md-8 ml-auto mr-auto">
          <ng-container>
            <button class="pull-right btn btn-info"
            (click)="sendData()"
            [translate]="'resources.products.buttons.send'"
            [disabled]="loading === true || !isValid">
          </button>
          <span class="pull-right mrg-right-10 mrg-top-10">
            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          </span>
          </ng-container>
        </div>

				<ng-container *ngIf="!customEditRedirect && !updateRedirect">
          <button *ngIf="mode === 'show' && apiEndPoint !== 'vouchers' && apiEndPoint !== 'synchros'"
                  class="btn btn-info"
                  [routerLink]="['', { outlets: { modal: ['update', apiEndPoint, id] } }]">
						<span>{{ 'components.show.buttons.edit' | translate }}</span>
					</button>
          <button *ngIf="mode === 'show' && apiEndPoint === 'reports'"
                  class="btn btn-success mrg-right-20"
                  (click)="generateReport()">{{'resources.reports.buttons.regenerate' | translate}}</button>
        </ng-container>
        <ng-container *ngIf="customEditRedirect">
          <button class="btn btn-info mrg-right-20"
                  (click)="handleCustomRedirect()">
						<span>{{ 'components.show.buttons.edit' | translate }}</span>
					</button>
        </ng-container>
        <ng-container *ngIf="updateRedirect && !isCustomerServiceDataReadOnly">
          <button class="btn btn-info mrg-right-20"
                  [routerLink]="updateRedirect">
						<span>{{ 'components.show.buttons.edit' | translate }}</span>
					</button>
        </ng-container>
			</div>
    </div>
  </div>
</div>
