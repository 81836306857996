import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../shared/services/question-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { SegmentsService } from '../segments.service';
import { RetroTagService } from './services/retro-tag.service';
import { Subject, takeUntil } from 'rxjs';
import { ModalStatusService } from '../../../shared/services/modal-status.service';
import * as moment from 'moment';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-retro-tag',
  templateUrl: './retro-tag.component.html',
  styleUrls: ['./retro-tag.component.scss']
})
export class RetroTagComponent implements OnInit, OnDestroy {

  retroTagForm: UntypedFormGroup;
  inputs: QuestionBase<unknown>[];
  id: number;
  loading: boolean;
  mode: string = this.route.snapshot.data.mode;
  periodStart: string;
  periodEnd: string;

  get isSegmenRetroTagMode(): boolean {
    return this.mode === 'segment_retro_tag';
  }

  get isPeriodsRetroTagMode(): boolean {
    return this.mode === 'periods_retro_tag';
  }

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private retroTagService: RetroTagService,
    private segmentsService: SegmentsService,
    private modalStatusService: ModalStatusService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    registerLocaleData(es);
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  closeModal(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  changeDate(): void {
    if (this.isSegmenRetroTagMode) {
      this.setPeriodsValues(0);
    } else if (this.isPeriodsRetroTagMode) {
      this.setPeriodsValues(-1);
    }
  }

  sendData(): void {
    const payload = this.getRetroTagPayload();
    const confirmTitle = this.translate.instant('resources.segments.retro_tag.title');
    const confirmText = this.translate.instant('resources.segments.retro_tag.warnings.confirm_text');

    if (!payload['start_year']) {
      const tagDate = this.translate.instant('resources.segments.retro_tag.warnings.tagDate');
      const tagText = this.translate.instant('resources.segments.retro_tag.warnings.tagText');
      this.confirmationService.displayAlert(tagDate, tagText, 'warning').catch(() => {});
    } else {
      this.confirmationService.displayConfirmationAlert(confirmTitle, confirmText).then(data => {
        if (data.hasOwnProperty('value') && data.value) {
          this.loading = true;
          const request$ = this.id ? this.retroTagService.sendSegmentRetroTagData(payload, this.id) : this.retroTagService.sendPeriodsRetroTagData(payload);
          request$.pipe(takeUntil(this.destroy$)).subscribe(
            () => this.handleSuccessRequest(),
            (errorData: HttpErrorResponse) => this.handleErrors(errorData)
          );
        }
      }).catch(() => {});
    }
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getSegmentById();
      } else {
        this.setInputsAndForm();
      }
    });
  }

  private getSegmentById(): void {
    this.segmentsService.getSegmentById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: () => this.setInputsAndForm(),
      error: (errorData: HttpErrorResponse) => this.displayErrorAlert(errorData.error.error)
    });
  }

  private setInputsAndForm(): void {
    this.inputs = this.retroTagService.getInputs();
    this.retroTagForm = this.qcs.toFormGroup(this.inputs);
  }

  private setPeriodsValues(amount: number): void {
    const tagDate = this.retroTagForm.value?.tag_date ? moment(this.retroTagForm.value.tag_date, 'YYYY-MM-DD') : null;
    this.periodStart = tagDate ? tagDate.add(amount, 'M').startOf('month').format('DD/MM/YYYY') : null;
    this.periodEnd = tagDate ? tagDate.add(0, 'M').endOf('month').format('DD/MM/YYYY') : null;
  }

  private getRetroTagPayload(): object {
    const retroTagPayload = {
      iterations: this.retroTagForm.value.iterations ? parseInt(this.retroTagForm.value.iterations, 10) : null,
      start_year: this.getTagDateValue(0),
      start_month: this.getTagDateValue(1)
    };

    if (this.isSegmenRetroTagMode) {
      retroTagPayload.start_month = retroTagPayload.start_month - 1;
      retroTagPayload['months_period'] = 1;
      if (retroTagPayload.start_month === 0) {
        retroTagPayload.start_month = 12;
        retroTagPayload.start_year--;
      }
    }

    return retroTagPayload;
  }

  private getTagDateValue(index: number): number {
    return this.retroTagForm.value.tag_date ? parseInt(this.retroTagForm.value.tag_date.split('-')[index], 10) : null;
  }

  private handleSuccessRequest(): void {
    this.loading = false;
    const successTitle = this.translate.instant('resources.segments.retro_tag.warnings.success_title');
    const successText = this.translate.instant('resources.segments.retro_tag.warnings.success_text');
    this.confirmationService.displaySuccessAlert(successTitle, successText).catch(() => {});
    this.modalStatusService.modalStatus.emit();
    this.closeModal();
  }

  private handleErrors(errorData: HttpErrorResponse): void {
    this.loading = false;
    const errorValue = errorData?.error?.error ? errorData.error.error : null;
    if (errorData?.error?.errors?.length) {
      this.qcs.paintErrorsInForm(this.inputs, this.retroTagForm, errorData.error.errors);
      if (errorValue) { this.displayErrorAlert(errorValue); }
    } else if (errorValue) {
      this.displayErrorAlert(errorValue);
    }
  }

  private displayErrorAlert(error: string): void {
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), error);
  }
}
