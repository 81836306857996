<ng-container *ngIf="inputs">
  <ng-container *ngIf="flags.showLocationTaxonomyTermIds && showRequiredLocationsAlert">
    <div class="row pdd-left-15">
      <div class="alert alert-danger d-flex align-items-center">
        <em class="fa-light fa-exclamation-circle mrg-right-10 font-size-16"></em>
        <strong><span class="font-size-13"> {{ 'resources.segment_conditions.messages.required_locations_alert' | translate }} </span></strong>
      </div>
    </div>
  </ng-container>

  <div class="row mrg-btm-15">
    <div class="col">
      <app-question [question]="getInputConfig('operator')" [form]="form"></app-question>
    </div>
    
    <div *ngIf="flags.showLocationTaxonomyTermIds" class="col">
      <app-question [question]="getInputConfig('location_taxonomy_term_ids')" 
                    [form]="form"
                    (multiselectChanged)="handleLocationTaxonomyTermIdsSelected($event)"
      ></app-question>
    </div>

    <div class="col">
      <app-question [question]="getInputConfig('values')" [form]="form"></app-question>
    </div>
  </div>
</ng-container>