import { Injectable } from '@angular/core';
import { ResourcesService } from './resources.service';
import { MultiselectDataSourceable } from '../components/multiselect/multiselect';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChallengeTypesService extends ResourcesService implements MultiselectDataSourceable {

  fetchMultiselect( searchValues?: string, page?: number ) {
    let requestOptions = {
      apiEndPoint: 'challenge_types',
      numberPerPage: 300,
      pageNumber: 1
    };

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `challenge_types/${id}` });
  }

  getNameWithTemplate(element: any) {
    const name = element?.name;
    return { id: element.id, name: name };
  }

  getChallengeTypes() {
    return this.getData({ apiEndPoint: `challenge_types` });
  }

}
