import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// Highcharts
import * as Highcharts from 'highcharts';
import { getCurrencySymbol } from '@angular/common';
import { ProfileService } from '../../profiles/profile.service';
import { Subject } from 'rxjs';
import { DateService } from './date.service';
import { GradientColorObject, PatternObject } from 'highcharts';

@Injectable()
export class HighchartsConfigService {
  currencySymbol: any;
  configs: any;
  resizeEvent = new Subject<unknown>();
  resizeEvent$ = this.resizeEvent.asObservable();

  constructor(
    private translate: TranslateService,
    private profileService: ProfileService,
    private dateService: DateService
  ) {
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
    this.configs = {
      area: {
        title: {
          text: this.translate.instant('dashboards.sales.title')
        },
        chart: {
          renderTo: 'container',
          type: 'area'
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: { // don't display the dummy year
            month: '%e %b %Y',
            year: '%b'
          },
          title: {
            text: this.translate.instant('dashboards.sales.filters.date')
          },
          plotLines: [{
            color: 'red',
            value: (new Date()).getTime(),
            width: 1.2
          }]
        },
        yAxis: [
          {
            title: {
              text: this.translate.instant('dashboards.sales.labels.sales_euro', {currencySymbol: this.currencySymbol})
            },
            min: 0
          },
          {
            labels: {
              format: '{value}',
              style: {
                color: Highcharts.getOptions().colors[1]
              }
            },
            min: 0,
            title: {
              text: this.translate.instant('dashboards.sales.labels.new_customers'),
              style: {
                color: Highcharts.getOptions().colors[1]
              }
            },
            opposite: true
          }
        ],
        tooltip: {
          xDateFormat: '%d/%m/%Y',
          shared: true,
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          area: {
            stacking: 'normal',
            lineColor: '#666666',
            lineWidth: 1,
            marker: {
              lineWidth: 1,
              lineColor: '#666666'
            }
          }
        },
        legend: {
          enabled: true
        },
        series: [
          {
            name: this.translate.instant('dashboards.sales.labels.identified'),
          },
          {
            name: this.translate.instant('dashboards.sales.labels.not_identified'),
          },
          {
            name: this.translate.instant('dashboards.sales.labels.new_customers'),
            type: 'spline',
            yAxis: 1
          }
        ]
      },
      line: {
        chart: {
          type: 'line'
        },
        title: {
          text: ''
        },
        xAxis: {
        },
        yAxis: [
        ],
        tooltip: {
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          area: {
            stacking: 'normal',
            lineColor: '#666666',
            lineWidth: 1,
            marker: {
              lineWidth: 1,
              lineColor: '#666666'
            }
          }
        },
        legend: {
          enabled: true
        }
      },
      scatter: {
        chart: {
          type: 'scatter',
          zoomType: 'xy'
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false
        },
        title: {
          text: this.translate.instant('dashboards.sales.labels.ticket_num_and_spend_average')
        },
        xAxis: {
          min: 0,
          title: {
            text: this.translate.instant('dashboards.sales.labels.ticket_num')
          },
          plotLines: [],
          startOnTick: true,
          endOnTick: true,
          showLastLabel: true
        },
        yAxis: {
          min: 0,
          plotLines: [],
          title: {
            text: this.translate.instant('dashboards.sales.labels.average_spend_euro', {currencySymbol: this.currencySymbol})
          }
        },
        plotOptions: {
          scatter: {
            dataLabels: {
              enabled: false
            },
            marker: {
              radius: 6,
              states: {
                hover: {
                  enabled: true,
                  lineColor: 'rgb(100,100,100)'
                }
              }
            },
            states: {
              hover: {
                marker: {
                  enabled: false
                }
              }
            },
            tooltip: {
              headerFormat: '',
              pointFormat: '<b>{point.name}</b><br>Tickets: {point.x}<br>Gasto medio: {point.y} €'
            }
          }
        },
        series: [{
          dataLabels: {
            allowOverlap: true,
            enabled: false,
            /* formatter: function() { return this.point.code; }, */
            inside: true,
            style: { color: 'black' }
          },
          data: []
        }]
      }
    };
  }

  getConfig(type: string) {
    return this.configs[type];
  }

  interpolateDatePoint(pointList, newDate, newValue) {

    newValue = (newValue === undefined ? null : newValue);
    let idx = 0;

    while (idx < pointList.length && pointList[idx][0] < newDate) {
      idx++;
    }

    pointList.splice(idx, 0, [newDate, newValue]);
  }

  getMonthYearDateTooltipSplineChart(
      chartValues: { nameY: string, valueY: number | string, pointColor: string | GradientColorObject | PatternObject }[],
      xAxisTimestamp: number
    ): string {
    /**
     * Highcharts transforms tooltip timestamp value (this.x - 1st day of the month on the utcDatesLast12Months) to date format (%B, %Y) based on the time zone.
     * As a consequence it returns the previous day (28th or 30th-31st), showing the previous month in the tooltip.
     * It is necessary to format tooltip to remove time zone to get the correct tooltip date (Don't use dateFormat from Highcharts).
     */
    const tooltipDate = this.dateService.dateTimestampToStringDateTimezoneOffset(xAxisTimestamp, 'MMMM, YYYY', true);
    let tooltipTxt = '<span style="font-size: 10px">' +  tooltipDate + '</span><br>';
    chartValues.forEach((item) =>
        tooltipTxt += '<span style="color:' + item.pointColor + '">\u25CF</span> ' + item.nameY + ': <strong>' + item.valueY + '</strong><br>'
    );
    return tooltipTxt;
  }
}
