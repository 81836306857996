import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { QuestionControlService } from '../../../../services/question-control.service';
import { SystemTagHistoriesService } from '../../../../services/system-tag-histories.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-system-segment-condition',
  templateUrl: './system-segment-condition.component.html',
  styleUrls: ['./system-segment-condition.component.css']
})
export class SystemSegmentConditionComponent implements OnInit, OnDestroy {

  @Input('item') item: Condition;
  @Input('inputs') inputs: QuestionBase<any>[] = [];
  @Input('form') form: UntypedFormGroup;
  @Input('filters') filters: { segment_category_id: number };

  subs$: Subscription[] = [];
  firstSegment: number;
  segmentValue: any;

  constructor( private qcs: QuestionControlService,
               private tagHistoriesService: SystemTagHistoriesService ) { }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach( s$ => s$.unsubscribe()); }
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    if ( !value ) { return false; }
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  handleApplySelected(value) {
    this.resetInput('apply', 'index_apply');
  }

  handleApplyIndexSelected(value) {
    this.resetInput('index_apply', 'apply');
  }

  /*
  * @param {Boolean} emitValue: If set to false won't refresh tag_histories input values
  */
  handleSegmentSelected(value, emitValue?: boolean) {

    if ( value && value.length > 0 && !this.firstSegment ) {
      this.firstSegment = value[0].id;
      this.tagHistoriesService.setSegment(value[0].id);
    }

    this.segmentValue = this.inputs.find( input => input.key === 'segment_ids').value;
    if ( isNullOrUndefined(this.segmentValue) || this.segmentValue.length === 0 ) {
      this.disableApplyInputs();
    } else {
      this.inputs.find( input => input.key === 'apply').disabled = false;
      this.inputs.find( input => input.key === 'index_apply').disabled = false;
    }
  }

  private init() {
    const segmentIds = this.inputs.find( input => input.key === 'segment_ids').selectedIds;
    if ( !isNullOrUndefined(segmentIds) && segmentIds.length > 0 ) {
      this.tagHistoriesService.setSegment(segmentIds[0]);
    } else {
      this.disableApplyInputs();
    }
  }

  private resetInput(inputSelectedKey: string, inputToResetKey: string, ) {
    const inputSelected = this.inputs.find( _input => _input.key === inputSelectedKey);
    const inputToReset = this.inputs.find( _input => _input.key === inputToResetKey);
    if ( inputSelected.value && inputSelected.value.length > 0 ) { 
      inputToReset.value = [];
      inputToReset.selectedIds = [];
      this.form.get(inputToResetKey).patchValue([]);
    }
  }

  private resetApplyInputs() {
    const applyInput = this.inputs.find( input => input.key === 'apply');
    applyInput.value = [];
    applyInput.selectedIds = [];
    this.inputs.find( input => input.key === 'index_apply').value = [];
    this.form.patchValue({ apply: [], index_apply: [] });
  }

  private disableApplyInputs() {
    this.resetApplyInputs();
    this.inputs.find( _input => _input.key === 'apply' ).disabled = true;
    this.inputs.find( _input => _input.key === 'index_apply' ).disabled = true;
  }
}
