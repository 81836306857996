import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
    providedIn: 'root'
})
export class ImportProductsService {

    isActive = false;
    failedProducts$ = new Subject<string[]>();

    setIsActive(isActive: boolean): void {
        this.isActive = isActive;
    }

    setFailedProducts(failedProducts: any[]): void {
        this.failedProducts$.next(failedProducts);
    }

    getFailedProducts(): Observable<string[]> {
        return this.failedProducts$.asObservable();
    }
}