<div class="row" *ngIf="inputs">

  <div class="col-md-3">
    <div class="form-group">
      <app-question [question]="getInputConfig('segment_id')"
                    [form]="form"
                    [filters]="filters"
                    (multiselectChanged)="handleSegmentSelected($event)"></app-question>
    </div>
  </div>

  <div class="col-md-3">
    <div class="form-group">
      <app-question [question]="getInputConfig('number_of_times')" [form]="form"></app-question>
    </div>
  </div>

  <div class="col-md-3">
    <div class="form-group">
      <app-question [question]="getInputConfig('applies')"
                    (multiselectChanged)="handleAppliesSelected($event)"
                    [form]="form"></app-question>
    </div>
  </div>

  <div class="col-md-3">
    <div class="form-group">
      <app-question [question]="getInputConfig('index_applies')"
                    (multiselectChanged)="handleAppliesIndexSelected($event)" [form]="form"></app-question>
    </div>
  </div>

</div>
