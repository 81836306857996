
import { Component, OnInit, OnDestroy } from '@angular/core';
import { QuestionControlService } from '../../../shared/services/question-control.service';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { SegmentCategoriesService } from '../segment-categories.service';
import { ModalStatusService } from '../../../shared/services/modal-status.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-form-segment-categories',
  templateUrl: './form-segment-categories.component.html',
  styleUrls: ['./form-segment-categories.component.css'],
  providers: [SegmentCategoriesService]
})
export class FormSegmentCategoriesComponent implements OnInit, OnDestroy {

  public segmentCategoriesInputs: QuestionBase<any>[];
  public segmentCategoriesForm: UntypedFormGroup;
  public inputs: QuestionBase<any>[];
  public id: number;
  public showButton: boolean;
  public loading: boolean;
  public subs$: Subscription[];

  constructor(private qcs: QuestionControlService,
              private route: ActivatedRoute,
              private segmentCategoriesService: SegmentCategoriesService,
              private router: Router,
              private confirmationService: ConfirmationService,
              private translate: TranslateService,
              private modalStatusService: ModalStatusService) {
    this.subs$ = [];
  }

  ngOnInit() {
    this.getParams();
    this.showBackButton();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  public getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  private getParams() {
    const route$ = this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getCampaignCategoriesById(this.id);
      } else {
        this.inputs = this.segmentCategoriesService.getInputs({});
        this.segmentCategoriesForm = this.qcs.toFormGroup(this.inputs);
      }
    });
    this.subs$.push(route$);
  }

  public sendData() {
    this.loading = true;
    const segmentCategoriesOptions = { name: this.segmentCategoriesForm.value.name };
    this.id ? this.updateCategory(segmentCategoriesOptions) : this.createCategory(segmentCategoriesOptions);
  }

  private getCampaignCategoriesById(id: number) {
    const category$ = this.segmentCategoriesService.getSegmentCategoriesById(this.id).subscribe(
      data => {
        category$.unsubscribe();
        this.inputs = this.segmentCategoriesService.getInputs(data);
        this.segmentCategoriesForm = this.qcs.toFormGroup(this.inputs);
      },
      errorData => {
        category$.unsubscribe();
        this.handleErrorOnRequest(errorData);
      }
    );
    this.subs$.push(category$);
  }

  private createCategory(segmentCategoriesOptions) {
    const category$ = this.segmentCategoriesService.sendData(segmentCategoriesOptions).subscribe(
      () => {
        category$.unsubscribe();
        this.handleSuccessOnRequest(
          this.translate.instant('resources.segment_categories.form.warnings.success_title'),
          this.translate.instant('resources.segment_categories.form.warnings.success_text')
        );
      },
      errorData => {
        category$.unsubscribe();
        this.handleErrorOnRequest(errorData);
      }
    );
    this.subs$.push(category$);
  }

  private updateCategory(segmentCategoriesOptions) {
    const category$ = this.segmentCategoriesService.updateData(segmentCategoriesOptions, this.id).subscribe(
      () => {
        category$.unsubscribe();
        this.handleSuccessOnRequest(
          this.translate.instant('resources.segment_categories.form.warnings.update_title'),
          this.translate.instant('resources.segment_categories.form.warnings.update_text')
        );
      },
      errorData => {
        category$.unsubscribe();
        this.handleErrorOnRequest(errorData);
      }
    );
    this.subs$.push(category$);
  }

  private handleSuccessOnRequest(title, text) {
    this.loading = false;
    this.confirmationService.displaySuccessAlert(title, text).catch(() => {});
    this.modalStatusService.modalStatus.emit();
    this.returnToList();
  }

  private handleErrorOnRequest(errorData) {
    this.confirmationService.displayErrorAlert('Error', errorData.error.error);
  }

  private returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/segments/segment_categories']).catch(() => {});
    }
  }

  private showBackButton() {
    this.showButton = !(this.router.url.indexOf('(modal:') >= 0);
  }
}
