import { QuestionBase } from './question-base';

export class ToggleCheckboxQuestion extends QuestionBase<boolean> {

  controlType = 'toggle-checkbox';
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}