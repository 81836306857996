import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerJourney } from '../../../models/customer-journeys/customer-journey';
import { CustomerJourneysService } from '../../../../resources/customer-journeys/customer-journeys.service';
import { TranslateService } from '@ngx-translate/core';
import { MulticouponJourneyService } from '../../../services/multicoupon-journey.service';
import { QuestionControlService } from '../../../services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../../models/forms/question-base';
import { PotentialJourneyService } from '../../../services/potential-journey.service';
import { RecuperationJourneyService } from '../../../services/recuperation-journey.service';

@Component({
  selector: 'app-journey-definition-block',
  templateUrl: './journey-definition-block.component.html',
  styleUrls: ['./journey-definition-block.component.css']
})
export class JourneyDefinitionBlockComponent implements OnInit {

  @Input()  journey:       CustomerJourney;
  @Input()  form:          UntypedFormGroup;
  @Output() onStatusEmit:  EventEmitter<any> = new EventEmitter();
  @Output() onFormEmit:    EventEmitter<any> = new EventEmitter();
  @Output() onFormSubmit:  EventEmitter<any> = new EventEmitter();

  // Object to store all options for multiselects in definition step
  public inputs;
  public loading: boolean;
  public options = {};
  public definitionChanged = false;
  public errorsData = [];

  constructor( private journeyService:      CustomerJourneysService,
               private translate:           TranslateService,
               private multicouponService:  MulticouponJourneyService,
               private potentialService:    PotentialJourneyService,
               private recuperationService: RecuperationJourneyService,
               private qcs:                 QuestionControlService ) { }

  ngOnInit() {
    if (this.journey.type === 'multicoupon') {
      this.inputs = this.multicouponService.getDefinitionStepFormCfg(this.journey);
      this.form = this.qcs.toFormGroup(this.inputs);
      this.onFormEmit.emit(this.form);
    } else if (this.journey.type === 'potential') {
      this.inputs = this.potentialService.getDefinitionStepFormCfg(this.journey);
      this.form = this.qcs.toFormGroup(this.inputs);
      this.onFormEmit.emit(this.form);
    } else if (this.journey.type === 'recuperation') {
      this.inputs = this.recuperationService.getDefinitionStepFormCfg(this.journey);
      this.form = this.qcs.toFormGroup(this.inputs);
      this.onFormEmit.emit(this.form);
    } else {
      this.inputs = this.journey.getDefinitionStepFormCfg();
      this.getOptions();
      this.loading = true;
      this.onStatusEmit.emit('loading');
    }
  }

  public handleDefinitionChanged(event: Event, input?: QuestionBase<any>) {
    if ( this.journey.type === 'multicoupon' && input && (input.key === 'via' || input.key === 'deliveries') ) {
      this.onFormSubmit.emit('save');
    }
    this.definitionChanged = true;
    this.onStatusEmit.emit('unsaved_changes');
  }

  public showErrors(data) {
    this.errorsData = data;
    data.error.errors.forEach(
      err => {
        const input = this.inputs.find(_input => _input.key === err.field);
        if(input) {
          input.hasErrors = true;
          input.errorMessages = err.message.join(', ');
        }
      }
    );
  }

  public handleGlobalSelection(value, inputKey) {
    this.handleDefinitionChanged(value);
    this.journey.configuration[inputKey] = value;
  }

  public getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  /**
   * Method to request for all options to populate multiselects
   * Each C. Journey type has its own definition steps configuration form
   */
  private getOptions() {
    this.requestRemoteOptions();
  }

  /**
   * Method to filter steps that has to populate themselves with external http call
   * TODO: Move to cj service
   */
  private getStepsWithRemoteOptions(definitionStepsCfg) {
    return definitionStepsCfg.filter(
      stepCfg => stepCfg.hasOwnProperty('requestData') &&
                 stepCfg['requestData'].hasOwnProperty('apiEndPoint') &&
                 stepCfg['requestData']['apiEndPoint'] != null );
  }

  /**
   * Method to filter steps that has static local options
   * TODO: Move to cj service
   */
  private getStepsWithLocalOptions(definitionStepsCfg) {
    return definitionStepsCfg.filter( stepCfg => !stepCfg.hasOwnProperty('requestData') );
  }

  public requestRemoteOptions() {
    const stepsWithRemoteOptions = this.getStepsWithRemoteOptions(this.inputs);
    stepsWithRemoteOptions.forEach(step => {
      this.journeyService.getMultiselectOptionsByEndpoint(step['requestData']).subscribe(
        response => {
          this.options[step['key']] = this.parseOptionsResponse(step, response);
          if ( !this.pendingRequests(Object.keys(this.options).length) ) {
            this.requestLocalOptions();
            this.markActivityTag();
            this.markCoupon();
            this.loading = false;
            this.onStatusEmit.emit('finished_loading');
          }
        }
      );
    });
    if (stepsWithRemoteOptions.length === 0) {
      this.requestLocalOptions();
    }
  }

  public markActivityTag() {
    if ( this.journey.configuration.hasOwnProperty('activity_tag') &&
         this.journey.configuration.activity_tag[0] &&
         this.journey.configuration.activity_tag[0].hasOwnProperty('id') &&
         this.journey.configuration.activity_tag[0].id ) {
      const tagSelected = this.options['activity_tag'].find(
        option => option.id === this.journey.configuration.activity_tag[0].id
      );
      if (tagSelected) {
        this.journey.configuration.activity_tag = [tagSelected];
      } else {
        console.warn('Activity tag not found in given list:', tagSelected );
      }
    }
  }

  /**
   * Method to mark coupon as selected in definition step
   */
  public markCoupon() {
    if ( this.journey.configuration.hasOwnProperty('coupon') &&
         this.journey.configuration.coupon[0] &&
         this.journey.configuration.coupon[0].hasOwnProperty('id') &&
         this.journey.configuration.coupon[0].id ) {
      const couponSelected = this.options['coupon'].find(
        option => option.id === this.journey.configuration.coupon[0].id.toString()
      );
      if (couponSelected) {
        this.journey.configuration.coupon = [couponSelected];
      } else {
        console.warn('Coupon not found in given list:', this.journey.configuration.coupon[0].id );
      }
    }
  }

  public isDisabled(input): boolean {
    if ( this.journey.type === 'recuperation' && input.key === 'coupon' ) {
      return false;
    }
    return !this.journey._original;
  }

  private requestLocalOptions() {
    const localSteps = this.getStepsWithLocalOptions(this.inputs);
    localSteps.forEach(step => {
      if (step.translatable) {
        step.options.forEach(element => {
          element.name = this.translate.instant(element.name);
        });
      }
      this.options[step['key']] = step.options;
    });
  }

  private pendingRequests(finishedRequests: number): boolean {
    const totalRequests = this.getStepsWithRemoteOptions(this.inputs).length;
    return finishedRequests < totalRequests;
  }

  private parseOptionsResponse(step, response) {
    let list = response.hasOwnProperty('list') ? response['list'] : response;
        list = list.hasOwnProperty('values') ? list['values'] : list;
    let _response = [];
    list.forEach(element => {
      const optionName = this.interpolate(step, element);
      _response.push(optionName);
    });

    _response = this.journey.filterOptionsByKey(_response, step);
    return _response;
  }

  private interpolate(template, element) {
    return {
      id: element.id,
      name: element.name
    };
  }
}
