import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../services/question-control.service';
import { LocationsService } from '../../../../../resources/data-warehouse/locations/locations.service';
import { MultiselectDataSource } from '../../../multiselect/multiselect';
import { handleSelectedLocationTaxonomyTermIdsField } from '../../utils/common.utils';

@Component({
  selector: 'app-promotion-condition',
  templateUrl: './promotion-condition.component.html',
  styleUrls: ['./promotion-condition.component.scss']
})
export class PromotionConditionComponent {

  @Input('item') item: Condition;
  @Input('inputs') inputs: QuestionBase<unknown>[];
  @Input('form') form: UntypedFormGroup;

  constructor(
    private readonly qcs: QuestionControlService,
    private readonly locationsService: LocationsService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  handleSelectedLocationTaxonomyTermIds(locationTaxonomyTermIdsSelected: MultiselectDataSource[]): void {
    handleSelectedLocationTaxonomyTermIdsField(
      locationTaxonomyTermIdsSelected,
      'location_ids',
      this.getInputConfig('location_ids'),
      this.form,
      this.changeDetector,
      this.locationsService
    );
  }
}
