import { LOOKER_THEME_NO_TITLE_NO_FILTERS } from './../../../../shared/constants/looker.constants';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Plan } from '../../plan';
import { ActivatedRoute } from '@angular/router';
import { Subject, finalize, takeUntil } from 'rxjs';
import { PlanCampaignService } from '../plan-campaign.service';
import { PlansService } from '../../plans.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { isNullOrUndefined } from '../../../../shared/utils/common.utils';
import { FilterConfiguration } from '../../../../shared/components/data-table-filter/data-table-filter-cfg';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { TranslateService } from '@ngx-translate/core';
import { PlanCampaignActionService } from '../custom-campaign-plan-audience/plan-campaign-action.service';
import { Option } from '../../../../shared/models/common/option';
import { FormGroup } from '@angular/forms';
import { LookerIFrameContainerComponent } from '../../../../shared/components/looker-iframe-container/looker-iframe-container.component';
import { ProfileService } from '../../../../profiles/profile.service';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-plan-analysis',
  templateUrl: './plan-analysis.component.html',
  styleUrls: ['./plan-analysis.component.scss']
})
export class PlanAnalysisComponent implements OnInit, OnDestroy {

  INTERVAL_DELAY = 30000;
  PLAN_ANALYSIS_DASHBOARD_ID = `/embed/dashboards/703?${LOOKER_THEME_NO_TITLE_NO_FILTERS}`;

  analysisDate: string;
  loading = false;
  plan: Plan;
  planId: string;
  filters: any;
  displayFilter = false;
  intervalInstance: any = null;
  filterCfg: FilterConfiguration = {
    disableOnSubmit: false,
    disableOnInit: false,
    disableSubmitBtn: true,
    customCallbackOnFilterChanged: (currentValue: any, currentFilter: QuestionBase<any>, inputs: QuestionBase<any>[], form: FormGroup) => {
      this.onFilterCallback(currentValue, currentFilter, inputs, form);
    }
  };
  showSubtotal: boolean = false;
  currencySymbol: string;

  @ViewChild('toggleBtn') toggleBtn: ElementRef;
  @ViewChild('dashboard') dashboard: LookerIFrameContainerComponent;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private planCampaignsService: PlanCampaignService,
    private plansService: PlansService,
    private modalStatusService: ModalStatusService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private planActionsService: PlanCampaignActionService,
    private profileService: ProfileService
  ) {
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
  }

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy() {
    if (this.intervalInstance) { clearInterval(this.intervalInstance); }
    this.destroy$.next();
    this.destroy$.complete();
  }

  isAnalysisInProgress() {
    return this.plan?.isAnalysisInProgress();
  }

  isAbleToCalculateAnalysis() {
    return !this.isAnalysisInProgress() && this.plan?.isDelivered();
  }

  handleShowSubtotal() {
    this.showSubtotal = this.toggleBtn.nativeElement.checked;
  }

  calculateAnalysis() {
    this.filters = null;
    this.loading = true;
    this.plansService.calculateAnalysis(`${this.plan.id}`).pipe(takeUntil(this.destroy$), finalize(() => this.loading = false)).subscribe({
      next: () => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.plans.messages.calc_analysis_success_title'),
          this.translate.instant('resources.plans.messages.calc_analysis_success_desc')
        ).catch(() => {});
        this.getPlan(`${this.plan.id}`);
      },
      error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
    });
  }

  onFilterHandler(filterPayload) {
    const payload = {
      'Campaign': filterPayload['campaign_plan_ids'],
      'Campaign Action': filterPayload['campaign_plan_action_ids'],
      'Metric Selector': filterPayload['chart_metric'],
      'Plan': `${this.planId}`
    };
    this.dashboard.onFilterHandler(payload);
  }

  onFilterClearHandler() {
    this.planActionsService.setCampaign(null);
  }

  private onFilterCallback(value: any, filterChanged: QuestionBase<any>, inputs: QuestionBase<any>[], form: FormGroup) {
    if (filterChanged?.key === 'campaign_plan_ids') {
      this.filterCfg.disableSubmitBtn = !value;
      if (value) {
        this.planActionsService.setCampaign(value.id);
      } else {
        this.planActionsService.setCampaign(null);
        form.patchValue({campaign_plan_action_ids: []});
      }
    }
  }

  private getParams() {
    this.route.parent.params.pipe(takeUntil(this.destroy$)).subscribe(parentParams => {
      this.planId = parentParams.id;
      this.planCampaignsService.setPlan(parseInt(parentParams.id, 10));
      this.getPlan(this.planId);
    });
  }

  private getPlan(planId: string) {
    this.plansService.getPlanById(planId).pipe(takeUntil(this.destroy$)).subscribe({
      next: (planData: HttpResponse<Object>) => {
        this.plan = new Plan(planData);
        this.modalStatusService.resourceStatus.emit(this.plan);
        this.filters = this.getInputs();
        this.displayFilter = this.plan?.isAnalysisDone();
        this.loading = this.isAnalysisInProgress();

        if (this.isAnalysisInProgress()) {
          this.startIterativeCheckRequest();
        } else {
          if (this.intervalInstance) {
            this.loading = false;
            clearInterval(this.intervalInstance);
          }
          this.analysisDate = this.plan.pre_post_updated_at;
        }
      },
      error: (err: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(err)
    });
  }

  private startIterativeCheckRequest() {
    if (isNullOrUndefined(this.intervalInstance)) {
      this.intervalInstance = setInterval(() => this.getPlan(`${this.plan.id}`), this.INTERVAL_DELAY);
    }
  }

  private getInputs(plan?: Plan): QuestionBase<any>[] {
    return [
      new MultiSelectQuestion({
        key: 'campaign_plan_ids',
        label: this.translate.instant('resources.plans.filters.campaign'),
        cssClasses: 'form-control input-default',
        settings: {singleSelection: true, enableSearchFilter: false, enableCheckAll: false, showCheckbox: false},
        dataSource: this.planCampaignsService,
        required: true
      }),
      new MultiSelectQuestion({
        key: 'campaign_plan_action_ids',
        label: this.translate.instant('resources.plans.filters.actions'),
        cssClasses: 'form-control input-default',
        settings: {singleSelection: false, enableSearchFilter: false, enableCheckAll: true, showCheckbox: true},
        dataSource: this.planActionsService
      }),
      new MultiSelectQuestion({
        key: 'chart_metric',
        label: this.translate.instant('resources.plans.filters.chart_metric'),
        cssClasses: 'form-control input-default',
        options: this.getChartMetrics(),
        settings: {singleSelection: true, enableSearchFilter: false, enableCheckAll: false, showCheckbox: false},
        value: [this.getChartMetrics()[0]]
      }),
    ];
  }

  private getChartMetrics(): Option[] {
    return [
      { id: "0", name: this.translate.instant('resources.plans.filters.chart_metrics.club_sales', {currencySymbol: this.currencySymbol}) },
      { id: "1", name: this.translate.instant('resources.plans.filters.chart_metrics.weight_sales') },
      { id: "2", name: this.translate.instant('resources.plans.filters.chart_metrics.club_tickets') },
      { id: "3", name: this.translate.instant('resources.plans.filters.chart_metrics.active_customers') },
      { id: "4", name: this.translate.instant('resources.plans.filters.chart_metrics.average_ticket') },
      { id: "5", name: this.translate.instant('resources.plans.filters.chart_metrics.average_freq') },
      { id: "6", name: this.translate.instant('resources.plans.filters.chart_metrics.average_spend') }
    ]
  }
}
