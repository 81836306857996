export class ContactsRowData {

 customersWithActivities: number;
 dniStatusTotal: number;
 phoneStatusTotal: number;
 emailStatusTotal: number;
 phoneDuplicityStatusTotal: number;
 pushStatusTotal: number;

  constructor(apiResponse: object) {
    this.customersWithActivities = apiResponse['customers_with_activities'];
    this.dniStatusTotal = apiResponse['dni_status_total'];
    this.phoneStatusTotal = apiResponse['phone_status_total'];
    this.emailStatusTotal = apiResponse['email_status_total'];
    this.phoneDuplicityStatusTotal = apiResponse['phone_duplicity_status_total'];
    this.pushStatusTotal = apiResponse['push_status_total'];
  }
}