import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { CustomEntityConditionForm } from "../../../../../models/segments/conditions/custom-entity-condition";
import { ATTRIBUTES_CONFIGURATION_FIELDS } from "../constants/custom-entity-condition.constants";

export function getAttributesConfigurationFormGroup(attributeConfig?: CustomEntityConditionForm): UntypedFormGroup {
  const controls = {};
  ATTRIBUTES_CONFIGURATION_FIELDS.forEach(fieldKey => controls[fieldKey] = new UntypedFormControl(attributeConfig?.[fieldKey] ?? null));
  return new UntypedFormGroup(controls);
}

export function resetAttributeConfigurationCtrl(attributeConfig: UntypedFormGroup, keyFields: string[]): void {
  keyFields.forEach(key => attributeConfig.get(key).patchValue(null));
}