import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MultiselectService {

  updateDropdownList: Subject<any> = new Subject<any>();
  updateDropdownList$ = this.updateDropdownList.asObservable();

}
