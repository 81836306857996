import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../services/question-control.service';
import { QuestionBase } from '../../../../models/forms/question-base';

@Component({
  selector: 'app-campaign-validation-block',
  templateUrl: './campaign-validation-block.component.html',
  styleUrls: ['./campaign-validation-block.component.scss']
})

export class CampaignValidationBlockComponent {

  @Input('campaign') campaign;
  @Input('inputs') inputs;
  @Input('companyCreditsInfo') companyCreditsInfo;
  @Input('campaignForm') campaignForm: UntypedFormGroup;

  constructor(
    private qcs: QuestionControlService
  ){}

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.campaignForm, formKey, value);
  }

}
