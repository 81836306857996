import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBase } from '../models/forms/question-base';
import { MultiSelectQuestion } from '../models/forms/question-multiselect';
import { CouponsService } from '../../resources/coupons/coupons.service';
import { CustomerJourney } from '../models/customer-journeys/customer-journey';
import { UntypedFormGroup } from '@angular/forms';
import { DateService } from './date.service';
import { TextboxQuestion } from '../models/forms/question-textbox';
import { EmailTemplatesService } from './email-templates.service';
import { SmsTemplatesService } from './sms-templates.service';
import { PushTemplatesService } from './push-templates.service';
import { CheckboxQuestion } from '../models/forms/question-checkbox';
import { FloatQuestion } from '../models/forms/question-float';
import { CampaignCategoriesService } from '../../resources/campaigns/campaign-categories/campaign-categories.service';
import { V1SegmentsService } from '../../resources/segments/v1-segments.service';

@Injectable()
export class MulticouponJourneyService {

  public inputs: QuestionBase<any>[];

  constructor( private translate:                 TranslateService,
               private segmentService:            V1SegmentsService,
               private couponService:             CouponsService,
               private dateService:               DateService,
               private emailTemplatesService:     EmailTemplatesService,
               private pushTemplatesService:      PushTemplatesService,
               private smsTemplatesService:       SmsTemplatesService,
               private campaignCategoriesService: CampaignCategoriesService ) {}

  public getDefinitionStepFormCfg(journey: CustomerJourney): any[] {
    this.inputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'extra_segment',
        dataSource: this.segmentService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        order: 1,
        label: this.translate.instant('resources.journeys.fields.segment_name'),
        getValue: (value) => ({ selectedIds: value })
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'coupon_id',
        dataSource: this.couponService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        order: 2,
        label: this.translate.instant('resources.journeys.fields.coupon'),
        getValue: (value) => (value ? { selectedIds: value[0] } : [])
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'via',
        options: this.getMultiCouponVias(),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 3,
        label: this.translate.instant('resources.journeys.fields.coupon_delivery'),
        getValue: (value) => (value ? [this.getMultiCouponVias().find( via => via.id === value.id)] : [])
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'deliveries',
        options: this.getDeliveries(),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 4,
        label: this.translate.instant('resources.journeys.fields.deliveries'),
        getValue: (value) => (value ? [this.getDeliveries().find( item => item.id === parseInt(value, 10))] : [])
      }),
      /* Step inputs */
      /* 1) */
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'step1.scheduled_at',
        order: 5,
        type: 'datetime-local',
        step: 60,
        getValue: (value) => value ? this.dateService.dateISOStringToDateTime(value) : null
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'step1.email',
        dataSource: this.emailTemplatesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 6,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'step1.push',
        dataSource: this.pushTemplatesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 6,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'step1.sms',
        dataSource: this.smsTemplatesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 6,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      /* 2) */
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'step2.scheduled_at',
        order: 7,
        type: 'datetime-local',
        step: 60,
        getValue: (value) => value ? this.dateService.dateISOStringToDateTime(value) : null
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'step2.email',
        dataSource: this.emailTemplatesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 8,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'step2.push',
        dataSource: this.pushTemplatesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 8,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'step2.sms',
        dataSource: this.smsTemplatesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 8,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      /* 3) */
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'step3.scheduled_at',
        order: 9,
        type: 'datetime-local',
        step: 60,
        getValue: (value) => value ? this.dateService.dateISOStringToDateTime(value) : null
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'step3.email',
        dataSource: this.emailTemplatesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 10,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'step3.push',
        dataSource: this.pushTemplatesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 10,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'step3.sms',
        dataSource: this.smsTemplatesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 10,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      /* 4) */
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'step4.scheduled_at',
        order: 11,
        type: 'datetime-local',
        step: 60,
        getValue: (value) => value ? this.dateService.dateISOStringToDateTime(value) : null
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'step4.email',
        dataSource: this.emailTemplatesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 12,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'step4.push',
        dataSource: this.pushTemplatesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 12,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'step4.sms',
        dataSource: this.smsTemplatesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 12,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      /* End step inputs */
      /* Reportable inputs */
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'reportable',
        label: this.translate.instant('resources.journeys.fields.include_in_roi'),
        order: 13,
        getValue: (value) => value
      }),
      new FloatQuestion({
        cssClasses: 'form-control input-md',
        key: 'prediction_percentage_redemption',
        label: this.translate.instant('resources.journeys.fields.redemption_percentage'),
        order: 14,
        type: 'number',
        min: 1,
        step: 0.1,
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'campaign_category_id',
        label: 'resources.campaigns.filters.category',
        dataSource: this.campaignCategoriesService,
        settings: {singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true},
        order: 15,
        getValue: (value) => value ? ({ selectedIds: [value] }) : [{ selectedIds: [] }]
      })
      /* End reportable inputs */
    ];

    /* Definition input fields values */
    Object.keys(journey.cfg.configuration).forEach(
      (key) => {
        const tmpInput = this.inputs.find( input => input.key === key );
        if ( tmpInput.hasOwnProperty('getValue') ) {
          const value = tmpInput.getValue( journey.cfg.configuration[key] );
          if ( value.hasOwnProperty('selectedIds')) {
            tmpInput.selectedIds = [value.selectedIds];
          } else {
            tmpInput.value = value;
          }
        }
      }
    );

    /* Steps schedule values */
    journey.steps.forEach(
      step => {
        const tmpInput = this.inputs.find( input => input.key === `step${step.order + 1}.scheduled_at` );
        const value = tmpInput.getValue(step.scheduled_at);
        tmpInput.value = value;
      }
    );

    /* Report values */
    const reportInput = this.inputs.find( _input => _input.key === 'reportable' );
    reportInput.value = reportInput.getValue(journey.reportable);

    const pctgInput = this.inputs.find( _input => _input.key === 'prediction_percentage_redemption' );
    pctgInput.value = pctgInput.getValue(journey.report_configuration.reward.prediction_percentage_redemption);

    const ctgInput = this.inputs.find( _input => _input.key === 'campaign_category_id' );
    ctgInput.selectedIds = ctgInput.getValue(journey.cfg.campaign_category_id).selectedIds;

    /* Disable fields on edit */
    if ( !journey._original ) {
      this.inputs.find( _input => _input.key === 'extra_segment' ).disabled = true;
      this.inputs.find( _input => _input.key === 'via' ).disabled = true;
      this.inputs.find( _input => _input.key === 'deliveries' ).disabled = true;
      this.inputs.find( _input => _input.key === 'prediction_percentage_redemption' ).disabled = true;
    }

    return this.inputs;
  }

  public getMultiCouponVias(): {id: string, name: string}[] {
    return [
      {id: 'email', name: this.translate.instant('resources.campaigns.channels.email')},
      {id: 'sms',   name: this.translate.instant('resources.campaigns.channels.sms')},
      {id: 'none',  name: this.translate.instant('resources.campaigns.channels.none')},
      {id: 'push',  name: this.translate.instant('resources.campaigns.channels.push')}
    ];
  }

  public getDeliveries(): {id: number, name: string}[] {
    return [
      { id: 1, name: '1' },
      { id: 2, name: '2' },
      { id: 3, name: '3' },
      { id: 4, name: '4' }
    ];
  }

  public prepareToSave(journey: CustomerJourney, form: UntypedFormGroup) {

    // Definition
    const name = journey.name;
    const extra_segment = (form.value['extra_segment'] && form.value['extra_segment'].length ) ? form.value['extra_segment'][0].id : null;
    const coupon = (form.value['coupon_id'] && form.value['coupon_id'].length ) ? [parseInt(form.value['coupon_id'][0].id, 10)] : null;
    const via = (form.value['via'] && form.value['via'].length ) ? form.value['via'][0].id : null;
    const deliveries = (form.value['deliveries'] && form.value['deliveries'].length ) ? `${form.value['deliveries'][0].id}` : 4;

    const configuration = {
      extra_segment: extra_segment,
      coupon_id: coupon,
      via: via,
      deliveries: deliveries
    };

    // Campaigns
    const steps = journey.steps.map(

      (step, index) => {

        const stepCfg = step.configuration;

        coupon ? stepCfg.coupon_id = coupon[0] : delete stepCfg.coupon_id;

        return {
          id: step.id,
          status: step.status,
          position: index + 1,
          scheduled_at: form.value[`step${index + 1}.scheduled_at`],
          configuration: step.configuration
        };
      }
    );

    // Summary
    const reportable = form.value[`reportable`] ? form.value[`reportable`] : false;
    const predictionPercentage = form.value.prediction_percentage_redemption ? parseFloat(form.value.prediction_percentage_redemption) : null;
    const reportCfg = predictionPercentage ? { reward: { prediction_percentage_redemption: predictionPercentage }} : {};
    const category = (form.value.campaign_category_id && form.value.campaign_category_id.length) ? `${form.value[`campaign_category_id`][0].id}` : null;

    // Switch steps to history_steps for journey histories
    return {
      name: name,
      configuration: configuration,
      steps: steps,
      reportable: reportable,
      report_configuration: reportCfg,
      campaign_category_id: category
    };
  }

  public refreshValues(journey, data) {
    journey.steps.forEach(
      (_, index) => {
        const input = this.inputs.find(_input => _input.key === `step${index + 1}.scheduled_at`);
        const schedule = data.steps[index].scheduled_at;
        if ( schedule ) {
          input.value = this.dateService.dateISOStringToDateTime(data.steps[index].scheduled_at);
        }
      }
    );
  }
}
