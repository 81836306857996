import { AbstractControl, UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";

export function getFormGroupValidations(control: UntypedFormGroup): ValidationErrors | null {
  const operatorCtrl = control.get('operator');
  const dateCtrl = control.get('date_value');
  const dateBtCtrl = control.get('date_value_bt');

  const dateValue = dateCtrl?.value ?? null;
  const dateBtValue = dateBtCtrl?.value ?? null;

  const isNoDateValue = !!(!dateValue || (dateValue instanceof Object && !Object.keys(dateValue)?.length));

  dateBtCtrl.setValidators([]);

  if (operatorCtrl?.value?.length) {
    if (operatorCtrl.value[0].id === 'bt') {
      dateBtCtrl.setValidators([Validators.required]);
      const isNoDateBtValue = !!(!dateBtValue || (dateBtValue instanceof Object && !Object.keys(dateBtValue)?.length));

      if (isNoDateValue && isNoDateBtValue) {
        setErrorsMark(dateCtrl);
        setErrorsMark(dateBtCtrl);
        return {['fullDateRangeRequired']: true};
      } else if (isNoDateValue && !isNoDateBtValue) {
        setErrorsMark(dateCtrl);
        return {['dateValueRequired']: true};
      } else if (!isNoDateValue && isNoDateBtValue) {
        setErrorsMark(dateBtCtrl);
        return {['dateBtValueRequired']: true};
      }
    } else {
      if (isNoDateValue) {
        setErrorsMark(dateCtrl);
        return {['dateValueRequired']: true};
      }
    }
  } else {
    setErrorsMark(operatorCtrl);
    return {['operatorRequired']: true};
  }

  return null;
}

function setErrorsMark(ctrl: AbstractControl): void {
  ctrl.setErrors({invalidRequired: true});
  ctrl.markAsDirty();
}
