import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'orderBySelected'
})
export class OrderBySelectedPipe implements PipeTransform {
  transform(value: any[], selectedList: any | any[]): any[] {
    if (value && value.length > 0 && selectedList) {
      if (selectedList instanceof Array) {
        const selectedIds = selectedList.map(item => item.id);
        const optionsListFiltered = value.filter(v => !selectedIds.includes(v.id));
        return [...selectedList, ...optionsListFiltered]; 
      } else {
        const optionsListFiltered = value.filter(v => v.id !== selectedList.id);
        return [selectedList, ...optionsListFiltered];
      }
    } else {
      if (selectedList) {
        if (selectedList instanceof Array) {
          return [...selectedList, ...value]
        } else {
          return [selectedList, ...value]
        }
      } else {
        return value;
      }
    }
  }
}