import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { ProfileFormService } from './form-profile.service';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { ProfileSchemaForm } from '../../../../shared/models/profile-schema-form';
import { Observable } from 'rxjs';
import { FeatureFlagsService } from './../../../../shared/services/feature-flags.service';
import { CurrentUser } from '../../../../shared/models/current-user';
import { ProfileService } from '../../../../profiles/profile.service';
import { HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';
import { isNullOrUndefined } from '../../../../shared/utils/common.utils';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-form-profiles',
  templateUrl: './form-profiles.component.html',
  styleUrls: ['./form-profiles.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FormProfilesComponent implements OnInit, OnDestroy {

  profilesForm: UntypedFormGroup;
  inputs: QuestionBase<any>[];
  id: string;
  loading: boolean;
  normalizedKeysArray: any;
  profileSchemaForm: ProfileSchemaForm;
  customInputs: QuestionBase<any>[] = [];
  currentUser: CurrentUser = new CurrentUser(this.profileService.getStoredUser());
  flags = this.featureFlags.flags;

  get creationMode(): boolean {
    return !this.id;
  }

  get isCreationFormWithUnifiedCustomers(): boolean {
    return this.creationMode && !!this.currentUser?.company?.unifiedCustomers;
  }

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private profileFormService: ProfileFormService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private modalStatusService: ModalStatusService,
    private qcs: QuestionControlService,
    private featureFlags: FeatureFlagsService,
    private profileService: ProfileService,
    private changeDetector: ChangeDetectorRef,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  getStatusInputConfig(): QuestionBase<any> {
    const qb = this.getInputConfig('status');
    if (this.hasFormKeyWithValue('status', 'merged')) {
      qb.disabled = true;
    } else {
      qb.options = qb.options.filter(option => option.id !== 'merged');
    }
    return qb;
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.profilesForm, formKey, value);
  }

  save(): void {
    if (isNullOrUndefined(this.profilesForm.value.terms) || !this.profilesForm.value.terms) {
      const accepts = this.translate.instant('resources.profiles.form.fields.warnings.accepts_lopd');
      this.displayErrorAlert(accepts);
    } else {
      const payload = this.profileFormService.preparePayload(this.profilesForm, this.id, this.isCreationFormWithUnifiedCustomers);
      this.loading = true;
      const request$ = this.creationMode ? this.profileFormService.createCustomerProfile(payload) : this.profileFormService.updateCustomerProfile(payload, this.id);
      const successTitle = this.translate.instant(this.creationMode ? 'resources.profiles.form.fields.warnings.success_title' : 'resources.profiles.form.fields.warnings.update_title');
      const successText = this.translate.instant(this.creationMode ? 'resources.profiles.form.fields.warnings.success_text' : 'resources.profiles.form.fields.warnings.update_text');
      this.handleRequest(request$, successTitle, successText);
    }
  }

  goBack(): void {
    this.location.back();
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.hasOwnProperty('id') && !isNullOrUndefined(params.id)) {
        this.id = params.id;
      }
      this.getFormInputs();
    });
  }

  private getProfilesById(): void {
    this.profileFormService.getProfilesById(this.id).pipe(takeUntil(this.destroy$)).subscribe(
      (data: HttpResponse<object>) => {
        this.setInputsAndForm(data);
        this.changeDetector.markForCheck();
      },
      (errData: HttpErrorResponse) => {
        this.displayErrorAlert(errData.error.error);
      }
    );
  }

  private handleRequest(request$: Observable<unknown>, successTitle: string, successText: string): void {
    request$.pipe(takeUntil(this.destroy$)).subscribe(
      () => {
        this.confirmationService.displaySuccessAlert(successTitle, successText ).catch(() => {});
        this.modalStatusService.modalStatus.emit();
        this.goBack();
        this.changeDetector.markForCheck();
      },
      (errorData: HttpErrorResponse) => {
        this.loading = false;
        const errorValue = errorData?.error?.error ? errorData.error.error : null;
        if (errorData?.error?.errors?.length) {
          this.paintErrorsInForm(this.profilesForm, errorData);
          if (errorValue) { this.displayErrorAlert(errorValue); }
        } else if (errorValue) {
          this.displayErrorAlert(errorValue);
        }
        this.changeDetector.markForCheck();
      }
    );
  }

  private getFormInputs(): void {
    const form$ = this.creationMode ? this.profileFormService.getNewFormFields() : this.profileFormService.getUpdateFormFields();
    form$.pipe(takeUntil(this.destroy$)).subscribe(
      (formData: HttpRequest<object>) => {
        this.profileSchemaForm = new ProfileSchemaForm(formData);
        if (this.id) {
          this.getProfilesById();
        } else {
          this.setInputsAndForm({});
          this.inputs.find(input => input.key === 'preferred_language').selectedIds = [this.currentUser.company.locale.slice(0,2)];
        }
        this.changeDetector.markForCheck();
      },
      (err: HttpErrorResponse) => {
        this.displayErrorAlert(err.error.error);
      }
    );
  }

  private setInputsAndForm(requestData: object | HttpResponse<object>): void {
    this.inputs = this.profileFormService.getInputs(requestData, this.profileSchemaForm, this.currentUser);
    this.profilesForm = this.qcs.toFormGroup(this.inputs);
    this.customInputs = this.inputs.filter(input => input.advanced);
  }

  private displayErrorAlert(error: string) {
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), error);
  }

  private paintErrorsInForm(form: UntypedFormGroup, errorObject: HttpErrorResponse): void {
    let errorsArray = [];
    if (errorObject?.error?.error) {
      errorsArray.push({
        field: 'Global',
        field_name: 'Global',
        message: errorObject.error.error
      });
    }

    this.normalizedKeysArray = this.qcs.getArrayWithNormalizedKeys(errorObject.error.errors, ['profiles.', 'profiles[0][', ']']);
    if (errorsArray) {
      errorsArray = [...errorsArray, ...this.normalizedKeysArray];
      this.qcs.paintErrorsInForm(this.inputs, form, errorsArray);
    }
  }
}
