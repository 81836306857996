import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { FrogedService } from '../../services/froged/froged.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})

export class ErrorPageComponent {

  constructor(
    private location: Location,
    private frogedService: FrogedService,
  ) { }

  goBack(): void {
    this.location.back();
    this.trackFrogedEvent('back');
  }

  reload(): void {
    this.trackFrogedEvent('reload');
    // Reload the page after 200ms to allow the tracking event to be sent
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  trackFrogedEvent(event: string): void {
    this.frogedService.track(`PLATFORM_error_${event}`);
  }

}
