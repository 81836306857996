<div class="alert alert-info" role="alert" *ngIf="!onSearch && !loading">
  <span>{{ 'components.looker-iframe.alerts.apply_filters' | translate }}</span>
</div>

<div class="row" *ngIf="onSearch">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title d-inline" [translate]="'dashboards.results.voucher_count.menu.coupons_per_person'"></h4>
        <i class="tooltip-position ti-info-alt mrg-top-10 font-size-14 pull-right"
           tooltipPosition="left"
           pTooltip="{{ 'dashboards.results.voucher_count.menu.tooltip_coupons_per_person' | translate }}"></i>
      </div>

      <app-loader styleClass="mrg-btm-15" *ngIf="loading" />

      <div class="card-block no-pdd no-mrg pdd-horizon-20" *ngIf="voucher_data">
        <table *ngIf="!loading" class="table table-bordered table-hover table-stripped table-sm" aria-hidden="true">
          <thead>
            <th></th>
            <th class="text-center" [translate]="'dashboards.results.voucher_count.table.coupons_assigned'"></th>
            <th class="text-center" [translate]="'dashboards.results.voucher_count.table.coupons_printed'"></th>
            <th class="text-center" [translate]="'dashboards.results.voucher_count.table.messages_assigned'"></th>
            <th class="text-center" [translate]="'dashboards.results.voucher_count.table.messages_printed'"></th>
          </thead>
          <tr *ngFor="let item of vouchersCount">
            <td class="text-left"><span class="label font-size-12"><b>{{item.id}}</b></span></td>
            <td class="text-right"><span class="label font-size-12">{{item.coupons_assigned | number:'1.0-0':'es'}}</span></td>
            <td class="text-right"><span class="label font-size-12">{{item.coupons_printed | number:'1.0-0':'es'}}</span></td>
            <td class="text-right"><span class="label font-size-12">{{item.messages_assigned | number:'1.0-0':'es'}}</span></td>
            <td class="text-right"><span class="label font-size-12">{{item.messages_printed | number:'1.0-0':'es'}}</span></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
