import { TranslateService } from "@ngx-translate/core";
import { CheckboxQuestion } from "../../../../../shared/models/forms/question-checkbox";
import { MultiSelectQuestion } from "../../../../../shared/models/forms/question-multiselect";
import { TextboxQuestion } from "../../../../../shared/models/forms/question-textbox";
import { LocationForm } from "../../../../../shared/models/locations/location-form";
import { CountriesService } from "../../../../../shared/services/countries.service";
import { LocationsService } from "../../locations.service";
import { LocationsTaxonomyTermsService } from "../../location-taxonomy-terms.service";
import { ScoreExchangesService } from "../../../../scores/score_exchanges/score-exchanges.service";

export function getLocationsFormInputs(
  formValues: LocationForm, 
  isEditMode: boolean,
  translate: TranslateService,
  countriesService: CountriesService, 
  locationsService: LocationsService,
  locationTaxonomyTermsService: LocationsTaxonomyTermsService,
  scoreExchangesService: ScoreExchangesService
  ): (MultiSelectQuestion | TextboxQuestion | CheckboxQuestion)[] {
    return [
      new TextboxQuestion({
        key: 'id',
        type: 'number',
        cssClasses: 'form-control input-default',
        value: formValues?.id ?? null,
        disabled: isEditMode
      }),
      new TextboxQuestion({
        key: 'code',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: formValues?.code ?? null,
        disabled: isEditMode
      }),
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: formValues?.name ?? null,
        required: true
      }),
      new TextboxQuestion({
        key: 'address',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: formValues?.address ?? null,
        required: true
      }),
      new TextboxQuestion({
        key: 'state',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: formValues?.state ?? null,
      }),
      new TextboxQuestion({
        key: 'city',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: formValues?.city ?? null,
        required: true
      }),
      new TextboxQuestion({
        key: 'postal_code',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: formValues?.postal_code ?? null,
        required: true
      }),
      new MultiSelectQuestion({
        key: 'country',
        type: 'text',
        cssClasses: 'form-control input-default',
        options: countriesService.getCountries(),
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        value: formValues?.country ? [countriesService.getCountriesById(formValues.country)] : [],
        required: true
      }),
      new TextboxQuestion({
        key: 'longitude',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: formValues?.longitude ?? null,
      }),
      new TextboxQuestion({
        key: 'latitude',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: formValues?.latitude ?? null,
      }),
      new TextboxQuestion({
        key: 'schedule',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: formValues?.schedule ?? null,
      }),
      new MultiSelectQuestion({
        key: 'status',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: locationsService.getStatusOptions(),
        value: formValues?.status ? [locationsService.getStatusOptions().find(option => option.id === formValues.status)] : [],
        required: true
      }),
      new CheckboxQuestion({
        label: translate.instant('resources.locations.form.gift_card_available'),
        key: 'gift_card_available',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        value: formValues?.gift_card_available ?? false
      }),
      new MultiSelectQuestion({
        key: 'location_taxonomy_terms',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: locationTaxonomyTermsService,
        selectedIds: formValues?.location_taxonomy_terms?.length ? formValues.location_taxonomy_terms.map(item => item.id) : []
      }),
      new MultiSelectQuestion({
        key: 'score_exchange_id',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        dataSource: scoreExchangesService,
        selectedIds: formValues?.score_exchange_id ? [formValues.score_exchange_id] : []
      }),
      new TextboxQuestion({
        key: 'phone',
        type: 'number',
        cssClasses: 'form-control input-default',
        value: formValues?.phone ?? null
      }),
      new TextboxQuestion({
        key: 'email',
        type: 'email',
        cssClasses: 'form-control input-default',
        value: formValues?.email ?? null
      }),
      new TextboxQuestion({
        key: 'goals',
        type: 'number',
        cssClasses: 'form-control input-default',
        value: formValues?.goals?.signups?.week ?? null
      })
    ];
}