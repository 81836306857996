import { Component, OnInit } from '@angular/core';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageCategoriesService } from '../message-categories.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';

@Component({
  selector: 'app-form-message-categories',
  templateUrl: './form-message-categories.component.html',
  styleUrls: ['./form-message-categories.component.css'],
  providers: [MessageCategoriesService]
})
export class FormMessageCategoriesComponent implements OnInit {

  public mesageCategoriesInputs: QuestionBase<any>[];
  public messageCategoriesForm: UntypedFormGroup;
  public inputs: any;
  public id: number;
  public showButton: boolean;
  public loading: boolean;

  constructor(private qcs: QuestionControlService,
              private route: ActivatedRoute,
              private router: Router,
              private messageCategoriesService: MessageCategoriesService,
              private confirmationService: ConfirmationService,
              private translate: TranslateService,
              private modalStatusService: ModalStatusService) {}

  ngOnInit() {
    this.getParams();
    this.showBackButton();
  }

  showBackButton() {
    this.showButton = !(this.router.url.indexOf('(modal:') >= 0);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getMessageCategoriesById(this.id);
      } else {
        this.inputs = this.messageCategoriesService.getInputs({});
        this.messageCategoriesForm = this.qcs.toFormGroup(this.inputs);
      }
    });
  }

  private getMessageCategoriesById(id: number) {
    this.messageCategoriesService.getMessageCategoriesById(this.id).subscribe(
      data => {
        this.inputs = this.messageCategoriesService.getInputs(data);
        this.messageCategoriesForm = this.qcs.toFormGroup(this.inputs);
      }
    );
  }

  public sendData() {

    const messageCategoriesOptions = {
      name: this.messageCategoriesForm.value.name,
    };

    if ( this.id ) {
      this.messageCategoriesService.updateData(messageCategoriesOptions, this.id).subscribe(
        response => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.message_categories.form.warnings.update_title'),
            this.translate.instant('resources.message_categories.form.warnings.update_text')
          ).catch(() => {});
          this.modalStatusService.modalStatus.emit();
          this.returnToList();
        }
      );
      } else {
        this.loading = false;
        this.messageCategoriesService.sendData(messageCategoriesOptions).subscribe(
        response => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.message_categories.form.warnings.success_title'),
            this.translate.instant('resources.message_categories.form.warnings.success_text')
          ).catch(() => {});
          this.loading = true;
          this.modalStatusService.modalStatus.emit();
          this.returnToList();
        }
      );
    }

  }

  public returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/omnichannel-manager/ticket-messages/categories']).catch(() => {});
    }
  }

}
