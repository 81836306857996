<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true" *ngIf="element">
              <tbody>
                <tr *ngIf="element.id">
                  <td class="no-border-top"><b [translate]="'resources.scores.reward_taxonomies.columns.id'"></b></td>
                  <td class="no-border-top">{{element.id}}</td>
                </tr>
                <tr *ngIf="element.name">
                  <td><b [translate]="'resources.scores.reward_taxonomies.columns.name'"></b></td>
                  <td>{{element.name}}</td>
                </tr>
                <tr *ngIf="element.slug">
                  <td><b [translate]="'resources.scores.reward_taxonomies.columns.slug'"></b></td>
                  <td>{{element.slug}}</td>
                </tr>
                <tr *ngIf="element.origin">
                  <td><b [translate]="'resources.scores.reward_taxonomies.columns.origin'"></b></td>
                  <td>{{'resources.scores.reward_taxonomies.origin.' + element.origin | translate}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
