<ng-select #select
  bindLabel="name"
  [attr.data-cy]="'multiselect_' + (question.key | camelCase)"
  [ngClass]="ctrlValid ? '' : 'ng-invalid'"
  [(ngModel)]="selectedItems"
  [items]="dropdownList | orderBySelected: selectedItems"
  [multiple]="!settings.singleSelection"
  [searchable]="false"
  [closeOnSelect]="settings.singleSelection"
  [loading]="loading"
  [disabled]="question.disabled"
  [virtualScroll]="true"
  [typeahead]="userSearch$"
  [hideSelected]="false"
  (change)="onChange($event)"
  (scrollToEnd)="loadMore($event)"
  (open)="onOpen($event)"
  (close)="onClose()"
  [clearOnBackspace]="false">

  <ng-template ng-header-tmp>
    <input *ngIf="settings.enableSearchFilter"
      #searchInput
      [placeholder]="'components.multiselect.search' | translate"
      style="width: 100%; line-height: 24px"
      type="text"
      autocomplete="off"
      [(ngModel)]="searchTerm"
      (keyup)="handleUserSearch(searchInput.value, select)"/>
    <div class="select-all-box" *ngIf="settings.enableCheckAll && dropdownList.length > 1">
      <input [checked]="allItemsSelected()"
        id="select-all"
        name="select-all"
        class="checkbox-option"
        type="checkbox"
        (change)="onSelectAllChange()">
      <label for="select-all" >{{'components.multiselect.select_all' | translate}}</label>
    </div>
  </ng-template>

  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!settings.singleSelection">
    <div class="ng-summary-list" *ngIf="selectedItems.length === 1">
      <span class="ng-value-label">
        {{selectedItems[0].name}}
      </span>
    </div>
    <div class="ng-summary-list" *ngIf="selectedItems.length > 1">
      <span class="ng-value-label">
        {{ selectedItems.length }} {{'components.multiselect.selected' | translate}}
      </span>
    </div>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-index="index">
    <!-- Added handleSingleSelectionItem because ng-option-tmp has an internal toggletItem method to select multiple and single options but it only unselects multiple options -->
   <div (click)="handleSingleSelectionItem(item)"
      class="item-wrapper"
      [ngClass]="{'separation': (!settings.singleSelection && selectedItems?.length > 0 && index === selectedItems.length - 1) ||
      (settings.singleSelection && selectedItems && item.id === selectedItems.id)}">
      <input *ngIf="!settings.singleSelection || (settings.singleSelection && isOptSelected(item))" class="checkbox-option" type="checkbox" [checked]="isOptSelected(item)" >
      <span [attr.data-cy]="'span_' + (question.key + ' ' + item.id | camelCase)">{{item.name}}</span>
   </div>
  </ng-template>
</ng-select>