import { Option } from "../../../../../models/common/option";
import { CustomerAttributeCondition, CustomerAttributeConditionForm } from "../../../../../models/segments/conditions/customer-attribute-condition";
import { Operator } from "../../../../../models/segments/conditions/operator";
import { isNullOrUndefined } from "../../../../../utils/common.utils";
import { DateService } from "../../../../date.service";
import { OperatorsService } from "../../../common/operators.service";
import { CustomerAttributeKey } from "../enums/customer-attribute-key.enums";
import { CustomerAttributeType } from "../enums/customer-attribute-type.enums";

export function parseFormValuesFromAPI(
  params: CustomerAttributeCondition, 
  operatorsService: OperatorsService, 
  dateService: DateService,
  booleanValueOptions: Option[]
  ): CustomerAttributeConditionForm {

  let parsedValuesObj: CustomerAttributeConditionForm = {
    loaded_definition_id: params.definition_id as number,
    definition_type: params.definition_type
  };

  const operator = params.operator as string;
  switch(params.definition_type) {
    case CustomerAttributeType.String:
      parsedValuesObj.string_operator = getOperatorValue(operator, operatorsService.getStringOperators()),
      parsedValuesObj.string_value = params?.value as string ?? null;
      break;
    case CustomerAttributeType.Integer:
      getNumericOperatorValuesToForm(parsedValuesObj, params, operatorsService.getNumericOperators(), CustomerAttributeKey.IntegerOperator, CustomerAttributeKey.IntegerValue, CustomerAttributeKey.IntegerValue2);
      break;
    case CustomerAttributeType.Float:
      getNumericOperatorValuesToForm(parsedValuesObj, params, operatorsService.getNumericOperators(), CustomerAttributeKey.FloatOperator, CustomerAttributeKey.FloatValue, CustomerAttributeKey.FloatValue2);
      break;
    case CustomerAttributeType.Boolean:
      parsedValuesObj.boolean_operator = getOperatorValue(operator, operatorsService.getEqualityOperators());
      parsedValuesObj.boolean_value = getOptionValue(params.value, booleanValueOptions);
      break;
    case CustomerAttributeType.Datetime:
      parsedValuesObj.date_operator = getOperatorValue(operator, operatorsService.getNumericOperators());
      if (params.operator === 'bt') {
        parsedValuesObj.date_value = params?.hasOwnProperty('value1') ? dateService.dynamicDateToDateOption(params.value1) : null;
        parsedValuesObj.date_value2 = params?.hasOwnProperty('value2') ? dateService.dynamicDateToDateOption(params.value2) : null;
      } else {
        parsedValuesObj.date_value = params?.hasOwnProperty('value') ? dateService.dynamicDateToDateOption(params.value) : null;
      }
      break;
  }

  return parsedValuesObj;
}

function getNumericOperatorValuesToForm(parsedValuesObj: CustomerAttributeConditionForm, params: CustomerAttributeCondition, operators: Operator[], operatorKey: string, valueKey: string, value2Key?: string): void {
  const operatorId = params.operator as string;
  parsedValuesObj[operatorKey] = getOperatorValue(operatorId, operators);
  if (params.operator === 'bt') {
    parsedValuesObj[valueKey] = params?.value1 as number ?? null;
    parsedValuesObj[value2Key] = params?.value2 as number ?? null;
  } else {
    parsedValuesObj[valueKey] = params?.value as number ?? null;
  }
}

function getOperatorValue(operatorId: string, operators: Operator[]): Operator[] {
  const operator = operatorId ? operators.find(operator => operator.id === operatorId) : null;
  return operator ? [operator] : null;
}

function getOptionValue(optionId: string | boolean | number, options: Option[]): Option[] {
  const isOptionId = optionId ?? null;
  const option = isNullOrUndefined(isOptionId) ? null : options.find(option => option.id === optionId);
  return option ? [option] : null;
}