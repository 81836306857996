import 'moment/locale/en-gb';
import 'moment/locale/es';
import { AuthService } from './auth/auth.service';
import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { CurrentUser } from './shared/models/current-user';
import { FeatureFlagsService } from './shared/services/feature-flags.service';
import { LaunchDarklyService } from './shared/services/launch-darkly/launchdarkly.service';
import { ProfileService } from './profiles/profile.service';
import { RedirectionService } from './shared/services/redirection.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, OnDestroy {

  subs$: Subscription[];
  userData: CurrentUser;
  flags = this.featureFlags.flags;

  constructor(
    private authService: AuthService,
    private featureFlags: FeatureFlagsService,
    private launchDarklyService: LaunchDarklyService,
    private profileService: ProfileService,
    private redirectionService: RedirectionService,
    private renderer: Renderer2,
    private router: Router,
    private translate: TranslateService
  ) {
    this.subs$ = [];
  }

  ngOnInit() {
    this.getLocale();
    moment.locale(this.translate.getDefaultLang());
    this.handleLogOut();
    this.processFeatureFlag();
    const router$ = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.handleIfBodyScrollShouldBeHidden();
        this.handleRedirection(val);
      }
    });
    this.subs$.push(router$);
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  private getLocale() {
    const userData$ = this.profileService.getCurrentUserProfile().subscribe(
      response => {
        this.userData = new CurrentUser(response);
        const platformLanguage = this.flags.newLanguageMenuVisibility ? this.userData.userPreferredLanguage : this.userData.companyLanguage;
        this.translate.setDefaultLang(platformLanguage);
      }
    );
    userData$.unsubscribe();
  }

  private handleLogOut() {
    const auth$ = this.authService.emitter.subscribe(() => {
      this.router.navigate(['/signin']).then(() => window.location.reload()).catch(() => {});
    });
    this.subs$.push(auth$);
  }

  private handleIfBodyScrollShouldBeHidden() {
    if (this.evalIfBodyScrollHidden()) {
      this.renderer.addClass(document.body, 'hidden-vertical-scroll');
    } else {
      this.renderer.removeClass(document.body, 'hidden-vertical-scroll');
    }
  }

  private handleRedirection(navigationEnd: NavigationEnd) {
    if (this.redirectionService.isRedirectable(navigationEnd)) {
      const redirectUrl = this.redirectionService.getInternalUrl(navigationEnd);
      this.router.navigateByUrl(redirectUrl).catch(() => {});
    }
  }

  private evalIfBodyScrollHidden(): boolean {
    const isModalDisplayed = this.router.url.indexOf(`(modal:`) >= 0;
    const isProfileRedesignedDisplayed = this.flags.profileRedesignVisibility &&
      this.router.url.indexOf('/data-warehouse/profiles/') >= 0 &&
      this.router.url.indexOf('/summary') >= 0;
    return isModalDisplayed || isProfileRedesignedDisplayed;
  }

  private processFeatureFlag() {

    this.handleIfBodyScrollShouldBeHidden();

    this.launchDarklyService.flagChanges.subscribe((flags) => {
      if (flags['show.cdp.profiles.redesign']) { this.handleIfBodyScrollShouldBeHidden(); }
    });
  }

}
