<app-loader *ngIf="loading" />

<div class="alert alert-danger fade show mrg-btm-0" *ngIf="company && !company.isTagged()" translate>
  common.untagged_company
</div>

<div *ngIf="summary" class="row">
  <div class="col-md-12">
    <div class="customers-block bg-info text-white">
      <span>{{ 'dashboards.customers.tabs.customers.totals' | translate }}</span>
      <strong>{{ activityTotals | number: '1.0-0' }}</strong>
    </div>
  </div>

  <!-- LEFT COLUMN -->
  <div class="col-md-6">
    <div class="customers-block bg-primary text-white">
      <span>{{ 'dashboards.customers.tabs.customers.inactive' | translate }}</span>
      <strong>{{ totalInactive | number: '1.0-0' }}</strong>
    </div>

    <!-- Inactive activities -->
    <div class="row">
      <div *ngFor="let activity of inactiveActivities" class="col-xs-12 col-sm-6">
        <div class="customers-block bg-warning text-dark">
          <span>{{ activity.name }}</span>
          <strong>{{ activity.count | number: '1.0-0' }}</strong>
        </div>
      </div>
    </div>

    <!-- Max sales -->
    <div class="row">
      <div *ngFor="let sale of summary.max_sales" class="col-xs-12 col-sm-4">
        <div class="customers-block bg-success text-white">
          <span>{{ sale.name }}</span>
          <strong>{{ sale.count | number: '1.0-0' }}</strong>
        </div>
      </div>
    </div>
  </div>

  <!-- RIGHT COLUMN -->
  <div class="col-md-6">
    <div class="customers-block bg-primary text-white">
      <span>{{ 'dashboards.customers.tabs.customers.active' | translate }}</span>
      <strong>{{ totalActive | number: '1.0-0' }}</strong>
    </div>

    <!-- Active activities -->
    <div class="row">

      <!-- New -->
      <div class="col-xs-12 col-sm-6">
        <div class="customers-block bg-warning text-dark">
          <span>{{ 'dashboards.customers.tabs.customers.new' | translate }}</span>
          <strong>{{ summary.new | number: '1.0-0' }}</strong>
        </div>
      </div>

      <!-- Recurrent -->
      <div class="col-xs-12 col-sm-6">
        <div class="customers-block bg-warning text-dark">
          <span>{{ 'dashboards.customers.tabs.customers.recurrent' | translate }}</span>
          <strong>{{ totalActive - summary.new | number: '1.0-0' }}</strong>
        </div>
      </div>
    </div>

    <!-- Sales -->
    <div class="row">
      <div *ngFor="let sale of summary.sales" class="col-xs-12 col-sm-4">
        <div class="customers-block bg-success text-white">
          <span>{{ sale.name }}</span>
          <strong>{{ sale.count | number: '1.0-0' }}</strong>
        </div>
      </div>
    </div>
  </div>
</div>
