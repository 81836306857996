import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActionModalsService } from '../action-modals.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentsService } from '../../../../../../../comments/comments.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfirmationService } from '../../../../../../../../shared/services/confirmation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from '../../../../../../../../shared/utils/common.utils';
import { LocationsService } from '../../../../../../locations/locations.service';
import { ModalStatusService } from '../../../../../../../../shared/services/modal-status.service';
import { QuestionBase } from '../../../../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../../../../shared/services/question-control.service';
import { Subject, Subscription, finalize, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-grant-points',
  templateUrl: './grant-points.component.html',
  providers: [ ActionModalsService ]
})

export class GrantPointsComponent implements OnInit, OnDestroy {

  grantPointsForm: UntypedFormGroup;
  grantPointsInputs: QuestionBase<any>[];
  hasComments: boolean;
  id: number;
  loading: boolean;
  normalizedKeysArray: unknown[];
  subs$: Subscription[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private actionModalService: ActionModalsService,
    private commentsService: CommentsService,
    private confirmationService: ConfirmationService,
    private locationsService: LocationsService,
    private modalStatusService: ModalStatusService,
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.grantPointsInputs, inputKey);
  }

  countrySelector(event) {
    const countriesIds = this.grantPointsInputs?.find(input => input.key === 'country').value?.map(item => item.id);
    this.locationsService.setCountries(countriesIds);
  }

  sendData() {

    const comments: string = this.hasComments ? this.grantPointsForm?.value?.multi_comments?.[0]?.name : this.grantPointsForm?.value?.comments;
    const country: string = this.grantPointsForm?.value?.country?.[0]?.id || null;
    const location_id: number = this.grantPointsForm?.value?.location_id?.[0]?.id || [];
    const loyalty_score_id: number = this.grantPointsForm?.value?.loyalty_score_id?.[0]?.id || [];
    const score: number = parseInt(this.grantPointsForm?.value?.score) || null;

    const payload: object = { score, country, location_id, loyalty_score_id, comments };

    this.loading = true;
    this.getConfirmationAlert(payload);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getConfirmationAlert(payload: object) {

    const titleMessage = this.translate.instant('common.messages.are_you_sure');
    const confirmationMessage = this.translate.instant('resources.customers.show.action_modals.messages.confirm_grant_points');

    this.confirmationService.displayConfirmationAlert( titleMessage, confirmationMessage ).then(data => {
      if (data?.value) {
        this.sendRequest(payload);
      } else {
        this.loading = false;
      }
    }).catch(() => {});
  }

  private getParams() {
    this.route.params.subscribe(params => {
      this.id = params.id;
      this.getCommentsResults().then(() => {
        this.retrieveGrantPointsInputs();
        this.setValidators();
      });
    });
  }

  private retrieveGrantPointsInputs() {
    const excludedKeys = ['destination_customer_id', 'allow_partial', 'new_customer_id_code', 'score_overwrite', 'reward_id'];
    const commentsKey = this.hasComments ? 'comments' : 'multi_comments';

    excludedKeys.push(commentsKey);

    this.grantPointsInputs = this.actionModalService.getInputs().filter(input => {
      return !excludedKeys.includes(input.key);
    });

    this.grantPointsForm = this.qcs.toFormGroup(this.grantPointsInputs);
  }

  private sendRequest(payload) {
    this.actionModalService.grantPoints(payload, this.id).pipe(takeUntil(this.destroy$), finalize(() => this.loading = false)) .subscribe({
      next: () => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.customers.warnings.grant_points_title'),
          this.translate.instant('resources.customers.warnings.grant_points_text')
        ).catch(() => {});
        this.modalStatusService.modalStatus.emit();
        this.closeModal();
      },
      error: (errorData: HttpErrorResponse) => {
        this.confirmationService.displayHttpErrorAlert(errorData);
      }
    });
  }

  private getCommentsResults(): Promise<void> {
    this.loading = true;
    return new Promise<void>((resolve, reject) => {
      this.commentsService.getComments().subscribe({
        next: (response: Comment[]) => {
          this.loading = false;
          this.hasComments = !!response?.length;
          resolve();
        },
        error: (error: HttpErrorResponse) => {
          this.loading = false;
          this.confirmationService.displayHttpErrorAlert(error);
          reject(error);
        }
      });
    });
  }

  private setValidators() {

    const commentsControlName = this.hasComments ? 'multi_comments' : 'comments';
    let controlNames = ['score', 'location_id', 'loyalty_score_id', 'country', commentsControlName];

    controlNames.forEach(controlName => {
      const control = this.grantPointsForm.get(controlName);

      const subscription = control.valueChanges.subscribe(() => {
        this.updateValidators(control);
      });

      this.subs$.push(subscription);
    });
  }

  private updateValidators(formControl: AbstractControl) {

    if (isNullOrUndefined(formControl)) {
      return;
    }

    formControl.clearValidators();

    if (!formControl.value) {
      formControl.setValidators([Validators.required]);
    } else {
      formControl.setValidators(null);
    }

    if (!formControl['__updatingValidators']) {
      formControl['__updatingValidators'] = true;
      formControl.updateValueAndValidity();
      formControl['__updatingValidators'] = false;
    }

  }

}
