import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from '../../../shared/services/resources.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Injectable()
export class CustomersService extends ResourcesService {

  refreshComponent$ = new Subject<string>();
  applyFilters$ = new Subject<any>();
  filters: any;

  constructor(http: HttpClient) {
    super(http);
    this.filters = {};
  }

  getCustomerById(id: number, extraParams: object = {}): Observable<any> {
    return this.getData({apiEndPoint: `customers/${id}`, filtering: extraParams});
  }

  getAttributesByCustomerId(id: number, extraParams: object = {}): Observable<unknown> {
    return this.getData({apiEndPoint: `customers/${id}/attributes`, filtering: extraParams});
  }

  getAssociatesByCustomerId(id: number): Observable<any> {
    return this.getData({apiEndPoint: `customers/${id}/associates`});
  }

  getAssociationsByCustomerId(id: number): Observable<any> {
    return this.getData({apiEndPoint: `customers/${id}/parents`});
  }

  getTiersByCustomerId(id: number): Observable<any> {
    return this.getData({apiEndPoint: `customers/${id}/tiers`});
  }

  getLastYearsNumber(threshold: number) {
    const yearOptions = [];
    const year = moment().year();
    for (let index = 0; index < threshold; index++) {
      const id = year - index;
      yearOptions.push({ id: id, name: `${id}` });
    }
    return yearOptions;
  }
}
