import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { HighchartsConfigService } from '../../../../../shared/services/highcharts-config.service';
import { ReportsService } from '../../reports.service';
import { chart } from 'highcharts';
import * as Highcharts from 'highcharts';
import { SegmentReportData } from '../../report.model';

@Component({
  selector: 'app-show-report-segment',
  templateUrl: './show-report-segment.component.html',
  styleUrls: ['./show-report-segment.component.css']
})

export class ShowReportSegmentComponent implements OnInit {

  @Input() element: SegmentReportData;
  @Input() id: number;

  public chart: Highcharts.Chart;
  public reportData: object;

  @ViewChild('reportSegmentChart') reportSegmentChart: ElementRef;

  constructor(
    private reportService: ReportsService,
    private highChartsConfigService: HighchartsConfigService
  ) { }

  ngOnInit() {
    this.getReportData();
    setTimeout(() => {
      this.prepareGraph(this.element);
    }, 100);
  }

  private getReportData() {
    this.reportService.getReportById(this.id).subscribe(
      resp => {
        this.reportData = resp;
      }
    );
  }

  private prepareGraph(detail) {

    let date;
    let history;
    const series = [];
    let graphOpts = {};

    detail.segments_evolution.forEach(function(segment) {
      segment.timeSeries = [];
      for (let idx = 0; idx < segment.histories.length; idx++) {
        history = segment.histories[idx];
        date = Date.UTC(history.year, history.month - 1, history.day, 0);
        segment.timeSeries.push([date, history['customers']]);
      }

      series.push({
        name: segment.name,
        data: segment.timeSeries
      });
    });

    graphOpts = Object.assign(this.highChartsConfigService.getConfig('line'), {
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: { // don't display the dummy year
          month: '%e %b %Y',
          year: '%b'
        },
        title: {
          text: 'Fecha'
        }
      },
      yAxis: [{
        title: { text: 'Clientes'},
        min: 0
      }],
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          },
          enableMouseTracking: true
        }
      },
      tooltip: {
        shared: true,
        formatter: function () {
          let tooltip;
          tooltip = '';
          if(this.points){
            tooltip += '<p><strong>' + Highcharts.dateFormat('%e/%m/%Y', this.points[0].x) + '</strong></p><br>';
            for (let idx = 0; idx < this.points.length; idx++) {
              if ( this.points[idx]) {
                tooltip += '<strong>' + this.points[idx].series.name + ':</strong> ' + this.points[idx].y + '<br>';
              }
            }
          }
          return tooltip;
        }
      }
    });

    this.chart = chart(this.reportSegmentChart.nativeElement, graphOpts);

    series.forEach(item => {

      this.chart.addSeries({
        name: item.name,
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.x:%m-%Y}: {point.name}'
        },
        type: 'line',
        data: item.data,
        lineWidth : 1,
        marker: {
          radius: 4,
          symbol: 'diamond'
        }
      }, false);
    });

    this.chart.redraw();
  }

}
