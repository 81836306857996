import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CurrencyConversionsService extends ResourcesService  {

  public inputs: any[];

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getConversionsById(id: number): Observable<any> {
    return this.getData({apiEndPoint: `currency_conversions/${id}`});
  }

  public sendData(creditsData) {
    return this.postResource(creditsData, 'currency_conversions');
  }

  public updateData(creditsData: any, _id: number) {
    return this.patchResource(creditsData, `currency_conversions/${_id}`);
  }

}
