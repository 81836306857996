import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-show-campaign-categories',
  templateUrl: './show-campaign-categories.component.html',
  styleUrls: ['./show-campaign-categories.component.css']
})
export class ShowCampaignCategoriesComponent implements OnInit {

  public element: any;
  public apiEndPoint: string;
  public id: number;
  public isModal: boolean;

  constructor(
    public route: ActivatedRoute,
    public resourcesService: ResourcesService,
    public translate: TranslateService,
    public router: Router
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.isModal = this.router.url.indexOf('(modal:show/campaign_categories/') >= 0;
    this.getParams();
    this.resourcesService.getData({ apiEndPoint: this.apiEndPoint + '/' + this.id })
        .subscribe(res => {
          this.element = res;
        },
        error => {
          console.log('error:', error);
        });
  }

  getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'campaign_categories';
      }
    });
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

}
