import { CustomEntityCondition, CustomEntityConditionForm } from "../../../../../models/segments/conditions/custom-entity-condition";
import { CustomEntityAttributeType } from "../enums/custom-entity-attribute-type.enums";
import { CustomEntityKey } from "../enums/custom-entity-key.enums";

export function parseCustomEntityFormValuesToAPI(params: CustomEntityConditionForm): CustomEntityCondition {
  let conditionObj: CustomEntityCondition =  {
    definition_id: params?.definition_id?.length ? params.definition_id[0].rawElement['id'] : null as number,
    slug: params?.definition_id?.[0]?.rawElement ? params.definition_id[0].rawElement['slug'] : null,
    ce_conditions: [],
    date_from: params?.date_from?.['id'] ?? null,
    date_to: params?.date_to?.['id'] ?? null
  }

  const attributesConfiguration = params?.entity_attributes_configuration;
  if (attributesConfiguration?.length) {
    attributesConfiguration.forEach((attributeConfig: CustomEntityConditionForm) => {
      const itemConditionObj = {
        field_type: attributeConfig?.entity_attribute_type ?? null,
        field_name: attributeConfig?.entity_attribute?.length ? attributeConfig.entity_attribute[0].id : null
      }
      getAttributeConfigOperatorsValuesPayload(itemConditionObj, attributeConfig);
      conditionObj.ce_conditions.push(itemConditionObj);
    });
  }

  // Set Quantity operator and values
  getOperatorValuesPayload(conditionObj, params, CustomEntityKey.QuantityOperator, CustomEntityKey.QuantityValue, CustomEntityKey.QuantityValue2, true);
  return conditionObj;
}

function getAttributeConfigOperatorsValuesPayload(itemConditionObj: CustomEntityCondition, attributeConfig: CustomEntityConditionForm): void {
  switch(attributeConfig.entity_attribute_type) {
    case CustomEntityAttributeType.String:
      getOperatorValuesPayload(itemConditionObj, attributeConfig, CustomEntityKey.StringOperator, CustomEntityKey.StringValue, null);
      break;
    case CustomEntityAttributeType.Integer:
      getOperatorValuesPayload(itemConditionObj, attributeConfig, CustomEntityKey.IntegerOperator, CustomEntityKey.IntegerValue, CustomEntityKey.IntegerValue2);
      break;
    case CustomEntityAttributeType.Float:
    case CustomEntityAttributeType.Number:
      getOperatorValuesPayload(itemConditionObj, attributeConfig, CustomEntityKey.FloatOperator, CustomEntityKey.FloatValue, CustomEntityKey.FloatValue2);
      break;
    case CustomEntityAttributeType.Boolean:
      getOperatorValuesPayload(itemConditionObj, attributeConfig, CustomEntityKey.BooleanOperator, CustomEntityKey.BooleanValue, null);
      break;
    case CustomEntityAttributeType.Datetime:
    case CustomEntityAttributeType.Date:
      getOperatorValuesPayload(itemConditionObj, attributeConfig, CustomEntityKey.DateOperator, CustomEntityKey.DateValue, CustomEntityKey.DateValue2);
      break;
  }
}

function getOperatorValuesPayload(conditionObj: CustomEntityCondition, params: CustomEntityConditionForm, operatorKey: string, valueKey: string, value2Key: string, isQuantity?: boolean): void {
  const operatorKeyPayload = isQuantity ? 'quantity_operator' : 'operator';
  const valueKeyPayload = isQuantity ? 'quantity_value' : 'value';
  const value1KeyPayload = isQuantity ? 'quantity_value1' : 'value1';
  const value2KeyPayload = isQuantity ? 'quantity_value2' : 'value2';
  conditionObj[operatorKeyPayload] = params?.[operatorKey]?.length ? params[operatorKey][0].id : null;
  if (conditionObj[operatorKeyPayload] !== 'bt') {
    getValueSimpleOperator(conditionObj, params, valueKeyPayload, valueKey);
  } else {
    conditionObj[value1KeyPayload] = parseValuesBtOperator(params, valueKey);
    conditionObj[value2KeyPayload] = parseValuesBtOperator(params, value2Key);
  }
}

function getValueSimpleOperator(conditionObj: CustomEntityCondition, params: CustomEntityConditionForm, valueKeyPayload: string, valueKey: string): void {
  if (params[valueKey] && (params[valueKey] instanceof Array)) {
    conditionObj[valueKeyPayload] = params[valueKey].length ? params[valueKey][0].id : null;
  } else if (params[valueKey] && (params[valueKey] instanceof Object)) {
    conditionObj[valueKeyPayload] = params[valueKey]?.id ?? null;
  } else {
    conditionObj[valueKeyPayload] = params[valueKey] ?? null;
  }
}

function parseValuesBtOperator(params: CustomEntityConditionForm, keyValue: string): string | number {
  return params[keyValue] && params[keyValue] instanceof Object ? params[keyValue]?.id ?? null : params[keyValue] ?? null;
}