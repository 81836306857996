import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LaunchDarklyService } from '../shared/services/launch-darkly/launchdarkly.service';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class SectionsGuard  {
  
  constructor(
    private router: Router,
    private launchDarklyService: LaunchDarklyService,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {

    if (!this.authService.isAuthenticated()) {
      setTimeout(() => void this.router.navigate(['/signin']));
      return false;
    } else {
      const routeFeatureFlag = route.routeConfig?.data?.featureFlag ? route.routeConfig.data.featureFlag : null;
      if (!routeFeatureFlag) {
        return true;
      } else {
        return this.launchDarklyService.client.waitUntilReady().then(() => {
          const flags = this.launchDarklyService.flags;
          const isFeatureFlagActivated = flags[routeFeatureFlag];
          if (!isFeatureFlagActivated) {
            this.router.navigate(['/error']).catch(() => {});
            return false;
          } else {
            return isFeatureFlagActivated;
          }
        });
      }
    }
  }
}