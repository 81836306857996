import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-campaign-report-block',
  templateUrl: './campaign-report-block.component.html',
  styleUrls: ['./campaign-report-block.component.scss']
})

export class CampaignReportBlockComponent {

  @Input('campaign') campaign;
  @Input('displayControlGroupRoundedWarning') displayControlGroupRoundedWarning;

}
