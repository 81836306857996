import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MultiselectDataSource } from '../../../shared/components/multiselect/multiselect';
import { Observable } from 'rxjs';
import { ResourcesService } from '../../../shared/services/resources.service';

@Injectable({
  providedIn: 'root'
})

export class RewardsCatalogService extends ResourcesService {

  customerId: number;

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  fetchMultiselect(searchValues?: string, page?: number): Observable<object> {

    let requestOptions = {
      apiEndPoint: 'rewards_catalog',
      useToken: true,
      nextToken: 0,
      filtering: {
        customer_id: this.customerId,
        redemption_type: 'manual',
        status: 'available',
        store: 'registration'
      },
      sorting: {}
    };

    requestOptions.nextToken = page;

    if (searchValues) {
      const nameFilter = { name: searchValues };
      requestOptions.filtering = { ...requestOptions.filtering, ...nameFilter };
    }

    return this.getData(requestOptions);
  }


  fetchSelectedById(id: number) {
    return this.getRewardsCatalogById(id);
  }

  getRewardsCatalogById(id: number): Observable<any> {
    return this.getData({apiEndPoint: `rewards_catalog/${id}`});
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`, element);
  }

  getCustomerId(customerId): number {
    return this.customerId = customerId;
  }

}
