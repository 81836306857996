export interface CustomerJourneyStepCfg {
  id?: number;
  campaign_id?: number;
  campaign_block_id?: number;
  type?: string;
  order?: number;
  original_status?: string;
  status?: string;
  campaign?: any;
  template?: any;
  actions?: object[];
  slug?: string;
  position?: number;
  motive?: string;
  configuration?: any;
  sent_at?: string;
  scheduled_at?: string;
  name?: string;
  _embedded?: {
    campaign?: any
  };
  customer_journey_type: string;
}

export class CustomerJourneyStep implements CustomerJourneyStepCfg {

  id?: number;
  campaign_id?: number;
  type?: string;
  order?: number;
  original_status?: string;
  status?: string;
  campaign?: any;
  template?: any;
  actions?: object[];
  slug?: string;
  position?: number;
  motive?: string;
  configuration?: any;
  sent_at?: string;
  scheduled_at?: any;
  name?: string;
  campaign_block_id: number;
  _embedded?: { campaign?: any };
  customer_journey_type: string;

  constructor( private cfg: CustomerJourneyStepCfg ) {
    this.campaign_block_id = cfg.campaign_block_id;
    this.campaign_id = cfg.campaign_id;
    this.configuration = cfg.configuration;
    this.order = cfg.position - 1;
    this.configuration.cut = cfg.configuration.cut ? parseInt(cfg.configuration.cut, 10) : this.order * 15;
    this.id = cfg.id;
    this.motive = cfg.motive;
    this.name = cfg.name;
    this.position = cfg.position;
    this.sent_at = this.dateISOStringToDateTime(cfg.sent_at);
    this.scheduled_at = this.dateISOStringToDateTime(cfg.scheduled_at);
    this.slug = cfg.slug;
    this.status = cfg.status;
    this.campaign = (cfg._embedded && cfg._embedded.campaign) ? cfg._embedded.campaign : undefined;
    this.customer_journey_type = cfg.customer_journey_type;
  }

  public getName(): string {
    return this.name;
  }

  /**
   * Method helper to check if step has message_id or coupon_id already defined
   */
  public hasKeyInConfiguration(configKey): boolean {
    return this.configuration.hasOwnProperty(configKey) &&
           this.configuration[configKey] &&
           ( !!this.configuration[configKey][0] || this.configuration[configKey] > 0 );
  }

  /**
   * Given step has valid cut value defined in configuration
   */
  public hasCutDefined(): boolean {
    return this.configuration.hasOwnProperty('cut') && this.configuration['cut'] >= 0;
  }

  /**
   * Given step has valid scheduled date defined in configuration
   */
  public hasScheduleDefined(): boolean {
    return this.scheduled_at && this.scheduled_at !== null && this.scheduled_at !== undefined;
  }

  /**
   * Method helper to retrieve id value from object and assign it to object to send to API
   * configDup: Object copied to send to API. i. e: {coupons: [{}], coupon_id: 111 }
   * configKey: key to search for in configuration. i. e: 'messages'
   * targetKey: API readable value. i. e. targetKey. i. e: 'message_id'
   */
  public copyKeyToStepConfig(configDup, configKey, targetKey) {
    if ( this.configuration.hasOwnProperty(configKey) &&
         this.configuration[configKey] ) {
      if (this.configuration[configKey][0] &&
          this.configuration[configKey][0].hasOwnProperty('id')) {
        configDup[targetKey] = parseInt(this.configuration[configKey][0].id, 10);
      } else {
        configDup[targetKey] = null;
      }
      delete configDup[configKey];
    }
  }

  private dateISOStringToDateTime(dateIsoString): string {
    if (dateIsoString !== undefined) {
      const date = new Date(dateIsoString);
      const timeOffset = date.getTimezoneOffset() * 60000;
      return (new Date(+date - timeOffset)).toISOString().slice(0, -1);
    }
  }

  /**
   * Only email, push and sms are vias with editable content
   */
  public hasEditableContent() {
    return ['email', 'push', 'sms'].includes(this.campaign.via);
  }

  /**
   * A customer journey step is only cancelable for slugs that contains 'rem' or are specific
   * reward types for upselling (premio alto). Its status must be is pending or paused.
   */
  public cancelable(): boolean {
    return (this.slug.indexOf('first') >= 0 ||
            this.slug.indexOf('rem') >= 0 ||
            this.slug.indexOf('reward1') >= 0) &&
           ['pending', 'paused'].indexOf(this.status) >= 0;
  }

  public activable(): boolean {
    if (['error', 'paused', 'cancelled'].indexOf(this.status) >= 0) {
      return true;
    } else {
      return false;
    }
  }

  public getStepLabelClass(): string {
    switch (this.status) {
      case 'cancelled': return 'label-danger';
      case 'done': return 'label-success';
      case 'enqueued': return 'label-success';
      case 'error': return 'label-danger';
      case 'paused': return 'label-warning';
      case 'pending': return 'label-primary';
      case 'running': return 'label-info';
    }
  }
}
