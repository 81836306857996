import { Injectable } from '@angular/core';
import { ProfileService } from '../../../profiles/profile.service';

@Injectable({ providedIn: 'root' })
export class FrogedService {
  
  constructor(
    private profileService: ProfileService
  ) {}
  
  setupFroged(): void {
    this.setAttributes();
    const isLGUser = this.profileService.isLGUser();
    if (isLGUser) {
      this.requestFroged('show')
    }
  }

  set(atts: any): void {
    this.requestFroged('set', atts);
  }

  logout(): void {
    this.requestFroged('logout');
  }

  track(eventName: string, atts?: any): void {
    this.requestFroged('track', eventName, atts);
  }
  
  private requestFroged(method: string, param1?: any, param2?: any): any {
    try { 
      return (<any>window[(<any>'Froged')])(method, param1, param2);
    } catch (error) {
      return null;
    }
  }
  
  private setAttributes() {
    const user = this.profileService.getStoredUser();
    const userFullname = user ? this.profileService.getStoredUserFullname(user) : '';
    
    this.set({
      userId: user && user.id ? user.id : '', 
      name: userFullname, 
      email: user && user.email ? user.email : '',
      account: {
        accountId: user && user.company && user.company.id ? user.company.id : '',
        name: user && user.company && user.company.name ? user.company.name : ''
      }
    })
  }
}