import { Injectable } from '@angular/core';
import swal, { SweetAlertResult, SweetAlertType } from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ConfirmationService {

  constructor(private translate: TranslateService) { }

  displayConfirmationAlert(title: string, text: string, type: SweetAlertType = 'warning'): Promise<SweetAlertResult> {
    return swal({
      type,
      title,
      text,
      showCancelButton: true,
      confirmButtonColor: '#AEDEF4',
      cancelButtonColor: '#DD6B55',
      confirmButtonText: this.translate.instant('common.buttons.confirm'),
      cancelButtonText: this.translate.instant('common.buttons.cancel')
    });
  }

  displayConfirmationAlertWithButtons(title: string, text: string, confirmText: string, cancelText: string, type: SweetAlertType = 'warning'): Promise<SweetAlertResult> {
    return swal({
      type,
      title,
      text,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirmText,
      cancelButtonText: cancelText
    });
  }

  displayAlert(title: string, text: string, type: SweetAlertType, showCancelButton?: boolean, showConfirmButton?: boolean): Promise<SweetAlertResult> {
    return swal({
      type,
      title,
      text,
      showCancelButton: showCancelButton ?? false,
      showConfirmButton: showConfirmButton ?? true,
    });
  }

  displayAlertWithHtml(title: string, html: string, type: SweetAlertType, showCancelButton?: boolean, showConfirmButton?: boolean): Promise<SweetAlertResult> {
    return swal({
      type,
      title,
      html,
      showCancelButton: showCancelButton ?? false,
      showConfirmButton: showConfirmButton ?? true,
    });
  }

  displayErrorAlert(title: string, text: string): void {
    swal(title, text, 'error').catch(() => { });
  }

  displaySuccessAlert(title: string, text: string): Promise<SweetAlertResult> {
    return swal(title, text, 'success');
  }

  displayOutsideUnclickableSuccessAlert(title: string, text: string): Promise<SweetAlertResult> {
    return swal({
      type: 'success',
      title,
      text,
      allowOutsideClick: false,
      allowEscapeKey: false
    });
  }

  displayTimerAlertWithButtons(title: string, html: string, timerId: string, timer: number | undefined, confirmText: string, cancelText: string, type: SweetAlertType = 'warning'): Promise<SweetAlertResult> {
    let timerInterval;
    return swal({
      type,
      title,
      html,
      timer,
      onBeforeOpen: (modal) => { modal.setAttribute('id', timerId) },
      onOpen: () => {
        const timerData = swal.getContent().querySelector('strong');
        timerInterval = setInterval(() => {
          timerData.textContent = `${Math.round(swal.getTimerLeft() / 1000)}`;
        }, 100);
      },
      onClose: () =>{clearInterval(timerInterval)},
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirmText,
      cancelButtonText: cancelText
    });
  }

  displayHttpErrorAlert(err: HttpErrorResponse): void {
    this.displayErrorAlert(this.translate.instant('common.error'), err.error.error);
  }
}
