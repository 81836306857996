import { BigqueryJobsStepsBase } from './bigquerystep-base';

export class PeriodStep extends BigqueryJobsStepsBase {
  category = 'period';

  constructor(options: {} = {}) {
    super(options);
    this.category = options['category'] || 'period';
  }

  public getPayload() {
    return super.getPayload();
  }
}
