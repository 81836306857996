import { Modules } from "../enums/modules.enums";

export const MODULE_ICONS = {
    [Modules.LoyaltyAnalytics]: 'fa-duotone fa-bag-shopping',
    [Modules.RetailGroupAnalytics]: 'fa-duotone fa-magnifying-glass-chart',
    [Modules.BasicAnalytics]: 'fa-duotone fa-chart-simple',
    [Modules.RetailAnalytics]: 'fa-duotone fa-chart-line-up',
    [Modules.SupplierAnalytics]: 'fa-duotone fa-bags-shopping',
    [Modules.OffersPersonalization]: 'fa-duotone fa-badge-percent',
    [Modules.Loyalty]: 'fa-duotone fa-star-exclamation',
    [Modules.Omnichannel]: 'fa-duotone fa-chart-network',
    [Modules.Audiences]: 'fa-duotone fa-chart-pie-simple',
    [Modules.CustomerDataPlatform]: 'fa-duotone fa-users-viewfinder',
    [Modules.Configuration]: 'fa-duotone fa-screwdriver-wrench',
    [Modules.Users]: 'fa-duotone fa-users-gear',
    [Modules.SystemStatus]: 'fa-duotone fa-heart',
    [Modules.LoyalGPT]: 'fa-duotone fa-microchip-ai',
} as const;