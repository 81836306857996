import { TitleCasePipe } from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'camelCase'
})

// Converts a string to camel case and strips hyphens (-), underscores (_), period (.) and whitespaces.
export class CamelCasePipe implements PipeTransform {
  
  constructor(
    private titleCasePipe: TitleCasePipe
  ) {}
  
  transform(value: string): string {
    return value && value.split(/[\-_.\s]+/g)
      .filter(word => !!word)
      .map((word, index) => {
        return index === 0 ? word.toLowerCase() : this.titleCasePipe.transform(word);
      })
      .join('');
  }
}

export function camelCase(value: string): string {
  return new CamelCasePipe(new TitleCasePipe()).transform(value);
}