import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SalesService } from './sales.service';
import { ProfileService } from '../../../profiles/profile.service';
import { DataTableFilterService } from '../../../shared/components/data-table-filter/data-table-filter.service';
import {getCurrencySymbol} from '@angular/common';

@Component({
  selector: 'app-sales',
  templateUrl: './sales-detail-dashboard.component.html',
  styleUrls: ['../analytics.scss']
})

export class SalesDetailDashboardComponent implements OnInit, OnDestroy {

  public loaders = { salesDetails: true };
  public company: any;
  public semaphores = [[20, null], [10, 20], [0, 10]];
  public salesDetails: any[];
  public totals: any;
  public currencySymbol: string;
  public subs$: Subscription[] = [];

  constructor( private salesService: SalesService,
               private profileService: ProfileService,
               private filterService: DataTableFilterService ) { }

  ngOnInit() {
    const profile$ = this.profileService.getCurrentUserProfile().subscribe(
      profileData => {
        this.company = profileData.company;
        const currency = this.profileService.getProfileCompany().currency;
        this.currencySymbol = getCurrencySymbol(currency, 'wide');
        if ( this.company.system_profile_groups && this.company.system_profile_groups.semaphores ) {
          this.semaphores = this.company.system_profile_groups.semaphores;
        }
        this.getSalesDetails();
        const filterChange$ = this.salesService.applyFilters$.subscribe(() => {
          this.getSalesDetails();
        });
        this.subs$.push(filterChange$);
      },
      error => console.error(JSON.stringify(error)),
      () => profile$.unsubscribe()
    );
    this.subs$.push(profile$);
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach(s$ => s$.unsubscribe()); }
    this.salesService.removeSubscriptions();
  }

  isGreen(value) {
    return (value >= this.semaphores[0][0]);
  }

  isYellow(value) {
    return (value > this.semaphores[1][0] && value < this.semaphores[1][1]);
  }

  isRed(value) {
    return (value >= this.semaphores[2][0] && value <= this.semaphores[2][1]);
  }

  private getSalesDetails() {
    this.loaders.salesDetails = true;
    this.filterService.loaderStatus.next(false);
    const locationSales$ = this.salesService.salesDetails$.subscribe(
       rawSalesDetails => {
        this.salesDetails = this.salesService.parseSalesDetails( rawSalesDetails );
        this.totals = this.salesService.calculateTotals( this.salesDetails );
        locationSales$.unsubscribe();
        this.loaders.salesDetails = false;
        this.filterService.loaderStatus.next(true);
      }
    );
    this.subs$.push(locationSales$);
    this.salesService.getSalesDetails({...this.salesService.filters});
  }
}
