import { AfterViewInit, Directive, ElementRef, HostBinding, Input, OnDestroy, Renderer2 } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[appFluidHeight]',
})
export class FluidHeightDirective implements AfterViewInit, OnDestroy {

  @Input() minHeight: number;
  // tslint:disable-next-line:no-input-rename
  @Input('fluidHeight') set topOffset(value: number) {
    this._topOffset = value;
    this.setHeight();
  };
  @HostBinding('style.overflow-y') overflowY = 'scroll';

  get topOffset(): number {
    return this._topOffset;
  }

  private _topOffset: number;
  private domElement: HTMLElement;
  private subs$: Subscription[] = [];

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    this.domElement = this.elementRef.nativeElement as HTMLElement;
    // Prevent calc per each pixel if window gets resized.  Use rxjs operators to set 500ms delay.
    const resizeSub$ = fromEvent(window, 'resize').pipe(throttleTime(500), debounceTime(500)).subscribe(() => this.setHeight());
    this.subs$.push(resizeSub$);
  }

  ngAfterViewInit() {
    this.setHeight();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  private setHeight() {
    const windowHeight = window.innerHeight;
    const topOffset = this.topOffset || this.calcTopOffset();
    let height = windowHeight - topOffset;

    // set min height instead of the calculated
    if (this.minHeight && height < this.minHeight) {
      height = this.minHeight;
    }

    this.renderer.setStyle(this.domElement, 'height', `${height}px`);
  }

  private calcTopOffset(): number {
    try {
      const rect = this.domElement.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return rect.top + scrollTop;
    } catch (e) {
      return 0;
    }
  }
}
