import { Injectable } from '@angular/core';
import { QuestionBase } from '../../models/forms/question-base';
import { MultiSelectQuestion } from '../../models/forms/question-multiselect';
import { SystemSegmentsService } from '../../../resources/segments/system-segments.service';
import { TranslateService } from '@ngx-translate/core';
import { SegmentConditionProvider } from './condition.service';
import { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { TagHistoriesService } from '../tag-histories.service';
import { MultiselectDataSource } from '../../components/multiselect/multiselect';
import { isNullOrUndefined, isNumber } from 'util';
import { multiselectPresenceValidator } from '../validations.service';

@Injectable()
export class NestedSegmentConditionService implements SegmentConditionProvider {

  valueSource = new BehaviorSubject<any>(null);
  valueSource$ = this.valueSource.asObservable();
  inputs: QuestionBase<any>[];
  subs$: Subscription[];
  customValidators = [() => null];

  constructor( private systemSegmentsService: SystemSegmentsService,
               private tagHistoresService: TagHistoriesService,
               private translate: TranslateService ) { }

  getInputs(params?: any): QuestionBase<any>[] {

    const paramsAreDefined = ( params && Object.keys(params).length > 0 );
    const operator = paramsAreDefined ? this.getOperator(params) : [];
    const segment = paramsAreDefined ? this.getSegmentFromParams(params) : [];
    const apply = paramsAreDefined ? this.getApplyFromParams(params) : [];
    const indexApply = paramsAreDefined ? this.getApplyIndexFromParams(params) : [];

    if ( segment && segment.length > 0 ) { this.tagHistoresService.setSegment( segment[0].id); }

    const inputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'operator',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.getOperatorsValues(),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        getValue: (value) => (value && value.length) ? value : null,
        parseValue: (value) => {
          return (value && value.length) ? this.getOperatorsValues().find( _operator => _operator.id === value[0].id ).id : null;
        },
        value: operator ? operator : [],
        customValidators: [multiselectPresenceValidator]
      }),
      new MultiSelectQuestion({
        label: this.translate.instant('resources.segment_conditions.fields.segment'),
        cssClasses: 'form-control input-md',
        key: 'segment_id',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        parseValue: (values) => ((values && values.length > 0) ? values[0].id : []),
        dataSource: this.systemSegmentsService,
        selectedIds: ( segment && segment.length > 0 ) ? [segment[0].id] : [],
        required: true,
        customValidators: [multiselectPresenceValidator]
      }),
      new MultiSelectQuestion({
        label: this.translate.instant('resources.segment_conditions.fields.static_tags'),
        cssClasses: 'form-control input-md',
        key: 'apply',
        settings: { singleSelection: true, enableCheckAll: true, showCheckbox: false, enableSearchFilter: false },
        parseValue: (values, objectToAPI?) => {
          if ( values && values.length > 0 ) {
            objectToAPI['apply_id'] = values[0].id;
            return values[0].rawElement.apply;
          } else {
            objectToAPI['apply_id'] = null;
            return null;
          }
        },
        value: (apply && apply.length > 0) ? apply : [],
        options: []
      }),
      new MultiSelectQuestion({
        label: this.translate.instant('resources.segment_conditions.fields.dynamic_tags'),
        cssClasses: 'form-control input-md',
        key: 'index_apply',
        settings: { singleSelection: true, enableCheckAll: true, showCheckbox: false, enableSearchFilter: false },
        options: this.getStaticTagList(),
        value: indexApply,
        parseValue: (values) => ((values && values.length > 0) ? values[0].id : null)
      })
    ];

    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI( params: any ): any {
    const parsedValuesObj = {};
    Object.keys(params).forEach( key => {
      const input = this.inputs.find( _input => _input.key === key );
      const parsedValue = input.parseValue( params[key], parsedValuesObj );
      parsedValuesObj[key] = parsedValue;
    });
    return parsedValuesObj;
  }

  prepareFormValuesFromAPI( params: any ): any {
    const parseValues = {
      operator: (value) => (value) ? value : null,
      segment_id: (value) => (value) ? value : null,
      apply_id: (value) => (value) ? value : null,
      apply: (value) => (value) ? value : null,
      index_apply: (value) => (!isNullOrUndefined(value)) ? value : null
    };
    const parsedValuesObj = {};
    Object.keys(params).forEach( key => { parsedValuesObj[key] = parseValues[key](params[key]); });
    return parsedValuesObj;
  }

  private getSegmentFromParams(params?){
    const segment = params.segment_id;
    if ( segment && typeof(segment) === 'number' ){
      return [{ id: segment, name: segment }];
    } else if ( segment && segment.length > 0 && typeof(segment) === 'object' ){
      return segment;
    } else {
      return [];
    }
  }

  private getApplyFromParams(params?) {
    let apply = params.apply_id;
    if ( !apply && params.apply && params.apply.length > 0 && params.apply[0] instanceof MultiselectDataSource ) {
      apply = params.apply[0].rawElement;
    }

    if ( apply && typeof apply === 'number' ) {
      return [apply];
    } else if ( apply && typeof(apply) === 'object' ) {
      return [apply.id];
    } else {
      return [];
    }
  }

  private getApplyIndexFromParams(params?) {
    const indexApply = params.index_apply;
    if ( isNumber(indexApply) && indexApply >= 0 ) {
      return [this.getStaticTagList().find( element => element.id === indexApply )];
    } else if ( indexApply && typeof indexApply !== 'number' ) {
      return indexApply;
    } else {
      return [];
    }
  }

  private getOperator(params?) {
    const operator = params.operator;
    if ( operator && operator.length > 0 && operator[0].hasOwnProperty('id') ) {
      return operator;
    } else if ( operator && operator.length > 0 && !operator[0].hasOwnProperty('id') ) {
      return [this.getOperatorsValues().find( op => op.id === operator )];
    } else {
      return [];
    }
  }

  private getStaticTagList(): { id: number, name: string }[] {
    const tagList = [{id: 0, name: this.translate.instant('resources.segment_conditions.fields.last_tag')}];
    for (let index = 1; index <= 13; index++) {
      tagList.push({id: index, name: this.translate.instant('resources.segment_conditions.fields.last_tag') + ` - ${index}` })
    }
    return tagList;
  }

  private getOperatorsValues(): {id: string, name: string}[] {
    return [
      { id: 'in', name: this.translate.instant('resources.segment_conditions.operators.belongs_to')},
      { id: 'not_in', name: this.translate.instant('resources.segment_conditions.operators.does_not_belongs')}
    ];
  }
}
