<div class="row">
  <div class="col">
    <div class="card">
      <div class="card-block">
        <h4 class="card-title">
          <b [translate]="'resources.journeys.fields.definition'"></b>
        </h4>
        <div class="row" *ngIf="journey.type !== 'multicoupon' && 
                            journey.type !== 'potential' &&
                            journey.type !== 'recuperation'">
          <div *ngFor="let input of inputs" [ngSwitch]="input.type" [ngClass]="input.cssClass || 'col-md-3'">

            <label>
              <b>{{input.label | translate}}</b>
              <span class="required" *ngIf="input.required">*</span>
            </label>

            <app-multiselect *ngSwitchCase="'multiselect'" [(ngModel)]="journey.configuration[input.key]"
              (ngModelChange)="handleDefinitionChanged($event); input.hasErrors = false" [data]="options[input.key]"
              [settings]="input.settings" [doDisable]="isDisabled(input)" [hasErrors]="input.hasErrors"
              (onDeSelect)="handleDefinitionChanged($event); input.hasErrors = false"
              (onDeSelectAll)="handleGlobalSelection($event, input.key); input.hasErrors = false"
              (onSelect)="handleDefinitionChanged($event); input.hasErrors = false"
              (onSelectAll)="handleGlobalSelection($event, input.key); input.hasErrors = false"></app-multiselect>
            <small *ngIf="input.info">{{input.info | translate}}</small>
            <small *ngIf="input.hasErrors" class="text-danger">
              {{input.errorMessages}}
            </small>
          </div>
        </div>

        <div class="row" *ngIf="journey.type === 'recuperation'">
          <div *ngFor="let input of inputs; let i = index" [ngClass]="input.colWidth">
            <ng-container *ngIf="i <= 4">
              <app-question (multiselectChanged)="handleDefinitionChanged($event); input.hasErrors = false"
                [question]="getInputConfig(input.key)" [form]="form"></app-question>
            </ng-container>
          </div>
        </div>

        <div class="row" *ngIf="journey.type === 'potential'">
          <div *ngFor="let input of inputs; let i = index" [ngClass]="input.cssClass || 'col-md-4'">
            <ng-container *ngIf="i <= 2">
              <app-question (multiselectChanged)="handleDefinitionChanged($event); input.hasErrors = false"
                [question]="getInputConfig(input.key)" [form]="form"></app-question>
            </ng-container>
          </div>
        </div>

        <div class="row" *ngIf="journey.type === 'multicoupon'">
          <div class="col-md-3" *ngFor="let input of inputs; let i = index">
            <ng-container *ngIf="i <= 3">
              <app-question (multiselectChanged)="handleDefinitionChanged($event, input); input.hasErrors = false"
                [question]="getInputConfig(input.key)" [form]="form"></app-question>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
