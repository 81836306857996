<div class="row">
  <div class="col-md-2">
    <app-question [question]="getInputConfig('name')" [form]="generalInputsForm"></app-question>
  </div>
  <div class="col-md-3">
    <app-question [question]="getInputConfig('supplier_id')" [form]="generalInputsForm" (multiselectChanged)="findProduct()"></app-question>
  </div>
  <div class="col-md-3">
    <app-question [question]="getInputConfig('product_ids')"
                  [form]="generalInputsForm"
                  [useToken]="true"
                  (selectedIdsLoaded)="onSelectedIdsLoaded()"></app-question>
  </div>
  <div class="col-md-1">
    <button class="btn btn-default btn-icon"
            type="button"
            pTooltip="{{'resources.campaign_plans.types.supplier.messages.import_product_ids' | translate}}"
            tooltipPosition="bottom"
            (click)="openImportProductsModal()">
      <i class="ei-import"></i>
    </button>
  </div>
  <div class="col-md-3">
    <app-question [question]="getInputConfig('segment_id')" [form]="generalInputsForm"></app-question>
  </div>
</div>

<app-import-products #importProductsModal (productIdsEmitter)="handleProductIdList($event)"></app-import-products>
