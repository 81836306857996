import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FeaturesService } from '../../../../../resources/data-warehouse/products/features.service';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';

@Injectable()
export class LookerNielsenFiltersService {

  constructor(
    private translate: TranslateService,
    private featuresService: FeaturesService
  ) {}
  
  getNielsenFilters(): QuestionBase<any>[] {
    return [
      new MultiSelectQuestion({
        key: 'sector',
        label: this.translate.instant('components.looker-dashboard-filter.filters.sector'),
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.featuresService,
        required: true,
        order: 1
      }),
      new MultiSelectQuestion({
        key: 'section',
        label: this.translate.instant('components.looker-dashboard-filter.filters.section'),
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.featuresService,
        disabled: true,
        order: 2
      }),
      new MultiSelectQuestion({
        key: 'categorization',
        label: this.translate.instant('components.looker-dashboard-filter.filters.categorization'),
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.featuresService,
        disabled: true,
        order: 3
      }),
      new MultiSelectQuestion({
        key: 'family',
        label: this.translate.instant('components.looker-dashboard-filter.filters.family'),
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.featuresService,
        disabled: true,
        order: 4
      }),
      new MultiSelectQuestion({
        key: 'sub_family',
        label: this.translate.instant('components.looker-dashboard-filter.filters.sub_family'),
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.featuresService,
        disabled: true,
        order: 5
      }),
      new MultiSelectQuestion({
        key: 'product_segment',
        label: this.translate.instant('components.looker-dashboard-filter.filters.product_segment'),
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.featuresService,
        disabled: true,
        order: 6
      })
    ];
  }
}