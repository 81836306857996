import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';
import { BigqueryjobsService } from '../../bigqueryjobs.service';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { DateService } from '../../../../../shared/services/date.service';
import { ModalStatusService } from '../../../../../shared/services/modal-status.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-form-execute-date',
  templateUrl: './form-execute-date.component.html',
  styleUrls: ['./form-execute-date.component.css']
})

export class FormExecuteDateComponent implements OnInit, OnDestroy {

  executeDateForm: UntypedFormGroup;
  id: number;
  inputs: QuestionBase<unknown>[];
  stepId: number;

  get executionTaskStepInformation(): string {
    return this.translate.instant(this.stepId ? 'resources.jobs.messages.execution_step_information' : 'resources.jobs.messages.execution_task_information');
  }

  private destroy$ = new Subject<void>();

  @Output() formValidity = new EventEmitter<string>();

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private bigqueryJobsService: BigqueryjobsService,
    private confirmationService: ConfirmationService,
    private modalStatusService: ModalStatusService,
    private translate: TranslateService,
    private dateService: DateService
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  sendData(): void {
    const confirmationTitle = this.translate.instant(this.stepId ? 'resources.jobs.messages.execution_confirmation_step' : 'resources.jobs.messages.execution_confirmation_task');
    const confirmationDescription = this.translate.instant('resources.jobs.messages.execution_confirmation_desc');
    this.confirmationService.displayConfirmationAlert(confirmationTitle, confirmationDescription).then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.modalStatusService.formLoaderStatus.emit('loading');
        const payload = this.stepId ? { date: this.executeDateForm.value.date_from, step_id: this.stepId } : this.executeDateForm.value.date_from;
        const request$ = this.stepId ? this.bigqueryJobsService.executeStep(payload, this.id) : this.bigqueryJobsService.processDate(payload, this.id);
        request$.pipe(takeUntil(this.destroy$)).subscribe({
          next: () => this.handleSuccessRequest(),
          error: (errorData: HttpErrorResponse) => this.handleErrors(errorData)
        });
      }
    }).catch(() => {});
  }

  private getParams(): void {
    this.route.params.subscribe(params => {
      this.modalStatusService.formLoaderStatus.emit('loading');
      this.id = params.id;
      this.stepId = params.step_id;
      this.setInputsAndForm();
    });
  }

  private setInputsAndForm(): void {
    this.inputs = this.getInputs();
    this.executeDateForm = this.qcs.toFormGroup(this.inputs);
    this.handleFormStatusChanges();
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
  }

  private getInputs(): QuestionBase<unknown>[] {
    return [
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_from',
        label: 'resources.jobs.modal.fields.date',
        type: 'date',
        maxDate: this.dateService.parseTodayWithFormat('YYYY-MM-DD'),
        required: true
      })
    ];
  }

  private handleFormStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.executeDateForm.status));
    this.executeDateForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
  }

  private handleSuccessRequest(): void {
    const successTitle = this.translate.instant(this.stepId ? 'resources.jobs.warnings.step_success_title' : 'resources.jobs.warnings.success_title');
    const successText = this.translate.instant(this.stepId ? 'resources.jobs.warnings.step_success_text' : 'resources.jobs.messages.executed_desc');
    this.confirmationService.displaySuccessAlert(successTitle, successText).catch(() => {});
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    this.modalStatusService.modalStatus.emit();
    this.returnToList();
  }

  private handleErrors(errorData: HttpErrorResponse): void {
    const errorValue = errorData?.error?.error ? errorData.error.error : null;
    if (errorData?.error?.errors?.length) {
      this.qcs.paintErrorsInForm(this.inputs, this.executeDateForm, errorData.error.errors);
      if (errorValue) { this.displayErrorAlert(errorValue); }
    } else if (errorValue) {
      this.displayErrorAlert(errorValue);
    }
  }

  private displayErrorAlert(error: string): void {
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), error);
  }

  private returnToList(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }
}
