import { UntypedFormGroup } from "@angular/forms";

export function getLocationsFormPayload(locationsForm: UntypedFormGroup, isEditMode: boolean): object {
  const id = locationsForm.value?.id ?? null;
  const code = locationsForm.value?.code ?? null;
  const locationTaxonomyTerms =locationsForm.value?.location_taxonomy_terms?.length ? getLocationTaxonomyTermsValue(locationsForm) : [];
  const country = locationsForm.value?.country?.length ? locationsForm.value.country[0].id : [];
  const status = locationsForm.value?.status?.length ? locationsForm.value.status[0].id : [];
  const scoreExchangeId = locationsForm.value?.score_exchange_id?.length ? locationsForm.value.score_exchange_id[0].id : null;

  const payload = {
    name: locationsForm.value.name,
    address: locationsForm.value.address,
    state: locationsForm.value.state,
    city: locationsForm.value.city,
    postal_code: locationsForm.value.postal_code,
    country: country,
    longitude: locationsForm.value.longitude,
    latitude: locationsForm.value.latitude,
    schedule: locationsForm.value.schedule,
    status: status,
    gift_card_available: locationsForm.value.gift_card_available,
    location_taxonomy_terms: locationTaxonomyTerms,
    score_exchange_id: scoreExchangeId,
    phone: locationsForm.value.phone,
    email: locationsForm.value.email,
    goals: {
      signups: {
        week: locationsForm.value.goals
      }
    }
  };
  
  if (!isEditMode) {
    if (code) {
      payload['code'] = code;
    } else if (id) {
      payload['id'] = id;
    }
  }

  return payload;
}

function getLocationTaxonomyTermsValue(locationsForm: UntypedFormGroup): { external_id: number, taxonomy_slug: string }[] {
  return locationsForm.value.location_taxonomy_terms.map(
    term => ({
      external_id: term?.rawElement?.external_id ?? null,
      taxonomy_slug: term?.rawElement?.taxonomy_slug ?? null
    })
  );
}