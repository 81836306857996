<div class="modal-backdrop fade in" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="mrg-left-15 mrg-btm-5" [translate]="'resources.customers.show.actions.transfer'"></h4>
        <button [attr.data-cy]="'button_closeTransferScore'" class="modal-close" (click)="closeModal()">
          <i class="ti-close"></i>
        </button>
      </div>

      <div class="modal-body background-gray">
        <div class="row">
          <div class="col-md-12">
            <div class="container-fluid">
              <div class="row">
                <div class="mrg-top-10 col-md-12">
                  <div class="card-block no-pdd">
                    <div class="row">
                      <div class="col-md-8 ml-auto mr-auto">
                        <form [formGroup]="transferForm" *ngIf="transferForm">

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="score" [translate]="'resources.customers.show.action_modals.score'"></label>
                                <app-question [question]="getInputConfig('score')" [form]="transferForm"></app-question>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group label-position">
                                <app-question [question]="getInputConfig('allow_partial')" [form]="transferForm"></app-question>
                              </div>
                            </div>
                          </div>
                          
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="destination_customer_id"
                                  [translate]="'resources.customers.show.action_modals.destination_customer_id'"></label>
                                <app-question [question]="getInputConfig('destination_customer_id')"
                                  [form]="transferForm"></app-question>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="country"
                                  [translate]="'resources.customers.show.action_modals.country'"></label>
                                <app-question [question]="getInputConfig('country')" [form]="transferForm">
                                </app-question>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="comments"
                                  [translate]="'resources.customers.show.action_modals.comments'"></label>
                                <app-question [question]="getInputConfig('comments')" [form]="transferForm">
                                </app-question>
                              </div>
                            </div>
                          </div>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer background-white">
        <span>
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        </span>
        <div class="pull-right">
          <button [attr.data-cy]="'button_customerTransferScoreConfirm'"
                  class="pull-right btn btn-info" 
                  (click)="sendData()"
                  [translate]="'resources.customers.show.actions.transfer'"
                  [disabled]="loading">
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
