<div class="row">
  <div class="col-md-12">
    <div class="row mrg-top-10 pdd-horizon-15">

      <div class="col text-center card mrg-right-15">
        <div class="mrg-top-30">
          <i style="color: gainsboro" class="ei-pie-chart-1 font-size-70"></i>
        </div>
        <button class="btn btn-info no-mrg-horizon" (click)="closeModal('segmentsV2')">
          {{ 'resources.segments.messages.new_segment_engine' | translate }}
        </button>
      </div>

      <div class="col text-center card">
        <div class="mrg-top-30">
          <i style="color: gainsboro" class="ei-file-code font-size-70"></i>
        </div>
        <button class="btn btn-info no-mrg-horizon" (click)="closeModal('csv')">
          {{ 'resources.segments.messages.csv_engine' | translate }}
        </button>
      </div>

    </div>
  </div>
</div>
