import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-show-affectation',
  templateUrl: './show-affectation.component.html',
  styleUrls: ['./show-affectation.component.scss']
})
export class ShowAffectationComponent {

  @Input() couponDetail;

}
