<div id="accordion-1" class="accordion panel-group" role="tablist" aria-multiselectable="true">
  <div class="card">
    <div class="card-heading border bottom" role="tab" id="headingOne">
      <h4 class="panel-title">
        <a data-toggle="collapse" data-parent="#accordion-1" href="#collapseReturns" aria-expanded="true" class="show no-pdd">
          <i class="fa-duotone fa-right-left font-size-16"></i>
          <span class="mrg-left-10" translate>resources.tickets.modal.cards.returns.title</span>
          <i class="icon ti-arrow-circle-down"></i>
        </a>
      </h4>
    </div>
    <div id="collapseReturns" class="panel-collapse show">
      <div class="card-block">
        <table class="table table-hover" aria-hidden="true">
          <thead>
            <tr>
              <th [translate]="'resources.tickets.modal.cards.returns.table.activity'"></th>
              <th [translate]="'resources.tickets.modal.cards.returns.table.line_order'"></th>
              <th>
                {{'resources.tickets.modal.cards.returns.table.amount' | translate: {currencySymbol: this.currencySymbol} }}
              </th>
              <th [translate]="'resources.tickets.modal.cards.returns.table.score'"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let score of ticketData?.activity_return_froms">
              <td class="col-lg-2">
                <a (click)="openTicketInNewTab(score.ticket_id)">
                  {{score.ticket_id}}
                </a>
              </td>
              <td class="col-lg-2">{{score.line_order}}</td>
              <td class="col-lg-2">{{getTicketLineAmount(score.from_line_order) | lgcurrency: '1.2-2': this.ticketData.currency }}</td>
              <td class="col-lg-2">{{score.score}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- -->
</div>