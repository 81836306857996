<app-audience-dates #audienceDates
  [plan]="plan"
  [planCampaign]="planCampaign"
  (campaignEmitter)="handleEmittedCampaign($event)">
</app-audience-dates>

<div class="table-responsive min-height-500" *ngIf="audienceData">
  <table class="table table-sm table-hover table-bordered" aria-hidden="true">
    <thead>
      <tr>
        <th class="no-border-right"></th>
        <th *ngFor="let column of tableColumns" class="text-center align-middle" [innerHTML]="column | translate: { currencySymbol }"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        app-audience-content *ngFor="let item of audienceData"
        [item]="item"
        [parentForm]="form"
        (actionEmitter)="handleEmittedAction()"
        (couponCreation)="handleCouponCreation($event)"
        (couponSelected)="handleCouponSelected($event)"
        (couponRecommend)="handleCouponRecommender($event)"
        (campaignEdition)="handleCampaignEdition($event)">
      </tr>
      <tr class="totals-row highlight-header" *ngIf="totals">
        <td class="text-right align-middle pdd-right-10 no-border-right"></td>
        <td class="align-middle">{{'common.total' | translate | uppercase }}</td>
        <td *ngIf="this.flags.showPlanCampaignAudienceChannel" class="text-right align-middle pdd-right-10 no-border-right"></td>
        <td class="text-right align-middle pdd-right-10">{{ totals.customers | number: '1.0-0' }}</td>
        <td *ngFor="let _ of [].constructor(5)" class="text-right align-middle pdd-right-10">{{'common.fields.na' | translate}}</td>
        <td class="text-right align-middle pdd-right-10">{{ totals.affectationSales | lgcurrency: '1.2-2' }}</td>
        <td class="text-right align-middle pdd-right-10">{{ totals.seasonalityAvg | number: '1.2-2' }}</td>
        <td *ngFor="let _ of [].constructor(6)" class="text-right align-middle pdd-right-10">{{'common.fields.na' | translate}}</td>
        <td class="text-right align-middle pdd-right-10 no-border-right"></td>
        <td class="text-right align-middle pdd-right-10"></td>
      </tr>
    </tbody>
  </table>
</div>

<div class="modal-backdrop fade in" [id]="'embeddedCouponsForm'" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="isModalOpen">
	<div class="modal-dialog modal-lg" role="document">
		<div class="modal-content">

			<div class="modal-header">
				<h4 class="mrg-left-15 mrg-btm-5 first-letter-upper">
          {{'resources.campaign_plans.types.custom.audience.table.assign_coupon' | translate}}:&nbsp;
          <b>{{actionToAssignCoupon.name}}</b>
        </h4>
				<button class="modal-close" (click)="closeModal()">
					<i class="ti-close"></i>
				</button>
			</div>

			<div class="modal-body background-gray">
				<div class="row">
					<div class="col-md-12">
            <app-form-coupons #couponsForm [embedded]="true" (savedCoupon)="handleSavedCoupon($event)" (closeCoupon)="closeModal()"></app-form-coupons>
          </div>
        </div>
      </div>

      <div class="modal-footer background-white">
				<div class="pull-right d-flex align-items-center">
					<span class="mrg-right-10">
						<i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
					</span>
					<button class="pull-right btn btn-info"
									(click)="sendData()"
									[translate]="'common.buttons.save'"
									[disabled]="loading">
					</button>
				</div>
			</div>
    </div>
  </div>
</div>

<div class="modal-backdrop fade in" [id]="'couponRecommender'" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="isCouponRecommenderOpened">
	<div class="modal-dialog modal-lg" role="document">
		<div class="modal-content">

			<div class="modal-header">
				<h4 class="mrg-left-15 mrg-btm-5 first-letter-upper">
          {{'resources.campaign_plans.types.custom.audience.table.recommend_coupon' | translate}}:
          <b>{{actionToRecommendCoupon.name}}</b>
        </h4>
				<button class="modal-close" (click)="closeRecommender()">
					<i class="ti-close"></i>
				</button>
			</div>

			<div class="modal-body background-gray">
				<div class="row">
					<div class="col-md-12">
            <app-audience-coupon-recommender #couponRecommender
              [action]="actionToRecommendCoupon"
              (formChanged)="handleFormChanged()"
              (recommendedCoupon)="handleRecommendedCoupon($event)"
              (errResponse)="handleErrorResponse($event)">
            </app-audience-coupon-recommender>
          </div>
        </div>
      </div>

      <div class="modal-footer background-white align-content-center">
        <div class="pull-right d-flex align-items-center">
          <button *ngIf="enableCouponRecommenderRecalculate"
                  class="pull-right btn btn-outline-info"
									(click)="recalculateRecommendedCoupon()"
									[translate]="'resources.campaign_plans.types.custom.audience.recommended_coupons.buttons.recalculate'"
									[disabled]="loading">
					</button>
          <button class="pull-right btn btn-info"
									(click)="selectRecommendedCoupon()"
									[translate]="'resources.campaign_plans.types.custom.audience.recommended_coupons.buttons.assign_coupon'"
									[disabled]="loading">
					</button>
				</div>
			</div>
    </div>
  </div>
</div>

<div *ngIf="openCampaignEditionModal">
  <app-create-update-campaigns-dynamic-form (closeModal)="closeManualCampaignModal(true)"
                                            [actionRaw]="actionRaw"
                                            [mode]="'plan_manual_campaign'"
                                            [selectedCoupon]="selectedCoupon"
                                            (updateSelectedCoupon)="handleSelectedCoupon($event)"
                                            (actionSavedChannel)="handleActionSaved($event)">
  </app-create-update-campaigns-dynamic-form>
</div>
