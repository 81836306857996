import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import { RewardCategoriesService } from '../reward-categories.service';
import { RewardCategory } from '../reward-category';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-show-rewards-categories',
  templateUrl: './show-rewards-categories.component.html',
  styleUrls: ['./show-rewards-categories.component.css']
})
export class ShowRewardsCategoriesComponent implements OnInit {

  element: RewardCategory;
  id: number;
  isModal: boolean;
  missingImg: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public translate: TranslateService,
    public router: Router,
    private rewardCategories: RewardCategoriesService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.isModal = this.router.url.indexOf('(modal:show/reward_categories/') >= 0;
    this.getParams();
    this.rewardCategories.fetchSelectedById(this.id).subscribe(
      (category: RewardCategory) => {
        this.element = category;
        this.missingImg = category.thumbnail.indexOf('thumbnail/missing') >= 0;
      },
      (err: HttpErrorResponse) => {
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), err.error.error);
        this.closeModal();
      }
    )
  }

  getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
      }
    });
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }
}
