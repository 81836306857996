import { Injectable } from '@angular/core';
import { Option } from '../../../../../shared/models/common/option';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { RadioQuestion } from '../../../../../shared/models/forms/question-radio';
import { TextareaQuestion } from '../../../../../shared/models/forms/question-textarea';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';
import { DateService } from '../../../../../shared/services/date.service';
import { DEFAULT_FIELD_CALCULATION_CUSTOM_VALIDATORS, SQL_PLACEHOLDER, SQL_PLACEHOLDER_TITLE } from '../../constants/customer-attributes.constants';
import { CustomerAttribute } from '../../models/customer-attribute.model';
import { CustomerAttributesOptionsService } from '../customer-attributes-options/customer-attributes-options.service';
import { TranslateService } from '@ngx-translate/core';
import { ToggleCheckboxQuestion } from '../../../../../shared/models/forms/question-toggle-checkbox';
import { CheckboxQuestion } from '../../../../../shared/models/forms/question-checkbox';
import { isNullOrUndefined } from '../../../../../shared/utils/common.utils';
import { FloatQuestion } from '../../../../../shared/models/forms/question-float';
import { ValidatorFn } from '@angular/forms';

@Injectable()
export class CustomerAttributesInputsService {

  constructor(
    private customerAttributesOptionsService: CustomerAttributesOptionsService,
    private dateService: DateService,
    private translate: TranslateService
  ) {}

  getInputs(formValues: CustomerAttribute, isEditMode: boolean, isCompanyStrictProfiling: boolean): QuestionBase<unknown>[] {
    const isSystemOrigin = !!formValues?.system;
    const isCalculationAttributeType = !!(formValues?.attributeType && formValues.attributeType === 'calculation');
    const isScheduledExecutionType = !!(formValues?.recurrence && formValues?.recurrence !== 'manual');
    const isCustomDataPeriod = this.getDataPeriodTypeValue(formValues) === 'custom';
    const isHourIntervalRecurrence = this.getRecurrenceValue(formValues) === 'hour_interval';
    const isSpecificMonthsRecurrence = this.getRecurrenceValue(formValues) === 'specific_months';
    const monthsOptions = this.customerAttributesOptionsService.getMonthsOptions();
    const hasLastExecutionTime = !!formValues?.lastExecutionTime;

    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        required: true,
        value: formValues?.name ?? null
      }),
      new TextboxQuestion({
        key: 'description',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: formValues?.description ?? null
      }),
      new RadioQuestion({
        key: 'attribute_type',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.customerAttributesOptionsService.getAttributeTypeOptions(),
        required: true,
        disabled: isEditMode,
        value: formValues?.attributeType ?? null
      }),
      new RadioQuestion({
        key: 'data_type',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.customerAttributesOptionsService.getDataTypeOptions(),
        required: true,
        disabled: isEditMode && (isSystemOrigin || hasLastExecutionTime),
        value: formValues?.dataType ?? null
      }),
      new ToggleCheckboxQuestion({
        key: 'results_available_in_real_time',
        type: 'toggle-checkbox',
        cssClasses: 'form-control input-default',
        label: this.translate.instant('resources.customer_attributes.fields.results_available_in_real_time_api_consumption'),
        value: formValues?.resultsAvailableInRealTime ?? null
      }),
      new TextareaQuestion({
        key: 'sql',
        type: 'text',
        cssClasses: 'form-control input-default',
        required: isCalculationAttributeType,
        placeholder: this.translate.instant(SQL_PLACEHOLDER_TITLE) + SQL_PLACEHOLDER,
        value: formValues?.sql ?? null
      }),
      new CheckboxQuestion({
        key: 'strict_profiling',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        value: isEditMode && !isNullOrUndefined(formValues.ignoreStrictProfiling) ? formValues.ignoreStrictProfiling : !isCompanyStrictProfiling
      }),
      new RadioQuestion({
        key: 'data_period_type',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.customerAttributesOptionsService.getDataPeriodTypeOptions(),
        required: isCalculationAttributeType,
        value: this.getDataPeriodTypeValue(formValues)
      }),
      new FloatQuestion({
        key: 'relative_value',
        type: 'number',
        cssClasses: 'form-control input-default',
        step: 1,
        min: 1,
        required: isCustomDataPeriod,
        disabled: !isCustomDataPeriod,
        value: formValues?.relativeValue ?? null,
        // Set also in form-validators -> handleCustomValidators -> setDefaultCalculationCustomValidators
        customValidators: isCustomDataPeriod ? this.getDefaultCalculationCustomValidators('relative_value') : []
      }),
      new MultiSelectQuestion({
        key: 'relative_custom_period',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: false },
        options: this.customerAttributesOptionsService.getRelativeCustomOptions(),
        required: isCustomDataPeriod,
        disabled: !isCustomDataPeriod,
        value: formValues?.dataPeriodType && isCustomDataPeriod ? this.getRelativeCustomPeriodValue(formValues.dataPeriodType) : null
      }),
      new RadioQuestion({
        key: 'execution_type',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.customerAttributesOptionsService.getExecutionTypeOptions(),
        required: isCalculationAttributeType,
        value: this.getExecutionTypeValue(formValues)
      }),
      new TextboxQuestion({
        key: 'start_date',
        type: 'date',
        cssClasses: 'form-control input-default',
        minDate: this.dateService.parseTodayWithFormat('YYYY-MM-DD'),
        required: !!(isCalculationAttributeType && isScheduledExecutionType),
        value: formValues?.startDate ?? null
      }),
      new TextboxQuestion({
        key: 'start_time',
        type: 'time',
        cssClasses: 'form-control input-default',
        step: 60,
        required: !!(isCalculationAttributeType && isScheduledExecutionType),
        value: formValues?.startTime ?? null
      }),
      new RadioQuestion({
        key: 'recurrence',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.customerAttributesOptionsService.getRecurrenceOptions(),
        required: !!(isCalculationAttributeType && isScheduledExecutionType),
        value: this.getRecurrenceValue(formValues)
      }),
      new MultiSelectQuestion({
        key: 'execution_months',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableSearchFilter: false },
        options: monthsOptions,
        required: isSpecificMonthsRecurrence,
        disabled: !isSpecificMonthsRecurrence,
        value: isSpecificMonthsRecurrence && formValues?.executionMonths?.length ? this.getExecutionMonthsValue(formValues.executionMonths, monthsOptions) : null
      }),
      new FloatQuestion({
        key: 'execution_hour_interval',
        type: 'number',
        cssClasses: 'form-control input-default',
        step: 1,
        min: 1,
        required: isHourIntervalRecurrence,
        disabled: !isHourIntervalRecurrence,
        value: isHourIntervalRecurrence && formValues?.executionHourInterval ? formValues.executionHourInterval : null,
        // Set also in form-validators -> handleCustomValidators -> setDefaultCalculationCustomValidators
        customValidators: isHourIntervalRecurrence ? this.getDefaultCalculationCustomValidators('execution_hour_interval') : []
      })
    ];

    return inputsArray;
  }

  private getRelativeCustomPeriodValue(dataPeriodTypeValue: string): Option[] | null {
    const relativeCustomPeriodValue = this.customerAttributesOptionsService.getRelativeCustomOptions().find(option => option.id === dataPeriodTypeValue);
    return relativeCustomPeriodValue ? [relativeCustomPeriodValue] : null;
  }

  private getExecutionTypeValue(formValues: any): string | null {
    let executionTypeValue = null;
    if (formValues?.recurrence) {
      executionTypeValue = formValues.recurrence === 'manual' ? formValues.recurrence : 'scheduled';
    }
    return executionTypeValue;
  }

  private getDataPeriodTypeValue(formValues: CustomerAttribute): string | null {
    let dataPeriodTypeValue = null;
    const customDataPeriodTypeValues = this.customerAttributesOptionsService.getRelativeCustomOptions().map(option => option.id);
    if (formValues?.dataPeriodType) {
      dataPeriodTypeValue = customDataPeriodTypeValues.includes(formValues.dataPeriodType) ? 'custom' : formValues.dataPeriodType;
    }
    return dataPeriodTypeValue;
  }

  private getRecurrenceValue(formValues: CustomerAttribute): string | null {
    let recurrenceValue = null;
    if (formValues?.recurrence) {
      recurrenceValue = formValues.recurrence === 'manual' ? null : formValues.recurrence;
    }
    return recurrenceValue;
  }

  private getExecutionMonthsValue(executionMonthsValue: string[], monthsOptions: Option[]): Option[] {
    return executionMonthsValue.map(executionMonth => monthsOptions.find(option => option.id === executionMonth));
  }

  private getDefaultCalculationCustomValidators(fieldKey: string): ValidatorFn[] {
    const fieldDefaultCustomValidators = DEFAULT_FIELD_CALCULATION_CUSTOM_VALIDATORS.find(validator => validator.key === fieldKey);
    return fieldDefaultCustomValidators?.customValidators?.length ? fieldDefaultCustomValidators.customValidators : [];
  }
}
