import { ActionModalsService } from '../action-modals.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfirmationService } from '../../../../../../../../shared/services/confirmation.service';
import { ModalStatusService } from '../../../../../../../../shared/services/modal-status.service';
import { QuestionBase } from '../../../../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../../../../shared/services/question-control.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-transfer-score',
  templateUrl: './transfer-score.component.html',
  providers: [ActionModalsService]
})

export class TransferScoreComponent implements OnInit, OnDestroy {

  id: number;
  inputs: QuestionBase<any>[];
  loading: boolean;
  requestedScore: any;
  scoreTransfered: any;
  showButton: boolean;
  subs$: Subscription[];
  transferForm: UntypedFormGroup;

  constructor(
    private actionModalService: ActionModalsService,
    private confirmationService: ConfirmationService,
    private modalStatusService: ModalStatusService,
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {
    this.subs$ = [];
  }

  ngOnInit() {
    this.getParams();
    this.showBackButton();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  showBackButton() {
    this.showButton = !(this.router.url.indexOf('(modal:') >= 0);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  sendData() {

    const score = this.transferForm.value.score ? parseInt(this.transferForm.value.score, 10) : null;
    const allowPartial = this.transferForm.value.allow_partial ? this.transferForm.value.allow_partial : false;
    const destinationCustomerId = this.transferForm.value.destination_customer_id ? this.transferForm.value.destination_customer_id.toString() : null;
    const country = this.transferForm.value.country && this.transferForm.value.country.length ? this.transferForm.value.country[0].id : [];
    const comments = this.transferForm.value.comments ? this.transferForm.value.comments : null;

    if ( this.checkIfHasSpecialChars(destinationCustomerId) ) {
      const errorMsg = this.translate.instant('resources.customers.warnings.error_transfer');
      return this.confirmationService.displayErrorAlert('Error', errorMsg);
    }

    this.loading = true;
    const transferPoint$ = this.actionModalService.transferPoints(score, allowPartial, this.id, destinationCustomerId, country, comments).subscribe(
      response => {
        transferPoint$.unsubscribe();
        this.scoreTransfered = response.data.score;
        this.requestedScore = response.data.requested_scores;
        const confirmationBody = this.translate.instant('resources.customers.warnings.can_transfer', { totalPoints: this.scoreTransfered, requestedScores: this.requestedScore });
        this.confirmationService.displaySuccessAlert(
          this.translate.instant(`resources.customers.warnings.transfer_score_title`),
          this.translate.instant(confirmationBody)
        ).catch(() => {});
        this.modalStatusService.modalStatus.emit();
        this.loading = false;
        this.closeModal();
      },
      error => {
        transferPoint$.unsubscribe();
        this.loading = false;
        this.confirmationService.displayErrorAlert('Error', error.error.error);
      }
    );
    this.subs$.push(transferPoint$);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getParams() {
    const route$ = this.route.params.subscribe(params => {
      this.id = params.id;
      this.inputs = this.actionModalService.getInputs();
      this.transferForm = this.qcs.toFormGroup(this.inputs);
    });
    this.subs$.push(route$);
  }

  private checkIfHasSpecialChars(customerId: string) {
    return /[\.\,]/.test(customerId);
  }

}
