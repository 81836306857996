<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-10">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form [formGroup]="customEntitiesForm" *ngIf="customEntitiesForm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name">
                        {{'resources.custom_entities.fields.name' | translate}}
                        <span class="text-danger">*</span>
                      </label>
                      <app-question [question]="getInputConfig('name')" [form]="customEntitiesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="slug" translate>
                        {{'resources.custom_entities.fields.slug' | translate}}
                        <span class="text-danger">*</span>
                      </label>
                      <app-question [question]="getInputConfig('slug')" [form]="customEntitiesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="entity_attributes">
                        {{'resources.custom_entities.fields.entity_attributes' | translate}}
                        <span class="text-danger">*</span>
                      </label>
                      <ace #editor
                        class="height-300"
                        [disabled]="false"
                        [mode]="'json'"
                        [(value)]="entity.entity_attributes"
                        theme="monokai">
                      </ace>
                    </div>
                    <div class="mrg-btm-10 helper-text-mrg">
                      <span class="text-left" [innerHTML]="'resources.custom_entities.messages.helper_json' | translate"></span>
                      <button class="btn btn-xs pull-right btn-info" (click)="presentTemplate()">
                        {{'resources.customer_attributes.buttons.use_template' | translate}}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
