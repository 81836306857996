import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../shared/components/multiselect/multiselect';
import { Observable } from 'rxjs';
import { PushTemplate, PushTemplateSchema } from '../models/templates/push-template.model';

@Injectable()
export class PushTemplatesService extends ResourcesService implements MultiselectDataSourceable {

  constructor(http: HttpClient) {
    super(http);
  }

  getTemplateById(id) {
    return this.getData({ apiEndPoint: `campaign_templates/${id}` });
  }

  fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'campaign_templates',
      numberPerPage: 300,
      filtering: { via: 'push' },
      pageNumber: 1,
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues, via: 'push' }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`);
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getTemplateById(id);
  }

  parsePushForm(formValues: any): {subject: string, message: string, schema: PushTemplateSchema, values: {url?: string}} {
    const { subject, message, values, schema } = this.parseFormValues(formValues);
    return { subject, message, schema, values };
  }

  parsePushTemplateValues(formValues: any): {name: string, html: string, schema: PushTemplateSchema, via: string} {
    let { subject: name, message: html, values: schemaValues, schema } = this.parseFormValues(formValues);
    const via = 'push';

    if (schemaValues.url) {
      schema = { values: schemaValues };
    }

    return { name, html, schema, via };
  }

  parseApiTemplate(apiTemplate: any, campaignId: number): PushTemplate {

    const parsedApiTemplate = {...apiTemplate};

    parsedApiTemplate['id'] = campaignId;
    parsedApiTemplate['subject'] = parsedApiTemplate['name'];
    parsedApiTemplate['message'] = parsedApiTemplate['html'];
    if (parsedApiTemplate?.['schema']?.['values']?.['url']) {
      parsedApiTemplate['values'] = { url: parsedApiTemplate['schema']['values']['url'] };
      parsedApiTemplate['schema'] = {};
    }

    delete parsedApiTemplate['name'];
    delete parsedApiTemplate['html'];

    return parsedApiTemplate;
  }

  private parseFormValues(formValues: any): PushTemplate {
    const subject = formValues.subject || '';
    const message = formValues.message || '';
    const values = formValues.url ? {url: formValues.url} : {};
    let schema = {};

    if (formValues.redirectStateName?.length) {
      schema = { redirectState: { name: formValues.redirectStateName[0].id } };
    } else if (formValues.externalRedirectStateName?.length) {
      schema = { redirectState: { name: formValues.externalRedirectStateName[0].id } };
    }

    if (formValues.redirectStateName?.[0]?.id === 'tab.news-show' && formValues.newsId?.length) {
      schema = { redirectState: { name: 'tab.news-show', params: { newsId: formValues.newsId[0].id }}};
    }

    return { subject, message, values, schema };
  }
}

