import { AbstractControl, UntypedFormGroup, Validators } from "@angular/forms";
import { QuestionBase } from "../../../models/forms/question-base";

export function setTemplateRequired(campaignForm: UntypedFormGroup, inputs: QuestionBase<unknown>[]) {
  const viaControl = campaignForm.get('via');
  const emailControl = campaignForm.get('email_template');
  const smsControl = campaignForm.get('sms_template');
  const pushControl = campaignForm.get('push_template');
  const emailSubjectControl = campaignForm.get('email_subject');
  const selectedVia = viaControl.value[0].id;

  const smsInput = inputs.find(input => input.key === 'sms_template')
  const pushInput = inputs.find(input => input.key === 'push_template')
  const emailInput = inputs.find(input => input.key === 'email_template')

  if (selectedVia === 'email') {
    setControlRequiredIfEmpty(emailControl);
    setControlRequiredIfEmpty(emailSubjectControl);
    smsControl.reset();
    pushControl.reset();
    smsInput.selectedIds = [];
    pushInput.selectedIds = [];
  } else {
    emailControl.clearValidators();
    emailSubjectControl.clearValidators();
  }

  if (selectedVia === 'sms') {
    setControlRequiredIfEmpty(smsControl);
    emailControl.reset();
    pushControl.reset();
    emailSubjectControl.reset();
    emailInput.selectedIds = [];
    pushInput.selectedIds = [];
  } else {
    smsControl.clearValidators();
  }

  if (selectedVia === 'push') {
    setControlRequiredIfEmpty(pushControl);
    smsControl.reset();
    emailControl.reset();
    emailSubjectControl.reset();
    emailInput.selectedIds = [];
    smsInput.selectedIds = [];
  } else {
    pushControl.clearValidators();
  }

  emailControl.updateValueAndValidity();
  emailSubjectControl.updateValueAndValidity();
  smsControl.updateValueAndValidity();
  pushControl.updateValueAndValidity();
}

 function setControlRequiredIfEmpty(control: AbstractControl) {
  if (!control.value || control.value?.length <= 0) {
    control.setValidators([Validators.required]);
  } else {
    control.clearValidators();
  }
}