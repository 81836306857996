import { IFormItem, IFormRow, IFormSection } from "../models/coupon-form.model";

/**
 * Creates a form section object with the given key and rows.
 * @param key The key of the section.
 * @param rows An array of form rows to include in the section.
 * @returns An object representing a form section.
 */
export const section = (key: string, rows: IFormRow[]) => ({ key, rows });

/**
 * Creates a form row object with the given items.
 * @param items An array of form items to include in the row.
 * @returns An object representing a form row.
 */
export const row = (items: IFormItem[]) => ({ items });

/**
 * Creates a form item object with the given properties.
 * @param colSize The size of the column that the item should occupy.
 * @param key The key of the item.
 * @param altLabel An optional alternative label for the item.
 * @param currency An optional flag indicating whether the item should include a currency symbol.
 * @param noLabel An optional flag indicating whether the item should have a label.
 * @param selectChange An optional function to call when the item's select value changes.
 * @param toolTip An optional flag indicating whether the item should have a tooltip.
 * @param useToken An optional flag indicating whether the item should use a token.
 * @returns An object representing a form item.
 */
export const item = (
  colSize: string,
  key: string,
  options: Omit<IFormItem, 'colSize' | 'key'> = {}
) => ({ colSize, key, ...options });

/**
 * @returns An object containing all form sections.
 * @example
{
  'key': 'points',
  'rows': [
    {
      'items': [
        {
          'colSize': '2',
          'key': 'reward_points',
          'noLabel': true,
          'toolTip': true
        },
        {
          'colSize': '5',
          'key': 'points'
        },
        {
          'colSize': '5',
          'key': 'points_money'
        }
      ]
    }
  ]
}
**/
export const getFormSections = (formSections: IFormSection[]): Record<string, IFormSection> =>{
  return Object.fromEntries(formSections.map(section => [section.key, { ...section }]));
  }
