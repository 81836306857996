import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerJourney } from '../../../models/customer-journeys/customer-journey';
import { RefreshCacheService } from '../../../services/refresh-cache.service';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../../models/forms/question-base';
import { QuestionControlService } from '../../../services/question-control.service';
import { MulticouponJourneyService } from '../../../services/multicoupon-journey.service';
import { PotentialJourneyService } from '../../../services/potential-journey.service';
import { RecuperationJourneyService } from '../../../services/recuperation-journey.service';

@Component({
  selector: 'app-journey-summary-block',
  templateUrl: './journey-summary-block.component.html',
  styleUrls: ['./journey-summary-block.component.css']
})

export class JourneySummaryBlockComponent implements OnInit {

  @Input() journey:       CustomerJourney;
  @Input() form:          UntypedFormGroup;
  @Output() onStatusEmit: EventEmitter<any> = new EventEmitter();

  public loading = true;
  public summaryChanged = false;
  public inputs: QuestionBase<any>[];

  constructor( private qcs:                 QuestionControlService,
               private refreshCacheService: RefreshCacheService,
               private multicouponService:  MulticouponJourneyService,
               private potentialService:    PotentialJourneyService,
               private recuperationService: RecuperationJourneyService ) { }

  public ngOnInit() {
    this.loading = false;
    if ( this.journey.type === 'multicoupon' ) {
      this.inputs = this.multicouponService.inputs;
    } else if ( this.journey.type === 'potential' ) {
      this.inputs = this.potentialService.inputs;
    } else if ( this.journey.type === 'recuperation' ) {
      this.inputs = this.recuperationService.inputs;
    }
    this.onStatusEmit.emit('finished_loading');
  }

  public handleSummaryChanged() {
    this.summaryChanged = true;
    this.onStatusEmit.emit('unsaved_changes');
  }

  public showJourneyIsOnDeliver() {
    this.loading = true;
    this.onStatusEmit.emit('loading');
  }

  public getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

}
