import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DictionaryShowFieldsService {

  dictionaryFields = {

    profiles: [
      {id: 'id', name: this.translate.instant('resources.profiles.fields.id')},
      {id: 'accepts_lopd', name: this.translate.instant('resources.profiles.fields.accepts_lopd')},
      {id: 'code', name: this.translate.instant('resources.profiles.fields.code')},
      {id: 'score_available', name: this.translate.instant('resources.profiles.fields.score_available')},
      {id: 'score', name: this.translate.instant('resources.profiles.fields.score')},
      {id: 'large_family', name: this.translate.instant('resources.profiles.fields.large_family')},
      {id: 'dni', name: this.translate.instant('resources.profiles.fields.dni')},
      {id: 'postal_code', name: this.translate.instant('resources.profiles.fields.postal_code')},
      {id: 'city', name: this.translate.instant('resources.profiles.fields.city')},
      {id: 'state', name: this.translate.instant('resources.profiles.fields.state')},
      {id: 'address', name: this.translate.instant('resources.profiles.fields.address')},
      {id: 'family_number', name: this.translate.instant('resources.profiles.fields.family_number')},
      {id: 'company_id', name: this.translate.instant('resources.profiles.fields.company_id')},
      {id: 'created_at', name: this.translate.instant('resources.profiles.fields.created_at')}
    ],
    collectives: [
      {id: 'id', name: this.translate.instant('resources.collectives.fields.id')},
      {id: 'name', name: this.translate.instant('resources.collectives.fields.name')},
      {id: 'slug', name: this.translate.instant('resources.collectives.fields.slug'),
      children: {
        students: this.translate.instant('resources.collectives.slugs.students'),
        new_clients: this.translate.instant('resources.collectives.slugs.new_clients'),
        employees: this.translate.instant('resources.collectives.slugs.employees'),
      }}
    ],
    segments: [
      {id: 'id', name: this.translate.instant('resources.segments.fields.id')},
      {id: 'name', name: this.translate.instant('resources.segments.fields.name')},
      {id: 'description', name: this.translate.instant('resources.segments.fields.description')},
      {id: 'last_execution', name: this.translate.instant('resources.segments.fields.last_execution')},
      {id: 'applies', name: this.translate.instant('resources.segments.fields.applies')}
    ],
    segment_categories: [
      {id: 'name', name: this.translate.instant('resources.segments.fields.name')}
    ],
    score_exchanges: [
      {id: 'name', name: this.translate.instant('resources.scores.score_exchanges.fields.name')},
      {id: 'description', name: this.translate.instant('resources.scores.score_exchanges.fields.description')},
      {id: 'currency_amount', name: this.translate.instant('resources.scores.score_exchanges.fields.currency_amount')},
      {id: 'currency', name: this.translate.instant('resources.scores.score_exchanges.fields.currency')},
    ],
    campaigns: [
      {id: 'name', name: this.translate.instant('resources.campaigns.fields.name')},
      {id: 'via', name: this.translate.instant('resources.campaigns.fields.via'),
      children: {
        email: this.translate.instant('resources.campaigns.channels.email'),
        sms: this.translate.instant('resources.campaigns.channels.sms'),
        none: this.translate.instant('resources.campaigns.channels.ticket_coupon'),
        push: this.translate.instant('resources.campaigns.channels.push'),
        ticket_message: this.translate.instant('resources.campaigns.channels.ticket_message'),
      }},
      {id: 'motive', name: this.translate.instant('resources.campaigns.fields.motive'),
      children: {
        informative: this.translate.instant('resources.campaigns.motive.informative'),
        coupon: this.translate.instant('resources.campaigns.motive.ticket_coupon'),
        message: this.translate.instant('resources.campaigns.motive.ticket_message'),
      }},
      {id: 'distribution', name: this.translate.instant('resources.campaigns.fields.distribution')},
      {id: 'voucheables.name', name: this.translate.instant('resources.campaigns.fields.coupon')},
      {id: 'segment.name', name: this.translate.instant('resources.campaigns.fields.segment')},
      {id: 'scheduled_at', name: this.translate.instant('resources.campaigns.fields.scheduled_at')}
    ],
    campaign_categories: [
      {id: 'name', name: this.translate.instant('resources.campaign_categories.fields.name')},
      {id: 'priority', name: this.translate.instant('resources.campaign_categories.fields.priority')}
    ],
    messages: [
      {id: 'id', name: this.translate.instant('resources.messages.fields.id')},
      {id: 'name', name: this.translate.instant('resources.messages.fields.name')},
      {id: 'available_days', name: this.translate.instant('resources.messages.fields.available_days')},
      {id: 'message_category.name', name: this.translate.instant('resources.messages.fields.message_category')},
      {id: 'location_ids', name: this.translate.instant('resources.messages.fields.location_ids')},
    ],
    // Mensajes asignados
    vouchers: [
      {id: 'motivator.name', name: this.translate.instant('resources.vouchers.fields.motivator_name')},
      {id: 'static_code', name: this.translate.instant('resources.vouchers.fields.static_code')},
      {id: 'customer_id', name: this.translate.instant('resources.vouchers.fields.customer_id')},
      {id: 'created_at', name: this.translate.instant('resources.vouchers.fields.created_at')},
    ],
    message_categories: [
      {id: 'name', name: this.translate.instant('resources.messages.fields.name')}
    ],
    // CMS
    news: [
      {id: 'title', name: this.translate.instant('resources.cms.fields.title')},
      {id: 'body', name: this.translate.instant('resources.cms.fields.body')}
    ],
    news_taxonomy_terms: [
      {id: 'thumbnail', name: this.translate.instant('resources.cms.fields.thumbnail')},
      {id: 'name', name: this.translate.instant('resources.cms.fields.name')},
      {id: 'slug', name: this.translate.instant('resources.cms.fields.slug')},
      {id: 'id', name: this.translate.instant('resources.cms.fields.id')},
      {id: 'news_taxonomy_terms', name: this.translate.instant('resources.cms.fields.news_taxonomy_terms')}
    ],
    news_taxonomies: [
      {id: 'name', name: this.translate.instant('resources.cms.fields.name')},
      {id: 'slug', name: this.translate.instant('resources.cms.fields.slug')}
    ],
    coupons: [
      {id: 'id', name: this.translate.instant('resources.coupons.fields.id')},
      {id: 'name', name: this.translate.instant('resources.coupons.fields.name')},
      {id: 'description', name: this.translate.instant('resources.coupons.fields.description')},
      {id: 'available_days', name: this.translate.instant('resources.coupons.fields.available_days')},
      {id: 'discount', name: this.translate.instant('resources.coupons.fields.discount')},
      {id: 'features_list.name', name: this.translate.instant('resources.coupons.fields.features_list')},
      {id: 'printable', name: this.translate.instant('resources.coupons.fields.printable')},
      {id: 'priority', name: this.translate.instant('resources.coupons.fields.priority')},
      {id: 'static_code', name: this.translate.instant('resources.coupons.fields.static_code')},
      {id: 'locations.name', name: this.translate.instant('resources.coupons.fields.locations_name')},
      {id: 'cumulative', name: this.translate.instant('resources.coupons.fields.cumulative')},
    ],
    campaign_blocks: [
      {id: 'pretty_configuration.extra_segment.name', name: this.translate.instant('resources.journeys.fields.segment_name')},
      {id: 'configuration.coupon_id', name: this.translate.instant('resources.journeys.fields.coupon')},
      {id: 'steps.name', name: this.translate.instant('resources.journeys.fields.steps')}
    ],
    recipes: [
      {id: 'executions', name: this.translate.instant('resources.marketing_automation.fields.executions')},
      {id: 'trigger', name: this.translate.instant('resources.marketing_automation.fields.trigger'),
      children: {
        after_create_activity: this.translate.instant('resources.marketing_automation.when.after_create_activity'),
        after_first_login: this.translate.instant('resources.marketing_automation.when.after_first_login'),
        after_create_voucher: this.translate.instant('resources.marketing_automation.when.after_create_voucher'),
        after_create_customer: this.translate.instant('resources.marketing_automation.when.after_create_customer'),
      }},
      {id: 'action', name: this.translate.instant('resources.marketing_automation.fields.action'),
      children: {
        push: this.translate.instant('resources.marketing_automation.actions.push'),
        coupon: this.translate.instant('resources.marketing_automation.actions.coupon'),
      }},
      {id: 'configuration.coupon_id', name: this.translate.instant('resources.marketing_automation.fields.coupon_id')}
    ],
    locations: [
      {id: 'name', name: this.translate.instant('resources.locations.fields.name')},
      {id: 'code', name: this.translate.instant('resources.locations.fields.code')},
      {
        id: 'status',
        name: this.translate.instant('resources.locations.fields.status'),
        children: {
          active: this.translate.instant('resources.locations.status.active'),
          inactive: this.translate.instant('resources.locations.status.inactive'),
        }
      },
      {id: 'phone', name: this.translate.instant('resources.locations.fields.phone')},
      {id: 'email', name: this.translate.instant('resources.locations.fields.email')},
      {id: 'address', name: this.translate.instant('resources.locations.fields.address')},
      {id: 'postal_code', name: this.translate.instant('resources.locations.fields.postal_code')},
      {id: 'country', name: this.translate.instant('resources.locations.fields.country')},
      {id: 'state', name: this.translate.instant('resources.locations.fields.state')},
      {id: 'city', name: this.translate.instant('resources.locations.fields.city')},
      {id: 'goals.signups.week', name: this.translate.instant('resources.locations.fields.goals.signups')},
      {id: 'franchise', name: this.translate.instant('resources.locations.fields.franchise')}
    ],
    location_taxonomy_terms: [
      {id: 'name', name: this.translate.instant('resources.locations.fields.name')},
      {id: 'slug', name: this.translate.instant('resources.locations.fields.slug')},
      {id: 'id', name: this.translate.instant('resources.locations.fields.id')},
      {id: 'taxonomy.name', name: this.translate.instant('resources.locations.fields.taxonomy_name')}
    ],
    location_taxonomies: [
      {id: 'name', name: this.translate.instant('resources.locations.fields.name')},
      {id: 'slug', name: this.translate.instant('resources.locations.fields.slug')}
    ],
    products: [
      {id: 'name', name: this.translate.instant('resources.products.fields.name')},
      {id: 'code', name: this.translate.instant('resources.products.fields.code')},
      {id: 'id', name: this.translate.instant('resources.products.fields.id')},
      {id: 'description', name: this.translate.instant('resources.products.fields.description')},
      {id: 'price', name: this.translate.instant('resources.products.fields.price')},
      // TODO: mostrar listado de array
      {id: 'features.taxonomy.name', name: this.translate.instant('resources.products.fields.features_name')},
      // TODO: Make template property for composed values as (location.id) location.city, location.address
    ],
    features: [
      {id: 'id', name: this.translate.instant('resources.products.fields.id')},
      {id: 'name', name: this.translate.instant('resources.products.fields.name')},
      {id: 'slug', name: this.translate.instant('resources.products.fields.slug')},
      {id: 'taxonomy.name', name: this.translate.instant('resources.products.fields.taxonomy')}
    ],
    feature_taxonomies: [
      {id: 'name', name: this.translate.instant('resources.products.fields.name')},
      {id: 'slug', name: this.translate.instant('resources.products.fields.slug')},
      {id: 'origin', name: this.translate.instant('resources.products.fields.origin'),
      children: {
        internal: this.translate.instant('resources.products.fields.internal'),
        external: this.translate.instant('resources.products.fields.external'),
      }}
    ],
    promotions: [
      {id: 'description', name: this.translate.instant('resources.promotions.fields.description')},
      {id: 'available_from', name: this.translate.instant('resources.promotions.fields.available_from')},
      {id: 'available_to', name: this.translate.instant('resources.promotions.fields.available_to')},
      // {id: 'locations_ids', name: this.translate.instant('resources.promotions.fields')}
    ],
    synchros: [
      {id: 'completed_at', name: this.translate.instant('resources.synchros.fields.completed_at')},
      {id: 'status', name: this.translate.instant('resources.synchros.fields.status'),
      children: {
        completed: this.translate.instant('resources.synchros.status.completed')
      }}
    ],
    streamings: [
      {id: 'id', name: this.translate.instant('resources.streaming.fields.id')},
      {id: 'total_elements', name: this.translate.instant('resources.streaming.fields.total_elements')},
      {id: 'total_errors', name: this.translate.instant('resources.streaming.fields.total_errors')},
      {id: 'total_hits', name: this.translate.instant('resources.streaming.fields.total_hits')},
      {id: 'created_at', name: this.translate.instant('resources.streaming.fields.created_at')}
    ],
    invoices: [
      {id: 'id', name: this.translate.instant('resources.invoices.fields.id')},
      {id: 'stripe_invoice_id', name: this.translate.instant('resources.invoices.fields.stripe_invoice_id')},
      {id: 'iva_amount', name: this.translate.instant('resources.invoices.fields.iva_amount')},
      {id: 'total', name: this.translate.instant('resources.invoices.fields.total')},
      {id: 'total_without_iva', name: this.translate.instant('resources.invoices.fields.total_without_iva')},
      {id: 'iva_percentage', name: this.translate.instant('resources.invoices.fields.iva_percentage')},
      {id: 'date', name: this.translate.instant('resources.invoices.fields.date')},
      {id: 'number', name: this.translate.instant('resources.invoices.fields.number')},
      {id: 'subject', name: this.translate.instant('resources.invoices.fields.subject')}
    ],
    credit_transactions: [
      {id: 'amount', name: this.translate.instant('resources.credits.fields.amount')},
      {id: 'kind', name: this.translate.instant('resources.credits.fields.kind')},
      {id: 'created_at', name: this.translate.instant('resources.credits.fields.created_at')},
      {id: 'notes', name: this.translate.instant('resources.credits.fields.notes')},
      {id: 'friendly_source', name: this.translate.instant('resources.credits.fields.friendly_source')}
    ],
    bigquery_jobs: [
      {id: 'name', name: this.translate.instant('resources.jobs.fields.name')},
      {id: 'execution', name: this.translate.instant('resources.jobs.fields.execution'),
      children: {
        daily: this.translate.instant('resources.jobs.execution.daily'),
        fortnightly: this.translate.instant('resources.jobs.execution.fortnightly'),
        manual: this.translate.instant('resources.jobs.execution.manual')
      }},
      {id: 'last_execution', name: this.translate.instant('resources.jobs.fields.last_execution')},
      {id: 'average_duration', name: this.translate.instant('resources.jobs.fields.average_duration')},
      {id: 'status', name: this.translate.instant('resources.jobs.fields.status'),
      children: {
        success: this.translate.instant('resources.jobs.status.success'),
        error: this.translate.instant('resources.jobs.status.error')
      }}
    ],
    periods: [
      {id: 'number', name: this.translate.instant('resources.periods.fields.number')},
      {id: 'date_from', name: this.translate.instant('resources.periods.fields.date_from')},
      {id: 'date_to', name: this.translate.instant('resources.periods.fields.date_to')},
      {id: 'period_date', name: this.translate.instant('resources.periods.fields.period_date')},
      {id: 'started_at', name: this.translate.instant('resources.periods.fields.started_at')},
      {id: 'updated_at', name: this.translate.instant('resources.periods.fields.updated_at')},
    ],
    versions: [
      {id: 'item_type', name: this.translate.instant('resources.versions.fields.item_type')},
      {id: 'item_id', name: this.translate.instant('resources.versions.fields.item_id')},
      {id: 'event', name: this.translate.instant('resources.versions.fields.event'),
      children: {
        update: this.translate.instant('resources.versions.actions.update'),
        create: this.translate.instant('resources.versions.actions.create'),
        login: this.translate.instant('resources.versions.actions.login'),
        logout: this.translate.instant('resources.versions.actions.logout'),
        destroy: this.translate.instant('resources.versions.actions.destroy')
      }},
      {id: 'params.email', name: this.translate.instant('resources.versions.fields.email')},
      {id: 'created_at', name: this.translate.instant('resources.versions.fields.created_at')}
    ],
    users: [
      {id: 'name', name: this.translate.instant('resources.users.fields.name')},
      {id: 'surname_1', name: this.translate.instant('resources.users.fields.surname_1')},
      {id: 'email', name: this.translate.instant('resources.users.fields.email')},
      {id: 'role.name', name: this.translate.instant('resources.users.fields.role_name')},
      {id: 'authentication_token', name: this.translate.instant('resources.users.fields.token')}
    ],
  };

  constructor(public translate: TranslateService) {}

  public getValuesByKey(key: string) {
    return this.dictionaryFields[key];
  }

  public isKeyInDictionary(key: string) {
    return this.dictionaryFields.hasOwnProperty(key);
  }

  public getNameByKeyAndId(key: string, id: string) {

    if (!this.dictionaryFields.hasOwnProperty(key)) {
      console.error('DictionaryError: Sent key is not in dictionary');
      return;
    }

    const arrayOfValues = this.dictionaryFields[key];

    let objToReturn = '';
    arrayOfValues.forEach(element => {
      if ( element.id === id ) {
        objToReturn = element;
      }
      return objToReturn['name'];
    });

    return objToReturn['name'];
  }
}
