import { BigqueryJobsStepsBase } from './bigquerystep-base';

export class GrantScoresStep extends BigqueryJobsStepsBase {
  public category: string;
  public config: {
    loyalty_score_id: number,
    segment_id: number,
    comments: string
  };

  constructor(options: {} = {}) {
    super(options);
    this.category = options['category'] || 'grant_scores';
    this.config = {
      loyalty_score_id: options['config']['loyalty_score_id'],
      segment_id: options['config']['segment_id'],
      comments: options['config']['comments']
    };
  }

  public getPayload() {
    const payload = super.getPayload();
    const extraValues = {
      config: {
        loyalty_score_id: this.config.loyalty_score_id,
        segment_id: this.config.segment_id,
        comments: this.config.comments
      }
    };
    return {...payload, ...extraValues};
  }
}
