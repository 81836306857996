<div class="card">
    <div class="card-heading border bottom">
        <h4 class="card-title" [translate]="'resources.coupons.form.titles.' + key"></h4>
    </div>
    <div class="card-block">
        <div class="alert alert-info" role="alert">
            <span class="alert-text" [translate]="'resources.coupons.form.subtitles.' + key"></span>
        </div>
        <ng-content></ng-content>
    </div>
</div>