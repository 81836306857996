import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionBase } from '../../../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../../../shared/services/question-control.service';
import { CampaignHistoriesService } from '../../../../../campaigns/campaign-histories.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CampaignsService } from '../../../../../campaigns/campaigns.service';
import { MultiSelectQuestion } from '../../../../../../shared/models/forms/question-multiselect';
import { CheckboxQuestion } from '../../../../../../shared/models/forms/question-checkbox';

@Component({
  selector: 'app-campaign-input',
  templateUrl: './campaign-input.component.html',
  styleUrls: ['./campaign-input.component.css'],
  providers: [CampaignsService, CampaignHistoriesService]
})
export class CampaignInputComponent implements OnInit {

  @Input('formKey') formKey: string;
  @Input('form') form: UntypedFormGroup;
  @Input('campaignValues') campaignValues;
  @Input('index') index: number;
  @Output('onDeleteCampaign') onDeleteCampaign: EventEmitter<number>;

  public campaignInputForm: UntypedFormGroup;
  public inputs: any;
  public hideInput: boolean;
  public campaignInputForm$: Subscription;
  public historyId: any;
  public isNext: boolean;
  public formValues = {};

  constructor(
    private qcs: QuestionControlService,
    private campaignHistoriesService: CampaignHistoriesService,
    private campaignHistories: CampaignHistoriesService,
    private translate: TranslateService,
    private campaigns: CampaignsService
  ) {
    this.onDeleteCampaign = new EventEmitter<number>();
  }

  ngOnInit() {
    setTimeout(() => {
      this.getParams();
    }, 100);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKey(formKey: string): boolean {
    return this.qcs.hasFormKey(this.campaignInputForm, formKey);
  }

  handleCampaignSelected(event) {
    if(!(event)) { this.campaignInputForm.reset(); }
    this.inputs.find(input => input.key === 'history').disabled = true;
    this.inputs.find(input => input.key === 'history').value = [];
    if(event && event.rawElement && event.rawElement.sent_at) {
      this.inputs.find(input => input.key === 'history').disabled = false;
      this.campaignHistoriesService.setCampaign(event.id);
    }
  }

  removeCampaign() {
    this.onDeleteCampaign.emit(this.index);
  }

  private getParams() {
    this.inputs = this.getInputs(this.campaignValues, this.formKey);
    this.campaignInputForm = this.qcs.toFormGroup(this.inputs);
    this.form.addControl(this.formKey, this.campaignInputForm);
    this.campaignInputForm$ = this.campaignInputForm.get(this.inputs[2].key).valueChanges.subscribe(
      checkBoxValue => {
        if (checkBoxValue) {
          this.inputs[1].value = [];
          this.inputs[1].disabled = true;
        } else {
          this.inputs[1].disabled = false;
        }
      }
    );
  }

  private getInputs(formValues: any, formKey: string) {

    let config = null;

    if (formValues) { config = formValues; };

    if (config && config.campaign_id) {
      this.campaignHistories.setCampaign(config.campaign_id[0]);
      this.historyId = config.id;
      this.isNext = this.historyId === 'next';
      this.historyId = this.isNext ? [] : [config.id];
    }

    return [
      new MultiSelectQuestion({
        key: 'campaigns',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: true, showCheckbox: false },
        dataSource: this.campaigns,
        selectedIds: (config && config.campaign_id && config.campaign_id.length > 0) ? [config.campaign_id[0]] : []
      }),
      new MultiSelectQuestion({
        key: 'history',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: true, showCheckbox: false },
        dataSource: this.campaignHistories,
        selectedIds: (config) ? this.historyId : [],
        disabled: true
      }),
      new CheckboxQuestion({
        key: 'prediction_next_delivery_' + formKey,
        label: this.translate.instant('resources.reports.form.fields.prediction_next_delivery'),
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        value: this.isNext
      })
    ];
  }
}
