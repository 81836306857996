import { isNullOrUndefined } from '../../utils/common.utils';

export class Location {

  id: number | string;
  status: string;
  name: string;
  code: string;
  phone: string;
  email: string;
  address: string;
  schedule: string;
  postalCode: string;
  state: string;
  city: string;
  country: string;
  scoreExchange: {
    name: string
  };
  giftCardAvailable: boolean;
  goals: {
    signups: {
      week: string
    }
  }
  locationTaxonomyTerms: {
    name: string,
    value: string
  }[];

  constructor(cfg: object) {
    this.id = cfg['id'];
    this.status = cfg['status'];
    this.name = cfg['name'];
    this.code = cfg['code'];
    this.phone = cfg['phone'];
    this.email = cfg['email'];
    this.address = cfg['address'];
    this.schedule = cfg['schedule'];
    this.postalCode = cfg['postal_code'];
    this.state = cfg['state'];
    this.city = cfg['city'];
    this.country = cfg['country'];
    this.scoreExchange = cfg['_embedded'] && cfg['_embedded']['score_exchange'] ? this.parseScoreExchange(cfg['_embedded']['score_exchange']) : null;
    this.giftCardAvailable = cfg['gift_card_available'];
    this.locationTaxonomyTerms = cfg['_embedded'] && cfg['_embedded']['location_taxonomy_terms']?.length ? this.parseTaxonomyTerms(cfg['_embedded']['location_taxonomy_terms']) : [];
  }

  private parseTaxonomyTerms(apiList: object[]): {name: string, value: string}[] {
    if(isNullOrUndefined(apiList)){return []};
    let parsedList = [];
    apiList.forEach((term: object) => {
      const shownElement = {
        value: term['name'],
        name: term['taxonomy'] ? term['taxonomy']['name'] : '-'
      }
      parsedList.push(shownElement);
    });
    return parsedList;
  }

  private parseScoreExchange(cfg: object) {
    if(isNullOrUndefined(cfg)){return null};
    if(cfg.hasOwnProperty('name') && !isNullOrUndefined(cfg['name'])){
      return {name: cfg['name']}
    };
    return null;
  }
}
