<div class="container-fluid clearfix">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-block">
          <div class="row">
            <div class="col-md-12">
              <h1>{{element.name}}</h1>
            </div>
          </div>

          <div class="flex space-between">
            <div class=" text-center">
              <span>{{ 'resources.reports.report_incremental.fields.impacted' | translate }}</span>
              <h4 class="font-size-17">{{element.impacted | number:'1.0-0':'es'}}</h4>
            </div>

            <div class=" text-center">
              <span>{{'resources.reports.report_incremental.fields.printed' | translate}}</span>
              <h4 class="font-size-17">{{element.printed | number:'1.0-0':'es'}}</h4>
            </div>

            <div class=" text-center">
              <span>{{'resources.reports.report_incremental.fields.ratio_printed' | translate}}</span>
              <h4 class="font-size-17">{{element.ratio_printed  | percent}}</h4>
            </div>

            <div class=" text-center">
              <span>{{'resources.reports.report_incremental.fields.unique_redemptions' | translate}}</span>
              <h4 class="font-size-17">{{element.unique_redemptions | number:'1.0-0':'es'}}</h4>
            </div>

            <div class=" text-center">
              <span>{{'resources.reports.report_incremental.fields.ratio_unique_redemptions' | translate}}</span>
              <h4 class="font-size-17">{{element.ratio_unique_redemptions | percent}}</h4>
            </div>

            <div class=" text-center">
              <span>{{'resources.reports.report_incremental.fields.discount' | translate}}</span>
              <h4 class="font-size-17">{{element.discount | lgcurrency:'1.2-2'}}</h4>
            </div>

            <div class=" text-center">
              <span>{{'resources.reports.report_incremental.fields.cost' | translate}}</span>
              <h4 class="font-size-17">{{element.cost | lgcurrency}}</h4>
            </div>

            <div class=" text-center">
              <span>{{'resources.reports.report_incremental.fields.total_cost' | translate}}</span>
              <h4 class="font-size-17">{{element.total_cost | lgcurrency:'1.0-0'}}</h4>
            </div>

            <div class=" text-center">
              <span>{{'resources.reports.report_incremental.fields.incremental' | translate}}</span>
              <h4 class="font-size-17">{{element.incremental | lgcurrency:'1.0-0'}}</h4>
            </div>

            <div class=" text-center">
              <span>{{'resources.reports.report_incremental.fields.ratio_incremental' | translate}}</span>
              <h4 class="font-size-17">{{element.ratio_incremental | number:'1.0-0':'es'}}</h4>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
