export class User {

  fullname: string;
  email: string;
  authtoken: string;
  locations: string[];
  role: UserRole;
  status: string;
  country: string;
  preferredLanguage: string;
  suppliers: UserSupplier[];

  constructor(options) {
    this.fullname = this.getFullName(options);
    this.email = options.email || null;
    this.authtoken = options.authentication_token || null;
    this.locations = options.locations?.length ? options.locations.map(location => location.name) : [];
    this.role = options.role ? new UserRole(options.role) : null;
    this.status = options.status || null;
    this.country = options.country || null;
    this.preferredLanguage = options.preferred_language || null;
    this.suppliers = options._embedded?.suppliers?.length ? options._embedded.suppliers.map(supplier => new UserSupplier(supplier)) : [];
  }

  private getFullName(params) {
    return (params.name || '') + ' ' + (params.surname_1 || '') + ' ' + (params.surname_2 || '');
  }
}

export class UserRole {
  id: number;
  name: string;
  permissions: string[];
  slug: string;
  
  constructor(options) {
    this.id = options.id || null;
    this.name = options.name || null;
    this.permissions = options.permissions?.length ? options.permissions : [];
    this.slug = options.slug || null;
  }
}

export class UserSupplier {
  id: number;
  name: string;
  slug: string;
  taxonomySlug: string;
  externalId: string;
  taxonomy: UserSupplierTaxonomy;
  pk: string;
  
  constructor(options) {
    this.id = options.id || null;
    this.name = options.name || null;
    this.slug = options.slug || null;
    this.taxonomySlug = options.taxonomy_slug || null;
    this.externalId = options.external_id || null;
    this.taxonomy = options.taxonomy ? new UserSupplierTaxonomy(options.taxonomy) : null;
  }
}

export class UserSupplierTaxonomy {
  id: number;
  name: string;
  origin: string;
  synchroId: string;
  kind: string;
  level: number;
  
  constructor(options) {
    this.id = options.id || null;
    this.name = options.name || null;
    this.origin = options.origin || null;
    this.synchroId = options.synchro_id || null;
    this.kind = options.kind || null;
    this.level = options.level || null;
  }
}