<div class="page-title background-white">
  <div class="row pdd-horizon-15">
    <div class="col-md-9 d-flex align-items-center min-height-60px">
      <h4 class="no-mrg-vertical" [translate]="'resources.profiles.title'"></h4>
    </div>
  </div>
</div>

<ul class="nav nav-tabs w-100" role="tablist">
  <li class="nav-item">
      <a (click)="selectTab('profiles')"
          [routerLink]="['profiles']"
          class="nav-link {{slug === 'profiles'? 'active' : ''}}"
          data-toggle="tab"
          href="#profiles-tab-1"
          role="tab"
          aria-selected="true"
          [translate]="'resources.profiles.tabs.clients'">
      </a>
  </li>
  <li class="nav-item" *ngIf="isAllowedRoleSlug">
      <a (click)="selectTab('collectives')"
          class="nav-link {{slug === 'collectives'? 'active' : ''}}"
          [routerLink]="['collectives']"
          data-toggle="tab"
          href="#profiles-tab-2"
          role="tab"
          aria-selected="false"
          [translate]="'resources.profiles.tabs.collectives'">
      </a>
  </li>                
</ul>

<div class="mrg-top-15 container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="position-relative">
        <router-outlet></router-outlet>
      </div>
    </div>    
  </div>
</div>
