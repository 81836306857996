import { AbstractControl } from "@angular/forms";
import { QuestionBase } from "../../../../shared/models/forms/question-base";
import { userPassworValidator } from "../../../../shared/validators/user-password.validator";
import { UserSecurity } from "../../../../shared/models/company/company-configurations";

export function handleUserPasswordError(
  userSecurity: UserSecurity,
  userPasswordFormField: AbstractControl,
  userPasswordInput: QuestionBase<unknown>,
  userPasswordError: unknown
): void {
  userPasswordFormField.addValidators([userPassworValidator(userSecurity)]);
  userPasswordFormField.updateValueAndValidity();
  const userPasswordFormErrors = { ...userPasswordFormField.errors, notPresent: true };
  userPasswordInput.hasErrors = true;
  userPasswordInput.errorText = userPasswordError?.['message'] ? userPasswordError['message'] : null;
  userPasswordFormField.setErrors(userPasswordFormErrors);
}