import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CampaignsFormService } from '../../campaigns-form.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FeatureFlagsService } from '../../../../services/feature-flags.service';
import { MultiselectDataSource } from '../../../multiselect/multiselect';
import { QuestionBase } from '../../../../models/forms/question-base';
import { QuestionControlService } from '../../../../services/question-control.service';
import { setTemplateRequired } from '../../template-validator-utils/template-utils';

@Component({
  selector: 'app-campaign-definition-block',
  templateUrl: './campaign-definition-block.component.html',
  styleUrls: ['./campaign-definition-block.component.scss']
})

export class CampaignDefinitionBlockComponent implements OnInit {

  @Input('campaign') campaign;
  @Input('campaignForm') campaignForm: UntypedFormGroup;
  @Input('inputs') inputs;
  @Input('mode') mode;
  @Input('refreshSender') refreshSender;

  @Output() resetTemplateEmitter = new EventEmitter<any>();

  controlGroupVisible: boolean;
  flags = this.featureFlags.flags;
  invalidScheduler = false;
  membershipCampaign: boolean;
  scheduledDateMsg: string;

  constructor(
    private campaignsFormService: CampaignsFormService,
    private featureFlags: FeatureFlagsService,
    private qcs: QuestionControlService
  ){}

  ngOnInit(): void {}

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.campaignForm, formKey, value);
  }

  handleViaInputChanged(event?: MultiselectDataSource) {
    const senderValue = this.campaignForm.get('sender_id');
    this.refreshSender = false;
    senderValue.reset();
    if(event) { this.handleSenderInputBehaviour(event.id); }
    this.resetTemplateEmitter.emit('resetCampaignTemplate');
  }

  handleSenderOptsLoaded(opts: MultiselectDataSource[]) {
    const defaultOpt = opts.find(opt => opt.rawElement.default === true);
    const selectedSender = this.campaignForm.value.sender_id;
    // using setTimeout to avoid change after checked error in ngform
    setTimeout(() => {
      // auto select default campaign sender if campaign is new
      if (!this.campaign.id || !selectedSender) {
        // auto select default opt in sender
        this.campaignForm.patchValue({sender_id: [defaultOpt]});
      }
    });
  }

  handleCouponValueChanged(event?: MultiselectDataSource) {
    this.campaignsFormService.handleCouponValueChanged(this.campaignForm);
  }

  inputHandler(evt) {
    const _target: HTMLInputElement = evt.target || evt.srcElement || evt.currentTarget;
    const scheduleInp = this.campaignForm.get('scheduled_at');
    if (_target) { this.invalidScheduler = _target.validity.badInput; }
    if (this.invalidScheduler) {
      scheduleInp.setErrors([]);
      scheduleInp.markAsDirty();
      scheduleInp.markAsTouched();
    } else {
      scheduleInp.setErrors(null);
      scheduleInp.markAsPristine();
      scheduleInp.markAsUntouched();
    }
  }

  private handleSenderInputBehaviour(selectedVia: string) {
    const senderInput = this.inputs.find((item => item.key === 'sender_id'));
    setTimeout(() => {
      this.refreshSender = true;
    }, 0);
    this.campaign.via = selectedVia;
    if(selectedVia === 'email' || selectedVia === 'sms') {
      senderInput.selectedIds = [];
      this.campaignForm.get('sender_id').reset();
    }
    setTemplateRequired(this.campaignForm, this.inputs);
  }

}
