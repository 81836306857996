<div class="row" *ngIf="inputs">

  <div class="col-md-3">
    <div class="form-group">
      <app-question [question]="getInputConfig('coupon_ids')" [form]="form" (multiselectChanged)="handleCouponSelected($event)"></app-question>
    </div>
  </div>

  <div class="col-md-2">
    <div class="form-group">
      <app-question [question]="getInputConfig('status')" [form]="form" (multiselectChanged)="handleStatusSelected($event)"></app-question>
    </div>
  </div>
  
  <div class="col-md-2 mrg-top-35">
    <div class="form-group">                          
      <app-question [question]="inputs[0]" [form]="form"></app-question>
    </div>
  </div>

  <div class="col-md-2" *ngIf="couponsSelectedLength > 1">
    <div class="form-group">
      <app-question [question]="getInputConfig('delivery_type_multi')" [form]="form"></app-question>
    </div>
  </div>

  <div class="col-md-2" *ngIf="!couponsSelectedLength || couponsSelectedLength <= 1">
    <div class="form-group">
      <app-question [question]="getInputConfig('delivery_type_single')"
                    (multiselectChanged)="handleDeliveryTypeSelected($event)"
                    [form]="form"></app-question>
    </div>
  </div>

  <div class="col-md-3" *ngIf="couponsSelectedLength === 1 &&
                               ( hasFormKeyWithValue('delivery_type_single', 'all') || 
                                 hasFormKeyWithValue('delivery_type_single', 'any') )">
    <div class="form-group">
      <app-question [question]="getInputConfig('coupon_history_ids')" [form]="form"></app-question>
    </div>
  </div>
</div>
