import { Component, OnInit, Input } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../services/question-control.service';
import { CustomConditionService } from '../../../../services/conditions/custom-condition.service';
import { isNullOrUndefined } from 'util';
import { MultiselectService } from '../../../multiselect/multiselect.service';

@Component({
  selector: 'app-custom-segment-condition',
  templateUrl: './custom-segment-condition.component.html',
  styleUrls: ['./custom-segment-condition.component.css']
})
export class CustomSegmentConditionComponent implements OnInit {

  @Input('item') item: Condition;
  @Input('inputs') inputs: QuestionBase<any>[] = [];
  @Input('form') form: UntypedFormGroup;

  mode = 'custom';
  extraValues = false;

  constructor( 
    private qcs: QuestionControlService,
    private customConditionService: CustomConditionService,
    private multiselectService: MultiselectService
  ) {}

  ngOnInit() {
    this.mode = this.getModeByScope(this.getInputConfig('scope').value);
    this.extraValues = this.hasExtraValues(this.getInputConfig('scope').value);
    this.handleInputs(false);
    this.updateInputsOptions();
  }

  handleScopeChanged(scopeSelection) {
    const operator = this.getInputConfig('operator');
    const valueArray = this.getInputConfig('value_array');
    this.handleInputs(true);
    if (scopeSelection) {
      this.mode = this.getModeByScope(scopeSelection);
      if ( scopeSelection.hasOwnProperty('options') ) {
        operator.options = this.customConditionService.getArrayOperators();
        valueArray.options = scopeSelection.options;
        this.extraValues = true;
      } else {
        operator.options = this.customConditionService.getStringOperators();
        valueArray.options = [];
        this.extraValues = false;
      }
    } else {
      operator.options = [];
    }
   
    this.updateInputsOptions();
  }

  handleOperatorChanged() {
    this.resetValueInputs();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    if ( !value ) { return false; }
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  private handleInputs(resetInputs: boolean) {
    if (resetInputs) {
      this.resetOperatorInputs();   
      this.resetValueInputs();
    }

    this.handleDisableInputs();
  }

  private resetOperatorInputs() {
    this.getInputConfig('operator').value = [];
    this.getInputConfig('operatorF').value = [];
    this.form.get('operator').reset();
    this.form.get('operatorF').reset();
  }

  private resetValueInputs() {
    this.getInputConfig('value_array').value = [];
    this.getInputConfig('value').value = null;
    this.getInputConfig('valueF').value = null;
    this.getInputConfig('value_bt').value = null;
    this.form.patchValue({value_array: [], value: null, valueF: null, value_bt: null}, {emitEvent: true});
    
    const fields = ['value', 'value_array', 'valueF', 'value_bt'];
    fields.forEach(field => this.form.get(field).markAsPristine());
  }

  private handleDisableInputs() {
    const scopeInput = this.getInputConfig('scope');
    const isScopeInputValue = scopeInput.value && scopeInput.value.length > 0;
    const fields = ['operator', 'operatorF', 'value', 'value_array', 'valueF', 'value_bt'];
    fields.forEach(field => {
      this.getInputConfig(field).disabled = !isScopeInputValue;
    });
  }

  private getModeByScope(_scope) {
    if (isNullOrUndefined(_scope) || (_scope instanceof Array && _scope.length === 0)) { return 'custom'; }
    let scopeId;
    if (_scope instanceof Array && _scope.length > 0) {
      scopeId = _scope[0].id;
    } else {
      scopeId = _scope.id;
    }
    return scopeId.endsWith('f') ? 'customf' : 'custom';
  }

  private hasExtraValues(scope): boolean {
    if (!scope || (scope && scope.length === 0)) { return false; }
    const scopeOptions = scope[0].options;
    return scopeOptions && scopeOptions.length > 0;
  }

  private updateInputsOptions() {
    this.multiselectService.updateDropdownList.next('operator');
    this.multiselectService.updateDropdownList.next('value_array');
  }
}
