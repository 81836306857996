import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../shared/components/multiselect/multiselect';
import { Observable } from 'rxjs';
import { QuestionBase } from '../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../shared/models/forms/question-textbox';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable()
export class SegmentCategoriesService extends ResourcesService implements MultiselectDataSourceable {

  inputs: TextboxQuestion[];
  onlyCategoriesWithSegments: boolean = false;
  onlyRFMCategories: boolean = false;

  optsSource = new BehaviorSubject<string>(null);
  optsSource$ = this.optsSource.asObservable();


  constructor(
    http: HttpClient,
    private translate: TranslateService
  ) {
    super(http);
  }

  setCategories() {
    this.optsSource.next('');
  }

  fetchMultiselect( searchValues?: string, page?: number, filters?: object ) {
    let requestOptions = {
      apiEndPoint: 'segment_categories',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    if (filters) {
      const filtering = Object.assign({}, requestOptions.filtering, filters);
      const filterObj = { filtering: filtering};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData(requestOptions).pipe(
      map(itemArray => {
        itemArray['list'].unshift({id: -1, name: this.translate.instant('resources.segments.messages.no_category')});

        if (this.onlyCategoriesWithSegments) {
          itemArray['list'] = itemArray['list'].filter(item => item.segment_count > 0);
        }

        if (this.onlyRFMCategories) {
          itemArray['list'] = itemArray['list'].filter(item => (item.name === 'Annual RFM' || item.name === 'System RFM'));
        }

        return itemArray;
      })
    );
  }

  fetchSelectedById(id: number) {
    return this.getSegmentCategoriesById(id);
  }

  getSegmentCategoriesById(id: number): Observable<any> {
    return this.getData({apiEndPoint: `segment_categories/${id}`});
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, `${element.name}`);
  }

  getInputs(formValues: any): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 1
      })
    ];

    Object.keys(formValues).map(
      key => {
        if (key === 'name') {
          inputsArray.find( input => input.key === 'name' ).value = formValues[key];
        }
    });

    this.inputs = inputsArray;
    return this.inputs;
  }

  sendData(creditsData) {
    return this.postResource(creditsData, 'segment_categories');
  }

  updateData(creditsData: any, _id: number) {
    return this.patchResource(creditsData, `segment_categories/${_id}`);
  }
}
