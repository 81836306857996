export const ANALYTICS_NAV_ROLES_NOT_AUTH = [
  'customer_service',
  'customer_service_mango',
  'customer_service_mango_limited',
  'franchise',
  'content_creation',
  'integration',
  'user_manager',
  'store_manager'
];

export const OFFERS_OMNI_NAV_ROLES_NOT_AUTH = [
  'customer_service',
  'customer_service_mango',
  'customer_service_mango_limited',
  'franchise',
  'analytics',
  'integration',
  'user_manager',
  'store_manager'
];

export const LOYALTY_AUDIENCES_NAV_ROLES_NOT_AUTH = [
  'customer_service',
  'customer_service_mango',
  'customer_service_mango_limited',
  'franchise',
  'content_creation',
  'analytics',
  'integration',
  'user_manager',
  'store_manager'
];

export const CDP_NAV_ROLES_NOT_AUTH = [
  'content_creation',
  'analytics',
  'user_manager'
];

export const CDP2_NAV_ROLES_NOT_AUTH = [
  'customer_service',
  'customer_service_mango',
  'customer_service_mango_limited',
  'franchise',
  'user_manager',
  'store_manager'
];

export const CONFIG_NAV_ROLES_NOT_AUTH = [
  'customer_service',
  'customer_service_mango',
  'customer_service_mango_limited',
  'franchise',
  'content_creation',
  'analytics',
  'store_manager'
];

export const USERS_STATUS_SYSTEM_ROLES_NOT_AUTH = [
  'customer_service',
  'customer_service_mango',
  'customer_service_mango_limited',
  'franchise',
  'content_creation',
  'analytics',
  'integration',
  'store_manager'
];

export const USERS_PERSONAL_DATA_AUTH = [
  'admin'
];

export const USERS_AUTH = [
  'admin',
  'owner',
  'user_manager'
];

export const ADMIN_DATA_AUTH = [
  'admin',
  'owner'
];
