import { Component, OnInit, OnDestroy } from '@angular/core';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { ModalStatusService } from '../../../../../shared/services/modal-status.service';
import { CashBackService } from '../cashback.service';
import { CountriesService } from '../../../../../shared/services/countries.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-form-cashback-jobs',
  templateUrl: './form-cashback.component.html',
  styleUrls: ['./form-cashback.component.css']
})

export class FormCashbackComponent implements OnInit, OnDestroy {

  cashbackInputs: QuestionBase<any>[];
  subs$: Subscription[] = [];
  cashbackForm: UntypedFormGroup;
  country: string;
  loading: boolean;
  currency: string;

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private cashbackSrv: CashBackService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private modalStatusService: ModalStatusService,
    private countriesService: CountriesService
  ) {}

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.cashbackInputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.cashbackForm, formKey, value);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }


  private getParams() {
    const route$ = this.route.params.subscribe(params => {
      if (params.hasOwnProperty('country')) {
        this.country = params.country;
        const deleteCashback$ = this.cashbackSrv.getElementByCountry(this.country ).subscribe(
          data => {
              this.cashbackInputs = this.cashbackSrv.getInputs(data);
              this.cashbackForm = this.qcs.toFormGroup(this.cashbackInputs);
              this.cashbackInputs.find(input => input.key === 'country').disabled = true;
          },
          errData => this.confirmationService.displayErrorAlert('Error', errData.error.error)
        );
        this.subs$.push(deleteCashback$);
      } else {
        this.cashbackInputs = this.cashbackSrv.getInputs({});
        this.cashbackForm = this.qcs.toFormGroup(this.cashbackInputs);
      }
    });
    this.subs$.push(route$);
  }

  private getPayload(): Object {
 
    const country = this.cashbackForm.value.country && this.cashbackForm.value.country.length > 0 ? this.cashbackForm.value.country[0].id : null;
    const score = this.cashbackForm.value.score;
    const money_representation = this.cashbackForm.value.money_representation;

    const payload = {
      country: country,
      score: parseFloat(score),
      money_representation: parseFloat(money_representation)
    };

    Object.keys(payload).forEach((key) => (payload[key] === null) && delete payload[key]);
    return payload;
  }


  onChangeCountry(country:unknown){

    if (!country){
      this.cashbackInputs.filter(e => e.key === "currency")[0].value = null;
      return;
    }
    
    this.loading = true;
    this.countriesService.getCountriesByCode(country['id']).subscribe(country => {
      this.currency = country.currency_iso_code;  
      this.cashbackInputs.filter(e => e.key === "currency")[0].value = country.currency_iso_code;
      this.loading = false;
    });
  }


  save() {
    this.loading = true;
    const payload = this.getPayload();
    const successTitle = this.translate.instant('resources.cashback_threshold.loyalty.form.success_title');
    
    const subscription = (this.country ? this.cashbackSrv.updateCashback(payload) : this.cashbackSrv.createCashback(payload))
      .subscribe(
        () => {
          subscription.unsubscribe();
          this.confirmationService.displaySuccessAlert(successTitle, "").catch(() => {});
          this.modalStatusService.modalStatus.emit();
          this.closeModal();
          this.loading = false;
        },
        errorData => {
          subscription.unsubscribe();
          this.confirmationService.displayErrorAlert('Error', errorData.error.error);
          this.loading = false;
        }
      );
    
    this.subs$.push(subscription);
  }
}
 