export class DataTableConfiguration {
  apiEndPoint?: string;
  columns: Array<any>;
  createButton?;
  isActive?: boolean;
  isMock?: boolean;
  mockConstant?: any;
  noElementsFoundCustomMessage?: string;
  page?: Object;
  renderRowCheckbox?: boolean;
  requestData: { [key: string]: any; };
  requiresCacheService?: boolean;
  rowActions: Array<any>;
  rows?: Array<any>;
  status?: string;
  tableActions: Array<any>;
  tableMapping: Array<Object>;
  title?: string;
  tokenPagination?: boolean;
  toolTipText?: { [key: string]: string; };
}

export class GetDataOptions {
  refreshRows?: boolean;
}
