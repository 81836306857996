<div class="modal-backdrop fade in modal-fs" id='modal-fs' tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header modal-border-bottom background-white" *ngIf="journey">
        <div>
          <h4 class="card-title no-mrg-btm">{{ journey.name }}</h4>
          <div class="row" *ngIf="form">
            <div class="col center-vertical">
              <label for="journey_history_id">
                <b>{{'resources.journeys.filters.history' | translate}}: </b>
              </label>
            </div>
            <div class="col histories-selector mrg-top-5">
              <app-question (multiselectChanged)="historyChangedHandler($event)"
                            [question]="inputs[0]"
                            [form]="form"></app-question>
            </div>
            <div class="col mrg-top-5">
              <button class="btn btn-info btn-icon btn-sm" (click)="searchHistory()">
                <i class="ei-search-alt font-size-14"></i>
                <span [translate]="'resources.journeys.buttons.filter'"></span>
              </button>
            </div>
          </div>

        </div>
        <!-- Close fullscreen modal btn -->
        <a class="modal-close" data-dismiss="modal" (click)="handleCloseModal()">
          <i class="ti-close"></i>
        </a>
      </div>

      <div class="modal-body height-100 background-gray">
        <div class="card background-gray no-border" *ngIf="journey">
          <!-- Tabs -->
          <div class="tab-info">
            <!-- Tabs content -->
            <div class="tab-content">
              <!-- General tab content -->
              <div class="tab-pane fade"
                    role="tabpanel"
                    id="tab-journeys-summary"
                    [ngClass]="{'in active' : journeyShowUrlContains('summary')}">

                <!-- Alerts content -->
                <div *ngIf="!journey._original && currentHistory" class="alert alert-success">
                  <ul>
                    <li>{{ 'resources.journey_histories.warnings.see_sent_on' | translate}}: <strong>{{journey.scheduled_at | date: 'dd/MM/yyyy'}}</strong></li>
                  </ul>
                </div>
                <div *ngIf="!journey._original && !journey.reportable" class="mrg-horizon-30 mrg-vertical-10 alert alert-warning">
                  <ul>
                    <li [translate]="'resources.journey_histories.warnings.no_reportable_for_roi'"></li>
                  </ul>
                </div>

                <!-- Potencial specs -->
                <div class="card card-block no-pdd-btm" *ngIf="journey.type === 'potential'">
                  <h4 class="card-title"[translate]="'resources.journeys.fields.summary'"></h4>
                  <div class="table-overflow">
                    <table class="table table-hover" aria-hidden="true">
                      <tbody>
                        <tr *ngIf="journey.pretty_configuration.best_sales_tag && journey.pretty_configuration.best_sales_tag.name">
                          <td class="label-summary">{{'resources.journeys.fields.best_sales_tag' | translate}}:</td>
                          <td>
                            <span class="text-primary">
                                {{journey.pretty_configuration.best_sales_tag.name}}
                            </span>
                          </td>
                        </tr>
                        <tr *ngIf="journey.pretty_configuration.location_terms && journey.pretty_configuration.location_terms.length">
                          <td class="label-summary">{{'resources.journeys.fields.location_taxonomy_terms' | translate}}:</td>
                          <td>
                            <a class="link text-primary"
                                  *ngFor="let term of journey.pretty_configuration.location_terms"
                                  [routerLink]="['', { outlets: { modal: ['show', 'location_taxonomy_terms', term.id] } }]">
                              {{term.name}}
                            </a>
                          </td>
                        </tr>
                        <tr *ngIf="journey.pretty_configuration.extra_segment">
                          <td class="label-summary">{{'resources.journeys.fields.extra_segment' | translate}}:</td>
                          <td>
                            <a class="link text-primary"
                                  [routerLink]="['', { outlets: { modal: ['show', 'segments', journey.pretty_configuration.extra_segment.id] } }]">
                              {{journey.pretty_configuration.extra_segment.name}}
                            </a>
                          </td>
                        </tr>
                        <tr *ngIf="journey.report_configuration.reward.prediction_percentage_redemption">
                          <td class="label-summary">{{'resources.journeys.fields.redemption_percentage' | translate}}:</td>
                          <td>
                            <span class="text-info">
                              {{ journey.report_configuration.reward.prediction_percentage_redemption }} %
                            </span>
                          </td>
                        </tr>

                        <tr *ngIf="journey.report_configuration.reward.prediction_coupon_discount">
                          <td class="label-summary">{{'resources.journeys.fields.prediction_coupon_discount' | translate}}:</td>
                          <td>
                            <span class="text-info">
                              {{ journey.report_configuration.reward.prediction_coupon_discount | lgcurrency: '1.2-2'}}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- Recuperation specs -->
                <div class="card card-block no-pdd-btm" *ngIf="journey.type === 'recuperation'">
                  <h4 class="card-title"[translate]="'resources.journeys.fields.summary'"></h4>
                  <div class="table-overflow">
                    <table class="table table-hover" aria-hidden="true">
                      <tbody>
                        <tr *ngIf="journey.pretty_configuration.activity_tag && journey.pretty_configuration.activity_tag.name">
                          <td class="label-summary">{{'resources.journeys.fields.activity_tag' | translate}}:</td>
                          <td>
                            <span class="text-info">
                              {{journey.pretty_configuration.activity_tag.name}}
                            </span>
                          </td>
                        </tr>
                        <tr *ngIf="journey.pretty_configuration.best_sales_tags">
                          <td class="label-summary">{{'resources.journeys.fields.best_sales_tag' | translate}}:</td>
                          <td>
                            <span class="text-primary"
                                  *ngFor="let tag of journey.pretty_configuration.best_sales_tags">
                                {{tag.name}}
                            </span>
                          </td>
                        </tr>
                        <tr *ngIf="journey.pretty_configuration.location_terms && journey.pretty_configuration.location_terms">
                          <td class="label-summary">{{'resources.journeys.fields.location_taxonomy_terms' | translate}}:</td>
                          <td>
                            <a class="link text-primary"
                                  *ngFor="let term of journey.pretty_configuration.location_terms"
                                  [routerLink]="['', { outlets: { modal: ['show', 'location_taxonomy_terms', term.id] } }]">
                              {{term.name}}
                            </a>
                          </td>
                        </tr>
                        <tr *ngIf="journey.pretty_configuration.extra_segment">
                          <td class="label-summary">{{'resources.journeys.fields.extra_segment' | translate}}:</td>
                          <td>
                            <a class="link text-primary"
                                  [routerLink]="['', { outlets: { modal: ['show', 'segments', journey.pretty_configuration.extra_segment.id] } }]">
                              {{journey.pretty_configuration.extra_segment.name}}
                            </a>
                          </td>
                        </tr>
                        <tr *ngIf="journey.pretty_configuration.message">
                          <td class="label-summary">{{'resources.journeys.fields.message' | translate}}:</td>
                          <td>
                            <span class="text-info">
                              {{journey.pretty_configuration.message}}
                            </span>
                          </td>
                        </tr>
                        <tr *ngIf="journey.report_configuration.reward.prediction_percentage_redemption">
                          <td class="label-summary">{{'resources.journeys.fields.low_prize' | translate}}:</td>
                          <td>
                            <span class="text-info">
                              {{ journey.report_configuration.reward.prediction_percentage_redemption }} %
                            </span>
                          </td>
                        </tr>
                        <tr *ngIf="journey.report_configuration.reward.prediction_coupon_discount">
                          <td class="label-summary">{{'resources.journeys.fields.low_prize' | translate}}:</td>
                          <td>
                            <span class="text-info">
                              {{ journey.report_configuration.reward.prediction_coupon_discount}} %
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- MultiCoupon specs -->
                <div class="card card-block no-pdd-btm" *ngIf="journey.type === 'multicoupon'">
                  <h4 class="card-title"[translate]="'resources.journeys.fields.summary'"></h4>
                  <div class="table-overflow">
                    <table class="table table-hover" aria-hidden="true">
                      <tbody>
                        <tr *ngIf="journey.pretty_configuration.extra_segment">
                          <td class="label-summary">{{'resources.journeys.fields.segment' | translate}}:</td>
                          <td>
                            <a class="link text-primary"
                                  [routerLink]="['', { outlets: { modal: ['show', 'segments', journey.pretty_configuration.extra_segment.id] } }]">
                              {{journey.pretty_configuration.extra_segment.name}}
                            </a>
                          </td>
                        </tr>
                        <tr *ngIf="journey.configuration.coupon_id">
                          <td class="label-summary">{{'resources.journeys.fields.coupon_id' | translate}}:</td>
                          <td>
                            <a class="link text-primary"
                                  [routerLink]="['', { outlets: { modal: ['show', 'coupons', journey.configuration.coupon_id[0] ]}}]">
                                {{journey.configuration.coupon_id}}
                            </a>
                          </td>
                        </tr>
                        <tr *ngIf="journey.configuration.via.length">
                          <td class="label-summary">{{'resources.journeys.fields.channels' | translate}}:</td>
                          <td>
                            <span class="text-info"
                                  *ngFor="let channel of journey.configuration.via">
                                {{channel.name}}
                            </span>
                          </td>
                        </tr>
                        <tr *ngIf="journey.report_configuration.reward.prediction_percentage_redemption">
                          <td class="label-summary">{{'resources.journeys.fields.estimated_redemption_percentage' | translate}}:</td>
                          <td>
                            <span class="text-info">
                              {{ journey.report_configuration.reward.prediction_percentage_redemption }} %
                            </span>
                          </td>
                        </tr>
                        <tr *ngIf="journey.report_configuration.reward.prediction_coupon_discount">
                          <td class="label-summary">{{'resources.journeys.fields.low_prize' | translate}}:</td>
                          <td>
                            <span class="text-info">
                              {{ journey.report_configuration.reward.prediction_coupon_discount}} %
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- Custom specs -->
                <div class="card card-block no-pdd-btm" *ngIf="journey._original && journey.type === 'custom'">
                  <h4 class="card-title"[translate]="'resources.journeys.fields.summary'"></h4>
                  <div class="table-overflow">
                    <table class="table table-hover" aria-hidden="true">
                      <tbody>
                        <tr *ngIf="journey.report_configuration.reward.prediction_percentage_redemption">
                          <td class="label-summary">{{'resources.journeys.fields.redemption_percentage' | translate}}:</td>
                          <td>
                            <span class="text-info">
                              {{ journey.report_configuration.reward.prediction_percentage_redemption }} %
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="card card-block no-pdd-top">
                  <h4 class="card-title mrg-top-15"[translate]="'resources.journeys.fields.steps'"></h4>
                  <div class="table-overflow">
                    <div *ngIf="journey.showableSteps().length == 0">
                      <table id="dt-opt" class="table table-lg table-hover dataTable no-footer mrg-top-20" role="grid" aria-describedby="dt-opt_info" aria-hidden="true">
                        <tbody>
                          <tr class="odd">
                            <td style="vertical-align: top;"
                                colspan="7"
                                class="dataTables_empty text-semibold text-center"
                                [translate]="'components.data-table.messages.no_elements_found'"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <table *ngIf="journey.showableSteps().length > 0" class="table table-hover" aria-hidden="true">
                      <thead></thead>
                      <tbody>
                        <tr *ngFor="let step of journey.showableSteps() | orderBy: 'position'; let i = index;">
                          <td>{{ step.name }}</td>
                          <td>
                            <ng-container *ngIf="!journey._original">
                              {{step.sent_at || step.scheduled_at | dateTimeZone: 'DD/MM/YYYY HH:mm'}}
                            </ng-container>
                          </td>
                          <td>
                            <span *ngIf="step.status"
                                  class="font-size-12 label"
                                  [ngClass]="step.getStepLabelClass()"
                                  [translate]="'resources.journey_steps.status.' + step.status"></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <button *ngIf="!journey._original && (journey.status === 'paused' || journey.status === 'cancelled' || journey.status === 'error')"
                          data-toggle="modal"
                          data-target="#confirmation-modal"
                          class="btn btn-info fit-content"
                          (click)="sendInfo()">
                    <span [translate]="'resources.journeys.buttons.resume'"></span>
                  </button>
                </div>

                <div class="card card-block no-pdd-top">
                  <h4 class="card-title no-mrg-btm mrg-top-15"[translate]="'resources.journeys.fields.histories'"></h4>
                  <hr class="no-mrg-top">
                  <app-data-table #historiesTable [config]="historiesTableConfig"></app-data-table>
                </div>
              </div>

              <!-- TODO: Future task, c. journey statistics (only for journeys with histories) -->

            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer background-white">
        <button *ngIf="journey && journey._original"
                class="btn btn-info pull-right no-margin-right"
                (click)="editJourney()">
          <span [translate]="'resources.journeys.buttons.edit'"></span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Steps information/confirm modals -->
<div class="modal fade" id="confirmation-modal" *ngIf="journey">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 [translate]="'resources.journeys.buttons.confirm_delivery'"></h4>
      </div>
      <div class="modal-body"
            *ngIf="stepsToDeliver && (stepsToDeliver['now'] || stepsToDeliver['scheduled'])">
        <div *ngIf="stepsToDeliver['now'].length > 0">
          <h5>{{stepsToDeliver['now'].length}} {{'resources.journeys.warnings.steps_to_deliver' | translate}}:</h5>
          <ul class="mrg-vertical-10">
            <li *ngFor="let step of stepsToDeliver['now']">
              <b>{{step.name}}</b>
            </li>
          </ul>
        </div>
        <div *ngIf="stepsToDeliver['scheduled'].length > 0">
          <h5>{{stepsToDeliver['scheduled'].length}} {{'resources.journeys.warnings.steps_to_schedule' | translate}}:</h5>
          <ul class="mrg-vertical-10">
            <li *ngFor="let step of stepsToDeliver['scheduled']">
              <b>{{step.date}}:</b><br>{{step.name}}
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer no-border">
        <div class="text-right">
          <button class="btn btn-info"
                  data-dismiss="modal"
                  (click)="triggerJourneyDelivery()"
                  [translate]="'resources.journeys.buttons.accept'"></button>
          <button class="btn btn-default"
                  data-dismiss="modal"
                  [translate]="'resources.journeys.buttons.cancel'"></button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end confirmation modal -->
