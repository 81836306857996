import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableConfiguration } from '../../../shared/components/data-table/data-table-cfg';
import { BigqueryjobsService } from './bigqueryjobs.service';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { Subject, takeUntil } from 'rxjs';
import { ModalStatusService } from '../../../shared/services/modal-status.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfileService } from '../../../profiles/profile.service';

@Component({
  selector: 'app-tab-jobs',
  templateUrl: './tab-jobs.component.html'
})

export class TabJobsComponent implements OnInit, OnDestroy {

  dataTableConfig: DataTableConfiguration;
  currentRole = this.profileService.getStoredUserRole();

  private readonly denialButton = this.translate.instant('resources.jobs.buttons.denial');
  private destroy$: Subject<void> = new Subject<void>();

  private get isIntegrationRole(): boolean {
    return this.currentRole === 'integration';
  }
  constructor(
    private translate: TranslateService,
    private profileService: ProfileService,
    private bigqueryJobsSrv: BigqueryjobsService,
    private confirmationService: ConfirmationService,
    private modalStatusSrv: ModalStatusService
  ) {}

  ngOnInit(): void {
    this.setDataTableConfig();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setDataTableConfig(): void {
    this.dataTableConfig = {
      isActive: true,
      createButton: {
        label: this.translate.instant('resources.jobs.buttons.jobs'),
        redirectTo: [{outlets: { modal: 'new/bigquery_jobs' }}]
      },
      rows: [],
      rowActions: [
        {
          name: this.translate.instant('resources.jobs.row_actions.details'),
          icon: 'eye',
          id: 'details',
        },
        {
          name: this.translate.instant('resources.jobs.row_actions.edit'),
          icon: 'pencil',
          id: 'edit',
          disabled: (element: {slug: string}) => element.slug === 'exports'
        },
        {
          name: this.translate.instant('common.buttons.delete'),
          icon: 'trash',
          id: 'delete',
          show: () => !this.isIntegrationRole,
          disabled: (element) => element.status === 'processing',
          callback: (element) => this.deleteScheduledTask(element.id)
        }
      ],
      tableActions: [
        {
          name: this.translate.instant('components.table_actions.export_csv'),
          icon: 'ei-export',
          id: 'exportCSV'
        },
        {
          name: this.translate.instant('resources.jobs.buttons.create_daily_pumps'),
          id: 'diary_pump',
          onClick: () => this.createQuickTask({ type: 'pumps' })
        },
        {
          name: this.translate.instant('resources.jobs.buttons.create_hourly_appends'),
          id: 'hourly_appends',
          onClick: () => this.createQuickTask({ recurrence: 'hourly', type: 'appends' })
        },
        {
          name: this.translate.instant('resources.jobs.buttons.create_daily_appends'),
          id: 'diary_appends',
          onClick: () => this.createQuickTask({ recurrence: 'daily', type: 'appends' })
        },
        {
          name: this.translate.instant('resources.jobs.buttons.create_every_15days_appends'),
          id: '15_days_appends',
          onClick: () => this.createQuickTask({ recurrence: 'fortnightly', type: 'appends' })
        }
      ],
      columns: [
        { name: this.translate.instant('resources.jobs.columns.name'), prop: 'name' },
        { name: this.translate.instant('resources.jobs.columns.last_execution'), prop: 'last_execution' },
        { name: this.translate.instant('resources.jobs.columns.status'), prop: 'status' },
        { name: this.translate.instant('resources.jobs.columns.execution'), prop: 'execution' },
        { name: this.translate.instant('resources.jobs.columns.execution_hour'), prop: 'execution_hour' },
        { name: this.translate.instant('resources.jobs.columns.average_duration'), prop: 'average_duration' }
      ],
      requestData: {
        translationResource: 'resources.jobs.columns.',
        apiEndPoint: 'bigquery_jobs',
        pageNumber: 0,
        sorting: {
          byProp: null,
          direction: null
        },
        filtering: {},
        numberPerPage: 10
      },
      tableMapping: [
        { prop: 'id', type: 'number', apiProp: 'id' },
        { prop: 'name', type: 'number', apiProp: 'name' },
        { prop: 'last_execution', type: 'date_time', apiProp: 'last_execution' },
        { prop: 'status', type: 'badge', apiProp: 'status', getValue: (element) => element.status ?? 'pending', badge: { dictionaryKey: 'jobs_status' } },
        { prop: 'execution', type: 'text', apiProp: 'execution', translatable_key: 'execution_jobs' },
        { prop: 'execution_hour', type: 'text', apiProp: 'execution_hour' },
        { prop: 'average_duration', type: 'date_duration', apiProp: 'average_duration' }
      ]
    };
  }

  private createQuickTask(job: object): void {
    const confirmationTitle = this.translate.instant('resources.jobs.messages.create_confirmation');
    const confirmationButton = this.translate.instant('resources.jobs.buttons.create_confirmation');
    this.confirmationService.displayConfirmationAlertWithButtons(confirmationTitle, '', confirmationButton, this.denialButton, 'question').then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.bigqueryJobsSrv.createMainJob(job).pipe(takeUntil(this.destroy$)).subscribe({
          next: () => {
            const confirmationSuccessTitle = this.translate.instant('resources.jobs.messages.create_title');
            const confirmationSuccessText = this.translate.instant('resources.jobs.messages.create_desc');
            this.confirmationService.displaySuccessAlert(confirmationSuccessTitle, confirmationSuccessText).catch(() => {});
            this.modalStatusSrv.modalStatus.emit();
          },
          error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
        });
      }
    }).catch(() => {});
  }

  private deleteScheduledTask(id: number): void {
    const confirmationTitle = this.translate.instant('resources.jobs.messages.delete_confirmation');
    const confirmationButton = this.translate.instant('resources.jobs.buttons.delete_confirmation');
    this.confirmationService.displayConfirmationAlertWithButtons(confirmationTitle, '', confirmationButton, this.denialButton, 'question').then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.bigqueryJobsSrv.deleteScheduledTask(id).pipe(takeUntil(this.destroy$)).subscribe(
          () => {
            this.confirmationService.displaySuccessAlert(this.translate.instant('resources.jobs.messages.delete_confirmation_success'), '').catch(() => {});
            this.modalStatusSrv.modalStatus.emit();
          },
          (errorData: HttpErrorResponse) => {
            this.confirmationService.displayHttpErrorAlert(errorData);
          }
        );
      }
    }).catch(() => {});
  }

}
