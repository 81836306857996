import { Role } from '../../profiles/roles.service';
import * as moment from 'moment';
import { isNullOrUndefined } from '../utils/common.utils';
import { Location } from './locations/location';
import { CompanyConfigurations } from './company/company-configurations';

export class PlansConfiguration {
  principalCategorization: string;
  roiDeliveryPercentage: number;
  supplierTaxonomy: string;
  constructor(jsonConfig) {
    this.principalCategorization = jsonConfig?.['principal_categorization'] ?? null;
    this.roiDeliveryPercentage = jsonConfig?.['roi_delivery_percentage'] ?? null;
    this.supplierTaxonomy = jsonConfig?.['supplier_taxonomy'] ?? null;
  }
}

export class AsyncAction {

  startedAt: string;
  description: string;
  fromNow: string;
  timestamp: number;

  constructor(jsonConfig) {
    this.startedAt = moment.unix(jsonConfig['started_at'] / 1000).format('DD/MM/YYYY hh:mm');
    this.description = jsonConfig['description'];
    this.fromNow = moment(jsonConfig['started_at']).fromNow();
    this.timestamp = parseInt(jsonConfig['started_at'], 10);
  }
}

export class SystemProfileGroups {

  age: number[][];
  familyNumber: number[][];
  scoreAvailable: number[][];
  semaphores: number[][];
  customerTypes: object[];
  customerTypesTaxonomySlug: string;
  systemSegments: {
    activityCuts: number[],
    salesCuts: number[][],
    restrictiveSegment: number
  };

  constructor(jsonConfig: object) {
    this.age = this.retrieveValueFromObjectKey(jsonConfig, 'age');
    this.familyNumber = this.retrieveValueFromObjectKey(jsonConfig, 'family_number');
    this.scoreAvailable = this.retrieveValueFromObjectKey(jsonConfig, 'score_available');
    this.semaphores = this.retrieveValueFromObjectKey(jsonConfig, 'semaphores');
    this.customerTypes = this.retrieveValueFromObjectKey(jsonConfig, 'customer_types');
    this.customerTypesTaxonomySlug = this.retrieveValueFromObjectKey(jsonConfig, 'customer_types_taxonomy_slug', null);
    const systemSegments = this.retrieveValueFromObjectKey(jsonConfig, 'system_segments', {});
    const activityCuts = this.retrieveValueFromObjectKey(systemSegments, 'activity');
    const salesCuts = this.retrieveValueFromObjectKey(systemSegments, 'sales');
    this.systemSegments = {
      activityCuts: activityCuts ? activityCuts : [],
      salesCuts: salesCuts ? salesCuts : [],
      restrictiveSegment: systemSegments?.restrictive_segment_id ?? null
    };
  }

  toPayload(): object {
    return {
      age: this.age,
      family_number: this.familyNumber,
      score_available: this.scoreAvailable,
      semaphores: this.semaphores,
      customer_types: this.customerTypes,
      customer_types_taxonomy_slug: this.customerTypesTaxonomySlug,
      system_segments: {
        activity: this.systemSegments.activityCuts,
        sales: this.systemSegments.salesCuts,
        restrictive_segment_id: this.systemSegments.restrictiveSegment
      }
    };
  }

  private retrieveValueFromObjectKey(cfg: object, keyValue: string, defaultValue?: any) {
    if(isNullOrUndefined(cfg) || Object.keys(cfg).length === 0){ return defaultValue ? defaultValue : [] };
    const retrievedValue = cfg[keyValue];
    if (!isNullOrUndefined(retrievedValue)) {
      return retrievedValue;
    }
  }
}

export class GDPRFields {
  profiling: boolean;
  communications: boolean;

  constructor(jsonConfig: object) {
    this.profiling = !!jsonConfig['profiling'];
    this.communications = !!jsonConfig['communications'];
  }
}

export class GDPRConfiguration {
  strictCommunications: boolean;
  showSmsUnsubscriptionLink: boolean;
  dataRetentionPeriod: number;

  constructor(jsonConfig: object) {
    this.strictCommunications = !!jsonConfig['strict_communications'];
    this.showSmsUnsubscriptionLink = !!jsonConfig['show_sms_unsubscription_link'];
    this.dataRetentionPeriod = jsonConfig['data_retention_period'] ?? null;
  }
}

export interface ModelConfiguration {
  static_code?: boolean;
}

export class CurrentCompany {

  id: number;
  name: string;
  slug: string;
  actionsRunning: any[];
  actionsWaiting: any[];
  availableLanguages: string[];
  email: string;
  alertsEmail: string;
  logo: string;
  locale: string;
  activeSync: boolean;
  systemProfileGroups: SystemProfileGroups;
  plansConfiguration: PlansConfiguration;
  controlGroupRatio: number;
  systemSegmentCategories: object;
  featureMainTaxonomy: string;
  strictProfiling: boolean;
  last_period_number_done: number;
  haveControlGroup: boolean;
  currency: string;
  unifiedCustomers: boolean;
  purchasedModules: string[];
  scheduledReportsCfg = {
    audiences: false,
    vouchers: false
  };
  gdprFields: GDPRFields;
  gdprConfiguration: GDPRConfiguration;
  modelConfiguration: ModelConfiguration;
  companyConfigurations: CompanyConfigurations;
  timezone: string;
  thumbnail: string;
  domains: string[];
  hasExternalApp: boolean;

  constructor(jsonConfig) {

    this.actionsRunning = [];
    if (jsonConfig['actions_running']) {
      const actionsRunning = jsonConfig['actions_running'].map( rawActionCfg => new AsyncAction(rawActionCfg) );
      this.actionsRunning = this.sortActionsByTimestamp( actionsRunning );
    }

    this.actionsWaiting = [];
    if (jsonConfig['actions_waiting']) {
      const actionsWaiting = jsonConfig['actions_waiting'].map( rawActionCfg => new AsyncAction(rawActionCfg));
      this.actionsWaiting = this.sortActionsByTimestamp( actionsWaiting );
    }

    this.availableLanguages = jsonConfig['available_languages'];
    this.id = parseInt(jsonConfig['id'], 10);
    this.name = jsonConfig['name'];
    this.slug = jsonConfig['slug'];
    this.logo = jsonConfig['thumbnail'];
    this.locale = jsonConfig['locale'];
    this.email = jsonConfig['email'];
    this.alertsEmail = jsonConfig['alerts_email'];
    this.availableLanguages = jsonConfig['available_languages'];
    this.activeSync = jsonConfig['synchro_active'];
    this.systemProfileGroups = new SystemProfileGroups(jsonConfig['system_profile_groups']);
    this.plansConfiguration = new PlansConfiguration(jsonConfig['plans_configuration']);
    this.controlGroupRatio = jsonConfig['control_group_ratio'];
    this.systemSegmentCategories = jsonConfig['system_segment_categories'];
    this.featureMainTaxonomy = jsonConfig['feature_taxonomy_main'];
    this.strictProfiling = jsonConfig['strict_profiling'] || false;
    this.last_period_number_done = jsonConfig['last_period_number_done'];
    this.haveControlGroup = jsonConfig['control_group_ratio'] > 0 ? true : false;
    this.currency = jsonConfig['currency'] ? jsonConfig['currency'] : '€';
    this.unifiedCustomers = jsonConfig['unified_customers'] || false;
    this.purchasedModules = jsonConfig['purchased_modules'] || [];
    this.scheduledReportsCfg.audiences = jsonConfig['export_configuration'] && jsonConfig['export_configuration']['audiences'] ? jsonConfig['export_configuration']['audiences'] : false;
    this.scheduledReportsCfg.vouchers = jsonConfig['export_configuration'] && jsonConfig['export_configuration']['vouchers'] ? jsonConfig['export_configuration']['vouchers'] : false;
    this.gdprFields = jsonConfig['gdpr_fields'] ? new GDPRFields(jsonConfig['gdpr_fields']) : null;
    this.gdprConfiguration = jsonConfig['gdpr_configuration'] ? new GDPRConfiguration(jsonConfig['gdpr_configuration']) : null;
    this.modelConfiguration = jsonConfig['model_configuration'];
    this.companyConfigurations = jsonConfig['company_configurations'] ? new CompanyConfigurations(jsonConfig['company_configurations']) : null;
    this.timezone = jsonConfig['time_zone'];
    this.thumbnail = jsonConfig['thumbnail'];
    this.domains = jsonConfig['domains'];
    this.hasExternalApp = this.companyConfigurations?.pushRedirections?.length > 0;
  }

  isTagged(): boolean {
    return this.last_period_number_done > 0;
  }

  isAccountsIdentifierByCode(): boolean {
    return this.companyConfigurations?.accountsIdentifier === "code";
  }

  private sortActionsByTimestamp(actions: AsyncAction[]): AsyncAction[] {
    return actions.sort(( actionA, actionB ) => {
      if ( actionA.timestamp < actionB.timestamp ) { return 1; }
      if ( actionA.timestamp > actionB.timestamp ) { return -1; }
      return 0;
    });
  }
}

export class CurrentUser {

  company: CurrentCompany;
  companyId: number;
  companyLanguage?: string;
  companyLogoUrl?: string;
  email: string;
  fullName: string;
  id: string;
  locations: Location[];
  name: string;
  role: Role;
  surname1?: string;
  surname2?: string;
  userPreferredLanguage: string;

  constructor(jsonConfig) {
    this.company = new CurrentCompany(jsonConfig['company']);
    this.companyLanguage = this.company.locale.substr(0, 2);
    this.email = jsonConfig['email'];
    this.id = jsonConfig['id'];
    this.locations = jsonConfig['locations']?.length ? this.getCurrentUserLocations(jsonConfig['locations']) : [];
    this.name = jsonConfig['name'];
    this.role = new Role(jsonConfig['role']);
    this.surname1 = jsonConfig['surname_1'];
    this.surname2 = jsonConfig['surname_2'];
    this.userPreferredLanguage = jsonConfig['preferred_language'];
    this.fullName = this.getfullName();
  }

  getfullName(): string {
    return `${this.name} ${this.surname1}`;
  }

  private getCurrentUserLocations(locations: object[]): Location[] {
    return locations.map(location => new Location(location));
  }
}