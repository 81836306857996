import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

export interface MultiselectDataSourceable {
  keyListName?: string;
  optsSource$?: Observable<any>;
  optsSource?: BehaviorSubject<any>;
  treatPkAsId?: boolean;
  fetchByBatch?: boolean;
  selectedOptsSource$?: Observable<any>;
  selectedOptsSource?: BehaviorSubject<any>;
  refreshSelectedOptsSource?(ids: string[], key: string): void;
  fetchMultiselect(searchValues?: string, page?: number, filters?: object, useToken?: boolean, opts?: object): Observable<object>;
  getNameWithTemplate(item: any, id?: string): MultiselectDataSource;
  fetchSelected?(ids: string[]): Observable<MultiselectDataSource[]>;
  fetchSelectedById?(id: number | string): Observable<object>;
  fetchSelectedByBatch?(ids): Observable<object>;
}

export class MultiselectDataSource {

  public id: string;
  public name: string;
  public rawElement?: any;

  constructor(id: string, name: string, rawElement?: any) {
    this.id = id;
    this.name = name;
    this.rawElement = rawElement;
  }
}

export interface BasicMultiselectCfg {
  singleSelection:         boolean;
  enableCheckAll:          boolean;
  showCheckbox:            boolean;
  enableSearchFilter:      boolean;
}
