import { Campaign } from '../../shared/models/campaign/campaign';
import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DictionaryService } from '../../shared/services/dictionary.service';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../shared/components/multiselect/multiselect';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DateTimeZoneService } from '../../shared/services/date-time-zone.service';
import { FeatureFlagsService } from '../../shared/services/feature-flags.service';
import { CampaignPayload } from '../../shared/models/campaign/campaign-payload';
import { CurrentCompany } from '../../shared/models/current-user';
import { ProfileService } from '../../profiles/profile.service';

@Injectable()
export class CampaignsService extends ResourcesService implements MultiselectDataSourceable {

  date_from: string;
  date_to: string;
  datesAreRequired = false;
  flags = this.featureFlags.flags;
  optsSource = new BehaviorSubject<{dateFrom?: string, dateTo?: string}>(null);
  optsSource$ = this.optsSource.asObservable();

  constructor(
    http: HttpClient,
    private translate: TranslateService,
    private dictionaryService: DictionaryService,
    private dateService: DateTimeZoneService,
    private featureFlags: FeatureFlagsService,
    private profileService: ProfileService
  ) {
    super(http);
  }

  getCategories() {
    const requestOptions = {
      apiEndPoint: 'campaign_categories',
      sorting: {},
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  getChannels() {
    return [
      {id: null, name: this.translate.instant('resources.campaigns.channels.all')},
      {id: 'email', name: this.translate.instant('resources.campaigns.channels.email')},
      {id: 'sms', name: this.translate.instant('resources.campaigns.channels.sms')},
      {id: 'none', name: this.translate.instant('resources.campaigns.channels.ticket_coupon')},
      {id: 'push', name: this.translate.instant('resources.campaigns.channels.push')},
      {id: 'ticket_message', name: this.translate.instant('resources.campaigns.channels.ticket_message')}
    ];
  }

  getExecutionPeriods() {
    return [
      {id: 'daily', name: this.translate.instant('resources.campaigns.execution_periods.daily')},
      {id: 'weekly', name: this.translate.instant('resources.campaigns.execution_periods.weekly')},
      {id: 'monthly', name: this.translate.instant('resources.campaigns.execution_periods.monthly')},
    ];
  }

  getCampaignTemplates() {
    return this.getData({ apiEndPoint: 'campaign_templates', filtering: { via: 'email'} });
  }

  getCampaignTemplateById(campaign_template_id: number) {
    return this.getData({ apiEndPoint: `campaign_templates/${campaign_template_id}` });
  }

  getCampaignCreationChannels() {
    return [
      {id: 'email', name: this.translate.instant('resources.campaigns.channels.email')},
      {id: 'sms', name: this.translate.instant('resources.campaigns.channels.sms')},
      {id: 'push', name: this.translate.instant('resources.campaigns.channels.push')},
      {id: 'none', name: this.translate.instant('resources.campaigns.channels.ticket_coupon')},
      {id: 'ticket_message', name: this.translate.instant('resources.campaigns.channels.ticket_message')}
    ];
  }

  getRedirectOptions() {
    return [
      { id: 'none', name: this.translate.instant('resources.campaigns.redirectOptions.none') },
      { id: 'app', name: this.translate.instant('resources.campaigns.redirectOptions.app') },
      { id: 'url', name: this.translate.instant('resources.campaigns.redirectOptions.url') }
    ];
  }

  getRedirectStates() {
    return [
      { id: 'tab.account', name: this.translate.instant('resources.campaigns.redirectStates.account') },
      { id: 'tab.activities', name: this.translate.instant('resources.campaigns.redirectStates.activities') },
      { id: 'tab.rewards', name: this.translate.instant('resources.campaigns.redirectStates.rewards') },
      { id: 'tab.vouchers.available', name: this.translate.instant('resources.campaigns.redirectStates.tabvouchers_available') },
      { id: 'tab.news', name: this.translate.instant('resources.campaigns.redirectStates.news') },
      { id: 'tab.locations', name: this.translate.instant('resources.campaigns.redirectStates.locations') },
      { id: 'tab.challenges', name: this.translate.instant('resources.campaigns.redirectStates.challenges') },
      { id: 'tab.news-show', name: this.translate.instant('resources.campaigns.redirectStates.news_show') },
    ];
  }

  getExternalRedirectStates() {
    return new CurrentCompany(this.profileService.getProfileCompany()).companyConfigurations.pushRedirections;
  }

  getCampaignById(id) {
    return this.getData({ apiEndPoint: `campaigns/${id}` });
  }

  getCampaignStatusById(id) {
    return this.getData({ apiEndPoint: `campaigns/${id}/status` });
  }

  getCampaignStatus() {
    return this.getData({ apiEndPoint: `campaigns/status` });
  }

  getCampaignHistoriesById(id) {
    return this.getData({
      apiEndPoint: `campaigns/${id}/histories`
    });
  }

  getCampaignHistoryByIdAndCampaignId(id, campaignId) {
    return this.getData({
      apiEndPoint: `campaigns/${campaignId}/histories/${id}`
    });
  }

  getCampaignStatsByIdAndApply(id, apply) {
    return this.getData({
      apiEndPoint: `analytics/campaign_stats?apply=${apply}&campaign_id=${id}`
    });
  }

  getCampaignSenderById(id) {
    return this.getData({
      apiEndPoint: `campaigns/senders?id=${id}`
    });
  }

  getCampaignTypeObject(element) {
    let iconObj;
    if (element.via) {
      iconObj = this.dictionaryService.getObjectByKeyAndId('campaigns_vias', element.via );
        return {
        'name': iconObj['name'],
        'icon': iconObj['icon'],
        'parsedValue': element.via,
        'resourceIcon': ['show', 'campaigns_content', element.id]
      };
    } else if (element._embedded.campaign_info.via) {
      iconObj = this.dictionaryService.getObjectByKeyAndId('campaigns_vias', element._embedded.campaign_info.via );
        return {
        'name': iconObj['name'],
        'icon': iconObj['icon'],
        'parsedValue': element._embedded.campaign.via,
        'resourceIcon': ['show', 'campaigns_content', element.id, 'histories', element._embedded.campaign.id]
      };
    } else {
      iconObj = this.dictionaryService.getObjectByKeyAndId('campaigns_vias', element._embedded.campaign.via );
        return {
        'name': iconObj['name'],
        'icon': iconObj['icon'],
        'parsedValue': element._embedded.campaign.via,
        'resourceIcon': ['show', 'campaigns_content', element.id]
      };
    }
  }

  fetchMultiselect( searchValues?: string, page?: number ) {
    let requestOptions = { apiEndPoint: 'campaigns', pageNumber: 1, filtering: {} };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues, id: searchValues, filter_logic: 'or' } };
      requestOptions = {...requestOptions, ...filterObj};
    }

    if (this.datesAreRequired) {
      const {date_from, date_to} = this;
      if (date_from && date_to) {
        requestOptions.filtering = { ...requestOptions.filtering, ...{status: 'sent', date_from: date_from, date_to: date_to}};
      } else {
        return of({});
      }
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `campaigns?id=${id}&order_field=id&order_sense=asc` });
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    const sentAt = element.sent_at ? `(${this.dateService.parseDateWithFormat(element.sent_at, 'DD/MM/YYYY')}) ` : '(-) ';
    return new MultiselectDataSource(element.id, `(${element.id}) ${sentAt}${element.name}`, element);
  }

  getCampaignMotives(): {id: string, name: string}[] {
    const list = [
      { id: 'informative', name: this.translate.instant('resources.campaigns.motive.informative') },
      { id: 'coupon', name: this.translate.instant('resources.campaigns.motive.coupon') },
      { id: 'reminder', name: this.translate.instant('resources.campaigns.motive.reminder') }
    ];
    if(this.flags.showMembershipOption) {
      list.push({ id: 'membership', name: this.translate.instant('resources.campaigns.motive.membership') });
    }
    return list;
  }

  getCouponVariables(): any[] {
    const list = [
      {id: '{name}', name: this.translate.instant('resources.campaigns.content_variables.name') },
      {id: '{surname_1}', name: this.translate.instant('resources.campaigns.content_variables.surname_1') },
      {id: '{surname_2}', name: this.translate.instant('resources.campaigns.content_variables.surname_2') },
      {id: '{phone}', name: this.translate.instant('resources.campaigns.content_variables.mobile_phone') },
      {id: '{email}', name: this.translate.instant('resources.campaigns.content_variables.email') },
      {id: '{code}', name: this.translate.instant('resources.campaigns.content_variables.client_code') },
      {id: '{couponCode}', name: this.translate.instant('resources.campaigns.content_variables.coupon_code') },
      {id: '{score}', name: this.translate.instant('resources.campaigns.content_variables.points') },
      {id: '{shop}', name: this.translate.instant('resources.campaigns.content_variables.usual_store') },
      {id: '{RegistrationLocationName}', name: this.translate.instant('resources.campaigns.content_variables.registration_store_name') },
      {id: '{address}', name: this.translate.instant('resources.campaigns.content_variables.address') },
      {id: '{city}', name: this.translate.instant('resources.campaigns.content_variables.city') },
      {id: '{postal_code}', name: this.translate.instant('resources.campaigns.content_variables.postal_code') },
      {id: '{CouponAvailableTo}', name: this.translate.instant('resources.campaigns.content_variables.coupon_available_to') },
      {id: '{CouponAvailableFrom}', name: this.translate.instant('resources.campaigns.content_variables.coupon_available_from') },
      {id: '{GDPR}', name: this.translate.instant('resources.campaigns.content_variables.modify_gdpr') },
      {id: '{CouponDiscountType}', name: this.translate.instant('resources.campaigns.content_variables.coupon_discount_type')},
      {id: '{CouponDiscount}', name: this.translate.instant('resources.campaigns.content_variables.coupon_discount')},
      {id: '{CouponMinimumMoney}', name: this.translate.instant('resources.campaigns.content_variables.coupon_minimum_money')},
      {id: '{CouponMaximumMoney}', name: this.translate.instant('resources.campaigns.content_variables.coupon_maximum_money')},
      {id: '{CouponMinimumUnits}', name: this.translate.instant('resources.campaigns.content_variables.coupon_minimum_units')},
      {id: '{CouponMaximumUnits}', name: this.translate.instant('resources.campaigns.content_variables.coupon_maximum_units')},
      {id: '{CouponMaximumRedemptions}', name: this.translate.instant('resources.campaigns.content_variables.coupon_maximum_redemptions')},
      {id: '{CouponName}', name: this.translate.instant('resources.campaigns.content_variables.coupon_name')},
    ];

    if(this.flags.showCustom1DynamicVariable) {
      list.push({id: '{custom1}', name: this.translate.instant('resources.campaigns.content_variables.custom_1')})
    }

    return list;
  }

  saveCampaign(campaign: Campaign | CampaignPayload): Observable<Campaign> {
    if ( campaign?.id ) {
      return this.patchResource(campaign, `campaigns/${campaign.id}`);
    } else {
      return this.postResource(campaign, `campaigns`);
    }
  }

  deliverCampaign(campaign: Campaign): Observable<Campaign> {
    return this.postResource({}, `campaigns/${campaign.id}/send`);
  }

  batchDeliver(ids: number[]): Observable<string> {
    return this.postResource({ids: ids}, `campaigns/send`);
  }

  reuseCampaign(campaign: Campaign): Observable<Campaign> {
    return this.postResource({}, `/campaigns/${campaign.id}/reuse`);
  }

  removeSchedule(campaignIds): Observable<any> {
    return this.postResource({ids: campaignIds}, `campaigns/remove_schedule`);
  }

  sendTest(recipients: string, campaign: Campaign): Observable<Campaign> {
    return this.postResource({ destination_test: recipients }, `/campaigns/${campaign.id}/send_test`);
  }

  cloneList(params): Observable<string> {
    return this.postResource(params, `/campaigns/clone`);
  }

  cancelList(params): Observable<string> {
    return this.postResource(params, `campaigns/histories/cancel`);
  }

  reprint(params): Observable<string> {
    return this.postResource(params, `campaigns/histories/reprint`);
  }

  exportCustomers(campaignId: number, apply: number, tagId: number): Observable<any> {
    return this.postResource({ apply: apply, tag_id: tagId }, `/campaigns/${campaignId}/export_customers` );
  }

  deleteCampaigns(campaignIds) {
    return this.deleteResourceWithParams('campaigns', {ids: campaignIds.join(',')});
  }

  isScheduleable(campaign: { scheduled_at: string }) {
    if (campaign.scheduled_at && campaign.scheduled_at !== '-') {
      return this.dateService.isFutureDate( campaign.scheduled_at );
    } else {
      return false;
    }
  }

  save(data) {
    return this.postResource(data, `campaigns`);
  }

  setDatesRequired() {
    this.datesAreRequired = true;
  }

  setDates(dateFrom: string, dateTo: string) {
    this.date_from = dateFrom;
    this.date_to = dateTo;
    this.optsSource.next({dateFrom, dateTo});
  }
}