<div class="container-fluid clearfix" *ngIf="segmentCategory">
  <div class="row">
    <div class="col">
      <div class="card mrg-btm-5 height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true">
              <tbody>
                <tr>
                  <td style="border-top: none;"><b [translate]="'resources.segment_categories.show.fields.name'"></b></td>
                  <td style="border-top: none;">{{segmentCategory.name}}</td>
                </tr>
                <tr>
                  <td style="border-top: none;"><b [translate]="'resources.segment_categories.show.fields.segment_count'"></b></td>
                  <td style="border-top: none;">{{segmentCategory.segment_count}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card col mrg-top-20 height-100">
    <h5 class="mrg-vertical-15" [translate]="'resources.segment_categories.show.table.title'"></h5>
    <app-data-table [config]="dataTableConfig"></app-data-table>
  </div>

</div>

