import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActionModalsService } from '../action-modals.service';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { QuestionControlService } from '../../../../../../../../shared/services/question-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBase } from '../../../../../../../../shared/models/forms/question-base';
import { ModalStatusService } from '../../../../../../../../shared/services/modal-status.service';
import { CurrentCompany } from '../../../../../../../../shared/models/current-user';
import { ProfileService } from '../../../../../../../../profiles/profile.service';
import { takeUntil } from 'rxjs/operators';
import { NewCard, NewUnifiedCustomerCard } from '../../../../../../../../shared/models/customers/new-card';

@Component({
  selector: 'app-new-card',
  templateUrl: './new-card.component.html'
})
export class NewCardComponent implements OnInit, OnDestroy {

  currentCompany: CurrentCompany;
  newCardForm: UntypedFormGroup;
  inputs: any;
  id: string;
  showButton: boolean;
  loading: boolean;
  newCustomerInputConfig: QuestionBase<any>;
  
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private profileService: ProfileService,
    private qcs: QuestionControlService,
    private actionModalService: ActionModalsService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private modalStatusService: ModalStatusService
  ) {}

  ngOnInit() {
    this.currentCompany = new CurrentCompany(this.profileService.getProfileCompany());
    this.getParams();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  newCustomerInputLabelKey(): string {
    let key = 'resources.customers.show.action_modals.new_card_number';

    if(!this.currentCompany.unifiedCustomers && this.currentCompany.isAccountsIdentifierByCode()) {
      key = 'resources.customers.show.action_modals.new_card_code';
    }
    return key;
  }

  sendData() {
    this.loading = true;

    let newCardData: NewCard | NewUnifiedCustomerCard;
    let requestNewCard$: Observable<unknown>;

    if (this.currentCompany?.unifiedCustomers) {
      newCardData = {
        id: this.newCardForm.value.new_customer_id_code,
        main: true
      };
      
      requestNewCard$ = this.actionModalService.deliverNewUnifiedCustomerCard(newCardData, this.id);
      
    } else {
      const newCustomerKey = this.currentCompany.isAccountsIdentifierByCode() ? 'new_customer_code' : 'new_customer_id';
      newCardData = { old_customer_id: this.id,  [newCustomerKey]: this.newCardForm.value.new_customer_id_code };
    
      requestNewCard$ = this.actionModalService.deliverNewCard(newCardData);
    }
    
    requestNewCard$.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.customers.warnings.new_card_title'),
          this.translate.instant('resources.customers.warnings.new_card_text')
        ).catch(() => {});
        this.modalStatusService.modalStatus.emit();
        this.loading = false;
        this.closeModal();
      },
      error => {
        this.loading = false;
        this.confirmationService.displayErrorAlert('Error', error.error.error);
      });
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getParams() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.id = params.id;
      this.newCustomerInputConfig = this.actionModalService.getInputs().find( input => input.key === 'new_customer_id_code');
      if(this.currentCompany.unifiedCustomers) {
        this.newCustomerInputConfig.type = 'number';
      }

      this.inputs = [this.newCustomerInputConfig];
      this.newCardForm = this.qcs.toFormGroup(this.inputs);
    });
  }
}
