import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomersService } from '../../customers.service';
import { Subscription } from 'rxjs';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { DataTableFilterService } from '../../../../../shared/components/data-table-filter/data-table-filter.service';
import { ProfileService } from '../../../../../profiles/profile.service';
import { CurrentCompany } from '../../../../../shared/models/current-user';

@Component({
  selector: 'app-customers-tab',
  templateUrl: './customers-tab.component.html',
  styleUrls: ['./customers-tab.component.css']
})
export class CustomersTabComponent implements OnInit, OnDestroy {

  public summary: any;
  public inactiveActivities: any[];
  public activityTotals: number;
  public totalActive: number;
  public totalInactive: number;
  public loading = false;
  public subs$: Subscription[] = [];
  public company: CurrentCompany;

  constructor(
    private customersService: CustomersService,
    private refreshCacheService: RefreshCacheService,
    private filterService: DataTableFilterService,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
    this.company = new CurrentCompany(this.profileService.getProfileCompany());
    if (this.company.isTagged()) {
      this.getSummary(this.customersService.filters['customers_summary']);
      const filterChange$ = this.customersService.applyFilters$.subscribe(
        filterValues => this.getSummary(filterValues)
      );
      this.subs$.push(filterChange$);
    }
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach(s$ => s$.unsubscribe()); }
  }

  private getActivityTotals(activities: any[]): number {
    let total = 0;
    activities.forEach(activity => {
      total += activity.count;
    });

    return total;
  }

  private getSummary(filterFormValue?): void {
    let filters = {...this.customersService.filters};
    if (filters instanceof Object && filters.hasOwnProperty('customers_summary')) {
      filters = this.customersService.filters['customers_summary'];
    }

    delete filters.year;
    delete filters.feature_ids;
    delete filters.taxonomy;
    delete filters.constant_customers;
    delete filters.typology;

    const params = { apiEndPoint: 'analytics/consistence_summary', filtering: filters };
    this.loading = true;
    this.filterService.loaderStatus.next(false);
    const summary$ = this.refreshCacheService.getExpensiveData(params).subscribe((data) => {
      if (!this.refreshCacheService.isRequestPending(data)) {
        this.summary = data;
        this.summary['sales'].pop(); // Remove zero
        summary$.unsubscribe();
        this.parseData();
        this.loading = false;
        this.filterService.loaderStatus.next(true);
      }
    });
    this.subs$.push(summary$);
  }

  private parseData(): void {
    const activities = [...this.summary['activity']];
    this.activityTotals = this.getActivityTotals(activities);
    this.totalActive = activities[0].count;

    activities.shift();
    this.inactiveActivities = activities;
    this.totalInactive = this.getActivityTotals(activities);
  }
}
