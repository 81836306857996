import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../shared/components/multiselect/multiselect';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { of, Observable } from 'rxjs';
import { DateService } from '../../shared/services/date.service';
import { DateTimeZoneService } from '../../shared/services/date-time-zone.service';

@Injectable()
export class CustomerJourneysHistoriesService extends ResourcesService implements MultiselectDataSourceable {

  public journeyId: number;
  public optsSource = new BehaviorSubject<number>(null);
  public optsSource$ = this.optsSource.asObservable();

  constructor( http: HttpClient,
               private dateService: DateTimeZoneService ) {
    super(http);
  }

  public setJourney(id: number) {
    this.journeyId = id;
    this.optsSource.next( this.journeyId );
  }

  public fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {

    if ( !this.journeyId ) { return of({}); }

    const requestOptions = {
      apiEndPoint: `campaign_blocks/${this.journeyId}/histories`,
      sorting: {},
      filtering: {
        paginate: false
      }
    };

    return this.getData( requestOptions );
  }

  public fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `campaign_blocks/${this.journeyId}/histories/${id}` });
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    let date;
    if (element.hasOwnProperty('sent_at') && element.sent_at) {
      date = new Date(element.sent_at);
    } else {
      date = new Date(element.scheduled_at);
    }
    date = this.dateService.parseDateWithFormat(date, 'DD/MM/YYYY');
    return new MultiselectDataSource(element.id, date, element);
  }
}
