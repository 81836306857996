<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <app-loader *ngIf="loading" />
          <div class="table-overflow" *ngIf="!loading">
            <table class="table table-hover" *ngIf="campaignHistoryData" aria-hidden="true">
              <tbody>
                <tr *ngIf="campaignHistoryData.campaign && campaignHistoryData.campaign.via">
                  <td class="no-border-top"><b [translate]="'resources.campaigns.fields.via'"></b></td>
                  <td class="no-border-top">
                    <span class="label font-size-12 label-info" *ngIf="campaignHistoryData.campaign.via === 'email'">{{'resources.campaigns.via.email' | translate}}</span>
                    <span class="label font-size-12 label-primary" *ngIf="campaignHistoryData.campaign.via === 'none'">{{'resources.campaigns.via.ticket_coupon' | translate}}</span>
                    <span class="label font-size-12 label-warning" *ngIf="campaignHistoryData.campaign.via === 'ticket_message'">{{'resources.campaigns.via.ticket_message'| translate}}</span>
                    <span class="label font-size-12 label-primary" *ngIf="campaignHistoryData.campaign.via === 'ticket_coupon'">{{'resources.campaigns.via.ticket_coupon'| translate}}</span>
                    <span class="label font-size-12 label-info" *ngIf="campaignHistoryData.campaign.via === 'sms'">{{'resources.campaigns.via.sms' | translate}}</span>
                    <span class="label font-size-12 label-info" *ngIf="campaignHistoryData.campaign.via === 'push'">{{'resources.campaigns.via.push' | translate}}</span>
                  </td>
                </tr>

                <tr *ngIf="campaignHistoryData.status">
                  <td class="no-border-top"><b [translate]="'resources.campaigns.fields.status'"></b></td>
                  <td class="no-border-top">
                    <span class="label font-size-12 label-success" *ngIf="campaignHistoryData.status === 'success'">{{'resources.campaigns.status.delivered' | translate}}</span>
                    <span class="label font-size-12 label-primary" *ngIf="campaignHistoryData.status === 'sending'">{{'resources.campaigns.status.' + campaignHistoryData.status | translate}}</span>
                    <span class="label font-size-12 label-danger" *ngIf="campaignHistoryData.status === 'delivered_with_errors'">{{'resources.campaigns.status.' + campaignHistoryData.status | translate}}</span>
                    <span class="label font-size-12 label-danger" *ngIf="campaignHistoryData.status === 'error'">{{'resources.campaigns.status.' + campaignHistoryData.status | translate}}</span>
                    <span class="label font-size-12 label-success" *ngIf="campaignHistoryData.status === 'delivered'">{{'resources.campaigns.status.' + campaignHistoryData.status | translate}}</span>
                    <span class="label font-size-12 label-success" *ngIf="campaignHistoryData.status === 'checked'">{{'resources.campaigns.status.' + campaignHistoryData.status | translate}}</span>
                    <span class="label font-size-12 label-info" *ngIf="campaignHistoryData.status === 'checking'">{{'resources.campaigns.status.' + campaignHistoryData.status | translate}}</span>
                  </td>
                </tr>

                <tr *ngIf="campaignHistoryData.campaign && campaignHistoryData.campaign.motive">
                  <td><b [translate]="'resources.campaigns.fields.motive'"></b></td>
                  <td>{{'resources.campaigns.motive.' + campaignHistoryData.campaign.motive | translate }}</td>
                </tr>

                <tr *ngIf="campaignHistoryData.sent_at">
                  <td><b [translate]="'resources.campaigns.fields.sent_at'"></b></td>
                  <td>{{campaignHistoryData.sent_at | dateTimeZone: 'DD/MM/YYYY HH:mm'}}</td>
                </tr>

                <tr *ngIf="campaignHistoryData.sent_number">
                  <td><b [translate]="'resources.campaigns.fields.sent_number'"></b></td>
                  <td>{{campaignHistoryData.sent_number}}</td>
                </tr>

                <tr *ngIf="campaignHistoryData.campaign && campaignHistoryData.campaign.motive === 'message' && campaignHistoryData.messages[0]">
                  <td><b [translate]="'resources.campaigns.fields.ticket_message'"></b></td>
                  <td>
                    <a [routerLink]="['', { outlets: { modal: ['show', 'messages', campaignHistoryData.messages[0].parent_id] } }]">
                      {{campaignHistoryData.messages[0].parent_id}} - {{campaignHistoryData.messages[0].name}}
                    </a>
                  </td>
                </tr>

                <tr *ngIf="campaignHistoryData.campaign && campaignHistoryData.campaign.motive === 'coupon' && campaignHistoryData.coupons[0]">
                  <td><b [translate]="'resources.campaigns.fields.coupon'"></b></td>
                  <td>
                    <a [routerLink]="['', { outlets: { modal: ['show', 'coupons', campaignHistoryData.coupons[0].parent_id] } }]">
                      {{campaignHistoryData.voucheables[0].database_id}} - {{campaignHistoryData.coupons[0].name}}</a>
                  </td>
                </tr>

                <tr *ngIf="campaignHistoryData.campaign && campaignHistoryData.campaign.motive === 'coupon' && campaignHistoryData.distribution">
                  <td [translate]="'resources.campaigns.fields.distribution'"></td>
                  <td>{{'resources.campaigns.distribution.' + campaignHistoryData.distribution | translate }}</td>
                </tr>


                <tr *ngIf="campaignHistoryData && campaignHistoryData.segment">
                  <td><b [translate]="'resources.campaigns.fields.segment'"></b></td>
                  <td *ngIf="!campaignHistoryData.segment.operator">
                    <a [routerLink]="['', { outlets: { modal: ['show', 'segments', campaignHistoryData.segment_id] } }]">
                      {{ campaignHistoryData.segment.name }}
                    </a>
                  </td>
                  <td *ngIf="campaignHistoryData && campaignHistoryData.segment">
                    <a href="#/campaigns/{{campaignId}}/histories/{{campaignHistoryId}}/segment" target="blank">{{ campaignHistoryData.segment.name }}</a>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
