<div class="row">
  <div class="col">
    <div class="card">
      <div class="card-block">
        <h4 class="card-title">
          <b [translate]="'resources.journeys.fields.message'"></b>
        </h4>
        <div class="row">
          <div class="form-group col-md-12">
            <div class="pull-right coupon-variable-btn-container">
              <button class="btn btn-info btn-inverse btn-rounded dropdown-toggle" data-toggle="dropdown"
                (click)='open = !open'>
                <span [translate]="'resources.campaigns.smsAddVariable.addVariable'"></span>
                <i class="ti-angle-down font-size-9"></i>
              </button>
              <ul class="dropdown-menu">
                <li (click)="smsAddVariable('{name}')">
                  <a [translate]="'resources.campaigns.content_variables.name'"></a>
                </li>
                <li (click)="smsAddVariable('{surname_1}')">
                  <a [translate]="'resources.campaigns.content_variables.surname_1'"></a>
                </li>
                <li (click)="smsAddVariable('{surname_2}')">
                  <a [translate]="'resources.campaigns.content_variables.surname_2'"></a>
                </li>
                <li (click)="smsAddVariable('{email}')">
                  <a [translate]="'resources.campaigns.content_variables.email'"></a>
                </li>
                <li (click)="smsAddVariable('{code}')">
                  <a [translate]="'resources.campaigns.content_variables.client_code'"></a>
                </li>
                <li (click)="smsAddVariable('{couponCode}')">
                  <a [translate]="'resources.campaigns.content_variables.coupon_code'"></a>
                </li>
                <li (click)="smsAddVariable('{score}')">
                  <a [translate]="'resources.campaigns.content_variables.points'"></a>
                </li>
                <li (click)="smsAddVariable('{shop}')">
                  <a [translate]="'resources.campaigns.content_variables.usual_store'"></a>
                </li>
                <li (click)="smsAddVariable('{address}')">
                  <a [translate]="'resources.campaigns.content_variables.address'"></a>
                </li>
                <li (click)="smsAddVariable('{city}')">
                  <a [translate]="'resources.campaigns.content_variables.city'"></a>
                </li>
                <li (click)="smsAddVariable('{postal_code}')">
                  <a [translate]="'resources.campaigns.content_variables.postal_code'"></a>
                </li>
                <li (click)="smsAddVariable('{country}')">
                  <a [translate]="'resources.campaigns.content_variables.country'"></a>
                </li>
                <!-- <li (click)="smsAddVariable('{trimestralExpend}')">
                  <a [translate]="'resources.campaigns.content_variables.trimestral_expend'"></a>
                </li>
                <li (click)="smsAddVariable('{monthExpend}')">
                  <a [translate]="'resources.campaigns.content_variables.month_expend'"></a>
                </li> -->
                <li (click)="smsAddVariable('{CouponAvailableTo}')">
                  <a [translate]="'resources.campaigns.content_variables.coupon_available_to'"></a>
                </li>
                <li (click)="smsAddVariable('{CouponAvailableFrom}')">
                  <a [translate]="'resources.campaigns.content_variables.coupon_available_from'"></a>
                </li>
              </ul>
            </div>
            <div [ngClass]="{'has-error': messageData.smsRemainingLength < 0}">
              <textarea #messageInput id="sms-textarea" [(ngModel)]="journey.configuration.message"
                (input)="writingSms($event)" class="form-control text-area-sm" rows="3"
                (ngModelChange)="handleContentChanged($event)"></textarea>
            </div>
            <p class="help-block">
              <span tooltip-placement="right" class="fa fa-info-circle" data-trigger="hover"
                [title]="'resources.campaigns.warnings.character_max_tooltip' | translate: { smsMaxLength: messageData.smsMaxLength, smsMultiLength: messageData.smsMultiLength, smsMaxLengthDoubled: messageData.smsMaxLengthDoubled }">
              </span>
              <small class="font-size-12">
                {{'resources.campaigns.warnings.character_count' | translate: { smsMessages: messageData.smsMessages, smsRemainingLength: messageData.smsRemainingLength, totalLength: messageData.totalLengthText} }}
              </small>
              <span class="text-danger" ng-show="smsHasVariables()">*</span>
            </p>
            <p *ngIf="smsHasVariables()">
              <span>
                <span class="text-danger">*</span>
                <small class="font-size-12">
                  {{'resources.campaigns.warnings.var_guideline' | translate }}
                </small>
              </span>
            </p>

            <!-- TODO: Eval after journey load and only if coupon selected in configuration
        <p *ngIf="journey">
          <span>
            <span class="text-danger">*</span>
            <small>{{'resources.campaigns.warnings.use_static_code' | translate}}</small>
          </span>
        </p>
        -->

          </div>
        </div>
      </div>
    </div>
  </div>
</div>