<ng-container *ngIf="plan">
  <h2 class="card-title mrg-btm-10">
    <span class="label font-size-14 label-info">
      {{'resources.campaign_plans.fields.user_audience_start_date' | translate}}:
      <b *ngIf="!userDatesPresent">{{defaultDates.userAudienceStartDate | date: 'dd/MM/yyyy'}}</b>
      <b *ngIf="userDatesPresent">{{planCampaign.userAudienceStartDate | date: 'dd/MM/yyyy'}}</b>
    </span>
    <span class="label font-size-14 label-info mrg-left-15">
      {{'resources.campaign_plans.fields.user_audience_end_date' | translate}}:
      <b *ngIf="!userDatesPresent">{{defaultDates.userAudienceEndDate | date: 'dd/MM/yyyy'}}</b>
      <b *ngIf="userDatesPresent">{{planCampaign.userAudienceEndDate | date: 'dd/MM/yyyy'}}</b>
    </span>
    <ng-container *ngIf="isEditionVisible">
      <i class="ti-info-alt tooltip-position font-size-20" tooltipPosition="right" pTooltip="{{'resources.campaign_plans.messages.tooltip_text' | translate }}"></i>
      <button type="button"
              class="btn btn-xs mrg-left-15 mrg-top-10 btn-default"
              (click)="toggleEdition()"
              *ngIf="isEditionEnabled"
              [disabled]="loading">
        {{'resources.plans.buttons.disable_edit' | translate}}
      </button>
      <button type="button"
              class="btn btn-xs mrg-left-15 mrg-top-10 btn-info"
              (click)="toggleEdition()"
              [disabled]="isEditionPrevented"
              *ngIf="!isEditionEnabled">
        {{'resources.plans.buttons.enable_edit' | translate}}
      </button>
      <button type="button"
              class="btn btn-xs mrg-left-5 mrg-top-10 btn-default"
              (click)="useDefaultDates()"
              [disabled]="isEditionPrevented || !userDatesPresent"
              *ngIf="!isEditionEnabled">
        {{'resources.campaign_plans.buttons.use_default_dates' | translate}}
      </button>
      <button type="button"
              class="btn btn-xs mrg-left-5 mrg-top-10 btn-info"
              (click)="save()"
              [disabled]="isEditionPrevented || plan.status !== 'draft'"
              *ngIf="!isEditionEnabled">
        {{'resources.campaign_plans.buttons.calculate' | translate}}
      </button>
    </ng-container>
  </h2>

</ng-container>

<ng-container *ngIf="isEditionEnabled">
  <form [formGroup]="audienceDatesForm" *ngIf="audienceDatesForm">
    <div>
      <div class="row mrg-top-10 mrg-btm-15">
        <div class="col-md-3">
          <app-question [question]="inputs[0]" (click)="markAsDirty('user_audience_start_date')" [form]="audienceDatesForm"></app-question>
        </div>
        <div class="col-md-3">
          <app-question [question]="inputs[1]" (click)="markAsDirty('user_audience_end_date')" [form]="audienceDatesForm"></app-question>
        </div>
        <div class="col-md-2">
          <button class="btn btn-sm btn-info mrg-top-30"
                  type="button"
                  [disabled]="audienceDatesForm.invalid"
                  (click)="save()">
            {{'resources.campaign_plans.buttons.save_dates' | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-container>
