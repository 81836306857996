import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../../../shared/components/multiselect/multiselect';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { Observable } from 'rxjs';

@Injectable()
export class RewardCategoriesService extends ResourcesService implements MultiselectDataSourceable {
  inputs: QuestionBase<any>[];
  treatPkAsId = false;

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  getAll() {

    const requestOptions = {
      apiEndPoint: 'reward_taxonomy_terms',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };

    return this.getData(requestOptions);
  }

  fetchMultiselect( searchValues?: string, page?: number ) {
    let requestOptions = {
      apiEndPoint: 'reward_taxonomy_terms',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getRewardCategoriesById(id);
  }

  getRewardCategoriesById(id: number): Observable<any> {
    return this.getData({apiEndPoint: `reward_taxonomy_terms/${id}`});
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    const id = this.treatPkAsId ? element.pk : element.id;
    return new MultiselectDataSource(id, element.name, element);
  }

  sendData(rewardData) {
    return this.postResource(rewardData, 'reward_taxonomy_terms');
  }

  updateData(rewardData: any, id: number) {
    return this.patchResource(rewardData, `reward_taxonomy_terms/${id}`);
  }

  deleteById(id: number) {
    return this.deleteResource({}, `reward_taxonomy_terms/${id}`);
  }
}
