<div class="container-fluid clearfix">

  <div class="page-title mrg-top-20" *ngIf="!isModal">
    <h4 [translate]="'resources.products.fields.title'"></h4>
  </div>

  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true" *ngIf="element">
              <tbody>
                <tr *ngIf="element.id">
                  <td class="no-border-top"><b [translate]="'resources.products.fields.id'"></b></td>
                  <td class="no-border-top">{{element.id}}</td>
                </tr>
                <tr *ngIf="element.status">
                  <td><b [translate]="'resources.products.fields.status'"></b></td>
                  <td *ngIf="element.status === 'active'"><span class="label label-info font-size-12" [translate]="'resources.products.fields.active'"></span></td>
                  <td *ngIf="element.status === 'inactive'"><span class="label label-danger font-size-12" [translate]="'resources.products.fields.inactive'"></span></td>
                </tr>
                <tr *ngIf="element.name">
                  <td><b [translate]="'resources.products.fields.name'"></b></td>
                  <td>{{element.name}}</td>
                </tr>
                <tr *ngIf="element.description">
                  <td><b [translate]="'resources.products.fields.description'"></b></td>
                  <td>{{element.description}}</td>
                </tr>
                <tr *ngIf="element.code">
                  <td><b [translate]="'resources.products.fields.code'"></b></td>
                  <td>{{element.code}}</td>
                </tr>
                <tr *ngIf="element.price">
                  <td><b [translate]="'resources.products.fields.price'"></b></td>
                  <td>{{element.price | lgcurrency: '1.2-2'}}</td>
                </tr>
                <tr *ngIf="element && element._embedded.features.length > 0">
                  <td><b [translate]="'resources.products.fields.features'"></b></td>
                  <td>
                    <ul class="list arrow bullet-success">
                      <li *ngFor="let item of element._embedded.features">
                        <a *ngIf="roleSlug !== 'customer_service' && roleSlug !== 'store_manager'" class="pointer colorLink" (click)="goToCategory(item.id)">{{item.pk}} - {{item.name}}</a>
                        <span *ngIf="roleSlug === 'customer_service' || roleSlug === 'store_manager'">{{item.pk}} - {{item.name}}</span>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
