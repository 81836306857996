import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourcesService } from '../../../shared/services/resources.service';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../../shared/components/multiselect/multiselect';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';

@Injectable()
export class MessageCategoriesService extends ResourcesService implements MultiselectDataSourceable {
  inputs: TextboxQuestion[];

  constructor(http: HttpClient) {
    super(http);
  }

  public fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'message_categories',
      numberPerPage: 300,
      filtering: {},
      pageNumber: 1,
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  public fetchSelectedById(id: number) {
    return this.getMessageCategoriesById(id);
  }

  public getMessageCategoriesById(id: number): Observable<any> {
    return this.getData({apiEndPoint: `message_categories/${id}`});
  }

  public getInputs(formValues: any): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 1
      })
    ];

    Object.keys(formValues).map(
      key => {
        if (key === 'name') {
          inputsArray.find( input => input.key === 'name' ).value = formValues[key];
        }
    });

    this.inputs = inputsArray;
    return this.inputs;
  }

  public sendData(creditsData) {
    return this.postResource(creditsData, 'message_categories');
  }

  public updateData(creditsData: any, _id: number) {
    return this.patchResource(creditsData, `message_categories/${_id}`);
  }


  public getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.name);
  }
}
