export const currencies = [
      { id: 'AFN', name: 'Afghan Afghani' },
      { id: 'ALL', name: 'Albanian Lek' },
      { id: 'DZD', name: 'Algerian Dinar' },
      { id: 'ADP', name: 'Andorran Peseta' },
      { id: 'AOA', name: 'Angolan Kwanza' },
      { id: 'ARA', name: 'Argentine Austral' },
      { id: 'ARS', name: 'Argentine Peso' },
      { id: 'AMD', name: 'Armenian Dram' },
      { id: 'AWG', name: 'Aruban Florin' },
      { id: 'AUD', name: 'Australian Dollar' },
      { id: 'ATS', name: 'Austrian Schilling' },
      { id: 'AZN', name: 'Azerbaijani Manat' },
      { id: 'BSD', name: 'Bahamian Dollar' },
      { id: 'BHD', name: 'Bahraini Dinar' },
      { id: 'BDT', name: 'Bangladeshi Taka' },
      { id: 'BBD', name: 'Barbadian Dollar' },
      { id: 'BYN', name: 'Belarusian Ruble' },
      { id: 'BEF', name: 'Belgian Franc' },
      { id: 'BZD', name: 'Belize Dollar' },
      { id: 'BMD', name: 'Bermudan Dollar' },
      { id: 'BTN', name: 'Bhutanese Ngultrum' },
      { id: 'BOB', name: 'Bolivian Boliviano' },
      { id: 'BOV', name: 'Bolivian Mvdol' },
      { id: 'BOP', name: 'Bolivian Peso' },
      { id: 'BAM', name: 'Bosnia-Herzegovina Convertible Mark' },
      { id: 'BWP', name: 'Botswanan Pula' },
      { id: 'BRL', name: 'Brazilian Real' },
      { id: 'GBP', name: 'British Pound' },
      { id: 'BND', name: 'Brunei Dollar' },
      { id: 'BGL', name: 'Bulgarian Hard Lev' },
      { id: 'BGN', name: 'Bulgarian Lev' },
      { id: 'BGM', name: 'Bulgarian Socialist Lev' },
      { id: 'BUK', name: 'Burmese Kyat' },
      { id: 'BIF', name: 'Burundian Franc' },
      { id: 'XPF', name: 'CFP Franc' },
      { id: 'KHR', name: 'Cambodian Riel' },
      { id: 'CAD', name: 'Canadian Dollar' },
      { id: 'CVE', name: 'Cape Verdean Escudo' },
      { id: 'KYD', name: 'Cayman Islands Dollar' },
      { id: 'XAF', name: 'Central African CFA Franc' },
      { id: 'CLE', name: 'Chilean Escudo' },
      { id: 'CLP', name: 'Chilean Peso' },
      { id: 'CLF', name: 'Chilean Unit of Account (UF)' },
      { id: 'CNX', name: 'Chinese People’s Bank Dollar' },
      { id: 'CNY', name: 'Chinese Yuan' },
      { id: 'COP', name: 'Colombian Peso' },
      { id: 'COU', name: 'Colombian Real Value Unit' },
      { id: 'KMF', name: 'Comorian Franc' },
      { id: 'CDF', name: 'Congolese Franc' },
      { id: 'CRC', name: 'Costa Rican Colón' },
      { id: 'HRD', name: 'Croatian Dinar' },
      { id: 'HRK', name: 'Croatian Kuna' },
      { id: 'CUC', name: 'Cuban Convertible Peso' },
      { id: 'CUP', name: 'Cuban Peso' },
      { id: 'CYP', name: 'Cypriot Pound' },
      { id: 'CZK', name: 'Czech Koruna' },
      { id: 'CSK', name: 'Czechoslovak Hard Koruna' },
      { id: 'DKK', name: 'Danish Krone' },
      { id: 'DJF', name: 'Djiboutian Franc' },
      { id: 'DOP', name: 'Dominican Peso' },
      { id: 'NLG', name: 'Dutch Guilder' },
      { id: 'XCD', name: 'East Caribbean Dollar' },
      { id: 'DDM', name: 'East German Mark' },
      { id: 'ECS', name: 'Ecuadorian Sucre' },
      { id: 'ECV', name: 'Ecuadorian Unit of Constant Value' },
      { id: 'EGP', name: 'Egyptian Pound' },
      { id: 'GQE', name: 'Equatorial Guinean Ekwele' },
      { id: 'ERN', name: 'Eritrean Nakfa' },
      { id: 'EEK', name: 'Estonian Kroon' },
      { id: 'ETB', name: 'Ethiopian Birr' },
      { id: 'EUR', name: 'Euro' },
      { id: 'XEU', name: 'European Currency Unit' },
      { id: 'FKP', name: 'Falkland Islands Pound' },
      { id: 'FJD', name: 'Fijian Dollar' },
      { id: 'FIM', name: 'Finnish Markka' },
      { id: 'FRF', name: 'French Franc' },
      { id: 'XFO', name: 'French Gold Franc' },
      { id: 'XFU', name: 'French UIC-Franc' },
      { id: 'GMD', name: 'Gambian Dalasi' },
      { id: 'GEK', name: 'Georgian Kupon Larit' },
      { id: 'GEL', name: 'Georgian Lari' },
      { id: 'DEM', name: 'German Mark' },
      { id: 'GHS', name: 'Ghanaian Cedi' },
      { id: 'GIP', name: 'Gibraltar Pound' },
      { id: 'GRD', name: 'Greek Drachma' },
      { id: 'GTQ', name: 'Guatemalan Quetzal' },
      { id: 'GWP', name: 'Guinea-Bissau Peso' },
      { id: 'GNF', name: 'Guinean Franc' },
      { id: 'GNS', name: 'Guinean Syli' },
      { id: 'GYD', name: 'Guyanaese Dollar' },
      { id: 'HTG', name: 'Haitian Gourde' },
      { id: 'HNL', name: 'Honduran Lempira' },
      { id: 'HKD', name: 'Hong Kong Dollar' },
      { id: 'HUF', name: 'Hungarian Forint' },
      { id: 'ISK', name: 'Icelandic Króna' },
      { id: 'INR', name: 'Indian Rupee' },
      { id: 'IDR', name: 'Indonesian Rupiah' },
      { id: 'IRR', name: 'Iranian Rial' },
      { id: 'IQD', name: 'Iraqi Dinar' },
      { id: 'IEP', name: 'Irish Pound' },
      { id: 'ILS', name: 'Israeli New Shekel' },
      { id: 'ILP', name: 'Israeli Pound' },
      { id: 'ITL', name: 'Italian Lira' },
      { id: 'JMD', name: 'Jamaican Dollar' },
      { id: 'JPY', name: 'Japanese Yen' },
      { id: 'JOD', name: 'Jordanian Dinar' },
      { id: 'KZT', name: 'Kazakhstani Tenge' },
      { id: 'KES', name: 'Kenyan Shilling' },
      { id: 'KWD', name: 'Kuwaiti Dinar' },
      { id: 'KGS', name: 'Kyrgystani Som' },
      { id: 'LAK', name: 'Laotian Kip' },
      { id: 'LVL', name: 'Latvian Lats' },
      { id: 'LVR', name: 'Latvian Ruble' },
      { id: 'LBP', name: 'Lebanese Pound' },
      { id: 'LSL', name: 'Lesotho Loti' },
      { id: 'LRD', name: 'Liberian Dollar' },
      { id: 'LYD', name: 'Libyan Dinar' },
      { id: 'LTL', name: 'Lithuanian Litas' },
      { id: 'LTT', name: 'Lithuanian Talonas' },
      { id: 'LUL', name: 'Luxembourg Financial Franc' },
      { id: 'LUC', name: 'Luxembourgian Convertible Franc' },
      { id: 'LUF', name: 'Luxembourgian Franc' },
      { id: 'MOP', name: 'Macanese Pataca' },
      { id: 'MKD', name: 'Macedonian Denar' },
      { id: 'MGA', name: 'Malagasy Ariary' },
      { id: 'MGF', name: 'Malagasy Franc' },
      { id: 'MWK', name: 'Malawian Kwacha' },
      { id: 'MYR', name: 'Malaysian Ringgit' },
      { id: 'MVR', name: 'Maldivian Rufiyaa' },
      { id: 'MLF', name: 'Malian Franc' },
      { id: 'MTL', name: 'Maltese Lira' },
      { id: 'MTP', name: 'Maltese Pound' },
      { id: 'MRO', name: 'Mauritanian Ouguiya' },
      { id: 'MUR', name: 'Mauritian Rupee' },
      { id: 'MXV', name: 'Mexican Investment Unit' },
      { id: 'MXN', name: 'Mexican Peso' },
      { id: 'MDC', name: 'Moldovan Cupon' },
      { id: 'MDL', name: 'Moldovan Leu' },
      { id: 'MCF', name: 'Monegasque Franc' },
      { id: 'MNT', name: 'Mongolian Tugrik' },
      { id: 'MAD', name: 'Moroccan Dirham' },
      { id: 'MAF', name: 'Moroccan Franc' },
      { id: 'MZE', name: 'Mozambican Escudo' },
      { id: 'MZN', name: 'Mozambican Metical' },
      { id: 'MMK', name: 'Myanmar Kyat' },
      { id: 'NAD', name: 'Namibian Dollar' },
      { id: 'NPR', name: 'Nepalese Rupee' },
      { id: 'ANG', name: 'Netherlands Antillean Guilder' },
      { id: 'TWD', name: 'New Taiwan Dollar' },
      { id: 'NZD', name: 'New Zealand Dollar' },
      { id: 'NIO', name: 'Nicaraguan Córdoba' },
      { id: 'NGN', name: 'Nigerian Naira' },
      { id: 'KPW', name: 'North Korean Won' },
      { id: 'NOK', name: 'Norwegian Krone' },
      { id: 'OMR', name: 'Omani Rial' },
      { id: 'PKR', name: 'Pakistani Rupee' },
      { id: 'PAB', name: 'Panamanian Balboa' },
      { id: 'PGK', name: 'Papua New Guinean Kina' },
      { id: 'PYG', name: 'Paraguayan Guarani' },
      { id: 'PEI', name: 'Peruvian Inti' },
      { id: 'PEN', name: 'Peruvian Sol' },
      { id: 'PHP', name: 'Philippine Peso' },
      { id: 'PLN', name: 'Polish Zloty' },
      { id: 'PTE', name: 'Portuguese Escudo' },
      { id: 'GWE', name: 'Portuguese Guinea Escudo' },
      { id: 'QAR', name: 'Qatari Rial' },
      { id: 'XRE', name: 'RINET Funds' },
      { id: 'RHD', name: 'Rhodesian Dollar' },
      { id: 'RON', name: 'Romanian Leu' },
      { id: 'RUB', name: 'Russian Ruble' },
      { id: 'RWF', name: 'Rwandan Franc' },
      { id: 'SVC', name: 'Salvadoran Colón' },
      { id: 'WST', name: 'Samoan Tala' },
      { id: 'SAR', name: 'Saudi Riyal' },
      { id: 'RSD', name: 'Serbian Dinar' },
      { id: 'SCR', name: 'Seychellois Rupee' },
      { id: 'SLL', name: 'Sierra Leonean Leone' },
      { id: 'SGD', name: 'Singapore Dollar' },
      { id: 'SKK', name: 'Slovak Koruna' },
      { id: 'SIT', name: 'Slovenian Tolar' },
      { id: 'SBD', name: 'Solomon Islands Dollar' },
      { id: 'SOS', name: 'Somali Shilling' },
      { id: 'ZAR', name: 'South African Rand' },
      { id: 'KRW', name: 'South Korean Won' },
      { id: 'SSP', name: 'South Sudanese Pound' },
      { id: 'SUR', name: 'Soviet Rouble' },
      { id: 'LKR', name: 'Sri Lankan Rupee' },
      { id: 'SHP', name: 'St. Helena Pound' },
      { id: 'SDG', name: 'Sudanese Pound' },
      { id: 'SRD', name: 'Surinamese Dollar' },
      { id: 'SRG', name: 'Surinamese Guilder' },
      { id: 'SZL', name: 'Swazi Lilangeni' },
      { id: 'SEK', name: 'Swedish Krona' },
      { id: 'CHF', name: 'Swiss Franc' },
      { id: 'SYP', name: 'Syrian Pound' },
      { id: 'STD', name: 'São Tomé & Príncipe Dobra' },
      { id: 'TJR', name: 'Tajikistani Ruble' },
      { id: 'TJS', name: 'Tajikistani Somoni' },
      { id: 'TZS', name: 'Tanzanian Shilling' },
      { id: 'THB', name: 'Thai Baht' },
      { id: 'TPE', name: 'Timorese Escudo' },
      { id: 'TOP', name: 'Tongan Paʻanga' },
      { id: 'TTD', name: 'Trinidad & Tobago Dollar' },
      { id: 'TND', name: 'Tunisian Dinar' },
      { id: 'TRY', name: 'Turkish Lira' },
      { id: 'TMT', name: 'Turkmenistani Manat' },
      { id: 'USD', name: 'US Dollar' },
      { id: 'UGX', name: 'Ugandan Shilling' },
      { id: 'UAH', name: 'Ukrainian Hryvnia' },
      { id: 'UAK', name: 'Ukrainian Karbovanets' },
      { id: 'AED', name: 'United Arab Emirates Dirham' },
      { id: 'UYU', name: 'Uruguayan Peso' },
      { id: 'UZS', name: 'Uzbekistani Som' },
      { id: 'VUV', name: 'Vanuatu Vatu' },
      { id: 'VEF', name: 'Venezuelan Bolívar' },
      { id: 'VND', name: 'Vietnamese Dong' },
      { id: 'CHE', name: 'WIR Euro' },
      { id: 'CHW', name: 'WIR Franc' },
      { id: 'XOF', name: 'West African CFA Franc' },
      { id: 'YDD', name: 'Yemeni Dinar' },
      { id: 'YER', name: 'Yemeni Rial' },
      { id: 'ZMW', name: 'Zambian Kwacha' },
      { id: 'ZWL', name: 'Zimbabwean Dollar' }
];
