import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { CheckboxQuestion } from '../../../../../shared/models/forms/question-checkbox';
import { DynamicDateQuestion } from '../../../../../shared/models/forms/question-dynamic-date';
import { DateService } from '../../../../../shared/services/date.service';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { PlanCampaignAction } from '../plan-campaign-action';
import { FeatureFlagsService } from './../../../../../shared/services/feature-flags.service';
import { RecommendedCoupon } from './recommended-coupon';

@Component({
  selector: 'app-audience-coupon-recommender',
  templateUrl: './audience-coupon-recommender.component.html',
  styleUrls: ['./audience-coupon-recommender.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AudienceCouponRecommenderComponent implements OnInit, OnDestroy {

  @ViewChild('modalToggleBtn') modalToggleBtn: ElementRef;
  @Input() action: PlanCampaignAction;
  @Output() recommendedCoupon: EventEmitter<RecommendedCoupon> = new EventEmitter();
  @Output() formChanged: EventEmitter<RecommendedCoupon> = new EventEmitter();
  @Output() errResponse: EventEmitter<HttpErrorResponse> = new EventEmitter();

  inputs: QuestionBase<any>[];
  form: UntypedFormGroup;
  loading = false;
  recommendedCoupons: RecommendedCoupon[] = [];
  couponSelectedIndex = 0;
  showSuccessRatio = false;
  flags = this.featureFlags.flags;
  subs$: Subscription[] = [];

  constructor(
    private qcs: QuestionControlService,
    private dateService: DateService,
    private refreshCacheService: RefreshCacheService,
    private featureFlags: FeatureFlagsService,
  ) { }

  ngOnInit() {
    this.inputs = this.getInputs();
    this.form = this.qcs.toFormGroup(this.inputs);
    this.addFormSub();
    this.getRecommendedCoupons();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  public selectRow(index: number) {
    this.couponSelectedIndex = index;
    const recommendedCoupon = this.recommendedCoupons[this.couponSelectedIndex];
    this.recommendedCoupon.emit(recommendedCoupon);
  }

  private addFormSub() {
    const form$ = this.form.valueChanges.subscribe(
      () => {
        this.formChanged.emit();
      }
    );
    this.subs$.push(form$);
  }

  private getInputs(): QuestionBase<any>[] {
    return [
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date',
        required: false,
        value:  this.dateService.dynamicDateToDateOption('%180_days_ago%'),
        mode: 'only_past'
      }),
      new CheckboxQuestion({
        key: 'success_ratio',
        value: false,
        cssClasses: 'form-control input-default',
      }),
    ];
  }

  public reset() {
    this.loading = false;
    this.recommendedCoupons = [];
    this.couponSelectedIndex = 0;
    this.inputs[0].value =  this.dateService.dynamicDateToDateOption('%180_days_ago%');
  }

  public close() {
    this.ngOnDestroy();
  }

  public recalculate() {
    this.getRecommendedCoupons();
  }

  private getRecommendedCoupons() {
    this.loading = true;
    const endpoint = `actions/${this.action.id}/recommend_coupons`;
    const recommender$ = this.refreshCacheService.postExpensiveData(endpoint, this.getPayload()).subscribe(
      (reqResponse) => {
        this.recommendedCoupons = reqResponse.map((el: object) => new RecommendedCoupon(el));
        this.loading = false;
        this.selectRow(0);
      },
      (errResponse: HttpErrorResponse) => {
        this.errResponse.emit(errResponse);
        this.loading = false;
      }
    );
    this.subs$.push(recommender$);
  }

  private getPayload(): object {
    const payload = {};
    if (Object.keys(this.form.value).length > 0 && this.form.value.date) {
      const dateId = this.form.value.date.id;
      const successRatio = this.form.value.success_ratio;
      if (dateId !== '%180_days_ago%') {
        payload['date'] = dateId;
      }
      if (successRatio) {
        payload['success_ratio'] = successRatio;
      } 
      this.showSuccessRatio = successRatio;
    }
    return payload;
  }
}
