import { Injectable } from "@angular/core";
import { SegmentConditionProvider } from "../../condition.service";
import { QuestionBase } from "../../../../models/forms/question-base";
import { formGroupEmptyValidator, multiselectPresenceValidator } from "../../../validations.service";
import { TranslateService } from "@ngx-translate/core";
import { DateService } from "../../../date.service";
import { OperatorsService } from "../../common/operators.service";
import { LocationsTaxonomyTermsService } from "../../../../../resources/data-warehouse/locations/location-taxonomy-terms.service";
import { LocationsService } from "../../../../../resources/data-warehouse/locations/locations.service";
import { defaultParseFormValuesToAPI, getParseDynamicDateValue, getParseMultiselectIdValues, getParseObjectKeysIdValue, getParseOperatorValue, getParseSelectedIdsValue, getParseSingleSelectOperatorIdValue, getParseValue, setLocationsFiltering } from "../../utils/common.utils";
import { MultiSelectQuestion } from "../../../../models/forms/question-multiselect";
import { DynamicDateQuestion } from "../../../../models/forms/question-dynamic-date";
import { PromotionCondition, PromotionConditionForm } from "../../../../models/segments/conditions/promotion-condition";
import { PromotionsService } from "../../../../../resources/data-warehouse/promotions/service/promotions.service";

@Injectable()
export class PromotionConditionService implements SegmentConditionProvider {

  inputs: QuestionBase<unknown>[];
  customValidators = formGroupEmptyValidator();

  private readonly operatorOpts = this.operatorsService.getHasUsedOperators();

  constructor(
    private readonly translate: TranslateService,
    private readonly dateService: DateService,
    private readonly promotionsService: PromotionsService,
    private readonly operatorsService: OperatorsService,
    private readonly locationsTaxonomyTermsService: LocationsTaxonomyTermsService,
    private readonly locationsService: LocationsService
  ) {}

  getInputs(params: PromotionConditionForm): QuestionBase<unknown>[] {
    setLocationsFiltering(params, this.locationsService);

    const inputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'promotion_id',
        label: this.translate.instant('resources.segment_conditions.fields.promotion_id'),
        options: this.operatorOpts,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.promotionsService,
        getValue: getParseSelectedIdsValue(),
        parseValue: getParseMultiselectIdValues(),
        required: true,
        customValidators: [multiselectPresenceValidator]
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'operator',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorOpts,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        getValue: getParseValue(),
        parseValue: getParseSingleSelectOperatorIdValue(this.operatorOpts),
        customValidators: [multiselectPresenceValidator]
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_from',
        label: this.translate.instant('resources.segment_conditions.fields.date_from'),
        getValue: getParseDynamicDateValue(this.dateService),
        parseValue: getParseObjectKeysIdValue()
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_to',
        label: this.translate.instant('resources.segment_conditions.fields.date_to'),
        getValue: getParseDynamicDateValue(this.dateService),
        parseValue: getParseObjectKeysIdValue()
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'location_taxonomy_term_ids',
        label: this.translate.instant('resources.segment_conditions.fields.location_taxonomy_term_ids'),
        dataSource: this.locationsTaxonomyTermsService,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        getValue: getParseSelectedIdsValue(),
        parseValue: getParseMultiselectIdValues()
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'location_ids',
        label: this.translate.instant('resources.segment_conditions.fields.location_ids'),
        dataSource: this.locationsService,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        getValue: getParseSelectedIdsValue(),
        parseValue: getParseMultiselectIdValues()
      })
    ];

    if (params) { this.prepareInputValuesFromParams(inputs, params); }
    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI(params: PromotionConditionForm): PromotionCondition {
    return defaultParseFormValuesToAPI(params, this.inputs);
  }

  prepareFormValuesFromAPI(params: PromotionCondition): PromotionConditionForm {
    const parseValues = {
      promotion_id: getParseValue(),
      operator: getParseOperatorValue(this.operatorOpts),
      date_from: getParseValue(),
      date_to: getParseValue(),
      location_taxonomy_term_ids: getParseValue(),
      location_ids: getParseValue()
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach( key => { parsedValuesObj[key] = parseValues[key](params[key]); });
    return parsedValuesObj;
  }

  private prepareInputValuesFromParams(inputs: QuestionBase<any>[], params: PromotionConditionForm): void {
    Object.keys(params).forEach(key => {
      const input = inputs.find(_input => _input.key === key);
      const value = input.getValue(params[key]);
      if (value?.hasOwnProperty('selectedIds') && value.selectedIds) {
        input.selectedIds = value.selectedIds;
      } else {
        input.value = value;
      }
    });
  }
}