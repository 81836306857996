// Core
import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { RefreshCacheService } from '../../../shared/services/refresh-cache.service';
import { getCurrencySymbol, registerLocaleData } from '@angular/common';
import { Subscription, zip } from 'rxjs';
import { SalesService } from './sales.service';
import { DateService } from '../../../shared/services/date.service';

// Highcharts
import { chart } from 'highcharts';
import * as Highcharts from 'highcharts';

// Dates
import es from '@angular/common/locales/es';
import * as moment from 'moment';
import { DataTableFilterService } from '../../../shared/components/data-table-filter/data-table-filter.service';
import { isNullOrUndefined } from 'util';
import { HighchartsConfigService } from '../../../shared/services/highcharts-config.service';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../../profiles/profile.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales-summary-dashboard.component.html',
  styleUrls: ['../analytics.scss'],
  providers: [HighchartsConfigService]
})

export class SalesSummaryDashboardComponent implements OnInit, OnDestroy {

  @ViewChild('areaChartTestExample', {static: true}) areaChartTestExample: ElementRef;

  filters: QuestionBase<any>[];
  filterSlug = 'sales_summary_dashboard';
  state = 'sales';
  average = 'ticketAverage';
  clients_count;
  clients_active;
  sales_total;
  clients_count_signups;
  clients_total_active: { customers: number };
  total_sales;
  identified_sales;
  ticket_average;
  identified_tickets;
  products_total: number;
  products_total_unidentified;
  total_sales_last_year;
  identified_sales_last_year;
  lastYearNum: string;
  currencySymbol: string;
  loaders = {
    salesBlock: true,
    ticketAverageBlock: true,
    customersBlock: true,
    salesGraph: true,
  };

  private tag_apply_filters;
  private registeredDates = {identified_sales: [], unidentified_sales: [], signups: [], segment_sales: []};
  private graphData = {identified_sales: [], unidentified_sales: [], signups: [], segment_sales: []};
  private isOneDay = false;
  private filteringBySegment = false;
  private salesChart: Highcharts.Chart;
  private subs$: Subscription[] = [];

  constructor(
    private refreshCacheService: RefreshCacheService,
    private salesService: SalesService,
    private dateService: DateService,
    private filterService: DataTableFilterService,
    private chartsCfgService: HighchartsConfigService,
    private translate: TranslateService,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    registerLocaleData(es);
    this.setServiceFiltersInitialDates();

    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');

    this.lastYearNum = this.getLastYearByFilter();

    this.initChart();
    this.prepareSalesRequests();
    this.callStatsMethods();

    this.filterService.loaderStatus.next(false);

    const filterChange$ = this.salesService.applyFilters$.subscribe(() => {

      this.registeredDates = {identified_sales: [], unidentified_sales: [], signups: [], segment_sales: []};

      this.clients_count = null;
      this.clients_active = null;
      this.sales_total = null;
      this.clients_count_signups = null;
      this.clients_total_active = { customers: null };
      this.total_sales = null;
      this.identified_sales = null;
      this.ticket_average = null;
      this.identified_tickets = null;
      this.products_total = null;
      this.products_total_unidentified = null;
      this.total_sales_last_year = null;
      this.identified_sales_last_year = null;

      this.lastYearNum = this.getLastYearByFilter();

      // Show main cards data
      this.showSales();
      this.showTicketAverage();

      // Init requests
      this.prepareSalesRequests();
      this.callStatsMethods();
      this.filterService.loaderStatus.next(false);
    });

    this.subs$.push(filterChange$);
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach( s$ => s$.unsubscribe() ); }
    this.salesService.removeSubscriptions();
  }

  showSales() {
    this.state = 'sales';
  }

  showActivities() {
    this.state = 'activities';
  }

  showTicketAverage() {
    this.average = 'ticketAverage';
  }

  showSpendingAverage() {
    this.average = 'spendingAverage';
  }

  private setServiceFiltersInitialDates() {
    this.salesService.filters.date_from = this.dateService.momentStartOfTypeAndFormat('month', 'YYYY-MM-DD');
    this.salesService.filters.date_to = this.dateService.momentEndOfTypeAndFormat('month', 'YYYY-MM-DD');
  }

  private callStatsMethods() {
    /* Customers / Signups requests */
    this.loaders.customersBlock = true;
    this.getClientsSignups();
    this.getClientsActive();
    /* Average ticket */
    this.loaders.ticketAverageBlock = true;
    this.getAverageTicket();
    this.getProductsIdentified();
    this.getProductsUnIdentified();
    this.getIdentifiedTickets();
    /* Sales / Activities block requests */
    this.loaders.salesBlock = true;
    this.getTotalSales();
    this.getIdentifiedSales();
    this.getTotalSalesFromLastYear();
    this.getIdentifiedSalesFromLastYear();
  }

  private prepareSalesRequests() {

    this.loaders.salesGraph = true;
    this.filteringBySegment = this.salesService.filters.hasOwnProperty('segment_id');

    this.isOneDay = (this.salesService.filters.date_from === this.salesService.filters.date_to);

    this.tag_apply_filters = { ...this.salesService.filters };
    delete this.tag_apply_filters.apply;
    delete this.tag_apply_filters.tag_id;

    if ( this.salesService.filters.apply && this.salesService.filters.tag_id ) {
      this.tag_apply_filters.no_tag_id = this.salesService.filters.tag_id;
      this.tag_apply_filters.no_appy = this.salesService.filters.apply;
    }

    const customerSales$ = this.salesService.customerSales$;
    const noCustomerSales$ = this.salesService.noCustomerSales$;
    const signUps$ = this.salesService.signUps$;

    if ( this.filteringBySegment ) {

      this.tag_apply_filters.apply = this.tag_apply_filters.no_apply;
      this.tag_apply_filters.tag_id = this.tag_apply_filters.no_tag_id;
      delete this.tag_apply_filters.no_apply;
      delete this.tag_apply_filters.no_tag_id;

      const segmentSales$ = this.salesService.segmentSales$;

      const salesRequestsWithSegment$ = zip( customerSales$, noCustomerSales$, signUps$, segmentSales$,
                                        (customerSales, noCustomerSales, signUps, segmentSales ) =>
          ({ customerSales, noCustomerSales, signUps, segmentSales })).subscribe(
          salesData => {
            this.prepareSalesData(salesData);
            salesRequestsWithSegment$.unsubscribe();
          }
        );

      this.subs$.push(salesRequestsWithSegment$);
      this.salesService.getSegmentSales({ ...this.tag_apply_filters });

    } else {

      const salesRequestsWithoutSegment$ = zip(customerSales$, noCustomerSales$, signUps$,
                                      ( customerSales, noCustomerSales, signUps ) =>
          ({ customerSales, noCustomerSales, signUps })).subscribe(
          salesData => {
            this.prepareSalesData(salesData);
            salesRequestsWithoutSegment$.unsubscribe();
          }
        );

      this.subs$.push(salesRequestsWithoutSegment$);
    }

    this.salesService.getCustomerSales({...this.tag_apply_filters, ...{ segment_id: undefined }});
    this.salesService.getNoCustomerSales({...this.salesService.filters, ...{ segment_id: undefined }});
    this.salesService.getSignups({...this.salesService.filters, ...{ segment_id: undefined }});
  }

  private prepareSalesData(salesData: {customerSales: any, noCustomerSales: any, signUps: any, segmentSales?: any}) {

    this.prepareGraphSales(salesData.customerSales, 'identified_sales');
    this.prepareGraphSales(salesData.noCustomerSales, 'unidentified_sales');

    this.graphData['signups'] = this.prepareSignupsData(salesData);
    this.forceZeros(this.isOneDay, 'signups');

    const segmentSerie = this.salesChart.series.find( serie => serie.color === '#336699' );
    if ( segmentSerie ) { segmentSerie.remove(true); }

    this.salesChart.series[0].setData(this.graphData.identified_sales, true);
    this.salesChart.series[0].name = this.translate.instant('dashboards.sales.labels.identified');
    this.salesChart.series[1].setData(this.graphData.unidentified_sales, true);
    this.salesChart.series[2].setData(this.graphData.signups, true);

    if ( this.filteringBySegment ) {
      this.prepareSegmentsData(salesData);
      this.salesChart.series[0].name = this.translate.instant('dashboards.sales.labels.other_identified_sales');
      const options: Highcharts.SeriesOptionsType = {
        type: 'area',
        name: this.translate.instant('dashboards.sales.labels.segment_sales'),
        color: '#336699',
        index: -1
      };
      this.salesChart.addSeries(options, false, true);
      this.salesChart.series[0].setData(this.graphData.segment_sales, true);
    }

    this.loaders.salesGraph = false;
    this.allLoadersFinished();
  }

  private prepareGraphSales(data, key: string) {
    if ( !data.hasOwnProperty('rows') ) { data.rows = []; }
    this.graphData[key] = data.rows.map( element => {
      const hour = (this.isOneDay ? element.f[5].v : 0);
      const date = (Date.UTC(element.f[4].v, element.f[3].v - 1, element.f[2].v, hour));
      this.registeredDates[key].push(date);
      return [date, Math.round(parseFloat(element.f[1].v))];
    });
    this.forceZeros(this.isOneDay, key);
  }

  private prepareSignupsData(salesData) {
    let hour; let date;
    const signups_total = salesData.signUps.rows || [];
    return signups_total.map( element => {
      hour = this.isOneDay ? element.f[4].v : 0;
      date = (Date.UTC(element.f[3].v, element.f[2].v - 1, element.f[1].v, hour));
      this.registeredDates.signups.push(date);
      return [date, parseInt(element.f[0].v, 10)];
    });
  }

  private prepareSegmentsData(salesData) {
    this.prepareGraphSales(salesData.segmentSales, 'segment_sales');
    this.graphData.identified_sales = this.graphData.identified_sales.map((el, index) => {
      el[1] = el[1] - this.graphData.segment_sales[index][1];
      return el;
    });
  }

  private forceZeros(isOneDay, type) {
    let interval;
    let currDate;
    let datets;

    if ( isOneDay ) {
      interval = 3600 * 1000;
      currDate = new Date( this.salesService.filters.date_from );
      const minimumTime = currDate.getTime() + 24 * 3600 * 1000;
      while ( currDate.getTime() <= minimumTime) {
        const hour = currDate.getHours();
        datets = Date.UTC(currDate.getFullYear(), currDate.getMonth(), currDate.getDate(), hour);
        currDate.setTime(currDate.getTime() + interval);
      }
    } else if (['signups', 'identified_sales', 'unidentified_sales', 'segment_sales' ].indexOf(type) >= 0) {
      interval = 24 * 3600 * 1000;
      currDate = new Date(this.salesService.filters.date_from);
      const endDate = new Date(this.salesService.filters.date_to);
      const fromDate = new Date(this.salesService.filters.date_from);
      let alreadyChanged = false;
      while (currDate.getTime() <= endDate.getTime()) {
        datets = Date.UTC(currDate.getFullYear(), currDate.getMonth(), currDate.getDate());
        if ( this.registeredDates[type].indexOf(datets) === -1 ) {
          this.chartsCfgService.interpolateDatePoint(this.graphData[type], datets, 0);
        }
        currDate.setTime(currDate.getTime() + interval);
        if (!alreadyChanged && this.dateService.hasChangedTimezone(fromDate, currDate)) {
          alreadyChanged = true;
          this.dateService.applyTimezoneChange(fromDate, currDate);
        }
      }
    }
  }

  private getTotalSales() {
    const copyParams = {...this.salesService.filters};
    if ( copyParams.hasOwnProperty('segment_id')) { delete copyParams.segment_id; }

    const params = {
      apiEndPoint: 'analytics/total_sum',
      filtering: copyParams
    };

    const totalSales$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        totalSales$.unsubscribe();
        this.total_sales = reqResponse;
        this.total_sales.total = Math.round(this.total_sales.total);
        this.salesActivitiesBlockReqFinished();
      }
    });
    this.subs$.push(totalSales$);
  }

  private getIdentifiedSales() {
    const params = {
      apiEndPoint: 'analytics/total_sum',
      filtering: {...this.salesService.filters, ...{ identified: true } }
    };

    const identifiedSales$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this.identified_sales = reqResponse;
        this.identified_sales.total = Math.round(this.identified_sales.total);
        identifiedSales$.unsubscribe();
        this.salesActivitiesBlockReqFinished();
      }
    });
    this.subs$.push(identifiedSales$);
  }

  private getAverageTicket() {
    const params = {
      apiEndPoint: 'analytics/total_sum',
      filtering: { ...this.tag_apply_filters, ...{ identified: false, segment_id: undefined } }
    };
    const ticketsAverage$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        ticketsAverage$.unsubscribe();
        this.ticket_average = reqResponse;
        this.ticketAverageBlockReqFinished();
      }
    });
    this.subs$.push(ticketsAverage$);
  }

  private getIdentifiedTickets() {
    const params = {
      apiEndPoint: 'analytics/total_sum',
      filtering: {...this.salesService.filters, ...{ identified: true } }
    };
    const identifiedTickets$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this.identified_tickets = reqResponse;
        this.ticketAverageBlockReqFinished();
        identifiedTickets$.unsubscribe();
      }
    });
    this.subs$.push(identifiedTickets$);
  }

  private getClientsSignups() {
    const params = {
      apiEndPoint: `analytics/clients_count`,
      filtering: {...this.salesService.filters, ...{ imported: false, nodates: true } }
    };
    const clientsCount$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this.clients_count = reqResponse;
        this.clientsBlockReqFinished();
        clientsCount$.unsubscribe();
      }
    });
    this.subs$.push(clientsCount$);

    // Filtered by dates (Altas)
    const filteredParams = {
      apiEndPoint: `analytics/clients_count`,
      filtering: {...this.salesService.filters, ...{ imported: false, nodates: false } }
    };
    const signUps$ = this.refreshCacheService.getExpensiveData(filteredParams).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        signUps$.unsubscribe();
        this.clients_count_signups = reqResponse;
        this.clientsBlockReqFinished();
      }
    });
    this.subs$.push(signUps$);
  }

  private getClientsActive() {
    // Totals
    const params = {
      apiEndPoint: `analytics/clients_active`,
      filtering: {...this.salesService.filters, ...{ nodates: true } }
    };
    const activeClients$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        activeClients$.unsubscribe();
        this.clients_active = reqResponse;
        this.clientsBlockReqFinished();
      }
    });
    this.subs$.push(activeClients$);

    // Filtered
    const filteredParams = {
      apiEndPoint: `analytics/clients_active`,
      filtering: {...this.salesService.filters, ...{ nodates: false } }
    };
    const totalActiveClients$ = this.refreshCacheService.getExpensiveData(filteredParams).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        totalActiveClients$.unsubscribe();
        this.clients_total_active = reqResponse;
        this.clientsBlockReqFinished();
      }
    });
    this.subs$.push(totalActiveClients$);
  }

  private getProductsIdentified() {
    const params = {
      apiEndPoint: 'analytics/products_quantity_total',
      filtering: {...this.salesService.filters, ...{ identified: true } }
    };
    const productsQuantityTotal$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        productsQuantityTotal$.unsubscribe();
        this.products_total = reqResponse.num_products / reqResponse.num_activities || 0;
        this.ticketAverageBlockReqFinished();
      }
    });
    this.subs$.push(productsQuantityTotal$);
  }

  private getProductsUnIdentified() {
    const params = {
      apiEndPoint: 'analytics/products_quantity_total',
      filtering: {...this.salesService.filters, ...{ identified: false, segment_id: undefined } }
    };
    const unidentifiedProduct$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        unidentifiedProduct$.unsubscribe();
        this.products_total_unidentified = reqResponse.num_products / reqResponse.num_activities || 0;
        this.ticketAverageBlockReqFinished();
      }
    });
    this.subs$.push(unidentifiedProduct$);
  }

  private clientsBlockReqFinished() {
    if ( this.clients_count &&
         this.clients_count_signups &&
         this.clients_active &&
         this.clients_total_active &&
         this.clients_total_active.customers ) {
      this.loaders.customersBlock = false;
      this.allLoadersFinished();
    }
  }

  private ticketAverageBlockReqFinished() {
    if ( !isNullOrUndefined(this.ticket_average) &&
         !isNullOrUndefined(this.products_total) &&
         !isNullOrUndefined(this.products_total_unidentified) &&
         !isNullOrUndefined(this.identified_tickets) ) {
      this.loaders.ticketAverageBlock = false;
      this.allLoadersFinished();
    }
  }

  private salesActivitiesBlockReqFinished() {
    if ( this.total_sales && this.identified_sales && this.total_sales_last_year && this.identified_sales_last_year ) {
      this.loaders.salesBlock = false;
      this.allLoadersFinished();
    }
  }

  private allLoadersFinished() {
    const loaderValues = Object.keys(this.loaders).every((key) => this.loaders[key] === false );
    if ( loaderValues ) { this.filterService.loaderStatus.next(loaderValues); }
  }

  private getTotalSalesFromLastYear() {
    let _params = {};
    const copyParams = {...this.salesService.filters};
    if ( copyParams.hasOwnProperty('segment_id')) { delete copyParams.segment_id; }
    if ( this.isOneDay ) {
      const uniqueDate = this.dateService.nPeriodsAgo( this.salesService.filters.date_from, 1, 'day' );
      _params = {
        apiEndPoint: 'analytics/total_sum',
        filtering: {...copyParams, ...{ date_from: uniqueDate, date_to: uniqueDate } }
      };
    } else {
      const date_from = this.dateService.calculateDate('substract', 1, 'year', this.salesService.filters.date_from);
      const date_to = this.dateService.calculateDate('substract', 1, 'year', this.salesService.filters.date_to);
      _params = {
        apiEndPoint: 'analytics/total_sum',
        filtering: {...copyParams, ...{ date_from: date_from, date_to: date_to } }
      };
    }

    const lastYearSales$ = this.refreshCacheService.getExpensiveData(_params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        lastYearSales$.unsubscribe();
        this.total_sales_last_year = reqResponse;
        this.salesActivitiesBlockReqFinished();
      }
    });
    this.subs$.push(lastYearSales$);
  }

  private getIdentifiedSalesFromLastYear() {
    const copyParams = {...this.salesService.filters};
    let _params = {};

    if ( this.isOneDay ) {
      const uniqueDate = this.dateService.nPeriodsAgo( this.salesService.filters.date_from, 1, 'day' );
      _params = {
        apiEndPoint: 'analytics/total_sum',
        filtering: {...copyParams, ...{ date_from: uniqueDate, date_to: uniqueDate, identified: true } }
      };
    } else {
      const date_from = this.dateService.calculateDate('substract', 1, 'year', this.salesService.filters.date_from);
      const date_to = this.dateService.calculateDate('substract', 1, 'year', this.salesService.filters.date_to);
      _params = {
        apiEndPoint: 'analytics/total_sum',
        filtering: {...copyParams, ...{ date_from: date_from, date_to: date_to, identified: true } }
      };
    }

    const identifiedSalesLastYear$ = this.refreshCacheService.getExpensiveData(_params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        identifiedSalesLastYear$.unsubscribe();
        this.identified_sales_last_year = reqResponse;
        this.salesActivitiesBlockReqFinished();
      }
    });
    this.subs$.push(identifiedSalesLastYear$);
  }

  private initChart() {
    this.salesChart = chart(this.areaChartTestExample.nativeElement, this.chartsCfgService.getConfig('area'));
  }

  private getLastYearByFilter() {
    let lastYear = moment(this.salesService.filters.date_from).startOf('month').format('YYYY-MM-DD');
        lastYear = moment(lastYear).subtract(1, 'year').format('YYYY');
    return lastYear;
  }

  public returnZeroIfNil(value): any {
    return value ? value : 0;
  }

  public numIsNaN(number: number): boolean {
    return Number.isNaN( number );
  }
}
