import { LocationsService } from "../../../../../data-warehouse/locations/locations.service";
import { LocationsTaxonomyTermsService } from "../../../../../data-warehouse/locations/location-taxonomy-terms.service";
import { MultiSelectQuestion } from "../../../../../../shared/models/forms/question-multiselect";
import { TextboxQuestion } from "../../../../../../shared/models/forms/question-textbox";
import { CustomReport } from "../../../report.model";
import { DictionaryService } from "../../../../../../shared/services/dictionary.service";
import * as moment from 'moment';
import { PlansService } from "../../../../../plans/plans.service";
import { CustomerJourneysService } from '../../../../../customer-journeys/customer-journeys.service';

/**
 * Defining ROI by store inputs and values assignment when creating or updating a ROIByStore report
 * @param *Service All instantiated services in component regarding the data sourceable inputs required in form
 * @param report When null we consider this to be the initialization of inputs for a new report instead of an update.
 * @returns An array of QuestionBase representing a form input.
 */
export function getROIByStoreInputs(
  dictionaryService: DictionaryService,
  locationsService: LocationsService,
  locationTaxonomyTermsService: LocationsTaxonomyTermsService,
  plansService: PlansService,
  report?: CustomReport,
  customerJourneyService?: CustomerJourneysService
): (MultiSelectQuestion | TextboxQuestion)[] {

  const months = dictionaryService.dictionaries.months;
  const currentMonth = months.find(month => month.id === moment().month() + 1);
  const currentYear = moment().year();
  const reportMonth = months.find(month => month.id === moment(report?.configuration?.start_date).month() + 1)
  const yearOpts = [
    { id: currentYear, name: currentYear },
    { id: currentYear - 1, name: currentYear - 1 },
    { id: currentYear - 2, name: currentYear - 2 }
  ];
  const reportYear = yearOpts.find(year => year.id === moment(report?.configuration?.start_date).year());

  return [
    new TextboxQuestion({
      key: 'name',
      type: 'text',
      cssClasses: 'form-control input-default',
      value: report?.name
    }),
    new MultiSelectQuestion({
      key: 'location_taxonomy_terms',
      cssClasses: 'form-control input-default',
      settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
      dataSource: locationTaxonomyTermsService,
      selectedIds: report?.configuration?.location_taxonomy_term_ids?.length ? report.configuration?.location_taxonomy_term_ids : []
    }),
    new MultiSelectQuestion({
      key: 'location_ids',
      cssClasses: 'form-control input-default',
      settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
      dataSource: locationsService,
      selectedIds: report?.configuration?.location_ids?.length ? report.configuration?.location_ids : [],
      required: true
    }),
    new MultiSelectQuestion({
      cssClasses: 'form-control input-default',
      key: 'month',
      options: dictionaryService.dictionaries.months,
      value: report ? [reportMonth] : [currentMonth],
      settings: {singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false},
      required: true
    }),
    new MultiSelectQuestion({
      cssClasses: 'form-control input-default',
      key: 'year',
      options: yearOpts,
      value: report ? [reportYear] : [yearOpts[0]],
      settings: {singleSelection: true, enableCheckAll: false, showCheckbox: false,enableSearchFilter: false},
      required: true
    }),
    new MultiSelectQuestion({
      cssClasses: 'form-control input-default',
      key: 'plan_ids',
      dataSource: plansService,
      selectedIds: report?.configuration?.plan_ids?.length ? report.configuration?.plan_ids.map(item => item) : [],
      settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
      required: true
    }),
    new MultiSelectQuestion({
      cssClasses: 'form-control input-default',
      key: 'campaign_block_ids',
      dataSource: customerJourneyService,
      selectedIds: report?.configuration?.campaign_block_ids?.length ? report.configuration?.campaign_block_ids.map(item => item) : [],
      settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
      required: true
    }),
  ];
}