export const ForecastContentMock = {
  id: '',
  updated_at: '',
  errors: [],
  data: {
    customers:                 300,
    max_redemption_per_coupon: 5,
    estimated_delivery:        60,
    delivered:                 180,
    delivery_cost:             11.11111,
    estimated_redemption:      20,
    unique_redemptions:        86.67,
    redemption_per_customer:   1.5,
    total_redemptions:         54,
    discount:                  10,
    discount_type:             'cash',
    min_purchase:              30.0,
    min_purchase_type:         'money',
    discount_over_purchase:    0.33,
    discount_cost:             540.0,
    loyal_guru_cost:           81.0,
    incremental:               900.0,
    incremental_ratio:         1.44,
    incremental_over_sales:    0.24,
    affectation_sales:         77684.04
  }
}
