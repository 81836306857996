import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { DateService } from '../../../../../shared/services/date.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from '../../../../../shared/utils/common.utils';
import { Plan } from '../../../plan';
import { PlanCampaign } from '../../plan-campaign';
import { PlanCampaignService } from '../../plan-campaign.service';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { Subscription } from 'rxjs';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-audience-dates',
  templateUrl: './audience-dates.component.html',
  styleUrls: ['./audience-dates.component.css']
})

export class AudienceDatesComponent implements OnInit, OnDestroy {

  @Input() plan: Plan;
  @Input() planCampaign: PlanCampaign;
  @Output() campaignEmitter = new EventEmitter<PlanCampaign>();

  audienceDatesForm: UntypedFormGroup;
  inputs: QuestionBase<any>[];
  isEditionEnabled = false;
  isEditionPrevented = false;
  isEditionVisible = true;
  loading = false;
  subs$: Subscription[] = [];
  userDatesPresent = false;

  defaultDates: {
    userAudienceStartDate: string;
    userAudienceEndDate: string
  };

  constructor(
    private confirmationService: ConfirmationService,
    private dateService: DateService,
    private planCampaignService: PlanCampaignService,
    private qcs: QuestionControlService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.defaultDates = {
      userAudienceStartDate: this.getDefaultStartDate(this.plan),
      userAudienceEndDate: this.getDefaultEndDate()
    }
    this.userDatesPresent = this.areUserDatesPresent(this.planCampaign);
    this.inputs = this.getInputs(this.planCampaign);
    this.audienceDatesForm = this.qcs.toFormGroup(this.inputs);
    this.audienceDatesForm.setValidators(this.getValidators());
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  toggleEdition() {
    this.audienceDatesForm.reset({user_audience_start_date: null, user_audience_end_date: null}, {emitEvent: true});
    this.isEditionEnabled = this.isEditionEnabled ? false : true;
  }

  markAsDirty(str: string) {
    this.audienceDatesForm.get(str).markAsDirty();
  }

  save() {
    this.loading = true;
    const payload = this.audienceDatesForm.value;

    const planCampaign$ = this.planCampaignService.patchCampaign(payload, `${this.planCampaign.id}`).subscribe(
      (responseData: HttpResponse<Object>) => this.handleSuccessfulSave(responseData),
      (errorData: HttpErrorResponse) => {
        this.loading = false;
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error);
      }
    );
    this.subs$.push(planCampaign$);
  }

  useDefaultDates() {
    this.loading = true;
    const payload = {
      user_audience_start_date: null,
      user_audience_end_date: null
    };
    const planCampaign$ = this.planCampaignService.patchCampaign(payload, `${this.planCampaign.id}`).subscribe(
      (responseData: HttpResponse<Object>) => this.handleSuccessfulSave(responseData),
      (errorData: HttpErrorResponse) => {
        this.loading = false;
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error);
      }
    );
    this.subs$.push(planCampaign$);
  }

  private handleSuccessfulSave(responseData: HttpResponse<Object>) {
    this.loading = false;
    this.isEditionEnabled = false;
    this.planCampaign = new PlanCampaign(responseData);
    this.userDatesPresent = this.areUserDatesPresent(this.planCampaign);
    this.campaignEmitter.emit(this.planCampaign);
  }

  private areUserDatesPresent(planCampaign: PlanCampaign): boolean {
    return !isNullOrUndefined(planCampaign.userAudienceStartDate) && !isNullOrUndefined(planCampaign.userAudienceEndDate);
  }

  private getInputs(formValues): QuestionBase<any>[] {
    return [
      new TextboxQuestion({
        label: this.translate.instant('resources.campaign_plans.fields.user_audience_start_date'),
        cssClasses: 'form-control input-md',
        key: 'user_audience_start_date',
        type: 'date',
        required: true,
        value: formValues?.userAudienceStartDate
      }),
      new TextboxQuestion({
        label: this.translate.instant('resources.campaign_plans.fields.user_audience_end_date'),
        cssClasses: 'form-control input-md',
        key: 'user_audience_end_date',
        type: 'date',
        required: true,
        value: formValues?.userAudienceEndDate
      })
    ];
  }

  private getValidators(): ValidatorFn[] {
    return [
      (form: UntypedFormGroup) => {
        const from = form.get('user_audience_start_date');
        const to = form.get('user_audience_end_date');
        if (this.dateService.isDate2BeforeDate1(from.value, to.value)){
          return this.setError(to, {invalidDateRange: true});
        } else {
          to.setErrors(null);
        }
        return null;
      },
      (form: UntypedFormGroup) => {
        const from = form.get('user_audience_start_date');
        if (!this.dateService.isValidDate(from.value)){
          return this.setError(from, {invalidDate: true});
        } else {
          form.setErrors(null);
        }
        return null;
      },
      (form: UntypedFormGroup) => {
        const to = form.get('user_audience_end_date');
        if (!this.dateService.isValidDate(to.value)){
          return this.setError(to, {invalidDate: true});
        } else {
          form.setErrors(null);
        }
        return null;
      }
    ];
  }

  private setError(control: AbstractControl, errorHash: object): object {
    control.setErrors(errorHash);
    return errorHash;
  }

  private getDefaultStartDate(plan: Plan): string {
    const planNDays = this.dateService.getDatesDiffByTimeUnit(new Date(plan.available_from), new Date(plan.available_to), 'days', true);
    return this.dateService.calculateDate('substract', planNDays*3, 'days');
  }

  private getDefaultEndDate(): string {
    return this.dateService.calculateDate('substract', 1, 'days');
  }
}
