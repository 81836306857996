<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-10">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form [formGroup]="executeDateForm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="alert alert-info d-flex align-items-center">
                      <em class="fa-regular fa-exclamation-circle mrg-right-10 font-size-16"></em>
                      <span class="font-size-14" [innerHTML]="executionTaskStepInformation"></span>
                    </div>
                    <div class="form-group">
                      <app-question [question]="getInputConfig('date_from')" [form]="executeDateForm"></app-question>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
