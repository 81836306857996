<div class="modal-backdrop fade in modal-fs" id='modal-fs' tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="element">

      <div class="row">
        <div class="col-md-12">
          <div class="modal-header no-pdd">
            <div class="col-md-4">
              <h4>{{'resources.coupons.modal.show.details.fields.coupon_details' | translate}}: <strong>{{element.name}}</strong></h4>
            </div>
            <!-- FILTER -->
            <div class="col-md-3 mrg-right-15 mrg-top-15">
              <label class="text-semibold text-dark"
                [translate]="'resources.coupons.modal.show.details.fields.selector_label'"></label>
              <app-question [question]="getInputConfig('coupon')" (multiselectChanged)="handleHistoryChange($event)"
                [form]="showCouponsForm"></app-question>
            </div>
            <div class="col-md-3 mrg-right-15 mrg-top-15" *ngIf="selectedTab === 'statistics'">
              <label class="text-semibold text-dark"
                [translate]="'resources.coupons.modal.show.details.fields.locations'"></label>
              <app-question [question]="getInputConfig('locations')"
                (multiselectChanged)="handleLocationsChange($event)" [form]="showCouponsForm"></app-question>
            </div>
            <!-- END FILTER -->
            <div class="pdd-vertical-20-but-bottom">
              <button class="modal-close mrg-right-25" (click)="closeModal()">
                <i class="ti-close"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="tab-info tab-background" *ngIf="element">
            <!-- Tabs menu -->
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item">
                <a href="#default-tab-1" (click)="refreshUrl('details')"
                  [ngClass]="{'active': selectedTab === 'details'}" class="nav-link padding-15" role="tab"
                  data-toggle="tab" [translate]="'resources.coupons.modal.show.tabs.details'">
                </a>
              </li>
              <li class="nav-item" *ngIf="selectedMotivator">
                <a href="#default-tab-2" (click)="refreshUrl('statistics')"
                  [ngClass]="{'active': selectedTab === 'statistics'}" class="nav-link padding-15" role="tab"
                  data-toggle="tab" [translate]="'resources.coupons.modal.show.tabs.statistics'">
                </a>
              </li>
              <li class="nav-item">
                <a href="#default-tab-3" (click)="refreshUrl('localized_content')"
                  [ngClass]="{'active': selectedTab === 'localized_content'}" class="nav-link padding-15" role="tab"
                  data-toggle="tab" [translate]="'resources.coupons.modal.show.tabs.localized_content'">
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="modal-body height-100">
        <!-- Tabs content -->
        <div class="tab-content">
          <!-- TAB DETAILS CONTENT-->
          <div role="tabpanel" [ngClass]="{'in active': selectedTab === 'details'}" class="tab-pane fade"
            id="default-tab-1">
            <form [formGroup]="showCouponsForm">
              <div class="row">
                <div class="col-md-12">
                  <div class="row mrg-top-15">
                    <!-- BLOQUE1 -->
                    <div class="col">
                      <app-show-history [couponDetail]="element"></app-show-history>
                    </div>
                    <!-- END BLOQUE1 -->

                    <!-- BLOQUE2 -->
                    <div class="col">
                      <app-show-history-info [couponDetail]="element"></app-show-history-info>
                    </div>
                    <!-- END BLOQUE2 -->

                    <!-- BLOQUE3 FEATURES-->
                    <div class="col" *ngIf="element.discount && element.affectation">
                      <app-show-affectation [couponDetail]="element"></app-show-affectation>
                    </div>
                    <!-- END BLOQUE3 -->
                  </div>
                </div>
              </div>
            </form>

            <!-- HISTORIC DATA TABLE -->
            <div class="card mrg-top-15">
              <div class="card-heading border bottom">
                <h4 class="card-title"
                  [translate]="'resources.coupons.modal.show.details.table.title'"></h4>
              </div>
              <div class="card-block">
                <app-data-table *ngIf="dataTableConfig" [config]="dataTableConfig"></app-data-table>
              </div>
            </div>
            <!-- END TABLE -->
          </div>

          <!-- STATISTICS -->
          <div role="tabpanel" [ngClass]="{'in active': selectedTab === 'statistics'}" class="tab-pane fade"
            id="default-tab-2">
            <app-show-statistics #statisticsComponent [apply]="apply" [couponDetail]="element"
              *ngIf="selectedTab === 'statistics'"></app-show-statistics>
          </div>
          <!-- Lozalized Content Tab -->
          <div role="tabpanel" [ngClass]="{'in active': selectedTab === 'localized_content'}" class="tab-pane fade"
            id="default-tab-3">
            <div class="row mrg-top-15">
              <div class="col">
                <div class="card">
                  <div class="card-block">
                    <div class="alert alert-warning" role="alert" *ngIf="!couponData.available_languages">
                      <span>{{ 'resources.coupons.messages.no_available_languages' | translate }}</span>
                    </div>
                    <app-language-accordions [inputs]="showCouponInputs"
                                             [form]="showCouponsForm"
                                             [mode]="mode"
                                             [filteredAvailableLanguages]="filteredAvailableLanguages">
                    </app-language-accordions>
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- FOOTER -->
      <ng-container *ngIf="roleSlug !== 'customer_service' && roleSlug !== 'store_manager'">
        <div class="modal-footer background-white mrg-right-4" *ngIf="!historyId">
          <button class="btn btn-info"
            [routerLink]="['', { outlets: { modal: ['update', 'coupons', element.id] } }]">{{'components.show.buttons.edit' | translate}}</button>
        </div>

        <div class="modal-footer background-white mrg-right-4" *ngIf="historyId">
          <button class="btn btn-info"
            [routerLink]="['', { outlets: { modal: ['update', 'coupons', id, 'histories', historyId] } }]">{{'components.show.buttons.edit' | translate}}</button>
        </div>
      </ng-container>
      <!-- END FOOTER -->

    </div>
  </div>

</div>



<!-- MODAL LISTADO LOCATIONS -->
<div class="modal fade" id="modal-locations">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="padding-15">
          <div class="row">
            <!-- FORMS -->
            <div class="col-md-12">
              <h5 [translate]="'resources.coupons.modal.show.details.fields.locations'"></h5>
              <div *ngIf="element">
                <div class="card">
                  <ul class="list tick bullet-success ul-style mrg-top-15 mrg-left-15">
                    <li *ngFor="let item of element._embedded.locations">
                      {{item.name}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END MODAL -->

<!-- MODAL LISTADO FEATURES -->
<div class="modal fade" id="modal-features">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="padding-15">
          <div class="row">
            <!-- FORMS -->
            <div class="col-md-12">
              <h5 [translate]="'resources.coupons.modal.show.details.fields.features'"></h5>
              <div *ngIf="element">
                <div class="card">
                  <ul class="list tick bullet-success ul-style mrg-top-15 mrg-left-15">
                    <li *ngFor="let item of element.features_list">
                      {{item.name}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END MODAL -->

<!-- MODAL LISTADO FEATURES LOCATION-->
<div class="modal fade" id="modal-featureLocations">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="padding-15">
          <div class="row">
            <!-- FORMS -->
            <div class="col-md-12">
              <h5 [translate]="'resources.coupons.modal.show.details.fields.feature_locations'"></h5>
              <div *ngIf="element">
                <div class="card">
                  <ul class="list tick bullet-success ul-style mrg-top-15 mrg-left-15">
                    <li *ngFor="let item of element._embedded.location_taxonomy_terms">
                      {{item.name}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END MODAL -->
