import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, finalize, takeUntil } from 'rxjs';
import { ReportsService } from '../reports.service';
import { DataTableComponent } from '../../../../shared/components/data-table/data-table.component';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportData } from '../report.model';
import { DataTableFilterService } from '../../../../shared/components/data-table-filter/data-table-filter.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';

@Component({
  selector: 'app-edit-report',
  templateUrl: './edit-report.component.html',
  styleUrls: ['./edit-report.component.scss'],
  providers: [ReportsService]
})

export class EditReportComponent implements OnInit, OnDestroy {

  inputs: QuestionBase<any>[];
  reportForm: UntypedFormGroup;
  id: number;
  reportData: ReportData;
  id1: number;
  id2: number;
  idOver: number;
  loading: boolean;
  reportId: number;
  isUpdate: boolean;
  isNewReport: boolean;
  newReportName: { name: string; };
  updateMode: boolean;

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private reportService: ReportsService,
    private filterService: DataTableFilterService,
    private modalStatusSrv: ModalStatusService
  ) { }

  ngOnInit() {
    this.getParams();
    this.setMode();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  deleteReport(report) {
    this.confirmationService.displayConfirmationAlert(
      this.translate.instant('resources.reports.warnings.generate_title'),
      this.translate.instant('resources.reports.warnings.confirm_delete'),
    ).then(data => {
        if ( data.hasOwnProperty('value') && data.value) {
          this.doDelete(report.id);
        }
      }
    ).catch(() => {});
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  getDetailSelection(event:{id: string}) {
    if (event && ['incremental', 'segment_evo', 'roi_by_store', 'coupons_in_app_analysis'].includes(event.id)) {
      const routeMap = {
        incremental: 'report_incremental',
        segment_evo: 'report_segment',
        roi_by_store: 'report_roi_by_store',
        coupons_in_app_analysis: 'report_coupons_in_app_analysis',
      };
      this.router.navigate([{ outlets: { modal: `reports/${this.id}/${routeMap[event.id]}/new` } }]).catch(() => {});
    }
  }

  dragStart(item: number) {
    this.id1 = item;
  }

  dragOver(item: number) {
    this.idOver = item;
  }

  dragLeave(item: number) {
    this.id2 = item;
  }

  dragEnd() {
    if (this.id1 !== this.id2) {
      this.onExchange(this.id1, this.id2);
    }
    this.idOver = null;
  }

  onExchange(id1: number, id2: number) {
    const item1 = this.reportData.report_details.find((item: {id: number}) => item.id === id1);
    const item2 = this.reportData.report_details.find((item: {id: number}) => item.id === id2);

    if (item1 && item2) {
      // Swap the items
      const index1 = this.reportData.report_details.indexOf(item1);
      const index2 = this.reportData.report_details.indexOf(item2);
      if (index1 !== -1 && index2 !== -1) {
        [this.reportData.report_details[index1], this.reportData.report_details[index2]] = [item2, item1];
      }
    }

    this.recalculatePositions();
    this.onUpdateReportDetails();
  }

  refreshCache() {
    const dataToSend = [];
    this.reportService.refreshCache(dataToSend, this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        const title = this.translate.instant('resources.reports.form.warnings.refresh_cache_title');
        const text = this.translate.instant('resources.reports.form.warnings.refresh_cache_text');
        this.confirmationService.displayAlert( title, text, 'warning').catch(() => {});
      },
      error: (error: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(error)
    });
  }

  sendData() {
    this.onUpdateReportDetails();
  }

  backTo() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  showReport() {
    this.router.navigate([{ outlets: { modal: `show/reports/${this.id}` } }]).catch(() => {});
  }

  setMode() {
    if (this.router.url.indexOf('(modal:update') >= 0 ) {
      this.updateMode = true;
    }
  }

  createNewReport() {
    if (this.reportForm.value.name) {
      this.newReportName = {
        name: this.reportForm.value.name,
      };
    }

    this.loading = true;
    this.reportService.createReport(this.newReportName)
      .pipe(takeUntil(this.destroy$), finalize(() => {
        this.loading = false;
        this.filterService.loaderStatus.next(true);
      }))
      .subscribe({
        next: response => {
          this.getReportById(response.id);
          this.router.navigate([{ outlets: { modal: `update/reports/${response.id}` } }]).catch(() => {});
          this.modalStatusSrv.modalStatus.emit();
        },
        error: (error: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(error)
    })
  }

  generateReport() {
    const reportData = {};
    const generateTitle = this.translate.instant('resources.reports.warnings.generate_title');
    const generateText = this.translate.instant('resources.reports.warnings.generate_text');

    this.confirmationService.displayConfirmationAlert(generateTitle, generateText).then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.reportService.generateReport(reportData, this.id).pipe(takeUntil(this.destroy$)).subscribe({
          next: () => {
            this.modalStatusSrv.modalStatus.emit();
            const generate_confirmation_title = this.translate.instant('resources.reports.warnings.generate_confirmation_title');
            const generate_confirmation_text = this.translate.instant('resources.reports.warnings.generate_confirmation_text');
            this.confirmationService.displaySuccessAlert(generate_confirmation_title, generate_confirmation_text).catch(() => {});
            this.closeModal();
          },
          error: (error: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(error)
        });
      }
    }).catch(() => {});
  }

  private doDelete(report) {
    this.reportService.deleteReportDetail(this.id, report).pipe(takeUntil(this.destroy$)).subscribe({
      next: () =>  {
        this.confirmationService.displaySuccessAlert('', this.translate.instant('resources.reports.warnings.delete_success')).catch(() => {});
      },
      error: (error: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(error)
    });
  }

  private recalculatePositions() {
    for (const i in this.reportData.report_details) {
      if (i) {
        this.reportData.report_details[i]['position'] = Number(i) + 1;
      }
    }
  }

  private onUpdateReportDetails( ) {
    const reportDetails = {
      id: this.reportData.id,
      name: this.reportForm.value.name,
      report_details: this.reportData.report_details
    };

    this.loading = true;
    if (this.reportId) {
       this.reportService.updateReportDetails(reportDetails, this.id)
        .pipe(takeUntil(this.destroy$), finalize(() => this.loading = false))
        .subscribe({
          next: () => {
            this.modalStatusSrv.modalStatus.emit();
            this.confirmationService.displaySuccessAlert(
              this.translate.instant('resources.reports.warnings.update_success_title'),
              this.translate.instant('resources.reports.warnings.update_success_text')
            ).catch(() => {});
          },
          error: (error: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(error)
        }
      );
    }
  }

  private getParams() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe({
      next: (params: {id: number}) => {
        if (params.hasOwnProperty('id')) {
          this.id = params.id;
          this.getReportById(this.id);
        } else {
          this.inputs = this.reportService.setInputs({});
          this.reportForm = this.qcs.toFormGroup(this.inputs);
        }
      }
    });
  }

  private getReportById(id: number) {
    this.reportService.fetchSelectedById(id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (data: ReportData) => {
        this.reportData = data;
        this.reportId = this.reportData.id;
        this.inputs = this.reportService.setInputs(data);
        this.reportForm = this.qcs.toFormGroup(this.inputs);
      },
      error: (error: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(error)
    });
  }
}
