<div class="container-fluid clearfix">  
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" *ngIf="element" aria-hidden="true">
              <tbody>
                <tr *ngIf="element.id">
                  <td class="no-border-top"><b [translate]="'resources.credits.fields.id'"></b></td>
                  <td class="no-border-top">{{element.id}}</td>
                </tr>
                <tr *ngIf="element.kind">
                  <td><b [translate]="'resources.credits.fields.kind'"></b></td>
                  <td *ngIf="element.kind === 'sms'" translate>resources.campaigns.channels.sms</td>
                  <td *ngIf="element.kind === 'email'" translate>resources.campaigns.channels.email</td>
                </tr>
                <tr *ngIf="element.amount">
                  <td><b [translate]="'resources.credits.fields.amount'"></b></td>
                  <td>{{element.amount | number: '1.4-4'}}</td>
                </tr>
                <tr *ngIf="element.action">
                  <td><b [translate]="'resources.credits.fields.action'"></b></td>             
                  <td *ngIf="element.action === 'in'"><span class="label label-success" [translate]="'resources.credits.actions.in'"></span></td>
                  <td *ngIf="element.action === 'out'"><span class="label label-danger" [translate]="'resources.credits.actions.out'"></span></td>
                  <td *ngIf="element.action === 'hold'"><span class="label label-warning" [translate]="'resources.credits.actions.hold'"></span></td>
                  <td *ngIf="element.action === 'release'"><span class="label label-primary" [translate]="'resources.credits.actions.release'"></span></td>
                </tr>
                <tr *ngIf="element.notes">
                  <td><b [translate]="'resources.credits.fields.notes'"></b></td>
                  <td>{{element.notes}}</td>
                </tr>
                <tr *ngIf="element.created_at">
                  <td><b [translate]="'resources.credits.fields.created_at'"></b></td>
                  <td>{{element.created_at | date: 'dd/MM/yyyy'}}</td>
                </tr>
                <tr *ngIf="element.source_type">
                  <td><b [translate]="'resources.credits.fields.friendly_source'"></b></td>
                  <td *ngIf="element.source_type === 'CampaignHistory'">
                    <a [routerLink]="['', { outlets: { modal: ['show', 'campaigns', element.source_type_id]}}]">
                      {{element.friendly_source}}
                    </a>
                  </td>
                  <td *ngIf="element.source_type === 'Account'">
                    <a [routerLink]="['', { outlets: { modal: ['show', 'users', element.source_type_id]}}]">
                      {{element.friendly_source}}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
