import { ReportRoiByStoreHeader, ReportRoiByStoreByCampaign } from "../model/report-roi-by-stores.model";

export const ReportRoiByStoreBaseHeaders: ReportRoiByStoreHeader[] = [
  {
    key: 'campaign_category',
    translateKey: 'resources.reports.roi_by_store.fields.campaign_category',
    display: true,
    calculateValueFn: (element: ReportRoiByStoreByCampaign): string => {
      return element.rawResults[0].campaign_category;
    }
  },
  {
    key: 'campaign_id',
    translateKey: 'resources.reports.roi_by_store.fields.campaign_id',
    display: true,
    calculateValueFn: (element: ReportRoiByStoreByCampaign): number => {
      return element.rawResults[0].campaign_id;
    }
  },
  {
    key: 'campaign_name',
    translateKey: 'resources.reports.roi_by_store.fields.campaign_name',
    display: true,
    calculateValueFn: (element: ReportRoiByStoreByCampaign): string => {
      return element.rawResults[0].campaign_name;
    }
  },
  {
    key: 'origin_id',
    translateKey: 'resources.reports.roi_by_store.fields.origin_id',
    display: true,
    calculateValueFn: (element: ReportRoiByStoreByCampaign): number => {
      const hasPlanId = element.rawResults[0].plan_id;
      element['groupedCalculations']['origin_type'] = hasPlanId ? 'plan' : 'campaign_block';
      return hasPlanId ? element.rawResults[0].plan_id : element.rawResults[0].campaign_block_id;
    }
  },
  {
    key: 'start_date',
    translateKey: 'resources.reports.roi_by_store.fields.start_date',
    display: true,
    calculateValueFn: (element: ReportRoiByStoreByCampaign): string => {
      return element.rawResults[0].start_date;
    }
  },
  {
    key: 'end_date',
    translateKey: 'resources.reports.roi_by_store.fields.end_date',
    display: true,
    calculateValueFn: (element: ReportRoiByStoreByCampaign): string => {
      return element.rawResults[0].end_date;
    }
  },
  {
    key: 'location_id',
    translateKey: 'resources.reports.roi_by_store.fields.store_id'
  },
  {
    key: 'location_name',
    translateKey: 'resources.reports.roi_by_store.fields.location_name'
  },
  {
    key: 'coupon_id',
    translateKey: 'resources.reports.roi_by_store.fields.coupon_id',
    display: true,
    calculateValueFn: (element: ReportRoiByStoreByCampaign): number => {
      return element.rawResults[0].coupon_id;
    }
  },
  {
    key: 'discount',
    translateKey: 'resources.reports.roi_by_store.fields.coupon_discount',
    display: true,
    calculateValueFn: (element: ReportRoiByStoreByCampaign): string => {
      return element.rawResults[0].discount;
    }
  },
  {
    key: 'discount_type',
    translateKey: 'resources.reports.roi_by_store.fields.coupon_discount_type',
    display: true,
    calculateValueFn: (element: ReportRoiByStoreByCampaign): string => {
      return element.rawResults[0].discount_type;
    },
    csv: false
  },
  {
    key: 'min_purchase',
    translateKey: 'resources.reports.roi_by_store.fields.coupon_min_purchase',
    display: true,
    calculateValueFn: (element: ReportRoiByStoreByCampaign): number => {
      return element.rawResults[0].min_purchase;
    }
  },
  {
    key: 'min_units',
    translateKey: 'resources.reports.roi_by_store.fields.coupon_min_purchase',
    display: true,
    calculateValueFn: (element: ReportRoiByStoreByCampaign): number => {
      return element.rawResults[0].min_units;
    },
    csv: false
  },
  // Only will apply for C. Journeys
  {
    key: 'customers_impacted_by_informative_campaign',
    translateKey: 'resources.reports.roi_by_store.fields.impacted_customers_informative',
    display: true,
    summable: true
  },
  {
    key: 'customers_impacted_by_coupon',
    translateKey: 'resources.reports.roi_by_store.fields.impacted_customers_coupon',
    summable: true,
    display: true
  },
  // Only will apply for C. Journeys
  {
    key: 'reaching_goal',
    translateKey: 'resources.reports.roi_by_store.fields.reaching_goal',
    summable: true,
    display: true
  },
  {
    key: 'printed_vouchers',
    translateKey: 'resources.reports.roi_by_store.fields.printed_vouchers',
    summable: true,
    display: true
  },
  {
    key: 'printed_vouchers_ratio',
    translateKey: 'resources.reports.roi_by_store.fields.printed_vouchers_ratio',
    display: true,
    calculateValueFn: (element: ReportRoiByStoreByCampaign): number => {
      if (element.groupedCalculations.customers_impacted_by_coupon === 0) return 0;
      return element.groupedCalculations.printed_vouchers / element.groupedCalculations.customers_impacted_by_coupon;
    }
  },
  {
    key: 'unique_voucher_redemptions',
    translateKey: 'resources.reports.roi_by_store.fields.unique_voucher_redemptions',
    summable: true,
    display: true
  },
  {
    key: 'unique_voucher_redemptions_ratio',
    translateKey: 'resources.reports.roi_by_store.fields.unique_voucher_redemptions_ratio',
    display: true,
    calculateValueFn: (element: ReportRoiByStoreByCampaign): number => {
      if (element.groupedCalculations.printed_vouchers === 0) return 0;
      return element.groupedCalculations.unique_voucher_redemptions / element.groupedCalculations.printed_vouchers;
    }
  },
  {
    key: 'discount_cost',
    translateKey: 'resources.reports.roi_by_store.fields.discount_cost',
    summable: true,
    display: true
  },
  {
    key: 'estimated_incremental_sales',
    translateKey: 'resources.reports.roi_by_store.fields.estimated_incremental_sales',
    summable: true,
    display: true
  },
  {
    key: 'incremental_ratio',
    translateKey: 'resources.reports.roi_by_store.fields.incremental_ratio',
    display: true,
    calculateValueFn: (element: ReportRoiByStoreByCampaign): number => {
      if (element.groupedCalculations.discount_cost === 0) return 0;
      return element.groupedCalculations.estimated_incremental_sales / element.groupedCalculations.discount_cost;
    }
  }
];

export const ReportRoiByStoreTableHeaders = [
  {
    key: 'campaign_category',
    translateKey: 'resources.reports.roi_by_store.columns.campaign_category'
  },
  {
    key: 'campaign_name',
    translateKey: 'resources.reports.roi_by_store.columns.campaign_name'
  },
  {
    key: 'origin_id',
    translateKey: 'resources.reports.roi_by_store.columns.origin_id'
  },
  {
    key: 'dates',
    translateKey: 'resources.reports.roi_by_store.columns.dates'
  },
  {
    key: 'coupon_id',
    translateKey: 'resources.reports.roi_by_store.columns.coupon_id'
  },
  {
    key: 'discount',
    translateKey: 'resources.reports.roi_by_store.columns.coupon_discount'
  },
  {
    key: 'min_purchase',
    translateKey: 'resources.reports.roi_by_store.columns.coupon_min_purchase'
  },
  {
    key: 'customers_impacted_by_informative_campaign',
    translateKey: 'resources.reports.roi_by_store.columns.customers_impacted_by_informative_campaign'
  },
  {
    key: 'customers_impacted_by_coupon',
    translateKey: 'resources.reports.roi_by_store.columns.customers_impacted_by_coupon'
  },
  {
    key: 'reaching_goal',
    translateKey: 'resources.reports.roi_by_store.columns.reaching_goal'
  },
  {
    key: 'printed_vouchers',
    translateKey: 'resources.reports.roi_by_store.columns.printed_vouchers'
  },
  {
    key: 'total_redemptions',
    translateKey: 'resources.reports.roi_by_store.columns.total_redemptions'
  },
  {
    key: 'discount_cost',
    translateKey: 'resources.reports.roi_by_store.columns.discount_cost'
  },
  {
    key: 'estimated_incremental_sales',
    translateKey: 'resources.reports.roi_by_store.columns.estimated_incremental_sales'
  },
  {
    key: 'incremental_ratio',
    translateKey: 'resources.reports.roi_by_store.columns.incremental_ratio'
  },
];
