<div class="row" *ngIf="form">

  <div class="col">
    <div class="form-group">
      <app-question [question]="getInputConfig('operator')"
                    [form]="form"
                    (multiselectChanged)="handleOperatorChanged($event)"></app-question>
    </div>
  </div>

  <div class="col-md-1" *ngIf="!hasFormKeyWithValue('operator', 'bt'); else btOperator">
    <div class="form-group">
      <app-question [question]="getInputConfig('value')" [form]="form"></app-question>
    </div>
  </div>

  <ng-template #btOperator>
    <div class="col-md-1" >
      <div class="form-group">
        <app-question [question]="getInputConfig('value_1')" [form]="form"></app-question>
      </div>
    </div>

    <div class="col-md-1">
      <div class="form-group">
        <app-question [question]="getInputConfig('value_2')" [form]="form"></app-question>
      </div>
    </div>
  </ng-template>

  <div class="col-md-2">
    <div class="form-group">
      <app-question [question]="getInputConfig('status')" [form]="form"></app-question>
    </div>
  </div>

  <div class="col">
    <div class="form-group">
      <app-question [question]="getInputConfig('date_from')" [form]="form"></app-question>
    </div>
  </div>

  <div class="col">
    <div class="form-group">
      <app-question [question]="getInputConfig('date_to')" [form]="form"></app-question>
    </div>
  </div>

  <div class="col mrg-top-30">
    <div class="form-group">
      <app-question [question]="inputs[7]" [form]="form"></app-question>
    </div>
  </div>

</div>
