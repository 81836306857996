import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from './condition.service';
import { QuestionBase } from '../../models/forms/question-base';
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectQuestion } from '../../models/forms/question-multiselect';
import { CouponsService } from '../../../resources/coupons/coupons.service';
import { CouponHistoriesService } from '../../../resources/coupons/coupon-histories.service';
import { CheckboxQuestion } from '../../models/forms/question-checkbox';
import { multiselectPresenceValidator, checkControlValuePresence } from '../validations.service';
import { UntypedFormGroup } from '@angular/forms';
import { isNullOrUndefined } from 'util';

@Injectable()
export class CouponConditionService implements SegmentConditionProvider {

  public inputs: QuestionBase<any>[];
  public customValidators = [(control: UntypedFormGroup) => this.getFormGroupValidations(control)];

  constructor( private translate: TranslateService,
               private couponHistoriesService: CouponHistoriesService,
               private coupons: CouponsService ) { }

  public getInputs( params?: any ): QuestionBase<any>[] {

    const miliseconds = new Date().getMilliseconds();

    const inputs = [
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: `any_state_${miliseconds}`,
        label: this.translate.instant('resources.segment_conditions.fields.any_state'),
        getValue: (value) => value ? value : false,
        parseValue: (value) => value ? value : false,
      }),
      new MultiSelectQuestion({
        key: 'delivery_type_single',
        cssClasses: 'form-control input-default',
        required: true,
        label: this.translate.instant('resources.segment_conditions.fields.delivery_type'),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: false },
        options: this.getSingleCouponOptions(),
        getValue: (value, _params?) => {
          if ( _params && _params.last_deliver_number === 1 ) {
            return [this.getSingleCouponOptions().find( opt => opt.id === 'last_1')];
          }
          if ( _params && _params['delivery_type_single'] && _params['delivery_type_single'].length > 0 ) {
            return [this.getSingleCouponOptions().find( opt => opt.id === _params['delivery_type_single'][0].id )];
          }
          return ( value && value.length && value[0] !== undefined ) ? value : [];
        },
        parseValue: (_, formValues?) => {
          if ( formValues && formValues['delivery_type_single'] && formValues['delivery_type_single'].length > 0 ) {
            return formValues['delivery_type_single'][0].id;
          } else {
            return null;
          }
        }
      }),
      new MultiSelectQuestion({
        key: 'delivery_type_multi',
        cssClasses: 'form-control input-default',
        required: true,
        label: this.translate.instant('resources.segment_conditions.fields.delivery_type'),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: false },
        options: this.getMultiCouponOptions(),
        getValue: (value) => ( value && value.length && value[0] !== undefined ) ? value : [],
        parseValue: (_, formValues?) => {
          if ( formValues && formValues['delivery_type_multi'] && formValues['delivery_type_multi'].length > 0 ) {
            return formValues['delivery_type_multi'][0].id;
          } else {
            return null;
          }
        }
      }),
      new MultiSelectQuestion({
        key: 'coupon_ids',
        label: this.translate.instant('resources.segment_conditions.fields.coupons'),
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.coupons,
        required: true,
        getValue: (value, formValues?) => {
          if ( value && value.length === 1 ) {
            value = value[0].hasOwnProperty('id') ? [value[0].id] : [value];
            this.couponHistoriesService.setCoupon(value[0]);
          }
          return { selectedIds: value };
        },
        parseValue: (values) => ((values && values.length) ? values.map( value => parseInt(value.id, 10) ) : []),
        customValidators: [multiselectPresenceValidator]
      }),
      new MultiSelectQuestion({
        key: 'status',
        label: this.translate.instant('resources.segment_conditions.fields.status'),
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        options: this.getCouponStatusList(),
        required: true,
        getValue: (value) => ( value && value.length ) ? value : [],
        parseValue: (value: any[]) => {
          if ( value && value.length ) {
            return value.map( statusString => statusString.id );
          }
        },
        customValidators: [multiselectPresenceValidator]
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.applies'),
        key: 'coupon_history_ids',
        dataSource: this.couponHistoriesService,
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: true, enableSearchFilter: false },
        required: true,
        getValue: (values, _params?) => {
          if ( values && values.length ) {
            values = values[0].hasOwnProperty('id') ? values.map( value => value.id) : values.map( value => value );
            return { selectedIds: values };
          } else {
            return { selectedIds: [] };
          }
        },
        parseValue: (values) => {
          if ( values && values.length > 0 ) {
            return values.map( value => ({ coupon_history_ids: value.id, apply: value.rawElement.campaign_history_id }));
          } else {
            return [];
          }
        }
      })
    ];

    if ( params && Object.keys(params).length > 0 ) { this.prepareInputValuesFromParams(inputs, params); }

    this.inputs = inputs;
    return this.inputs;
  }

  private prepareInputValuesFromParams(inputs: QuestionBase<any>[], params?) {

    Object.keys(params).forEach( _key => {

      let input: QuestionBase<any>;

      if ( _key.indexOf('any_state') >= 0) {
        input = inputs[0];
      } else if ( _key.indexOf('delivery_type') >= 0) {
        const coupons = params.coupon_ids;
        if ( coupons && coupons.length === 1 ) {
          input = inputs.find( _input => _input.key === 'delivery_type_single');
        } else if ( coupons && coupons.length > 1 ) {
          input = inputs.find( _input => _input.key === 'delivery_type_multi');
        }
      } else {
        input = inputs.find( _input => _input.key === _key);
      }

      /* Check if input exist because API returns object keys that are no inputs as delivery_type_number */
      if (input) {
        const value = input.getValue(params[_key], params);
        if (value && value.hasOwnProperty('selectedIds')) {
          input.selectedIds = value.selectedIds;
        } else {
          input.value = value;
        }
      }
    });
  }

  public getDeliveryOptions(params: { coupon_ids: any[] }) {
    if ( !params ) { return this.getSingleCouponOptions(); }
    if ( params.coupon_ids.length === 1 ) {
      return this.getSingleCouponOptions();
    } else {
      return this.getMultiCouponOptions();
    }
  }

  public getCouponStatusList() {
    return [
      { id: 'pending', name: this.translate.instant('resources.vouchers.states.pending') },
      { id: 'printed', name: this.translate.instant('resources.vouchers.states.printed') },
      { id: 'redeemed', name: this.translate.instant('resources.vouchers.states.redeemed') },
      { id: 'exhausted', name: this.translate.instant('resources.vouchers.states.soldout') },
      { id: 'cancelled', name: this.translate.instant('resources.vouchers.states.cancelled') }
    ];
  }

  public getSingleCouponOptions() {
    return [
      { id: 'last', name: this.translate.instant('resources.vouchers.states.last') },
      { id: 'last_1', name: this.translate.instant('resources.vouchers.states.last') + ' - 1' },
      { id: 'any', name: this.translate.instant('resources.vouchers.states.any') },
      { id: 'all', name: this.translate.instant('resources.vouchers.states.all') },
    ];
  }

  public getMultiCouponOptions() {
    return [
      { id: 'any', name: this.translate.instant('resources.vouchers.states.any') },
      { id: 'all', name: this.translate.instant('resources.vouchers.states.all') },
      { id: 'last|any', name: this.translate.instant('resources.vouchers.states.last_any') },
      { id: 'last|all', name: this.translate.instant('resources.vouchers.states.last_all') },
    ];
  }

  public prepareFormValuesToAPI( params: any ): any {

    const parsedValuesObj = {};

    Object.keys(params).forEach( _key => {
      const input = this.getInputByKey(this.inputs, _key);
      const parsedValue = input.parseValue( params[_key], params );
      const key = this.getKeyWithoutDateId(_key);

      if ( key === 'coupon_history_ids' && parsedValue.length > 0 ) {
        parsedValuesObj['applies'] = parsedValue.map( value => value.apply );
        parsedValuesObj['coupon_history_ids'] = parsedValue.map( value => value.coupon_history_ids );
      } else {
        parsedValuesObj[key] = parsedValue;
      }
    });

    if ( parsedValuesObj['any_state'] ) { parsedValuesObj['status'] = ['any_state']; }

    /* Get values from single when there is only 1 coupon selected */
    if ( parsedValuesObj['coupon_ids'].length === 1 ) {

      parsedValuesObj['delivery_type'] = parsedValuesObj['delivery_type_single'];

      if ( parsedValuesObj['delivery_type'] === 'last_1' ) {
        parsedValuesObj['delivery_type'] = 'last';
        parsedValuesObj['last_deliver_number'] = 1;
      } else if ( parsedValuesObj['delivery_type'] === 'last' ) {
        parsedValuesObj['last_deliver_number'] = 0;
      }
      /* Do not send applies if delivery_type is any, all */
      if ( !['all', 'any'].includes(parsedValuesObj['delivery_type']) ) {
        delete parsedValuesObj['coupon_history_ids'];
        delete parsedValuesObj['applies'];
      }
    } else if ( parsedValuesObj['coupon_ids'].length > 1 ) {
      parsedValuesObj['delivery_type'] = parsedValuesObj['delivery_type_multi'];
      delete parsedValuesObj['coupon_history_ids'];
      delete parsedValuesObj['applies'];
    }

    delete parsedValuesObj['any_state'];
    delete parsedValuesObj['delivery_type_single'];
    delete parsedValuesObj['delivery_type_multi'];

    return parsedValuesObj;
  }

  public prepareFormValuesFromAPI( params: any ): any {

    const parseValues = {
      coupon_ids: (value) => (value && value.length) ? value : null,
      coupon_history_ids: (value) => (value && value.length) ? value : null,
      status: (value) => {
        if (value && value.length > 0) {
          return this.getCouponStatusList().filter(item => value.includes(item.id));
        } else {
          return null;
        }
      },
      any_state: (value) => (value) ? value : null,
      delivery_type: (value) => {
        const coupons = params.coupon_ids;
        if (coupons && coupons.length === 1) {
          return [this.getSingleCouponOptions().find(item => item.id === value)];
        } else if (coupons && coupons.length > 1) {
          return [this.getMultiCouponOptions().find(item => item.id === value)];
        } else {
          return [];
        }
      },
      applies: (value) => (value && value.length) ? value : null,
      last_deliver_number: (value) => value ? value : null
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach( _key => {
      const key: string = this.getKeyWithoutDateId(_key);
      parsedValuesObj[key] = parseValues[key](params[key]);
      if ( params.status.indexOf('any_state') >= 0 ) {
        parsedValuesObj['any_state'] = parseValues['any_state'](true);
      } else if ( params.status !== 'any_state' ) {
        parsedValuesObj[key] = parseValues[key](params[key]);
      }
    });

    return parsedValuesObj;
  }

  private getInputByKey(_inputs: QuestionBase<any>[], _key: string ): QuestionBase<any> {
    let input: QuestionBase<any>;
    if ( _key.indexOf('any_state') >= 0) {
      input = _inputs[0];
    } else {
      input = _inputs.find( _input => _input.key === _key);
    }
    return input;
  }

  private getKeyWithoutDateId(_key: string): string {
    let key: string;
    if ( _key.indexOf('any_state') >= 0) {
      key = 'any_state';
    } else {
      key = _key;
    }
    return key;
  }

  public getFormGroupValidations(control: UntypedFormGroup) {

    const deliveryTypeSingle = control.get('delivery_type_single');
    const deliveryTypeMulti = control.get('delivery_type_multi');
    const coupons = control.get('coupon_ids');
    const status = control.get('status');
    const couponHistories = control.get('coupon_history_ids');
    const statusCheckkey = Object.keys(control.controls).find(_key => _key.indexOf('any_state') >= 0);
    const anyStatusCheck = control.get(statusCheckkey);

    deliveryTypeSingle.setValidators([]);
    deliveryTypeMulti.setValidators([]);
    couponHistories.setValidators([]);

    const couponsLength = coupons.value && coupons.value.length;
    let deliveryTypeSingleVal = null;

    if (deliveryTypeSingle.value && deliveryTypeSingle.value.length > 0 && !isNullOrUndefined(deliveryTypeSingle.value[0])) {
      deliveryTypeSingleVal = deliveryTypeSingle.value[0].id;
    }

    if (anyStatusCheck.value === true) { status.setValidators([]); }

    if (anyStatusCheck.value === false &&
        (!status.value || status.value.length === 0 || (status.value.length > 0 && isNullOrUndefined(status.value[0])))) {
      return checkControlValuePresence(status, 'invalidStatus');
    }

    if (couponsLength === 1 && !isNullOrUndefined(coupons.value[0])) {
      if (['any', 'all'].indexOf(deliveryTypeSingleVal) >= 0 ) {
        return checkControlValuePresence(couponHistories, 'invalidCouponHistories');
      }
      return checkControlValuePresence(deliveryTypeSingle, 'invalidDeliverySingle');
    }

    if (couponsLength > 1 && !isNullOrUndefined(coupons.value[0])) {
      return checkControlValuePresence(deliveryTypeMulti, 'invalidDeliverySingle');
    }
  }
}
