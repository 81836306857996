<div class="container-fluid clearfix">
  <div *ngIf="streamingData">
    <h4 class="card-title d-inline">{{'resources.streaming.fields.process_day' | translate}}: {{streamingData.created_at | date: 'dd/MM/yyyy'}} </h4>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="row mrg-top-15" *ngIf="streamingData">
        <div class="col-sm-9">
          <div class="card">
            <div class="card-block">
              <div class="table-overflow">
                <table class="table table-hover table-sm table-bordered no-mrg-btm" aria-hidden="true">
                  <thead>
                    <tr>
                      <th></th>
                      <th class="text-center">{{'resources.streaming.fields.show.resource' | translate}}</th>
                      <th class="text-center">{{'common.total' | translate}}</th>
                      <th class="text-center">{{'resources.streaming.fields.show.creation' | translate}}</th>
                      <th class="text-center">{{'resources.streaming.fields.show.update' | translate}}</th>
                      <th class="text-center">{{'resources.streaming.fields.show.elimination' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let file of streamingData.streaming_elements">
                      <td class="align-middle">
                        <a class="btn btn-default alignment"
                           data-toggle="tooltip"
                           data-placement="bottom"
                           (click)="showDetails(file)">
                           <i class="fa fa-eye mr-0 font-size-16 text-dark"></i>
                        </a>
                      </td>
                      <td class="align-middle text-center">{{file.resource_name}}</td>
                      <td class="align-middle text-center">
                        <span class="line-height-15 label label-success text-right">{{file.total_elements}}</span>
                        &nbsp;-&nbsp;
                        <span class="line-height-15 label label-primary text-right">{{file.total_elements - file.total_errors}}</span>
                        &nbsp;-&nbsp;
                        <span class="line-height-15 label label-danger text-right">{{file.total_errors}}</span>
                      </td>
                      <td class="align-middle text-center">
                        <span class="line-height-15 label label-success text-right">{{file.post_elements}}</span>
                        &nbsp;-&nbsp;
                        <span class="line-height-15 label label-primary text-right">{{file.post_elements - file.post_errors}}</span>
                        &nbsp;-&nbsp;
                        <span class="line-height-15 label label-danger text-right">{{file.post_errors}}</span>
                      </td>
                      <td class="align-middle text-center">
                        <span class="line-height-15 label label-success text-right">{{file.patch_elements}}</span>
                        &nbsp;-&nbsp;
                        <span class="line-height-15 label label-primary text-right">{{file.patch_elements - file.patch_errors}}</span>
                        &nbsp;-&nbsp;
                        <span class="line-height-15 label label-danger text-right">{{file.patch_errors}}</span>
                      </td>
                      <td class="align-middle text-center">
                        <span class="line-height-15 label label-success text-right">{{file.delete_elements}}</span>
                        &nbsp;-&nbsp;
                        <span class="line-height-15 label label-primary text-right">{{file.delete_elements - file.delete_errors}}</span>
                        &nbsp;-&nbsp;
                        <span class="line-height-15 label label-danger text-right">{{file.delete_errors}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="streamingElement">
          <div class="col-sm-3">
            <div class="card">
              <div class="card-block">
                <table class="table table-hover table-sm table-bordered" aria-hidden="true">
                  <thead>
                    <tr>
                      <th></th>
                      <th class="text-center">{{'resources.streaming.fields.show.time'| translate}}</th>
                      <th class="text-center">{{'resources.streaming.fields.show.c'| translate}}</th>
                      <th class="text-center">{{'resources.streaming.fields.show.a'| translate}}</th>
                      <th class="text-center">{{'resources.streaming.fields.show.e'| translate}}</th>
                    </tr>
                  </thead>
                  <tr *ngFor="let file of streamingElement.files">
                    <td class="align-middle">
                      <a class="btn btn-default alignment-details"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        (click)="getFile(streamingElement, file.id)">
                        <i class="fa fa-eye mr-0 font-size-16 text-dark"></i>
                      </a>
                    </td>
                    <td class="align-middle text-center"><span>{{file.date | dateTimeZone: 'HH:mm'}}</span></td>
                    <td class="align-middle text-center"><span class="line-height-15 label label-danger">{{file.post_errors}}</span></td>
                    <td class="align-middle text-center"><span class="line-height-15 label label-danger">{{file.patch_errors}}</span></td>
                    <td class="align-middle text-center"><span class="line-height-15 label label-danger">{{file.delete_errors}}</span></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>


</div>
