import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MultiselectDataSource, MultiselectDataSourceable } from '../components/multiselect/multiselect';
import { Observable } from 'rxjs';
import { ResourcesService } from './resources.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LanguagesService extends ResourcesService implements MultiselectDataSourceable{

  constructor(
    http: HttpClient,
    private translate: TranslateService
  ) {
    super(http);
  }

  fetchMultiselect( searchValues?: string ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'languages',
      filtering: {},
    };

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues}};
      requestOptions = {...requestOptions, ...filterObj};
    }
    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getLanguagesById(id);
  }

  getLanguagesById(id: number): Observable<any> {
    return this.getData({apiEndPoint: `languages/${id}`});
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.name);
  }

  getPlatformLanguages() {
    return [
      { id: 'en', name: this.translate.instant('resources.company.language.platform_language.english')},
      { id: 'es', name: this.translate.instant('resources.company.language.platform_language.spanish')},
      { id: 'hr', name: this.translate.instant('resources.company.language.platform_language.croatian')},  
      { id: 'it', name: this.translate.instant('resources.company.language.platform_language.italian')},
      { id: 'ro', name: this.translate.instant('resources.company.language.platform_language.romanian')},
    ];
  }

  getLocalizedLanguages() {
    return [
      { id: 'ca', name: this.translate.instant('resources.company.language.localized_content.catalan') },
      { id: 'de', name: this.translate.instant('resources.company.language.localized_content.deutsch') },
      { id: 'en', name: this.translate.instant('resources.company.language.localized_content.english') },
      { id: 'es', name: this.translate.instant('resources.company.language.localized_content.spanish') },
      { id: 'fr', name: this.translate.instant('resources.company.language.localized_content.french') },
      { id: 'hr', name: this.translate.instant('resources.company.language.localized_content.croatian') },
      { id: 'hu', name: this.translate.instant('resources.company.language.localized_content.hungarian') },
      { id: 'it', name: this.translate.instant('resources.company.language.localized_content.italian') },
      { id: 'ro', name: this.translate.instant('resources.company.language.localized_content.romanian')},
      { id: 'pt', name: this.translate.instant('resources.company.language.localized_content.portuguese')}
    ];
  }

}
