<div class="row" *ngIf="inputs">
  <div class="col-md-6">
    <app-question (multiselectChanged)="handleTaxonomy($event)"
                  [question]="getInputConfig('non_buyers_taxonomy_slug')"
                  [form]="form"></app-question>
  </div>
  <div class="col-md-6">
    <app-question [question]="getInputConfig('non_buyers_feature_id')"
                  [form]="form"></app-question>
  </div>
</div>
