<div class="card">
  <div class="card-heading border bottom">
    <h4 *ngIf="type === 'tier'" class="card-title text-bold">{{'resources.scores.tiers-management.fields.tiers' | translate}}</h4>
    <h4 *ngIf="type === 'challenge'" class="card-title text-bold">{{'resources.scores.challenges.title' | translate}}</h4>
  </div>
  <div class="card-body">
    <!-- TIERS  -->
    <ng-container *ngFor="let tier of tiersArray?.controls; let i = index">
      <div class="pdd-15 mrg-btm-15" *ngIf="type !== 'challenge'">
        <div class="row">
          <div class="col-md-6">
            <label for="name">{{'common.name' | translate}}</label>
            <span *ngIf="getInputConfig('name').required" class="text-danger">*</span>
            <app-question [question]="getInputConfig('name')" [form]="tiersArray.controls[i]"></app-question>
          </div>
          <div class="col-md-6">
            <label for="threshold_tier">{{'resources.scores.tiers-management.fields.min_range' | translate}}</label>
            <span *ngIf="getInputConfig('threshold_tier').required" class="text-danger">*</span>
            <app-question [question]="getInputConfig('threshold_tier')" [form]="tiersArray.controls[i]"></app-question>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" *ngIf="i === tiersArray?.controls?.length-1">
            <button class="btn btn-sm btn-danger pull-right no-mrg-right no-mrg-btm"
              (click)="deleteTier(i, tier)"
              [hidden]="mode !== 'create' || tierDefinition?.has_run">
              {{'common.buttons.delete' | translate}}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- END TIERS -->

    <div class="row" *ngIf="type === 'tier'">
      <div class="col-md-12">
        <button class="btn btn-info no-mrg-right pull-right mrg-top-5"
          (click)="addTier()"
          [hidden]="!isChangeTiersAllowed()">
          <span>{{'resources.scores.tiers-management.buttons.add_tier' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
