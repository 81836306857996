import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, filter, takeUntil } from 'rxjs';
import { LaunchDarklyService } from '../../services/launch-darkly/launchdarkly.service';

export type SimpleTabBase = {
  slug: string;
  routerLink: string[];
  name: string;
  featureFlag?: string;
}

@Component({
  selector: 'app-dynamic-simple-tabs',
  templateUrl: './dynamic-simple-tabs.component.html',
  styleUrls: ['./dynamic-simple-tabs.component.scss']
})
export class DynamicSimpleTabsComponent {

  currentSlug: string;
  flags: object = {};
  isOnSelectTab = true;
  loading = true;
  tabs: SimpleTabBase[];
  title: string;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly launchDarklyService: LaunchDarklyService
  ) {}

  ngOnInit(): void {
    this.processFeatureFlag();
    this.title = this.translate.instant(this.route.snapshot.data.title);
    this.currentSlug = this.route?.firstChild?.snapshot?.data?.slug ?? '';
    this.tabs = this.setTabsByRouteConfig();
    this.loading = false;
    this.handleSelectedTab();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectTab(slug): void {
    this.isOnSelectTab = true;
    this.currentSlug = slug;
  }

  private handleSelectedTab(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd), takeUntil(this.destroy$)).subscribe(() => {
      if (!this.isOnSelectTab && this.router.url === this.route.firstChild.snapshot.data.routerLink[0]) {
        this.selectTab(this.route.firstChild.snapshot.data.slug);
      }
      this.isOnSelectTab = false;
    });
  }

  private setTabsByRouteConfig(): SimpleTabBase[] {
    const children = this.route.snapshot.routeConfig.children.filter(el => el.data);
    return children.map(el => {
      const tabInfo = {
        slug: el.data['slug'],
        routerLink: el.data['routerLink'],
        name: this.translate.instant(el.data['name']),
        featureFlag: el.data.featureFlag
      };
      return tabInfo;
    });
  }

  private processFeatureFlag(): void {
    if (!this.route.parent) { return; }
    const ffChildren = this.route.parent.snapshot.children[0].routeConfig.children.filter(child => child?.data?.featureFlag);
    if (ffChildren.length === 0) { return; }

    ffChildren.forEach((el) => {
      this.setFlagValue(el.data.featureFlag, this.launchDarklyService.client.variation(el.data.featureFlag, false));
    });

    this.launchDarklyService.flagChanges.subscribe((flags) => {
      Object.keys(this.flags).forEach(key => {
        if ((flags[key])) {this.setFlagValue(key, flags[key]); }
      });
    });
  }

  private setFlagValue(key: string, flagValue): void {
    flagValue = flagValue.hasOwnProperty('current') ? flagValue.current : flagValue;
    this.flags[key] = flagValue;
  }
}
