import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourcesService } from '../../../shared/services/resources.service';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../../shared/components/multiselect/multiselect';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { currencies } from '../../../shared/services/currencies.mock';
import { CurrenciesService } from '../../../shared/services/currencies.service';

@Injectable()
export class ScoreExchangesService extends ResourcesService implements MultiselectDataSourceable {

  public inputs: any;

  constructor(
    http: HttpClient,
    private currenciesService: CurrenciesService
  ) {
    super(http);
  }

  public fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'score_exchanges',
      numberPerPage: 100,
      filtering: {},
      pageNumber: 1,
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  public fetchSelectedById(id: number) {
    return this.getScoreExchangesById(id);
  }

  public getScoreExchangesById(id: number): Observable<any> {
    return this.getData({apiEndPoint: `score_exchanges/${id}`});
  }

  public getInputs(formValues: any): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
      }),
      new TextboxQuestion({
        key: 'description',
        type: 'text',
        cssClasses: 'form-control input-default',
      }),
      new MultiSelectQuestion({
        key: 'currency',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        options: currencies,
      }),
      new TextboxQuestion({
        key: 'currency_amount',
        type: 'number',
        step: '0.01',
        cssClasses: 'form-control input-default',
      })
    ];

    this.inputs = inputsArray;
    this.assignFormInputValues(formValues);
    Object.keys(formValues).map(
      key => {
        if (key === 'name') {
          inputsArray.find( input => input.key === 'name' ).value = formValues[key];
        } else if (key === 'description') {
          inputsArray.find( input => input.key === 'description' ).value = formValues[key];
        } else if (key === 'currency') {
          inputsArray.find( input => input.key === 'currency' ).value = [this.currenciesService.getCurrenciesById(formValues[key])];
        } else if (key === 'currency_amount') {
          inputsArray.find( input => input.key === 'currency_amount' ).value = formValues[key];
        }
      });
    return this.inputs;
  }

  public sendData(creditsData) {
    return this.postResource(creditsData, 'score_exchanges');
  }

  public updateData(creditsData: any, _id: number) {
    return this.patchResource(creditsData, `score_exchanges/${_id}`);
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.name);
  }

  private assignFormInputValues(formValues) {
    Object.keys(formValues).map(
      key => {
        const tmpInput = this.inputs.find( input => input.key === key);
        if (tmpInput && tmpInput.getValue ) {
          const value = tmpInput.getValue(formValues[key]);
          if (value && value.hasOwnProperty('selectedIds')) {
            tmpInput.selectedIds = value.selectedIds;
          } else {
            tmpInput.value = value;
          }
        }
      }
    );
  }
}
