import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { QuestionBase } from '../../../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../../../shared/services/question-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalStatusService } from '../../../../../../shared/services/modal-status.service';
import { NewsTaxonomyTermsService } from '../../../news-taxonomy-terms-service';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-form-news-taxonomy-terms',
  templateUrl: './form-news-taxonomy-terms.component.html',
  styleUrls: ['./form-news-taxonomy-terms.component.css'],
  providers: [NewsTaxonomyTermsService]
})
export class FormNewsTaxonomyTermsComponent implements OnInit {

  form: UntypedFormGroup;
  id: number;
  imageData: { filename: any; filesize: any; filetype: any; base64: string; };
  inputs: QuestionBase<any>[];
  loading: boolean;
  subs$: Subscription[] = [];

  @Output('formValidity') formValidity: EventEmitter<string> = new EventEmitter();

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private newsTaxonomyTermsService: NewsTaxonomyTermsService,
    private modalStatusService: ModalStatusService
  ) { }

  ngOnInit() {
    this.getParams();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.imageData = {
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
          base64: reader.result.toString().split(',')[1]
        };
      };
    }
  }


  sendData() {
    const payload = {
      name: this.form.value.name,
      external_id: this.form.value.external_id,
      taxonomy_slug: this.form.value.taxonomy ? this.form.value.taxonomy[0].rawElement.slug : null,
    };

    const translatePath = 'resources.cms.form.warnings';
    this.modalStatusService.formLoaderStatus.emit('loading');
    const req$ = this.getRequest(payload);
    let successTitle = this.translate.instant(`${translatePath}.news_taxonomies_term_success_title`);
    let successDesc = this.translate.instant(`${translatePath}.news_taxonomies_term_success_text`);
    if (this.id) {
      successTitle = this.translate.instant(`${translatePath}.news_taxonomies_term_update_title`);
      successDesc = this.translate.instant(`${translatePath}.news_taxonomies_term_update_text`);
    }
    this.executeRequest(req$, successTitle, successDesc);
  }

  returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/omnichannel-manager/cms/categories']).catch(() => {});
    }
  }

  private getRequest(payload: object): Observable<any> {
    if(this.id) {
      delete payload['external_id'];
    }
    return this.id ? this.newsTaxonomyTermsService.updateData(payload, this.id) : this.newsTaxonomyTermsService.sendData(payload);
  }

  private executeRequest(req: Observable<any>, successTitle: string, successDesc: string) {
    const req$ = req.subscribe({
      next: () => {
        this.confirmationService.displaySuccessAlert(successTitle, successDesc);
        this.loading = true;
        this.modalStatusService.modalStatus.emit();
        this.returnToList();
      },
      error: (errorData: HttpErrorResponse) => this.handleError(errorData),
    })
    this.subs$.push(req$);
  }

  private handleError(errorData: HttpErrorResponse) {
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error);
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getNewsTaxonomyTermsById(this.id);
      } else {
        this.inputs = this.newsTaxonomyTermsService.setInputs({});
        this.form = this.qcs.toFormGroup(this.inputs);
        this.formStatusChanges();
      }
    });
  }

  private getNewsTaxonomyTermsById(id: number) {
    this.newsTaxonomyTermsService.getNewsTaxonomyTermsById(this.id).subscribe(
      data => {
        this.inputs = this.newsTaxonomyTermsService.setInputs(data);
        this.form = this.qcs.toFormGroup(this.inputs);
        this.formStatusChanges();
      }
    );
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.form.status));
    const formStatus$ = this.form.statusChanges.pipe(distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
    this.subs$.push(formStatus$);
  }

}
