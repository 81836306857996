export const timeZones = [
  {
    'id': 1,
    'name': 'Africa/Abidjan',
    'offset': 'UTC'
  },
  {
    'id': 2,
    'name': 'Africa/Accra',
    'offset': 'UTC'
  },
  {
    'id': 3,
    'name': 'Africa/Addis_Ababa',
    'offset': 'UTC +03:00'
  },
  {
    'id': 4,
    'name': 'Africa/Algiers',
    'offset': 'UTC +01:00'
  },
  {
    'id': 5,
    'name': 'Africa/Asmara',
    'offset': 'UTC +03:00'
  },
  {
    'id': 6,
    'name': 'Africa/Bamako',
    'offset': 'UTC'
  },
  {
    'id': 7,
    'name': 'Africa/Bangui',
    'offset': 'UTC +01:00'
  },
  {
    'id': 8,
    'name': 'Africa/Banjul',
    'offset': 'UTC'
  },
  {
    'id': 9,
    'name': 'Africa/Bissau',
    'offset': 'UTC'
  },
  {
    'id': 10,
    'name': 'Africa/Blantyre',
    'offset': 'UTC +02:00'
  },
  {
    'id': 11,
    'name': 'Africa/Brazzaville',
    'offset': 'UTC +01:00'
  },
  {
    'id': 12,
    'name': 'Africa/Bujumbura',
    'offset': 'UTC +02:00'
  },
  {
    'id': 13,
    'name': 'Africa/Cairo',
    'offset': 'UTC +02:00'
  },
  {
    'id': 14,
    'name': 'Africa/Casablanca',
    'offset': 'UTC +01:00'
  },
  {
    'id': 15,
    'name': 'Africa/Ceuta',
    'offset': 'UTC +02:00'
  },
  {
    'id': 16,
    'name': 'Africa/Conakry',
    'offset': 'UTC'
  },
  {
    'id': 17,
    'name': 'Africa/Dakar',
    'offset': 'UTC'
  },
  {
    'id': 18,
    'name': 'Africa/Dar_es_Salaam',
    'offset': 'UTC +03:00'
  },
  {
    'id': 19,
    'name': 'Africa/Djibouti',
    'offset': 'UTC +03:00'
  },
  {
    'id': 20,
    'name': 'Africa/Douala',
    'offset': 'UTC +01:00'
  },
  {
    'id': 21,
    'name': 'Africa/El_Aaiun',
    'offset': 'UTC +01:00'
  },
  {
    'id': 22,
    'name': 'Africa/Freetown',
    'offset': 'UTC'
  },
  {
    'id': 23,
    'name': 'Africa/Gaborone',
    'offset': 'UTC +02:00'
  },
  {
    'id': 24,
    'name': 'Africa/Harare',
    'offset': 'UTC +02:00'
  },
  {
    'id': 25,
    'name': 'Africa/Johannesburg',
    'offset': 'UTC +02:00'
  },
  {
    'id': 26,
    'name': 'Africa/Juba',
    'offset': 'UTC +03:00'
  },
  {
    'id': 27,
    'name': 'Africa/Kampala',
    'offset': 'UTC +03:00'
  },
  {
    'id': 28,
    'name': 'Africa/Khartoum',
    'offset': 'UTC +02:00'
  },
  {
    'id': 29,
    'name': 'Africa/Kigali',
    'offset': 'UTC +02:00'
  },
  {
    'id': 30,
    'name': 'Africa/Kinshasa',
    'offset': 'UTC +01:00'
  },
  {
    'id': 31,
    'name': 'Africa/Lagos',
    'offset': 'UTC +01:00'
  },
  {
    'id': 32,
    'name': 'Africa/Libreville',
    'offset': 'UTC +01:00'
  },
  {
    'id': 33,
    'name': 'Africa/Lome',
    'offset': 'UTC'
  },
  {
    'id': 34,
    'name': 'Africa/Luanda',
    'offset': 'UTC +01:00'
  },
  {
    'id': 35,
    'name': 'Africa/Lubumbashi',
    'offset': 'UTC +02:00'
  },
  {
    'id': 36,
    'name': 'Africa/Lusaka',
    'offset': 'UTC +02:00'
  },
  {
    'id': 37,
    'name': 'Africa/Malabo',
    'offset': 'UTC +01:00'
  },
  {
    'id': 38,
    'name': 'Africa/Maputo',
    'offset': 'UTC +02:00'
  },
  {
    'id': 39,
    'name': 'Africa/Maseru',
    'offset': 'UTC +02:00'
  },
  {
    'id': 40,
    'name': 'Africa/Mbabane',
    'offset': 'UTC +02:00'
  },
  {
    'id': 41,
    'name': 'Africa/Mogadishu',
    'offset': 'UTC +03:00'
  },
  {
    'id': 42,
    'name': 'Africa/Monrovia',
    'offset': 'UTC'
  },
  {
    'id': 43,
    'name': 'Africa/Nairobi',
    'offset': 'UTC +03:00'
  },
  {
    'id': 44,
    'name': 'Africa/Ndjamena',
    'offset': 'UTC +01:00'
  },
  {
    'id': 45,
    'name': 'Africa/Niamey',
    'offset': 'UTC +01:00'
  },
  {
    'id': 46,
    'name': 'Africa/Nouakchott',
    'offset': 'UTC'
  },
  {
    'id': 47,
    'name': 'Africa/Ouagadougou',
    'offset': 'UTC'
  },
  {
    'id': 48,
    'name': 'Africa/Porto-Novo',
    'offset': 'UTC +01:00'
  },
  {
    'id': 49,
    'name': 'Africa/Sao_Tome',
    'offset': 'UTC +01:00'
  },
  {
    'id': 50,
    'name': 'Africa/Tripoli',
    'offset': 'UTC +02:00'
  },
  {
    'id': 51,
    'name': 'Africa/Tunis',
    'offset': 'UTC +01:00'
  },
  {
    'id': 52,
    'name': 'Africa/Windhoek',
    'offset': 'UTC +02:00'
  },
  {
    'id': 53,
    'name': 'America/Adak',
    'offset': 'UTC -09:00'
  },
  {
    'id': 54,
    'name': 'America/Anchorage',
    'offset': 'UTC -08:00'
  },
  {
    'id': 55,
    'name': 'America/Anguilla',
    'offset': 'UTC -04:00'
  },
  {
    'id': 56,
    'name': 'America/Antigua',
    'offset': 'UTC -04:00'
  },
  {
    'id': 57,
    'name': 'America/Araguaina',
    'offset': 'UTC -03:00'
  },
  {
    'id': 58,
    'name': 'America/Argentina/Buenos_Aires',
    'offset': 'UTC -03:00'
  },
  {
    'id': 59,
    'name': 'America/Argentina/Catamarca',
    'offset': 'UTC -03:00'
  },
  {
    'id': 60,
    'name': 'America/Argentina/Cordoba',
    'offset': 'UTC -03:00'
  },
  {
    'id': 61,
    'name': 'America/Argentina/Jujuy',
    'offset': 'UTC -03:00'
  },
  {
    'id': 62,
    'name': 'America/Argentina/La_Rioja',
    'offset': 'UTC -03:00'
  },
  {
    'id': 63,
    'name': 'America/Argentina/Mendoza',
    'offset': 'UTC -03:00'
  },
  {
    'id': 64,
    'name': 'America/Argentina/Rio_Gallegos',
    'offset': 'UTC -03:00'
  },
  {
    'id': 65,
    'name': 'America/Argentina/Salta',
    'offset': 'UTC -03:00'
  },
  {
    'id': 66,
    'name': 'America/Argentina/San_Juan',
    'offset': 'UTC -03:00'
  },
  {
    'id': 67,
    'name': 'America/Argentina/San_Luis',
    'offset': 'UTC -03:00'
  },
  {
    'id': 68,
    'name': 'America/Argentina/Tucuman',
    'offset': 'UTC -03:00'
  },
  {
    'id': 69,
    'name': 'America/Argentina/Ushuaia',
    'offset': 'UTC -03:00'
  },
  {
    'id': 70,
    'name': 'America/Aruba',
    'offset': 'UTC -04:00'
  },
  {
    'id': 71,
    'name': 'America/Asuncion',
    'offset': 'UTC -04:00'
  },
  {
    'id': 72,
    'name': 'America/Atikokan',
    'offset': 'UTC -05:00'
  },
  {
    'id': 73,
    'name': 'America/Bahia',
    'offset': 'UTC -03:00'
  },
  {
    'id': 74,
    'name': 'America/Bahia_Banderas',
    'offset': 'UTC -05:00'
  },
  {
    'id': 75,
    'name': 'America/Barbados',
    'offset': 'UTC -04:00'
  },
  {
    'id': 76,
    'name': 'America/Belem',
    'offset': 'UTC -03:00'
  },
  {
    'id': 77,
    'name': 'America/Belize',
    'offset': 'UTC -06:00'
  },
  {
    'id': 78,
    'name': 'America/Blanc-Sablon',
    'offset': 'UTC -04:00'
  },
  {
    'id': 79,
    'name': 'America/Boa_Vista',
    'offset': 'UTC -04:00'
  },
  {
    'id': 80,
    'name': 'America/Bogota',
    'offset': 'UTC -05:00'
  },
  {
    'id': 81,
    'name': 'America/Boise',
    'offset': 'UTC -06:00'
  },
  {
    'id': 82,
    'name': 'America/Cambridge_Bay',
    'offset': 'UTC -06:00'
  },
  {
    'id': 83,
    'name': 'America/Campo_Grande',
    'offset': 'UTC -04:00'
  },
  {
    'id': 84,
    'name': 'America/Cancun',
    'offset': 'UTC -05:00'
  },
  {
    'id': 85,
    'name': 'America/Caracas',
    'offset': 'UTC -04:00'
  },
  {
    'id': 86,
    'name': 'America/Cayenne',
    'offset': 'UTC -03:00'
  },
  {
    'id': 87,
    'name': 'America/Cayman',
    'offset': 'UTC -05:00'
  },
  {
    'id': 88,
    'name': 'America/Chicago',
    'offset': 'UTC -05:00'
  },
  {
    'id': 89,
    'name': 'America/Chihuahua',
    'offset': 'UTC -06:00'
  },
  {
    'id': 90,
    'name': 'America/Costa_Rica',
    'offset': 'UTC -06:00'
  },
  {
    'id': 91,
    'name': 'America/Creston',
    'offset': 'UTC -07:00'
  },
  {
    'id': 92,
    'name': 'America/Cuiaba',
    'offset': 'UTC -04:00'
  },
  {
    'id': 93,
    'name': 'America/Curacao',
    'offset': 'UTC -04:00'
  },
  {
    'id': 94,
    'name': 'America/Danmarkshavn',
    'offset': 'UTC'
  },
  {
    'id': 95,
    'name': 'America/Dawson',
    'offset': 'UTC -07:00'
  },
  {
    'id': 96,
    'name': 'America/Dawson_Creek',
    'offset': 'UTC -07:00'
  },
  {
    'id': 97,
    'name': 'America/Denver',
    'offset': 'UTC -06:00'
  },
  {
    'id': 98,
    'name': 'America/Detroit',
    'offset': 'UTC -04:00'
  },
  {
    'id': 99,
    'name': 'America/Dominica',
    'offset': 'UTC -04:00'
  },
  {
    'id': 100,
    'name': 'America/Edmonton',
    'offset': 'UTC -06:00'
  },
  {
    'id': 101,
    'name': 'America/Eirunepe',
    'offset': 'UTC -05:00'
  },
  {
    'id': 102,
    'name': 'America/El_Salvador',
    'offset': 'UTC -06:00'
  },
  {
    'id': 103,
    'name': 'America/Fort_Nelson',
    'offset': 'UTC -07:00'
  },
  {
    'id': 104,
    'name': 'America/Fortaleza',
    'offset': 'UTC -03:00'
  },
  {
    'id': 105,
    'name': 'America/Glace_Bay',
    'offset': 'UTC -03:00'
  },
  {
    'id': 106,
    'name': 'America/Godthab',
    'offset': 'UTC -02:00'
  },
  {
    'id': 107,
    'name': 'America/Goose_Bay',
    'offset': 'UTC -03:00'
  },
  {
    'id': 108,
    'name': 'America/Grand_Turk',
    'offset': 'UTC -04:00'
  },
  {
    'id': 109,
    'name': 'America/Grenada',
    'offset': 'UTC -04:00'
  },
  {
    'id': 110,
    'name': 'America/Guadeloupe',
    'offset': 'UTC -04:00'
  },
  {
    'id': 111,
    'name': 'America/Guatemala',
    'offset': 'UTC -06:00'
  },
  {
    'id': 112,
    'name': 'America/Guayaquil',
    'offset': 'UTC -05:00'
  },
  {
    'id': 113,
    'name': 'America/Guyana',
    'offset': 'UTC -04:00'
  },
  {
    'id': 114,
    'name': 'America/Halifax',
    'offset': 'UTC -03:00'
  },
  {
    'id': 115,
    'name': 'America/Havana',
    'offset': 'UTC -04:00'
  },
  {
    'id': 116,
    'name': 'America/Hermosillo',
    'offset': 'UTC -07:00'
  },
  {
    'id': 117,
    'name': 'America/Indiana/Indianapolis',
    'offset': 'UTC -04:00'
  },
  {
    'id': 118,
    'name': 'America/Indiana/Knox',
    'offset': 'UTC -05:00'
  },
  {
    'id': 119,
    'name': 'America/Indiana/Marengo',
    'offset': 'UTC -04:00'
  },
  {
    'id': 120,
    'name': 'America/Indiana/Petersburg',
    'offset': 'UTC -04:00'
  },
  {
    'id': 121,
    'name': 'America/Indiana/Tell_City',
    'offset': 'UTC -05:00'
  },
  {
    'id': 122,
    'name': 'America/Indiana/Vevay',
    'offset': 'UTC -04:00'
  },
  {
    'id': 123,
    'name': 'America/Indiana/Vincennes',
    'offset': 'UTC -04:00'
  },
  {
    'id': 124,
    'name': 'America/Indiana/Winamac',
    'offset': 'UTC -04:00'
  },
  {
    'id': 125,
    'name': 'America/Inuvik',
    'offset': 'UTC -06:00'
  },
  {
    'id': 126,
    'name': 'America/Iqaluit',
    'offset': 'UTC -04:00'
  },
  {
    'id': 127,
    'name': 'America/Jamaica',
    'offset': 'UTC -05:00'
  },
  {
    'id': 128,
    'name': 'America/Juneau',
    'offset': 'UTC -08:00'
  },
  {
    'id': 129,
    'name': 'America/Kentucky/Louisville',
    'offset': 'UTC -04:00'
  },
  {
    'id': 130,
    'name': 'America/Kentucky/Monticello',
    'offset': 'UTC -04:00'
  },
  {
    'id': 131,
    'name': 'America/Kralendijk',
    'offset': 'UTC -04:00'
  },
  {
    'id': 132,
    'name': 'America/La_Paz',
    'offset': 'UTC -04:00'
  },
  {
    'id': 133,
    'name': 'America/Lima',
    'offset': 'UTC -05:00'
  },
  {
    'id': 134,
    'name': 'America/Los_Angeles',
    'offset': 'UTC -07:00'
  },
  {
    'id': 135,
    'name': 'America/Lower_Princes',
    'offset': 'UTC -04:00'
  },
  {
    'id': 136,
    'name': 'America/Maceio',
    'offset': 'UTC -03:00'
  },
  {
    'id': 137,
    'name': 'America/Managua',
    'offset': 'UTC -06:00'
  },
  {
    'id': 138,
    'name': 'America/Manaus',
    'offset': 'UTC -04:00'
  },
  {
    'id': 139,
    'name': 'America/Marigot',
    'offset': 'UTC -04:00'
  },
  {
    'id': 140,
    'name': 'America/Martinique',
    'offset': 'UTC -04:00'
  },
  {
    'id': 141,
    'name': 'America/Matamoros',
    'offset': 'UTC -05:00'
  },
  {
    'id': 142,
    'name': 'America/Mazatlan',
    'offset': 'UTC -06:00'
  },
  {
    'id': 143,
    'name': 'America/Menominee',
    'offset': 'UTC -05:00'
  },
  {
    'id': 144,
    'name': 'America/Merida',
    'offset': 'UTC -05:00'
  },
  {
    'id': 145,
    'name': 'America/Metlakatla',
    'offset': 'UTC -08:00'
  },
  {
    'id': 146,
    'name': 'America/Mexico_City',
    'offset': 'UTC -05:00'
  },
  {
    'id': 147,
    'name': 'America/Miquelon',
    'offset': 'UTC -02:00'
  },
  {
    'id': 148,
    'name': 'America/Moncton',
    'offset': 'UTC -03:00'
  },
  {
    'id': 149,
    'name': 'America/Monterrey',
    'offset': 'UTC -05:00'
  },
  {
    'id': 150,
    'name': 'America/Montevideo',
    'offset': 'UTC -03:00'
  },
  {
    'id': 151,
    'name': 'America/Montserrat',
    'offset': 'UTC -04:00'
  },
  {
    'id': 152,
    'name': 'America/Nassau',
    'offset': 'UTC -04:00'
  },
  {
    'id': 153,
    'name': 'America/New_York',
    'offset': 'UTC -04:00'
  },
  {
    'id': 154,
    'name': 'America/Nipigon',
    'offset': 'UTC -04:00'
  },
  {
    'id': 155,
    'name': 'America/Nome',
    'offset': 'UTC -08:00'
  },
  {
    'id': 156,
    'name': 'America/Noronha',
    'offset': 'UTC -02:00'
  },
  {
    'id': 157,
    'name': 'America/North_Dakota/Beulah',
    'offset': 'UTC -05:00'
  },
  {
    'id': 158,
    'name': 'America/North_Dakota/Center',
    'offset': 'UTC -05:00'
  },
  {
    'id': 159,
    'name': 'America/North_Dakota/New_Salem',
    'offset': 'UTC -05:00'
  },
  {
    'id': 160,
    'name': 'America/Ojinaga',
    'offset': 'UTC -06:00'
  },
  {
    'id': 161,
    'name': 'America/Panama',
    'offset': 'UTC -05:00'
  },
  {
    'id': 162,
    'name': 'America/Pangnirtung',
    'offset': 'UTC -04:00'
  },
  {
    'id': 163,
    'name': 'America/Paramaribo',
    'offset': 'UTC -03:00'
  },
  {
    'id': 164,
    'name': 'America/Phoenix',
    'offset': 'UTC -07:00'
  },
  {
    'id': 165,
    'name': 'America/Port_of_Spain',
    'offset': 'UTC -04:00'
  },
  {
    'id': 166,
    'name': 'America/Port-au-Prince',
    'offset': 'UTC -04:00'
  },
  {
    'id': 167,
    'name': 'America/Porto_Velho',
    'offset': 'UTC -04:00'
  },
  {
    'id': 168,
    'name': 'America/Puerto_Rico',
    'offset': 'UTC -04:00'
  },
  {
    'id': 169,
    'name': 'America/Punta_Arenas',
    'offset': 'UTC -03:00'
  },
  {
    'id': 170,
    'name': 'America/Rainy_River',
    'offset': 'UTC -05:00'
  },
  {
    'id': 171,
    'name': 'America/Rankin_Inlet',
    'offset': 'UTC -05:00'
  },
  {
    'id': 172,
    'name': 'America/Recife',
    'offset': 'UTC -03:00'
  },
  {
    'id': 173,
    'name': 'America/Regina',
    'offset': 'UTC -06:00'
  },
  {
    'id': 174,
    'name': 'America/Resolute',
    'offset': 'UTC -05:00'
  },
  {
    'id': 175,
    'name': 'America/Rio_Branco',
    'offset': 'UTC -05:00'
  },
  {
    'id': 176,
    'name': 'America/Santarem',
    'offset': 'UTC -03:00'
  },
  {
    'id': 177,
    'name': 'America/Santiago',
    'offset': 'UTC -04:00'
  },
  {
    'id': 178,
    'name': 'America/Santo_Domingo',
    'offset': 'UTC -04:00'
  },
  {
    'id': 179,
    'name': 'America/Sao_Paulo',
    'offset': 'UTC -03:00'
  },
  {
    'id': 180,
    'name': 'America/Scoresbysund',
    'offset': 'UTC'
  },
  {
    'id': 181,
    'name': 'America/Sitka',
    'offset': 'UTC -08:00'
  },
  {
    'id': 182,
    'name': 'America/St_Barthelemy',
    'offset': 'UTC -04:00'
  },
  {
    'id': 183,
    'name': 'America/St_Johns',
    'offset': 'UTC -02:30'
  },
  {
    'id': 184,
    'name': 'America/St_Kitts',
    'offset': 'UTC -04:00'
  },
  {
    'id': 185,
    'name': 'America/St_Lucia',
    'offset': 'UTC -04:00'
  },
  {
    'id': 186,
    'name': 'America/St_Thomas',
    'offset': 'UTC -04:00'
  },
  {
    'id': 187,
    'name': 'America/St_Vincent',
    'offset': 'UTC -04:00'
  },
  {
    'id': 188,
    'name': 'America/Swift_Current',
    'offset': 'UTC -06:00'
  },
  {
    'id': 189,
    'name': 'America/Tegucigalpa',
    'offset': 'UTC -06:00'
  },
  {
    'id': 190,
    'name': 'America/Thule',
    'offset': 'UTC -03:00'
  },
  {
    'id': 191,
    'name': 'America/Thunder_Bay',
    'offset': 'UTC -04:00'
  },
  {
    'id': 192,
    'name': 'America/Tijuana',
    'offset': 'UTC -07:00'
  },
  {
    'id': 193,
    'name': 'America/Toronto',
    'offset': 'UTC -04:00'
  },
  {
    'id': 194,
    'name': 'America/Tortola',
    'offset': 'UTC -04:00'
  },
  {
    'id': 195,
    'name': 'America/Vancouver',
    'offset': 'UTC -07:00'
  },
  {
    'id': 196,
    'name': 'America/Whitehorse',
    'offset': 'UTC -07:00'
  },
  {
    'id': 197,
    'name': 'America/Winnipeg',
    'offset': 'UTC -05:00'
  },
  {
    'id': 198,
    'name': 'America/Yakutat',
    'offset': 'UTC -08:00'
  },
  {
    'id': 199,
    'name': 'America/Yellowknife',
    'offset': 'UTC -06:00'
  },
  {
    'id': 200,
    'name': 'Antarctica/Casey',
    'offset': 'UTC +08:00'
  },
  {
    'id': 201,
    'name': 'Antarctica/Davis',
    'offset': 'UTC +07:00'
  },
  {
    'id': 202,
    'name': 'Antarctica/DumontDUrville',
    'offset': 'UTC +10:00'
  },
  {
    'id': 203,
    'name': 'Antarctica/Macquarie',
    'offset': 'UTC +11:00'
  },
  {
    'id': 204,
    'name': 'Antarctica/Mawson',
    'offset': 'UTC +05:00'
  },
  {
    'id': 205,
    'name': 'Antarctica/McMurdo',
    'offset': 'UTC +12:00'
  },
  {
    'id': 206,
    'name': 'Antarctica/Palmer',
    'offset': 'UTC -03:00'
  },
  {
    'id': 207,
    'name': 'Antarctica/Rothera',
    'offset': 'UTC -03:00'
  },
  {
    'id': 208,
    'name': 'Antarctica/Syowa',
    'offset': 'UTC +03:00'
  },
  {
    'id': 209,
    'name': 'Antarctica/Troll',
    'offset': 'UTC +02:00'
  },
  {
    'id': 210,
    'name': 'Antarctica/Vostok',
    'offset': 'UTC +06:00'
  },
  {
    'id': 211,
    'name': 'Arctic/Longyearbyen',
    'offset': 'UTC +02:00'
  },
  {
    'id': 212,
    'name': 'Asia/Aden',
    'offset': 'UTC +03:00'
  },
  {
    'id': 213,
    'name': 'Asia/Almaty',
    'offset': 'UTC +06:00'
  },
  {
    'id': 214,
    'name': 'Asia/Amman',
    'offset': 'UTC +03:00'
  },
  {
    'id': 215,
    'name': 'Asia/Anadyr',
    'offset': 'UTC +12:00'
  },
  {
    'id': 216,
    'name': 'Asia/Aqtau',
    'offset': 'UTC +05:00'
  },
  {
    'id': 217,
    'name': 'Asia/Aqtobe',
    'offset': 'UTC +05:00'
  },
  {
    'id': 218,
    'name': 'Asia/Ashgabat',
    'offset': 'UTC +05:00'
  },
  {
    'id': 219,
    'name': 'Asia/Atyrau',
    'offset': 'UTC +05:00'
  },
  {
    'id': 220,
    'name': 'Asia/Baghdad',
    'offset': 'UTC +03:00'
  },
  {
    'id': 221,
    'name': 'Asia/Bahrain',
    'offset': 'UTC +03:00'
  },
  {
    'id': 222,
    'name': 'Asia/Baku',
    'offset': 'UTC +04:00'
  },
  {
    'id': 223,
    'name': 'Asia/Bangkok',
    'offset': 'UTC +07:00'
  },
  {
    'id': 224,
    'name': 'Asia/Barnaul',
    'offset': 'UTC +07:00'
  },
  {
    'id': 225,
    'name': 'Asia/Beirut',
    'offset': 'UTC +03:00'
  },
  {
    'id': 226,
    'name': 'Asia/Bishkek',
    'offset': 'UTC +06:00'
  },
  {
    'id': 227,
    'name': 'Asia/Brunei',
    'offset': 'UTC +08:00'
  },
  {
    'id': 228,
    'name': 'Asia/Chita',
    'offset': 'UTC +09:00'
  },
  {
    'id': 229,
    'name': 'Asia/Choibalsan',
    'offset': 'UTC +08:00'
  },
  {
    'id': 230,
    'name': 'Asia/Colombo',
    'offset': 'UTC +05:30'
  },
  {
    'id': 231,
    'name': 'Asia/Damascus',
    'offset': 'UTC +03:00'
  },
  {
    'id': 232,
    'name': 'Asia/Dhaka',
    'offset': 'UTC +06:00'
  },
  {
    'id': 233,
    'name': 'Asia/Dili',
    'offset': 'UTC +09:00'
  },
  {
    'id': 234,
    'name': 'Asia/Dubai',
    'offset': 'UTC +04:00'
  },
  {
    'id': 235,
    'name': 'Asia/Dushanbe',
    'offset': 'UTC +05:00'
  },
  {
    'id': 236,
    'name': 'Asia/Famagusta',
    'offset': 'UTC +03:00'
  },
  {
    'id': 237,
    'name': 'Asia/Gaza',
    'offset': 'UTC +03:00'
  },
  {
    'id': 238,
    'name': 'Asia/Hebron',
    'offset': 'UTC +03:00'
  },
  {
    'id': 239,
    'name': 'Asia/Ho_Chi_Minh',
    'offset': 'UTC +07:00'
  },
  {
    'id': 240,
    'name': 'Asia/Hong_Kong',
    'offset': 'UTC +08:00'
  },
  {
    'id': 241,
    'name': 'Asia/Hovd',
    'offset': 'UTC +07:00'
  },
  {
    'id': 242,
    'name': 'Asia/Irkutsk',
    'offset': 'UTC +08:00'
  },
  {
    'id': 243,
    'name': 'Asia/Jakarta',
    'offset': 'UTC +07:00'
  },
  {
    'id': 244,
    'name': 'Asia/Jayapura',
    'offset': 'UTC +09:00'
  },
  {
    'id': 245,
    'name': 'Asia/Jerusalem',
    'offset': 'UTC +03:00'
  },
  {
    'id': 246,
    'name': 'Asia/Kabul',
    'offset': 'UTC +04:30'
  },
  {
    'id': 247,
    'name': 'Asia/Kamchatka',
    'offset': 'UTC +12:00'
  },
  {
    'id': 248,
    'name': 'Asia/Karachi',
    'offset': 'UTC +05:00'
  },
  {
    'id': 249,
    'name': 'Asia/Kathmandu',
    'offset': 'UTC +05:45'
  },
  {
    'id': 250,
    'name': 'Asia/Khandyga',
    'offset': 'UTC +09:00'
  },
  {
    'id': 251,
    'name': 'Asia/Kolkata',
    'offset': 'UTC +05:30'
  },
  {
    'id': 252,
    'name': 'Asia/Krasnoyarsk',
    'offset': 'UTC +07:00'
  },
  {
    'id': 253,
    'name': 'Asia/Kuala_Lumpur',
    'offset': 'UTC +08:00'
  },
  {
    'id': 254,
    'name': 'Asia/Kuching',
    'offset': 'UTC +08:00'
  },
  {
    'id': 255,
    'name': 'Asia/Kuwait',
    'offset': 'UTC +03:00'
  },
  {
    'id': 256,
    'name': 'Asia/Macau',
    'offset': 'UTC +08:00'
  },
  {
    'id': 257,
    'name': 'Asia/Magadan',
    'offset': 'UTC +11:00'
  },
  {
    'id': 258,
    'name': 'Asia/Makassar',
    'offset': 'UTC +08:00'
  },
  {
    'id': 259,
    'name': 'Asia/Manila',
    'offset': 'UTC +08:00'
  },
  {
    'id': 260,
    'name': 'Asia/Muscat',
    'offset': 'UTC +04:00'
  },
  {
    'id': 261,
    'name': 'Asia/Nicosia',
    'offset': 'UTC +03:00'
  },
  {
    'id': 262,
    'name': 'Asia/Novokuznetsk',
    'offset': 'UTC +07:00'
  },
  {
    'id': 263,
    'name': 'Asia/Novosibirsk',
    'offset': 'UTC +07:00'
  },
  {
    'id': 264,
    'name': 'Asia/Omsk',
    'offset': 'UTC +06:00'
  },
  {
    'id': 265,
    'name': 'Asia/Oral',
    'offset': 'UTC +05:00'
  },
  {
    'id': 266,
    'name': 'Asia/Phnom_Penh',
    'offset': 'UTC +07:00'
  },
  {
    'id': 267,
    'name': 'Asia/Pontianak',
    'offset': 'UTC +07:00'
  },
  {
    'id': 268,
    'name': 'Asia/Pyongyang',
    'offset': 'UTC +09:00'
  },
  {
    'id': 269,
    'name': 'Asia/Qatar',
    'offset': 'UTC +03:00'
  },
  {
    'id': 270,
    'name': 'Asia/Qyzylorda',
    'offset': 'UTC +06:00'
  },
  {
    'id': 271,
    'name': 'Asia/Riyadh',
    'offset': 'UTC +03:00'
  },
  {
    'id': 272,
    'name': 'Asia/Sakhalin',
    'offset': 'UTC +11:00'
  },
  {
    'id': 273,
    'name': 'Asia/Samarkand',
    'offset': 'UTC +05:00'
  },
  {
    'id': 274,
    'name': 'Asia/Seoul',
    'offset': 'UTC +09:00'
  },
  {
    'id': 275,
    'name': 'Asia/Shanghai',
    'offset': 'UTC +08:00'
  },
  {
    'id': 276,
    'name': 'Asia/Singapore',
    'offset': 'UTC +08:00'
  },
  {
    'id': 277,
    'name': 'Asia/Srednekolymsk',
    'offset': 'UTC +11:00'
  },
  {
    'id': 278,
    'name': 'Asia/Taipei',
    'offset': 'UTC +08:00'
  },
  {
    'id': 279,
    'name': 'Asia/Tashkent',
    'offset': 'UTC +05:00'
  },
  {
    'id': 280,
    'name': 'Asia/Tbilisi',
    'offset': 'UTC +04:00'
  },
  {
    'id': 281,
    'name': 'Asia/Tehran',
    'offset': 'UTC +04:30'
  },
  {
    'id': 282,
    'name': 'Asia/Thimphu',
    'offset': 'UTC +06:00'
  },
  {
    'id': 283,
    'name': 'Asia/Tokyo',
    'offset': 'UTC +09:00'
  },
  {
    'id': 284,
    'name': 'Asia/Tomsk',
    'offset': 'UTC +07:00'
  },
  {
    'id': 285,
    'name': 'Asia/Ulaanbaatar',
    'offset': 'UTC +08:00'
  },
  {
    'id': 286,
    'name': 'Asia/Urumqi',
    'offset': 'UTC +06:00'
  },
  {
    'id': 287,
    'name': 'Asia/Ust-Nera',
    'offset': 'UTC +10:00'
  },
  {
    'id': 288,
    'name': 'Asia/Vientiane',
    'offset': 'UTC +07:00'
  },
  {
    'id': 289,
    'name': 'Asia/Vladivostok',
    'offset': 'UTC +10:00'
  },
  {
    'id': 290,
    'name': 'Asia/Yakutsk',
    'offset': 'UTC +09:00'
  },
  {
    'id': 291,
    'name': 'Asia/Yangon',
    'offset': 'UTC +06:30'
  },
  {
    'id': 292,
    'name': 'Asia/Yekaterinburg',
    'offset': 'UTC +05:00'
  },
  {
    'id': 293,
    'name': 'Asia/Yerevan',
    'offset': 'UTC +04:00'
  },
  {
    'id': 294,
    'name': 'Atlantic/Azores',
    'offset': 'UTC'
  },
  {
    'id': 295,
    'name': 'Atlantic/Bermuda',
    'offset': 'UTC -03:00'
  },
  {
    'id': 296,
    'name': 'Atlantic/Canary',
    'offset': 'UTC +01:00'
  },
  {
    'id': 297,
    'name': 'Atlantic/Cape_Verde',
    'offset': 'UTC -01:00'
  },
  {
    'id': 298,
    'name': 'Atlantic/Faroe',
    'offset': 'UTC +01:00'
  },
  {
    'id': 299,
    'name': 'Atlantic/Madeira',
    'offset': 'UTC +01:00'
  },
  {
    'id': 300,
    'name': 'Atlantic/Reykjavik',
    'offset': 'UTC'
  },
  {
    'id': 301,
    'name': 'Atlantic/South_Georgia',
    'offset': 'UTC -02:00'
  },
  {
    'id': 302,
    'name': 'Atlantic/St_Helena',
    'offset': 'UTC'
  },
  {
    'id': 303,
    'name': 'Atlantic/Stanley',
    'offset': 'UTC -03:00'
  },
  {
    'id': 304,
    'name': 'Australia/Adelaide',
    'offset': 'UTC +09:30'
  },
  {
    'id': 305,
    'name': 'Australia/Brisbane',
    'offset': 'UTC +10:00'
  },
  {
    'id': 306,
    'name': 'Australia/Broken_Hill',
    'offset': 'UTC +09:30'
  },
  {
    'id': 307,
    'name': 'Australia/Currie',
    'offset': 'UTC +10:00'
  },
  {
    'id': 308,
    'name': 'Australia/Darwin',
    'offset': 'UTC +09:30'
  },
  {
    'id': 309,
    'name': 'Australia/Eucla',
    'offset': 'UTC +08:45'
  },
  {
    'id': 310,
    'name': 'Australia/Hobart',
    'offset': 'UTC +10:00'
  },
  {
    'id': 311,
    'name': 'Australia/Lindeman',
    'offset': 'UTC +10:00'
  },
  {
    'id': 312,
    'name': 'Australia/Lord_Howe',
    'offset': 'UTC +10:30'
  },
  {
    'id': 313,
    'name': 'Australia/Melbourne',
    'offset': 'UTC +10:00'
  },
  {
    'id': 314,
    'name': 'Australia/Perth',
    'offset': 'UTC +08:00'
  },
  {
    'id': 315,
    'name': 'Australia/Sydney',
    'offset': 'UTC +10:00'
  },
  {
    'id': 316,
    'name': 'Europe/Amsterdam',
    'offset': 'UTC +02:00'
  },
  {
    'id': 317,
    'name': 'Europe/Andorra',
    'offset': 'UTC +02:00'
  },
  {
    'id': 318,
    'name': 'Europe/Astrakhan',
    'offset': 'UTC +04:00'
  },
  {
    'id': 319,
    'name': 'Europe/Athens',
    'offset': 'UTC +03:00'
  },
  {
    'id': 320,
    'name': 'Europe/Belgrade',
    'offset': 'UTC +02:00'
  },
  {
    'id': 321,
    'name': 'Europe/Berlin',
    'offset': 'UTC +02:00'
  },
  {
    'id': 322,
    'name': 'Europe/Bratislava',
    'offset': 'UTC +02:00'
  },
  {
    'id': 323,
    'name': 'Europe/Brussels',
    'offset': 'UTC +02:00'
  },
  {
    'id': 324,
    'name': 'Europe/Bucharest',
    'offset': 'UTC +03:00'
  },
  {
    'id': 325,
    'name': 'Europe/Budapest',
    'offset': 'UTC +02:00'
  },
  {
    'id': 326,
    'name': 'Europe/Busingen',
    'offset': 'UTC +02:00'
  },
  {
    'id': 327,
    'name': 'Europe/Chisinau',
    'offset': 'UTC +03:00'
  },
  {
    'id': 328,
    'name': 'Europe/Copenhagen',
    'offset': 'UTC +02:00'
  },
  {
    'id': 329,
    'name': 'Europe/Dublin',
    'offset': 'UTC +01:00'
  },
  {
    'id': 330,
    'name': 'Europe/Gibraltar',
    'offset': 'UTC +02:00'
  },
  {
    'id': 331,
    'name': 'Europe/Guernsey',
    'offset': 'UTC +01:00'
  },
  {
    'id': 332,
    'name': 'Europe/Helsinki',
    'offset': 'UTC +03:00'
  },
  {
    'id': 333,
    'name': 'Europe/Isle_of_Man',
    'offset': 'UTC +01:00'
  },
  {
    'id': 334,
    'name': 'Europe/Istanbul',
    'offset': 'UTC +03:00'
  },
  {
    'id': 335,
    'name': 'Europe/Jersey',
    'offset': 'UTC +01:00'
  },
  {
    'id': 336,
    'name': 'Europe/Kaliningrad',
    'offset': 'UTC +02:00'
  },
  {
    'id': 337,
    'name': 'Europe/Kiev',
    'offset': 'UTC +03:00'
  },
  {
    'id': 338,
    'name': 'Europe/Kirov',
    'offset': 'UTC +03:00'
  },
  {
    'id': 339,
    'name': 'Europe/Lisbon',
    'offset': 'UTC +01:00'
  },
  {
    'id': 340,
    'name': 'Europe/Ljubljana',
    'offset': 'UTC +02:00'
  },
  {
    'id': 341,
    'name': 'Europe/London',
    'offset': 'UTC +01:00'
  },
  {
    'id': 342,
    'name': 'Europe/Luxembourg',
    'offset': 'UTC +02:00'
  },
  {
    'id': 343,
    'name': 'Europe/Madrid',
    'offset': 'UTC +02:00'
  },
  {
    'id': 344,
    'name': 'Europe/Malta',
    'offset': 'UTC +02:00'
  },
  {
    'id': 345,
    'name': 'Europe/Mariehamn',
    'offset': 'UTC +03:00'
  },
  {
    'id': 346,
    'name': 'Europe/Minsk',
    'offset': 'UTC +03:00'
  },
  {
    'id': 347,
    'name': 'Europe/Monaco',
    'offset': 'UTC +02:00'
  },
  {
    'id': 348,
    'name': 'Europe/Moscow',
    'offset': 'UTC +03:00'
  },
  {
    'id': 349,
    'name': 'Europe/Oslo',
    'offset': 'UTC +02:00'
  },
  {
    'id': 350,
    'name': 'Europe/Paris',
    'offset': 'UTC +02:00'
  },
  {
    'id': 351,
    'name': 'Europe/Podgorica',
    'offset': 'UTC +02:00'
  },
  {
    'id': 352,
    'name': 'Europe/Prague',
    'offset': 'UTC +02:00'
  },
  {
    'id': 353,
    'name': 'Europe/Riga',
    'offset': 'UTC +03:00'
  },
  {
    'id': 354,
    'name': 'Europe/Rome',
    'offset': 'UTC +02:00'
  },
  {
    'id': 355,
    'name': 'Europe/Samara',
    'offset': 'UTC +04:00'
  },
  {
    'id': 356,
    'name': 'Europe/San_Marino',
    'offset': 'UTC +02:00'
  },
  {
    'id': 357,
    'name': 'Europe/Sarajevo',
    'offset': 'UTC +02:00'
  },
  {
    'id': 358,
    'name': 'Europe/Saratov',
    'offset': 'UTC +04:00'
  },
  {
    'id': 359,
    'name': 'Europe/Simferopol',
    'offset': 'UTC +03:00'
  },
  {
    'id': 360,
    'name': 'Europe/Skopje',
    'offset': 'UTC +02:00'
  },
  {
    'id': 361,
    'name': 'Europe/Sofia',
    'offset': 'UTC +03:00'
  },
  {
    'id': 362,
    'name': 'Europe/Stockholm',
    'offset': 'UTC +02:00'
  },
  {
    'id': 363,
    'name': 'Europe/Tallinn',
    'offset': 'UTC +03:00'
  },
  {
    'id': 364,
    'name': 'Europe/Tirane',
    'offset': 'UTC +02:00'
  },
  {
    'id': 365,
    'name': 'Europe/Ulyanovsk',
    'offset': 'UTC +04:00'
  },
  {
    'id': 366,
    'name': 'Europe/Uzhgorod',
    'offset': 'UTC +03:00'
  },
  {
    'id': 367,
    'name': 'Europe/Vaduz',
    'offset': 'UTC +02:00'
  },
  {
    'id': 368,
    'name': 'Europe/Vatican',
    'offset': 'UTC +02:00'
  },
  {
    'id': 369,
    'name': 'Europe/Vienna',
    'offset': 'UTC +02:00'
  },
  {
    'id': 370,
    'name': 'Europe/Vilnius',
    'offset': 'UTC +03:00'
  },
  {
    'id': 371,
    'name': 'Europe/Volgograd',
    'offset': 'UTC +03:00'
  },
  {
    'id': 372,
    'name': 'Europe/Warsaw',
    'offset': 'UTC +02:00'
  },
  {
    'id': 373,
    'name': 'Europe/Zagreb',
    'offset': 'UTC +02:00'
  },
  {
    'id': 374,
    'name': 'Europe/Zaporozhye',
    'offset': 'UTC +03:00'
  },
  {
    'id': 375,
    'name': 'Europe/Zurich',
    'offset': 'UTC +02:00'
  },
  {
    'id': 376,
    'name': 'Indian/Antananarivo',
    'offset': 'UTC +03:00'
  },
  {
    'id': 377,
    'name': 'Indian/Chagos',
    'offset': 'UTC +06:00'
  },
  {
    'id': 378,
    'name': 'Indian/Christmas',
    'offset': 'UTC +07:00'
  },
  {
    'id': 379,
    'name': 'Indian/Cocos',
    'offset': 'UTC +06:30'
  },
  {
    'id': 380,
    'name': 'Indian/Comoro',
    'offset': 'UTC +03:00'
  },
  {
    'id': 381,
    'name': 'Indian/Kerguelen',
    'offset': 'UTC +05:00'
  },
  {
    'id': 382,
    'name': 'Indian/Mahe',
    'offset': 'UTC +04:00'
  },
  {
    'id': 383,
    'name': 'Indian/Maldives',
    'offset': 'UTC +05:00'
  },
  {
    'id': 384,
    'name': 'Indian/Mauritius',
    'offset': 'UTC +04:00'
  },
  {
    'id': 385,
    'name': 'Indian/Mayotte',
    'offset': 'UTC +03:00'
  },
  {
    'id': 386,
    'name': 'Indian/Reunion',
    'offset': 'UTC +04:00'
  },
  {
    'id': 387,
    'name': 'Pacific/Apia',
    'offset': 'UTC +13:00'
  },
  {
    'id': 388,
    'name': 'Pacific/Auckland',
    'offset': 'UTC +12:00'
  },
  {
    'id': 389,
    'name': 'Pacific/Bougainville',
    'offset': 'UTC +11:00'
  },
  {
    'id': 390,
    'name': 'Pacific/Chatham',
    'offset': 'UTC +12:45'
  },
  {
    'id': 391,
    'name': 'Pacific/Chuuk',
    'offset': 'UTC +10:00'
  },
  {
    'id': 392,
    'name': 'Pacific/Easter',
    'offset': 'UTC -06:00'
  },
  {
    'id': 393,
    'name': 'Pacific/Efate',
    'offset': 'UTC +11:00'
  },
  {
    'id': 394,
    'name': 'Pacific/Enderbury',
    'offset': 'UTC +13:00'
  },
  {
    'id': 395,
    'name': 'Pacific/Fakaofo',
    'offset': 'UTC +13:00'
  },
  {
    'id': 396,
    'name': 'Pacific/Fiji',
    'offset': 'UTC +12:00'
  },
  {
    'id': 397,
    'name': 'Pacific/Funafuti',
    'offset': 'UTC +12:00'
  },
  {
    'id': 398,
    'name': 'Pacific/Galapagos',
    'offset': 'UTC -06:00'
  },
  {
    'id': 399,
    'name': 'Pacific/Gambier',
    'offset': 'UTC -09:00'
  },
  {
    'id': 400,
    'name': 'Pacific/Guadalcanal',
    'offset': 'UTC +11:00'
  },
  {
    'id': 401,
    'name': 'Pacific/Guam',
    'offset': 'UTC +10:00'
  },
  {
    'id': 402,
    'name': 'Pacific/Honolulu',
    'offset': 'UTC -10:00'
  },
  {
    'id': 403,
    'name': 'Pacific/Kiritimati',
    'offset': 'UTC +14:00'
  },
  {
    'id': 404,
    'name': 'Pacific/Kosrae',
    'offset': 'UTC +11:00'
  },
  {
    'id': 405,
    'name': 'Pacific/Kwajalein',
    'offset': 'UTC +12:00'
  },
  {
    'id': 406,
    'name': 'Pacific/Majuro',
    'offset': 'UTC +12:00'
  },
  {
    'id': 407,
    'name': 'Pacific/Marquesas',
    'offset': 'UTC -09:30'
  },
  {
    'id': 408,
    'name': 'Pacific/Midway',
    'offset': 'UTC -11:00'
  },
  {
    'id': 409,
    'name': 'Pacific/Nauru',
    'offset': 'UTC +12:00'
  },
  {
    'id': 410,
    'name': 'Pacific/Niue',
    'offset': 'UTC -11:00'
  },
  {
    'id': 411,
    'name': 'Pacific/Norfolk',
    'offset': 'UTC +11:00'
  },
  {
    'id': 412,
    'name': 'Pacific/Noumea',
    'offset': 'UTC +11:00'
  },
  {
    'id': 413,
    'name': 'Pacific/Pago_Pago',
    'offset': 'UTC -11:00'
  },
  {
    'id': 414,
    'name': 'Pacific/Palau',
    'offset': 'UTC +09:00'
  },
  {
    'id': 415,
    'name': 'Pacific/Pitcairn',
    'offset': 'UTC -08:00'
  },
  {
    'id': 416,
    'name': 'Pacific/Pohnpei',
    'offset': 'UTC +11:00'
  },
  {
    'id': 417,
    'name': 'Pacific/Port_Moresby',
    'offset': 'UTC +10:00'
  },
  {
    'id': 418,
    'name': 'Pacific/Rarotonga',
    'offset': 'UTC -10:00'
  },
  {
    'id': 419,
    'name': 'Pacific/Saipan',
    'offset': 'UTC +10:00'
  },
  {
    'id': 420,
    'name': 'Pacific/Tahiti',
    'offset': 'UTC -10:00'
  },
  {
    'id': 421,
    'name': 'Pacific/Tarawa',
    'offset': 'UTC +12:00'
  },
  {
    'id': 422,
    'name': 'Pacific/Tongatapu',
    'offset': 'UTC +13:00'
  },
  {
    'id': 423,
    'name': 'Pacific/Wake',
    'offset': 'UTC +12:00'
  },
  {
    'id': 424,
    'name': 'Pacific/Wallis',
    'offset': 'UTC +12:00'
  },
  {
    'id': 425,
    'name': 'UTC',
    'offset': 'UTC'
  },
];

export default { timeZones };
