<app-data-table-filter-analytics #filter
                       *ngIf="filterQuestions"
                       [cfg]="filterConfig"
                       [slug]="filterSlug"
                       [questions]="filterQuestions"
                       [defaultQuestions]="defaultFilterQuestions"
                       (onSubmitEmitter)="onFilterHandler($event)">
</app-data-table-filter-analytics>

<div class="alert alert-danger fade show mrg-btm-0" *ngIf="company && !company.isTagged()" translate>
  common.untagged_company
</div>

<div class="alert alert-info" role="alert" *ngIf="!activitiesData && company.isTagged() && !loading">
  <span [translate]="'components.looker-iframe.alerts.apply_filters'"></span>
</div>

<app-loader noWrapper *ngIf="loading" />

<div class="row" *ngIf="chartOptions && company.isTagged()">
  <div class="col-md-12">
    <div class="card">
      <div class="card-block padding-20">

        <div class="card-heading no-pdd mrg-btm-15">
          <h4>{{chartOptions.name}}</h4>
          <i class="tooltip-position ti-info-alt mrg-top-10 font-size-14 positioning"
              tooltipPosition="left"
              pTooltip="{{ 'dashboards.migrations.activity.table.tooltip' | translate }}">
          </i>
        </div>

        <div class="table-responsive" style="display: block">
          <table class="table table-sm table-bordered table-hover" aria-hidden="true">
            <thead>
              <tr>
                <th scope="auto"></th>
                <th scope="auto" class="highlight-header text-center" *ngFor="let header of tableHeaders.list">{{header.name}}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" *ngFor="let row of chartOptions.table; let i = index" >
                <th scope="auto">{{chartOptions.axisLabels.y[i] | translate}}</th>
                <td class="text-center" *ngFor="let cell of row; let j = index">{{cell[2] | number:'1.0-0':'es'}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
</div>
