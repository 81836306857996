import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatrixService } from '../../../../../shared/services/matrix.service';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { LocationsService } from '../../../../data-warehouse/locations/locations.service';
import { SegmentsService } from '../../../../segments/segments.service';
import { TagSalesExtraValuesService } from '../../../../../shared/services/tag-sales-extra-values.service';
import { MultiselectDataSource } from '../../../../../shared/components/multiselect/multiselect';
import { Subscription } from 'rxjs';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { DataTableFilterService } from '../../../../../shared/components/data-table-filter/data-table-filter.service';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { ProfileService } from '../../../../../profiles/profile.service';
import { CurrentCompany } from '../../../../../shared/models/current-user';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-migrations-flow',
  templateUrl: './migrations-flow.component.html',
  styleUrls: ['./migrations-flow.component.css']
})
export class MigrationsFlowComponent implements OnInit, OnDestroy {

  filters: QuestionBase<any>[];
  defaultFilters: QuestionBase<any>[];
  period_from: MultiselectDataSource[];
  period_to: MultiselectDataSource[];
  subs$: Subscription[] = [];
  loading: boolean;
  salesExpensesDataCurrent: any;
  salesExpensesDataFrom: any;
  migrationsData: any;
  company: CurrentCompany;
  filterSlug: string;
  filterConfig = { disableOnInit: true, disableOnSubmit: true, ensureButtonDisabling: true};
  chartOptions: {
    columnMenuEnabled: boolean;
    table: any;
    sort: number;
    name: any;
    description: any;
    axisLabels: { x: any; y: any; };
    axisTitles: { x: any; y: any; };
    colorRules: { mainDiagonal: string; };
    highLightedColumns: any[];
  };

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private matrixService: MatrixService,
    private refreshCacheService: RefreshCacheService,
    private filterService: DataTableFilterService,
    private locationsService: LocationsService,
    private segmentsService: SegmentsService,
    private confirmationService: ConfirmationService,
    private tagSalesExtraValuesService: TagSalesExtraValuesService,
    private profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    registerLocaleData(es);
    this.company = new CurrentCompany(this.profileService.getProfileCompany());
    this.filterSlug = this.route.snapshot.data.slug;
    if (this.company.isTagged()) {
      this.filters = this.setFilterQuestions();
      this.defaultFilters = this.setFilterQuestions();
    }
    setTimeout(() => this.filterService.loaderStatus.next(true));
  }

  ngOnDestroy(): void {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }
  
  isAxisTitleInformed(axis): boolean {
    return this.chartOptions?.axisTitles?.hasOwnProperty(axis);
  }

  getStyle(row, col): any {
    const options = this.chartOptions;
    if (options?.colorRules) {
      const colorRules = options.colorRules;
      if (colorRules.mainDiagonal && row === col) {
        return {'background-color': colorRules.mainDiagonal};
      }
    }
    return '';
  }

  onFilterHandler(filterFormValue): void {
    this.loading = true;
    if (filterFormValue.apply_from && filterFormValue.apply_to) {
      if (!this.compareDates(filterFormValue)) {
        return;
      }
    }
    this.filterService.loaderStatus.next(false);
    this.getMigrationsData(filterFormValue);
  }

  private getMigrationsData(filterFormValue?): void {
    let filtering;
    if (filterFormValue) {
      filtering = {
        country: 'ES',
        location_ids: filterFormValue.location_ids,
        segment_id: filterFormValue.segment_id,
        apply_from: filterFormValue.apply_from,
        apply_to: filterFormValue.apply_to,
      };
    }

    const params = {
      apiEndPoint: 'analytics/migrations',
      filtering: filtering
    };

    this.loading = true;
    const totalCustomers$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this.migrationsData = reqResponse;
        this.generateMigrationsChart(this.migrationsData);
        totalCustomers$.unsubscribe();
        this.loading = false;
        this.filterService.loaderStatus.next(true);
      }
    });
    this.subs$.push(totalCustomers$);
  }

  private generateMigrationsChart(data): void {
    const table = this.matrixService.createFromCoordsArray(data.values, [data.names.length, data.names.length], [1 , 0] );
    this.chartOptions = {
      columnMenuEnabled: false,
      table: table,
      sort: 1,
      name: this.translate.instant('views.common.navigation.dashboards.migrations.title'),
      description: this.translate.instant('lg_specs.dashboards.potential.chart_descriptions.migrations'),
      axisLabels: {
          x: data.names,
          y: data.names
      },
      axisTitles: {
          x: this.translate.instant('dashboards.migrations.migrations_flow.table.x'),
          y: this.translate.instant('dashboards.migrations.migrations_flow.table.y')
      },
      colorRules: {
          mainDiagonal: 'paleturquoise'
      },
      highLightedColumns: []
    };
  }

  private alertDateMessage(): void {
    this.confirmationService.displayAlert(
      this.translate.instant('components.data-table.errors.title_error_date'),
      this.translate.instant('components.data-table.errors.date_bigger_than'),
      'warning').catch(() => {});
  }

  private compareDates(filterFormValue): boolean {
    if (parseInt(filterFormValue.apply_from, 10) > parseInt(filterFormValue.apply_to, 10)) {
      this.loading = false;
      this.alertDateMessage();
      setTimeout(() => {
        this.filterService.loaderStatus.next(true);
      });
      return false;
    }
    return true;
  }

  private setFilterQuestions(): QuestionBase<any>[] {
    return [
      new MultiSelectQuestion({
        key: 'location_ids',
        label: 'dashboards.migrations.migrations_flow.filters.location',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locationsService,
      }),
      new MultiSelectQuestion({
        key: 'segment_id',
        label: 'dashboards.migrations.migrations_flow.filters.segment',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: true },
        dataSource: this.segmentsService,
      }),
      new MultiSelectQuestion({
        key: 'apply_from',
        label: 'dashboards.migrations.migrations_flow.filters.apply_from',
        cssClasses: 'form-control input-default',
        required: true,
        settings: { singleSelection: true, enableSearchFilter: false},
        dataSource: this.tagSalesExtraValuesService,
      }),
      new MultiSelectQuestion({
        key: 'apply_to',
        label: 'dashboards.migrations.migrations_flow.filters.apply_to',
        cssClasses: 'form-control input-default',
        required: true,
        settings: { singleSelection: true, enableSearchFilter: false},
        dataSource: this.tagSalesExtraValuesService,
      })
    ];
  }

}
