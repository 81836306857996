import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CampaignTemplatesService } from './service/campaign-templates-service.service';
import { ConfirmationService } from '../../services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../../profiles/profile.service';
import { ModalStatusService } from '../../services/modal-status.service';
import { Subscription } from 'rxjs';
import { DictionaryService } from '../../services/dictionary.service';
import { ContentDesignerService } from '../../../resources/content-designer/content-designer.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { CurrentUser } from '../../models/current-user';
import { FeatureFlagsService } from './../../services/feature-flags.service';

@Component({
  selector: 'app-create-update-templates',
  templateUrl: './create-update-templates.component.html',
  styleUrls: ['./create-update-templates.component.css'],
  providers: [CampaignTemplatesService, DictionaryService, ContentDesignerService]
})

export class CreateUpdateTemplatesComponent implements OnInit, OnDestroy {

  id: number;
  userData: any;
  templateData: any;
  flags = this.featureFlags.flags;
  subs$: Subscription[] = [];
  beeCustomConfig = {
    onSave: (json: JSON, html: string) => this.onSave(json, html)
  };

  private mergeTags: {name: string, value:string}[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private currentUserProfile: ProfileService,
    private modalStatusSrv: ModalStatusService,
    private campaignTemplatesService: CampaignTemplatesService,
    private dictionary: DictionaryService,
    private contentDesignerService: ContentDesignerService,
    private location: Location,
    private featureFlags: FeatureFlagsService,
  ) { }

  ngOnInit() {
    this.mergeTags = this.dictionary.getValuesByKey('campaign_templates_custom_variables');
    if(!this.flags.showCustom1DynamicVariable){
      this.mergeTags = this.mergeTags.filter(tag => tag.value !== '{custom1}');
    }
    this.getUserData();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  confirmClose() {
    const confirmationTitle = this.translate.instant('resources.campaign_templates.modal.warnings.continue');
    const confirmationMessage = this.translate.instant('resources.campaign_templates.modal.warnings.cancel_message');

    this.confirmationService.displayConfirmationAlert(confirmationTitle, confirmationMessage).then(
      data => {
        if (data.hasOwnProperty('value') && data.value) {
          this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
          this.modalStatusSrv.modalStatus.emit();
        }
      }
    ).catch(() => {});
  }

  private getUserData() {
    const profile$ = this.currentUserProfile.getRawUserProfile().subscribe(
      userData => {
        this.userData = userData;
        this.getParams();
      },
      errorData => this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), JSON.stringify(errorData.error.error))
    );
    this.subs$.push(profile$);
  }

  private getParams() {
    const params$ = this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.getById();
      } else {
        this.duplicateDefaultToCreateNew();
      } 
    });
    this.subs$.push(params$);
  }

  private duplicateDefaultToCreateNew(){
    const duplicate$ = this.contentDesignerService.duplicateTemplate(1).subscribe(
      (response: HttpResponse<any>) => {
        this.modalStatusSrv.modalStatus.emit();
        this.id = response['id'];
        this.getById();
      },
      (errData: HttpErrorResponse) => {
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errData.error.error);
        this.location.back();
      }
    );
    this.subs$.push(duplicate$);
  }

  private getById() {
    const template$ = this.campaignTemplatesService.getById(this.id).subscribe(
      templateData => {
        this.templateData = templateData;
        const user = new CurrentUser(this.currentUserProfile.getStoredUser());
        const language = this.flags.newLanguageMenuVisibility ? user.userPreferredLanguage : user.companyLanguage;
        this.campaignTemplatesService.initBee(
          this.userData,
          this.beeCustomConfig,
          templateData['schema'],
          language,
          this.mergeTags
        );
      },
      errorData => this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), JSON.stringify(errorData.error.error))
    );
    this.subs$.push(template$);
  }

  private onSave(json, html) {
    const saveTemplate$ = this.campaignTemplatesService.updateTemplateById(this.id, json, html, this.templateData.name).subscribe(
      () => saveTemplate$.unsubscribe(),
      errorData => {
        saveTemplate$.unsubscribe();
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), JSON.stringify(errorData.error.error));
      }
    );
    this.subs$.push(saveTemplate$);
  }
}
