import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../../../shared/services/question-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalStatusService } from '../../../../../../shared/services/modal-status.service';
import { QuestionBase } from '../../../../../../shared/models/forms/question-base';
import { NewsTaxonomiesService } from '../../../news-taxonomies.service';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-form-news-taxonomies',
  templateUrl: './form-news-taxonomies.component.html',
  styleUrls: ['./form-news-taxonomies.component.css']
})

export class FormNewsTaxonomiesComponent implements OnInit, OnDestroy {

  public form: UntypedFormGroup;
  public inputs: any;
  public id: number;
  public loading: boolean;
  public subs$: Subscription[] = [];

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private newsTaxonomiesService: NewsTaxonomiesService,
    private modalStatusService: ModalStatusService) { }

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach(s$ => s$.unsubscribe()); }
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  public returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/omnichannel-manager/cms/taxonomies']).catch(() => {});
    }
  }

  sendData() {

    const dataToSend = {
      name: this.form.value.name,
      slug: this.form.value.slug,
    };

    this.modalStatusService.formLoaderStatus.emit('loading');
    if ( this.id ) {
      this.handleRequests(
        this.newsTaxonomiesService.updateData(dataToSend, this.id),
        this.translate.instant('resources.cms.form.warnings.news_taxonomies_update_title'),
        this.translate.instant('resources.cms.form.warnings.news_taxonomies_update_text')
      );
    } else {
      this.handleRequests(
        this.newsTaxonomiesService.sendData(dataToSend),
        this.translate.instant('resources.cms.form.warnings.news_taxonomies_success_title'),
        this.translate.instant('resources.cms.form.warnings.news_taxonomies_success_text')
      );
    }
  }

  private handleRequests(obs$: Observable<any>, successTitle: string, successText: string) {
    const requests$ = obs$.subscribe(
      response => {
        requests$.unsubscribe();
        this.confirmationService.displaySuccessAlert(successTitle, successText ).catch(() => {});
        this.modalStatusService.modalStatus.emit();
        this.returnToList();
      },
      errorData => {
        this.modalStatusService.formLoaderStatus.emit('loading_finished');
        this.confirmationService.displayErrorAlert('Error', errorData.error.error);
      }
    );
    this.subs$.push(requests$);
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getNewsTaxonomiesById(this.id);
      } else {
        this.inputs = this.newsTaxonomiesService.setInputs({});
        this.form = this.qcs.toFormGroup(this.inputs);
      }
    });
  }

  private getNewsTaxonomiesById(id: number) {
    const getTaxonomies$ = this.newsTaxonomiesService.getNewsTaxonomiesById(this.id).subscribe(
      data => {
        getTaxonomies$.unsubscribe();
        this.inputs = this.newsTaxonomiesService.setInputs(data);
        this.form = this.qcs.toFormGroup(this.inputs);
      }
    );
    this.subs$.push(getTaxonomies$);
  }

}
