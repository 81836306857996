<div class="row" *ngIf="inputs">

  <div class="col-md-3">
    <div class="form-group">
      <app-question [question]="getInputConfig('campaign_ids')"
                    [form]="form"
                    (multiselectChanged)="handleCampaignsSelected($event)">
      </app-question>
    </div>
  </div>

  <div class="col-md-3">
    <div class="form-group">
      <app-question [question]="getInputConfig('impacted')"  
                    [form]="form"
                    (multiselectChanged)="handleImpactedSelected($event)">
      </app-question>
    </div>
  </div>

  <div class="col-md-3">
    <div class="form-group">
      <app-question [question]="getInputConfig('delivery_type')"
                    [form]="form"
                    (multiselectChanged)="handleDeliveryTypeSelected($event)">
      </app-question>
    </div>
  </div>

  <div class="col-md-3" *ngIf="showApplies()">
    <div class="form-group">
      <app-question [question]="getInputConfig('campaign_history_ids')" [form]="form"></app-question>
    </div>
  </div>
</div>
