import { Modules } from "../enums/modules.enums";

const baseUrl = 'https://loyal-guru.atlassian.net/servicedesk/customer/portal/';

export const MODULE_HELP_CENTER_LINKS = {
    [Modules.LoyaltyAnalytics]: `${baseUrl}14/topic/ad1413b9-036c-4883-8681-c35149dbf976`,
    [Modules.RetailGroupAnalytics]: `${baseUrl}14/topic/45326854-9501-4cbd-ad77-b8f7e564e708`,
    [Modules.BasicAnalytics]: `${baseUrl}14`,
    [Modules.RetailAnalytics]: `${baseUrl}14/topic/b0617bb4-32d6-404a-bcbd-b0ad81261af5`,
    [Modules.SupplierAnalytics]: `${baseUrl}14/topic/d680df20-3c98-4e4e-8b11-62a2dbe31d7c`,
    [Modules.OffersPersonalization]: `${baseUrl}13`,
    [Modules.Loyalty]: `${baseUrl}9`,
    [Modules.Omnichannel]: `${baseUrl}10`,
    [Modules.Audiences]: `${baseUrl}12`,
    [Modules.CustomerDataPlatform]: `${baseUrl}19`,
} as const;