import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-show-news-taxonomies',
  templateUrl: './show-news-taxonomies.component.html',
  styleUrls: ['./show-news-taxonomies.component.css']
})
export class ShowNewsTaxonomiesComponent implements OnInit {

  public element: any;
  public apiEndPoint: string;
  public id: number;
  public isModal: boolean;

  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private router: Router
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.isModal = this.router.url.indexOf('(modal:show/news_taxonomies/') >= 0;
    this.getParams();
    this.resourcesService.getData({ apiEndPoint: this.apiEndPoint + '/' + this.id })
        .subscribe(res => {
          this.element = res;
        },
        error => {
          console.log('error:', error);
        });
  }

  getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'news_taxonomies';
      }
    });
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

}
