import { ActivatedRoute, Router } from '@angular/router';
import { BigqueryjobsService } from '../bigqueryjobs.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';

@Component({
  selector: 'app-show-bigquery-jobs',
  templateUrl: './show-bigquery-jobs.component.html',
  styleUrls: ['./show-bigquery-jobs.component.css']
})
export class ShowBigqueryJobsComponent implements OnInit, OnDestroy {

  apiEndPoint: string;
  element: any;
  id: number;
  stepExecuting: boolean;
  subs$: Subscription[];

  bigqueryJobValues = {
    creating_csv: this.translate.instant('resources.jobs.step_status.creating_csv'),
    creating_job: this.translate.instant('resources.jobs.step_status.creating_job'),
    error: this.translate.instant('resources.jobs.step_status.error'),
    processing: this.translate.instant('resources.jobs.step_status.processing'),
    success: this.translate.instant('resources.jobs.step_status.success'),
    uploading: this.translate.instant('resources.jobs.step_status.uploading')
  };

  private destroy$ = new Subject<void>();

  get taskStepsSegments(): object | null {
    let taskStepsSegments = {};
    const steps = this.element?.['bigquery_job_steps'];
    if (steps?.length) {
      taskStepsSegments = steps.reduce((list, item) => {
        if (item.category === 'segment' || item.category === 'segment_category') {
          if (!list[item.category]) {
            list[item.category] = [];
          }
          list[item.category].push({id: item.id, name: item.name});
        }
        return list;
      }, {});
    }
    return Object.keys(taskStepsSegments)?.length ? taskStepsSegments : null;
  }

  constructor(
    private bigqueryJobsService: BigqueryjobsService,
    private confirmationService: ConfirmationService,
    private modalStatusService: ModalStatusService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {
    this.subs$ = [];
  }

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy() {
    this.subs$.forEach( s$ => s$.unsubscribe());
  }

  translateStatus(str: string) {
    if (str === null || str === undefined || str === '') return '';
    if (str === '1') return this.translate.instant('resources.jobs.step_status.processing');
    if (str === 'done') return this.translate.instant('resources.jobs.step_status.success');
    if (str === 'pending') return this.translate.instant('resources.jobs.modal.fields.pending_execution');
    return this.bigqueryJobValues[str];
  }

  execute() {

    const callback = () => {
      this.confirmationService.displaySuccessAlert('', this.translate.instant('resources.jobs.messages.executed_desc')).catch(() => {});
      this.closeModal();
    };

    this.bigqueryJobsService.process(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.confirmExecution(callback);
      },
      error: (error: HttpErrorResponse) => {
        this.handleErrorResponse(error);
      }
    });
  }

  executeStep(step) {

    const callback = () => {
      this.confirmationService.displaySuccessAlert('', this.translate.instant('resources.jobs.messages.step_executed_desc')).catch(() => {})
    };

    this.bigqueryJobsService.executeStep({step_id: step.id}, this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.confirmExecution(callback);
      },
      error: (error: HttpErrorResponse) => {
        this.handleErrorResponse(error);
      }
    });
  }

  executeFromStep(index) {
    this.pushStep(index);
  }

  openExecuteDate(step?: object) {
    if(step) {
      this.router.navigate([{ outlets: { modal: `show/bigquery_jobs/${this.id}/steps/${step['id']}/execute_date` } }]).catch(() => {});
    } else {
      this.router.navigate([{ outlets: { modal: `show/bigquery_jobs/${this.id}/execute_date` } }]).catch(() => {});
    }
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private pushStep(start_index) {
    const request = (start_index > 0) ? this.bigqueryJobsService.processStartStep(start_index, this.id) : this.bigqueryJobsService.process(this.id);
    const callback = () => {
      this.confirmationService.displaySuccessAlert('', this.translate.instant('resources.jobs.messages.step_executed_desc')).catch(() => {})
    };

    request.subscribe({
      next: () => {
        this.confirmExecution(callback);
      },
      error: (error: HttpErrorResponse) => {
        this.handleErrorResponse(error);
      }
    });
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'bigquery_jobs';
        this.getBigQueryJobDetail();
      }
    });
  }

  private getBigQueryJobDetail() {
    const getBigQueryJobsById$ = this.bigqueryJobsService.getBigqueryJobsById(this.id).subscribe(
      response => {
        this.element = response;
        this.element['averageString'] = this.element['average_duration'] ? moment.duration(this.element['average_duration'], 'seconds').humanize() : '-';
        this.isExecuting();
      }
    );
    this.subs$.push(getBigQueryJobsById$);
  }

  private handleSuccessResponse(callback?: Function) {
    this.getBigQueryJobDetail();
    if (callback) callback();
  }

  private handleErrorResponse(errorResponse: HttpErrorResponse) {
    this.confirmationService.displayHttpErrorAlert(errorResponse);
  }

  private isExecuting() {
    this.stepExecuting = this.element['bigquery_job_steps'].some(step => ['creating_csv', 'uploading', 'creating_job', 'processing', '1'].includes(step.status))
  }

  private confirmExecution(callback: Function) {
    this.confirmationService.displayConfirmationAlert(
      this.translate.instant('common.messages.are_you_sure'),
      this.translate.instant('resources.jobs.messages.confirm_execution')
    ).then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.handleSuccessResponse(callback);
        this.modalStatusService.modalStatus.emit();
      }
    }).catch(() => {});
  }

}
