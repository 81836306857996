import { BigqueryJobsStepsBase } from './bigquerystep-base';

export class DumpStep extends BigqueryJobsStepsBase {

  category = 'dump';
  tableName: string;

  constructor(options: {} = {}) {
    super(options);
    this.category = options['category'] || 'dump';
    this.tableName = options['table_name'] || '';
  }

  public getPayload() {
    const payload = super.getPayload();
    const newValues = {table_name: this.tableName};
    return {...payload, ...newValues};
  }
}
