import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { UntypedFormGroup, AbstractControl, ValidatorFn } from '@angular/forms';
import { CouponsService } from '../../../../coupons/coupons.service';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { CheckboxQuestion } from '../../../../../shared/models/forms/question-checkbox';
import { Subscription } from 'rxjs';
import { PlanCampaignAction } from '../plan-campaign-action';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FloatQuestion } from '../../../../../shared/models/forms/question-float';
import { FeatureFlagsService } from '../../../../../shared/services/feature-flags.service';
import { isNullOrUndefined } from '../../../../../shared/utils/common.utils';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[app-audience-content]',
  templateUrl: './audience-content.component.html',
  styleUrls: ['./audience-content.component.css']
})
export class AudienceContentComponent implements OnInit, OnDestroy {

  inputs: QuestionBase<any>[];
  form: UntypedFormGroup;
  subs$: Subscription[] = [];
  childForm: UntypedFormGroup;
  couponId: number;
  isCouponSelected = false;
  inputsVisibility = {
    estimatedCustomerRedemption: false,
    estimatedRedemption: false,
    estimatedDelivery: false
  };
  flags = this.featureFlags.flags;

  @Input() item: PlanCampaignAction;
  @Input() parentForm: UntypedFormGroup;
  @Output() formValidity: EventEmitter<any> = new EventEmitter();
  @Output() actionEmitter: EventEmitter<PlanCampaignAction> = new EventEmitter();
  @Output() couponCreation: EventEmitter<PlanCampaignAction> = new EventEmitter();
  @Output() couponSelected: EventEmitter<any> = new EventEmitter();
  @Output() couponRecommend: EventEmitter<PlanCampaignAction> = new EventEmitter();
  @Output() campaignEdition: EventEmitter<PlanCampaignAction> = new EventEmitter();

  constructor(
    private couponsService: CouponsService,
    private qcs: QuestionControlService,
    private refreshCacheService: RefreshCacheService,
    private featureFlags: FeatureFlagsService
  ) {}

  ngOnInit() {
    this.setInputs();
    this.enqueuePollingRequest();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  handleCouponSelected(selectedCoupon) {
    if (selectedCoupon) {
      this.isCouponSelected = true;
      const key = this.inputs[0].key;
      this.childForm.get(key).setValue(true);
      if(!this.item.hasCustomEstimationFields) {
        this.autoFillEstimationFields(selectedCoupon);
      }
    } else {
      this.isCouponSelected = false;
    }
    this.couponSelected.emit(selectedCoupon);
  }

  handlePreSelectedCoupon(selectedCoupon: object) {
    if(!this.item.hasCustomEstimationFields){
      this.autoFillEstimationFields({rawElement: selectedCoupon});
    }
  }

  handleShowCoupon() {
    if (this.childForm.value.coupon_id !== undefined && this.childForm.value.coupon_id.length > 0) {
      this.couponId = this.childForm.value.coupon_id[0].id;
      window.open(`#/offers-personalization/plans(modal:show/coupons/${this.couponId})`);
    }
  }

  handleShowEdit() {
    if (this.childForm.value.coupon_id !== undefined && this.childForm.value.coupon_id.length > 0) {
      this.couponId = this.childForm.value.coupon_id[0].id;
      window.open(`#/offers-personalization/plans(modal:update/coupons/${this.couponId})`);
    }
  }

  handleCreateCoupon() {
    this.couponCreation.emit(this.item);
  }

  handleRecommendCoupon() {
    this.couponRecommend.emit(this.item);
  }

  assignCoupon(coupon: Object) {
    this.childForm.patchValue({coupon_id: null}, {emitEvent: true});
    const couponInput = this.inputs.find(inp => inp.key === 'coupon_id');
    couponInput.value = [];
    couponInput.selectedIds = [];
    couponInput.selectedIds = [parseInt(coupon['id'], 10)];

    const key = this.inputs[0].key;
    this.childForm.get(key).patchValue(true);
    this.inputs[0].value = true;
    this.isCouponSelected = true;

    this.handlePreSelectedCoupon(coupon);

    setTimeout(() => this.couponsService.refresh());
  }

  toggleInputDisplay(str: string) {
    this.inputsVisibility[str] = !this.inputsVisibility[str];
  }

  openSegment() {
    window.open(`#/show/${this.item.segment_id}/segmentsV2`, '_blank', 'noopener');
  }

  openCampaignEdition() {
    this.campaignEdition.emit(this.item);
  }

  private autoFillEstimationFields(selectedCoupon: object) {
    let estimatedCustomerRedemption = selectedCoupon['rawElement']['estimated_customer_redemption'] || 0;
    let estimatedDelivery = selectedCoupon['rawElement']['estimated_delivery'] || 0;
    let estimatedRedemption = selectedCoupon['rawElement']['estimated_redemption'] || 0;

    // Input fields modification
    this.childForm.patchValue({
      estimated_customer_redemption: estimatedCustomerRedemption,
      estimated_delivery: estimatedDelivery,
      estimated_redemption: estimatedRedemption
    }, {emitEvent: true});

    // Purple digit modification (not inputs)
    this.item.estimatedCustomerRedemption = estimatedCustomerRedemption;
    this.item.estimatedDelivery = estimatedDelivery;
    this.item.estimatedRedemption = estimatedRedemption;
  }

  private setInputs() {
    const milliseconds = new Date().getMilliseconds();
    let couponValue = [];
    if (!isNullOrUndefined(this.item.coupon_id)) {
      this.isCouponSelected = true;
      couponValue = [this.item.coupon_id];
    }
    const inputs = [
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: `check_${milliseconds}`,
        value: !isNullOrUndefined(this.item.coupon_id) ? true : false,
        required: true,
        customValidators: []
      }),
      new FloatQuestion({
        cssClasses: 'form-control input-info',
        key: `estimated_customer_redemption`,
        type: 'number',
        step: 0.01,
        value: this.item.estimatedCustomerRedemption
      }),
      new FloatQuestion({
        cssClasses: 'form-control input-default',
        key: `estimated_redemption`,
        type: 'number',
        step: 0.01,
        value: this.item.estimatedRedemption
      }),
      new FloatQuestion({
        cssClasses: 'form-control input-default',
        key: `estimated_delivery`,
        type: 'number',
        step: 0.01,
        value: this.item.estimatedDelivery
      }),
      new MultiSelectQuestion({
        key: 'coupon_id',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.couponsService,
        selectedIds: couponValue,
        required: true,
        customValidators: []
      })
    ];

    const formGroupKey = `childForm_${milliseconds}`;

    this.inputs = inputs;
    this.childForm = this.qcs.toFormGroup(this.inputs);
    this.childForm.setValidators(this.getFormGroupValidations());
    this.item.formGroupKey = formGroupKey;
    this.parentForm.addControl(formGroupKey, this.childForm);
  }

  private getFormGroupValidations(): ValidatorFn[] {
    const planActionRowValidation = (_ctrl: AbstractControl) => {

      const checkKey = this.inputs[0].key;
      const checkInput = _ctrl.get(checkKey);
      const couponInp = _ctrl.get('coupon_id');
      const couponPresent = (couponInp.value && couponInp.value.length > 0 && !isNullOrUndefined(couponInp.value[0]));

      if (checkInput.value === false) {
        checkInput.setErrors(null);
        checkInput.markAsPristine();
        checkInput.markAsUntouched();
        couponInp.setErrors(null);
        couponInp.markAsPristine();
        couponInp.markAsUntouched();
        return null;
      } else if(checkInput.value === true) {
        if (couponPresent) {
          couponInp.setErrors(null);
          return null;
        } else {
          couponInp.markAsDirty();
          couponInp.markAsTouched();
          couponInp.setErrors({required: true});
          return {couponRequired: true};
        }
      }
    }
    return [planActionRowValidation];
  }

  enqueuePollingRequest() {
    this.item.loading = true;
    this.item.calculatedDataLoaded = false;
    const endpoint = `actions/${this.item.id}/calculate_data`;
    const body = { operation: 'audience' };
    const actionAudience$ = this.refreshCacheService.postExpensiveData(endpoint, body).subscribe({
      next: (response: Object) => {
        if (!this.refreshCacheService.isRequestPending(response)) {
          this.item.loading = false;
          if(response['status'] === 'error') {
            this.item.errors.push(response['error_message']);
          } else {
            this.item.processAudienceData(response['data']);
          }
          this.item.calculatedDataLoaded = true;
          this.actionEmitter.emit(this.item);
        }
      },
      error: (errorData: HttpErrorResponse) => {
        this.item.loading = false;
        this.item.calculatedDataLoaded = true;
        this.item.errors.push(errorData.error.error);
        this.actionEmitter.emit(this.item);
      }}
    );
    this.subs$.push(actionAudience$);
  }
}
