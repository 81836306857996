import { QuestionBase } from './question-base';

export class MultiSelectQuestion extends QuestionBase<any> {

  controlType = 'multiselect';
  options: any[];
  translatable: boolean;
  settings: object;
  apiEndPoint: string;
  allowBlank?: boolean;

  constructor(options: {} = {}) {
    super(options);
    this.value = options['value'] ? options['value'] : [];
    this.options = options['options'] || [];
    this.translatable = options['translatable'] || false;
    this.settings = options['settings'];
    this.apiEndPoint = options['apiEndPoint'] || null;
    this.allowBlank = options['allowBlank'] === false ? false : true;
  }
}
