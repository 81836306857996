import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { MultiselectDataSource } from '../../../shared/components/multiselect/multiselect';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { Observable } from 'rxjs';

@Injectable()
export class NewsTaxonomiesService extends ResourcesService {

  public inputs: QuestionBase<any>[];

  constructor(http: HttpClient) {
    super(http);
  }

  public getAll() {
    const requestOptions = {
      apiEndPoint: '/news_taxonomies',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  public getNewsTaxonomyTermsById(id: number) {
    return this.getData({ apiEndPoint: `news_taxonomies/${id}` });
  }

  public getNewsTaxonomiesById(id: number) {
    return this.getData({ apiEndPoint: `news_taxonomies/${id}` });
  }

  public fetchMultiselect( searchValues?: string, page?: number ) {
    let requestOptions = {
      apiEndPoint: 'news_taxonomies',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  public fetchSelectedById(id: number): Observable<object> {
    return this.getNewsTaxonomyTermsById(id);
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`, element);
  }

  public sendData(data) {
    return this.postResource(data, 'news_taxonomies');
  }

  public updateData(data: any, _id: number) {
    return this.patchResource(data, `news_taxonomies/${_id}`);
  }

  public setInputs(formValues: any) {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        label: 'resources.cms.form.fields.name',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'slug',
        type: 'text',
        label: 'resources.cms.form.fields.slug',
        cssClasses: 'form-control input-default',
        required: true,
        getValue: (value) => value
      })
    ];

    this.inputs = inputsArray;
    this.assignFormInputValues(formValues);
    return this.inputs;
  }

  private disableInputs() {
    this.inputs.find( input => input.key === 'slug' ).disabled = true;
  }

  private assignFormInputValues(formValues) {
    Object.keys(formValues).map(
      key => {
        const tmpInput = this.inputs.find( input => input.key === key);

        if (tmpInput && tmpInput.getValue ) {
          const value = tmpInput.getValue(formValues[key]);
          if (value && value.hasOwnProperty('selectedIds')) {
            tmpInput.selectedIds = value.selectedIds;
          } else {
            tmpInput.value = value;
          }
        }
      }
    );
    if (Object.keys(formValues).length > 0) {
      this.disableInputs();
    }
  }

}
