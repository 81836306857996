import { Component, OnInit } from '@angular/core';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { CreditTransactionsService } from '../credit-transactions.service';

@Component({
  selector: 'app-form-credit-transactions',
  templateUrl: './form-credit-transactions.component.html',
  styleUrls: ['./form-credit-transactions.component.css'],
  providers: [CreditTransactionsService]
})
export class FormCreditTransactionsComponent implements OnInit {

  public creditTransactionsInputs: QuestionBase<any>[];
  public creditsForm: UntypedFormGroup;
  public inputs: any;
  public id: number;
  public showButton: boolean;

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private creditsService: CreditTransactionsService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ){}

  ngOnInit() {
    this.getParams();
    this.showBackButton();
  }

  showBackButton() {
    this.showButton = !(this.router.url.indexOf('(modal:') >= 0);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getCreditTransactionsById(this.id);
      } else {
        this.inputs = this.creditsService.getInputs({});
        this.creditsForm = this.qcs.toFormGroup(this.inputs);
      }
    });
  }

  private getCreditTransactionsById(id: number) {
    this.creditsService.getCreditTransactionsById(this.id).subscribe(
      data => {
        this.inputs = this.creditsService.getInputs(data);
        this.creditsForm = this.qcs.toFormGroup(this.inputs);
      }
    );
  }

  public sendData() {
    const creditsOptions = {
      amount: this.creditsForm.value.amount,
      action: this.creditsForm.value.action[0].id,
      kind: this.creditsForm.value.kind[0].id,
      notes: this.creditsForm.value.notes,
    };

    if ( this.id ) {
      this.creditsService.updateData(creditsOptions, this.id).subscribe(
       () => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.credits.form.warnings.update_title'),
            this.translate.instant('resources.credits.form.warnings.update_text')
          ).catch(() => {});
          this.returnToList();
        }
      );
      } else {
      this.creditsService.sendData(creditsOptions).subscribe(
        () => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.credits.form.warnings.success_title'),
            this.translate.instant('resources.credits.form.warnings.success_text')
          ).catch(() => {});
          this.returnToList();
        }
      );
    }
  }

  public returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/config/credit_transactions']).catch(() => {});
    }
  }
}
