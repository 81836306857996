import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from './condition.service';
import { QuestionBase } from '../../models/forms/question-base';
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectQuestion } from '../../models/forms/question-multiselect';
import { CampaignsService } from '../../../resources/campaigns/campaigns.service';
import { CampaignHistoriesService } from '../../../resources/campaigns/campaign-histories.service';
import { MultiselectDataSource } from '../../components/multiselect/multiselect';
import { isNullOrUndefined } from 'util';
import { UntypedFormGroup } from '@angular/forms';
import { multiselectPresenceValidator, checkControlValuePresence } from '../validations.service';

@Injectable()
export class MailConditionService implements SegmentConditionProvider {

  public inputs: QuestionBase<any>[];
  public customValidators = [(control: UntypedFormGroup) => this.getFormGroupValidations(control)];

  constructor( private translate: TranslateService,
               private campaigns: CampaignsService,
               private campaignHistories: CampaignHistoriesService ) { }

  public getInputs( params?: any ): QuestionBase<any>[] {
    const inputs = [
      new MultiSelectQuestion({
        key: 'campaign_ids',
        label: this.translate.instant('resources.segment_conditions.fields.campaigns'),
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.campaigns,
        required: true,
        customValidators: [multiselectPresenceValidator],
        getValue: (value, formValues?) => {
          let campaigns = formValues.campaign_ids;
          if ( campaigns && campaigns.length === 1 ) {
            campaigns = campaigns[0].hasOwnProperty('id') ? [campaigns[0].id] : [value];
            this.campaignHistories.setCampaign(campaigns[0]);
          }
          return { selectedIds: campaigns };
        },
        parseValue: (values) => {
          if ( values && values.length > 0 ) {
            return values.map( value => ({id: parseInt(value.id, 10), tag_id: parseInt(value.rawElement.tag_id, 10)}));
          } else {
            return [];
          }
        }
      }),
      new MultiSelectQuestion({
        key: 'action',
        label: this.translate.instant('resources.segment_conditions.fields.action'),
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: this.getActionsList(),
        required: true,
        customValidators: [multiselectPresenceValidator],
        getValue: (value) => {
          if (value && value.length > 0 && value[0].hasOwnProperty('id')) {
            return [this.getActionsList().find( item => item.id === value[0].id )];
          } else {
            return [];
          }
        },
        parseValue: (value: any[]) => {
          if ( value && value.length > 0 ) {
            return value[0].id;
          }
        }
      }),
      new MultiSelectQuestion({
        key: 'delivery_type',
        cssClasses: 'form-control input-default',
        label: this.translate.instant('resources.segment_conditions.fields.delivery_type'),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: this.singleCampaignTypeList(),
        required: true,
        customValidators: [multiselectPresenceValidator],
        getValue: (value, _params?) => {
          if ( _params && _params.last_deliver_number === 1 ) {
            return [this.singleCampaignTypeList().find( opt => opt.id === 'last_1')];
          }
          return ( value && value.length && value[0] !== undefined ) ? value : [];
        },
        parseValue: (value) => {
          if (value && value.length) {
            return [this.singleCampaignTypeList().find( item => item.id === value[0].id)];
          } else {
            return null;
          }
        }
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.applies'),
        key: 'campaign_history_ids',
        dataSource: this.campaignHistories,
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: true, enableSearchFilter: false },
        getValue: (values, _params?) => {
          if ( values && values.length ) {
            values = values[0].hasOwnProperty('id') ? values.map( value => value.id) : values.map( value => value );
            return { selectedIds: values };
          } else {
            return { selectedIds: [] };
          }
        },
        parseValue: (values) => {
          if ( values && values.length > 0 ) {
            return values.map( value => ({ campaign_history_ids: value.id, apply: value.rawElement.apply }));
          } else {
            return [];
          }
        }
      })
    ];

    if ( params && Object.keys(params).length > 0 ) { this.prepareInputValuesFromParams(inputs, params); }

    this.inputs = inputs;
    return this.inputs;
  }

  private prepareInputValuesFromParams(inputs: QuestionBase<any>[], params?) {
    /* Case 1: On drop, Case 2: On edit */
    let campaigns: number[];
    if ( params.campaign_tag_ids &&
         params.campaign_tag_ids.length &&
         params.campaign_tag_ids[0] instanceof MultiselectDataSource ) {
      campaigns = params.campaign_tag_ids.map( item => item.rawElement.id );
    } else if ( params.campaign_ids && params.campaign_ids.length ) {
      campaigns = params['campaign_ids'];
    }

    /* Need to overwrite values for campaign_ids input */
    params.campaign_ids = campaigns;
    params.campaign_tag_ids = campaigns;

    if ( !isNullOrUndefined(params.action) && params.action.length > 0 ) {
      const _actionId = params.action instanceof Array ? params.action[0].id : params.action;
      params.action = [this.getActionsList().find( item => item.id === _actionId )];
    }

    Object.keys(params).forEach( _key => {
      const input = inputs.find( _input => _input.key === _key );
      /* Check if input exist because API returns object keys that are no inputs as delivery_type_number */
      if (input) {
        const value = input.getValue(params[_key], params);
        if (value && value.hasOwnProperty('selectedIds')) {
          input.selectedIds = value.selectedIds;
        } else {
          input.value = value;
        }
      }
    });
  }

  public prepareFormValuesToAPI( params: any ): any {

    const campaigns = params.campaign_ids ? params.campaign_ids : [];
    const campaignIds = campaigns.map( campaign => campaign.id );
    const action = params.action ? params.action[0].id : undefined;
    const deliveryType = ( params.delivery_type && params.delivery_type.length > 0 ) ? params.delivery_type[0].id : undefined;
    const campaignHistories = params.campaign_history_ids;

    const parsedValuesObj = {
      campaign_ids: campaignIds,
      action: action,
      delivery_type: deliveryType
    };

    if (deliveryType === 'last') { parsedValuesObj['last_deliver_number'] = 0; }

    if (deliveryType === 'last_1') {
      parsedValuesObj['delivery_type'] = 'last';
      parsedValuesObj['last_deliver_number'] = 1;
    }

    if (campaignIds && campaignIds.length === 1 && ( deliveryType === 'any' || deliveryType === 'all')) {
      parsedValuesObj['campaign_history_ids'] = campaignHistories.map( history => history.id );
      parsedValuesObj['applies'] = campaignHistories.map( history => history.rawElement.apply );
    } else {
      delete parsedValuesObj['campaign_history_ids'];
      delete parsedValuesObj['applies'];
    }

    return parsedValuesObj;
  }

  public prepareFormValuesFromAPI( params: any ): any {
    const parseValues = {
      campaign_ids: (value) => (value && value.length) ? value : null,
      campaign_tag_ids: (value) => (value && value.length) ? value : null,
      delivery_type: (value) => {
        const campaigns = params.campaign_ids;
        if (campaigns && campaigns.length === 1) {
          return [this.singleCampaignTypeList().find(item => item.id === value)];
        } else if (campaigns && campaigns.length > 1) {
          return [this.multiCampaignTypeList().find(item => item.id === value)];
        }
      },
      campaign_history_ids: (value) => (value && value.length) ? value : null,
      applies: (value) => (value && value.length) ? value : null,
      last_deliver_number: (value) => value,
      action: (value) => (value && value.length) ? value : null
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach( _key => {
      const key: string = _key;
      if ( parseValues.hasOwnProperty( key ) ) {
        parsedValuesObj[key] = parseValues[key](params[key]);
      }
    });

    return parsedValuesObj;
  }

  private getActionsList(): { id: string, name: string }[] {
    return [
      { id: 'open', name: this.translate.instant('resources.segment_conditions.operators.open') },
      { id: 'no_open', name: this.translate.instant('resources.segment_conditions.operators.no_open') },
      { id: 'click', name: this.translate.instant('resources.segment_conditions.operators.click') },
      { id: 'no_click', name: this.translate.instant('resources.segment_conditions.operators.no_click') }
    ];
  }

  public singleCampaignTypeList() {
    return [
      { id: 'last', name: this.translate.instant('resources.segment_conditions.fields.last_delivery') },
      { id: 'last_1', name: this.translate.instant('resources.segment_conditions.fields.last_delivery') + ' - 1' },
      { id: 'any', name: this.translate.instant('resources.segment_conditions.fields.any_delivery') },
      { id: 'all', name: this.translate.instant('resources.segment_conditions.fields.all_deliveries') }
    ];
  }

  public multiCampaignTypeList() {
    return [
      { id: 'any', name: this.translate.instant('resources.segment_conditions.fields.any_delivery') },
      { id: 'all', name: this.translate.instant('resources.segment_conditions.fields.all_deliveries') },
      { id: 'last|any', name: this.translate.instant('resources.segment_conditions.fields.any_last_delivery') },
      { id: 'last|all', name: this.translate.instant('resources.segment_conditions.fields.all_last_deliveries') }
    ];
  }

  private getFormGroupValidations(control: UntypedFormGroup) {
    const campaignsCtrl = control.get('campaign_ids');
    const deliveryType = control.get('delivery_type');
    const histories = control.get('campaign_history_ids');

    const campaigns = (campaignsCtrl.value && campaignsCtrl.value.length > 0) ? campaignsCtrl.value : null;

    let deliveryTypeValue = null;

    if (deliveryType.value && deliveryType.value.length > 0 && !isNullOrUndefined(deliveryType.value[0])) {
      deliveryTypeValue = deliveryType.value[0].id;
    }

    if (campaigns && (campaigns.length === 1 && !isNullOrUndefined(campaigns[0]))) {
      if (deliveryTypeValue === 'any' || deliveryTypeValue === 'all') {
        return checkControlValuePresence(histories, 'invalidHistories');
      }
      return checkControlValuePresence(deliveryType, 'invalidDeliverySingle');
    }

    if (campaigns && (campaigns.length > 1 && !isNullOrUndefined(campaigns[0]))) {
      return checkControlValuePresence(deliveryType, 'invalidDeliveryMulti');
    }
  }
}
