import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MultiselectDataSource, MultiselectDataSourceable } from '../../../shared/components/multiselect/multiselect';
import { Observable } from 'rxjs';

@Injectable()
export class CustomEntitiesService extends ResourcesService implements MultiselectDataSourceable {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  getById(id: string) {
    return this.getData({ apiEndPoint: `custom_entity_definitions/${id}`});
  }

  getBySlug(slug: string) {
    return this.getData({ apiEndPoint: `custom_entity_definitions`, filtering: { slug: slug }}).pipe(
      map(itemArray => itemArray['list'][0])
    );
  }

  updateById(id: string, payload: object) {
    return this.patchResource(payload, `custom_entity_definitions/${id}`);
  }

  deleteById(id: string) {
    return this.deleteResource({}, `custom_entity_definitions/${id}`);
  }

  create(payload: object) {
    return this.postResource(payload, `custom_entity_definitions`);
  }

  fetchMultiselect( searchValues?: string, page?: number) {
    let requestOptions = {
      apiEndPoint: 'custom_entity_definitions',
      numberPerPage: 100,
      pageNumber: 1,
      filtering: {},
      sorting: {}
    };

    requestOptions.pageNumber = page ?? requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: string): Observable<object> {
    return this.getById(id);
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    return new MultiselectDataSource(element.slug, element.name, element);
  }
}
