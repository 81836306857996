<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header background-white">
        <div class="row flex-grow-1">
          <div class="col-md-8 ml-auto mr-auto">
            <h1>{{ 'resources.reports.form.fields.detail' | translate }}</h1>
          </div>
        </div>
        <button class="modal-close" (click)="closeModal()">
          <i class="ti-close"></i>
        </button>
      </div>
      <div class="modal-body height-100 background-gray">
        <div class="row no-margin-right">
          <div class="col-md-8 mr-auto ml-auto">
            <div class="card">
              <div class="card-heading border bottom">
                <h4 class="card-title" [translate]="'resources.reports.form.fields.details'"></h4>
              </div>
              <div class="card-block">
                <form class="form-horizontal" *ngIf="reportForm">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="name" [translate]="'resources.reports.roi_by_store.fields.name'"></label>
                        <span *ngIf="getInputConfig('name').required" class="text-danger">*</span>
                        <app-question [question]="getInputConfig('name')" [form]="reportForm"></app-question>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="location_taxonomy_terms" [translate]="'resources.reports.roi_by_store.fields.location_taxonomy_terms'"></label>
                        <span *ngIf="getInputConfig('location_taxonomy_terms').required" class="text-danger">*</span>
                        <app-question
                          [question]="getInputConfig('location_taxonomy_terms')"
                          (multiselectChanged)="handleSelectedLocationTaxonomyTermIds($event)"
                          [form]="reportForm"
                        ></app-question>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="location_ids" [translate]="'resources.reports.roi_by_store.fields.location_ids'"></label>
                        <span *ngIf="getInputConfig('location_ids').required" class="text-danger">*</span>
                        <app-question [question]="getInputConfig('location_ids')" [form]="reportForm"></app-question>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="month" [translate]="'resources.reports.roi_by_store.fields.month'"></label>
                        <span *ngIf="getInputConfig('month').required" class="text-danger">*</span>
                        <app-question [question]="getInputConfig('month')" (multiselectChanged)="dateChangedHandler()" [form]="reportForm"></app-question>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="year" [translate]="'resources.reports.roi_by_store.fields.year'"></label>
                        <span *ngIf="getInputConfig('year').required" class="text-danger">*</span>
                        <app-question [question]="getInputConfig('year')" (multiselectChanged)="dateChangedHandler()" [form]="reportForm"></app-question>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="plan_ids" [translate]="'resources.reports.roi_by_store.fields.plan_ids'"></label>
                        <span *ngIf="getInputConfig('plan_ids').required" class="text-danger">*</span>
                        <app-question [question]="getInputConfig('plan_ids')" [form]="reportForm"></app-question>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="flags.showTemplateCampaignsInputReports">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="campaign_block_ids" [translate]="'resources.reports.roi_by_store.fields.campaign_block_ids'"></label>
                        <span *ngIf="getInputConfig('campaign_block_ids').required" class="text-danger">*</span>
                        <app-question [question]="getInputConfig('campaign_block_ids')" [form]="reportForm"></app-question>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row modal-footer background-white">
        <div class="col-md-8 ml-auto mr-auto text-right">
          <span><i class="fa fa-spinner fa-spin" *ngIf="loading"></i></span>
          <button class="btn btn-default" (click)="returnToList()" [translate]="'resources.reports.buttons.back'"></button>
          <button class="btn btn-info" (click)="sendData()" [translate]="'resources.reports.buttons.send'"></button>
        </div>
      </div>
    </div>
  </div>
</div>
