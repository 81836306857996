import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from '../../../shared/services/resources.service';
import { Observable } from 'rxjs';

@Injectable()
export class TicketsService extends ResourcesService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getTicketData(id: number): Observable<any> {
    return this.getData({ apiEndPoint: `activities/${id}` });
  }

  public getVouchersData(id: number): Observable<any> {
    return this.getData({ apiEndPoint: `activities/${id}/used_vouchers` });
  }

  public getPromotionData(id: number): Observable<any> {
    return this.getData({ apiEndPoint: `activities/${id}/promotions`, numberPerPage: 300 });
  }

  public getTicketsByCustomerId(customerId: number): Observable<any> {
    return this.getData({ apiEndPoint: 'activities', filtering: {customer_id: customerId} });
  }
}
