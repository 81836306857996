import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomEntitiesService } from '../custom-entities.service';
import { HttpErrorResponse } from '@angular/common/http';
import 'brace';
import 'brace/theme/monokai';
import { CustomEntity } from '../custom-entity';

@Component({
  selector: 'app-form-custom-entities',
  templateUrl: './form-custom-entities.component.html',
  styleUrls: ['./form-custom-entities.component.css']
})
export class FormCustomEntitiesComponent implements OnInit {

  customEntitiesForm: UntypedFormGroup;
  inputs: any;
  attributesList: any[] = [];
  mode: string;
  subs$: Subscription[] = [];
  entity: CustomEntity;
  exampleSchema = JSON.stringify({
    "stringAttribute": {"type": "string"},
    "numericAttribute1": {"type": "number"},
    "numericAttribute2": {"type": "number"}
  }, null, '\t');

  @Output('formValidity') formValidity: EventEmitter<string> = new EventEmitter();

  constructor(
    private qcs: QuestionControlService,
    private router: Router,
    private route: ActivatedRoute,
    private modalStatusService: ModalStatusService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private customEntitiesService: CustomEntitiesService
  ) {}

  ngOnInit() {
    this.mode = this.route.snapshot.routeConfig.data.mode;
    this.getParams();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/customer-data-platform/warehouse/custom-entities']).catch(() => {});
    }
  }

  sendData() {
    this.modalStatusService.formLoaderStatus.emit('loading');

    const payload = this.customEntitiesForm.value;
    payload['entity_attributes'] = JSON.parse(this.entity.entity_attributes);

    this.getRequest(payload).subscribe(
      () => this.displaySuccessMsg(),
      (errorResponse: HttpErrorResponse) => {
        this.modalStatusService.formLoaderStatus.emit('loading_finished');
        this.confirmationService.displayErrorAlert('Error', errorResponse.error.error);
      }
    );
  }

  presentTemplate() {
    this.entity.entity_attributes = this.exampleSchema;
  }

  private getRequest(payload: object): Observable<any> {
    if (this.entity?.id) {
      return this.customEntitiesService.updateById(this.entity.id, payload)
    } else {
      return this.customEntitiesService.create(payload);
    }
  }

  private displaySuccessMsg() {
    let titleMsg = this.translate.instant('resources.custom_entities.messages.create_success_title');
    let descMsg = this.translate.instant('resources.custom_entities.messages.create_success_desc');
    if (this.entity?.id) {
      titleMsg = this.translate.instant('resources.custom_entities.messages.update_success_title');
      descMsg = this.translate.instant('resources.custom_entities.messages.update_success_desc');
    }
    this.confirmationService.displaySuccessAlert(titleMsg, descMsg).catch(() => {});
    this.modalStatusService.modalStatus.emit();
    this.closeModal();
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.getEntityById(params.id);
      } else {
        this.entity = {id: undefined, name: undefined, slug: undefined, entity_attributes: ''};
        this.initForm();
      }
    });
  }

  private getEntityById(id: string) {
    this.customEntitiesService.getById(id).subscribe(
      (response: CustomEntity) => {
        this.entity = response;
        this.entity.entity_attributes = JSON.stringify(this.entity['entity_attributes'], null, '\t');
        this.initForm();
      }
    );
  }

  private initForm() {
    this.inputs = this.getInputs(this.entity);
    this.customEntitiesForm = this.qcs.toFormGroup(this.inputs);
    this.formStatusChanges();       
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.customEntitiesForm.status));
    const formStatus$ = this.customEntitiesForm.statusChanges.pipe(distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
    this.subs$.push(formStatus$);
  }

  private getInputs(entity?: CustomEntity): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 1,
        required: true,
        value: entity?.name ? entity.name : null
      }),
      new TextboxQuestion({
        key: 'slug',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 2,
        required: true,
        value: entity?.slug ? entity.slug : null,
        disabled: entity?.slug ? true : false
      }),
      new TextboxQuestion({
        key: `entity_attributes`,
        type: 'text',
        cssClasses: 'form-control input-default',
        required: false,
        value: entity?.entity_attributes ? JSON.stringify(entity.entity_attributes) : null
      })
    ];
    
    return inputsArray;
  }

  private closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }
}

