import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesService } from '../../../shared/services/resources.service';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { CouponsService } from '../../coupons/coupons.service';

@Component({
  selector: 'app-show-recipes',
  templateUrl: './show-recipes.component.html',
  styleUrls: ['./show-recipes.component.css']
})
export class ShowRecipesComponent implements OnInit {

  public element: any;
  public apiEndPoint: string;
  public id: number;
  public couponId: number;
  public isModal: boolean;
  public couponData: Object;

  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private couponService: CouponsService,
    private router: Router
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.isModal = this.router.url.indexOf('(modal:show/recipes/') >= 0;
    this.getParams();
    this.resourcesService.getData({ apiEndPoint: this.apiEndPoint + '/' + this.id })
        .subscribe(res => {
          this.element = res;
          this.getCoupon();
        },
        error => {
          console.log('error:', error);
        });
  }

  getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'recipes';
      }
    });
  }

  getCoupon() {
    if (this.element.configuration.coupon_id && this.element.configuration.coupon_id.length > 0) {
      this.couponService.getCouponById(this.element.configuration.coupon_id).subscribe(
        data => {
          this.couponData = data;
        }
      );
    }
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  goToCoupon () {
    if ( this.isModal ) {
      window.open(`#/customer-data-platform/warehouse/coupons(modal:show/coupons/${this.element.configuration.coupon_id})`);
    } else {
      this.router.navigate([{ outlets: { modal: `show/coupons/${this.element.configuration.coupon_id}`}}]).catch(() => {});
    }
  }

}
