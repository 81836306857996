import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CustomEntitiesService } from '../../../../data-warehouse/custom-entities/custom-entities.service';
import { CustomEntity } from '../../../../data-warehouse/custom-entities/custom-entity';
import { DictionaryService } from '../../../../../shared/services/dictionary.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MultiselectService } from '../../../../../shared/components/multiselect/multiselect.service';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { RewardsService } from '../../../rewards/rewards.service';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-award-value-block',
  templateUrl: './award-value-block.component.html',
  styleUrls: ['./award-value-block.component.scss']
})

export class AwardValueBlockComponent implements OnDestroy, OnInit {

  @Input('loyaltyScoresForm') loyaltyScoresForm: UntypedFormGroup;
  @Input('inputs') inputs;
  @Input('isUsed') isUsed;
  @Input('mode') mode;
  @Input('loyaltyScoresData') loyaltyScoresData;
  @Output() hideValidityPointsBlockChange = new EventEmitter<boolean>();
  @Output() customEntitySelected =new EventEmitter<unknown>();

  awardTypeSelected: any;
  customEntity: CustomEntity;
  hideValidityPointsBlock: boolean = false;
  isCustomEntity: boolean;

  strategyFlags: {
    disablePoints: boolean,
    showLabelPoints: boolean,
    showLabelPointsMultiplicator: boolean,
    showLabelPriceMultiplicator: boolean,
    showPoints: boolean,
    showPointsPriceMultiplicator: boolean,
    showTransferablePoints: boolean,
    symbol: string
  } = {
    disablePoints: false,
    showLabelPoints: false,
    showLabelPointsMultiplicator: false,
    showLabelPriceMultiplicator: false,
    showPoints: false,
    showPointsPriceMultiplicator: false,
    showTransferablePoints: false,
    symbol: ''
  };

  private destroy$ = new Subject<void>();

  constructor(
    private customEntityService: CustomEntitiesService,
    private dictionaryService: DictionaryService,
    private multiselectService: MultiselectService,
    private qcs: QuestionControlService,
    private rewardsService: RewardsService,
    private translate: TranslateService
  ) {
    this.rewardsService.filterByLoyaltyRule = true;
  }

  ngOnInit(): void {
    this.initiateStrategyFlags();
    this.setAwardTypeOptionsOnUpdate();
    this.checkVisibilityForValidityPointsBlock();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.loyaltyScoresForm, formKey, value);
  }

  momentChanged($event: Event) {
    const strategyTypeInput = this.inputs.find(inp => inp.key === 'strategy_type');
    // if a moment is selected input should be enabled
    if ($event) {
      strategyTypeInput.disabled = false;
      const moment = this.loyaltyScoresForm.get('loyalty_score_moment').value[0]?.id;
      this.activateAwardTypeOptions(moment);
      if (moment !== 'after_create_activity') {
        //  Auto select and block fixed points strategy to all but "after create activity" moment
        const fixedOpt = {
          'id': 'fixed_points',
          'name': this.translate.instant('resources.scores.loyalty_scores.fields.fixed_amount')
        };

        this.loyaltyScoresForm.patchValue({strategy_type: [fixedOpt]}, {emitEvent: true});
        strategyTypeInput.disabled = true;
        this.loyaltyScoresForm.get('customer_registration_countries').reset();
        this.loyaltyScoresForm.get('countries').reset();
        this.strategySelectorChange(fixedOpt);
      }
      this.loyaltyScoresForm.get('countries').reset();
    }
    else {
      this.loyaltyScoresForm.patchValue({strategy_type: []}, {emitEvent: true});
      strategyTypeInput.disabled = true;
      this.strategySelectorChange(null);
    }
    // Handler if a custom entity is selected (SAP)
    this.handleIfCustomEntity($event);
    this.checkVisibilityForValidityPointsBlock();
  }

  strategySelectorChange(event) {
    if (!event) {
      this.setStrategyFlags(false, false, false, false, '', false, false, false);
      this.cleanStrategySelectorOptions();
      return;
    }
    this.cleanStrategySelectorOptions();
    this.setStrategiesOptions(this.loyaltyScoresForm.get('strategy_type').value[0].id);
  }

  onAwardTypeChange(event) {
    this.loyaltyScoresForm.get('reward_id').reset();
    this.cleanStrategySelectorOptions();
    this.cleanValidityPointsBlockOptions();
    if(event) {
      this.awardTypeSelected = event.id
      this.hideValidityPointsBlockChange.emit(event.id);
      this.checkVisibilityForValidityPointsBlock();
    }
  }

  private checkVisibilityForValidityPointsBlock() {
    const awardTypeValue = this.loyaltyScoresForm.get('award_type')?.value?.[0]?.id
    const hideValidityPointsBlock = awardTypeValue === 'score';
    this.hideValidityPointsBlockChange.emit(hideValidityPointsBlock);
  }

  private activateAwardTypeOptions(moment: string) {
    const defaultValue = this.dictionaryService.getValuesByKey('award_type_values').find(opt => opt.id === 'score');
    this.loyaltyScoresForm.get('award_type').setValue([defaultValue]);
    this.awardTypeSelected = null;
    const awardTypeInput = this.inputs.find(inp => inp.key === 'award_type');
    const awardTypeOptions = this.dictionaryService.getValuesByKey('award_type_values');

    if (awardTypeInput) {
      if(moment !== 'after_winning_challenge' && moment.indexOf('custom_entity') !== 0) {
        awardTypeInput.options = awardTypeOptions.filter(opt => opt.id === 'score');
      } else {
        awardTypeInput.options = [...awardTypeOptions];
      }
      this.multiselectService.updateDropdownList.next('award_type');
    }
  }

  private cleanStrategySelectorOptions(){
    this.loyaltyScoresForm.get('points').reset();
    this.loyaltyScoresForm.get('points_price_multiplicator').reset();
    this.loyaltyScoresForm.get('points_price_percentage').reset();
    this.loyaltyScoresForm.get('transferable').reset();
  }

  private cleanValidityPointsBlockOptions(){
    this.loyaltyScoresForm.get('activation_delay').reset();
    this.loyaltyScoresForm.get('activation_delay_unit').reset();
    this.loyaltyScoresForm.get('expiration_period_type').reset();
    this.loyaltyScoresForm.get('availability_duration').reset();
    this.loyaltyScoresForm.get('availability_duration_unit').reset();
    this.loyaltyScoresForm.get('fixed_option_selected').reset();
  }

  private setStrategyFlags(showPoints: boolean, disablePoints: boolean, showPointsPriceMultiplicator: boolean, showTransferablePoints: boolean,
    symbol: string, showLabelPoints: boolean, showLabelPriceMultiplicator: boolean, showLabelPointsMultiplicator: boolean) {
    this.strategyFlags = {
      ...this.strategyFlags,
      showPoints,
      disablePoints,
      showPointsPriceMultiplicator,
      showTransferablePoints,
      symbol,
      showLabelPoints,
      showLabelPriceMultiplicator,
      showLabelPointsMultiplicator
    }
    this.inputs.find( input => input.key === 'points').disabled = disablePoints;
  }

  private setStrategiesOptions(strategy_type_id:string){
    switch (strategy_type_id) {
      case 'chunk':
        this.setStrategyFlags(true, false, true, true, '=', true, false, false);
        break;
      case 'multiplicator':
        this.loyaltyScoresForm.get('points').setValue(1);
        this.setStrategyFlags(true, true, true, true, 'X', true, false, true);
        break;
      case 'fixed_points':
        this.setStrategyFlags(true, false, false, true, '', false, true, false);
        break;
      case 'percentage':
        this.setStrategyFlags(false, false, false, true, '', false, false, false);
        break;
      case 'cashback_percentage':
        this.setStrategyFlags(false, false, false, true, '', false, false, false);
        break;
    }
  }

  private handleIfCustomEntity($event) {
    this.customEntity = null;
    this.customEntitySelected.emit(this.customEntity);
    // When a custom entity is selected
    if ($event && $event['id'].indexOf('custom_entity') === 0) {
      // id in this case is entity slug
      const slug = $event['id'].replace('custom_entity_', '');
      // I use this flag to avoid synchronization errors between the moment the custom entity is selected and the data is loaded
      this.customEntitySelected.emit(slug)
    } else {
      this.customEntitySelected.emit();
    }
  }

  private getCustomEntityData(customEntitySlug: string) {
    this.customEntityService.getBySlug(customEntitySlug).pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: CustomEntity) => {
        this.customEntity = response;
      },
      error: (err: HttpErrorResponse) => {
        console.warn('custom entity get error', err);
      }
    });
  }

  private initiateStrategyFlags(){
    const strategyTypeValue = this.loyaltyScoresForm.get('strategy_type').value;
    if (this.loyaltyScoresForm && this.mode === 'update' && strategyTypeValue.length > 0 && strategyTypeValue[0]?.id){
      this.setStrategiesOptions(this.loyaltyScoresForm.get('strategy_type').value[0].id);
    }
  }

  private setAwardTypeOptionsOnUpdate() {
    setTimeout(() => {
      const awardTypeInput = this.inputs.find(inp => inp.key === 'award_type');
      const moment = this.loyaltyScoresData?.loyaltyScoreMoment;
      this.awardTypeSelected = this.loyaltyScoresForm.get('award_type').value?.[0]?.id;

      if (this.mode === 'update' && awardTypeInput) {
        if (moment === 'after_winning_challenge' || moment?.indexOf('custom_entity') === 0) {
          awardTypeInput.options = this.dictionaryService.getValuesByKey('award_type_values');
        } else {
          awardTypeInput.options = this.dictionaryService.getValuesByKey('award_type_values').filter(opt => opt.id === 'score');
        }
        this.multiselectService.updateDropdownList.next('award_type');
      }
    }, 0);
  }

}