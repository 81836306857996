<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true" *ngIf="element">
              <tbody>
                <tr *ngIf="element.id">
                  <td class="no-border-top"><b [translate]="'resources.marketing_automation.fields.id'"></b></td>
                  <td class="no-border-top">{{element.id}}</td>
                </tr>
                <tr>
                  <td><b [translate]="'resources.marketing_automation.fields.executions'"></b></td>
                  <td>{{element.executions}}</td>
                </tr>
                <tr *ngIf="element.trigger">
                  <td><b [translate]="'resources.marketing_automation.fields.trigger'"></b></td>
                  <td *ngIf="element.trigger === 'after_create_activity'" [translate]="'resources.marketing_automation.when.after_create_activity'"></td>
                  <td *ngIf="element.trigger === 'after_first_login'" [translate]="'resources.marketing_automation.when.after_first_login'"></td>
                  <td *ngIf="element.trigger === 'after_create_customer'" [translate]="'resources.marketing_automation.when.after_create_customer'"></td>
                </tr>
                <tr>
                  <td><b [translate]="'resources.marketing_automation.fields.action'"></b></td>
                  <td *ngIf="element.action === 'coupon'" [translate]="'resources.marketing_automation.actions.coupon'"></td>
                  <td *ngIf="element.action === 'push'" [translate]="'resources.marketing_automation.actions.push'"></td>
                </tr>
                <tr *ngIf="element.configuration.coupon_id && couponData">
                  <td><b [translate]="'resources.marketing_automation.fields.coupon_id'"></b></td>
                  <td>
                    <a class="pointer colorLink" (click)="goToCoupon()">{{element.configuration.coupon_id }} - {{couponData.name}}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
