import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { CustomerAttributesService } from '../../services/customer-attributes/customer-attributes.service';
import { CustomerAttribute, CustomerAttributeAPIResponse } from '../../models/customer-attribute.model';
import { CustomerAttributeDetail, CustomerAttributeDetailInputs } from '../../models/customer-attribute-detail.model';
import { CustomerAttributesOptionsService } from '../../services/customer-attributes-options/customer-attributes-options.service';
import { TranslateService } from '@ngx-translate/core';
import { Location, TitleCasePipe } from '@angular/common';
import { getCustomerAttributesDetailInputs } from '../../utils/detail-inputs.utils';
import { DateService } from '../../../../../shared/services/date.service';
import { parseCustomerAttributesDetailData } from '../../parsers/detail-data.parser';
import { CUSTOMER_ATTRIBUTES_LIST_ROUTERLINK } from '../../constants/customer-attributes.constants';
import { ProfileService } from '../../../../../profiles/profile.service';
import { DictionaryService } from '../../../../../shared/services/dictionary.service';

@Component({
  selector: 'app-show-customer-attributes',
  templateUrl: './show-customer-attributes.component.html',
  styleUrls: ['./show-customer-attributes.component.scss']
})
export class ShowCustomerAttributesComponent implements OnInit, OnDestroy {
  id: number;
  customerAttributesDetailData: CustomerAttributeDetail;
  customerAttributesDetailInputs: CustomerAttributeDetailInputs[];
  currentNavigation: Navigation = this.router.getCurrentNavigation();
  currentRole = this.profileService.getStoredUserRole();

  get isAdminRole(): boolean {
    return this.currentRole === 'admin';
  }

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private customerAttributesService: CustomerAttributesService,
    private customerAttributesOptionsService: CustomerAttributesOptionsService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private location: Location,
    private translate: TranslateService,
    private titleCasePipe: TitleCasePipe,
    private dateService: DateService,
    private profileService: ProfileService,
    private dictionaryService: DictionaryService
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  edit(): void {
    this.router.navigate([`/customer-data-platform/customer-attributes/edit/${this.id}`]).catch(() => {});
  }

  back(isBackToList: boolean = false): void {
    if (isBackToList || (!isBackToList && !this.currentNavigation)) {
      this.router.navigate(CUSTOMER_ATTRIBUTES_LIST_ROUTERLINK).catch(() => {});
    } else {
      this.location.back();
    }
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.getCustomerAttributesData();
      }
    });
  }

  private getCustomerAttributesData(): void {
    this.customerAttributesService.getCustomerAttributesById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: CustomerAttributeAPIResponse) => {
        const customerAttributesData = new CustomerAttribute(response);
        const isCalculationAttributeType = !!(customerAttributesData.attributeType && customerAttributesData.attributeType === 'calculation');
        const isScheduledExecutionType = !!(customerAttributesData.recurrence && customerAttributesData.recurrence !== 'manual');

        this.customerAttributesDetailData = parseCustomerAttributesDetailData(
          customerAttributesData,
          this.customerAttributesOptionsService,
          this.dateService,
          this.titleCasePipe,
          this.translate,
          this.dictionaryService
        );

        this.customerAttributesDetailInputs = getCustomerAttributesDetailInputs(this.customerAttributesDetailData, isCalculationAttributeType, isScheduledExecutionType);
      },
      error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
    });
  }
}
