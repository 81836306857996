<div class="row">
  <div class="col-lg-12">
    <div role="alert"
         class="alert alert-warning alert-dismissible fade show mrg-top-5"
         *ngIf="isForecastInProgress()">
      {{'resources.plans.messages.calculating_forecast' | translate}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>

<app-data-table-filter *ngIf="displayFilter"
                       [slug]="'plan-forecast-detail'"
                       [questions]="filters"
                       (onSubmitEmitter)="onFilterHandler($event)"
                       [cfg]="filterCfg">
</app-data-table-filter>

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-block padding-20 pdd-btm-10 pdd-top-10">
        <app-loader styleClass="card" *ngIf="loading" />
        <div class="pull-left mrg-top-10" *ngIf="forecastDate">
          <i class="ei-timer"></i>&nbsp;<b class="card-title">{{'resources.plans.fields.generated_at' | translate}}</b>: <b class="card-title">{{forecastDate | dateTimeZone: 'DD/MM/YYYY HH:mm'}}</b>
        </div>
        <div class="btn-group pull-right">
          <button id="planDetailForecastConfigTableBtn"
                  class="btn btn-sm btn-default btn-icon mrg-btm-10"
                  type="button"
                  data-toggle="dropdown"
                  tooltipPosition="left"
                  pTooltip="{{'resources.plans.buttons.configure_table' | translate}}">
            <i class="ei-table icon-align"></i>
          </button>
          <ul class="dropdown-menu">
            <li *ngFor="let column of tableColumnsList; let i = index;">
              <div class="checkbox pdd-horizon-10" (click)="tableCfgClickHandler($event, column.id)">
                <input type="checkbox"
                       [id]="'column_'+i"
                       [name]="'column_'+i"
                       [attr.checked]="column.visible ? '' : null"
                       [attr.disabled]="column.disabled ? '' : null">
                <label class="font-size-12" [attr.for]="'column_'+i" [ngClass]="{'disabled': column.disabled}">
                  {{column.plainTitle}}
                </label>
              </div>
            </li>
          </ul>
          <button id="planDetailForecastChangeCouponsBtn"
                  class="btn btn-sm btn-default btn-icon"
                  *ngIf="plan"
                  (click)="openSwitchCouponsModal()"
                  tooltipPosition="left"
                  pTooltip="{{'resources.plans.buttons.switch_coupons' | translate}}">
            <i class="ei-refresh-alt icon-align"></i>
          </button>
          <button id="planDetailForecastExportCouponsBtn"
                  class="btn btn-sm btn-default btn-icon"
                  *ngIf="plan"
                  (click)="exportCoupons()"
                  tooltipPosition="left"
                  pTooltip="{{'resources.plans.buttons.export_coupons' | translate}}">
            <i class="ei-export icon-align"></i>
          </button>
          <button id="planDetailForecastExportBtn"
                  class="btn btn-sm btn-default btn-icon"
                  *ngIf="plan"
                  [disabled]="isForecastInProgress()"
                  (click)="exportForecast()"
                  tooltipPosition="left"
                  pTooltip="{{'resources.plans.buttons.export_forecast' | translate}}">
            <i class="ei-cloud-download icon-align"></i>
          </button>
          <button id="planDetailForecastCalcBtn"
                  class="btn btn-sm btn-default btn-icon"
                  *ngIf="plan"
                  [disabled]="!isForecastCalculable()"
                  (click)="calculateForecast()"
                  tooltipPosition="left"
                  pTooltip="{{'resources.plans.buttons.calc_forecast' | translate}}">
            <i class="ei-refresh icon-align"></i>
          </button>
          <button *ngIf="plan" id="planDetailForecastApproveAllBtn"
                  class="btn btn-sm btn-default btn-icon"
                  [ngClass]="{ 'not-allowed': planForecastData && planForecastData.length === 0 || !ableToApprove }"
                  (click)="approveAllCampaigns()"
                  [disabled]="(planForecastData && planForecastData.length === 0) || !ableToApprove"
                  tooltipPosition="left"
                  pTooltip="{{'resources.plans.buttons.approve_all_campaigns' | translate}}">
            <i class="fa-light fa-thumbs-up icon-align font-size-15"></i>
          </button>
        </div>

        <div id="forecastTable">
          <table *ngIf="!loading" class="table table-sm table-bordered" aria-hidden="true">
            <thead>
              <tr class="highlight-header">
                <ng-container *ngFor="let column of tableColumnsList; let i = index;">
                  <th class="text-center font-size-14" [hidden]="!column.visible" [innerHTML]="column.headerTitle"
                      [ngClass]="{
                        'width-200': column.id === 'campaign_type' || column.id === 'campaign_name' || column.id === 'segment_name' }">
                  </th>
                </ng-container>
                <th class="text-center">{{'resources.plans.columns.campaign_status' | translate}}</th>
                <th class="text-center">{{'resources.plans.columns.actions' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let element of planForecastData; let i = index;">
                <ng-container *ngFor="let campaign of element.campaigns; let j = index;">
                  <ng-container *ngFor="let action of campaign.actions; let k = index;">
                    <tr>
                      <!-- FIXED COLUMNS -->
                      <td *ngIf="j === 0 && k === 0"
                        class="text-center align-middle text-bold category-sticky"
                        [hidden]="!tableColumnsList[0].visible"
                        [attr.rowSpan]="element.rowsPerCategory"
                        [ngClass]="{'bg-custom': element.type === 'Plans::CustomCampaign',
                                    'bg-supplier': element.type === 'Plans::SupplierCampaign',
                                    'bg-up-cross': element.type === 'Plans::UpCrossCampaign',
                                    'bg-recovery': element.type === 'Plans::RecoveryCampaign',
                                    'bg-random': element.type === 'random_1',
                                    'bg-random': element.type === 'random_2',
                                    'bg-random': element.type === 'random_3'}">
                        {{element.category}}
                      </td>
                      <td *ngIf="k === 0"
                        class="text-left align-middle td-stick2"
                        [attr.rowSpan]="campaign.actions.length"
                        [hidden]="!tableColumnsList[1].visible">
                        <span class="overflow-text no-pointer-event">{{campaign.name}}</span>
                      </td>
                      <td class="text-left td-stick3" [hidden]="!tableColumnsList[2].visible">
                        {{action.campaignId}}
                        <i *ngIf="action.forecast.errors && action.forecast.errors.length > 0"
                          class="fa fa-warning text-danger text-bold"
                          tooltipPosition="top"
                          container="body"
                          pTooltip="{{action.forecast.errors}}">
                        </i>
                      </td>
                      <td class="text-left td-stick4" [hidden]="!tableColumnsList[3].visible">
                        <ng-container *ngIf="!isPlanDelivered()">
                          <span class="overflow-text">
                            <a href="#/update/segmentsV2/{{action.segment_id}}"
                              target="_blank"
                              rel="noopener"><b><u>{{action.name}}</u></b>
                            </a>
                          </span>
                        </ng-container>
                        <ng-container *ngIf="isPlanDelivered()">
                          <span class="overflow-text">
                            <a href="#/campaigns/{{action.campaignId}}/histories/{{action.campaignHistoryId}}/segment"
                              target="_blank"
                              rel="noopener"><b><u>{{action.name}}</u></b>
                            </a>
                          </span>
                        </ng-container>
                      </td>
                      <!-- REST OF COLUMNS -->
                      <td class="text-right" [hidden]="!tableColumnsList[4].visible">
                        <span class="label label-default">{{action.forecast.customers | number: '1.0-0'}}</span>
                      </td>
                      <!-- EDITABLE FIELDS -->
                      <ng-container *ngIf="forecastForm">
                        <td class="align-middle text-right" [hidden]="!tableColumnsList[5].visible || action['deliveryVisibility']">
                          <span *ngIf="!flags.displayEditableInputsForecast" class="label label-default">{{action.forecast.estimatedDelivery | number: '1.2-2'}}%</span>
                          <span *ngIf="flags.displayEditableInputsForecast" class="label font-size-12 label-primary" (click)="toggleInputDisplay(action, 'deliveryVisibility')">
                            <u *ngIf="action.forecastConfig?.delivered_percentage === null || action.forecastConfig?.delivered_percentage === undefined">
                              {{ action.forecast.estimatedDelivery | number: '1.2-2' }}
                            </u>
                            <u *ngIf="action.forecastConfig?.delivered_percentage !== null && action.forecastConfig?.delivered_percentage !== undefined">
                              {{ action.forecastConfig?.delivered_percentage | number: '1.2-2' }}
                            </u>
                          </span>
                        </td>
                        <td *ngIf="flags.displayEditableInputsForecast" class="align-middle input-width pdd-top-20" [hidden]="!action['deliveryVisibility']">
                          <app-question (blur)="handleFormChanges(action)" [question]="getInputConfig('estimated_delivery_'+action.campaignId)" [form]="forecastForm"></app-question>
                        </td>

                        <td class="align-middle text-right" [hidden]="!tableColumnsList[6].visible || action['redemptionsVisibility']">
                          <span *ngIf="!flags.displayEditableInputsForecast" class="redemptionsPercentage label label-default">
                            {{action.forecast.redemptionsPercentage | number: '1.2-2'}}%
                          </span>
                          <span *ngIf="flags.displayEditableInputsForecast" class="redemptionsPercentage label font-size-12 label-primary" (click)="toggleInputDisplay(action, 'redemptionsVisibility')">
                            <u *ngIf="action.forecastConfig?.total_redemptions_percentage === null || action.forecastConfig?.total_redemptions_percentage === undefined">
                              {{ action.forecast.redemptionsPercentage | number: '1.2-2' }}
                            </u>
                            <u *ngIf="action.forecastConfig?.total_redemptions_percentage !== null && action.forecastConfig?.total_redemptions_percentage !== undefined">
                              {{ action.forecastConfig.total_redemptions_percentage | number: '1.2-2' }}
                            </u>
                          </span>
                        </td>
                        <td *ngIf="flags.displayEditableInputsForecast" class="align-middle input-width pdd-top-20" [hidden]="!action['redemptionsVisibility']">
                          <app-question (blur)="handleFormChanges(action)" [question]="getInputConfig('estimated_redemption_'+action.campaignId)" [form]="forecastForm"></app-question>
                        </td>
                      </ng-container>
                      <!-- ###################################### -->

                      <td class="text-right" [hidden]="!tableColumnsList[7].visible">
                        <span class="label label-default">{{action.forecast.budgetExpense | lgcurrency: '1.2-2'}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[8].visible">
                        <span class="label label-default">{{action.forecast.budgetRatio | percent: '1.2-2'}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[9].visible">
                        <span class="label label-default">{{action.forecast.incSales | lgcurrency: '1.2-2'}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[10].visible">
                        <span class="label label-default">{{action.forecast.incRatio | number: '1.2-2'}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[11].visible">
                        <span class="label label-default">{{action.forecast.netIncrementalRatio | number: '1.2-2'}}</span>
                      </td>
                      <td class="text-center" [hidden]="!tableColumnsList[12].visible">
                        <span class="label label-default">
                          <a *ngIf="!isPlanDelivered()"
                              href="#/data-warehouse/coupons/coupons(modal:show/coupons/{{action.coupon_id}})"
                              target="_blank"
                              rel="noopener"><b><u>{{action.coupon_id}}</u></b></a>
                          <span *ngIf="isPlanDelivered()">{{action.coupon_id}}</span>
                        </span>
                      </td>
                      <td class="text-center" [hidden]="!tableColumnsList[13].visible">
                        <span *ngIf="action.couponName">{{action.couponName}}</span>
                      </td>
                      <td class="text-center" [hidden]="!tableColumnsList[14].visible">
                        <span *ngIf="action.couponAffectation && action.couponAffectation !== '[]'">
                          {{'resources.coupons.fields.' + action.couponAffectation | translate}}
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[15].visible">
                        <span class="label label-default" *ngIf="action.couponPriority">{{action.couponPriority}}</span>
                        <span class="label label-default" *ngIf="!action.couponPriority">{{'common.fields.na' | translate}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[16].visible">
                        <span class="label label-default">
                          {{action.audience.averageExpenditure | lgcurrency: '1.2-2'}}
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[17].visible">
                        <span class="label label-default">
                          {{action.audience.averageTicket | lgcurrency: '1.2-2'}}
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[18].visible">
                        <span class="label label-default">
                          {{action.audience.units | number: '1.2-2'}}
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[19].visible">
                        <span class="label label-default">
                          {{action.audience.frequency | number: '1.2-2'}}
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[20].visible">
                        <span class="label label-default">
                          {{action.audience.pvp | lgcurrency: '1.2-2'}}
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[21].visible">
                        <span class="label label-default">
                          {{action.audience.affectationSales | lgcurrency: '1.2-2'}}
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[22].visible">
                        <span class="label label-default">
                          {{action.audience.seasonality | number: '1.2-2'}}
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[23].visible">
                        <span class="label label-default">
                          {{action.audience.controlGroup | number: '1.0-0'}}
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[24].visible">
                        <span class="label label-default">
                          {{action.audience.minMoney | number: '1.0-0' }}
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[25].visible">
                        <span class="label label-default">
                          {{action.audience.minUnits | number: '1.0-0' }}
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[26].visible">
                        <span class="label label-default">
                          {{action.forecast.maxRedemptionsPerCoupon | number: '1.0-0'}}
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[27].visible">
                        <span class="label label-default">
                          {{action.forecast.totalRedemptions | number: '1.0-0'}}
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[28].visible">
                        <span class="label label-default">
                          {{action.forecast.estimatedRedemption | number: '1.2-2'}}%
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[29].visible">
                        <span class="label label-default">
                          {{action.forecast.redemptionsPerCustomer | number: '1.2-2'}}
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[30].visible">
                        <span class="label label-default">
                          {{action.forecast.uniqueRedemptions | number: '1.0-0'}}
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[31].visible">
                        <span class="label label-default">
                          {{action.forecast.discount | number: '1.2-2'}}<span *ngIf="action.forecast.discountType === 'cash'">{{currencySymbol}}</span><span *ngIf="action.forecast.discountType === 'percent'">%</span>
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[32].visible">
                        <span class="label label-default">
                          {{action.forecast.minPurchase | number: '1.2-2'}}<span *ngIf="action.forecast.minPurchaseType === 'money'">{{currencySymbol}}</span><span *ngIf="action.forecast.minPurchaseType === 'units'">u</span>
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[33].visible">
                        <span class="label label-default" *ngIf="action.forecast.discountType === 'cash'">
                          {{action.forecast.discountOverPurchase | percent: '1.2-2'}}
                        </span>
                        <span class="label label-default" *ngIf="action.forecast.discountType === 'percent'">
                          {{action.forecast.discount | number: '1.2-2'}}%
                        </span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumnsList[34].visible">
                        <span class="label label-default">
                          {{action.forecast.budgetExpense | lgcurrency: '1.2-2'}}
                        </span>
                      </td>

                      <td class="text-right" [hidden]="!tableColumnsList[35].visible">
                        <span class="label label-default">
                          {{action.forecast.deliveryCost | lgcurrency: '1.2-2'}}
                        </span>
                      </td>

                      <td class="text-right" [hidden]="!tableColumnsList[36].visible">
                        <span class="label label-default" *ngIf="element.type === 'Plans::SupplierCampaign'">
                          {{action.forecast.lgCost | lgcurrency: '1.2-2'}}
                        </span>
                        <span class="label label-default" *ngIf="element.type !== 'Plans::SupplierCampaign'">{{'common.fields.na' | translate}}</span>
                      </td>

                      <td class="text-right" [hidden]="!tableColumnsList[37].visible">
                        <span class="label label-default">
                          {{action.forecast.incrementalOverSales | number: '1.2-2'}}%
                        </span>
                      </td>
                      <td class="text-right"></td>
                      <td class="text-right align-middle">
                        <div class="text-center mrg-top-10">
                          <button id="planDetailForecastCalcBtnPerRow"
                                  class="btn btn-sm btn-default btn-icon action-align"
                                  tooltipPosition="left"
                                  pTooltip="{{'resources.plans.buttons.recalculate' | translate}}"
                                  (click)="recalculateRow(action)">
                            <i class="ei-refresh icon-align"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  <!-- CAMPAIGN TOTAL -->
                  <tr class="highlight-header">
                    <td class="vertical-align-middle text-left text-bold td-stick2" [hidden]="!tableColumnsList[1].visible">
                      {{'resources.campaign_plans.types.custom.forecast.table.campaign_total' | translate}}
                    </td>
                    <td class="td-stick3"></td>
                    <td class="td-stick4" [hidden]="!tableColumnsList[2].visible"></td>
                    <td class="text-right" [hidden]="!tableColumnsList[4].visible">
                      <span class="label label-default">{{campaign.impactedCustomers | number: '1.0-0'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[5].visible">
                      <span class="label label-default">{{campaign.estimatedDelivery | percent: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[6].visible">
                      <span class="label label-default">{{campaign.redemptions | percent: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[7].visible">
                      <span class="label label-default">{{campaign.budgetExpense | lgcurrency: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[8].visible">
                      <span class="label label-default">{{campaign.budgetExpenseRatio | percent: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[9].visible">
                      <span class="label label-default">{{campaign.incSales | lgcurrency: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[10].visible">
                      <span class="label label-default">{{campaign.incSalesRatio | number: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[11].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[12].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[13].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[14].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[15].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[16].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[17].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[18].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[19].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[20].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[21].visible">
                      <span class="label label-default">{{campaign.affectationSales | lgcurrency: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[22].visible">
                      <span class="label label-default">{{campaign.seasonality | number: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[23].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[24].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[25].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[26].visible">
                      <span class="label label-default">{{campaign.maxRedemptionsPerCoupon | number: '1.0-0'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[27].visible">
                      <span class="label label-default">{{campaign.totalRedemptions | number: '1.0-0'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[28].visible">
                      <span class="label label-default">{{campaign.estimatedRedemptionPerc | number: '1.2-2'}}%</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[29].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[30].visible">
                      <span class="label label-default">{{campaign.uniqueRedemptions | number: '1.0-0'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[31].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[32].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[33].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[34].visible">
                      <span class="label label-default">{{campaign.discountCost | lgcurrency: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[35].visible">
                      <span class="label label-default">{{campaign.deliveryCost | lgcurrency: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[36].visible">
                      <span class="label label-default" *ngIf="element.type === 'Plans::SupplierCampaign'">
                        {{campaign.lgCost | lgcurrency: '1.2-2'}}
                      </span>
                      <span class="label label-default" *ngIf="element.type !== 'Plans::SupplierCampaign'">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumnsList[37].visible">
                      <span class="label label-default">{{campaign.incrementalOverSales | number: '1.2-2'}}%</span>
                    </td>
                    <td class="text-center vertical-align-middle">
                      <div class="btn-group text-center">
                        <button type="button"
                          class="btn btn-icon no-pdd font-size-20 pdd-horizon-10 pdd-vertical-2 default mrg-btm-0"
                          [ngClass]="{'btn-success': campaign.status === 'completed' || campaign.status === 'delivered',
                                      'btn-dark': campaign.status !== 'completed' && campaign.status !== 'delivered',
                                      'not-allowed': !ableToApprove}"
                          [disabled]="!ableToApprove"
                          (click)="approveCampaign(campaign)">
                          <i class="fa fa-thumbs-up"></i>
                        </button>
                        <button type="button"
                          class="btn btn-icon no-pdd font-size-20 pdd-horizon-10 pdd-vertical-2 default mrg-btm-0"
                          [ngClass]="{'btn-danger': campaign.status === 'rejected',
                                      'btn-dark': campaign.status !== 'rejected',
                                      'not-allowed': !ableToApprove}"
                          [disabled]="!ableToApprove"
                          (click)="rejectCampaign(campaign)">
                          <i class="fa fa-thumbs-down"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <!-- END CAMPAIGN TOTAL -->
                </ng-container>

                <!-- SUBTOTAL / CAMPAIGN TYPE -->
                <tr class="highlight-header">
                  <td [hidden]="!tableColumnsList[1].visible" class="text-left text-bold td-stick2">{{'common.subtotal' | translate}}</td>
                  <td class="td-stick3"></td>
                  <td class="td-stick4" [hidden]="!tableColumnsList[2].visible"></td>
                  <td class="text-right" [hidden]="!tableColumnsList[4].visible">
                    <span class="label label-default">{{element.subtotal.impactedCustomers | number: '1.0-0'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[5].visible">
                    <span class="label label-default">{{element.subtotal.estimatedDelivery | percent: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[6].visible">
                    <span class="label label-default">{{element.subtotal.totalRedemptionsPercentage | percent: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[7].visible">
                    <span class="label label-default">{{element.subtotal.budgetExpense | lgcurrency: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[8].visible">
                    <span class="label label-default">{{element.subtotal.budgetRatio |percent: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[9].visible">
                    <span class="label label-default">{{element.subtotal.incSales | lgcurrency: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[10].visible">
                    <span class="label label-default">{{element.subtotal.incRatio | number: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[11].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[12].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[13].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[14].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[15].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[16].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[17].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[18].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[19].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[20].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[21].visible">
                    <span class="label label-default">{{element.subtotal.affectationSales | lgcurrency: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[22].visible">
                    <span class="label label-default">{{element.subtotal.seasonality | number: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[23].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[24].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[25].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[26].visible">
                    <span class="label label-default">{{element.subtotal.maxRedemptionsPerCoupon | number: '1.0-0'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[27].visible">
                    <span class="label label-default">{{element.subtotal.totalRedemptions | number: '1.0-0'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[28].visible">
                    <span class="label label-default">{{element.subtotal.estimatedRedemptionPerc | number: '1.2-2'}}%</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[29].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[30].visible">
                    <span class="label label-default">{{element.subtotal.uniqueRedemptions | number: '1.0-0'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[31].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[32].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[33].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[34].visible">
                    <span class="label label-default">{{element.subtotal.discountCost | lgcurrency: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[35].visible">
                    <span class="label label-default">{{element.subtotal.deliveryCost | lgcurrency: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[36].visible">
                    <span class="label label-default" *ngIf="element.type === 'Plans::SupplierCampaign'">
                      {{element.subtotal.lgCost | lgcurrency: '1.2-2'}}
                    </span>
                    <span class="label label-default" *ngIf="element.type !== 'Plans::SupplierCampaign'">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumnsList[37].visible">
                    <span class="label label-default">{{element.subtotal.incrementalOverSales | number: '1.2-2'}}%</span>
                  </td>
                  <td class="text-right"></td>
                </tr>
                <!-- END SUBTOTAL / CAMPAIGN TYPE -->
              </ng-container>

              <!-- GLOBAL TOTAL -->
              <tr class="highlight-header">
                <td class="td-stick1"></td>
                <td [hidden]="!tableColumnsList[1].visible" class="text-left text-bold td-stick2">{{'resources.plans.fields.total' | translate}}</td>
                <td class="td-stick3"></td>
                <td class="td-stick4"></td>
                <td class="text-right" [hidden]="!tableColumnsList[4].visible">
                  <span class="label label-default">{{totals.impactedCustomers | number: '1.0-0'}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[5].visible">
                  <span class="label label-default">{{totals.estimatedDelivery | percent: '1.2-2'}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[6].visible">
                  <span class="label label-default">{{totals.calcTotalRedemptions | percent: '1.2-2'}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[7].visible">
                  <span class="label label-default">{{totals.budgetValue | lgcurrency: '1.2-2'}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[8].visible">
                  <span class="label label-default">{{totals.budgetRatioValue | percent: '1.2-2'}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[9].visible">
                  <span class="label label-default">{{totals.incSales | lgcurrency: '1.2-2'}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[10].visible">
                  <span class="label label-default">{{totals.incSalesRatio | number: '1.2-2'}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[11].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[12].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[13].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[14].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[15].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[16].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[17].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[18].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[19].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[20].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[21].visible">
                  <span class="label label-default">{{totals.affectationSales | lgcurrency: '1.2-2'}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[22].visible">
                  <span class="label label-default">{{totals.seasonality | number: '1.2-2'}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[23].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[24].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[25].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[26].visible">
                  <span class="label label-default">{{totals.maxRedemptionsPerCoupon | number: '1.0-0'}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[27].visible">
                  <span class="label label-default">{{totals.redemptions | number: '1.0-0'}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[28].visible">
                  <span class="label label-default">{{totals.estimatedRedemptionPerc | number: '1.2-2'}}%</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[29].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[30].visible">
                  <span class="label label-default">{{totals.uniqueRedemptions | number: '1.0-0'}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[31].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[32].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[33].visible">
                  <span class="label label-default">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[34].visible">
                  <span class="label label-default">{{totals.discountCost | lgcurrency: '1.2-2'}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[35].visible">
                  <span class="label label-default">{{totals.deliveryCost | lgcurrency: '1.2-2'}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[36].visible">
                  <span class="label label-default" *ngIf="hasSupplierCampaign">{{totals.lgCost | lgcurrency: '1.2-2'}}</span>
                  <span class="label label-default" *ngIf="!hasSupplierCampaign">{{'common.fields.na' | translate}}</span>
                </td>
                <td class="text-right" [hidden]="!tableColumnsList[37].visible">
                  <span class="label label-default">{{totals.incrementalOverSales | number: '1.2-2'}}%</span>
                </td>
                <td class="text-right"></td>
              </tr>
              <!-- END GLOBAL TOTAL -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODAL -->
<app-switch-coupons #switchCoupons *ngIf="plan" [plan]="plan"></app-switch-coupons>
<!--  -->
