<div class="mrg-left-15 mrg-top-15">
  <span class="font-size-14 no-mrg-btm">
    <button class="btn btn-rounded btn-default pointer" (click)="goBack()">
      <em class="pi pi-arrow-left font-size-12"></em>
      {{ 'common.back' | translate}}
    </button>
  </span>
</div>
<div class="bgtext">
  <div class="centering">
    <p class="title-text">{{'common.messages.error_page_title' | translate}}</p>
    <p class="subtitle-text">{{'common.messages.error_page_sub1' | translate}}</p>
    <p class="subtitle-text">{{'common.messages.error_page_sub2' | translate}}</p>
    <a class="btn btn-rounded btn-default"
      href="https://loyal-guru.atlassian.net/servicedesk/customer/portals"
      target="_blank"
      (click)="trackFrogedEvent('helpCenter')"
      rel="noopener">
      {{'common.buttons.help_center' | translate}}
    </a>
    <button class="btn btn-rounded btn-info" (click)="reload()">{{'common.buttons.reload' | translate}}</button>
  </div>
</div>
