import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Used to display an icon info button with a customizable size.
 *
 * @example
 * ```html
 * // Parent component template
 * <icon-info-button size="24px"></icon-info-button>
 * ```
 */
@Component({
  selector: 'icon-info-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon-info-button.component.html',
  styleUrls: ['./icon-info-button.component.scss']
})
export class IconInfoButtonComponent {
  @Input('size') btnSize: string = '32px';
}