import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { ProfileService } from '../../../../profiles/profile.service';
import { FeatureTaxonomiesService } from '../feature-taxonomies.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import es from '@angular/common/locales/es';
import { DEFAULT_PRINCIPAL_CATEGORY } from '../../../../shared/constants/looker.constants';

@Component({
  selector: 'app-show-products-feature-taxonomies',
  templateUrl: './show-products-feature-taxonomies.component.html',
  styleUrls: ['./show-products-feature-taxonomies.component.css']
})
export class ShowProductsFeatureTaxonomiesComponent implements OnInit, OnDestroy {

  element: Object;
  apiEndPoint: string;
  id: number;
  isModal: boolean;
  currentRole: string;
  subs$: Subscription[];
  principalCategorization: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService,
    private taxonomiesService: FeatureTaxonomiesService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ){
    this.subs$ = [];
  }

  ngOnInit() {
    registerLocaleData(es);
    this.currentRole = this.profileService.getStoredUserRole();
    this.isModal = this.router.url.indexOf('(modal:show/feature_taxonomies/') >= 0;
    this.principalCategorization = this.profileService.getStoredUser().company.plans_configuration?.principal_categorization || DEFAULT_PRINCIPAL_CATEGORY;
    this.getParams();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getParams() {
    const route$ = this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.getTaxonomy();
      }
    });
    this.subs$.push(route$);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getTaxonomy() {
    const taxonomy$ = this.taxonomiesService.getFeatureById(this.id).subscribe(
      (res: HttpResponse<object>) => {
        this.element = res;
      },
      (err: HttpErrorResponse) => {
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), err.error.error);
      }
    );
    this.subs$.push(taxonomy$);
  }
}
