<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-10">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form [formGroup]="conversionsForm" *ngIf="conversionsForm">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="currency_a" [translate]="'resources.currencies.conversions.form.currency_a'"></label>
                      <app-question [question]="getInputConfig('currency_a')" [form]="conversionsForm" [filters]="{active: true}"></app-question>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="currency_b" [translate]="'resources.currencies.conversions.form.currency_b'"></label>
                      <app-question [question]="getInputConfig('currency_b')" [form]="conversionsForm" [filters]="{active: true}"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="conversion_rate_a">{{ conversionRateTextA }}</label>
                      <app-question [question]="getInputConfig('conversion_rate_a')" [form]="conversionsForm"></app-question>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="conversion_rate_b">{{ conversionRateTextB }}</label>
                      <app-question [question]="getInputConfig('conversion_rate_b')" [form]="conversionsForm"></app-question>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
