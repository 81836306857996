<a #modalToggleBtn
   data-toggle="modal"
   data-target="#push-template-edit-modal"
   data-keyboard="false"
   [attr.data-backdrop]="modeReminder !== 'reminder_plan' && modeReminder !== 'received_reminder' && mode === 'edit'"
   hidden></a>
<form [formGroup]="form">
  <div class="modal fade show" id="push-template-edit-modal">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header border bottom">
          <h4 class="mrg-btm-15 mrg-top-8">{{ title }}</h4>
          <button class="modal-close" [attr.data-cy]="'button_closePushModal'" data-dismiss="modal" (click)="closeWithoutSaving()">
            <em class="ti-close"></em>
          </button>
        </div>
        <div class="modal-body background-gray">
          <div class="row" *ngIf="inputs">
            <div class="col-md-12 ml-auto mr-auto">
              <div class="card no-mrg-btm">
                <div class="card-block pdd-15">
                  <div class="form-group">
                    <label for="title" [translate]="'resources.campaigns.fields.title'"></label>
                    <app-question [question]="getInputConfig('subject')" [form]="form" (keyup)="detectTyping($event)"></app-question>
                  </div>
                  <div class="form-group">
                    <label for="message" [translate]="'resources.campaigns.fields.message'"></label>
                    <app-question [question]="getInputConfig('message')" [form]="form" (keyup)="detectTyping($event)"></app-question>
                    <p class="help-block" *ngIf="titleMessageMetrics || bodyMessageMetrics">
                      <span tooltip-placement="right"
                            class="fa fa-info-circle"
                            data-trigger="hover">
                      </span>
                      <small class="font-size-12">
                        <span [innerHTML]="'resources.campaigns.warnings.character_count_push' | translate: {titleTotalLength: titleMessageMetrics.length, titleRecommendedLength: 20, descTotalLength: bodyMessageMetrics.length, descRecommendedLength: 60}">
                        </span>
                      </small>
                    </p>
                  </div>
                  <div class="form-group">
                    <label for="redirectOption" [translate]="'resources.campaigns.fields.redirect_option'"></label>
                    <app-question [question]="getInputConfig('redirectOption')" [form]="form"></app-question>
                  </div>
                  <div class="form-group" *ngIf="hasFormKeyWithValue('redirectOption', 'app') && !hasExternalApp">
                    <label for="redirectStateName" [translate]="'resources.campaigns.fields.redirect_app'"></label>
                    <app-question (multiselectChanged)="handleRedirectionChanged($event)"
                                  [question]="getInputConfig('redirectStateName')"
                                  [form]="form">
                    </app-question>
                  </div>
                  <div class="form-group" *ngIf="hasFormKeyWithValue('redirectOption', 'app') && hasExternalApp">
                    <label for="externalRedirectStateName" [translate]="'resources.campaigns.fields.redirect_app'"></label>
                    <app-question [question]="getInputConfig('externalRedirectStateName')"
                                  [form]="form">
                    </app-question>
                  </div>
                  <div class="form-group" *ngIf="displayNewsSelection">
                    <label for="newsId" [translate]="'resources.campaigns.fields.redirect_news'"></label>
                    <app-question [question]="getInputConfig('newsId')" [form]="form"></app-question>
                  </div>
                  <div class="form-group" *ngIf="hasFormKeyWithValue('redirectOption', 'url')">
                    <label for="externalRedirectStateName" [translate]="'resources.campaigns.fields.redirect_url'"></label>
                    <app-question [question]="getInputConfig('url')"
                                  [form]="form">
                    </app-question>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" *ngIf="mode !== 'readonly' && mode !== 'show'">
          <button class="btn btn-default"
                [attr.data-cy]="'button_cancelPushModal'"
                data-dismiss="modal"
                [translate]="'common.buttons.cancel'"
                (click)="closeWithoutSaving()">
          </button>
          <span><i class="fa fa-spinner fa-spin" *ngIf="loading"></i></span>
          <button id="saveBtn"
                  type="button"
                  class="btn btn-primary no-mrg-right"
                  [attr.data-cy]="'button_savePushModal'"
                  (click)="save()"
                  [disabled]="loading"
                  [translate]="'resources.campaign_templates.buttons.save'">
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
