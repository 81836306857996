import { Pipe, PipeTransform } from '@angular/core';
/*
 * Usage:
 *   array | orderBy: 'field': true or null is ascending, false is descending
 * Example:
 *   *ngFor="let item of data.details |  orderBy:'name'"
 *   {{ data | orderBy:'name':false" }}
*/

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
    transform(array: [any], _orderBy: string | string[], asc = true) {

        let orderBy: string;

        if (_orderBy instanceof Array && _orderBy.length > 0 ) {
            orderBy = _orderBy[0];
        }

        if (!orderBy || orderBy.trim() === '') {
            return array;
        }

        // asc
        if (asc) {
            return Array.from(array).sort((item1: any, item2: any) => {
                if (item1[orderBy] && item2[orderBy]) {
                    return this.orderByComparator(item1[orderBy], item2[orderBy]);
                }
            });
        } else {
            // desc
            return Array.from(array).sort((item1: any, item2: any) => {
                if (item1[orderBy] && item2[orderBy]) {
                    return this.orderByComparator(item2[orderBy], item1[orderBy]);
                }
            });
        }

    }

    orderByComparator(a: any, b: any): number {

        if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
            // Lowercase to compare
            if (a.toLowerCase() < b.toLowerCase()) {
                return -1;
            }
            if (a.toLowerCase() > b.toLowerCase()) {
                return 1;
            }
        } else {
            // Parsing
            if (parseFloat(a) < parseFloat(b)) {
                return -1;
            }
            if (parseFloat(a) > parseFloat(b)) {
                return 1;
            }
        }

        return 0; // equal
    }
}
