import { Component, Input } from '@angular/core';
import { CustomEntityKey } from '../../../../../../services/conditions/services/custom-entity-condition/enums/custom-entity-key.enums';
import { CustomEntityAttributeType } from '../../../../../../services/conditions/services/custom-entity-condition/enums/custom-entity-attribute-type.enums';
import { QuestionBase } from '../../../../../../models/forms/question-base';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../../../services/question-control.service';
import { Operator } from '../../../../../../models/segments/conditions/operator';
import { getAttributesConfigurationFormGroup, resetAttributeConfigurationCtrl } from '../../utils/attributes-form.utils';
import { MultiselectDataSource } from '../../../../../multiselect/multiselect';
import { ATTRIBUTES_CONFIGURATION_OPERATORS_VALUES_FIELDS, NARROW_LAYOUT_CLASS, WIDE_LAYOUT_CLASS } from '../../constants/custom-entity-condition.constants';

@Component({
  selector: 'app-attributes-custom-entity-condition',
  templateUrl: './attributes-custom-entity-condition.component.html',
  styleUrls: ['./attributes-custom-entity-condition.component.scss']
})
export class AttributesCustomEntityConditionComponent {

  wideLayout = WIDE_LAYOUT_CLASS;
  narrowLayout = NARROW_LAYOUT_CLASS;
  customEntityKey = CustomEntityKey;
  customEntityAttributeType = CustomEntityAttributeType;

  @Input() inputs: QuestionBase<unknown>[];
  @Input() form: UntypedFormGroup;
  @Input() attributesConfigArray: UntypedFormArray;
  @Input() newAttributeForm: UntypedFormGroup;

  constructor(
    private qcs: QuestionControlService
  ) {}

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  isSelectedAttributeType(attributeType: string): boolean {
    return this.newAttributeForm?.get(this.customEntityKey.AttributeType)?.value === attributeType;
  }

  isFloatAttribute(): boolean {
    return !!(this.isSelectedAttributeType(this.customEntityAttributeType.Float) || this.isSelectedAttributeType(this.customEntityAttributeType.Number));
  }

  isDateOrDatetimeAttribute(): boolean {
    return !!(this.isSelectedAttributeType(this.customEntityAttributeType.Datetime) || this.isSelectedAttributeType(this.customEntityAttributeType.Date));
  }

  isBtOperator(operatorKey: string): boolean {
    return this.qcs.hasFormKeyWithValue(this.newAttributeForm, operatorKey, 'bt');
  }

  classByOperator(operatorKey: string): string {
    return this.isBtOperator(operatorKey) ? this.narrowLayout : this.wideLayout;
  }

  handleSelectedAttribute(selectedAttribute: MultiselectDataSource): void {
    if (selectedAttribute) {
      this.handleAttributeSelectedOperatorAndValueFields(selectedAttribute);
    } else {
      this.newAttributeForm.reset();
    }
  }

  handleOperatorChanges(operator: Operator, keyValue2: string) {
    const op = operator?.hasOwnProperty('id') ? operator.id : null;
    if (!op || op !== 'bt') {
      resetAttributeConfigurationCtrl(this.newAttributeForm, [keyValue2]);
    }
  }

  addNewAttribute(): void {
    if (this.newAttributeForm.valid) {
      this.attributesConfigArray.push(getAttributesConfigurationFormGroup(this.newAttributeForm.getRawValue()));
      this.newAttributeForm.reset();
    }
  }

  private handleAttributeSelectedOperatorAndValueFields(attribute: MultiselectDataSource): void {
    const attributeTypeData = attribute['rawElement']?.type ? attribute['rawElement'].type : null;
    this.newAttributeForm.get(this.customEntityKey.AttributeType).patchValue(attributeTypeData);
    resetAttributeConfigurationCtrl(this.newAttributeForm, ATTRIBUTES_CONFIGURATION_OPERATORS_VALUES_FIELDS);
    this.newAttributeForm.markAsUntouched();
  }
}
