import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { CountriesService } from '../../../../shared/services/countries.service';
import { Subscription } from 'rxjs';
import { ScoreExchangesService } from '../score-exchanges.service';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-show-score-exchanges',
  templateUrl: './show-score-exchanges.component.html',
  styleUrls: ['./show-score-exchanges.component.css'],
  providers: [ ScoreExchangesService ]
})
export class ShowScoreExchangesComponent implements OnInit, OnDestroy {

  public element: any;
  public apiEndPoint: string;
  public id: number;
  public isModal: boolean;
  public subs$: Subscription[] = [];

  constructor(
    public route: ActivatedRoute,
    public scoreExchangeSrv: ScoreExchangesService,
    public countriesService: CountriesService,
    public confirmationService: ConfirmationService,
    public translate: TranslateService,
    public router: Router
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.isModal = this.router.url.indexOf('(modal:show/score_exchanges/') >= 0;
    this.getParams();
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach(s$ => s$.unsubscribe()); }
  }

  getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'score_exchanges';
        this.getScoreExchangeData();
      }
    });
  }

  getScoreExchangeData() {
    const data$ = this.scoreExchangeSrv.getScoreExchangesById(this.id).subscribe(
      res => {
        this.element = res;
      },
      error => {
        this.confirmationService.displayErrorAlert('Error', error);
      }
    );
    this.subs$.push(data$);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

}
