<div class="side-nav" sideBar *ngIf="navItems?.length">
  <div class="side-nav-inner">
    <!-- Top left logo -->
    <div class="side-nav-logo">
      <a routerLink="{{roleSlug !== 'user_manager' ? '/home' : '/config/users'}}">
        <div class="logo logo-dark" style="background-image: url('assets/images/logo/lg_logo_long.png')"></div>
      </a>
      <div class="mobile-toggle side-nav-toggle">
        <a class="side-nav-toggle" href="javascript:void(0);" (click)="triggerEvent('side-nav-toggle')"
          id="side-nav.toggle">
          <em class="iconExpanded" title="{{ 'components.content-header.buttons.collapsed' | translate }}"></em>
        </a>
      </div>
    </div>

    <ul class="side-nav-menu scrollable">

      <ng-template #iconsAndTitle let-item>
        <span class="icon-holder">
          <em class="{{item.icon}} alignment" [attr.data-cy]="'icon_' + (item.id | camelCase) + 'Title'"></em>
        </span>
        <span class="title" [translate]="'menu_dia.' + item.title" [attr.data-cy]="'span_' + (item.id | camelCase) + 'Title'"></span>
        <span class="arrow" *ngIf="item.children?.length">
          <em class="ti-angle-right chev-align" [attr.data-cy]="'icon_' + (item.id | camelCase) + 'TitleArrow'" ></em>
        </span>
      </ng-template>

      <ng-template #items let-navItems>
        <ng-container *ngFor="let item of navItems">

          <hr *ngIf="item.groupSeparator && roleSlug !== 'user_manager'">
          <!-- Nav Item -->
          <li class="nav-item" [class.dropdown]="item.children?.length" routerLinkActive="active open"
            [hidden]="isNotAuth(item)">
            <!-- Item purchased -->
            <ng-container *ngIf="item.isPurchased; else notPurchased">
              <!-- Children -->
              <ng-container *ngIf="item.children?.length; else noChildren">
                <a class="dropdown-toggle" href="javascript:void(0);" id="{{'side-nav.' + item.id}}">
                  <span [routerLink]="item.route" hidden></span>
                  <ng-container *ngTemplateOutlet="iconsAndTitle; context:{ $implicit: item }"></ng-container>
                </a>
                <ul class="dropdown-menu">
                  <ng-container *ngTemplateOutlet="items; context:{ $implicit: item.children }"></ng-container>
                </ul>
              </ng-container>

              <ng-template #noChildren>
                <!-- External link -->
                <ng-container *ngIf="item.isExternal; else notExternal">
                  <a [routerLink]="item.route" routerLinkActive="active-list-item" id="{{'side-nav.' + item.id}}"
                    class="ei-link" pTooltip="{{ 'menu_dia.external_module' | translate }}" tooltipPosition="top"
                    [attr.data-cy]="'link_' + (item.id | camelCase) + 'Title'">
                    <span class="title" [translate]="'menu_dia.' + item.title" [attr.data-cy]="'span_' + (item.id | camelCase) + 'Title'"></span>
                  </a>
                </ng-container>

                <ng-template #notExternal>
                  <a [routerLink]="item.route" routerLinkActive="active-list-item" id="{{'side-nav.' + item.id}}">
                    <ng-container *ngTemplateOutlet="iconsAndTitle; context:{ $implicit: item }"></ng-container>
                  </a>
                </ng-template>
              </ng-template>

            </ng-container>

            <ng-template #notPurchased>
              <a class="dropdown-toggle nav-disabled" href="javascript:void(0);"
                id="{{'side-nav.' + item.id + '.unavailable'}}" (click)="showNotAvailable()">
                <ng-container *ngTemplateOutlet="iconsAndTitle; context:{ $implicit: item }"></ng-container>
              </a>
            </ng-template>
          </li>
        </ng-container>
      </ng-template>

      <ng-container *ngTemplateOutlet="items; context:{ $implicit: navItems }"></ng-container>
    </ul>
  </div>
</div>