<div class="container-fluid clearfix" [ngClass]="{'mrg-top-15': !isModal}">
  <div class="row" *ngIf="element">
    <div class="col">
      <div class="card mrg-btm-15">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true">
              <tbody>
                <tr *ngIf="element.id">
                  <td class="no-border-top"><b [translate]="'resources.product_categories.fields.id'"></b></td>
                  <td class="no-border-top">{{element.id}}</td>
                </tr>
                <tr *ngIf="element.name">
                  <td><b [translate]="'resources.product_categories.fields.name'"></b></td>
                  <td>{{element.name}}</td>
                </tr>
                <tr *ngIf="element.slug">
                  <td><b [translate]="'resources.product_categories.fields.slug'"></b></td>
                  <td>{{element.slug}}</td>
                </tr>
                <tr *ngIf="element.taxonomy">
                  <td><b [translate]="'resources.product_categories.fields.taxonomy_slug'"></b></td>
                  <td>                          
                    <a class="pointer colorLink" [routerLink]="['', { outlets: { modal: ['show', 'feature_taxonomies', element.taxonomy.id] } }]">
                      {{element.taxonomy.name}}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <app-data-table-filter *ngIf="filters" 
                            [questions]="filters" 
                            (onSubmitEmitter)="onFilterHandler($event)">
      </app-data-table-filter>

      <div class="card" *ngIf="element">
        <div class="card-block">
          <app-data-table [config]="productsTableConfig"></app-data-table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Categorize Products Modal -->
<a #categorizeProductsBtn data-toggle="modal" data-target="#categorize-modal" hidden></a>
<div class="modal fade modal" id="categorize-modal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content background-white">
      <div class="modal-header">
        <h4 [translate]="'resources.product_categories.form.categorize_title'"></h4>
        <button type="button" class="modal-close" data-dismiss="modal"><i class="ti-close"></i></button>
      </div>
      <div class="modal-body">
        <p [translate]="'resources.product_categories.form.categorize_text'"></p>
        <form class="form-horizontal mrg-top-40 pdd-right-30">
          <div class="form-group row">
            <div class="col-md-12">
              <textarea id="height-40vh" [(ngModel)]="csv_text" name="csv_text" placeholder="id;&#10;12;&#10;32;&#10;15"
                class="form-control">
              </textarea>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer no-border">
        <div class="text-right">
          <button (click)="categorizeProducts()" class="btn btn-info btn-sm" data-dismiss="modal"
            [translate]="'resources.product_categories.buttons.send'">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
