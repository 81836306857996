import { UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";
import { INTEGER_REGEX } from "../../../constants/condition.constants";
import { isCtrlMinInteger, setValidationMinIntegerErrorsMark } from "../../../validations/common.validations";
import { checkValue1GreaterThanValue2 } from "../../../../validations.service";

export function getFormGroupValidations(control: UntypedFormGroup, minValue: number): ValidationErrors | null {
  const operator = control.get('operator');
  const featureCount = control.get('feature_count');
  const featureCountBt = control.get('feature_count_bt');

  featureCountBt.setValidators([]);

  if (operator.value?.length && operator.value[0].id === 'bt') {
    featureCount.setValidators([Validators.required, Validators.min(minValue), Validators.pattern(INTEGER_REGEX)]);
    featureCountBt.setValidators([Validators.required, Validators.min(minValue), Validators.pattern(INTEGER_REGEX)]);
    if (!isCtrlMinInteger(featureCount, minValue)) {
      return setValidationMinIntegerErrorsMark(featureCount);
    } else if (!isCtrlMinInteger(featureCountBt, minValue)) {
      return setValidationMinIntegerErrorsMark(featureCountBt);
    } else {
      return checkValue1GreaterThanValue2(featureCount, featureCountBt);
    }
  }
  return null;
}