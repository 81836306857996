import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { MultiselectDataSource } from '../../../../shared/components/multiselect/multiselect';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { TranslateService } from '@ngx-translate/core';
import { LocationsService } from '../../locations/locations.service';
import { CountriesService } from '../../../../shared/services/countries.service';
import { Observable } from 'rxjs';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { CheckboxQuestion } from '../../../../shared/models/forms/question-checkbox';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ProfileSchemaForm, CustomInputCfg } from '../../../../shared/models/profile-schema-form';
import { cleanUndefinedFormValues, futureDatesValidator } from '../../../../shared/services/validations.service';
import { LanguagesService } from '../../../../shared/services/languages.service';
import { FloatQuestion } from '../../../../shared/models/forms/question-float';
import { Option } from '../../../../shared/models/common/option';
import { InputType } from '../../../../shared/enums/input-type.enums';
import { CurrentUser } from '../../../../shared/models/current-user';

@Injectable()
export class ProfileFormService extends ResourcesService {

  inputs: QuestionBase<any>[];
  clearIntervalInstance: number;

  constructor(
    http: HttpClient,
    private translate: TranslateService,
    private locations: LocationsService,
    private countriesService: CountriesService,
    private languagesSrv: LanguagesService
  ) {
    super(http);
  }

  getNewFormFields(): Observable<any> {
    return this.getData({apiEndPoint: `companies/customers/new`});
  }

  getUpdateFormFields(): Observable<any> {
    return this.getData({apiEndPoint: `companies/customers/update?full=true`});
  }

  getProfiles(): Observable<any> {
    return this.getData({apiEndPoint: `profiles`});
  }

  getProfilesById(id: string): Observable<any> {
    return this.getData({apiEndPoint: `profiles/${id}`});
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.name);
  }

  getStatusOptions(): Option[] {
    return [
      { id: 'active', name: this.translate.instant('resources.profiles.form.fields.active')},
      { id: 'inactive', name: this.translate.instant('resources.profiles.form.fields.inactive')},
      { id: 'merged', name: this.translate.instant('common.status.merged')}
    ];
  }

  getEmailAndPhoneStatus(): Option[] {
    return [
      { id: 'pending', name: this.translate.instant('resources.profiles.form.fields.pending')},
      { id: 'empty', name: this.translate.instant('resources.profiles.form.fields.empty')},
      { id: 'valid', name: this.translate.instant('resources.profiles.form.fields.valid')},
      { id: 'invalid', name: this.translate.instant('resources.profiles.form.fields.invalid')}
    ];
  }

  getDocumentType(): Option[] {
    return [
      { id: 'dni', name: this.translate.instant('resources.profiles.form.fields.dni')},
      { id: 'other', name: this.translate.instant('resources.profiles.form.fields.other')}
    ];
  }

  getGender(): Option[] {
    return [
      { id: 1, name: this.translate.instant('resources.profiles.form.fields.masculine')},
      { id: 2, name: this.translate.instant('resources.profiles.form.fields.femenine')}
    ];
  }

  getInputs(formValues: object, profileSchemaForm: ProfileSchemaForm, currentUser: CurrentUser): QuestionBase<unknown>[] {
    const isCreationMode = !Object.keys(formValues).length;
    const hasUserOnlyOneAssignedLocation = !!(currentUser?.locations?.length === 1);
    const inputsArray = [
      new MultiSelectQuestion({
        key: 'registration_location_id',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: true, showCheckbox: false},
        dataSource: this.locations,
        disabled: isCreationMode && hasUserOnlyOneAssignedLocation,
        selectedIds: isCreationMode && hasUserOnlyOneAssignedLocation && currentUser.locations[0].id ? [currentUser.locations[0].id] : [],
        getValue: (value) => ({ selectedIds: [value] }),
        required: true
      }),
      new TextboxQuestion({
        key: 'email',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value,
        required: profileSchemaForm.chkIfRequired('email'),
        customValidators: profileSchemaForm.chkForCustomValidators('email')
      }),
      new MultiSelectQuestion({
        key: 'gender',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.getGender(),
        getValue: (value) => {
          if (value) {
            const gender = this.getGender().find(el => el.id === value);
            return gender ? [gender] : [];
          } else {
            return [];
          }
        },
        required: profileSchemaForm.chkIfRequired('gender', 'profiles')
      }),
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value,
        required: profileSchemaForm.chkIfRequired('name'),
        customValidators: profileSchemaForm.chkForCustomValidators('name')
      }),
      new TextboxQuestion({
        key: 'surname_1',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value,
        required: profileSchemaForm.chkIfRequired('surname_1'),
        customValidators: profileSchemaForm.chkForCustomValidators('surname_1')
      }),
      new TextboxQuestion({
        key: 'surname_2',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value,
        required: profileSchemaForm.chkIfRequired('surname_2'),
        customValidators: profileSchemaForm.chkForCustomValidators('surname_2')
      }),
      new TextboxQuestion({
        key: 'address',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value,
        required: profileSchemaForm.chkIfRequired('address', 'profiles'),
        customValidators: profileSchemaForm.chkForCustomValidators('address', 'profiles')
      }),
      new TextboxQuestion({
        key: 'city',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value,
        required: profileSchemaForm.chkIfRequired('city', 'profiles')
     }),
     new TextboxQuestion({
       key: 'postal_code',
       type: 'text',
       cssClasses: 'form-control input-default',
       getValue: (value) => value,
       required: profileSchemaForm.chkIfRequired('postal_code', 'profiles'),
       customValidators: profileSchemaForm.chkForCustomValidators('postal_code', 'profiles')
     }),
      new TextboxQuestion({
        key: 'state',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value,
        required: profileSchemaForm.chkIfRequired('state', 'profiles')
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'birth',
        type: 'date',
        getValue: (value) => value,
        required: profileSchemaForm.chkIfRequired('birth', 'profiles'),
        customValidators: [futureDatesValidator()]
      }),
      new MultiSelectQuestion({
        key: 'document_type',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.getDocumentType(),
        getValue: (value) => [this.getDocumentType().find(el => el.id === value)],
        required: profileSchemaForm.chkIfRequired('document_type', 'profiles')
      }),
      new TextboxQuestion({
        key: 'dni',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value,
        required: profileSchemaForm.chkIfRequired('dni', 'profiles'),
        customValidators: profileSchemaForm.chkForCustomValidators('dni', 'profiles')
      }),
      new CheckboxQuestion({
        key: 'allow_duplicate_email',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new CheckboxQuestion({
        key: 'allow_duplicate_dni',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'card_id',
        type: 'number',
        cssClasses: 'form-control input-default'
      }),
      new MultiSelectQuestion({
        key: 'phone_prefix',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        options: this.countriesService.getCountries('phone_prefixes'),
        getValue: (value) => {
          if (value) {
            const code = this.countriesService.getCountries().find(el => el.rawElement.dial_code === value);
            return code ? [code] : [];
          } else {
            return [];
          }
        },
        required: profileSchemaForm.chkIfRequired('phone_prefix')
      }),
      new TextboxQuestion({
        key: 'phone',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value,
        required: profileSchemaForm.chkIfRequired('phone'),
        customValidators: profileSchemaForm.chkForCustomValidators('phone')
      }),
      new MultiSelectQuestion({
        key: 'preferred_language',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.languagesSrv,
        getValue: (value) => ({ selectedIds: value ? [value] : [] }),
        required: profileSchemaForm.chkIfRequired('preferred_language', 'profiles')
      }),
      new MultiSelectQuestion({
        key: 'status',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.getStatusOptions(),
        getValue: (value) => [this.getStatusOptions().find(el => el.id === value)],
        required: profileSchemaForm.chkIfRequired('status')
      }),
      new MultiSelectQuestion({
        key: 'email_status',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.getEmailAndPhoneStatus(),
        getValue: (value) => [this.getEmailAndPhoneStatus().find(el => el.id === value)]
      }),
      new MultiSelectQuestion({
        key: 'phone_status',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.getEmailAndPhoneStatus(),
        getValue: (value) => [this.getEmailAndPhoneStatus().find(el => el.id === value)]
      }),
      new CheckboxQuestion({
        key: 'fraudulent',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new CheckboxQuestion({
        key: 'terms',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        required: true,
        getValue: (value) => value
      }),
      new CheckboxQuestion({
        key: 'profiling',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new CheckboxQuestion({
        key: 'sms_unsubscribe',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new CheckboxQuestion({
        key: 'email_unsubscribe',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new CheckboxQuestion({
        key: 'push_unsubscribe',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new CheckboxQuestion({
        key: 'paper_free',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      })
    ];

    if (profileSchemaForm?.customFormInputsCfg) {
      profileSchemaForm.customFormInputsCfg.forEach( customInputElement => {
        const customInput = this.toQuestionBase(customInputElement, profileSchemaForm);
        inputsArray.push(customInput);
      });
    }

    this.inputs = inputsArray;
    this.assignFormInputValues(formValues);
    if (formValues['customer']) { this.assignFormInputValues(formValues['customer']); }
    return this.inputs;
  }

  createCustomerProfile(payload: unknown) {
    return this.postResource(payload, 'companies/customers');
  }

  updateCustomerProfile(payload: unknown, _id: string | number) {
    return this.patchResource(payload, `customers/${_id}`);
  }

  preparePayload(form: UntypedFormGroup, id: string, isCreationFormWithUnifiedCustomers: boolean) {

    const phone_prefix = form.value.phone_prefix && form.value.phone_prefix.length ? form.value.phone_prefix[0].rawElement.dial_code : [];
    const document_type = form.value.document_type[0]?.id ? form.value.document_type[0].id : [];
    const gender = form.value.gender && form.value.gender.length ? form.value.gender[0].id : [];
    const status = form.value.status && form.value.status.length ? form.value.status[0].id : [];
    const email_status = form.value.email_status && form.value.email_status.length ? form.value.email_status[0].id : [];
    const phone_status = form.value.phone_status && form.value.phone_status.length ? form.value.phone_status[0].id : [];
    const registration_location_id = form.value.registration_location_id && form.value.registration_location_id.length ? form.value.registration_location_id[0].id : [];
    const preferred_language = form.value.preferred_language && form.value.preferred_language.length ? form.value.preferred_language[0].id : null;
    const accepts_lopd = !!form.value.terms;

    let profile: any = {
      id: id,
      accepts_lopd: accepts_lopd,
      address: form.value.address,
      birth: form.value.birth,
      document_type: document_type,
      dni: form.value.dni,
      family_number: form.value.family_number,
      gender: gender,
      postal_code: form.value.postal_code,
      registration_location_id: registration_location_id,
      state: form.value.state,
      city: form.value.city
    };

    // Merge custom values to nested profiles array object
    const profileCustomvalues = {};
    const copiedCustomValues = {};

    this.inputs.filter(input => input.advanced).forEach(input => {
      let value = form.value[input.key];
      // Get id from selected option for multiselects
      if (value instanceof Array && value.length && value[0].hasOwnProperty('id')) {
        value = value[0].id;
      } else if (value instanceof Array && !value.length) {
        value = null;
      }

      profileCustomvalues[input.key] = value;
      if (input['copyValueTo']?.length) {
        input['copyValueTo'].forEach(_key => copiedCustomValues[_key] = value );
      }
    });

    profile = {...profile, ...profileCustomvalues};
    profile = cleanUndefinedFormValues(profile);

    let payload: any = {
      accepts_lopd: accepts_lopd,
      email_unsubscribe: form.value.email_unsubscribe,
      push_unsubscribe: form.value.push_unsubscribe,
      sms_unsubscribe: form.value.sms_unsubscribe,
      paper_free: form.value.paper_free,
      automate_password: true,
      name: form.value.name,
      phone_prefix: phone_prefix,
      phone: form.value.phone,
      profiles: [profile],
      status: status,
      fraudulent: !!form.value.fraudulent,
      email_status: email_status,
      phone_status: phone_status,
      surname_1: form.value.surname_1,
      surname_2: form.value.surname_2,
      allow_duplicate_email: form.value.allow_duplicate_email,
      allow_duplicate_dni: form.value.allow_duplicate_dni
    };

    if (isCreationFormWithUnifiedCustomers) {
      payload['card_id'] = form.value.card_id || null;
    }

    if (payload.profiles?.length && payload.profiles[0]?.hasOwnProperty('code')) {
      if (!id) { payload['code'] = payload.profiles[0].code; }
      delete payload.profiles[0].code;
    }

    if (Object.keys(copiedCustomValues).length) {
      payload = {...payload, ...copiedCustomValues};
    }

    payload = cleanUndefinedFormValues(payload);

    // Always send e-mail on create and update.
    payload['email'] = form.value.email;

    // GDPR
    payload['terms'] = accepts_lopd;
    payload['profiling'] = !!form.value.profiling;

    payload['preferred_language'] = preferred_language;

    return payload;
  }

  private toQuestionBase(customInputCfg: CustomInputCfg, schemaForm: ProfileSchemaForm): MultiSelectQuestion | CheckboxQuestion | FloatQuestion | TextboxQuestion {

    const customInput = customInputCfg.questionClassType;
    customInput['key'] = customInputCfg.key;
    customInput['title'] = customInputCfg.placeholder;
    customInput['options'] = customInputCfg.options;
    customInput['settings'] = customInputCfg.settings;
    customInput['getValue'] = customInputCfg.getValue;
    customInput['value'] = customInputCfg.value;
    customInput['copyValueTo'] = customInputCfg.copyValueTo;
    customInput['required'] = schemaForm.chkIfRequired(customInputCfg.key, 'profiles');
    customInput['customValidators'] = schemaForm.chkForCustomValidators(customInputCfg.key, 'profiles');
    customInput['type'] = this.setCustomInputType(customInputCfg.type);
    
    // Defaults
    customInput['cssClasses'] = 'form-control input-default';

    // Treat inputs as advanced in order to filter them more easily from component
    customInput['advanced'] = true;

    // Exception for when the key is 'code' we get the label from the title value instead of placeholder
    if (customInput['key'] === 'code') {customInput['title'] = customInputCfg.title; }

    return customInput;
  }

  private setCustomInputType(customInputType: string): string {
    switch (customInputType) {
      case InputType.Select:
        return null;
      case InputType.Boolean:
        return InputType.Checkbox;
      case InputType.Number:
        return InputType.Number;
      default:
        return InputType.Text;
    }
  }

  private assignFormInputValues(formValues): void {
    Object.keys(formValues).map(
      key => {
        const tmpInput = this.inputs.find( input => input.key === key);
        if (tmpInput && tmpInput.getValue ) {
          const value = tmpInput.getValue(formValues[key]);
          if (value && value.hasOwnProperty('selectedIds')) {
            tmpInput.selectedIds = value.selectedIds;
          } else {
            tmpInput.value = value;
          }
        }
    });
  }
}
