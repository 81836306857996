<button class="btn btn-outline-primary no-mrg-btm btn-sm btn-icon left-side"
				type="button"
				[disabled]="(currentStep === 2 && currentMode !== 'create') || disablePrev || preventSave"
				(click)="emitNavEvent('prev_step')">
				<i class="align-arrow ei-left-arrow mrg-right-5"></i>
				{{'resources.plans.buttons.prev_step' | translate}}
</button>

<button *ngIf="currentStep !== stepsNum"
				class="btn btn-blue-lg no-mrg-btm btn-sm pull-right btn-icon"
				type="button"
				[disabled]="disableNext || preventSave"
				(click)="emitNavEvent('next_step')">
				{{'resources.plans.buttons.save_and_continue' | translate}}
				<i class="align-arrow ei-right-arrow"></i>
</button>

<button *ngIf="currentStep === stepsNum"
				class="btn btn-outline-primary no-mrg-btm btn-sm mrg-right-15"
				type="button"
				[disabled]="!forbbidenRoles"
				(click)="emitNavEvent('reject')">
				{{'resources.plans.buttons.reject' | translate}}
</button>

<button *ngIf="currentStep === stepsNum"
				class="btn btn-success no-mrg-btm btn-sm pull-right"
				type="button"
				[disabled]="disableSubmit || preventSave || !forbbidenRoles"
				(click)="emitNavEvent('submit_campaign')">
				{{'resources.plans.buttons.submit_campaign' | translate}}
</button>

<button class="pull-right btn btn-outline-primary no-mrg-btm btn-sm mrg-right-15"
				type="button"
				[disabled]="disableSave || preventSave"
				(click)="emitNavEvent('save_and_exit')">
				{{'resources.plans.buttons.save_and_exit' | translate}}
</button>

<span *ngIf="loading" class="pull-right mrg-right-15 mrg-top-5">
	<i class="fa fa-spinner fa-spin"></i>
</span>
