import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';

@Injectable()
export class ValidationsService { }

export function futureDatesValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const isFutureDate = moment(control.value).isAfter();
    return isFutureDate ? {'validDateRequired': {value: control.value}} : null;
  };
}

export function formGroupEmptyValidator(): ValidatorFn[] {
  return [(control: UntypedFormGroup): ValidationErrors | null => null ];
}

export function multiselectPresenceValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const isValuePresent = (control.value && control.value.length > 0 && !isNullOrUndefined(control.value[0]));
    return !isValuePresent ? {'valueRequired': {value: control.value}} : null;
  };
}

export function multiselectValuesCountValidator(minValuesCount: number, maxValuesCount: number): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const isValuePresent = (control.value && control.value.length > 0 && !isNullOrUndefined(control.value[0]));
    const valuesCount = isValuePresent ? control.value.length : 0;
    return valuesCount < minValuesCount || valuesCount > maxValuesCount ? {'valueRequired': {value: control.value}} : null;
  };
}

export function checkControlValuePresence(valueObj: AbstractControl, errorKey: string): ValidationErrors | null {
  const statusValue = (valueObj.value && valueObj.value.length > 0) ? valueObj.value[0].id : null;
  if (isNullOrUndefined(statusValue)) {
    const responseObj = {};
    responseObj[errorKey] = true;
    return responseObj;
  } else {
    return null;
  }
}

export function checkNumericControlValuePresence(control: AbstractControl, errorKey: string): ValidationErrors | null {
  if (isNullOrUndefined(control.value)) {
    const responseObj = {};
    responseObj[errorKey] = true;
    return responseObj;
  } else {
    return null;
  }
}

export function checkValue1GreaterThanValue2(value1Ctrl: AbstractControl, value2Ctrl: AbstractControl): ValidationErrors | null {

  value1Ctrl.setErrors(null);
  value2Ctrl.setErrors(null);

  if ( parseFloat(value2Ctrl.value) > parseFloat(value1Ctrl.value) ) { return null; }

  value1Ctrl.setValidators([Validators.required]);
  value2Ctrl.setValidators([Validators.required]);
  value1Ctrl.setErrors({'invalid': true});
  value2Ctrl.setErrors({'invalid': true});
  value1Ctrl.markAsDirty();
  value2Ctrl.markAsDirty();

  return {'value2MustBeGreaterThanValue1': true};
}

export function cleanUndefinedFormValues(formValues): Object {
  Object.keys(formValues).forEach(function (key) {
    if (formValues[key] === 'null' ||
        formValues[key] === null ||
        formValues[key] === undefined ||
        (formValues[key] instanceof Array && formValues[key].length === 0)) {
      delete formValues[key];
    }
  });
  return formValues;
}