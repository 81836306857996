<form [formGroup]="form">

  <app-supplier-general-inputs *ngIf="form" [generalInputsForm]="form" [planCampaign]="planCampaign" (selectedIdsLoaded)="onSelectedIdsLoaded()"></app-supplier-general-inputs>

  <hr>

  <!-- UP SELLING -->
  <div class="row">
    <div class="col-md-3">
      <app-question [question]="getInputConfig('upselling')" [form]="form"></app-question>
    </div>
  </div>

  <div class="row mrg-left-15">
    <div class="col-md-3">
      <app-question [question]="getInputConfig('up_campaign_plan_category_id')" [form]="form"></app-question>
    </div>
  </div>

  <div class="row mrg-left-15">
    <div class="col-md-3">
      <app-question (click)="markAsDirty('period_date_from')" [question]="getInputConfig('period_date_from')" [form]="form"></app-question>
    </div>
    <div class="col-md-3">
      <app-question (click)="markAsDirty('period_date_to')" [question]="getInputConfig('period_date_to')" [form]="form"></app-question>
    </div>
  </div>

  <hr>

  <!-- CROSS-SELLING -->
  <div class="row">
    <div class="col-md-3">
      <app-question [question]="getInputConfig('cross')" [form]="form"></app-question>
    </div>
  </div>

  <div class="row mrg-left-15">
    <div class="col">
      <app-question [question]="getInputConfig('scope')" [form]="form" (blur)="handleScope()"></app-question>
    </div>
  </div>

  <div class="row mrg-left-15">
    <div class="col-md-3">
      <app-question [question]="getInputConfig('cross_campaign_plan_category_id')" [form]="form"></app-question>
    </div>
  </div>

  <div class="row mrg-left-15">
    <div class="col-md-3">
      <app-question (multiselectChanged)="handlePeriodChange($event)" [question]="getInputConfig('periods')" [form]="form"></app-question>
    </div>
    <div class="col-md-3" *ngIf="hasFormKeyWithValue('scope', 'feature')">
      <app-question [question]="getInputConfig('taxonomy_slug')" [form]="form" (multiselectChanged)="taxonomySelector()"></app-question>
    </div>
    <div class="col-md-3" *ngIf="hasFormKeyWithValue('scope', 'feature')">
      <app-question [question]="getInputConfig('feature_ids')" [form]="form"></app-question>
    </div>
    <div class="col" *ngIf="hasFormKeyWithValue('scope', 'white_label')">
      <app-white-label-inputs *ngIf="form" [inputs]="inputs" [generalInputsForm]="form" [planCampaign]="planCampaign"></app-white-label-inputs>
    </div>
  </div>

  <div class="row mrg-left-15">
    <div class="col-md-3">
      <app-question (blur)="handleCheckChanged()" [question]="getInputConfig('has_bought')" [form]="form"></app-question>
    </div>
  </div>

  <div class="row mrg-left-15">
    <div class="col-md-3">
      <app-question (blur)="handleCheckChanged()" [question]="getInputConfig('active_clients')" [form]="form"></app-question>
    </div>
  </div>

</form>
