import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { DataTableComponent } from '../../../../../shared/components/data-table/data-table.component';
import { registerLocaleData } from '@angular/common';
import { LocationsService } from '../../../../data-warehouse/locations/locations.service';
import { SegmentsService } from '../../../../segments/segments.service';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { DataTableFilterService } from '../../../../../shared/components/data-table-filter/data-table-filter.service';
import { Subscription } from 'rxjs';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';
import es from '@angular/common/locales/es';
import * as moment from 'moment';
import { DateService } from '../../../../../shared/services/date.service';

@Component({
  selector: 'app-repetition-tab',
  templateUrl: './repetition-tab.component.html',
  styleUrls: ['./repetition-tab.component.css'],
  providers: [RefreshCacheService, LocationsService, SegmentsService]
})

export class RepetitionTabComponent implements OnInit, OnDestroy {

  filters: QuestionBase<any>[];
  defaultFilters: QuestionBase<any>[];
  dateFrom: any;
  dateTo: any;
  total_customers: any;
  data: { totalPeople: number; totalWeighted: number; };
  totalPeople = 0;
  totalWeight = 0;
  totalWeighted: any;
  totals: any;
  segment_categories: any;
  segments: any;
  current_user: any;
  location_ids: any;
  segment_id: any;
  loading: boolean;
  filterConfig: { disableOnInit: boolean, disableOnSubmit: boolean };
  subs$: Subscription[] = [];
  filterSlug = 'repetition-tab';

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  constructor(
    private refreshCacheService: RefreshCacheService,
    private locationsService: LocationsService,
    private translate: TranslateService,
    private filterService: DataTableFilterService,
    private confirmationService: ConfirmationService,
    private segmentsService: SegmentsService,
    private dateService: DateService
  ) {}

  ngOnInit() {
    registerLocaleData(es);
    this.setDefaultDates();
    this.filterConfig = { disableOnInit: true, disableOnSubmit: true };
    this.getRepetitionData();
    this.getSegments();
    this.filters = this.setFilters();
    this.defaultFilters = this.setFilters();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  onFilterHandler(filterFormValue) {
    this.loading = true;

    if (filterFormValue.date_from && filterFormValue.date_to) {
      if (!this.compareDates(filterFormValue)) {
        return;
      }
    }
    this.filterService.loaderStatus.next(false);
    this.getRepetitionData(filterFormValue);
  }

  private alertDateMessage() {
    this.confirmationService.displayAlert(
      this.translate.instant('components.data-table.errors.title_error_date'),
      this.translate.instant('components.data-table.errors.date_bigger_than'),
      'warning').catch(() => {});
  }

  private compareDates(filterFormValue): boolean {
    if (moment(filterFormValue.date_from).isAfter(filterFormValue.date_to)) {
      this.loading = false;
      this.alertDateMessage();
      setTimeout(() => {
        this.filterService.loaderStatus.next(true);
      });
      return false;
    }
    return true;
  }

  private generateSummaryChart(data) {
    if ( this.total_customers ) {
      this.totals = this.total_customers.values.reduce(function(prev, act){
        return [
          prev[0] + act[1],
          prev[1] + act[0] * act[1]
        ];
      }, [0, 0]);

      this.data = {
          totalPeople: this.totalPeople,
          totalWeighted: this.totalWeight / this.totalPeople
      };
    }
  }

  private getRepetitionData( filterFormValue? ) {
    let filtering;
    if (filterFormValue) {
      filtering = {
        country: 'ES',
        date_from: filterFormValue.date_from,
        date_to: filterFormValue.date_to,
        location_ids: filterFormValue.location_ids,
        apply: filterFormValue.apply,
        segment_id: filterFormValue.segment_id,
        tag_id: filterFormValue.tag_id
      };
    } else {
      filtering = {
        country: 'ES',
        date_from: this.dateFrom,
        date_to: this.dateTo
      };
    }
    const params = {
      apiEndPoint: 'analytics/repetition',
      filtering: filtering
    };

    this.loading = true;
    this.filterService.loaderStatus.next(false);

    const totalCustomers$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this.total_customers = reqResponse;
        this.generateSummaryChart(this.total_customers);
        totalCustomers$.unsubscribe();
        this.loading = false;
        this.filterService.loaderStatus.next(true);
      }
    });

    this.subs$.push(totalCustomers$);
  }

  private getSegments() {
    const params = {
      apiEndPoint: 'segments',
      filtering: {
      }
    };
    const segments$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this.segments = reqResponse;
        this.generateSummaryChart(this.segments);
        segments$.unsubscribe();
      }
    });
    this.subs$.push(segments$);
  }

  private setDefaultDates() {
    this.dateFrom = this.dateService.momentStartOfPreviousPeriodTypeAndFormat(1, 'months', 'month', 'YYYY-MM-DD');
    this.dateTo = this.dateService.momentEndOfPreviousPeriodTypeAndFormat(1, 'months', 'month', 'YYYY-MM-DD');
  }

  private setFilters(): QuestionBase<any>[] {
    return [
      new MultiSelectQuestion({
        key: 'location_ids',
        label: 'dashboards.migrations.repetition.filters.locations',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locationsService,
        order: 1
      }),
      new MultiSelectQuestion({
        key: 'segment_id',
        label: 'dashboards.migrations.repetition.filters.segments',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: true},
        dataSource: this.segmentsService,
        order: 2
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_from',
        label: 'dashboards.migrations.repetition.filters.available_from',
        order: 3,
        type: 'date',
        value: this.dateFrom,
        required: true
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_to',
        label: 'dashboards.migrations.repetition.filters.available_to',
        order: 4,
        type: 'date',
        value: this.dateTo,
        required: true
      })
    ];
  }
}
