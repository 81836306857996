<div id="accordion-1" class="accordion panel-group" role="tablist" aria-multiselectable="true">
  <div class="card">
    <div class="card-heading border bottom" role="tab" id="headingOne">
      <h4 class="panel-title">
        <a data-toggle="collapse" data-parent="#accordion-1" href="#collapsePromotions" aria-expanded="true" class="show no-pdd">
          <i class="fa-duotone fa-badge-percent"></i>
          <span class="mrg-left-10" translate>resources.tickets.modal.cards.promotions.title</span>
          <i class="icon ti-arrow-circle-down"></i>
        </a>
      </h4>
    </div>
    <div id="collapsePromotions" class="panel-collapse show">
      <div class="card-block no-pdd-vertical">
        <div class="table-overflow">
          <table class="table table-hover table-sm" aria-hidden="true">
            <thead>
              <tr>
                <th [translate]="'resources.tickets.modal.cards.promotions.table.promotion'"></th>
                <th [translate]="'resources.tickets.modal.cards.promotions.table.line'"></th>
                <th [translate]="'resources.tickets.modal.cards.promotions.table.discount'"></th>
              </tr>
            </thead>
            <ng-container *ngFor="let item of promoReady; let i = index;">
              <tbody>
                <tr *ngIf="promoReady">
                  <td class="col-md-8 elipsis">
                    <a class="text-info cursor-pointer" (click)="openPromotionInNewTab(item.promotion._embedded.promotion.id)">
                      {{item.promotion._embedded.promotion.name}}
                    </a>
                    <span *ngIf="!item.promotion">-</span>
                  </td>
                  <td class="col-md-2">
                    <div class="btn-group">
                      <div class="btn-group">
                        <a type="button"
                          data-toggle="collapse"
                          class="btn btn-default"
                          [attr.data-target]="'#collapsePromos'+i">
                          <i class="fa fa-eye font-size-16"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td class="col-md-2">
                    <a class="text-info cursor-pointer" data-toggle="collapse" [attr.data-target]="'#collapsePromos'+i">
                      <strong>{{item.discount / 10000 | lgcurrency: '1.2-2': this.ticketData.currency}}</strong>
                    </a>
                  </td>
                </tr>
              </tbody>
              <tbody [id]="'collapsePromos'+i" class="collapse">
                <tr *ngFor="let detail of item.children" class="background-green">
                  <td class="col-md-8"></td>
                  <td class="col-md-2">
                    <ng-container *ngIf="detail.line_order">
                      <a class="text-info cursor-pointer" (click)="scroll(detail.line_order)">
                        <strong>{{detail.line_order}}</strong>
                      </a>
                    </ng-container>
                  </td>
                  <td class="col-md-2" *ngIf="!detail._embedded?.product?.name"><span class="badge badge-info"
                      [translate]="'resources.tickets.modal.cards.promotions.table.empty'"></span></td>
                  <td>{{detail.discount / 10000 | lgcurrency: '1.2-2': this.ticketData.currency }}</td>
                </tr>
              </tbody>
            </ng-container>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
