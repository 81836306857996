import { BigqueryJobsStepsBase } from './bigquerystep-base';

export class CustomStep extends BigqueryJobsStepsBase {
  category = 'custom';
  custom: string;

  constructor(options: {} = {}) {
    super(options);
    this.category = options['category'] || 'custom';
    this.custom = options['custom'] || '';
  }

  public getPayload() {
    const payload = super.getPayload();
    const newValues = {custom: this.custom, name: this.custom};
    return {...payload, ...newValues};
  }
}
