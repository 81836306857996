import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourcesService } from '../resources.service';

export interface EurecatPredictionParams {
  discount: number;
  affectation?: 'attribute';
  max_redemptions: number;
  discount_type?: 'cash';
  feature_pks: string[];
  via_none: number;
  via_push: number;
  via_ticket_message: number;
  via_sms: number;
  via_email: number;
  priority: number;
  printable: number;
  non_printable: number;
  start_date: string;
  end_date: string;
  segment_ids: string;
}

export interface RawPredictionParams {
  discount: number;
  max_redemptions: number;
  feature_ids: string[];
  customers_count: number;
  start_date: string;
  end_date: string;
  priority: number;
  printable: boolean;
}

@Injectable()
export class EurecatService extends ResourcesService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getPrediction(params: EurecatPredictionParams): Observable<any> {
    return this.postResource(params, 'redemptions/predict');
  }

  public toEurecatParams(obj: RawPredictionParams): EurecatPredictionParams {
    let translatedParams: EurecatPredictionParams = {
      discount: obj.discount,
      affectation: 'attribute',
      max_redemptions: obj.max_redemptions,
      discount_type: 'cash',
      feature_pks: obj.feature_ids,
      via_none: obj.customers_count,
      via_push: 0,
      via_ticket_message: 0,
      via_sms: 0,
      via_email: 0,
      priority: obj.priority ? obj.priority : 0,
      printable: obj.printable ? obj.customers_count : 0,
      non_printable: obj.printable ? 0 : obj.customers_count,
      start_date: obj.start_date,
      end_date: obj.end_date,
      segment_ids: '1'  // useless (must be populated), response won't change but this is required anyways.
    };
    return translatedParams;
  }
}
