import { TitleCasePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { Option } from "../../../../shared/models/common/option";
import { CustomerAttribute } from "../models/customer-attribute.model";
import { CustomerAttributesOptionsService } from "../services/customer-attributes-options/customer-attributes-options.service";
import { DictionaryService } from "../../../../shared/services/dictionary.service";

export function parsePropertyByOptionName(options: Option[], fieldValue: string): string {
  const option = options.find(option => option.id === fieldValue);
  return option?.name ?? null;
}

export function parseAttributeType(attributeTypeValue: string, optionsService: CustomerAttributesOptionsService, titleCasePipe: TitleCasePipe): string {
  const attributeTypeOptions = optionsService.getAttributeTypeOptions();
  attributeTypeOptions.forEach(option => option.name = titleCasePipe.transform(option.name));
  return parsePropertyByOptionName(attributeTypeOptions, attributeTypeValue);
}

export function parseDataPeriodType(customerAttributesData: CustomerAttribute, optionsService: CustomerAttributesOptionsService, translate: TranslateService): string {
  let dataPeriodTypeName = null;
  let dataPeriodTypeValue = customerAttributesData.dataPeriodType;
  if (dataPeriodTypeValue) {
    const customDataPeriodTypeValues = optionsService.getRelativeCustomOptions().map(option => option.id);
    const isCustomDataPeriodType = !!customDataPeriodTypeValues.includes(dataPeriodTypeValue);
    dataPeriodTypeValue = isCustomDataPeriodType ? 'custom' : dataPeriodTypeValue;
    const name = parsePropertyByOptionName(optionsService.getDataPeriodTypeOptions(), dataPeriodTypeValue);
    dataPeriodTypeName = isCustomDataPeriodType ? parseCustomDataPeriodType(customerAttributesData, optionsService, name, translate) : name;
  }
  return dataPeriodTypeName;
}

export function parseCustomDataPeriodType(customerAttributesData: CustomerAttribute, optionsService: CustomerAttributesOptionsService, customName: string, translate: TranslateService): string {
  const timeAgo = translate.instant('resources.customer_attributes.fields.ago');
  const customRelativeValue = customerAttributesData.relativeValue ?? null;
  const customRelativePeriodName = parsePropertyByOptionName(optionsService.getRelativeCustomOptions(), customerAttributesData.dataPeriodType);
  return customRelativeValue && customRelativePeriodName ?
    `${customName} (${customRelativeValue} ${customRelativePeriodName} ${timeAgo})` : customName;
}

export function parseExecutionType(recurrenceValue: string, optionsService: CustomerAttributesOptionsService): string {
  let executionTypeName = null;
  if (recurrenceValue) {
    const executionTypeValue = recurrenceValue === 'manual' ? recurrenceValue : 'scheduled';
    executionTypeName = parsePropertyByOptionName(optionsService.getExecutionTypeOptions(), executionTypeValue);
  }
  return executionTypeName;
}

export function parseRecurrence(customerAttributesData: CustomerAttribute, optionsService: CustomerAttributesOptionsService): string {
  let recurrenceName = null;
  const recurrenceValue = customerAttributesData.recurrence;
  if (recurrenceValue && recurrenceValue !== 'manual') {
    recurrenceName = parsePropertyByOptionName(optionsService.getRecurrenceOptions(), recurrenceValue);
    if (recurrenceValue === 'specific_months') {
      recurrenceName = parseRecurrenceMonths(customerAttributesData.executionMonths, recurrenceName, optionsService);
    } else if (recurrenceValue === 'hour_interval') {
      const executionHourIntervalValue = customerAttributesData.executionHourInterval ?? '-';
      recurrenceName = `${recurrenceName} (${executionHourIntervalValue})`;
    }
  }
  return recurrenceName;
}

export function parseRecurrenceMonths(executionMonths: string[], recurrenceName: string, optionsService: CustomerAttributesOptionsService): string {
  let recurrenceMonthsName = null;
  if (executionMonths?.length) {
    const months = executionMonths.map(month => optionsService.getMonthsOptions().find(option => option.id === month));
    const monthsNames = months.reduce((string, month, index) => string + `${index ? ', ' : ''}${month.name}`, '');
    recurrenceMonthsName = monthsNames ? `${recurrenceName} (${monthsNames})` : recurrenceName;
  }
  return recurrenceMonthsName;
}

export function parseStatus(status: string): string {
  return status ? status.toLowerCase() : 'new';
}

export function parseSystem(system: boolean, translate: TranslateService, dictionaryService: DictionaryService): string {
  return translate.instant(dictionaryService.getNameByKeyAndId('customer_attribute_origin', system.toString()));
}

export function parseBooleanParameter(booleanParam: boolean, translate: TranslateService): string {
  return translate.instant(booleanParam ? 'common.yes' : 'common.no');
}