<div class="row">
  <div class="col-lg-6">
    <div class="card" *ngIf="state === 'sales'">

      <app-loader *ngIf="loaders.salesBlock" />

      <div class="portlet">
        <ul class="portlet-item">
          <li>
            <button (click)="showActivities()"
                    type="button"
                    class="btn btn-icon btn-flat btn-rounded"
                    card-portlet-refresh>
              <i class="ei-bars"></i>
            </button>
          </li>
        </ul>
      </div>
      <div class="card-heading">
        <h4 class="card-title" [translate]="'resources.sales.cards.sales'"></h4>
      </div>
      <div class="card-block no-pdd-vertical">
        <div class="row">
          <div class="col">
            <p class="text-bold mrg-btm-5" [translate]="'resources.sales.fields.totals'"></p>
            <i class="tooltip-position ti-info-alt mrg-top-3 mrg-left-5 total-sales-position"
                tooltipPosition="right"
                pTooltip="{{ 'dashboards.sales.tooltips.total_sales_text' | translate }}">
            </i>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="total_sales">{{total_sales.total | lgcurrency: '1.0-0' }}</h1>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="!total_sales">-</h1>
            <p class="text-semibold text-info" *ngIf="total_sales_last_year">
              {{lastYearNum}}: {{returnZeroIfNil(total_sales_last_year.total) | lgcurrency: '1.0-0' }}
            </p>
            <p class="text-semibold text-info" *ngIf="!total_sales_last_year">
              {{lastYearNum}}
            </p>
          </div>
          <div class="col">
            <p class="text-bold mrg-btm-5" [translate]="'resources.sales.fields.total_identified'"></p>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="identified_sales">{{identified_sales.total | lgcurrency: '1.0-0' }}</h1>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="!identified_sales">-</h1>
            <p class="text-semibold text-info" *ngIf="identified_sales_last_year">
              {{lastYearNum}}: {{returnZeroIfNil(identified_sales_last_year.total) | lgcurrency: '1.0-0' }}
            </p>
            <p class="text-semibold text-info" *ngIf="!identified_sales_last_year">
              {{lastYearNum}}
            </p>
          </div>
          <div class="col">
            <p class="text-bold mrg-btm-5" [translate]="'resources.sales.fields.percentage_identified'"></p>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="identified_sales && total_sales">
              {{returnZeroIfNil(identified_sales.total / total_sales.total * 100) | number: '1.0-0' }}%
            </h1>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="!(identified_sales && total_sales)">-</h1>
            <p class="text-semibold text-info" *ngIf="identified_sales_last_year && total_sales_last_year">
              {{lastYearNum}}: {{returnZeroIfNil(identified_sales_last_year.total / total_sales_last_year.total * 100) | number: '1.0-0' }}%
            </p>
            <p class="text-semibold text-info" *ngIf="!(identified_sales_last_year && total_sales_last_year)">
              {{lastYearNum}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card" *ngIf="state === 'activities'">
      <div class="portlet">
        <ul class="portlet-item">
          <li>
            <button (click)="showSales()"
                    class="btn btn-icon btn-flat btn-rounded"
                    card-portlet-refresh>
              {{currencySymbol}}
            </button>
          </li>
        </ul>
      </div>
      <div class="card-heading">
        <h4 class="card-title" [translate]="'resources.sales.cards.activities'"></h4>
      </div>
      <div class="card-block no-pdd-vertical">
        <div class="row">
          <div class="col">
            <p class="text-bold mrg-btm-5" [translate]="'resources.sales.fields.totals'"></p>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="total_sales">{{total_sales.num_activities | number: '1.0-0'}}</h1>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="!total_sales">{{0 | number: '1.0-0'}}</h1>
            <p class="text-semibold text-info" *ngIf="total_sales_last_year">
              {{lastYearNum}}: {{total_sales_last_year.num_activities | number: '1.0-0'}}
            </p>
            <p class="text-semibold text-info" *ngIf="!total_sales_last_year">
              {{lastYearNum}}: {{0 | number: '1.0-0'}}
            </p>
          </div>
          <div class="col">
            <p class="text-bold mrg-btm-5" [translate]="'resources.sales.fields.total_identified'"></p>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="identified_sales">{{identified_sales.num_activities | number: '1.0-0'}}</h1>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="!identified_sales">{{0 | number: '1.0-0'}}</h1>
            <p class="text-semibold text-info" *ngIf="identified_sales_last_year">
              {{lastYearNum}}: {{identified_sales_last_year.num_activities | number: '1.0-0'}}
            </p>
            <p class="text-semibold text-info" *ngIf="!identified_sales_last_year">
              {{lastYearNum}}: {{0 | number: '1.0-0'}}
            </p>
          </div>
          <div class="col">
            <p class="text-bold mrg-btm-5" [translate]="'resources.sales.fields.percentage_identified'"></p>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="identified_sales && total_sales">
              {{returnZeroIfNil(identified_sales.num_activities / total_sales.num_activities * 100) | number: '1.0-0' }}%
            </h1>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="!(identified_sales && total_sales)">
              {{0 | number: '1.0-0' }}%
            </h1>
            <p class="text-semibold text-info" *ngIf="identified_sales_last_year && total_sales_last_year">
              {{lastYearNum}}: {{returnZeroIfNil(identified_sales_last_year.num_activities / total_sales_last_year.num_activities * 100) | number: '1.0-0' }}%
            </p>
            <p class="text-semibold text-info" *ngIf="!(identified_sales_last_year && total_sales_last_year)">
              {{lastYearNum}}: {{0 | number: '1.0-0' }}%
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-3">
    <div class="card" *ngIf="average === 'ticketAverage'">
      <div class="portlet">
        <ul class="portlet-item">
          <li>
            <button (click)="showSpendingAverage()"
                    class="btn btn-icon btn-flat btn-rounded"
                    card-portlet-refresh>
              <i class="ei-bars"></i>
            </button>
          </li>
        </ul>
      </div>
      <div class="card-heading">
        <h4 class="card-title" [translate]="'resources.sales.cards.card_title2'"></h4>
      </div>
      <div class="card-block no-pdd-vertical">
        <app-loader *ngIf="loaders.ticketAverageBlock" />
        <div class="row">
          <div class="col">
            <p class="text-bold mrg-btm-5" [translate]="'resources.sales.fields.unidentified'"></p>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="ticket_average">
              {{returnZeroIfNil(ticket_average.total / ticket_average.num_activities) | lgcurrency: '1.2-2'}}
            </h1>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="!ticket_average">-</h1>
            <p class="text-semibold text-info">
              {{returnZeroIfNil(products_total_unidentified) | number: '1.1-1'}}
              <span [translate]="'resources.sales.fields.products'"></span>
            </p>
          </div>
          <div class="col">
            <p class="text-bold mrg-btm-5" [translate]="'resources.sales.fields.identified'"></p>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="identified_tickets">
              {{returnZeroIfNil(identified_tickets.total / identified_tickets.num_activities) | lgcurrency: '1.2-2'}}
            </h1>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="!identified_tickets">-</h1>
            <p class="text-no-wrap text-semibold text-info">
              {{returnZeroIfNil(products_total) | number: '1.1-1'}}
              <span [translate]="'resources.sales.fields.products'"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card" *ngIf="average === 'spendingAverage'">
      <div class="portlet">
        <ul class="portlet-item">
          <li>
            <button (click)="showTicketAverage()"
                    class="btn btn-icon btn-flat btn-rounded"
                    card-portlet-refresh>
              <i class="ei-ticket"></i>
            </button>
          </li>
        </ul>
      </div>
      <div class="card-heading">
        <h4 class="card-title" [translate]="'resources.sales.fields.average_spending'"></h4>
      </div>
      <div class="card-block no-pdd-vertical">
        <app-loader *ngIf="loaders.customersBlock" />
        <div class="row">
          <div class="col">
            <p class="text-bold mrg-btm-5" [translate]="'resources.sales.fields.total_spend_average'"></p>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="identified_sales && clients_total_active.customers">
              <ng-container *ngIf="!numIsNaN( identified_sales.total / clients_total_active.customers )">
                {{ identified_sales.total / clients_total_active.customers | lgcurrency: '1.2-2' }}
              </ng-container>
              <ng-container *ngIf="numIsNaN( identified_sales.total / clients_total_active.customers )">
                {{ 0 | lgcurrency: '1.2-2' }}
              </ng-container>
            </h1>
            <br>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title" [translate]="'resources.sales.cards.card_title3'"></h4>
      </div>
      <div class="card-block no-pdd-vertical">
        <app-loader *ngIf="loaders.customersBlock" />
        <div class="row">
          <div class="col">
            <p class="text-bold mrg-btm-5" [translate]="'resources.sales.fields.signups'"></p>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="clients_count_signups">{{clients_count_signups.total | number: '1.0-0'}}</h1>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="!clients_count_signups">-</h1>
            <p class="text-semibold text-info">
              {{ 'common.total' | translate }} <span *ngIf="clients_count">{{clients_count.total | number: '1.0-0'}}</span>
            </p>
          </div>
          <div class="col">
            <p class="text-bold mrg-btm-5" [translate]="'resources.sales.fields.actives'"></p>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="clients_total_active && clients_total_active.customers">
              {{clients_total_active.customers | number: '1.0-0'}}
            </h1>
            <h1 class="no-mrg-vertical font-size-18" *ngIf="!(clients_total_active && clients_total_active.customers)">
              -
            </h1>
            <p class="text-semibold text-info">
              {{ 'common.total' | translate }} <span *ngIf="clients_active">{{clients_active.customers | number: '1.0-0'}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-block">
        <i class="tooltip-position ti-info-alt mrg-top-8 mrg-left-5 positioning" tooltipPosition="left" pTooltip="{{ 'dashboards.sales.tooltips.sales_text' | translate }}"></i>
        <div class="tab-info">
          <app-loader *ngIf="loaders.salesGraph" />
          <div #areaChartTestExample></div>
        </div>
      </div>
    </div>
  </div>
</div>
