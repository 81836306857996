import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PartnersService } from "../partners.service";
import { Partner } from "../partner";
import { HttpErrorResponse } from "@angular/common/http";
import { ConfirmationService } from "../../../../shared/services/confirmation.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-show-partners',
  templateUrl: './partner-detail.component.html',
  styleUrls: ['./partner-detail.component.scss']
})

export class PartnerDetailComponent implements OnInit {
  partnerId = this.route.snapshot.params.id;
  partner: Partner;
  missingImg: boolean = false;

  @Output('updateEmitter') updateEmitter: EventEmitter<any>  = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private partnersService: PartnersService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private router: Router
  ){}

  ngOnInit(): void {
    this.getRewardsById();
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getRewardsById() {
    this.partnersService.getById(this.partnerId).subscribe(
      (partner: Partner) => this.handleSuccessfulResponse(partner),
      (err: HttpErrorResponse) => this.handleErrorResponse(err)
    )
  }

  private handleSuccessfulResponse(partner: Partner) {
    this.partner = partner;
    this.missingImg = partner.thumbnail.indexOf('thumbnail/missing') >= 0;
    this.updateEmitter.emit(['', { outlets: { modal: ['update', 'partners', this.partner.id]}}]);
  }

  private handleErrorResponse(err: HttpErrorResponse) {
    this.confirmationService.displayErrorAlert(
      this.translate.instant('common.error'),
      err.error.error
    )
    this.closeModal();
  }
}