import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomersService } from '../../../customers/customers.service';
import { TranslateService } from '@ngx-translate/core';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { ClubIndicatorsData } from './club-indicators';
import { isNullOrUndefined } from 'util';
import { DataTableFilterService } from '../../../../../shared/components/data-table-filter/data-table-filter.service';
import { DecimalPipe, getCurrencySymbol } from '@angular/common';
import { DateService } from '../../../../../shared/services/date.service';
import { ProfileService } from '../../../../../profiles/profile.service';

@Component({
  selector: 'app-club-indicators',
  templateUrl: './club-indicators-tab.component.html',
  styleUrls: ['./club-indicators-tab.component.css'],
  providers: [DecimalPipe]
})

export class ClubIndicatorsTabComponent implements OnInit, OnDestroy {

  loading: boolean;
  subs$: Subscription[];
  clubIndicatorsData: ClubIndicatorsData;
  filter: {titleRange1: string, titleRange2: string};
  isFilteringBySegment: boolean;
  currencySymbol: string;
  onSearch: boolean;

  constructor(
    private customersService: CustomersService,
    private translate: TranslateService,
    private refreshCacheService: RefreshCacheService,
    private filterService: DataTableFilterService,
    private decimalPipe: DecimalPipe,
    private dateService: DateService,
    private profileService: ProfileService
  ){
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
    this.isFilteringBySegment = false;
    this.subs$ = [];
    this.clubIndicatorsData = new ClubIndicatorsData();
  }

  ngOnInit() {
    this.onSearch = false;
    this.setTableTitles();
    const filterChange$ = this.customersService.applyFilters$.subscribe(
      (filterValues) => {
        this.getClubIndicators(filterValues);
        this.onSearch = true;
      }
    );
    this.subs$.push(filterChange$);
    setTimeout(() => this.filterService.loaderStatus.next(true));
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach( s$ => s$.unsubscribe() ); }
  }

  private getClubIndicators(filterValues) {

    this.clubIndicatorsData = new ClubIndicatorsData();
    this.setTableTitles();

    this.loading = true;
    this.filterService.loaderStatus.next(false);

    let initFilters = {...filterValues};

    if ( Object.keys(initFilters).length === 0 ) {
      const dateFrom = this.dateService.momentStartOfPreviousPeriodTypeAndFormat(1, 'months', 'month', 'YYYY-MM-DD');
      const dateTo = this.dateService.momentEndOfPreviousPeriodTypeAndFormat(1, 'months', 'month', 'YYYY-MM-DD');
      initFilters = { date_from: dateFrom, date_to: dateTo };
    }

    const defaultFilters = { ...initFilters };

    const dateFromPreviousPeriod = this.dateService.samePeriodLastYear( defaultFilters.date_from );
    const dateToPreviousPeriod = this.dateService.samePeriodLastYear( defaultFilters.date_to );
    const segmentId = defaultFilters['segment_id'];
    const locationIds = defaultFilters['location_ids'];

    // filters
    const filters = { ...{ date_from: defaultFilters.date_from, date_to: defaultFilters.date_to } };
    const totalSalesFilters = { ...{ date_from: defaultFilters.date_from, date_to: defaultFilters.date_to } };
    const filtersPreviousPeriod = { ...{ date_from: dateFromPreviousPeriod, date_to: dateToPreviousPeriod } };
    const totalSalesfiltersPreviousPeriod = { ...{ date_from: dateFromPreviousPeriod, date_to: dateToPreviousPeriod } };

    this.isFilteringBySegment = !isNullOrUndefined(segmentId);

    if ( this.isFilteringBySegment ) {
      filters['segment_id'] = segmentId;
      filtersPreviousPeriod['segment_id'] = segmentId;
    }

    if ( !isNullOrUndefined(locationIds) ) {
      filters['location_ids'] = locationIds;
      totalSalesFilters['location_ids'] = locationIds;
      filtersPreviousPeriod['location_ids'] = locationIds;
      totalSalesfiltersPreviousPeriod['location_ids'] = locationIds;
    }

    // filter column names
    const currentDateRangeLabel = this.dateService.momentFormat(filters.date_from, 'DD/MM/YYYY') + ' - ' + this.dateService.momentFormat(filters.date_to, 'DD/MM/YYYY');
    const previousDateRangeLabel = this.dateService.momentFormat(dateFromPreviousPeriod, 'DD/MM/YYYY') + ' - ' + this.dateService.momentFormat(dateToPreviousPeriod, 'DD/MM/YYYY');
    this.filter = { titleRange1: currentDateRangeLabel, titleRange2: previousDateRangeLabel };

    const params = { apiEndPoint: 'analytics/total_sum', filtering: totalSalesFilters };
    const paramsPrev = { apiEndPoint: 'analytics/total_sum', filtering: totalSalesfiltersPreviousPeriod };
    const paramsIdentified = { apiEndPoint: 'analytics/total_sum', filtering: { ...filters, ...{ identified: true } } };
    const paramsPrevIdentified = { apiEndPoint: 'analytics/total_sum', filtering: { ...filtersPreviousPeriod, ...{ identified: true } } };
    const paramsClients = { apiEndPoint: 'analytics/clients_active', filtering: { ...filters, ...{ nodates: false } } };
    const paramsClientsPrev = { apiEndPoint: 'analytics/clients_active', filtering: { ...filtersPreviousPeriod, ...{ nodates: false } } };
    const paramsClientsCountParams = { apiEndPoint: `analytics/clients_count`, filtering: {...filters, ...{ imported: false, nodates: false } } };
    const paramsClientsCountPrevParams = { apiEndPoint: `analytics/clients_count`, filtering: { ...filtersPreviousPeriod, ...{ imported: false, nodates: false } } };
    const unIdticketsParams = { apiEndPoint: `analytics/total_sum`, filtering: { ...filters, ...{ identified: false } } };
    const unIdticketsPrevParams = { apiEndPoint: `analytics/total_sum`, filtering: { ...filtersPreviousPeriod, ...{ identified: false } } };
    const massificationParams = { apiEndPoint: 'analytics/clients_redemptions', filtering: { ...filters } };
    const massificationParamsPrev = { apiEndPoint: 'analytics/clients_redemptions', filtering: { ...filtersPreviousPeriod } };

    this.enqueuePollingRequest(params, 'totalSales', { currentDateRange: 'total', numActivities: 'num_activities' });
    this.enqueuePollingRequest(paramsPrev, 'totalSales', { previousDateRange: 'total', numActivitiesPreviousPeriod: 'num_activities' });
    this.enqueuePollingRequest(paramsIdentified, 'totalIdentifiedSales', { currentDateRange: 'total', numActivities: 'num_activities' });
    this.enqueuePollingRequest(paramsPrevIdentified, 'totalIdentifiedSales', { previousDateRange: 'total', numActivitiesPreviousPeriod: 'num_activities'});
    this.enqueuePollingRequest(paramsClients, 'activeCustomers', { currentDateRange: 'customers' });
    this.enqueuePollingRequest(paramsClientsPrev, 'activeCustomers', { previousDateRange: 'customers' });
    this.enqueuePollingRequest(paramsClientsCountParams, 'signups', { currentDateRange: 'total' });
    this.enqueuePollingRequest(paramsClientsCountPrevParams, 'signups', { previousDateRange: 'total' });
    this.enqueuePollingRequest(unIdticketsParams, 'unidentifiedTickets', { currentDateRange: 'num_activities' });
    this.enqueuePollingRequest(unIdticketsPrevParams, 'unidentifiedTickets', { previousDateRange: 'num_activities' });
    this.enqueuePollingRequest(massificationParams, 'masification', { rawDateRange: 'customers' });
    this.enqueuePollingRequest(massificationParamsPrev, 'masification', { rawPreviousDateRange: 'customers' });
  }

  private enqueuePollingRequest(requestCfg, mainPropery, mappedProps ) {
    const req$ = this.refreshCacheService.getExpensiveData(requestCfg).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this.assignRawDataNumberOrString(reqResponse, mainPropery, mappedProps);
        this.refresh();
        req$.unsubscribe();
      }
    });
    this.subs$.push(req$);
  }

  private assignRawDataNumberOrString(reqResponse, mainPropery, mappedProps): any {
    Object.keys(mappedProps).forEach( key => {
      let dataValue = reqResponse[mappedProps[key]];
      if (dataValue) {
        // Value can be a string mumber with scientific notation
        if (typeof dataValue === 'string' && dataValue.indexOf('E') >= 0) {
          const numberToString = this.decimalPipe.transform(dataValue, '1.2-2', 'es').replace(/\./g, '');
          const stringToFloat = parseFloat( numberToString ) || 0;
          this.clubIndicatorsData[mainPropery][key] = stringToFloat;
        // Value can be a string mumber with dots (NO scientific notation) that reflects decimals or in signup case it reflects thousands
        } else if (typeof dataValue === 'string' && dataValue.indexOf('E') < 0) {
          if ( mainPropery === 'signups' ) {
            dataValue = dataValue.replace(/\./g, '');
          }
          this.clubIndicatorsData[mainPropery][key] = dataValue;
        // Value can be a number (new massification endpoint)
        } else {
          this.clubIndicatorsData[mainPropery][key] = dataValue;
        }
      } else {
        this.clubIndicatorsData[mainPropery][key] = 0;
      }
    });
  }

  private refresh() {
    const salesReady = this.clubIndicatorsData.salesReady();
    const eurosByCustomerReady = this.clubIndicatorsData.eurosByCustomerReady();
    const frequencyReady = this.clubIndicatorsData.frequencyReady();
    const totalIdentifiedReady = this.clubIndicatorsData.totalIdentifiedReady();
    const massificationReady = this.clubIndicatorsData.massificationReady();

    if ( salesReady ) { this.clubIndicatorsData.calculateIdentifiedSalesPercentage(); }
    if ( eurosByCustomerReady ) { this.clubIndicatorsData.calculateEurosByCustomer(); }
    if ( frequencyReady ) { this.clubIndicatorsData.calculateFrequency(); }
    if ( totalIdentifiedReady ) { this.clubIndicatorsData.calculateTicketAverage(); }
    if ( massificationReady ) { this.clubIndicatorsData.calculateMassification(); }

    if ( salesReady && eurosByCustomerReady && frequencyReady && totalIdentifiedReady && massificationReady ) {
      this.loading = false;
      this.filterService.loaderStatus.next(true);
    }
  }

  private setTableTitles() {
    this.clubIndicatorsData.totalSales.title = this.translate.instant('dashboards.club.tabs.club_indicators.fields.total-sales');
    this.clubIndicatorsData.totalIdentifiedSales.title = this.translate.instant('dashboards.club.tabs.club_indicators.fields.total-identified-sales');
    this.clubIndicatorsData.totalIdentifiedSalePercentage.title = this.translate.instant('dashboards.club.tabs.club_indicators.fields.total-identified-sale-percentage');
    this.clubIndicatorsData.activeCustomers.title = this.translate.instant('dashboards.club.tabs.club_indicators.fields.active-customers');
    this.clubIndicatorsData.unidentifiedTickets.title = this.translate.instant('dashboards.club.tabs.club_indicators.fields.unidentified-tickets');
    this.clubIndicatorsData.eurosByCustomer.title = this.translate.instant('dashboards.club.tabs.club_indicators.fields.euros-by-customer', {currencySymbol: this.currencySymbol});
    this.clubIndicatorsData.frequency.title = this.translate.instant('dashboards.club.tabs.club_indicators.fields.frequency');
    this.clubIndicatorsData.identifiedTicketAverage.title = this.translate.instant('dashboards.club.tabs.club_indicators.fields.identified-ticket-average');
    this.clubIndicatorsData.signups.title = this.translate.instant('dashboards.club.tabs.club_indicators.fields.signups');
    this.clubIndicatorsData.masification.title = this.translate.instant('dashboards.club.tabs.club_indicators.fields.masification');
  }
}
