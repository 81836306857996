import { isNullOrUndefined, getResourceProp } from '../../../shared/utils/common.utils';

export interface EmbeddedCampaign {
  actions: PlanRoiCampaignDetailAction[];
  budgetConsumed: number;
  budgetDeliveredVsForecasted: number;
  budgetExpense: number;
  costVsBudget: number;
  customerRedemptions: number;
  customersCount: number;
  deliveredCoupons: number;
  deliveredPercentage: number;
  discountCost: number;
  forecastBudgetConsumed: number;
  id: number;
  incRatio: number;
  incSales: number;
  lgCost: number;
  maxRedemptionsPerCoupon: number;
  name: string;
  redemptions: number;
  redemptionsPercentage: number;
  status: string;
  uniqueRedemptionsPercentage: number;
}

export class PlanRoiCampaignGroup {

  actions: PlanRoiCampaignDetailAction[];
  campaigns: EmbeddedCampaign[];
  category: string;
  daysPassed: number;
  rowsPerCategory: number;
  subtotal: PlanRoiCampaignSubtotal;
  sumUniqueRedemp: number;
  type: string;
  planDuration: number;

  constructor(cfg: Object) {
    this.type = cfg['type'];
    this.category = cfg['category'];
    this.daysPassed = cfg['daysPassed'];
    this.planDuration = cfg['planDuration'];
    this.actions = cfg['campaigns'].map(el => new PlanRoiCampaignDetailAction(el, this.daysPassed, this.planDuration));
    this.campaigns = this.parseCampaigns();
    this.rowsPerCategory = this.actions.length + this.campaigns.length + 1; //actions + 1 row per each campaign + 1 subtotal row
    this.subtotal = new PlanRoiCampaignSubtotal(this.actions.filter(this.onlyCompleteOrDelivered));
  }

  private parseCampaigns(): EmbeddedCampaign[] {
    const uniqCampaignIds = this.actions.map(action => action.embeddedCampaign.id).filter(this.onlyUnique);
    return uniqCampaignIds.map(id => {
      const groupedActions = this.actions.filter(action => action.embeddedCampaign.id === id);
      const customersCount = groupedActions.reduce((acc, obj) => acc + (obj.customersCount || 0), 0);
      const deliveredCoupons =  groupedActions.reduce((acc, obj) => acc + (obj.deliveredCoupons || 0), 0);
      const deliveredPercentage = customersCount === 0 ? 0 : deliveredCoupons / customersCount;
      const redemptions = groupedActions.reduce((acc, obj) => acc + (obj.redemptions || 0), 0);
      this.sumUniqueRedemp = groupedActions.reduce((acc, obj) => acc + (obj.uniqueRedemptions || 0), 0);
      const costVsBudget = groupedActions.reduce((acc, obj) => acc + (obj.costVsBudget || 0), 0);
      const customerRedemptions = redemptions / this.sumUniqueRedemp;
      const maxRedemptionsPerCoupon = groupedActions.reduce((acc, obj) => acc + (obj.maxRedemptionsPerCoupon || 0), 0);
      const redemptionsPercentage = deliveredCoupons ? redemptions / deliveredCoupons : 0;
      const uniqueRedemptionsPercentage = deliveredCoupons ? this.sumUniqueRedemp / deliveredCoupons : 0;
      const budgetExpense = groupedActions.reduce((acc, obj) => acc + (obj.budgetExpense || 0), 0);
      const discountCost = groupedActions.reduce((acc, obj) => acc + (obj.discountCost || 0), 0);
      const lgCost =  groupedActions.reduce((acc, obj) => acc + (obj.lgCost || 0), 0);
      const incSales = groupedActions.reduce((acc, obj) => acc + (obj.incSales || 0), 0);
      const incRatio = (discountCost + lgCost) === 0 ? 0 : incSales / (discountCost + lgCost);
      const forecastBudgetConsumed = groupedActions.reduce((acc, obj) => acc + (obj.forecastBudgetConsumed || 0), 0);

      const budgetConsumed = discountCost / budgetExpense;
      const budgetDeliveredVsForecasted =  forecastBudgetConsumed / budgetExpense;

      return {
        actions: groupedActions,
        budgetConsumed: budgetConsumed,
        budgetExpense: budgetExpense,
        costVsBudget: costVsBudget,
        customerRedemptions: customerRedemptions,
        customersCount: customersCount,
        deliveredCoupons: deliveredCoupons,
        deliveredPercentage: deliveredPercentage,
        discountCost: discountCost,
        id: id,
        incRatio: incRatio,
        incSales: incSales,
        lgCost: lgCost,
        maxRedemptionsPerCoupon: maxRedemptionsPerCoupon,
        name: groupedActions[0].embeddedCampaign.name,
        redemptions: redemptions,
        redemptionsPercentage: redemptionsPercentage,
        status: groupedActions[0].embeddedCampaign.status,
        uniqueRedemptionsPercentage: uniqueRedemptionsPercentage,
        forecastBudgetConsumed: forecastBudgetConsumed,
        budgetDeliveredVsForecasted: budgetDeliveredVsForecasted
      };
    });
  }

  private onlyUnique(value: any, index: number, self: any[]) {
    return self.indexOf(value) === index;
  }

  private onlyCompleteOrDelivered(action: PlanRoiCampaignDetailAction, index: number, self: object[]) {
    return ['completed', 'delivered'].includes(action.embeddedCampaign.status);
  }
}

export class PlanRoiCampaignDetailAction {

  actionId: number;
  budgetConsumed: any;
  budgetDeliveredVsForecasted: number;
  budgetExpense: any;
  campaignId: number;
  controlGroup: number;
  customerRedemptions: number;
  costVsBudget: number;
  customersCount: number;
  deliveredCoupons: number;
  deliveredPercentage: number;
  discountCost: number;
  errors: string[];
  forecastBudgetConsumed: number;
  incRatio: number;
  incSales: number;
  lgCost: number;
  maxRedemptionsPerCoupon: number;
  name: string;
  redemptions: number;
  redemptionsPercentage: number;
  reminderIds: number[];
  reminders: any;
  segmentName: string;
  uniqueRedemptions: number;
  uniqueRedemptionsPercentage: number;
  embeddedCampaign: {
    id: number,
    name: string,
    status: string,
    couponId: number,
    couponRaw: object,
    campaignHistoryId: number
  };

  duration: number;
  significance: number;
  exhaustedVouchers: number;
  exhaustedVouchersRatio: number;
  totalVouchersDelivered: number;
  redemptionsFromTotalVouchersDelivered: number;
  forecastedIncrementalSales: number;
  forecastedIncrementalRatio: number;
  incrementalSalesVsEstimation: number;
  incrementalRatioVsEstimation: number;
  channel: string;
  deliveryCost: number;
  emailDelivered: number;
  emailOrTotal: number;
  emailOrUnique: number;
  emailClicks: number;
  emailErrors: number;
  redemptionsVsOr: number;
  smsDelivered: number;
  redemptionsVsSmsDelivered: number;
  smsErrors: number;
  smsUnsubscribe: number;
  netIncrementalRatio: number;
  reminderCost: number;

  constructor(cfg: Object, daysPassed?: number, planDuration?: number) {
    this.embeddedCampaign = {
      id: cfg['_embedded']['campaign_plan']['id'],
      name: cfg['_embedded']['campaign_plan']['name'],
      status: cfg['_embedded']['campaign_plan']['status'],
      couponId: cfg['_embedded']['coupon']['id'],
      couponRaw: cfg['_embedded']['coupon'],
      campaignHistoryId: cfg['_embedded']['campaign_history'] && cfg['_embedded']['campaign_history']['id'] ? cfg['_embedded']['campaign_history']['id'] : null
    };
    this.actionId = cfg['id'];
    this.name = cfg['_embedded']['campaign_plan']['name'];
    this.campaignId = cfg['campaign_id'];
    this.segmentName = cfg['name'];
    this.customersCount = this.getPropValue('customers', cfg);
    this.errors = cfg['roi']['errors'];
    this.controlGroup = this.getPropValue('control_group', cfg);
    this.channel = getResourceProp(cfg, 'configuration', 'campaign_via', 'none');
    switch (this.channel) {
      case 'email':
        this.deliveredCoupons = this.getPropValue('impacted_by_email', cfg);
        this.deliveredPercentage = this.deliveredCoupons / this.customersCount;
        break;
      case 'none':
        this.deliveredCoupons = this.getPropValue('delivered_vouchers', cfg);
        this.deliveredPercentage = this.getPropValue('delivered_ratio', cfg);
        break;
      case 'sms':
        this.deliveredCoupons = this.getPropValue('impacted_by_sms', cfg);
        this.deliveredPercentage = this.deliveredCoupons / this.customersCount;
        break;
    }
    this.redemptions = this.getPropValue('total_redemptions', cfg);
    this.customerRedemptions = this.getPropValue('redemptions_per_customer', cfg);
    this.maxRedemptionsPerCoupon = this.getPropValue('max_redemption_per_coupon', cfg, 'forecast');
    this.budgetExpense = this.getPropValue('discount_cost', cfg, 'forecast');
    this.redemptionsPercentage = this.getPropValue('total_redemptions_ratio', cfg);
    this.uniqueRedemptionsPercentage = this.getPropValue('unique_redemptions_ratio', cfg);
    this.uniqueRedemptions = this.getPropValue('unique_redemptions', cfg);
    this.discountCost = this.getPropValue('discount_cost', cfg);
    this.lgCost = this.getPropValue('loyal_guru_cost', cfg);
    this.incSales = this.getPropValue('incremental', cfg);
    this.incRatio = this.getPropValue('incremental_ratio', cfg);
    this.reminderIds = cfg['_embedded']['reminders'];
    this.duration = this.getPropValue('duration', cfg);
    this.significance = this.getPropValue('significance', cfg);
    this.exhaustedVouchers = this.getPropValue('exhausted_vouchers', cfg);
    this.exhaustedVouchersRatio = this.getPropValue('exhausted_vouchers_ratio', cfg);
    this.totalVouchersDelivered = this.getPropValue('total_vouchers_delivered', cfg);
    this.redemptionsFromTotalVouchersDelivered = this.getPropValue('redemptions_from_total_vouchers_delivered', cfg);
    this.forecastedIncrementalSales = this.getPropValue('forecasted_incremental_sales', cfg);
    this.forecastedIncrementalRatio = this.getPropValue('forecasted_incremental_ratio', cfg);
    this.incrementalSalesVsEstimation = this.getPropValue('incremental_sales_vs_estimation', cfg);
    this.incrementalRatioVsEstimation = this.getPropValue('incremental_ratio_vs_estimation', cfg);
    this.deliveryCost = parseFloat(this.getPropValue('delivery_cost', cfg));
    this.emailDelivered = this.getPropValue('impacted_by_email', cfg);
    this.emailOrTotal = this.getPropValue('email_open_rate', cfg);
    this.emailClicks = this.getPropValue('email_clicks', cfg);
    this.emailErrors = this.getPropValue('email_errors', cfg);
    this.redemptionsVsOr = this.getPropValue('redemptions_vs_email_open_rate', cfg);
    this.smsDelivered = this.getPropValue('sms_delivered', cfg);
    this.redemptionsVsSmsDelivered = this.getPropValue('redemptions_vs_sms_delivered', cfg);
    this.smsErrors = this.getPropValue('sms_errors', cfg);
    this.smsUnsubscribe = this.getPropValue('sms_unsubscribes', cfg);
    this.netIncrementalRatio = this.getPropValue('net_incremental_ratio', cfg);
    this.reminderCost = this.getPropValue('reminders_cost', cfg);
    this.costVsBudget = this.getPropValue('cost_vs_budget', cfg);
    this.budgetConsumed =  this.discountCost / this.budgetExpense;
    this.forecastBudgetConsumed = planDuration * this.discountCost / daysPassed;
    this.budgetDeliveredVsForecasted = this.forecastBudgetConsumed > 0 ? this.forecastBudgetConsumed / this.budgetExpense: 0;
  }

  private getPropValue(prop: string, obj: Object, key = 'roi') {
    if (obj[key].hasOwnProperty('data') &&
        obj[key]['data'].hasOwnProperty(prop) &&
        !isNullOrUndefined(obj[key]['data'][prop])) {
      return obj[key]['data'][prop];
    } else {
      return 0;
    }
  }
}

export class PlanRoiCampaignSubtotal {

  budgetConsumed: number;
  budgetDeliveredVsForecasted: number
  budgetExpense: any;
  costVsBudget: number;
  customerRedemptions: number;
  customersCount: number;
  deliveredCoupons: number;
  deliveredPercentage: number;
  discountCost: number;
  forecastBudgetConsumed: number;
  incRatio: number;
  incSales: number;
  lgCost: number;
  redemptions: number;
  redemptionsPercentage: number;
  sumUniqueRedemp: number;
  uniqueRedemptions: number;

  constructor(campaigns: PlanRoiCampaignDetailAction[]) {

    const deliveredCoupons = campaigns.reduce((acc, obj) => acc + (obj.deliveredCoupons || 0), 0);

    this.budgetExpense = campaigns.reduce((acc, obj) => acc + (obj.budgetExpense || 0), 0);
    this.costVsBudget = campaigns.reduce((acc, obj) => acc + (obj.costVsBudget || 0), 0);
    this.customersCount = campaigns.reduce((acc, obj) => acc + (obj.customersCount || 0), 0);
    this.deliveredCoupons = campaigns.reduce((acc, obj) => acc + (obj.deliveredCoupons || 0), 0);
    this.discountCost = campaigns.reduce((acc, obj) => acc + (obj.discountCost || 0), 0);
    this.incSales = campaigns.reduce((acc, obj) => acc + (obj.incSales || 0), 0);
    this.lgCost = campaigns.reduce((acc, obj) => acc + (obj.lgCost || 0), 0);
    this.redemptions = campaigns.reduce((acc, obj) => acc + (obj.redemptions || 0), 0);
    this.sumUniqueRedemp = campaigns.reduce((acc, obj) => acc + (obj.uniqueRedemptions || 0), 0);
    this.uniqueRedemptions = deliveredCoupons ? this.sumUniqueRedemp / deliveredCoupons : 0;
    this.forecastBudgetConsumed = campaigns.reduce((acc, obj) => acc + (obj.forecastBudgetConsumed || 0), 0);

    this.budgetConsumed = this.discountCost / this.budgetExpense;
    this.budgetDeliveredVsForecasted = this.forecastBudgetConsumed > 0 ? this.forecastBudgetConsumed / this.budgetExpense: 0;
    this.customerRedemptions = this.sumUniqueRedemp ? this.redemptions / this.sumUniqueRedemp : 0;
    this.deliveredPercentage = this.customersCount ? this.deliveredCoupons / this.customersCount : 0;
    this.incRatio = this.incSales / (this.discountCost + this.lgCost);
    this.redemptionsPercentage = this.deliveredCoupons ? this.redemptions / this.deliveredCoupons : 0;
  }
}

export class PlanRoiGlobalTotal {

  totalBudgetConsumed: number;
  totalBudgetDeliveredVsForecasted: number;
  totalBudgetExpense: any;
  totalCostVsBudget: number;
  totalCustomerRedemptions: number;
  totalCustomersCount: number;
  totalDeliveredCoupons: number;
  totalDeliveredPercentage: number;
  totalDiscountCost: number;
  totalForecastBudgetConsumed: number;
  totalIncRatio: number;
  totalIncSales: number;
  totalLgCost: number;
  totalRedemptions: number;
  totalRedemptionsCount: number;
  totalRedemptionsPercentage: number;
  totalUniqueRedemptions: number;

  constructor(groups: PlanRoiCampaignGroup[]) {

    const subTotalValues = groups.map(group => group.subtotal);
    const redemptions = subTotalValues.reduce((acc, obj) => acc + (obj.redemptions || 0), 0);
    const deliveredCoupons = subTotalValues.reduce((acc, obj) => acc + (obj.deliveredCoupons || 0), 0);
    const sumUniqueRedemp = subTotalValues.reduce((acc, obj) => acc + (obj.sumUniqueRedemp || 0), 0);

    this.totalBudgetExpense = subTotalValues.reduce((acc, obj) => acc + (obj.budgetExpense || 0), 0);
    this.totalCostVsBudget = subTotalValues.reduce((acc, obj) => acc + (obj.costVsBudget || 0), 0);
    this.totalCustomerRedemptions = sumUniqueRedemp ? redemptions / sumUniqueRedemp: 0;
    this.totalCustomersCount = subTotalValues.reduce((acc, obj) => acc + (obj.customersCount || 0), 0);
    this.totalDeliveredCoupons = subTotalValues.reduce((acc, obj) => acc + (obj.deliveredCoupons || 0), 0);
    this.totalDiscountCost = subTotalValues.reduce((acc, obj) => acc + (obj.discountCost || 0), 0);
    this.totalIncSales = subTotalValues.reduce((acc, obj) => acc + (obj.incSales || 0), 0);
    this.totalLgCost = subTotalValues.reduce((acc, obj) => acc + (obj.lgCost || 0), 0);
    this.totalRedemptions = subTotalValues.reduce((acc, obj) => acc + (obj.redemptions || 0), 0);
    this.totalUniqueRedemptions = deliveredCoupons ? sumUniqueRedemp / deliveredCoupons : 0;
    this.totalForecastBudgetConsumed = subTotalValues.reduce((acc, obj) => acc + (obj.forecastBudgetConsumed || 0), 0);

    this.totalBudgetConsumed = this.totalDiscountCost / this.totalBudgetExpense;
    this.totalBudgetDeliveredVsForecasted = this.totalForecastBudgetConsumed > 0 ? this.totalForecastBudgetConsumed / this.totalBudgetExpense: 0;
    this.totalDeliveredPercentage = this.totalCustomersCount ? (this.totalDeliveredCoupons / this.totalCustomersCount) : 0;
    this.totalIncRatio = this.totalIncSales / (this.totalDiscountCost + this.totalLgCost);
    this.totalRedemptionsPercentage = this.totalDeliveredCoupons ? this.totalRedemptions / this.totalDeliveredCoupons : 0;
  }
}
