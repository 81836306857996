import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Campaign } from '../../models/campaign/campaign';
import { CampaignPayload } from '../../models/campaign/campaign-payload';
import { CampaignReport } from '../../models/campaign/campaign-report';
import { CampaignsFormService } from './campaigns-form.service';
import { CampaignsService } from '../../../resources/campaigns/campaigns.service';
import { CampaignValidateModalComponent } from './../../../resources/campaigns/campaign-validate-modal/campaign-validate-modal.component';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Input, EventEmitter, Output } from '@angular/core';
import { ConfirmationService } from '../../services/confirmation.service';
import { CurrentUser } from '../../models/current-user';
import { EmailTemplateEditComponent } from '../../../resources/content-designer/campaign-templates/email-template-edit/email-template-edit.component';
import { EmailTemplateViewComponent } from '../../../resources/content-designer/campaign-templates/email-template-view/email-template-view.component';
import { FeatureFlagsService } from './../../services/feature-flags.service';
import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from '../../utils/common.utils';
import { ModalStatusService } from '../../services/modal-status.service';
import { ProfileService } from '../../../profiles/profile.service';
import { PushTemplateEditComponent } from '../../../resources/content-designer/campaign-templates/push-template-edit-component/push-template-edit.component';
import { QuestionBase } from '../../models/forms/question-base';
import { QuestionControlService } from '../../services/question-control.service';
import { RefreshCacheService } from '../../services/refresh-cache.service';
import { registerLocaleData } from '@angular/common';
import { SmsTemplateEditComponent } from '../../../resources/content-designer/campaign-templates/sms-template-edit-component/sms-template-edit.component';
import { Subject, Subscription, filter, finalize, merge, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import es from '@angular/common/locales/es';
import { setTemplateRequired } from './template-validator-utils/template-utils';
import { SegmentsService } from '../../../resources/segments/segments.service';
import { PlanCampaignActionService } from '../../../resources/plans/campaigns/custom-campaign-plan-audience/plan-campaign-action.service';

enum MODES {
  Default,
  PlanManualCampaign,
  ReminderPlan,
  ReceivedReminder
}

@Component({
  selector: 'app-create-update-campaigns-dynamic-form',
  templateUrl: './create-update-campaigns-dynamic-form.component.html',
  styleUrls: ['./create-update-campaigns-dynamic-form.component.scss'],
  providers: [CampaignsFormService, PlanCampaignActionService]
})

export class CreateUpdateCampaignsDynamicFormComponent implements OnInit, OnDestroy {

  @Input() actionRaw: object;
  @Input() mode: 'default' | 'plan_manual_campaign' | 'reminder_plan' | 'received_reminder' = 'default';
  @Input() reminderIds;
  @Input() reminderStatus: string;
  @Input() selectedCoupon: unknown;

  @Output() closeModal = new EventEmitter<any>();
  @Output() updateRemindersCount = new EventEmitter<any>();
  @Output() updateSelectedCoupon = new EventEmitter<{actionId: unknown, coupon: unknown}>();
  @Output() actionSavedChannel = new EventEmitter<string>();

  @ViewChild(CampaignValidateModalComponent) campaignValidateModal: CampaignValidateModalComponent;
  @ViewChild(EmailTemplateEditComponent) emailTemplateEdit: EmailTemplateEditComponent;
  @ViewChild(EmailTemplateViewComponent) emailTemplateView: EmailTemplateViewComponent;
  @ViewChild(PushTemplateEditComponent) pushTemplateEdit: PushTemplateEditComponent;
  @ViewChild(SmsTemplateEditComponent) smsTemplateEdit: SmsTemplateEditComponent;

  ableToApprove: boolean = true;
  actionBtnLabel = this.translate.instant('resources.campaigns.buttons.send_campaign');
  assignedCouponHistoryId: number;
  assignedSegmentId: number;
  campaign: Campaign;
  campaignForm: UntypedFormGroup;
  clearIntervalInstance: any;
  companyCreditsInfo: { smsCredits?: number, emailCredits?: number };
  deliverOnProgress = false;
  displayControlGroupRoundedWarning = false;
  flags = this.featureFlags.flags;
  formHasBeenChanged: boolean;
  hasBeenValidated: boolean = false;
  inputs: QuestionBase<any>[];
  invalidScheduler = false;
  isScheduled = false;
  loading = false;
  membershipCampaign: boolean;
  refreshSender: boolean;
  saveCampaign$: Subscription;
  scheduledDateMsg: string;
  selectedReminder: number;

  activeMode: MODES;
  readonly MODE = MODES;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private campaignsFormService: CampaignsFormService,
    private campaignsService: CampaignsService,
    private changeDetector: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private featureFlags: FeatureFlagsService,
    private modalStatusService: ModalStatusService,
    private profileService: ProfileService,
    private qcs: QuestionControlService,
    private refreshCacheService: RefreshCacheService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private planCampaignActionService: PlanCampaignActionService
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.setActiveMode(this.mode);
    this.getParams();
    this.eventSubscriptions();
    if(this.flags.managePlanOrCampaignApprovers) {
      this.forbbidenRoles();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.campaignsFormService.removeFormFieldsSubscriptions();
  }

  openReminder(item) {
    this.selectedReminder = item;
    this.getCampaign(item.id);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.campaignForm, formKey, value);
  }

  templateHandler(event) {
    switch (event) {
      case 'emailView':
        this.emailTemplateView.openAndViewTemplate(this.campaign.template);
        break;

      case 'emailEdit':
        this.emailTemplateEdit.open();
        break;

      case 'smsView':
        this.smsTemplateEdit.open('readonly');
        break;

      case 'smsEdit':
        this.smsTemplateEdit.open('edit');
        break;

      case 'pushView':
        this.pushTemplateEdit.open('readonly');
        break;

      case 'pushEdit':
        this.pushTemplateEdit.open('edit');
        break;
    }
  }

  openCampaignValidateModal(): void {
    this.campaignValidateModal.toggleModal();
  }

  confirmCampaignValidate(emailNotification: boolean): void {
    this.saveOrValidateOrSend('validate', false, emailNotification);
  }

  saveOrValidateOrSend(action:string, _renderConfirmation?: boolean, emailNotification?: boolean) {
    this.loading = true;
    const payload = this.campaignsFormService.prepareCampaignWithForm(this.campaignForm.getRawValue(), this.campaign);

    if (this.isInReminderMode() || this.campaign.reminder_plan) {
      this.addReminderPayload(payload);
      this.assignSegmentAndCouponHistoryToReminder(payload);
    }

    if (!this.allSyncValidatorsOk()) { return; }

    if (this.isInPlanCampaignMode()) {
      this.updateSelectedCoupon.emit({actionId: this.actionRaw['id'], coupon: this.campaignForm.get('coupon_ids').value[0]?.rawElement});
      this.getCompanyCreditsInfo();
      this.saveRelatedSegmentAndAction(action, payload, emailNotification);
      return;
    }

    this.campaignsService.saveCampaign(payload).pipe(takeUntil(this.destroy$)).subscribe({
      next: (campaignData: any) => {
        this.campaign.id = campaignData.id;
        this.campaign.template['id'] = campaignData.template['id'];
        if (this.isInReminderMode()) {
          this.assignedSegmentId = campaignData['segment_id'];
          this.assignedCouponHistoryId = campaignData['campaign_history_coupon_id'];
          this.updateRemindersCount.emit();
        }
        this.getCompanyCreditsInfo();
        if (action === 'save') {
          this.saveAsADraft();
        } else if (action === 'validate'){
          this.getCampaignReport(campaignData, emailNotification, 'validate');
        } else if (action === 'send') {
          this.getCampaignReport(campaignData, emailNotification, 'send');
        }
      },
      error: errorData => {
        this.loading = false;
        this.handleErrorFromSave(errorData);
      }}
    );
  }

  close() {
    if ( this.deliverOnProgress ) {
      this.confirmationService.displayConfirmationAlert(
        this.translate.instant('resources.campaigns.warnings.campaign_close_delivery'),
        this.translate.instant('resources.campaigns.warnings.campaign_close_delivery_text')
      ).then(data => {
        if (data.hasOwnProperty('value') && data.value) {
          if (this.isInReminderMode() || this.isInPlanCampaignMode()) {
            this.campaign.ready_report = undefined;
            this.closeModal.emit();
          } else {
            this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
            this.modalStatusService.modalStatus.emit();
            this.deliverOnProgress = false;
          }
        }
      }).catch(() => {});
    } else {
      this.confirmationService.displayConfirmationAlert(
        this.translate.instant('resources.campaigns.warnings.campaign_close'),
        this.translate.instant('resources.campaigns.warnings.campaign_close_text')
      ).then(data => {
        if (data.hasOwnProperty('value') && data.value) {
          if (this.isInReminderMode() || this.isInPlanCampaignMode()) {
            this.campaign.ready_report = undefined;
            this.closeModal.emit();
          } else {
            this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
            this.modalStatusService.modalStatus.emit();
          }
        }
      }).catch(() => {});
    }
  }

  reUse() {
    this.loading = true;
    this.campaignsService.reuseCampaign(this.campaign).pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.loading = false;
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.campaigns.messages.campaign_reused_title'),
          this.translate.instant('resources.campaigns.messages.campaign_reused_description')
        ).then( data => {
            if (data.hasOwnProperty('value') && data.value) {
              this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
              this.modalStatusService.modalStatus.emit();
            }
          }
        ).catch(() => {});
      },
      error: (reusedCampaignError: HttpErrorResponse) => {
        console.error('reUse', reusedCampaignError);
        this.loading = false;
      }}
    );
  }

  getErrors(): { field: string, message: string }[] {
    return this.campaignForm.errors.map( error => {
      const field = error.field_name.indexOf('translation missing') >= 0 ? error.field : error.field_name;
      const message = error.message;
      return { field: field, message: message };
    });
  }

  removeError(error: {field: string, message: string}) {
    const errorsArray = this.campaignForm.errors.filter( _error => _error.field !== error.field );
    this.campaignForm.setErrors(errorsArray);
  }

  handleTemplateSaved( resource: string, template: any) {
    if(resource === 'email') {
      this.handleEmailSaved(template);
    } else if(resource === 'sms') {
      this.campaign.copySmsTemplate(this.campaign.campaign_template_id, { id: this.campaign.template.id, template: template });
    } else if (resource === 'push') {
      this.campaign.copyPushTemplate(this.campaign.campaign_template_id, { id: this.campaign.template.id, template: template });
    }
    this.saveAsADraft(false);
  }

  /* Required to reset forms when the campaign via is modified */
  resetCampaignTemplate() {
    this.smsTemplateEdit.form.patchValue({ 'message': '' }, { emitEvent: false });
    this.pushTemplateEdit.form.patchValue({ 'message': '' }, { emitEvent: false });
  }

  isInReminderMode(): boolean {
    return this.activeMode === this.MODE.ReminderPlan || this.activeMode === this.MODE.ReceivedReminder;
  }

  isInPlanCampaignMode(): boolean {
    return this.activeMode === this.MODE.PlanManualCampaign;
  }

  shouldDisplaySegmentBlock(): boolean {
    return this.activeMode !== this.MODE.ReceivedReminder &&
      !this.membershipCampaign &&
      !this.hasFormKeyWithValue('motive', 'membership');
  }

  private setActiveMode(mode: string) {
    switch (mode) {
      case 'plan_manual_campaign':
        this.activeMode = this.MODE.PlanManualCampaign;
        break;
      case 'reminder_plan':
        this.activeMode = this.MODE.ReminderPlan;
        break;
      case 'received_reminder':
        this.activeMode = this.MODE.ReceivedReminder;
        break;
      default:
        this.activeMode = this.MODE.Default;
        break;
    }
  }

  private forbbidenRoles() {
    const userRole = this.profileService.getStoredUserRole();
    this.ableToApprove = ['admin', 'owner', 'manager', 'user_manager'].includes(userRole);
  }

  private listenToFormChanges() {
    const inputsTrigger = [
      'via',
      'motive',
      'segment',
      'coupon_ids',
      'campaign_history_coupon_id',
      'message_ids',
      'email_template',
      'sms_template',
      'push_template'
    ];

    const changes$ = inputsTrigger.map(input =>
      this.campaignForm.get(input).valueChanges.pipe(
        filter(() => this.campaignForm.get(input).valid)
      )
    );

    merge(...changes$).pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.hasBeenValidated = false;
      }
    });
  }

  private saveAsADraft(_renderConfirmation?: boolean,) {
    this.loading = false;
    const renderConfirmation = _renderConfirmation === false ? _renderConfirmation : true;
    if ( renderConfirmation ) {
      this.confirmationService.displaySuccessAlert(
        this.translate.instant('resources.campaigns.messages.campaign_saved_title'),
        this.translate.instant('resources.campaigns.messages.campaign_saved_description'),
      ).then(data => {
        if (data.hasOwnProperty('value') && data.value) {
          if (this.isInReminderMode()) {
            this.closeModal.emit();
          } else {
            this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
          }
          this.modalStatusService.modalStatus.emit();
          this.updateRemindersCount.emit();
        }
      }).catch(() => {});
    }
  }

  private saveRelatedSegmentAndAction(action: string, campaignPayload: CampaignPayload, emailNotification: boolean) {

    this.loading = true;

    const { id,
      campaign_category_id,
      campaign_template_id,
      coupon_ids,
      description,
      skip_amount_error,
      sender_id,
      template,
      via,
      segment_id
    } = campaignPayload;

    const payload = {
      campaign_category_id,
      campaign_template_id,
      coupon_ids,
      description,
      id,
      include_phone_duplicated: via === 'sms' ? this.campaignForm.get('include_phone_duplicated').value : false,
      skip_amount_error,
      segment_id,
      sender_id,
      template,
      via
    };

    this.planCampaignActionService.patchCampaignChannel(payload, this.actionRaw['id'])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          if (action === 'validate') {
            this.getCampaignReport(payload, emailNotification, action);
          } else {
            this.loading = false;
            this.handleCampaignChannelSaved(payload);
          }
        },
        error: err => {
          this.loading = false;
          this.confirmationService.displayHttpErrorAlert(err)
        }
      });
  }

  private handleCampaignChannelSaved(payload) {
    this.actionSavedChannel.emit(payload.via);
    this.closeModal.emit();
    this.modalStatusService.modalStatus.emit();
  }

  private eventSubscriptions() {
    this.campaignsFormService.eventItem$.pipe(takeUntil(this.destroy$)).subscribe({
      next: event => this.handleFormEvent(event)
    });
    this.campaignsFormService.globalEventSource$.pipe(takeUntil(this.destroy$)).subscribe({
      next: globalEvent => this.handleFormGlobalEvent(globalEvent)
    });
  }

  private assignSegmentAndCouponHistoryToReminder(payload: CampaignPayload) {
    if ((this.isInReminderMode()) && this.campaign['id'] !== null) {
      payload.segment_id = this.assignedSegmentId;
      payload.campaign_history_coupon_id = this.assignedCouponHistoryId;
    }
  }

  private handleEmailSaved(emailTemplate: { json: string, html: string }) {
    if ( emailTemplate.json && emailTemplate.html ) {
      this.campaign.template['schema'] = JSON.parse(emailTemplate.json);
      this.campaign.template['message'] = emailTemplate.html;
    }
  }

  private addReminderPayload(payload: CampaignPayload) {
    if (this.activeMode === this.MODE.ReminderPlan) {
      payload['mode'] = 'reminder_plan';
      payload.reminder = true;
      payload['action_id'] = this.actionRaw['actionId'];
    }
    if (this.activeMode === this.MODE.ReminderPlan && !payload.id ) {
      payload['segment_id'] = -1;
      payload['campaign_history_coupon_id'] = -1;
    }
    if (this.activeMode === this.MODE.ReceivedReminder || payload.id ) {
      payload['campaign_history_coupon_id'] = this.assignedCouponHistoryId;
    }
  }

  private displayAvailableMotiveOptions(selectedVia) {
    const motiveInput = this.inputs.find(input => input.key === 'motive');
    if(selectedVia === 'none' || selectedVia === 'membership') {
      motiveInput.options = this.campaignsService.getCampaignMotives().filter(motive => motive.id === 'coupon' || motive.id === 'membership');
    } else if (selectedVia === 'ticket_message') {
      motiveInput.options = this.campaignsService.getCampaignMotives().filter(motive => motive.id === 'informative');
    } else {
      motiveInput.options = this.campaignsService.getCampaignMotives().filter(motive => motive.id !== 'membership');
    }
  }

  private filterChannelAndMotiveOptions() {
    const motiveInput = this.inputs.find(input => input.key === 'motive');
          motiveInput.options = this.campaignsService.getCampaignMotives().filter(motive => motive.id === 'reminder');
    this.campaignForm.patchValue({'motive': 'reminder'});

    const channelInput = this.inputs.find(input => input.key === 'via');
          channelInput.options = this.campaignsService.getCampaignCreationChannels().filter(via => via.id !== 'none' && via.id !== 'ticket_message');
  }

  private filterChannelAndMotiveOptionsPlanCampaign() {
    const motiveInput = this.inputs.find(input => input.key === 'motive');
          motiveInput.options = this.campaignsService.getCampaignMotives().filter(motive => motive.id === 'coupon');
    this.campaignForm.patchValue({'motive': 'coupon'});

    const channelInput = this.inputs.find(input => input.key === 'via');
          channelInput.options = this.campaignsService.getCampaignCreationChannels().filter(via => ['none', 'email', 'sms'].includes(via.id));
  }

  private assignValuesToReminder() {
    this.campaignForm.controls['name'].setValue(this.translate.instant('resources.campaigns.modal.fields.reminder') + ' ' + this.actionRaw['campaignId'] + ' - ' + this.actionRaw['name']);
    this.inputs.find(input => input.key === 'name').value = this.translate.instant('resources.campaigns.modal.fields.reminder') + ' ' + this.actionRaw['campaignId'] + ' - ' + this.actionRaw['name'];
    this.campaignForm.controls['description'].setValue(this.translate.instant('resources.campaigns.modal.fields.description') + ' ' + this.actionRaw['campaignId'] + ' - ' + this.actionRaw['name']);
    this.inputs.find(input => input.key === 'description').value = this.translate.instant('resources.campaigns.modal.fields.description') + ' ' + this.actionRaw['campaignId'] + ' - ' + this.actionRaw['name'];
    this.campaignForm.patchValue({coupon_ids: [{id: this.actionRaw['embeddedCampaign'].couponRaw['id'], name: this.actionRaw['embeddedCampaign'].couponRaw['name']}]});
  }

  private disableInputsForReminderPlan() {
    [
      'name',
      'description',
      'coupon_ids',
      'campaign_history_coupon_id',
      'segment'
    ].forEach(inputKey => this.inputs.find(input => input.key === inputKey).disabled = true)
  }

  private disableInputsForPlanCampaign() {
    [
      'name',
      'campaign_history_coupon_id',
      'segment'
    ].forEach(inputKey => this.inputs.find(input => input.key === inputKey).disabled = true)
  }

  private allSyncValidatorsOk(): boolean {

    let allValid = true;

    if ( this.campaignForm.get('periodic').value && !this.campaignForm.get('execution').value ) {
      const fieldName = this.translate.instant('resources.campaigns.warnings.periodic_execution');
      this.campaignsFormService.paintErrorsInForm(
        this.campaignForm,
        [{
          field: fieldName,
          field_name: fieldName,
          message: this.translate.instant('resources.campaigns.warnings.periodic_execution_required')
        }]
      );
      this.loading = false;
      allValid = false;
    }

    if ( this.campaignForm.get('scheduled_at').value &&
         this.campaignForm.get('execution_until').value &&
         this.campaignForm.get('scheduled_at').value > this.campaignForm.get('execution_until').value ) {
      const fieldName = this.translate.instant('resources.campaigns.warnings.execution_field_name');
      this.campaignsFormService.paintErrorsInForm(
        this.campaignForm,
        [{
          field: fieldName,
          field_name: fieldName,
          message: this.translate.instant('resources.campaigns.warnings.execution_greater_scheduled')
        }]
      );
      this.loading = false;
      allValid = false;
    }

    return allValid;
  }

  private getCompanyCreditsInfo() {
    const company = this.profileService.getStoredUser().company;
    this.companyCreditsInfo = {};
    this.companyCreditsInfo.smsCredits = parseFloat(company['sms_credits']);
    this.companyCreditsInfo.emailCredits = parseFloat(company['email_credits']);
  }

  private prepareToDeliver() {
    this.campaignsService.deliverCampaign(this.campaign)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loading = false;
          this.deliverOnProgress = false;
        }))
      .subscribe({
        next: () => {
          let msgTitle = this.translate.instant('resources.campaigns.messages.campaign_sent_title');
          let msgDesc = this.translate.instant('resources.campaigns.messages.campaign_sent_description');

          if (this.isScheduled) {
            msgTitle = this.translate.instant('resources.campaigns.messages.campaign_scheduled_title');
            msgDesc = this.translate.instant('resources.campaigns.messages.campaign_scheduled_description');
          }

          this.confirmationService.displaySuccessAlert( msgTitle, msgDesc ).catch(() => {});
          if (this.isInReminderMode()) {
            this.closeModal.emit();
          } else {
            this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
          }
          this.modalStatusService.modalStatus.emit();
        },
        error: (err: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(err)
      }
    );
  }

  private handleErrorFromSave(errorData: {message: string, error: { errors: string[]}}) {
    this.loading = false;
    if (errorData.error && errorData.error.errors && errorData.error.errors.length > 0) {
      this.paintErrorsInForm(errorData.error.errors);
      this.confirmationService.displayErrorAlert(
        this.translate.instant('resources.campaigns.modal.alerts.campaign_error'),
        JSON.stringify(errorData.error['error'])
      );
    }
  }

  private handleFormEvent(event: string) {
    switch (event) {
      case 'undefined_scheduled_at':
        this.actionBtnLabel = this.translate.instant('resources.campaigns.buttons.send_campaign');
        this.isScheduled = false;
        break;
      case 'defined_scheduled_at':
        this.actionBtnLabel = this.translate.instant('resources.campaigns.buttons.schedule_campaign');
        this.isScheduled = true;
        break;
      case 'loaded_sms_template':
        this.smsTemplateEdit.refreshTemplate(this.campaign.template);
        break;
      case 'loaded_push_template':
        this.pushTemplateEdit.refreshTemplate(this.campaign.template);
        break;
      case 'loaded_email_template':
        this.emailTemplateEdit.refreshTemplate(this.campaign.template);
        break;
      default:
        break;
    }
  }

  private handleDateMsgForm(event: string) {
    this.scheduledDateMsg = event === '' ? null : event;
  }

  private handleFormGlobalEvent(event: any) {
    if ( event === 'inform_change' ) {
      this.campaign.report = undefined;
      this.campaign.ready_report = undefined;
    }
  }

  private paintErrorsInForm(errorsArray) {
    this.campaignsFormService.paintErrorsInForm(this.campaignForm, errorsArray);
  }

  private getParams() {
    this.campaignForm = new UntypedFormGroup({});
    // If we are in reminder mode, we need to get the first reminder id to get the campaign
    // and that is why this logic should be extracted from getParams method -> TODO
    if (this.activeMode === this.MODE.ReceivedReminder) {
      this.selectedReminder = this.reminderIds[0];
      return this.getCampaign(this.reminderIds[0].id);
    }

    // If we are in plan campaign mode, we need to get the actionRaw campaignId
    // and that is why this logic should be extracted from getParams method -> TODO
    if (this.activeMode === this.MODE.PlanManualCampaign) {
      return this.getCampaign(this.actionRaw['campaignId']);
    }

    // By default we will subscribe to the route params in order to get the campaign id
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe({
      next: params => {
        // If we have an id, we will get the campaign
        if (params.hasOwnProperty('id')) {
          this.getCampaign(params.id);
          this.getCompanyCreditsInfo();
        // If we don't have an id, we will create a new campaign
        } else {
          this.campaign = new Campaign();
          this.inputs = this.campaignsFormService.getCampaignInputs();
          this.campaignForm = this.qcs.toFormGroup(this.inputs);
          this.campaignForm.setValidators(this.getValidators());
          this.syncFormWithCampaignData({});
          this.handleSegmentVisualization();
          this.listenToFormChanges();
          if (this.isInReminderMode()) {
            this.filterChannelAndMotiveOptions();
            this.assignValuesToReminder();
            this.disableInputsForReminderPlan();
          }
        }
      }
    });
  }

  /*
    New method to start adding properly all form validators in a future refactor
  */
  private getValidators(): ValidatorFn[] {
    const ensureSenderIfEmailCampaign = ((_ctrl: AbstractControl) => {
      const viaCtrl = _ctrl.get('via');
      const viaValue = viaCtrl && viaCtrl.value ? viaCtrl.value[0]?.id : [];
      const senderCtrl = _ctrl.get('sender_id');
      const senderValue = senderCtrl && senderCtrl?.value ? senderCtrl?.value[0]?.id : [];
      if (viaValue === 'email' && !senderValue) {
        return {campaignSenderRequired: true};
      } else if (viaValue === 'sms' && !senderValue) {
        return {campaignSenderRequired: true};
      }
      return null;
    });
    return this.flags.displayCampaignSender ? [ensureSenderIfEmailCampaign] : [];
  }

  /*
    Hides segment block if selected motive is membershit -> Will implement the same for collectives probably
  */
  private handleSegmentVisualization() {
    this.campaignForm.get('motive').valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: (value) => {
        this.membershipCampaign = value && value === 'membership' ? true : false
      }
    });
  }

  private getCampaign(id: number) {
    this.campaignsService.getCampaignById(id).pipe(takeUntil(this.destroy$)).subscribe({
      next: campaignData => {
        this.refreshSender = true;
        if (this.activeMode === this.MODE.ReceivedReminder) {
          this.assignedCouponHistoryId = campaignData['campaign_history_coupon_id'];
        }
        this.campaignForm = null;
        this.campaign = new Campaign(campaignData);
        this.changeDetector.detectChanges();
        this.inputs = this.campaignsFormService.getCampaignInputs(this.campaign, this.selectedCoupon);
        this.campaignForm = this.qcs.toFormGroup(this.inputs);
        this.campaignForm.setValidators(this.getValidators());
        this.handleSegmentVisualization();
        this.syncFormWithCampaignData(campaignData);
        this.listenToFormChanges();

        if (this.campaign['reminder_plan']) {
          this.filterChannelAndMotiveOptions();
          this.disableInputsForReminderPlan();
        } else {
          this.displayAvailableMotiveOptions(this.campaignForm.value.via[0].id);
        }

        if (this.isInReminderMode()) {
          this.filterChannelAndMotiveOptions();
          this.disableInputsForReminderPlan();
        } else if(this.isInPlanCampaignMode()) {
          this.filterChannelAndMotiveOptionsPlanCampaign();
          this.disableInputsForPlanCampaign();
        }

        if (this.activeMode === this.MODE.ReceivedReminder) { 
          this.assignValuesToReminder();
        }

        if(campaignData['report_present']) {
          this.getCampaignReport(this.campaign);
        }

        setTemplateRequired(this.campaignForm, this.inputs);
      },
      error: errorDataOnGet => {
        if ( errorDataOnGet.status === 500 ) {
            this.confirmationService.displayErrorAlert(
            this.translate.instant('resources.campaigns.messages.internal_error'),
            this.translate.instant('resources.campaigns.messages.campaign_error') + `: ${id}`
          );
          this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
        }
        console.error(`GetCampaign Err: ${JSON.stringify(errorDataOnGet)}`);
      }
    });
  }

  private getCampaignReport(campaignData: { id: number }, emailNotification?: boolean, operation?:string) {
    let requestData = { apiEndPoint: `campaigns/${campaignData.id}/report` };
    if (emailNotification) {
      requestData['emailNotification'] = emailNotification;
    } else {
      requestData['emailNotification'] = false;
    }
    this.refreshCacheService.getExpensiveData(requestData).pipe(takeUntil(this.destroy$)).subscribe({
      next: campaignReport => {
        if (!this.refreshCacheService.isRequestPending(campaignReport)) {
          this.loading = false;
          this.campaign.report = new CampaignReport(campaignReport);
          this.displayControlGroupRoundedWarning = this.showControlGroupWarning();
          if (this.activeMode !== this.MODE.ReminderPlan || !campaignData['segment_id']) {
            this.campaign.editable = true;
            // Enable all inputs in form if campaign is editable
            const inputsToDisable = this.inputs.filter(input => !(input.key.indexOf('_test_delivery') >= 0));

            if (this.campaign?.editable && !this.campaign?.reminder) {
              inputsToDisable.forEach( input => input.disabled = false );
            }

            if (operation === 'send' && campaignReport.hasOwnProperty('status') && campaignReport['status'] === 'ok') {
              this.prepareToDeliver();
            } else if ( operation === 'validate' && campaignReport.status === 'ok' || this.campaign.report_present ) {
              this.hasBeenValidated = true;
            }

            if (campaignData['motive'] === 'membership') {
              this.getInputConfig('periodic').disabled = true;
              this.getInputConfig('execution').disabled = true;
              this.getInputConfig('execution_until').disabled = true;
            }
          } else {
            if ( operation === 'validate' && campaignReport.status === 'ok' || this.campaign.report_present ) {
              this.hasBeenValidated = true;
            }
            if ( operation === 'send' && campaignReport.status === 'ok' ) {
              this.prepareToDeliver();
            }
          }

          this.changeDetector.detectChanges();
        }
      },
      error: campaignReportError => {
        this.loading = false;
        this.campaign.report = campaignReportError;
        console.error( 'KO campaignReport', JSON.stringify(campaignReportError));
      }
    });
  }

  private syncFormWithCampaignData(campaignData: object) {
    /* SetTimeout 0 to prevent changedAfterChecked errors in form */
    this.clearIntervalInstance = setInterval( () => {
      if ( campaignData['reminder'] ) {
        this.campaignForm.patchValue({'motive': 'reminder'}, {emitEvent: false});
      }
      if ( !campaignData['editable'] ) {
        this.campaignForm.get('motive').disable({emitEvent: false});
      }
      /* Campaigns with histories cannot change via / motive */
      if ( campaignData['has_been_sent'] ) {
        this.getInputConfig('via').disabled = true;
        this.campaignForm.get('via').disable({emitEvent: false});
        this.getInputConfig('motive').disabled = true;
        this.campaignForm.get('motive').disable({emitEvent: false});
      }

      if (campaignData['motive'] === 'membership') {
        this.getInputConfig('periodic').disabled = true;
        this.getInputConfig('execution').disabled = true;
        this.getInputConfig('execution_until').disabled = true;
      }

      this.campaignsFormService.scheduledAtMsg$.pipe(takeUntil(this.destroy$)).subscribe({
        next: event => this.handleDateMsgForm(event)
      });

      /* Load SMS template message if any */
      if ( this.hasFormKeyWithValue('via', 'sms') ) {
        this.smsTemplateEdit.refreshTemplate(this.campaign.template);
      }

      this.campaignsFormService.addFormFieldsSubscriptions(this.campaignForm, this.campaign , this.mode);
      clearInterval(this.clearIntervalInstance);
    });
  }

  private showControlGroupWarning(): boolean {
    const company = new CurrentUser(this.profileService.getStoredUser()).company;
    if (isNullOrUndefined(company.controlGroupRatio) || !this.campaign.control_group_flag) { return false; }
    const controlGroupCustomers = this.campaign.report.count * (company.controlGroupRatio / 100);
    return (controlGroupCustomers > 0 && controlGroupCustomers < 1);
  }
}
