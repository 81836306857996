import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class ReservedIdsService extends ResourcesService {

  constructor(http: HttpClient) {
    super(http);
  }

  public loadCSV(reservedIds) {
    return this.postResource(reservedIds, 'reserved_ids');
  }

}
