import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { DataTableConfiguration } from '../../../../../shared/components/data-table/data-table-cfg';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalStatusService } from '../../../../../shared/services/modal-status.service';
import { Reward } from '../../../../../shared/models/reward/reward';
import { RewardCodeBatchesMock } from '../../../../../shared/mocks/reward-code-batches-mock';
import { RewardsService } from '../../rewards.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reward-stock-details',
  templateUrl: './reward-stock-details.component.html',
  styleUrls: ['./reward-stock-details.component.scss']
})

export class RewardStockDetailsComponent implements OnInit{

  dataTableConfig: DataTableConfiguration;
  reward: Reward;
  rewardId: number;

  constructor(
    private confirmationService: ConfirmationService,
    private modalStatusSrv: ModalStatusService,
    private rewardsService: RewardsService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ){ }

  ngOnInit(): void {
    this.getParams();
  }

  private getParams() {
    this.route.parent.params.subscribe(params => {
      if (params.id) {
        this.rewardId = params.id;
        this.setDataTableConfig();
      }
    });
  }

  private confirmDelete(id: number) {
    this.confirmationService.displayConfirmationAlert(
      this.translate.instant('common.messages.are_you_sure'),
      this.translate.instant('resources.scores.reward_code_batches.warnings.confirm_delete'),
      'question')
    .then(
      data => {
        if (data.hasOwnProperty('value') && data.value) {
          this.rewardsService.deleteRewardCodeBatch(this.rewardId, id).subscribe({
            next: () => {
              this.confirmationService.displaySuccessAlert('', this.translate.instant('resources.scores.reward_code_batches.warnings.delete_success')).catch(() => {});
              this.modalStatusSrv.modalStatus.emit();
            },
            error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
          });
        }
      }
    ).catch(() => {});
  }

  private setDataTableConfig() {
    this.dataTableConfig = {
      isActive: true,
      isMock: false,
      mockConstant: RewardCodeBatchesMock,
      tokenPagination: false,
      createButton: {
        label: this.translate.instant('resources.scores.rewards.buttons.add_stock'),
        redirectTo: [{outlets: { modal: `show/rewards/${this.rewardId}/stock/add` }}]
      },
      rows: [],
      rowActions: [
        {
          name: this.translate.instant('common.delete'),
          icon: 'trash',
          id: 'delete',
          callback: (element: { id: number }) => this.confirmDelete(element.id)
        }
      ],
      tableActions: [
        {
          name: this.translate.instant('resources.scores.reward_code_batches.table_actions.export_csv'),
          icon: 'ei-export',
          id: 'exportCSV'
        }
      ],
      columns: [
        {
          name: this.translate.instant('resources.scores.rewards.columns.id'),
          prop: 'id'
        },
        {
          name: this.translate.instant('resources.scores.rewards.columns.status'),
          prop: 'status'
        },
        {
          name: this.translate.instant('resources.scores.rewards.columns.created_at'),
          prop: 'created_at'
        },
        {
          name: this.translate.instant('resources.scores.rewards.columns.stock'),
          prop: 'num_codes'
        },
        {
          name: this.translate.instant('resources.scores.rewards.columns.remaining_stock'),
          prop: 'remaining_stock'
        }
      ],
      requestData: {
        translationResource: 'resources.scores.rewards.columns.',
        apiEndPoint: `/rewards/${this.rewardId}/code_batches`,
        pageNumber: 0,
        sorting: {},
        filtering: {},
        numberPerPage: 10
      },
      tableMapping: [
        {prop: 'id', type: 'number', apiProp: 'id'},
        {prop: 'status', type: 'badge', apiProp: 'status', badge: {dictionaryKey: 'reward_code_batches_status'} },
        {prop: 'created_at', type: 'date_time', apiProp: 'created_at'},
        {prop: 'num_codes', type: 'number', apiProp: 'num_codes'},
        { prop: 'remaining_stock', type: 'number', apiProp: 'remaining_stock'}
      ]
    };
  }

}
