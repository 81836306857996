<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true" *ngIf="element">
              <tbody>
                <tr *ngIf="element.image">
                  <td class="no-border-top"><b [translate]="'resources.promotions.show.fields.image'"></b></td>
                  <td class="no-border-top"><img id="image" [src]="element.image" class="img-fluid" alt="image"></td>
                </tr>
                <tr *ngIf="element.id">
                  <td><b [translate]="'resources.promotions.show.fields.id'"></b></td>
                  <td>{{element.id}}</td>
                </tr>
                <tr *ngIf="element.name">
                  <td><b [translate]="'resources.promotions.show.fields.name'"></b></td>
                  <td>{{element.name}}</td>
                </tr>
                <tr *ngIf="element.description">
                  <td><b [translate]="'resources.promotions.show.fields.description'"></b></td>
                  <td>{{element.description}}</td>
                </tr>
                <tr *ngIf="element.discount_type">
                  <td><b [translate]="'resources.promotions.show.fields.discount_type'"></b></td>
                  <td *ngIf="element.discount_type === 'cash'" [translate]="'resources.promotions.show.fields.cash'"></td>
                  <td *ngIf="element.discount_type === 'percent'" [translate]="'resources.promotions.show.fields.percent'"></td>
                </tr>
                <tr *ngIf="element.cumulative">
                  <td><b [translate]="'resources.promotions.show.fields.cumulative'"></b></td>
                  <td *ngIf="element.cumulative"><span><i class="fa fa-check text-success"></i></span></td>
                  <td *ngIf="!element.cumulative"><span><i class="fa fa-times text-danger"></i></span></td>
                </tr>
                <tr *ngIf="element.discount">
                  <td><b [translate]="'resources.promotions.show.fields.discount'"></b></td>
                  <td *ngIf="element.discount_type === 'percent'">{{element.discount}}%</td>
                  <td *ngIf="element.discount_type === 'cash'">{{element.discount}}&nbsp;{{currencySymbol}}</td>
                </tr>
                <tr *ngIf="element.min_money">
                  <td><b [translate]="'resources.promotions.show.fields.min_money'"></b></td>
                  <td>{{element.min_money}}</td>
                </tr>
                <tr *ngIf="element.max_money">
                  <td><b [translate]="'resources.promotions.show.fields.max_money'"></b></td>
                  <td>{{element.max_money}}</td>
                </tr>
                <tr *ngIf="element.min_units">
                  <td><b [translate]="'resources.promotions.show.fields.min_units'"></b></td>
                  <td>{{element.min_units}}</td>
                </tr>
                <tr *ngIf="element.max_units">
                  <td><b [translate]="'resources.promotions.show.fields.max_units'"></b></td>
                  <td>{{element.max_units}}</td>
                </tr>
                <tr *ngIf="element.available_from">
                  <td><b [translate]="'resources.promotions.show.fields.available_from'"></b></td>
                  <td>{{element.available_from | date: 'dd/MM/yyyy'}}</td>
                </tr>
                <tr *ngIf="element.available_to">
                  <td><b [translate]="'resources.promotions.show.fields.available_to'"></b></td>
                  <td>{{element.available_to | date: 'dd/MM/yyyy'}}</td>
                </tr>
                <tr *ngIf="element.affectation">
                  <td><b [translate]="'resources.promotions.show.fields.affectation'"></b></td>
                  <td *ngIf="element.affectation === 'activity'" [translate]="'resources.coupons.modal.show.details.fields.activity'"></td>
                  <td *ngIf="element.affectation === 'attribute'" [translate]="'resources.coupons.modal.show.details.fields.attribute'"></td>
                  <td *ngIf="element.affectation === 'product'" [translate]="'resources.coupons.modal.show.details.fields.product'"></td>
                </tr>

                <tr *ngIf="element?.feature_pks !== '[]'">
                  <td><b [translate]="'resources.promotions.show.fields.product_category'"></b><b>&nbsp;({{element._embedded.features_list.length}})</b></td>
                  <td>
                    <ul class="list arrow bullet-success">
                      <ng-container>
                        <li *ngFor="let item of element._embedded.features_list | orderBy:['name'] | slice: 0:5">
                          <span>{{item.name}}</span>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="element._embedded.features_list.length > 5">
                        <li>
                          <a (click)="viewAll()" class="text-blue pointer">
                            <u>{{'common.view_all' | translate}}</u>
                          </a>
                        </li>
                      </ng-container>
                    </ul>
                  </td>
                </tr>

                <tr *ngIf="element.product_ids?.length > 0">
                  <td><b [translate]="'resources.promotions.show.fields.products'"></b><b>&nbsp;({{element._embedded.products.length}})</b></td>
                  <td>
                    <ul class="list arrow bullet-success">
                      <ng-container>
                        <li *ngFor="let item of element._embedded.products | orderBy:['name'] | slice: 0:5">
                          <span>{{item.name}}</span>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="element._embedded.products.length > 5">
                        <li>
                          <a (click)="viewAll()" class="text-blue pointer">
                            <u>{{'common.view_all' | translate}}</u>
                          </a>
                        </li>
                      </ng-container>
                    </ul>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
