<div class="alert alert-info" role="alert" *ngIf="!onSearch && !loading">
  <span>{{ 'components.looker-iframe.alerts.apply_filters' | translate }}</span>
</div>

<div class="row" *ngIf="onSearch">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title d-inline" [translate]="'dashboards.customers.tabs.type_redemptions.title'"></h4>
      </div>

      <app-loader styleClass="mrg-btm-15" *ngIf="loading" />

      <div class="card-block no-pdd no-mrg pdd-horizon-20 pdd-btm-5">
        <div class="alert alert-warning fade show mrg-btm-15" *ngIf="!loading && typeRedemptionsData.length === 0">
          {{'components.data-table.messages.empty' | translate}}
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-sm table-bordered" aria-hidden="true" *ngIf="!loading && typeRedemptionsData.length > 0">
            <thead>
              <tr class="highlight-header border-highlight">
                <th></th>
                <th></th>
                <th class="text-center" *ngFor="let i of this.activeMonths;">{{months[i].name}}</th>
                <th class="text-center" translate>common.average</th>
              </tr>
            </thead>
            <tbody>
              <!-- Init Loyal -->
              <tr>
                <td [attr.rowSpan]="3" class="text-bold border-highlight main-row-label">
                  {{('dashboards.customers.tabs.type_redemptions.fields.loyal' | translate).toUpperCase()}}
                </td>
                <td translate>dashboards.customers.tabs.type_redemptions.fields.loyal</td>
                <td *ngFor="let monthNum of activeMonths;" class="text-right">
                  <span class="label label-default">{{typeRedemptionsData[monthNum].values.loyal[0] | lgcurrency: '1.2-2'}}</span>
                </td>
                <td class="text-right">
                  <span class="label label-default">{{averagesColumn.loyal[0] | lgcurrency: '1.2-2'}}</span>
                </td>
              </tr>
              <tr>
                <td translate>dashboards.customers.tabs.type_redemptions.fields.loyal_redeemed</td>
                <td *ngFor="let monthNum of activeMonths;" class="text-right">
                  <span class="label label-default">{{typeRedemptionsData[monthNum].values.loyal[1] | lgcurrency: '1.2-2'}}</span>
                </td>
                <td class="text-right">
                  <span class="label label-default">{{averagesColumn.loyal[1] | lgcurrency: '1.2-2'}}</span>
                </td>
              </tr>
              <tr class="border-highlight">
                <td translate>dashboards.customers.tabs.type_redemptions.fields.loyal_not_redeemed</td>
                <td *ngFor="let monthNum of activeMonths;" class="text-right">
                  <span class="label label-default">{{typeRedemptionsData[monthNum].values.loyal[2] | lgcurrency: '1.2-2'}}</span>
                </td>
                <td class="text-right">
                  <span class="label label-default">{{averagesColumn.loyal[2] | lgcurrency: '1.2-2'}}</span>
                </td>
              </tr>
              <!-- End loyal -->
              <!-- Init Shared -->
              <tr>
                <td [attr.rowSpan]="3" class="text-bold border-highlight main-row-label">
                  {{('dashboards.customers.tabs.type_redemptions.fields.shared' | translate).toUpperCase()}}
                </td>
                <td translate>dashboards.customers.tabs.type_redemptions.fields.shared</td>
                <td *ngFor="let monthNum of activeMonths;" class="text-right">
                  <span class="label label-default">{{typeRedemptionsData[monthNum].values.shared[0] | lgcurrency: '1.2-2'}}</span>
                </td>
                <td class="text-right">
                  <span class="label label-default">{{averagesColumn.shared[0] | lgcurrency: '1.2-2'}}</span>
                </td>
              </tr>
              <tr>
                <td translate>dashboards.customers.tabs.type_redemptions.fields.shared_redeemed</td>
                <td *ngFor="let monthNum of activeMonths;" class="text-right">
                  <span class="label label-default">{{typeRedemptionsData[monthNum].values.shared[1] | lgcurrency: '1.2-2'}}</span>
                </td>
                <td class="text-right">
                  <span class="label label-default">{{averagesColumn.shared[1] | lgcurrency: '1.2-2'}}</span>
                </td>
              </tr>
              <tr class="border-highlight">
                <td translate>dashboards.customers.tabs.type_redemptions.fields.shared_not_redeemed</td>
                <td *ngFor="let monthNum of activeMonths;" class="text-right">
                  <span class="label label-default">{{typeRedemptionsData[monthNum].values.shared[2] | lgcurrency: '1.2-2'}}</span>
                </td>
                <td class="text-right">
                  <span class="label label-default">{{averagesColumn.shared[2] | lgcurrency: '1.2-2'}}</span>
                </td>
              </tr>
              <!-- End shared -->
              <!-- Init casual -->
              <tr>
                <td [attr.rowSpan]="3" class="text-bold border-highlight main-row-label">
                  {{('dashboards.customers.tabs.type_redemptions.fields.casual' | translate).toUpperCase()}}
                </td>
                <td translate>dashboards.customers.tabs.type_redemptions.fields.casual</td>
                <td *ngFor="let monthNum of activeMonths;" class="text-right">
                  <span class="label label-default">{{typeRedemptionsData[monthNum].values.casual[0] | number: '1.2-2' | lgcurrency}}</span>
                </td>
                <td class="text-right">
                  <span class="label label-default">{{averagesColumn.casual[0] | number: '1.2-2' | lgcurrency}}</span>
                </td>
              </tr>
              <tr>
                <td translate>dashboards.customers.tabs.type_redemptions.fields.casual_redeemed</td>
                <td *ngFor="let monthNum of activeMonths;" class="text-right">
                  <span class="label label-default">{{typeRedemptionsData[monthNum].values.casual[1] | number: '1.2-2' | lgcurrency}}</span>
                </td>
                <td class="text-right">
                  <span class="label label-default">{{averagesColumn.casual[1] | number: '1.2-2' | lgcurrency}}</span>
                </td>
              </tr>
              <tr class="border-highlight">
                <td translate>dashboards.customers.tabs.type_redemptions.fields.casual_not_redeemed</td>
                <td *ngFor="let monthNum of activeMonths;" class="text-right">
                  <span class="label label-default">{{typeRedemptionsData[monthNum].values.casual[2] | number: '1.2-2' | lgcurrency}}</span>
                </td>
                <td class="text-right">
                  <span class="label label-default">{{averagesColumn.casual[2] | number: '1.2-2' | lgcurrency}}</span>
                </td>
              </tr>
              <!-- End casual -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
