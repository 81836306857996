<div class="mrg-top-15 mrg-btm-40 pdd-horizon-30">
  <p-table [value]="attributesConfigArray?.controls" [scrollable]="true" scrollHeight="400px">
    <ng-template pTemplate="header">
      <tr>
        <th scope="col" style="width: 24%">{{ 'resources.segment_conditions.titles.attribute' | translate }}</th>
        <th scope="col" style="width: 24%">{{ 'resources.segment_conditions.titles.operator' | translate }}</th>
        <th scope="col" style="width: 24%">{{ 'resources.segment_conditions.titles.value' | translate }}</th>
        <th scope="col" style="width: 24%">{{ 'resources.segment_conditions.titles.value2' | translate }}</th>
        <th scope="col"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-attributeConfigCtrl let-rowIndex="rowIndex">
      <tr>
        <td>{{ attributeConfigItemList(attributeConfigCtrl, attributesConfigColumnList.AttributeName) | emptyData }}</td>
        <td>{{ attributeConfigItemList(attributeConfigCtrl, attributesConfigColumnList.Operator) | emptyData }}</td>
        <td>{{ attributeConfigItemList(attributeConfigCtrl, attributesConfigColumnList.Value) | emptyData }}</td>
        <td>{{ attributeConfigItemList(attributeConfigCtrl, attributesConfigColumnList.Value2) | emptyData }}</td>
        <td class="text-right">
            <em class="fa-light fa-trash-can pointer icon-delete-attributes" (click)="deleteAttribute(rowIndex)"></em>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="5" class="text-center">{{ 'resources.segment_conditions.messages.no_data' | translate }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
