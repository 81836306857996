import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FLAGS } from '../constants/feature-flags.constants';
import { LaunchDarklyService } from './launch-darkly/launchdarkly.service';

export type Flag = typeof FLAGS[number]['name'];

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {

  /**
   * Typically a boolean, but also can be used as a string.
   */
  flags: { [name in Flag]: any };

  flagsLoaded$ = new BehaviorSubject<boolean>(false);

  get flagsLoaded(): boolean {
    return this.flagsLoaded$.value;
  }

  constructor(private launchDarklyService: LaunchDarklyService) {

    this.initFeatureFlags();
    this.processFeatureFlags();
  }

  private initFeatureFlags(): void {
    FLAGS.forEach(flag => this.flags = { ...this.flags, [flag.name]: flag.value });
  }

  private processFeatureFlags(): void {
    if (this.launchDarklyService.client) {
      for (const { name, key, value } of FLAGS) {
        this.setFlagValue(name, this.launchDarklyService.client.variation(key, value));
      }
    }

    this.launchDarklyService.flagChanges.subscribe((flags) => {
      for (const { name, key } of FLAGS) {
        if (flags[key]) { this.setFlagValue(name, flags[key]); }
      }

      this.setFlagsLoaded();

    });
  }

  private setFlagValue(name: string, flagValue): void {
    flagValue = flagValue.hasOwnProperty('current') ? flagValue.current : flagValue;
    this.flags[name] = flagValue;
  }

  private setFlagsLoaded(): void {
    if (!this.flagsLoaded) {
      this.flagsLoaded$.next(true)
      this.flagsLoaded$.complete();
    }
  }
}
