import { Component, OnInit } from '@angular/core';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { ScoreExchangesService } from '../score-exchanges.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';

@Component({
  selector: 'app-form-score-exchanges',
  templateUrl: './form-score-exchanges.component.html',
  styleUrls: ['./form-score-exchanges.component.css'],
  providers: [ScoreExchangesService]
})
export class FormScoreExchangesComponent implements OnInit {

  public scoreExchangesInputs: QuestionBase<any>[];
  public scoreExchangesForm: UntypedFormGroup;
  public inputs: any;
  public id: number;
  public showButton: boolean;

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private scoreExchangeService: ScoreExchangesService,
    private confirmationService: ConfirmationService,
    private modalStatusService: ModalStatusService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.getParams();
    this.showBackButton();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  sendData() {
    const currency = this.scoreExchangesForm.value.currency && this.scoreExchangesForm.value.currency.length > 0 ? this.scoreExchangesForm.value.currency[0].id : null;
    const scoreExchangesOptions = {
      name: this.scoreExchangesForm.value.name,
      description: this.scoreExchangesForm.value.description,
      currency: currency,
      currency_amount: this.scoreExchangesForm.value.currency_amount,
    };

    if ( this.id ) {
      this.scoreExchangeService.updateData(scoreExchangesOptions, this.id).subscribe(
        response => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.scores.score_exchanges.form.warnings.update_title'),
            this.translate.instant('resources.scores.score_exchanges.form.warnings.update_text')
          ).catch(() => {});
          this.modalStatusService.modalStatus.emit();
          this.returnToList();
        },
        errorData => {
          if (errorData.error && errorData.error.errors && errorData.error.errors.length > 0) {
            this.paintErrorsInForm(this.scoreExchangesForm, errorData.error.errors);
          } else if (typeof errorData.error.error === 'string') {
            this.confirmationService.displayErrorAlert(
              this.translate.instant('resources.scores.score_exchanges.form.warnings.error_title'),
              errorData.error.error
            );
          }
        }
      );
      } else {
      this.scoreExchangeService.sendData(scoreExchangesOptions).subscribe(
        response => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.scores.score_exchanges.form.warnings.success_title'),
            this.translate.instant('resources.scores.score_exchanges.form.warnings.success_text')
          ).catch(() => {});
          this.modalStatusService.modalStatus.emit();
          this.returnToList();
        },
        errorData => {
          if (errorData.error && errorData.error.errors && errorData.error.errors.length > 0) {
            this.paintErrorsInForm(this.scoreExchangesForm, errorData.error.errors);
          } else if (typeof errorData.error.error === 'string') {
            this.confirmationService.displayErrorAlert(
              this.translate.instant('resources.scores.score_exchanges.form.warnings.error_title'),
              errorData.error.error
            );
          }
        }
      );
    }
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getScoreExchangesById(this.id);
      } else {
        this.inputs = this.scoreExchangeService.getInputs({});
        this.scoreExchangesForm = this.qcs.toFormGroup(this.inputs);
      }
    });
  }

  private getScoreExchangesById(id: number) {
    this.scoreExchangeService.getScoreExchangesById(this.id).subscribe(
      data => {
        this.inputs = this.scoreExchangeService.getInputs(data);
        this.scoreExchangesForm = this.qcs.toFormGroup(this.inputs);
      }
    );
  }

  private paintErrorsInForm(form: UntypedFormGroup, errorsArray: {field: string, message: any}[]) {
    errorsArray.forEach(apiError => {
      const controlKey = apiError.field;
      form.get(controlKey).setErrors({'notPresent': true});
      const question = this.qcs.getInputCfgByKey(this.inputs, controlKey);
      question.hasErrors = true;
      question.errorText = apiError.message[0];
    });
  }

  private showBackButton() {
    this.showButton = !(this.router.url.indexOf('(modal:') >= 0);
  }

  private returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/scores/score_exchanges']).catch(() => {});
    }
  }

}
