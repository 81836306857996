import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { checkValue1GreaterThanValue2 } from "../../../../validations.service";
import { INTEGER_REGEX } from "../../../constants/condition.constants";
import { CustomerAttributeKey } from "../enums/customer-attribute-key.enums";
import { CustomerAttributeType } from "../enums/customer-attribute-type.enums";

export function getFormGroupValidations(control: UntypedFormGroup): ValidationErrors | null {
  const definitionTypeCtrl = control.get(CustomerAttributeKey.DefinitionType);
  const stringOperatorCtrl = control.get(CustomerAttributeKey.StringOperator);
  const integerOperatorCtrl = control.get(CustomerAttributeKey.IntegerOperator);
  const floatOperatorCtrl = control.get(CustomerAttributeKey.FloatOperator);
  const booleanOperatorCtrl = control.get(CustomerAttributeKey.BooleanOperator);
  const dateOperatorCtrl = control.get(CustomerAttributeKey.DateOperator);
  const stringValueCtrl = control.get(CustomerAttributeKey.StringValue);
  const integerValueCtrl = control.get(CustomerAttributeKey.IntegerValue);
  const integerValue2Ctrl = control.get(CustomerAttributeKey.IntegerValue2);
  const floatValueCtrl = control.get(CustomerAttributeKey.FloatValue);
  const floatValue2Ctrl = control.get(CustomerAttributeKey.FloatValue2);
  const booleanValueCtrl = control.get(CustomerAttributeKey.BooleanValue);
  const dateValueCtrl = control.get(CustomerAttributeKey.DateValue);
  const dateValue2Ctrl = control.get(CustomerAttributeKey.DateValue2);

  const controls = [
    stringOperatorCtrl, integerOperatorCtrl, floatOperatorCtrl, booleanOperatorCtrl, dateOperatorCtrl,
    stringValueCtrl, integerValueCtrl, integerValue2Ctrl, floatValueCtrl, floatValue2Ctrl, booleanValueCtrl,
    dateValueCtrl, dateValue2Ctrl
  ];
  controls.forEach(ctrl => ctrl.setValidators([]));

  const definitionTypeValue = definitionTypeCtrl?.value ?? null;
  switch(definitionTypeValue) {
    case CustomerAttributeType.String:
      return handleControlsValidators(stringOperatorCtrl, stringValueCtrl);
    case CustomerAttributeType.Integer:
      return handleControlsValidators(integerOperatorCtrl, integerValueCtrl, integerValue2Ctrl, definitionTypeCtrl);
    case CustomerAttributeType.Float:
      return handleControlsValidators(floatOperatorCtrl, floatValueCtrl, floatValue2Ctrl);
    case CustomerAttributeType.Boolean:
      return handleControlsValidators(booleanOperatorCtrl, booleanValueCtrl);
    case CustomerAttributeType.Datetime:
      return handleControlsValidators(dateOperatorCtrl, dateValueCtrl, dateValue2Ctrl, definitionTypeCtrl);
    default:
      return null;
  }
}

function handleControlsValidators(
  operatorCtrl: AbstractControl,
  valueCtrl: AbstractControl,
  value2Ctrl?: AbstractControl,
  definitionTypeCtrl?: AbstractControl
): ValidationErrors | null {
  const defintionTypeValue = definitionTypeCtrl ? definitionTypeCtrl.value : null;
  const value = valueCtrl?.value ?? null;
  const value2 = value2Ctrl?.value ?? null;

  if (defintionTypeValue && defintionTypeValue === CustomerAttributeType.Integer) {
    const isValueInteger = !!(value && value.toString().match(INTEGER_REGEX));
    if (!isValueInteger) {
      setRequiredIntegerControlValidators(valueCtrl);
      return {['requiredIntegerValue']: true};
    }
  }

  if (operatorCtrl?.value?.length) {
    if (operatorCtrl.value[0].id === 'bt') {
      if (defintionTypeValue && defintionTypeValue === CustomerAttributeType.Integer) {
        const isValue2Integer = !!(value2 && value2.toString().match(INTEGER_REGEX));
        if (!isValue2Integer) {
          setRequiredIntegerControlValidators(value2Ctrl);
          return {['requiredIntegerValue']: true};
        }
      }

      if (defintionTypeValue && defintionTypeValue === CustomerAttributeType.Datetime) {
        const isNoValue = !!(!value || (value instanceof Object && !Object.keys(value)?.length));
        const isNoValue2 = !!(!value2 || (value2 instanceof Object && !Object.keys(value2)?.length));
        if (isNoValue && isNoValue2) {
          setRequiredControlsValidators(valueCtrl);
          setRequiredControlsValidators(value2Ctrl);
          return {['fullDateRangeRequired']: true};
        } else if (isNoValue && !isNoValue2) {
          setRequiredControlsValidators(valueCtrl);
          return {['valueRequired']: true};
        } else if (!isNoValue && isNoValue2) {
          setRequiredControlsValidators(value2Ctrl);
          return {['value2Required']: true};
        }
      } else {
        return checkValue1GreaterThanValue2(valueCtrl, value2Ctrl);
      }
    } else {
      if (!value || (value instanceof Array && !value?.length) || (value instanceof Object && !Object.keys(value)?.length)) {
        setRequiredControlsValidators(valueCtrl);
        return {['valueRequired']: true};
      }
    }
  }

  if (!operatorCtrl.value || !operatorCtrl?.value?.length) {
    setRequiredControlsValidators(operatorCtrl);
    return {['operatorRequired']: true};
  }

  return null;
}

function setRequiredControlsValidators(ctrl: AbstractControl): void {
  setValidatorsErrorsMark(ctrl, Validators.required, 'invalidRequired');
}

function setRequiredIntegerControlValidators(ctrl: AbstractControl): void {
  setValidatorsErrorsMark(ctrl, Validators.pattern(INTEGER_REGEX), 'invalidInteger');
}

function setValidatorsErrorsMark(ctrl: AbstractControl, validator: ValidatorFn, errorKey: string): void {
  ctrl.setValidators([validator]);
  ctrl.setErrors({[errorKey]: true});
  ctrl.markAsDirty();
}