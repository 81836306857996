import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomersService } from '../../customers.service';
import { DataTableFilterService } from '../../../../../shared/components/data-table-filter/data-table-filter.service';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { registerLocaleData } from '@angular/common';
import { Subscription } from 'rxjs';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-consistence-tab',
  templateUrl: './consistence-tab.component.html',
  styleUrls: ['./consistence-tab.component.css']
})

export class ConsistenceTabComponent implements OnInit, OnDestroy {

  details: any;
  loading = false;
  subs$: Subscription[] = [];

  constructor(
    private customersService: CustomersService,
    private refreshCacheService: RefreshCacheService,
    private filterService: DataTableFilterService
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.getDetails();
    const filterChange$ = this.customersService.applyFilters$.subscribe(() => {
      this.getDetails();
    });
    this.subs$.push(filterChange$);
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach(s$ => s$.unsubscribe()); }
  }

  private getDetails() {
    let filters = {...this.customersService.filters};

    if (filters instanceof Object && filters.hasOwnProperty('channels')) {
      filters = this.customersService.filters['channels'];
    }

    delete filters.year;
    delete filters.feature_ids;
    delete filters.taxonomy;

    const params = { apiEndPoint: 'analytics/consistence', filtering: filters };
    this.loading = true;
    this.filterService.loaderStatus.next(false);
    const details$ = this.refreshCacheService.getExpensiveData(params).subscribe((data) => {
      if (!this.refreshCacheService.isRequestPending(data)) {
        this.details = data;
        this.loading = false;
        this.filterService.loaderStatus.next(true);
        details$.unsubscribe();
      }
    });
    this.subs$.push(details$);
  }

}
