import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { ModalStatusService } from '../../../../../shared/services/modal-status.service';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { CompanyPromotionsService } from '../../service/promotional-discounts.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-form-promotional-discounts',
  templateUrl: './form-promotional-discounts.component.html',
  styleUrls: ['./form-promotional-discounts.component.scss'],
  providers: [CompanyPromotionsService]
})
export class FormCompanyPromotionsComponent implements OnInit, OnDestroy {

  inputs: QuestionBase<unknown>[];
  id: number;
  companyPromotionsForm: UntypedFormGroup;
  
  private destroy$: Subject<void> = new Subject<void>();
  
  private get isEditMode(): boolean {
    return !!this.id;
  }

  @Output() formValidity: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private companyPromotionsSrv: CompanyPromotionsService,
    private confirmationService: ConfirmationService,
    private modalStatusService: ModalStatusService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  sendData(): void {
    const payload = {
      name: this.companyPromotionsForm.value.name ? this.companyPromotionsForm.value.name : null,
      discount: this.companyPromotionsForm.value.discount ? this.companyPromotionsForm.value.discount : 0
    };

    this.modalStatusService.formLoaderStatus.emit('loading');
    const request$ = this.isEditMode ? this.companyPromotionsSrv.updatePromotionType(payload, this.id) : this.companyPromotionsSrv.sendPromotionType(payload);
    request$.pipe(takeUntil(this.destroy$)).subscribe(
      () => this.handleSuccessRequest(),
      (errorData: HttpErrorResponse) => this.handleErrors(errorData)
    );
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getPromotionalDiscountById();
      } else {
        this.setInputsAndForm({});
      }
    });
  }

  private getPromotionalDiscountById(): void {
    this.companyPromotionsSrv.fetchSelectedById(this.id).pipe(takeUntil(this.destroy$)).subscribe(
      (data: HttpResponse<object>) => this.setInputsAndForm(data),
      (errorData: HttpErrorResponse) => this.displayErrorAlert(errorData.error.error)
    );
  }

  private setInputsAndForm(requestData: object | HttpResponse<object>): void {
    this.inputs = this.companyPromotionsSrv.getInputs(requestData);
    this.companyPromotionsForm = this.qcs.toFormGroup(this.inputs);
    this.formStatusChanges();
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
  }
  
  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.companyPromotionsForm.status));
    this.companyPromotionsForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
  }
  
  private handleSuccessRequest(): void {
    const successTitle = this.translate.instant(this.isEditMode ? 'resources.company_promotions.form.warnings.update_title' : 'resources.company_promotions.form.warnings.success_title');
    const successText = this.translate.instant(this.isEditMode ? 'resources.company_promotions.form.warnings.update_text' : 'resources.company_promotions.form.warnings.success_text');
    this.confirmationService.displaySuccessAlert(successTitle, successText).catch(() => {});
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    this.modalStatusService.modalStatus.emit();
    this.returnToList();
  }
  
  private handleErrors(errorData: HttpErrorResponse): void {
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    const errorValue = errorData?.error?.error ? errorData.error.error : null;
    if (errorData?.error?.errors?.length) {
      this.qcs.paintErrorsInForm(this.inputs, this.companyPromotionsForm, errorData.error.errors);
      if (errorValue) { this.displayErrorAlert(errorValue); }
    } else if (errorValue) {
      this.displayErrorAlert(errorValue);
    }
  }
  
  private displayErrorAlert(error: string): void {
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), error);
  }

  private returnToList(): void {
   this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }
}
