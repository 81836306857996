import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { MultiselectDataSource, MultiselectDataSourceable } from '../../shared/components/multiselect/multiselect';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable()
export class RolesService extends ResourcesService implements MultiselectDataSourceable {

  roleSlugsToFilter: string[] = [];

  constructor(http: HttpClient,
              private translate: TranslateService) {
    super(http);
  }

  fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'roles',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    if (this.roleSlugsToFilter && this.roleSlugsToFilter.length > 0) {
      return this.getData(requestOptions).pipe(
        map(data => {
          data['list'] = data['list'].filter(item => !this.roleSlugsToFilter.includes(item.slug));
          return data;
        })
      );
    } else {
      return this.getData( requestOptions );
    }
  }

  fetchSelectedById(id: number) {
    return this.getData({ apiEndPoint: `roles/${id}` });
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.name, element);
  }

  setRoleSlugsToFilter(roleSlugs: string[]) {
    this.roleSlugsToFilter = roleSlugs;
  }
}
