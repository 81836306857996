import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DataTableFilterService {

  loaderStatus: Subject<any> = new Subject<any>();
  loaderStatus$ = this.loaderStatus.asObservable();

  lookerFilterId: Subject<any> = new Subject<any>();
  lookerFilterId$ = this.lookerFilterId.asObservable();

  filtersLoaderStatus: Subject<any> = new Subject<any>();
  filtersLoaderStatus$ = this.filtersLoaderStatus.asObservable();

}
