import { Component, OnInit, Input } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { IncrementalReportData } from '../../report.model';

@Component({
  selector: 'app-show-report-incremental',
  templateUrl: './show-report-incremental.component.html',
  styleUrls: ['./show-report-incremental.component.css']
})
export class ShowReportIncrementalComponent implements OnInit {

  @Input() element: IncrementalReportData;

  constructor() { }

  ngOnInit() {
    registerLocaleData(es);
  }

}
