import { Injectable } from '@angular/core';
import BeePlugin from '@mailupinc/bee-plugin';
import { TranslateService } from '@ngx-translate/core';
import { ResourcesService } from '../../../services/resources.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { IBeeConfig, ModuleDescriptorOrderNames } from '@mailupinc/bee-plugin/dist/types/bee';

@Injectable()
export class CampaignTemplatesService extends ResourcesService {

  public specialLinks = [
    {
    type: this.translate.instant('resources.campaign_templates.modal.special_links.cancel_subscription'),
    label: this.translate.instant('resources.campaign_templates.modal.special_links.cancel_subscription'),
    link: '{unsubscribe}'
    },
    {
      type: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr'),
      label: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr_es'),
      link: 'https://gdpr-landing.web.app/#/gdpr/{gdpr_token}?locale=es'
    },
    {
      type: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr'),
      label: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr_cat'),
      link: 'https://gdpr-landing.web.app/#/gdpr/{gdpr_token}?locale=ca'
    },
    {
      type: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr'),
      label: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr_en'),
      link: 'https://gdpr-landing.web.app/#/gdpr/{gdpr_token}?locale=en'
    },
    {
      type: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr'),
      label: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr_it'),
      link: 'https://gdpr-landing.web.app/#/gdpr/{gdpr_token}?locale=it'
    },
    {
      type: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr'),
      label: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr_fr'),
      link: 'https://gdpr-landing.web.app/#/gdpr/{gdpr_token}?locale=fr'
    },
    {
      type: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr'),
      label: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr_de'),
      link: 'https://gdpr-landing.web.app/#/gdpr/{gdpr_token}?locale=de'
    },
    {
      type: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr'),
      label: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr_hr'),
      link: 'https://gdpr-landing.web.app/#/gdpr/{gdpr_token}?locale=hr'
    }
  ];

  public  mergeContents = [
    {
      name: this.translate.instant('resources.campaigns.content_variables.coupon_barcode'),
      value: '{coupon_barcode}'
    },
  ];

  public beeInstance: BeePlugin;


  constructor(http: HttpClient,
              private translate: TranslateService) {
    super(http);
  }

  public initBee(userData, cfg, schema, language, mergeTags: {name:string, value:string}[]) {
    const beeConfig: IBeeConfig = {
      uid: userData.company.slug, // needed for identify resources of the that user and billing stuff
      container: 'bee-plugin-container', // Identifies the id of div element that contains BEE Plugin
      language: language.concat('-', language.toUpperCase()),
      mergeTags: mergeTags,
      mergeContents: this.mergeContents,
      specialLinks: this.specialLinks,
      editorFonts: {
        showDefaultFonts: true,
        customFonts: [
          {
            name: 'Roboto Mono',
            fontFamily: '\'Roboto Mono\', monospace',
            url: 'https://fonts.googleapis.com/css2?family=Oswald'
          },
          {
            name: 'Recoleta',
            fontFamily: '\'Recoleta\', sans-serif',
            url: 'https://fonts.cdnfonts.com/css/recoleta'
          },
          {
            name: 'Work Sans',
            fontFamily: '\'Work Sans\', sans-serif',
            url: 'https://fonts.googleapis.com/css2?family=Work+Sans'
          },
          {
            name: 'Neue Machina',
            fontFamily: '\'Neue Machina\', sans-serif',
            url: 'https://lg-custom-fonts.s3.eu-west-1.amazonaws.com/neue_machina/neue_machina.css'
          },
          {
            name: 'Ginto Nord',
            fontFamily: '\'Ginto Nord\', sans-serif',
            url: 'https://lg-custom-fonts.s3.eu-west-1.amazonaws.com/ginto_nord/ginto_nord_v2.css'
          }
        ]
      },
      onSave: (_schema, _html) => {
        cfg.onSave(_schema, _html);
      }
    };

    this.beeInstance = new BeePlugin();

    this.beeInstance.getToken(environment.beePluginClientId, environment.beePluginKey).then(
      () => this.beeInstance.start(beeConfig, schema).then(
        () => this.beeInstance.load(schema)
      )
    ).catch(() => {});
  }

  public load(schema) {
    this.beeInstance.load(schema);
  }

  public getCompanyData() {
    return this.getData({ apiEndPoint: `users/me` });
  }

  public getById(id: number) {
    return this.getData({ apiEndPoint: `campaign_templates/${id}` });
  }

  public updateTemplateById(id, json, html, name) {
    return this.patchResource({schema: json, html: html, name: name}, `campaign_templates/${id}`);
  }
}
