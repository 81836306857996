<!-- BLOQUE3 FEATURES-->
  <div class="card height-100" *ngIf="couponDetail" >
    <div class="card-heading border bottom">
      <h4 class="card-title" [translate]="'resources.coupons.modal.show.details.fields.coupon_affectation'"></h4>
    </div>
    <div class="card-block">
      <div class="table-overflow">
        <table class="table table-hover" aria-hidden="true">
          <tbody>
            <tr *ngIf="couponDetail.min_money">
              <td><b [translate]="'resources.coupons.modal.show.details.fields.min_money'"></b></td>
              <td>{{couponDetail.min_money | number: '1.2-2' }}</td>
            </tr>
            <tr *ngIf="couponDetail.max_money">
              <td><b [translate]="'resources.coupons.modal.show.details.fields.max_money'"></b></td>
              <td>{{couponDetail.max_money | number: '1.2-2' }}</td>
            </tr>
            <tr *ngIf="couponDetail.min_units">
              <td><b [translate]="'resources.coupons.modal.show.details.fields.min_units'"></b></td>
              <td>{{couponDetail.min_units}}</td>
            </tr>
            <tr *ngIf="couponDetail.max_units">
              <td><b [translate]="'resources.coupons.modal.show.details.fields.max_units'"></b></td>
              <td>{{couponDetail.max_units}}</td>
            </tr>
            <tr *ngIf="couponDetail.discount">
              <td><b [translate]="'resources.coupons.modal.show.details.fields.discount'"></b></td>
              <td>{{couponDetail.discount | number:'':'es'}}</td>
            </tr>
            <tr *ngIf="couponDetail.affectation !== '[]'">
              <td><b [translate]="'resources.coupons.modal.show.details.fields.affectation'"></b></td>
              <td *ngIf="couponDetail.affectation === 'activity'" [translate]="'resources.coupons.modal.show.details.fields.activity'"></td>
              <td *ngIf="couponDetail.affectation === 'attribute'" [translate]="'resources.coupons.modal.show.details.fields.attribute'"></td>
              <td *ngIf="couponDetail.affectation === 'product'" [translate]="'resources.coupons.modal.show.details.fields.product'"></td>
            </tr>
            <tr>
              <td *ngIf="couponDetail.affectation === 'attribute' && couponDetail.features_list"><b [translate]="'resources.coupons.modal.show.details.fields.features'"></b><b> ({{couponDetail.features_list.length}})</b></td>
              <td *ngIf="!couponDetail.features_list"><b [translate]="'resources.coupons.modal.show.details.fields.features'"></b><b> (0)</b></td>
              <td *ngIf="!couponDetail.features_list"></td>
              <td *ngIf="couponDetail.affectation === 'attribute' && couponDetail.features_list">
                <ul class="ul-style">
                  <li *ngFor="let item of couponDetail.features_list | slice: 0:4">
                    {{item.name }}
                  </li>
                </ul>
              </td>
            </tr>
            <tr *ngIf="couponDetail.coupon_type && couponDetail.purchased_feature_pks">
              <td><b [translate]="'resources.coupons.modal.show.details.fields.you_buy'"></b></td>
              <td>{{couponDetail.purchased_features_list[0].name}}</td>
            </tr>
            <tr *ngIf="couponDetail.features_list.length && couponDetail.coupon_type && couponDetail.feature_pks && couponDetail.applies_to === 'itself'">
              <td>
                <b [translate]="'resources.coupons.modal.show.details.fields.you_buy'"></b>
              </td>
              <td>{{couponDetail.features_list[0].name}}</td>
            </tr>
            <tr *ngIf="couponDetail.features_list.length && couponDetail.coupon_type && couponDetail.feature_pks">
              <td>
                <b [translate]="'resources.coupons.modal.show.details.fields.you_receive'"></b>
              </td>
              <td>{{couponDetail.features_list[0].name}}</td>
            </tr>
          </tbody>
        </table>
        <div class="border top" *ngIf="couponDetail.affectation === 'attribute' && couponDetail.features_list?.length > 10">
          <div class="pdd-vertical-15 pdd-horizon-20 text-center">
            <a data-toggle="modal" data-target="#modal-features" class="text-semibold text-dark pointer">
              <span class="btn btn-light" [translate]="'resources.coupons.modal.show.buttons.show_all_features'"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- END BLOQUE3 -->
