<div class="page-title background-white">
  <div class="row pdd-horizon-15">
    <div class="col-md-9 d-flex align-items-center min-height-60px">
      <a (click)="goBack()" class="text-blue pointer">
        <i class="fa-thin fa-arrow-left"></i>&nbsp;<u>{{'common.back' | translate}}</u>
      </a>
    </div>
  </div>
  <div class="row pdd-horizon-15">
    <div class="col-md-9 d-flex align-items-center min-height-60px">
      <h4 *ngIf="!id" class="no-mrg-vertical">{{'resources.promotions.form.title' | translate}}</h4>
      <h4 *ngIf="id" class="no-mrg-vertical">{{'resources.promotions.form.edit_title' | translate}}</h4>
    </div>
  </div>
</div>

<app-loader noWrapper *ngIf="loading" />
<form [formGroup]="promotionsForm" *ngIf="promotionsForm">
  <div class="mrg-top-15 container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading border bottom">
            <h4 class="card-title" [translate]="'resources.promotions.form.details'"></h4>
          </div>
          <div class="card-block">
            <div class="row">
              <div class="col-md-12">
                <img *ngIf="id" class="img-fluid max-height-vh-73" src="{{promotionsData.image}}" alt="image">
                <div class="row flex-column">
                  <div class="col-md-6">
                    <label for="image" class="mrg-top-20 font-size-14 text-bold text-no-wrap"
                      [translate]="'resources.promotions.form.image'">
                    </label>
                    <app-question [question]="getInputConfig('image')"
                                  (change)="onFileChange($event)"
                                  [form]="promotionsForm">
                    </app-question>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mrg-top-30">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="name" class="font-size-14 text-bold text-no-wrap"
                        [translate]="'resources.promotions.form.name'"></label>
                      <span *ngIf="getInputConfig('name').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('name')"
                                    [form]="promotionsForm">
                      </app-question>
                    </div>
                  </div>
                </div>

                <label class="font-size-14 text-bold text-no-wrap">{{'resources.promotions.form.mechanic' |
                  translate}}</label>
                <span class="text-danger">*</span>
                <em class="ti-info-alt mrg-top-4 mrg-left-5" tooltipPosition="top"
                  pTooltip="{{ 'resources.promotions.form.tooltip.description' | translate }}">
                </em>
                <div class="row mrg-btm-20">
                  <div class="col-md-12">
                    <textarea *ngIf="!promotionsData" [ngxSummernote]="config"></textarea>
                    <textarea *ngIf="promotionsData" [(ngModel)]="promotionsData.description"
                      [ngModelOptions]="{standalone: true}" [ngxSummernote]="config"
                      (ngModelChange)="handleDescriptionValue($event)">
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading border bottom">
            <h4 class="card-title" [translate]="'resources.promotions.form.availability'"></h4>
          </div>
          <div class="card-block">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="available_from" class="font-size-14 text-bold text-no-wrap"
                    [translate]="'resources.promotions.form.available_from'"></label>
                  <span *ngIf="getInputConfig('available_from').required" class="text-danger">*</span>
                  <app-question [question]="getInputConfig('available_from')"
                                [form]="promotionsForm">
                  </app-question>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="available_to" class="font-size-14 text-bold text-no-wrap"
                    [translate]="'resources.promotions.form.available_to'"></label>
                  <span *ngIf="getInputConfig('available_to').required" class="text-danger">*</span>
                  <app-question [question]="getInputConfig('available_to')"
                                [form]="promotionsForm">
                  </app-question>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="cumulative" class="font-size-14 text-bold text-no-wrap"
                    [translate]="'resources.promotions.form.cumulative'">
                  </label>
                  <i class="font-size-14 tooltip-position ti-info-alt mrg-top-8 mrg-left-5 positioning" tooltipPosition="left"
                    pTooltip="{{ 'resources.promotions.form.tooltip.cumulative' | translate }}"></i>
                  <app-question [question]="getInputConfig('cumulative')"
                                [form]="promotionsForm">
                  </app-question>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="feature_location_ids" class="font-size-14 text-bold text-no-wrap"
                    [translate]="'resources.promotions.form.feature_location_ids'"></label>
                  <app-question [question]="getInputConfig('feature_location_ids')"
                                [form]="promotionsForm">
                  </app-question>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="location_ids" class="font-size-14 text-bold text-no-wrap"
                    [translate]="'resources.promotions.form.location_ids'"></label>
                  <app-question [question]="getInputConfig('location_ids')"
                                [form]="promotionsForm">
                  </app-question>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="discount_type" class="font-size-14 text-bold text-no-wrap">
                        <span>{{'resources.promotions.form.discount_type' | translate}}</span>
                        <span *ngIf="getInputConfig('discount_type').required" class="text-danger">*</span>
                        <em class="ti-info-alt pull-right mrg-top-4 mrg-left-5" tooltipPosition="top"
                          pTooltip="{{ 'resources.promotions.form.tooltip.discount_type' | translate }}">
                        </em>
                      </label>
                      <app-question [question]="getInputConfig('discount_type')"
                                    [form]="promotionsForm">
                      </app-question>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="discount" class="font-size-14 text-bold text-no-wrap">
                        <span>{{'resources.promotions.form.discount' | translate}}</span>
                        <span *ngIf="getInputConfig('discount').required" class="text-danger">*</span>
                        <em class="ti-info-alt pull-right mrg-top-4 mrg-left-5" tooltipPosition="top"
                          pTooltip="{{ 'resources.promotions.form.tooltip.discount' | translate }}">
                        </em>
                      </label>
                      <app-question [question]="getInputConfig('discount')"
                                    [form]="promotionsForm">
                      </app-question>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="min_money" class="font-size-14 text-bold text-no-wrap">
                        <span>{{'resources.coupons.form.min_money' | translate}}({{currencySymbol}})</span>
                        <em class="ti-info-alt pull-right mrg-top-4 mrg-left-5" tooltipPosition="top"
                          pTooltip="{{ 'resources.promotions.form.tooltip.min_money' | translate }}">
                        </em>
                      </label>
                      <app-question [question]="getInputConfig('min_money')"
                                    [form]="promotionsForm">
                      </app-question>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="max_money" class="font-size-14 text-bold text-no-wrap">
                        <span>{{'resources.promotions.form.max_money' | translate}}({{currencySymbol}})</span>
                        <em class="ti-info-alt pull-right mrg-top-4 mrg-left-5" tooltipPosition="top"
                          pTooltip="{{ 'resources.promotions.form.tooltip.max_money' | translate }}">
                        </em>
                      </label>
                      <app-question [question]="getInputConfig('max_money')"
                                    [form]="promotionsForm">
                      </app-question>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="min_units" class="font-size-14 text-bold text-no-wrap">
                        <span>{{'resources.promotions.form.min_units' | translate}}</span>
                        <em class="ti-info-alt pull-right mrg-top-4 mrg-left-5" tooltipPosition="top"
                          pTooltip="{{ 'resources.promotions.form.tooltip.min_units' | translate }}">
                        </em>
                      </label>
                      <app-question [question]="getInputConfig('min_units')"
                                    [form]="promotionsForm">
                      </app-question>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="max_units" class="font-size-14 text-bold text-no-wrap">
                        <span>{{'resources.promotions.form.max_units' | translate}}</span>
                        <em class="ti-info-alt pull-right mrg-top-4 mrg-left-5" tooltipPosition="top"
                          pTooltip="{{ 'resources.promotions.form.tooltip.max_units' | translate }}">
                        </em>
                      </label>
                      <app-question [question]="getInputConfig('max_units')"
                                    [form]="promotionsForm">
                      </app-question>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="affectation" class="font-size-14 text-bold text-no-wrap">
                        <span>{{'resources.promotions.form.affectation' | translate}}</span>
                        <span *ngIf="getInputConfig('affectation').required" class="text-danger">*</span>
                        <em class="ti-info-alt pull-right mrg-top-4 mrg-left-5" tooltipPosition="top"
                          pTooltip="{{ 'resources.promotions.form.tooltip.affectation' | translate }}">
                        </em>
                      </label>
                      <app-question [question]="getInputConfig('affectation')"
                                    [form]="promotionsForm">
                      </app-question>
                    </div>

                    <div class="row" *ngIf="hasFormKeyWithValue('affectation', 'attribute')">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="font-size-14 text-bold" for="feature_pks"
                                [translate]="'resources.promotions.form.product_category'">
                          </label>
                          <span *ngIf="getInputConfig('feature_pks').required" class="text-danger">*</span>
                          <app-question [question]="getInputConfig('feature_pks')"
                                        [form]="promotionsForm">
                          </app-question>
                        </div>
                      </div>
                    </div>

                    <div class="row" *ngIf="hasFormKeyWithValue('affectation', 'product')">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="font-size-14 text-bold" for="product_ids"
                            [translate]="'resources.coupons.form.product_ids'"></label>
                            <span *ngIf="getInputConfig('product_ids').required" class="text-danger">*</span>
                          <app-question [question]="getInputConfig('product_ids')"
                                        [form]="promotionsForm">
                          </app-question>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="ml-auto mr-auto text-right">
                          <span *ngIf="loading"><em class="fa fa-spinner fa-spin"></em></span>
                          <button class="btn btn-default mrg-right-10" (click)="goBack()">
                            {{'common.buttons.cancel' | translate}}
                          </button>
                          <button class="btn btn-info no-mrg-right"
                                  (click)="sendData()"
                                  [translate]="'resources.promotions.buttons.send'"
                                  [disabled]="!promotionsForm.valid">
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
