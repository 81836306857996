import { Injectable } from '@angular/core';
import { QuestionBase } from '../../../../models/forms/question-base';
import { MultiSelectQuestion } from '../../../../models/forms/question-multiselect';
import { SegmentConditionProvider } from '../../condition.service';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDateQuestion } from '../../../../models/forms/question-dynamic-date';
import { DateService } from '../../../date.service';
import { formGroupEmptyValidator, multiselectPresenceValidator } from '../../../validations.service';
import { defaultParseFormValuesToAPI, getParseDynamicDateValue, getParseObjectKeysIdValue, getParseOperatorValue, getParseSingleSelectOperatorIdValue, getParseValue, parseParamsValues } from '../../utils/common.utils';
import { OperatorsService } from '../../common/operators.service';
import { ClientRegistrationDateCondition, ClientRegistrationDateConditionForm } from '../../../../models/segments/conditions/client-registration-date-condition';

@Injectable()
export class ClientRegistrationDateConditionService implements SegmentConditionProvider {

  inputs: QuestionBase<unknown>[];
  customValidators = formGroupEmptyValidator();

  private operatorOpts = this.operatorsService.getNumericWithoutBtOperators();

  constructor(
    private translate: TranslateService,
    private dateService: DateService,
    private operatorsService: OperatorsService
  ) {}

  getInputs(params: ClientRegistrationDateConditionForm): QuestionBase<unknown>[] {
    const inputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'operator',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorOpts,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        getValue: getParseValue(),
        parseValue: getParseSingleSelectOperatorIdValue(this.operatorOpts),
        customValidators: [multiselectPresenceValidator]
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'value',
        label: this.translate.instant('resources.segment_conditions.fields.date'),
        required: true,
        hideNoDateOpt: true,
        getValue: getParseDynamicDateValue(this.dateService),
        parseValue: getParseObjectKeysIdValue()
      })
    ];

    parseParamsValues(params, inputs);
    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI(params: ClientRegistrationDateConditionForm): ClientRegistrationDateCondition {
    return defaultParseFormValuesToAPI(params, this.inputs);
  }

  prepareFormValuesFromAPI(params: ClientRegistrationDateCondition): ClientRegistrationDateConditionForm {
    const parseValues = {
      operator: getParseOperatorValue(this.operatorOpts),
      value: getParseValue()
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach( key => { parsedValuesObj[key] = parseValues[key](params[key]); });
    return parsedValuesObj;
  }
}
