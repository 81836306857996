<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true" *ngIf="walletsData">
              <tbody>
                <tr *ngIf="walletsData.name">
                  <td class="no-border-top"><b [translate]="'resources.scores.wallets.show.fields.name'"></b></td>
                  <td class="no-border-top">{{walletsData.name}}</td>
                </tr>
                <tr *ngIf="walletsData.points">
                  <td><b [translate]="'resources.scores.wallets.show.fields.score'"></b></td>
                  <td>{{walletsData.points}}</td>
                </tr>
                <tr *ngIf="walletsData.reward.name">
                  <td><b [translate]="'resources.scores.wallets.show.fields.reward'"></b></td>
                  <td>
                    <a class="pointer colorLink" (click)="goToReward()">
                      {{walletsData.reward.name}}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
