<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header background-white">
        <div class="row flex-grow-1">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 class="card-title">{{ 'resources.reports.form.fields.detail' | translate }}</h1>				
          </div>
        </div>
				<button class="modal-close" (click)="closeModal()">
					<i class="ti-close"></i>
				</button>
			</div>

      <div class="modal-body height-100 background-gray mrg-left-15 mrg-right-15">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-block">
                <form class="form-horizontal" *ngIf="reportSegmentForm">

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <h4 class="card-title" [translate]="'resources.reports.form.fields.details'"></h4>
                        <hr>

                        <div class="row flex-column">
                          <label for="name" class="col-md-3 mrg-top-10 text-semibold control-label" [translate]="'resources.reports.form.fields.name'"></label>
                          <div class="col-md-6">
                            <app-question [question]="getInputConfig('name')" [form]="reportSegmentForm"></app-question>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="row mrg-top-10">
                    <div class="col-md-6">
                      <span class="font-size-16 card-title" [translate]="'resources.reports.form.fields.analysis_date'"></span>
                    </div>            
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <app-question [question]="getInputConfig('date_from')" [form]="reportSegmentForm"></app-question>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <app-question [question]="getInputConfig('date_to')" [form]="reportSegmentForm"></app-question>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <app-question [question]="getInputConfig('segment_ids')" [form]="reportSegmentForm"></app-question>
                    </div>
                  </div>     

                </form>

              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="modal-footer background-white">
				<ng-container>
          <span><i class="fa fa-spinner fa-spin" *ngIf="loading"></i></span>
          <button class="btn btn-default" (click)="returnToList()" [translate]="'resources.reports.buttons.back'"></button>
					<button class="btn btn-info" (click)="sendData()" [translate]="'resources.reports.buttons.send'"></button>
				</ng-container>
			</div>

    </div>
  </div>
</div>









