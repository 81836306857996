<div class="card">
  <div class="card-heading border bottom">
    <h4 class="text-bold card-title" [translate]="'resources.scores.loyalty_scores.fields.limits'"></h4>
  </div>
  <div class="card-block">
    <div class="alert alert-info" role="alert">
      <span class="alert-text">{{'resources.scores.loyalty_scores.descriptions.limits' | translate}}</span>
    </div>
    <!-- CHANCES TO WIN -->
    <div class="row">
      <label for="chancesToWin" class="text-bold mrg-left-15"
        [translate]="'resources.scores.loyalty_scores.fields.chances_to_win'"></label>
    </div>
    <div class="row flex-end">
      <div class="col-md-6">
        <div class="form-group">
          <app-question [question]="getInputConfig('once')" [form]="loyaltyScoresForm"
                        (multiselectChanged)="chancesToWinSelectorChange($event)"
                        [disabled]="isUsed">
          </app-question>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <app-question [question]="getInputConfig('once_limit')" [form]="loyaltyScoresForm"
                        [disabled]="isUsed">
          </app-question>
        </div>
      </div>
    </div>
  </div>
</div>
