import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../models/forms/question-base';
import { TextareaQuestion } from '../models/forms/question-textarea';
import { QuestionControlService } from '../services/question-control.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from '../services/confirmation.service';
import { ImportProductsService } from './import-products.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-import-products',
  templateUrl: './import-products.component.html',
  styleUrls: ['./import-products.component.css']
})
export class ImportProductsComponent implements OnInit, OnDestroy {

  @ViewChild('modalToggleBtn') modalToggleBtn: ElementRef;
  @Output() productIdsEmitter = new EventEmitter();

  form: UntypedFormGroup;
  inputs: QuestionBase<any>[];
  failedImportProducts: string = '';
  sub$ = new Subscription();
  loading = false;

  constructor(
    private qcs: QuestionControlService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private importProductsService: ImportProductsService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.inputs = this.getInputs();
    this.form = this.qcs.toFormGroup(this.inputs);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
    this.failedImportProducts = '';
  }

  openModal() {
    this.modalToggleBtn.nativeElement.click();
  }

  closeModal(withConfirmation: boolean = true) {
    if (withConfirmation) {
      const text = this.translate.instant('resources.campaign_plans.types.supplier.messages.import_products_cancel');
      this.confirmationService.displayConfirmationAlert('', text).then(data => {
        if (data.hasOwnProperty('value') && data.value) {
          this.form.reset();
          this.modalToggleBtn.nativeElement.click();
        }
      }).catch(() => {});
    } else {
      this.form.reset();
      this.modalToggleBtn.nativeElement.click();
    }
  }

  save() {
    this.loading = true;
    const productIds = this.form.value['product_ids'];

    this.importProductsService.setIsActive(true);
    this.failedImportProducts = '';

    this.productIdsEmitter.emit(productIds);

    this.sub$ = this.importProductsService.getFailedProducts().subscribe(
      (data) => {
        if (data.length) {
          this.failedImportProducts = data.join(', ');
          this.cd.detectChanges();
        } else {
          this.closeModal(false);
        }
        this.loading = false;
        this.importProductsService.setIsActive(false);
        this.sub$.unsubscribe();
      }
    );

    this.closeModal(false);
  }

  private getInputs(): QuestionBase<any>[] {
    return [
      new TextareaQuestion({
        key: 'product_ids',
        type: 'textarea',
        cssClasses: 'form-control input-default',
        placeholder: this.translate.instant('resources.campaign_plans.types.supplier.fields.product_ids_tooltip'),
        required: true
      })
    ];
  }
}
