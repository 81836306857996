import { Injectable } from '@angular/core';
import { ResourcesService } from './resources.service';
import { MultiselectDataSourceable, MultiselectDataSource } from '../components/multiselect/multiselect';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DateService } from './date.service';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SystemTagHistoriesService extends ResourcesService implements MultiselectDataSourceable {

  public segmentId = null;
  public optsSource = new BehaviorSubject<number>(null);
  public optsSource$ = this.optsSource.asObservable();

  constructor( http: HttpClient,
               private dateService: DateService ) {
    super(http);
  }

  public setSegment(segmentId: number) {
    this.segmentId = segmentId;
    this.optsSource.next( segmentId );
  }

  public fetchMultiselect( _?: string, page?: number ): Observable<object> {

    if ( !this.segmentId ) { return of({}); }

    const requestOptions = this.getRequestOpts();
    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;
    return this.getData( requestOptions );
  }

  public fetchSelectedById(apply: number | string): Observable<any> {
    return this.getData(this.getRequestOpts()).pipe(
            map( itemArray => itemArray['list'] ),
            map( element => {
              return element.filter( _tagHistory => _tagHistory.apply === apply )[0];
            }));
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    const dateLabel = this.dateService.parseDateWithFormat(element.created_at, 'DD/MM/YYYY');
    return new MultiselectDataSource(element.apply, dateLabel, element);
  }

  private getRequestOpts() {
    return {
      apiEndPoint: 'tag_histories',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {
        segment_id: this.segmentId
      }
    };
  }
}
