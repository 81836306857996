import { AbstractControl, UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";
import { isNullOrUndefined } from "../../../../../utils/common.utils";
import { isCtrlPositiveInteger, setValidationPositiveIntegerErrorsMark } from "../../../validations/common.validations";
import { POSITIVE_INTEGER_REGEX } from "../../../constants/condition.constants";
import { checkValue1GreaterThanValue2 } from "../../../../validations.service";

export function getFormGroupValidations(control: UntypedFormGroup): ValidationErrors | null {

  const scopeKey = Object.keys(control.controls).find((_key => _key.indexOf('scope') >= 0));
  const scope = control.get(scopeKey);
  const products = control.get('product_ids');
  const features = control.get('feature_ids');
  const totalSumOp = control.get('total_sum_operator');
  const totalSumValue = control.get('total_sum_value');
  const totalSumValue1 = control.get('total_sum_value_1');
  const totalSumValue2 = control.get('total_sum_value_2');
  const ticketCountOp = control.get('activity_count_operator');
  const ticketCountValue = control.get('activity_count_value');
  const ticketCountValue1 = control.get('activity_count_value_1');
  const ticketCountValue2 = control.get('activity_count_value_2');

  [products, features, totalSumValue, totalSumValue1, totalSumValue2, ticketCountValue, ticketCountValue1, ticketCountValue2]
    .forEach(_ctrl => _ctrl.setValidators([]));

  const featureValidator = setScopeValidators(scope, 'feature', features, 'featuresRequired');
  if (featureValidator) { return featureValidator; }

  const productValidator = setScopeValidators(scope, 'product', products, 'productsRequired');
  if (productValidator) { return productValidator; }

  const totalSumValidator = setOperatorsValidators(totalSumOp, totalSumValue, totalSumValue1, totalSumValue2, 'totalSumOpRequired', 'totalSumValueRequired');
  if (totalSumValidator) { return totalSumValidator; }

  const ticketCountValidator = setOperatorsValidators(ticketCountOp, ticketCountValue, ticketCountValue1, ticketCountValue2, 'ticketCountOpRequired', 'ticketCountValueRequired', true);
  if (ticketCountValidator) { return ticketCountValidator; }

  return null;
}

function setScopeValidators(scope: AbstractControl, scopeKeyValue: string, control: AbstractControl, requiredValidator: string): ValidationErrors | null {
  if (scope.value === scopeKeyValue) {
    const controlValues = control?.value?.length ? control.value[0].id : null;
    if (isNullOrUndefined(controlValues)) {
      const responseObj = {};
      responseObj[requiredValidator] = true;
      return responseObj;
    }
  }

  return null;
}

function setOperatorsValidators(
  operator: AbstractControl,
  operatorValue: AbstractControl,
  operatorValue1: AbstractControl,
  operatorValue2: AbstractControl,
  operatorRequiredValidator: string,
  operatorValueRequiredValidator: string,
  positiveIntegerValidation: boolean = false
): ValidationErrors | null {
  if (operator?.value?.length && operator.value[0].id !== 'bt') {
    if (isNullOrUndefined(operatorValue.value) || isNaN(parseFloat(operatorValue.value))) {
        operatorValue.setValidators([Validators.required]);
        operatorValue.setErrors({'invalid': true});
        operatorValue.markAsDirty();
        return {[operatorValueRequiredValidator]: true};
    } else if (positiveIntegerValidation && !isCtrlPositiveInteger(operatorValue)) {
      operatorValue.setValidators([Validators.required, Validators.pattern(POSITIVE_INTEGER_REGEX)]);
      return setValidationPositiveIntegerErrorsMark(operatorValue);
    }
  } else if (operator?.value?.length && operator.value[0].id === 'bt') {
    if (positiveIntegerValidation) {
      operatorValue1.setValidators([Validators.required, Validators.pattern(POSITIVE_INTEGER_REGEX)]);
      operatorValue2.setValidators([Validators.required, Validators.pattern(POSITIVE_INTEGER_REGEX)]);
      if (!isCtrlPositiveInteger(operatorValue1)) {
        return setValidationPositiveIntegerErrorsMark(operatorValue1);
      } else if (!isCtrlPositiveInteger(operatorValue2)) {
        return setValidationPositiveIntegerErrorsMark(operatorValue2);
      } else {
        return checkValue1GreaterThanValue2(operatorValue1, operatorValue2);
      }
    } else {
      const rangeValidity = checkValue1GreaterThanValue2(operatorValue1, operatorValue2);
      if (rangeValidity !== null) {return rangeValidity; }
    }
  }

  if (!isNullOrUndefined(operatorValue.value) && !isNaN(parseFloat(operatorValue.value))) {
    if (!operator.value || (operator.value && operator.value.length === 0)) {
      return {[operatorRequiredValidator]: true};
    }
  }

  return null;
}