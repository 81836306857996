import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocationsService } from './../../../resources/data-warehouse/locations/locations.service';
import { FeaturesService } from './../../../resources/data-warehouse/products/features.service';
import { QuestionBase } from '../../models/forms/question-base';
import { DateService } from '../../services/date.service';
import { SalesService } from './../../../resources/analytics/sales/sales.service';
import { ConfirmationService } from '../../services/confirmation.service';
import { DataTableFilterService } from '../data-table-filter/data-table-filter.service';
import { CustomersService } from './../../../resources/analytics/customers/customers.service';
import { LaunchDarklyService } from '../../services/launch-darkly/launchdarkly.service';
import { FeatureTaxonomiesService } from '../../../resources/data-warehouse/products/feature-taxonomies.service';
import { ProductsService } from '../../../resources/data-warehouse/products/products.service';
import { HttpErrorResponse } from '@angular/common/http';
import { combineLatest, Subscription } from 'rxjs';
import { ProfileService } from '../../../profiles/profile.service';
import { CurrentCompany } from '../../models/current-user';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import { getCurrencySymbol } from '@angular/common';
import { filter, take } from 'rxjs/operators';
import { DashCategoriesService } from '../../../resources/analytics/categories/dash-categories.service';
import { TabBase } from './models/tab-base.model';
import { DynamicBaseFiltersService } from './services/dynamic-base-filters.service';
import { SegmentCategoriesService } from '../../../resources/segments/segment-categories.service';
import { handlePeriodFilter, handleECRTaxonomySlugKind } from '../../utils/dynamic-data.utils';
import { CountriesService } from '../../services/countries.service';
import { LOYALTY_SLUGS_TABS_NO_LOOKER, LOYALTY_SLUGS_TABS_WITH_COUNTRY_FF, SLUGS_TABS_TO_SET_COUNTRY, TAG_DATE_REGEX } from './constants/dynamic-tabs.constants';
import { isIntegerValue1GreaterThanValue2 } from '../../utils/common.utils';
import { FidelizationService } from '../../../resources/analytics/fidelization/fidelization.service';
import { FeatureFlagsService } from '../../services/feature-flags.service';

@Component({
  selector: 'app-dynamic-tabs',
  templateUrl: './dynamic-tabs.component.html',
  styleUrls: ['./dynamic-tabs.component.scss'],
  providers: [
    CustomersService,
    { provide: 'featuresPkService', useClass: FeaturesService },
    { provide: 'featuresSupplierECRPkService', useClass: FeaturesService },
    { provide: 'featuresBrandPkService', useClass: FeaturesService },
    { provide: 'featuresBrandECRPkService', useClass: FeaturesService }
  ]
})

export class DynamicTabsComponent implements OnInit, OnDestroy {

  featureflags = this.featureFlags.flags;
  title: string;
  tabs: TabBase[];
  currentSlug: string;
  activeTab: {name: string, title: string} = {name: 'summary', title: 'summary'};
  tabsFilters: object = {};
  config: object = {};
  flags: object = {};
  subs$: Subscription[] = [];
  company: CurrentCompany;
  currencySymbol: string;
  isOnSelectTab = false;
  dateFrom: any;
  dateTo: any;

  private baseFilters: QuestionBase<any>[];

  private get applyCountryFFValue(): boolean {
    return !!(this.featureflags?.country && this.featureflags.country !== 'All');
  }

  constructor(
    @Inject('featuresPkService') private readonly featuresPkService: FeaturesService,
    @Inject('featuresSupplierECRPkService') private readonly featuresSupplierECRPkService: FeaturesService,
    @Inject('featuresBrandPkService') private readonly featuresBrandPkService: FeaturesService,
    @Inject('featuresBrandECRPkService') private readonly featuresBrandECRPkService: FeaturesService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private countriesService: CountriesService,
    private locationsService: LocationsService,
    private featuresService: FeaturesService,
    private dateService: DateService,
    private salesService: SalesService,
    private confirmationService: ConfirmationService,
    private filterService: DataTableFilterService,
    private customersService: CustomersService,
    private launchDarklyService: LaunchDarklyService,
    private featureTaxonomiesService: FeatureTaxonomiesService,
    private productsService: ProductsService,
    private profileService: ProfileService,
    private dashCategoriesService: DashCategoriesService,
    private fidelizationService: FidelizationService,
    private dynamicBaseFiltersService: DynamicBaseFiltersService,
    private segmentCategoriesService: SegmentCategoriesService,
    private featureFlags: FeatureFlagsService
  ) {}

  ngOnInit(): void {
    this.processFeatureFlag();
    this.title = this.translate.instant(this.route.snapshot.data.title);
    this.config = this.route.snapshot.data.config;
    this.currentSlug = this.route.firstChild && this.route.firstChild.snapshot.data.slug ? this.route.firstChild.snapshot.data.slug : 'summary';
    this.company = new CurrentCompany(this.profileService.getProfileCompany());
    this.currencySymbol = getCurrencySymbol(this.company.currency, 'wide');

    this.dateFrom = this.dateService.momentStartOfPreviousPeriodTypeAndFormat(1, 'months', 'month', 'YYYY-MM-DD');
    this.dateTo = this.dateService.momentEndOfPreviousPeriodTypeAndFormat(1, 'months', 'month', 'YYYY-MM-DD');

    // This is required when implemented for sales dashboards (summary and retail)
    this.salesService.filters = {
      country: 'ES',
      date_from: this.dateFrom,
      date_to: this.dateTo
    };

    this.getTaxonomies();
    this.handleSelectedTab();
  }

  ngOnDestroy(): void {
    if (this.subs$) { this.subs$.forEach( s$ => s$.unsubscribe() ); }
    this.featuresService.setTaxonomies(null);
    this.productsService.setFeatures(null);
    this.locationsService.setLocationTaxonomyTermIds(null);
    this.segmentCategoriesService.onlyCategoriesWithSegments = false;
    this.segmentCategoriesService.onlyRFMCategories = false;
  }

  selectTab(slug): void {
    this.isOnSelectTab = true;
    this.changeTitle(slug);
    this.currentSlug = slug;
    this.handleFiltersOnTabSelection();
  }

  onFiltersChangeValue(currentTabSlug: string, currentSlugFiltersValues: object): void {
    Object.keys(this.tabsFilters).forEach(key => {
      this.tabsFilters[key].inputs.forEach(input => {
        const currentSlugFilterKey = Object.keys(currentSlugFiltersValues).find(filterKey => filterKey === input.key);
        if (currentSlugFilterKey) {
          input.selectedIds = [];
          if (input?.dataSource?.refreshSelectedOptsSource) { input.dataSource.refreshSelectedOptsSource([], input.key); }
          if (key !== currentTabSlug) {
            const isCurrentTabWithPeriodDatesFilters = !!(this.tabsFilters[currentTabSlug].inputs.find(input => input.key === 'period') && (currentSlugFilterKey === 'date_from' || currentSlugFilterKey === 'date_to'));
            if (isCurrentTabWithPeriodDatesFilters) {
              this.setCurrentTabSlugDateFilters(key, input, currentSlugFilterKey, currentSlugFiltersValues);
            } else {
              this.setCurrentSlugFiltersValues(key, input, currentSlugFilterKey, currentSlugFiltersValues);
            }
          } else {
            input.value = currentSlugFiltersValues[currentSlugFilterKey];
            this.handleShowSingleECRTaxonomySlugKindDashboardFilterCfg(key, input, currentSlugFilterKey);
          }

          if (currentSlugFiltersValues.hasOwnProperty('taxonomy_slug')) {
            const taxonomyValue = currentSlugFiltersValues?.['taxonomy_slug']?.[0];
            const inputs = this.tabsFilters[key]?.inputs;
            handleECRTaxonomySlugKind(taxonomyValue, inputs, null);
          }
        }
      });
    });
  }

  onFilterHandler(slug: string, filterValues: object): void {
    const filterFormValue = filterValues;
    // Date consistency validation
    if (filterFormValue['date_from'] && filterFormValue['date_to']) {
      if (!this.compareDates(filterFormValue, 'date_from', 'date_to')) {
        return;
      }
    }

    if (filterFormValue['date_from_compared'] && filterFormValue['date_to_compared']) {
      if (!this.compareDates(filterFormValue, 'date_from_compared', 'date_to_compared')) {
        return;
      }
    }

    if (filterFormValue['tag_date_origin'] && filterFormValue['tag_date_destination'] && isIntegerValue1GreaterThanValue2(filterFormValue['tag_date_origin'], filterFormValue['tag_date_destination'])) {
      return this.handleInvalidDates(false);
    }

    Object.keys(filterFormValue).forEach(formValue => {
      filterFormValue[formValue] = this.parseValueToSubmit(formValue, filterFormValue[formValue], filterFormValue);
    });

    // TODO: Remove conditional and fix from dynamic tabs configuration
    const customersServiceSlugs = ['billing', 'typology', 'club_evolution', 'club_indicators', 'vouchers_count', 'type_redemptions', 'channels'];
    if (customersServiceSlugs.includes(slug)) {
      delete filterValues['apply'];
      delete filterValues['tag_id'];
      this.customersService.filters[slug] = filterValues;
      this.customersService.applyFilters$.next(filterValues);
    } else if (slug === 'migrations' || slug === 'active_attributes' || slug === 'categories_evolution') {
      delete filterFormValue['apply'];
      delete filterFormValue['tag_id'];
      this.dashCategoriesService.filters = { ...filterFormValue };
      this.dashCategoriesService.applyFilters$.next();
    } else if (LOYALTY_SLUGS_TABS_NO_LOOKER.includes(slug)) {
      this.fidelizationService.filters = { ...filterFormValue };
      this.fidelizationService.applyFilters$.next();
    } else {
      if (!filterFormValue.hasOwnProperty('country')) {
        filterFormValue['country'] = 'ES';
        const countryInput = this.tabsFilters[slug].inputs.find(filter => filter.key === 'country');
        if (countryInput) {
          countryInput.value = [this.countriesService.getCountriesById('ES')];
        }
      }
      this.salesService.filters = {...filterFormValue};
      this.salesService.applyFilters$.next({...filterFormValue});
    }
  }

  private setCurrentSlugFiltersValues(key: string, input: QuestionBase<unknown>, currentSlugFilterKey: string, currentSlugFiltersValues: object): void {
    input.value = null;
    if (currentSlugFiltersValues[currentSlugFilterKey] !== undefined) {
      const multiAndSingleSelectionKeys = ['taxonomy_slug', 'feature_ids', 'product_ids', 'country', 'supplier', 'supplier_ecr', 'brand'];
      const isCurrentKeySingleSelection = multiAndSingleSelectionKeys.includes(currentSlugFilterKey) && input.settings && input.settings['singleSelection'];
      input.value = isCurrentKeySingleSelection && currentSlugFiltersValues[currentSlugFilterKey] && currentSlugFiltersValues[currentSlugFilterKey].length ?
        [currentSlugFiltersValues[currentSlugFilterKey][0]] : currentSlugFiltersValues[currentSlugFilterKey];
      this.handleShowSingleECRTaxonomySlugKindDashboardFilterCfg(key, input, currentSlugFilterKey);
    }
  }

  private handleShowSingleECRTaxonomySlugKindDashboardFilterCfg(key: string, input: QuestionBase<unknown>, currentSlugFilterKey: string): void {
    const hasShowSingleECRTaxonomySlugKindDashboardFilterConfig = !!this.tabsFilters[key]?.config?.hasOwnProperty('showSingleECRTaxonomySlugKindDashboard');
    if (currentSlugFilterKey === 'taxonomy_slug' && hasShowSingleECRTaxonomySlugKindDashboardFilterConfig) {
      const taxonomyRawElementInput = input.value?.[0]?.rawElement;
      const isECRTaxonomySlugKind = !!(taxonomyRawElementInput?.kind === 'ecr' || taxonomyRawElementInput?.kind === 'supplier_ecr'  || taxonomyRawElementInput?.kind === 'brand_ecr' );
      this.tabsFilters[key].config.showSingleECRTaxonomySlugKindDashboard = isECRTaxonomySlugKind;
    }
  }

  private setCurrentTabSlugDateFilters(key: string, input: QuestionBase<unknown>, currentSlugFilterKey: string, currentSlugFiltersValues: object): void {
    const isDateFromInput = input.key === 'date_from';
    const isDateToInput = input.key ==='date_to';
    const dateValue: Date = currentSlugFiltersValues[currentSlugFilterKey] !== undefined ? currentSlugFiltersValues[currentSlugFilterKey] : null;
    input.value = dateValue;
    const comparedWithInput = this.tabsFilters[key].inputs.find(input => input.key === 'compare_with');
    const dateFromComparedInput = this.tabsFilters[key].inputs.find(input => input.key === 'date_from_compared');
    const dateToComparedInput = this.tabsFilters[key].inputs.find(input => input.key === 'date_to_compared');

    if (comparedWithInput && !comparedWithInput.value) {
      if (isDateFromInput && dateFromComparedInput) { dateFromComparedInput.value = this.dateService.calculateDate('substract', 1, 'year', dateValue);}
      if (isDateToInput && dateToComparedInput) { dateToComparedInput.value = this.dateService.calculateDate('substract', 1, 'year', dateValue);}
    }
  }

  private handleSelectedTab(): void {
    const router$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (!this.isOnSelectTab && this.router.url === this.route.firstChild.snapshot.data.routerLink[0]) {
        this.selectTab(this.route.firstChild.snapshot.data.slug);
      }
      this.isOnSelectTab = false;
    });
    this.subs$.push(router$);
  }

  private handleFiltersOnTabSelection() {
    if (this.currentSlug === 'sales' || this.currentSlug === 'detail') {
      this.handleCountryFilterValue();
    }
  }

  private handleCountryFilterValue(): void {
    const countryInput = this.tabsFilters[this.currentSlug].inputs.find(item => item.key === 'country');
    if (countryInput) {
      countryInput.value = countryInput.value?.length ? countryInput.value : [this.countriesService.getCountriesById('ES')];
    }
  }

  private setFiltersByRouteConfig(): TabBase[] {
    const children = this.route.snapshot.routeConfig.children.filter(el => el.data);
    return children.map(el => {
      const tabInfo = {
        slug: el.data['slug'],
        routerLink: el.data['routerLink'],
        name: this.translate.instant(el.data['name']),
        filters: el.data.filters,
        filterCfg: el.data.filterCfg,
        featureFlag: el.data.featureFlag
      };
      this.tabsFilters[tabInfo.slug] = this.buildFiltersPerTab(tabInfo);
      return tabInfo;
    });
  }

  private getTaxonomies(): void {
    const supplierTaxonomies$ = this.featureTaxonomiesService.fetchMultiselect(null, null, {kind: 'supplier'});
    const supplierECRTaxonomies$ = this.featureTaxonomiesService.fetchMultiselect(null, null, {kind: 'supplier_ecr'});
    const brandTaxonomies$ = this.featureTaxonomiesService.fetchMultiselect(null, null, {kind: 'brand'});
    const brandECRTaxonomies$ = this.featureTaxonomiesService.fetchMultiselect(null, null, {kind: 'brand_ecr'});
    const taxonomies$ = combineLatest([supplierTaxonomies$, supplierECRTaxonomies$, brandTaxonomies$, brandECRTaxonomies$]).pipe(take(1)).subscribe({
      next: ([supplierTaxonomies, supplierECRTaxonomies, brandTaxonomies, brandECRTaxonomies]) => {
        this.setBaseFilters(supplierTaxonomies, supplierECRTaxonomies, brandTaxonomies, brandECRTaxonomies);
      },
      error: (err: HttpErrorResponse) => this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), err.error.error)
    });
    this.subs$.push(taxonomies$);
  }

  private setBaseFilters(supplierTaxonomies: object, supplierECRTaxonomies: object, brandTaxonomies: object, brandECRTaxonomies: object): void {
    this.baseFilters = this.dynamicBaseFiltersService.getBaseFilters(
      this.featuresPkService, this.featuresSupplierECRPkService,  this.featuresBrandPkService, this.featuresBrandECRPkService, this.dateFrom, this.dateTo,
      this.currencySymbol, supplierTaxonomies, supplierECRTaxonomies, brandTaxonomies, brandECRTaxonomies
    );
    this.tabs = this.setFiltersByRouteConfig();
  }

  private parseValueToSubmit(formFieldKey: string, formFieldValue: unknown, filterObject: object): unknown {
    if (formFieldKey === 'segment_id' && formFieldValue) {
      const segmentInputValue = this.tabsFilters[this.currentSlug].inputs.find(i => i.key === 'segment_id').value[0];
      filterObject['apply'] = segmentInputValue.rawElement.applies;
      filterObject['tag_id'] = segmentInputValue.rawElement['_embedded']['tag']['id'];
      return formFieldValue;
    }

    if (formFieldKey === 'feature_ids' && formFieldValue) {
      const segmentInputValue = this.tabsFilters[this.currentSlug].inputs.find(i => i.key === 'feature_ids').value;
      const featurePks = segmentInputValue.map( el => el.rawElement.pk ).join(',');
      return featurePks;
    }

    const tagDateKeys = ['tag_date', 'tag_date_origin', 'tag_date_destination'];
    if (tagDateKeys.includes(formFieldKey) && formFieldValue) {
      const tagDateInputValue = this.tabsFilters[this.currentSlug].inputs.find(i => i.key === formFieldKey).value;
      const tagDateValue = tagDateInputValue?.[0]?.name?.match(TAG_DATE_REGEX)?.[1] ?? null;
      return tagDateValue;
    }

    return formFieldValue;
  }

  private compareDates(filterFormValue: object, dateFromControlName: string, dateToControlName: string): boolean {
    if (this.dateService.isDate2BeforeDate1(filterFormValue[dateFromControlName], filterFormValue[dateToControlName])) {
      this.handleInvalidDates(false);
      return false;
    }

    if (this.dateService.getDatesDiffByTimeUnit(filterFormValue[dateFromControlName], filterFormValue[dateToControlName], 'days', false) > 365) {
      this.handleInvalidDates(true);
      return false;
    }
    return true;
  }

  private handleInvalidDates(isYearCompared: boolean): void {
    this.confirmationService.displayAlert(
      this.translate.instant('components.data-table.errors.title_error_date'),
      this.translate.instant(isYearCompared ? 'components.data-table.errors.year_bigger' : 'components.data-table.errors.date_bigger_than'),
      'warning').catch(() => {});

    setTimeout(() => {
      this.filterService.loaderStatus.next(true);
    });
  }

  private buildFiltersPerTab(tab: TabBase): {inputs: QuestionBase<any>[], defaultInputs: QuestionBase<any>[], config: object} {
    if (!tab.filters) {
      return {
        inputs: [],
        defaultInputs: [],
        config: null
      };
    }
    const _baseFilters = this.baseFilters.map(baseFltr => Object.assign({}, baseFltr));
    const inputs = _baseFilters.filter(baseFilter => {
      const tabFilterKeys = tab.filters.map(filter => filter['key']);
      return tabFilterKeys.includes(baseFilter.key);
    }).map(filter => {
      const fltrConfig = tab.filters.find(_filter => _filter['key'] === filter.key);
      filter.order = fltrConfig['order'] ? fltrConfig['order'] : null;
      filter.required = fltrConfig['required'] ? fltrConfig['required'] : false;
      filter.preStandard = fltrConfig['preStandard'] ? fltrConfig['preStandard'] : false;
      filter.settings = this.setFilterSettings(filter, fltrConfig);
      return filter;
    });

    if (tab.slug === 'detail' || LOYALTY_SLUGS_TABS_NO_LOOKER.includes(tab.slug)) {
      this.setDateFilters(inputs);
    }

    if (SLUGS_TABS_TO_SET_COUNTRY.includes(tab.slug)) {
      const isLoyaltyCountry = LOYALTY_SLUGS_TABS_WITH_COUNTRY_FF.includes(tab.slug);
      this.setCountry(inputs, isLoyaltyCountry);
    }

    if (tab.filterCfg['periodDatesFilters']) {
      handlePeriodFilter(inputs, this.dateService, null);
    }

    const defaultBaseFilters = inputs.map(baseFltr => Object.assign({}, baseFltr));

    return {
      inputs: inputs,
      defaultInputs: defaultBaseFilters,
      config: tab.filterCfg
    };
  }

  private setFilterSettings(filter: QuestionBase<any>, fltrConfig: string): object {
    const _filterSettings = this.baseFilters.find(_baseFilter => _baseFilter['key'] === filter.key).settings;
    const filterSettings = _filterSettings !== null ? { ..._filterSettings } : null;
    if (fltrConfig.hasOwnProperty('settings')) {
        Object.keys(fltrConfig['settings']).forEach(key => {
          filterSettings[key] = fltrConfig['settings'][key];
        })
    }
    return filterSettings;
  }

  private setDateFilters(inputs: QuestionBase<any>[]): void {
    inputs.find(item => item['key'] === 'date_from').value = this.dateService.momentStartOfTypeAndFormat('month', 'YYYY-MM-DD');
    inputs.find(item => item['key'] === 'date_to').value = this.dateService.momentEndOfTypeAndFormat('month', 'YYYY-MM-DD');
  }

  private setCountry(inputs: QuestionBase<any>[], isLoyaltyCountry: boolean) {
    const countryFilter = inputs.find(item => item['key'] === 'country');
    if (countryFilter) {
      if (isLoyaltyCountry) {
        countryFilter.value = countryFilter.settings && countryFilter.settings['singleSelection'] ?
          [this.countriesService.getCountriesById(this.applyCountryFFValue ? this.featureflags.country : 'ES')] :
          this.applyCountryFFValue ? [this.countriesService.getCountriesById(this.featureflags.country)] : [...this.countriesService.getCountries()];
      } else {
        countryFilter.value = [...this.countriesService.getCountries()];
      }
    }
  }

  private changeTitle(slug): void {
    this.activeTab.name = slug;
    this.activeTab.title = slug;
  }

  private processFeatureFlag(): void {
    if (!this.route.parent) { return; }
    const ffChildren = this.route.parent.snapshot.children[0].routeConfig.children.filter(child => child.data && child.data.featureFlag);
    if (ffChildren.length === 0) { return; }

    ffChildren.forEach((el) => {
      this.setFlagValue(el.data.featureFlag, this.launchDarklyService.client.variation(el.data.featureFlag, false));
    });

    this.launchDarklyService.flagChanges.subscribe((flags) => {
      Object.keys(this.flags).forEach(key => {
        if ((flags[key])) {this.setFlagValue(key, flags[key]); }
      });
    });
  }

  private setFlagValue(key: string, flagValue): void {
    flagValue = flagValue.hasOwnProperty('current') ? flagValue.current : flagValue;
    this.flags[key] = flagValue;
  }
}
