<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header background-white">
        <div class="row flex-grow-1">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 [attr.data-cy]="'h4_createNewCustomerTitle'" class="mrg-bottom-05-rem" *ngIf="creationMode" [translate]="'resources.profiles.form.title'"></h1>
            <h1 *ngIf="!creationMode" [attr.data-cy]="'h4_editCustomerTitle'" [translate]="'resources.profiles.form.edit_title'"></h1>
          </div>
        </div>

        <div class="pdd-vertical-20-but-bottom">
          <button [attr.data-cy]="creationMode ? 'button_closeCreateNewCustomer' : 'button_closeEditCustomer'" class="modal-close" (click)="goBack()">
            <i class="ti-close"></i>
          </button>
        </div>
      </div>

      <div class="modal-body back-color">
        <div class="card-block no-pdd">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form [formGroup]="profilesForm" *ngIf="profilesForm">

                <div class="card">
                  <div class="card-block">

                    <h4 [attr.data-cy]="'h4_customerDataSectionTitle'" [translate]="'resources.profiles.form.fields.details'"></h4>

                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_gender'" for="gender">
                            {{'resources.profiles.form.fields.gender' | translate}}
                            <span *ngIf="getInputConfig('gender').required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('gender')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_email'" for="email">
                            {{'resources.profiles.form.fields.email' | translate}}
                            <span *ngIf="getInputConfig('email').required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('email')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_registrationLocationId'" for="registration_location_id">
                            {{'resources.profiles.form.fields.registration_location_id' | translate}}
                            <span *ngIf="getInputConfig('registration_location_id').required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('registration_location_id')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_name'" for="name">
                            {{'resources.profiles.form.fields.name' | translate}}
                            <span *ngIf="getInputConfig('name').required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('name')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_surname1'" for="surname_1">
                            {{'resources.profiles.form.fields.surname_1' | translate}}
                            <span *ngIf="getInputConfig('surname_1').required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('surname_1')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_surname2'"for="surname_2">
                            {{'resources.profiles.form.fields.surname_2' | translate}}
                            <span *ngIf="getInputConfig('surname_2').required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('surname_2')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_documentType'" for="document_type">
                            {{'resources.profiles.form.fields.document_type' | translate}}
                            <span *ngIf="getInputConfig('document_type').required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('document_type')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_dni'" for="dni">
                            {{'resources.profiles.form.fields.identity_number' | translate}}
                            <span *ngIf="getInputConfig('dni').required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('dni')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_birth'" for="birth">
                            {{'resources.profiles.form.fields.birth' | translate}}
                            <span *ngIf="getInputConfig('birth').required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('birth')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="flags.profileAllowDuplicateVisibility" class="row">
                      <div class="col-md-4">
                        <div class="form-group d-inline-flex" id="allow-checkbox">
                          <app-question [question]="getInputConfig('allow_duplicate_email')" [form]="profilesForm"></app-question>
                          <span [attr.data-cy]="'span_allowDuplicateEmail'" class="mrg-top-5" [translate]="'resources.profiles.form.fields.allow_duplicate_email'"></span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group d-inline-flex" id="allow-checkbox">
                          <app-question [question]="getInputConfig('allow_duplicate_dni')" [form]="profilesForm"></app-question>
                          <span [attr.data-cy]="'span_allowDuplicateDni'" class="mrg-top-5" [translate]="'resources.profiles.form.fields.allow_duplicate_dni'"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="isCreationFormWithUnifiedCustomers" class="card">
                  <div class="card-block">
                    <h4 [attr.data-cy]="'h4_loyaltyCardSectionTitle'" [translate]="'resources.profiles.form.fields.loyalty_card'"></h4>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_cardId'" for="card_id">
                            {{'resources.profiles.form.fields.card_id' | translate}}
                          </label>
                          <app-question [question]="getInputConfig('card_id')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-block">
                    <h4 [attr.data-cy]="'h4_customerContactInformationSectionTitle'" [translate]="'resources.profiles.form.fields.contact_info'"></h4>
                    <div class="row">
                      <div *ngIf="!flags.hideCustomerAddress" class="col-md-6">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_address'" for="address">
                            {{'resources.profiles.form.fields.address' | translate}}
                            <span *ngIf="getInputConfig('address').required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('address')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                      <div [ngClass]="flags.hideCustomerAddress ? 'col-md-4' : 'col-md-3'">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_city'" for="city">
                            {{'resources.profiles.form.fields.city' | translate}}
                            <span *ngIf="getInputConfig('city').required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('city')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                      <ng-container *ngIf="!flags.hideCustomerAddress">
                        <div class="col-md-3"></div>
                      </ng-container>
                      <div [ngClass]="flags.hideCustomerAddress ? 'col-md-4' : 'col-md-3'">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_postalCode'" for="postal_code">
                            {{'resources.profiles.form.fields.postal_code' | translate}}
                            <span *ngIf="getInputConfig('postal_code').required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('postal_code')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                      <div [ngClass]="flags.hideCustomerAddress ? 'col-md-4' : 'col-md-3'">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_state'" for="state">
                            {{'resources.profiles.form.fields.state' | translate}}
                            <span *ngIf="getInputConfig('state').required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('state')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_phonePrefix'" for="phone_prefix">
                            {{'resources.profiles.form.fields.phone_prefix' | translate}}
                            <span *ngIf="getInputConfig('phone_prefix').required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('phone_prefix')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_phone'" for="phone">
                            {{'resources.profiles.form.fields.phone' | translate}}
                            <span *ngIf="getInputConfig('phone').required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('phone')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_preferredLanguage'" for="preferred_language">
                            {{'resources.profiles.form.fields.preferred_language' | translate}}
                            <span *ngIf="getInputConfig('preferred_language').required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('preferred_language')" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-block">
                    <h4 [attr.data-cy]="'h4_customerAccountInformationSectionTitle'" [translate]="'resources.profiles.form.fields.account_info'"></h4>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_status'" for="status">
                            {{'resources.profiles.form.fields.status' | translate}}
                            <span *ngIf="getStatusInputConfig().required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="getStatusInputConfig()" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                      <ng-container *ngIf="!creationMode">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label [attr.data-cy]="'label_emailStatus'" for="email_status">
                              {{'resources.profiles.form.fields.email_status' | translate}}
                              <span *ngIf="getInputConfig('email_status').required" class="text-danger">*</span>
                            </label>
                            <app-question [question]="getInputConfig('email_status')" [form]="profilesForm"></app-question>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label [attr.data-cy]="'label_phoneStatus'" for="phone_status">
                              {{'resources.profiles.form.fields.phone_status' | translate}}
                              <span *ngIf="getInputConfig('phone_status').required" class="text-danger">*</span>
                            </label>
                            <app-question [question]="getInputConfig('phone_status')" [form]="profilesForm"></app-question>
                          </div>
                        </div>
                      </ng-container>
                    </div>

                    <ng-container *ngIf="flags.showFraudulent">
                      <div class="row">
                        <div class="col-md">
                          <div class="form-group d-inline-flex">
                            <app-question [question]="getInputConfig('fraudulent')" [form]="profilesForm"></app-question>
                            <span [attr.data-cy]="'span_fraudulent'" class="mrg-top-5" [translate]="'resources.profiles.form.fields.fraudulent'"></span>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <div class="card" *ngIf="customInputs.length > 0">
                  <div class="card-block">
                    <h4 [attr.data-cy]="'h4_customerCustomInformationSectionTitle'" [translate]="'resources.profiles.form.fields.custom_info'"></h4>
                    <div class="row">
                      <div class="col-md-4" *ngFor="let customInput of customInputs">
                        <div class="form-group">
                          <label [attr.data-cy]="'label_' + (customInput.key | camelCase)" for="{{customInput.key}}">
                            {{customInput['title']}}
                            <span *ngIf="customInput.required" class="text-danger">*</span>
                          </label>
                          <app-question [question]="customInput" [form]="profilesForm"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-block">
                    <h4 [attr.data-cy]="'h4_customerSubscriptionsSectionTitle'" [translate]="'resources.profiles.form.fields.subscriptions_info'"></h4>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group d-inline-flex">
                          <app-question [question]="getInputConfig('terms')" [form]="profilesForm"></app-question>
                          <a target="_blank"
                             [attr.data-cy]="'link_terms'"
                             class="mrg-top-5"
                             [href]="'https://gdpr-landing.web.app/#/gdpr?slug=' + currentUser.company.slug">
                            <u [attr.data-cy]="'span_terms'" translate>resources.profiles.form.fields.accepts_lopd</u>
                            <span class="text-danger">*</span>
                          </a>
                        </div>
                      </div>
                      <div class="col-md-3" *ngIf="flags.profileProfilingVisibility">
                        <div class="form-group d-inline-flex">
                          <app-question [question]="getInputConfig('profiling')" [form]="profilesForm"></app-question>
                          <a target="_blank"
                             [attr.data-cy]="'link_profiling'"
                             class="mrg-top-5"
                             [href]="'https://gdpr-landing.web.app/#/gdpr?slug=' + currentUser.company.slug">
                            <u [attr.data-cy]="'span_profiling'" translate>resources.profiles.form.fields.accepts_profiling</u>
                          </a>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group d-inline-flex">
                          <app-question [question]="getInputConfig('sms_unsubscribe')" [form]="profilesForm"></app-question>
                          <span [attr.data-cy]="'span_smsUnsubscribe'" class="mrg-top-5" [translate]="'resources.profiles.form.fields.sms_unsubscribe'"></span>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group d-inline-flex">
                          <app-question [question]="getInputConfig('email_unsubscribe')" [form]="profilesForm"></app-question>
                          <span [attr.data-cy]="'span_emailUnsubscribe'" class="mrg-top-5" [translate]="'resources.profiles.form.fields.email_unsubscribe'"></span>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group d-inline-flex">
                          <app-question [question]="getInputConfig('push_unsubscribe')" [form]="profilesForm"></app-question>
                          <span [attr.data-cy]="'span_pushUnsubscribe'" class="mrg-top-5" [translate]="'resources.profiles.form.fields.push_unsubscribe'"></span>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group d-inline-flex">
                          <app-question [question]="getInputConfig('paper_free')" [form]="profilesForm"></app-question>
                          <span [attr.data-cy]="'span_paperFree'" class="mrg-top-5" [translate]="'resources.profiles.form.fields.paper_free'"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="row modal-footer background-white">
        <div class="col-md-8 ml-auto mr-auto text-right">
          <span>
            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          </span>

          <button *ngIf="profilesForm"
                  [attr.data-cy]="creationMode ? 'button_saveCustomer' : 'button_updateCustomer'"
                  class="btn btn-info no-mrg-right"
                  (click)="save()"
                  [translate]="'components.create-update.fields.save'"
                  [disabled]="loading || profilesForm.invalid">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
