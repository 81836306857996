import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, finalize, takeUntil } from "rxjs";
import { ConfirmationService } from "../../../../../shared/services/confirmation.service";
import { TranslateService } from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";
import { QuestionBase } from "../../../../../shared/models/forms/question-base";
import { UntypedFormGroup } from "@angular/forms";
import { QuestionControlService } from "../../../../../shared/services/question-control.service";
import { ReportIncrementalService } from "../incremental/report-incremental.service";
import { getROIByStoreInputs } from "./utils/edit-roi-by-store-inputs.utils";
import { LocationsService } from "../../../../data-warehouse/locations/locations.service";
import { LocationsTaxonomyTermsService } from '../../../../data-warehouse/locations/location-taxonomy-terms.service';
import { CustomReport } from "../../report.model";
import { DictionaryService } from "../../../../../shared/services/dictionary.service";
import { PlansService } from "../../../../plans/plans.service";
import { MultiselectDataSource } from "../../../../../shared/components/multiselect/multiselect";
import { handleSelectedLocationTaxonomyTermIdsField } from "../../../../../shared/components/create-update-segments-v2/utils/common.utils";
import { MultiSelectQuestion } from "../../../../../shared/models/forms/question-multiselect";
import { TextboxQuestion } from "../../../../../shared/models/forms/question-textbox";
import { CustomerJourneysService } from "../../../../customer-journeys/customer-journeys.service";
import { FeatureFlagsService } from "../../../../../shared/services/feature-flags.service";
import { DateService } from "../../../../../shared/services/date.service";

@Component({
  selector: 'app-edit-roi-by-store',
  templateUrl: './edit-roi-by-store.component.html',
  styleUrls: ['./edit-roi-by-store.component.scss'],
  providers: [DictionaryService]
})

export class EditROIByStoreComponent implements OnInit, OnDestroy {

  historyId: number;
  id: number;
  inputs: (TextboxQuestion | MultiSelectQuestion)[];
  loading = false;
  report: CustomReport;
  reportForm: UntypedFormGroup;
  flags = this.featureFlags.flags;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private confirmationService: ConfirmationService,
    private dictionaryService: DictionaryService,
    private locationsService: LocationsService,
    private locationsTaxonomyTermsService: LocationsTaxonomyTermsService,
    private qcs: QuestionControlService,
    private reportIncrementalService: ReportIncrementalService,
    private route: ActivatedRoute,
    private router: Router,
    private plansService: PlansService,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private customerJourneysService: CustomerJourneysService,
    private featureFlags: FeatureFlagsService,
    private dateService: DateService
  ) {}

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  returnToList() {
    const navigateTo = this.router.url.indexOf('(modal:') >= 0
        ? `update/reports/${this.id}`
        : '/offers-personalization/dashboard/reports';
    this.router.navigate([{outlets: { modal: navigateTo}}]).catch(() => {});
  }

  sendData() {
    const payload = this.getPayload();
    const req$ = this.historyId ? this.reportIncrementalService.updateData(payload, this.id, this.historyId) : this.reportIncrementalService.sendData(payload, this.id);
    const successTitle = this.historyId ? 'resources.reports.form.warnings.report_update_title': 'resources.reports.form.warnings.success_title';
    const successDesc = this.historyId ? 'resources.reports.form.warnings.report_update_text': 'resources.reports.form.warnings.success_text';

    this.loading = true;

    req$.pipe(takeUntil(this.destroy$), finalize(() => this.loading = false)).subscribe({
      next: () => {
        this.confirmationService.displaySuccessAlert(this.translate.instant(successTitle), this.translate.instant(successDesc)).catch(() => {});
        this.returnToList();
      },
      error: (error: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(error)
    });
  }

  getInputConfig(inputKey: string): QuestionBase<TextboxQuestion | MultiSelectQuestion> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  /**
   * Plans selector should be always cleansed and disabled if month/year aren't fullfilled
   */
  dateChangedHandler(): void {
    const monthValue = (this.getInputConfig('month').value || [])[0]?.id;
    const yearValue = (this.getInputConfig('year').value || [])[0]?.id;
    const isMonthEmpty = !monthValue || monthValue.length === 0;
    const isYearEmpty = !yearValue || yearValue.length === 0;

    this.handleSelectedDates(monthValue, yearValue);

    if (isMonthEmpty || isYearEmpty) {
      this.reportForm.patchValue({ plan_ids: [] });
      this.getInputConfig('plan_ids').disabled = true;
    } else {
      this.getInputConfig('plan_ids').disabled = false;
    }
  }

  /**
   * Executes each time a location category input is changed.
   * Filter link between location categories and locations: All locations belong to current selected location categories.
   */
  handleSelectedLocationTaxonomyTermIds(locationTaxonomyTermIdsSelected: MultiselectDataSource[]): void {
    handleSelectedLocationTaxonomyTermIdsField(
      locationTaxonomyTermIdsSelected,
      'location_ids',
      this.getInputConfig('location_ids'),
      this.reportForm,
      this.changeDetector,
      this.locationsService
    );
  }

  /**
   * Executes each time a location category input is changed.
   * Filter link between location categories and locations: All locations belong to current selected location categories.
   */
  private handleSelectedDates(month: string, year: string): void {
    this.plansService.setDatesRequired();
    this.customerJourneysService.setDatesRequired();
    if (month && year) {
      month = String(month).length === 1 ? `0${month}` : String(month);
      const targetDate = this.dateService.getTzGuessMomentYearMonthDate(year, month);
      const dateFrom = targetDate.startOf('month').format('YYYY-MM-DD');
      const dateTo = targetDate.endOf('month').format('YYYY-MM-DD');
      this.plansService.setDates(dateFrom, dateTo);
      this.customerJourneysService.setDates(dateFrom, dateTo);
    } else {
      this.plansService.setDates(null, null);
      this.customerJourneysService.setDates(null, null);
    }
  }

  /**
   * Code for processing route parameters
   */
  private getParams() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.hasOwnProperty('id') && params.hasOwnProperty('history_id')) {
        this.id = params.id;
        this.historyId = params.history_id;
        this.getReportDetailById();
      } else {
        this.id = params.id;
        this.setupForm();
      }
    });
  }

  /**
   * Fetching report details & setting up the form
   */
  private getReportDetailById() {
    this.reportIncrementalService.fetchReportDetailById(this.id, this.historyId).pipe(takeUntil(this.destroy$)).subscribe({
      next: (data: CustomReport) => {
        this.report = data;
        this.setupForm();
      },
      error: (error: HttpErrorResponse) => {
        this.confirmationService.displayHttpErrorAlert(error)
      }
    }
  )}

  /**
   * Initialize form and set values
   */
  private setupForm() {
    this.inputs = getROIByStoreInputs(
      this.dictionaryService,
      this.locationsService,
      this.locationsTaxonomyTermsService,
      this.plansService,
      this.report,
      this.customerJourneysService
    )
    this.reportForm = this.qcs.toFormGroup(this.inputs);
  }

  private getPayload() {
    const selectedMonth = this.reportForm.value.month[0]?.id;
    const selectedYear = this.reportForm.value.year[0]?.id;
    const selectedDate = this.dateService.getTzGuessMomentYearMonthDate(selectedYear, selectedMonth);
    const startOfMonth = selectedDate.startOf('month').format('YYYY-MM-DD');
    const endOfMonth = selectedDate.endOf('month').format('YYYY-MM-DD');
    return {
      configuration: {
        campaign_block_ids: this.reportForm.value?.campaign_block_ids?.map(item => item.id),
        location_taxonomy_term_ids: this.reportForm.value?.location_taxonomy_terms?.map(item => item.id),
        location_ids: this.reportForm.value?.location_ids?.map(item => item.id),
        plan_ids: this.reportForm.value?.plan_ids?.map(item => item.id),
        start_date: startOfMonth,
        end_date: endOfMonth
      },
      name: this.reportForm.value.name,
      report_id: this.id,
      type: 'ReportRoiByStore'
    };
  }
}
