<div class="page-title background-white">
  <div class="row pdd-horizon-15">
    <div class="col-md-9 d-flex align-items-center min-height-60px">
      <h4 class="no-mrg-vertical">{{title}}</h4>
    </div>
  </div>
</div>

<ng-container *ngIf="!loading; else loadingTabs">
  <ng-container *ngIf="tabs?.length">
    <ul class="nav nav-tabs w-100">
      <ng-container *ngFor="let tab of tabs; let i = index">
        <li class="nav-item" *ngIf="!tab.featureFlag || flags[tab.featureFlag]">
          <a (click)="selectTab(tab.slug)"
            [routerLink]="tab.routerLink"
            class="nav-link {{currentSlug === tab.slug ? 'active' : ''}}"
            data-toggle="tab"
            href="#tab-{{i}}"
            role="tab"
            aria-selected="true">
            {{tab.name}}
          </a>
        </li>
      </ng-container>
    </ul>

    <div class="container-fluid mrg-top-15" *ngIf="tabs?.length">
      <div class="row">
        <div class="col-md-12">
          <div class="tab-info">
            <div class="pdd-bottom-20 position-relative">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loadingTabs>
  <app-loader styleClass="mrg-top-20" noWrapper/>
</ng-template>