import { QuestionBase } from "../models/forms/question-base";
import { DateService } from "../services/date.service";
import { isNullOrUndefined } from "./common.utils";
import { FormGroup, UntypedFormGroup } from "@angular/forms";

export function handlePeriodFilter(filters: QuestionBase<any>[], dateService: DateService, form?: FormGroup): void {
  const periodFilter = filters.find(item => item.key === 'period');
  const dateFromFilter = filters.find(item => item.key === 'date_from');
  const dateToFilter = filters.find(item => item.key === 'date_to');

  if (periodFilter && dateFromFilter && dateToFilter) {
    const periodValue = isNullOrUndefined(form) ? periodFilter.value : form.get('period').value;
    const hasPeriodFilterValue = !!periodValue?.length;
    const periodId = hasPeriodFilterValue ? periodValue[0].id : null;
    const isCustomPeriodOption = !!(hasPeriodFilterValue && periodId === 'custom');
    dateFromFilter.hidden = !isCustomPeriodOption;
    dateToFilter.hidden = !isCustomPeriodOption;
    if (hasPeriodFilterValue) {
      if (periodId === 'current_month') {
        const startPreviousMonth = dateService.momentStartOfTypeAndFormat('month', 'YYYY-MM-DD');
        const endPreviousMonth = dateService.momentEndOfTypeAndFormat('month', 'YYYY-MM-DD');
        setPeriodDates(dateFromFilter, dateToFilter, form, startPreviousMonth, endPreviousMonth);
      } else if (periodId === 'last_month') {
        const startCurrentMonth = dateService.momentStartOfPreviousPeriodTypeAndFormat(1, 'months', 'month', 'YYYY-MM-DD');
        const endCurrentMonth = dateService.momentEndOfPreviousPeriodTypeAndFormat(1, 'months', 'month', 'YYYY-MM-DD');
        setPeriodDates(dateFromFilter, dateToFilter, form, startCurrentMonth, endCurrentMonth);
      }
    }
  }
}

function setPeriodDates(dateFromFilter: QuestionBase<any>, dateToFilter: QuestionBase<any>, form: FormGroup, startMonth: string, endMonth: string): void {
  if (isNullOrUndefined(form)) {
    dateFromFilter.value = startMonth;
    dateToFilter.value = endMonth;
  } else {
    form.get('date_from').patchValue(startMonth);
    form.get('date_to').patchValue(endMonth);
  }
}

export function handleECRTaxonomySlugKind(taxonomyValue: unknown, inputs: QuestionBase<unknown>[], form: UntypedFormGroup): void {
  const isECRTaxonomySlugKind = !!(taxonomyValue?.['rawElement']?.kind === 'ecr' || taxonomyValue?.['rawElement']?.kind === 'supplier_ecr' ||  taxonomyValue?.['rawElement']?.kind === 'brand_ecr');
  const supplierInput = inputs?.find(input => input.key === 'supplier');
  const supplierECRInput = inputs?.find(input => input.key === 'supplier_ecr');
  const brandPkInput = inputs?.find(input => input.key === 'brand_pk');
  const brandPkECRInput = inputs?.find(input => input.key === 'brand_pk_ecr');
  if (isECRTaxonomySlugKind) {
    if (supplierInput && supplierECRInput) {
      handleFieldsForECRTaxonomy(supplierInput, supplierECRInput, 'supplier', form);
    }

    if (brandPkInput && brandPkECRInput) {
      handleFieldsForECRTaxonomy(brandPkInput, brandPkECRInput, 'brand_pk', form);
    }
  } else {
    handleFieldsForNoECRTaxonomy(supplierInput, supplierECRInput, 'supplier_ecr', form);
    handleFieldsForNoECRTaxonomy(brandPkInput, brandPkECRInput, 'brand_pk_ecr', form);
  }
}


function handleFieldsForECRTaxonomy(defaultInput: QuestionBase<unknown>, ecrInput: QuestionBase<unknown>, defaultFieldKey: string, form: UntypedFormGroup): void {
  cleanInputs([defaultInput]);
  defaultInput.hidden = true;
  ecrInput.hidden = false;
  if (form) {
    form.patchValue({ [defaultFieldKey]: null});
  }
}

function handleFieldsForNoECRTaxonomy(defaultInput: QuestionBase<unknown>, ecrInput: QuestionBase<unknown>, ecrFieldKey: string, form: UntypedFormGroup): void {
  if (defaultInput) {
   if (ecrInput) { cleanInputs([ecrInput]); }
    defaultInput.hidden = false;
    if (form) { form.patchValue({[ecrFieldKey]: null}); }
  }

  if (ecrInput) {
    cleanInputs([ecrInput]);
    ecrInput.hidden = true;
    if (form) { form.patchValue({ [ecrFieldKey]: null}); }
  }
}

export function cleanInputs(inputs: QuestionBase<unknown>[]): void {
  inputs.forEach(input => {
    input.value = null;
    input.selectedIds = [];
  });
}