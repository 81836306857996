<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-40">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form [formGroup]="productsFeatureInternalForm" *ngIf="productsFeatureInternalForm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name" [translate]="'resources.product_internal_categories.form.name'"></label>
                      <app-question [question]="getInputConfig('name')" [form]="productsFeatureInternalForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="slug" [translate]="'resources.product_internal_categories.form.slug'"></label>
                      <span *ngIf="getInputConfig('slug').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('slug')" [form]="productsFeatureInternalForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="taxonomy_slug" [translate]="'resources.product_internal_categories.form.taxonomy_slug'"></label>
                      <span *ngIf="getInputConfig('taxonomy_slug').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('taxonomy_slug')" [form]="productsFeatureInternalForm" [filters]="{origin: 'internal'}"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="features" [translate]="'resources.product_internal_categories.form.features'"></label>
                      <app-question [question]="getInputConfig('features')" [form]="productsFeatureInternalForm"></app-question>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
