<div class="modal-backdrop fade in"
     id="reward-stock-modal"
     tabindex="-1"
     role="dialog"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header border bottom">
        <h4 class="mrg-btm-15 mrg-top-8">
          {{'resources.scores.rewards.stock.title' | translate}}
        </h4>
        <button class="modal-close" [attr.data-cy]="'button_closeRewardStockModal'" data-dismiss="modal" (click)="closeModal()">
          <em class="ti-close"></em>
        </button>
      </div>
      <div class="modal-body background-gray">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <app-loader noWrapper *ngIf="loading" />
            <form [formGroup]="addStockForm" *ngIf="addStockForm">

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="total" [translate]="'resources.scores.rewards.stock.form.value'"></label>
                    <app-question [question]="getInputConfig('total')" [form]="addStockForm"></app-question>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer background-white align-content-center">
				<div class="pull-right d-flex align-items-center">
					<button class="pull-right btn btn-info no-mrg-right"
                  [disabled]="loading || addStockForm.value.total < 0 || !addStockForm.valid || !addStockForm.value.total"
									(click)="sendData()"
									[translate]="'common.buttons.save'">
          </button>
				</div>
			</div>
    </div>
  </div>
</div>
