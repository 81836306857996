import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  private readonly regexp = new RegExp('plans/[0-9]+/campaigns/[0-9]+/step_[3-4]');

  private correlationId: string = null;
  private previousUrl: string = null;

  get currentUrl(): string {
    return this.router.url;
  }

  constructor(private router: Router) { }

  // This code creates a new request object with a new "X-Correlation-ID" header.
  // The new correlation ID is only generated when the current URL is a step 3 or step 4 URL.

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (this.isStep3or4()) {

      if (this.isNewUrl()) {
        this.correlationId = uuidv4();
      }      

      const headers = request.headers.set('X-Correlation-ID', this.correlationId);
      const newRequest = request.clone({ headers });
      return next.handle(newRequest);
    }

    this.previousUrl = null;

    return next.handle(request);
  }

  private isStep3or4(): boolean {
    const isStep3or4 = this.regexp.test(this.currentUrl);
    return isStep3or4;
  }

  private isNewUrl(): boolean {
    const isNewUrl = this.currentUrl !== this.previousUrl;
    this.previousUrl = this.currentUrl;
    return isNewUrl;
  }
}
