<div class="alert alert-info" role="alert" *ngIf="!onSearch && !loading">
  <span>{{ 'components.looker-iframe.alerts.apply_filters' | translate }}</span>
</div>

<div class="row" *ngIf="onSearch">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title d-inline" [translate]="'dashboards.club.tabs.typology.title'"></h4>
      </div>

      <app-loader styleClass="mrg-btm-15" *ngIf="loading" />

      <div class="card-block no-pdd no-mrg pdd-horizon-20 pdd-btm-5">
        <table class="table table-hover table-sm table-bordered" aria-hidden="true" *ngIf="!loading && typologyData">
          <thead>
            <tr class="highlight-header">
              <th></th>
              <th class="text-center" translate>
                dashboards.club.tabs.typology.columns.customers
              </th>
              <th class="text-center" translate>
                dashboards.club.tabs.typology.columns.customers_percentage
              </th>
              <th class="text-center" translate>
                dashboards.club.tabs.typology.columns.sales_percentage
              </th>
              <th class="text-center" translate>
                dashboards.club.tabs.typology.columns.sections
              </th>
              <th class="text-center" translate>
                dashboards.club.tabs.typology.columns.frequency
              </th>
              <th class="text-center">
                {{currencySymbol}}/{{'dashboards.club.tabs.typology.columns.euros_by_customer' | translate}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of typologyData; let i = index;"
                [ngClass]="{'highlight-header': i === typologyData.length - 1 }">
              <td class="text-left">
                <div class="font-size-12 text-dark">
                  <b>{{row.typologyName | translate}}</b>
                </div>
                <small><i>{{row.typologySpec}}</i></small>
              </td>
              <td class="text-right">
                <span class="label label-default font-size-12">{{row.customersCount | number: '1.0-0'}}</span>
              </td>
              <td class="text-right">
                <span class="label label-default font-size-12">
                  {{row.customersPercentage | percent:'1.2-2':'es'}}
                </span>
              </td>
              <td class="text-right">
                <span class="label label-default font-size-12">
                  {{row.salesPercentage | percent:'1.2-2':'es'}}</span>
              </td>
              <td class="text-right">
                <span class="label label-default font-size-12">
                  {{row.sections | number: '1.2-2'}}
                </span>
              </td>
              <td class="text-right">
                <span class="label label-default font-size-12">
                  {{row.frequency | number: '1.2-2'}}
                </span>
              </td>
              <td class="text-right">
                <span class="label label-default font-size-12">
                  {{row.eurosByCustomer | lgcurrency: '1.2-2'}}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
