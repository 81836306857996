import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PlanCampaign } from '../../plan-campaign';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { FeatureTaxonomiesService } from '../../../../data-warehouse/products/feature-taxonomies.service';
import { FeaturesService } from '../../../../data-warehouse/products/features.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-feature-input',
  templateUrl: './feature-input.component.html',
  styleUrls: ['./feature-input.component.css'],
  providers: [FeatureTaxonomiesService, FeaturesService]
})
export class FeatureInputComponent implements OnInit, OnDestroy {

  @Input('form') form: UntypedFormGroup;
  @Input('planCampaign') planCampaign: PlanCampaign;
  @Input('inputs') inputs: QuestionBase<any>[];

  constructor(
    private qcs: QuestionControlService,
    private taxonomiesService: FeatureTaxonomiesService,
    private featuresService: FeaturesService
  ) { }

  ngOnInit() {
    this.featuresService.treatPkAsId = true;
    this.setInputs(this.planCampaign);
  }

  ngOnDestroy() {
    this.qcs.removeFromFormGroup(this.form, 'non_buyers_taxonomy_slug');
    this.qcs.removeFromFormGroup(this.form, 'non_buyers_feature_id');
    const indexA = this.inputs.findIndex(input => input.key === 'non_buyers_taxonomy_slug');
    const indexB = this.inputs.findIndex(input => input.key === 'non_buyers_feature_id');
    this.inputs = this.inputs.slice(indexA, 1);
    this.inputs = this.inputs.slice(indexB, 1);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  public handleTaxonomy(taxonomy) {
    isNullOrUndefined(taxonomy) ? this.featuresService.setTaxonomies(null) : this.featuresService.setTaxonomies(taxonomy.id);
  }

  private setInputs(planCampaign?: PlanCampaign) {
    const taxonomyPresent = planCampaign && planCampaign.config.hasOwnProperty('non_buyers_taxonomy_slug');
    const featurePresent = planCampaign && planCampaign.config.hasOwnProperty('non_buyers_feature_id');
    const inputs = [
      new MultiSelectQuestion({
        key: 'non_buyers_taxonomy_slug',
        label: 'resources.campaign_plans.types.supplier.fields.non_buyers_taxonomy',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.taxonomiesService,
        selectedIds: taxonomyPresent ? [planCampaign.config['non_buyers_taxonomy_slug']] : []
      }),
      new MultiSelectQuestion({
        key: 'non_buyers_feature_id',
        label: 'resources.campaign_plans.types.supplier.fields.non_buyers_feature',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.featuresService,
        selectedIds: featurePresent ? [planCampaign.config['non_buyers_feature_id']] : [],
        required: true
      }),
    ];

    inputs.forEach(input => {
      this.qcs.addToFormGroup(this.form, input);
      this.inputs.push(input);
    });
  }
}
