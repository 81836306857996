export class ClientsConstantData {

  public month: string;
  public clientsCount: number;
  public yearSalesConstant: number;
  public yearToCompareSalesConstant: number;
  public yearSalesDiff: number;
  public yearSalesNoConstant: number;
  public yearToCompareSalesNoConstant: number;
  public yearSalesNoConstantDiff: number;

  constructor ( cfg ) {
    this.month = cfg.month;
    this.clientsCount = cfg.clientsCount; 
    this.yearSalesConstant = cfg.yearSalesConstant;
    this.yearToCompareSalesConstant = cfg.yearToCompareSalesConstant;
    this.yearSalesDiff = cfg.yearSalesDiff;
    this.yearSalesNoConstant = cfg.yearSalesNoConstant;
    this.yearToCompareSalesNoConstant = cfg.yearToCompareSalesNoConstant;
    this.yearSalesNoConstantDiff = cfg.yearSalesNoConstantDiff;
  }
}

// Mock constant for testing
export const ClientsConstantDataMock = {

  'constant_sales': {
    'year': [5.5, 10.0, 10.0, 15.5, 10.0, 10.0, 10.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    'compared_year': [5.5, 10.0, 50.0, 163.5, 3720.0, 1170.0, 2500040.0, 0.0, 0.0, 0.0, 0.0, 0.0]
  },
  'no_constant_sales': {
    'year': [0.0, 0.0, 0.0, 35.25, 78.57, 0.0, 0.0, 10.0, 121.7, 6973.82, 69369.54, 25230.2],
    'compared_year': [10.0, 1875.36, 780.5, 95.0, 1204968.2, 7583.75, 15132643935.11, 82.0, 87.0, 365.0, 0.0, 0.0]
  },
  'constant_customers': [1, 1, 1, 2, 1, 1, 1, 0, 0, 0, 0, 0]

  // constant_sales: {
  //   year: [
  //     6936328,
  //     7524057,
  //     9260393,
  //     10160393,
  //     6936122,
  //     9315321,
  //     7836122,
  //     8915321,
  //     7560323,
  //     6839900,
  //     9832142,
  //     8032142
  //   ],
  //   compared_year: [
  //     7668750,
  //     7524058,
  //     9260394,
  //     10160394,
  //     6936123,
  //     9315322,
  //     7836123,
  //     8915322,
  //     7560324,
  //     6839901,
  //     9999142,
  //     8032143
  //   ]
  // },
  // no_constant_sales: {
  //   year: [
  //     1139214,
  //     1237161,
  //     1386463,
  //     1230527,
  //     1322775,
  //     1123289,
  //     1413289,
  //     1434289,
  //     1122289,
  //     1134289,
  //     1321289,
  //     1457289
  //   ],
  //   compared_year: [
  //     3087192,
  //     3076821,
  //     3211627,
  //     3541564,
  //     3830077,
  //     3899305,
  //     1001421,
  //     1001421,
  //     1001421,
  //     1001421,
  //     1001421,
  //     1001421
  //   ]
  // },
  // constant_customers: [
  //   122102,
  //   120336,
  //   128132,
  //   127832,
  //   129313,
  //   130488,
  //   131998,
  //   131424,
  //   132989,
  //   133321,
  //   134000,
  //   135978
  // ]
};
