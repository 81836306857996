import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { UserSecurity } from "../../shared/models/company/company-configurations";
import { DIGITS_REGEX, SPECIAL_CHARACTERS_REGEX, UPPERCASE_REGEX } from "../constants/validators.constants";

export function userPassworValidator(userSecurity: UserSecurity): ValidatorFn {
  return (ctrl: AbstractControl): ValidationErrors | null => {
    if (userSecurity) {
      let defaultInvalidPasswordConditions = {
        invalidMinLength: true,
        invalidMinDigits: true,
        invalidMinUppercase: true,
        invalidMinSpecialCharacters: true
      };

      const ctrlValue = ctrl?.value;
      if (ctrlValue) {
        defaultInvalidPasswordConditions = {
          ...defaultInvalidPasswordConditions,
          invalidMinLength: !!(userSecurity.passwordMinLength && ctrlValue.length < userSecurity.passwordMinLength),
          invalidMinDigits: isMinConditionInvalid(ctrlValue, userSecurity.passwordMinDigits, DIGITS_REGEX),
          invalidMinUppercase: isMinConditionInvalid(ctrlValue, userSecurity.passwordMinUppercase, UPPERCASE_REGEX),
          invalidMinSpecialCharacters: isMinConditionInvalid(ctrlValue, userSecurity.passwordMinSpecialCharacters, SPECIAL_CHARACTERS_REGEX)
        };
      }

      const invalidPasswordConditions = Object.keys(defaultInvalidPasswordConditions).reduce((acc, currentKey) => {
        if (defaultInvalidPasswordConditions[currentKey]) { acc[currentKey] = true; }
        return acc;
      }, {});

      return Object.keys(invalidPasswordConditions)?.length ? {'invalidPassword': { ...invalidPasswordConditions }} : null;
    }
    return null;
  };
}

function isMinConditionInvalid(ctrlValue: string, passwordMinCondition: number, conditionRegex: RegExp): boolean {
  const conditionRegexMatchArray = ctrlValue.match(conditionRegex);
  const conditionTotalMatches = conditionRegexMatchArray?.length ? conditionRegexMatchArray.reduce((acc, currentValue) => acc + currentValue).length : 0;
  return !!(passwordMinCondition && conditionTotalMatches < passwordMinCondition);
}