import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ImageQuestion } from '../../../../../shared/models/forms/question-image';
import { ModalStatusService } from '../../../../../shared/services/modal-status.service';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { RewardCategoriesService } from '../reward-categories.service';
import { RewardCategory, RewardCategoryPayload } from '../reward-category';
import { RewardsService } from '../../rewards.service';
import { RewardTaxonomiesService } from '../../reward-taxonomies/reward-taxonomies.service';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-reward-categories',
  templateUrl: './form-reward-categories.component.html',
  styleUrls: ['./form-reward-categories.component.css'],
  providers: [RewardCategoriesService]
})

export class FormRewardCategoriesComponent implements OnInit {

  id: number;
  inputs: QuestionBase<any>[];
  missingImg = false;
  rewardCategoriesForm: UntypedFormGroup;
  rewardCategoriesInputs: QuestionBase<any>[];
  rewardCategory: RewardCategory;
  showButton: boolean;

  private imageData: { filename: any; filesize: any; filetype: any; base64: string; };
  private subs$: Subscription[] = [];

  @Output('formValidity') formValidity: EventEmitter<string> = new EventEmitter();

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private rewardCategoriesService: RewardCategoriesService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private modalStatusSrv: ModalStatusService,
    private rewardTaxonomies: RewardTaxonomiesService,
    private rewards: RewardsService
  ) {}

  ngOnInit() {
    this.getParams();
    this.showBackButton();
  }

  showBackButton() {
    this.showButton = !(this.router.url.indexOf('(modal:') >= 0);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.imageData = {
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
          base64: reader.result.toString().split(',')[1]
        };
      };
    }
  }

  sendData() {
    let req$ = this.id ?
      this.rewardCategoriesService.updateData(this.getPayload(), this.id) :
      this.rewardCategoriesService.sendData(this.getPayload());
    let title = this.id ?
      this.translate.instant('resources.scores.reward_categories.form.warnings.update_title') :
      this.translate.instant('resources.scores.reward_categories.form.warnings.success_title');
    let subtitle = this.id ?
      this.translate.instant('resources.scores.reward_categories.form.warnings.update_text') :
      this.translate.instant('resources.scores.reward_categories.form.warnings.success_text');

    this.modalStatusSrv.formLoaderStatus.emit('loading');

    req$.subscribe(
      () => {
        this.confirmationService.displaySuccessAlert(title, subtitle).catch(() => {});
        this.modalStatusSrv.formLoaderStatus.emit('loading_finished');
        this.modalStatusSrv.modalStatus.emit();
        this.returnToList();
      },
      (err: HttpErrorResponse) => this.handleError(err)
    )
  }

  returnToList() {
    this.router.navigate([{ outlets: { modal: null }}]).catch(() => {});
  }

  private getPayload(): RewardCategoryPayload {
    const payload = this.rewardCategoriesForm.value;
    payload['taxonomy_slug'] = payload['taxonomy_slug'][0].id;
    payload['reward_ids'] = payload['reward_ids'] ? payload['reward_ids'].map(el => el.id) : null;
    if (this.imageData) {
      payload['image'] = this.imageData;
    } else {
      delete payload['image'];
    }
    return payload;
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getRewardCategoriesById(this.id);
      } else {
        this.setInputsAndForm();
      }
    });
  }

  private getRewardCategoriesById(id: number) {
    this.rewardCategoriesService.getRewardCategoriesById(this.id).subscribe(
      (data: RewardCategory) => {
        this.rewardCategory = data;
        this.missingImg = data?.thumbnail_medium.indexOf('images/medium/missing') >= 0;
        this.setInputsAndForm(data);
      },
      (err: HttpErrorResponse) => this.handleError(err)
    );
  }

  private setInputsAndForm(data?: RewardCategory) {
    this.inputs = this.getInputs(data);
    this.rewardCategoriesForm = this.qcs.toFormGroup(this.inputs);
    this.formStatusChanges();
  }

  private handleError(err: HttpErrorResponse) {
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), err.error.error);
    this.modalStatusSrv.formLoaderStatus.emit('loading_finished');
    this.returnToList();
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.rewardCategoriesForm.status));
    const formStatus$ = this.rewardCategoriesForm.statusChanges.pipe(distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
    this.subs$.push(formStatus$);
  }

  private getInputs(rewardCategory?: RewardCategory): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        cssClasses: 'form-control input-default',
        key: 'name',
        order: 1,
        required: true,
        type: 'text',
        value: rewardCategory?.name
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        dataSource: this.rewardTaxonomies,
        disabled: rewardCategory?.external_id,
        key: 'taxonomy_slug',
        order: 2,
        required: true,
        selectedIds: rewardCategory?.taxonomy?.id ? [rewardCategory.taxonomy.id] : [],
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false }
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-default',
        disabled: rewardCategory?.external_id,
        key: 'external_id',
        order: 3,
        required: true,
        type: 'number',
        value: rewardCategory?.external_id
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-default',
        dataSource: this.rewards,
        key: 'reward_ids',
        order: 4,
        required: false,
        settings: {singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true},
        selectedIds: rewardCategory?.reward_ids?.length ? rewardCategory.reward_ids : []
      }),
      new ImageQuestion({
        cssClasses: 'input-default',
        key: 'image',
        order: 5,
        type: 'file'
      })
    ];

    this.inputs = inputsArray;
    return this.inputs;
  }
}


