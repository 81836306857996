import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../shared/components/multiselect/multiselect';
import { Observable } from 'rxjs';
import { QuestionBase } from '../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../shared/models/forms/question-multiselect';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { CouponsService } from '../coupons/coupons.service';

@Injectable()
export class RecipesService extends ResourcesService implements MultiselectDataSourceable {

  inputs: (TextboxQuestion | MultiSelectQuestion)[];

  constructor(http: HttpClient,
              private translate: TranslateService,
              private couponService: CouponsService ) {
    super(http);
  }

  public fetchMultiselect(searchValues?: string, page?: number): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'recipes',
      numberPerPage: 10,
      filtering: {},
      pageNumber: 1,
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues } };
      requestOptions = { ...requestOptions, ...filterObj };
    }

    return this.getData(requestOptions);
  }

  public fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `recipes?id=${id}` });
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`, element);
  }

  public getRecipesById(id: number): Observable<any> {
    return this.getData({ apiEndPoint: `recipes/${id}` });
  }

  public getTriggerOptions() {
    return [
      { id: 'after_create_customer', name: this.translate.instant('resources.marketing_automation.when.after_create_customer')},
      { id: 'after_first_login', name: this.translate.instant('resources.marketing_automation.when.after_first_login')},
      { id: 'after_create_activity', name: this.translate.instant('resources.marketing_automation.when.after_create_activity')},
    ];
  }

  public getActionsOptions() {
    return [
      { id: 'push', name: this.translate.instant('resources.marketing_automation.actions.push')},
      { id: 'coupon', name: this.translate.instant('resources.marketing_automation.actions.coupon')}
    ];
  }

  public getInputs(formValues: any): QuestionBase<any>[] {
    const inputsArray = [
      new MultiSelectQuestion({
        key: 'trigger',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: this.getTriggerOptions(),
        order: 1,
        getValue: (value) => ([this.getTriggerOptions().find( item => item.id === value)])
      }),
        new MultiSelectQuestion({
        key: 'action',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: this.getActionsOptions(),
        order: 2,
        getValue: (value) => ([this.getActionsOptions().find( item => item.id === value)])
      }),
      // when push
      new TextboxQuestion({
        key: 'subject',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 3,
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'message',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 4,
        getValue: (value) => value
      }),
      // when coupon
      new MultiSelectQuestion({
        key: 'coupon_id',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.couponService,
        order: 5,
        getValue: (value) => ({ selectedIds: [value] })
      })
    ];

    this.inputs = inputsArray;

    this.assignFormInputValues(formValues);

    Object.keys(formValues).map(
      key => {
        if (key === 'coupon_id') {
          inputsArray.find( input => input.key === 'coupon_id' ).selectedIds = formValues[key];
        } else if (key === 'configuration') {
          inputsArray.find( input => input.key === 'subject' ).value = formValues['configuration'].subject;
          inputsArray.find( input => input.key === 'message' ).value = formValues['configuration'].message;
          inputsArray.find( input => input.key === 'coupon_id' ).selectedIds = formValues['configuration'].coupon_id;
        }
      }
    );

    return this.inputs;
  }

  private assignFormInputValues(formValues) {
    Object.keys(formValues).map(
      key => {
        const tmpInput = this.inputs.find( input => input.key === key);

        if (tmpInput && tmpInput.getValue ) {
          const value = tmpInput.getValue(formValues[key]);
          if (value && value.hasOwnProperty('selectedIds')) {
            tmpInput.selectedIds = value.selectedIds;
          } else {
            tmpInput.value = value;
          }
        }
    });
  }

  public sendData(data) {
    return this.postResource(data, 'recipes');
  }

  public updateData(data: any, _id: number) {
    return this.patchResource(data, `recipes/${_id}`);
  }

  public deleteAction(id: number) {
    return this.deleteResource({}, `recipes/${id}`);
  }

}
