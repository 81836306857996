import { MultiselectDataSource } from "../../../../../components/multiselect/multiselect";
import { CustomEntityCondition, CustomEntityConditionForm } from "../../../../../models/segments/conditions/custom-entity-condition";
import { Operator } from "../../../../../models/segments/conditions/operator";
import { DateService } from "../../../../date.service";
import { OperatorsService } from "../../../common/operators.service";
import { CustomEntityKey } from "../enums/custom-entity-key.enums";

export function parseCustomEntityFormValuesFromAPI(
  params: CustomEntityCondition,
  operatorsService: OperatorsService,
  dateService: DateService
  ): CustomEntityConditionForm {

  let parsedValuesObj: CustomEntityConditionForm = {
    loaded_definition_id: params.definition_id,
    entity_attribute: params.field_name ? [new MultiselectDataSource(params.field_name, decorateAttrName(params.field_name), { type: params.field_type })] : null,
    entity_attribute_type: params.field_type,
    date_from: params?.hasOwnProperty('date_from') ? dateService.dynamicDateToDateOption(params.date_from) : null,
    date_to: params?.hasOwnProperty('date_to') ? dateService.dynamicDateToDateOption(params.date_to) : null
  };

  // Set Quantity operator and values
  getNumericOperatorValuesToForm(parsedValuesObj, params, operatorsService.getNumericOperators(), CustomEntityKey.QuantityOperator, CustomEntityKey.QuantityValue, CustomEntityKey.QuantityValue2, true);
  return parsedValuesObj;
}

function getNumericOperatorValuesToForm(parsedValuesObj: CustomEntityConditionForm, params: CustomEntityCondition, operators: Operator[], operatorKey: string, valueKey: string, value2Key: string, isQuantity?: boolean): void {
  const operatorApiKey = isQuantity ? 'quantity_operator' : 'operator';
  const valueApiKey = isQuantity ? 'quantity_value' : 'value';
  const value1ApiKey = isQuantity ? 'quantity_value1' : 'value1';
  const value2ApiKey = isQuantity ? 'quantity_value2' : 'value2';
  const operatorId = params[operatorApiKey];
  parsedValuesObj[operatorKey] = getOperatorValue(operatorId, operators);
  if (params[operatorApiKey] === 'bt') {
    parsedValuesObj[valueKey] = params?.[value1ApiKey] as number ?? null;
    parsedValuesObj[value2Key] = params?.[value2ApiKey] as number ?? null;
  } else {
    parsedValuesObj[valueKey] = params?.[valueApiKey] as number ?? null;
  }
}

function getOperatorValue(operatorId: string, operators: Operator[]): Operator[] {
  const operator = operatorId ? operators.find(operator => operator.id === operatorId) : null;
  return operator ? [operator] : null;
}

function decorateAttrName(rawName: string): string {
  rawName = rawName.replace(/-/g, ' ');
  rawName = rawName.replace(/_/g, ' ');
  rawName = rawName.charAt(0).toUpperCase() + rawName.slice(1);
  return rawName;
}