import { Injectable } from '@angular/core';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { Observable } from 'rxjs';
import { MultiselectDataSource, MultiselectDataSourceable } from '../../../../shared/components/multiselect/multiselect';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from '../../../../shared/services/resources.service';

@Injectable()
export class CompanyPromotionsService extends ResourcesService implements MultiselectDataSourceable {

  inputs: QuestionBase<unknown>[];

  constructor(http: HttpClient) {
    super(http);
  }

  getAll() {
    const requestOptions = {
      apiEndPoint: '/promotional_discounts',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  fetchMultiselect(searchValues?: string, page?: number): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'promotional_discounts',
      numberPerPage: 10,
      filtering: {},
      pageNumber: 1,
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues } };
      requestOptions = { ...requestOptions, ...filterObj };
    }

    return this.getData(requestOptions);
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `promotional_discounts/${id}` });
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`, element);
  }

  getInputs(formValues: object): QuestionBase<unknown>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        required: true
      }),
      new TextboxQuestion({
        key: 'discount',
        type: 'number',
        cssClasses: 'form-control input-default',
        step: 1,
        min: 0,
        max: 100
      })
    ];

    this.inputs = inputsArray;
    Object.keys(formValues).map(
      key => {
        if (key === 'name') {
          inputsArray.find( input => input.key === 'name' ).value = formValues[key];
        } else if (key === 'discount') {
          inputsArray.find( input => input.key === 'discount' ).value = parseInt(formValues[key], 10);
        }
      }
    );
    return this.inputs;
  }

  sendPromotionType(payload: object) {
    return this.postResource(payload, 'promotional_discounts');
  }

  updatePromotionType(payload: object, _id: number) {
    return this.patchResource(payload, `promotional_discounts/${_id}`);
  }

}
