<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" *ngIf="element" aria-hidden="true">
              <tbody>
                <tr *ngIf="element.id">
                  <td class="no-border-top"><b [translate]="'resources.message_categories.fields.id'"></b></td>
                  <td class="no-border-top">{{element.id}}</td>
                </tr>
                <tr *ngIf="element.name">
                  <td><b [translate]="'resources.message_categories.fields.name'"></b></td>
                  <td>{{element.name}}</td>
                </tr>
                <tr *ngIf="element.description">
                  <td><b [translate]="'resources.message_categories.fields.description'"></b></td>
                  <td>{{element.description}}</td>
                </tr>
                <tr *ngIf="element.available_days">
                  <td><b [translate]="'resources.message_categories.fields.available_days'"></b></td>
                  <td>{{element.available_days}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card col mrg-top-20 height-100">
    <h5 class="mrg-vertical-15">Listado</h5>
    <app-data-table *ngIf="dataTableConfig" [config]="dataTableConfig"></app-data-table>
  </div>

</div>
