import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SynchrosService extends ResourcesService {

  constructor(http: HttpClient,
              private translate: TranslateService) {
    super(http);
  }

  public getResourcesList() {
    return [
      { id: 'Activity', name: this.translate.instant('resources.synchros.filters.resources_options.activity') },
      { id: 'ActivityVoucher', name: this.translate.instant('resources.synchros.filters.resources_options.activity_voucher') },
      { id: 'Customer', name: this.translate.instant('resources.synchros.filters.resources_options.customer') },
      { id: 'Feature', name: this.translate.instant('resources.synchros.filters.resources_options.feature') },
      { id: 'FeatureProduct', name: this.translate.instant('resources.synchros.filters.resources_options.feature_product') },
      { id: 'Line', name: this.translate.instant('resources.synchros.filters.resources_options.line') },
      { id: 'Motivator', name: this.translate.instant('resources.synchros.filters.resources_options.motivator') },
      { id: 'Product', name: this.translate.instant('resources.synchros.filters.resources_options.product') },
      { id: 'Profile', name: this.translate.instant('resources.synchros.filters.resources_options.profile') },
      { id: 'Voucher', name: this.translate.instant('resources.synchros.filters.resources_options.voucher') },
      { id: 'VoucherHistory', name: this.translate.instant('resources.synchros.filters.resources_options.voucher_history') },
      { id: 'LocationsMotivator', name: this.translate.instant('resources.synchros.filters.resources_options.locations_motivator') }
    ];
  }

  public getStatusOptions() {
    return [
      { id: 'all', name: this.translate.instant('resources.synchros.filters.status_options.all') },
      { id: 'completed', name: this.translate.instant('resources.synchros.filters.status_options.completed') },
      { id: 'completed_with_errors', name: this.translate.instant('resources.synchros.filters.status_options.completed_with_errors') },
      { id: 'error', name: this.translate.instant('resources.synchros.filters.status_options.error') },
      { id: 'pending', name: this.translate.instant('resources.synchros.filters.status_options.pending') }
    ];
  }

  public getSynchroById(id: number): Observable<any> {
    return this.getData({apiEndPoint: `synchros/${id}`});
  }

}
