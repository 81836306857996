<ng-container *ngIf="inputs && isCustomEntityReady; else loadingCondition">
  <div class="row">
    <div [class]="wideLayout">
      <app-question [question]="getInputConfig(customEntityKey.DefinitionId)" [form]="form" (multiselectChanged)="handleSelectedEntity($event)"></app-question>
    </div>
  </div>

  <app-attributes-custom-entity-condition
      [inputs]="inputs"
      [form]="form"
      [attributesConfigArray]="attributesConfigArray"
      [newAttributeForm]="newAttributeForm">
  </app-attributes-custom-entity-condition>

  <div class="row mrg-top-15">
    <div class="col-md-12">
      <h5 class="text-bold">{{ 'resources.segment_conditions.titles.dates' | translate }}</h5>
    </div>
  </div>
  <div class="card-block no-pdd-vertical">
    <div class="row">
      <div [class]="wideLayout">
        <app-question [question]="getInputConfig(customEntityKey.DateFrom)" [form]="form"></app-question>
      </div>

      <div [class]="wideLayout">
        <app-question [question]="getInputConfig(customEntityKey.DateTo)" [form]="form"></app-question>
      </div>
    </div>
  </div>

  <div class="row mrg-top-15">
    <div class="col-md-12">
      <h5 class="text-bold">{{ 'resources.segment_conditions.titles.quantity' | translate }}</h5>
    </div>
  </div>
  <div class="card-block no-pdd-vertical">
    <div class="row mrg-btm-15">
      <div [ngClass]="isQuantityBtOperator ? narrowLayout : wideLayout">
        <app-question [question]="getInputConfig(customEntityKey.QuantityOperator)"
                      [form]="form"
                      (multiselectChanged)="handleOperatorChanges($event, customEntityKey.QuantityValue2)">
        </app-question>
      </div>

      <div [ngClass]="isQuantityBtOperator ? narrowLayout : wideLayout">
        <app-question [question]="getInputConfig(customEntityKey.QuantityValue)" [form]="form"></app-question>
      </div>

      <div *ngIf="isQuantityBtOperator" [class]="narrowLayout">
        <app-question [question]="getInputConfig(customEntityKey.QuantityValue2)" [form]="form"></app-question>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingCondition>
  <app-loader styleClass="mrg-btm-15" noWrapper />
</ng-template>
