
<div class="row" *ngIf="element">
  <div class="col-md-12">
    <div class="card mrg-left-15 mrg-right-15">
      <div class="card-block">
        <div class="pull-left">
          <h4 class="card-title">
            <i class="ei-timer"></i>
            {{ 'resources.reports.fields.last_generated' | translate }}
            {{lastGenerationAt | dateTimeZone: 'DD/MM/YYYY HH:mm'}}
          </h4>
        </div>
        <div class="btn-group pull-right">
          <button class="btn btn-default" (click)="downloadCsv()">
            {{'resources.reports.roi_by_store.buttons.download_csv' | translate}}
          </button>
        </div>
        <table class="table table-hover table-sm table-bordered no-mrg-btm" aria-hidden="true">
          <thead>
            <tr class="highlight-header">
              <th class="text-center" *ngFor="let header of tableHeaders">
                {{header.translateKey | translate: {currencySymbol: currencySymbol} }}
              </th>
            </tr>
          </thead>
          <tbody *ngIf="content">
            <tr *ngFor="let item of content | keyvalue">
              <td *ngFor="let header of tableHeaders; let i = index;">
                <ng-container [ngSwitch]="header.key">
                  <ng-container *ngSwitchCase="'campaign_category'">
                    {{ item.value.groupedCalculations.campaign_category }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'campaign_name'">
                    {{ item.value.groupedCalculations.campaign_name }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'origin_id'" >
                    <span
                      class="text-info cursor-pointer"
                      (click)="goToOrigin(item.value.groupedCalculations.origin_type, item.value.groupedCalculations.origin_id)"
                    >
                      <strong>{{ item.value.groupedCalculations.origin_id }}</strong>
                    </span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'dates'">
                    {{item.value.groupedCalculations.start_date | date: 'dd/MM/yyyy'}}
                    <br>
                    {{item.value.groupedCalculations.end_date | date: 'dd/MM/yyyy'}}
                  </ng-container>
                  <ng-container *ngSwitchCase="'coupon_id'" >
                    <span class="text-info cursor-pointer" (click)="goToCoupon(item.value.groupedCalculations.coupon_id)">
                      <strong>{{ item.value.groupedCalculations.coupon_id }}</strong>
                    </span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'discount'" >
                    <ng-container *ngIf="item.value.groupedCalculations.discount_type === 'percent'">
                      {{item.value.groupedCalculations.discount | number: '1.2-2' }} %
                    </ng-container>
                    <ng-container *ngIf="item.value.groupedCalculations.discount_type === 'cash'">
                      {{item.value.groupedCalculations.discount | lgcurrency: '1.2-2' }}
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'min_purchase'">
                    <ng-container *ngIf="item.value.groupedCalculations.min_units">
                      {{item.value.groupedCalculations.min_units | number: '1.0-0' }}
                      {{'resources.reports.roi_by_store.fields.units' | translate}}
                    </ng-container>
                    <ng-container *ngIf="item.value.groupedCalculations.min_purchase">
                      {{item.value.groupedCalculations.min_purchase | lgcurrency: '1.2-2' }}
                    </ng-container>
                    <!-- {{ item.value.groupedCalculations.min_purchase }} / {{item.value.groupedCalculations.min_units}} -->
                  </ng-container>
                  <ng-container *ngSwitchCase="'customers_impacted_by_informative_campaign'">
                    {{ item.value.groupedCalculations.customers_impacted_by_informative_campaign | number: '1.0-0' }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'customers_impacted_by_coupon'">
                    {{ item.value.groupedCalculations.customers_impacted_by_coupon | number: '1.0-0' }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'reaching_goal'">
                    {{item.value.groupedCalculations.reaching_goal | number: '1.2-2' }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'printed_vouchers'">
                    {{ item.value.groupedCalculations.printed_vouchers | number: '1.0-0' }}
                    <span class="label label-info font-size-14">
                      {{ item.value.groupedCalculations.printed_vouchers_ratio | percent: '1.2-2' }}
                    </span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'total_redemptions'">
                    {{ item.value.groupedCalculations.unique_voucher_redemptions | number: '1.0-0' }}
                    <span class="label label-info font-size-14">
                      {{ item.value.groupedCalculations.unique_voucher_redemptions_ratio | percent: '1.2-2' }}
                    </span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'discount_cost'">
                    {{ item.value.groupedCalculations.discount_cost | lgcurrency: '1.2-2' }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'estimated_incremental_sales'">
                    {{ item.value.groupedCalculations.estimated_incremental_sales | lgcurrency: '1.2-2' }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'incremental_ratio'">
                    {{ item.value.groupedCalculations.incremental_ratio | number: '1.2-2' }}
                  </ng-container>
                </ng-container>
              </td>
            </tr>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>
