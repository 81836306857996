import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function matchPasswordValidator(ctrlName: string, matchCtrlName: string): ValidatorFn {
  return (formCtrl: AbstractControl): ValidationErrors | null => {
    const ctrl = formCtrl.get(ctrlName);
    const matchCtrl = formCtrl.get(matchCtrlName);
    if (ctrl.value !== matchCtrl.value) {
      matchCtrl.setErrors({'notMatchingPassword': true});
      return { 'invalidMatchingPasswords': true };
    }
    matchCtrl.setErrors(null);
    return null;
  };
}