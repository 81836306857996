<div class="container-fluid clearfix" [ngClass]="{ 'mrg-top-15': !isModal }">

  <div class="page-title mrg-top-20" *ngIf="!isModal">
    <h4 [translate]="'resources.messages.fields.title'"></h4>
  </div>

  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-block">
          <ng-container *ngIf="element && form">
            <form [formGroup]="form">
              <app-question [question]="inputs[0]" [form]="form" (multiselectChanged)="handleDeliveryChanged($event)"></app-question>
            </form>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" *ngIf="element" aria-hidden="true">
              <tbody>
                <tr *ngIf="element.id">
                  <td class="no-border-top"><b [translate]="'resources.messages.fields.id'"></b></td>
                  <td class="no-border-top">{{element.id}}</td>
                </tr>
                <tr *ngIf="element.name">
                  <td><b [translate]="'resources.messages.fields.name'"></b></td>
                  <td>{{element.name}}</td>
                </tr>
                <tr *ngIf="element.description">
                  <td><b [translate]="'resources.messages.fields.description'"></b></td>
                  <td>{{element.description}}</td>
                </tr>
                <tr *ngIf="element.available_days">
                  <td><b [translate]="'resources.messages.fields.available_days'"></b></td>
                  <td>{{element.available_days}}</td>
                </tr>
                <tr *ngIf="element.priority">
                  <td><b [translate]="'resources.messages.fields.priority'"></b></td>
                  <td>{{element.priority}}</td>
                </tr>
                <tr *ngIf="element.message_category">
                  <td><b [translate]="'resources.messages.fields.message_category'"></b></td>
                  <td>{{element.message_category.name}}</td>
                </tr>
                <tr *ngIf="element.available_from">
                  <td><b [translate]="'resources.messages.fields.available_from'"></b></td>
                  <td>{{element.available_from | date: 'dd/MM/yyyy'}}</td>
                </tr>
                <tr *ngIf="element.available_to">
                  <td><b [translate]="'resources.messages.fields.available_to'"></b></td>
                  <td>{{element.available_to | date: 'dd/MM/yyyy'}}</td>
                </tr>
                <tr *ngIf="element.location_ids">
                  <td><b [translate]="'resources.messages.fields.location_ids'"></b>&nbsp;
                    <span *ngIf="element.location_ids.length > 0" class="text-semibold text-dark">({{element.location_ids.length}})</span>
                  </td>
                  <td *ngIf="element.location_ids.length === 0">{{'resources.messages.fields.all_stores' | translate}}</td>
                  <td *ngIf="element.location_ids.length > 0">
                    <ul class="list arrow bullet-success">
                      <li *ngFor="let item of element._embedded.locations">
                        <a class="pointer colorLink" (click)="goToLocation(item.id)">{{item.name}}</a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
