<div class="row mrg-btm-15" *ngIf="inputs">
  <div class="col-md-4">
    <app-question [question]="inputs[5]" [form]="form"></app-question>
  </div>
  <div class="col-md-4">
    <app-question [question]="inputs[1]" [form]="form"></app-question>
  </div>
  <div class="col-md-4">
    <app-question [question]="inputs[2]" [form]="form"></app-question>
  </div>
  <div class="col-md-4">
    <app-question [question]="inputs[0]" [form]="form"></app-question>
  </div>
  <div class="col-md-4">
    <app-question [question]="inputs[3]" (multiselectChanged)="handleChanges()" [form]="form"></app-question>
  </div>
  <div class="col-md-4">
    <app-question [question]="inputs[4]" [form]="form" [useToken]="true"></app-question>
  </div>
</div>
