import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Operator } from '../../../models/segments/conditions/operator';

@Injectable()
export class OperatorsService {

  constructor( 
    private translate: TranslateService 
  ) {}

  getStringOperators(): Operator[] {
    return [
      { id: 'eq', name: this.translate.instant('resources.segment_conditions.operators.eq') },
      { id: 'not_eq', name: this.translate.instant('resources.segment_conditions.operators.not_eq') },
      { id: 'contains', name: this.translate.instant('resources.segment_conditions.operators.contains') },
      { id: 'starts_with', name: this.translate.instant('resources.segment_conditions.operators.starts_with') },
      { id: 'ends_with', name: this.translate.instant('resources.segment_conditions.operators.ends_with') }
    ];
  }

  getNumericOperators(): Operator[] {
    return [
      { id: 'eq', name: this.translate.instant('resources.segment_conditions.operators.eq') },
      { id: 'lteq', name: this.translate.instant('resources.segment_conditions.operators.lteq') },
      { id: 'lt', name: this.translate.instant('resources.segment_conditions.operators.lt') },
      { id: 'gteq', name: this.translate.instant('resources.segment_conditions.operators.gteq') },
      { id: 'gt', name: this.translate.instant('resources.segment_conditions.operators.gt') },
      { id: 'bt', name: this.translate.instant('resources.segment_conditions.operators.bt') }
    ];
  }

  getNumericWithoutBtOperators(): Operator[] {
    return [
      { id: 'eq', name: this.translate.instant('resources.segment_conditions.operators.eq' )},
      { id: 'gteq', name: this.translate.instant('resources.segment_conditions.operators.gteq' )},
      { id: 'gt', name: this.translate.instant('resources.segment_conditions.operators.gt' )},
      { id: 'lteq', name: this.translate.instant('resources.segment_conditions.operators.lteq' )},
      { id: 'lt', name: this.translate.instant('resources.segment_conditions.operators.lt' )}
    ];
  }

  getAllNumericOperators(): Operator[] {
    return [
      { id: 'eq', name: this.translate.instant('resources.segment_conditions.operators.eq') },
      { id: 'not_eq', name: this.translate.instant('resources.segment_conditions.operators.not_eq') },
      { id: 'lteq', name: this.translate.instant('resources.segment_conditions.operators.lteq') },
      { id: 'lt', name: this.translate.instant('resources.segment_conditions.operators.lt') },
      { id: 'gteq', name: this.translate.instant('resources.segment_conditions.operators.gteq') },
      { id: 'gt', name: this.translate.instant('resources.segment_conditions.operators.gt') },
      { id: 'bt', name: this.translate.instant('resources.segment_conditions.operators.bt') }
    ];
  }

  getEqualityOperators(): Operator[] {
    return [
      { id: 'eq', name: this.translate.instant('resources.segment_conditions.operators.eq') },
      { id: 'not_eq', name: this.translate.instant('resources.segment_conditions.operators.not_eq') }
    ];
  }

  getHasBoughtOperators(): Operator[] {
    return [
      { id: 'in', name: this.translate.instant('resources.segment_conditions.operators.has_bought')},
      { id: 'not_in', name: this.translate.instant('resources.segment_conditions.operators.has_not_bought')}
    ];
  }

  getHasReturnedOperators(): Operator[] {
    return [
      { id: 'in', name: this.translate.instant('resources.segment_conditions.operators.has_returned')},
      { id: 'not_in', name: this.translate.instant('resources.segment_conditions.operators.has_not_returned')}
    ];
  }

  getHasUsedOperators(): Operator[] {
    return [
      { id: 'in', name: this.translate.instant('resources.segment_conditions.operators.has_used')},
      { id: 'not_in', name: this.translate.instant('resources.segment_conditions.operators.has_not_used')}
    ];
  }
}
