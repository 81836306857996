<div class="row" *ngIf="inputs">
  <div class="col-md-6">
    <div class="form-group">
      <app-question [question]="getInputConfig('channel')" 
                    [form]="form"></app-question>
    </div>
  </div>

  <div class="col-md-6" *ngIf="hasFormKeyWithValue('channel', 'push')">
    <div class="form-group">
      <app-question [question]="getInputConfig('status_push')" [form]="form"></app-question>
    </div>
  </div>

  <div class="col-md-6" *ngIf="hasFormKeyWithValue('channel', 'email')">
    <div class="form-group">
      <app-question [question]="getInputConfig('status_email')" [form]="form"></app-question>
    </div>
  </div>

  <div class="col-md-6" *ngIf="hasFormKeyWithValue('channel', 'sms')">
    <div class="form-group">
      <app-question [question]="getInputConfig('status_sms')" [form]="form"></app-question>
    </div>
  </div>
</div>
