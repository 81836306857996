import { Component, Input } from "@angular/core";
import { PromoReadyTicketDetail, TicketDetail } from "../../../../../shared/models/purchases/ticket-detail";

@Component({
  selector: 'app-promotions-block',
  templateUrl: './promotions-block.component.html',
  styleUrls: ['./promotions-block.component.scss']
})

export class PromotionsBlockComponent {

  @Input() promoReady: PromoReadyTicketDetail;
  @Input() ticketData: TicketDetail;
  @Input() currencySymbol: string;

  constructor() {}

  scroll(lineOrder: number) {
    document.getElementById("line" + lineOrder).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  openPromotionInNewTab(promoId: number) {
    window.open(`/#/customer-data-platform/warehouse/promotions(modal:show/promotions/${promoId})`, '_blank');
  }
}
