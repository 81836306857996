import { isNull } from 'util';

export class ClubIndicatorsDataRow {
  title: string;
  rawDateRange?: number = null;
  rawPreviousDateRange?: number = null;
  currentDateRange: number = null;
  previousDateRange: number = null;
  numActivities?: number = null;
  numActivitiesPreviousPeriod?: number = null;
}

export class ClubIndicatorsData {

  totalSales = new ClubIndicatorsDataRow();
  totalIdentifiedSales = new ClubIndicatorsDataRow();
  totalIdentifiedSalePercentage = new ClubIndicatorsDataRow();
  activeCustomers = new ClubIndicatorsDataRow();
  unidentifiedTickets = new ClubIndicatorsDataRow();
  eurosByCustomer = new ClubIndicatorsDataRow();
  frequency = new ClubIndicatorsDataRow();
  identifiedTicketAverage = new ClubIndicatorsDataRow();
  signups = new ClubIndicatorsDataRow();
  masification = new ClubIndicatorsDataRow();

  public salesReady() {
    return !isNull(this.totalSales.currentDateRange) &&
           !isNull(this.totalSales.previousDateRange) &&
           !isNull(this.totalIdentifiedSales.currentDateRange) &&
           !isNull(this.totalIdentifiedSales.previousDateRange);
  }

  public eurosByCustomerReady() {
    return !isNull(this.activeCustomers.currentDateRange) &&
           !isNull(this.activeCustomers.previousDateRange) &&
           !isNull(this.totalIdentifiedSales.currentDateRange) &&
           !isNull(this.totalIdentifiedSales.previousDateRange);
  }

  public frequencyReady() {
    return !isNull(this.totalIdentifiedSales.numActivities) &&
           !isNull(this.activeCustomers.currentDateRange) &&
           !isNull(this.totalIdentifiedSales.numActivitiesPreviousPeriod) &&
           !isNull(this.activeCustomers.previousDateRange);
  }

  public totalIdentifiedReady() {
    return !isNull(this.totalIdentifiedSales.currentDateRange) &&
           !isNull(this.totalIdentifiedSales.previousDateRange) &&
           !isNull(this.totalIdentifiedSales.numActivities) &&
           !isNull(this.totalIdentifiedSales.numActivitiesPreviousPeriod);
  }

  public massificationReady() {
    return !isNull(this.masification.rawDateRange) &&
           !isNull(this.masification.rawPreviousDateRange) &&
           !isNull(this.activeCustomers.currentDateRange) &&
           !isNull(this.activeCustomers.previousDateRange);
  }

  public calculateIdentifiedSalesPercentage() {
    let prevIdentified = 0;
    let currentIdentified = 0;
    if (this.totalSales.currentDateRange !== 0) {
      currentIdentified = ( this.totalIdentifiedSales.currentDateRange / this.totalSales.currentDateRange ) * 100;
    }
    if (this.totalSales.previousDateRange !== 0) {
      prevIdentified = ( this.totalIdentifiedSales.previousDateRange / this.totalSales.previousDateRange ) * 100;
    }
    if (isNaN(currentIdentified)) { currentIdentified = 0; }
    if (isNaN(prevIdentified)) { prevIdentified = 0; }
    this.totalIdentifiedSalePercentage.currentDateRange = currentIdentified;
    this.totalIdentifiedSalePercentage.previousDateRange = prevIdentified;
  }

  public calculateEurosByCustomer() {
    let eurosByCustomer = 0;
    let eurosByCustomerPrev = 0;
    if (this.activeCustomers.currentDateRange !== 0) {
      eurosByCustomer = ( this.totalIdentifiedSales.currentDateRange / this.activeCustomers.currentDateRange );
    }
    if (this.activeCustomers.previousDateRange !== 0) {
      eurosByCustomerPrev = ( this.totalIdentifiedSales.previousDateRange / this.activeCustomers.previousDateRange );
    }
    if (isNaN(eurosByCustomer)) { eurosByCustomer = 0; }
    if (isNaN(eurosByCustomerPrev)) { eurosByCustomerPrev = 0; }
    this.eurosByCustomer.currentDateRange = eurosByCustomer;
    this.eurosByCustomer.previousDateRange = eurosByCustomerPrev;
  }

  public calculateFrequency() {
    let frequency = 0;
    let frequencyPrev = 0;
    if (this.activeCustomers.currentDateRange) {
      frequency = ( this.totalIdentifiedSales.numActivities / this.activeCustomers.currentDateRange );
    }
    if ( this.activeCustomers.previousDateRange !== 0 ) {
      frequencyPrev = ( this.totalIdentifiedSales.numActivitiesPreviousPeriod / this.activeCustomers.previousDateRange );
    }
    if (isNaN(frequency)) { frequency = 0; }
    if (isNaN(frequencyPrev)) {frequencyPrev = 0; }
    this.frequency.currentDateRange = frequency;
    this.frequency.previousDateRange = frequencyPrev;
  }

  public calculateTicketAverage() {
    let identifiedSales = 0;
    let identifiedSalesPrev = 0;
    if ( this.totalIdentifiedSales.numActivities !== 0 ) {
      identifiedSales = ( this.totalIdentifiedSales.currentDateRange / this.totalIdentifiedSales.numActivities );
    }
    if ( this.totalIdentifiedSales.numActivitiesPreviousPeriod !== 0 ) {
      identifiedSalesPrev = ( this.totalIdentifiedSales.previousDateRange / this.totalIdentifiedSales.numActivitiesPreviousPeriod );
    }
    if (isNaN(identifiedSales)) {identifiedSales = 0; }
    if (isNaN(identifiedSalesPrev)) {identifiedSalesPrev = 0; }
    this.identifiedTicketAverage.currentDateRange = identifiedSales;
    this.identifiedTicketAverage.previousDateRange = identifiedSalesPrev;
  }

  public calculateMassification() {
    let massification = 0;
    let massificationPrev = 0;

    if (this.activeCustomers.rawDateRange !== 0) {
      massification = ( this.masification.rawDateRange / this.activeCustomers.currentDateRange ) * 100;
    }
    if (this.activeCustomers.rawPreviousDateRange !== 0) {
      massificationPrev = ( this.masification.rawPreviousDateRange / this.activeCustomers.previousDateRange ) * 100;
    }
    if (isNaN(massification)) { massification = 0; }
    if (isNaN(massificationPrev)) { massificationPrev = 0; }
    this.masification.currentDateRange = massification;
    this.masification.previousDateRange = massificationPrev;
  }
}
