import { Injectable } from '@angular/core';
import { QuestionBase } from '../../models/forms/question-base';
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectQuestion } from '../../models/forms/question-multiselect';
import { multiselectPresenceValidator, formGroupEmptyValidator } from '../validations.service';
import { DictionaryService } from '../dictionary.service';
import { CountriesService } from '../countries.service';

@Injectable()
export class PhonePrefixConditionService {

  public inputs: QuestionBase<any>[];
  public customValidators = formGroupEmptyValidator();

  constructor(
    private translate: TranslateService,
    private countriesSrv: CountriesService,
  ) { }

  getInputs(params?: any): QuestionBase<any>[] {
    const inputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'phone_prefixes',
        label: this.translate.instant('resources.segment_conditions.fields.phone_prefix'),
        options: this.countriesSrv.getCountries('phone_prefixes'),
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        required: true,
        getValue: (value) => ( value && value.length ) ? value : [],
        parseValue: (values) => ((values && values.length) ? values.map( value => value.rawElement.dial_code ) : []),
        customValidators: [multiselectPresenceValidator]
      })
    ];

    if ( params ) {
      Object.keys(params).forEach( key => {
        const input = inputs.find( _input => _input.key === key );
        const value = input.getValue(params[key]);
        if ( value && value.hasOwnProperty('selectedIds')) {
          input.selectedIds = value.selectedIds;
        } else {
          input.value = value;
        }
      });
    }

    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI( params: any ): any {
    const parsedValuesObj = {};
    Object.keys(params).forEach( key => {
      const input = this.inputs.find( _input => _input.key === key );
      const parsedValue = input.parseValue( params[key] );
      parsedValuesObj[key] = parsedValue;
    });
    return parsedValuesObj;
  }

  prepareFormValuesFromAPI( params: any ): any {
    const parseValues = {
      phone_prefixes: (value) => (value && value.length) ? this.countriesSrv.getCountries('phone_prefixes').filter(item => value.includes(item.rawElement.dial_code)) : null
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach( key => { parsedValuesObj[key] = parseValues[key](params[key]); });
    return parsedValuesObj;
  }

}
