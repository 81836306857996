import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MultiselectDataSource, MultiselectDataSourceable } from '../../shared/components/multiselect/multiselect';
import { Observable, map } from 'rxjs';
import { ResourcesService } from '../../shared/services/resources.service';

@Injectable({
  providedIn: 'root'
})
export class ChallengesService extends ResourcesService implements MultiselectDataSourceable {

  fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'achievement_definitions',
      filtering: {
        achievement_type: 'challenge',
        active: true
      },
      numberPerPage: 300,
      pageNumber: 1
    };

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues, achievement_type: 'challenge', active: true }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `achievement_definitions?id=${id}&achievement_type=challenge&order_field=id&order_sense=asc&per_page=300` }).pipe(
      map( itemArray => itemArray['list'] ),
      map( element => {
        return element.filter( x => `${x.id}` === `${id}` )[0];
      }));
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    const name = element?.name_with_tier ? element.name_with_tier : element.name;
    return new MultiselectDataSource(element.id, name);
  }

}
