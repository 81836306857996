import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBase } from './../../../shared/models/forms/question-base';
import { CheckboxQuestion } from './../../../shared/models/forms/question-checkbox';
import { QuestionControlService } from './../../../shared/services/question-control.service';

@Component({
  selector: 'app-campaign-validate-modal',
  templateUrl: './campaign-validate-modal.component.html',
  styleUrls: ['./campaign-validate-modal.component.scss'],
})

export class CampaignValidateModalComponent implements OnInit {  
  translations = {
    title: 'resources.campaigns.modal.validate.title',
    msg_title: 'resources.campaigns.modal.validate.messages.title',
    msg_description1: 'resources.campaigns.modal.validate.messages.description1',
    msg_description2: 'resources.campaigns.modal.validate.messages.description2',
    email_notification: 'resources.campaigns.modal.validate.fields.email_notification',
    validate: 'resources.campaigns.buttons.validate',
    send_campaign: 'resources.campaigns.buttons.send_campaign',
  };

  campaignValidateModalForm: UntypedFormGroup;

  inputs: QuestionBase<any>[];

  @Output() onValidateCampaign = new EventEmitter<boolean>();

  @ViewChild('modalToggleBtn', { static: true }) modalToggleBtn: ElementRef<HTMLElement>;

  constructor(
    private qcs: QuestionControlService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.inputs = this.setInputs();
    this.campaignValidateModalForm = this.qcs.toFormGroup(this.inputs);
  }

  setInputs(): QuestionBase<any>[] {
    return [
      new CheckboxQuestion({
        key: 'email_notification',
        label: this.translate.instant(this.translations.email_notification),
        value: false,
        cssClasses: 'form-control input-default',
      }),
    ];
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  toggleModal(): void {
    this.modalToggleBtn.nativeElement.click();
  }

  validate(): void {
    this.onValidateCampaign.emit(this.campaignValidateModalForm.value.email_notification);
    this.toggleModal();
  }
}
