import { TranslateService } from "@ngx-translate/core";
import { DictionaryService } from "../../../../shared/services/dictionary.service";
import { parseSystem } from "../utils/parser.utils";
import { OriginBadgeClassName } from "../enums/customer-attributes.enums";

export class OriginBadge {
  className: string;
  name: string;

  constructor(
    systemOrigin: boolean,
    translate: TranslateService,
    dictionaryService: DictionaryService
  ) {
    this.className = systemOrigin ? OriginBadgeClassName.System : OriginBadgeClassName.Custom;
    this.name = parseSystem(systemOrigin, translate, dictionaryService);
  }
}
