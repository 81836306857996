import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBase } from '../models/forms/question-base';
import { MultiSelectQuestion } from '../models/forms/question-multiselect';
import { CouponsService } from '../../resources/coupons/coupons.service';
import { CustomerJourney } from '../models/customer-journeys/customer-journey';
import { UntypedFormGroup } from '@angular/forms';
import { DateService } from './date.service';
import { TextboxQuestion } from '../models/forms/question-textbox';
import { CheckboxQuestion } from '../models/forms/question-checkbox';
import { FloatQuestion } from '../models/forms/question-float';
import { TagSalesService } from './tag-sales.service';
import { LocationsTaxonomyTermsService } from '../../resources/data-warehouse/locations/location-taxonomy-terms.service';
import { TagActivitiesService } from './tag-activities.service';
import { V1SegmentsService } from '../../resources/segments/v1-segments.service';

@Injectable()
export class RecuperationJourneyService {

  public inputs: QuestionBase<any>[];

  constructor( private translate:                       TranslateService,
               private segmentService:                  V1SegmentsService,
               private couponsService:                  CouponsService,
               private dateService:                     DateService,
               private locationsTaxonomiesTermsService: LocationsTaxonomyTermsService,
               private tagService:                      TagSalesService,
               private tagActivitiesService:            TagActivitiesService ) {}

  public getDefinitionStepFormCfg(journey: CustomerJourney): any[] {
    this.inputs = [
      new MultiSelectQuestion({
        colWidth: 'col-md-2',
        cssClasses: 'form-control input-md',
        key: 'activity_tag',
        label: this.translate.instant('resources.journeys.fields.activity_tag'),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: false },
        dataSource: this.tagActivitiesService,
        getValue: (value) => ({ selectedIds: value })
      }),
      new MultiSelectQuestion({
        colWidth: 'col-md-2',
        cssClasses: 'form-control input-md',
        key: 'best_sales_tags',
        dataSource: this.tagService,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: false },
        label: this.translate.instant('resources.journeys.fields.best_sales_tag'),
        getValue: (value) => value ? ({ selectedIds: value }) : { selectedIds: [] }
      }),
      new MultiSelectQuestion({
        colWidth: 'col-md-3',
        cssClasses: 'form-control input-md',
        key: 'location_terms',
        label: this.translate.instant('resources.journeys.fields.location_taxonomy_terms'),
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locationsTaxonomiesTermsService,
        getValue: (value) => value ? ({selectedIds: value}) : { selectedIds: [] }
      }),
      new MultiSelectQuestion({
        colWidth: 'col-md-2',
        cssClasses: 'form-control input-md',
        key: 'extra_segment',
        label: this.translate.instant('resources.journeys.fields.extra_segment'),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.segmentService,
        getValue: (value) => value ? { selectedIds: [value] } : { selectedIds: [] }
      }),
      new MultiSelectQuestion({
        colWidth: 'col-md-3',
        cssClasses: 'form-control input-md',
        key: 'coupon',
        label: this.translate.instant('resources.journeys.fields.coupon'),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.couponsService,
        getValue: (value) => value ? { selectedIds: [value] } : { selectedIds: [] }
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'reportable',
        label: this.translate.instant('resources.journeys.fields.include_in_roi'),
        getValue: (value) => value
      }),
      new FloatQuestion({
        cssClasses: 'form-control input-md',
        key: 'prediction_percentage_redemption',
        label: this.translate.instant('resources.journeys.fields.redemption_percentage'),
        type: 'number',
        min: 0.0,
        step: 0.1,
        getValue: (value) => value
      })
    ];

    journey.steps.forEach((step, index) => {

      const stepKeyName = `step${index + 1}`;
      const isEditableStep = journey.isEditableStep(step);

      this.inputs.push(
        new TextboxQuestion({
          cssClasses: 'form-control input-md',
          key: `${stepKeyName}.scheduled_at`,
          disabled: !isEditableStep,
          type: 'datetime-local',
          step: 60,
          getValue: (value) => value ? this.dateService.dateISOStringToDateTime(value) : null
        })
      );
    });

    /* Definition input fields values */
    Object.keys(journey.cfg.configuration).forEach(
      (key) => {
        const tmpInput = this.inputs.find( input => input.key === key );
        if ( tmpInput && tmpInput.hasOwnProperty('getValue') ) {
          const value = tmpInput.getValue( journey.cfg.configuration[key] );
          if ( value.hasOwnProperty('selectedIds')) {
            tmpInput.selectedIds = value.selectedIds instanceof Array ? value.selectedIds : [value.selectedIds];
          } else {
            tmpInput.value = value;
          }
        }
      }
    );

    /* Steps schedule values */
    journey.steps.forEach(
      (step, index) => {
        const tmpInput = this.inputs.find( input => input.key === `step${index + 1}.scheduled_at` );
        const valueSch = tmpInput.getValue(step.scheduled_at);
        tmpInput.value = valueSch;

        /* Get coupon id from first step configuration */
        if ( index === 0) {
          const couponInput = this.inputs.find( input => input.key === 'coupon');
          const couponValue = couponInput.getValue(step.configuration.coupon_id);
          couponInput.selectedIds = couponValue.selectedIds;
        }
      }
    );

    /* Report values */
    const reportInput = this.inputs.find( _input => _input.key === 'reportable' );
    reportInput.value = reportInput.getValue(journey.reportable);

    if ( journey.cfg.report_configuration.reward &&
         journey.cfg.report_configuration.reward.hasOwnProperty('prediction_percentage_redemption') &&
         journey.cfg.report_configuration.reward.prediction_percentage_redemption ) {
      const rewardInput = this.inputs.find( _input => _input.key === 'prediction_percentage_redemption' );
      const rewardValue = rewardInput.getValue( journey.cfg.report_configuration.reward.prediction_percentage_redemption );
      rewardInput.value = rewardValue;
    }

    if ( !journey._original ) {
      ['activity_tag', 'best_sales_tags', 'location_terms', 'extra_segment'].forEach( key => {
        this.inputs.find( _input => _input.key === key ).disabled = true;
      });
    }

    return this.inputs;
  }

  public prepareToSave(journey: CustomerJourney, form: UntypedFormGroup) {
    const name = journey.name;
    const activity_tag = (form.value['activity_tag'] && form.value['activity_tag'].length) ? form.value['activity_tag'][0].id : null;
    const best_sales_tags = (form.value['best_sales_tags'] && form.value['best_sales_tags'].length) ? form.value['best_sales_tags'].map( el => el.id ) : null;
    const location_terms = (form.value['location_terms'] && form.value['location_terms'].length) ? form.value['location_terms'].map( el => el.id ) : null;
    const extra_segment = (form.value['extra_segment'] && form.value['extra_segment'].length) ? form.value['extra_segment'][0].id : null;
    const coupon = (form.value['coupon'] && form.value['coupon'].length) ? form.value['coupon'][0].id : null;

    const configuration = {
      activity_tag: activity_tag,
      best_sales_tags: best_sales_tags,
      location_terms: location_terms,
      message: journey.configuration.message
    };

    if ( extra_segment ) { configuration['extra_segment'] = extra_segment; }

    const steps = journey.steps.map(
      (step, index) => {
        const stepCfg = step.configuration;
        stepCfg.coupon_id = coupon ? parseInt(coupon, 10) : null;
        /* TODO: Check if nec. to add voucheable info to each step cfg */
        return {
          id: step.id,
          status: step.status,
          position: step.position,
          scheduled_at: form.value[`step${index + 1}.scheduled_at`],
          configuration: stepCfg
        };
      }
    );

    // Summary
    const reportCfg = {};
    const reportable = form.value[`reportable`] ? form.value[`reportable`] : false;
    const rewardPercentage = form.value.prediction_percentage_redemption ? parseFloat(form.value.prediction_percentage_redemption) : null;

    if ( rewardPercentage ) { reportCfg['reward'] = { prediction_percentage_redemption: rewardPercentage }; }

    const objToPost = {
      name: name,
      configuration: configuration,
      reportable: reportable,
      report_configuration: reportCfg,
      steps: steps
    };

    return objToPost;
  }

  public getJourneyName(journey: CustomerJourney) {
    return journey.name;
  }
}
