import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ActionModalsService } from '../../data-warehouse/profiles/show/tabs/summary/action-modals/action-modals.service';
import { PlanCampaignService } from '../campaigns/plan-campaign.service';
import { PlansService } from '../plans.service';
import { Campaign } from './../../../shared/models/campaign/campaign';
import { QuestionBase } from './../../../shared/models/forms/question-base';
import { MultiSelectQuestion } from './../../../shared/models/forms/question-multiselect';
import { TextboxQuestion } from './../../../shared/models/forms/question-textbox';
import { ConfirmationService } from './../../../shared/services/confirmation.service';
import { ModalStatusService } from './../../../shared/services/modal-status.service';
import { QuestionControlService } from './../../../shared/services/question-control.service';

@Component({
  selector: 'app-plan-campaign-clone',
  templateUrl: './plan-campaign-clone.component.html',
  styleUrls: ['./plan-campaign-clone.component.scss'],
  providers: [ActionModalsService]
})
export class PlanCampaignCloneComponent implements OnInit, OnDestroy {

  planID: string[];
  campaign: Campaign;
  campaignId: string;
  baseUrl: string;

  cloneCampaignForm: UntypedFormGroup;
  inputs: QuestionBase<any>[];

  loading: boolean;

  get name() { return this.cloneCampaignForm?.get('name') }
  get plan() { return this.cloneCampaignForm?.get('plan') }

  private sub$ = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private planCampaignService: PlanCampaignService,
    private plansService: PlansService,
    private confirmationService: ConfirmationService,
    private modalStatusService: ModalStatusService,
    private qcs: QuestionControlService,

  ) { }

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  closeModal(): void {
    this.modalStatusService.modalStatus.emit();
    this.router.navigate([{ outlets: { modal: this.baseUrl } }]).catch(() => {});
  }

  async cloneCampaign(): Promise<void> {
    this.loading = true;

    const cloneTitle = this.translate.instant('resources.campaign_plans.messages.title_edit_completed');
    const cloneText = this.translate.instant('resources.campaign_plans.messages.confirm_clone',
      { oldName: this.campaign.name, newName: this.name.value });

    const confirm = await this.confirmationService.displayConfirmationAlert(cloneTitle, cloneText, 'question');

    if (confirm.value) {
      const payload = {
        cloned_name: this.name.value,
        plan_to_move_id: this.plan.value[0].id,
      };

      const clone$ = this.planCampaignService.cloneCampaign(payload, this.campaignId).subscribe(
        () => this.closeModal(),
        errorData => this.confirmationService.displayErrorAlert('Error', errorData.error.error)
      );

      this.sub$.add(clone$);
    }

    this.loading = false;
  }

  private getParams(): void {
    this.loading = true;
    const router$ = this.route.params.subscribe(params => {
      this.campaignId = params.id;
      const url = this.router.url;

      this.baseUrl = url.slice(url.indexOf('show'), url.indexOf(`/${this.campaignId}`));
      this.planID = this.baseUrl.match(/\d+/g);

      this.getCampaign();
    });
    this.sub$.add(router$);
  }

  private getCampaign(): void {
    const getCampaign$ = this.planCampaignService.getOne(this.campaignId).subscribe(
      (response: Campaign) => {
        this.campaign = new Campaign(response);
        this.getInputs();
      }
    );
    this.sub$.add(getCampaign$);
  }

  private getInputs(): void {

    this.plansService.setStatusSlugToFilter('draft');

    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        value: `Copy_${this.campaign.name}`,
        required: true,
        cssClasses: 'form-control input-default',
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'plan',
        dataSource: this.plansService,
        selectedIds: this.planID,
        required: true,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true }
      }),
    ];

    this.inputs = inputsArray;
    this.cloneCampaignForm = this.qcs.toFormGroup(this.inputs);
    this.loading = false;
  }
}
