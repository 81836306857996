import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { ConfirmationService } from '../../services/confirmation.service';
import { DataTableFilterService } from '../data-table-filter/data-table-filter.service';
import { DateService } from '../../services/date.service';
import { DateTimeZoneService } from '../../services/date-time-zone.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { isNullOrUndefined } from '../../utils/common.utils';
import { LaunchDarklyService } from '../../services/launch-darkly/launchdarkly.service';
import { LocationsService } from '../../../resources/data-warehouse/locations/locations.service';
import { RefreshCacheService } from '../../services/refresh-cache.service';
import { SalesService } from '../../../resources/analytics/sales/sales.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-looker-iframe-container',
  templateUrl: './looker-iframe-container.component.html',
  styleUrls: ['./looker-iframe-container.component.scss']
})
export class LookerIFrameContainerComponent implements OnInit, OnDestroy {

  embedUrls: SafeResourceUrl[] = [];
  embedErrs: string[] = [];
  subs$: Subscription[] = [];
  loading = false;
  dashboardIds: string[] = [];
  dashboardCount = 0;
  slug: string;
  dateFrom: string;
  dateTo: string;
  flags = {
    lookerIntegrationTestDashboardId: null
  };
  dashboardPerUnitMeasure = false;
  unitMeasureVisibility: object = {
    units: true,
    variation: true
  };
  hideCurrentDashboard = false;
  hideExtraDashboard = true;
  isOnSearchSubmitted = false;
  filtersLoaded: boolean;
  tabHasFilters: boolean;

  private preloadedSegments = 0;
  private segmentsToPreload = 1;

  @Input() dashboardIdsInpt: string[] = [];

  @ViewChildren('iframes') iframes;

  constructor(
    private salesService: SalesService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private launchDarklyService: LaunchDarklyService,
    private dateService: DateService,
    private dateTimeZoneService: DateTimeZoneService,
    private translate: TranslateService,
    private refreshCacheService: RefreshCacheService,
    private confirmationService: ConfirmationService,
    private filterService: DataTableFilterService,
    private locationsService: LocationsService
  ) {}

  ngOnInit(): void {
    this.route.snapshot.data.mode ? this.handleContainerForIntegrationTestModes() : this.initIframeContainer();
    this.handleDashboardsPerUnitMeasure();
    this.attachFiltersSub();
    this.slug = this.route.snapshot.data.slug;
    this.tabHasFilters = this.route.snapshot.data.hasOwnProperty('hasFilters') ? this.route.snapshot.data.hasFilters : true;
    this.handleFiltersLoaderStatus();
  }

  ngOnDestroy(): void {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  initIframeContainer(): void {
    this.loading = true;
    this.dashboardIds = this.route.snapshot.data.dashboardIds || this.dashboardIdsInpt;
    if (!this.dashboardIds || this.dashboardIds.length <= 0) {
      console.warn('LookerIFrameContainerComponent requires dashboardIds value in route data');
      return;
    }
    this.displayIframesByRouteCfg(this.dashboardIds[this.dashboardCount]);
  }

  onFilterHandler(fltrPayload: object): void {
    const dateFrom = this.dateTimeZoneService.parseDateWithFormat(fltrPayload['date_from'], 'YYYY/MM/DD');
    const dateTo = this.dateTimeZoneService.parseDateWithFormat(fltrPayload['date_to'], 'YYYY/MM/DD');

    fltrPayload['Launch Product Date'] = `${dateFrom} to ${dateTo}`;
    fltrPayload['Purchase Date'] = `${dateFrom} to ${dateTo}`;
    delete fltrPayload['date_from'];
    delete fltrPayload['date_to'];

    this.updateAndRunLookerDashboards(fltrPayload);
  }

  private handleContainerForIntegrationTestModes(): void {
    if (['test_with_ff', 'test_with_ff_filters'].includes(this.route.snapshot.data.mode)) {
      this.processFeatureFlag();
    } else if (this.route.snapshot.data.mode === 'test_with_input') {
      const filter$ = this.filterService.lookerFilterId$.subscribe(
        (userInput: string) => this.setIframeUrlById(userInput)
      );
      this.subs$.push(filter$);
    }
  }

  private handleDashboardsPerUnitMeasure(): void {
    const filterCfg = this.route.snapshot.data && this.route.snapshot.data.filterCfg;
    const hasEnsureButtonDisabling = filterCfg && filterCfg.ensureButtonDisabling;
    const hasUnitMeasureCfg = filterCfg && filterCfg.dashboardPerUnitMeasure;
    const hasUnitMeasureQuaCfg = filterCfg && filterCfg.dashboardPerUnitMeasureQuantity;

    if (hasUnitMeasureCfg || hasUnitMeasureQuaCfg || hasEnsureButtonDisabling) {
      this.dashboardPerUnitMeasure = hasUnitMeasureCfg || hasUnitMeasureQuaCfg;
      this.unitMeasureVisibility = this.displayDashboardByUnitMeasure();
      setTimeout(() => this.filterService.loaderStatus.next(true));
    }
  }

  private attachFiltersSub(): void {
    if (this.salesService.applyFilters$) {
      const filterChange$ = this.salesService.applyFilters$?.subscribe(
        (filterPayload: object) => {
          if (this.slug === 'evolution') {
            this.handleEvolutionFilterPayload(filterPayload);
          } else if (['summary', 'sales_evolution', 'concurrency', 'identified_customers', 'summary_contribution', 'time_slot_analysis', 'price_index',
            'customers_evolution_shopper', 'classification', 'categories_contribution', 'locations_contribution', 'logs_streaming_api', 'distribution', 'promotions',
            'retention', 'substitutability', 'private_label_analysis', 'apps_analysis','constant_customers', 'rfm_analysis', 'affinity', 'transferability', 'private_label',
            'metrics', 'suppliers_brands', 'products_substitutability', 'segment_categories', 'customers_summary', 'customers_migrations', 'loyalty_summary',
            'loyalty_points_accrued', 'loyalty_points_redeemed', 'loyalty_rewards', 'omnichannel_campaigns_cost', 'geomarketing', 'features_analysis', 'data_quality_summary',
            'store_migration', 'segments_customer_type', 'evolution_roi', 'sales_analysis', 'customers_analysis', 'stores_analysis', 'product_ean_analysis',
            'segment_drill_down', 'overlap_customers_segment', 'kpi_segments_comparison'].includes(this.slug)) {
            this.handleRetailerCommonFilterPayload(filterPayload);
          } else if (['sales_evolution_supplier', 'concurrency_supplier', 'identified_customers_supplier', 'summary_contribution_supplier', 'time_slot_analysis_supplier',
            'price_index_supplier', 'customers_evolution_supplier', 'classification_supplier', 'categories_contribution_supplier', 'locations_contribution_supplier',
            'distribution_supplier', 'promotions_supplier', 'retention_supplier', 'affinity_supplier', 'transferability_supplier', 'suppliers_brands_supplier',
            'products_substitutability_supplier'].includes(this.slug)) {
            this.updateAndRunLookerDashboards(filterPayload);
          }
        }
      );
      this.subs$.push(filterChange$);
    }
  }

  private displayDashboardByUnitMeasure(fltrPayload?: object): {units: boolean, variation: boolean} {
    const payload = fltrPayload ? fltrPayload : this.salesService.filters;
    if (payload.hasOwnProperty('unit_measure') && payload.unit_measure === '€') {
      return {units: true, variation: false};
    } else if (payload.hasOwnProperty('unit_measure_quantity') && payload.unit_measure_quantity === '#') {
      return {units: true, variation: false};
    } else if (payload.hasOwnProperty('unit_measure') && payload.unit_measure === '%') {
      return {units: false, variation: true};
    } else if (payload.hasOwnProperty('unit_measure_quantity') && payload.unit_measure_quantity === '%') {
      return {units: false, variation: true};
    }
    return {units: true, variation: false};
  }

  private handleEvolutionFilterPayload(fltrPayload: object): void {
    const productIds = fltrPayload['product_ids'];
    const params = {apiEndPoint: 'analytics/launch_dates', filtering: {product_ids: productIds}};
    this.isOnSearchSubmitted = true;
    const launchDates$ = this.refreshCacheService.getExpensiveData(params).subscribe((response: HttpResponse<object>) => {
      this.loading = true;
      if (!this.refreshCacheService.isRequestPending(response)) {
        launchDates$.unsubscribe();
        this.loading = false;
        this.handleLaunchDatesResponse(response, fltrPayload);
      }
    });
    this.subs$.push(launchDates$);
  }

  private calculateLastCompletedMonth(fltrPayload) {
    const actualDate = new Date();
    const actualYear = actualDate.getUTCFullYear();
    const selectedYear = parseInt(fltrPayload['year_of_last_years']);
    const actualMonth = actualDate.getUTCMonth() + 1; // Sumamos 1 porque los meses en JavaScript se indexan desde 0
    const lastDayOfCompletedMonth = new Date(Date.UTC(actualYear, actualMonth - 1, 0));
    const parsedDate = lastDayOfCompletedMonth.toISOString().split('T')[0];

    const evaluateDate = selectedYear === actualYear ?  parsedDate : `${fltrPayload['year_of_last_years']}-12-31`;
    return evaluateDate;
  }

  private handleRetailerCommonFilterPayload(fltrPayload: object): void {
    let calculatedDates = null;
    if (fltrPayload.hasOwnProperty('date_from') && fltrPayload.hasOwnProperty('date_to')) {
      calculatedDates = this.getCalculatedDates(fltrPayload, 'date_from', 'date_to');
    } else if (fltrPayload['tag_date'] || fltrPayload['tag_date_origin']) {
      const fltrKey = fltrPayload['tag_date'] ? 'tag_date' : 'tag_date_origin';
      calculatedDates = this.getCalculatedTagDates(fltrPayload, fltrKey);
    } else if (fltrPayload.hasOwnProperty('year_of_last_years')) {
      const firstDay = `${fltrPayload['year_of_last_years']}-01-01`;
      const lastDay = this.calculateLastCompletedMonth(fltrPayload);
      calculatedDates = `${firstDay} to ${lastDay}`;
    }

    let calculatedComparedDates = null;
    const filterCfg = this.route?.snapshot?.data?.filterCfg;
    if (fltrPayload.hasOwnProperty('date_from_compared') && fltrPayload.hasOwnProperty('date_to_compared')) {
      calculatedComparedDates = this.getCalculatedDates(fltrPayload, 'date_from_compared', 'date_to_compared');
    } else if (fltrPayload['tag_date_destination']) {
      calculatedComparedDates = this.getCalculatedTagDates(fltrPayload, 'tag_date_destination');
    } else if (filterCfg?.periodDatesFilters && fltrPayload['date_from'] && fltrPayload['date_to']) {
      calculatedComparedDates = this.getCalculatedComparedPreviousYearDates(fltrPayload);
    } else if (fltrPayload.hasOwnProperty('compared_year')) {
      const first = `${fltrPayload['compared_year']}-01-01`
      const last =  this.calculateComparedYear(fltrPayload);
      calculatedComparedDates = `${first} to ${last}`
    }

    const payload = {
      'Brand Pk': fltrPayload['brand_pk'],
      'Brand ECR Pk': fltrPayload['brand_pk_ecr'],
      'Campaigns ID': fltrPayload['campaign_ids'],
      'City': fltrPayload['profiles_cities_ids'],
      'Country': fltrPayload['country'],
      'Coupon ID': fltrPayload['coupon_ids'],
      'Currency': fltrPayload['currency'],
      'Date Granularity Filter': fltrPayload['evolution_frequency'],
      'Date Granularity YMD': fltrPayload['year_month_date'],
      'Date Granularity': this.getChartGranularity(fltrPayload),
      'Day of Week': fltrPayload['day_of_week'],
      'Dimension selector': fltrPayload['dimension'],
      'Dimension Parameter 1': fltrPayload['dimension_store_product'],
      'Entity': fltrPayload['entity'],
      'Feature Pk': fltrPayload['feature_ids'],
      'Feature Taxonomy Slug': fltrPayload['taxonomy_slug'],
      'Homogeneous Location Parameter': fltrPayload['only_comparable_stores'] ? '1' : null,
      'Homogeneous Location': fltrPayload['only_comparable_stores'] ? '1' : null,
      'ID/EAN Product': fltrPayload['id_ean_product'],
      'In Segment': fltrPayload['segment_id'] ? 'Yes' : null,
      'In Segment 2': fltrPayload['segment2_id'] ? 'Yes' : null,
      'Location ID': fltrPayload['location_ids'] ? fltrPayload['location_ids'] : null,
      'Marca': this.slug === 'private_label' ? '1' : fltrPayload['brand'],
      'Method': fltrPayload['method'],
      'Metric Selector 7': fltrPayload['chart_metrics_with_own_brand'],
      'Metric Selector 8': fltrPayload['metrics_segment_drill_down'],
      'Metric Selector ME': fltrPayload['param_measure_type'],
      'Metric Selector': fltrPayload['evolution_chart'],
      'Metrics segments by cust typology': fltrPayload['metrics_segments_by_cust_typology'],
      'Metricas': fltrPayload['chart_metricas'],
      'Param Measure Currency': fltrPayload['company_currency'] ? '1' : '0',
      'Param Measure Store Migration': fltrPayload['chart_metric_sales_customers_purchases'],
      'Periodo 2': calculatedDates,
      'Periodo Comparado': calculatedComparedDates,
      'Periodo': calculatedDates,
      'Plan ID': fltrPayload['plan_ids'],
      'Postal Code': fltrPayload['postal_code'],
      'Postal Code/City': fltrPayload['postal_code_city'],
      'Product ID': fltrPayload['product_ids'],
      'Proveedor/Marca': fltrPayload['supplier_brand'],
      'Recompensa': fltrPayload['rewards'],
      'Rules': fltrPayload['rules'],
      'Segment Category ID': fltrPayload['segment_category_id'],
      'Segment': fltrPayload['segment_id'],
      'Segment 2': fltrPayload['segment2_id'],
      'Selector Metrica SB': fltrPayload['chart_metric_supplier_brand'],
      'Selector Metrica': fltrPayload['chart_metric'],
      'Supplier ECR Pk': fltrPayload['supplier_ecr'],
      'Supplier Pk': fltrPayload['supplier'],
      'Tagged Segments': fltrPayload['tagged_segment_ids'],
      'Tickets': fltrPayload['tickets'] && fltrPayload['tickets'] !== 'null' ? fltrPayload['tickets'] : null,
      'Tipo de metrica': fltrPayload['chart_metric_type'],
      'Top Brands/Supplier': fltrPayload['top_brand_supplier'],
      'Top Locations': fltrPayload['top_locations'],
      'View By Brand/Supplier': fltrPayload['view_brand_supplier']
    };

    if (fltrPayload['location_taxonomy_term_ids'] && !fltrPayload['location_ids']) {
      const locationsRequest$ = this.locationsService.fetchMultiselect().subscribe(data => {
        const locationsData = data['list'].reduce((string, item, index) => string + `${index ? ',' : ''}${item.id}`, '');
        payload['Location ID'] = locationsData ? locationsData : null;
        this.updateAndRunLookerDashboards(payload);
      });
      this.subs$.push(locationsRequest$);

    } else {
      this.updateAndRunLookerDashboards(payload);
    }
  }

  private getChartGranularity(fltrPayload: object): string {
    if (fltrPayload['evo_granularity']) {
      return fltrPayload['evo_granularity'];
    } else if (fltrPayload['chart_granularity']) {
      return fltrPayload['chart_granularity'];
    } else {
      return null;
    }
  }

  private calculateComparedYear(fltrPayload) {
    const actualDate = new Date();
    const actualYear = actualDate.getUTCFullYear();
    const selectedYear = parseInt(fltrPayload['compared_year']);
    const actualMonth = actualDate.getUTCMonth() + 1; // Sumamos 1 porque los meses en JavaScript se indexan desde 0
    const lastDayOfCompletedMonth = new Date(Date.UTC(selectedYear, actualMonth - 1, 0));
    const parsedDate = lastDayOfCompletedMonth.toISOString().split('T')[0];

    if(parseInt(fltrPayload['year_of_last_years']) !== actualYear) {
      return `${fltrPayload['compared_year']}-12-31`
    }

    const evaluateDate = selectedYear !== actualYear ?  parsedDate : `${fltrPayload['compared_year']}-12-31`;
    return evaluateDate;
  }

  private getCalculatedDates(fltrPayload: object, keyDateFrom: string, keyDateTo: string): string {
    const dateFrom = this.dateService.parseUTCDateWithFormat(fltrPayload[keyDateFrom], 'YYYY/MM/DD');
    const parseDateTo = this.getParseDateTo(fltrPayload, keyDateTo);
    return `${dateFrom} to ${parseDateTo}`;
  }

  private getCalculatedComparedPreviousYearDates(fltrPayload: object): string {
    fltrPayload['date_from_compared'] = this.dateService.calculateDate('substract', 1, 'year', fltrPayload['date_from']);
    fltrPayload['date_to_compared'] = this.dateService.calculateDate('substract', 1, 'year', fltrPayload['date_to']);
    return this.getCalculatedDates(fltrPayload, 'date_from_compared', 'date_to_compared');
  }

  private getCalculatedTagDates(fltrPayload: object, tagDateKey: string): string {
    const tagDateFormatString = this.dateService.momentStrictFormat(fltrPayload[tagDateKey], 'DD-MM-YYYY', 'MM-DD-YYYY');
    const tagDate = this.dateService.dateStringToDateTimezoneOffset(tagDateFormatString);
    const tagDateTo = this.dateService.parseUTCDateWithFormat(tagDate.toISOString(), 'YYYY/MM/DD');
    let tagDateFrom = '';
    if (tagDateKey === 'tag_date') {
      const monthsNumber = this.slug === 'segment_categories' ? 6 : 1;
      const tagPreviousMonthsDate = this.dateService.calculateUTCDate('substract', monthsNumber, 'month', tagDate);
      tagDateFrom = this.dateService.parseUTCDateWithFormat(tagPreviousMonthsDate, 'YYYY/MM/DD');
    } else {
      const tagPreviousYearDateString = this.dateService.calculateUTCDate('substract', 1, 'year', tagDate);
      const tagPreviousYearDate = this.dateService.dateStringToDateTimezoneOffset(tagPreviousYearDateString);
      const tagPreviousYearNextMonthDate = this.dateService.calculateUTCDate('add', 1, 'month', tagPreviousYearDate);
      tagDateFrom = this.dateService.parseUTCDateWithFormat(tagPreviousYearNextMonthDate, 'YYYY/MM/DD');
    }
    return`${tagDateFrom} to ${tagDateTo}`;
  }

  private getParseDateTo(fltrPayload: object, dateToControlName: string): string {
    const addExtraDayFilterCfg = this.route.snapshot?.data?.filterCfg?.addExtraDayToSelectorDate;
    if (addExtraDayFilterCfg) {
      const dateTimezoneOffset = this.dateService.dateStringToDateTimezoneOffset(fltrPayload[dateToControlName]);
      return this.dateService.calculateUTCDate('add', 1, 'days', dateTimezoneOffset, 'YYYY/MM/DD');
    } else {
      return this.dateService.parseUTCDateWithFormat(fltrPayload[dateToControlName], 'YYYY/MM/DD');
    }
  }

  private handleLaunchDatesResponse(response: object, fltrPayload: object): void {
    if (!response || !response['launch_dates'] || response['launch_dates'] instanceof Array && response['launch_dates'].length === 0) {
      this.confirmationService.displayErrorAlert(
        this.translate.instant('common.error'),
        this.translate.instant('components.looker-dashboard-filter.errors.no_launch_date')
      );
      this.filterService.loaderStatus.next(true);
    } else {
      const periods = fltrPayload['monthly_evolution'];
      this.dateFrom = this.dateService.parseUTCDateWithFormat(response['launch_dates'][0], 'YYYY/MM/DD');
      this.dateTo = this.dateTimeZoneService.getLastDayOfCalculatedPeriod('add', periods, 'months', response['launch_dates'][0], 'month', 'YYYY/MM/DD');
      const calculatedDates = `${this.dateFrom} to ${this.dateTo}`;
      const payload = {
        'Product ID': fltrPayload['product_ids'],
        'Launch Product Date': calculatedDates,
        'Purchase Date': calculatedDates,
      };
      this.updateAndRunLookerDashboards(payload);
    }
  }

  private updateAndRunLookerDashboards(payload: object): void {
    setTimeout(() => this.filterService.loaderStatus.next(true));
    const updateFiltersEvt = {...{type: 'dashboard:filters:update'}, ...{filters: payload}};
    const runDashboardEvt = {type: 'dashboard:run'};

    Object.keys(payload).forEach(key => {
      payload[key] = isNullOrUndefined(payload[key]) ? '' : payload[key];
    });

    this.handleDashboardsByPayload(payload);
    this.preloadedSegments = 0;

    if (payload['Segment'] && !payload['Segment 2']) {
      this.segmentsToPreload = 1;
      this.handleSegmentLookerDashboard(payload['Segment'], updateFiltersEvt, runDashboardEvt);
    } else if(payload['Segment'] && payload['Segment 2']) {
      this.segmentsToPreload = 2;
      this.handleSegmentLookerDashboard(payload['Segment'], updateFiltersEvt, runDashboardEvt);
      this.handleSegmentLookerDashboard(payload['Segment 2'], updateFiltersEvt, runDashboardEvt);
    } else {
      this.postLookerIframes(updateFiltersEvt, runDashboardEvt)
    }

    this.isOnSearchSubmitted = true;
  }

  private handleSegmentLookerDashboard(segmentId: string, updateFiltersEvt: {filters: object, type: string}, runDashboardEvt: {type: string}): void {

    const params = {apiEndPoint: `analytics/process_segment/${segmentId}`, filtering: {}};

    this.loading = true;
    const processSegment$ = this.refreshCacheService.postExpensiveData(params.apiEndPoint, params.filtering).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        processSegment$.unsubscribe();
        this.preloadedSegments++;
        if (this.preloadedSegments === this.segmentsToPreload) {
          this.loading = false;
          this.postLookerIframes(updateFiltersEvt, runDashboardEvt);
        }
      }
    },
      (errorData: HttpErrorResponse) => {
        this.loading = false;
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error);
      }

    );
    this.subs$.push(processSegment$);
  }

  private postLookerIframes(updateFiltersEvt: {filters: object, type: string}, runDashboardEvt: {type: string}): void {
    this.iframes.forEach(iframe => {
      iframe.nativeElement.contentWindow.postMessage(JSON.stringify(updateFiltersEvt), 'https://jobtitudeloyalguru.cloud.looker.com/');
      iframe.nativeElement.contentWindow.postMessage(JSON.stringify(runDashboardEvt), 'https://jobtitudeloyalguru.cloud.looker.com/');
    });
  }

  private handleDashboardsByPayload(payload: object): void {
    const filterCfg = this.route.snapshot.data?.filterCfg;
    const hideSupplierDashboard = !!filterCfg?.supplierDashboard && !(!!payload['Supplier Pk']) && !(!!payload['Supplier ECR Pk']);
    const hasTaxonomySlugAndSingleECRTaxonomyKindFilterCfg = !!payload['Feature Taxonomy Slug'] && filterCfg?.hasOwnProperty('showSingleECRTaxonomySlugKindDashboard');
    const hideSingleECRTaxonomyKindDashboard = hasTaxonomySlugAndSingleECRTaxonomyKindFilterCfg && !filterCfg?.showSingleECRTaxonomySlugKindDashboard;
    this.hideCurrentDashboard = hasTaxonomySlugAndSingleECRTaxonomyKindFilterCfg && filterCfg?.showSingleECRTaxonomySlugKindDashboard;
    this.hideExtraDashboard = hideSupplierDashboard || hideSingleECRTaxonomyKindDashboard;
  }

  private displayIframesByRouteCfg(id: string): void {
    const dashboardParams = {embed_url: id};
    const iframeUrl$ = this.salesService.getLookerDashboardUrl(dashboardParams).subscribe(
      (response: string) => {
        this.loading = false;
        this.dashboardCount++;
        if (response?.match("^https://jobtitudeloyalguru.cloud.looker.com/login/embed/")?.length) {
          this.embedUrls.push(this.sanitizer.bypassSecurityTrustResourceUrl(response));
          if (this.dashboardIds[this.dashboardCount]) {
            this.displayIframesByRouteCfg(this.dashboardIds[this.dashboardCount]);
          }
        }
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        this.embedErrs.push(err.status + ', ' + err.statusText);
      }
    );
    this.subs$.push(iframeUrl$);
  }

  private setIframeUrlById(id: string): void {
    const dashboardParams = {embed_url: id};
    const iframeUrl$ = this.salesService.getLookerDashboardUrl(dashboardParams).subscribe(
      (response: string) => {
        this.loading = false;
        this.embedUrls = [];
        if (response?.match("^https://jobtitudeloyalguru.cloud.looker.com/login/embed/")?.length) {
          setTimeout(() => this.embedUrls = [this.sanitizer.bypassSecurityTrustResourceUrl(response)]);
        }
      },
      (err: HttpErrorResponse) => {
        console.error(err);
      }
    );
    this.subs$.push(iframeUrl$);
  }

  private handleFiltersLoaderStatus(): void {
    const filtersLoader$ = this.filterService.filtersLoaderStatus$.subscribe((filtersLoaded) => {
        this.filtersLoaded = filtersLoaded;
    });

    this.subs$.push(filtersLoader$);
  }

  private processFeatureFlag(): void {
    this.setFlagValue('lookerIntegrationTestDashboardId', this.launchDarklyService.client.variation('looker.integration.dashboard.id', false));
    this.launchDarklyService.flagChanges.subscribe((flags) => {
      if ((flags['looker.integration.dashboard.id'])) {
        this.setFlagValue('lookerIntegrationTestDashboardId', flags['looker.integration.dashboard.id']);
      }
    });
  }

  private setFlagValue(key: string, flagValue): void {
    flagValue = flagValue.hasOwnProperty('current') ? flagValue.current : flagValue;
    this.flags[key] = flagValue;

    if (key === 'lookerIntegrationTestDashboardId') {
      this.setIframeUrlById(this.flags[key]);
    }
  }

}
