import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class ModalStatusService {

  public modalStatus: EventEmitter<any> = new EventEmitter();
  public formLoaderStatus: EventEmitter<any> = new EventEmitter();
  public resourceStatus: EventEmitter<any> = new EventEmitter();

  constructor() { }

}
