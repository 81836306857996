<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-10">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form [formGroup]="productsForm" *ngIf="productsForm">
                <ng-container *ngFor="let field of productsFormFieldsTemplate">
                  <ng-container *ngTemplateOutlet="fieldsRowsTemplate; context: { key: field.key, showField: showFieldTemplate(field.key) }">
                  </ng-container>
                </ng-container>
                <ng-template #fieldsRowsTemplate let-key="key" let-showField="showField">
                  <div *ngIf="showField" class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label [for]="key" [translate]="'resources.products.form.' + key"></label>
                        <span *ngIf="getInputConfig(key).required" class="text-danger">*</span>
                        <app-question [class.field-warning]="getInvalidFieldValue(key)" [question]="getInputConfig(key)" [form]="productsForm"></app-question>
                        <span *ngIf="getInvalidFieldValue(key) as invalidFieldValue" class="text-danger">
                          <em class="fa-solid fa-triangle-exclamation"></em>
                          {{ invalidFieldValue?.message | translate }}
                        </span>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
