import 'brace';
import 'brace/mode/json';
import 'brace/theme/monokai';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LgCurrencyPipe } from '../../../../shared/pipes/lg-currency.pipe';
import { LgMultilanguageTranslatePipe } from './../../../../shared/pipes/lg-multilanguage-translate.pipe';
import { onlyUnique, sortObjArrayByProperty, sumObjArrayByProperty } from '../../../../shared/services/array.service';
import { ProfileService } from '../../../../profiles/profile.service';
import { PromoReadyTicketDetail, PromotionListTicketDetail, PromotionTicketDetail, SummaryPointsTicketDetail, TicketDetail, VoucherTicketDetail } from '../../../../shared/models/purchases/ticket-detail';
import { registerLocaleData } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { TicketsService } from '../tickets.service';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-show-tickets',
  templateUrl: './show-tickets.component.html',
  styleUrls: ['./show-tickets.component.scss'],
  providers: [ LgCurrencyPipe, LgMultilanguageTranslatePipe ]
})

export class ShowTicketsComponent implements OnInit, OnDestroy {

  currencySymbol: string;
  pointsSummary: SummaryPointsTicketDetail[] = [];
  promoReady: PromoReadyTicketDetail[];
  roleSlug = this.profileService.getStoredUserRole();
  ticketData: TicketDetail;
  ticketId: number = this.route.params['value'].id;
  totalDiscount: number;
  vouchersData: VoucherTicketDetail[];

  private destroy$ = new Subject<void>();

  constructor(
    private clipboard: Clipboard,
    private confirmationService: ConfirmationService,
    private lgCurrencyPipe: LgCurrencyPipe,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private ticketsService: TicketsService
  ) { }

  ngOnInit(): void {
    registerLocaleData(es);
    this.getTicketData();
    this.getVouchersData();
    this.getPromotionsData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeModal(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.ticketData.raw);
  }

  scroll(elementId: string): void {
    document.getElementById(elementId).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  showPointsBlock(): boolean {
    return this.ticketData?.points_won !== 0 ||
      this.ticketData?.score_used !== 0 ||
      this.ticketData?.score_returned_from_customer !== 0 ||
      this.ticketData?.score_returned_to_customer !== 0;
  }

  private getTicketData(): void {
    this.ticketsService.getTicketData(this.ticketId).pipe(takeUntil(this.destroy$)).subscribe({
      next: (ticketData: TicketDetail) => {
        this.ticketData = ticketData;
        this.ticketData.cashbackTotalAmount = 0;
        this.ticketData.cashbackTotalPoints = 0;
        this.ticketData.raw = this.ticketData['raw'] ? JSON.stringify(this.ticketData['raw'], null, '\t') : null;
        this.currencySymbol = this.lgCurrencyPipe.getCurrencySymbol(this.ticketData['currency']);
        this.sortPointsData();
        this.getPointsSummary();
        this.calculateCashbackTotals();
      },
      error: (errorData: HttpErrorResponse) => {
        this.confirmationService.displayHttpErrorAlert(errorData);
        this.closeModal();
      }
    });
  }

  private getVouchersData(): void {
    this.ticketsService.getVouchersData(this.ticketId).pipe(takeUntil(this.destroy$)).subscribe({
      next: (vouchersData: VoucherTicketDetail[]) => {
        this.totalDiscount = vouchersData?.length ? vouchersData.reduce((acc, obj) => acc + (obj.total_discount || 0), 0) : 0;
        this.vouchersData = vouchersData;
      },
      error: () => {
        this.totalDiscount = 0;
        this.vouchersData = [];
      }
    });
  }

  private getPromotionsData(): void {
    this.ticketsService.getPromotionData(this.ticketId).pipe(takeUntil(this.destroy$)).subscribe({
      next: (promotionsData: PromotionTicketDetail) => {
       if (promotionsData?.list?.length) { this.groupPromos(promotionsData)};
      },
      error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
    });
  }

  private groupPromos(promosRaw: PromotionTicketDetail): void {
    const uniquePromoIds = promosRaw.list.map(el => el._embedded['promotion']['id']).filter(onlyUnique);
    this.promoReady = uniquePromoIds.map(promoId => {
      const promos = promosRaw.list.filter(promo => promo._embedded['promotion']['id'] === promoId);
      return {
        promotion_id: promoId,
        created_at: promos[0].created_at,
        promotion: promos[0],
        discount: this.sumDiscounts(promos),
        children: promos
      }
    });
  }

  private sumDiscounts(activitiesList: PromotionListTicketDetail[]): number {
    return activitiesList.reduce((a, b) => ({discount: a.discount + b.discount})).discount;
  }

  private getPointsSummary(): void {
    this.pointsSummary = this.ticketData._embedded.lines.map(line => {
        const lineId = line.line_order;
        return {
            line_order: lineId,
            scores_by_line: this.aggregateFieldByLine(this.ticketData.scores_by_line, lineId, 'original_points'),
            cashbacks: this.aggregateFieldByLine(this.ticketData.cashbacks, lineId, 'points'),
            cashbacks_returns: this.aggregateFieldByLine(this.ticketData.cashback_returns, lineId, 'score'),
            activity_return_froms: this.aggregateFieldByLine(this.ticketData.activity_return_froms, lineId, 'score', true)
        };
    });
    this.pointsSummary = sortObjArrayByProperty(this.pointsSummary, 'line_order');
  }

  private aggregateFieldByLine(resource: object[], lineId: number, resourceField: string, isActivityReturnFroms = false): number {
    return resource?.reduce((total, element) => {
        const isMatchedLine = !isActivityReturnFroms ? element['line_order'] === lineId : element['from_line_order'] === lineId;
        return isMatchedLine ? total + parseInt(element[resourceField], 10) : total;
    }, 0) || 0;
  }

  private sortPointsData(): void {
    this.ticketData.scores_by_line = sortObjArrayByProperty(this.ticketData.scores_by_line, 'line_order');
    this.ticketData.cashbacks = sortObjArrayByProperty(this.ticketData.cashbacks, 'line_order');
  }

  private calculateCashbackTotals(): void {
    if (this.ticketData.cashbacks?.length) {
      this.ticketData.cashbackTotalAmount = sumObjArrayByProperty(this.ticketData.cashbacks, 'amount');
      this.ticketData.cashbackTotalPoints = sumObjArrayByProperty(this.ticketData.cashbacks, 'points');
    }
  }

}
