<ng-container>
  <div class="row" *ngIf="mode !== 'show'">
    <div class="col-md-12">
      <div class="form-group">
        <label class="text-bold" for="available_languages">
          <span class="font-size-14" [translate]="'resources.coupons.form.available_languages'"></span>
        </label>
        <app-question [question]="getInputConfig('available_languages')" [form]="form" (multiselectChanged)="handleLanguageSelection($event)"></app-question>
      </div>
    </div>
  </div>

  <div class="row">
    <ng-container *ngIf="parsedLangKeys && parsedLangKeys.length > 0">
      <ng-container *ngFor="let item of parsedLangKeys; let i = index;">
        <div [ngClass]="mode === 'show' ? 'col-md-6' : 'col-md-12'">
          <div [attr.id]="'accordion_' + i" class="accordion panel-group" role="tablist" aria-multiselectable="true">
            <div class="panel panel-default">
              <div class="card-header no-pdd" role="tab" id="headingOne{{i}}">
                <h5 class="panel-title font-size-14">
                  <a class="pdd-vertical-5" data-toggle="collapse" [attr.data-parent]="'#accordion_' + i" [attr.href]="'#collapseOne_' + i"
                    aria-expanded="false">
                    <i class="icon ti-arrow-circle-down pull-left"></i>
                    <b><span>{{item.label | translate}}</span></b>
                  </a>
                </h5>
              </div>
              <div [attr.id]="'collapseOne_' + i" class="panel-collapse collapse"
                [ngClass]="{'show': parsedLangKeys && parsedLangKeys.length > 0}">
                <div class="card-body no-pdd-btm">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="name">
                          <span class="font-size-14 text-bold" [translate]="'resources.coupons.form.name'"></span>
                        </label>
                        <span class="text-danger">*</span>
                        <app-question [question]="getInputConfig('name_'+item.lang)" [form]="form"></app-question>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="description">
                          <span class="font-size-14 text-bold" [translate]="'resources.coupons.form.description'"></span>
                        </label>
                        <app-question [question]="getInputConfig('description_'+item.lang)" [form]="form"></app-question>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
