import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from '../../../shared/services/resources.service';
import { Subject } from 'rxjs';
import { RefreshCacheService } from '../../../shared/services/refresh-cache.service';
import { Subscription } from 'rxjs';

@Injectable()
export class SalesService extends ResourcesService {

  public filters: any;
  public applyFilters$ = new Subject<any>();
  public customerSales: Subject<any> = new Subject<any>();
  public customerSales$ = this.customerSales.asObservable();
  public noCustomerSales: Subject<any> = new Subject<any>();
  public noCustomerSales$ = this.noCustomerSales.asObservable();
  public signUps: Subject<any> = new Subject<any>();
  public signUps$ = this.signUps.asObservable();
  public scoreDots: Subject<any> = new Subject<any>();
  public scoreDots$ = this.scoreDots.asObservable();
  public locationSales: Subject<any> = new Subject<any>();
  public locationSales$ = this.locationSales.asObservable();
  public segmentSales: Subject<any> = new Subject<any>();
  public segmentSales$ = this.segmentSales.asObservable();
  public salesDetails: Subject<any> = new Subject<any>();
  public salesDetails$ = this.salesDetails.asObservable();
  public totalSum: Subject<any> = new Subject<any>();
  public totalSum$ = this.totalSum.asObservable();

  public subs$: Subscription[] = [];

  constructor( http: HttpClient,
               private refreshCacheService: RefreshCacheService ) {
    super(http);
  }

  /* Temporal method to retrieve iframe url from management API */
  public getLookerDashboardUrl(_params: object) {
    const params = {...{ apiEndPoint: `analytics/embed` }, ...{ filtering: _params }};
    return this.getData(params);
  }

  /* Sales summary graph requests */
  public getCustomerSales(filterParams) {
    const params = {...{ apiEndPoint: 'analytics/customer_sales' }, ...{ filtering: filterParams }};
    this.getDataWithFiltersForObservable(params, this.customerSales, true);
  }

  public getNoCustomerSales(filterParams) {
    const params = {...{ apiEndPoint: 'analytics/no_customer_sales' }, ...{ filtering: filterParams }};
    this.getDataWithFiltersForObservable(params, this.noCustomerSales, true);
  }

  public getSegmentSales(filterParams) {
    const params = {...{ apiEndPoint: 'analytics/customer_sales' }, ...{ filtering: filterParams }};
    this.getDataWithFiltersForObservable(params, this.segmentSales, true);
  }

  public getSignups(filterParams) {
    const params = {...{ apiEndPoint: 'analytics/signups' }, ...{ filtering: filterParams }};
    this.getDataWithFiltersForObservable(params, this.signUps, true);
  }

  public getLocationSales(filterParams) {
    const params = {...{ apiEndPoint: 'analytics/location_sales' }, ...{ filtering: filterParams }};
    this.getDataWithFiltersForObservable(params, this.locationSales, false);
  }
  /* Sales summary graph requests end */

  /* Sales detail table requests */
  public getSalesDetails(filterParams) {
    const params = {...{ apiEndPoint: 'analytics/sales_details' }, ...{ filtering: filterParams }};
    this.getDataWithFiltersForObservable(params, this.salesDetails,  true);
  }
  /* Sales detail table requests end */

  /* Sales for club indicators */
  public getTotalSum(filterParams) {
    const params = {...{ apiEndPoint: 'analytics/total_sum' }, ...{ filtering: filterParams }};
    this.getDataWithFiltersForObservable(params, this.totalSum,  true);
  }
  /* end of club indicators */

  public removeSubscriptions() {
    if ( this.subs$.length > 0 ) {
      this.subs$.forEach( s$ => s$.unsubscribe() );
      this.subs$ = [];
    }
  }

  // Private
  private getDataWithFiltersForObservable( params: any, subject: Subject<any>, usesCacheService?: boolean ) {
    const _usesCacheService = usesCacheService ? usesCacheService : true;
    if (_usesCacheService) {
      const s$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
        if (!this.refreshCacheService.isRequestPending(reqResponse)) {
          subject.next(reqResponse);
          s$.unsubscribe();
        }
      });
      this.subs$.push(s$);
    } else {
      const s$ = this.getData(params).subscribe((reqResponse) => {
        subject.next(reqResponse);
        s$.unsubscribe();
      });
      this.subs$.push(s$);
    }
  }

  /* Data parsers for sales location detail tab (Dasboard seguimiento) */
  public parseSalesDetails(data) {
    /* Set additional information for each location */
    data = this.sortObjectArrayByField(data, 'name');
    data.forEach(location => {
      location.name = location.name;
      location.id = location.id;
      location.franchise = !!location.franchise; /* Transform the string to a useful boolean */

      location.revenue = location.total_sum || 0;
      location.revenue_id = location.total_sum_identified || 0;
      location.revenue_perc = !location.revenue ? 0 : location.revenue_id / location.revenue * 100;

      location.tickets = location.activity_count || 0;
      location.tickets_id = location.activity_count_identified || 0;
      location.tickets_perc = !location.tickets ? 0 : location.tickets_id / location.tickets * 100;

      location.customers = location.customer_count || 0;
      location.products = location.product_count || 0;

      location.signups = location.signups || 0;
      location.total_signups = location.total_signups || 0;

      // Ratios
      location.average_ticket = !location.tickets ? 0 : location.revenue / location.tickets
      location.products_ticket = !location.tickets ? 0 : location.products / location.tickets
      location.customers_ticket = !location.customers ? 0 : location.revenue_id / location.customers
    });
    return data;
  }

  public calculateTotals(data) {
    const result = {
      customers: 0,
      revenue: 0,
      revenue_id: 0,
      tickets: 0,
      tickets_id: 0,
      products: 0,
      signups: 0,
      total_signups: 0,
      revenue_perc: 0,
      tickets_perc: 0,
      revenue_ticket: 0,
      products_ticket: 0,
      customers_ticket: 0
    };

    data.forEach(location => {
      result.revenue += parseFloat(location.revenue);
      result.revenue_id += parseFloat(location.revenue_id);
      result.tickets += parseFloat(location.tickets);
      result.tickets_id += parseFloat(location.tickets_id);
      result.customers += parseFloat(location.customers);
      result.signups += parseFloat(location.signups);
      result.total_signups += parseFloat(location.total_signups) || 0;
      result.products += parseFloat(location.products);
    });

    result.revenue_perc = (result.revenue_id / result.revenue) * 100;
    result.tickets_perc = (result.tickets_id / result.tickets) * 100;
    result.revenue_ticket = result.revenue / result.tickets;
    result.products_ticket = result.products / result.tickets;
    result.customers_ticket = result.revenue_id / result.customers;

    return result;
  }

  private sortObjectArrayByField(arr: any[], field: string ): any[] {
    let sorted = [];
    sorted = arr.sort((elementA, elementB) => {
      if (elementA[field].toLowerCase() > elementB[field].toLowerCase()) { return  1; }
      if (elementA[field].toLowerCase() < elementB[field].toLowerCase()) { return -1; }
      return 0;
    });
    return sorted;
  }
}
