export const environment = {
  beePluginClientId: '672ea576-73f4-4396-92ae-951ca9aa6d6a',
  beePluginKey: 'h3n9KX0MVwXIPUYPLF8qnLM5cPZqwGrkeGZJdx7K8iohigp2J5wg',
  clientBaseUrl: 'https://lg-app-owner-v2-preproduction.web.app',
  gtag: 'X-XXXXXXXXXX',
  launchDarklyClientId: '5fae65e3bb17700a0424227d',
  lgApiBaseUrl: 'https://management-api.loyal.guru',
  lgApiIframeBaseUrl: 'https://lg-app-owner-v2-preproduction.web.app/#/iframe',
  name: 'Preproduction',
  production: true,
  s3bucket: 'loyal-guru-default',
  s3region: 'us-east-1'
};
