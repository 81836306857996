<div class="container-fluid clearfix">
  <div class="row" *ngIf="element">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true">
              <tbody>

                <tr *ngIf="element.status">
                  <td class="no-border-top"><b [translate]="'resources.campaigns.fields.status'"></b></td>
                  <td class="no-border-top">
                    <span class="label label-success" *ngIf="element.status === 'delivered'">{{'resources.campaigns.status.' + element.status | translate}}</span>
                    <span class="label label-primary" *ngIf="element.status === 'sending'">{{'resources.campaigns.status.' + element.status | translate}}</span>
                    <span class="label label-info" *ngIf="element.status === 'enqueued'">{{'resources.campaigns.status.' + element.status | translate}}</span>
                    <span class="label label-danger" *ngIf="element.status === 'error'">{{'resources.campaigns.status.' + element.status | translate}}</span>
                    <span class="label label-info" *ngIf="element.status === 'draft'">{{'resources.campaigns.status.' + element.status | translate}}</span>
                    <span class="label label-primary" *ngIf="element.status === 'scheduled'">{{'resources.campaigns.status.' + element.status | translate}}</span>
                    <span class="label label-warning" *ngIf="element.status === 'checking'">{{'resources.campaigns.status.' + element.status | translate}}</span>
                  </td>
                </tr>

                <tr *ngIf="element.id">
                  <td><b [translate]="'resources.campaigns.fields.id'"></b></td>
                  <td>{{element.id}}</td>
                </tr>

                <tr *ngIf="element.description">
                  <td class="no-border-top"><b [translate]="'resources.campaigns.fields.description'"></b></td>
                  <td class="no-border-top">{{element.description}}</td>
                </tr>

                <tr *ngIf="element.name">
                  <td><b [translate]="'resources.campaigns.fields.name'"></b></td>
                  <td>{{element.name}}</td>
                </tr>

                <tr *ngIf="element.sender">
                  <td><b [translate]="'resources.campaigns.fields.sender'"></b></td>
                  <td>{{element.sender}}</td>
                </tr>

                <tr *ngIf="element.via">
                  <td><b [translate]="'resources.campaigns.fields.via'"></b></td>
                  <td>{{'resources.campaigns.via.' + element.via | translate }}
                    <span class="label label-primary" *ngIf="element.reward">{{'resources.campaigns.type.reward' | translate}}</span>
                    <span class="label label-info" *ngIf="element.reminder">{{'resources.campaigns.type.reminder' | translate}}</span>
                  </td>
                </tr>

                <tr *ngIf="element.motive">
                  <td><b [translate]="'resources.campaigns.fields.motive'"></b></td>
                  <td>{{'resources.campaigns.motive.' + element.motive | translate }}</td>
                </tr>

                <tr *ngIf="element.coupon_distribution === 'ranking'">
                  <td><b [translate]="'resources.campaigns.fields.ranking'"></b></td>
                  <td>{{element.ranks }}</td>
                </tr>

                <tr *ngIf="element.motive === 'message' && element.messages[0]">
                  <td><b [translate]="'resources.campaigns.fields.ticket_message'"></b></td>
                  <td><a [routerLink]="['', { outlets: { modal: ['show', 'messages', element.messages[0].id] } }]">
                  {{element.messages[0].id}} -  {{element.messages[0].name}}</a></td>
                </tr>

                <tr *ngIf="element.motive === 'coupon' && element.coupons[0]">
                  <td><b [translate]="'resources.campaigns.fields.coupon'"></b></td>
                  <td>
                    <a [routerLink]="['', { outlets: { modal: ['show', 'coupons', element.coupons[0].id] } }]">
                      {{element.voucheables[0].database_id}} -  {{element.coupons[0].name}}
                    </a>
                  </td>
                </tr>

                <tr *ngIf="element.status === 'delivered' && element.sent_at">
                  <td><b [translate]="'resources.campaigns.fields.sent_at'"></b></td>
                  <td>{{element.sent_at | dateTimeZone: 'DD/MM/YYYY HH:mm'}}</td>
                </tr>

                <tr *ngIf="element.segment">
                  <td><b [translate]="'resources.campaigns.fields.segment'"></b></td>
                  <td>
                    <a [routerLink]="['', { outlets: { modal: ['show', 'segments', element.segment.id] } }]">
                      {{element.segment.name}}
                    </a>
                  </td>
                </tr>

                <tr *ngIf="element.status === 'scheduled' && element.scheduled_at">
                  <td><b [translate]="'resources.campaigns.fields.scheduled_at'"></b></td>
                  <td>{{element.scheduled_at | dateTimeZone: 'DD/MM/YYYY HH:mm'}}</td>
                </tr>

              </tbody>
            </table>

            <ng-container *ngIf="element.status === 'checking'">
              <div class="card">
                <h5 class="card-header text-info">
                  <i class="tooltip-position ti-info-alt mrg-top-5 font-size-14 pull-left" tooltipPosition="left"></i>
                  &nbsp;<span class="text-semibold blueLG" [translate]="'resources.campaigns.show.messages.validating_status'"></span>                
                </h5>
                <div class="card-body">
                  <p class="card-text text-info" [translate]="'resources.campaigns.show.messages.checking_campaign'"></p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
