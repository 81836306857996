import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { ModalStatusService } from '../../../../../shared/services/modal-status.service';
import { RewardTaxonomy } from '../reward-taxonomy';
import { RewardTaxonomiesService } from '../reward-taxonomies.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-form-reward-taxonomies',
  templateUrl: './form-reward-taxonomies.component.html',
  styleUrls: ['./form-reward-taxonomies.component.css'],
  providers: [RewardTaxonomiesService]
})
export class FormRewardTaxonomiesComponent implements OnInit {

  rewardTaxonomy: RewardTaxonomy;
  inputs: QuestionBase<any>[];
  rewardTaxonomyForm: UntypedFormGroup;
  id: number;
  showButton: boolean;

  private subs$: Subscription[] = [];

  @Output('formValidity') formValidity: EventEmitter<string> = new EventEmitter();

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private modalStatusSrv: ModalStatusService,
    private taxonomies: RewardTaxonomiesService
  ) {}

  ngOnInit() {
    this.getParams();
    this.showBackButton();
  }

  showBackButton() {
    this.showButton = !(this.router.url.indexOf('(modal:') >= 0);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  sendData() {
    let req$ = this.id ?
      this.taxonomies.updateById(this.getPayload(), this.id) :
      this.taxonomies.create(this.getPayload());
    let title = this.id ?
      this.translate.instant('resources.scores.reward_taxonomies.form.warnings.update_title') :
      this.translate.instant('resources.scores.reward_taxonomies.form.warnings.success_title');
    let subtitle = this.id ?
      this.translate.instant('resources.scores.reward_taxonomies.form.warnings.update_text') :
      this.translate.instant('resources.scores.reward_taxonomies.form.warnings.success_text');

    this.modalStatusSrv.formLoaderStatus.emit('loading');
    req$.subscribe(
      () => {
        this.confirmationService.displaySuccessAlert(title, subtitle).catch(() => {});
        this.modalStatusSrv.formLoaderStatus.emit('loading_finished');
        this.returnToList();
        this.modalStatusSrv.modalStatus.emit();
      },
      (err: HttpErrorResponse) => this.handleError(err)
    )
  }

  returnToList() {
    this.router.navigate([{ outlets: { modal: null }}]).catch(() => {});
  }

  private getPayload(): RewardTaxonomy {
    const payload = this.rewardTaxonomyForm.value;
    if(!payload.origin.length || !payload.origin) {
      delete payload.origin;
    } else {
      payload.origin = payload.origin[0].id;
    }
    return payload;
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getTaxonomy();
      } else {
        this.setInputsAndForm();
      }
    });
  }

  private getTaxonomy() {
    this.taxonomies.getById(this.id).subscribe(
      (data: RewardTaxonomy) => {
        this.rewardTaxonomy = data;
        this.setInputsAndForm();
      },
      (err: HttpErrorResponse) => this.handleError(err)
    );
  }

  private setInputsAndForm() {
    this.inputs = this.taxonomies.getInputs(this.rewardTaxonomy);
    this.rewardTaxonomyForm = this.qcs.toFormGroup(this.inputs);
    this.formStatusChanges();
  }

  private handleError(err: HttpErrorResponse) {
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), err.error.error);
    this.modalStatusSrv.formLoaderStatus.emit('loading_finished');
    this.returnToList();
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.rewardTaxonomyForm.status));
    const formStatus$ = this.rewardTaxonomyForm.statusChanges.pipe(distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
    this.subs$.push(formStatus$);
  }
}


