import { DecimalPipe } from "@angular/common";
import { isNullOrUndefined } from "../../../../../utils/common.utils";
import { MultiselectDataSource } from "../../../../multiselect/multiselect";
import { decorateAttrName } from "../utils/common.utils";

export function parseAttributeOptions(entityAttributes: object, attributeOptions: MultiselectDataSource[]): object[] {
  if (entityAttributes) {
    const keys = Object.keys(entityAttributes);
    attributeOptions = keys.length ? keys.map(key => (new MultiselectDataSource(key, decorateAttrName(key), entityAttributes[key]))) : [];
  }
  return attributeOptions;
}

export function parseFloatAttributeValue(value: number, decimalPipe: DecimalPipe): number {
  return (!isNullOrUndefined(value) ? decimalPipe.transform(value, '1.2-2', 'es') : value) as number;
}