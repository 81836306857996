import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { QuestionControlService } from '../../../../services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';
import { CouponHistoriesService } from '../../../../../resources/coupons/coupon-histories.service';
import { ConditionService } from '../../../../services/conditions/condition.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-coupon-condition',
  templateUrl: './coupon-condition.component.html',
  styleUrls: ['./coupon-condition.component.css']
})
export class CouponConditionComponent implements OnInit, OnDestroy {

  @Input('item') item: Condition;
  @Input('inputs') inputs: QuestionBase<any>[] = [];
  @Input('form') form: UntypedFormGroup;

  public anyStateValue: any;
  public couponsSelectedLength: number;
  public couponIdsChange$: Subscription;
  public anyStateChanges$: Subscription;
  public deliveryTypeChanges$: Subscription;

  constructor( private qcs: QuestionControlService,
               private conditionService: ConditionService,
               private couponHistoriesService: CouponHistoriesService ) { }

  ngOnInit() {
    this.setAnyStateValueChanges();
    this.setCouponValueChanges();
    this.setDeliveryTypeValueChanges();
  }

  ngOnDestroy() {
    if (this.anyStateChanges$) { this.anyStateChanges$.unsubscribe(); }
    if (this.couponIdsChange$) { this.couponIdsChange$.unsubscribe(); }
    if (this.deliveryTypeChanges$) { this.deliveryTypeChanges$.unsubscribe(); }
  }

  public setCoupon(id: number) {
    this.couponHistoriesService.setCoupon(id);
  }

  private setCouponValueChanges() {
    this.couponIdsChange$ = this.form.get('coupon_ids').valueChanges.subscribe(
      couponIdsValue => {
        this.inputs.find( input => input.key === 'coupon_history_ids').value = [];
        this.couponsSelectedLength = couponIdsValue ? couponIdsValue.length : 0;
      }
    );
  }

  private setDeliveryTypeValueChanges() {
    const deliveryType = this.form.get('delivery_type_single').value;
    if ( deliveryType && deliveryType.length > 0 && !['all', 'any'].includes(deliveryType[0].id) ) {
      this.inputs.find( input => input.key === 'coupon_history_ids').selectedIds = [];
    }

    this.deliveryTypeChanges$ = this.form.get('delivery_type_single').valueChanges.subscribe(
      _singleDeliveryType => {
        const singleDeliveryType = _singleDeliveryType;
        if (singleDeliveryType && singleDeliveryType.length > 0 && !['all', 'any'].includes(singleDeliveryType[0].id) ) {
          this.inputs.find( input => input.key === 'coupon_history_ids').selectedIds = [];
        }
      }
    );
  }

  private setAnyStateValueChanges( ) {
    const key = Object.keys(this.form.controls).find( controlKey => controlKey.indexOf('any_state') >= 0 );
    if ( this.form.get(key).value ) { this.handleStatusInput(true); }
    this.anyStateChanges$ = this.form.get(key).valueChanges.subscribe(
      anyStateValue => this.handleStatusInput(anyStateValue)
    );
  }

  private handleStatusInput(value: boolean) {
    const statusInput = this.inputs.find( input => input.key === 'status');
    if (value) { statusInput.value = []; }
    this.anyStateValue = value;
  }

  public getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    if ( !value ) { return false; }
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  public handleCouponSelected(event) {
    const coupons = this.conditionService.formValues[this.item.content.formControlId]['coupon_ids'];
    this.couponsSelectedLength = coupons ? coupons.length : 0;
    if (this.couponsSelectedLength === 1) {
      this.couponHistoriesService.setCoupon(coupons[0].id);
    }
    const appliesInput = this.inputs.find( input => input.key === 'coupon_history_ids');
    appliesInput.selectedIds = [];
    appliesInput.value = [];
  }

  public handleStatusSelected(statusValue) {
    if (statusValue) {
      // Value not bound to checkbox
      const key = this.inputs[0].key;
      this.getInputConfig(key).value = null;
    }
  }

  public handleDeliveryTypeSelected(event) {
    const appliesInput = this.inputs.find( input => input.key === 'coupon_history_ids');
    appliesInput.selectedIds = [];
    appliesInput.value = [];
  }
}
