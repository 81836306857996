import { ActivatedRouteSnapshot, Route, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ProfileService } from '../profiles/profile.service';
import { Role, RolesService } from '../profiles/roles.service';
import { AuthService } from '../auth/auth.service';
import { MODULE_PERMISSIONS } from '../shared/constants/module-permissions.constants';
import { CurrentUser } from '../shared/models/current-user';
import { LaunchDarklyService } from '../shared/services/launch-darkly/launchdarkly.service';
@Injectable()
export class ModulesGuard  {

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private rolesService: RolesService,
    private authService: AuthService,
    private launchDarklyService: LaunchDarklyService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const pathRoute = route.routeConfig.path;
    const routeFeatureFlag = route.routeConfig?.data?.featureFlag ? route.routeConfig.data.featureFlag : null;
    return this.hasPermission(pathRoute, routeFeatureFlag);
  }

  canLoad(route: Route): boolean {
    const pathRoute = route.path;
    const routeFeatureFlag = route.data?.featureFlag ? route.data.featureFlag : null;
    return this.hasPermission(pathRoute, routeFeatureFlag);
  }

  private hasPermission(pathRoute: string, routeFeatureFlag: string): boolean {

    if (!this.authService.isAuthenticated()) {
      setTimeout(() => void this.router.navigate(['/signin']));
      return false;
    }

    const user = new CurrentUser(this.profileService.getStoredUser());
    const hasModuleFFPermission = !routeFeatureFlag ? true : this.hasModuleFFPermission(routeFeatureFlag);
    const isModulePurchased = this.isModulePurchased(pathRoute, user);
    const isNotAuthorizedRole = this.isNotAuthorizedRole(pathRoute, user.role);
    const isAuthorizedRole = this.isAuthorizedRole(pathRoute, user.role);
    
    if (!hasModuleFFPermission || !isModulePurchased || isNotAuthorizedRole || !isAuthorizedRole) { 
      setTimeout(() => void this.router.navigate(['/error']));
      return false;
    } else {
      return hasModuleFFPermission && isModulePurchased && !isNotAuthorizedRole && isAuthorizedRole;
    }
  }

  private hasModuleFFPermission(routeFeatureFlag: string): Promise<boolean> {
    return this.launchDarklyService.client.waitUntilReady().then(() => {
      const flags = this.launchDarklyService.flags;
      const isFeatureFlagActivated = flags[routeFeatureFlag];
      if (!isFeatureFlagActivated) {
        this.router.navigate(['/error']).catch(() => {});
        return false;
      } else {
        return isFeatureFlagActivated;
      }
    });
  }

  private isModulePurchased(pathRoute: string, user: CurrentUser): boolean {
    const moduleSlug = this.getModuleProp(pathRoute, 'slug') as string;
    const purchasedModules = user.company.purchasedModules;
    const isDefaultPurchasedRoute = !!(MODULE_PERMISSIONS[pathRoute] && !moduleSlug);    
    return !purchasedModules.length || isDefaultPurchasedRoute ? true : purchasedModules.includes(moduleSlug);
  }

  private isNotAuthorizedRole(pathRoute: string, userRole: Role): boolean {
    const moduleNotAuthRoles = this.getModuleProp(pathRoute, 'notAuthRoles') as string[];
    return this.rolesService.isNotAuthorized(moduleNotAuthRoles, userRole);
  }

  private isAuthorizedRole(pathRoute: string, userRole: Role): boolean {
    const moduleAuthRoles = this.getModuleProp(pathRoute, 'authRoles') as string[];
    return this.rolesService.isAuthorized(moduleAuthRoles, userRole);
  }

  private getModuleProp(pathRoute: string, prop: string): string | string[] {
    return (MODULE_PERMISSIONS[pathRoute] && MODULE_PERMISSIONS[pathRoute][prop]) || null;
  }
}