<div class="alert alert-info" role="alert" *ngIf="!onSearch && !loading">
  <span>{{ 'components.looker-iframe.alerts.apply_filters' | translate }}</span>
</div>

<div class="row" *ngIf="onSearch">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title d-inline" [translate]="'dashboards.club.tabs.club_indicators.title'"></h4>
      </div>

      <app-loader styleClass="mrg-btm-15" *ngIf="loading" />

      <div class="card-block no-pdd no-mrg pdd-horizon-20 pdd-btm-5">
        <table class="table table-hover table-sm table-bordered" aria-hidden="true" *ngIf="!loading && filter">
          <thead>
            <tr class="highlight-header">
              <th class="text-left">
                <b>{{'dashboards.club.tabs.club_indicators.fields.dates-ranges' | translate}}</b>
              </th>
              <th class="text-center">{{filter.titleRange1}}</th>
              <th class="text-center">{{filter.titleRange2}}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-right">
              <td class="text-left">
                <b>{{clubIndicatorsData.totalSales.title}}</b>
                <i class="tooltip-position ti-info-alt mrg-left-5 total-sales-position" tooltipPosition="left" pTooltip="{{ 'dashboards.sales.tooltips.total_sales_text' | translate }}"></i>
              </td>
              <td>
                <span class="label label-info font-size-12">
                  {{clubIndicatorsData.totalSales.currentDateRange | lgcurrency: '1.2-2'}}
                </span>
              </td>
              <td>
                <span class="label label-primary font-size-12">
                  {{clubIndicatorsData.totalSales.previousDateRange | lgcurrency: '1.2-2'}}
                </span>
              </td>
            </tr>
            <tr class="text-right">
              <td class="text-left">
                <b>{{clubIndicatorsData.totalIdentifiedSales.title}}</b>
              </td>
              <td>
                <span class="label label-info font-size-12">
                  {{clubIndicatorsData.totalIdentifiedSales.currentDateRange | lgcurrency: '1.2-2'}}
                </span>
              </td>
              <td>
                <span class="label label-primary font-size-12">
                  {{clubIndicatorsData.totalIdentifiedSales.previousDateRange | lgcurrency: '1.2-2'}}
                </span>
              </td>
            </tr>
            <tr class="text-right">
              <td class="text-left">
                <b>{{clubIndicatorsData.totalIdentifiedSalePercentage.title}}</b>
              </td>
              <td>
                <span class="label label-info font-size-12">
                  {{clubIndicatorsData.totalIdentifiedSalePercentage.currentDateRange | number: '1.2-2'}} %
                </span>
              </td>
              <td>
                <span class="label label-primary font-size-12">
                  {{clubIndicatorsData.totalIdentifiedSalePercentage.previousDateRange | number: '1.2-2'}} %
                </span>
              </td>
            </tr>
            <tr class="text-right">
              <td class="text-left">
                <b>{{clubIndicatorsData.activeCustomers.title}}</b>
              </td>
              <td>
                <span class="label label-info font-size-12">
                  {{clubIndicatorsData.activeCustomers.currentDateRange | number: '1.0-0'}}
                </span>
              </td>
              <td>
                <span class="label label-primary font-size-12">
                  {{clubIndicatorsData.activeCustomers.previousDateRange | number: '1.0-0'}}
                </span>
              </td>
            </tr>
            <tr class="text-right">
              <td class="text-left">
                <b>{{clubIndicatorsData.unidentifiedTickets.title}}</b>
              </td>
              <td>
                <span class="label label-info font-size-12">
                  {{clubIndicatorsData.unidentifiedTickets.currentDateRange | number: '1.0-0'}}
                </span>
              </td>
              <td>
                <span class="label label-primary font-size-12">
                  {{clubIndicatorsData.unidentifiedTickets.previousDateRange | number: '1.0-0'}}
                </span>
              </td>
            </tr>
            <tr class="text-right">
              <td class="text-left">
                <b>{{clubIndicatorsData.eurosByCustomer.title}}</b>
              </td>
              <td>
                <span class="label label-info font-size-12">
                  {{clubIndicatorsData.eurosByCustomer.currentDateRange | lgcurrency: '1.2-2'}}
                </span>
              </td>
              <td>
                <span class="label label-primary font-size-12">
                  {{clubIndicatorsData.eurosByCustomer.previousDateRange | lgcurrency: '1.2-2'}}
                </span>
              </td>
            </tr>
            <tr class="text-right">
              <td class="text-left">
                <b>{{clubIndicatorsData.frequency.title}}</b>
              </td>
              <td>
                <span class="label label-info font-size-12">
                  {{clubIndicatorsData.frequency.currentDateRange | number: '1.2-2'}}
                </span>
              </td>
              <td>
                <span class="label label-primary font-size-12">
                  {{clubIndicatorsData.frequency.previousDateRange | number: '1.2-2'}}
                </span>
              </td>
            </tr>
            <tr class="text-right">
              <td class="text-left">
                <b>{{clubIndicatorsData.identifiedTicketAverage.title}}</b>
              </td>
              <td>
                <span class="label label-info font-size-12">
                  {{clubIndicatorsData.identifiedTicketAverage.currentDateRange | lgcurrency: '1.2-2'}}
                </span>
              </td>
              <td>
                <span class="label label-primary font-size-12">
                  {{clubIndicatorsData.identifiedTicketAverage.previousDateRange | lgcurrency: '1.2-2'}}
                </span>
              </td>
            </tr>
            <tr class="text-right">
              <td class="text-left">
                <b>{{clubIndicatorsData.signups.title}}</b>
              </td>
              <td>
                <span class="label label-info font-size-12">
                  {{clubIndicatorsData.signups.currentDateRange | number: '1.0-0'}}
                </span>
              </td>
              <td>
                <span class="label label-primary font-size-12">
                  {{clubIndicatorsData.signups.previousDateRange | number: '1.0-0'}}
                </span>
              </td>
            </tr>
            <tr class="text-right">
              <td class="text-left">
                <b>{{clubIndicatorsData.masification.title}}</b>
              </td>
              <td>
                <span class="label label-info font-size-12">
                  {{clubIndicatorsData.masification.currentDateRange | number: '1.2-2'}} %
                </span>
              </td>
              <td>
                <span class="label label-primary font-size-12">
                  {{clubIndicatorsData.masification.previousDateRange | number: '1.2-2'}} %
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
