<form [formGroup]="form">
  <div class="row">
    <div class="col-md-6">
      <app-question [question]="getInputConfig('name')" [form]="form"></app-question>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <app-question [question]="getInputConfig('campaign_plan_category_id')" [form]="form"></app-question>
    </div>
  </div>
  <app-segment-inputs *ngFor="let segment of segments"
                      [key]="segment.key"
                      [segment]="segment.data"
                      [form]="form"
                      [length]="segments.length"
                      (removeEmitter)="handleDeletedSegment($event)"></app-segment-inputs>
  <button class="btn btn-info btn-sm mrg-btm-70"
          type="button"
          (click)="addSegment()"
          [disabled]="!form.valid">
    {{'resources.campaign_plans.buttons.add_segment' | translate}}
  </button>
</form>
