import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProductSalesService extends ResourcesService{

  constructor(http: HttpClient) {
    super(http);
  }

  public getFilterData(location_ids?: number, product_ids?: number, date_from?: number, date_to?: number) {
    return this.getData({apiEndPoint: `product_sales?${location_ids}/${product_ids}/${date_from}/${date_to}`});
  }

}
