<app-loader *ngIf="loading" />

<div *ngIf="details">
  <div class="row">
    <div class="col-lg-3">
      <div class="card bg-info">
        <div class="card-block">
          <div class="row">
            <div class="col-md-12">
              <p class="text-uppercase font-size-13">
                <i class="ei ei-employees"></i>
                <b>{{ 'dashboards.customers.tabs.consistence.registered-partners' | translate }}</b>
              </p>
              <h1 class="no-mrg-top font-size-30">{{ details.total | number: '1.0-0' }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="details">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="card">
          <div class="card-block">
            <h4 class="card-title">
              <i class="ei ei-phone-call"></i>
              {{ 'dashboards.customers.tabs.consistence.phone.title' | translate }}
            </h4>
            <div class="mrg-top-20">
              <div class="table-overflow">
                <table class="table" aria-hidden="true">
                  <tbody>
                    <tr>
                      <td>{{ 'dashboards.customers.tabs.consistence.phone.wrong' | translate }}</td>
                      <td class="text-right font-weight-bold">{{ details.phone_errors | number: '1.0-0' }}</td>
                    </tr>
                    <tr>
                      <td>{{ 'dashboards.customers.tabs.consistence.phone.pending' | translate }}</td>
                      <td class="text-right font-weight-bold">{{ details.phone_pending_check | number: '1.0-0' }}</td>
                    </tr>
                    <tr>
                      <td>{{ 'dashboards.customers.tabs.consistence.phone.correct' | translate }}</td>
                      <td class="text-right font-weight-bold">{{ details.phone_correct | number: '1.0-0' }}</td>
                    </tr>
                    <tr>
                      <td>{{ 'dashboards.customers.tabs.consistence.phone.empty' | translate }}</td>
                      <td class="text-right font-weight-bold">{{ details.phone_empty | number: '1.0-0' }}</td>
                    </tr>
                    <tr>
                      <td>{{ 'dashboards.customers.tabs.consistence.phone.duplicated' | translate }}</td>
                      <td class="text-right font-weight-bold">{{ details.phone_duplicated | number: '1.0-0' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="card">
          <div class="card-block">
            <h4 class="card-title">
              <i class="ei ei-email"></i>
              {{ 'dashboards.customers.tabs.consistence.email.title' | translate }}
            </h4>
            <div class="mrg-top-20">
              <div class="table-overflow">
                <table class="table" aria-hidden="true">
                  <tbody>
                    <tr>
                      <td>{{ 'dashboards.customers.tabs.consistence.email.wrong' | translate }}</td>
                      <td class="text-right font-weight-bold">{{ details.email_errors | number: '1.0-0' }}</td>
                    </tr>
                    <tr>
                      <td>{{ 'dashboards.customers.tabs.consistence.email.pending' | translate }}</td>
                      <td class="text-right font-weight-bold">{{ details.email_pending_check | number: '1.0-0' }}</td>
                    </tr>
                    <tr>
                      <td>{{ 'dashboards.customers.tabs.consistence.email.correct' | translate }}</td>
                      <td class="text-right font-weight-bold">{{ details.email_correct | number: '1.0-0' }}</td>
                    </tr>
                    <tr>
                      <td>{{ 'dashboards.customers.tabs.consistence.email.empty' | translate }}</td>
                      <td class="text-right font-weight-bold">{{ details.email_empty | number: '1.0-0' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="card">
          <div class="card-block">
            <h4 class="card-title">
              <i class="ei ei-store"></i>
              {{ 'dashboards.customers.tabs.consistence.address.title' | translate }}
            </h4>
            <div class="mrg-top-20">
              <div class="table-overflow">
                <table class="table" aria-hidden="true">
                  <tbody>
                    <tr>
                      <td>{{ 'dashboards.customers.tabs.consistence.address.empty' | translate }}</td>
                      <td class="text-right font-weight-bold">{{ details.address_empty | number: '1.0-0' }}</td>
                    </tr>
                    <tr>
                      <td>{{ 'dashboards.customers.tabs.consistence.address.pending' | translate }}</td>
                      <td class="text-right font-weight-bold">{{ details.address_pending | number: '1.0-0' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>