<form [formGroup]="form">
  <div class="modal-backdrop fade in modal-fs" 
       id='modal-fs' 
       tabindex="-1" 
       role="dialog" 
       aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header background-white" *ngIf="journey">
          <div class="row card-heading flex-grow-1 no-pdd-btm">
            <div class="col-md-12">
              <div class="row ml-auto align-items">
                <label>
                  <h2>
                    <b [translate]="'resources.journeys.title_singular'"></b> 
                    <span>{{'resources.journeys.type.' + journey.type | translate}}:</span>
                  </h2>
                </label>
                <div class="col-md-9 max-width-73">
                  <app-question [question]="inputs[0]" [form]="form"></app-question>
                </div>
              </div>
            </div>
            <a class="modal-close" data-dismiss="modal" (click)="handleCloseModal($event)">
              <i class="ti-close"></i>
            </a>
          </div>
        </div>

        <div class="modal-body background-gray height-100">
          <div class="card background-gray no-border" *ngIf="journey">
            <div class="alert alert-warning">
              <ul class="no-pdd">
                <li>
                  <span>{{'resources.journeys.warnings.edit_sent_on' | translate}}:</span>
                  <strong [innerText]="dataLabel"></strong>
                </li>
                <li *ngIf="!journey._original" translate>resources.journeys.warnings.editing_history</li>
                <li *ngIf="journey._original" translate>resources.journeys.warnings.editing_parent</li>
              </ul>
            </div>

            <div class="alert alert-danger" *ngIf="errors">
              <ul>
                <li *ngFor="let error of errors">
                  <strong *ngIf="error.field.length">{{error.field}}: </strong>
                  <span>{{error.message}}</span>
                </li>
              </ul>
            </div>

            <div class="row" *ngIf="loading">
              <app-loader styleClass="col-md-12 pdd-vertical-10" noWrapper />
            </div>

            <div class="card">
              <div class="card-block">
                <h4 class="card-title">
                  <b [translate]="'resources.journeys.fields.roi_config'"></b>
                </h4>
                <div class="row">
                  <div class="col-md-3">
                    <label>&nbsp;</label>
                    <app-question [question]="inputs[1]" [form]="form"></app-question>
                  </div>
                  <div class="col-md-4">
                    <app-question [question]="inputs[2]" [form]="form"></app-question>
                  </div>
                  <div class="col-md-5">
                    <app-question [question]="inputs[3]" [form]="form"></app-question>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-block">
                <h4 class="card-title"><b [translate]="'resources.journeys.fields.campaigns'"></b></h4>

                <div *ngFor="let campaign of campaignInputs; let i = index;">
                  <div class="row" *ngIf="!campaign.hidden">
                    <div class="col-md-7">
                      <app-question [question]="campaignInputs[i]" 
                                    [form]="form"
                                    (multiselectChanged)="handleMultiselectChanged(campaignInputs[i], $event)"></app-question>
                    </div>
                    <div class="col-md-5">
                      <div class="btn-group">
                        <button class="btn btn-default" 
                                type="button"
                                (click)="removeCampaign(campaign, i)">
                          <i class="ei-garbage-alt font-size-16"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <button type="button" 
                        class="btn btn-info btn-icon" 
                        (click)="addCampaign($event)"
                        [disabled]="isLastCampaignEmpty()">
                  <i class="fa fa-plus"></i>
                  <span [translate]="'resources.journeys.buttons.add_campaign'"></span>
                </button>
              </div>
            </div>
            
          </div>
        </div>
        <div class="modal-footer" *ngIf="journey">
          <span *ngIf="saving">
            <i class="fa fa-spinner fa-spin"></i>
          </span>
          <button class="btn btn-info mrg-right-15" type="button" (click)="onSubmit()" [disabled]="saving">
            <span [translate]="'resources.journeys.buttons.save'"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
