import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { TextareaQuestion } from '../../../../shared/models/forms/question-textarea';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { Subscription } from 'rxjs';
import { PlansService } from '../../plans.service';
import { Plan } from '../../plan';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';

@Component({
  selector: 'app-switch-coupons',
  templateUrl: './switch-coupons.component.html',
  styleUrls: ['./switch-coupons.component.css']
})

export class SwitchCouponsComponent implements OnInit, OnDestroy {

  public form: UntypedFormGroup;
  public inputs: QuestionBase<any>[];
  public subs$: Subscription[];

  @Input('plan') plan: Plan;
  @ViewChild('switchCouponsModal') switchCouponsModal: ElementRef;

  constructor(
    private translate: TranslateService,
    private qcs: QuestionControlService,
    private plansService: PlansService,
    private confirmationService: ConfirmationService
  ) {
    this.subs$ = [];
  }

  ngOnInit() {
    this.inputs = this.setInputs();
    this.form = this.qcs.toFormGroup(this.inputs);
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  open() {
    this.switchCouponsModal.nativeElement.click();
  }

  closeModal() {
    this.switchCouponsModal.nativeElement.click();
    this.form.reset();
  }

  save() {
    const payload = this.form.value;
    const switchCoupons$ = this.plansService.switchCouponsByPlan(`${this.plan.id}`, payload).subscribe(
      () => {
        const title = this.translate.instant('resources.plans.messages.switch_coupons_success_title');
        const desc = this.translate.instant('resources.plans.messages.switch_coupons_success_desc');
        this.closeModal();
        this.confirmationService.displaySuccessAlert(title, desc).catch(() => {});
      },
      (err: HttpErrorResponse) => {
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), err.error.error);
      }
    );
    this.subs$.push(switchCoupons$);
  }

  private setInputs(): QuestionBase<any>[] {
    return [
      new TextareaQuestion({
        key: 'coupon_ids',
        type: 'textarea',
        cssClasses: 'form-control input-default',
        placeholder: this.translate.instant('resources.plans.messages.switch_coupons_placeholder'),
        required: true
      })
    ];
  }
}
