import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DictionaryService } from '../../services/dictionary.service';
import { EditSegmentEvoComponent } from '../../../resources/analytics/reports/edit-report/segment-evo/edit-segment-evo.component';
import { EditIncrementalComponent } from '../../../resources/analytics/reports/edit-report/incremental/edit-incremental.component';
import { EditReportComponent } from '../../../resources/analytics/reports/edit-report/edit-report.component';
import { ShowReportsComponent } from '../../../resources/analytics/reports/shows/show-reports.component';
import { ProfileService } from '../../../profiles/profile.service';
import { Subscription } from 'rxjs';
import { ModalStatusService } from '../../services/modal-status.service';
import { FormCouponsComponent } from '../../../resources/data-warehouse/data-warehouse-coupons/form-coupons/form-coupons.component';
import { FormRewardsComponent } from '../../../resources/scores/rewards/form-rewards/form-rewards.component';
import { FeatureFlagsService } from '../../services/feature-flags.service';

@Component({
  selector: 'app-fullscreen-modal-container',
  templateUrl: './fullscreen-modal-container.component.html',
  styleUrls: ['./fullscreen-modal-container.component.css'],
  providers: [DictionaryService]
})
export class FullscreenModalContainerComponent implements OnInit, OnDestroy {

  apiEndPoint: string;
  customEditRedirect;
  formLoaderStatus$: Subscription;
  id: number;
  isValid: boolean;
  loading = true;
  mode: string;
  roleSlug: string;
  title: string;
  updateRedirect;
  flags = this.featureFlags.flags;

  get isCustomerServiceDataReadOnly(): boolean {
    return this.flags.customerServiceDataReadOnly;
  }

  @ViewChild('form') form: EditSegmentEvoComponent | EditIncrementalComponent | FormCouponsComponent | FormRewardsComponent;
  @ViewChild('formReport') formReport:  EditReportComponent;
  @ViewChild('show') show:  ShowReportsComponent;

  constructor(
    private router: Router,
    private dictionarySrv: DictionaryService,
    private featureFlags: FeatureFlagsService,
    private profileService: ProfileService,
    private modalStatusService: ModalStatusService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.roleSlug = this.profileService.getStoredUserRole();
    this.getApiEndPoint();
    this.getMode();
    this.getId();
    this.getTitle();
    setTimeout(() => {
      this.initLoaderStatus();
    }, 100);
  }

  ngOnDestroy() {
    if (this.formLoaderStatus$) { this.formLoaderStatus$.unsubscribe(); }
  }

  initLoaderStatus() {
    this.loading = false;
    this.formLoaderStatus$ = this.modalStatusService.formLoaderStatus.subscribe(
      (loaderStatus: string) => {
        switch (loaderStatus) {
          case 'loading':
            this.loading = true;
            break;
          case 'loading_finished':
            this.loading = false;
            break;
        }
      }
    );
  }

  getId() {
    this.id = this.route.snapshot.params.id;
  }

  getApiEndPoint() {
    this.apiEndPoint = this.route.snapshot.data.title;
  }

  getTitle() {
    const title = this.dictionarySrv.dictionaries.modal_titles.find( _title => _title.id === this.apiEndPoint );
    this.title = title[`${this.mode}_title`];
  }

  getMode() {
    this.mode = this.route.snapshot.data.mode;
  }

  createNewReport() {
    if ( this.formReport ) {
      this.formReport.createNewReport();
    }
  }

  sendData() {
    if ( this.form ) {
      this.form.sendData();
    } else {
      this.formReport.sendData();
    }
  }

  backTo() {
    if ( this.form ) {
      this.form.returnToList();
    } else {
      this.formReport.backTo();
    }
  }

  showReport() {
    if ( this.formReport ) { this.formReport.showReport(); }
  }

  generateReport() {
    if ( this.formReport ) { this.formReport.generateReport(); }
    if ( this.show ) { this.show.generateReport(); }
  }

  closeModal() {
    const routes = this.route.snapshot.url.map(el => el.path);
    if (routes.includes('show') && routes.includes('synchros')) {
      this.router.navigate([{ outlets: { modal: null } }]).then(() => {
        this.router.navigate(['/customer-data-platform/collect/files-management/synchro-history']).catch(() => {});
      }).catch(() => {})
    } else {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    }
  }

  handleEmitter(emitterValue) {
    if ( emitterValue.hasOwnProperty('customEditRedirect') && emitterValue.customEditRedirect ) {
      this.customEditRedirect = emitterValue.customEditRedirect;
    }
    if ( emitterValue.hasOwnProperty('updateRedirect') && emitterValue.updateRedirect ) {
      this.updateRedirect = emitterValue.updateRedirect;
    }
    if ( emitterValue.title ) {
      this.title = emitterValue.title;
    }
  }

  handleCustomRedirect() {
    window.open(this.customEditRedirect, '_self');
  }

  handleFormStatus(formStatus) {
    this.isValid = formStatus === 'VALID';
  }

}
