import { DateService } from "../../../../../../shared/services/date.service";

/*
* Header that will be displayed in table in ReportCouponsAppAnalysis detail
*/
export interface ReportCouponsAppAnalysisHeader {
  calculateValueFn?: Function,
  csv?: boolean;
  display?: boolean
  key: string;
  summable?: boolean;
  translateKey: string;
  type: string;
}

/*
* Calculated fields across all report detail row.
*/
export interface ReportCouponsAppAnalysisCalculatedData {
  activating_customers_with_app?: number;
  activating_customers_without_app?: number;
  auto_redeem?: boolean;
  average_discount_per_customer_with_app?: number;
  average_discount_per_customer_without_app?: number;
  campaign_category?: string;
  campaign_history_id?: string;
  campaign_id?: string;
  campaign_name?: string;
  channel?: string;
  coupon_discount?: string;
  coupon_discount_type?: string;
  coupon_id?: string;
  coupon_name?: string;
  coupon_max_redemptions?: number;
  coupon_printing_customers_with_app?: number;
  coupon_printing_customers_without_app?: number;
  coupons_activated_with_app?: number;
  coupons_issued_with_app?: number;
  coupons_issued_without_app?: number;
  coupons_redemptions_per_customer_with_app?: number;
  coupons_redemptions_per_customer_without_app?: number;
  customers_impacted_with_app?: number;
  customers_impacted_without_app?: number;
  date_from?: string;
  date_to?: string;
  origin_type?: string;
  percentage_customers_engaged_with_app_ratio?: number;
  percentage_printing_customers_with_app_ratio?: number;
  percentage_printing_customers_without_app_ratio?: number;
  percentage_redemption_with_app?: number;
  percentage_redemption_without_app?: number;
  percentage_redeemer_customers_with_app_after_selection_ratio?: number;
  percentage_redeemer_customers_with_app_ratio?: number;
  percentage_redeemer_customers_without_app_ratio?: number;
  plan_id?: string;
  redeemer_customers_with_app?: number;
  redeemer_customers_with_app_after_selection?: number;
  redeemer_customers_without_app?: number;
  sum_discount_with_app?: number;
  sum_discount_without_app?: number;
  total_voucher_redemptions_with_app?: number;
  total_voucher_redemptions_without_app?: number;
}

export interface CouponsAppAnalysisReportData {
  last_generation_at?: string;
  outdated?: string;
  report_details?: {
    type: string,
    results: ReportCouponsAppAnalysisCalculatedData[]
  },
  status?: string;
  total_cost?: number;
  total_incremental_sale?: number;
  total_ratio?: number;
  type: string;
};

export interface CouponsAppAnalysisReportDetailData {
  results: ReportCouponsAppAnalysisCalculatedData[];
  type: string;
};


export class CouponsAppAnalysisDetailResult implements ReportCouponsAppAnalysisCalculatedData {

  origin_type?: string;
  date_from?: string;
  date_to?: string;
  start_end_date?: string;
  channel?: string;

  constructor(rawJson: ReportCouponsAppAnalysisCalculatedData, dateService: DateService) {
    // Summarizes the need to manually assign each property from the rawJson object to the class instance
    Object.assign(this, rawJson);
    this.origin_type = rawJson.origin_type ? `resources.reports.coupons_in_app_analysis.fields.${rawJson.origin_type}` : '';
    this.date_from = dateService.momentFormat(rawJson.date_from, 'DD/MM/YYYY');
    this.date_to = dateService.momentFormat(rawJson.date_to, 'DD/MM/YYYY');
    this.start_end_date = `${this.date_from} - ${this.date_to}`;
    this.channel = rawJson.channel ? `resources.campaigns.channels.${rawJson.channel}` : '';
  }
}
