import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Subscription } from 'rxjs';
import { CompanyPromotionsService } from '../../service/promotional-discounts.service';

@Component({
  selector: 'app-show-promotional-discounts',
  templateUrl: './show-promotional-discounts.component.html',
  styleUrls: ['./show-promotional-discounts.component.css'],
  providers: [CompanyPromotionsService]
})
export class ShowCompanyPromotionsComponent implements OnInit, OnDestroy {

  public element: any;
  public apiEndPoint: string;
  public id: number;
  public isModal: boolean;
  public route$: Subscription;

  constructor(
    public route: ActivatedRoute,
    public resourcesService: ResourcesService,
    private companypromotionsService: CompanyPromotionsService,
    public translate: TranslateService,
    public router: Router
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.isModal = this.router.url.indexOf('(modal:show/promotional_discounts/') >= 0;
    this.getParams();
    this.companypromotionsService.fetchSelectedById( this.id )
        .subscribe(res => {
          this.element = res;
        },
        error => {
          console.log('error:', error);
        });
  }

  ngOnDestroy() {
    this.route$.unsubscribe();
  }

  getParams() {
    this.route$ = this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'promotional_discounts';
      }
    });
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }


}
