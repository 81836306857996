import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../../../shared/components/multiselect/multiselect';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LoyaltyScoreMomentsService extends ResourcesService implements MultiselectDataSourceable {

  public inputs: any[];

  constructor(
    http: HttpClient,
    private translate: TranslateService
  ) {
    super(http);

  }

  public getAll() {

    const requestOptions = {
      apiEndPoint: 'loyalty_score_moments',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };

    return this.getData(requestOptions);
  }

  public fetchMultiselect( searchValues?: string, page?: number ) {
    let requestOptions = {
      apiEndPoint: 'loyalty_score_moments',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  public fetchSelectedById(id: number): Observable<object> {
    return this.getScoreMomentsById(id);
  }

  public getScoreMomentsById(id: number): Observable<any> {
    return this.getData({apiEndPoint: `loyalty_score_moments/${id}`});
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    if (element.slug.indexOf('custom_entity_') === 0) {
      const prefix = this.translate.instant('resources.custom_entities.messages.selector_name_prefix');
      element.name = `${prefix} ${element.name}`;
    }
    return new MultiselectDataSource(element.slug, element.name, element);
  }

  public getInputs(formValues: any): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 1,
        disabled: formValues && formValues.protected
      }),
      new TextboxQuestion({
        key: 'slug',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 2,
        disabled: formValues && formValues.protected
      })
    ];

    Object.keys(formValues).map(
      key => {
        if (key === 'name') {
          inputsArray.find( input => input.key === 'name' ).value = formValues[key];
        } else if (key === 'slug') {
          inputsArray.find( input => input.key === 'slug' ).value = formValues[key];
      }
    });

    this.inputs = inputsArray;
    return this.inputs;
  }

  public sendData(rewardData) {
    return this.postResource(rewardData, 'loyalty_score_moments');
  }

  public updateData(rewardData: any, _id: number) {
    return this.patchResource(rewardData, `loyalty_score_moments/${_id}`);
  }
}
