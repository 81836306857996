import { getCurrencySymbol } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SweetAlertResult } from 'sweetalert2';
import { ProfileService } from '../../../../profiles/profile.service';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { RefreshCacheService } from '../../../../shared/services/refresh-cache.service';
import { FormCouponsComponent } from '../../../data-warehouse/data-warehouse-coupons/form-coupons/form-coupons.component';
import { Plan } from '../../plan';
import { PlanCampaign } from '../plan-campaign';
import { AudienceContentComponent } from './audience-content/audience-content.component';
import { AudienceCouponRecommenderComponent } from './audience-coupon-recommender/audience-coupon-recommender.component';
import { RecommendedCoupon } from './audience-coupon-recommender/recommended-coupon';
import { AudienceDatesComponent } from './audience-dates/audience-dates.component';
import { PlanCampaignAction } from './plan-campaign-action';
import { PlanCampaignActionMock } from './plan-campaign-action-mock';
import { PlanCampaignActionService } from './plan-campaign-action.service';
import { FeatureFlagsService } from '../../../../shared/services/feature-flags.service';

@Component({
  selector: 'app-custom-campaign-plan-audience',
  templateUrl: './custom-campaign-plan-audience.component.html',
  styleUrls: ['./custom-campaign-plan-audience.component.css'],
  providers: [PlanCampaignActionService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomCampaignPlanAudienceComponent implements OnInit, OnDestroy {

  flags = this.featureFlags.flags;
  form: UntypedFormGroup;
  debug = false;
  loading: boolean;
  audienceData: PlanCampaignAction[];
  eventKey: string;
  isModalOpen = false;
  isCouponRecommenderOpened = false;
  actionToAssignCoupon: PlanCampaignAction;
  actionToRecommendCoupon: PlanCampaignAction;
  recommendedCoupon: RecommendedCoupon;
  enableCouponRecommenderRecalculate = false;
  currencySymbol: string;
  tableColumns = this.getColumnHeaders();
  totals: {
    customers: number,
    affectationSales: number,
    seasonalityAvg: number
  };
  openCampaignEditionModal = false;
  actionRaw: PlanCampaignAction;
  selectedCoupon: unknown;

  private couponSelectionChanged = false;
  private destroy$: Subject<void> = new Subject<void>();

  @Input() plan: Plan;
  @Input() planCampaign: PlanCampaign;

  @Output() formValidity = new EventEmitter<string>();
  @Output() requestsStatus = new EventEmitter<string>();
  @Output() calculatedDataStatus = new EventEmitter<string>();

  @ViewChild('audienceDates', { static: true }) audienceDates: AudienceDatesComponent;
  @ViewChild('couponsForm') couponsForm: FormCouponsComponent;
  @ViewChild('couponRecommender') couponRecommender: AudienceCouponRecommenderComponent;

  @ViewChildren(AudienceContentComponent) actions: QueryList<AudienceContentComponent>;

  constructor(
    private refreshCacheService: RefreshCacheService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private actionsService: PlanCampaignActionService,
    private profileService: ProfileService,
    private changeDetector: ChangeDetectorRef,
    private featureFlags: FeatureFlagsService
  ) { }

  ngOnInit(): void {
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
    // if plan status is 'draft' then call loadAudienceData() to load the data from the server
    if (this.plan.status !== 'draft') {
      this.loadAudienceData();
    }
    this.changeDetector.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //#region <Template handlers>

  handleEmittedAction() {
    const unsavedActions = this.audienceData.filter((audienceRow) => !audienceRow.calculatedDataLoaded);
    this.calculatedDataStatus.emit('loading');
    if (unsavedActions.length === 0) {
      this.calculatedDataStatus.emit('finished_loading');
      this.calculateTotals();
      this.audienceDates.isEditionPrevented = false;
    }
  }

  handleEmittedCampaign(planCampaign: PlanCampaign) {
    this.planCampaign = planCampaign;
    this.audienceDates.isEditionPrevented = true;
    this.loadAudienceData();
    this.changeDetector.markForCheck();
  }

  handleCouponCreation(action: PlanCampaignAction) {
    this.isModalOpen = true;
    this.actionToAssignCoupon = action;
    this.changeDetector.markForCheck();
  }

  handleCouponSelected(selectedCoupon?: unknown) {
    this.couponSelectionChanged = true;
    this.selectedCoupon = selectedCoupon;
    this.changeDetector.markForCheck();
  }

  handleCouponRecommender(action: PlanCampaignAction) {
    this.actionToRecommendCoupon = action;
    this.isCouponRecommenderOpened = true;
    this.changeDetector.markForCheck();
  }

  handleSavedCoupon(couponObj: object) {
    this.closeModal();
    const filteredComponents: AudienceContentComponent[] = this.actions.filter(action => action.item.id === this.actionToAssignCoupon.id);
    if (filteredComponents.length > 0) {
      filteredComponents[0].assignCoupon(couponObj);
    }
  }

  handleRecommendedCoupon(coupon: RecommendedCoupon) {
    this.recommendedCoupon = coupon;
    this.changeDetector.markForCheck();
  }

  handleErrorResponse(errResponse: HttpErrorResponse) {
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errResponse.error.error);
    this.closeRecommender();
  }

  handleFormChanged() {
    this.enableCouponRecommenderRecalculate = true;
    this.changeDetector.markForCheck();
  }
  //#endregion <Template handlers>

  closeModal(): void {
    this.isModalOpen = false;
    this.changeDetector.markForCheck();
  }

  closeRecommender(): void {
    this.couponRecommender.reset();
    this.couponRecommender.close();
    this.enableCouponRecommenderRecalculate = false;
    this.isCouponRecommenderOpened = false;
    this.recommendedCoupon = null;
    this.changeDetector.markForCheck();
  }

  closeManualCampaignModal(event) {
    this.openCampaignEditionModal = !event;
  }

  triggerSave(eventKey: string): void {
    this.eventKey = eventKey;
    const checkedFormGroupKeys: string[] = this.getCheckedFormsGroupKeys();
    if (checkedFormGroupKeys.length <= 0) {
      this.displayNoRowsSelectedWarning()
      .then(data => {
        if (data.hasOwnProperty('value') && data.value) {
          this.prepareToSave();
        } else if (data.hasOwnProperty('dismiss') && data.dismiss) {
          this.requestsStatus.emit('save_cancelled');
        }
      })
      .catch(() => {});
      return;
    }
    this.prepareToSave();
  }

  sendData(): void {
    if (this.couponsForm) { this.couponsForm.sendData(); }
  }

  selectRecommendedCoupon(): void {
    const filteredComponents: AudienceContentComponent[] =
      this.actions.filter(action => action.item.id === this.actionToRecommendCoupon.id);
    if (filteredComponents.length > 0 && this.recommendedCoupon) {
      filteredComponents[0].assignCoupon(this.recommendedCoupon);
    }
    this.closeRecommender();
  }

  recalculateRecommendedCoupon(): void {
    if (this.couponRecommender) {
      this.enableCouponRecommenderRecalculate = false;
      this.couponRecommender.recalculate();
    }
  }

  hasAnyCampaignPlanActionChanged(): boolean {
    return this.couponSelectionChanged;
  }

  handleCampaignEdition(action: PlanCampaignAction) {
    this.actionRaw = action;
    this.openCampaignEditionModal = true;
    this.changeDetector.markForCheck();
  }

  handleSelectedCoupon(payload: {actionId: number, coupon: object}) {
    const filteredComponents: AudienceContentComponent[] = this.actions.filter(action => action.item.id === payload.actionId);
    if (filteredComponents.length > 0) {
      filteredComponents[0].assignCoupon(payload.coupon);
    }
  }

  handleActionSaved(campaignVia: 'none' | 'email' | 'sms'): void {
    this.actions.find(action => action.item.id === this.actionRaw.id)?.enqueuePollingRequest();
    this.actionRaw.channel = campaignVia;
    this.changeDetector.markForCheck();
  }

  private loadAudienceData(): void {
    this.loading = true;
    this.debug ? this.enqueueDebugRequest() : this.enqueuePollingRequest();
    this.calculatedDataStatus.emit('loading');
    this.changeDetector.markForCheck();
  }

  private calculateTotals(): void {
    const customers = this.audienceData.reduce((acc, action) => acc + (action.audience.totalCustomers || 0), 0);
    const affectationSales = this.audienceData.reduce((acc, action) => acc + (action.audience.affectationSales || 0), 0);
    const sumSalesMpaa = this.audienceData.reduce((acc, action) => acc + (action.audience.salesMpaa || 0), 0);
    const sumSalesPeriod5Mpaa = this.audienceData.reduce((acc, action) => acc + (action.audience.salesPeriodMpaa || 0), 0);
    const seasonalityAvg = sumSalesPeriod5Mpaa !== 0 ? (sumSalesMpaa / sumSalesPeriod5Mpaa) : 0;

    setTimeout(() => {
      this.totals = { customers, affectationSales, seasonalityAvg };
      this.changeDetector.markForCheck();
    });
  }

  private prepareToSave(): void {
    const actions$ = this.buildRequestsArray(Object.keys(this.form.controls));
    actions$.forEach(requestItem => this.executeRequest(requestItem));
  }

  private executeRequest(requestData: { actionId: number, sub$: Observable<any> }): void {
    const action = this.audienceData.find(el => el.id === requestData.actionId);
    action.loading = true;
    requestData.sub$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        action.loading = false;
        action.saved = true;
        this.checkIfAllActionsSaved();
        this.changeDetector.markForCheck();
      },
      error: (errorData: HttpErrorResponse) => {
        action.loading = false;
        action.saved = false;
        action.errors.push(errorData.error.error);
        this.changeDetector.markForCheck();
      }}
    );
  }

  private checkIfAllActionsSaved(): void {
    const formGroupKeys: string[] = Object.keys(this.form.controls);
    const actionsToSave = this.audienceData.filter(el => formGroupKeys.includes(el.formGroupKey));
    const unsavedActions = actionsToSave.filter(el => !el.saved);
    if (unsavedActions.length === 0) { this.requestsStatus.emit(this.eventKey); }
  }

  private buildRequestsArray(formGroupKeys: string[]): { actionId: number, sub$: Observable<any> }[] {
    const checkedFormsGroupKeys = this.getCheckedFormsGroupKeys();
    return formGroupKeys.map(key => {
      const action = this.audienceData.find(el => el.formGroupKey === key);
      const childForm: UntypedFormGroup = this.form.get(key) as UntypedFormGroup;
      const payload = {
        coupon_id: null,
        audience_config: null,
        forecast_config: null
      };

      if (checkedFormsGroupKeys.includes(action.formGroupKey)) {
        this.getPayloadChildForm(childForm, payload);
      }

      return {
        actionId: action.id,
        sub$: this.actionsService.patchCampaign(payload, `${action.id}`)
      };
    });
  }

  private getPayloadChildForm(form: UntypedFormGroup, payload: { coupon_id: number, audience_config: object, forecast_config: object }): void {
    const couponValue = form.get('coupon_id').value;
    const couponId = parseInt(couponValue[0].id, 10);
    const estimatedCustomerRedemption = parseFloat(form.get('estimated_customer_redemption').value);
    const estimatedRedemption = parseFloat(form.get('estimated_redemption').value);
    const estimatedDelivery = parseFloat(form.get('estimated_delivery').value);

    payload.coupon_id = couponId;
    payload.audience_config = {
      estimated_customer_redemption: estimatedCustomerRedemption,
      estimated_redemption: estimatedRedemption,
      estimated_delivery: estimatedDelivery
    };
    payload.forecast_config = {}
  }

  private displayNoRowsSelectedWarning(): Promise<SweetAlertResult> {
    const desc = this.translate.instant('resources.campaign_plans.messages.none_selected');
    return this.confirmationService.displayConfirmationAlert('', desc);
  }

  private getCheckedFormsGroupKeys(): string[] {
    const nestedForms = this.form?.controls ?? [];
    const checkedForms = Object.keys(nestedForms).filter(formGroupKey => {
      const subForm: UntypedFormGroup = nestedForms[formGroupKey] as UntypedFormGroup;
      const checkKey = Object.keys(subForm.controls).find(key => key.indexOf('check_') >= 0);
      return subForm.get(checkKey).value === true;
    });
    return checkedForms;
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.form.status));
    this.form.statusChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
  }

  private enqueueDebugRequest(): void {
    setTimeout(() => {
      this.loading = false;
      this.audienceData = PlanCampaignActionMock.map(action => new PlanCampaignAction(action));
      this.form = new UntypedFormGroup({});
      this.formStatusChanges();
      this.changeDetector.markForCheck();
    }, 500);
  }

  private enqueuePollingRequest(): void {
    const params = {
      apiEndPoint: `campaign_plans/${this.planCampaign.id}/actions`,
      numberPerPage: 300
    };
    this.refreshCacheService.getExpensiveData(params).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.loading = false;
        this.audienceData = response['list'].map(actionApiObj => new PlanCampaignAction(actionApiObj));
        this.form = new UntypedFormGroup({});
        this.formStatusChanges();
        this.changeDetector.markForCheck();
      },
      error: errorData => {
        this.loading = false;
        this.audienceData = [];
        this.confirmationService.displayErrorAlert('Error', errorData.error.error);
        this.changeDetector.markForCheck();
      }}
    );
  }

  private getColumnHeaders() {
    const baseTranslation = 'resources.campaign_plans.types.custom.audience.table.';
    const tableColumns = [
      'name',
      'impacted_customer',
      'average_expenditure',
      'average_ticket',
      'units',
      'frequency',
      'pvp',
      'affectation_sales',
      'seasonality',
      'control_group',
      'minimum_money',
      'minimum_units',
      'estimated_customer_redemption',
      'estimated_redemption',
      'estimated_delivery',
      'select_coupon',
      'coupon_actions'
    ];
    if(this.flags.showPlanCampaignAudienceChannel) {
      tableColumns.splice(1, 0, 'channel');
    }
    return tableColumns.map(key => baseTranslation + key);
  }

}