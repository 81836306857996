<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table" aria-hidden="true" *ngIf="partner">
              <tbody>
                <tr *ngIf="partner.id">
                  <td class="no-border-top"><b [translate]="'resources.scores.partners.columns.id'"></b></td>
                  <td class="no-border-top">{{partner.id}}</td>
                </tr>
                <tr>
                  <td><b [translate]="'resources.scores.partners.columns.active'"></b></td>
                  <td *ngIf="partner.active">
                    <span class="font-size-12 label label-success">
                      {{'resources.scores.partners.status.active' | translate}}
                    </span>
                  </td>
                  <td *ngIf="!partner.active">
                    <span class="font-size-12 label label-danger">
                      {{'resources.scores.partners.status.inactive' | translate}}
                    </span>
                  </td>
                </tr>
                <tr *ngIf="partner.name">
                  <td><b [translate]="'resources.scores.partners.columns.name'"></b></td>
                  <td>{{partner.name}}</td>
                </tr>
                <tr *ngIf="partner.description">
                  <td><b [translate]="'resources.scores.partners.columns.description'"></b></td>
                  <td>{{partner.description}}</td>
                </tr>
                <tr *ngIf="!missingImg">
                  <td><b [translate]="'resources.scores.partners.columns.thumbnail'"></b></td>
                  <td><img class="img-container" src="{{partner.thumbnail}}" alt="thumbnail"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
