import { Component } from '@angular/core';
import { ContentDesignerService } from '../../content-designer.service';
import { Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-load-csv',
  templateUrl: './load-csv.component.html',
  styleUrls: ['./load-csv.component.css'],
  providers: [ContentDesignerService]
})
export class LoadCsvComponent {
  public csv_text: string;

  constructor(private contentDesignerService: ContentDesignerService,
              private confirmationService: ConfirmationService,
              private translate: TranslateService,
              private router: Router) { }

  loadMessagesWithCsv() {
    this.contentDesignerService.loadMessagesWithCsv(this.csv_text)
    .subscribe( response => {
      this.confirmationService.displaySuccessAlert(
        this.translate.instant('resources.messages.warnings.csv_load_title'),
        this.translate.instant('resources.messages.warnings.csv_load_text')
      ).catch(() => {});
      this.closeModal();
    },
    error => {
      if (!this.csv_text) {this.confirmationService.displayErrorAlert(
        this.translate.instant('resources.messages.warnings.error_load_title'),
        this.translate.instant('resources.messages.warnings.error_load_text')
      );
      }
      console.log(error);
    });
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

}
