<div class="row" *ngIf="element">
  <div class="col-md-12">
    <div class="card mrg-left-15 mrg-right-15">
      <div class="card-block">
        <div class="pull-left">
          <h4 class="card-title">
            <i class="ei-timer"></i>
            {{ 'resources.reports.fields.last_generated' | translate }}
            {{ lastGenerationAt | dateTimeZone: 'DD/MM/YYYY HH:mm' }}
          </h4>
        </div>
        <div class="btn-group pull-right">
          <button class="btn btn-default" (click)="downloadCsv()">
            {{ 'resources.reports.coupons_in_app_analysis.buttons.download_csv' | translate }}
          </button>
        </div>
        <table class="table table-responsive table-hover table-sm table-bordered no-mrg-btm" aria-hidden="true">
          <thead>
            <tr class="highlight-header">
              <th class="text-center" *ngFor="let header of tableHeaders">
                {{header.translateKey | translate: {currencySymbol: currencySymbol} }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of element.results | keyvalue">
              <td *ngFor="let header of tableHeaders; let i = index;">
                <ng-container [ngSwitch]="header.key">
                  <ng-container *ngSwitchCase="'auto_redeem'">
                    <ng-container *ngIf="item.value[header.key]; else notAutoRedemable">
                      <span class="text-success"><i class="fa-solid fa-check"></i></span>
                    </ng-container>
                    <ng-template #notAutoRedemable>
                      <span class="text-danger"><i class="fa-solid fa-x"></i></span>
                    </ng-template>
                  </ng-container>
                  <ng-container *ngSwitchCase="'campaign_category'">
                    <ng-container *ngIf="containsPlansPrefix(item.value[header.key]); else notTranslatable">
                      {{ 'resources.campaign_plans.types.dictionary.' + item.value[header.key] | translate }}
                    </ng-container>
                    <ng-template #notTranslatable>
                      {{item.value[header.key]}}
                    </ng-template>
                  </ng-container>
                  <ng-container *ngSwitchCase="'campaign_id'">
                    <a (click)="goToCampaign(item.value.campaign_id)">
                      {{item.value.campaign_id}}
                    </a>
                  </ng-container>
                  <ng-container *ngSwitchCase="'campaign_history_id'">
                    <a (click)="goToCampaignHistory(item.value.campaign_id, item.value.campaign_history_id)">
                      {{item.value.campaign_history_id}}
                    </a>
                  </ng-container>
                  <ng-container *ngSwitchCase="'plan_id'">
                    <ng-container *ngIf="item.value.plan_id; else noPlan">
                      <a (click)="goToPlan(item.value.plan_id)">
                        {{item.value.plan_id}}
                      </a>
                    </ng-container>
                    <ng-template #noPlan>-</ng-template>
                  </ng-container>
                  <ng-container *ngSwitchCase="'coupon_id'">
                    <a (click)="goToCoupon(item.value.coupon_id)">
                      {{item.value.coupon_id}}
                    </a>
                  </ng-container>
                  <ng-container *ngSwitchCase="'coupon_discount'">
                    <ng-container *ngIf="item.value.coupon_discount_type === 'cash'">
                      {{ item.value.coupon_discount | currency: currencySymbol }}
                    </ng-container>
                    <ng-container *ngIf="item.value.coupon_discount_type === 'percent'">
                      {{ item.value.coupon_discount | number:'1.2-2'}} %
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'coupon_name'">
                    {{ item.value.campaign_name | lgMultilanguageTranslate}}
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <ng-container *ngIf="isString(item.value[header.key]); else noString">
                      {{item.value[header.key] | translate}}
                    </ng-container>
                    <ng-template #noString>
                      <ng-container *ngIf="isType(header.key, 'percentage')">
                        {{item.value[header.key] | percent: '1.2-2': 'es'}}
                      </ng-container>
                      <ng-container *ngIf="isType(header.key, 'integer')">
                        {{item.value[header.key] | number: '1.0-0': 'es'}}
                      </ng-container>
                      <ng-container *ngIf="isType(header.key, 'float')">
                        {{item.value[header.key] | number: '1.2-2': 'es'}}
                      </ng-container>
                      <ng-container *ngIf="isType(header.key, 'currency_float')">
                        {{item.value[header.key] | number: '1.2-2' }} {{currencySymbol}}
                      </ng-container>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>