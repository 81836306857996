import { getCurrencySymbol } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ProfileService } from '../../../../profiles/profile.service';
import { ImportProductsComponent } from '../../../../shared/import-products/import-products.component';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { DateTimeZoneService } from '../../../../shared/services/date-time-zone.service';
import { DictionaryService } from '../../../../shared/services/dictionary.service';
import { LaunchDarklyService } from '../../../../shared/services/launch-darkly/launchdarkly.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { CouponsService } from '../../../coupons/coupons.service';
import { FeaturesService } from '../../products/features.service';
import { ProductsService } from '../../products/products.service';
import { FeatureFlagsService } from './../../../../shared/services/feature-flags.service';
import { FrogedService } from './../../../../shared/services/froged/froged.service';
import { COUPON_SECTIONS } from './constants/form-sections.constant';
import { getFormSections } from './coupon-utils/coupon-form-section-utils';
import { getValidations } from './coupon-utils/coupon-form-validation';
import { getTranslationInputs } from './coupon-utils/coupon-utils';

type FieldAction = 'disable' | 'enable' | 'reset';

@Component({
  selector: 'app-form-coupons',
  templateUrl: './form-coupons.component.html',
  styleUrls: ['./form-coupons.component.scss'],
  providers: [CouponsService, DictionaryService]
})

export class FormCouponsComponent implements OnInit, OnDestroy {

  @Input() embedded = false;
  @Output() savedCoupon = new EventEmitter<Object>();
  @Output() closeCoupon = new EventEmitter();
  @ViewChild(ImportProductsComponent) importProductsModal: ImportProductsComponent;

  company = this.profileService.getProfileCompany();
  couponData: any;
  couponForm: UntypedFormGroup;
  couponSections = getFormSections(COUPON_SECTIONS);
  couponTypeSelected: boolean;
  currencySymbol = getCurrencySymbol(this.company.currency, 'wide');
  filteredAvailableLanguages: any[];
  flags = this.featureFlags.flags;
  formMode = this.route.snapshot.data.form;
  hasNullName: boolean;
  historyId: number;
  id: number;
  imageData: { filename: any; filesize: any; filetype: any; base64: string; };
  inputs: QuestionBase<any>[];
  isMissingImg: boolean;
  loading: boolean;
  mode = this.route.snapshot.data.mode;
  subs$: Subscription[] = [];

  constructor(
    private confirmationService: ConfirmationService,
    private couponsService: CouponsService,
    private dateService: DateTimeZoneService,
    private featureFlags: FeatureFlagsService,
    private featureService: FeaturesService,
    private frogedService: FrogedService,
    private launchDarklyService: LaunchDarklyService,
    private modalStatusService: ModalStatusService,
    private profileService: ProfileService,
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    if (this.embedded) {
      this.mode = 'create';
      this.initializeForm();
    } else {
      this.getParams();
    }
    this.reactFeatureFlagChanges();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  // ==========================================
  // INIT FORM
  // ==========================================
  private initializeForm(): void {
    this.inputs = this.couponsService.getInputs({});
    this.refreshInputs();
    this.handleAvailableInputs();
    this.handlePrintChanges();
    this.handleAppliesChanges();
    this.getAvailableLanguages();
    this.handlePointsReward();
  }

  private getParams(): void {
    const route$ = this.route.params.subscribe(params => {
      const hasId = params.hasOwnProperty('id');
      const hasHistoryId = params.hasOwnProperty('history_id');

      if (hasHistoryId) {
        this.id = params.id;
        this.historyId = params.history_id;
        this.getCouponByHistoryId();
      } else if (hasId) {
        this.id = params.id;
        this.getCouponById();
      } else {
        this.initializeForm();
      }
    });
    this.subs$.push(route$);
  }
  // ==========================================
  // END: INIT FORM
  // ==========================================


  // ==========================================
  // GET DATA
  // ==========================================

  private getCouponById() {
    if (this.formMode === 'update_coupon') {
      this.couponsService.getCouponsById(this.id).subscribe(
        coupon => {
          this.initializeFormWithCouponData(coupon);
        }
      );
    }
  }

  private getCouponByHistoryId(): void {
    this.couponsService.getCouponsByHistoryId(this.id, this.historyId).subscribe(
      historyCoupon =>{
        this.initializeFormWithCouponData(historyCoupon);
      }
    );
  }

  private initializeFormWithCouponData(coupon: any): void {
    this.couponData = coupon;
    if (coupon.thumbnail === '') { this.isMissingImg = true; }
    this.setFormInputs(coupon);
    this.handlePrintChanges();
    this.handleAppliesChanges();
    this.handlePointsReward();
    getTranslationInputs(coupon, this.qcs, this.couponForm, this.inputs, this.mode);
    this.filteredAvailableLanguages = coupon.available_languages;
  }
  // ==========================================
  // END: GET DATA
  // ==========================================


  // ==========================================
  // MULTISELECT CHANGES
  // ==========================================
  handleMultiselectChange({ key, event }: { key: string, event: any }): void {
    const handlers = {
      affectation: () => this.handleAffectationChanges(event),
      coupon_type: () => this.couponChanges(event),
      discount_type: () => this.applyFieldAction('reset', 'discount'),
      taxonomy: () => this.setProductsCategories(),
    };
    handlers[key]();
  }

  private handleAffectationChanges(event): void {
    if (event) {
      this.applyFieldAction('reset', 'product_ids')
      this.applyFieldAction('reset', 'feature_pks')
    }
  }

  private setProductsCategories(): void {
    this.findInput('feature_pks').selectedIds = [];
    const taxonomy = this.couponForm.value['taxonomy'];

    setTimeout(() => {
      const slug = taxonomy.length ? taxonomy[0].rawElement.slug : null;
      this.featureService.setTaxonomies(slug);
    });
  }
  // ==========================================
  // END: MULTISELECT CHANGES
  // ==========================================


  // ==========================================
  // COUPON CHANGES
  // ==========================================

  private couponChanges(couponType: { id: string, name: string }): void {
    const affectationInput = this.findInput('affectation');
    const appliesInput = this.findInput('applies_to');
    const discountTypeInput = this.findInput('discount_type');
    const featurePksInput = this.findInput('feature_pks');
    const purchasedFeaturePks = this.findInput('purchased_feature_pks');

    if (couponType) {
      this.couponTypeSelected = true;
      featurePksInput.settings['singleSelection'] = true;
      discountTypeInput.disabled = true;
      affectationInput.disabled = true;

      const couponTypeValues = {
        '2x1': { min_units: '2', discount: '100' },
        '3x2': { min_units: '3', discount: '100' }
      };

      if (couponType.id in couponTypeValues) {
        this.applyFieldAction('disable', 'discount', 'min_units', 'max_units');
        this.applyFieldAction('enable', 'min_money', 'max_money');
        this.setCommonOptions();
        this.resetFeatureInputs();

        const { min_units, discount } = couponTypeValues[couponType.id];
        this.couponForm.patchValue({ min_units, discount });

      } else if (couponType.id === '2nd_unit') {
        this.resetFeatureInputs();
        this.setCommonOptions(couponType.id)
        this.handleSecondUnitOptions();

      } else if (couponType.id === 'min_units') {
        this.resetFeatureInputs();
        appliesInput.options = this.couponsService.getAppliesOptions();
        this.couponForm.setValidators(getValidations(this.couponForm));
        this.couponForm.get('discount_type').patchValue([{ id: 'cash', name: this.currencySymbol }]);
        this.applyFieldAction('enable', 'min_units', 'max_units', 'min_money', 'max_money', 'discount');
        this.setCommonOptions(couponType.id);
        this.applyFieldAction('reset', 'min_units', 'max_units', 'min_money', 'max_money', 'discount');
      }
    } else {
      this.couponForm.clearValidators();
      this.couponForm.get('applies_to').patchValue('itself');
      this.couponTypeSelected = false;
      discountTypeInput.disabled = false;
      this.applyFieldAction('reset', 'min_units', 'discount_type', 'max_units', 'feature_pks', 'purchased_feature_pks', 'discount');
      this.applyFieldAction('enable', 'discount', 'min_units', 'max_units', 'min_money', 'max_money');
      featurePksInput.selectedIds = [];
      purchasedFeaturePks.selectedIds = [];
      affectationInput.disabled = false;
      this.couponForm.get('taxonomy').reset();
      this.couponForm.get('affectation').reset();
      featurePksInput.settings['singleSelection'] = false;
    }
    // Froged event to track coupon type selection
    this.frogedService.track('OFFERS_coupons_newCoupon_couponType', { coupon_type: couponType?.name });
  }

  private handleSecondUnitOptions(): void {
    const appliesInput = this.findInput('applies_to');
    const discountInput = this.findInput('discount');

    appliesInput.options = this.couponsService.getAppliesOptions().filter(({ id }) => id === 'itself');

    this.couponForm.get('min_units').patchValue('2');
    this.applyFieldAction('disable', 'min_money', 'max_money', 'min_units', 'max_units');
    this.applyFieldAction('enable', 'discount');

    discountInput.disabled = false;
  }

  private resetFeatureInputs(): void {
    const featurePksInput = this.findInput('feature_pks');
    const purchasedFeaturePks = this.findInput('purchased_feature_pks');
    featurePksInput.selectedIds = [];
    purchasedFeaturePks.selectedIds = [];
    this.couponForm.get('feature_pks').reset();
    this.couponForm.get('purchased_feature_pks').reset();
    this.couponForm.get('taxonomy').reset();
  }

  private setCommonOptions(couponType?: string): void {
    this.couponForm.get('affectation').patchValue([{ id: 'attribute', name: this.translate.instant('resources.coupons.fields.attribute') }]);
    if (couponType !== 'min_units') {
      this.couponForm.get('discount_type').patchValue([{ id: 'percent', name: this.translate.instant('resources.coupons.fields.percent') }]);
      this.couponForm.get('max_units').patchValue('1');
    }
  }
  // ==========================================
  // END: COUPON CHANGES
  // ==========================================


  // ==========================================
  // SEND DATA
  // ==========================================
  sendData(): void {
    if (!this.checkDateValidity() || !this.checkCompanyLanguage()) {
      return;
    }

    this.loading = true;

    if (this.formMode === 'update_coupon') {
      this.handleOperation(this.formMode);
    } else if (this.mode === 'create') {
      this.handleOperation(this.mode);
    } else if (this.formMode === 'update_history_id') {
      this.handleOperation(this.formMode);
    }
  }

  private handleOperation(operation: 'create' | 'update_coupon' | 'update_history_id'): void {
    const couponsOptions = this.getCouponsPayload(operation);
    let operation$;

    if (operation === 'create') {
      operation$ = this.couponsService.sendData(couponsOptions);
    } else if (operation === 'update_coupon') {
      operation$ = this.couponsService.updateData(couponsOptions, this.id);
    } else if (operation === 'update_history_id') {
      operation$ = this.couponsService.updateHistoryData(couponsOptions, this.id, this.historyId);
    }

    operation$.subscribe(
      response => this.handleSuccessfulOperation(response, operation),
      (errorData: HttpErrorResponse) => this.handleUnsuccessfulOperation(errorData)
    );
  }

  private handleSuccessfulOperation(response: any, mode: string): void {
    let title = '';
    let text = '';

    if (mode === 'create') {
      title = 'resources.coupons.form.warnings.create_title';
      text = 'resources.coupons.form.warnings.create_text';

      if (this.embedded) {
        this.savedCoupon.emit(response);
        this.loading = false;
        return;
      }
    } else if (mode === 'update_coupon' || mode === 'update_history_id') {
      title = 'resources.coupons.form.warnings.update_title';
      text = 'resources.coupons.form.warnings.update_text';
    }

    this.confirmationService.displaySuccessAlert(this.translate.instant(title), this.translate.instant(text)).catch(() => { });

    this.modalStatusService.modalStatus.emit();
    this.returnToList();
    this.loading = false;
  }

  private handleUnsuccessfulOperation(errorData: HttpErrorResponse): void {
    this.loading = false;

    const errorList = errorData?.error?.errors;
    const errorMessage = errorData?.error?.error;

    if (errorList?.length) {
      this.handleInternalServerError(errorData);

      if ((this.formMode === 'update_coupon' && this.hasNullName) || (this.mode === 'create' && !this.couponForm.value.name)) {
        this.confirmationService.displayErrorAlert('Error', errorMessage);
      } else {
        this.paintErrorsInForm(errorList);
      }
    }
  }
  // ==========================================
  // END: SEND DATA
  // ==========================================

  // ==========================================
  // PAYLOAD
  // ==========================================

  private getCouponsPayload(operation: 'create' | 'update_coupon' | 'update_history_id') {
    const formValue = this.couponForm.getRawValue();

    const affectation = formValue.affectation?.[0]?.id || null;
    const appliesTo = formValue.coupon_type?.length ? formValue.applies_to : null;
    const couponType = formValue.coupon_type?.[0]?.id || null;
    const discountType = formValue.discount_type?.[0]?.id || null;
    const feature_location_ids = formValue.feature_location_ids?.length ? formValue.feature_location_ids.map(item => item.id) : [];
    const location_ids = formValue.location_ids?.length ? formValue.location_ids.map(item => item.id) : [];
    const partnerId = formValue.partner_id?.[0]?.id || null;
    const points = formValue.reward_points && formValue.points ? parseInt(formValue.points, 10) : null;
    const pointsMoney = formValue.reward_points && formValue.points_money ? parseFloat(formValue.points_money) : null;
    const purchasedFeaturePks = formValue.purchased_feature_pks?.length ? formValue.purchased_feature_pks.map(el => el.rawElement.pk) : null;
    const featurePks = formValue.feature_pks ? formValue.feature_pks.map(term => (term.rawElement.pk)) : purchasedFeaturePks;

    const payload = {
      affectation: affectation,
      applies_to: appliesTo,
      auto_select: formValue.auto_select ?? false,
      available_days: formValue.available_days,
      available_from: formValue.available_from,
      available_to: formValue.available_to,
      coupon_type: couponType,
      cumulative: formValue.cumulative ?? false,
      description: formValue.description,
      discount_type: discountType,
      discount: formValue.discount,
      estimated_customer_redemption: formValue.estimated_customer_redemption ? parseFloat(formValue.estimated_customer_redemption) : null,
      estimated_delivery: formValue.estimated_delivery ? parseFloat(formValue.estimated_delivery) : null,
      estimated_redemption: formValue.estimated_redemption ? parseFloat(formValue.estimated_redemption) : null,
      external_id: formValue.external_id,
      feature_location_ids: feature_location_ids,
      feature_pks: featurePks?.length ? featurePks : null,
      funded: formValue.funded ?? false,
      id: formValue.id,
      image: this.imageData,
      key: formValue.key,
      location_ids: location_ids,
      max_money: formValue.max_money ? parseFloat(formValue.max_money) : null,
      max_redemptions: formValue.max_redemptions ? parseInt(formValue.max_redemptions, 10) : null,
      max_units: formValue.max_units ? parseInt(formValue.max_units, 10) : null,
      min_money: formValue.min_money ? parseFloat(formValue.min_money) : null,
      min_units: formValue.min_units ? parseInt(formValue.min_units, 10) : null,
      name: formValue.name,
      partner_id: partnerId,
      points_money: pointsMoney,
      points: points,
      print_from: formValue.print_from ?? '',
      print_to: formValue.print_to ?? '',
      printable: formValue.printable ?? false,
      priority: formValue.priority,
      provider_id: formValue.provider_id,
      purchased_affectation: formValue.applies_to === 'purchased' ? affectation : null,
      purchased_feature_pks: formValue.applies_to === 'purchased' ? purchasedFeaturePks : null,
      static_code: formValue.static_code
    };

    if (payload['applies_to'] === 'purchased') {
      payload['purchased_feature_pks'] = featurePks;
      payload['feature_pks'] = purchasedFeaturePks;
    }

    this.processPayloadAffectation(payload, formValue, operation);

    if (payload.location_ids?.length >= 0 && payload.feature_location_ids?.length === 0) { delete payload.feature_location_ids; }
    if (payload.feature_location_ids?.length >= 0 && payload.location_ids?.length === 0) { delete payload.location_ids; }

    if (this.flags.couponsAvailableLanguagesVisibility) {
      this.nameAndDescriptionPayload(payload);
    }
    return payload;
  }

  private processPayloadAffectation(payload: any, formValue: any, operation: 'create' | 'update_coupon' | 'update_history_id'): void {
    const isProductAffectation = payload['affectation'] === 'product';
    const isAttributeAffectation = payload['affectation'] === 'attribute';
    const isUpdateHistoryOperation = operation === 'update_history_id';

    if (this.flags.showCouponProductAffectation && isProductAffectation) {
      // Handle product affectation
      delete payload['feature_pks'];
      payload['product_ids'] = formValue.product_ids ? formValue.product_ids.map(product => product.id) : [];
    } else if (isAttributeAffectation && !isUpdateHistoryOperation) {
      // Handle attribute affectation for create or update_coupon operations
      payload['product_ids'] = [];
    } else if (isAttributeAffectation && isUpdateHistoryOperation) {
      // Handle attribute affectation for update_history_id operation
      payload['product_ids'] = this.couponData.product_ids;
    } else {
      // Default case
      payload['feature_pks'] = [];
      payload['product_ids'] = [];
    }
  }

  private nameAndDescriptionPayload(payload): void {
    const nameKey = Object.keys(this.couponForm.value).filter(item => /name_/.test(item)).map(element => element.split('_')[1]);
    const availableLanguages = [];
    if (nameKey && nameKey.length > 0) {
      payload.name = {};
      payload.description = {};
      nameKey.forEach(key => {
        payload['name'][`${key}`] = this.couponForm.value[`name_${key}`];
        payload['description'][`${key}`] = this.couponForm.value[`description_${key}`];
        availableLanguages.push(key);
      });
      this.hasNullName = Object.values(payload.name).some(x => x === null || x === '');
      if (this.hasNullName) {
        payload['name'] = null;
        payload['description'] = null;
      }
    }
    payload.available_languages = availableLanguages;
  }
  // ==========================================
  // END: PAYLOAD
  // ==========================================

  // ==========================================
  // OTHER HANDLERS
  // ==========================================

  handleImportProductList(productIds): void {
    const ids = productIds.split('\n').filter(el => el !== undefined && el !== '');
    const productsInput = this.findInput('product_ids');
    productsInput.value = [];
    productsInput.selectedIds = [];
    productsInput.selectedIds = ids;
    setTimeout(() => (productsInput.dataSource as ProductsService).setFeatures(null));
  }

  handleAvailableInputs(): void {
    const range$ = this.couponForm.get('available_days').valueChanges.subscribe(
      data => {
        if (data.length) {
          const printToInput = this.findInput('print_to');
          this.couponForm.get('available_from').disable();
          this.couponForm.get('available_to').disable();
          this.couponForm.patchValue(
            {
              'available_from': null,
              'available_to': null,
              'print_from': null,
              'print_to': null
            },
            { emitEvent: false }
          );
          printToInput.minDate = null;
          printToInput.maxDate = null;
        } else {
          this.couponForm.get('available_from').enable();
          this.couponForm.get('available_to').enable();
        }
      }
    );
    this.subs$.push(range$);
  }

  private handlePointsReward(): void {
    const rewardPointsCheckbox$ = this.couponForm.get('reward_points').valueChanges.subscribe(value => {
      if (value) {
        this.applyFieldAction('enable', 'points', 'points_money');
      } else {
        this.applyFieldAction('disable', 'points', 'points_money');
        this.applyFieldAction('reset', 'points', 'points_money');
      }
    });

    this.subs$.push(rewardPointsCheckbox$);
  }

  private handleAppliesChanges(): void {
    const youBuyInput = this.findInput('feature_pks');
    const youReceiveInput = this.findInput('purchased_feature_pks');
    const appliesInputValue = this.findInput('applies_to').value;

    if (appliesInputValue === 'itself') {
      youReceiveInput.disabled = true;
    }
    const appliesTo$ = this.couponForm.get('applies_to').valueChanges.subscribe(
      (appliesToValue) => {
        if (appliesToValue === 'itself') {
          youReceiveInput.disabled = true;
          this.couponForm.get('purchased_feature_pks').setValue(null);
        } else {
          youReceiveInput.disabled = false;
          youBuyInput.disabled = false;
        }
      }
    );
    this.subs$.push(appliesTo$);
  }

  private handlePrintChanges(): void {
    const available$ = this.couponForm.get('available_from').valueChanges.subscribe(
      availableFromValue => {
        const isPrintable = this.couponForm.get('printable').value;
        if (!isPrintable) {
          this.couponForm.get('print_from').setValue(null);
        } else {
          this.couponForm.get('print_from').enable();
          this.couponForm.get('print_from').patchValue(availableFromValue);
        }
      }
    );

    const availableTo$ = this.couponForm.get('available_to').valueChanges.subscribe(
      availableToValue => {
        const isPrintable = this.couponForm.get('printable').value;
        const printToInput = this.inputs.find((item => item.key === 'print_to'));
        const calculateDate = availableToValue ? this.dateService.calculateDate('substract', 4, 'days', availableToValue) : null;

        if (!isPrintable) {
          this.couponForm.get('print_to').setValue(null);
        } else {
          this.couponForm.get('print_to').enable();
          printToInput.minDate = this.couponForm.value.print_from;
          printToInput.maxDate = calculateDate;
          this.couponForm.get('print_to').patchValue(calculateDate);
        }
      }
    );

    const printFrom$ = this.couponForm.get('print_from').valueChanges.subscribe(
      printFromValue => {
        const printToInput = this.inputs.find((item => item.key === 'print_to'));
        printToInput.minDate = printFromValue;
        if (printFromValue && this.couponForm.value.available_to) {
          printToInput.maxDate = this.dateService.calculateDate('substract', 4, 'days', this.couponForm.value.available_to);
        } else {
          printToInput.maxDate = null;
        }
      }
    );

    const printTo$ = this.couponForm.get('print_to').valueChanges.subscribe(
      printToValue => {
        const isValid = this.dateService.isDate2BeforeDate1(printToValue, this.couponForm.value.print_from);
        if (!isValid) {
          const printToInput = this.inputs.find((item => item.key === 'print_to'));
          const printFrom = this.couponForm.value.print_from;
          printToInput.minDate = printToValue ? printFrom : null;
          printToInput.maxDate = printToValue ? printFrom : null;
        }
      }
    );

    const printable$ = this.couponForm.get('printable').valueChanges.subscribe(
      printable => {
        const availableFromInput = this.couponForm.get('available_from').value;
        const availableToInput = this.couponForm.get('available_to').value;
        const printFromInput = this.inputs.find((item => item.key === 'print_from'));
        const printToInput = this.inputs.find((item => item.key === 'print_to'));
        if (!printable) {
          this.couponForm.get('print_from').disable();
          this.couponForm.get('print_to').disable();
          this.couponForm.get('print_from').setValue(null);
          this.couponForm.get('print_to').setValue(null);
          printFromInput.required = false;
          printToInput.required = false;
        }
        if (printable && availableFromInput && availableToInput) {
          this.couponForm.get('print_from').enable();
          this.couponForm.get('print_to').enable();
          this.couponForm.get('available_from').patchValue(availableFromInput);
          this.couponForm.get('available_to').patchValue(availableToInput);
        }
      }
    );

    const locationId$ = this.couponForm.get('location_ids').valueChanges.subscribe(
      locationIds => {
        const locationTermsField = this.couponForm.get('feature_location_ids');
        const locationTermsInput = this.inputs.find((item => item.key === 'feature_location_ids'));
        if (locationIds && locationIds.length > 0) {
          locationTermsField.setValue(null);
          locationTermsInput.value = [];
          locationTermsInput.selectedIds = [];
        }
      }
    );

    const locationTaxonomyTermIds$ = this.couponForm.get('feature_location_ids').valueChanges.subscribe(
      locationTaxonomyTermIds => {
        const locationsField = this.couponForm.get('location_ids');
        const locationsInput = this.inputs.find((item => item.key === 'location_ids'));
        if (locationTaxonomyTermIds && locationTaxonomyTermIds.length > 0) {
          locationsField.setValue(null);
          locationsInput.value = [];
          locationsInput.selectedIds = [];
        }
      }
    );
    this.subs$.push(available$);
    this.subs$.push(availableTo$);
    this.subs$.push(printFrom$);
    this.subs$.push(printTo$);
    this.subs$.push(printable$);
    this.subs$.push(locationId$);
    this.subs$.push(locationTaxonomyTermIds$);
  }

  private handleInputsIfEditingHistory(): void {
    const autoSelectInp = this.findInput('auto_select')
    autoSelectInp.disabled = !!this.historyId;
  }

  // ==========================================
  // END: OTHER HANDLERS
  // ==========================================


  // ==========================================
  // UTILS / HELPERS
  // ==========================================
  getInputConfig = (inputKey: string): QuestionBase<any> => {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.couponForm, formKey, value);
  }

  onFileChange(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.imageData = {
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
          base64: reader.result.toString().split(',')[1]
        };
      };
    }
  }

  returnToList(): void {
    if (this.router.url.indexOf('(modal:') >= 0) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => { });
    } else {
      this.router.navigate(['/data-warehouse/coupons/coupons']).catch(() => { });
    }
  }

  private applyFieldAction(action: FieldAction, ...fields: string[]): void {
    fields.forEach((field) => this.couponForm.get(field)[action]());
  }

  private checkDateValidity(): boolean {
    const { available_from, available_to } = this.couponForm.value;

    if (available_from && available_to && available_from > available_to) {
      this.confirmationService.displayErrorAlert(
        this.translate.instant('resources.coupons.form.errors.error_title'),
        this.translate.instant('resources.coupons.form.errors.dates_error_text')
      );
      return false;
    }
    return true;
  }

  private checkCompanyLanguage(): boolean {
    const translatePath = 'resources.coupons.form.warnings';
    const companyLanguage = this.company.locale.split('_')[0];
    const parsedLanguageSelected = this.couponForm.get('available_languages').value.map(lang => lang.id);

    if (this.flags.couponsAvailableLanguagesVisibility && !parsedLanguageSelected.includes(companyLanguage)) {
      this.confirmationService.displayErrorAlert(
        this.translate.instant('common.error'),
        this.translate.instant(`${translatePath}.company_lang_not_selected`)
      );
      return false;
    }
    return true;
  }

  private findInput(key: string): QuestionBase<any> {
    return this.inputs.find(input => input.key === key);
  }

  private getAvailableLanguages(): void {
    const availableLanguages = this.company?.available_languages;
    if (availableLanguages) {
      const availableLanguagesOptions = this.findInput('available_languages').options;
      const filtered = availableLanguagesOptions.filter(item => availableLanguages.some(_item => _item.startsWith(item.id)));
      this.couponForm.get('available_languages').patchValue(filtered);
      this.filteredAvailableLanguages = filtered;
    }
  }

  private handleInternalServerError(errorData: HttpErrorResponse): void {
    const errorMsg = this.translate.instant('common.messages.internal_error')
    if (errorData.status === 500) {
      this.confirmationService.displayErrorAlert('Error', errorMsg);
    }
  }

  private paintErrorsInForm(errorsArray: { field: string }[]): void {
    const couponType = this.couponForm.get('coupon_type').value;
    const appliesTo = this.couponForm.get('applies_to').value;

    if (couponType && appliesTo === 'purchased') {
      const fieldMapping = {
        'feature_pks': 'purchased_feature_pks',
        'purchased_feature_pks': 'feature_pks'
      };

      errorsArray.forEach((error) => {
        error.field = fieldMapping[error.field] ?? error.field;
      });
    }

    this.qcs.paintErrorsInForm(this.inputs, this.couponForm, errorsArray);
  }

  private reactFeatureFlagChanges(): void {
    this.launchDarklyService.flagChanges.subscribe((flags) => {
      this.refreshInputs();
    });
  }

  private refreshInputs(): void {
    if (this.inputs !== undefined) {

      if (this.flags.showCouponProductAffectation) {
        this.findInput('affectation').options = this.couponsService.getAffectation(true);
      }

      this.couponForm = this.qcs.toFormGroup(this.inputs);
      this.couponForm.setValidators(getValidations(this.couponForm));

      const couponType = this.couponForm?.value?.coupon_type;

      if (couponType?.length) {
        this.couponTypeSelected = true;
        this.findInput('feature_pks').settings['singleSelection'] = true;
        this.findInput('affectation').disabled = true;
      }
    }
  }

  private setFormInputs(coupon): void {
    this.inputs = this.couponsService.getInputs(coupon);
    this.couponForm = this.qcs.toFormGroup(this.inputs);
    this.refreshInputs();
    this.handleAvailableInputs();
    this.handleInputsIfEditingHistory();
  }

  // ==========================================
  // END: UTILS / HELPERS
  // ==========================================
}
