import { isNullOrUndefined } from "../../../../shared/utils/common.utils";

export class CustomerAttribute {
  id: number;
  system: boolean;
  name: string;
  description: string;
  attributeType: string;
  dataType: string;
  sql: string;
  ignoreStrictProfiling: boolean;
  dataPeriodType: string;
  relativeValue: number;
  startDate: string;
  startTime: string;
  recurrence: string;
  executionMonths: string[];
  executionHourInterval: number;
  status: string;
  executionType?: string;
  lastExecutionTime?: string;
  resultsAvailableInRealTime?: boolean;

  constructor(cfg: CustomerAttributeAPIResponse) {
    this.id = cfg['id'] ?? null;
    this.system = cfg['system'] ?? false;
    this.name = cfg['name'] ?? null;
    this.description = cfg['description'] ?? null;
    this.attributeType = cfg['attribute_type'] ?? null;
    this.dataType = cfg['data_type'] ?? null;
    this.sql = cfg['sql'] ?? null;
    this.ignoreStrictProfiling = !isNullOrUndefined(cfg['strict_profiling']) ? !cfg['strict_profiling'] : false;
    this.dataPeriodType = cfg['data_period_type'] ?? null;
    this.relativeValue = cfg['relative_value'] ?? null;
    this.startDate = cfg['start_date'] ?? null;
    this.startTime = cfg['start_time'] ?? null;
    this.recurrence = cfg['recurrence'] ?? null;
    this.executionMonths = cfg['execution_months'] ?? null;
    this.executionHourInterval = cfg['execution_hour_interval'] ?? null;
    this.status = cfg['status'] ?? null;
    this.lastExecutionTime = cfg['last_execution_time'] ?? null;
    this.resultsAvailableInRealTime = cfg['results_available_in_real_time'] ?? false;
  }
}

export interface CustomerAttributeAPIResponse {
  id?: number;
  system?: boolean;
  name?: string;
  description?: string;
  attribute_type?: string;
  data_type?: string;
  sql?: string;
  strict_profiling?: boolean;
  data_period_type?: string;
  relative_value?: number;
  recurrence?: string;
  execution_months?: string[];
  start_date?: string;
  start_time?: string;
  status?: string;
  last_execution_time?: string;
  results_available_in_real_time?: boolean;
}