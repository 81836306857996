import {Component, OnDestroy, OnInit} from '@angular/core';
import { WalletsService } from '../../wallets.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-show-wallets',
  templateUrl: './show-wallets.component.html',
  styleUrls: ['./show-wallets.component.css'],
  providers: [WalletsService]
})
export class ShowWalletsComponent implements OnInit, OnDestroy {

  public loading: boolean;
  public isModal: boolean;
  public id: number = this.route.params['value'].id;
  public walletsData: any;
  private subs$: Subscription[];


  constructor(private walletsService: WalletsService,
              private router: Router,
              private route: ActivatedRoute)
  {
    this.subs$ = [];
  }

  ngOnInit() {
    this.isModal = this.router.url.indexOf('(modal:show/wallets/') >= 0;
    this.getWalletsData();
  }

  ngOnDestroy() {
    this.subs$.forEach(sub => sub.unsubscribe());
  }

  private getWalletsData() {
    this.loading = true;
    const wallets$ = this.walletsService.getWalletById(this.id).subscribe(
      walletsData => {
        this.walletsData = walletsData;
        wallets$.unsubscribe();
        this.loading = false;
      },
      () => {
        wallets$.unsubscribe();
        this.loading = false;
      }
    );
    this.subs$.push(wallets$);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  goToReward() {
    if ( this.isModal ) {
      window.open(`#/scores/rewards/${this.walletsData._embedded.reward.id}`);
    } else {
      this.router.navigate([{ outlets: { modal: `show/rewards/${this.walletsData.reward.id}`}}]).catch(() => {});
    }
  }

}
