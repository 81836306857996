import { UntypedFormGroup } from "@angular/forms";

export function getCustomerAttributesFormPayload(customerAttributesForm: UntypedFormGroup): object {
  let payload: object = {
    name: customerAttributesForm.get('name').value,
    description: customerAttributesForm.get('description').value,
    attribute_type: customerAttributesForm.get('attribute_type').value,
    data_type: customerAttributesForm.get('data_type').value
  };

  const isCalculationAttributeType = payload['attribute_type'] === 'calculation';
  if (isCalculationAttributeType) {
    payload = {
      ...payload,
      results_available_in_real_time: customerAttributesForm.get('results_available_in_real_time').value,
      sql: customerAttributesForm.get('sql').value,
      strict_profiling: !customerAttributesForm.get('strict_profiling').value,
      data_period_type: getPayloadDataPeriodTypeValue(customerAttributesForm),
      relative_value: customerAttributesForm.get('relative_value').value,
      recurrence: getPayloadRecurrenceValue(customerAttributesForm)
    }
  }

  const isScheduledExecutionType = customerAttributesForm.get('execution_type').value === 'scheduled';
  if (isScheduledExecutionType) {
    payload = {
      ...payload,
      start_date: customerAttributesForm.get('start_date').value,
      start_time: customerAttributesForm.get('start_time').value,
      execution_months: getPayloadExecutionMonths(customerAttributesForm),
      execution_hour_interval: customerAttributesForm.get('execution_hour_interval').value
    }
  }

  return payload;
}

function getPayloadDataPeriodTypeValue(customerAttributesForm: UntypedFormGroup): string | null {
  let dataPeriodTypeValue = customerAttributesForm.get('data_period_type').value ?? null;
  const relativeCustomPeriodValue = customerAttributesForm.get('relative_custom_period').value;
  if (dataPeriodTypeValue === 'custom' && relativeCustomPeriodValue?.length) {
    dataPeriodTypeValue = relativeCustomPeriodValue[0].id;
  }
  return dataPeriodTypeValue;
}

function getPayloadRecurrenceValue(customerAttributesForm: UntypedFormGroup): string | null {
  const executionTypeValue = customerAttributesForm.get('execution_type').value ?? null;
  const recurrenceValue = customerAttributesForm.get('recurrence').value ?? null;
  return executionTypeValue && executionTypeValue === 'scheduled' ? recurrenceValue : executionTypeValue;
}

function getPayloadExecutionMonths(customerAttributesForm: UntypedFormGroup): string[] | null {
  let payloadExecutionMonthsValue = null;
  const recurrenceValue = customerAttributesForm.get('recurrence').value ?? null;
  const executionMonthsValue = customerAttributesForm.get('execution_months').value;
  if (recurrenceValue === 'specific_months' && executionMonthsValue?.length) {
    payloadExecutionMonthsValue = executionMonthsValue.map(executionMonth => executionMonth.id);
  }
  return payloadExecutionMonthsValue;
}