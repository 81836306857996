import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-credit-amount-info',
  templateUrl: './credit-amount-info.component.html'
})
export class CreditAmountInfoComponent {
  @Input() creditAmount: number;
  @Input() titleKey: string;
  @Input() icon: string;
  @Input() subtitle: string;
}
