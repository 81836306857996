import { TranslateService } from "@ngx-translate/core";
import { Option } from "../../../../../models/common/option";
import { CustomEntityCondition, CustomEntityConditionForm } from "../../../../../models/segments/conditions/custom-entity-condition";
import { Operator } from "../../../../../models/segments/conditions/operator";
import { OperatorsService } from "../../../../../services/conditions/common/operators.service";
import { CustomEntityAttributeType } from "../../../../../services/conditions/services/custom-entity-condition/enums/custom-entity-attribute-type.enums";
import { CustomEntityKey } from "../../../../../services/conditions/services/custom-entity-condition/enums/custom-entity-key.enums";
import { DateService } from "../../../../../services/date.service";
import { isNullOrUndefined } from "../../../../../utils/common.utils";
import { MultiselectDataSource } from "../../../../multiselect/multiselect";
import { decorateAttrName, getBooleanValueOptions } from "../utils/common.utils";

export function parseItemCondition(
  item: CustomEntityCondition,
  operatorsService: OperatorsService,
  dateService: DateService,
  translate: TranslateService
): CustomEntityConditionForm {
  let parsedItemObj: CustomEntityConditionForm = {
    entity_attribute: item.field_name ? [new MultiselectDataSource(item.field_name, decorateAttrName(item.field_name), { type: item.field_type })] : null,
    entity_attribute_type: item.field_type
  }

  const attributeOperator = item.operator;
  switch(item.field_type) {
    case CustomEntityAttributeType.String:
      parsedItemObj.string_operator = getOperatorValue(attributeOperator, operatorsService.getStringOperators());
      parsedItemObj.string_value = item?.value as string ?? null;
      break;
    case CustomEntityAttributeType.Integer:
      getNumericOperatorValuesToForm(parsedItemObj, item, operatorsService.getNumericOperators(), CustomEntityKey.IntegerOperator, CustomEntityKey.IntegerValue, CustomEntityKey.IntegerValue2);
      break;
    case CustomEntityAttributeType.Float:
    case CustomEntityAttributeType.Number:
      getNumericOperatorValuesToForm(parsedItemObj, item, operatorsService.getNumericOperators(), CustomEntityKey.FloatOperator, CustomEntityKey.FloatValue, CustomEntityKey.FloatValue2);
      break;
    case CustomEntityAttributeType.Boolean:
      parsedItemObj.boolean_operator = getOperatorValue(attributeOperator, operatorsService.getEqualityOperators());
      parsedItemObj.boolean_value = getOptionValue(item.value, getBooleanValueOptions(translate));
      break;
    case CustomEntityAttributeType.Datetime:
    case CustomEntityAttributeType.Date:
      getDatesValuesToForm(parsedItemObj, item, operatorsService.getNumericOperators(), attributeOperator, dateService);
      break;
  }
  return parsedItemObj;
}


function getNumericOperatorValuesToForm(parsedItemObj: CustomEntityConditionForm, item: CustomEntityCondition, operators: Operator[], operatorKey: string, valueKey: string, value2Key: string): void {
  const operatorId = item.operator;
  parsedItemObj[operatorKey] = getOperatorValue(operatorId, operators);
  if (item.operator === 'bt') {
    parsedItemObj[valueKey] = item?.value1 as number ?? null;
    parsedItemObj[value2Key] = item?.value2 as number ?? null;
  } else {
    parsedItemObj[valueKey] = item?.value as number ?? null;
  }
}

function getDatesValuesToForm(parsedItemObj: CustomEntityConditionForm, item: CustomEntityCondition, operators: Operator[], operatorKey: string, dateService: DateService): void {
  parsedItemObj.date_operator = getOperatorValue(operatorKey, operators);
  if (item.operator === 'bt') {
    parsedItemObj.date_value = item?.hasOwnProperty('value1') ? dateService.dynamicDateToDateOption(item.value1) : null;
    parsedItemObj.date_value2 = item?.hasOwnProperty('value2') ? dateService.dynamicDateToDateOption(item.value2) : null;
  } else {
    parsedItemObj.date_value = item?.hasOwnProperty('value') ? dateService.dynamicDateToDateOption(item.value) : null;
  }
}

function getOperatorValue(operatorId: string, operators: Operator[]): Operator[] {
  const operator = operatorId ? operators.find(operator => operator.id === operatorId) : null;
  return operator ? [operator] : null;
}

function getOptionValue(optionId: string | boolean | number, options: Option[]): Option[] {
  const isOptionId = optionId ?? null;
  const option = isNullOrUndefined(isOptionId) ? null : options.find(option => option.id === optionId);
  return option ? [option] : null;
}
