<div id="accordion-1" class="accordion panel-group" role="tablist" aria-multiselectable="true">
  <div class="card">
    <div class="card-heading border bottom" role="tab" id="headingOne">
      <h4 class="panel-title">
        <a data-toggle="collapse" data-parent="#accordion-1" href="#collapseCoupons" aria-expanded="true" class="show no-pdd">
          <i class="fa-duotone fa-ticket"></i>
          <span class="mrg-left-10" translate>resources.tickets.modal.cards.vouchers_redeemed.title</span>
          <i class="icon ti-arrow-circle-down"></i>
        </a>
      </h4>
    </div>
    <div id="collapseCoupons" class="panel-collapse show">
      <div class="card-block no-pdd-vertical">
        <div class="table-overflow">
          <table class="table table-hover table-sm" aria-hidden="true">
            <thead>
              <tr>
                <th [translate]="'resources.tickets.modal.cards.vouchers_redeemed.table.coupon'"></th>
                <th [translate]="'resources.tickets.modal.cards.vouchers_redeemed.table.line'"></th>
                <th [translate]="'resources.tickets.modal.cards.vouchers_redeemed.table.discount'"></th>
              </tr>
            </thead>
            <ng-container *ngFor="let item of vouchersData; let i = index;">
              <tbody>
                <tr *ngIf="vouchersData">
                  <td class="col-md-8 elipsis" *ngIf="item.activity_vouchers.length > 0">
                    {{ item.name | lgMultilanguageTranslate }}
                  </td>
                  <td class="col-md-2">
                    <div class="btn-group">
                      <a type="button"
                        data-toggle="collapse"
                        class="btn btn-default"
                        [attr.data-target]="'#collapseCoupons'+i">
                        <i class="fa fa-eye font-size-16"></i>
                      </a>
                    </div>
                  </td>
                  <td class="col-md-2">
                    <a class="text-info cursor-pointer" data-toggle="collapse" [attr.data-target]="'#collapseCoupons'+i">
                      <strong>{{item.total_discount / 100 | lgcurrency: '1.2-2': this.ticketData.currency }}</strong>
                    </a>
                  </td>
                </tr>
              </tbody>
              <tbody [id]="'collapseCoupons'+i" class="collapse">
                <tr *ngFor="let detail of item.activity_vouchers">
                  <td class="col-md-8"></td>
                  <td class="col-md-2" (click)="scroll(detail.line_order)">
                    <a class="text-info cursor-pointer">
                      <strong>{{ detail.line_order }}</strong>
                    </a>
                  </td>
                  <td class="col-md-2">
                    {{ detail.discount / 100  | lgcurrency: '1.2-2': this.ticketData.currency }}
                  </td>
                </tr>
              </tbody>
            </ng-container>
            <tfoot>
              <tr>
                <td class="col-md-8">
                  <strong>Total</strong>
                </td>
                <td class="col-md-2"></td>
                <td class="col-md-2">
                  <strong>{{totalDiscount / 100 | lgcurrency: '1.2-2': this.ticketData.currency }}</strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
