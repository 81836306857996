import { Injectable } from '@angular/core';

@Injectable()
export class MatrixService {

  constructor() { }

  /*
  * @desc creates a 2D matrix from a 1D array, each element of the array being a N-tuple element in the form of [a1,a2,..,aN]. matrix cells' have a 3-tuple format [row, column, value]
  * @param {Array} array  the original 1D array
  * @param {Array} dimensions a 2-element array with the matrix's dimensions [rows, columns]
  * @param {Array} interleaving a 2-element array with the step size iteration in the array and the starting positions.
  * it defaults to [1,0], which means "all elements (step size 1) from the start (0 index)"
  * @return {Array} the matrix, which is an array of rows, every row being an array of 3-tuple cells
  */
  public createFromTupleArray(array, dimensions, tupleIndex, interleaving?) {
    let table = [], row = [];
    let numRows = dimensions[0], numColumns = dimensions[1];

    if (interleaving == 1 || interleaving == undefined) interleaving = [1, 0];

    if (numColumns * numRows !== array.length / interleaving[0]) {
      return null;
    }

    if (tupleIndex > 0) {
      for (let idxR = 0; idxR < numRows; idxR++) {
        row = [];
        for (let idxC = 0; idxC < numColumns; idxC++) {
          row.push([idxR, idxC, array[interleaving[1] + (idxR * numColumns + idxC) * interleaving[0]][tupleIndex]]);
        }
        table.push(row);
      }
    }
    //placing the if inside the for loop vs performance
    else if (tupleIndex == 0) {
      for (let idxR = 0; idxR < numRows; idxR++) {
        row = [];
        for (let idxC = 0; idxC < numColumns; idxC++) {
          //TODO: add a try for [tupleIndex]
          row.push([idxR, idxC, array[interleaving[1] + (idxR * numColumns + idxC) * interleaving[0]]]);
        }
        table.push(row);
      }
    }

    return table;
  }

  public createFromArray(array, dimensions) {
    return this.process(this.createFromTupleArray(array, dimensions, 0), {});
  }

  /*
  * @desc performs a series of actions to a matrix depending on an options parameter
  * @param {Array} table the matrix
  * @param {object} options a key-value object. every key refers to an action to be performed to the table
  * @return {Array} the (if) modified matrix
  */
  public process(table, options) {
    if (options) {

      if (options.addSumRow) this.addSumRow(table);

      if (options.addSumColumn) this.addSumColumn(table);

      if (options.addAverageColumn) this.addAverageColumn(table);

      if (options.addDivisionColumn) this.addDivisionColumn(table, options.addDivisionColumn[0], options.addDivisionColumn[1]);

      if (options.round) this.round(table);
    }
    return table;
  }

  /*
  * @desc adds a row at the end of the matrix, each cell being the sum of the column
  * @param {Array} table the matrix
  * @return {Array} the matrix with the new row
  */
  private addSumRow(table) {
    if(table && table.length){
      const numColumns = table[0].length;
      const numRows = table.length;
      const totalsPerColumn = {};
      const row = [];

      for (let idxR = 0; idxR < numRows; idxR++) {
        for (let idxC = 0; idxC < numColumns; idxC++) {
          if (totalsPerColumn[idxC] == undefined) totalsPerColumn[idxC] = 0;
          totalsPerColumn[idxC] += parseFloat(table[idxR][idxC][2]) || 0;
        }
      }
      for (let idxC = 0; idxC < numColumns; idxC++) {
        row.push([table.length, idxC, totalsPerColumn[idxC]]);
      }
      table.push(row);

      return table;
    }
  }

  /*
  * @desc adds a column at the end of the matrix, each cell being the sum of the row
  * @param {Array} table the matrix
  * @return {Array} the matrix with the new column
  */
  private addSumColumn(table) {
    if (table.length && table[0].length) {
      const numColumns = table[0].length;
      const numRows = table.length;
      let totalsPerRow;

      for (let idxR = 0; idxR < numRows; idxR++) {
        totalsPerRow = 0;
        for (let idxC = 0; idxC < numColumns; idxC++) {
          totalsPerRow += parseFloat(table[idxR][idxC][2]) || 0;
        }
        table[idxR].push([idxR, numColumns, totalsPerRow]);
      }
    }
    return table;
  }

  /*
  * @desc adds a column at the end of the matrix, each cell being the average of the row
  * @param {Array} table the matrix
  * @return {Array} the matrix with the new column
  */
  private addAverageColumn(table) {
    const numColumns = table[0].length;
    const numRows = table.length;
    let totalsPerRow;

    for (let idxR = 0; idxR < numRows; idxR++) {
      totalsPerRow = 0;
      for (let idxC = 0; idxC < numColumns; idxC++) {
        totalsPerRow += parseFloat(table[idxR][idxC][2]) || 0;
      }
      table[idxR].push([idxR, numColumns, totalsPerRow / numColumns]);
    }

    return table;
  }

  /*
  * @desc adds a column at the end, each cell being the division from dividende and divisor columnn
  * @param {Array} table the matrix
  * @param {number} dividend the index of the dividend column
  * @param {number} divisor the index of the divisor column
  * @return {Array} the matrix with the new column
  */
  private addDivisionColumn(table, dividend, divisor) {
    const numColumns = table[0].length;
    const numRows = table.length;

    for (let idxR = 0; idxR < numRows; idxR++) {
      table[idxR].push([idxR, numColumns, this.nanToZero(table[idxR][dividend][2] / table[idxR][divisor][2])]);
    }
    return table;
  }


  /*
  * @desc rounds all the elements of the table
  * @param {Array} table the matrix
  * @return {Array} the rounded matrix
  */
  private round(table) {
    const numColumns = table[0].length;
    const numRows = table.length;

    for (let idxR = 0; idxR < numRows; idxR++) {
      for (let idxC = 0; idxC < numColumns; idxC++) {
        table[idxR][idxC][2] = Math.round(parseFloat(table[idxR][idxC][2]) || 0);
      }
    }
    return table;
  }

  /*
  * @desc performs the element-wise division of two matrices
  * @param {Array} dividend
  * @param {Array} divisor
  * @result {Array} the resulting matrix
  */
  public division(dividend, divisor) {
    const numRows = dividend.length;
    const numColumns = divisor[0].length;

    const table = [];
    let row = [];

    for (let idxR = 0; idxR < numRows; idxR++) {
      row = [];
      for (let idxC = 0; idxC < numColumns; idxC++) {
        row.push([idxR, idxC, this.nanToZero(dividend[idxR][idxC][2] / divisor[idxR][idxC][2])]);
      }
      table.push(row);
    }

    return table;
  }

  /*
  * @desc gets a subset of the given matrix
  * @param {Array} matrix
  * @param {Array} rows an array of row indices to get the subset
  * @param {Array} columns an array of column indices to get the subset
  * @return {Array} subset the resulting subset matrix
  */
  public subset(matrix, rows, columns) {
    const numRows = matrix.length;
    const numColumns = matrix[0].length;

    const table = [];
    let row = [];
    for (let idxR = 0; idxR < numRows; idxR++) {
      if (rows === 'all' || rows.indexOf(idxR) >= 0) {
        row = [];
        for (let idxC = 0; idxC < numColumns; idxC++) {
          // TODO: find a proper way to indicate all rows/columns
          if (columns === 'all' || columns.indexOf(idxC) >= 0) {
            row.push([table.length, row.length, matrix[idxR][idxC][2]]);
          }
        }
        table.push(row);
      }
    }
    return table;
  }

  public nanToZero(input) {
    return (isFinite(input) && input !== null ? input : 0);
  }

  public flattenYAxisGroups(axisGroups) {
    return this.reAssignIndex(axisGroups.y.reduce((a, b) => a.concat(b), []));
  }

  public reAssignIndex(ygroup) {
    let cum = 0;
    for (let idx = 0; idx < ygroup.length; idx++) {
      ygroup[idx][2] = cum;
      cum += ygroup[idx][1];
    }
    return ygroup;
  }
  /*
  * @desc creates a 2D matrix from an array, each element of the array being a row and each element of the row a numeric value
  * @param {Array} array  the original 1D array
  * @param {Array} dimensions a 2-element array with the matrix's dimensions [rows, columns]
  * @return {Array} the matrix, which is an array of rows, every row being an array of 3-tuple cells
  */
  public createFromTable(array, dimensions) {

    const table = [];
    let row = [];
    const numRows = dimensions[0], numColumns = dimensions[1];

    for (let idxR = 0; idxR < numRows; idxR++) {
      row = [];
      for (let idxC = 0; idxC < numColumns; idxC++) {
        row.push([idxR, idxC, array[idxR][idxC]]);
      }
      table.push(row);
    }

    return table;
  }

  public transpose(matrix) {
    const numRows = matrix[0].length;
    const numColumns = matrix.length;
    const table = [];

    let row = [];

    for (let idxR = 0; idxR < numRows; idxR++) {
      row = [];
      for (let idxC = 0; idxC < numColumns; idxC++) {
        row.push([idxR, idxC, matrix[idxC][idxR][2] ]);
      }
      table.push(row);
    }

    return table;
  }

  /*
  * @desc performs the element-wise difference of two matrices
  * @param {Array} matrix1
  * @param {Array} matrix2
  * @result {Array} the resulting matrix
  */
  public difference( matrix1, matrix2 ) {
    const numRows = matrix1.length;
    const numColumns = matrix1[0].length;
    const table = [];
    let row = [];
    for (let idxR = 0; idxR < numRows; idxR++) {
      row = [];
      for (let idxC = 0; idxC < numColumns; idxC++) {
        row.push([idxR, idxC, matrix1[idxR][idxC][2] - matrix2[idxR][idxC][2] ]);
      }
      table.push(row);
    }
    return table;
  }

  /*
  * @desc creates a 2D matrix being the union of two already existing matrices. Current implementation adds table2 after table1's last column
  * @param {Array} table1 the first matrix
  * @param {Array} table2 the second matrix
  * @return {Array} the union matrix
  */
 public merge(table1, table2) {
    const table = [];
    const numRows = table1.length;
    const numColumns = table1[0].length + table2[0].length;
    let row = [];

    if (table1.length !== table2.length) {
      return null;
    }

    for ( let idxR = 0; idxR < numRows; idxR++ ) {
      row = [];
      for ( let idxC = 0; idxC < numColumns; idxC++) {
        if ( idxC < table1[0].length) {
          row.push([idxR, idxC, table1[idxR][idxC][2]])
        } else {
          row.push([idxR, idxC, table2[idxR][idxC - table1[0].length][2]]);
        }
      }
      table.push(row);
    }

    return table;
  }

  public percentageVariation(to, from) {
    const table = [];
    let row = [];
    if (to.length && from.length && from[0].length) {
      const numRows = to.length;
      const numColumns = from[0].length;
      for (let idxR = 0; idxR < numRows; idxR++) {
        row = [];
        for (let idxC = 0; idxC < numColumns; idxC++) {
          row.push([ idxR, idxC, this.nanToZero( (to[idxR][idxC][2] - from[idxR][idxC][2]) / from[idxR][idxC][2]) ]);
        }
        table.push(row);
      }
    }
    return table;
  }

  public sortByColumnIndex(table, index) {

    if (index !== table.sortCfg.index) {
      table.sortCfg.index = index;
    } else {
      table.sortCfg.sense = !table.sortCfg.sense;
    }

    let mapping = table.data.map((row, iterIndex) => ({ index: iterIndex, value: row[index][2] }));

    mapping = mapping.sort((itemA, itemB) => {
      if (itemA.value > itemB.value) { return  1; }
      if (itemA.value < itemB.value) { return  -1; }
      return 0;
    });

    if ( table.sortCfg.sense ) { mapping = mapping.reverse(); }

    const _data = mapping.map((item) => table.data[item.index]);
    const _yLabels = mapping.map((item) => table.axisLabels.y[item.index]);

    table.data = _data;
    table.axisLabels.y = _yLabels;

    return table;
  }

  processOptions(table, options) {
    if (options) {

      if (options.addSumRow) { this.addSumRow(table); }

      if (options.addSumColumn) { this.addSumColumn(table); }

      if (options.addAverageColumn) { this.addAverageColumn(table); }

      if (options.addDivisionColumn) { this.addDivisionColumn(table, options.addDivisionColumn[0], options.addDivisionColumn[1]); }

      if (options.round) { this.round(table); }
    }
    return table;
  }

  createFromCoordsArray(array, dimensions, interleaving, options?) {
    const table = this.createFromTupleArray(array, dimensions, 2, interleaving);
    return this.processOptions(table, options);
  }
}
