<div class="row mrg-top-15">
  <div class="col-md-8 mr-auto ml-auto">
    <div class="card">
      <div class="pdd-horizon-15 pdd-vertical-15">
        <app-data-table *ngIf="dataTableConfig" [config]="dataTableConfig"></app-data-table>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
