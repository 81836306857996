import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../services/question-control.service';
import { handleSelectedLocationTaxonomyTermIdsField } from '../../utils/common.utils';
import { LocationsService } from '../../../../../resources/data-warehouse/locations/locations.service';
import { MultiselectDataSource } from '../../../multiselect/multiselect';

@Component({
  selector: 'app-returns-condition',
  templateUrl: './returns-condition.component.html',
  styleUrls: ['./returns-condition.component.scss']
})
export class ReturnsConditionComponent {

  @Input('item') item: Condition;
  @Input('inputs') inputs: QuestionBase<unknown>[];
  @Input('form') form: UntypedFormGroup;

  constructor(
    private qcs: QuestionControlService,
    private locationsService: LocationsService,
    private changeDetector: ChangeDetectorRef
  ) {}

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  handleSelectedLocationTaxonomyTermIds(locationTaxonomyTermIdsSelected: MultiselectDataSource[]): void {
    handleSelectedLocationTaxonomyTermIdsField(
      locationTaxonomyTermIdsSelected,
      'location_ids',
      this.getInputConfig('location_ids'),
      this.form,
      this.changeDetector,
      this.locationsService
    );
  }
}
