import { Component, OnInit, OnDestroy } from '@angular/core';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { AbstractControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { RadioQuestion } from '../../../../shared/models/forms/question-radio';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PlansService } from '../../plans.service';
import { Subscription } from 'rxjs';
import { Plan } from '../../plan';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { DateService } from '../../../../shared/services/date.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-schedule-plan-form',
  templateUrl: './schedule-plan-form.component.html',
  styleUrls: ['./schedule-plan-form.component.css']
})
export class SchedulePlanFormComponent implements OnInit, OnDestroy {

  public inputs: QuestionBase<any>[];
  public schedulePlanForm: UntypedFormGroup;
  public subs$: Subscription[] = [];
  public id = this.route.snapshot.params.id;
  public plan: Plan;
  public loading: boolean;

  constructor(
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    private qcs: QuestionControlService,
    private translate: TranslateService,
    private planService: PlansService,
    private modalStatusService: ModalStatusService,
    private dateService: DateService,
    private modalStatus: ModalStatusService
  ) { }

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getPlanById();
        this.setInputs();
      }
    });
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.schedulePlanForm, formKey, value);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  getPlanById() {
    const getPlan$ = this.planService.getPlanById(this.id).subscribe(
      response => {
        this.plan = new Plan(response);
      }
    );
    this.subs$.push(getPlan$);
  }

  handleFormSelection() {
    if (this.schedulePlanForm.value.schedule === 'send_now') {
      this.executePlan();
    } else {
      this.schedulePlan();
    }
  }

  handleSchedule(value) {
    this.schedulePlanForm.patchValue({schedule_date: null}, {emitEvent: true});
  }

  private getValidators(): ValidatorFn[] {
    const scheduleDate = this.schedulePlanForm.get('schedule_date');
    const scheduleInput = this.schedulePlanForm.get('schedule');
    scheduleDate.setValidators([]);

    return [
      (form: UntypedFormGroup) => {
        if (scheduleInput.value === 'send_now') {
          return null;
        }
      },
      (form: UntypedFormGroup) => {
        if (scheduleInput.value === 'schedule') {
          scheduleDate.setValidators([Validators.required]);
        } else {
          scheduleDate.setValidators([]);
        }

        if (scheduleInput.value === 'schedule' && !this.dateService.isValidDate(scheduleDate.value)) {
          return this.setError(scheduleDate, {invalidDate: true});
        } else {
          form.setErrors(null);
          return null;
        }
      },
    ];
  }

  private setError(control: AbstractControl, errorHash: object): object {
    control.setErrors(errorHash);
    return errorHash;
  }

  private schedulePlan() {
    const scheduled_at = this.schedulePlanForm.get('schedule_date').value ? this.schedulePlanForm.get('schedule_date').value : null;
    this.plan['scheduled_at'] = scheduled_at;
    const messageConfirmation = this.translate.instant('resources.plans.messages.schedule');
    this.confirmationService.displayConfirmationAlert(this.plan.name, messageConfirmation, 'question').then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.loading = true;
        const schedule$ = this.planService.updatePlan(`${this.id}`, this.plan).subscribe(
          () => {
            this.planService.executePlan(this.id).subscribe(
              () => {
                this.loading = false;
                const successTitle = this.translate.instant('resources.plans.messages.schedule_success_title');
                const successDesc = this.translate.instant('resources.plans.messages.schedule_success_desc');
                this.confirmationService.displaySuccessAlert(successTitle, successDesc).catch(() => {});
                this.modalStatusService.modalStatus.emit();
                this.loading = false;
                this.closeModal();
              },
              errorData => {
                this.loading = false;
                this.confirmationService.displayErrorAlert('Error', errorData.error.error);
                this.closeModal();
              }
            );
          },
          errorData => {
            this.loading = false;
            this.confirmationService.displayErrorAlert('Error', errorData.error.error);
          }
        );
        this.subs$.push(schedule$);
      }
    }).catch(() => {});
  }

  private executePlan() {
    const scheduled_at = this.schedulePlanForm.get('schedule_date').value ? this.schedulePlanForm.get('schedule_date').value : null;
    this.plan['scheduled_at'] = scheduled_at;
    const messageConfirmation = this.translate.instant('resources.plans.messages.execute_sure');
    this.confirmationService.displayConfirmationAlert(this.plan.name, messageConfirmation, 'question').then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.loading = true;
        this.planService.updatePlan(`${this.id}`, this.plan).subscribe(
          () => {
            const execute$ = this.planService.executePlan(`${this.id}`).subscribe(
              () => {
                this.loading = false;
                const successTitle = this.translate.instant('resources.plans.messages.execute_success_title');
                const successDesc = this.translate.instant('resources.plans.messages.execute_success_desc');
                this.confirmationService.displaySuccessAlert(successTitle, successDesc).catch(() => {});
                this.modalStatusService.modalStatus.emit();
                this.loading = false;
                this.closeModal();
              },
              (errorData: HttpErrorResponse) => {
                this.loading = false;
                this.handleExecutionError(errorData);
              }
            );
            this.subs$.push(execute$);
          }
        );
      }
    }).catch(() => {});
  }

  private handleExecutionError(err: HttpErrorResponse) {
    if(err.error.hasOwnProperty('extra_info') && err.error.extra_info.length > 0) {
      this.modalStatus.resourceStatus.emit({plan: this.plan, err: err});
    } else {
      this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), err.error.error);
    }
    this.closeModal();
  }

  private scheduleOptions() {
    return [
      { id: 'send_now', name: this.translate.instant('resources.plans.fields.send_now')},
      { id: 'schedule', name: this.translate.instant('resources.plans.fields.schedule')}
    ];
  }

  private setInputs() {
    const inputs = [
      new RadioQuestion({
        key: 'schedule',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.scheduleOptions(),
        value: 'send_now'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'schedule_date',
        type: 'datetime-local',
        step: 60,
        required: true
      })
    ];

    this.inputs = inputs;
    this.schedulePlanForm = this.qcs.toFormGroup(this.inputs);
    this.schedulePlanForm.setValidators(this.getValidators());
    return this.inputs;
  }

}
