import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-card-recipe',
  templateUrl: './card-recipe.component.html',
  styleUrls: ['./card-recipe.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardRecipeComponent {

  @Input() title: string;
  @Input() text: string;
  @Input() checked = false;
  @Input() icon: string;

  toggleChecked() {
    this.checked = !this.checked;
  }
}
