import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-show-streaming-api',
  templateUrl: './show-streaming-api.component.html',
  styleUrls: ['./show-streaming-api.component.css']
})

export class ShowStreamingApiComponent implements OnInit {

  public id: number;
  public subs$: Subscription[];
  public streamingData: Object;
  public show: boolean;
  public streamingElement: boolean;

  constructor(
    private route: ActivatedRoute,
    private streamingSrv: ApiService,
  ) {
    this.subs$ = [];
  }

  ngOnInit() {
    this.getParams();
  }

  getParams() {
    const route$ = this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.getDataById();
      }
    });
    this.subs$.push(route$);
  }

  getDataById() {
    const streamings$ = this.streamingSrv.getStreamingDataById(this.id).subscribe(
      (data) => {
        this.streamingData = data;
      }
    );
    this.subs$.push(streamings$);
  }

  getFile(streaming_element, file_id) {
    const download$ = this.streamingSrv.getFile(this.id, streaming_element.id, file_id).subscribe(
      (data) => {
        window.open(data['file']);
      }
    );
    this.subs$.push(download$);
  }

  showDetails(streamingElement) {
    this.streamingElement = streamingElement;
  }

}
