<div class="modal-backdrop fade in modal-fs" id='modal-fs' tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="loyaltyScoresData">

      <div class="row">
        <div class="col-md-12">
          <div class="modal-header no-pdd height-60">
            <h1 class="mrg-left-25">
              {{loyaltyScoresData.name}}
            </h1>
            <div class="pdd-vertical-20-but-bottom">
              <button class="modal-close mrg-right-15" (click)="closeModal()">
                <i class="ti-close"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="row">
          <div class="col-md-12">
            <div class="position-relative massive-container" [ngStyle]="{ 'min-height': minHeight }">
              <div class="container container-content">
                <div class="pdd-horizon-15 pdd-vertical-15">
                  <div class="row">

                    <div class="col-md-5">
                      <div class="card">
                        <div class="card-block">
                          <div class="alert alert-warning" role="alert" *ngIf="!loyaltyScoresData.available_languages">
                            <span>{{ 'resources.scores.rewards.messages.no_available_languages' | translate }}</span>
                          </div>

                          <app-language-accordions [inputs]="loyaltyScoresInputs"
                                                   [form]="loyaltyScoresForm"
                                                   [mode]="mode"
                                                   [filteredAvailableLanguages]="loyaltyScoresData.available_languages">
                          </app-language-accordions>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-7">
                      <div class="card">
                        <div class="card-block">
                          <div class="table-overflow">
                            <table class="table table-hover" aria-hidden="true" *ngIf="loyaltyScoresData">
                              <tbody>
                                <tr *ngIf="loyaltyScoresData.id">
                                  <td class="no-border-top"><b [translate]="'resources.scores.loyalty_scores.fields.id'"></b></td>
                                  <td class="no-border-top">{{loyaltyScoresData.id}}</td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.name">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.name'"></b></td>
                                  <td>{{loyaltyScoresData.name}}</td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.description">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.description'"></b></td>
                                  <td>{{loyaltyScoresData.description}}</td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.embeddedLoyaltyScoreMoment">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.when'"></b></td>
                                  <td *ngIf="loyaltyScoresData.embeddedLoyaltyScoreMoment">
                                    <a *ngIf="roleSlug !== 'customer_service' && roleSlug !== 'store_manager'" class="pointer colorLink" (click)="goToMoment()">{{loyaltyScoresData.embeddedLoyaltyScoreMoment.name}}</a>
                                    <span *ngIf="roleSlug === 'customer_service' || roleSlug === 'store_manager'">{{loyaltyScoresData.when}}</span>
                                  </td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.awardType">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.award_type'"></b></td>
                                  <td>{{loyaltyScoresService.getAwardTypeName(loyaltyScoresData.awardType)}}</td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.awardType === 'reward'">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.award'"></b></td>
                                  <td>
                                    <a class="pointer colorLink" (click)="goToReward()">{{loyaltyScoresData.reward?.name}}</a>
                                  </td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.strategyType && loyaltyScoresData.awardType !== 'reward'">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.accumulation_type'"></b></td>
                                  <td>{{loyaltyScoresService.getStrategyTypeName(loyaltyScoresData.strategyType)}}</td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.points">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.points'"></b></td>
                                  <td>{{loyaltyScoresData.points}}</td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.pointsPricePercentage">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.points'"></b></td>
                                  <td>{{loyaltyScoresData.pointsPricePercentage}}%</td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.awardType !== 'reward'">
                                  <td class="no-border-top"><b [translate]="'resources.scores.loyalty_scores.fields.transferable'"></b></td>
                                  <td class="no-border-top" *ngIf="loyaltyScoresData.transferable" class="no-border-top"><span><i class="fa fa-check text-success"></i></span></td>
                                  <td class="no-border-top" *ngIf="!loyaltyScoresData.transferable" class="no-border-top"><span><i class="fa fa-times text-danger"></i></span></td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.availabilityDuration">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.availability_duration'"></b></td>
                                  <td>{{loyaltyScoresData.availabilityDuration}} <span *ngIf="loyaltyScoresData.availabilityDurationUnit">{{'resources.scores.loyalty_scores.fields.' + loyaltyScoresData.availabilityDurationUnit | translate }}</span></td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.activationDelay">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.available'"></b></td>
                                  <td *ngIf="loyaltyScoresData.activationDelay === 0" class="badge badge-info">{{'resources.scores.loyalty_scores.fields.activation_delay' | translate }}</td>
                                  <td *ngIf="loyaltyScoresData.activationDelay !== 0">{{ loyaltyScoresData.activationDelay }} <span *ngIf="loyaltyScoresData.activationDelayUnit">{{'resources.scores.loyalty_scores.fields.' + loyaltyScoresData.activationDelayUnit | translate }}</span></td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.acumulable">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.acumulable'"></b></td>
                                  <td class="badge badge-danger">{{'resources.scores.loyalty_scores.fields.not_acumulable' | translate}}</td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.active">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.active'"></b></td>
                                  <td *ngIf="loyaltyScoresData.active"><span><i class="fa fa-check text-success"></i></span></td>
                                  <td *ngIf="!loyaltyScoresData.active"><span><i class="fa fa-times text-danger"></i></span></td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.once">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.chances_to_win'"></b></td>
                                  <td>{{loyaltyScoresService.getOnceOptionsName(loyaltyScoresData.once)}} ({{loyaltyScoresData.onceLimit}})</td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.availableFrom">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.available_from'"></b></td>
                                  <td>{{loyaltyScoresData.availableFrom | date: 'dd/MM/yyyy'}}</td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.availableTo">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.available_to'"></b></td>
                                  <td>{{loyaltyScoresData.availableTo | date: 'dd/MM/yyyy'}}</td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.recurrenceType">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.recurrence_type'"></b></td>
                                  <td>{{loyaltyScoresData.recurrenceType}}</td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.availableDays">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.available_days'"></b></td>
                                  <td>{{loyaltyScoresData.availableDays}}</td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.countries">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.countries'"></b>&nbsp;<span class="text-semibold text-dark">({{loyaltyScoresData.countries.length}})</span></td>
                                  <td *ngIf="loyaltyScoresData.countries?.length > 0"><a data-toggle="modal" data-target="#modal-countries" class="text-semibold text-dark pointer" [translate]="'resources.scores.loyalty_scores.buttons.view_all'"></a></td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.includedLocations">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.included_locations'"></b>&nbsp;<span *ngIf="loyaltyScoresData.includedLocations.length > 0" class="text-semibold text-dark">({{loyaltyScoresData.includedLocations.length}})</span></td>
                                  <td *ngIf="loyaltyScoresData.includedLocations.length > 0"><a data-toggle="modal" data-target="#modal-locations" class="text-semibold text-dark pointer" [translate]="'resources.scores.loyalty_scores.buttons.view_all'"></a></td>
                                  <td *ngIf="loyaltyScoresData.includedLocations.length === 0" [translate]="'resources.scores.loyalty_scores.fields.redeemable'"></td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.includedFeatureLocations?.length > 0">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.included_feature_locations'"></b>&nbsp;<span class="text-semibold text-dark">({{loyaltyScoresData.includedFeatureLocations.length}})</span></td>
                                  <td><a data-toggle="modal" data-target="#modal-feature-locations" class="text-semibold text-dark pointer" [translate]="'resources.scores.loyalty_scores.buttons.view_all'"></a></td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.includedProducts?.length > 0">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.included_products'"></b>&nbsp;<span class="text-semibold text-dark">({{loyaltyScoresData.includedProducts.length}})</span></td>
                                  <td><a data-toggle="modal" data-target="#modal-included-products" class="text-semibold text-dark pointer" [translate]="'resources.scores.loyalty_scores.buttons.view_all'"></a></td>
                                </tr>

                                <tr *ngIf="loyaltyScoresData.includedFeatures?.length > 0">
                                  <td><b [translate]="'resources.scores.loyalty_scores.fields.included_features'"></b>&nbsp;<span class="text-semibold text-dark">({{loyaltyScoresData.includedFeatures.length}})</span></td>
                                  <td><a data-toggle="modal" data-target="#modal-included-features" class="text-semibold text-dark pointer" [translate]="'resources.scores.loyalty_scores.buttons.view_all'"></a></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- FOOTER -->
      <div class="modal-footer background-white">
        <button class="btn btn-info no-mrg-right mrg-top-0"
                [routerLink]="['', { outlets: { modal: ['update', 'loyalty_scores', loyaltyScoresData.id] } }]">{{'components.show.buttons.edit' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- MODAL COUNTRIES -->
<div class="modal fade" id="modal-countries">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="padding-15">
          <div class="row">
            <div class="col-md-12">
              <h5 [translate]="'resources.scores.loyalty_scores.fields.countries'"></h5>
              <div *ngIf="loyaltyScoresData">
                <div class="card">
                  <ul class="list tick bullet-success ul-style mrg-top-15 mrg-left-15">
                    <li *ngFor="let item of loyaltyScoresData.countries">
                      {{item.name}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END MODAL -->

<!-- MODAL LOCATIONS -->
<div class="modal fade" id="modal-locations">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="padding-15">
          <div class="row">
            <div class="col-md-12">
              <h5 [translate]="'resources.scores.loyalty_scores.fields.included_locations'"></h5>
              <div *ngIf="loyaltyScoresData">
                <div class="card">
                  <ul class="list tick bullet-success ul-style mrg-top-15 mrg-left-15">
                    <li *ngFor="let item of loyaltyScoresData.includedLocations">
                      {{item.name}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END MODAL -->

<!-- MODAL FEATURE LOCATIONS -->
<div class="modal fade" id="modal-feature-locations">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="padding-15">
          <div class="row">
            <div class="col-md-12">
              <h5 [translate]="'resources.scores.loyalty_scores.fields.included_feature_locations'"></h5>
              <div *ngIf="loyaltyScoresData">
                <div class="card">
                  <ul class="list tick bullet-success ul-style mrg-top-15 mrg-left-15">
                    <li *ngFor="let item of loyaltyScoresData.includedFeatureLocations">
                        ({{item.taxonomy.name}},{{item.external_id}}) {{item.name}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END MODAL -->

<!-- MODAL INCLUDED PRODUCTS -->
<div class="modal fade" id="modal-included-products">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="padding-15">
          <div class="row">
            <div class="col-md-12">
              <h5 [translate]="'resources.scores.loyalty_scores.fields.included_products'"></h5>
              <div *ngIf="loyaltyScoresData">
                <div class="card">
                  <ul class="list tick bullet-success ul-style mrg-top-15 mrg-left-15">
                    <li *ngFor="let item of loyaltyScoresData.includedProducts">
                      ({{item.id}}) {{item.name}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END MODAL -->

<!-- MODAL INCLUDED FEATURES -->
<div class="modal fade" id="modal-included-features">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="padding-15">
          <div class="row">
            <div class="col-md-12">
              <h5 [translate]="'resources.scores.loyalty_scores.fields.included_features'"></h5>
              <div *ngIf="loyaltyScoresData">
                <div class="card">
                  <ul class="list tick bullet-success ul-style mrg-top-15 mrg-left-15">
                    <li *ngFor="let item of loyaltyScoresData.includedFeatures">
                        ({{item.taxonomy.name}},{{item.external_id}}) {{item.name}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END MODAL -->
