<div class="header navbar">
  <div class="header-container">
    <ul class="nav-left">
      <li>
        <div class="side-nav-toggle"(click)="triggerEvent('side-nav-toggle')">
          <em class="icon fa-solid fa-arrows-to-dotted-line fa-rotate-90"></em>
        </div>
      </li>
    </ul>
    <ul class="nav-right" *ngIf="currentUser">
      <li *ngIf="isLocalToProduction" class="pdd-top-20 mrg-right-5">
        <span class="label label-primary"><b>{{'common.production_warning' | translate}}</b></span>
      </li>
      <li class="user-profile dropdown">
        <a href="" class="dropdown-toggle" data-toggle="dropdown">
          <img class="profile-img img-fluid" [src]="currentUser.company.logo" alt="logo">
          <div class="user-info">
            <span class="name pdd-right-5">{{currentUser.fullName}}</span>
            <i class="ti-angle-down font-size-10"></i>
          </div>
        </a>
        <ul class="dropdown-menu">
          <li style="width: 215px;">
            <a class="pointer" (click)="openModal()" *ngIf="flags.showUserPreferences">
              <em class="fa-light fa-user-gear pdd-right-10"></em>
              <span>{{'common.buttons.user_settings' | translate}}</span>
            </a>
            <a class="pointer" (click)="logout()">
              <em class="ti-power-off pdd-right-10"></em>
              <span>{{'common.buttons.logout' | translate}}</span>
            </a>
          </li>
        </ul>
      </li>

      <li class="notifications dropdown" *ngIf="roleSlug === 'admin' || roleSlug === 'owner' || roleSlug === 'manager'">
        <a class="side-panel-toggle" href="javascript:void(0);" (click)="toggleSidePanelOpen()">
          <span class="counter" *ngIf="currentUser.company.actionsWaiting.length + currentUser.company.actionsRunning.length > 0">
            {{ currentUser.company.actionsWaiting.length + currentUser.company.actionsRunning.length }}
          </span>
          <em class="fa-solid fa-bell font-size-18 text-40-black"></em>
        </a>
      </li>
      <li class="border-left notifications">
        <a href="https://loyal-guru.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noopener">
          <i class="fa-light fa-circle-question text-neo-blue"></i>
        </a>
      </li>
    </ul>
  </div>
</div>

<!-- Side Panel START -->
<div class="side-panel"
     [ngClass]="{ 'side-panel-open' : sidePanelOpen }"
     *ngIf="roleSlug === 'admin' || roleSlug === 'owner' || roleSlug === 'manager'">
  <div class="side-panel-wrapper bg-white">
    <ul class="nav nav-tabs d-flex" role="tablist">
      <li class="nav-item">
        <a class="nav-link pdd-left-15 pdd-right-5 active" href="#executing" role="tab" data-toggle="tab">
          <span class="font-size-12">{{ 'components.content-header.tabs.executing' | translate }}</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link pdd-left-5 pdd-right-5" href="#pending" role="tab" data-toggle="tab">
          <span class="font-size-12">{{ 'components.content-header.tabs.pending' | translate }}</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link pdd-left-5 pdd-right-15" href="#history" role="tab" data-toggle="tab">
          <span class="font-size-12">{{ 'components.content-header.tabs.history' | translate }}</span>
        </a>
      </li>
      <li class="panel-close">
        <a class="side-panel-toggle pdd-horizon-12" href="javascript:void(0);" (click)="toggleSidePanelOpen()">
          <i class="ti-close font-size-12"></i>
        </a>
      </li>
    </ul>
    <div class="tab-content" *ngIf="currentUser">
      <!-- executing START -->
      <div id="executing" role="tabpanel" class="tab-pane fade in active">
        <div class="chat">
          <div class="chat-user-list scrollable">
            <div class="chat-section">
              <div *ngFor="let notification of currentUser.company.actionsRunning; let i = index;">
                <div class="user-info no-pdd-left pdd-horizon-15 pdd-vertical-5"
                     [ngClass]="{ 'pdd-btm-40': i !== 0 && i === currentUser.company.actionsRunning.length - 1 }">
                  <div class="prev-chat font-size-10">
                    {{notification.fromNow}}
                    <span class="pull-right"><i class="ei-semibreve text-warning"></i></span>
                  </div>
                  <span class="user-name">
                    <span class="font-size-12 text-dark" [innerHTML]="notification.description"></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- executing END -->
      <!-- pending START -->
      <div id="pending" role="tabpanel" class="tab-pane fade">
        <div class="chat">
          <div class="chat-user-list scrollable">
            <div class="chat-section">
              <div *ngFor="let notification of currentUser.company.actionsWaiting; let i = index;">
                <div class="user-info no-pdd-left pdd-horizon-15 pdd-vertical-5"
                     [ngClass]="{ 'pdd-btm-40': i !== 0 && i === currentUser.company.actionsWaiting.length - 1 }">
                  <div class="prev-chat font-size-10">
                    {{notification.fromNow}}
                    <span class="pull-right"><i class="ei-semibreve text-success"></i></span>
                  </div>
                  <span class="user-name">
                    <span class="font-size-12 text-dark" [innerHTML]="notification.description"></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- pending END -->
      <!-- history START -->
      <div id="history" role="tabpanel" class="tab-pane fade">
        <div class="chat">
          <div class="chat-user-list scrollable">
            <div class="chat-section">
              <div *ngFor="let notification of notifications; let i = index;">
                <div class="user-info no-pdd-left pdd-horizon-15 pdd-vertical-5"
                     [ngClass]="{ 'pdd-btm-70': i !== 0 && i === notifications.length - 1 }">
                  <div class="prev-chat font-size-10">
                    {{notification.date}}
                    <span class="pull-right">
                      <i class="ei-semibreve"
                         [ngClass]="{ 'text-success': notification.type === 'success' || notification.type === 'info',
                                      'text-danger': notification.type === 'error',
                                      'text-warning': notification.type !== 'success' && notification.type !== 'info' && notification.type !== 'error' }"></i>
                    </span>
                  </div>
                  <span class="user-name font-size-12 text-dark">
                    {{notification.title}}
                  </span>
                  <p class="notification-body" [innerHTML]="notification.text"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Check all notifications START -->
        <div class="notifications">
          <a [routerLink]="['/notifications']">
            <span class="font-size-12">
              {{ 'components.content-header.buttons.check_all' | translate }}
            </span>
            <i class="ei-right-chevron pdd-left-5 font-size-10"></i>
          </a>
        </div>
        <!-- Check all notifications END -->
      </div>
      <!-- history END -->
    </div>
  </div>
</div>
<!-- Side Panel END -->

<!-- TOASTER NOTIFICATION -->
<p-toast [life]="5000">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column align-items-start" style="flex: 1">
      <div class="flex align-items-center gap-2">
        <span class="font-bold text-sm text-900" [innerHTML]="message.summary"></span>
      </div>
      <div class="overflow-anywhere font-medium text-sm my-1 text-900">
        <span [innerHTML]="message.detail"></span>
      </div>
    </div>
  </ng-template>
</p-toast>
<!--  -->

<app-user-settings #modalToggleBtn></app-user-settings>
