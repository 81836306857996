import { BigqueryJobsStepsBase } from './bigquerystep-base';

export class SegmentStep extends BigqueryJobsStepsBase {
  category = 'segment';
  segment_id: number;

  constructor(options: {} = {}) {
    super(options);
    this.category = options['category'] || 'segment';
    this.segment_id = options['segment_id'] || '';
  }

  public getPayload() {
    const payload = super.getPayload();
    const newValues = {segment_id: this.segment_id, name: this.name};
    return {...payload, ...newValues};
  }
}
