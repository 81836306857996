import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Campaign } from '../../../../shared/models/campaign/campaign';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { CampaignsService } from '../../campaigns.service';
import { FeatureFlagsService } from './../../../../shared/services/feature-flags.service';

@Component({
  selector: 'app-show-campaigns',
  templateUrl: './show-campaigns.component.html',
  styleUrls: ['./show-campaigns.component.scss']
})

export class ShowCampaignsComponent implements OnInit, OnDestroy {

  element: Campaign;
  apiEndPoint: string;
  id: number;
  route$: Subscription;
  campaign$: Subscription;
  flags = this.featureFlags.flags;

  @Output('campaignStatus') campaignStatus: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private campaignService: CampaignsService,
    private confirmationService: ConfirmationService,
    private featureFlags: FeatureFlagsService
  ) { }

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy() {
    if (this.route$) { this.route$.unsubscribe(); }
    if (this.campaign$) { this.campaign$.unsubscribe(); }
  }

  getParams() {
    this.route$ = this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'campaigns';
        this.getCampaignById();
      }
    });
  }

  getCampaignById() {
    this.campaign$ = this.campaignService.getCampaignById(this.id).pipe(
      switchMap((campaign: Campaign) => {
        if (this.flags.displayCampaignSender && campaign.sender_id) {
          return this.campaignService.getCampaignSenderById(campaign.sender_id).pipe(
            map((sender: Object) => {
              campaign.sender = sender['_embedded'].list[0].value;
              return campaign;
            })  
          );
        } else {
          return of(campaign);
        }
      })
    ).subscribe(
      campaign => {
        this.element = new Campaign(campaign);
        this.campaignStatus.emit(this.element.status);
      },
      errorCampaign => this.confirmationService.displayErrorAlert('Error', errorCampaign.error.error)
    );
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }
}
