<app-data-table-filter-analytics *ngIf="filters"
                        [slug]="filterSlug"
                        [questions]="filters"
                        [defaultQuestions]="defaultFilters"
                        #filter
                        (onSubmitEmitter)="onFilterHandler($event)">
</app-data-table-filter-analytics>

<app-loader noWrapper *ngIf="loading" />

<div class="alert alert-info" role="alert" *ngIf="!productSalesData && !loading">
  <span [translate]="'components.looker-iframe.alerts.apply_filters'"></span>
</div>

<div class="row" *ngIf="charts.productSales.axisLabels.x.length">
  <div class="col-md-12">
    <div class="card">
      <div class="card-block">
        <h5>{{currencySymbol}} {{'dashboards.results.product_sales.table.title' | translate}}</h5>
        <i class="tooltip-position ti-info-alt mrg-top-10 font-size-14 pull-right positioning"
            tooltipPosition="left"
            pTooltip="{{ 'dashboards.results.product_sales.table.tooltip' | translate }}"></i>
        <div class="table-overflow table-responsive" style="display: block">

          <table class="table-sm table-hover table-bordered w-100" aria-hidden="true">
            <thead>
              <tr>
                <th *ngIf="isAxisGroupInformed('y')"></th>
                <th></th>
                <th *ngFor="let xlabel of charts.productSales.axisLabels.x" class="border-column text-center">{{xlabel | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of charts.productSales.table; let i = index">
                <ng-container *ngFor="let ygroup of charts.productSales.flattenY">
                  <th class="pdd-left-20" *ngIf="i == ygroup[2]" [attr.rowspan]="ygroup[1]">
                    <span>{{ ygroup[0] | number:'1.0-0':'es' }}</span>
                  </th>
                </ng-container>
                <th class="text-center">
                  {{charts.productSales.axisLabels.y[i] | translate}}
                </th>
                <td *ngFor="let cell of row"
                    class="border-column text-center"
                    [ngClass]="{'border-row': i % 2 == 0, 'text-danger': hasTextDanger(cell)}">
                    <span *ngIf="cell[1] === 0">
                      {{processValue(cell[0], cell[1], cell[2])}}
                    </span>
                    <span *ngIf="cell[1] >= 1 && cell[1] <= 3">
                      {{processValue(cell[0], cell[1], cell[2] | lgcurrency:'1.2-2')}}
                    </span>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</div>
