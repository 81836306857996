import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DataTableConfiguration } from "../../../../../shared/components/data-table/data-table-cfg";
import { TranslateService } from "@ngx-translate/core";
import { RewardCodeBatchDetailMock } from "../../../../../shared/mocks/reward-code-batch-detail-mock";
import { RewardsService } from "../../rewards.service";
import { getDataTableIconPropValue } from "../../../../../shared/utils/common.utils";

@Component({
  selector: 'app-reward-codes-batch-detail',
  templateUrl: './reward-codes-batch-detail.component.html',
  styleUrls: ['./reward-codes-batch-detail.component.scss']
})

export class RewardCodesBatchDetailComponent implements OnInit {

  rewardId = this.route.snapshot.parent.parent.params.id;
  batchId = this.route.snapshot.params.id;
  dataTableConfig: DataTableConfiguration;
  batch: RewardCodeBatch;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private rewardsService: RewardsService
  ) {}

  ngOnInit() {
    this.rewardsService.getRewardBatch(this.rewardId, this.batchId).subscribe(
      (batch: RewardCodeBatch) => {
        this.batch = batch;
        if(this.batch.status === 'uploaded') this.setDataTableConfig();
      }
    );
  }

  closeModal() {
    this.router.navigate([{outlets: { modal: `show/rewards/${this.rewardId}/codes` }}]).catch(() => {});
  }

  private setDataTableConfig() {
    this.dataTableConfig = {
      isActive: true,
      isMock: false,
      mockConstant: RewardCodeBatchDetailMock,
      tokenPagination: false,
      rows: [],
      rowActions: [],
      tableActions: [
        {
          name: this.translate.instant('resources.scores.reward_code_batches.table_actions.export_csv'),
          icon: 'ei-export',
          id: 'exportCSV'
        }
      ],
      columns: [
        {
          name: this.translate.instant('resources.scores.reward_code_batches.columns.id'),
          prop: 'id'
        },
        {
          name: this.translate.instant('resources.scores.reward_code_batches.columns.code'),
          prop: 'code'
        },
        {
          name: this.translate.instant('resources.scores.reward_code_batches.columns.available'),
          prop: 'available'
        },
        {
          name: this.translate.instant('resources.scores.reward_code_batches.columns.redemption_id'),
          prop: 'redemption_id'
        }
      ],
      requestData: {
        translationResource: 'resources.scores.reward_code_batches.columns.',
        apiEndPoint: `/rewards/${this.rewardId}/code_batches/${this.batchId}/codes`,
        pageNumber: 0,
        sorting: {},
        filtering: {},
        numberPerPage: 10
      },
      tableMapping: [
        { prop: 'id', type: 'number', apiProp: 'id' },
        { prop: 'code', type: 'text', apiProp: 'code' },
        { prop: 'available', type: 'icon', apiProp: 'available', getValue: getDataTableIconPropValue('available'), icon: { dictionaryKey: 'loyalty_score_moments_protected' } },
        {
          prop: 'redemption_id',
          type: 'text',
          apiProp: 'redemption_id',
          getValue: (element: {redemption_id: number}) => {
            return {
              'customRouterLink': ['', { outlets: { modal: ['show', 'redemptions', element.redemption_id] } }],
              'parsedValue': element.redemption_id
            };
          }
        }
      ]
    };
  }
}

export interface RewardCodeBatch {
  created_at: string;
  error_message: string
  id: number;
  num_codes: number;
  reward_id: number;
  status: string;
}
