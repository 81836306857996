export class CampaignReport {
  count: number;
  contact_policy_count?: number;
  email_empty_count?: number;
  email_error_count?: number;
  email_pending_count?: number;
  error: {
    error: string;
  };
  message: string;
  phone_duplicated_count?: number;
  phone_empty_count?: number;
  phone_error_count?: number;
  phone_pending_check_count?: number;
  phone_prefix_empty_count?: number;
  push_token_empty_count?: number;
  spending_info?: unknown;
  status: string;
  validation_status: string;
  warning_motives?: unknown[];

  constructor(data: any) {
    this.count = data.count || 0;
    this.contact_policy_count = data.contact_policy_count || 0;
    this.email_empty_count = data.email_empty_count || 0;
    this.email_error_count = data.email_error_count || 0;
    this.email_pending_count = data.email_pending_count || 0;
    this.error = data && data.error ? data.error.error : null;
    this.message = data.message || undefined;
    this.phone_duplicated_count = data.phone_duplicated_count || 0;
    this.phone_empty_count = data.phone_empty_count || 0;
    this.phone_error_count = data.phone_error_count || 0;
    this.phone_pending_check_count = data.phone_pending_check_count || 0;
    this.phone_prefix_empty_count = data.phone_prefix_empty_count || 0;
    this.push_token_empty_count = data.push_token_empty_count || 0;
    this.spending_info = data.spending_info || null;
    this.status = data.status || undefined;
    this.validation_status = data.validation_status || null;
    this.warning_motives = data.warning_motives || null;
  }
}
