import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FileUploadComponent),
    }
  ]
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {

  @Input() types: string[] = [];
  @Input() multiple: boolean;

  @Input() form: UntypedFormGroup;
  @Input() formControlName: string;
  @Input() id: string;
  @Input() hidden: boolean;
  @Input() class: string;
  @Input() required: boolean;
  @Input() value: any;

  public files: File[] = [];

  constructor() { }

  ngOnInit() {
  }

  /**
   * Tells Angular how to write value from model into view
   * Using setValue method to patch form values
   * Has to be implemented when using ControlValueAccessor
   */
  writeValue() {}

  /**
   * Registers a handler function that is called when the view changes
   * Has to be implemented when using ControlValueAccessor
   */
  registerOnChange() {}

  /**
   * Registers a handler to be called when the component receives a touch event,
   * useful for knowing if the component has been focused
   * Has to be implemented when using ControlValueAccessor
   */
  registerOnTouched() {}

  onSelect(event) {
    this.files.push(...event.addedFiles);
    if (!this.multiple && this.files && this.files.length >= 2) {
      this.onRemove(this.files[0]);
    }
    this.setValue(this.files);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.setValue(this.files);
  }

  setValue(value: any) {
    const formValue = {};
    formValue[this.id] = value;
    this.form.patchValue(formValue);
  }

  getFilteredTypes() {
    const strTypes = this.types.map(type => '.' + type);
    return strTypes.join(',');
  }

  showFilteredTypes() {
    return this.getFilteredTypes();
  }

  isCsv(): boolean {
    return this.types.indexOf('csv') !== -1;
  }

}
