import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourcesService } from './resources.service';
import { MultiselectDataSource, MultiselectDataSourceable } from '../components/multiselect/multiselect';
import { map } from 'rxjs/operators';

@Injectable()
export class TagSalesService extends ResourcesService implements MultiselectDataSourceable {

  keyListName = 'values';

  constructor(http: HttpClient) {
    super(http);
  }

  public getAll() {
    const requestOptions = {
      apiEndPoint: '/segment_conditions/tag_sales',
      sorting: {},
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  public fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {
    let requestOptions = {
      apiEndPoint: '/segment_conditions/tag_sales',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  public fetchSelectedById?(id: number | string): Observable<any> {
    return this.getData({apiEndPoint: `/segment_conditions/tag_sales?id=${id}`}).pipe(
      map(item => item['values']),
      map( memberArray => {
        return memberArray.filter( x => x.id === id )[0];
      }));
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.name);
  }
}
