import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-coupon-form-section',
  templateUrl: './coupon-form-section.component.html',
})
export class CouponFormSectionComponent {

  @Input() key: string;

}
