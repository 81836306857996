<ng-container *ngIf="loadFilters; else noCompanySegmentsTagged">
  <div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="row">
        <div class="col-md-12">
          <div id="accordion-1" class="accordion panel-group" role="tablist" aria-multiselectable="true">
            <div class="panel panel-default border-radius-4">
              <div class="panel-heading" role="tab" id="headingOne">
                <div class="row justify-content-end collapse-filters-header">
                  <h4 class="panel-title">
                    <a (click)="handleCollapse()"
                      class="d-flex align-items-center no-mrg no-pdd mrg-right-15 mrg-top-10 mrg-bottom-10 font-size-14"
                      role="button"
                      data-toggle="collapse"
                      [attr.data-target]="'#'+slug"
                      href="#{{slug}}"
                      aria-expanded="true"
                    >
                      <span class="mrg-right-15">{{ (isCollapsed ? 'components.data_table_filter_analytics.toggle_button.show_filters' : 'components.data_table_filter_analytics.toggle_button.hide_filters') | translate }}</span>
                      <em title="{{(isCollapsed ? 'components.data_table_filter_analytics.toggle_button.show_filters' : 'components.data_table_filter_analytics.toggle_button.hide_filters') | translate}}"
                        class="icon icon-up-down ti-angle-up font-size-12 no-mrg"></em>
                    </a>
                  </h4>
                </div>
              </div>

              <div id="{{slug}}" class="collapse show">
                <div class="panel-body no-pdd-vertical mrg-horizon-20">
                  <div *ngIf="preStandardFilters?.length" class="row align-items-end">
                    <ng-container *ngFor="let filter of preStandardFilters">
                      <div class="col-md-2 form-group" *ngIf="!filter.hidden">
                        <app-question [question]="filter" [form]="form" [useToken]="filter.useToken" [filters]="filter.filters"></app-question>
                      </div>
                    </ng-container>
                  </div>

                  <div class="row">
                    <ng-container *ngFor="let filter of standardFilters">
                      <div class="col-md-2 form-group" *ngIf="!filter.hidden">
                        <app-question [question]="filter" [form]="form" (multiselectChanged)="handleFilterChanges($event, filter)" [useToken]="filter.useToken" [filters]="filter.filters"></app-question>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <div *ngIf="checkboxRowFilters?.length" class="panel-body no-pdd-vertical mrg-horizon-20">
                  <div class="row">
                    <ng-container *ngFor="let filter of checkboxRowFilters">
                      <div class="col-xl-4 col-lg-6 col-md-6 form-group flex align-items-center" *ngIf="!filter.hidden">
                        <app-question [question]= "filter" [form]="form" [filters]="filter.filters"></app-question>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <div *ngIf="slug === 'segment_categories_filter' || slug === 'rfm_analysis_filter'" class="panel-body mrg-horizon-20 mrg-top-10 mrg-btm-20 message-wrapper">
                  <span>*</span>
                  <span *ngIf="slug === 'segment_categories_filter'" [innerHtml]="'components.data_table_filter_analytics.messages.segment_categories_results' | translate"></span>
                  <span *ngIf="slug === 'rfm_analysis_filter'" [innerHtml]="'components.data_table_filter_analytics.messages.rfm_analysis_results' | translate"></span>
                </div>

                <div *ngIf="!isCollapsed"
                  class="panel-body mrg-horizon-20 mrg-btm-10 d-flex align-items-baseline flex-grow-1">
                  <button id="searchBtn"
                    [attr.data-cy]="'button_search'"
                    class="btn btn-info btn-icon btn-sm"
                    type="submit"
                    data-toggle="collapse"
                    [attr.data-target]="'#'+slug"
                    [attr.aria-controls]="''+slug"
                    aria-expaned="true"
                    [disabled]="disableBtns || cfg.disableSubmitBtn">
                      <em class="ei-search-alt font-size-14"></em>
                      <span [translate]="'components.data-table-filter.buttons.search'"></span>
                  </button>
                  <button id="clearBtn"
                    [attr.data-cy]="'button_clear'"
                    class="btn btn-default btn-icon btn-sm"
                    type="button"
                    (click)="resetAllFilters()">
                    <em class="ei-eraser-alt font-size-14"></em>
                    <span [translate]="'components.data-table-filter.buttons.reset'"></span>
                  </button>
                  <app-save-filter
                    *ngIf="flags.saveFiltersVisibility"
                    #saveFilter
                    [filterForm]="form"
                    (filterSelectedValues)="handleFilterSelectedValues($event)">
                  </app-save-filter>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>

<ng-template #noCompanySegmentsTagged>
  <div class="alert alert-danger fade show mrg-btm-0">
    <span>{{ 'common.untagged_company' | translate }}</span>
  </div>
</ng-template>