<app-data-table-filter-analytics #filter *ngIf="filters"
                        [questions]="filters"
                        [defaultQuestions]="defaultFilters"
                        [slug]="filterSlug"
                        (onSubmitEmitter)="onFilterHandler($event)"
                        [cfg]="filterConfig">
</app-data-table-filter-analytics>

<div class="alert alert-danger fade show mrg-btm-0" *ngIf="company && !company.isTagged()" translate>
  common.untagged_company
</div>

<div class="alert alert-info" role="alert" *ngIf="!migrationsData && company.isTagged() && !loading">
  <span [translate]="'components.looker-iframe.alerts.apply_filters'"></span>
</div>

<app-loader noWrapper *ngIf="loading" />

<div class="row" *ngIf="chartOptions && company.isTagged()">
  <div class="col-md-12">
    <div class="card">
      <div class="card-block">
        <div class="card-heading no-pdd mrg-btm-15">
          <h4>{{'dashboards.migrations.migrations_flow.table.title' | translate}}</h4>
          <i class="tooltip-position ti-info-alt mrg-top-10 font-size-14 positioning"
            tooltipPosition="left"
            pTooltip="{{ 'dashboards.migrations.migrations_flow.table.tooltip' | translate }}"></i>
        </div>

        <div class="table-responsive" style="display: block">
          <table class="table table-sm table-bordered table-hover" aria-hidden="true">
            <thead *ngIf="isAxisTitleInformed('x')">
              <tr>
                <th scope="auto" [attr.colspan]="chartOptions.axisLabels.x.length+2">
                  <div class="text-center">{{chartOptions.axisTitles.x | translate}}</div>
                </th>
              </tr>
            </thead>

            <thead>
              <tr>
                <th scope="auto"></th>
                <th scope="auto"></th>
                <th scope="auto" class="text-center" *ngFor="let xlabel of chartOptions.axisLabels.x; let i = index">{{xlabel | translate}}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr class="text-center" *ngFor="let row of chartOptions.table; let i = index" >
                <th scope="auto" [attr.rowspan]="chartOptions.axisLabels.y.length+2" *ngIf="i === 0" class="rotate-axis">
                  <div>
                    <span class="text-bold text-dark no-wrap">{{chartOptions.axisTitles.y | translate}}</span>
                  </div>
                </th>
                <th scope="auto">
                  {{chartOptions.axisLabels.y[i] | translate}}
                </th>
                <td class="text-center" *ngFor="let cell of row; let j = index" [ngStyle]="getStyle(i, j, cell[2])">{{cell[2] | number:'1.0-0':'es'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</div>
