import { MultiselectDataSourceable } from '../../components/multiselect/multiselect';
import { ValidatorFn } from '@angular/forms';

// The following QuestionBase is a fundamental question class.
// Mother of all inputs in a dynamic form.
// Do not care which type T is - as long as it is the same type everywhere you use it.

export class QuestionBase<T> {

  advanced: boolean;
  value: T;
  key: string;
  formKey: string;
  label: string;
  isDisabledOn: string;
  isHiddenOn: string;
  hidden: boolean;
  translatable: boolean;
  disabled: boolean;
  doRefresh: boolean;
  order: number;
  controlType: string;
  error: object;
  cssClasses: string;
  required: boolean;
  dataSource?: MultiselectDataSourceable;
  selectedIds?: any[];
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
  hasErrors: boolean;
  getValue?: Function;
  parseValue?: Function;
  colWidth?: string;
  options?: any[];
  type?: string;
  helpText?: string;
  errorText?: string;
  allowBlank?: boolean;
  useToken?: boolean;
  customValidators?: ValidatorFn[];
  showTooltip?: boolean;
  tooltipKey?: string;
  hideNoDateOpt?: boolean;
  hideOverlappingOpts?: boolean;
  filters?: object;
  mode?: string;
  minDate?: string;
  maxDate?: string;
  settings?: object;
  preStandard?: boolean;
  checkboxRow?: boolean;

  constructor(options: {
      advanced?: boolean,
      allowBlank?: boolean,
      checkboxRow?: boolean,
      colWidth?: string,
      controlType?: string,
      cssClasses?: string
      customValidators?: ValidatorFn[],
      dataSource?: MultiselectDataSourceable,
      disabled?: boolean,
      doRefresh?: boolean,
      error?: object,
      errorText?: string,
      filters?: object,
      formKey?: string,
      getValue?: Function,
      hasErrors?: boolean,
      helpText?: string,
      hidden?: boolean,
      hideNoDateOpt?: boolean,
      hideOverlappingOpts?: boolean,
      isDisabledOn?: string,
      isHiddenOn?: string,
      key?: string,
      label?: string,
      max?: number,
      maxDate?: string,
      min?: number,
      minDate?: string,
      mode?: string,
      options?: any[],
      order?: number,
      parseValue?: Function,
      placeholder?: string,
      preStandard?: boolean,
      required?: boolean,
      selectedIds?: any[],
      settings?: object,
      showTooltip?: boolean,
      step?: number,
      tooltipKey?: string,
      translatable?: boolean,
      type?: string,
      useToken?: boolean,
      value?: T
    } = {}) {
    this.advanced = options.advanced || false;
    this.allowBlank = options.allowBlank || true;
    this.checkboxRow = options.checkboxRow || false;
    this.colWidth = options.colWidth || 'col-md-3';
    this.controlType = options.controlType || '';
    this.cssClasses = options.cssClasses || '';
    this.customValidators = options.customValidators || [];
    this.dataSource = options.dataSource || null;
    this.disabled = options.disabled || false;
    this.doRefresh = options.doRefresh || false;
    this.error = options.error || null;
    this.errorText = options.errorText || null;
    this.filters = options.filters || null;
    this.formKey = options.formKey || '';
    this.getValue = options.getValue || null;
    this.hasErrors = options.hasErrors || false;
    this.helpText = options.helpText || null;
    this.hidden = options.hidden || false;
    this.hideNoDateOpt = options.hideNoDateOpt || false;
    this.hideOverlappingOpts = options.hideOverlappingOpts || false;
    this.isDisabledOn = options.isDisabledOn || '';
    this.isHiddenOn = options.isHiddenOn || '';
    this.key = options.key || '';
    this.label = options.label || null;
    this.max = options.max || null;
    this.maxDate = options.maxDate || null;
    this.min = options.min || null;
    this.minDate = options.minDate || null;
    this.mode = options.mode || null;
    this.options = options.options || null;
    this.order = options.order === undefined ? 1 : options.order;
    this.parseValue = options.parseValue || null;
    this.placeholder = options.placeholder || '';
    this.preStandard = options.preStandard || false;
    this.required = !!options.required;
    this.selectedIds = options.selectedIds || [];
    this.settings = options.settings || null;
    this.showTooltip = options.showTooltip || false;
    this.step = options.step || null;
    this.tooltipKey = options.tooltipKey || null;
    this.translatable = options.translatable || false;
    this.type = options.type || null;
    this.useToken = options.useToken || false;
    this.value = options.value;
  }

  isAdvanced(): boolean {
    return this.advanced === true;
  }
}
