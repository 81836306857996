<div class="container-fluid clearfix" class="mrg-top-15">
  <div class="row" *ngIf="element">
    <div class="col-md-8 ml-auto mr-auto">
      <div class="card mrg-btm-15">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-describedby="Custom entities list">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="element.id">
                  <td class="no-border-top"><strong [translate]="'resources.custom_entities.fields.id'"></strong></td>
                  <td class="no-border-top">{{element.id}}</td>
                </tr>
                <tr *ngIf="element.name">
                  <td><strong [translate]="'resources.custom_entities.fields.name'"></strong></td>
                  <td>{{element.name}}</td>
                </tr>
                <tr *ngIf="element.slug">
                  <td><strong [translate]="'resources.custom_entities.fields.slug'"></strong></td>
                  <td>{{element.slug}}</td>
                </tr>
                <tr *ngIf="element.entity_attributes">
                  <td><strong [translate]="'resources.custom_entities.fields.entity_attributes'"></strong></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <ace #editor 
              class="height-100"
              [disabled]="true"
              [mode]="'json'"
              [(value)]="element.entity_attributes"
              theme="monokai">
            </ace>
          </div>
        </div>
      </div>      
    </div>
  </div>
  <div class="row" *ngIf="customEntitiesCfg">
    <div class="col-md-8 mr-auto ml-auto">
      <div class="card">
        <div class="card-block">
          <app-data-table [config]="customEntitiesCfg"></app-data-table>
        </div>
      </div>
    </div>
  </div>
</div>
