import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MultiselectDataSource } from '../../components/multiselect/multiselect';
import { ResourcesService } from '../resources.service';

@Injectable({
  providedIn: 'root'
})
export class ConnectorActionsService extends ResourcesService {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  fetchMultiselect( searchValues?: string, page?: number) {
    let requestOptions = {
      apiEndPoint: 'connector_actions',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {},
      sorting: {}
    };

    requestOptions.pageNumber = page ?? requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }
    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `connector_actions/${id}` });
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    return new MultiselectDataSource(element.id, element.name, element);
  }

  getConnectorActionById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `connector_actions/${id}` });
  }

  createConnectorAction(payload: object) {
    return this.postResource(payload, 'connector_actions');
  }

  updateConnectorAction(payload: object, id: number) {
    return this.patchResource(payload, `connector_actions/${id}`);
  }

  deleteConnectorAction(id: number) {
    return this.deleteResource({}, `connector_actions/${id}`);
  }
}
