<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content ">

      <div class="modal-header background-white">
        <h4 class="mrg-btm-5 first-letter-upper">
          <span>{{'resources.cashback_threshold.loyalty.form.title' | translate}}</span>
        </h4>
        <button class="modal-close" (click)="closeModal()">
          <i class="ti-close"></i>
        </button>
      </div>

      <div #body class="modal-body back-color">
        <div class="card-block no-pdd">
          <form class="form-horizontal mrg-top-40 pdd-right-30" [formGroup]="cashbackForm" *ngIf="cashbackForm">

            <div class="form-group row">
              <div class="col-md-3">
                  <app-question [question]="getInputConfig('country')" (multiselectChanged)="onChangeCountry($event)" [form]="cashbackForm"></app-question>
              </div>

              <div class="col-md-3">
                  <app-question [question]="getInputConfig('score')" [form]="cashbackForm"></app-question>
              </div>

              <div class="col-md-3">
                  <app-question [question]="getInputConfig('money_representation')" [form]="cashbackForm"></app-question>
              </div>

              <div class="col-md-3">
                  <app-question [question]="getInputConfig('currency')" [form]="cashbackForm"></app-question>
              </div>
            </div>

            <div class="col-md-12 ml-auto mr-auto text-right no-pdd-right">
              <span><i class="fa fa-spinner fa-spin" *ngIf="loading"></i></span>
              <button class="btn btn-info no-mrg"
                      (click)="save()"
                      [translate]="'resources.cashback_threshold.loyalty.form.buttons.save'"
                      [disabled]="loading">
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
