<div class="row mrg-btm-15" *ngIf="inputs">

  <div [ngClass]="isAttributeWithBtOperator ? wideLayout : narrowLayout">
    <app-question [question]="getInputConfig(customerAttributeKey.DefinitionId)" [form]="form" (multiselectChanged)="handleAttributeSelected($event)"></app-question>
  </div>

  <ng-container *ngIf="form.get(customerAttributeKey.DefinitionId)?.value?.length"> 
    <ng-container *ngIf="isStringAttribute">
       <div [class]="narrowLayout">
        <app-question [question]="getInputConfig(customerAttributeKey.StringOperator)" 
                      [form]="form">
        </app-question>
      </div>
      <div [class]="narrowLayout">
        <app-question [question]="getInputConfig(customerAttributeKey.StringValue)" [form]="form"></app-question>
      </div>
    </ng-container>

    <ng-container *ngIf="isIntegerAttribute">
      <div [ngClass]="isIntegerBtOperator ? wideLayout : narrowLayout">
        <app-question [question]="getInputConfig(customerAttributeKey.IntegerOperator)" 
                      [form]="form"
                      (multiselectChanged)="handleOperatorChanges($event, customerAttributeKey.IntegerValue2)">
        </app-question>
      </div>
      <div [ngClass]="isIntegerBtOperator ? wideLayout : narrowLayout">
        <app-question [question]="getInputConfig(customerAttributeKey.IntegerValue)" [form]="form"></app-question>
      </div>

      <div [class]="wideLayout" *ngIf="isIntegerBtOperator">
        <app-question [question]="getInputConfig(customerAttributeKey.IntegerValue2)" [form]="form"></app-question>
      </div>
    </ng-container>
    
    <ng-container *ngIf="isFloatAttribute">
      <div [ngClass]="isFloatBtOperator ? wideLayout : narrowLayout">
        <app-question [question]="getInputConfig(customerAttributeKey.FloatOperator)" 
                      [form]="form"
                      (multiselectChanged)="handleOperatorChanges($event, customerAttributeKey.FloatValue2)">
        </app-question>
      </div>
      <div [ngClass]="isFloatBtOperator ? wideLayout : narrowLayout">
        <app-question [question]="getInputConfig(customerAttributeKey.FloatValue)" [form]="form"></app-question>
      </div>
      
      <div [class]="wideLayout" *ngIf="isFloatBtOperator">
        <app-question [question]="getInputConfig(customerAttributeKey.FloatValue2)" [form]="form"></app-question>
      </div>
    </ng-container>
    
    <ng-container *ngIf="isBooleanAttribute">
      <div [class]="narrowLayout">
        <app-question [question]="getInputConfig(customerAttributeKey.BooleanOperator)" 
                      [form]="form">
        </app-question>
      </div>
      <div [class]="narrowLayout">
        <app-question [question]="getInputConfig(customerAttributeKey.BooleanValue)" [form]="form"></app-question>
      </div>
    </ng-container>
    
    <ng-container *ngIf="isDatetimeAttribute">
      <div [ngClass]="isDateBtOperator ? wideLayout : narrowLayout">
        <app-question [question]="getInputConfig(customerAttributeKey.DateOperator)" 
                      [form]="form"
                      (multiselectChanged)="handleOperatorChanges($event, customerAttributeKey.DateValue2)">
        </app-question>
      </div>
      <div [ngClass]="isDateBtOperator ? wideLayout : narrowLayout">
        <app-question [question]="getInputConfig(customerAttributeKey.DateValue)" [form]="form"></app-question>
      </div>
      <div [class]="wideLayout" *ngIf="isDateBtOperator">
        <app-question [question]="getInputConfig(customerAttributeKey.DateValue2)" [form]="form"></app-question>
      </div>
    </ng-container>
  </ng-container>
</div>