import { Component, Input } from '@angular/core';
/**
 * Component that displays a loading spinner.
 * @example
 * <app-loader />
 * <app-loader noWrapper />
 * <app-loader styleClass="my-class" />
 * <app-loader styleClass="my-class" noWrapper />
 */
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  /**
   * The CSS classes to apply to the loader wrapper.
   */
  @Input() styleClass: string = '';

  /**
   * If set, the loader-wrapper class will not be applied to the container div.
   */
  @Input() set noWrapper(value: string) {
    this.loaderWrapper = value;
  }

  loaderWrapper = 'loader-wrapper ';
}