<a #modalToggleBtn data-toggle="modal" data-target="#user-settings-modal" data-backdrop="static" hidden></a>

<div class="modal fade in " id="user-settings-modal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header border bottom">
        <h4 class="mrg-btm-15">{{'resources.user_settings.title' | translate}}</h4>
        <button class="modal-close" [attr.data-cy]="'button_closeUserSettingsModal'" data-dismiss="modal">
          <em class="ti-close"></em>
        </button>
      </div>
      <div class="modal-body background-gray">
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <form [formGroup]="userSettingsForm" *ngIf="userSettingsForm">
              <!-- Personal Data -->
              <div class="card">
                <div class="card-block">
                  <h5 class="card-title d-inline">{{'resources.user_settings.fields.personal_data' | translate}}</h5>
                  <div class="row mrg-top-15">
                    <div class="col-md-6">
                      <div class="form-group">
                        <app-question [question]="getInputConfig('name')" [form]="userSettingsForm"></app-question>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <app-question [question]="getInputConfig('surname_1')" [form]="userSettingsForm"></app-question>
                      </div>
                    </div>
                  </div>
    
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <app-question [question]="getInputConfig('surname_2')" [form]="userSettingsForm"></app-question>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Account Info -->
              <div class="card">
                <div class="card-block">
                  <h5 class="card-title d-inline">{{'resources.user_settings.fields.account_info' | translate}}</h5>
                  <div class="row mrg-top-15">
                    <div class="col-md-6">
                      <div class="form-group">
                        <app-question [question]="getInputConfig('email')" [form]="userSettingsForm"></app-question>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Language -->
              <div class="card">
                <div class="card-block">
                  <h5 class="card-title d-inline">{{'resources.user_settings.fields.language' | translate}}</h5>
                  <div class="row mrg-top-15">
                    <div class="col-md-6">
                      <div class="form-group">
                        <app-question [question]="getInputConfig('user_preferred_language')" [form]="userSettingsForm"></app-question>
                      </div>
                    </div>                    
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="text-right">
          <button class="btn btn-default" [attr.data-cy]="'button_closeUserSettingsModal'" data-dismiss="modal"
            [translate]="'common.buttons.cancel'">
          </button>
          <button id="saveBtn" 
                  class="btn btn-info" 
                  [attr.data-cy]="'button_saveUserSettingsModal'" 
                  [translate]="'common.buttons.save'"
                  data-dismiss="modal"
                  [disabled]="!userSettingsForm.valid"
                  (click)="save()">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
