<div class="row" *ngIf="inputs">
  <div class="col-md-8 col-lg-8 col-xl-3">
    <app-question [question]="getInputConfig('taxonomy_slug')" [form]="form"></app-question>
  </div>

  <div class="col-md-6 col-lg-6 col-xl-3">
    <app-question [question]="getInputConfig('operator')"
                  [form]="form"
                  (multiselectChanged)="handleOperatorChanged($event)">
    </app-question>
  </div>

  <div class="col-md-2" *ngIf="!hasFormKeyWithValue('operator', 'bt')">
    <app-question [question]="getInputConfig('feature_count')" [form]="form"></app-question>
  </div>

  <div class="col-md-3" *ngIf="hasFormKeyWithValue('operator', 'lteq')">
    <app-question class="position" [question]="getInputConfig('include_zero')" [form]="form"></app-question>
  </div>

  <div class="col-md-3" *ngIf="hasFormKeyWithValue('operator', 'lt')">
    <app-question class="position" [question]="getInputConfig('include_zero')" [form]="form"></app-question>
  </div>

  <ng-container *ngIf="hasFormKeyWithValue('operator', 'bt')">
    <div class="col-md-2">
      <app-question [question]="getInputConfig('feature_count')" [form]="form"></app-question>
    </div>
    <div class="col-md-2">
      <app-question [question]="getInputConfig('feature_count_bt')" [form]="form"></app-question>
    </div>
  </ng-container>
</div>

<div class="row">
  <div class="col-md-6 col-lg-6 col-xl-3">
    <app-question [question]="getInputConfig('date_from')" [form]="form"></app-question>
  </div>
  
  <div class="col-md-6 col-lg-6 col-xl-3">
    <app-question [question]="getInputConfig('date_to')" [form]="form"></app-question>
  </div>
  
  <div *ngIf="flags.showLocationTaxonomyTermIds" class="col-md-6 col-lg-6 col-xl-3">
    <app-question [question]="getInputConfig('location_taxonomy_term_ids')" 
                  [form]="form"
                  (multiselectChanged)="handleSelectedLocationTaxonomyTermIds($event)"
    ></app-question>
  </div>

  <div class="col-md-6 col-lg-6 col-xl-3">
    <app-question [question]="getInputConfig('location_ids')" [form]="form"></app-question>
  </div>
</div>
