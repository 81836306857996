<div class="segmentation-form modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="inputs">
      <div class="row">
        <div class="col-md-12">
          <div class="modal-header">
            <div class="col-md-12">
              <h1 *ngIf="mode === 'create'" class="mrg-btm-10 no-pdd" translate>
                resources.segments.new_segment
              </h1>
              <h1 *ngIf="mode === 'update'" class="mrg-btm-10 no-pdd" translate>
                resources.segments.update_segment
              </h1>
            </div>
            <div class="pdd-vertical-20-but-bottom">
              <button [attr.data-cy]="'button_closeSegment'" class="modal-close mrg-right-25" (click)="closeModal()">
                <em class="ti-close"></em>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="historyData" class="pdd-horizon-35 mrg-btm-10">
        <h2 class="card-title mrg-btm-5 font-size-18">
          <b>{{historyData.name}}</b>
        </h2>
        <div>
          <span class="label label-info font-size-12">
            {{'resources.campaigns.fields.sent_at' | translate}}: <b>{{historyData.deliveryDate | dateTimeZone: 'DD/MM/YYYY HH:mm'}}</b>
          </span>
          <span class="label label-info font-size-12">
            {{'resources.campaigns.fields.impacted_customers' | translate}}: <b>{{historyData.customers | number: '1.0-0'}}</b>
          </span>
        </div>
      </div>

      <div class="row mrg-top-10 pdd-horizon-35">
        <div class="col-md-4">
          <app-question [question]="getInputConfig('name')" [form]="form"></app-question>
        </div>
        <div class="col-md-5">
          <app-question [question]="getInputConfig('description')" [form]="form"></app-question>
        </div>
        <div class="col-md-3">
          <app-question [question]="getInputConfig('segment_category_id')" [form]="form" [filters]="{with_privileges: true}"></app-question>
        </div>
      </div>

      <div class="modal-body back-color">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-2" *ngIf="mode !== 'readonly'">
              <div class="widget-profile-1 card sticky max-height-vh-73">
                <div class="pdd-horizon-10 pdd-vertical-10">
                  <ng-container *ngFor="let list of conditionLists">
                    <h5 class="text-semibold border bottom">
                      <em *ngIf="list.icon" [ngClass]="list.icon"></em>
                      {{ list.name | translate }}
                    </h5>
                    <div class="btn-group-justified">
                      <button *ngFor="let condition of list.children"
                              [attr.data-cy]="'button_' + (condition.key | camelCase)"
                              class="btn btn-inverse w-100 text-left label-ellipsis"
                              [ngClass]="condition.cssClass || 'btn-info'"
                              type="button"
                              [dndDraggable]="condition"
                              [dndEffectAllowed]="condition.effectAllowed"
                              [dndDisableIf]="condition.disabled || false"
                              [disabled]="condition.disabled || false"
                              (dndMoved)="onDragged(condition, null, 'copy')"
                              (dndCanceled)="onDragged(condition, null, 'none')">
                        <em class='ei-crossroads-alt'></em>
                        {{ condition.name | translate }}
                      </button>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div [ngClass]="mode === 'readonly' ? 'col-md-12' : 'col-md-10'">
              <div class="layout-padding">
                <ng-template #recursiveList let-list>
                  <!-- <div *ngFor="let item of list; let j = index; trackBy: trackByFn" -->
                  <div *ngFor="let item of list; let j = index;"
                        dndEffectAllowed="move"
                        [dndDraggable]="item"
                        (dndStart)="onDragStart($event, item, 'move')"
                        (dndMoved)="onDragged(item, list, 'move')"
                        (dndCanceled)="onDragged(item, list, 'none')"
                        (dndEnd)="onDragEnd($event, item)">
                    <!-- <div dndDragImageRef>_TEST_PERF_</div> -->
                    <div class="card no-pdd-btm no-mrg-btm"
                          [ngClass]="item.content.key === 'group' ? 'group-condition-card' : 'default-condition-card'">
                      <div class="portlet">
                        <ul class="portlet-item navbar">
                          <!-- Prevent readonly conditions from being removed from segment -->
                          <li *ngIf="mode !== 'readonly' && item.content.key !== 'calculated_values_buyer_types'">
                            <a class="btn btn-icon btn-flat btn-rounded"
                                data-toggle="card-refresh"
                                (click)="remove(item, list)">
                              <em class="ti-close"></em>
                            </a>
                          </li>
                        </ul>
                      </div>

                      <!-- No title for condition subgroups -->
                      <div *ngIf="item.content.key === 'group'"
                           class="card-heading no-pdd-btm no-mrg-btm pdd-left-30" >
                        <h6 class="card-title">
                          <em class="dnd-handle ei-crossroads-alt font-size-10 text-info" dndHandle></em>
                          {{ item.content.name }}
                        </h6>
                      </div>
                      <div class="card-heading no-pdd-btm no-mrg-btm pdd-left-30"
                           *ngIf="item.content.key !== 'group'">
                        <h5 class="card-title">
                          <em class="dnd-handle ei-crossroads-alt font-size-14 text-primary" dndHandle></em>
                          {{ item.content.name | translate }}
                        </h5>
                      </div>
                      <!-- -->

                      <div class="pdd-horizon-30 pdd-top-10">

                        <app-segment-condition *ngIf="!item.children" [item]="item" (formValidity)="handleFormValidity($event)"></app-segment-condition>

                        <div *ngIf="item.content.key === 'group'"
                              class="my-dropzone layout-padding"
                              [ngClass]="!item.children.length <= 0 ? 'empty-subgroup-dropzone' : 'populated-subgroup-dropzone'"
                              dndDragoverClass="group-drag-over"
                              (dndDrop)="onDrop($event, item)"
                              [dndDisableIf]="item.disable || false"
                              dndDropzone>
                          <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item.children }"></ng-container>
                          <!-- Remark: Define if show message info always or filter by item.children.length == 0 ? -->
                          <div *ngIf="mode !== 'readonly'" class="pdd-5">
                            <h5>
                              <em class="font-size-18 ei-download-1"></em>
                              <span [innerHtml]="'resources.segment_conditions.messages.drag_to_dropzone' | translate"></span>
                            </h5>
                          </div>
                          <!--  -->
                        </div>
                      </div>
                    </div>
                    <div style="text-align: center;">
                      <div class="dropdown inline-block">
                        <button *ngIf="list[j+1]"
                                class="btn btn-inverse btn-info btn-sm dropdown-toggle mrg-vertical-5"
                                type="button"
                                data-toggle="dropdown"
                                aria-expanded="false">
                          <span [innerText]="item.operator"></span>
                          <em class="ti-angle-down font-size-9"></em>
                        </button>
                        <ul class="dropdown-menu"
                            x-placement="bottom-start"
                            style="position: absolute;
                                    transform: translate3d(0px, 41px, 0px);
                                    top: 0px; left: 0px;
                                    will-change: transform;">
                          <li>
                            <a (click)="changeOperator(list, 'and')">{{ 'and' }}</a>
                          </li>
                          <li>
                            <a (click)="changeOperator(list, 'or')">{{ 'or' }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <div dndDropzone
                     class="my-dropzone layout-padding"
                     [ngClass]="!nestableList.length ? 'empty-dropzone flex-align-center' : 'populated-dropzone'"
                     dndDragoverClass="drag-over"
                     (dndDrop)="onDropInBaseDropZone($event, nestableList)">

                  <!-- Placeholder & info to drag without children -->
                  <div *ngIf="!nestableList.length"
                        class="text-center">
                    <div>
                      <em class="font-size-100 ei-download-1"></em>
                    </div>
                    <h4 [innerHtml]="'resources.segment_conditions.messages.drag_to_dropzone' | translate"></h4>
                  </div>
                  <!--  -->

                  <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: nestableList }"></ng-container>

                  <!-- Placeholder & info to drag with children - small -->
                  <div *ngIf="mode !== 'readonly' && nestableList.length > 0" class="pdd-10">
                    <h5>
                      <em class="font-size-18 ei-download-1"></em>
                      &nbsp;<span [innerHtml]="'resources.segment_conditions.messages.drag_to_dropzone' | translate"></span>
                    </h5>
                  </div>
                  <!--  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-wrapper">
        <div class="row channel-checks-container">
          <div class="col-4 col-xl">
            <app-question [question]="getInputConfig('active_customers')" [form]="form"></app-question>
            <span [attr.data-cy]="'span_activeCustomers'" [translate]="'resources.segments.fields.exclude_actives'"></span>
          </div>
          <ng-container *ngIf="flags.showFraudulent">
            <div class="col-4 col-xl">
              <app-question [question]="getInputConfig('exclude_fraudulent_customers')" [form]="form"></app-question>
              <span [attr.data-cy]="'span_excludeFraudulentCustomers'" [translate]="'resources.segments.fields.exclude_fraudulent_customers'"></span>
            </div>
          </ng-container>
          <div class="col-4 col-xl">
            <app-question [question]="getInputConfig('profiled_customers')" [form]="form"></app-question>
            <span [attr.data-cy]="'span_profiledCustomers'" [translate]="'resources.segments.fields.exclude_profiled'"></span>
          </div>
          <ng-container *ngIf="hasCompanyGDPRCommunications">
            <div class="col-4 col-xl">
              <app-question [question]="getInputConfig('apply_communications_consent')" [form]="form"></app-question>
              <span [attr.data-cy]="'span_applyCommunicationsConsent'" [translate]="'resources.segments.fields.apply_communications_consent'"></span>
            </div>
          </ng-container>
          <div class="col-4 col-xl">
            <app-question [question]="getInputConfig('send_email')" [form]="form"></app-question>
            <span [attr.data-cy]="'span_sendEmail'" [translate]="'resources.segments.fields.email'"></span>
          </div>
          <div class="col-4 col-xl">
            <app-question [question]="getInputConfig('send_push')" [form]="form"></app-question>
            <span [attr.data-cy]="'span_sendPush'" [translate]="'resources.segments.fields.push'"></span>
          </div>
          <div class="col-4 col-xl">
            <app-question [question]="getInputConfig('send_sms')" [form]="form" (blur)="smsChanged($event)"></app-question>
            <span [attr.data-cy]="'span_sendSms'" [translate]="'resources.segments.fields.sms'"></span>
          </div>
          <div class="col-4 col-xl" [ngClass]="{'no-input': !hasFormKeyWithValue('send_sms', true)}">
            <app-question [question]="getInputConfig('include_phone_duplicated')" [form]="form"></app-question>
            <span [attr.data-cy]="'span_includePhoneDuplicated'" [translate]="'resources.segments.fields.allow_duplicates'"></span>
          </div>
        </div>
        <div class="save-wrapper">
          <span *ngIf="loading" class="mrg-right-5">
            <em class="fa fa-spinner fa-spin"></em>
          </span>
          <span class="mrg-right-5"
                tooltipPosition="left"
                pTooltip="{{ 'resources.segments.messages.save_disabled_text' | translate }}"
                *ngIf="mode !== 'readonly' && !ableToSave && nestableList.length > 0">
            <em class="fa fa-warning text-warning"></em>
          </span>
          <button *ngIf="mode !== 'readonly'"
                  [attr.data-cy]="'button_saveSegment'"
                  type='button'
                  class="btn btn-info text-left no-mrg-right no-mrg-btm"
                  (click)="save()"
                  [disabled]="!this.form.value.name || !ableToSave && nestableList.length > 0">
            {{ 'resources.segments.buttons.save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
