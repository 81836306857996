import { QuestionBase } from './question-base';

export class FloatQuestion extends QuestionBase<number> {

  controlType = 'float';
  type: string;
  step: number;
  min: number;
  max: number;
  value: number;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || 'number';
    this.step = options['step'] || 1;
    this.min = options['min'];
    this.max = options['max'];
    this.value = options['value'] ?? null;
  }
}
