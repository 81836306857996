import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, OnDestroy, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription, distinctUntilChanged, takeUntil } from "rxjs";
import { QuestionBase } from "../../../../shared/models/forms/question-base";
import { ModalStatusService } from "../../../../shared/services/modal-status.service";
import { QuestionControlService } from "../../../../shared/services/question-control.service";
import { ConfirmationService } from "../../../../shared/services/confirmation.service";
import { ApiKey } from "../api-key";
import { ApiKeyService } from "../api-key-service";
import { TextareaQuestion } from "../../../../shared/models/forms/question-textarea";
import { CheckboxQuestion } from "../../../../shared/models/forms/question-checkbox";

@Component({
  selector: 'app-form-api-keys',
  templateUrl: './api-keys-form.component.html',
  styleUrls: ['./api-keys-form.component.scss']
})

export class ApiKeyFormComponent implements OnInit, OnDestroy {
  inputs: QuestionBase<any>[];
  apiKeysForm: FormGroup;
  id: number | string;
  missingImg = false;
  apiKey: ApiKey;

  private subs$: Subscription[] = [];
  private destroy$: Subject<void> = new Subject<void>();

  @Output('formValidity') formValidity: EventEmitter<string> = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private apiKeyService: ApiKeyService,
    private qcs: QuestionControlService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private modalStatusService: ModalStatusService,
    private router: Router
  ){}

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  sendData() {
    this.getRequest().subscribe(
      () => this.handleSuccessRequest(),
      (err: HttpErrorResponse) => {
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), err.error.error)
      }
    )
  }

  private getRequest() {
    return this.id ? this.apiKeyService.updateById(this.getPayload(), this.id) : this.apiKeyService.create(this.getPayload());
  }

  private getPayload(): ApiKey {
    return this.apiKeysForm.value;
  }

  private handleSuccessRequest(): void {
    const successTitle = this.translate.instant(this.id ? 'resources.api_keys.warnings.update_title' : 'resources.api_keys.warnings.success_title');
    const successText = this.translate.instant(this.id ? 'resources.api_keys.warnings.update_text' : 'resources.api_keys.warnings.success_text');
    this.confirmationService.displaySuccessAlert(successTitle, successText).catch(() => {});
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    this.modalStatusService.modalStatus.emit();
    this.closeModal();
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getApiKeyById();
      } else {
        this.setInputsAndForm();
      }
    });
  }

  private getApiKeyById(): void {
    this.apiKeyService.getById(this.id).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ApiKey) => this.setInputsAndForm(data),
      (errorData: HttpErrorResponse) => this.handleErrorResponse(errorData)
    );
  }

  private handleErrorResponse(err: HttpErrorResponse) {
    this.confirmationService.displayErrorAlert(
      this.translate.instant('common.error'),
      err.error.error
    )
    this.closeModal();
  }

  private setInputsAndForm(apiKey?: ApiKey):void {
    this.apiKey = apiKey;
    this.inputs = this.getInputs(apiKey);
    this.apiKeysForm = this.qcs.toFormGroup(this.inputs);
    this.formStatusChanges();
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.apiKeysForm.status));
    const formStatus$ = this.apiKeysForm.statusChanges.pipe(distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
    this.subs$.push(formStatus$);
  }

  private getInputs(apiKey?: ApiKey): QuestionBase<any>[] {
    const inputs = [
      new TextareaQuestion({
        key: 'description',
        cssClasses: 'form-control input-default',
        value: apiKey?.description,
        required: false
      }),
      new CheckboxQuestion({
        key: 'enabled',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        value: apiKey?.enabled
      })
    ]
    return inputs;
  }

  private closeModal(): void {
    this.router.navigate([{ outlets: { modal: null }}]).catch(() => {});
  }
}