import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../shared/services/resources.service';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { FloatQuestion } from '../../../shared/models/forms/question-float';
import { Observable } from 'rxjs';
import { MultiselectDataSource, MultiselectDataSourceable } from '../../../shared/components/multiselect/multiselect';
import { BehaviorSubject } from 'rxjs';
import { ScheduledTask } from '../../../shared/models/bigquery-steps/scheduled-task';
import { Option } from '../../../shared/models/common/option';

@Injectable()
export class BigqueryjobsService extends ResourcesService implements MultiselectDataSourceable {

  inputs: QuestionBase<any>[];
  optsSource = new BehaviorSubject<string>(null);
  optsSource$ = this.optsSource.asObservable();

  constructor(
    http: HttpClient,
    private translate: TranslateService,
  ) {
    super(http);
  }

  setTask() {
    this.optsSource.next('');
  }

  getAll() {
    const requestOptions = {
      apiEndPoint: '/bigquery_jobs',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {
    let requestOptions = {
      apiEndPoint: '/bigquery_jobs',
      numberPerPage: 10,
      filtering: {},
      pageNumber: 1,
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number) {
    return this.getBigqueryJobsById(id);
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.name);
  }

  getBigqueryJobsById(id: number) {
    return this.getData({ apiEndPoint: `bigquery_jobs/${id}` });
  }

  getExecutionOptions(): Option[] {
    return [
      { id: 'manual', name: this.translate.instant('resources.jobs.modal.fields.manual') },
      { id: 'hourly', name: this.translate.instant('resources.jobs.modal.fields.hourly') },
      { id: 'daily', name: this.translate.instant('resources.jobs.modal.fields.daily') },
      { id: 'weekly', name: this.translate.instant('resources.jobs.modal.fields.weekly') },
      { id: 'fortnightly', name: this.translate.instant('resources.jobs.modal.fields.fortnightly') },
      { id: 'monthly', name: this.translate.instant('resources.jobs.modal.fields.monthly') },
      { id: 'quarterly', name: this.translate.instant('resources.jobs.modal.fields.quarterly') },
      { id: 'biannually', name: this.translate.instant('resources.jobs.modal.fields.biannually') }
    ];
  }

  getExecutionHours(): Option[] {
    return [
      { id: '00:00:00', name: '00:00' },
      { id: '01:00:00', name: '01:00' },
      { id: '02:00:00', name: '02:00' },
      { id: '03:00:00', name: '03:00' },
      { id: '04:00:00', name: '04:00' },
      { id: '05:00:00', name: '05:00' },
      { id: '06:00:00', name: '06:00' },
      { id: '07:00:00', name: '07:00' },
      { id: '08:00:00', name: '08:00' },
      { id: '09:00:00', name: '09:00' },
      { id: '10:00:00', name: '10:00' },
      { id: '11:00:00', name: '11:00' },
      { id: '12:00:00', name: '12:00' },
      { id: '13:00:00', name: '13:00' },
      { id: '14:00:00', name: '14:00' },
      { id: '15:00:00', name: '15:00' },
      { id: '16:00:00', name: '16:00' },
      { id: '17:00:00', name: '17:00' },
      { id: '18:00:00', name: '18:00' },
      { id: '19:00:00', name: '19:00' },
      { id: '20:00:00', name: '20:00' },
      { id: '21:00:00', name: '21:00' },
      { id: '22:00:00', name: '22:00' },
      { id: '23:00:00', name: '23:00' }
    ];
  }

  getInputs(scheduledTask?: ScheduledTask): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        cssClasses: 'form-control input-default',
        label: 'resources.jobs.modal.fields.name',
        type: 'text',
        required: true,
        value: scheduledTask.id ? scheduledTask.name : null
      }),
      new MultiSelectQuestion({
        key: 'execution',
        label: 'resources.jobs.modal.fields.execution',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        options: this.getExecutionOptions(),
        value: scheduledTask.id ? this.getFieldOptionsValue(scheduledTask, this.getExecutionOptions(), 'execution') : []
      }),
      new FloatQuestion({
        key: 'execution_day',
        type: 'number',
        label: 'resources.jobs.modal.fields.execution_day',
        cssClasses: 'form-control input-default',
        min: 1,
        max: 28,
        step: 1,
        required: true,
        value: scheduledTask.id ? scheduledTask.executionDay : null
      }),
      new MultiSelectQuestion({
        key: 'execution_hour',
        label: 'resources.jobs.modal.fields.execution_hour',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: this.getExecutionHours(),
        required: true,
        value: scheduledTask.id ? this.getFieldOptionsValue(scheduledTask, this.getExecutionHours(), 'executionHour') : []
      })
    ];

    this.inputs = inputsArray;
    return this.inputs;
  }

  executeStep(params: object, bigqueryJobId: number) {
    return this.postResource(params, `bigquery_jobs/${bigqueryJobId}/execute_step`);
  }

  pumpTables() {
    return this.getData({ apiEndPoint: `bigquery_jobs/pump_tables` });
  }

  process(id: number) {
    return this.getData({ apiEndPoint: `bigquery_jobs/${id}/process` });
  }

  processStartStep(date: string, id: number) {
    return this.getData({ apiEndPoint: `bigquery_jobs/${id}/process?start_step=${date}` });
  }

  processDate(date: string, id: number) {
    return this.getData({ apiEndPoint: `bigquery_jobs/${id}/process?date=${date}` });
  }

  createScheduledTask(data) {
    return this.postResource(data, 'bigquery_jobs');
  }

  createMainJob(data) {
    return this.postResource(data, 'bigquery_jobs/main_jobs');
  }

  updateScheduledTask(data: object, id: number) {
    return this.patchResource(data, `bigquery_jobs/${id}`);
  }

  deleteScheduledTask(id: number) {
    return this.deleteResource({}, `bigquery_jobs/${id}`)
  }

  private getFieldOptionsValue(scheduledTask: ScheduledTask, options: Option[], fieldKey: string): Option[] {
    const option = options.find(item => item.id === scheduledTask[fieldKey]);
    return option ? [option] : [];
  }
}
