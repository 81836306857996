import { MultiSelectQuestion } from "../../../../../../shared/models/forms/question-multiselect";
import { TextboxQuestion } from "../../../../../../shared/models/forms/question-textbox";
import { CustomReport } from "../../../report.model";
import { DictionaryService } from "../../../../../../shared/services/dictionary.service";
import * as moment from 'moment';
import { PlansService } from "../../../../../plans/plans.service";
import { CampaignsService } from "../../../../../campaigns/campaigns.service";

/**
 * Defining coupons in app analysis inputs and values assignment when creating or updating a CouponsInAppAnalysis report
 * @param *Service All instantiated services in component regarding the data sourceable inputs required in form
 * @param report When null we consider this to be the initialization of inputs for a new report instead of an update.
 * @returns An array of QuestionBase representing a form input.
 */
export function getCouponsInAppAnalysisInputs(
  dictionaryService: DictionaryService,
  plansService: PlansService,
  report?: CustomReport,
  campaignsService?: CampaignsService
): (MultiSelectQuestion | TextboxQuestion)[] {

  const months = dictionaryService.dictionaries.months;
  const currentMonth = months.find(month => month.id === moment().month() + 1);
  const currentYear = moment().year();
  const reportMonth = months.find(month => month.id === moment(report?.configuration?.date_from).month() + 1)
  const yearOpts = [
    { id: currentYear, name: currentYear },
    { id: currentYear - 1, name: currentYear - 1 },
    { id: currentYear - 2, name: currentYear - 2 }
  ];
  const reportYear = yearOpts.find(year => year.id === moment(report?.configuration?.date_from).year());

  return [
    new TextboxQuestion({
      key: 'name',
      type: 'text',
      cssClasses: 'form-control input-default',
      value: report?.name
    }),
    new MultiSelectQuestion({
      cssClasses: 'form-control input-default',
      key: 'month',
      options: dictionaryService.dictionaries.months,
      value: report ? [reportMonth] : [currentMonth],
      settings: {singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false},
      required: true
    }),
    new MultiSelectQuestion({
      cssClasses: 'form-control input-default',
      key: 'year',
      options: yearOpts,
      value: report ? [reportYear] : [yearOpts[0]],
      settings: {singleSelection: true, enableCheckAll: false, showCheckbox: false,enableSearchFilter: false},
      required: true
    }),
    new MultiSelectQuestion({
      cssClasses: 'form-control input-default',
      key: 'plan_ids',
      dataSource: plansService,
      selectedIds: report?.configuration?.plan_ids?.length ? report.configuration?.plan_ids.map(item => item) : [],
      settings: { singleSelection: false, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
      required: true
    }),
    new MultiSelectQuestion({
      cssClasses: 'form-control input-default',
      key: 'campaign_ids',
      dataSource: campaignsService,
      selectedIds: report?.configuration?.campaign_ids?.length ? report.configuration?.campaign_ids.map(item => item) : [],
      settings: { singleSelection: false, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
      required: true
    }),
  ];
}