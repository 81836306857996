import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from '../../condition.service';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBase } from '../../../../models/forms/question-base';
import { MultiSelectQuestion } from '../../../../models/forms/question-multiselect';
import { TextboxQuestion } from '../../../../models/forms/question-textbox';
import { formGroupEmptyValidator, multiselectPresenceValidator } from '../../../validations.service';
import { defaultParseFormValuesToAPI, getParseOperatorValue, getParseSingleSelectOperatorIdValue, getParseValue, parseParamsValues } from '../../utils/common.utils';
import { CityCondition } from '../../../../models/segments/conditions/city-condition';
import { OperatorsService } from '../../common/operators.service';

@Injectable()
export class CityConditionService implements SegmentConditionProvider {

  inputs: QuestionBase<any>[];
  customValidators = formGroupEmptyValidator();

  private operatorOpts = this.operatorsService.getStringOperators();

  constructor(
    private translate: TranslateService,
    private operatorsService: OperatorsService
  ) {}

  getInputs(params?: CityCondition): QuestionBase<any>[] {
    const inputs = [
      new MultiSelectQuestion({
        key: 'operator',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorOpts,
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        required: true,
        getValue: getParseValue(),
        parseValue: getParseSingleSelectOperatorIdValue(this.operatorOpts),
        customValidators: [multiselectPresenceValidator]
      }),
      new TextboxQuestion({
        key: 'city',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.city'),
        type: 'text',
        required: true,
        getValue: getParseValue(false),
        parseValue: getParseValue(false)
      })
    ];

    parseParamsValues(params, inputs);
    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI(params: CityCondition): CityCondition {
    return defaultParseFormValuesToAPI(params, this.inputs);
  }

  prepareFormValuesFromAPI(params: CityCondition): CityCondition {
    const parseValues = {
      operator: getParseOperatorValue(this.operatorOpts),
      city: getParseValue(false)
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach(key => { parsedValuesObj[key] = parseValues[key](params[key]); });
    return parsedValuesObj;
  }
}
