<div class="wrapper wrapper-content animated fadeInRight" *ngIf="element">
  <div class="row">
    <div class="col-lg-12">
      <div class="alert alert-warning mrg-left-15 mrg-right-15" *ngIf="element.status === 'pending'">
        {{'resources.reports.warnings.not_generated' | translate}}
      </div>
      <div class="alert alert-warning mrg-left-15 mrg-right-15" *ngIf="element.status === 'refreshing'">
        {{'resources.reports.warnings.refreshing' | translate}}
      </div>
      <div class="alert alert-danger mrg-left-15 mrg-right-15" *ngIf="element.status === 'error'">
        {{'resources.reports.warnings.not_generated' | translate}}
      </div>
      <div class="alert alert-warning mrg-left-15 mrg-right-15" *ngIf="element.outdated">
        {{'resources.reports.warnings.outdated' | translate}}
        <button (click)="generateReport()" [disabled]="element.status === 'refreshing'"
                class="btn-sm btn-success mrg-left-15">{{'resources.reports.buttons.regenerate' | translate}}
        </button>
      </div>
      <div *ngIf="element.status == 'done'">

        <div class="row" *ngIf="isNotSpecificReportType()">
          <div class="col-md-12">
            <div class="card mrg-left-15 mrg-right-15">
              <div class="card-block">

                <h4 class="card-title">
                  <i class="ei-timer"></i>
                  {{ 'resources.reports.fields.last_generated' | translate }}
                  {{element.last_generation_at | dateTimeZone: 'DD/MM/YYYY HH:mm'}}
                </h4>

                <div class="row" *ngIf="element.report_details.length > 0">

                  <div class="col-md-4">
                    <div class="card bg-info">
                      <div class="card-block">
                        <p class="text-uppercase font-size-13">
                          <strong>{{'resources.reports.fields.total_cost' | translate}}</strong>
                        </p>
                        <h1 class="no-mrg-top font-size-30">{{element.total_cost | lgcurrency:'1.2-2'}}</h1>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="card bg-info">
                      <div class="card-block">
                        <p class="text-uppercase font-size-13">
                          <strong>{{'resources.reports.fields.total_incremental_sale' | translate}}</strong>
                        </p>
                        <h1 class="no-mrg-top font-size-30">{{element.total_incremental_sale | lgcurrency:'1.2-2'}}</h1>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="card bg-info height-90">
                      <div class="card-block">
                        <p class="text-uppercase font-size-13">
                          <strong>{{'resources.reports.fields.total_ratio' | translate}}</strong>
                        </p>
                        <h1 class="no-mrg-top font-size-30">{{element.total_ratio | number:'1.2-2':'es'}}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngFor="let detail of element.report_details">
          <app-show-report-incremental *ngIf="detail.type  === 'ReportIncremental'" [element]="detail"></app-show-report-incremental>
          <app-show-report-segment *ngIf="detail.type === 'ReportSegment'" [element]="detail" [id]="id"></app-show-report-segment>
          <app-show-report-roi-by-store *ngIf="detail.type === 'ReportRoiByStore'" [element]="detail" [lastGenerationAt]="element.last_generation_at"></app-show-report-roi-by-store>
          <app-show-report-coupons-in-app-analysis *ngIf="detail.type === 'ReportCouponsAppAnalysis'" [element]="detail" [lastGenerationAt]="element.last_generation_at"></app-show-report-coupons-in-app-analysis>
        </div>
      </div>
    </div>
  </div>
</div>
