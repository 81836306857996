import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { PlanCampaignAction } from '../../custom-campaign-plan-audience/plan-campaign-action';
import { ForecastContentMock } from '../../../../../shared/mocks/forecast-content-mock';
import { EurecatPredictionParams, EurecatService, RawPredictionParams } from '../../../../../shared/services/eurecat/eurecat.service';
import { Plan } from '../../../plan';
import { PlanCampaign } from '../../plan-campaign';
import { ProfileService } from '../../../../../profiles/profile.service';
import { getCurrencySymbol } from '@angular/common';
import { FeatureFlagsService } from '../../../../../shared/services/feature-flags.service';

@Component({
  selector: '[app-forecast-content]',
  templateUrl: './forecast-content.component.html',
  styleUrls: ['./forecast-content.component.css'],
  providers: [EurecatService]
})
export class ForecastContentComponent implements OnInit, OnDestroy {

  debug: boolean;
  subs$: Subscription[];
  hideEurecatRedemption: boolean;
  currencySymbol: string;
  flags = this.featureFlags.flags;

  @Input() plan: Plan;
  @Input() planCampaign: PlanCampaign;
  @Input('item') item: PlanCampaignAction;
  @Output('actionEmitter') actionEmitter: EventEmitter<PlanCampaignAction>;

  constructor(
    private refreshCacheService: RefreshCacheService,
    private eurecatService: EurecatService,
    private profileService: ProfileService,
    private featureFlags: FeatureFlagsService,
  ) {
    this.debug = false;
    this.hideEurecatRedemption = true;
    this.subs$ = [];
    this.actionEmitter = new EventEmitter();
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
  }

  ngOnInit() {
    this.debug ? this.enqueueDebugRequest() : this.enqueuePollingRequest();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getEurecatEstimatedRedemption() {
    this.hideEurecatRedemption = false;
    if (this.item.forecast.eurecatEstimatedRedemption) { return; }
    const eurecatParams: EurecatPredictionParams = this.eurecatService.toEurecatParams(this.getRawPredictionParams());
    const predict$ = this.eurecatService.getPrediction(eurecatParams).subscribe(
      (res: HttpResponse<any>) => {
        this.item.forecast.eurecatEstimatedRedemption = res['percent_redemption'];
      },
      (err: HttpErrorResponse) => {
        this.item.forecast.eurecatEstimatedRedemptionErr = err.error['error'];
      }
    );
    this.subs$.push(predict$);
  }

  hideEurecatEstimatedRedemption() {
    this.hideEurecatRedemption = true;
  }

  openSegment() {
    window.open(`#/show/${this.item.segment_id}/segmentsV2`, '_blank', 'noopener');
  }

  private enqueueDebugRequest() {
    this.item.loading = true;
    this.item.calculatedDataLoaded = false;
    setTimeout(() => {
      this.item.loading = false;
      this.item.calculatedDataLoaded = true;
      this.item.processForecastData(ForecastContentMock.data);
      this.actionEmitter.emit(this.item);
    }, 100 );
  }

  private enqueuePollingRequest() {
    this.item.loading = true;
    this.item.calculatedDataLoaded = false;
    const endpoint = `actions/${this.item.id}/calculate_data`;
    const body = { operation: 'forecast' };
    const actionForecast$ = this.refreshCacheService.postExpensiveData(endpoint, body).subscribe(
      (response: Object) => {
        if (!this.refreshCacheService.isRequestPending(response)) {
          this.item.loading = false;
          if (response['status'] === 'error') {
            this.item.errors.push(response['error_message']);
          } else if (response['errors'] && response['errors'].length > 0) {
            response['errors'].forEach(err => this.item.errors.push(err));
          }
          this.item.calculatedDataLoaded = true;
          this.item.processForecastData(response['data']);
          this.actionEmitter.emit(this.item);
        }
      },
      (errorData: HttpErrorResponse) => {
        this.item.loading = false;
        this.item.calculatedDataLoaded = true;
        this.item.errors.push(errorData.error.error);
        this.actionEmitter.emit(this.item);
      }
    );
    this.subs$.push(actionForecast$);
  }

  private getRawPredictionParams(): RawPredictionParams{
    return {
      discount: this.item.forecast.discount,
      max_redemptions: this.item.forecast.maxRedemptionsPerCoupon,
      feature_ids: this.planCampaign.getFeatureIds(),
      customers_count: this.item.forecast.customers,
      start_date: this.plan.available_from,
      end_date: this.plan.available_to,
      priority: this.item.couponPriority,
      printable: this.item.isCouponPrintable
    };
  }
}
