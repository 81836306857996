import { ActivatedRoute } from '@angular/router';
import { CampaignHistoriesService } from '../../campaign-histories.service';
import { CampaignsService } from '../../campaigns.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { CampaignHistory, LinkedCampaign } from '../../models/campaign-history';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-show-campaigns-history',
  templateUrl: './show-campaigns-history.component.html',
  styleUrls: ['./show-campaigns-history.component.css']
})

export class ShowCampaignsHistoryComponent implements OnInit, OnDestroy {

  public campaignHistoryId: number;
  public campaignId: number;
  public campaignHistoryData: CampaignHistory;
  public srcdoc: SafeHtml;
  public template: string;
  public subs$: Subscription[];
  public loading: boolean;

  constructor(
    private campaignsService: CampaignsService,
    private confirmationService: ConfirmationService,
    private campaignHistoriesSrv: CampaignHistoriesService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    this.subs$ = [];
  }

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach( s$ => s$.unsubscribe() ); }
  }

  getParams() {
    const route$ = this.route.params.subscribe(params => {
      if (params.campaign_id && params.campaign_history_id) {
        this.campaignId = params.campaign_id;
        this.campaignHistoryId = params.campaign_history_id;
        this.getData();
      }
    });
    this.subs$.push(route$);
  }

  private getData() {
    this.loading = true;
    const campaignHistory$ = this.campaignHistoriesSrv.getCampaignHistoryById(this.campaignId, this.campaignHistoryId).subscribe(
      (response: HttpResponse<object>) => {
        this.loading = false;
        this.campaignHistoryData = new CampaignHistory(response);
        if (this.campaignHistoryData && this.campaignHistoryData.message && this.campaignHistoryData.campaign.via === 'email') {
          this.srcdoc = this.campaignHistoryData['message'];
        }
        if (this.campaignHistoryData && this.campaignHistoryData.campaign) {
          const campaign$ = this.campaignsService.getCampaignById(this.campaignHistoryData.campaign.id).subscribe(
            (campaign: LinkedCampaign) => {
            this.campaignHistoryData.campaign = campaign;
          });
          this.subs$.push(campaign$);
        }
      },
      error => {
        this.loading = false;
        this.confirmationService.displayErrorAlert('Error', error.error.error);
      }
    );
    this.subs$.push(campaignHistory$);
  }

}
