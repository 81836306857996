import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { PromotionsService } from '../../service/promotions.service';
import { getCurrencySymbol, registerLocaleData } from '@angular/common';
import { Subscription } from 'rxjs';
import es from '@angular/common/locales/es';
import { ProfileService } from '../../../../../profiles/profile.service';

@Component({
  selector: 'app-show-promotions',
  templateUrl: './show-promotions.component.html',
  styleUrls: ['./show-promotions.component.css'],
  providers: [PromotionsService]
})

export class ShowPromotionsComponent implements OnInit, OnDestroy {

  apiEndPoint: string;
  element: any;
  id: number;
  isModal: boolean = this.router.url.indexOf('(modal:show/promotions/') >= 0;
  subs$: Subscription[] = [];

  @Output('updateEmitter') updateEmitter: EventEmitter<any>  = new EventEmitter();
  currencySymbol: any;

  constructor(
    private route: ActivatedRoute,
    private promotionService: PromotionsService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
    registerLocaleData(es);
    this.getParams();
  }

  ngOnDestroy() {
    this.subs$.forEach(sub  => {
      sub.unsubscribe();
    });
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  viewAll() {
    this.router.navigate([{ outlets: { modal: null } }]).then(() => {
      this.router.navigate([`customer-data-platform/warehouse/promotion/${this.id}/edit`]);
    });
  }

  private getParams() {
    const routeSubscription$ = this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'promotions';
        this.promotionService.getPromotionsById( this.id ).subscribe({
          next: (res) => {
            this.element = res;
            this.updateEmitter.emit([`/customer-data-platform/warehouse/promotion/${this.element.id}/edit`]);
          },
          error: (error) => {
            this.confirmationService.displayErrorAlert('Error', error);
          }
        });
      }
    });
    this.subs$.push(routeSubscription$);
  }

}
