
export class CustomersTypologyRowData {

  public typologyKey: string;
  public typologyName: string;
  public typologySpec: string;
  public customersCount: number;
  public customersPercentage: number;
  public salesPercentage: number;
  public sections: number;
  public frequency: number;
  public eurosByCustomer: number;
  public activitiesSum: number;
  public activitiesCount: number;
  public featuresCount: number;

  constructor(cfg) {
    this.calculateValues(cfg);
  }

  public calculateRowAverages(totals: CustomersTypologyRowData) {
    this.customersPercentage = totals.customersCount ? (this.customersCount) / totals.customersCount : 0;
    this.salesPercentage = totals.activitiesSum ? (this.activitiesSum) / totals.activitiesSum : 0;
  }

  private calculateValues(cfg) {
    this.typologyKey = cfg['customer_type'];
    this.typologyName = `dashboards.club.tabs.typology.types.${this.typologyKey}`;
    this.typologySpec = ``;
    this.customersCount = parseFloat(cfg['customers_count']) || 0;

    this.customersPercentage = 0;
    this.salesPercentage = 0;

    this.featuresCount = parseFloat(cfg['features_count']) || 0;
    this.sections = (this.featuresCount / this.customersCount) || 0;

    this.activitiesCount = parseFloat(cfg['activities_count']) || 0;
    this.frequency = (this.activitiesCount / this.customersCount) || 0;

    this.activitiesSum = parseFloat(cfg['activities_sum']) || 0;
    this.eurosByCustomer = (this.activitiesSum / this.customersCount) || 0;
  }
}

export const TypologyDataMock = [
  {
    customer_type: 'casual',
    customers_count: 78656,
    activities_count: 1032765,
    activities_sum: 18327650,
    features_count: 124
  },
  {
    customer_type: 'shared',
    customers_count: 38632,
    activities_count: 832765,
    activities_sum: 11320050,
    features_count: 323
  },
  {
    customer_type: 'loyal',
    customers_count: 12632,
    activities_count: 433265,
    activities_sum: 8320050,
    features_count: 567
  }
];
