import { Component, Input } from "@angular/core";
import { TicketDetail } from "../../../../../shared/models/purchases/ticket-detail";

@Component({
  selector: 'app-lines-block',
  templateUrl: './lines-block.component.html',
  styleUrls: ['./lines-block.component.scss']
})

export class LinesBlockComponent {

  @Input() roleSlug: string;
  @Input() ticketData: TicketDetail;
  @Input() currencySymbol: string;

  get isProductNameText(): boolean {
    return ['store_manager', 'customer_service', 'customer_service_mango', 'customer_service_mango_limited'].includes(this.roleSlug);
  }

  openProductInNewTab(productId: number) {
    window.open(`/#/data-warehouse/products/products/${productId}`, '_blank');
  }
}
