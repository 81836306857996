import { Component, OnInit, Input } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../services/question-control.service';

@Component({
  selector: 'app-customer-channel-status-segment-condition',
  templateUrl: './customer-channel-status-segment-condition.component.html',
  styleUrls: ['./customer-channel-status-segment-condition.component.css']
})

export class CustomerChannelStatusSegmentConditionComponent implements OnInit {

  @Input('item') item: Condition;
  @Input('inputs') inputs: QuestionBase<any>[] = [];
  @Input('form') form: UntypedFormGroup;

  constructor( private qcs: QuestionControlService ) { }

  ngOnInit() {}

  public getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  public hasFormKeyWithValue(formKey: string, value: any): boolean {
    if ( !value ) { return false; }
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

}
