import { AnalyticsService } from '../../../../shared/services/analytics/analytics.service';
import { CampaignsService } from '../../../../resources/campaigns/campaigns.service';
import { CheckboxQuestion } from '../../../models/forms/question-checkbox';
import { CountriesService } from '../../../services/countries.service';
import { CouponsService } from '../../../../resources/coupons/coupons.service';
import { CurrenciesService } from '../../../services/currencies.service';
import { CurrentCompany } from '../../../models/current-user';
import { CustomersService } from '../../../../resources/analytics/customers/customers.service';
import { DateService } from '../../../../shared/services/date.service';
import { FeaturesService } from '../../../../resources/data-warehouse/products/features.service';
import { FeatureTaxonomiesService } from '../../../../resources/data-warehouse/products/feature-taxonomies.service';
import { Inject, Injectable } from '@angular/core';
import { LocationsService } from '../../../../resources/data-warehouse/locations/locations.service';
import { LocationsTaxonomyTermsService } from '../../../../resources/data-warehouse/locations/location-taxonomy-terms.service';
import { LoyaltyScoresService } from '../../../../resources/scores/loyalty_scores/loyalty-scores.service';
import { MultiselectDataSource } from '../../multiselect/multiselect';
import { MultiSelectQuestion } from '../../../models/forms/question-multiselect';
import { Option } from '../../../../shared/models/common/option';
import { PlansService } from '../../../../resources/plans/plans.service';
import { ProductsService } from '../../../../resources/data-warehouse/products/products.service';
import { ProfilesCitiesService } from '../../../services/profiles-cities.service';
import { ProfileService } from '../../../../profiles/profile.service';
import { QuestionBase } from '../../../models/forms/question-base';
import { RewardsService } from '../../../../resources/scores/rewards/rewards.service';
import { SegmentCategoriesService } from '../../../../resources/segments/segment-categories.service';
import { SegmentsService } from '../../../../resources/segments/segments.service';
import { TagSalesExtraValuesService } from '../../../services/tag-sales-extra-values.service';
import { TextboxQuestion } from '../../../models/forms/question-textbox';
import { ToggleCheckboxQuestion } from '../../../models/forms/question-toggle-checkbox';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DynamicBaseFiltersService {

  yearOptions: Option[] = this.dateService.getComparedYearOptions(3);
  yearSingleOptions: MultiselectDataSource[] = this.dateService.getYearOptions(4);

  constructor(
    @Inject('taggedSegmentsService') private readonly taggedSegmentsService: SegmentsService,
    private readonly analyticsService: AnalyticsService,
    private readonly campaignsService: CampaignsService,
    private readonly countriesService: CountriesService,
    private readonly couponsService: CouponsService,
    private readonly currenciesService: CurrenciesService,
    private readonly customersService: CustomersService,
    private readonly dateService: DateService,
    private readonly featuresService: FeaturesService,
    private readonly featureTaxonomiesService: FeatureTaxonomiesService,
    private readonly locationsService: LocationsService,
    private readonly loyaltyScoresService: LoyaltyScoresService,
    private readonly plansService: PlansService,
    private readonly productsService: ProductsService,
    private readonly profileService: ProfileService,
    private readonly rewardsService: RewardsService,
    private readonly segmentCategoriesService: SegmentCategoriesService,
    private readonly segmentsService: SegmentsService,
    private readonly tagSalesExtraValuesService: TagSalesExtraValuesService,
    private readonly taxonomiesService: LocationsTaxonomyTermsService,
    private readonly profilesCitiesService: ProfilesCitiesService
  ) {}

  getBaseFilters(
    featuresPkService: FeaturesService, featuresSupplierECRPkService: FeaturesService, featuresBrandPkService: FeaturesService, featuresBrandECRPkService: FeaturesService, dateFrom: any, dateTo: any,
    currencySymbol: string, supplierTaxonomies: object, supplierECRTaxonomies: object, brandTaxonomies: object, brandECRTaxonomies: object
  ): QuestionBase<any>[] {

    featuresPkService.treatPkAsId = true;
    featuresSupplierECRPkService.treatPkAsId = true;
    featuresBrandPkService.treatPkAsId = true;
    featuresBrandECRPkService.treatPkAsId = true;
    this.segmentCategoriesService.onlyCategoriesWithSegments = true;
    const supplierTaxonomySlug = this.featureTaxonomiesService.getTaxonomySlug(supplierTaxonomies);
    const supplierECRTaxonomySlug = this.featureTaxonomiesService.getTaxonomySlug(supplierECRTaxonomies);
    const brandTaxonomySlug = this.featureTaxonomiesService.getTaxonomySlug(brandTaxonomies);
    const brandECRTaxonomySlug = this.featureTaxonomiesService.getTaxonomySlug(brandECRTaxonomies);
    const defaultCompanyCurrency = new CurrentCompany(this.profileService.getProfileCompany()).currency;

    return [
      new MultiSelectQuestion({
        key: 'period',
        label: 'components.looker-dashboard-filter.filters.period',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getPeriodOptions(),
        value: [this.analyticsService.getPeriodOptions()[0]]
      }),
      new MultiSelectQuestion({
        key: 'country',
        label: 'dashboards.sales.filters.country',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        options: this.countriesService.getCountries(),
        value: [this.countriesService.getCountriesById('ES')]
      }),
      new MultiSelectQuestion({
        key: 'location_taxonomy_term_ids',
        label: 'dashboards.sales.filters.location_taxonomy_terms',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.taxonomiesService
      }),
      new MultiSelectQuestion({
        key: 'location_ids',
        label: 'dashboards.sales.filters.locations',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locationsService
      }),
      new MultiSelectQuestion({
        key: 'segment_category_id',
        label: 'components.looker-dashboard-filter.filters.segment_category',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: true},
        dataSource: this.segmentCategoriesService,
        showTooltip: true,
        tooltipKey: 'components.looker-dashboard-filter.tooltips.segment_category'
      }),
      new MultiSelectQuestion({
        key: 'segment_id',
        label: 'dashboards.sales.filters.segments',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: true},
        dataSource: this.segmentsService
      }),
      new MultiSelectQuestion({
        key: 'segment2_id',
        label: 'dashboards.sales.filters.segments_2',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: true},
        dataSource: this.segmentsService
      }),
      new MultiSelectQuestion({
        key: 'tagged_segment_ids',
        label: 'components.looker-dashboard-filter.filters.tagged_segments',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableSearchFilter: true },
        dataSource: this.taggedSegmentsService,
        filters: { only_tagged: true },
        required: true
      }),
      new MultiSelectQuestion({
        key: 'feature_ids',
        label: 'dashboards.sales.filters.features',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableSearchFilter: true},
        dataSource: this.featuresService
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_from',
        label: 'dashboards.sales.filters.available_from',
        type: 'date',
        value: dateFrom,
        required: true
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_to',
        label: 'dashboards.sales.filters.available_to',
        type: 'date',
        value: dateTo,
        required: true
      }),
      new ToggleCheckboxQuestion({
        cssClasses: 'form-control input-default',
        key: 'compare_with',
        label: 'dashboards.sales.filters.compare_with',
        type: 'toggle-checkbox',
        value: false
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_from_compared',
        label: 'dashboards.sales.filters.available_from',
        type: 'date',
        value: this.dateService.calculateDate('substract', 1, 'year', dateFrom),
        required: true,
        hidden: true
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_to_compared',
        label: 'dashboards.sales.filters.available_to',
        type: 'date',
        value: this.dateService.calculateDate('substract', 1, 'year', dateTo),
        required: true,
        hidden: true
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'year',
        label: 'dashboards.results.p&l.filters.year',
        type: 'date',
        value: [{ id: this.yearOptions[0].id, name: this.yearOptions[0].name }],
        options: this.yearOptions,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'year_single',
        label: 'dashboards.categories.filters.year',
        type: 'date',
        value: [{ id: this.yearSingleOptions[0].id, name: this.yearSingleOptions[0].name }],
        options: this.yearSingleOptions,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true
      }),
      new MultiSelectQuestion({
        key: 'year_of_last_years',
        label: 'dashboards.club.tabs.constant_customers.filters.year',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.customersService.getLastYearsNumber(11),
        value: [this.customersService.getLastYearsNumber(11)[0]],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'compared_year',
        label: 'dashboards.club.tabs.constant_customers.filters.compared_year',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.customersService.getLastYearsNumber(11).slice(1, 10),
        value: [{id: moment().year() - 1, name: `${moment().year() - 1}`}],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'taxonomy_slug',
        label: 'dashboards.categories.filters.taxonomy_slug',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.featureTaxonomiesService
      }),
      new MultiSelectQuestion({
        key: 'product_ids',
        label: 'dashboards.sales.filters.products',
        cssClasses: 'form-control input-default',
        useToken: true,
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.productsService
      }),
      new MultiSelectQuestion({
        key: 'monthly_evolution',
        label: 'components.looker-dashboard-filter.filters.monthly_evolution',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getMonthlyEvolutionOpts()
      }),
      new MultiSelectQuestion({
        key: 'supplier',
        label: 'components.looker-dashboard-filter.filters.supplier',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: true },
        dataSource: featuresPkService,
        filters: {taxonomy_slug: supplierTaxonomySlug ? supplierTaxonomySlug : 'none'}
      }),
      new MultiSelectQuestion({
        key: 'supplier_ecr',
        label: 'components.looker-dashboard-filter.filters.supplier_ecr',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: true },
        dataSource: featuresSupplierECRPkService,
        filters: {taxonomy_slug: supplierECRTaxonomySlug ?? 'none'},
        hidden: true
      }),
      new MultiSelectQuestion({
        key: 'brand_pk',
        label: 'components.looker-dashboard-filter.filters.brand',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: featuresBrandPkService,
        filters: {taxonomy_slug: brandTaxonomySlug ? brandTaxonomySlug : 'none'}
      }),
      new MultiSelectQuestion({
        key: 'brand_pk_ecr',
        label: 'components.looker-dashboard-filter.filters.brand_ecr',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: featuresBrandECRPkService,
        filters: {taxonomy_slug: brandECRTaxonomySlug ?? 'none'},
        hidden: true
      }),
      new MultiSelectQuestion({
        key: 'brand',
        label: 'components.looker-dashboard-filter.filters.brand_type',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getOwnBrandOptions()
      }),
      new MultiSelectQuestion({
        key: 'unit_measure',
        label: 'components.looker-dashboard-filter.filters.unit_measure',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getUnitMeasures(currencySymbol),
        value: [this.analyticsService.getUnitMeasures(currencySymbol)[0]]
      }),
      new MultiSelectQuestion({
        key: 'unit_measure_quantity',
        label: 'components.looker-dashboard-filter.filters.unit_measure',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getUnitMeasuresQuantity(),
        value: [this.analyticsService.getUnitMeasuresQuantity()[0]]
      }),
      new MultiSelectQuestion({
        key: 'param_measure_type',
        label: 'components.looker-dashboard-filter.filters.measure_type',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getMeasureTypes(),
        required: true
      }),
      new MultiSelectQuestion({
        key: 'evolution_frequency',
        label: 'components.looker-dashboard-filter.filters.evolution',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getChartGranularityOptions(),
        value: [this.analyticsService.getChartGranularityOptions()[3]],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'tickets',
        label: 'components.looker-dashboard-filter.filters.tickets',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getTicketsOptions(),
        value: [this.analyticsService.getTicketsOptions()[0]]
      }),
      new MultiSelectQuestion({
        key: 'supplier_brand',
        label: 'components.looker-dashboard-filter.filters.supplier_brand',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getSupplierBrandOptions(),
        value: [this.analyticsService.getSupplierBrandOptions()[0]]
      }),
      new MultiSelectQuestion({
        key: 'view_brand_supplier',
        label: 'components.looker-dashboard-filter.filters.view_by_brand_supplier',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getBrandSupplierEntities(),
        value: [this.analyticsService.getBrandSupplierEntities()[0]]
      }),
      new MultiSelectQuestion({
        key: 'top_brand_supplier',
        label: 'components.looker-dashboard-filter.filters.top_brand_supplier',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getTopAllBrandSupplierEntities(),
        value: [this.analyticsService.getTopAllBrandSupplierEntities()[0]]
      }),
      new MultiSelectQuestion({
        key: 'evolution_chart',
        label: 'components.looker-dashboard-filter.filters.evolution_chart',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getEvolutionChartOptions(),
        value: [this.analyticsService.getEvolutionChartOptions()[0]]
      }),
      new MultiSelectQuestion({
        key: 'chart_metric',
        label: 'components.looker-dashboard-filter.filters.chart_metric',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getChartMetricOptions(),
        value: [this.analyticsService.getChartMetricOptions()[0]],
      }),
      new MultiSelectQuestion({
        key: 'chart_metric_type',
        label: 'components.looker-dashboard-filter.filters.chart_metric',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getChartMetricTypeOptions(),
        value: [this.analyticsService.getChartMetricTypeOptions()[0]],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'chart_metric_sales_customers_purchases',
        label: 'components.looker-dashboard-filter.filters.chart_metric',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getChartMetricSalesCustomersPurchasesOptions(),
        value: [this.analyticsService.getChartMetricSalesCustomersPurchasesOptions()[0]],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'metrics_segments_by_cust_typology',
        label: 'components.looker-dashboard-filter.filters.chart_metric',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getChartMetricSegmentsCustomerTypeOptions(),
        value: [this.analyticsService.getChartMetricSegmentsCustomerTypeOptions()[0]],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'chart_metric_supplier_brand',
        label: 'components.looker-dashboard-filter.filters.chart_metric',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getChartMetricSupplierBrandOptions(),
        value: [this.analyticsService.getChartMetricSupplierBrandOptions()[0]],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'chart_metricas',
        label: 'components.looker-dashboard-filter.filters.chart_metric',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getChartMetricasOptions(),
        value: [this.analyticsService.getChartMetricasOptions()[0]],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'chart_metrics_with_own_brand',
        label: 'components.looker-dashboard-filter.filters.chart_metric',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getChartMetricsWithOwnBrandOptions(),
        value: [this.analyticsService.getChartMetricsWithOwnBrandOptions()[0]],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'day_of_week',
        label: 'components.looker-dashboard-filter.filters.day_of_week',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        options: this.analyticsService.getWeekdaysOptions(),
        value: [...this.analyticsService.getWeekdaysOptions()],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'chart_granularity',
        label: 'components.looker-dashboard-filter.filters.chart_granularity',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getChartGranularityOptions(),
        value: [this.analyticsService.getChartGranularityOptions()[3]],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'evo_granularity',
        label: 'components.looker-dashboard-filter.filters.evo_granularity',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getChartGranularityOptions(),
        value: [this.analyticsService.getChartGranularityOptions()[0]],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'dimension',
        label: 'components.looker-dashboard-filter.filters.dimension',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getDimensionOptions(),
        value: [this.analyticsService.getDimensionOptions()[0]],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'dimension_store_product',
        label: 'components.looker-dashboard-filter.filters.dimension',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getDimensionStoreProductOptions(),
        value: [this.analyticsService.getDimensionStoreProductOptions()[0]]
      }),
      new MultiSelectQuestion({
        key: 'metrics_segment_drill_down',
        label: 'components.looker-dashboard-filter.filters.metrics_segment_drill_down',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getSalesMetricsOptions(),
        value: [this.analyticsService.getSalesMetricsOptions()[0]],
        required: true
      }),
      new CheckboxQuestion({
        key: 'only_comparable_stores',
        label: 'components.looker-dashboard-filter.filters.only_comparable_stores',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        value: false,
        checkboxRow: true,
        tooltipKey: 'components.looker-dashboard-filter.tooltips.only_comparable_stores'
      }),
      new MultiSelectQuestion({
        key: 'tag_date',
        label: 'components.looker-dashboard-filter.filters.tag_date',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: false },
        dataSource: this.tagSalesExtraValuesService
      }),
      new MultiSelectQuestion({
        key: 'tag_date_origin',
        label: 'components.looker-dashboard-filter.filters.tag_date_origin',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: false },
        dataSource: this.tagSalesExtraValuesService
      }),
      new MultiSelectQuestion({
        key: 'tag_date_destination',
        label: 'components.looker-dashboard-filter.filters.tag_date_destination',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: false },
        dataSource: this.tagSalesExtraValuesService
      }),
      new MultiSelectQuestion({
        key: 'currency',
        label: 'components.looker-dashboard-filter.filters.currency',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.currenciesService.setActiveSlugFilter() && this.currenciesService,
        selectedIds: defaultCompanyCurrency ? [defaultCompanyCurrency] : []
      }),
      new CheckboxQuestion({
        key: 'company_currency',
        label: 'components.looker-dashboard-filter.filters.company_currency',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        value: true,
        checkboxRow: true,
        tooltipKey: 'components.looker-dashboard-filter.tooltips.company_currency'
      }),
      new MultiSelectQuestion({
        key: 'rules',
        label: 'components.looker-dashboard-filter.filters.rules',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableSearchFilter: true },
        dataSource: this.loyaltyScoresService,
        showTooltip: true,
        tooltipKey: 'components.looker-dashboard-filter.tooltips.rules'
      }),
      new MultiSelectQuestion({
        key: 'rewards',
        label: 'components.looker-dashboard-filter.filters.rewards',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.rewardsService
      }),
      new MultiSelectQuestion({
        key: 'id_ean_product',
        label: 'components.looker-dashboard-filter.filters.id_ean_product',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: false },
        options: this.analyticsService.getIdEanProductOptions(),
        value: [this.analyticsService.getIdEanProductOptions()[0]]
      }),
      new MultiSelectQuestion({
        key: 'plan_ids',
        label: 'components.looker-dashboard-filter.filters.plan_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.plansService
      }),
      new MultiSelectQuestion({
        key: 'campaign_ids',
        label: 'components.looker-dashboard-filter.filters.campaign_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.campaignsService
      }),
      new MultiSelectQuestion({
        key: 'year_month_date',
        label: 'components.looker-dashboard-filter.filters.chart_granularity',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: false },
        options: this.analyticsService.getYearMonthDateOptions(),
        value: [this.analyticsService.getYearMonthDateOptions()[1]]
      }),
      new TextboxQuestion({
        key: 'postal_code',
        label: 'components.looker-dashboard-filter.filters.postal_code',
        type: 'text',
        cssClasses: 'form-control input-default',
        showTooltip: true,
        tooltipKey: 'components.looker-dashboard-filter.tooltips.postal_code'
      }),
      new MultiSelectQuestion({
        key: 'postal_code_city',
        label: 'components.looker-dashboard-filter.filters.postal_code_city',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: false },
        options: this.analyticsService.getPostalCodeCityOptions(),
        value: [this.analyticsService.getPostalCodeCityOptions()[0]]
      }),
      new MultiSelectQuestion({
        key: 'top_locations',
        label: 'components.looker-dashboard-filter.filters.top_locations',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getTopAllLocationsOptions(),
        value: [this.analyticsService.getTopAllLocationsOptions()[0]]
      }),
      new MultiSelectQuestion({
        key: 'coupon_ids',
        label: 'components.looker-dashboard-filter.filters.coupon_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.couponsService
      }),
      new MultiSelectQuestion({
        key: 'profiles_cities_ids',
        label: 'components.looker-dashboard-filter.filters.profiles_cities',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableSearchFilter: true },
        dataSource: this.profilesCitiesService
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'entity',
        label: 'components.looker-dashboard-filter.filters.entity',
        value: [this.analyticsService.getEntityOptions()[0]],
        options: this.analyticsService.getEntityOptions(),
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: true, enableSearchFilter: false }
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'method',
        label: 'components.looker-dashboard-filter.filters.method',
        value: [this.analyticsService.getMethodOptions()[0]],
        options: this.analyticsService.getMethodOptions(),
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: true, enableSearchFilter: false }
      }),
    ];
  }
}