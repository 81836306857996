import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { InfoPanelComponent } from '../components/info-panel/info-panel.component';

@Directive({
  selector: '[openInfoPanel]'
})
export class OpenInfoPanelDirective implements OnInit, OnDestroy {
  @Input('openInfoPanel') infoPanel: InfoPanelComponent;

  private destroy$ = new Subject<void>();

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.infoPanel.alertClosed.pipe(
      tap(() => this.updateButtonVisibility()),
      takeUntil(this.destroy$)
    ).subscribe();

    this.updateButtonVisibility();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('click') onClick() {
    this.infoPanel.showAlert = true;
    this.updateButtonVisibility();
  }

  private updateButtonVisibility(): void {
    const isHidden = this.infoPanel.showAlert;
    if (isHidden) {
      this.renderer.addClass(this.el.nativeElement, 'hidden');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'hidden');
    }
  }
}