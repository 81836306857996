import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../services/question-control.service';
import { LocationsService } from '../../../../../resources/data-warehouse/locations/locations.service';
import { MultiselectDataSource } from '../../../multiselect/multiselect';
import { FeatureFlagsService } from '../../../../../shared/services/feature-flags.service';
import { handleSelectedLocationTaxonomyTermIdsField } from '../../utils/common.utils';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-client-usual-location-condition',
  templateUrl: './client-usual-location-condition.component.html',
  styleUrls: ['./client-usual-location-condition.component.scss']
})
export class ClientUsualLocationConditionComponent implements OnInit {

  flags = this.featureFlags.flags;
  showRequiredLocationsAlert: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();
  
  @Input('item') item: Condition;
  @Input('inputs') inputs: QuestionBase<any>[];
  @Input('form') form: UntypedFormGroup;

  constructor(
    private qcs: QuestionControlService,
    private locationsService: LocationsService,
    private changeDetector: ChangeDetectorRef,
    private featureFlags: FeatureFlagsService
  ) {}
  
  ngOnInit(): void {
    this.handleShowRequiredLocationsAlertText();
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }
  
  handleLocationTaxonomyTermIdsSelected(locationTaxonomyTermIdsSelected: MultiselectDataSource[]): void {
    handleSelectedLocationTaxonomyTermIdsField(
      locationTaxonomyTermIdsSelected,
      'values',
      this.getInputConfig('values'),
      this.form,
      this.changeDetector,
      this.locationsService
    );
  }
  
  private handleShowRequiredLocationsAlertText(): void {
    if (this.flags.showLocationTaxonomyTermIds) {
      if (!this.item?.content?.id) {
        this.showRequiredLocationsAlert = true;
      }

      this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((formValues) => {
        const hasNullLocationTaxonomyTermIdsValue = !!(!formValues?.location_taxonomy_term_ids?.length || isNullOrUndefined(formValues?.location_taxonomy_term_ids));
        const hasNullValuesValue = !!(!formValues?.values?.length || isNullOrUndefined(formValues?.values));
        this.showRequiredLocationsAlert = hasNullLocationTaxonomyTermIdsValue && hasNullValuesValue;
      });
    }
  }
}
