<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true" *ngIf="element">
              <tbody>
                <tr>
                  <td class="no-border-top"><b [translate]="'resources.scores.loyalty_score_moments.show.fields.id'"></b></td>
                  <td class="no-border-top">{{element.id}}</td>
                </tr>
                <tr>
                  <td><b [translate]="'resources.scores.loyalty_score_moments.show.fields.name'"></b></td>
                  <td>{{element.name}}</td>
                </tr>
                <tr>
                  <td><b [translate]="'resources.scores.loyalty_score_moments.show.fields.slug'"></b></td>
                  <td>{{element.slug}}</td>
                </tr>
                <tr>
                  <td><b [translate]="'resources.scores.loyalty_score_moments.show.fields.protected'"></b></td>
                  <td *ngIf="element.protected"><span><i class="fa fa-check text-success"></i></span></td>
                  <td *ngIf="!element.protected"><span><i class="fa fa-times text-danger"></i></span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
