import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DataTableFilterComponent } from '../../../shared/components/data-table-filter/data-table-filter.component';
import { DataTableConfiguration } from '../../../shared/components/data-table/data-table-cfg';
import { DataTableComponent } from '../../../shared/components/data-table/data-table.component';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { CsvExportService } from '../../../shared/services/csv-export.service';
import { CouponsService } from '../../coupons/coupons.service';
import { CheckboxQuestion } from '../../../shared/models/forms/question-checkbox';

@Component({
  selector: 'app-tab-coupons',
  templateUrl: './tab-coupons.component.html'
})
export class TabCouponsComponent implements OnInit, OnDestroy {

  public title = this.translate.instant('resources.coupons.title');
  public filters: QuestionBase<any>[];
  public dataTableConfig: DataTableConfiguration;
  public subs$: Subscription[];

  @ViewChild('couponsFilter') couponsFilter: DataTableFilterComponent;
  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private couponsService: CouponsService,
    private confirmationService: ConfirmationService,
    private csvExportService: CsvExportService
  ) {
    this.subs$ = [];
  }

  ngOnInit() {
    this.setDataTableConfig();
    this.getFiltersSelectOptions();
  }

  ngOnDestroy() {
    this.subs$.forEach(sub$ => sub$.unsubscribe());
  }

  onFilterHandler(filterFormValue) {
    this.dataTable.onFilter(filterFormValue);
  }

  getFiltersSelectOptions() {
    this.setFilters();
  }

  private setDataTableConfig() {
    this.dataTableConfig = {
      isActive: true,
      createButton: {
        label: this.translate.instant('resources.coupons.buttons.coupons'),
        redirectTo: [{outlets: { modal: 'new/coupon' }}]
      },
      tableActions: [
        {
          name: this.translate.instant('resources.coupons.table_actions.export_selected'),
          icon: 'ei-export',
          id: 'exportCSV'
        },
        {
          name: this.translate.instant('resources.coupons.table_actions.export_coupons'),
          icon: 'ei-export',
          id: 'exportCoupons',
          onClick: () => this.exportCoupons()
        },
        {
          name: this.translate.instant('resources.coupons.table_actions.import_coupons'),
          icon: 'ei-upload-1',
          id: 'importCoupons',
          onClick: () => this.router.navigate([{ outlets: { modal: `import/coupons` } }])
        },
        {
          name: this.translate.instant('resources.coupons.table_actions.upload_images'),
          icon: 'ei-upload-2',
          id: 'uploadImages',
          onClick: () => this.router.navigate([{ outlets: { modal: `import/images` } }])
        },
        {
          name: this.translate.instant('resources.coupons.table_actions.download_images_urls'),
          icon: 'ei-download-2',
          id: 'downloadImages',
          onClick: () => this.downloadImagesCsv()
        },
        {
          name: this.translate.instant('resources.coupons.table_actions.clone_coupons'),
          icon: 'fa-light fa-clone',
          id: 'cloneCoupons',
          onClick: () => this.handleCloneCoupons(this.dataTable.selected)
        },
        {
          name: this.translate.instant('resources.coupons.table_actions.archive_coupons'),
          icon: 'fa-light fa-folder-arrow-down',
          id: 'archiveCoupon',
          onClick: () => this.handleArchiveCoupons(this.dataTable.selected)
        },
        {
          name: this.translate.instant('resources.coupons.table_actions.unarchive_coupons'),
          icon: 'fa-light fa-folder-arrow-up',
          id: 'unarchiveCoupon',
          onClick: () => this.handleUnarchiveCoupons(this.dataTable.selected)
        }
      ],
      rowActions: [
        {
          name: this.translate.instant('resources.coupons.row_actions.details'),
          icon: 'eye',
          id: 'details'
        },
        {
          name: this.translate.instant('resources.coupons.row_actions.edit'),
          icon: 'pencil',
          id: 'edit',
          onClick: (element: { id: number }) => this.router.navigate([
            { outlets: { modal: `update/coupons/${element.id}` } }
          ])
        },
        {
          name: this.translate.instant('resources.coupons.row_actions.clone'),
          icon: 'clone',
          id: 'clone',
          callback: (element: {id: string; name: string}) => this.handleCloneCoupons([element])
        },
        {
          name: this.translate.instant('resources.coupons.row_actions.archive'),
          icon: 'fa-light fa-folder-arrow-down',
          id: 'archive',
          callback: (element: {id: string; name: string}) => this.handleArchiveCoupons([element]),
          show: (element: {archived: boolean}) => !element.archived,
        },
        {
          name: this.translate.instant('resources.coupons.row_actions.unarchive'),
          icon: 'fa-light fa-folder-arrow-up',
          id: 'unarchive',
          callback: (element: {id: string; name: string}) => this.handleUnarchiveCoupons([element]),
          show: (element: {archived: boolean}) => element.archived,
        },
      ],
      columns: [
        {
          name: this.translate.instant('resources.coupons.columns.id'),
          prop: 'id',
        },
        {
          name: this.translate.instant('resources.coupons.columns.name'),
          prop: 'name',
        },
        {
          name: this.translate.instant('resources.coupons.columns.key'),
          prop: 'key',
        },
        {
          name: this.translate.instant('resources.coupons.columns.coupon_type'),
          prop: 'coupon_type',
        },
        {
          name: this.translate.instant('resources.coupons.columns.available_from'),
          prop: 'available_from',
        },
        {
          name: this.translate.instant('resources.coupons.columns.available_to'),
          prop: 'available_to',
        },
        {
          name: this.translate.instant('resources.coupons.columns.available_days'),
          prop: 'available_days',
        },
        {
          name: this.translate.instant('resources.coupons.columns.print_from'),
          prop: 'print_from',
        },
        {
          name: this.translate.instant('resources.coupons.columns.print_to'),
          prop: 'print_to',
        },
        {
          name: this.translate.instant('resources.coupons.columns.priority'),
          prop: 'priority',
        },
        {
          name: this.translate.instant('resources.coupons.columns.static_code'),
          prop: 'static_code',
        },
        {
          name: this.translate.instant('resources.coupons.columns.times_used'),
          prop: 'times_used',
        },
        {
          name: this.translate.instant('resources.coupons.columns.active'),
          prop: 'active',
        }
      ],
      requestData: {
        apiEndPoint: 'coupons',
        pageNumber: 0,
        sorting: {
          byProp: 'id',
          direction: 'desc'
        },
        filtering: {},
        numberPerPage: 10
      },
      tableMapping: [
        { prop: 'id', type: 'number', apiProp: 'id' },
        { prop: 'key', type: 'number', apiProp: 'key' },
        { prop: 'coupon_type', type: 'text', apiProp: 'coupon_type' },
        { prop: 'available_from', type: 'date', apiProp: 'available_from' },
        { prop: 'available_to', type: 'date', apiProp: 'available_to' },
        { prop: 'print_from', type: 'date', apiProp: 'print_from' },
        { prop: 'print_to', type: 'date', apiProp: 'print_to' },
        { prop: 'static_code', type: 'number', apiProp: 'static_code' },
        { prop: 'name', type: 'text', apiProp: 'name' },
        { prop: 'priority', type: 'number', apiProp: 'priority' },
        { prop: 'available_days', type: 'number', apiProp: 'available_days' },
        { prop: 'times_used', type: 'number', apiProp: 'times_used' },
        { prop: 'active', type: 'text', apiProp: 'active', badge: {dictionaryKey: 'coupons_status'} },
      ]
    };
  }

  private setFilters() {
    this.filters = [
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'id',
        label: 'resources.coupons.filters.id',
        order: 1,
        type: 'number'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'name',
        label: 'resources.coupons.filters.name',
        order: 2,
        type: 'text'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'external_id',
        label: 'resources.coupons.filters.external_id',
        order: 3,
        type: 'text'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_from',
        label: 'resources.coupons.filters.available_from',
        order: 4,
        type: 'date',
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_to',
        label: 'resources.coupons.filters.available_to',
        order: 5,
        type: 'date'
      }),
      new CheckboxQuestion({
        key: 'show_archived',
        label: 'resources.coupons.filters.archived_coupons',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        value: false,
        checkboxRow: true,
        tooltipKey: 'resources.coupons.messages.archived_coupons'
      })
    ];
  }

  exportCoupons() {
    const exportSubs$ = this.couponsService.exportData(this.couponsFilter.payLoad).subscribe({
      next: () => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.coupons.form.warnings.export_title'),
          this.translate.instant('resources.coupons.form.warnings.export_text')
        ).catch(() => {});
      },
      error: (errorData: HttpErrorResponse) => {
        if (errorData.error && (typeof errorData.error.error === 'string')) {
          this.confirmationService.displayErrorAlert(
            this.translate.instant('resources.coupons.form.warnings.error_title'),
            errorData.error.error
          );
        }
      }});
    this.subs$.push(exportSubs$);
  }

  private downloadImagesCsv() {
    const images$ = this.couponsService.getUploadedImages().subscribe({
      next: response => {
        this.triggerCSVDownload(response);
      },
      error: (errorData: HttpErrorResponse) => {
        this.confirmationService.displayErrorAlert(
          this.translate.instant('common.error'),
          errorData.error.error
        );
      }
    });
    this.subs$.push(images$);
  }

  private triggerCSVDownload(rawImages) {
    const csvData = this.convertToCSV(rawImages);
    const blob = this.csvExportService.getCsvBlob(csvData);
    const url = window.URL.createObjectURL(blob);
    const nav = (window.navigator as any);

    if (nav.msSaveBlob) {
      nav.msSaveBlob(blob, 'coupon_images_urls.csv');
    } else {
      const a = document.createElement('a');
      a.href = url;
      a.download = 'coupon_images_urls' + '_' + moment().format('DDMMYYYY') + '.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  private convertToCSV(rawImages) {
    const headers = ['name', 'url'];
    let stringCsv = '';
    headers.forEach( header => { stringCsv += header + ';'; } );
    stringCsv += '\n';
    rawImages['list'].forEach(item => {
      stringCsv += item['name'] + ';';
      stringCsv += item['image'] + ';';
      stringCsv += '\n';
    });
    return stringCsv;
  }

  private handleCloneCoupons(coupons: { id: string; name: string }[]): void {
    if (coupons.length) {
      const title = this.translate.instant('resources.coupons.row_actions.clone');
      const desc = this.translate.instant('resources.coupons.form.warnings.clone_coupons', { count: coupons.length });
      this.displayConfirmationAlert(coupons, title, desc).then((result) => {
        if (result.value) {
          const couponIds = coupons.map((coupon) => coupon.id);
          this.cloneCoupons(couponIds);
        }
      }).catch(() => {});
    } else {
      this.displayNoneSelectedError();
    }
  }

  // TODO: REmove duplicated CODE - PR in draft
  private handleArchiveCoupons(coupons: { id: string; name: string }[]): void {
    if (coupons.length) {
      const title = this.translate.instant('resources.coupons.row_actions.archive');
      const desc = this.translate.instant('resources.coupons.form.warnings.archive_coupons', { count: coupons.length });
      this.displayConfirmationAlert(coupons, title, desc).then((result) => {
        if (result.value) {
          const couponIds = coupons.map((coupon) => coupon.id);
          this.archiveCoupons(couponIds);
        }
      }).catch(() => {});
    } else {
      this.displayNoneSelectedError();
    }
  }

  private handleUnarchiveCoupons(coupons: { id: string; name: string }[]): void {
    if (coupons.length) {
      const title = this.translate.instant('resources.coupons.row_actions.unarchive');
      const desc = this.translate.instant('resources.coupons.form.warnings.unarchive_coupons', { count: coupons.length });
      this.displayConfirmationAlert(coupons, title, desc).then((result) => {
        if (result.value) {
          const couponIds = coupons.map((coupon) => coupon.id);
          this.unarchiveCoupons(couponIds);
        }
      }).catch(() => {});
    } else {
      this.displayNoneSelectedError();
    }
  }
  //

  private displayNoneSelectedError(): void {
    this.confirmationService.displayErrorAlert(
      this.translate.instant('common.error'),
      this.translate.instant('resources.vouchers.warnings.none_selected')
    );
  }

  private displayConfirmationAlert(coupons: { id: string; name: string }[], title: string, desc: string): Promise<any> {
    return this.confirmationService.displayConfirmationAlert(title, (coupons.length === 1 ? coupons[0].name : desc));
  }

  private cloneCoupons(couponIds: string[]): void {
    const cloneSub$ = this.couponsService.cloneCoupons(couponIds).subscribe({
      next: () => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.coupons.form.warnings.clone_success_title'),
          this.translate.instant('resources.coupons.form.warnings.clone_success_text')
        ).catch(() => {});
        this.dataTable.getData({ refreshRows: true });
      },
      error: (e: HttpErrorResponse) =>
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), e?.error?.error ?? ''),
    });
    this.subs$.push(cloneSub$);
  }

  // TODO: REmove duplicated CODE - PR in draft
  private archiveCoupons(couponIds: string[]): void {
    const cloneSub$ = this.couponsService.archiveCoupons(couponIds).subscribe({
      next: () => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.coupons.form.warnings.archive_success_title'),
          this.translate.instant('resources.coupons.form.warnings.archive_success_text')
        ).catch(() => {});
        this.dataTable.getData({ refreshRows: true });
      },
      error: (e: HttpErrorResponse) =>
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), e?.error?.error ?? ''),
    });
    this.subs$.push(cloneSub$);
  }

  private unarchiveCoupons(couponIds: string[]): void {
    const cloneSub$ = this.couponsService.unarchiveCoupons(couponIds).subscribe({
      next: () => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.coupons.form.warnings.unarchive_success_title'),
          this.translate.instant('resources.coupons.form.warnings.unarchive_success_text')
        ).catch(() => {});
        this.dataTable.getData({ refreshRows: true });
      },
      error: (e: HttpErrorResponse) =>
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), e?.error?.error ?? ''),
    });
    this.subs$.push(cloneSub$);
  }

}
