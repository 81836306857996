export const RewardCodeBatchesMock = {
  "_links": {
    "total_count": {
      "href": 5
    },
    "self": {
      "href": "/rewards?page=1&per_page=10"
    },
    "next": {
      "href": "/rewards?page=2&per_page=10"
    },
    "last": {
      "href": "/rewards?page=151&per_page=10"
    }
  },
  "_embedded": {
    "list": [
      {
        "id": "312",
        "reward_id": "14",
        "created_at": "2022-04-11T13:22:55.000+02:00",
        "status": "error",
        "num_codes": "",
        "error_message": "Ocurrío un problema con tu lote de códigos"
      },
      {
        "id": "313212",
        "reward_id": "14",
        "created_at": "2022-04-10T12:22:55.000+02:00",
        "status": "error",
        "num_codes": "0",
        "error_message": "Ocurrío un problema con tu lote de códigos"
      },
      {
        "id": "31412",
        "reward_id": "15",
        "created_at": "2022-04-10T11:22:55.000+02:00",
        "status": "uploaded",
        "num_codes": "123, 123123, 1231241231, 1231, 234532, 321122",
        "error_message": ""
      },
      {
        "id": "31512",
        "reward_id": "15",
        "created_at": "2022-04-09T11:22:55.000+02:00",
        "status": "uploaded",
        "num_codes": "123, 123123, 1231241231"
      },
      {
        "id": "35112",
        "reward_id": "16",
        "created_at": "2022-04-09T10:22:55.000+02:00",
        "status": "uploading"
      }
    ]
  }
};
