import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Option } from '../../models/common/option';

@Injectable()
export class AnalyticsService {

  constructor(private translate: TranslateService) {}

  getPeriodOptions(): Option[] {
    return [
      { id: 'current_month', name: this.translate.instant('components.looker-dashboard-filter.fields.current_month')},
      { id: 'last_month', name: this.translate.instant('components.looker-dashboard-filter.fields.last_month')},
      { id: 'custom', name: this.translate.instant('components.looker-dashboard-filter.fields.custom')}
    ];
  }

  getMonthlyEvolutionOpts(): Option[] {
    const opts = [{id: 1, name: this.translate.instant('components.looker-dashboard-filter.fields.period_month', {num: 1})}];
    for (let index = 2; index <= 12; index++) {
      opts.push({id: index, name: this.translate.instant('components.looker-dashboard-filter.fields.period_months', {num: index})})
    }
    return opts;
  }

  getOwnBrandOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('resources.product_taxonomies.types.supplier')},
      { id: '1', name: this.translate.instant('resources.product_taxonomies.types.own_brand')}
    ];
  }

  getUnitMeasures(currencySymbol: string): Option[] {
    return [
      { id: '€', name: this.translate.instant('components.looker-dashboard-filter.fields.currency', {currencySymbol: currencySymbol})},
      { id: '%', name: this.translate.instant('components.looker-dashboard-filter.fields.variation')}
    ];
  }

  getUnitMeasuresQuantity(): Option[] {
    return [
      { id: '#', name: this.translate.instant('components.looker-dashboard-filter.fields.quantity')},
      { id: '%', name: this.translate.instant('components.looker-dashboard-filter.fields.variation')}
    ];
  }

  getMeasureTypes(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.sales')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.tickets')},
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.average_ticket')},
      { id: '3', name: this.translate.instant('components.looker-dashboard-filter.fields.units')},
      { id: '4', name: this.translate.instant('components.looker-dashboard-filter.fields.products_by_ticket')}
    ];
  }

  getTicketsOptions(): Option[] {
    return [
      { id: 'null', name: this.translate.instant('components.looker-dashboard-filter.fields.all')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.identified')},
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.unidentified')}
    ];
  }

  getSupplierBrandOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.supplier')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.brand')}
    ];
  }

  getBrandSupplierEntities(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.brand')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.supplier')}
    ];
  }

  getTopAllBrandSupplierEntities(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.top_ten')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.all_singular')}
    ];
  }

  getEvolutionChartOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.sales')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.quantity_units')},
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.avg_ticket')},
      { id: '3', name: this.translate.instant('components.looker-dashboard-filter.fields.avg_expenditure')},
      { id: '4', name: this.translate.instant('components.looker-dashboard-filter.fields.sales_weight')},
      { id: '5', name: this.translate.instant('components.looker-dashboard-filter.fields.penetration')}
    ];
  }

  getChartMetricOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.sales')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.units')},
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.tickets')},
      { id: '3', name: this.translate.instant('components.looker-dashboard-filter.fields.customers')},
      { id: '4', name: this.translate.instant('components.looker-dashboard-filter.fields.avg_ticket')},
      { id: '5', name: this.translate.instant('components.looker-dashboard-filter.fields.avg_expense')}
    ];
  }

  getChartMetricTypeOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.identified_sales')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.identified_tickets')},
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.avg_expenditure')},
      { id: '3', name: this.translate.instant('components.looker-dashboard-filter.fields.avg_ticket')},
      { id: '4', name: this.translate.instant('components.looker-dashboard-filter.fields.frequency')},
      { id: '5', name: this.translate.instant('components.looker-dashboard-filter.fields.customers')}
    ];
  }

  getChartMetricSegmentsCustomerTypeOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.sales')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.tickets')},
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.customers')},
      { id: '3', name: this.translate.instant('components.looker-dashboard-filter.fields.avg_ticket')},
      { id: '4', name: this.translate.instant('components.looker-dashboard-filter.fields.frequency')},
    ];
  }

  getChartMetricSalesCustomersPurchasesOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.sales')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.customers')},
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.tickets')}
    ];
  }

  getChartMetricSupplierBrandOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.sales')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.units')},
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.tickets')},
      { id: '3', name: this.translate.instant('components.looker-dashboard-filter.fields.customers')},
      { id: '4', name: this.translate.instant('components.looker-dashboard-filter.fields.avg_ticket')},
      { id: '5', name: this.translate.instant('components.looker-dashboard-filter.fields.avg_expenditure')},
      { id: '6', name: this.translate.instant('components.looker-dashboard-filter.fields.products_per_ticket')}
    ];
  }

  getChartMetricasOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.sales')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.tickets')},
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.customers')},
      { id: '3', name: this.translate.instant('components.looker-dashboard-filter.fields.avg_ticket')},
      { id: '4', name: this.translate.instant('components.looker-dashboard-filter.fields.avg_expenditure')},
      { id: '5', name: this.translate.instant('components.looker-dashboard-filter.fields.frequency')},
      { id: '6', name: this.translate.instant('components.looker-dashboard-filter.fields.units')},
      { id: '7', name: this.translate.instant('components.looker-dashboard-filter.fields.products_per_ticket')}
    ];
  }

  getChartMetricsWithOwnBrandOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.sales')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.tickets')},
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.customers')},
      { id: '3', name: this.translate.instant('components.looker-dashboard-filter.fields.avg_ticket')},
      { id: '4', name: this.translate.instant('components.looker-dashboard-filter.fields.avg_expenditure')},
      { id: '5', name: this.translate.instant('components.looker-dashboard-filter.fields.own_brand')},
      { id: '6', name: this.translate.instant('components.looker-dashboard-filter.fields.percentage_own_brand_sales')},
      { id: '7', name: this.translate.instant('components.looker-dashboard-filter.fields.units')},
      { id: '8', name: this.translate.instant('components.looker-dashboard-filter.fields.products_per_ticket')},
      { id: '9', name: this.translate.instant('components.looker-dashboard-filter.fields.penetration')},
      { id: '10', name: this.translate.instant('components.looker-dashboard-filter.fields.percentage_sales_weight')}
    ];
  }

  getChartGranularityOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.month')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.week')},
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.day_of_year')},
      { id: '3', name: this.translate.instant('components.looker-dashboard-filter.fields.day_of_month')},
      { id: '4', name: this.translate.instant('components.looker-dashboard-filter.fields.day_of_week')}
    ];
  }

  getDimensionOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.country')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.stores')},
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.features')},
      { id: '3', name: this.translate.instant('components.looker-dashboard-filter.fields.products')}
    ];
  }

  getDimensionStoreProductOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.store')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.product_category')},
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.product_name')}
    ];
  }

  getSalesMetricsOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.sales') },
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.customers') },
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.tickets') },
      { id: '3', name: this.translate.instant('components.looker-dashboard-filter.fields.average_ticket') },
      { id: '4', name: this.translate.instant('components.looker-dashboard-filter.fields.avg_expenditure') },
      { id: '5', name: this.translate.instant('components.looker-dashboard-filter.fields.frequency') }
    ];
  }

  getWeekdaysOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.monday')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.tuesday')},
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.wednesday')},
      { id: '3', name: this.translate.instant('components.looker-dashboard-filter.fields.thursday')},
      { id: '4', name: this.translate.instant('components.looker-dashboard-filter.fields.friday')},
      { id: '5', name: this.translate.instant('components.looker-dashboard-filter.fields.saturday')},
      { id: '6', name: this.translate.instant('components.looker-dashboard-filter.fields.sunday')}
    ];
  }

  getIdEanProductOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.id')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.ean')},
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.id_ean')}
    ]
  }

  getYearMonthDateOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.year')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.month')},
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.date')}
    ];
  }

  getTopAllLocationsOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.top_ten')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.all_locations')}
    ];
  }

  getPostalCodeCityOptions(): Option[] {
    return [
      { id: '0', name: this.translate.instant('components.looker-dashboard-filter.fields.postal_code')},
      { id: '1', name: this.translate.instant('components.looker-dashboard-filter.fields.city')},
      { id: '2', name: this.translate.instant('components.looker-dashboard-filter.fields.postal_code_city')}
    ];
  }

  getEntityOptions(): Option[] {
    return [
      { id: 'Activities', name: this.translate.instant('components.looker-dashboard-filter.fields.activities')},
      { id: 'Customers', name: this.translate.instant('components.looker-dashboard-filter.fields.customers')},
      { id: 'Locations', name: this.translate.instant('components.looker-dashboard-filter.fields.locations')},
      { id: 'Products', name: this.translate.instant('components.looker-dashboard-filter.fields.products')},
    ];
  }

  getMethodOptions(): Option[] {
    return [
      { id: 'Creation', name: this.translate.instant('components.looker-dashboard-filter.fields.creation')},
      { id: 'Update', name: this.translate.instant('components.looker-dashboard-filter.fields.update')}
    ];
  }
}
