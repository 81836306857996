<div class="page-title background-white">
  <div class="row pdd-horizon-15">
    <div class="col-md-9 d-flex align-items-center min-height-60px">
      <h4 class="no-mrg-vertical" [translate]="'resources.coupons.title'"></h4>
      <icon-info-button size="18px" [openInfoPanel]="infoPanel" />
    </div>
  </div>
  <app-info-panel #infoPanel
    [titleKey]="'components.info_panel.titles.coupons'"
    [subtitleKey]="'components.info_panel.subtitles.coupons'"
    [imageUrl]="'assets/images/info-panels/coupons.png'"
    [learnMoreLink]="'https://loyal-guru.atlassian.net/servicedesk/customer/portal/13/topic/4882bbaa-6eee-4283-9dcb-54573dfefa34/article/337969157'">
  </app-info-panel>
</div>
<ul class="nav nav-tabs w-100" role="tablist">
  <li class="nav-item" *ngIf="isTabVisibile('coupons')">
      <a (click)="selectTab('coupons')"
          [routerLink]="['coupons']"
          class="nav-link {{slug === 'coupons'? 'active' : ''}}"
          data-toggle="tab"
          href="#coupons-tab-1"
          role="tab"
          aria-selected="true"
          [translate]="'resources.coupons.tabs.coupons'">
      </a>
  </li>
  <li class="nav-item" *ngIf="isTabVisibile('histories')">
      <a (click)="selectTab('histories')"
          class="nav-link {{slug === 'histories'? 'active' : ''}}"
          [routerLink]="['histories']"
          data-toggle="tab"
          href="#coupons-tab-2"
          role="tab"
          aria-selected="false"
          [translate]="'resources.coupons.tabs.history'">
      </a>
  </li>
  <li class="nav-item" *ngIf="isTabVisibile('config') && isAuthorized(['admin'])">
      <a (click)="selectTab('config')"
          class="nav-link {{slug === 'config'? 'active' : ''}}"
          [routerLink]="['config']"
          data-toggle="tab"
          href="#coupons-tab-3"
          role="tab"
          aria-selected="false"
          [translate]="'resources.coupons.config.tabs.config-coupons'">
      </a>
  </li>
  <li class="nav-item" *ngIf="isTabVisibile('templates') && isAuthorized(['admin'])">
      <a (click)="selectTab('templates')"
          class="nav-link {{slug === 'templates'? 'active' : ''}}"
          [routerLink]="['templates']"
          data-toggle="tab"
          href="#coupons-tab-4"
          role="tab"
          aria-selected="false"
          [translate]="'resources.coupons.config.tabs.templates'">
      </a>
  </li>
</ul>

<div class="mrg-top-15 container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="position-relative">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
