import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../services/question-control.service';
import { ProfileService } from '../../../../../profiles/profile.service';
import { Subscription } from 'rxjs';
import { SystemTagHistoriesService } from '../../../../services/system-tag-histories.service';

@Component({
  selector: 'app-max-sales-condition',
  templateUrl: './max-sales-condition.component.html',
  styleUrls: ['./max-sales-condition.component.css']
})

export class MaxSalesConditionComponent implements OnInit, OnDestroy {

  @Input('item') item: Condition;
  @Input('inputs') inputs: QuestionBase<any>[] = [];
  @Input('form') form: UntypedFormGroup;

  subs$: Subscription[] = [];
  filters: object;

  constructor( 
    private qcs: QuestionControlService,
    private profileService: ProfileService,
    private tagHistoriesService: SystemTagHistoriesService
  ) {
    this.setSegmentCategoryId();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach( s$ => s$.unsubscribe()); }
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    if ( !value ) { return false; }
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  handleAppliesSelected(value) {
    this.resetInput('applies', 'index_applies');
  }

  handleAppliesIndexSelected(value) {
    this.resetInput('index_applies', 'applies');
  }

  /*
  * @param {Boolean} emitValue: If set to false won't refresh tag_histories input values
  */
  handleSegmentSelected(value, emitValue?: boolean) {

    const _emitValue = emitValue === false ? false : true;

    if ( value ) {

      if ( _emitValue ) {
        this.resetAppliesInputs();
        this.inputs.find( input => input.key === 'applies').disabled = false;
        this.inputs.find( input => input.key === 'index_applies').disabled = false;
      }

      this.tagHistoriesService.setSegment(value.id);
    } else {
      this.disableAppliesInputs();
    }
  }

  private init() {
    if (this.inputs[0].selectedIds && this.inputs[0].selectedIds.length > 0) {
      this.handleSegmentSelected( { id: this.inputs[0].selectedIds[0] }, false );
    } else {
      this.disableAppliesInputs();
    }
  }

  private setSegmentCategoryId() {
    const user = this.profileService.getStoredUser();
    const salesSegmentCategory = user.company['system_segment_categories']['sales'];
    this.filters = {all: false, segment_category_id: salesSegmentCategory};
  }

  private resetInput(inputSelectedKey: string, inputToResetKey: string, ) {
    const inputSelected = this.inputs.find( _input => _input.key === inputSelectedKey);
    const inputToReset = this.inputs.find( _input => _input.key === inputToResetKey);
    if ( inputSelected.value && inputSelected.value.length > 0 ) { 
      inputToReset.value = [];
      this.form.get(inputToResetKey).patchValue([]);
    }
  }

  private resetAppliesInputs() {
    this.inputs.find( input => input.key === 'applies').value = [];
    this.inputs.find( input => input.key === 'index_applies').value = [];
    this.form.patchValue({ applies: [], index_applies: [] });
  }

  private disableAppliesInputs() {
    this.resetAppliesInputs();
    this.inputs.find( _input => _input.key === 'applies' ).disabled = true;
    this.inputs.find( _input => _input.key === 'index_applies' ).disabled = true;
  }
}
