import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableConfiguration } from '../../../shared/components/data-table/data-table-cfg';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { DataTableComponent } from '../../../shared/components/data-table/data-table.component';

@Component({
  selector: 'app-show-logs',
  templateUrl: './show-logs.component.html',
  styleUrls: ['./show-logs.component.css']
})
export class ShowLogsComponent implements OnInit {

  public service: string = this.route.snapshot.params.id;
  public dataTableConfig: DataTableConfiguration;

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.setDataTableConfig();
  }

  private setDataTableConfig() {
    this.dataTableConfig = {
      isActive: true,
      rows: [],
      rowActions: [],
      tableActions: [
        {
          name: this.translate.instant('components.table_actions.export_csv'),
          icon: 'ei-export',
          id: 'exportCSV'
        }
      ],
      columns: [
        {
          name: this.translate.instant('resources.status.columns.service'),
          prop: 'service',
        },
        {
          name: this.translate.instant('resources.status.columns.created_at'),
          prop: 'created_at',
        },
        {
          name: this.translate.instant('resources.status.columns.status'),
          prop: 'status',
        },
        {
          name: this.translate.instant('resources.status.columns.offline_motive'),
          prop: 'offline_motive',
        }
      ],
      requestData: {
        apiEndPoint: `status/${this.service}/log`
      },
      tableMapping: [
        { prop: 'service', type: 'number', apiProp: 'service' },
        { prop: 'created_at', type: 'date', apiProp: 'created_at' },
        { prop: 'status', type: 'text', apiProp: 'status', badge: {dictionaryKey: 'system_status'}},
        { prop: 'offline_motive', type: 'text', apiProp: 'offline_motive'},
      ]
    };
  }

}
