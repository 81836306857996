import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MultiselectDataSource } from '../../components/multiselect/multiselect';
import { ResourcesService } from '../resources.service';

@Injectable({
  providedIn: 'root'
})
export class DataExtensionsFieldsService extends ResourcesService {

  connectorId: number;
  dataExtensionId: string;
  optsSource = new BehaviorSubject<string[] | string>(null);
  optsSource$ = this.optsSource.asObservable();

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  fetchMultiselect(): Observable<object> {
    let requestOptions = {
      apiEndPoint: `connectors/${this.connectorId}/salesforce/data-extensions/${this.dataExtensionId}/fields`,
      filtering: {},
      sorting: {}
    };

    return this.dataExtensionId ? this.getData(requestOptions) : of([]);
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.name, element);
  }

  setDataExtensionId(dataExtensionId: string): void {
    this.dataExtensionId = dataExtensionId;
    this.optsSource.next(dataExtensionId);
  }
}
