import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ResourcesService } from '../../../shared/services/resources.service';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { Subject } from 'rxjs';

@Injectable()
export class FidelizationService extends ResourcesService {

  public inputs: QuestionBase<any>[];
  public applyFilters$ = new Subject<void>();
  public filters: any;

  constructor(http: HttpClient) {
    super(http);
  }
}
