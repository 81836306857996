import { Pipe, PipeTransform } from '@angular/core';
import { LanguagesService } from '../services/languages.service';
import { ProfileService } from '../../profiles/profile.service';

@Pipe({ name: 'lgMultilanguageTranslate' })
export class LgMultilanguageTranslatePipe implements PipeTransform {

  // Define an array of supported languages ids: i. e. ['es', 'en', 'fr'...]
  supportedLanguagesCodes = this.languageService.getLocalizedLanguages().map(el => el.id);
  locale: string;

  constructor(
    private languageService: LanguagesService,
    private currentUserProfile: ProfileService
  ) {}

  transform(value: string, returnAllLanguages?: boolean) {

    let result = {};
    this.locale = this.loadLocale();

    // Create a regex pattern that matches any of the supported languages
    const languagePattern = this.supportedLanguagesCodes.join('|');

    // Construct the regular expression and use capturing groups to extract both the language and the content between the tags
    const regex = new RegExp(`<(${languagePattern})>(.*?)<\/(${languagePattern})>`, 'g');
    const matches = [...value.matchAll(regex)];

    matches.forEach((match) => {
      const language = match[1];
      const content = match[2];
      const key = language.toUpperCase();
      result[key] = content;
    });

    const entries = Object.entries(result);

    if (entries.length > 0) {
      return returnAllLanguages ? this.parseString(entries) : result[this.locale.toUpperCase()];
    } else {
      return value;
    }
  }

  private parseString(entries) {
    let parsedString = '';
    entries.forEach(([k,v], index) => {
      const newEntry = `${k}: ${v}`;
      parsedString += index === 0 ? `${newEntry}` : `, ${newEntry}`;
    })
    return parsedString;
  }

  private loadLocale(): string {
    const user = this.currentUserProfile.getStoredUser();
    return user.preferred_language;
  }
}