import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../../../profiles/profile.service';
import { CurrentUser } from '../../../models/current-user';
import { QuestionBase } from '../../../models/forms/question-base';
import { MultiSelectQuestion } from '../../../models/forms/question-multiselect';
import { TextboxQuestion } from '../../../models/forms/question-textbox';
import { DictionaryService } from '../../../services/dictionary.service';
import { LanguagesService } from '../../../services/languages.service';
import { ResourcesService } from '../../../services/resources.service';

@Injectable({
  providedIn: 'root'
})

export class UserSettingsService extends ResourcesService {

  inputs: QuestionBase<any>[] = [];
  userData: CurrentUser;

  constructor(
    http: HttpClient,
    private translate: TranslateService,
    private profileService: ProfileService,
    private dictionarySrv: DictionaryService,
    private languagesService: LanguagesService
  ) {
    super(http);
    this.userData = new CurrentUser(this.profileService.getStoredUser());
  }

  updateUser(userData: any, id: number | string) {
    return this.patchResource(userData, `users/${id}`);
  }

  getInputs(formValues: any): QuestionBase<any>[] {
    const languages = this.languagesService.getPlatformLanguages();
    const language = this.userData?.userPreferredLanguage;

    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        label: this.translate.instant('resources.user_settings.fields.name'),
        type: 'text',
        cssClasses: 'form-control input-default',
        value: this.userData.name,
        disabled: true
      }),
      new TextboxQuestion({
        key: 'surname_1',
        label: this.translate.instant('resources.user_settings.fields.surname_1'),
        type: 'text',
        cssClasses: 'form-control input-default',
        value: this.userData.surname1?? '-',
        disabled: true
      }),
      new TextboxQuestion({
        key: 'surname_2',
        label: this.translate.instant('resources.user_settings.fields.surname_2'),
        type: 'text',
        cssClasses: 'form-control input-default',
        value: this.userData.surname2 ?? '-',
        disabled: true
      }),
      new TextboxQuestion({
        key: 'email',
        label: this.translate.instant('resources.user_settings.fields.email'),
        type: 'text',
        cssClasses: 'form-control input-default',
        value: this.userData.email ?? '-',
        disabled: true
      }),
      new MultiSelectQuestion({
        key: 'user_preferred_language',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: false },
        options: this.languagesService.getPlatformLanguages().filter(lang => !['hr', 'ro'].includes(lang.id)),
        value: [ this.userData.hasOwnProperty('userPreferredLanguage') ? languages.find(item => item.id === language) : [] ],
        required: true
      })
    ];

    this.inputs = inputsArray;
    this.assignFormInputValues(formValues);
    return this.inputs;
  }

  private assignFormInputValues(formValues) {
    Object.keys(formValues).forEach(
      key => {
        const tmpInput = this.inputs.find( input => input.key === key);
        if (tmpInput && tmpInput.getValue ) {
          const value = tmpInput.getValue(formValues[key]);
          if (value && value.hasOwnProperty('selectedIds')) {
            tmpInput.selectedIds = value.selectedIds;
          } else {
            tmpInput.value = value;
          }
        }
      }
    );
  }

}
