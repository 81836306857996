<div class="modal-backdrop fade in modal-fs" id='modal-fs' tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="card">
          <div class="card-block padding-15">
            <div class="modal-header no-pdd mrg-btm-15">
              <h4 class="card-title" [translate]="'resources.messages.modal_csv.title'"></h4>
              <button class="modal-close" (click)="closeModal()">
                  <i class="ti-close"></i>
              </button>
            </div>
            <div class="row">
              <div class="col-md-12">                
                <p><b [translate]="'resources.messages.modal_csv.p1'"></b></p>
                <p><b [translate]="'resources.messages.modal_csv.p2'"></b></p>
                <p [translate]="'resources.messages.modal_csv.p3'"></p>
                <form class="form-horizontal">                                      
                  <div class="form-group row">
                    <div class="col-md-12">
                      <textarea id="height-40vh"
                                [(ngModel)]="csv_text"
                                name="csv_text"
                                placeholder="{{'resources.messages.modal_csv.body_placeholder' | translate }}"
                                class="form-control"></textarea>
                    </div>
                  </div>
                </form>
                <div class="row">
                  <button type="button" class="mrg-left-15 btn btn-info" (click)="loadMessagesWithCsv()" [translate]="'resources.messages.buttons.send'"></button>
                </div>
              </div>
            </div>
          </div>            
        </div>
      </div>
    </div>
  </div>
</div>
