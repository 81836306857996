import { CustomerAttributeDetail, CustomerAttributeDetailInputs } from "../models/customer-attribute-detail.model";

export function getCustomerAttributesDetailInputs(customerAttributesDetailData: CustomerAttributeDetail, isCalculationAttributeType: boolean, isScheduledExecutionType: boolean): CustomerAttributeDetailInputs[] {

  return [
    {
      key:'name',
      type: 'text',
      label: 'resources.customer_attributes.fields.name',
      value: customerAttributesDetailData.name,
      required: true
    },
    {
      key:'description',
      type: 'text',
      label: 'resources.customer_attributes.fields.description',
      value: customerAttributesDetailData.description,
      required: false
    },
    {
      key:'system',
      type: 'text',
      label: 'resources.customer_attributes.fields.origin',
      value: customerAttributesDetailData.system,
      required: true
    },
    {
      key:'attribute_type',
      type: 'text',
      label: 'resources.customer_attributes.fields.attribute_type',
      value: customerAttributesDetailData.attributeType,
      required: true,
    },
    {
      key:'data_type',
      type: 'text',
      label: 'resources.customer_attributes.fields.data_type',
      value: customerAttributesDetailData.dataType,
      required: true
    },
    {
      key:'results_available_in_real_time',
      type: 'text',
      label: 'resources.customer_attributes.fields.results_available_in_real_time',
      value: isCalculationAttributeType ? customerAttributesDetailData.resultsAvailableInRealTime : null,
      required: false
    },
    {
      key:'strict_profiling',
      type: 'text',
      label: 'resources.customer_attributes.fields.strict_profiling_detail',
      value: isCalculationAttributeType ? customerAttributesDetailData.ignoreStrictProfiling : null,
      required: false
    },
    {
      key:'data_period_type',
      type: 'text',
      label: 'resources.customer_attributes.fields.data_period_type',
      value: customerAttributesDetailData.dataPeriodType,
      required: isCalculationAttributeType,
      tooltip: 'resources.customer_attributes.tooltips.data_period_type'
    },
    {
      key:'execution_type',
      type: 'text',
      label: 'resources.customer_attributes.fields.execution_type',
      value: customerAttributesDetailData.executionType,
      required: isCalculationAttributeType
    },
    {
      key:'start_date',
      type: 'text',
      label: 'resources.customer_attributes.fields.start_date',
      value: customerAttributesDetailData.startDate,
      required: !!(isCalculationAttributeType && isScheduledExecutionType)
    },
    {
      key:'start_time',
      type: 'text',
      label: 'resources.customer_attributes.fields.start_time',
      value: customerAttributesDetailData.startTime,
      required: !!(isCalculationAttributeType && isScheduledExecutionType)
    },
    {
      key:'recurrence',
      type: 'text',
      label: 'resources.customer_attributes.fields.recurrence',
      value: customerAttributesDetailData.recurrence,
      required: isCalculationAttributeType
    },
    {
      key:'sql',
      type: 'textarea',
      label: 'resources.customer_attributes.fields.sql',
      value: customerAttributesDetailData.sql,
      required: isCalculationAttributeType,
      tooltip: 'resources.customer_attributes.tooltips.sql'
    }
  ]
}