<div class="container-fluid">  
  <div class="row">
    <div class="col-md-12">        
      <div class="card-block">
        <div class="mrg-top-10">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form  [formGroup]="locationTaxonomiesForm" *ngIf="locationTaxonomiesForm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name" [translate]="'resources.location_taxonomies.form.name'"></label>
                      <span *ngIf="getInputConfig('name').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('name')" [form]="locationTaxonomiesForm"></app-question>
                    </div>
                  </div>                      
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="slug" [translate]="'resources.location_taxonomies.form.slug'"></label>
                      <span *ngIf="getInputConfig('slug').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('slug')" [form]="locationTaxonomiesForm"></app-question>
                    </div>
                  </div>
                </div>   
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="origin" [translate]="'resources.location_taxonomies.form.origin'"></label>
                      <span *ngIf="getInputConfig('origin').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('origin')" [form]="locationTaxonomiesForm"></app-question>
                    </div>
                  </div>
                </div>                    
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
