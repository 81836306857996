import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrUndefined } from 'util';
import { PlansService } from '../plans.service';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { Plan } from '../plan';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CampaignType } from './campaign-type';
import { CustomCampaignPlanDefinitionComponent } from '../campaigns/custom-campaign-plan-definition/custom-campaign-plan-definition.component';
import { StepsNavComponent } from '../../../shared/components/form-wizard/steps-nav/steps-nav.component';
import { PlanCampaignService } from '../campaigns/plan-campaign.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { PlanCampaign } from '../campaigns/plan-campaign';
import { Observable } from 'rxjs';
import { ModalStatusService } from '../../../shared/services/modal-status.service';
import { CustomCampaignPlanAudienceComponent } from '../campaigns/custom-campaign-plan-audience/custom-campaign-plan-audience.component';
import { SupplierCampaignPlanDefinitionComponent } from '../campaigns/supplier-campaign-plan-definition/supplier-campaign-plan-definition.component';
import { UpCrossCampaignDefinitionComponent } from '../campaigns/up-cross-campaign-definition/up-cross-campaign-definition.component';
import { RecoveryCampaignPlanDefinitionComponent } from '../campaigns/recovery-campaign-plan-definition/recovery-campaign-plan-definition/recovery-campaign-plan-definition.component';
import { PlanCampaignActionService } from '../campaigns/custom-campaign-plan-audience/plan-campaign-action.service';
import { PlanCampaignAction } from '../campaigns/custom-campaign-plan-audience/plan-campaign-action';

@Component({
  selector: 'app-plan-campaign-new',
  templateUrl: './plan-campaign-new.component.html',
  styleUrls: ['./plan-campaign-new.component.scss'],
  providers: [PlanCampaignActionService],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PlanCampaignNewComponent implements OnInit, OnDestroy {

  loading: boolean;
  planCampaignLoader: boolean;
  slug: string;
  plan: Plan;
  planId: string;
  selectedType: CampaignType;
  currentPlan: Plan;
  planCampaign: PlanCampaign;
  originalPlanCampaign: PlanCampaign;
  currentStep: number;
  currentMode: string;
  keepCoupons: boolean;

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild(CustomCampaignPlanDefinitionComponent) definition: CustomCampaignPlanDefinitionComponent;
  @ViewChild(SupplierCampaignPlanDefinitionComponent) supplierDefinition: SupplierCampaignPlanDefinitionComponent;
  @ViewChild(UpCrossCampaignDefinitionComponent) upCrossDefinition: UpCrossCampaignDefinitionComponent;
  @ViewChild(RecoveryCampaignPlanDefinitionComponent) recoveryDefinition: UpCrossCampaignDefinitionComponent;
  @ViewChild(CustomCampaignPlanAudienceComponent) audience: CustomCampaignPlanAudienceComponent;
  @ViewChild(StepsNavComponent) nav: StepsNavComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private plansService: PlansService,
    private confirmationService: ConfirmationService,
    private planCampaignsService: PlanCampaignService,
    private modalStatusService: ModalStatusService,
    private planCampaignActionService: PlanCampaignActionService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.loading = false;
    this.planCampaignLoader = false;
    this.currentStep = 1;
  }

  ngOnInit() {
    this.slug = this.route.snapshot.data.slug;
    this.currentMode = this.route.snapshot.data.mode;
    this.currentStep = this.route.snapshot.data.step_num;
    this.selectedType = this.plansService.selectedType;

    if (isNullOrUndefined(this.selectedType) && this.currentMode === 'create' && this.slug === 'step_2') {
      this.router.navigate(['../step_1'], {relativeTo: this.route}).catch(() => {});
      return;
    }

    this.plan = this.plansService.currentPlan;
    this.routeSub();

    this.changeDetector.markForCheck();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeModal() {
    this.router.navigate(['../'], {relativeTo: this.route}).catch(() => {});
  }

  saveType(type: CampaignType) {
    this.plansService.selectedType = type;
    this.router.navigate(['../step_2'], {relativeTo: this.route}).catch(() => {});
    this.changeDetector.markForCheck();
  }

  /*
  * @desc performs a series of actions depending on eventId string value
  * @param {eventId: save_and_exit} should save and redirect to plan campaign list on success
  * @param {eventId: prev_step} should NOT save and redirect to prev step instantly (warning message ?)
  * @param {eventId: next_step} should save and redirect to next step on success
  * @param {eventId: submit_campaign} should submit plan campaign and redirect to plan campaign list on success
  * TODO: Divide in methods
  */
  handleNavEvent(eventId: string) {
    switch (eventId) {
      case 'prev_step':
        if (this.slug === 'step_3') {
          this.saveAudience('prev_step');
        } else {
          this.prevStep();
        }
        break;
      case 'save_and_exit':
        if (this.slug === 'step_2') {
          this.saveAndRunCallback((_: PlanCampaign) => {
            const title = this.translate.instant('resources.campaign_plans.messages.save_success_title');
            const desc = this.translate.instant('resources.campaign_plans.messages.save_success_desc');
            this.confirmationService.displaySuccessAlert(title, desc).catch(() => {});
            this.closeModal();
          });
        } else if (this.slug === 'step_3') {
          this.handleDefinitionFormStatus('INVALID');
          this.saveAudience('save_and_exit');
        } else if (this.slug === 'step_4') {
          this.closeModal();
        }
        break;
      case 'next_step':
        if (this.slug === 'step_2') {
          this.saveAndRunCallback((planCampaign: PlanCampaign) => this.nav.gotoNextStep(planCampaign));
        } else if (this.slug === 'step_3') {
          this.handleDefinitionFormStatus('INVALID');
          this.saveAudience('next_step');
        }
        break;
      case 'submit_campaign':
        this.submitCampaign();
        break;
      case 'reject':
        this.rejectCampaign();
        break;
    }
  }

  handleDefinitionFormStatus(formStatus: string) {
    if (isNullOrUndefined(this.nav)) { return; }
    const ableToGoPrev = formStatus === 'VALID';
    const ableToSave = formStatus === 'VALID';
    const ableToGoNext = formStatus === 'VALID';
    this.nav.disablePrev = !ableToGoPrev;
    this.nav.disableNext = !ableToGoNext;
    this.nav.disableSave = !ableToSave;
  }

  handleRequestStatus(requestsStatus: string) {
    switch (requestsStatus) {
      case 'save_and_exit':
        const title = this.translate.instant('resources.campaign_plans.messages.save_success_title');
        const desc = this.translate.instant('resources.campaign_plans.messages.save_success_desc');
        this.confirmationService.displaySuccessAlert(title, desc).catch(() => {});
        this.closeModal();
        break;
      case 'prev_step':
        this.nav.gotoPreviousStep(this.planCampaign);
        break;
      case 'next_step':
        this.nav.gotoNextStep(this.planCampaign);
        break;
      case 'save_cancelled':
        this.handleDefinitionFormStatus('VALID');
        break;
    }
  }

  confirmAndCloseModal() {
    // Prevent showing confirmation if plan is already delivered
    if(!this.plan || (this.plan && this.plan.isDelivered())){
      return this.closeModal();
    }
    // Definition step: Show confirmation message to exit only if campaign name or campaign config changed
    if(this.currentStep === 2 && !this.hasCampaignPlanChanged()){
      return this.closeModal();
    };
    // Audience step: Show confirmation message to exit only if any linked coupon changed
    if(this.currentStep === 3 && !this.audience.hasAnyCampaignPlanActionChanged()) {
      return this.closeModal();
    };
    // Forecast step: Never show confirmation message as in this step there is no way to change anything
    if(this.currentStep === 4) {
      return this.closeModal();
    };
    // If none of the above is met, show confirmation message
    const title = this.translate.instant('resources.campaign_plans.messages.pending_changes_title');
    const desc = this.translate.instant('resources.campaign_plans.messages.pending_changes_desc');
    this.confirmationService.displayConfirmationAlert(title, desc, 'question').then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.closeModal();
      }
    }).catch(() => {});
  }

  handleCalculatedData(eventId: string) {
    if (isNullOrUndefined(this.nav)) { return; }
    if (eventId === 'loading' || eventId === 'actions_with_errors') {
      this.nav.disableSubmit = true;
      this.nav.disableSave = true;
    }else if (eventId === 'finished_loading') {
      this.nav.disableSubmit = false;
      this.nav.disableSave = false;
    }
  }

  handleForecastStatus(eventId: string) {
    if (isNullOrUndefined(this.nav)) { return; }
    if (eventId === 'empty_selected_audiences')  {
      this.nav.disableSubmit = true;
      this.nav.disableSave = true;
    } else {
      this.nav.disableSubmit = false;
      this.nav.disableSave = false;
    }
  }

  isPlanDelivered() {
    return this.plan && this.plan.isDelivered();
  }

  private prevStep() {
    this.nav.gotoPreviousStep(this.planCampaign);
  }

  private prepareSave(callback: Function) {
    isNullOrUndefined(this.planCampaign) ? this.save(callback) : this.checkIfKeepCouponsIsDisplayable(callback);
  }

  private checkIfKeepCouponsIsDisplayable(callback: Function) {
    this.planCampaignActionService.getCampaignActions(this.planCampaign.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: actions => {
        const planCampaignActions = actions['list'].map(obj => new PlanCampaignAction(obj));
        const actionsWithCoupon = planCampaignActions.filter(action => !isNullOrUndefined(action.coupon_id));
        if (actionsWithCoupon.length > 0 && this.hasCampaignPlanCfgChanged()) {
          this.saveWithKeepCouponsConfirmation(callback);
        } else {
          this.keepCoupons = false;
          this.save(callback);
        }
      },
      error: (err: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert((err))
    });
  }

  private hasCampaignPlanChanged(): boolean {
    if (!this.originalPlanCampaign) { return false; }
    if (this.originalPlanCampaign && this.originalPlanCampaign.name !== this.getPayload()['name']) {
      return true;
    }
    return this.hasCampaignPlanCfgChanged();
  }

  private hasCampaignPlanCfgChanged(): boolean {
    const originalConfig = this.originalPlanCampaign.config;
    const newConfig = this.getPayload()['config'];
    return JSON.stringify(originalConfig) !== JSON.stringify(newConfig);
  }

  private save(callback: Function) {
    this.getRequestMethod().pipe(takeUntil(this.destroy$)).subscribe({
      next: (successData: HttpResponse<any>) => {
        this.loading = false;
        this.modalStatusService.modalStatus.emit();
        this.planCampaign = new PlanCampaign(successData);
        this.nav.preventSave = false;
        this.changeDetector.markForCheck();
        callback(this.planCampaign);
      },
      error: (errorData: HttpErrorResponse) => {
        this.loading = false;
        this.nav.preventSave = false;
        this.changeDetector.markForCheck();
        this.confirmationService.displayHttpErrorAlert(errorData);
      }
    });
  }

  private saveWithKeepCouponsConfirmation(callback: Function) {
    const keepCouponsText = this.translate.instant('resources.campaign_plans.messages.keep_coupons_text');
    const accept = this.translate.instant('common.yes');
    const refuse = this.translate.instant('common.no');
    this.confirmationService.displayConfirmationAlertWithButtons('', keepCouponsText, accept, refuse).then(
      data => {
        // Click on yes will save keeping coupons
        if (data.hasOwnProperty('value') && data.value) {
          this.keepCoupons = true;
          this.save(callback);
        // Click on no will save removing coupons
        } else if (data.hasOwnProperty('dismiss') && data['dismiss'].toString() === 'cancel')  {
          this.keepCoupons = false;
          this.save(callback);
        // Dismissing modal through esc key press or click outside popup will keep user in form
        } else {
          this.nav.preventSave = false;
        }
      }
    ).catch(() => {});
  }

  private saveAndRunCallback(callback: Function) {
    this.loading = true;
    this.nav.preventSave = true;
    this.changeDetector.markForCheck();
    this.prepareSave(callback);
  }

  private saveAudience(eventKey: string) {
    this.audience.triggerSave(eventKey);
  }

  private rejectCampaign() {
    this.loading = true;
    const title = this.translate.instant('resources.campaign_plans.messages.confirm_submit_title');
    const text = this.translate.instant('resources.campaign_plans.messages.confirm_reject_desc');
    this.changeDetector.markForCheck();
    this.confirmationService.displayConfirmationAlert(title, text, 'question').then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.planCampaignsService.reject(`${this.planCampaign.id}`).pipe(takeUntil(this.destroy$)).subscribe({
          next: (_: HttpResponse<any>) => {
            this.loading = false;
            const rejectTitle = this.translate.instant('resources.campaign_plans.messages.reject_success_title');
            const rejectSuccess = this.translate.instant('resources.campaign_plans.messages.reject_success_desc');
            this.closeModal();
            this.confirmationService.displaySuccessAlert(rejectTitle, rejectSuccess).catch(() => {});
            this.modalStatusService.modalStatus.emit();
            this.changeDetector.markForCheck();
          },
          error: (errorData: HttpErrorResponse) => {
            this.loading = false;
            this.confirmationService.displayHttpErrorAlert(errorData)
            this.changeDetector.markForCheck();
          }
        });
      }
    }).catch(() => {});
  }

  private submitCampaign() {
    this.loading = true;
    const title = this.translate.instant('resources.campaign_plans.messages.confirm_submit_title');
    const desc = this.translate.instant('resources.campaign_plans.messages.confirm_submit_desc');
    this.changeDetector.markForCheck();
    this.confirmationService.displayConfirmationAlert(title, desc, 'question').then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.planCampaignsService.submit(`${this.planCampaign.id}`).pipe(takeUntil(this.destroy$)).subscribe({
          next: (response: {warning_messages: string[]}) => {
            this.loading = false;
            const successTitle = this.translate.instant('resources.campaign_plans.messages.submit_success_title');
            const textTitle = this.translate.instant('resources.campaign_plans.messages.submit_success_desc');
            this.closeModal();
            if (response.warning_messages?.length > 0) {
              const textWarnings = this.translate.instant('resources.campaign_plans.messages.submit_success_warnings_desc');
              const warnings = `${textWarnings}: <strong>${response.warning_messages.join(', ')}</strong>`;
              this.confirmationService.displayAlertWithHtml(successTitle, warnings, 'warning').catch(() => {});
            } else {
              this.confirmationService.displaySuccessAlert(successTitle, textTitle).catch(() => {});
            }
            this.modalStatusService.modalStatus.emit();
            this.changeDetector.markForCheck();
          },
          error: (errorData: HttpErrorResponse) => {
            this.loading = false;
            this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error);
            this.changeDetector.markForCheck();
          }
        });
      }
    }).catch(() => {});
  }

  private getRequestMethod(): Observable<any> {
    if (isNullOrUndefined(this.planCampaign)) { return this.planCampaignsService.createCampaign(this.getPayload()); }
    return this.planCampaignsService.patchCampaign(this.getPayload(), `${this.planCampaign.id}`);
  }

  private getPayload(): object {
    let payload;
    if (this.supplierDefinition) {
      payload = this.supplierDefinition.getPayload();
    } else if (this.upCrossDefinition) {
      payload = this.upCrossDefinition.getPayload();
    } else if (this.recoveryDefinition) {
      payload = this.recoveryDefinition.getPayload();
    } else if (this.definition) {
      payload = this.definition.getPayload();
    }
    payload['plan_id'] = this.plan.id;
    payload['keep_coupons'] = this.keepCoupons;
    return payload;
  }

  private routeSub() {
    this.route.parent.parent.params.pipe(takeUntil(this.destroy$)).subscribe(
      planDetailParams => {
        const planId = planDetailParams.hasOwnProperty('id') ? planDetailParams.id : null;
        if (!isNullOrUndefined(planId)) {
          this.getPlan(planDetailParams.id);
        }
      }
    );
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(
      params => {
        this.slug = this.route.snapshot.data.slug;
        this.currentMode = this.route.snapshot.data.mode;
        this.currentStep = this.route.snapshot.data.step_num;
        if (!isNullOrUndefined(params.id)) {
          this.getPlanCampaign(params.id, true);
        } else {
          this.planCampaign = null;
          this.planCampaignLoader = false;
        }
        this.changeDetector.markForCheck();
      }
    );
  }

  private getPlan(id: string) {
    this.plansService.getPlanById(id).pipe(takeUntil(this.destroy$)).subscribe({
      next: planData => {
        this.plan = new Plan(planData);
        this.plansService.currentPlan = this.plan;
        this.changeDetector.markForCheck();
      },
      error: errorData => {
        this.plan = null;
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error);
        this.changeDetector.markForCheck();
      }
    });
  }

  private getPlanCampaign(id: string, firstTime?: boolean) {
    this.planCampaignLoader = true;
    this.planCampaignsService.getOne(id).pipe(takeUntil(this.destroy$)).subscribe({
      next: campaignPlanData => {
        this.selectedType = this.getStatusPlanCampaignType(campaignPlanData);
        if(firstTime){
          this.originalPlanCampaign = new PlanCampaign(campaignPlanData);
        }
        this.planCampaign = new PlanCampaign(campaignPlanData);
        this.planCampaignLoader = false;
        this.changeDetector.markForCheck();
      },
      error: errorData => {
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error);
        this.planCampaignLoader = false;
        this.changeDetector.markForCheck();
      }
    });
  }

  private getStatusPlanCampaignType(rawResponse: Object): CampaignType {
    return this.plansService.getStaticTypes().find((planType: CampaignType) => planType.type === rawResponse['type']);
  }
}
