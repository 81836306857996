import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { FeatureFlagsService } from '../../../../shared/services/feature-flags.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { nameAndDescriptionPayload } from '../../../data-warehouse/data-warehouse-coupons/form-coupons/coupon-utils/coupon-utils';
import { Subject, takeUntil } from 'rxjs';
import { TierDefinition} from '../tier';
import { TiersComponent } from '../tiers-management.component';
import { TiersConfigComponent } from '../tiers-config/tiers-config.component';
import { TiersManagementService } from '../tiers-management.service';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../../../profiles/profile.service';

@Component({
  selector: 'app-tier-container',
  templateUrl: './tier-container.component.html',
  styleUrls: ['./tier-container.component.css']
})

export class TierContainerComponent implements OnDestroy{

  flags = this.featureFlags.flags;
  hasNullName: boolean;
  id: number;
  levelId: number;
  loading: boolean;
  mode: string;
  type: string;
  valuesState: boolean = true;

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('tierDefinition') tierDefinition: TiersComponent;
  @ViewChild('tierConfig') tierConfig: TiersConfigComponent;

  constructor(
    private confirmationService: ConfirmationService,
    private featureFlags: FeatureFlagsService,
    private modalStatusService: ModalStatusService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private tiersManagementService: TiersManagementService,
    private translate: TranslateService
  ) {
    this.id = this.route.snapshot.params.id;
    this.mode = this.route.snapshot.data.mode;
    this.type = this.route.snapshot.data.type;
    this.tiersManagementService.setVariables(this.mode, this.type);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChildResponse(response: TierDefinition) {
    this.levelId = response.achievement_definitions[0].id;
  }

  save() {
    const payload = this.type === 'challenge' ? this.getChallengePayload() : this.getPayload();
    const companyLanguage = this.profileService.getProfileCompany().locale.split('_')[0];
    const translatePath = 'resources.scores.challenges.warnings';

    if (this.type === 'challenge' && this.flags.challengesAvailableLanguagesVisibility && !payload['available_languages']?.includes(companyLanguage)) {
      return this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), this.translate.instant(`${translatePath}.company_lang_not_selected`));
    }
    if (!payload.name) {
      return this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), this.translate.instant(`${translatePath}.name_required`));
    }
    this.confirmSave(payload);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  disableSaveBtn() {
    return !!((this.type === 'challenge' || this.type === 'tier')
               && this.tierDefinition?.tiersDefinitionForm?.invalid
               || this.type === 'tier' && this.tierConfig?.tiersConfigForm.invalid
             );
  }

  private confirmSave(payload) {
    let warningMessage: string;

    if (this.type === 'challenge') {
      warningMessage = this.translate.instant('resources.scores.challenges.messages.save_warning');
    } else {
      warningMessage = this.translate.instant('resources.scores.tiers-management.messages.save_warning');
    }

    this.confirmationService.displayConfirmationAlert(
      this.translate.instant('common.messages.are_you_sure'), warningMessage).then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.triggerRequest(payload);
      }
    }).catch(() => {});
  }

  private triggerRequest(payload) {

    const request$ = this.id
                     ? this.tiersManagementService.updateData(payload, this.id)
                     : this.tiersManagementService.saveData(payload);

    this.loading = true;
    request$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.loading = false;
        this.getConfirmationMessage();
        this.modalStatusService.modalStatus.emit();
        this.closeModal();
      },
      error: (errorData) => {
        this.loading = false;
        this.confirmationService.displayHttpErrorAlert(errorData);
      }
    });
  }

  private setLevelPayload() {
    const levelDefinitions = [{
      customer_attribute_definition_id: this.tierDefinition?.tiersDefinitionForm?.value.customer_attribute?.[0]?.id || null,
      id: this.type === 'challenge' && this.mode === 'update' ? this.levelId : null,
      name: this.tierDefinition?.tiersDefinitionForm?.getRawValue().level_name,
      threshold: this.tierDefinition?.tiersDefinitionForm?.getRawValue().threshold_challenge,
      tier_level: 1,
    }];
    return levelDefinitions;
  }

  private getChallengePayload() {

    const payload = {
      achievement_definitions: this.setLevelPayload(),
      achievement_type: this.type,
      active: Boolean(this.tierDefinition?.tiersDefinitionForm?.value.active),
      challenge_definition: {
        available_from: this.tierDefinition?.tiersDefinitionForm?.value?.available_from || null,
        available_to: this.tierDefinition?.tiersDefinitionForm?.value?.available_to || null,
        display_from: this.tierDefinition?.tiersDefinitionForm?.value?.display_from || null,
        display_to: this.tierDefinition?.tiersDefinitionForm?.value?.display_to || null,
        challenge_type: this.tierDefinition?.tiersDefinitionForm?.value?.challenge_type?.[0]?.id || null,
        image: this.tierDefinition?.image,
        max_enrollments: parseInt(this.tierDefinition?.tiersDefinitionForm?.value?.max_enrollments),
        open_to_all: !this.tierDefinition?.tiersDefinitionForm?.value?.open_to_all,
        partner_id: this.tierDefinition?.tiersDefinitionForm?.value?.partner_id?.[0]?.id || null,
      },
      countries: this.tierDefinition?.tiersDefinitionForm?.value.countries.map(el => el.id),
      description: this.tierDefinition?.tiersDefinitionForm?.value.description,
      expiration_relative_value: this.tierDefinition?.tiersDefinitionForm?.value.expiration_relative_value,
      expiration: this.tierDefinition?.tiersDefinitionForm?.value.expiration?.length > 0 ? this.tierDefinition.tiersDefinitionForm.value.expiration[0].id : null,
      name: this.tierDefinition?.tiersDefinitionForm.value?.name,
    }

    if (this.flags.challengesAvailableLanguagesVisibility) {
      nameAndDescriptionPayload(payload, this.tierDefinition.tiersDefinitionForm);
    }
    return payload;
  }

  private getPayload() {
    const achievementDefinitions = [];
    this.tierConfig.tiersConfigForm.controls.tiers['controls'].forEach((tier, index) => {

      if (!tier.value.hasOwnProperty('threshold_tier')) {
        tier.value.threshold_tier = parseInt(tier.controls.threshold_tier.value, 10);
      }
      tier.value.threshold_tier = tier.value.threshold_tier === "" ? null : parseInt(tier.value.threshold_tier);

      if(this.id && tier.value.hasOwnProperty('id')) {
        tier.value['customer_attribute_definition_id'] = this.tierDefinition.tiersDefinitionForm.value.customer_attribute[0].id;
      } else {
        tier.value = {...tier.value, condition: 'between', tier_level: index + 1, kind: 'analytical', customer_attribute_definition_id: this.tierDefinition.tiersDefinitionForm.value.customer_attribute ? this.tierDefinition.tiersDefinitionForm.value.customer_attribute.length > 0 && this.tierDefinition.tiersDefinitionForm.value.customer_attribute[0].id : null}
      }
      achievementDefinitions.push(tier.value)
      achievementDefinitions[index].threshold = achievementDefinitions[index].threshold_tier;
      delete achievementDefinitions[index].threshold_tier;
    });

    const payload = {
      achievement_definitions: achievementDefinitions,
      name: this.tierDefinition.tiersDefinitionForm.value.name,
      description: this.tierDefinition.tiersDefinitionForm.value.description,
      expiration: this.tierDefinition.tiersDefinitionForm.value.expiration && this.tierDefinition.tiersDefinitionForm.value.expiration.length > 0 ? this.tierDefinition.tiersDefinitionForm.value.expiration[0].id : null,
      expiration_relative_value: this.tierDefinition.tiersDefinitionForm.value.expiration_relative_value,
      countries: this.tierDefinition.tiersDefinitionForm.value.countries.map(el => el.id),
      active: this.tierDefinition.tiersDefinitionForm.value.active,
    }
    return payload;
  }

  private getConfirmationMessage() {
    const baseTranslationPath = 'resources.scores.tiers-management.messages.';
    const type = this.type === 'tier' ? 'tiers' : 'challenge';
    const mode = this.mode === 'create' ? '' : 'update_';
    const text = this.translate.instant(`${baseTranslationPath}${type}_${mode}success_text`);

    if (this.mode === 'create') {
      this.confirmationService.displayAlert(text, '', 'success', false, false).catch(() => {});
    } else if (this.mode === 'update') {
      this.confirmationService.displayAlert(text, '', 'success', false, false).catch(() => {});
    }
  }

}
