import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { Observable, Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { TextareaQuestion } from '../../../../shared/models/forms/question-textarea';
import { CampaignsService } from '../../../campaigns/campaigns.service';
import { RadioQuestion } from '../../../../shared/models/forms/question-radio';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { CmsService } from '../../campaign-news-list/cms.service';
import { PushTemplatesService } from '../../../../shared/services/push-templates.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { ContentDesignerService } from '../../content-designer.service';
import { ProfileService } from '../../../../profiles/profile.service';
import { CurrentCompany } from '../../../../shared/models/current-user';
import { FeatureFlagsService } from '../../../../shared/services/feature-flags.service';

@Component({
  selector: 'app-push-template-edit',
  templateUrl: './push-template-edit.component.html',
  styleUrls: ['./push-template-edit.component.scss'],
  providers: [ContentDesignerService]
})
export class PushTemplateEditComponent implements OnInit, OnDestroy, AfterViewInit {

  static CRUD_MODES = ['create', 'update', 'show'];

  form: UntypedFormGroup;
  inputs: QuestionBase<any>[];
  id: number;
  mode: string;
  title = '';
  displayNewsSelection = false;
  loading: boolean = false;
  isInCrudMode: boolean;
  userType$ = new Subject<string | null>();
  titleMessageMetrics = {
    length: 0,
    remaining_characters: 20
  };
  bodyMessageMetrics = {
    length: 0,
    remaining_characters: 60
  };
  hasExternalApp: boolean;
  flags = this.featureFlags.flags;

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('modalToggleBtn', {static: true}) modalToggleBtn: ElementRef;

  @Output() pushEmitter: EventEmitter<any> = new EventEmitter();
  @Input() template: any;
  @Input() modeReminder: string;

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private campaignService: CampaignsService,
    private modalService: ModalStatusService,
    private cmsService: CmsService,
    private pushService: PushTemplatesService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private contentDesignerService: ContentDesignerService,
    private profileService: ProfileService,
    private featureFlags: FeatureFlagsService
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({});
    this.getMode();
    if (this.isInCrudMode && this.modeReminder !== 'plan_manual_campaign') {
      this.getParams();
    } else {
      this.inputs = this.setInputs({});
      this.form = this.qcs.toFormGroup(this.inputs);
    }
    this.hasExternalApp = new CurrentCompany(this.profileService.getProfileCompany()).hasExternalApp;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  open(mode: string) {
    this.setMode(mode);
    this.modalToggleBtn.nativeElement.click();
    if (mode === 'readonly' || mode === 'show') {
      this.title = this.translate.instant('resources.campaign_templates.push_title_view');
      this.inputs.forEach( input => input.disabled = true );
    } else {
      this.title = this.translate.instant('resources.campaign_templates.push_title');
      this.inputs.forEach( input => input.disabled = false );
      this.form.controls['redirectOption'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
        next: () => this.handleRedirectValueChanged()
      });
      this.form.controls['redirectStateName'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
        next: () => this.handleRedirectNameChanged()
      });
      this.calculatePUSH(this.form.get('subject').value, 'titleMessageMetrics');
      this.calculatePUSH(this.form.get('message').value, 'bodyMessageMetrics');
    }
  }

  detectTyping(event) {
    this.userType$.next(event.target.value);
  }

  openAndViewTemplate(mode: string, template: object) {
    this.displayNewsSelection = false;
    this.template = template;
    this.ngOnInit();
    this.open(mode);
  }

  save() {
    if (this.isInCrudMode) {
      this.handleRequestsIfCrud();
    } else {
      this.pushEmitter.emit(this.pushService.parsePushForm(this.form.value));
      this.close();
    }
  }

  closeWithoutSaving() {
    if (this.isInCrudMode) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.modalToggleBtn.nativeElement.click();
    }
  }

  refreshTemplate(template: any) {
    this.template = template;
    this.inputs = this.setInputs({});
    this.form = this.qcs.toFormGroup(this.inputs);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  handleRedirectionChanged(value: any) {
    if (value && value.id === 'tab.news-show') {
      this.displayNewsSelection = true;
    } else {
      this.displayNewsSelection = false;
    }
  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      this.calculatePUSH(this.form.get('subject').value, 'titleMessageMetrics');
      this.calculatePUSH(this.form.get('message').value, 'bodyMessageMetrics');
    }, 500);

    this.userType$.pipe(debounceTime(700))
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.calculatePUSH(this.form.get('subject').value, 'titleMessageMetrics');
          this.calculatePUSH(this.form.get('message').value, 'bodyMessageMetrics');
        }
      }
    );
  }

  private calculatePUSH(data, metricsKey) {
    this.contentDesignerService.calculateSmsMetrics(data ?? '').pipe(takeUntil(this.destroy$)).subscribe({
      next: (resp) => this[metricsKey] = resp
    });
  }

  private getMode() {
    this.setMode(this.route.snapshot.data.mode);
  }

  private setMode(mode: string) {
    this.mode = mode;
    this.isInCrudMode = PushTemplateEditComponent.CRUD_MODES.indexOf( mode ) >= 0;
  }

  private getParams() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe({
      next: params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getPushTemplateById();
      } else {
        this.inputs = this.setInputs({});
        this.form = this.qcs.toFormGroup(this.inputs);
        if (this.mode === 'create') {
          this.open(this.mode);
        }
      }
   }});
  }

  private getPushTemplateById() {
    this.campaignService.getCampaignTemplateById(this.id).pipe(takeUntil(this.destroy$)).subscribe(
      data => {
        this.inputs = this.setInputs(data);
        this.form = this.qcs.toFormGroup(this.inputs);
        this.open(this.mode);
      }
    );
  }

  private close() {
    this.modalToggleBtn.nativeElement.click();
  }

  private displayAlert() {
    this.confirmationService.displaySuccessAlert(
      this.translate.instant('resources.push-templates.warnings.push_success_title'),
      this.translate.instant('resources.push-templates.warnings.push_success_text')
    ).catch(() => {});
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    this.modalService.modalStatus.emit();
  }

  private handleRequestsIfCrud() {
    if (this.mode === 'create') {
      if (!this.form.value.message || !this.form.value.subject) {
        this.confirmationService.displayErrorAlert('Error', this.translate.instant('resources.push-templates.warnings.push_error_text'));
      }
    }
    this.getRequestCrudMethod().pipe(takeUntil(this.destroy$)).subscribe({
      next: () => this.displayAlert()
    });
    this.pushEmitter.emit(this.pushService.parsePushForm(this.form.value));
    this.close();
  }

  private getRequestCrudMethod(): Observable<any> {
    return this.mode === 'create' ?
      this.contentDesignerService.createCampaignTemplate(this.pushService.parsePushTemplateValues(this.form.value)) :
      this.contentDesignerService.updateCampaignTemplateById(this.pushService.parsePushTemplateValues(this.form.value), this.id);
  }

  private handleRedirectValueChanged() {
    this.inputs.find( input => input.key === 'url' ).value = null;
    this.inputs.find( input => input.key === 'redirectStateName' ).value = [];
    this.inputs.find( input => input.key === 'newsId' ).value = [];
    this.inputs.find( input => input.key === 'redirectOption').value = this.form.get('redirectOption').value;
    this.form.patchValue({ 'redirectStateName': null, 'newsId': null, 'url': null }, { emitEvent: false });
    this.displayNewsSelection = false;
  }

  private handleRedirectNameChanged() {
    this.inputs.find( input => input.key === 'newsId' ).value = [];
    this.form.patchValue({ 'newsId': null }, { emitEvent: false });
  }

  private getRedirectOptions() {
    const appVersion = this.profileService.getStoredUser().company.app_version;
    const hasAppVersion = appVersion && Object.keys(appVersion).length > 0;
    const redirectOpts = this.campaignService.getRedirectOptions();
    return hasAppVersion ? redirectOpts : redirectOpts.filter( opt => opt.id !== 'app' );
  }

  private setInputs(formValue) {
    const inputs = [
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'subject',
        order: 1,
        type: 'text',
        getValue: (value) => value
      }),
      new TextareaQuestion({
        cssClasses: 'form-control input-md',
        key: 'message',
        order: 2,
        type: 'text',
        getValue: (value) => value
      }),
      new RadioQuestion({
        cssClasses: 'radio-inline radio-info',
        key: 'redirectOption',
        order: 3,
        type: 'radio',
        options: this.getRedirectOptions(),
        value: 'none',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'url',
        order: 4,
        type: 'text',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'redirectStateName',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 5,
        options: this.campaignService.getRedirectStates(),
        value: this.getRedirectStateName(formValue)
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'newsId',
        dataSource: this.cmsService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 6,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        cssClasses: 'radio-inline radio-info',
        key: 'externalRedirectStateName',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 7,
        options: this.campaignService.getExternalRedirectStates(),
        value: this.getExternalRedirectStateName(formValue)
      })
    ];

    if (this.mode === 'update' || this.mode === 'show') {

      this.template = this.template ? this.template : {};

      if (formValue.hasOwnProperty('schema')) {
        this.template.schema = formValue['schema'];
      }

      if (formValue.hasOwnProperty('schema') &&
          formValue.schema.hasOwnProperty('values') &&
          formValue.schema.values.hasOwnProperty('url')) {
        this.template.values = {};
        this.template.values.url = formValue.schema.values['url'];
      }

      if (formValue.hasOwnProperty('name')) {
        inputs.find( input => input.key === 'subject' ).value = formValue['name'];
        this.template.subject = formValue['name'];
      }

      if (formValue.hasOwnProperty('html')) {
        inputs.find( input => input.key === 'message' ).value = formValue['html'];
        this.template.message = formValue['html'];
      }
    }

    this.setInputValuesByTemplate(inputs);
    this.syncFormWithData();
    return inputs;
  }


  private getRedirectStateName(formValue: unknown) {
    const externalRedirectState = formValue?.['schema']?.['redirectState']?.['name'];
    if (!this.hasExternalApp && externalRedirectState) {
      return [this.campaignService.getRedirectStates().find(el => el.id === externalRedirectState)];
    }
  }

  private getExternalRedirectStateName(formValue: unknown) {
    const externalRedirectState = formValue?.['schema']?.['redirectState']?.['name'];
    if (this.hasExternalApp && externalRedirectState) {
      return [this.campaignService.getExternalRedirectStates().find(el => el.id === externalRedirectState)];
    }
  }

  private setInputValuesByTemplate(inputs: QuestionBase<any>[]) {
    if (this.template && (this.template.hasOwnProperty('schema') || this.template.hasOwnProperty('values'))) {
      let input = null;
      input = inputs.find( _input => _input.key === 'subject');
      input.value = input.getValue(this.template.subject);
      input = inputs.find( _input => _input.key === 'message');
      input.value = input.getValue(this.template.message);

      if ( this.template.hasOwnProperty('schema') &&
           this.template.schema &&
           this.template.schema.hasOwnProperty('redirectState') &&
           this.template.schema.redirectState &&
           this.template.schema.redirectState.hasOwnProperty('name') &&
           this.template.schema.redirectState.name ) {

        if ( this.template.schema.redirectState.hasOwnProperty('params') &&
             this.template.schema.redirectState.params.hasOwnProperty('newsId') &&
             this.template.schema.redirectState.params.newsId ) {

          input = inputs.find( _input => _input.key === 'newsId');
          input.selectedIds = input.getValue(this.template.schema.redirectState.params.newsId).selectedIds;
        }

      } else if ( this.template.hasOwnProperty('schema') &&
                  this.template.schema &&
                  this.template.schema.hasOwnProperty('values') && 
                  this.template.schema.values &&
                  this.template.schema.values.hasOwnProperty('url') &&
                  this.template.schema.values.url) {

        inputs.find( _input => _input.key === 'redirectOption').value = 'url';
        inputs.find( _input => _input.key === 'url').value = this.template.schema.values.url;

      } else if ( this.template.hasOwnProperty('values') &&
                  this.template.values &&
                  this.template.values.hasOwnProperty('url') &&
                  this.template.values.url ) {
        inputs.find( _input => _input.key === 'redirectOption').value = 'url';
        inputs.find( _input => _input.key === 'url').value = this.template.values.url;
      }
    }
  }

  private syncFormWithData() {
    setTimeout(() => {
      const redirectStateNameValue = this.form.get('redirectStateName').value;
      const externalRedirectStateNameValue = this.form.get('externalRedirectStateName').value;
      if(redirectStateNameValue?.[0]?.['id'] || externalRedirectStateNameValue?.[0]?.['id']) {
        this.form.patchValue({ 'redirectOption': 'app' }, { emitEvent: false });
        if ( redirectStateNameValue?.[0]?.['id'] === 'tab.news-show' ) {
          this.displayNewsSelection = true;
        }
      }
    });
  }
}
