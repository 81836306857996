<ng-container *ngIf="inputs">
  <div class="row">
    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('date_from')" [form]="form"></app-question>
    </div>
    
    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('date_to')" [form]="form"></app-question>
    </div>
    
    <div *ngIf="flags.showLocationTaxonomyTermIds" class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('location_taxonomy_term_ids')" 
                    [form]="form"
                    (multiselectChanged)="handleSelectedLocationTaxonomyTermIds($event)">
      </app-question>
    </div>

    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('location_ids')" [form]="form"></app-question>
    </div>  
  </div>

  <div class="row mrg-btm-15">
    <div class="col-md-3">
      <app-question [question]="getInputConfig('operator')"
                    [form]="form"
                    (multiselectChanged)="handleOperatorChanges($event)">
      </app-question>
    </div>

    <div class="col-md-2">
      <app-question [question]="getInputConfig('value')" [form]="form"></app-question>
    </div>

    <ng-container *ngIf="hasFormKeyWithValue('operator', 'bt')">
      <div class="col-md-2">
        <app-question [question]="getInputConfig('value_bt')" [form]="form"></app-question>
      </div>
    </ng-container>  

    <div class="col checkbox-non-positive-average">
      <app-question [question]="inputs[7]" [form]="form"></app-question>
    </div>
  </div>
</ng-container>
