import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from '../../condition.service';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBase } from '../../../../models/forms/question-base';
import { MultiSelectQuestion } from '../../../../models/forms/question-multiselect';
import { formGroupEmptyValidator, multiselectPresenceValidator } from '../../../validations.service';
import { defaultParseFormValuesToAPI, getParseMultiselectIdValues, getParseMultiselectValueInOptions, getParseValue, parseParamsValues } from '../../utils/common.utils';
import { Option } from '../../../../models/common/option';
import { GenderCondition } from '../../../../../shared/models/segments/conditions/gender-condition';

@Injectable()
export class GenderConditionService implements SegmentConditionProvider {

  inputs: QuestionBase<any>[];
  customValidators = formGroupEmptyValidator();

  constructor( private translate: TranslateService ) { }

  getInputs(params?: GenderCondition): QuestionBase<any>[] {
    const inputs = [
      new MultiSelectQuestion({
        key: 'gender',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.gender'),
        options: this.getGenderOptions(),
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: false },
        required: true,
        getValue: getParseValue(),
        parseValue: getParseMultiselectIdValues(),
        customValidators: [multiselectPresenceValidator]
      })
    ];

    parseParamsValues(params, inputs);
    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI(params: GenderCondition): GenderCondition {
    return defaultParseFormValuesToAPI(params, this.inputs);
  }

  prepareFormValuesFromAPI(params: GenderCondition): GenderCondition {
    const parseValues = {
      gender: getParseMultiselectValueInOptions(this.getGenderOptions())
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach(key => { parsedValuesObj[key] = parseValues[key](params[key]); });
    return parsedValuesObj;
  }

  private getGenderOptions(): Option[] {
    return [
      { id: 1, name: this.translate.instant('resources.profiles.form.fields.masculine')},
      { id: 2, name: this.translate.instant('resources.profiles.form.fields.femenine')}
    ];
  }
}
