<div class="card-recipe">
  <div class="card" [class.disabled]="!checked">
    <!-- Switch toggle -->
    <div class="card__toggle">
      <p-inputSwitch [(ngModel)]="checked" name="switch"></p-inputSwitch>
    </div>
    <div class="card__content">
      <!-- Title -->
      <h4 class="card__title text-bold">{{ title }}</h4>
      <!-- Description -->
      <p class="card__text text-dark">{{ text }}</p>
      <!-- State -->
      <p-chip label="{{ checked ? 'Active' : 'Off' }}" styleClass="card__chip"></p-chip>
      <!-- Icon -->
      <div class="card__icon" *ngIf="icon">
        <em class="{{ icon }}" style="font-size: 36px" [style.color]="'#1D8CFA'"></em>
      </div>
    </div>
    <div class="card__bottom-decor">
      <!-- Action -->
      <div class="action">
        <em class="action__icon pi pi-eye"></em>
        <em class="action__icon fa-light fa-pen-to-square"></em>
        <em class="action__icon pi pi-trash"></em>
      </div>
    </div>
  </div>
</div>