import { Component, OnInit } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';

@Component({
  selector: 'app-show-credit-transactions',
  templateUrl: './show-credit-transactions.component.html',
  styleUrls: ['./show-credit-transactions.component.css']
})
export class ShowCreditTransactionsComponent implements OnInit {

  public element: Object;
  public apiEndPoint: string;
  public id: number;
  public isModal: boolean;

  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.isModal = this.router.url.indexOf('(modal:show/credit_transactions/') >= 0;
    this.getParams();
    this.resourcesService.getData({ apiEndPoint: this.apiEndPoint + '/' + this.id })
        .subscribe(res => {
          this.element = res;
        },
        error => {
          this.confirmationService.displayErrorAlert('Error', error);
        });
  }

  getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'credit_transactions';
      }
    });
  }

}
