import { Injectable } from '@angular/core';
import { ResourcesService } from './resources.service';
import { MultiselectDataSourceable, MultiselectDataSource } from '../components/multiselect/multiselect';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AttributesConditionsService extends ResourcesService implements MultiselectDataSourceable {

  constructor(http: HttpClient) {
    super(http);
  }

  public fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'coupons/attributes_conditions',
      numberPerPage: 10,
      filtering: {},
      pageNumber: 1,
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData(requestOptions);
  }

  public fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `coupons/attributes_conditions` });
  }

  getConditionById(id) {
    return this.getData({ apiEndPoint: `segment_conditions/${id}` });
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.value, element.name);
  }

}
