import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourcesService } from './resources.service';
import { MultiselectDataSource, MultiselectDataSourceable } from '../components/multiselect/multiselect';


@Injectable()
export class ProfilesCitiesService extends ResourcesService implements MultiselectDataSourceable {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {

    let requestOptions = {
      apiEndPoint: 'profiles/cities',
      numberPerPage: 10,
      pageNumber: 1,
      sorting: {},
      filtering: {}
    };

    requestOptions.pageNumber = page ?? requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { city: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData(requestOptions);
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    return new MultiselectDataSource(element.city, element.city);
  }
}
