import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { Subject, takeUntil } from 'rxjs';
import { CustomEntity } from '../custom-entity';
import { CustomEntitiesService } from '../custom-entities.service';
import { TranslateService } from '@ngx-translate/core';
import { DataTableConfiguration } from '../../../../shared/components/data-table/data-table-cfg';
import 'brace';
import 'brace/theme/monokai';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomEntityDetail } from '../../../../shared/models/custom-entities/custom-entity-detail';

@Component({
  selector: 'app-show-custom-entities',
  templateUrl: './show-custom-entities.component.html',
  styleUrls: ['./show-custom-entities.component.css']
})
export class ShowCustomEntitiesComponent implements OnInit, OnDestroy {

  customEntitiesCfg: DataTableConfiguration;
  element: CustomEntity;
  id: string;

  private destroy$ = new Subject<void>();

  @Output('updateEmitter') updateEmitter = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private customEntitiesService: CustomEntitiesService,
    private translate: TranslateService
  ){}

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.getEntity();
      }
    });
  }

  private getEntity(): void {
    this.customEntitiesService.getById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: CustomEntity) => {
        const element = response;
        element['entity_attributes'] = JSON.stringify(element['entity_attributes'], null, '\t');
        this.element = element;
        setTimeout(() => {
          this.updateEmitter.emit({
            title: this.element.name,
            updateRedirect: ['', { outlets: { modal: ['update', 'custom-entities', this.id]}}]
          });
          this.setTableCfg();
        });
      },
      error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
    });
  }

  private setTableCfg(): void {
    this.customEntitiesCfg = {
      isActive: true,
      requiresCacheService: false,
      tokenPagination: true,
      requestData: {
        apiEndPoint: `custom_entities`,
        filtering: {
          slug: this.element.slug
        }
      },
      rowActions: [],
      tableActions: [],
      columns: [
        {
          name: this.translate.instant('resources.custom_entities.fields.id'),
          prop: 'id'
        },
        {
          name: this.translate.instant('resources.custom_entities.fields.customer_id'),
          prop: 'customer_id'
        },
        {
          name: this.translate.instant('resources.custom_entities.fields.entity_attributes'),
          prop: 'data'
        },
        {
          name: this.translate.instant('resources.custom_entities.fields.created_at'),
          prop: 'created_at'
        }
      ],
      tableMapping: [
        { prop: 'id', type: 'text', apiProp: 'id' },
        {
          prop: 'customer_id',
          type: 'text',
          apiProp: 'customer_id',
          getValue: (element) => {
            if (element?.customer_id) {
              return {
                parsedValue: element.customer_id,
                callback: (element) => {
                  this.router.navigate([`/data-warehouse/profiles/${element.customer_id}/summary`]).then(() => {
                    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
                  }).catch(() => {});
                }
              };
            } else {
              return null;
            }
          }
        },
        { prop: 'data', type: 'text', apiProp: 'data', getValue: (element) => this.decorateAttrs(element) },
        { prop: 'created_at', type: 'date', apiProp: 'created_at'}
      ]
    };
  }

  private decorateAttrs(rawAttrs: CustomEntityDetail): string {
    if (rawAttrs['data']) {
      let tempAttrs = Object.keys(rawAttrs['data']);
      tempAttrs = tempAttrs.map(str => {
        const value = rawAttrs['data'][str];
        str = str.replace(/\-/g, ' ');
        str = str.replace(/\_/g, ' ');
        str = str.charAt(0).toUpperCase() + str.slice(1);
        str += `: ${value}`;
        return str;
      });
      return tempAttrs.join(', ');
    }
  }
}
