import { CampaignPayload } from '../../../../models/campaign/campaign-payload';
import { CampaignsFormService } from '../../campaigns-form.service';
import { CampaignsService } from '../../../../../resources/campaigns/campaigns.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfirmationService } from '../../../../services/confirmation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { QuestionBase } from '../../../../models/forms/question-base';
import { QuestionControlService } from '../../../../services/question-control.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-campaign-content-block',
  templateUrl: './campaign-content-block.component.html',
  styleUrls: ['./campaign-content-block.component.scss']
})

export class CampaignContentBlockComponent {

  @Input('campaignForm') campaignForm: UntypedFormGroup;
  @Input('inputs') inputs;
  @Input('campaign') campaign;
  @Input('mode') mode;
  @Input('actionRaw') actionRaw;

  @Output() viewOrEditTemplateEmitter = new EventEmitter<any>();

  assignedCouponHistoryId: number;
  assignedSegmentId: number;
  loading: boolean;

  constructor(
    private campaignsFormService: CampaignsFormService,
    private qcs: QuestionControlService,
    private confirmationService: ConfirmationService,
    private campaignsService: CampaignsService,
    private translate: TranslateService
  ){}

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.campaignForm, formKey, value);
  }

  handleTemplateChanges(event, resourceTemplate) {

    if( event && resourceTemplate === 'email' ) {
      this.campaignsFormService.handleEmailTemplateValueChanged( this.campaignForm, this.campaign );
    } else if ( event && resourceTemplate === 'sms' ) {
      this.campaignsFormService.handleSmsTemplateValueChanged( this.campaignForm, this.campaign );
    } else if ( event && resourceTemplate === 'push' ) {
      this.campaignsFormService.handlePushTemplateValueChanged( this.campaignForm, this.campaign );
    }
  }

  viewOrEditTemplate(action:string) {
    this.viewOrEditTemplateEmitter.emit(action);
  }

  submitTest(resource:string) {    
    resource === 'email_test_delivery' ? this.deliverTestBy('email_test_delivery') : 
    resource === 'sms_test_delivery' ? this.deliverTestBy('sms_test_delivery') : 
    resource === 'push_test_delivery' ? this.deliverTestBy('push_test_delivery') : null;
  }

  deliverTestBy(testInputKey: string) {
    this.loading = true;
    const payload = this.campaignsFormService.prepareCampaignWithForm(this.campaignForm.getRawValue(), this.campaign);
    if (this.mode === 'reminder_plan' || this.mode === 'received_reminder' || this.campaign['reminder_plan']) {
      this.addReminderPayload(payload);
      this.assignSegmentAndCouponHistoryToReminder(this.campaign);      
    }
    payload['sender_id'] = this.campaignForm.get('sender_id').value[0]?.id;
    if (!this.allSyncValidatorsOk()) { return; }
    this.campaignsService.saveCampaign(payload).subscribe(
      (campaignData) => {
        // if(this.mode === 'reminder_plan' || this.mode === 'received_reminder') { this.updateRemindersCount.emit(); }
        this.campaign.id = campaignData.id;
        let recipients = this.campaignForm.get(testInputKey).value;
        if (testInputKey.indexOf('push') >= 0) {
          const pre = this.campaignForm.get('push_test_delivery_prefix').value;
          const num = this.campaignForm.get('push_test_delivery').value;
          recipients = `${pre}|${num}`;
        }
        this.campaignsService.sendTest(recipients, this.campaign).subscribe(
          (emailTestData) => {
            this.loading = false;
            if (emailTestData.status !== 'error') {
              this.confirmationService.displaySuccessAlert(
                this.translate.instant('resources.campaigns.messages.campaign_test_delivered_title'),
                this.translate.instant('resources.campaigns.messages.campaign_test_delivered_description')
              ).catch(() => {});
            } else {
              this.confirmationService.displayErrorAlert(
                this.translate.instant('resources.campaigns.messages.campaign_test_delivered_error_title'),
                this.translate.instant('resources.campaigns.messages.campaign_test_delivered_error_description')
              );
            }
          },
          (emailTestError: HttpErrorResponse) => {
            this.loading = false;
            this.confirmationService.displayErrorAlert(
              this.translate.instant('resources.campaigns.messages.campaign_test_delivered_error_title'),
              JSON.stringify(emailTestError.error.error)
            );
          }
        );
      },
      (errorData: HttpErrorResponse) => {
        this.loading = false;
        if (errorData.error && errorData.error.errors && errorData.error.errors.length > 0) {
          this.paintErrorsInForm(errorData.error.errors);
        } else {
          this.confirmationService.displayErrorAlert(
            this.translate.instant('resources.campaigns.modal.alerts.campaign_error'),
            JSON.stringify(errorData.message)
          );
        }
      }
    );
  }

  private paintErrorsInForm(errorsArray) {
    this.campaignsFormService.paintErrorsInForm(this.campaignForm, errorsArray);
  }

  private addReminderPayload(payload: CampaignPayload) {
    if (this.mode === 'reminder_plan') {
      payload['mode'] = 'reminder_plan';
      payload.reminder = true;
      payload['action_id'] = this.actionRaw['actionId'];
    } 
    if (this.mode === 'reminder_plan' && !payload.id ) {
      payload['segment_id'] = -1;
      payload['campaign_history_coupon_id'] = -1;
    }
    if (this.mode === 'received_reminder' || payload.id ) {
      payload['campaign_history_coupon_id'] = this.assignedCouponHistoryId;
    }
  }

  private assignSegmentAndCouponHistoryToReminder(payload: CampaignPayload) {
    if ((this.mode === 'reminder_plan' || this.mode === 'received_reminder') && this.campaign['id'] !== null) {
      payload.segment_id = this.assignedSegmentId;
      payload.campaign_history_coupon_id = this.assignedCouponHistoryId;
    }
  }

  private allSyncValidatorsOk(): boolean {

    let allValid = true;

    if ( this.campaignForm.get('periodic').value && !this.campaignForm.get('execution').value ) {
      const fieldName = this.translate.instant('resources.campaigns.warnings.periodic_execution');
      this.campaignsFormService.paintErrorsInForm(
        this.campaignForm,
        [{
          field: fieldName,
          field_name: fieldName,
          message: this.translate.instant('resources.campaigns.warnings.periodic_execution_required')
        }]
      );
      this.loading = false;
      allValid = false;
    }

    if ( this.campaignForm.get('scheduled_at').value &&
         this.campaignForm.get('execution_until').value &&
         this.campaignForm.get('scheduled_at').value > this.campaignForm.get('execution_until').value ) {
      const fieldName = this.translate.instant('resources.campaigns.warnings.execution_field_name');
      this.campaignsFormService.paintErrorsInForm(
        this.campaignForm,
        [{
          field: fieldName,
          field_name: fieldName,
          message: this.translate.instant('resources.campaigns.warnings.execution_greater_scheduled')
        }]
      );
      this.loading = false;
      allValid = false;
    }

    return allValid;
  }

}
