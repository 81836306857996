import { Component, OnInit } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { LocationsService } from '../../../../data-warehouse/locations/locations.service';
import { SegmentsService } from '../../../../segments/segments.service';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { Subscription } from 'rxjs';
import { MatrixService } from '../../../../../shared/services/matrix.service';
import { TranslateService } from '@ngx-translate/core';
import { DataTableFilterService } from '../../../../../shared/components/data-table-filter/data-table-filter.service';
import { ProfileService } from '../../../../../profiles/profile.service';
import { CurrentCompany } from '../../../../../shared/models/current-user';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-activity-tab',
  templateUrl: './activity-tab.component.html',
  styleUrls: ['./activity-tab.component.css']
})
export class ActivityTabComponent implements OnInit {

  filterQuestions: QuestionBase<any>[];
  defaultFilterQuestions: QuestionBase<any>[];
  subs$: Subscription[] = [];
  loading: boolean;
  activitiesData: any;
  charts: any = {};
  companyData: any;
  segmentCategoryId: any;
  tableHeaders: any;
  filterConfig: { disableOnInit: boolean, disableOnSubmit: boolean, ensureButtonDisabling: boolean };
  company: CurrentCompany;
  filterSlug: string = 'activity-tab-filter';
  chartOptions: {
    columnMenuEnabled: boolean;
    table: any;
    sort: number;
    name: any;
    description: any;
    axisLabels: { x: any; y: any; };
    highLightedColumns: any[];
  };

  constructor(
    private refreshCacheService: RefreshCacheService,
    private locationsService: LocationsService,
    private matrixService: MatrixService,
    private translate: TranslateService,
    private filterService: DataTableFilterService,
    private profileService: ProfileService,
    private segmentService: SegmentsService,
    private segmentsService: SegmentsService
  ) {}

  ngOnInit() {
    this.filterConfig = { disableOnInit: true, disableOnSubmit: true, ensureButtonDisabling: true };
    registerLocaleData(es);
    this.company = new CurrentCompany(this.profileService.getProfileCompany());
    if (this.company.isTagged()) {
      this.filterQuestions = this.setFilterQuestions();
      this.defaultFilterQuestions = this.setFilterQuestions();
      this.companyData = this.profileService.getStoredUser().company;
      this.segmentCategoryId = this.companyData.system_segment_categories.activity;
    }
    setTimeout(() => this.filterService.loaderStatus.next(true));
  }

  onFilterHandler(filters: Object) {
    const params = { apiEndPoint: 'analytics/activities', filtering: {...filters, country: 'ES'}  };
    this.enqueuePollingRequest(params, 'activitiesData');
    this.getSegmentCategoriesById();
  }

  enqueuePollingRequest(requestCfg,  mainProperty) {
    this.loading = true;
    const req$ = this.refreshCacheService.getExpensiveData(requestCfg).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this[mainProperty] = reqResponse;
        this.refresh();
        req$.unsubscribe();
        this.loading = false;
      }
    });
    this.subs$.push(req$);
  }

  private refresh() {
    if ( this.activitiesData ) {
      this.loading = false;
      this.filterService.loaderStatus.next(true);
      this.generateActivityChart(this.activitiesData);
    }
  }

  generateActivityChart(data) {
    let table;
    if (this.tableHeaders.list && this.tableHeaders.list.length) {
      table = this.matrixService.createFromCoordsArray(data.values, [data.names.length, this.tableHeaders.list.length], [1, 0], {addSumRow: true});
    }

    const yLabels = data.names;
    yLabels.push('Total');
    this.chartOptions = {
      columnMenuEnabled: false,
      table: table,
      sort: 1,
      name: this.translate.instant('dashboards.migrations.activity.title'),
      description: this.translate.instant('lg_specs.dashboards.potential.chart_descriptions.activity'),
      axisLabels: {
          x: [],
          y: yLabels
      },
      highLightedColumns: []
    };
    this.charts['activity'] = this.chartOptions;
  }

  getSegmentCategoriesById() {
    this.segmentService.getSegments({
      filtering: {'segment_category_id': this.segmentCategoryId},
      sorting: {
        byProp: 'sort',
        direction: 'asc'
      }
      }).subscribe(
      resp => {
        this.tableHeaders = resp;
      }
    );
  }

  private setFilterQuestions(): QuestionBase<any>[] {
    return [
      new MultiSelectQuestion({
        key: 'location_ids',
        label: 'dashboards.migrations.activity.filters.location',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locationsService,
      }),
      new MultiSelectQuestion({
        key: 'segment_id',
        label: 'dashboards.migrations.activity.filters.segment',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: true },
        dataSource: this.segmentsService,
      })
    ];
  }

}
