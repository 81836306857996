<div class="modal-backdrop fade in modal-fs" id='modal-fs' tabindex="-1" role="dialog" aria-hidden="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="row">
        <div class="col-md-12">
          <div class="modal-header no-pdd">
            <div class="col-md-4">
              <div class="col-md-9 d-flex align-items-center min-height-60px">
                <h1 *ngIf="type === 'tier'" class="no-mrg-vertical mrg-left-5" [translate]="'menu_dia.loyalty_management.sections.tiers_management'"></h1>
                <h1 *ngIf="type === 'challenge'" class="no-mrg-vertical mrg-left-5" [translate]="'menu_dia.loyalty_management.sections.challenges'"></h1>
              </div>
            </div>
            <div class="pdd-vertical-20-but-bottom">
              <button class="modal-close mrg-right-25" (click)="closeModal()">
                <i class="ti-close"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-body height-100">
        <div class="mrg-top-15 container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="position-relative">
                <div class="row">
                  <div [ngClass]="{'col-md-12': type === 'challenge', 'col-md-5': type === 'tier'}">
                    <app-tiers (responseEmitter)="onChildResponse($event)" #tierDefinition></app-tiers>
                  </div>
                  <div *ngIf="type === 'tier'" class="col-md-7">
                    <app-tiers-config #tierConfig></app-tiers-config>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- FOOTER -->
      <div class="row">
        <div class="col-md-12">
          <div class="modal-footer background-white">
            <button class="btn btn-info mrg-right-15"
                    (click)="save()"
                    [hidden]="mode === 'show'"
                    [disabled]="this.disableSaveBtn() || loading">
              <span class="mrg-right-10" *ngIf="loading">
                <em class="fa fa-spinner fa-spin"></em>
              </span>
              {{'common.buttons.save' | translate}}
            </button>
          </div>
        </div>
      </div>
      <!-- END FOOTER -->
  </div>
</div>
