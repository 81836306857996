import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ApiKey } from "../api-key";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiKeyService } from "../api-key-service";
import { ConfirmationService } from "../../../../shared/services/confirmation.service";
import { TranslateService } from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-show-api-key',
  templateUrl: './api-keys-detail.component.html',
  styleUrls: ['./api-keys-detail.component.scss']
})

export class ApiKeyDetailComponent implements OnInit {
  apiKeyId = this.route.snapshot.params.id;
  apiKey: ApiKey

  @Output('updateEmitter') updateEmitter: EventEmitter<any>  = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private apiKeyService: ApiKeyService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private router: Router
  ){}

  ngOnInit(): void {
    this.getApiKeyById();
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getApiKeyById() {
    this.apiKeyService.getById(this.apiKeyId).subscribe(
      (partner: ApiKey) => this.handleSuccessfulResponse(partner),
      (err: HttpErrorResponse) => this.handleErrorResponse(err)
    )
  }

  private handleSuccessfulResponse(apiKey: ApiKey) {
    this.apiKey = apiKey;
    setTimeout(() => {
      if(this.apiKey.system) {
        this.updateEmitter.emit(['', { outlets: { modal: null }}]);
      } else {
        this.updateEmitter.emit(['', { outlets: { modal: ['update', 'api-key', this.apiKey.id]}}]);
      }
    });
  }

  private handleErrorResponse(err: HttpErrorResponse) {
    this.confirmationService.displayErrorAlert(
      this.translate.instant('common.error'),
      err.error.error
    )
    this.closeModal();
  }
}