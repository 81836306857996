import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CouponsService } from '../../../coupons/coupons.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';
import { S3Service } from '../../../../shared/services/s3.service';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { TranslateService } from '@ngx-translate/core';
import { CsvExportService } from '../../../../shared/services/csv-export.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-import-coupons',
  templateUrl: './import-coupons.component.html',
  styleUrls: ['./import-coupons.component.css'],
  providers: [CouponsService]
})

export class ImportCouponsComponent implements OnInit {

  public couponsForm: UntypedFormGroup;
  public inputs: QuestionBase<any>[];
  public mode: string;
  public apiEndPoint: string;

  constructor(
    private route: ActivatedRoute,
    private qcs: QuestionControlService,
    private confirmationService: ConfirmationService,
    private modalStatusService: ModalStatusService,
    private translate: TranslateService,
    private couponsService: CouponsService,
    private s3Service: S3Service,
    private router: Router,
    private csvExportService: CsvExportService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.getMode();
    this.getParams();
  }

  getParams() {
    this.route.params.subscribe(() => {
      this.inputs = this.couponsService.getInputs({});
      this.couponsForm = this.qcs.toFormGroup(this.inputs);
      this.apiEndPoint = 'import_coupons';
    });
  }

  getMode() {
    this.mode = this.route.snapshot.data.mode;
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  downloadTemplate() {
    this.http.get('/coupons/csv_template', { responseType: 'text' as 'json'}).subscribe(data => {
      this.downloadCSV(data);
    });
  }

  downloadCSV(data) {
    const blob = this.csvExportService.getCsvBlob(data);
    const url = window.URL.createObjectURL(blob);
    const filename = `csv_template.csv`;

    if (navigator['msSaveOrOpenBlob']) {
      navigator['msSaveBlob'](blob, filename);
    } else {
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }


  public sendData() {
    const files = this.couponsForm.value.csv;
    if (files && files.length > 0) {
      const file = files[0];
      this.s3Service.uploadFile(file).then(filename => {
        this.couponsService.importData(filename).subscribe(() => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.coupons.form.warnings.import_title'),
            this.translate.instant('resources.coupons.form.warnings.import_text')
          ).catch(() => {});
          this.modalStatusService.modalStatus.emit();
          this.closeModal();
        }, err => {
          this.confirmationService.displayErrorAlert('Error', err.error.error);
          this.closeModal();
        });
      });
    }
  }

}
