import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from './condition.service';
import { QuestionBase } from '../../models/forms/question-base';
import { MultiSelectQuestion } from '../../models/forms/question-multiselect';
import { TranslateService } from '@ngx-translate/core';
import { multiselectPresenceValidator, checkControlValuePresence } from '../validations.service';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

@Injectable()
export class CustomerChannelStatusConditionService implements SegmentConditionProvider {

  public inputs: QuestionBase<any>[];
  public customValidators = [(control: UntypedFormGroup) => this.getFormGroupValidations(control)];

  constructor( private translate: TranslateService ) { }

  public getInputs(params?: any): QuestionBase<any>[] {

    const paramsAreDefined = ( params && Object.keys(params).length > 0 );
    const channel = paramsAreDefined ? this.elementByKeyInAry(params, 'channel', this.getChannels()) : [];
    const status_email = paramsAreDefined ? this.elementByKeyInAry(params, 'status_email', this.getEmailChannelsStatus()) : [];
    const status_sms = paramsAreDefined ? this.elementByKeyInAry(params, 'status_sms', this.getSmsChannelsStatus()) : [];
    const status_push = paramsAreDefined ? this.elementByKeyInAry(params, 'status_push', this.getAppChannelsStatus()) : [];

    const inputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'channel',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.getChannels(),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        getValue: (value) => (value && value.length) ? value : null,
        parseValue: (value) => {
          return (value && value.length) ? this.getChannels().find( operator => operator.id === value[0].id ).id : null;
        },
        value: channel ? channel : [],
        customValidators: [multiselectPresenceValidator]
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'status_push',
        label: this.translate.instant('resources.segment_conditions.fields.channel_status'),
        options: this.getAppChannelsStatus(),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        getValue: (value) => (value && value.length) ? value : null,
        parseValue: (value) => {
          return (value && value.length) ? this.getAppChannelsStatus().find( operator => operator.id === value[0].id ).id : null;
        },
        value: status_push ? status_push : []
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'status_email',
        label: this.translate.instant('resources.segment_conditions.fields.channel_status'),
        options: this.getEmailChannelsStatus(),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        getValue: (value) => (value && value.length) ? value : null,
        parseValue: (value) => {
          return (value && value.length) ? this.getEmailChannelsStatus().find( operator => operator.id === value[0].id ).id : null;
        },
        value: status_email ? status_email : []
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'status_sms',
        label: this.translate.instant('resources.segment_conditions.fields.channel_status'),
        options: this.getSmsChannelsStatus(),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        getValue: (value) => (value && value.length) ? value : null,
        parseValue: (value) => {
          return (value && value.length) ? this.getSmsChannelsStatus().find( operator => operator.id === value[0].id ).id : null;
        },
        value: status_sms ? status_sms : []
      }),
    ];
    this.inputs = inputs;
    return this.inputs;
  }

  public prepareFormValuesToAPI( params: any ): any {
    const parsedValuesObj = {};
    Object.keys(params).forEach( key => {
      const input = this.inputs.find( _input => _input.key === key );
      const parsedValue = input.parseValue( params[key], parsedValuesObj );
      parsedValuesObj[key] = parsedValue;
    });

    switch (parsedValuesObj['channel']) {
      case 'push':
        parsedValuesObj['status'] = parsedValuesObj['status_push'];
        break;
      case 'email':
        parsedValuesObj['status'] = parsedValuesObj['status_email'];
        break;
      case 'sms':
        parsedValuesObj['status'] = parsedValuesObj['status_sms'];
        break;
      default:
        break;
    }

    delete parsedValuesObj['status_push'];
    delete parsedValuesObj['status_email'];
    delete parsedValuesObj['status_sms'];

    return parsedValuesObj;
  }

  public prepareFormValuesFromAPI( params: any ): any {
    if (params && params.hasOwnProperty('channel') && params.channel ) {
      switch (params.channel) {
        case 'push':
          params.status_push = this.elementByKeyInAry(params, 'status', this.getAppChannelsStatus());
          break;
        case 'email':
          params.status_email = this.elementByKeyInAry(params, 'status', this.getEmailChannelsStatus());
          break;
        case 'sms':
          params.status_sms = this.elementByKeyInAry(params, 'status', this.getSmsChannelsStatus());
          break;
      }
    }
    const parseValues = {
      channel: (value) => (value) ? value : null,
      status: (value) => (value) ? value : null,
      status_sms: (value) => (value) ? value : null,
      status_push: (value) => (value) ? value : null,
      status_email: (value) => (value) ? value : null
    };
    const parsedValuesObj = {};
    Object.keys(params).forEach( key => {
      parsedValuesObj[key] = parseValues[key](params[key]);
    });
    return parsedValuesObj;
  }

  private elementByKeyInAry(params, elementKey: string, ary: any[]) {
    const el = params[elementKey];
    if ( el && el.length > 0 && el[0].hasOwnProperty('id') ) {
      return el;
    } else if ( el && el.length > 0 && !el[0].hasOwnProperty('id') ) {
      return [ary.find( op => op.id === el )];
    } else {
      return [];
    }
  }

  public getChannels(): {id: string, name: string}[] {
    return [
      { id: 'push', name: this.translate.instant('resources.segment_conditions.operators.push')},
      { id: 'email', name: this.translate.instant('resources.segment_conditions.operators.email')},
      { id: 'sms', name: this.translate.instant('resources.segment_conditions.operators.sms')}
    ];
  }

  public getAppChannelsStatus(): {id: string, name: string}[] {
    return [
      { id: 'app', name: this.translate.instant('resources.segment_conditions.operators.app')},
      { id: 'not_app', name: this.translate.instant('resources.segment_conditions.operators.not_app')}
    ];
  }

  public getEmailChannelsStatus(): {id: string, name: string}[] {
    return [
      { id: 'valid', name: this.translate.instant('resources.segment_conditions.operators.active')},
      { id: 'pending', name: this.translate.instant('resources.segment_conditions.operators.pending')},
      { id: 'empty', name: this.translate.instant('resources.segment_conditions.operators.empty')},
      { id: 'invalid', name: this.translate.instant('resources.segment_conditions.operators.invalid')}
    ];
  }

  public getSmsChannelsStatus(): {id: string, name: string}[] {
    return [
      { id: 'valid', name: this.translate.instant('resources.segment_conditions.operators.active')},
      { id: 'pending', name: this.translate.instant('resources.segment_conditions.operators.pending')},
      { id: 'empty', name: this.translate.instant('resources.segment_conditions.operators.empty')},
      { id: 'invalid', name: this.translate.instant('resources.segment_conditions.operators.invalid')}
    ];
  }

  private getFormGroupValidations(control: UntypedFormGroup): ValidationErrors | null {
    const channel = control.get('channel');
    const statusPush = control.get('status_push');
    const statusEmail = control.get('status_email');
    const statusSms = control.get('status_sms');

    statusPush.setValidators([]);
    statusEmail.setValidators([]);
    statusSms.setValidators([]);

    if (channel.value && channel.value.length > 0 && channel.value[0].id === 'push') {
      return checkControlValuePresence(statusPush, 'invalidPushStatusValue');
    } else if (channel.value && channel.value.length > 0 && channel.value[0].id === 'email') {
      return checkControlValuePresence(statusEmail, 'invalidEmailStatusValue');
    } else if (channel.value && channel.value.length > 0 && channel.value[0].id === 'sms') {
      return checkControlValuePresence(statusSms, 'invalidEmailStatusValue');
    }
  }
}
