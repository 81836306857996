import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DictionaryService } from '../../../../../shared/services/dictionary.service';
import { CustomersService } from '../../../customers/customers.service';
import { DataTableFilterService } from '../../../../../shared/components/data-table-filter/data-table-filter.service';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { ClubEvolutionRowData, ClubEvolutionRowDataMock } from '../../../../../shared/models/analytics/club-evolution-row-data';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-club-evolution',
  templateUrl: './club-evolution.component.html',
  styleUrls: ['./club-evolution.component.css'],
  providers: [DictionaryService]
})

export class ClubEvolutionComponent implements OnInit, OnDestroy {

  debug: boolean;
  loading: boolean;
  subs$: Subscription[];
  clubEvolutionData: ClubEvolutionRowData[];
  clubEvolutionTotalSalesData: ClubEvolutionRowData[];
  months: {id: number, name: string, key: string}[];
  activeMonths: number;
  filter: { year: number, compared_year: number, location_ids?: number[], segment_id?: number };
  isFilteringBySegment: boolean;
  onSearch: boolean;

  constructor( private dictionary: DictionaryService,
               private customersService: CustomersService,
               private filterService: DataTableFilterService,
               private refreshCacheService: RefreshCacheService,
               private translate: TranslateService ) {
    this.debug = false;
    this.loading = false;
    this.subs$ = [];
    this.months = this.dictionary.dictionaries.months;
    this.activeMonths = this.months.length;
    this.isFilteringBySegment = false;
  }

  ngOnInit() {
    this.onSearch = false;
    const filterChange$ = this.customersService.applyFilters$.subscribe((filterValues) => {
      this.getEvolutionClubData(filterValues);
      this.onSearch = true;
    });
    this.subs$.push(filterChange$);
    setTimeout(() => this.filterService.loaderStatus.next(true));
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach(s$ => s$.unsubscribe()); }
  }

  private getEvolutionClubData(filterValues): void {
    this.clubEvolutionData = null;
    this.clubEvolutionTotalSalesData = null;

    let filters = { ...filterValues };

    if ( Object.keys(filters).length === 0 ) {
      filters = { year: moment().year(), compared_year: moment().year() - 1 };
    } else {
      const { year_of_last_years, ...filtersPayload } = filters;
      const fltrPayload = {...filtersPayload, year: year_of_last_years };
      filters = fltrPayload;
    }

    if ( filters.compared_year > filters.year ) {
      this.filter = { year: filters.compared_year, compared_year: filters.year };
    } else {
      this.filter = { year: filters.year, compared_year: filters.compared_year };
    }

    filters.year = this.filter.year;
    filters.compared_year = this.filter.compared_year;

    this.isFilteringBySegment = !isNullOrUndefined(filters.segment_id);

    if ( this.isFilteringBySegment ) {
      this.filter.segment_id = filters.segment_id;
    }

    if ( filters.hasOwnProperty('location_ids') ) {
      this.filter.location_ids = filters.location_ids;
    }

    // Calculate quantity of active moths if filtered year is current year
    const today = new Date();
    if ( parseInt(filters.year, 10) === today.getFullYear()) {
      this.activeMonths = (today.getMonth() > 0) ? today.getMonth() : 1;
    } else {
      this.activeMonths = this.months.length;
    }

    if (this.debug) {
      this.enqueueDebugRequest();
    } else {
      if (this.isFilteringBySegment) {
        const totalSalesFilters = Object.assign({}, filters);
        delete totalSalesFilters.segment_id;
        this.enqueuePollingRequest('clubEvolutionData', filters);
        this.enqueuePollingRequest('clubEvolutionTotalSalesData', totalSalesFilters);
      } else {
        this.enqueuePollingRequest('clubEvolutionData', filters);
      }
    }
  }

  private enqueueDebugRequest() {
    this.loading = true;
    this.filterService.loaderStatus.next(false);
    setTimeout(() => {
      this.clubEvolutionData = this.toConstantData( ClubEvolutionRowDataMock );
      this.calculateAverage('clubEvolutionData');
      this.refreshLoaders();
    }, 1000 );
  }

  private enqueuePollingRequest(dataPropertyKey: string, filters) {
    const params = { apiEndPoint: 'analytics/yearly_club_evolution', filtering: filters };
    this.loading = true;
    this.filterService.loaderStatus.next(false);
    const clubEvolution$ = this.refreshCacheService.getExpensiveData(params).subscribe((data) => {
      if (!this.refreshCacheService.isRequestPending(data)) {
        this[dataPropertyKey] = this.toConstantData(data);
        this.calculateAverage(dataPropertyKey);
        this.refreshLoaders();
        clubEvolution$.unsubscribe();
      }
    });
    this.subs$.push(clubEvolution$);
  }

  private toConstantData(rawData): ClubEvolutionRowData[] {

    const result = [];

    for (let index = 0; index < this.activeMonths; index++) {

      const yearTotalIdentifiedSales = rawData.total_identified_sales.year[ index ];
      const comparedYearTotalIdentifiedSales = rawData.total_identified_sales.compared_year[ index ];

      const yearTotalSales = rawData.total_sales.year[ index ];
      const comparedYearTotalSales = rawData.total_sales.compared_year[ index ];

      const yearTotalIdentifiedTickets = rawData.total_identified_tickets.year[ index ];
      const comparedYearTotalIdentifiedTickets = rawData.total_identified_tickets.compared_year[ index ];

      const yearActiveCustomers = rawData.active_customers.year[ index ];
      const comparedYearActiveCustomers = rawData.active_customers.compared_year[ index ];

      // Calculated values
      // Spent average
      let yearSpentAverage = ( yearTotalIdentifiedSales / yearActiveCustomers );
          yearSpentAverage = this.isNanOrInfinite(yearSpentAverage) ? 0 : yearSpentAverage;

      let comparedYearSpentAverage = ( comparedYearTotalIdentifiedSales / comparedYearActiveCustomers );
          comparedYearSpentAverage = this.isNanOrInfinite(comparedYearSpentAverage) ? 0 : comparedYearSpentAverage;

      let diffSpentAverage = (( yearSpentAverage - comparedYearSpentAverage ) / comparedYearSpentAverage ) * 100;
          diffSpentAverage = this.isNanOrInfinite(diffSpentAverage) ? 0 : diffSpentAverage;

      // Ticket average
      let yearTicketAverage = ( yearTotalIdentifiedSales / yearTotalIdentifiedTickets );
          yearTicketAverage = this.isNanOrInfinite(yearTicketAverage) ? 0 : yearTicketAverage;

      let comparedYearTicketAverage = ( comparedYearTotalIdentifiedSales / comparedYearTotalIdentifiedTickets );
          comparedYearTicketAverage = this.isNanOrInfinite(comparedYearTicketAverage) ? 0 : comparedYearTicketAverage;

      let diffTicketAverage = (( yearTicketAverage - comparedYearTicketAverage ) / comparedYearTicketAverage ) * 100;
          diffTicketAverage = this.isNanOrInfinite(diffTicketAverage) ? 0 : diffTicketAverage;

      // Freq
      let yearFrequency = ( yearTotalIdentifiedTickets / yearActiveCustomers );
          yearFrequency = this.isNanOrInfinite(yearFrequency) ? 0 : yearFrequency;

      let comparedYearFrequency = ( comparedYearTotalIdentifiedTickets / comparedYearActiveCustomers );
          comparedYearFrequency = this.isNanOrInfinite(comparedYearFrequency) ? 0 : comparedYearFrequency;

      let diffFrequency = (( yearFrequency - comparedYearFrequency ) / comparedYearFrequency ) * 100;
          diffFrequency = this.isNanOrInfinite(diffFrequency) ? 0 : diffFrequency;

      // Diff Sales (total / identified)
      let diffIdentifiedSales = (( yearTotalIdentifiedSales - comparedYearTotalIdentifiedSales ) / comparedYearTotalIdentifiedSales ) * 100;
          diffIdentifiedSales = this.isNanOrInfinite(diffIdentifiedSales) ? 0 : diffIdentifiedSales;

      let diffTotalSales = (( yearTotalSales - comparedYearTotalSales ) / comparedYearTotalSales ) * 100;
          diffTotalSales = this.isNanOrInfinite(diffTotalSales) ? 0 : diffTotalSales;

      // % Identified sales
      let pctgYearIdentifiedSales = ( yearTotalIdentifiedSales / yearTotalSales ) * 100 ;
          pctgYearIdentifiedSales = this.isNanOrInfinite(pctgYearIdentifiedSales) ? 0 : pctgYearIdentifiedSales;

      let pctgComparedYearIdentifiedSales = ( comparedYearTotalIdentifiedSales / comparedYearTotalSales ) * 100 ;
          pctgComparedYearIdentifiedSales = this.isNanOrInfinite(pctgComparedYearIdentifiedSales) ? 0 : pctgComparedYearIdentifiedSales;

      const diffPctgIdentifiedSales = pctgYearIdentifiedSales - pctgComparedYearIdentifiedSales;

      // Diff active customers
      let diffActiveCustomers = (( yearActiveCustomers - comparedYearActiveCustomers) / comparedYearActiveCustomers ) * 100;
          diffActiveCustomers = this.isNanOrInfinite( diffActiveCustomers ) ? 0 : diffActiveCustomers;

      const evoDataRawObj = {
        //
        month: this.months[index].name,
        //
        yearSpentAverage: yearSpentAverage,
        comparedYearSpentAverage: comparedYearSpentAverage,
        diffSpentAverage: diffSpentAverage,
        //
        yearTicketAverage: yearTicketAverage,
        comparedYearTicketAverage: comparedYearTicketAverage,
        diffTicketAverage: diffTicketAverage,
        //
        yearFrequency: yearFrequency,
        comparedYearFrequency: comparedYearFrequency,
        diffFrequency: diffFrequency,
        //
        yearTotalIdentifiedSales: yearTotalIdentifiedSales,
        comparedYearTotalIdentifiedSales: comparedYearTotalIdentifiedSales,
        diffIdentifiedSales: diffIdentifiedSales,
        //
        yearTotalSales: yearTotalSales,
        comparedYearTotalSales: comparedYearTotalSales,
        diffTotalSales: diffTotalSales,
        //
        pctgYearIdentifiedSales: pctgYearIdentifiedSales,
        pctgComparedYearIdentifiedSales: pctgComparedYearIdentifiedSales,
        diffPctgIdentifiedSales: diffPctgIdentifiedSales,
        //
        yearTotalIdentifiedTickets: yearTotalIdentifiedTickets,
        comparedYearTotalIdentifiedTickets: comparedYearTotalIdentifiedTickets,
        //
        yearActiveCustomers: yearActiveCustomers,
        comparedYearActiveCustomers: comparedYearActiveCustomers,
        diffActiveCustomers: diffActiveCustomers
      };

      const evoDataRow = new ClubEvolutionRowData(evoDataRawObj);
      result.push(evoDataRow);
    }

    return result;
  }

  private calculateAverage(dataPropertyKey: string) {

    const yearSpentAverage = this.sum(this[dataPropertyKey], 'yearSpentAverage') / this.activeMonths;
    const comparedYearSpentAverage = this.sum(this[dataPropertyKey], 'comparedYearSpentAverage') / this.activeMonths;
    let diffSpentAverage = (( yearSpentAverage - comparedYearSpentAverage ) / comparedYearSpentAverage ) * 100;
        diffSpentAverage = this.isNanOrInfinite(diffSpentAverage) ? 0 : diffSpentAverage;

    const yearTicketAverage = this.sum(this[dataPropertyKey], 'yearTicketAverage') / this.activeMonths;
    const comparedYearTicketAverage = this.sum(this[dataPropertyKey], 'comparedYearTicketAverage') / this.activeMonths;
    let diffTicketAverage = (( yearTicketAverage - comparedYearTicketAverage ) / comparedYearTicketAverage ) * 100;
        diffTicketAverage = this.isNanOrInfinite(diffTicketAverage) ? 0 : diffTicketAverage;

    const yearFrequency = this.sum(this[dataPropertyKey], 'yearFrequency') / this.activeMonths;
    const comparedYearFrequency = this.sum(this[dataPropertyKey], 'comparedYearFrequency') / this.activeMonths;
    let diffFrequency = (( yearFrequency - comparedYearFrequency ) / comparedYearFrequency ) * 100;
        diffFrequency = this.isNanOrInfinite(diffFrequency) ? 0 : diffFrequency;

    const yearTotalSales = this.sum(this[dataPropertyKey], 'yearTotalSales') / this.activeMonths;
    const comparedYearTotalSales = this.sum(this[dataPropertyKey], 'comparedYearTotalSales') / this.activeMonths;
    let diffTotalSales = (( yearTotalSales - comparedYearTotalSales ) / comparedYearTotalSales ) * 100;
        diffTotalSales = this.isNanOrInfinite(diffTotalSales) ? 0 : diffTotalSales;

    const yearTotalIdentifiedSales = this.sum(this[dataPropertyKey], 'yearTotalIdentifiedSales') / this.activeMonths;
    const comparedYearTotalIdentifiedSales = this.sum(this[dataPropertyKey], 'comparedYearTotalIdentifiedSales') / this.activeMonths;
    let diffIdentifiedSales = (( yearTotalIdentifiedSales - comparedYearTotalIdentifiedSales ) / comparedYearTotalIdentifiedSales ) * 100;
        diffIdentifiedSales = this.isNanOrInfinite(diffIdentifiedSales) ? 0 : diffIdentifiedSales;

    const pctgYearIdentifiedSales = this.sum(this[dataPropertyKey], 'pctgYearIdentifiedSales') / this.activeMonths;
    const pctgComparedYearIdentifiedSales = this.sum(this[dataPropertyKey], 'pctgComparedYearIdentifiedSales') / this.activeMonths;
    const diffPctgIdentifiedSales = pctgYearIdentifiedSales - pctgComparedYearIdentifiedSales;

    const yearActiveCustomers = this.sum(this[dataPropertyKey], 'yearActiveCustomers') / this.activeMonths;
    const comparedYearActiveCustomers = this.sum(this[dataPropertyKey], 'comparedYearActiveCustomers') / this.activeMonths;
    let diffActiveCustomers = (( yearActiveCustomers - comparedYearActiveCustomers ) / comparedYearActiveCustomers ) * 100;
        diffActiveCustomers = this.isNanOrInfinite(diffActiveCustomers) ? 0 : diffActiveCustomers;

    const averageRow = new ClubEvolutionRowData({

      month: this.translate.instant('common.average'),

      yearSpentAverage: yearSpentAverage,
      comparedYearSpentAverage: comparedYearSpentAverage,
      diffSpentAverage: diffSpentAverage,

      yearTicketAverage: yearTicketAverage,
      comparedYearTicketAverage: comparedYearTicketAverage,
      diffTicketAverage: diffTicketAverage,

      yearFrequency: yearFrequency,
      comparedYearFrequency: comparedYearFrequency,
      diffFrequency: diffFrequency,

      yearTotalSales: yearTotalSales,
      comparedYearTotalSales: comparedYearTotalSales,
      diffTotalSales: diffTotalSales,

      yearTotalIdentifiedSales: yearTotalIdentifiedSales,
      comparedYearTotalIdentifiedSales: comparedYearTotalIdentifiedSales,
      diffIdentifiedSales: diffIdentifiedSales,

      pctgYearIdentifiedSales: pctgYearIdentifiedSales,
      pctgComparedYearIdentifiedSales: pctgComparedYearIdentifiedSales,
      diffPctgIdentifiedSales: diffPctgIdentifiedSales,

      yearActiveCustomers: yearActiveCustomers,
      comparedYearActiveCustomers: comparedYearActiveCustomers,
      diffActiveCustomers: diffActiveCustomers,
    });

    this[dataPropertyKey].push(averageRow);
  }

  private refreshLoaders() {
    if (!this.isFilteringBySegment) {
      if (!isNullOrUndefined(this.clubEvolutionData)) {
        this.dispatchLoaders();
      }
    } else {
      if (!isNullOrUndefined(this.clubEvolutionData) &&
          !isNullOrUndefined(this.clubEvolutionTotalSalesData)) {
        this.dispatchLoaders();
      }
    }
  }

  private dispatchLoaders() {
    this.loading = false;
    this.filterService.loaderStatus.next(true);
  }

  private sum(array: any[], key: string): number {
    return array.reduce((a, b) => a + (b[key] || 0), 0);
  }

  private isNanOrInfinite( num: number ): boolean {
    return ( isNaN( num ) || !isFinite( num ) );
  }
}
