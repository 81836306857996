import { Component, Input, OnInit } from "@angular/core";
import { LgCurrencyPipe } from "../../../../../shared/pipes/lg-currency.pipe";
import { DecimalPipe } from "@angular/common";
import { DateService } from "../../../../../shared/services/date.service";
import { TicketDetail, VoucherTicketDetail } from "../../../../../shared/models/purchases/ticket-detail";

@Component({
  selector: 'app-details-block',
  templateUrl: './details-block.component.html',
  styleUrls: ['./details-block.component.scss']
})

export class DetailsBlockComponent implements OnInit {

  @Input() ticketData: TicketDetail;
  @Input() currencySymbol: string;
  @Input() vouchersData: VoucherTicketDetail[];
  @Input() totalDiscount: number;

  details: DetailsBlockItem[];

  constructor(
    private lgCurrency: LgCurrencyPipe,
    private decimalPipe: DecimalPipe,
    private dateService: DateService
  ) {}

  ngOnInit() {
    this.details = [{
      blockTitleKey: 'resources.tickets.modal.cards.details.title',
      children: [
        {
          titleKey: 'common.id',
          value: this.ticketData.id
        },
        {
          titleKey: 'resources.tickets.modal.cards.details.fields.total',
          value: `${this.lgCurrency.transform(this.ticketData.total, '1.2-2', this.ticketData.currency)}`
        },
        {
          titleKey: 'resources.tickets.modal.cards.details.fields.code',
          value: this.ticketData.code ? this.ticketData.code : '-'
        },
        {
          titleKey: 'resources.tickets.modal.cards.details.fields.customer',
          value: this.handleCustomerFullName(this.ticketData?._embedded?.customer),
          link: this.getCustomerLink(this.ticketData?._embedded?.customer)
        },
        {
          titleKey: 'resources.tickets.modal.cards.details.fields.date',
          value: this.dateService.parseDateWithFormat(this.ticketData.created_at, 'DD/MM/YYYY HH:mm:ss')
        },
        {
          titleKey: 'resources.tickets.modal.cards.details.fields.location',
          value: this.ticketData._embedded.location ? this.ticketData._embedded.location?.name : ''
        }
      ]
    },{
      blockTitleKey: 'resources.tickets.modal.cards.points.title',
      children: [
        {
          titleKey: 'resources.tickets.modal.cards.points.fields.points_won',
          value: this.decimalPipe.transform(this.ticketData.points_won, '1.0-0', 'es')
        },
        {
          titleKey: 'resources.tickets.modal.cards.points.fields.scores_used',
          value:  this.decimalPipe.transform(this.ticketData.score_used, '1.0-0', 'es')
        },
        {
          titleKey: 'resources.tickets.modal.cards.points.fields.from_customer',
          value: this.decimalPipe.transform(this.ticketData.score_returned_from_customer, '1.0-0', 'es'),
          tooltip: 'resources.tickets.modal.cards.points.tooltips.from_customer'
        },
        {
          titleKey: 'resources.tickets.modal.cards.points.fields.to_customer',
          value:  this.decimalPipe.transform(this.ticketData.score_returned_to_customer, '1.0-0', 'es'),
          tooltip: 'resources.tickets.modal.cards.points.tooltips.to_customer'
        },
        {
          titleKey: 'resources.tickets.modal.cards.points.fields.score_payment',
          value: `${this.decimalPipe.transform(this.ticketData.cashbackTotalPoints, '1.0-0', 'es')} (${this.lgCurrency.transform(this.ticketData.cashbackTotalAmount, '1.2-2', this.ticketData.currency)})`
        }
      ]
    },{
      blockTitleKey: 'resources.tickets.modal.cards.vouchers_redeemed.title',
      children: [
        {
          titleKey: 'resources.tickets.modal.cards.vouchers_redeemed.fields.used',
          value: this.vouchersData.length
        },
        {
          titleKey: 'resources.tickets.modal.cards.vouchers_redeemed.fields.total_discount',
          value: this.lgCurrency.transform(this.totalDiscount / 100, '1.2-2', this.ticketData.currency)
        }
      ]
    }];

    if(this.ticketData.order_id) {
      let orderId = {
        titleKey: 'resources.tickets.modal.cards.details.fields.order_id',
        value: this.ticketData.order_id
      }
      this.details[0].children.splice(2, 0, orderId);
    }
  }

  openUrlInNewTab(url: string) {
    window.open(url, '_blank');
  }

  scroll(elementId: string) {
    document.getElementById(elementId).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  private handleCustomerFullName(customer: object): string {
    const customerId = customer?.['id'] ? customer['id'] : '-';
    let customerFullName = customer?.['name'] ?? '';
    if (customer?.['surname_1']) { customerFullName += ` ${customer['surname_1']}`};
    return customerFullName?.trim() ? customerFullName : customerId;
  }

  private getCustomerLink(customer: object): string {
    return customer?.['id'] ? `/#/data-warehouse/profiles/${customer['id']}/summary` : null;
  }
}

export interface DetailsBlockItem {
  blockTitleKey: string;
  children: {
    titleKey: string;
    value: any;
    link?: string;
    tooltip?: string;
  }[];
}
