import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, finalize, takeUntil } from "rxjs";
import { ConfirmationService } from "../../../../../shared/services/confirmation.service";
import { TranslateService } from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";
import { QuestionBase } from "../../../../../shared/models/forms/question-base";
import { UntypedFormGroup } from "@angular/forms";
import { QuestionControlService } from "../../../../../shared/services/question-control.service";
import { ReportIncrementalService } from "../incremental/report-incremental.service";
import { getCouponsInAppAnalysisInputs } from './utils/edit-coupons-in-app-analysis-inputs.utils';
import { CustomReport } from "../../report.model";
import { DictionaryService } from "../../../../../shared/services/dictionary.service";
import { PlansService } from "../../../../plans/plans.service";
import * as moment from 'moment';
import { MultiSelectQuestion } from "../../../../../shared/models/forms/question-multiselect";
import { TextboxQuestion } from "../../../../../shared/models/forms/question-textbox";
import { FeatureFlagsService } from "../../../../../shared/services/feature-flags.service";
import { CampaignsService } from '../../../../campaigns/campaigns.service';

@Component({
  selector: 'app-edit-coupons-in-app-analysis',
  templateUrl: './edit-coupons-in-app-analysis.component.html',
  styleUrls: ['./edit-coupons-in-app-analysis.component.scss'],
  providers: [DictionaryService]
})

export class EditCouponsInAppAnalysisComponent implements OnInit, OnDestroy {

  historyId: number;
  id: number;
  inputs: (TextboxQuestion | MultiSelectQuestion)[];
  loading = false;
  report: CustomReport;
  reportForm: UntypedFormGroup;
  flags = this.featureFlags.flags;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private confirmationService: ConfirmationService,
    private dictionaryService: DictionaryService,
    private qcs: QuestionControlService,
    private reportIncrementalService: ReportIncrementalService,
    private route: ActivatedRoute,
    private router: Router,
    private plansService: PlansService,
    private translate: TranslateService,
    private campaignsService: CampaignsService,
    private featureFlags: FeatureFlagsService,
  ) {}

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  returnToList() {
    const navigateTo = this.router.url.indexOf('(modal:') >= 0
        ? `update/reports/${this.id}`
        : '/offers-personalization/dashboard/reports';

    this.router.navigate([{outlets: { modal: navigateTo}}]).catch(() => {});
  }

  sendData() {
    const payload = this.getPayload();
    const req$ = this.historyId ? this.reportIncrementalService.updateData(payload, this.id, this.historyId) : this.reportIncrementalService.sendData(payload, this.id);
    const successTitle = this.historyId ? 'resources.reports.form.warnings.report_update_title': 'resources.reports.form.warnings.success_title';
    const successDesc = this.historyId ? 'resources.reports.form.warnings.report_update_text': 'resources.reports.form.warnings.success_text';

    this.loading = true;

    req$.pipe(takeUntil(this.destroy$), finalize(() => this.loading = false)).subscribe({
      next: () => {
        this.confirmationService.displaySuccessAlert(this.translate.instant(successTitle), this.translate.instant(successDesc)).catch(() => {});
        this.returnToList();
      },
      error: (error: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(error)
    });
  }

  getInputConfig(inputKey: string): QuestionBase<TextboxQuestion | MultiSelectQuestion> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  cfgChangedHandler(key: string, value: unknown): void {
    switch (key) {
      case 'plan_ids':
        this.reportForm.patchValue({ campaign_ids: [] });
        break;
      case 'campaign_ids':
        this.reportForm.patchValue({ plan_ids: [] });
        break;
      default:
        break;
    }
  }

  /**
   * Plans selector should be always cleansed and disabled if month/year aren't fullfilled
   */
  dateChangedHandler(): void {
    const monthValue = (this.getInputConfig('month').value || [])[0]?.id;
    const yearValue = (this.getInputConfig('year').value || [])[0]?.id;
    const isMonthEmpty = !monthValue || monthValue.length === 0;
    const isYearEmpty = !yearValue || yearValue.length === 0;

    this.handleSelectedDates(monthValue, yearValue);

    if (isMonthEmpty || isYearEmpty) {
      this.reportForm.patchValue({ plan_ids: [] });
      this.getInputConfig('plan_ids').disabled = true;
    } else {
      this.getInputConfig('plan_ids').disabled = false;
    }
  }

  /**
   * Executes each time a location category input is changed.
   * Filter link between location categories and locations: All locations belong to current selected location categories.
   */
  private handleSelectedDates(month: string, year: string): void {
    this.plansService.setDatesRequired();
    this.campaignsService.setDatesRequired();
    if (month && year) {
      month = String(month).length === 1 ? `0${month}` : String(month);
      const targetDate = moment(`${year}-${month}`);
      const dateFrom = targetDate.startOf('month').format('YYYY-MM-DD');
      const dateTo = targetDate.endOf('month').format('YYYY-MM-DD');
      this.plansService.setDates(dateFrom, dateTo);
      this.campaignsService.setDates(dateFrom, dateTo);
    } else {
      this.plansService.setDates(null, null);
      this.campaignsService.setDates(null, null);
    }
  }

  /**
   * Code for processing route parameters
   */
  private getParams() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.hasOwnProperty('id') && params.hasOwnProperty('history_id')) {
        this.id = params.id;
        this.historyId = params.history_id;
        this.getReportDetailById();
      } else {
        this.id = params.id;
        this.setupForm();
      }
    });
  }

  /**
   * Fetching report details & setting up the form
   */
  private getReportDetailById() {
    this.reportIncrementalService.fetchReportDetailById(this.id, this.historyId).pipe(takeUntil(this.destroy$)).subscribe({
      next: (data: CustomReport) => {
        this.report = data;
        this.setupForm();
      },
      error: (error: HttpErrorResponse) => {
        this.confirmationService.displayHttpErrorAlert(error)
      }
    }
  )}

  /**
   * Initialize form and set values
   */
  private setupForm() {
    this.inputs = getCouponsInAppAnalysisInputs(
      this.dictionaryService,
      this.plansService,
      this.report,
      this.campaignsService
    )
    this.reportForm = this.qcs.toFormGroup(this.inputs);
  }

  private getPayload() {
    const selectedMonth = this.reportForm.value.month[0]?.id;
    const selectedYear = this.reportForm.value.year[0]?.id;
    const selectedDate = moment(`${selectedYear}-${selectedMonth}`);
    const startOfMonth = selectedDate.startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = selectedDate.endOf('month').format('YYYY-MM-DD');
    return {
      configuration: {
        campaign_ids: this.reportForm.value?.campaign_ids?.map(item => item.id),
        plan_ids: this.reportForm.value?.plan_ids?.map(item => item.id),
        date_from: startOfMonth,
        date_to: endOfMonth
      },
      name: this.reportForm.value.name,
      report_id: this.id,
      type: 'ReportCouponsAppAnalysis'
    };
  }
}
