<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="csv" class="font-size-14 text-bold" [translate]="'resources.location_taxonomy_terms.form.csv'"></label>
                <app-question [question]="getInputConfig('csv')" [form]="importForm"></app-question>
              </div>
              <p>
                {{ 'resources.location_taxonomy_terms.modal.import.download_template' | translate }}
                <a href="https://loyalguru-imports.s3.eu-west-1.amazonaws.com/import_location_categories_template.csv" target="_blank" rel="noopener">
                  {{ 'resources.location_taxonomy_terms.modal.import.here_link' | translate }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
