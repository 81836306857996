<div class="row">
  <div class="col-md-3">
    <app-question [question]="inputs[0]" [form]="segmentForm" [disabled]="this.disableInputs"></app-question>
  </div>
  <div class="col-md-2">
    <app-question [question]="inputs[1]" [form]="segmentForm" [disabled]="this.disableInputs"></app-question>
  </div>
  <div class="col-md-3" *ngIf="hasFormKeyWithValue('affectation', 'feature')">
    <app-question [question]="inputs[2]" [form]="segmentForm" [disabled]="this.disableInputs" (multiselectChanged)="setProductsCategories()"></app-question>
  </div>
  <div class="col-md-3" *ngIf="hasFormKeyWithValue('affectation', 'feature')">
    <app-question [question]="inputs[3]" [form]="segmentForm" [disabled]="this.disableInputs"></app-question>
  </div>
  <div *ngIf="length > 1" class="col">
    <div class="btn-group mrg-top-25">
      <button class="btn btn-default"
              type="button"
              (click)="deleteSegment()">
        <i class="ei-garbage-alt font-size-14"></i>
      </button>
    </div>
  </div>
</div>
