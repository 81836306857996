import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CampaignsService } from '../../campaigns.service';
import { Subscription } from 'rxjs';
import { CampaignContent } from './campaign-content';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';

@Component({
  selector: 'app-show-campaigns-content',
  templateUrl: './show-campaigns-content.component.html',
  styleUrls: ['./show-campaigns-content.component.css']
})
export class ShowCampaignsContentComponent implements OnInit, OnDestroy {

  public srcdoc: object;
  public apiEndPoint: string;
  public id: number;
  public template: object;
  public historyId: number;
  public subs$: Subscription[] = [];
  public campaignContent: CampaignContent;
  public loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private campaignsService: CampaignsService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private sanitizer: DomSanitizer) 
  {
    this.loading = false;
  }

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach(s$ => s$.unsubscribe()); }
  }

  getParams() {
    const route$ = this.route.params.subscribe(params => {
      if (params.id && params.history_id) {
        this.id = params.id;
        this.historyId = params.history_id;
        this.getCampaignHistory(params.id, params.history_id);
      } else if (params.id && !params.history_id) {
        this.id = params.id;
        this.getCampaignById();
      }
    });
    this.subs$.push(route$);
  }

  getCampaignHistory(campaignId, historyId) {
    this.loading = true;
    const historyData$ = this.campaignsService.getCampaignHistoryByIdAndCampaignId(campaignId, historyId).subscribe(
      response => {
        historyData$.unsubscribe();
        this.loading = false;
        this.campaignContent = new CampaignContent(response);
        this.template = response['_embedded'].campaign_info.template;
        this.srcdoc = this.campaignContent.template['message'];
      },
      errorData => {
        historyData$.unsubscribe();
        this.loading = false;
        this.confirmationService.displayErrorAlert('Error', errorData.error.error);
      }
    );
    this.subs$.push(historyData$);
  }

  getCampaignById() {
    this.loading = true;
    const campaign$ = this.campaignsService.getCampaignById(this.id).subscribe(
      response => {
        campaign$.unsubscribe();
        this.loading = false;
        this.campaignContent = new CampaignContent(response);
        this.template = response['_embedded'].template;
        this.srcdoc = this.campaignContent.template['message'];
      },
      errorData => {
        campaign$.unsubscribe();
        this.loading = false;
        this.confirmationService.displayErrorAlert('Error', errorData.error.error);
      }
    );
    this.subs$.push(campaign$);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

}
