import { Component, EventEmitter, Output } from '@angular/core';
import { FeatureFlagsService } from '../../shared/services/feature-flags.service';
import { NavItem } from './nav-item';
import { SIDENAV } from './nav-items.constans';
import { SideNavService } from './side-nav.service';

@Component({
  selector: 'app-new-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})

export class SideNavComponent {

  featureFlags = this.featureFlagsService.flags;
  roleSlug = this.sideNavService.role.slug;
  flags = this.sideNavService.flags;
  navItems:  NavItem[] = this.sideNavService.setModulePermissions([...SIDENAV]);

  @Output() onEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private sideNavService: SideNavService,
    private featureFlagsService: FeatureFlagsService
  ) { }

  isNotAuth(item: NavItem): boolean {
    return this.sideNavService.isNotAuth(item);
  }

  triggerEvent(event): void {
    this.onEvent.emit(event);
  }

  showNotAvailable(): void {
    this.sideNavService.showNotAvailable();
  }
}