import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { QuestionBase } from "../../../../shared/models/forms/question-base";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, Subscription, distinctUntilChanged, takeUntil } from "rxjs";
import { PartnersService } from "../partners.service";
import { Partner } from "../partner";
import { QuestionControlService } from "../../../../shared/services/question-control.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ConfirmationService } from "../../../../shared/services/confirmation.service";
import { TranslateService } from "@ngx-translate/core";
import { TextboxQuestion } from "../../../../shared/models/forms/question-textbox";
import { ImageQuestion } from "../../../../shared/models/forms/question-image";
import { RadioQuestion } from "../../../../shared/models/forms/question-radio";
import { DictionaryService } from "../../../../shared/services/dictionary.service";
import { ModalStatusService } from "../../../../shared/services/modal-status.service";

@Component({
  selector: 'app-form-partners',
  templateUrl: './partner-form.component.html',
  styleUrls: ['./partner-form.component.scss']
})

export class PartnerFormComponent implements OnInit, OnDestroy {
  inputs: QuestionBase<any>[];
  partnerForm: FormGroup;
  id: number | string;
  missingImg = false;
  partner: Partner;

  private subs$: Subscription[] = [];
  private imageData: { filename: any; filesize: any; filetype: any; base64: string; };
  private destroy$: Subject<void> = new Subject<void>();

  @Output('formValidity') formValidity: EventEmitter<string> = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private partnersService: PartnersService,
    private qcs: QuestionControlService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private dictionarySrv: DictionaryService,
    private modalStatusService: ModalStatusService,
    private router: Router
  ){}

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.imageData = {
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
          base64: reader.result.toString().split(',')[1]
        };
      };
    }
  }

  sendData() {
    const payload = this.getPayload();
    const req$ = this.id ? this.partnersService.updateById(payload, this.id) : this.partnersService.create(payload);
    req$.subscribe({
      next: () => this.handleSuccessRequest(),
      error: (err: HttpErrorResponse) => {
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), err.error.error)
      }
    })
  }

  private getPayload(): object {
    const payload = this.partnerForm.value;
    payload['image'] = this.imageData ? this.imageData : null;
    return payload;
  }

  private handleSuccessRequest(): void {
    const successTitle = this.translate.instant(this.id ? 'resources.scores.partners.warnings.update_title' : 'resources.scores.partners.warnings.success_title');
    const successText = this.translate.instant(this.id ? 'resources.scores.partners.warnings.update_text' : 'resources.scores.partners.warnings.success_text');
    this.confirmationService.displaySuccessAlert(successTitle, successText).catch(() => {});
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    this.modalStatusService.modalStatus.emit();
    this.closeModal();
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getPartnerById();
      } else {
        this.setInputsAndForm();
      }
    });
  }

  private getPartnerById(): void {
    this.partnersService.getById(this.id).pipe(takeUntil(this.destroy$)).subscribe(
      (data: Partner) => this.setInputsAndForm(data),
      (errorData: HttpErrorResponse) => this.handleErrorResponse(errorData)
    );
  }

  private handleErrorResponse(err: HttpErrorResponse) {
    this.confirmationService.displayErrorAlert(
      this.translate.instant('common.error'),
      err.error.error
    )
    this.closeModal();
  }

  private setInputsAndForm(partner?: Partner):void {
    this.partner = partner;
    this.missingImg = partner?.thumbnail_medium.indexOf('images/medium/missing') >= 0;
    this.inputs = this.getInputs(partner);
    this.partnerForm = this.qcs.toFormGroup(this.inputs);
    this.formStatusChanges();
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.partnerForm.status));
    const formStatus$ = this.partnerForm.statusChanges.pipe(distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
    this.subs$.push(formStatus$);
  }

  private getInputs(partner?: Partner): QuestionBase<any>[] {
    const inputs = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: partner?.name ?? null,
        required: true
      }),
      new TextboxQuestion({
        key: 'description',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: partner?.description ?? null
      }),
      new ImageQuestion({
        key: 'image',
        type: 'file',
        cssClasses: 'input-default',
      }),
      new RadioQuestion({
        key: 'active',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.statusOptions(),
        value: partner && partner.active ? this.statusOptions()[0].id : this.statusOptions()[1].id
      })
    ]

    return inputs;
  }

  private statusOptions() {
    return this.dictionarySrv.getValuesByKey('partners_status');
  }

  private closeModal(): void {
    this.router.navigate([{ outlets: { modal: null }}]).catch(() => {});
  }
}