import { Component, Input } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../services/question-control.service';

@Component({
  selector: 'app-last-purchase-condition',
  templateUrl: './last-purchase-condition.component.html',
  styleUrls: ['./last-purchase-condition.component.css']
})

export class LastPurchaseConditionComponent {

  @Input() item: Condition;
  @Input() inputs: QuestionBase<unknown>[];
  @Input() form: UntypedFormGroup;

  constructor(private qcs: QuestionControlService) {}

  hasFormKeyWithValue(formKey: string, value: unknown): boolean {
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  handleOperatorChanged(): void {
    this.getInputConfig('date_value_bt').value = null;
    this.form.patchValue({date_value_bt: null}, {emitEvent: true});
  }
}
