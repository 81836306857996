<div class="row" *ngIf="inputs">
  <div class="col-md-4">
    <app-question [question]="getInputConfig('operator')"
                  [form]="form"
                  (multiselectChanged)="handleOperatorChanged()"></app-question>
  </div>
  <div class="col-md-4">
    <app-question [question]="getInputConfig('date_value')" [form]="form"></app-question>
  </div>
  <div class="col-md-4" *ngIf="hasFormKeyWithValue('operator', 'bt')">
    <app-question [question]="getInputConfig('date_value_bt')" [form]="form"></app-question>
  </div>
</div>
