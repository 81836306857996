<div id="accordion-1" class="accordion panel-group" role="tablist" aria-multiselectable="true">
  <div class="card">
    <div class="card-heading border bottom" role="tab" id="headingOne">
      <h4 class="panel-title">
        <a data-toggle="collapse" data-parent="#accordion-1" href="#collapsePoints" aria-expanded="true" class="show no-pdd">
          <i class="fa-duotone fa-hundred-points"></i>
          <span class="mrg-left-10" translate>resources.tickets.modal.cards.points.title</span>
          <i class="icon ti-arrow-circle-down"></i>
        </a>
      </h4>
    </div>

    <div id="collapsePoints" class="panel-collapse show">
      <div class="card-block">
        <div class="row">
          <div class="col-md-3">
            <div class="card">
              <div class="card-heading border bottom">
                <h5 class="card_title no-mrg" translate>
                  resources.tickets.modal.cards.points.titles.points_rules
                </h5>
              </div>
              <div class="card-block no-pdd-vertical">
                <div class="table-overflow">
                  <table class="table table-hover" aria-hidden="true">
                    <thead>
                      <tr>
                        <th scope="auto" colspan="3" class="text-left" [translate]="'resources.tickets.modal.cards.scores.table.policy'"></th>
                      </tr>
                      <tr>
                        <th scope="auto"></th>
                        <th scope="auto"[translate]="'resources.tickets.modal.cards.scores.table.line'"></th>
                        <th scope="auto" [translate]="'resources.tickets.modal.cards.scores.table.available_points'"></th>
                      </tr>
                    </thead>
                    <tbody *ngIf="ticketData">
                      <tr *ngFor="let item of ticketData.scores_by_line">
                        <td>{{ item.promotion_name | lgMultilanguageTranslate }}</td>
                        <td>
                          <a class="text-info cursor-pointer" (click)="scroll(item.line_order)">
                            <strong>{{item.line_order}}</strong>
                          </a>
                        </td>
                        <td>{{ item.original_points }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td class="text-bold text-dark">Total</td>
                        <td class="text-semibold text-dark"></td>
                        <td class="text-semibold text-dark">{{ ticketData.points_won }}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card">
              <div class="card-heading border bottom">
                <h5 class="card_title no-mrg" translate>
                  resources.tickets.modal.cards.points.titles.points_breakdown
                </h5>
              </div>
              <div class="card-block no-pdd-vertical">
                <div class="table-overflow" *ngIf="pointsSummary && pointsSummary.length > 0">
                  <table class="table table-hover" aria-hidden="true">
                    <thead>
                      <tr>
                        <th colspan="1" scope="auto"></th>
                        <th colspan="2" scope="colgroup" class="text-center bg-gray">
                          {{'resources.tickets.modal.cards.scores.table.purchase' | translate}}</th>
                        <th colspan="2" scope="colgroup" class="text-center">
                          {{'resources.tickets.modal.cards.scores.table.returns' | translate}}</th>
                      </tr>
                      <tr>
                        <th scope="col">{{'resources.tickets.modal.cards.scores.table.line' | translate}}</th>
                        <th class="text-center bg-gray" scope="col">{{'resources.tickets.modal.cards.scores.table.earned' | translate}}</th>
                        <th class="text-center bg-gray" scope="col">{{'resources.tickets.modal.cards.scores.table.spend' | translate}}</th>
                        <th class="text-center" scope="col">{{'resources.tickets.modal.cards.scores.table.recovered' | translate}}</th>
                        <th class="text-center" scope="col">{{'resources.tickets.modal.cards.scores.table.canceled' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of pointsSummary">
                        <td>
                          <a class="text-info cursor-pointer" (click)="scroll(item.line_order)">
                            <strong>{{item.line_order}}</strong>
                          </a>
                        </td>
                        <td class="text-center bg-gray">{{ item.scores_by_line }}</td>
                        <td class="text-center bg-gray">{{ item.cashbacks ? '-' + item.cashbacks : 0 }}</td>
                        <td class="text-center">{{ item.cashbacks_returns }}</td>
                        <td class="text-center">{{ item.activity_return_froms ? '-' + item.activity_return_froms : 0 }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td class="text-bold text-dark">Total</td>
                        <td class="text-center text-semibold text-dark bg-gray">{{ ticketData.points_won }}</td>
                        <td class="text-center text-semibold text-dark bg-gray">{{ ticketData.score_used }}</td>
                        <td class="text-center text-semibold text-dark">{{ ticketData.score_returned_to_customer }}</td>
                        <td class="text-center text-semibold text-dark">{{ ticketData.score_returned_from_customer }}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card">
              <div class="card-heading border bottom">
                <h5 class="card_title no-mrg" translate>
                  resources.tickets.modal.cards.points.titles.cashbacks
                </h5>
              </div>
              <div class="card-block no-pdd-btm" *ngIf="ticketData.cashbacks?.length > 0; noElements">
                <div class="table-overflow">
                  <table class="table table-hover" aria-hidden="true">
                    <thead>
                      <tr>
                        <th scope="auto"></th>
                        <th scope="auto"></th>
                        <th scope="auto"></th>
                      </tr>
                    </thead>
                    <thead>
                      <tr></tr>
                      <tr>
                        <th scope="col">{{'resources.tickets.modal.cards.scores.table.line' | translate}}</th>
                        <th scope="col">{{'resources.tickets.modal.cards.points.fields.amount' | translate}}</th>
                        <th scope="col">{{'resources.tickets.modal.cards.points.fields.points' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of ticketData.cashbacks">
                        <td>
                          <a class="text-info cursor-pointer" (click)="scroll(item.line_order)">
                            <strong>{{item.line_order}}</strong>
                          </a>
                        </td>
                        <td>{{ item.amount | lgcurrency: '1.2-2': this.ticketData.currency }}</td>
                        <td>{{ item.points }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td class="text-bold text-dark">Total</td>
                        <td class="text-semibold text-dark">{{ ticketData.cashbackTotalAmount | lgcurrency: '1.2-2': this.ticketData.currency }}</td>
                        <td class="text-semibold text-dark">{{ ticketData.cashbackTotalPoints }}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div #noElements class="card-block padding-20">
                <div class=" text-center">
                  {{'components.data-table.messages.no_elements_found' | translate}}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
