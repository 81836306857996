import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from './condition.service';
import { QuestionBase } from '../../models/forms/question-base';
import { TranslateService } from '@ngx-translate/core';
import { TextboxQuestion } from '../../models/forms/question-textbox';
import { TextareaQuestion } from '../../models/forms/question-textarea';

@Injectable()
export class SqlConditionService implements SegmentConditionProvider {

  public inputs: QuestionBase<any>[];

  constructor( private translate: TranslateService ) { }

  public getInputs( params: any ): QuestionBase<any>[] {


    const inputs = [
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'name',
        label: this.translate.instant('resources.segment_conditions.fields.name'),
        type: 'text',
        getValue: (value) => value,
        parseValue: (value) => value
      }),
      new TextareaQuestion({
        key: 'sql_query',
        label: this.translate.instant('resources.segment_conditions.fields.sql_query'),
        type: 'textarea',
        cssClasses: 'form-control input-default',
        placeholder: '',
        getValue: (value) => value,
        parseValue: (value) => value,
        required: true
      })
    ];

    if ( params ) {
      Object.keys(params).forEach( key => {
        const input = inputs.find( _input => _input.key === key );
        const value = input.getValue(params[key]);
        input.value = value;
      });
    }

    this.inputs = inputs;
    return this.inputs;
  }

  public prepareFormValuesToAPI( params: any ): any {
    const parsedValuesObj = {};
    Object.keys(params).forEach( key => {
      const input = this.inputs.find( _input => _input.key === key );
      const parsedValue = input.parseValue( params[key] );
      parsedValuesObj[key] = parsedValue;
    });
    return parsedValuesObj;
  }

  public prepareFormValuesFromAPI( params: any ): any {
    const parseValues = {
      name: (value) => (value && value.length) ? value : null,
      sql_query: (value) => (value && value.length) ? value : null
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach( key => { parsedValuesObj[key] = parseValues[key](params[key]); });
    return parsedValuesObj;
  }
}
