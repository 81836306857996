import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CouponsService } from '../../../coupons/coupons.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { TranslateService } from '@ngx-translate/core';
import { FileQuestion } from '../../../../shared/models/forms/question-file';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-upload-images',
  templateUrl: './upload-images.component.html',
  styleUrls: ['./upload-images.component.css']
})
export class UploadImagesComponent implements OnInit, OnDestroy {

  public couponsForm: UntypedFormGroup;
  public inputs: QuestionBase<any>[];
  public subs$: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private qcs: QuestionControlService,
    private confirmationService: ConfirmationService,
    private modalStatusService: ModalStatusService,
    private translate: TranslateService,
    private couponsService: CouponsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getParams() {
    const route$ = this.route.params.subscribe(() => {
      this.inputs = this.getInputs();
      this.couponsForm = this.qcs.toFormGroup(this.inputs);
    });
    this.subs$.push(route$);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  sendData() {
    const images = this.couponsForm.value.images;
    if (images && images.length > 0) {
      const imgs = [];
      images.forEach((file, index) => {
        this.processImage(file).then((imgObj) => {
          imgs.push(imgObj);
          if (index === images.length - 1) {
            this.uploadImagesRequest(imgs);
          }
        });
      });
    }
  }

  private uploadImagesRequest(imgs: object[]) {
    const images$ = this.couponsService.uploadImages({images: imgs}).subscribe(
      () => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.coupons.form.warnings.import_title'),
          this.translate.instant('resources.coupons.form.warnings.import_text')
        ).catch(() => {});
        this.modalStatusService.modalStatus.emit();
        this.closeModal();
      },
      err => {
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), err.error.error);
        this.closeModal();
      }
    );

    this.subs$.push(images$);
  }

  private processImage(img): Promise<object> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(
        {
          name: img['name'],
          image: {
            filename: img['name'],
            filesize: img['size'],
            filetype: img['type'],
            base64: reader.result.toString().split(',')[1]
          }
        }
      );
      reader.onerror = reject;
      reader.readAsDataURL(img);
    });
  }

  private getInputs(): QuestionBase<any>[] {
    const inputs = [new FileQuestion({
      key: 'images',
      types: ['jpg', 'png'],
      multiple: true,
      cssClasses: 'form-control input-default'
    })];

    return inputs;
  }
}
