export class LoyaltyScore {
  activationDelay: number;
  activationDelayBeginningMonth: boolean;
  activationDelayUnit: string;
  active: boolean;
  acumulable: boolean;
  availabilityDuration: number;
  availabilityDurationNatural: boolean;
  availabilityDurationUnit: string;
  available_languages: string[];
  availableFrom: string;
  availableTo: string;
  awardType: string;
  countries: {
    id: number;
    name: string;
  }[];
  customEntityAttributes?: {field, value, condition, custom_entity_id}[];
  description_ca: string;
  description_de: string;
  description_en: string;
  description_es: string;
  description_fr: string;
  description_hr: string;
  description_hu: string;
  description_it: string;
  description_pt: string;
  description: string;
  expirationPeriodType: string;
  featureIds: string[];
  featureLocationIds: string[];
  fixedOptionSelected: string;
  id: number;
  includedFeatureLocations: {
    id: number;
    name: string;
    external_id: string;
    taxonomy: {
      name: string;
    }
  }[];
  includedFeatures: {
    id: number;
    name: string;
    external_id: string;
    taxonomy: {
      name: string;
    }
  }[];
  includedLocations: {
    id: number;
    name: string;
  }[];
  includedProducts: {
    id: number;
    name: string;
  }[];
  locations: number[];
  loyaltyScoreMoment: string;
  name_ca: string;
  name_de: string;
  name_en: string;
  name_es: string;
  name_fr: string;
  name_hr: string;
  name_hu: string;
  name_it: string;
  name_pt: string;
  name: string;
  once: string;
  onceLimit: number;
  onlyFirstActivity: boolean;
  paymentMethodIds: number[];
  points: number;
  pointsPercentage: number;
  pointsPriceMultiplicator: number;
  pointsPricePercentage: number;
  productIds: string[];
  recurrenceDays: string[];
  recurrenceType: string;
  strategyType: string;
  transferable: boolean;
  rewardId: number;
  when: string;

  embeddedLoyaltyScoreMoment: {
    id: number;
    name: string;
    protected: boolean;
    slug: string;
    isCustomEntity: boolean;
  };

  reward: {
    available_languages: string[],
    name: string
  };

  constructor(cfg: object) {
    this.activationDelay = cfg['activation_delay'];
    this.activationDelayBeginningMonth = cfg['activation_delay_beginning_month'];
    this.activationDelayUnit = cfg['activation_delay_unit'];
    this.active = cfg['active'];
    this.acumulable = cfg['acumulable'];
    this.availabilityDuration = cfg['availability_duration'];
    this.availabilityDurationNatural = cfg['availability_duration_natural'];
    this.availabilityDurationUnit = cfg['availability_duration_unit'];
    this.available_languages = cfg['available_languages'];
    this.availableFrom = cfg['available_from'];
    this.availableTo = cfg['available_to'];
    this.awardType = cfg['award_type'];
    this.countries = cfg['countries'];
    this.customEntityAttributes = cfg['custom_entity_attributes'];
    this.description = cfg['description'];
    this.description_ca = cfg['description_ca'];
    this.description_de = cfg['description_de'];
    this.description_en = cfg['description_en'];
    this.description_es = cfg['description_es'];
    this.description_fr = cfg['description_fr'];
    this.description_hr = cfg['description_hr'];
    this.description_hu = cfg['description_hu'];
    this.description_it = cfg['description_it'];
    this.description_pt = cfg['description_pt'];
    this.expirationPeriodType = cfg['expiration_period_type'];
    this.featureIds = cfg['feature_ids'];
    this.featureLocationIds = cfg['feature_location_ids'];
    this.fixedOptionSelected = cfg['expiration_period_type'];
    this.id = cfg['id'];
    this.includedFeatureLocations = cfg['included_feature_locations'];
    this.includedFeatures = cfg['included_features'];
    this.includedLocations = cfg['included_locations'];
    this.includedProducts = cfg['included_products'];
    this.locations = cfg['location_ids'];
    this.loyaltyScoreMoment = cfg['when'];
    this.name = cfg['name'];
    this.name_ca = cfg['name_ca'];
    this.name_de = cfg['name_de'];
    this.name_en = cfg['name_en'];
    this.name_es = cfg['name_es'];
    this.name_fr = cfg['name_fr'];
    this.name_hr = cfg['name_hr'];
    this.name_hu = cfg['name_hu'];
    this.name_it = cfg['name_it'];
    this.name_pt = cfg['name_pt'];
    this.once = cfg['once'];
    this.onceLimit = cfg['once_limit'];
    this.onlyFirstActivity = cfg['only_first_activity'];
    this.paymentMethodIds = cfg['payment_method_ids'];
    this.points = cfg['points'];
    this.pointsPercentage = cfg['points_percentage'];
    this.pointsPriceMultiplicator = cfg['points_price_multiplicator'];
    this.pointsPricePercentage = cfg['points_price_percentage'];
    this.productIds = cfg['product_ids'];
    this.recurrenceDays = cfg['recurrence_days'];
    this.recurrenceType = cfg['recurrence_type'];
    this.reward = cfg['reward'];
    this.rewardId = cfg['reward_id'];
    this.strategyType = cfg['strategy_type'];
    this.transferable = cfg['transferable'];
    this.when = cfg['when'];

    this.embeddedLoyaltyScoreMoment = {
      id: cfg['_embedded']['loyalty_score_moment'] && cfg['_embedded']['loyalty_score_moment']['id'] ? cfg['_embedded']['loyalty_score_moment']['id'] : null,
      name: cfg['_embedded']['loyalty_score_moment'] && cfg['_embedded']['loyalty_score_moment']['name'] ? cfg['_embedded']['loyalty_score_moment']['name'] : null,
      protected: cfg['_embedded']['loyalty_score_moment'] && cfg['_embedded']['loyalty_score_moment']['protected'] ? cfg['_embedded']['loyalty_score_moment']['protected'] : null,
      slug: cfg['_embedded']['loyalty_score_moment'] && cfg['_embedded']['loyalty_score_moment']['slug'] ? cfg['_embedded']['loyalty_score_moment']['slug'] : null,
      isCustomEntity: this.isMomentCustomEntity(cfg['_embedded']['loyalty_score_moment'])
    };
  }

  private isMomentCustomEntity(momentObj: object): boolean {
    if (!momentObj) return false;
    if (!momentObj.hasOwnProperty('slug')) return false;
    return momentObj['slug'].indexOf('custom_entity') === 0;
  }
}
