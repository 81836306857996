import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from '../../../../../../shared/services/confirmation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalStatusService } from '../../../../../../shared/services/modal-status.service';
import { QuestionBase } from '../../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../../shared/services/question-control.service';
import { Reward } from '../../../../../../shared/models/reward/reward';
import { RewardsService } from '../../../rewards.service';
import { Subject, finalize, takeUntil } from 'rxjs';
import { TextboxQuestion } from '../../../../../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-add-stock',
  templateUrl: './form-add-stock.component.html',
  styleUrls: ['./form-add-stock.component.scss']
})

export class FormAddStockComponent implements OnInit, OnDestroy {

  addStockForm: UntypedFormGroup;
  inputs: QuestionBase<any>[];
  loading: boolean;
  reward: Reward;
  rewardId = this.route.snapshot.parent.parent.params.id;
  private destroy$ = new Subject<void>();

  @ViewChild('modalToggleBtn', {static: true}) modalToggleBtn: ElementRef;

  constructor(
    private confirmationService: ConfirmationService,
    private modalStatusService: ModalStatusService,
    private qcs: QuestionControlService,
    private rewardService: RewardsService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getRewardById(this.rewardId);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  closeModal() {
    this.router.navigate([{outlets: { modal: `show/rewards/${this.rewardId}/stock` }}]).catch(() => {});
  }

  getInputs(): QuestionBase<any>[] {
    this.inputs = [
      new TextboxQuestion({
        key: 'total',
        type: 'number',
        cssClasses: 'form-control input-default',
        max: 10000
      })
    ];
    return this.inputs;
  }

  sendData () {
    const payload = {
      total: this.addStockForm.value.total
    };
    this.loading = true;
    this.rewardService.addMoreStock(payload, this.rewardId).pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.scores.rewards.stock.messages.success_title'),
          this.translate.instant('resources.scores.rewards.stock.messages.success_text')
        ).catch(() => {});
        this.modalStatusService.modalStatus.emit();
        this.returnToList();
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
        this.confirmationService.displayHttpErrorAlert(error);
      }
    });
  }

  private returnToList() {
    this.router.navigate([{ outlets: { modal: `show/rewards/${this.rewardId}/stock` } }]).catch(() => {});
  }

  private getRewardById(id:number) {
    this.loading = true;
    this.rewardService.getRewardsById(id).pipe(finalize(() => this.loading = false)).subscribe({
      next: (resp: Reward) => {
        this.reward = resp;
        this.inputs = this.getInputs();
        this.addStockForm = this.qcs.toFormGroup(this.inputs);
      },
      error: (error) => {
        this.confirmationService.displayErrorAlert('Error', error.error.error)
      }
    });
  }

}
