<div class="row" *ngIf="element">
  <div class="col-md 12">
    <div class="card mrg-left-15 mrg-right-15">
      <div class="card-block">
        <h4 class="card-title mrg-left-35">{{element.name}}</h4>
        <div class="flex">
          <span class="card-title mrg-left-35 font-size-13">
            <i class="ei-profit"></i>
            {{element.name}}
          </span>
          <span class="card-title mrg-left-35 font-size-13" *ngIf="reportData">
            <i class="ei-timer"></i>
            {{ 'resources.reports.fields.last_generated' | translate }}
            {{reportData.last_generation_at | dateTimeZone: 'DD/MM/YYYY HH:mm'}}
          </span>
        </div>
        <div #reportSegmentChart></div>
      </div>
    </div>
  </div>
</div>
