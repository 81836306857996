<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-40">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form  [formGroup]="creditsForm" *ngIf="creditsForm"> 
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="amount" [translate]="'resources.credits.form.amount'"></label>
                      <app-question [question]="getInputConfig('amount')" [form]="creditsForm"></app-question>
                    </div>
                  </div>
                </div>           
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="action" [translate]="'resources.credits.form.action'"></label>
                      <app-question [question]="getInputConfig('action')" [form]="creditsForm"></app-question>
                    </div>
                  </div>
                </div>                  
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="kind" [translate]="'resources.credits.form.kind'"></label>
                      <app-question [question]="getInputConfig('kind')" [form]="creditsForm"></app-question>
                    </div>
                  </div>
                </div>                  
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="notes" [translate]="'resources.credits.form.notes'"></label>
                      <app-question [question]="getInputConfig('notes')" [form]="creditsForm"></app-question>
                    </div>
                  </div>
                </div>                       
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
