export class CompanyData {
  id: number;
  smsCredits: number;
  smsCreditsPrice: { prefix: string, price: number }[];
  smsAproxDeliverAmount: number;
  emailCredits: number;
  emailCreditsPrice: number;
  emailAproxDeliverAmount: number;
  currency: string;
  multiCurrency: boolean;
  availableLanguages: string[];
  locale: string;

  constructor (options) {
    this.id = options.id;
    this.smsCredits = options.sms_credits;
    this.smsCreditsPrice = options.sms_credit_price;
    this.smsAproxDeliverAmount = (this.smsCredits / this.smsCreditsPrice[0].price);
    this.emailCredits = options.email_credits;
    this.emailCreditsPrice = options.email_credit_price;
    this.emailAproxDeliverAmount = this.emailCredits / this.emailCreditsPrice;
    this.currency = options.currency;
    this.multiCurrency = options.multicurrency;
    this.availableLanguages = options.available_languages;
    this.locale = options.locale;
  }
}
