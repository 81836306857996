import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Plan } from '../../../../resources/plans/plan';
import { PlanCampaign } from '../../../../resources/plans/campaigns/plan-campaign';

@Component({
  selector: 'app-steps-bubbles',
  templateUrl: './steps-bubbles.component.html',
  styleUrls: ['./steps-bubbles.component.scss']
})
export class StepsBubblesComponent implements OnInit {

  public steps: { title: string, isActive: boolean, isDisabled: boolean, isCompleted: boolean}[];
  public progressBarWidthPercentage: string;
  public currentStep: number;
  public slug: string;

  @Input() plan: Plan;
  @Input() planCampaign: PlanCampaign;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {
    this.currentStep = 1;
  }

  ngOnInit() {
    this.slug = this.route.snapshot.data.slug;
    this.steps = this.getStaticSteps();
    switch (this.slug) {
      case 'step_1':
        this.currentStep = 1;
        this.steps[0].isActive = true;
        this.steps[0].isDisabled = false;
        break;
      case 'step_2':
        this.currentStep = 2;
        this.steps[0].isActive = true;
        this.steps[0].isDisabled = false;
        this.steps[0].isCompleted = true;
        this.steps[1].isActive = true;
        this.steps[1].isDisabled = false;
        this.progressBarWidthPercentage = '33.33%';
        break;
      case 'step_3':
        this.currentStep = 3;
        this.steps[0].isActive = true;
        this.steps[0].isDisabled = false;
        this.steps[0].isCompleted = true;
        this.steps[1].isActive = true;
        this.steps[1].isDisabled = false;
        this.steps[1].isCompleted = true;
        this.steps[2].isActive = true;
        this.steps[2].isDisabled = false;
        this.progressBarWidthPercentage = '66.66%';
        break;
      case 'step_4':
        this.currentStep = 4;
        this.steps[0].isActive = true;
        this.steps[0].isDisabled = false;
        this.steps[0].isCompleted = true;
        this.steps[1].isActive = true;
        this.steps[1].isDisabled = false;
        this.steps[1].isCompleted = true;
        this.steps[2].isActive = true;
        this.steps[2].isDisabled = false;
        this.steps[2].isCompleted = true;
        this.steps[3].isActive = true;
        this.steps[3].isDisabled = false;
        this.steps[3].isCompleted = true;
        this.progressBarWidthPercentage = '100%';
        break;
      default:
        break;
    }
  }

  private getStaticSteps() {
    return [
      {title: this.translate.instant('resources.campaign_plans.steps.step_1'), isActive: true, isDisabled: false, isCompleted: false},
      {title: this.translate.instant('resources.campaign_plans.steps.step_2'), isActive: false, isDisabled: false, isCompleted: false},
      {title: this.translate.instant('resources.campaign_plans.steps.step_3'), isActive: false, isDisabled: false, isCompleted: false},
      {title: this.translate.instant('resources.campaign_plans.steps.step_4'), isActive: false, isDisabled: false, isCompleted: false}
    ];
  }

}
