import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableConfiguration } from '../../../../shared/components/data-table/data-table-cfg';
import { Subscription } from 'rxjs';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { CashBackService } from './cashback.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';


@Component({
  selector: 'app-tab-cashback',
  templateUrl: './tab-cashback.component.html',
})
export class TabCashbackComponent implements OnInit, OnDestroy {

  dataTableConfig: DataTableConfiguration;
  subs$: Subscription[] = [];

  constructor(
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cashbackSrv: CashBackService,
    private modalStatusService: ModalStatusService ,   
  ) {}

  ngOnInit() {
    this.setDataTableConfig();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  private setDataTableConfig() {
    this.dataTableConfig = {
        renderRowCheckbox: false,
        isActive: true,
        requiresCacheService: false,
        requestData: {
          apiEndPoint: `/cashback_thresholds`,
          pageNumber: 0,
          sorting: {},
          filtering: {},
          numberPerPage: 10
        },
      createButton: {
        label: this.translate.instant('resources.cashback_threshold.loyalty.buttons.create'),
        redirectTo: [{outlets: { modal: 'new/cashback' }}]
      },
      rows: [],
      rowActions: [
        {
          name: this.translate.instant('resources.cashback_threshold.loyalty.buttons.edit'),
          icon: 'pencil',
          id: 'edit',
          customRoute: (element: { country: string }) => {
            return ['', {outlets: {modal: ['update', 'cashback', element.country ]}}];
          }
        },
        {
          name: this.translate.instant('resources.cashback_threshold.loyalty.buttons.delete'),
          icon: 'trash',
          id: 'delete',
          callback: (element) => this.onClickDeleteCashback(element)
        }
      ],
      tableActions: [],
      columns: [
        {
          name: '',
          prop: 'blank',
        },
        {
          name: this.translate.instant('resources.cashback_threshold.loyalty.columns.country'),
          prop: 'country'
        },
        {
          name: this.translate.instant('resources.cashback_threshold.loyalty.columns.min_points'),
          prop: 'score'
        },
        {
          name: this.translate.instant('resources.cashback_threshold.loyalty.columns.min_money_spent'),
          prop: 'money_representation'
        },
        {
          name: this.translate.instant('resources.cashback_threshold.loyalty.columns.currency'),
          prop: 'currency'
        },
      ],
      tableMapping: [
        { prop: 'country', type: 'text', apiProp: 'country' },
        { prop: 'score', type: 'number', apiProp: 'score' },
        { prop: 'money_representation', type: 'number', apiProp: 'money_representation'},
        { prop: 'currency', type: 'text', apiProp: 'currency' }
      ]
    };
  }

  onClickDeleteCashback(value:unknown) {
    this.confirmationService.displayConfirmationAlert(
      this.translate.instant('common.messages.are_you_sure'),
      this.translate.instant('resources.cashback_threshold.loyalty.form.confirm_delete'),
    ).then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        const deleteCashback$ = this.cashbackSrv.deleteCashback(value['country']).subscribe(
        () =>  {
          this.confirmationService.displaySuccessAlert('', this.translate.instant('resources.cashback_threshold.loyalty.form.delete_success')).catch(() => {});
          this.modalStatusService.modalStatus.emit();
        },
        errData => {
          deleteCashback$.unsubscribe();
          this.confirmationService.displayErrorAlert('Error', errData.error.error);
        }
        );
        this.subs$.push(deleteCashback$);
      }
      }
    ).catch(() => {});
  }

}
