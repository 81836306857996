import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-show-history',
  templateUrl: './show-history.component.html',
  styleUrls: ['./show-history.component.css']
})
export class ShowHistoryComponent implements OnInit {

  isMissingImg: boolean;
  @Input() couponDetail;

  constructor() { }

  ngOnInit() {
    this.isMissingImg = !this.couponDetail.thumbnail_medium;
  }
}
