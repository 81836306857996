import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableComponent } from '../../../shared/components/data-table/data-table.component';

@Component({
  selector: 'app-profiles-tab-collectives',
  templateUrl: './tab-collectives.component.html'
})

export class TabCollectivesComponent implements OnInit {

  public title = this.translate.instant('resources.collectives.title');

  public dataTableConfig = {
    isMock: false,
    isActive: true,
    tableActions: [
      {
        name: this.translate.instant('components.table_actions.export_csv'),
        icon: 'ei-export',
        id: 'exportCSV'
      }
    ],
    requestData: {
      apiEndPoint: 'collectives',
      pageNumber: 0,
      sorting: {
        byProp: null,
        direction: null
      },
      filtering: [],
      numberPerPage: 10
    },
    columns: [
      { name: this.translate.instant('resources.collectives.columns.id'), prop: 'id' },
      { name: this.translate.instant('resources.collectives.columns.name'), prop: 'name' },
      { name: this.translate.instant('resources.collectives.columns.slug'), prop: 'slug' }
    ],
    tableMapping: [
      { prop: 'id', type: 'number', apiProp: 'id' },
      { prop: 'name', type: 'text', apiProp: 'name' },
      { prop: 'slug', type: 'text', apiProp: 'slug' },
    ]
  };

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  constructor(public translate: TranslateService) {}

  ngOnInit() {}

  onFilterHandler(filterFormValue) {
    this.dataTable.onFilter(filterFormValue);
  }
}
