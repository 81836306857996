import { Component } from '@angular/core';

@Component({
  selector: 'app-bigquery-jobs',
  templateUrl: './bigquery-jobs.component.html'
})
export class BigqueryJobsComponent {

  constructor() {}

}
