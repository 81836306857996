<a #modalToggleBtn
   data-toggle="modal"
   data-target="#email-template-edit-modal"
   data-keyboard="false"
   data-backdrop="false"
   hidden></a>
<div class="modal fade modal-fs show" id="email-template-edit-modal" [attr.data-backdrop]="mode !== 'reminder_plan' && mode !== 'received_reminder'">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body height-100">
        <div id='bee-plugin-container'></div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-info"
                (click)="closeWithConfirmation()"
                [translate]="'resources.campaign_templates.modal.buttons.close'"></button>
      </div>
    </div>
  </div>
</div>
