import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RedemptionsService extends ResourcesService{

  constructor(http: HttpClient) {
    super(http);
  }


  public getRedemptionsData(id: number) {
    return this.getData({ apiEndPoint: `redemptions/${id}` });
  }

}
