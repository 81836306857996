import * as moment from 'moment';
import { PlanCampaignAction } from '../campaigns/custom-campaign-plan-audience/plan-campaign-action';
import { Plan } from '../plan';

export interface EmbeddedCampaign {
  id: number;
  name: string;
  status: string;
  actions: PlanCampaignAction[];
  customers?: number;
  impactedCustomers?: number;
  estimatedDelivery?: number;
  redemptions?: number;
  budgetExpense?: number;
  budgetExpenseRatio?: number;
  incSales?: number;
  incSalesRatio?: number;
  affectationSales?: number;
  seasonality?: number;
  maxRedemptionsPerCoupon?: number;
  totalRedemptions?: number;
  estimatedRedemptionPerc?: number;
  uniqueRedemptions?: number;
  discountCost?: number;
  lgCost?: number;
  incrementalOverSales?: number;
  deliveryCost?: number;
}

export class PlanForecastCampaignGroup {

  type: string;
  category: string;
  campaigns: EmbeddedCampaign[];
  actions: PlanCampaignAction[];
  subtotal: PlanForecastCampaignSubtotal;
  rowsPerCategory: number;
  plan: Plan;

  constructor(cfg: object) {
    this.plan = cfg['plan'];
    this.type = cfg['type'];
    this.category = cfg['category'];
    this.actions = cfg['actions'].map(obj => {
      const action = new PlanCampaignAction(obj);
      if (obj['audience'] && obj['audience']['data']) { action.processAudienceData(obj['audience']['data']); }
      if (obj['forecast'] && obj['forecast']['data']) { action.processForecastData(obj['forecast']['data']); }
      if (obj['forecast'] && obj['forecast']['errors'] && obj['forecast']['errors'].length > 0) {
        action.processErrorsData(obj['forecast']['errors']);
      }
      return action;
    });
    this.campaigns = this.parseCampaigns();
    this.rowsPerCategory = this.actions.length + this.campaigns.length + 1; // actions + 1 row per each campaign + 1 subtotal row
    this.subtotal = new PlanForecastCampaignSubtotal(this.actions.filter(this.onlyCompleteOrDelivered), this.plan);
  }

  private parseCampaigns(): EmbeddedCampaign[] {

    const uniqCampaignIds = this.actions.map(action => action.embeddedCampaign.id).filter(this.onlyUnique);

    return uniqCampaignIds.map(id => {
      const groupedActions = this.actions.filter(action => action.embeddedCampaign.id === id);
      const impactedCustomers = groupedActions.reduce((acc, obj) => acc + (obj.forecast.customers || 0), 0);
      const totalCustomers = groupedActions.reduce((acc, obj) => acc + (obj.audience.totalCustomers || 0), 0);
      const budgetExpense = groupedActions.reduce((acc, obj) => acc + (obj.forecast.budgetExpense || 0), 0);
      const incSales = groupedActions.reduce((acc, obj) => acc + (obj.forecast.incSales || 0), 0);
      const totalDeliveredCoupons = groupedActions.reduce((acc, obj) => acc + (obj.forecast.delivered || 0), 0);
      const totalRedemptions = groupedActions.reduce((acc, obj) => acc + (obj.forecast.totalRedemptions || 0), 0);
      const totalRedemptionsPercent = totalDeliveredCoupons === 0 ? 0 : totalRedemptions / totalDeliveredCoupons;

      const affectationSales = groupedActions.reduce((acc, obj) => acc + (obj.audience.affectationSales || 0), 0);
      const sumSalesMpaa = groupedActions.reduce((acc, obj) => acc + (obj.audience.salesMpaa || 0), 0);
      const sumSalesPeriod5Mpaa = groupedActions.reduce((acc, obj) => acc + (obj.audience.salesPeriodMpaa || 0), 0);
      const seasonalityAvg = sumSalesPeriod5Mpaa !== 0 ? (sumSalesMpaa / sumSalesPeriod5Mpaa) : 0;

      const maxRedemptionsPerCoupon = groupedActions.reduce((acc, obj) => acc + (obj.forecast.maxRedemptionsPerCoupon || 0), 0);
      const estimatedRedemptionPerc = totalDeliveredCoupons !== 0 ? ((totalRedemptions / totalDeliveredCoupons) * 100) : 0;
      const uniqueRedemptions = groupedActions.reduce((acc, obj) => acc + (Math.round(obj.forecast.uniqueRedemptions) || 0), 0);
      const discountCost = groupedActions.reduce((acc, obj) => acc + (obj.forecast.budgetExpense || 0), 0);
      const deliveryCost = groupedActions.reduce((acc, obj) => acc + (obj.forecast.deliveryCost || 0), 0);
      const lgCost = groupedActions.reduce((acc, obj) => acc + (obj.forecast.lgCost || 0), 0);

      const totalAffectationSalesByPlanDaysCount = affectationSales * getPlanDaysCount(this.plan);
      const incrementalOverSales = totalAffectationSalesByPlanDaysCount !== 0 ? (incSales / totalAffectationSalesByPlanDaysCount) * 100 : 0;

      return {
        id: id,
        name: groupedActions[0].embeddedCampaign.name,
        status: groupedActions[0].embeddedCampaign.status,
        actions: groupedActions,
        customers: totalCustomers,
        impactedCustomers: impactedCustomers,
        budgetExpense: budgetExpense,
        incSales: incSales,
        incSalesRatio: budgetExpense === 0 ? 0 : incSales / budgetExpense,
        estimatedDelivery: impactedCustomers === 0 ? 0 : totalDeliveredCoupons / impactedCustomers,
        redemptions: totalRedemptionsPercent,
        affectationSales: affectationSales,
        seasonality: seasonalityAvg,
        maxRedemptionsPerCoupon: maxRedemptionsPerCoupon,
        totalRedemptions: totalRedemptions,
        estimatedRedemptionPerc: estimatedRedemptionPerc,
        uniqueRedemptions: uniqueRedemptions,
        discountCost: discountCost,
        lgCost: lgCost,
        incrementalOverSales: incrementalOverSales,
        forecastConfig: groupedActions[0].forecastConfig,
        deliveryCost: deliveryCost
      };
    });
  }

  private onlyUnique(value: any, index: number, self: any[]) {
    return self.indexOf(value) === index;
  }

  private onlyCompleteOrDelivered(action: PlanCampaignAction, index: number, self: object[]) {
    return ['completed', 'delivered'].includes(action.embeddedCampaign.status);
  }
}

export class PlanForecastCampaignSubtotal {

  impactedCustomers: number;
  budgetExpense: number;
  budgetRatio: number;
  incSales: number;
  incRatio: number;
  estimatedDelivery: number;
  couponsDelivered: number;
  totalRedemptionsPercentage: number;
  totalRedemptions: number;
  totalCustomers: number;
  affectationSales: number;
  sumSalesMpaa: number;
  sumSalesPeriod5Mpaa: number;
  seasonality: number;
  maxRedemptionsPerCoupon: number;
  estimatedRedemptionPerc: number;
  uniqueRedemptions: number;
  discountCost: number;
  lgCost: number;
  incrementalOverSales: number;
  plan: Plan;
  deliveryCost: number;

  constructor(actions: PlanCampaignAction[], plan?: Plan) {

    this.plan = plan;

    this.sumSalesMpaa = actions.reduce((acc, obj) => acc + (obj.audience.salesMpaa || 0), 0);
    this.sumSalesPeriod5Mpaa = actions.reduce((acc, obj) => acc + (obj.audience.salesPeriodMpaa || 0), 0);
    const seasonalityAvg = this.sumSalesPeriod5Mpaa !== 0 ? (this.sumSalesMpaa / this.sumSalesPeriod5Mpaa) : 0;

    this.impactedCustomers = actions.reduce((acc, obj) => acc + (obj.forecast.customers || 0), 0);
    this.budgetExpense = actions.reduce((acc, obj) => acc + (obj.forecast.budgetExpense || 0), 0);
    this.incSales = actions.reduce((acc, obj) => acc + (obj.forecast.incSales || 0), 0);
    this.incRatio = this.budgetExpense === 0 ? 0 : this.incSales / this.budgetExpense;
    this.couponsDelivered = actions.reduce((acc, obj) => acc + (obj.forecast.delivered || 0), 0);
    this.totalRedemptions = actions.reduce((acc, obj) => acc + (obj.forecast.totalRedemptions || 0), 0);
    this.estimatedDelivery = this.impactedCustomers === 0 ? 0 : this.couponsDelivered / this.impactedCustomers;
    this.totalRedemptionsPercentage = this.couponsDelivered === 0 ? 0 : this.totalRedemptions / this.couponsDelivered;
    this.totalCustomers = actions.reduce((acc, obj) => acc + (obj.audience.totalCustomers || 0), 0);
    this.affectationSales = actions.reduce((acc, obj) => acc + (obj.audience.affectationSales || 0), 0);
    this.seasonality = seasonalityAvg;
    this.maxRedemptionsPerCoupon = actions.reduce((acc, obj) => acc + (obj.forecast.maxRedemptionsPerCoupon || 0), 0);

    const estimatedRedemptionPerc = this.couponsDelivered !== 0 ? ((this.totalRedemptions / this.couponsDelivered) * 100) : 0;
    this.estimatedRedemptionPerc = estimatedRedemptionPerc;

    this.uniqueRedemptions = actions.reduce((acc, obj) => acc + (Math.round(obj.forecast.uniqueRedemptions) || 0), 0);
    this.discountCost = actions.reduce((acc, obj) => acc + (obj.forecast.budgetExpense || 0), 0);
    this.lgCost = actions.reduce((acc, obj) => acc + (obj.forecast.lgCost || 0), 0);
    this.deliveryCost = actions.reduce((acc, obj) => acc + (obj.forecast.deliveryCost || 0), 0);

    if(plan){
      const totalAffectationSalesByPlanDaysCount = this.affectationSales * getPlanDaysCount(this.plan);
      const incrementalOverSales = totalAffectationSalesByPlanDaysCount !== 0 ? (this.incSales / totalAffectationSalesByPlanDaysCount) * 100 : 0;
      this.incrementalOverSales = incrementalOverSales;
    }else{
      // TODO: How this should be calculated ?
      this.incrementalOverSales = -1;
    }
  }
}

export function getPlanDaysCount(plan: Plan): number {
  const mDate1 = moment(plan.available_from);
  const mDate2 = moment(plan.available_to);
  const result = mDate2.diff(mDate1, 'days');
  return result + 1;
}
