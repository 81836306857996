<form [formGroup]="campaignForm" *ngIf="campaignForm">
  <div
    [ngClass]="{'modal-xl my-modal-backdrop': isInReminderMode() || isInPlanCampaignMode(),
      'modal-fs': activeMode !== MODE.ReminderPlan && activeMode !== MODE.PlanCampaign}"
    class="modal-backdrop fade in"
    id="modal-fs"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div [ngClass]="{'height90vh': isInReminderMode() || isInPlanCampaignMode()}" class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header background-white" *ngIf="inputs">
          <ul class="nav nav-tabs" role="tablist" *ngIf="activeMode === MODE.ReceivedReminder">
            <li class="nav-item" *ngFor="let item of reminderIds; let i = index">
                <a (click)="openReminder(item)" class="nav-link pointer" role="tab" data-toggle="tab" [ngClass]="{'active': selectedReminder === item }">{{item.name}}</a>
            </li>
          </ul>
          <div class="row card-heading flex-grow-1 no-pdd-left pdd-right-15 no-pdd-top no-pdd-btm navigation">
            <div class="col-md-12" *ngIf="activeMode !== MODE.ReceivedReminder">
              <div class="row pdd-right-0">
                <div class="col-md-8 ml-auto mr-auto">
                  <label class="text-bold">
                    {{'resources.campaigns.fields.name_label' | translate}}
                    <span class="text-danger">*</span>
                  </label>
                  <app-question [question]="getInputConfig('name')" [form]="campaignForm"></app-question>
                </div>
              </div>
            </div>
            <a class="modal-close pull-right" data-dismiss="modal" (click)="close()">
              <i class="ti-close"></i>
            </a>
          </div>
        </div>
        <!-- Modal body + Loader -->
        <div class="modal-body height-100" *ngIf="!inputs">
          <app-loader noWrapper />
        </div>
        <!-- Modal body + FormFields -->
        <div class="modal-body background-gray height-100" *ngIf="inputs">
          <div class="card background-gray no-border">
            <div class="row">
              <div class="col-md-8 ml-auto mr-auto">
                <div *ngIf="!campaign.editable && campaign.status === 'checking'">
                  <div class="alert alert-warning alert-dismissible fade show" role="alert">
                    <strong>{{'resources.campaigns.warnings.uneditable' | translate}}. </strong>
                    {{'resources.campaigns.warnings.validating' | translate}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
                <div *ngIf="campaignForm.errors?.length > 0">
                  <div class="alert alert-danger alert-dismissible fade show"
                        *ngFor="let error of getErrors()"
                        role="alert">
                    <b>{{error.field}}:</b> {{error.message}}.
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="removeError(error)">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
                <div *ngIf="!campaign.editable && campaign.status !== 'checking'">
                  <div class="alert alert-warning alert-dismissible fade show" role="alert">
                    <strong>{{'resources.campaigns.warnings.uneditable' | translate}}. </strong>
                    {{'resources.campaigns.messages.delivery_already_sent' | translate}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="campaign.last_send_history && campaign.last_send_history.sent_at">
                    <strong>{{'resources.campaigns.fields.last_delivery' | translate}}: </strong> {{campaign.last_send_history.sent_at | date: 'dd/MM/yyyy HH:mm:ss'}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>

                <div *ngIf="campaignForm.errors?.length > 0">
                  <div class="alert alert-danger alert-dismissible fade show"
                        *ngFor="let error of getErrors()"
                        role="alert">
                    <b>{{error.field}}:</b> {{error.message}}.
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="removeError(error)">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>

                <!-- Definition Block -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="card-title">
                      {{'resources.campaigns.fields.definition' | translate}}
                    </h4>
                  </div>
                  <div class="card-block">
                    <div class="row" *ngIf="activeMode === MODE.ReceivedReminder">
                      <div class="col-md-8">
                        <label class="text-bold">
                          {{'resources.campaigns.fields.name_label' | translate}}
                          <span class="text-danger">*</span>
                        </label>
                        <app-question [question]="getInputConfig('name')" [form]="campaignForm"></app-question>
                      </div>
                    </div>
                    <app-campaign-definition-block [inputs]="inputs"
                                                    [campaignForm]="campaignForm"
                                                    [campaign]="campaign"
                                                    [mode]="mode"
                                                    [refreshSender]="refreshSender"
                                                    (resetTemplateEmitter)="resetCampaignTemplate()">
                    </app-campaign-definition-block>
                  </div>
                </div>
                <!-- END DEFINITION BLOCK  -->

                <!-- ##### Segment Block  ###### -->
                <div class="card" *ngIf="campaignForm && shouldDisplaySegmentBlock()">
                  <div class="card-heading border bottom">
                    <h4 class="card-title">{{'resources.campaigns.fields.segmentation' | translate}}</h4>
                  </div>
                  <div class="card-block">
                    <app-campaign-segment-block [inputs]="inputs"
                                                [campaignForm]="campaignForm"
                                                [mode]="mode">
                    </app-campaign-segment-block>
                  </div>
                </div>
                <!-- ### END SEGMENT BLOCK ### -->

                <!-- CONTENT BLOCK -->
                <div class="card" *ngIf="campaign.hasContent">
                  <div class="card-heading border bottom">
                    <h4 class="card-title">{{'resources.campaigns.fields.content' | translate}}</h4>
                  </div>
                  <div class="card-block">
                    <app-campaign-content-block [actionRaw]="actionRaw"
                                                [inputs]="inputs"
                                                [campaignForm]="campaignForm"
                                                [campaign]="campaign"
                                                [mode]="mode"
                                                (viewOrEditTemplateEmitter)="templateHandler($event)">
                    </app-campaign-content-block>
                  </div>
                </div>
                <!-- ### END CONTENT BLOCK ### -->

                <!-- Report Block -->
                <ng-container *ngIf="campaign.motive !== 'membership'">
                  <div class="card" *ngIf="campaign.report && campaign.validation_status !== 'ok'">
                    <div class="card-heading border bottom">
                      <h4 class="card-title">{{'resources.campaigns.fields.report' | translate}}</h4>
                    </div>
                    <div class="card-block">
                      <app-campaign-report-block [campaign]="campaign"
                                                  [displayControlGroupRoundedWarning]="displayControlGroupRoundedWarning">
                      </app-campaign-report-block>
                    </div>
                  </div>
                </ng-container>
                <!-- END REPORT BLOCK -->

                <!-- Campaign Validation Block -->
                <div class="card" *ngIf="campaign.report">
                  <div class="card-heading border bottom">
                    <h4 class="card-title">
                      {{'resources.campaigns.messages.campaign_validation' | translate}}
                    </h4>
                  </div>
                  <div class="card-block">
                    <app-campaign-validation-block [inputs]="inputs"
                                                    [campaign]="campaign"
                                                    [campaignForm]="campaignForm"
                                                    [companyCreditsInfo]="companyCreditsInfo">
                    </app-campaign-validation-block>
                  </div>
                </div>
                <!-- END VALIDATION BLOCK -->
              </div>
            </div>
          </div>
        </div>

        <div class="row modal-footer" *ngIf="campaign">
          <div class="col-md-8 ml-auto mr-auto text-right">
            <span *ngIf="loading">
              <i class="fa fa-spinner fa-spin"></i>
            </span>

            <button type="button" class="btn btn-primary text-left" (click)="reUse()" [hidden]="campaign.status !== 'delivered' || isInPlanCampaignMode()" [disabled]="loading || invalidScheduler || !campaignForm.valid">
              <span>{{'resources.campaigns.buttons.reuse' | translate}}</span>
            </button>

            <button type="button" class="btn btn-info" (click)="saveOrValidateOrSend('save')" [hidden]="!campaign.editable" [disabled]="loading || invalidScheduler || !campaignForm.valid">
              <span>{{'resources.campaigns.buttons.save' | translate}}</span>
            </button>

            <button type="button" class="btn btn-info" (click)="openCampaignValidateModal()" [hidden]="!campaign.editable" [disabled]="loading || invalidScheduler || !campaignForm.valid">
              <span>{{'resources.campaigns.buttons.validate' | translate}}</span>
            </button>

            <button type="button" class="btn btn-primary no-mrg-right" (click)="saveOrValidateOrSend('send')" [hidden]="!campaign.editable || isInPlanCampaignMode()" [disabled]="loading || invalidScheduler || !campaignForm.valid || !hasBeenValidated || !ableToApprove">
              <span [innerText]="actionBtnLabel"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- Push template edition modal -->
<app-push-template-edit #pushTemplateEdit
                        *ngIf="campaign"
                        [template]="campaign.template"
                        (pushEmitter)="handleTemplateSaved('push', $event)"
                        [modeReminder]="mode">
</app-push-template-edit>
<!--  -->

<!-- SMS template edition modal -->
<app-sms-template-edit #smsTemplateEdit
                       *ngIf="campaign"
                       [message]="campaign.template?.message"
                       (messageEmitter)="handleTemplateSaved('sms', $event)"
                       [modeReminder]="mode">
</app-sms-template-edit>
<!--  -->

<!-- Email template quick view -->
<app-email-template-view #emailTemplateView
                         *ngIf="campaign"
                         [template]="campaign.template"
                         [mode]="mode">
</app-email-template-view>
<!--  -->

<!-- Email template edit -->
<app-email-template-edit #emailTemplateEdit
                         *ngIf="campaign"
                         [template]="campaign.template"
                         (emailTemplateEmitter)="handleTemplateSaved('email', $event)"
                         [mode]="mode">
</app-email-template-edit>
<!--  -->

<app-campaign-validate-modal (onValidateCampaign)="confirmCampaignValidate($event)"></app-campaign-validate-modal>
