import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from '../../../shared/services/resources.service';
import { Observable } from 'rxjs';
import { MultiselectDataSource, MultiselectDataSourceable } from '../../../shared/components/multiselect/multiselect';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';

@Injectable()
export class CampaignCategoriesService extends ResourcesService implements MultiselectDataSourceable {

  public inputs: TextboxQuestion[];

  constructor(
    http: HttpClient,
    private translate: TranslateService
  ) {
    super(http);
  }

  public getCampaignCategoriesById(id: number): Observable<any> {
    return this.getData({apiEndPoint: `campaign_categories/${id}`});
  }

  public fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'campaign_categories',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filter = {filtering: { name: searchValues}};
      requestOptions = {...requestOptions, ...filter};
    }

    return this.getData(requestOptions).pipe(
      map(itemArray => {
        const list = itemArray['list'];
        list.unshift({id: -1, name: this.translate.instant('resources.campaign_categories.messages.no_category')});
        return itemArray;
      })
    );
  }

  public fetchSelectedById(id: number) {
    if (id < 1) {
      return of({id: -1, name: this.translate.instant('resources.campaign_categories.messages.no_category')});
    }
    return this.getCampaignCategoriesById(id);
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id,  element.name);
  }

  public sendData(creditsData) {
    return this.postResource(creditsData, 'campaign_categories');
  }

  public updateData(creditsData: any, _id: number) {
    return this.patchResource(creditsData, `campaign_categories/${_id}`);
  }

  public deleteCampaignCategory(id: number) {
    return this.deleteResource({}, `campaign_categories/${id}`);
  }
}
