import { AbstractControl, UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";
import { CustomEntityKey } from "../../enums/custom-entity-key.enums";
import { POSITIVE_INTEGER_REGEX } from "../../../../constants/condition.constants";
import { checkValue2GreaterValue1Validation, setRequiredControlsValidators, setValidatorsErrorsMark } from "../utils/common.validations.utils";
import { isNullOrUndefined } from "../../../../../../utils/common.utils";
import { checkIntegerValuesBtOperatorValidation, getIsValuePositiveInteger } from "../utils/integer-validations.utils";
import { MIN_POSITIVE_INTEGER_0_VALUE_REQUIRED, MIN_POSITIVE_INTEGER_1_VALUE_REQUIRED, MIN_POSITIVE_INTEGER_VALUES_REQUIRED, OPERATOR_REQUIRED, POSTIVIE_INTEGER_VALUE_REQUIRED } from "../constants/controls-validators.constants";

export function handleQuantityControlsValidators(control: UntypedFormGroup): ValidationErrors | null {
  const quantityOperatorCtrl = control.get(CustomEntityKey.QuantityOperator);
  const quantityValueCtrl = control.get(CustomEntityKey.QuantityValue);
  const quantityValue2Ctrl = control.get(CustomEntityKey.QuantityValue2);
  const value = quantityValueCtrl?.value ?? null;
  const value2 = quantityValue2Ctrl?.value ?? null;

  const controls = [quantityOperatorCtrl, quantityValueCtrl, quantityValue2Ctrl];
  controls.forEach(ctrl => ctrl?.setValidators([]));

  const isValuePositiveInteger = getIsValuePositiveInteger(value);
  const isValue2PositiveInteger = getIsValuePositiveInteger(value2);

  if (quantityOperatorCtrl?.value?.length) {
    if (quantityOperatorCtrl.value[0].id === 'bt') {
      checkQuantityIntegerBtOperatorValidation(quantityValueCtrl, quantityValue2Ctrl, value, value2, isValuePositiveInteger, isValue2PositiveInteger);
    } else if (isNullOrUndefined(value) || (!value && value !== 0)) {
      setValidatorsErrorsMark(quantityValueCtrl, [Validators.required, Validators.pattern(POSITIVE_INTEGER_REGEX)], MIN_POSITIVE_INTEGER_0_VALUE_REQUIRED);
      return {[MIN_POSITIVE_INTEGER_0_VALUE_REQUIRED]: true};
    } else {
      quantityValueCtrl.setErrors(null);
      checkValuePositiveIntegerValidation(isValuePositiveInteger, quantityValueCtrl);
    }
  }

  checkValuePositiveIntegerValidation(isValuePositiveInteger, quantityValueCtrl);
  checkOperatorValidation(quantityOperatorCtrl);
  return null;
}

function checkValuePositiveIntegerValidation(isValuePositiveInteger: boolean, quantityValueCtrl: AbstractControl): ValidationErrors | null {
  if (!isValuePositiveInteger) {
    setValidatorsErrorsMark(quantityValueCtrl, [Validators.pattern(POSITIVE_INTEGER_REGEX)], POSTIVIE_INTEGER_VALUE_REQUIRED);
    return {[POSTIVIE_INTEGER_VALUE_REQUIRED]: true};
  }
}

function checkOperatorValidation(quantityOperatorCtrl: AbstractControl): ValidationErrors | null {
  if (!quantityOperatorCtrl.value || (quantityOperatorCtrl?.value instanceof Array && !quantityOperatorCtrl?.value?.length)) {
    setRequiredControlsValidators(quantityOperatorCtrl);
    return {[OPERATOR_REQUIRED]: true};
  }
}

function checkQuantityIntegerBtOperatorValidation(
  quantityValueCtrl: AbstractControl,
  quantityValue2Ctrl: AbstractControl,
  value: string | number,
  value2: string | number,
  isValuePositiveInteger: boolean,
  isValue2PositiveInteger: boolean
): ValidationErrors | null {
  if ((isNullOrUndefined(value) || (!value && value !== 0))  && (isNullOrUndefined(value2) || (!value2 && value2 !== 0))) {
    setValidatorsErrorsMark(quantityValueCtrl, [Validators.required, Validators.pattern(POSITIVE_INTEGER_REGEX)], MIN_POSITIVE_INTEGER_0_VALUE_REQUIRED);
    setValidatorsErrorsMark(quantityValue2Ctrl, [Validators.required, Validators.min(1), Validators.pattern(POSITIVE_INTEGER_REGEX)], MIN_POSITIVE_INTEGER_1_VALUE_REQUIRED);
    return {[MIN_POSITIVE_INTEGER_VALUES_REQUIRED]: true};
  } else if ((isNullOrUndefined(value) || (!value && value !== 0)) && value2 && isValue2PositiveInteger) {
    setValidatorsErrorsMark(quantityValueCtrl, [Validators.required, Validators.pattern(POSITIVE_INTEGER_REGEX)], MIN_POSITIVE_INTEGER_0_VALUE_REQUIRED);
    return {[MIN_POSITIVE_INTEGER_0_VALUE_REQUIRED]: true};
  } else if (value && isValuePositiveInteger && (isNullOrUndefined(value2) || (!value2 && value2 !== 0))) {
    setValidatorsErrorsMark(quantityValue2Ctrl, [Validators.required, Validators.min(1), Validators.pattern(POSITIVE_INTEGER_REGEX)], MIN_POSITIVE_INTEGER_1_VALUE_REQUIRED);
    return {[MIN_POSITIVE_INTEGER_1_VALUE_REQUIRED]: true};
  } else {
    quantityValueCtrl.setErrors(null);
    quantityValue2Ctrl.setErrors(null);
    checkIntegerValuesBtOperatorValidation(quantityValueCtrl, quantityValue2Ctrl, value, value2, POSITIVE_INTEGER_REGEX, POSTIVIE_INTEGER_VALUE_REQUIRED);
    checkMinValueValidation(quantityValueCtrl, value, 0, MIN_POSITIVE_INTEGER_0_VALUE_REQUIRED);
    checkMinValueValidation(quantityValue2Ctrl, value2, 1, MIN_POSITIVE_INTEGER_1_VALUE_REQUIRED);
    checkValue2GreaterValue1Validation(quantityValueCtrl, quantityValue2Ctrl, value, value2);
  }
}

function checkMinValueValidation(valueCtrl: AbstractControl, value: string | number, minValue: number, errorkKey: string): ValidationErrors | null {
  if (parseInt(value as string) < minValue) {
    setValidatorsErrorsMark(valueCtrl, [Validators.min(minValue)], errorkKey);
    return {[errorkKey]: true};
  }
}