<form [formGroup]="planForm" *ngIf="planForm">
  <app-loader *ngIf="loading"/>
  <!-- Name -->
  <div class="col-md-8 m-auto">
    <div class="row mrg-top-10 mrg-btm-15">
      <div class="col-md-12">
        <app-question [question]="inputs[0]" [form]="planForm"></app-question>
      </div>
    </div>
  </div>

  <div class="col-md-8 m-auto">
    <div class="row mrg-top-10 mrg-btm-15">
      <div class="col-md-6">
        <app-question [question]="inputs[1]"
                      (click)="markAsDirty('available_from')"
                      [form]="planForm">
        </app-question>
      </div>
      <div class="col-md-6">
        <app-question [question]="inputs[2]"
                      (click)="markAsDirty('available_to')"
                      [form]="planForm">
        </app-question>
      </div>
    </div>
  </div>

  <!-- Printable checkbox -->
  <div class="col-md-8 m-auto">
    <div class="row mrg-top-10">
      <div class="col-md-12">
        <app-question [question]="inputs[5]" [form]="planForm"></app-question>
      </div>
    </div>
  </div>

  <div class="col-md-8 m-auto">
    <div class="row mrg-top-10">
      <div class="col-md-6">
        <app-question [question]="inputs[3]" (click)="markAsDirty('print_from')" [form]="planForm"></app-question>
      </div>
      <div class="col-md-6">
        <app-question [question]="inputs[4]" (click)="markAsDirty('print_to')" [form]="planForm"></app-question>
      </div>
    </div>
  </div>

  <!-- CSV checkbox -->
  <div class="col-md-8 m-auto" *ngIf="!plan?.id && flags.showPlansCampaignsRecommended">
    <div class="row mrg-top-10">
      <div class="col-md-12">
        <app-question [question]="inputs[6]" [form]="planForm"></app-question>
      </div>
    </div>
  </div>

  <!-- CSV file -->
  <div class="col-md-8 m-auto mrg-top-10" *ngIf="!plan?.id && planForm.value.created_by_csv">
    <app-question [question]="inputs[7]" [form]="planForm"></app-question>
    {{ 'resources.coupons.modal.import.download_template' | translate }}
    <a (click)="downloadTemplate()" target="_blank">
      <span class="text-link text-info pointer">{{ 'resources.coupons.modal.import.here_link' | translate }}</span>
    </a>
    <div class="mrg-top-30" *ngIf="actionsCSVexceeded">
      <span class="alert alert-danger fade show">
        <i class="fa-light fa-info-circle mrg-right-10"></i>
        <strong>
          {{'resources.plans.warnings.csv_actions_exceeded' | translate}}
        </strong>
      </span>
    </div>
  </div>

  <div class="row">
    <div class="col-md-8 m-auto">
      <button class="btn btn-info pull-right mrg-right-10 mrg-btm-20 mrg-top-30"
              type="button"
              [disabled]="planForm.invalid"
              (click)="save()">
        {{'components.create-update.fields.save' | translate}}
      </button>
    </div>
  </div>
</form>
