import { Component, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-email-template-view',
  templateUrl: './email-template-view.component.html',
  styleUrls: ['./email-template-view.component.css']
})

export class EmailTemplateViewComponent implements OnChanges, OnDestroy {

  @Input('template') template: { html: string };
  @Input() mode: string;
  @ViewChild('modalToggleBtn') modalToggleBtn: ElementRef;

  srcdoc: any;
  hasHtml: boolean;

  constructor( private sanitizer: DomSanitizer ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.template.currentValue === undefined) {
      this.template = undefined;
      this.srcdoc = undefined;
      this.hasHtml = false;
    }
  }

  ngOnDestroy() {
    this.template = undefined;
    this.hasHtml = false;
    this.srcdoc = null;
  }

  open() {
    let schema = '';
    this.modalToggleBtn.nativeElement.click();
    if (this.template) {
      if ( this.template['html'] && this.template['html'].length > 0 ) {
        this.hasHtml = true;
        schema = this.template['html'];
      } else if ( this.template['message'] && this.template['message'].length > 0 ) {
        this.hasHtml = true;
        schema = this.template['message'];
      }
      this.srcdoc = this.sanitizer.bypassSecurityTrustHtml(schema);
    } else {
      this.hasHtml = false;
      this.srcdoc = undefined;
    }
  }

  openAndViewTemplate(template: any) {
    this.template = template ? template : undefined;
    this.open();
  }

  close() {
    this.modalToggleBtn.nativeElement.click();
  }
}
