import { Component, Input } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { MultiselectDataSource } from '../../../multiselect/multiselect';
import { QuestionControlService } from '../../../../services/question-control.service';
import { Operator } from '../../../../models/segments/conditions/operator';

@Component({
  selector: 'app-voucher-count',
  templateUrl: './voucher-count.component.html',
  styleUrls: ['./voucher-count.component.css']
})
export class VoucherCountComponent {

  couponsSelected: MultiselectDataSource[] = [];

  @Input() item: Condition;
  @Input() inputs: QuestionBase<unknown>[] = [];
  @Input() form: UntypedFormGroup;

  constructor(private qcs: QuestionControlService) {}

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: unknown): boolean {
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  handleOperatorChanged(operator: Operator): void {
    if (operator?.id === 'bt') {
      this.getInputConfig('value').value = null;
      this.form.patchValue({ value: null }, { emitEvent: true });
    } else {
      this.getInputConfig('value_1').value = null;
      this.getInputConfig('value_2').value = null;
      this.form.patchValue({ value_1: null, value_2: null }, { emitEvent: true });
    }
  }
}
