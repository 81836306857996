import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CUSTOMER_ATTRIBUTES_LIST_ROUTERLINK } from '../../constants/customer-attributes.constants';
import { handleFieldValueChanges } from '../../utils/form-subscriptions.utils';
import { getCustomerAttributesFormPayload } from '../../utils/form-payload.utils';
import { CustomerAttributesInputsService } from '../../services/customer-attributes-inputs/customer-attributes-inputs.service';
import { CustomerAttributesService } from '../../services/customer-attributes/customer-attributes.service';
import { handleCustomValidators } from '../../utils/form-validators.utils';
import { CustomerAttribute, CustomerAttributeAPIResponse } from '../../models/customer-attribute.model';
import { OriginBadge } from '../../models/origin-badge.model';
import { ProfileService } from '../../../../../profiles/profile.service';
import { DictionaryService } from '../../../../../shared/services/dictionary.service';
import { CustomerAttributesFormTitle, RequestMessageSuccess } from '../../enums/customer-attributes.enums';
import { CurrentCompany } from '../../../../../shared/models/current-user';

@Component({
  selector: 'app-form-customer-attributes',
  templateUrl: './form-customer-attributes.component.html',
  styleUrls: ['./form-customer-attributes.component.scss']
})
export class FormCustomerAttributesComponent implements OnInit, OnDestroy {

  currentNavigation: Navigation = this.router.getCurrentNavigation();
  currentRole = this.profileService.getStoredUserRole();
  customerAttributesForm: UntypedFormGroup;
  id: number;
  inputs: QuestionBase<unknown>[];
  loading: boolean = false;
  originBadge: OriginBadge;

  get isAdminRole(): boolean {
    return this.currentRole === 'admin';
  }

  get isEditMode(): boolean {
    return !!this.id;
  }

  get modeTitle(): string {
    return this.translate.instant(this.isEditMode ? CustomerAttributesFormTitle.Edit : CustomerAttributesFormTitle.New);
  }

  private get isCompanyStrictProfiling(): boolean {
    return !!this.company?.strictProfiling;
  }

  private company: CurrentCompany = new CurrentCompany(this.profileService.getProfileCompany());
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private customerAttributesService: CustomerAttributesService,
    private customerAttributesInputsService: CustomerAttributesInputsService,
    private qcs: QuestionControlService,
    private confirmationService: ConfirmationService,
    private location: Location,
    private translate: TranslateService,
    private dictionaryService: DictionaryService
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: unknown): boolean {
    return this.qcs.hasFormKeyWithValue(this.customerAttributesForm, formKey, value);
  }

  back(isBackToList?: boolean): void {
    if (this.customerAttributesForm.dirty && this.customerAttributesForm.touched) {
      const confirmationBackTitle = this.translate.instant('resources.customer_attributes.messages.back_confirmation');
      const confirmationBackButton  = this.translate.instant('resources.customer_attributes.buttons.back_confirmation');
      const denialButton = this.translate.instant('resources.customer_attributes.buttons.denial');
      this.confirmationService.displayConfirmationAlertWithButtons(confirmationBackTitle, '', confirmationBackButton, denialButton, 'question').then(data => {
        if (data.hasOwnProperty('value') && data.value) {
          this.requestBack(isBackToList);
        }
      }).catch(() => {});
    } else {
      this.requestBack(isBackToList);
    }
  }

  save(): void {
    const payload = getCustomerAttributesFormPayload(this.customerAttributesForm);
    this.loading = true;
    const request$ = this.isEditMode ? this.customerAttributesService.updateCustomerAttribute(payload, this.id) : this.customerAttributesService.createCustomerAttribute(payload);
    request$.pipe(takeUntil(this.destroy$), finalize(() => this.loading = false)).subscribe({
      next: () => this.handleSuccessRequest(),
      error: (errorData: HttpErrorResponse) => this.handleErrors(errorData)
    });
  }

  private setupFormSubscriptions(): void {
    this.customerAttributesForm.get('attribute_type').valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(
      attributeTypeValue => handleFieldValueChanges('attribute_type', attributeTypeValue, this.inputs, this.customerAttributesForm)
    );

    this.customerAttributesForm.get('execution_type').valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(
      executionTypeValue => handleFieldValueChanges('execution_type', executionTypeValue, this.inputs, this.customerAttributesForm)
    );

    this.customerAttributesForm.get('data_period_type').valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(
      dataPeriodTypeValue => handleFieldValueChanges('data_period_type', dataPeriodTypeValue, this.inputs, this.customerAttributesForm)
    );

    this.customerAttributesForm.get('recurrence').valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(
      recurrenceValue => handleFieldValueChanges('recurrence', recurrenceValue, this.inputs, this.customerAttributesForm)
    );
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getCustomerAttributesById();
      } else {
        this.setInputsAndForm({});
      }
    });
  }

  private getCustomerAttributesById(): void {
    this.customerAttributesService.getCustomerAttributesById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (data: CustomerAttributeAPIResponse) => this.setInputsAndForm(data),
      error: (errorData: HttpErrorResponse) => this.displayErrorAlert(errorData.error.error)
    });
  }

  private setInputsAndForm(requestData: CustomerAttributeAPIResponse): void {
    const customerAttributes = new CustomerAttribute(requestData);
    this.inputs = this.customerAttributesInputsService.getInputs(customerAttributes, this.isEditMode, this.isCompanyStrictProfiling);
    this.customerAttributesForm = this.qcs.toFormGroup(this.inputs);
    this.originBadge = new OriginBadge(customerAttributes.system, this.translate, this.dictionaryService);
    this.setFormDisabledValues(); // set to be able to control primeng radiobutton disabled style
    handleCustomValidators(this.inputs, this.customerAttributesForm, true);
    this.setupFormSubscriptions();
  }

  private setFormDisabledValues(): void {
    this.inputs.forEach(input => {
      const control = this.customerAttributesForm.get(input.key);
      if (input.disabled) { control.disable(); }
    });
  }

  private handleSuccessRequest(): void {
    const successRequest = this.translate.instant(this.isEditMode ? RequestMessageSuccess.Edit : RequestMessageSuccess.New);
    this.confirmationService.displaySuccessAlert(successRequest, '').catch(() => {});
    this.requestBack(true);
  }

  private handleErrors(errorData: HttpErrorResponse): void {
    const errorValue = errorData?.error?.error ? errorData.error.error : null;
    if (errorData?.error?.errors?.length) {
      this.qcs.paintErrorsInForm(this.inputs, this.customerAttributesForm, errorData.error.errors);
      if (errorValue) { this.displayErrorAlert(errorValue); }
    } else if (errorValue) {
      this.displayErrorAlert(errorValue);
    }
  }

  private displayErrorAlert(error: string): void {
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), error);
  }

  private requestBack(isBackToList: boolean = false): void {
    if (isBackToList || (!isBackToList && !this.currentNavigation)) {
      this.router.navigate(CUSTOMER_ATTRIBUTES_LIST_ROUTERLINK).catch(() => {});
    } else {
      this.location.back();
    }
  }
}
