import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { LocationsTaxonomyTermsService } from '../location-taxonomy-terms.service';
import { DataTableConfiguration } from '../../../../shared/components/data-table/data-table-cfg';
import { DataTableComponent } from '../../../../shared/components/data-table/data-table.component';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { Subject, takeUntil } from 'rxjs';
import { Option } from '../../../../shared/models/common/option';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { getDataTableTextPropValue } from '../../../../shared/utils/common.utils';

@Component({
  selector: 'app-show-location-taxonomy-terms',
  templateUrl: './show-location-taxonomy-terms.component.html',
  styleUrls: ['./show-location-taxonomy-terms.component.css']
})
export class ShowLocationTaxonomyTermsComponent implements OnInit {

  locationTaxonomyTerm: object;
  id: number;
  locationsTableConfig: DataTableConfiguration;
  filters: QuestionBase<unknown>[];

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private locationsTaxonomyTermsService: LocationsTaxonomyTermsService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    registerLocaleData(es);
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFilterHandler(filterFormValue: object): void {
    filterFormValue['location_taxonomy_term_ids'] = this.id;
    this.dataTable.onFilter(filterFormValue);
  }

  getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.getLocationTaxonomyTerm();
        this.setFilters();
      }
    });
  }

  private getLocationTaxonomyTerm(): void {
    this.locationsTaxonomyTermsService.getLocationTaxonomyTermsById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: HttpResponse<object>) => {
        this.locationTaxonomyTerm = response;
        this.setTableCfg();
      },
      error: (errorData: HttpErrorResponse) => this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error)
    });
  }

  private setTableCfg(): void {
    this.locationsTableConfig = {
      isActive: true,
      tableActions: [
        { name: this.translate.instant( 'components.table_actions.export_csv' ),
          icon: 'ei-export',
          id: 'exportCSV'
        }
      ],
      rowActions: [
        {
          name: this.translate.instant('resources.locations.row_actions.details'),
          icon: 'eye',
          id: 'details'
        },
        {
          name: this.translate.instant('resources.locations.row_actions.edit'),
          icon: 'pencil',
          id: 'edit',
          onClick: (element: {id: number}) => this.router.navigate([
            { outlets: { modal: `update/locations/${element.id}` } }
          ])
        }
      ],
      columns: [
        { name: this.translate.instant('resources.locations.columns.id'), prop: 'id', sortByField: 'id' },
        { name: this.translate.instant('resources.locations.columns.code'), prop: 'code', sortByField: 'code' },
        { name: this.translate.instant('resources.locations.columns.name'), prop: 'name', sortByField: 'name' },
        { name: this.translate.instant('resources.locations.columns.postal_code'), prop: 'postal_code', sortByField: 'postal_code' },
        { name: this.translate.instant('resources.locations.columns.status'), prop: 'status', sortByField: 'status' },
        { name: this.translate.instant('resources.locations.columns.synchro_created_at'), prop: 'synchro_created_at', sortByField: 'synchro.created_at' }
      ],
      requestData: {
        apiEndPoint: 'locations',
        pageNumber: 0,
        filtering: {
          location_taxonomy_term_ids: this.id
        },
        numberPerPage: 10
      },
      rows: [],
      tableMapping: [
        { prop: 'id', type: 'text', apiProp: 'id', getValue: getDataTableTextPropValue('id') },
        { prop: 'code', type: 'text', apiProp: 'code', getValue: getDataTableTextPropValue('code') },
        { prop: 'name', type: 'text', apiProp: 'name', getValue: getDataTableTextPropValue('name') },
        { prop: 'postal_code', type: 'text', apiProp: 'postal_code', getValue: getDataTableTextPropValue('postal_code') },
        { prop: 'status', type: 'text', apiProp: 'status', getValue: getDataTableTextPropValue('status'), badge: {dictionaryKey: 'location_status' }},
        { prop: 'synchro_created_at', type: 'date', apiProp: '_embedded.synchro.created_at', link: { resourceId: '_embedded.synchro.id', resourcePath: 'synchros' }}
      ]
    };
  }

  private setFilters(): void {
    this.filters = [
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'id',
        label: 'resources.locations.filters.id',
        order: 1,
        type: 'number'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'code',
        label: 'resources.locations.filters.code',
        order: 2,
        type: 'text'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'name',
        label: 'resources.locations.filters.name',
        order: 3,
        type: 'text'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'postal_code',
        label: 'resources.locations.filters.postal_code',
        order: 4,
        type: 'text'
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'status',
        label: 'resources.locations.filters.status',
        options: this.getStatusOptions(),
        settings: { singleSelection: true, enableSearchFilter: false },
        order: 6
      })
    ];
  }

  private getStatusOptions(): Option[] {
    return [
      {id: null, name: this.translate.instant('common.all')},
      {id: 'active', name: this.translate.instant('resources.locations.filters.active')},
      {id: 'inactive', name: this.translate.instant('resources.locations.filters.inactive')}
    ];
  }
}
