export class TypeRedemptionsDataColumn {
  public month: number;
  public values: {
    loyal: number[],
    shared: number[],
    casual: number[]
  };

  constructor(month, cfgAri: any[]) {
    this.month = month;
    this.values = {
      loyal: [0, 0, 0],
      shared: [0, 0, 0],
      casual: [0, 0, 0]
    };
    if (cfgAri.length > 0) {this.values = this.process(cfgAri); }
  }

  private process(cfgAri: Object[]) {
    return {
      loyal: this.parseArray(cfgAri.filter(element => element['customer_type'] === 'loyal')),
      shared: this.parseArray(cfgAri.filter(element => element['customer_type'] === 'shared')),
      casual: this.parseArray(cfgAri.filter(element => element['customer_type'] === 'casual'))
    };
  }

  private parseArray(ariByType: Object[]): number[] {
    if (ariByType.length === 0) {return [0, 0, 0]; }

    let redeemedValue = 0,
      nonRedeemedValue = 0,
      global = 0,
      globalActivitesSum = 0,
      globalCustomersCount = 0;

    const redeemedAri = ariByType.filter(element => element['redeemer_type'] === 'redeemer'),
      nonRedeemerAri = ariByType.filter(element => element['redeemer_type'] === 'non_redeemer');

    if (redeemedAri.length > 0) {
      globalActivitesSum = globalActivitesSum + redeemedAri[0]['total'];
      globalCustomersCount = globalCustomersCount + redeemedAri[0]['customers_count'];
      redeemedValue = redeemedAri[0]['total'] / redeemedAri[0]['customers_count'];
    }

    if (nonRedeemerAri.length > 0) {
      globalActivitesSum = globalActivitesSum + nonRedeemerAri[0]['total'];
      globalCustomersCount = globalCustomersCount + nonRedeemerAri[0]['customers_count'];
      nonRedeemedValue = nonRedeemerAri[0]['total'] / nonRedeemerAri[0]['customers_count'];
    }

    global = globalCustomersCount !== 0 ? globalActivitesSum / globalCustomersCount : 0;

    return [global, redeemedValue, nonRedeemedValue];
  }
}

export const TypeRedemptionsDataMock = [
  {m: 1, customer_type: 'casual', redeemer_type: 'non_redeemer', customers_count: 35, total:  234.2 },
  {m: 1, customer_type: 'casual', redeemer_type: 'redeemer', customers_count: 25, total:  234.2 },
  {m: 1, customer_type: 'loyal', redeemer_type: 'non_redeemer', customers_count: 15, total:  234.2 },
  {m: 1, customer_type: 'loyal', redeemer_type: 'redeemer', customers_count: 5, total:  234.2 },
  {m: 1, customer_type: 'shared', redeemer_type: 'non_redeemer', customers_count: 65, total:  234.2 },
  {m: 1, customer_type: 'shared', redeemer_type: 'redeemer', customers_count: 45, total:  234.2 },
  {m: 2, customer_type: 'casual', redeemer_type: 'redeemer', customers_count: 15, total:  234.2 },
  {m: 2, customer_type: 'casual', redeemer_type: 'non_redeemer', customers_count: 55, total:  234.2 },
  {m: 2, customer_type: 'loyal', redeemer_type: 'non_redeemer', customers_count: 15, total:  234.2 },
  {m: 3, customer_type: 'loyal', redeemer_type: 'redeemer', customers_count: 3, total:  150 },
  {m: 4, customer_type: 'loyal', redeemer_type: 'redeemer', customers_count: 3, total:  150 }
];
