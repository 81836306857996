<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="ticketData">

      <div class="modal-header background-white">
        <h1>{{'resources.tickets.modal.title' | translate}}: {{ticketId}}</h1>
        <button class="modal-close" (click)="closeModal()">
          <em class="ti-close"></em>
        </button>
      </div>

      <div class="modal-body height-100 back-color">
        <div class="back-color">
          <div class="row">
            <div class="col-md-12 mrg-btm-15">
              <div class="btn-group">
                <button *ngIf="ticketData?.activity_return_froms?.length"
                  class="btn btn-default btn-icon d-flex align-items-center"
                  (click)="scroll('returns')">
                  <em class="fa-duotone fa-right-left font-size-16"></em>
                  <span class="mrg-left-10">{{ 'resources.tickets.modal.cards.returns.title' | translate}}</span>
                </button>
                <button *ngIf="showPointsBlock()"
                  class="btn btn-default btn-icon d-flex align-items-center"
                  (click)="scroll('points')">
                  <em class="fa-duotone fa-hundred-points font-size-16"></em>
                  <span class="mrg-left-10">{{ 'resources.tickets.modal.cards.points.title' | translate}}</span>
                </button>
                <button *ngIf="vouchersData?.length"
                  class="btn btn-default btn-icon d-flex align-items-center"
                  (click)="scroll('vouchers')">
                  <em class="fa-duotone fa-ticket font-size-16"></em>
                  <span class="mrg-left-10">{{ 'resources.tickets.modal.cards.vouchers_redeemed.title' | translate}}</span>
                </button>
                <button *ngIf="promoReady?.length"
                  class="btn btn-default btn-icon d-flex align-items-center"
                  (click)="scroll('promotions')">
                  <em class="fa-duotone fa-badge-percent font-size-16"></em>
                  <span class="mrg-left-10">{{ 'resources.tickets.modal.cards.promotions.title' | translate}}</span>
                </button>
              </div>
            </div>
          </div>

          <!-- Always visible: Details / Lines and Returns (if available) -->
          <div class="row">
            <div class="col-md-3" *ngIf="ticketData && vouchersData">
              <app-details-block
                [ticketData]="ticketData"
                [currencySymbol]="currencySymbol"
                [vouchersData]="vouchersData"
                [totalDiscount]="totalDiscount">
              </app-details-block>
            </div>
            <div class="col-md-9" id="lines">
              <app-lines-block [roleSlug]="roleSlug" [ticketData]="ticketData" [currencySymbol]="currencySymbol"></app-lines-block>
            </div>
          </div>
          <!-- End Details / Lines / (Returns) -->

          <!-- Ticket in JSON Format block -->
          <div class="row" *ngIf="ticketData.raw" id="json">
            <div class="col-md-12">
              <div class="collapse multi-collapse" id="multiCollapseExample1">
                <div class="card">
                  <div class="card-heading border bottom d-flex justify-content-between align-items-center">
                    <h4 class="card-title"
                      [innerHTML]="'resources.tickets.modal.cards.json_format.title' | translate: {id: ticketData.id}">
                    </h4>
                    <div class="btn-group">
                      <button class="btn btn-default btn-sm btn-icon"
                        type="button"
                        (click)="copyToClipboard()">
                        <em class="fa fa-copy"></em>
                        {{'resources.tickets.buttons.copy_json' | translate}}
                      </button>
                      <button class="btn btn-default btn-sm btn-icon"
                        type="button"
                        data-toggle="collapse"
                        data-target=".multi-collapse"
                        aria-expanded="false"
                        aria-controls="multiCollapseExample1">
                        <em class="fa fa-close"></em>
                        {{'resources.tickets.buttons.close' | translate}}
                      </button>
                    </div>
                  </div>
                  <div class="card-body">
                    <ace #editor
                      class="height-400"
                      [(value)]="ticketData.raw"
                      [mode]="'json'"
                      [disabled]="true"
                      theme="monokai">
                    </ace>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END Ticket in JSON Format block -->

          <!-- Returns -->
          <div class="row">
            <div class="col-md-12">
              <app-returns-block
                id="returns"
                *ngIf="ticketData?.activity_return_froms?.length"
                [ticketData]="ticketData"
                [currencySymbol]="currencySymbol"></app-returns-block>
            </div>
          </div>
          <!-- End Returns -->

          <!-- Points block -->
          <div class="row" *ngIf="showPointsBlock()">
            <div class="col-md-12">
              <app-points-block
                id="points"
                [ticketData]="ticketData"
                [currencySymbol]="currencySymbol"
                [pointsSummary]="pointsSummary"></app-points-block>
            </div>
          </div>
          <!-- END Points block -->

          <!-- Coupons block -->
          <div class="row" *ngIf="vouchersData?.length">
            <div class="col-md-12">
              <app-coupons-block
                id="vouchers"
                [ticketData]="ticketData"
                [currencySymbol]="currencySymbol"
                [vouchersData]="vouchersData"
                [totalDiscount]="totalDiscount"></app-coupons-block>
            </div>
          </div>
          <!-- END Coupons block -->

          <!-- Promotions block -->
          <div class="row" *ngIf="promoReady?.length">
            <div class="col-md-12">
              <app-promotions-block
                id="promotions"
                [ticketData]="ticketData"
                [promoReady]="promoReady"
                [currencySymbol]="currencySymbol"></app-promotions-block>
            </div>
          </div>
          <!-- END Promotions block -->
        </div>
      </div>
    </div>
  </div>
</div>
