import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { DataTableComponent } from '../../../../shared/components/data-table/data-table.component';
import { DataTableConfiguration } from '../../../../shared/components/data-table/data-table-cfg';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-show-segment-categories',
  templateUrl: './show-segment-categories.component.html',
  styleUrls: ['./show-segment-categories.component.css']
})
export class ShowSegmentCategoriesComponent implements OnInit, OnDestroy {

  segmentCategory: object;
  apiEndPoint: string;
  category_id = this.route.snapshot.params.id;
  id: number;
  isModal: boolean = this.router.url.indexOf('(modal:show/segment_categories/') >= 0;
  dataTableConfig: DataTableConfiguration;
  
  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private translate: TranslateService,
    private router: Router,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    registerLocaleData(es);
    this.setDataTableConfig();
    this.getParams();
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'segment_categories';
        this.getData();
      }
    });
  }
  
  private setDataTableConfig(): void {
    this.dataTableConfig = {
      isMock: false,
      isActive: true,
      tableActions: [
        {
          name: this.translate.instant('components.table_actions.export_csv'),
          icon: 'ei-export',
          id: 'exportCSV'
        }
      ],
      rowActions: [
        {
          name: this.translate.instant('resources.campaigns.row_actions.details'),
          icon: 'eye',
          id: 'details',
          customRoute: (element: { id: number }) => {
            return ['', {outlets: {modal: ['show', 'segments', element.id]}}];
          }
        }
      ],
      columns: [
        { name: this.translate.instant('resources.segment_categories.show.table.columns.name'), prop: 'name', sortByField: 'name' },
        { name: this.translate.instant('resources.segment_categories.show.table.columns.description'), prop: 'description', sortByField: 'description' },
        { name: this.translate.instant('resources.segment_categories.show.table.columns.created_at'), prop: 'created_at', sortByField: 'created_at' },
        { name: this.translate.instant('resources.segment_categories.show.table.columns.applies'), prop: 'applies', sortByField: 'applies' }
      ],
      requestData: {
        apiEndPoint: `segments?segment_category_id=${this.category_id}`,
        translationResource: 'resources.segment_categories.show.table.columns.',
        pageNumber: 0,
        sorting: {
          byProp: 'created_at',
          direction: 'desc',
        },
        numberPerPage: 10
      },
      tableMapping: [
        { prop: 'id', type: 'number', apiProp: 'id' },
        { prop: 'name', type: 'text', apiProp: 'name' },
        { prop: 'description', type: 'text', apiProp: 'description' },
        { prop: 'created_at', type: 'date', apiProp: 'created_at'},
        { prop: 'applies', type: 'number', apiProp: 'applies' }
      ]
    };
  }
  
  private getData(): void {
    this.resourcesService.getData({ apiEndPoint: this.apiEndPoint + '/' + this.id }).pipe(takeUntil(this.destroy$)).subscribe(
      dataResponse => this.segmentCategory = dataResponse,
      error => this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), error)
    );
  }
}
