import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from '../../../shared/services/resources.service';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../../shared/components/multiselect/multiselect';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FloatQuestion } from '../../../shared/models/forms/question-float';
import { map } from 'rxjs/operators';
import { Option } from '../../../shared/models/common/option';
import { FeatureTaxonomy } from '../../../shared/models/products/feature-taxonomy';
import { Validators } from '@angular/forms';
import { POSITIVE_INTEGER_REGEX } from '../../../shared/constants/validators.constants';
import { ProfileService } from '../../../profiles/profile.service';
import { DEFAULT_PRINCIPAL_CATEGORY } from '../../../shared/constants/looker.constants';

@Injectable()
export class FeatureTaxonomiesService extends ResourcesService implements MultiselectDataSourceable {

  inputs: QuestionBase<any>[];
  selectedOptsSource = new BehaviorSubject<{selectedIds: string[], key: string}>(null);
  selectedOptsSource$ = this.selectedOptsSource.asObservable();

  constructor(
    http: HttpClient,
    private translate: TranslateService,
    private profileService: ProfileService
  ) {
    super(http);
  }

  getFeatureById(id: number | string) {
    return this.getData({ apiEndPoint: `feature_taxonomies/${id}` });
  }

  getAll() {
    const requestOptions = {
      apiEndPoint: 'feature_taxonomies',
      sorting: {},
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  fetchMultiselect( searchValues?: string, page?: number, filters?: object) {
    let requestOptions = {
      apiEndPoint: 'feature_taxonomies',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: { },
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    if (filters) {
      const filtering = Object.assign({}, requestOptions.filtering, filters);
      const filterObj = { filtering: filtering};
      requestOptions = {...requestOptions, ...filterObj};
    }

    if(filters && filters.hasOwnProperty('is_main') && !filters['is_main']) {
      return this.getData(requestOptions).pipe(
        map(itemArray => itemArray['list'].filter((el: object) => !el['main']))
      );
    }

    return this.getData(requestOptions);
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    if ( !element ) { return new MultiselectDataSource( '-', '-' ); }
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    return new MultiselectDataSource(element.slug, element.name, element);
  }

  fetchSelectedById(slug: string): Observable<object> {
    return this.getData({apiEndPoint: `feature_taxonomies?slug=${slug}`}).pipe(
            map( itemArray => itemArray['list'] ),
            map( element => {
              return element.filter( x => x.slug === `${slug}` )[0];
            }));
  }

  getTaxonomySlug(taxonomies: object): string | null {
    return taxonomies['list'] && taxonomies['list'][0] ? taxonomies['list'][0].slug : null;
  }

  getOriginOptions(): Option[] {
    return [
      { id: 'internal', name: this.translate.instant('resources.products.form.internal')},
      { id: 'external', name: this.translate.instant('resources.products.form.external')}
    ];
  }

  getTypes(): Option[] {
    const principalCategorization = this.profileService.getStoredUser().company.plans_configuration?.principal_categorization || DEFAULT_PRINCIPAL_CATEGORY;
    const productTaxonomiesTypes = [
      { id: 'brand', name: this.translate.instant('resources.product_taxonomies.types.brand')},
      { id: 'own_brand', name: this.translate.instant('resources.product_taxonomies.types.own_brand')},
      { id: principalCategorization, name: this.translate.instant('resources.product_taxonomies.types.nielsen')},
      { id: 'supplier', name: this.translate.instant('resources.product_taxonomies.types.supplier')}
    ];
    return productTaxonomiesTypes.sort((a, b) => a.name.localeCompare(b.name));
  }

  getInputs(formValues: FeatureTaxonomy, isEditMode: boolean): QuestionBase<unknown>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: formValues?.name ?? null
      }),
      new TextboxQuestion({
        key: 'slug',
        isDisabledOn: 'update',
        type: 'text',
        cssClasses: 'form-control input-default',
        disabled: isEditMode,
        required: true,
        value: formValues?.slug ?? null
      }),
      new MultiSelectQuestion({
        key: 'origin',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false},
        options: this.getOriginOptions(),
        required: true,
        value: formValues?.origin ? this.getFieldOptionsValue(this.getOriginOptions(), formValues.origin) : null,
      }),
      new MultiSelectQuestion({
        key: 'kind',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false},
        options: this.getTypes(),
        value: formValues?.kind ? this.getFieldOptionsValue(this.getTypes(), formValues.kind) : null
      }),
      new FloatQuestion({
        key: 'level',
        type: 'number',
        cssClasses: 'form-control input-default',
        step: 1,
        min: 0,
        required: !!formValues?.kind,
        value: formValues?.level ?? null,
        customValidators: [Validators.pattern(POSITIVE_INTEGER_REGEX)]
      })
    ];

    this.inputs = inputsArray;
    return this.inputs;
  }

  create(data: object): Observable<object> {
    return this.postResource(data, 'feature_taxonomies');
  }

  updateById(data: object, id: number): Observable<object> {
    return this.patchResource(data, `feature_taxonomies/${id}`);
  }

  refreshSelectedOptsSource(selectedIds: string[], key: string) {
    this.selectedOptsSource.next({selectedIds, key});
  }

  private getFieldOptionsValue(options: Option[], fieldValue: string): Option[] {
    const fieldOption = options.find( item => item.id === fieldValue);
    return fieldOption ? [fieldOption] : null;
  }
}
