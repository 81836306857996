import { Injectable } from '@angular/core';

@Injectable()
export class ValidationsService { }

export function parseMonthData(arr: number[], monthNum?: number): number[] {
  monthNum = monthNum ? monthNum : (new Date()).getMonth();
  const pastYearData = arr.splice(monthNum, arr.length);
  return [...pastYearData, ...arr];
}

export function getPropValue(element, key) {
  if (element) {
    const parsedItem = element.map(item => item[key]);
    return parsedItem[0];
  }
}

export function onlyUnique(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}

export function genCharArray(charA: string, charZ: string, caps?: boolean) {
  let a = [],
    i = charA.charCodeAt(0),
    j = charZ.charCodeAt(0);
  for (i; i <= j; ++i) {
    let value = String.fromCharCode(i);
    value = caps ? value.toUpperCase() : value;
    a.push(value);
  }
  return a;
}

export function sortObjArrayByProperty(objArray: any[], prop: string) {
  return objArray.sort((a, b) => a[prop] - b[prop]);
}

export function areArraysEquals(a: any[], b: any[]): boolean {
  return Array.isArray(a) && Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index]);
}

export function sumObjArrayByProperty(objArray: any[], prop: string) {
  return objArray.reduce((acc, obj) => acc + (obj[prop] || 0), 0);
}
