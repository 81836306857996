<div class="sign-in row no-mrg-horizon">
  <!-- Main logo -->
  <div class="col-xl-8 no-pdd-horizon d-none d-xl-block">
    <div class="full-height bg" style="background-image: url('assets/images/others/lg_login.jpeg')"></div>
  </div>
  <!-- Form area -->
  <div class="col-xl-4 no-pdd-horizon">
    <div class="flex align-items-center full-height pdd-horizon-70 bg-white">
      <div class="flex-grow-1 pdd-horizon-15">

        <!-- Logo for small screen -->
        <img id="right-xl-logo" width="200" src="assets/images/logo/logo_blue_0472FF.png" alt="Loyal Guru">

        <!-- Main Text -->
        <ng-container [ngSwitch]="activeMode">

          <ng-container *ngSwitchCase="MODE.Login">
            <h5 class="mrg-vertical-15 text-bold">{{ 'components.signin.titles.sign_in' | translate }}</h5>
            <p class="mrg-vertical-15 font-size-13">{{ 'components.signin.messages.enter_credentials' | translate }}</p>
          </ng-container>

          <ng-container *ngSwitchCase="MODE.SSOSignIn">
            <h5 class="mrg-vertical-15 text-bold">{{ 'components.signin.titles.sign_in_with_sso' | translate }}</h5>
            <p class="mrg-vertical-15 font-size-13">{{ 'components.signin.messages.sign_in_with_sso_desc' | translate }}</p>
          </ng-container>

          <ng-container  *ngSwitchCase="MODE.SSOErrorSignIn">
            <div class="mrg-vertical-15">
              <em class="fa-solid fa-triangle-exclamation text-danger font-size-16 mrg-right-5"></em>
              <h5 class="d-inline text-bold no-mrg">{{ 'components.signin.titles.sign_in_with_sso_error' | translate }}</h5>
            </div>
            <p class="mrg-vertical-15 font-size-13">{{ 'components.signin.messages.sign_in_with_sso_error_desc' | translate }}</p>
          </ng-container>

          <ng-container *ngSwitchCase="MODE.LoadingHome">
            <div class="mrg-vertical-15">
              <em class="fa fa-spinner fa-spin font-size-15 align-text-top mrg-right-5"></em>
              <h5 class="d-inline text-bold no-mrg">{{ 'components.signin.titles.loading_home' | translate }}</h5>
            </div>
            <p class="mrg-vertical-15 font-size-13">{{ 'components.signin.messages.loading_home_desc' | translate }}</p>
          </ng-container>

          <ng-container *ngSwitchCase="MODE.RecoverPassword">
            <app-recover-password></app-recover-password>
          </ng-container>

          <ng-container *ngSwitchCase="MODE.ResetPassword">
            <app-reset-password></app-reset-password>
          </ng-container>

          <ng-container *ngSwitchCase="MODE.ResetExpiredPassword">
            <app-reset-password [isResetExpiredPassword]="true"></app-reset-password>
          </ng-container>

          <div class="mrg-vertical-15" *ngSwitchCase="MODE.Activation">
            <em *ngIf="loading" class="fa fa-spinner fa-spin font-size-15 align-text-top"></em>
            <h5 class="d-inline text-bold no-mrg" [class.mrg-left-5]="loading">{{ (loading ? 'components.signin.titles.activating_user' : 'components.signin.titles.activate_user') | translate }}</h5>
          </div>

          <div class="mrg-vertical-15" *ngSwitchCase="MODE.ActivationError">
            <em class="fa-solid fa-triangle-exclamation text-danger font-size-16 mrg-right-5"></em>
            <h5 class="d-inline text-bold no-mrg">{{ 'components.signin.titles.activating_user_error' | translate }}</h5>
          </div>
        </ng-container>

        <!-- Login form -->
        <form #loginForm="ngForm" *ngIf="activeMode === MODE.Login">
          <input type="email" name="email" class="form-group form-control" placeholder="{{'common.email' | translate}}" autocomplete="off" ngModel required>
          <input type="password" name="password" class="form-group form-control" placeholder="{{'common.password' | translate}}" autocomplete="off" ngModel required>
          <div>
            <span *ngIf="loading" class="pdd-top-10 pdd-horizon-10">
              <i class="fa fa-spinner fa-spin"></i>
            </span>
            <button class="btn btn-info no-mrg mrg-top-5" [disabled]="loading || loginForm.invalid" (click)="onSignin(loginForm)">
              {{'components.signin.buttons.login' | translate}}
            </button>
          </div>
          <div class="redirect-info">
            <a [class.disabled]="loading" [routerLink]="['/sso-signin']"><u>{{ 'components.signin.buttons.sign_in_with_sso' | translate }}</u></a>
          </div>
          <div class="redirect-info secondary-info">
            <a [class.disabled]="loading" [routerLink]="['/recover-password']"><u>{{ 'components.signin.buttons.forgot_password' | translate }}</u></a>
          </div>
        </form>

        <!-- Single Sign-On (SSO) form -->
        <ng-container *ngIf="activeMode === MODE.SSOSignIn || activeMode === MODE.SSOErrorSignIn">
          <form #ssoSignInForm="ngForm">
            <input type="email" name="email" class="form-group form-control" placeholder="{{'common.email' | translate}}" autocomplete="off" ngModel required>
            <div>
              <span *ngIf="loading" class="pdd-top-10 pdd-horizon-10">
                <i class="fa fa-spinner fa-spin"></i>
              </span>
              <button class="btn btn-info no-mrg mrg-top-5" [disabled]="loading || ssoSignInForm.invalid" (click)="onSignInWithSSO(ssoSignInForm)">
                {{ 'components.signin.buttons.sign_in_with_sso' | translate}}
              </button>
            </div>
            <div class="redirect-info">
              <span> {{ 'components.signin.messages.back_to_sign_in_from_sso' | translate }}</span>
              <a [routerLink]="[signinRoute]"><u>{{ 'components.signin.messages.sign_in' | translate }}</u></a>
            </div>
          </form>
        </ng-container>

        <!-- User activation -->
        <ng-container *ngIf="activeMode === MODE.Activation">
          <p class="mrg-vertical-15 font-size-13">{{ (loading ? 'components.signin.messages.activating_user_desc' : 'components.signin.messages.activate_user_desc') | translate }}</p>
        </ng-container>

        <ng-container *ngIf="activeMode === MODE.ActivationError">
          <p class="mrg-vertical-15 font-size-13">{{ 'components.signin.messages.activating_user_error_desc' | translate }}</p>
          <button class="btn btn-info no-mrg mrg-top-5" (click)="onValidateEmail()">
            {{ 'components.signin.buttons.validate_email' | translate }}
          </button>
          <div class="redirect-info">
            <a [routerLink]="[signinRoute]"><u>{{ 'components.signin.buttons.go_to_sign_in' | translate }}</u></a>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="login-footer">
      <span class="font-size-13 pull-left pdd-top-10">
        {{'components.signin.messages.copyright' | translate: { year: currentYear } }}
      </span>
    </div>

  </div>
</div>
