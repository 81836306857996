<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-warning alert-dismissible fade show" *ngIf="historyId">
        {{'resources.messages.form.warnings.history_warning_a' | translate}}
        <br>
        {{'resources.messages.form.warnings.history_warning_b' | translate}}
        <br>
        {{'resources.messages.form.warnings.history_warning_c' | translate}}
        <button type="button" class="close no-pdd-top pdd-top-10" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="card-block no-pdd">
        <div class="mrg-top-40">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form  [formGroup]="messagesForm" *ngIf="messagesForm">             
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name" [translate]="'resources.messages.form.name'"></label>
                      <app-question [question]="getInputConfig('name')" [form]="messagesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="description" [translate]="'resources.messages.form.description'"></label>
                      <app-question [question]="getInputConfig('description')" [form]="messagesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="priority" [translate]="'resources.messages.form.priority'"></label>
                      <app-question [question]="getInputConfig('priority')" [form]="messagesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="available_days" [translate]="'resources.messages.form.available_days'"></label>
                      <app-question [question]="getInputConfig('available_days')" [form]="messagesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="available_from" [translate]="'resources.messages.form.available_from'"></label>
                      <app-question [question]="getInputConfig('available_from')" [form]="messagesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="available_to" [translate]="'resources.messages.form.available_to'"></label>
                      <app-question [question]="getInputConfig('available_to')" [form]="messagesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="!historyId">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="message_category_id" [translate]="'resources.messages.form.message_category_id'"></label>
                      <app-question [question]="getInputConfig('message_category_id')" [form]="messagesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="location_ids" [translate]="'resources.messages.form.location_ids'"></label>
                      <app-question [question]="getInputConfig('location_ids')" [form]="messagesForm"></app-question>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
