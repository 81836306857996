export class RecommendedCoupon {
  public id: number;
  public name: string;
  public incrementalRatio: number;
  public redemptionPercentage: number;
  public successRatio: number;

  constructor(apiCfg: object) {
    this.id = apiCfg['id'] || 0;
    this.name = apiCfg['coupon_name'] || 0;
    this.incrementalRatio = apiCfg['incremental_ratio'] || 0;
    this.redemptionPercentage = apiCfg['redemption_percentage'] || 0;
    this.successRatio = apiCfg['success_ratio'] || 0;
  }
}
