import { CampaignCategoriesService } from '../../../campaigns/campaign-categories/campaign-categories.service';
import { Component, OnInit, EventEmitter, Output, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { getRandomNumber, isNullOrUndefined } from '../../../../shared/utils/common.utils';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { PlanCampaign } from '../plan-campaign';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { Subscription } from 'rxjs';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-custom-campaign-plan-definition',
  templateUrl: './custom-campaign-plan-definition.component.html',
  styleUrls: ['./custom-campaign-plan-definition.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomCampaignPlanDefinitionComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  inputs: QuestionBase<any>[];
  subs$: Subscription[] = [];
  segments: {
    data?: {
      action_id: number,
      affectation: 'ticket' | 'feature',
      feature_ids: string[],
      id: number,
      taxonomy_id: string[]
    }
    key: string
  }[] = [];

  @Input('planCampaign') planCampaign: PlanCampaign;
  @Output('formValidity') formValidity: EventEmitter<any>;

  constructor(
    private qcs: QuestionControlService,
    private campaignCategoriesSrv: CampaignCategoriesService,
    private translate: TranslateService
  ) {
    this.formValidity = new EventEmitter();
  }

  ngOnInit() {
    this.prepareSegments(this.planCampaign);
    this.setInputs(this.planCampaign);
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  getPayload(): Object {
    const payload = Object.assign({}, this.form.value);
    payload['type'] = 'CustomCampaign';
    payload['config'] = {
      segments: this.parseSegmentsPayload(),
      campaign_plan_category_id: this.parseCampaignCategoryPayload()
    };

    Object.keys(this.form.value).filter(key => key.indexOf('segment_') >= 0 || key.indexOf('campaign_plan_category_id') >= 0)
    .forEach(key => {
      delete payload[key];
    });
    return payload;
  }

  addSegment(segmentData?: {id: number, affectation: 'ticket' | 'feature', taxonomy_id?: string[], feature_ids?: string[], action_id?: number}) {
    const uniqueKey = `segment_${getRandomNumber()}`;
    if (segmentData) {
      this.segments.push({
        key: uniqueKey,
        data: {
          id: segmentData.id,
          affectation: segmentData.affectation,
          taxonomy_id: segmentData.taxonomy_id,
          feature_ids: segmentData.feature_ids,
          action_id: segmentData.action_id
        }
      });
    } else {
      this.segments.push({key: uniqueKey});
    }
  }

  handleDeletedSegment(key: string) {
    if (this.segments.length > 1) {
      const keyIndex = this.segments.findIndex(segment => segment.key === key);
      this.segments.splice(keyIndex, 1);
    }
  }

  private setInputs(planCampaign?: PlanCampaign) {
    let campaignCategories;
    if (planCampaign) {
      campaignCategories = this.getCampaignCategoryValue(planCampaign);
    }
    const inputs = [
      new TextboxQuestion({
        key: 'name',
        label: 'resources.campaign_plans.types.custom.fields.name',
        type: 'text',
        cssClasses: 'form-control input-default',
        required: true,
        value: planCampaign ? planCampaign.name : null
      }),
      new MultiSelectQuestion({
        key: 'campaign_plan_category_id',
        label: 'resources.plans.filters.campaign_category',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.campaignCategoriesSrv,
        selectedIds: (campaignCategories && campaignCategories.length > 0 && campaignCategories[0] !== 0) ? campaignCategories : null,
        value: [{id: -1, name: this.translate.instant('resources.campaign_categories.messages.no_category')}],
        required: true
      })
    ];

    this.inputs = inputs;
    this.form = this.qcs.toFormGroup(this.inputs);
    this.formStatusChanges();
  }

  private getCampaignCategoryValue(planCampaign: PlanCampaign) {
    const campaignPlanCategoryId = planCampaign.config['campaign_plan_category_id'];
    if (isNullOrUndefined(campaignPlanCategoryId)) {return []; }
    return [campaignPlanCategoryId];
  }

  private prepareSegments(planCampaign?: PlanCampaign) {
    if (isNullOrUndefined(planCampaign)) {
      this.addSegment();
      return;
    }
    this.planCampaign.config['segments'].forEach(segmentData => this.addSegment(segmentData));
  }

  private parseCampaignCategoryPayload() {
    const campaignValue = this.form.value['campaign_plan_category_id'];
    if (campaignValue && campaignValue[0] && campaignValue[0].id) {
      return campaignValue[0].id;
    } else {
      return null;
    }
  }

  private parseSegmentsPayload(): any[] {
    const segmentKeys = Object.keys(this.form.value).filter(key => key.indexOf('segment_') >= 0);
    return segmentKeys.map(key => {
      const segmentValue = this.form.value[key];
      if (!segmentValue.id) {
        return null;
      }
      const parsedSegmentObj = {
        id: segmentValue.id[0].id,
        affectation: segmentValue.affectation[0].id,
        action_id: segmentValue.action_id
      };
      if (parsedSegmentObj.affectation === 'feature') {
        parsedSegmentObj['feature_ids'] = segmentValue.feature_ids?.map(feature => feature.rawElement.pk);
        if (parsedSegmentObj['taxonomy_id']) {
          parsedSegmentObj['taxonomy_id'] = segmentValue.taxonomy_id?.map(taxonomy => taxonomy.id);
        }
      }
      return parsedSegmentObj;
    });
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.form.status));
    const formStatus$ = this.form.statusChanges.pipe(distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
    this.subs$.push(formStatus$);
  }
}
