<div class="alert alert-info" role="alert" *ngIf="!onSearch && !loading">
  <span>{{ 'components.looker-iframe.alerts.apply_filters' | translate }}</span>
</div>

<div class="row" *ngIf="onSearch">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title d-inline" [translate]="'dashboards.results.p&l.menu.evaluation'"></h4>
        <i class="tooltip-position ti-info-alt mrg-top-10 font-size-14 pull-right"
            tooltipPosition="left"
            pTooltip="{{ 'dashboards.results.p&l.menu.tooltip_evaluation' | translate }}"></i>
      </div>

      <app-loader styleClass="mrg-btm-15" *ngIf="loading" />

      <div class="card-block">
        <table class="table table-hover table-sm table-bordered" aria-hidden="true" *ngIf="!loading">
          <thead>
            <tr class="highlight-header">
              <th></th>
              <th class="text-center" [innerText]="currentYear"></th>
              <th class="text-center" [innerText]="previousYear"></th>
              <th class="text-center" [translate]="'dashboards.results.p&l.table.difference'">
                {{'dashboards.results.p&l.table.difference' | translate}}({{currencySymbol}})
              </th>
              <th class="text-center" [translate]="'dashboards.results.p&l.table.variation'"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of yearCountData">
              <td class="text-left pdd-left-10"><b>{{item.month}}</b></td>
              <td class="text-right">
                <span class="label font-size-12">{{item.actualYear | lgcurrency:'1.0-0'}}</span>
              </td>
              <td class="text-right">
                <span class="label font-size-12">{{item.lastYear | lgcurrency:'1.0-0'}}</span>
              </td>
              <td class="text-right">
                <span class="label font-size-12" [ngClass]="{'label-danger': item.diff <= 0.0,
                                                             'label-success': item.diff > 0.0}">
                  {{item.diff | lgcurrency:'1.0-0'}}
                </span>
              </td>
              <td class="text-right">
                <span class="label font-size-12" [ngClass]="{'label-danger': item.variation <= 0.0,
                                                             'label-success': item.variation > 0.0}">
                  {{item.variation | number:'1.2-2'}} %
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
