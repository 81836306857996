<ng-container *ngIf="customerAttributesDetailData">
  <div class="page-title background-white">
    <div class="row pdd-horizon-15">
      <div class="col-md-9 d-flex align-items-center min-height-60px">
        <h4 class="no-mrg-vertical">{{ customerAttributesDetailData.name }}</h4>
      </div>
    </div>
  </div>
  <div class="mrg-top-15 container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card" >
          <div class="">
            <div class="card-heading border bottom">
              <h4 class="card-title">
                {{ 'resources.customer_attributes.fields.description' | translate }}
              </h4>
            </div>
            <div class="card-block">
              <div class="row">
                <div class="col-md-9 ml-auto mr-auto">
                  <ng-container *ngFor="let input of customerAttributesDetailInputs">
                    <ng-container *ngIf="input.value">
                      <div class="col-md-9 mr-auto ml-auto">
                        <div class="form-group">
                          <label for="input.key" class="text-bold">
                            <span>{{ input.label | translate }}</span>
                            <span *ngIf="input.required" class="text-danger">*</span>
                            <em *ngIf="input.tooltip" class="pi pi-question-circle icon-tooltip"
                              position="right"
                              pTooltip="{{ input.tooltip | translate }}">
                            </em>
                          </label>
                          <ng-container *ngIf="input.type === 'text'">
                            <input
                              pInputText
                              [id]="input.key"
                              [type]="text"
                              [attr.data-cy]="'input_' + (input.key | camelCase)"
                              [(ngModel)]="input.value"
                              [class]="'form-control input-default'"
                              [disabled]="true">
                          </ng-container>
                          <ng-container *ngIf="input.type === 'textarea'">
                            <textarea
                              pInputTextarea
                              [id]="input.key"
                              [attr.data-cy]="'textarea_' + (input.key | camelCase)"
                              [(ngModel)]="input.value"
                              [class]="'form-control input-default'"
                              [disabled]="true"
                              [rows]="4">
                            </textarea>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>

                  <div class="col-md-9 ml-auto mr-auto text-right">
                    <button
                      translate="common.buttons.cancel"
                      (click)="back(true)"
                      class="btn btn-default"
                      [attr.data-cy]="'button_cancelCustomerAttributes'"
                    ></button>
                    <button *ngIf="isAdminRole"
                      (click)="edit()"
                      class="mr-0 btn btn-info"
                      [attr.data-cy]="'button_editCustomerAttributes'"
                      translate="common.buttons.edit"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

