<div id="date-picker" class="btn-group">
  <div class="input-group">

    <button type="button" class="btn btn-default btn-calendar height-39" (click)="toggleCalendar($event, calendar)" #btnCalendar>
      <i class="ei-calendar"></i>
    </button>

    <p-calendar #calendar
      inputStyleClass="hidden"
      [appendTo]="btnCalendar"
      [firstDayOfWeek]="1"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [showIcon]="true"
      (onSelect)="onDateSelected($event)">
    </p-calendar>

    <input type="text" class="form-control not-allowed" [value]="(value)?.name" readonly>

    <div class="input-group-append">
      <button type="button" class="btn btn-default dropdown-toggle btn-dynamic-dates height-39" data-toggle="dropdown">
        <i class="ei-tools"></i>
      </button>
      <ul class="dropdown-menu">
        <li [ngClass]="{ 'separator' : date.separatorAfter }" *ngFor="let date of dynamicDateValues">
          <a (click)="setValue(date)">{{date.name}}</a>
        </li>
      </ul>
    </div>

  </div>
</div>
