import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../shared/services/resources.service';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../../shared/components/multiselect/multiselect';
import { HttpClient } from '@angular/common/http';
import { DateService } from '../../../shared/services/date.service';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { of } from 'rxjs';
import { DateTimeZoneService } from '../../../shared/services/date-time-zone.service';

@Injectable()
export class MessageHistoriesService extends ResourcesService implements MultiselectDataSourceable {

  public messageId: number;
  public optsSource = new BehaviorSubject<number>(null);
  public optsSource$ = this.optsSource.asObservable();

  constructor(http: HttpClient,
              private dateService: DateTimeZoneService) {
    super(http);
  }

  public setMessage(id: number) {
    this.messageId = id;
    this.optsSource.next( this.messageId );
  }

  public fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {

    if ( !this.messageId ) { return of({}); }

    let requestOptions = {
      apiEndPoint: `messages/${this.messageId}/histories`,
      numberPerPage: 10,
      filtering: {
        paginate: false
      },
      pageNumber: 1,
      sorting: {
        byProp: 'created_at',
        direction: 'desc'
      }
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues, paginate: false }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  public fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `messages/${this.messageId}/histories/${id}` });
  }

  public getMessageHistoryById(id: number, historyId: number): Observable<any> {
    return this.getData({apiEndPoint: `messages/${id}/histories/${historyId}`});
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    const name = element._embedded.campaign.name;
    const date = this.dateService.parseDateWithFormat(element.sent_at, 'DD/MM/YYYY');
    return new MultiselectDataSource(element.id, `${name} (${date})`, element);
  }

  public updateData(data: any, motivatorId: number, historyId: number) {
    return this.patchResource(data, `messages/${motivatorId}/histories/${historyId}`);
  }
}
