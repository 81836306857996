import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { Condition } from '../../../../../shared/models/segments/condition';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';

@Component({
  selector: 'app-name-condition',
  templateUrl: './name-condition.component.html',
  styleUrls: ['./name-condition.component.css']
})
export class NameConditionComponent {

  @Input() item: Condition;
  @Input() inputs: QuestionBase<any>[];
  @Input() form: FormGroup;

  constructor(private qcs: QuestionControlService) {}

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

}
