import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-audience-selector',
  templateUrl: './audience-selector.component.html',
  styleUrls: ['./audience-selector.component.css']
})
export class AudienceSelectorComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  closeModal(typeOfAudience: string) {
    if (typeOfAudience === 'segmentsV2') {
      this.router.navigate([{ outlets: { modal: null } }]).then(() => {
        this.router.navigate(['/segmentsV2/new']).catch(() => {});
      }).catch(() => {});
    } else if (typeOfAudience === 'csv') {
      this.router.navigate([{ outlets: { modal: null } }]).then(() => {
        this.router.navigate(['/segmentsCSV/new']).catch(() => {});
      }).catch(() => {});
    }
  }

}
