<ng-container *ngIf="hasUserSecurity">
  <div class="password-validation-hint">
    <div *ngIf="userSecurity.passwordMinLength as minLength">
      <em class="fa-solid mrg-right-8" [ngClass]="validationConditionClass('invalidMinLength')"></em>
      <span>{{ (minLength === 1 ? 'resources.users.hints.password_min_one_length' : 'resources.users.hints.password_min_length') | translate: { number: minLength } }}</span>
    </div>
    <div *ngIf="userSecurity.passwordMinUppercase as minUpperCase">
      <em class="fa-solid mrg-right-8" [ngClass]="validationConditionClass('invalidMinUppercase')"></em>
      <span>{{ (minUpperCase === 1 ? 'resources.users.hints.password_min_one_uppercase' : 'resources.users.hints.password_min_uppercase') | translate: { number: minUpperCase } }}</span>
    </div>
    <div *ngIf="userSecurity.passwordMinDigits as minDigits">
      <em class="fa-solid mrg-right-8" [ngClass]="validationConditionClass('invalidMinDigits')"></em>
      <span>{{ (minDigits === 1 ? 'resources.users.hints.password_min_one_digit' : 'resources.users.hints.password_min_digits') | translate: { number: minDigits } }}</span>
    </div>
    <div *ngIf="userSecurity.passwordMinSpecialCharacters as minSpecialCharacters">
      <em class="fa-solid mrg-right-8" [ngClass]="validationConditionClass('invalidMinSpecialCharacters')"></em>
      <div>
        <span>{{ (minSpecialCharacters === 1 ? 'resources.users.hints.password_min_one_special_character' : 'resources.users.hints.password_min_special_characters') | translate: { number: minSpecialCharacters } }}</span>
        <em class="pi pi-question-circle font-size-13"
          tooltipPosition="right"
          pTooltip="{{ 'resources.users.tooltips.password_min_special_characters' | translate }}">
        </em>
      </div>
    </div>
  </div>
</ng-container>
