import { Component, OnInit, OnDestroy } from '@angular/core';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { MessagesService } from '../messages.service';
import { MessageCategoriesService } from '../message-categories.service';
import { Subscription } from 'rxjs';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { MessageHistoriesService } from '../message-histories.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-form-messages',
  templateUrl: './form-messages.component.html',
  styleUrls: ['./form-messages.component.css'],
  providers: [MessagesService, MessageCategoriesService, MessageHistoriesService]
})

export class FormMessagesComponent implements OnInit, OnDestroy {

  public id: number;
  public historyId: number;
  public inputs: QuestionBase<any>[];
  public messagesForm: UntypedFormGroup;
  public subs$: Subscription[];
  public showButton: boolean;

  constructor(private qcs: QuestionControlService,
              private route: ActivatedRoute,
              private router: Router,
              private messagesService: MessagesService,
              private messagesHistoryService: MessageHistoriesService,
              private confirmationService: ConfirmationService,
              private modalStatusService: ModalStatusService,
              private translate: TranslateService) {
    this.subs$ = [];
  }

  ngOnInit() {
    this.getParams();
    this.showBackButton();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  showBackButton() {
    this.showButton = !(this.router.url.indexOf('(modal:') >= 0);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  private getParams() {
    const route$ = this.route.params.subscribe(params => {
      this.resetForm();
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        if (params.hasOwnProperty('history_id')) {
          this.historyId = params.history_id;
          this.getMessageDelivery(params.id, params.history_id);
        } else {
          this.getMessagesById(params.id);
        }
      } else {
        this.inputs = this.messagesService.getInputs({});
        this.messagesForm = this.qcs.toFormGroup(this.inputs);
        this.handleAvailableInputs();
      }
    });
    this.subs$.push(route$);
  }

  private resetForm() {
    this.inputs = null;
    this.messagesForm = null;
  }

  private handleAvailableInputs() {
    const range$ = this.messagesForm.get('available_days').valueChanges.subscribe(
      data => {
        if ( data.length ) {
          this.messagesForm.get('available_from').disable();
          this.messagesForm.get('available_to').disable();
          this.messagesForm.patchValue({'available_from': null}, {emitEvent: false});
          this.messagesForm.patchValue({'available_to': null}, {emitEvent: false});
        } else {
          this.messagesForm.get('available_from').enable();
          this.messagesForm.get('available_to').enable();
        }
      }
    );
    this.subs$.push(range$);
  }

  private getMessagesById(id: number) {
    const message$ = this.messagesService.getMessagesById(id).subscribe(
      data => {
        message$.unsubscribe();
        this.inputs = this.messagesService.getInputs(data);
        this.messagesForm = this.qcs.toFormGroup(this.inputs);
        this.handleAvailableInputs();
      },
      errorData => {
        message$.unsubscribe();
        this.confirmationService.displayErrorAlert('Error', errorData.error.error);
      }
    );
    this.subs$.push(message$);
  }

  private getMessageDelivery(id: number, historyId: number) {
    const messageHistory$ = this.messagesHistoryService.getMessageHistoryById(id, historyId).subscribe(
      data => {
        messageHistory$.unsubscribe();
        this.inputs = this.messagesService.getInputs(data);
        this.messagesForm = this.qcs.toFormGroup(this.inputs);
        this.handleAvailableInputs();
      },
      errorData => {
        messageHistory$.unsubscribe();
        this.handleErrorOnRequest(errorData);
      }
    );
    this.subs$.push(messageHistory$);
  }

  public sendData() {
    const messagesOptions = this.processMessageToSave();
    if (!isNullOrUndefined(this.id) && !isNullOrUndefined(this.historyId)) {
      this.updateHistory(messagesOptions);
    } else if (!isNullOrUndefined(this.id) && isNullOrUndefined(this.historyId)) {
      this.updateMessage(messagesOptions);
    } else {
      this.createMessage(messagesOptions);
    }
  }

  private createMessage(messagesOptions) {
    const saveMessage$ = this.messagesService.sendData(messagesOptions).subscribe(
      () => {
        saveMessage$.unsubscribe();
        this.handleSuccessOnRequest(
          this.translate.instant('resources.messages.form.warnings.success_title'),
          this.translate.instant('resources.messages.form.warnings.success_text')
        );
      },
      errorData => {
        saveMessage$.unsubscribe();
        this.handleErrorOnRequest(errorData);
      }
    );
    this.subs$.push(saveMessage$);
  }

  private updateMessage(messagesOptions) {
    const saveMessage$ = this.messagesService.updateData(messagesOptions, this.id).subscribe(
      () => {
        saveMessage$.unsubscribe();
        this.handleSuccessOnRequest(
          this.translate.instant('resources.messages.form.warnings.update_title'),
          this.translate.instant('resources.messages.form.warnings.update_text')
        );
      },
      errorData => {
        saveMessage$.unsubscribe();
        this.handleErrorOnRequest(errorData);
      }
    );
    this.subs$.push(saveMessage$);
  }

  private updateHistory(messagesOptions) {
    const saveMessageHistory$ = this.messagesHistoryService.updateData(messagesOptions, this.id, this.historyId).subscribe(
      () => {
        saveMessageHistory$.unsubscribe();
        this.handleSuccessOnRequest(
          this.translate.instant('resources.messages.form.warnings.history_saved_title'),
          this.translate.instant('resources.messages.form.warnings.history_saved_text')
        );
      },
      errorData => {
        saveMessageHistory$.unsubscribe();
        this.handleErrorOnRequest(errorData);
      }
    );
    this.subs$.push(saveMessageHistory$);
  }

  private processMessageToSave() {
    const category = this.messagesForm.value.message_category_id;
    const locations = this.messagesForm.value.location_ids;

    const messagesOptions = {
      name: this.messagesForm.value.name,
      description: this.messagesForm.value.description,
      priority: this.messagesForm.value.priority,
      available_days: this.messagesForm.value.available_days,
      available_from: this.messagesForm.value.available_from,
      available_to: this.messagesForm.value.available_to,
    };

    messagesOptions['location_ids'] = (locations && locations.length > 0) ? locations.map(item => item.id) : [];
    if (isNullOrUndefined(this.historyId)) {
      messagesOptions['message_category_id'] = (category && category.length > 0) ? category[0].id : null;
    }

    return messagesOptions;
  }

  private handleSuccessOnRequest(messageTitle: string, messageDesc: string) {
    this.confirmationService.displaySuccessAlert( messageTitle, messageDesc ).catch(() => {});
    this.returnToList();
  }

  private handleErrorOnRequest(errorData) {
    if (errorData.error && errorData.error.errors && errorData.error.errors.length > 0) {
      this.paintErrorsInForm(this.messagesForm, errorData.error.errors);
    }
  }

  private paintErrorsInForm(form: UntypedFormGroup, errorsArray: {field: string}[]) {
    errorsArray.forEach(apiError => {
      const controlKey = apiError.field;
      form.get(controlKey).setErrors({'notPresent': true});
      this.qcs.getInputCfgByKey(this.inputs, controlKey).hasErrors = true;
    });
  }

  public returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.modalStatusService.modalStatus.emit();
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/omnichannel-manager/ticket-messages']).catch(() => {});
    }
  }
}
