<div class="card">
  <div class="card-heading border bottom">
    <h4 class="text-bold card-title" [translate]="'resources.scores.loyalty_scores.fields.details'"></h4>
  </div>
  <div class="card-block">
    <div class="alert alert-info" role="alert">
      <span class="alert-text">{{'resources.scores.loyalty_scores.descriptions.details' | translate}}</span>
    </div>
    <div *ngIf="flags.loyaltyAvailableLanguagesVisibility">
      <div class="form-group">
        <app-language-accordions [inputs]="inputs"
                                 [form]="loyaltyScoresForm"
                                 [mode]="mode"
                                 [filteredAvailableLanguages]="filteredAvailableLanguages">
        </app-language-accordions>
      </div>
    </div>

    <ng-container *ngIf="!flags.loyaltyAvailableLanguagesVisibility">
      <div class="row mrg-top-15">
        <div class="col-md-12">
          <div class="form-group">
            <label class="text-bold" for="name" [translate]="'resources.scores.loyalty_scores.fields.name'"></label>
            <span class="text-danger">*</span>
            <app-question [question]="getInputConfig('name')" [form]="loyaltyScoresForm"></app-question>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="description" class="text-bold"
              [translate]="'resources.scores.loyalty_scores.fields.description'"></label>
            <app-question [question]="getInputConfig('description')" [form]="loyaltyScoresForm"></app-question>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
