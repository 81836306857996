import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from '../../../shared/services/resources.service';

@Injectable()
export class ProfilesService extends ResourcesService {

  constructor(http: HttpClient) {
    super(http);
  }

  getCollectives() {
    const requestOptions = {
      apiEndPoint: '/collectives',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  getCustomerById(id: number) {
    return this.getData({ apiEndPoint: `profiles/${id}` });
  }

  deleteCustomer(id: number) {
    return this.deleteResource({}, `customers/${id}`);
  }

}
