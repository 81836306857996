import { Component, OnInit, OnDestroy } from '@angular/core';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { DataTableFilterService } from '../../../../../shared/components/data-table-filter/data-table-filter.service';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomersService } from '../../../customers/customers.service';
import { Subscription } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import * as moment from 'moment';
import { DateService } from '../../../../../shared/services/date.service';

@Component({
  selector: 'app-voucher-count-tab',
  templateUrl: './voucher-count-tab.component.html',
  styleUrls: ['./voucher-count-tab.component.css']
})

export class VoucherCountTabComponent implements OnInit, OnDestroy {

  dateFrom: any;
  dateTo: any;
  voucher_data: any;
  vouchersCount: any[];
  loading: boolean;
  onSearch: boolean;
  subs$: Subscription[];

  constructor(
    private refreshCacheService: RefreshCacheService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private filterService: DataTableFilterService,
    private customersFilter: CustomersService,
    private dateService: DateService
  ) {
    this.vouchersCount = [];
    this.subs$ = [];
    this.loading = false;
  }

  ngOnInit() {
    registerLocaleData(es);
    this.onSearch = false;
    this.setDefaultDates();
    const filter$ = this.customersFilter.applyFilters$.subscribe(
      (filterValues) => {
        this.getVoucherCountData(filterValues);
        this.onSearch = true;
      }
    );
    this.subs$.push(filter$);
    setTimeout(() => this.filterService.loaderStatus.next(true));
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  private getVoucherCountData(filterValues) {

    const filterFormValue = {...filterValues};

    if (filterFormValue.date_from && filterFormValue.date_to) {
      if (!this.compareDates(filterFormValue)) {
        return;
      }
    }

    this.vouchersCount = [];

    let filtering;
    if (filterFormValue) {
      filtering = {
        country: 'ES',
        date_from: filterFormValue.date_from,
        date_to: filterFormValue.date_to,
        location_ids: filterFormValue.location_ids,
        segment_id: filterFormValue.segment_id,
      };
    } else {
      filtering = {
        country: 'ES',
        date_from: this.dateFrom,
        date_to: this.dateTo
      };
    }
    const params = {
      apiEndPoint: 'analytics/voucher_count',
      filtering: filtering
    };

    this.loading = true;
    const voucherData$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        voucherData$.unsubscribe();
        this.loading = false;
        this.filterService.loaderStatus.next(true);
        this.voucher_data = reqResponse;
        this.parseVoucherData();
      }
    });
    this.subs$.push(voucherData$);
  }

  private setDefaultDates() {
    this.dateFrom = this.dateService.momentStartOfPreviousPeriodTypeAndFormat(1, 'months', 'month', 'YYYY-MM-DD');
    this.dateTo = this.dateService.momentEndOfPreviousPeriodTypeAndFormat(1, 'months', 'month', 'YYYY-MM-DD');
  }

  private parseVoucherData() {
    this.voucher_data.meta.rows.forEach((_, index) => this.vouchersCount.push({
      id: this.voucher_data.meta.rows[index],
      coupons_assigned: this.voucher_data.values[index][1],
      coupons_printed: this.voucher_data.values[index][2],
      messages_assigned: this.voucher_data.values[index][3],
      messages_printed: this.voucher_data.values[index][4]
    }));
  }

  private alertDateMessage() {
    this.confirmationService.displayAlert(
      this.translate.instant('components.data-table.errors.title_error_date'),
      this.translate.instant('components.data-table.errors.date_bigger_than'),
      'warning'
    ).catch(() => {});
  }

  private compareDates(filterFormValue): boolean {
    if (moment(filterFormValue.date_from).isAfter(filterFormValue.date_to)) {
      this.loading = false;
      this.alertDateMessage();
      setTimeout(() => { this.filterService.loaderStatus.next(true); });
      return false;
    }
    return true;
  }
}
