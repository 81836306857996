import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';
import { isNullOrUndefined, isNumber } from 'util';
import { SystemSegmentsService } from '../../../resources/segments/system-segments.service';
import { MultiselectDataSource } from '../../components/multiselect/multiselect';
import { QuestionBase } from '../../models/forms/question-base';
import { MultiSelectQuestion } from '../../models/forms/question-multiselect';
import { SystemTagHistoriesService } from '../system-tag-histories.service';
import { multiselectPresenceValidator } from '../validations.service';

@Injectable()
export class SystemSegmentConditionService {

  valueSource = new BehaviorSubject<any>(null);
  valueSource$ = this.valueSource.asObservable();
  inputs: QuestionBase<any>[];
  subs$: Subscription[];
  customValidators = [() => null];

  constructor( private systemSegmentsService: SystemSegmentsService,
               private tagHistoresService: SystemTagHistoriesService,
               private translate: TranslateService ) { }

  getInputs(params?: any): QuestionBase<any>[] {

    const paramsAreDefined = ( params && Object.keys(params).length > 0 );
    const operator = paramsAreDefined ? this.getOperator(params) : [];
    const segments = paramsAreDefined ? this.getSegmentFromParams(params) : [];
    const apply = paramsAreDefined ? this.getApplyFromParams(params) : [];
    const indexApply = paramsAreDefined ? this.getApplyIndexFromParams(params) : [];

    if ( segments && segments.length > 0 ) {
      this.tagHistoresService.setSegment(segments[0]);
    }

    const inputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'operator',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.getOperatorsValues(),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        parseValue: (value) => {
          return (value && value.length) ? this.getOperatorsValues().find( _operator => _operator.id === value[0].id ).id : null;
        },
        value: operator ? operator : [],
        customValidators: [multiselectPresenceValidator]
      }),
      new MultiSelectQuestion({
        label: this.translate.instant('resources.segment_conditions.fields.segment'),
        cssClasses: 'form-control input-md',
        key: 'segment_ids',
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        parseValue: (values) => ((values && values.length > 0) ? values.map(_val => _val.id) : []),
        dataSource: this.systemSegmentsService,
        selectedIds: ( segments && segments.length > 0 ) ? segments : [],
        required: true,
        customValidators: [multiselectPresenceValidator]
      }),
      new MultiSelectQuestion({
        label: this.translate.instant('resources.segment_conditions.fields.static_tags'),
        cssClasses: 'form-control input-md',
        key: 'apply',
        settings: { singleSelection: true, enableCheckAll: true, showCheckbox: false, enableSearchFilter: false },
        parseValue: (values) => ( values && values.length > 0 ) ? values[0].id : null,
        dataSource: this.tagHistoresService,
        selectedIds: apply
      }),
      new MultiSelectQuestion({
        label: this.translate.instant('resources.segment_conditions.fields.dynamic_tags'),
        cssClasses: 'form-control input-md',
        key: 'index_apply',
        settings: { singleSelection: true, enableCheckAll: true, showCheckbox: false, enableSearchFilter: false },
        options: this.getStaticTagList(),
        value: indexApply,
        parseValue: (values) => ((values && values.length > 0) ? values[0].id : null)
      })
    ];

    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI(params: any): any {
    const parsedValuesObj = {};
    Object.keys(params).forEach( key => {
      const input = this.inputs.find( _input => _input.key === key );
      const parsedValue = input.parseValue( params[key], parsedValuesObj );
      parsedValuesObj[key] = parsedValue;
    });
    return parsedValuesObj;
  }

  prepareFormValuesFromAPI(params: any): any {
    const parseValues = {
      operator: (value) => (value) ? value : null,
      segment_ids: (value) => (value) ? value : null,
      apply: (value) => (value) ? value : null,
      index_apply: (value) => (value >= 0) ? value : null
    };
    const parsedValuesObj = {};
    Object.keys(params).forEach( key => { parsedValuesObj[key] = parseValues[key](params[key]); });
    return parsedValuesObj;
  }

  private getSegmentFromParams(params?) {
    const segments = params.segment_ids;
    if ( segments && segments instanceof Array && segments.length > 0 && !isNullOrUndefined(segments[0])) {
      return segments.map(_segment => _segment.hasOwnProperty('id') ? _segment.id : _segment);
    } else {
      return [];
    }
  }

  private getApplyFromParams(params?) {
    if ( params.apply && isNumber(params.apply) ) {
      return [params.apply];
    } else if ( params.apply && params.apply.length > 0 && params.apply[0] instanceof MultiselectDataSource ) {
      return [params.apply[0].id];
    }
  }

  private getApplyIndexFromParams(params?) {
    const indexApply = params.index_apply;
    if ( typeof indexApply === 'number' && indexApply >= 0 ) {
      return [this.getStaticTagList().find(_element => _element.id === indexApply)];
    } else if (typeof indexApply !== 'number') {
      return indexApply;
    } else {
      return [];
    }
  }

  private getOperator(params?) {
    const operator = params.operator;
    if ( operator && operator.length > 0 && operator[0].hasOwnProperty('id') ) {
      return operator;
    } else if ( operator && operator.length > 0 && !operator[0].hasOwnProperty('id') ) {
      return [this.getOperatorsValues().find( op => op.id === operator )];
    } else {
      return [];
    }
  }

  private getStaticTagList(): { id: number, name: string }[] {
    const tagList = [{id: 0, name: this.translate.instant('resources.segment_conditions.fields.last_tag')}];
    for (let index = 1; index <= 13; index++) {
      tagList.push({id: index, name: this.translate.instant('resources.segment_conditions.fields.last_tag') + ` - ${index}` });
    }
    return tagList;
  }

  private getOperatorsValues(): {id: string, name: string}[] {
    return [
      { id: 'in', name: this.translate.instant('resources.segment_conditions.operators.belongs_to')},
      { id: 'not_in', name: this.translate.instant('resources.segment_conditions.operators.does_not_belongs')}
    ];
  }
}
