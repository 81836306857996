import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import es from '@angular/common/locales/es';
import { RewardTaxonomy } from '../reward-taxonomy';
import { RewardTaxonomiesService } from '../reward-taxonomies.service';

@Component({
  selector: 'app-show-reward-taxonomies',
  templateUrl: './show-rewards-taxonomies.component.html',
  styleUrls: ['./show-rewards-taxonomies.component.css']
})
export class ShowRewardsTaxonomiesComponent implements OnInit {

  element: RewardTaxonomy;
  id: number;

  @Output('updateEmitter') updateEmitter: EventEmitter<any>  = new EventEmitter();

  constructor(
    public route: ActivatedRoute,
    public translate: TranslateService,
    public router: Router,
    private confirmationService: ConfirmationService,
    private taxonomies: RewardTaxonomiesService
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.getParams();
  }

  getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.taxonomies.fetchSelectedById(this.id).subscribe(
          (category: RewardTaxonomy) => {
            this.element = category;
            this.updateEmitter.emit(['', { outlets: { modal: ['update', 'reward-taxonomies', this.id]}}]);
          },
          (err: HttpErrorResponse) => {
            this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), err.error.error);
            this.closeModal();
          }
        )
      }
    });
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }
}
