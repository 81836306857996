<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <app-loader *ngIf="loading" />
          <div class="table-overflow" *ngIf="!loading">
            <table class="table table-hover" *ngIf="campaignContent" aria-hidden="true">
              <tbody>

                <tr>
                  <td class="no-border-top"><b [translate]="'resources.campaigns.fields.via'"></b></td>
                  <td class="no-border-top">{{'resources.campaigns.channels.' + campaignContent.via | translate }}</td>
                </tr>

                <tr>
                  <td><b [translate]="'resources.campaigns.fields.motive'"></b></td>
                  <td>{{'resources.campaigns.motive.' + campaignContent.motive | translate }}</td>
                </tr>

                <!-- Coupon message campaigns will not be redirected to this modal -->
                <tr *ngIf="campaignContent.via === 'none'">
                  <td><i class="fa fa-envelope-square"></i><b class="text-dark font-size-18" [translate]="'resources.campaigns.fields.message'"></b><br></td>              
                  <td [translate]="'resources.campaigns.warnings.coupon_ticket_no_content'"></td>
                </tr>

                <!-- Render iframe por campaigns with email -->
                <ng-container *ngIf="campaignContent.template && campaignContent.via === 'email'">
                  <tr *ngIf="template">
                    <td><b [translate]="'resources.campaigns.fields.subject'"></b></td>
                    <td>{{campaignContent['template'].subject}}</td>
                  </tr>
                </ng-container>
                <!--  -->

                <!-- Render embedded template data for push, sms and ticket_message  -->
                <ng-container *ngIf="['sms', 'push', 'ticket_message'].indexOf(campaignContent.via) !== -1">
                  <tr *ngIf="campaignContent.template && campaignContent['template'].subject">
                    <td><b [translate]="'resources.campaigns.fields.message_subject'"></b></td>  
                    <td>{{campaignContent['template'].subject}}</td>
                  </tr>
                  <tr *ngIf="campaignContent.template && campaignContent['template'].message">
                    <td><b [translate]="'resources.campaigns.fields.message_body'"></b></td>  
                    <td>{{campaignContent['template'].message}}</td>
                  </tr>
                </ng-container>
                <!--  -->

              </tbody>
            </table>
          </div>

          <div class="row" *ngIf="campaignContent && campaignContent.via === 'email' && campaignContent.template">
            <div class="col">
              <div>
                <iframe class="iframeStyle" id="{{campaignContent.template['message']}}" [srcdoc]="srcdoc" title="{{campaignContent.template['message']}}"></iframe>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
