import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../services/question-control.service';
import { TagHistoriesService } from '../../../../services/tag-histories.service';
import { Subscription } from 'rxjs';
import { MultiselectService } from '../../../multiselect/multiselect.service';

@Component({
  selector: 'app-nested-segment-condition',
  templateUrl: './nested-segment-condition.component.html',
  styleUrls: ['./nested-segment-condition.component.css']
})
export class NestedSegmentConditionComponent implements OnInit, OnDestroy {

  @Input('item') item: Condition;
  @Input('inputs') inputs: QuestionBase<any>[] = [];
  @Input('form') form: UntypedFormGroup;
  @Input('filters') filters: { system_mode: string };

  subs$: Subscription[] = [];

  constructor( 
    private qcs: QuestionControlService,
    private tagHistoriesService: TagHistoriesService,
    private changeDetector: ChangeDetectorRef,
    private multiselectService: MultiselectService
  ) { }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach( s$ => s$.unsubscribe()); }
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    if ( !value ) { return false; }
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  handleApplySelected(value) {
    this.resetInput('apply', 'index_apply');
  }

  handleApplyIndexSelected(value) {
    this.resetInput('index_apply', 'apply');
  }

  /*
  * @param {Boolean} emitValue: If set to false won't refresh tag_histories input values
  */
  handleSegmentSelected(value, emitValue?: boolean) {
    const _emitValue = emitValue === false ? false : true;
    if (value) {
      if (_emitValue) {
        this.resetApplyInputs();
        this.inputs.find( input => input.key === 'apply').options = [];
        this.inputs.find( input => input.key === 'apply').disabled = false;
        this.inputs.find( input => input.key === 'index_apply').disabled = false;
        this.multiselectService.updateDropdownList.next('apply');
      };
      this.tagHistoriesService.setSegment(value.id);
      const histories$ = this.tagHistoriesService.fetchMultiselect().subscribe(
        tagHistoriesData => {
          if(tagHistoriesData['list'].length > 0) {
            const options = tagHistoriesData['list'].map( history => this.tagHistoriesService.getNameWithTemplate(history) );
            if ( this.inputs[2].value.length > 0 ) {
              const values = this.inputs[2].value.map( selectedId => options.find( opt => opt.id === selectedId ) );
              this.inputs[2].value = values;
              this.form.get(this.inputs[2].key).patchValue(values);
            }
            this.inputs[2].options = options;
            this.multiselectService.updateDropdownList.next(this.inputs[2].key);
            this.filterAppliedIndexesRegardingApply(tagHistoriesData['_links']['total_count']['href']);
          } else {
            this.disableApplyInputs();
            this.changeDetector.markForCheck();
          }
        }
      );
      this.subs$.push(histories$);
    } else {
      this.disableApplyInputs();
    }
  }

  handleSegmentApplies(segmentPayload: object): void {
    if(segmentPayload['applies'] > 0) {
      this.filterAppliedIndexesRegardingApply(segmentPayload['applies'])
    }else if(segmentPayload['applies'] <= 0) {
      this.disableApplyInputs();
    }
  }

  private init() {
    if ( this.inputs[1].selectedIds && this.inputs[1].selectedIds.length > 0 ) {
      this.handleSegmentSelected( { id: this.inputs[1].selectedIds[0] }, false );
    } else {
      this.disableApplyInputs();
    }
  }

  private resetInput(inputSelectedKey: string, inputToResetKey: string, ) {
    const inputSelected = this.inputs.find( _input => _input.key === inputSelectedKey);
    const inputToReset = this.inputs.find( _input => _input.key === inputToResetKey);
    if ( inputSelected.value && inputSelected.value.length > 0 ) { 
      inputToReset.value = [];
      this.form.get(inputToResetKey).patchValue([]);
    }
  }

  private filterAppliedIndexesRegardingApply(appliesNum: number) {
    const indexApplyInput = this.inputs.find(inp => inp.key === 'index_apply');
    const indexApplyOpts = indexApplyInput.options.splice(0, appliesNum);
    indexApplyInput.options = indexApplyOpts;
    this.multiselectService.updateDropdownList.next('index_apply');
  }

  private resetApplyInputs() {
    this.inputs.find( input => input.key === 'apply').value = [];
    this.inputs.find( input => input.key === 'index_apply').value = [];
    this.form.patchValue({ apply: [], index_apply: [] });
  }

  private disableApplyInputs() {
    this.resetApplyInputs();
    this.inputs.find( _input => _input.key === 'apply' ).disabled = true;
    this.inputs.find( _input => _input.key === 'index_apply' ).disabled = true;
  }
}
