<div class="modal-backdrop fade in" tabindex="-1" role="dialog" aria-hidden="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="mrg-left-15 mrg-btm-5" [translate]="'resources.customers.show.actions.redeem_reward'"></h4>
        <button [attr.data-cy]="'button_closeRedeemReward'" class="modal-close" (click)="closeModal()">
          <i class="ti-close"></i>
        </button>
      </div>

      <app-loader *ngIf="loading"></app-loader>

      <div class="modal-body background-gray">
        <div class="row">
          <div class="col-md-12">
            <div class="container-fluid">
              <div class="row">
                <div class="mrg-top-10 col-md-12">
                  <div class="card-block no-pdd">
                    <div class="row">
                      <div class="col-md-8 ml-auto mr-auto">
                        <form [formGroup]="redeemRewardForm" *ngIf="redeemRewardForm">

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="reward_id" [translate]="'resources.customers.show.action_modals.reward'"></label>
                                <span class="text-danger">*</span>
                                <app-question [question]="getInputConfig('reward_id')"
                                              [form]="redeemRewardForm"
                                              [useToken]="true"
                                              (multiselectChanged)="handleRewardSelection($event)">
                                </app-question>
                              </div>
                            </div>
                          </div>

                          <div class="row" *ngIf="rewardWithFlexibleScoreAllowed">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="score_overwrite" [translate]="'resources.customers.show.action_modals.score_overwrite'"></label>
                                <span class="text-danger">*</span>
                                <app-question [question]="getInputConfig('score_overwrite')"
                                              [form]="redeemRewardForm">
                                </app-question>
                              </div>
                            </div>
                          </div>

                          <div class="row" *ngIf="!loading">
                            <div class="col-md-12">
                              <div class="alert alert-info" role="alert">
                                <p class="mrg-btm-5"><strong>{{'resources.customers.show.action_modals.messages.redeem_reward.redeem_reward_title' | translate}}</strong></p>
                                <ul>
                                  <li><em><b>{{'resources.customers.show.action_modals.messages.redeem_reward.customer' | translate}}</b></em></li>
                                  <li><em><b>{{'resources.customers.show.action_modals.messages.redeem_reward.points' | translate}}</b></em></li>
                                  <li><em><b>{{'resources.customers.show.action_modals.messages.redeem_reward.availability_status' | translate}}</b></em></li>
                                  <li><em><b>{{'resources.customers.show.action_modals.messages.redeem_reward.availability_reward' | translate}}</b></em></li>
                                  <li><em><b>{{'resources.customers.show.action_modals.messages.redeem_reward.stock' | translate}}</b></em></li>
                                  <li><em><b>{{'resources.customers.show.action_modals.messages.redeem_reward.limits' | translate}}</b></em></li>
                                  <li><em><b>{{'resources.customers.show.action_modals.messages.redeem_reward.tier' | translate}}</b></em></li>
                                </ul>
                              </div>
                            </div>
                          </div>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer background-white">
        <div class="pull-right">
          <button [attr.data-cy]="'button_customerRedeemRewardConfirm'"
                  class="pull-right btn btn-info"
                  [translate]="'resources.customers.show.actions.redeem_reward'"
                  [disabled]="!redeemRewardForm?.valid || loading"
                  (click)="setPayload()">
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
