import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { UsersService } from '../../../../resources/users/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSecurity } from '../../../../shared/models/company/company-configurations';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { AuthService } from '../../../auth.service';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { userPassworValidator } from '../../../../shared/validators/user-password.validator';
import { matchPasswordValidator } from '../../../../shared/validators/match-password.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  loading = false;
  resetPasswordForm: UntypedFormGroup;
  resetPasswordInputs: QuestionBase<unknown>[];
  resetToken: string;
  showPasswordVisibility = {
    new_password: false,
    new_password_confirmation: false
  };
  tokenValidationError = false;
  userSecurity: UserSecurity;
  validateResetPasswordToken = true;

  private destroy$ = new Subject<void>();

  @Input() isResetExpiredPassword = false;

  constructor(
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private usersService: UsersService
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.resetPasswordInputs, inputKey);
  }

  handlePasswordVisibility(key: string): void {
    this.showPasswordVisibility[key] = !this.showPasswordVisibility[key];
    this.getInputConfig(key).type = this.showPasswordVisibility[key] ? 'text' : 'password';
  }

  resetPassword(): void {
    this.loading = true;
    const formValue = this.resetPasswordForm.getRawValue();
    const params = {
      password: formValue.new_password,
      password_confirmation: formValue.new_password_confirmation,
      reset_password_token: this.resetToken
    };

    if (params.password !== params.password_confirmation) {
      this.confirmationService.displayAlert(
        this.translate.instant('resources.users.fields.password'),
        this.translate.instant('resources.users.messages.password_mismatch'),
        'warning'
      ).catch(() => {});
    } else {
      this.authService.resetPassword(params).pipe(takeUntil(this.destroy$), finalize(() => this.loading = false)).subscribe({
        next: () => {
          this.confirmationService.displayOutsideUnclickableSuccessAlert(
            this.translate.instant('components.signin.messages.reset_password_success_title'),
            this.translate.instant('components.signin.messages.reset_password_success_desc')
          ).then(({ value }) => {
            if (value) {
              this.router.navigate(['/signin']).catch(() => {});
            }
          }).catch(() => {});
        },
        error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
      });
    }
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.resetToken = this.isResetExpiredPassword ? params.reset_password_token : params.reset_token;
      this.handleValidateResetPassword();
      }
    );
  }

  private handleValidateResetPassword(): void {
    this.usersService.getResetTokenValidationPasswordPolicy(this.resetToken).pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: HttpResponse<object>) => {
        this.userSecurity = new UserSecurity(response ?? {});
        this.setInputsAndForm();
        this.validateResetPasswordToken = false;
      },
      error: (errorData: HttpErrorResponse) => {
        this.confirmationService.displayHttpErrorAlert(errorData);
        this.validateResetPasswordToken = false;
        this.tokenValidationError = true;
      }
    });
  }

  private setInputsAndForm(): void {
    this.resetPasswordInputs = this.getInputs();
    this.resetPasswordForm = this.qcs.toFormGroup(this.resetPasswordInputs);
    this.setFormValidators();
  }

  private setFormValidators(): void {
    this.resetPasswordForm.addValidators(matchPasswordValidator('new_password', 'new_password_confirmation'));
    this.resetPasswordForm.updateValueAndValidity();
  }

  private getInputs(): QuestionBase<unknown>[] {
    return [
      new TextboxQuestion({
        key: 'new_password',
        type: 'password',
        cssClasses: 'form-control input-default',
        required: true,
        customValidators: [userPassworValidator(this.userSecurity)]
      }),
      new TextboxQuestion({
        key: 'new_password_confirmation',
        type: 'password',
        cssClasses: 'form-control input-default',
        required: true
      })
    ];
  }
}
