<div class="row mrg-top-15">
  <div class="col-md-8 mr-auto ml-auto">
    <div class="card">
      <div class="card-block">
        <div class="alert alert-warning" role="alert" *ngIf="!rewardData?.available_languages">
          <span>{{ 'resources.scores.rewards.messages.no_available_languages' | translate }}</span>
        </div>
        <app-language-accordions *ngIf="showRewardsForm"
          [inputs]="showRewardsInputs"
          [form]="showRewardsForm"
          [mode]="mode"
          [filteredAvailableLanguages]="rewardData.available_languages">
        </app-language-accordions>
      </div>
    </div>
  </div>
</div>
