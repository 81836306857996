import { Component, OnInit, Input } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../services/question-control.service';
import { DictionaryService } from '../../../../services/dictionary.service';

@Component({
  selector: 'app-phone-prefix-condition',
  templateUrl: './phone-prefix-condition.component.html',
  styleUrls: ['./phone-prefix-condition.component.css'],
  providers: [ DictionaryService ]
})
export class PhonePrefixConditionComponent implements OnInit {

  @Input('item') item: Condition;
  @Input('inputs') inputs: QuestionBase<any>[];
  @Input('form') form: UntypedFormGroup;

  constructor(
    private qcs: QuestionControlService
  ) { }

  ngOnInit() { }

  public hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  public getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

}
