<footer class="content-footer no-border">
  <div class="footer no-border no-mrg-top no-pdd-top">
    <div class="copyright pdd-btm-15 pdd-mrg-15">
      <span class="go-right">
        <a href="https://www.loyal.guru/" target="_blank" rel="noopener">
          {{ 'components.signin.messages.copyright' | translate: { year: currentYear } }}
        </a>
      </span>
    </div>
  </div>
</footer>
