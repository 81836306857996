import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerJourney } from '../../../shared/models/customer-journeys/customer-journey';
import { CustomerJourneysService } from '../customer-journeys.service';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { DataTableComponent } from '../../../shared/components/data-table/data-table.component';
import { Subscription } from 'rxjs';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { QuestionControlService } from '../../../shared/services/question-control.service';
import { CustomerJourneysHistoriesService } from '../customer-journeys-histories.service';
import { ProfileService } from '../../../profiles/profile.service';
import { DateTimeZoneService } from '../../../shared/services/date-time-zone.service';
import { registerLocaleData, getCurrencySymbol } from '@angular/common';
import es from '@angular/common/locales/es';
import * as moment from 'moment';

@Component({
  selector: 'app-customer-journey-show',
  templateUrl: './customer-journey-show.component.html',
  styleUrls: ['./customer-journey-show.component.css']
})

export class CustomerJourneyShowComponent implements OnInit, OnDestroy {

  public journeyId: number;
  public journey: CustomerJourney;
  public selectedTab = 'summary';
  public currentHistory: { id: number, name?: string };
  public historiesSelectorConfig: {
    options: {id: number, name: string}[],
    settings: {
      text: string,
      singleSelection: boolean,
      showCheckbox: boolean
    }
    value?: [{id: number, name?: string}]
  };
  public stepsToDeliver: {
    now: { name: string }[],
    scheduled: { name: string, date: string }[]
  };
  public currencySymbol: any;
  public historiesTableConfig: {};
  public subs$: Subscription[] = [];
  public inputs: QuestionBase<any>[];
  public form: UntypedFormGroup;

  @ViewChild('historiesSelector') historiesSelector: ElementRef;
  @ViewChild('historiesTable') historiesTable: DataTableComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private journeyService: CustomerJourneysService,
    private translate: TranslateService,
    private dateService: DateTimeZoneService,
    private confirmationService: ConfirmationService,
    private changeDetector: ChangeDetectorRef,
    private qcs: QuestionControlService,
    private journeyHistoriesService: CustomerJourneysHistoriesService,
    private profileService: ProfileService
  ){
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
  }

  ngOnInit() {
    registerLocaleData(es);
    this.getParams();
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach( s$ => s$.unsubscribe() ); }
  }

  handleCloseModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  journeyShowUrlContains(stringId) {
    return this.router.url.indexOf(`show/customer_journeys/`) >= 0 && this.router.url.indexOf(stringId) >= 0;
  }

  // Private
  private getParams() {
    const route$ = this.route.params.subscribe(params => {

      if (params.hasOwnProperty('id')) {
        this.journeyId = params.id;
        this.setTableCfg();
      }

      if (params.hasOwnProperty('history_id')) {
        this.getJourneyHistory(this.journeyId, params.history_id);
      } else {
        this.currentHistory = { id: -1 };
        this.getJourney(params.id);
      }

      this.initHistoriesFilter(params);
    });

    this.subs$.push(route$);
  }

  private getJourney(id: number) {
    const journey$ = this.journeyService.getCustomerJourneyById(id).subscribe(
      journeyApiData => {
        this.loadJourney(journeyApiData);
      }
    );
    this.subs$.push(journey$);
  }

  private getJourneyHistory(id: number, historyId: number) {
    const journeyHistory$ = this.journeyService.getCustomerJourneyHistory(id, historyId).subscribe(
      customerJourneyHistoryData => {
        this.loadJourney(customerJourneyHistoryData);
        const sentAt = this.dateService.parseDateWithFormat(customerJourneyHistoryData.sent_at, 'DD/MM/YYYY');
        this.currentHistory = { name: sentAt, id: customerJourneyHistoryData.id };
      }
    );
    this.subs$.push(journeyHistory$);
  }

  private initHistoriesFilter(params?) {
    const selectedIds = params.history_id;
    this.journeyHistoriesService.setJourney( this.journeyId );
    this.inputs = [
      new MultiSelectQuestion({
        key: 'journey_history_id',
        cssClasses: 'form-control input-default',
        settings: {
          singleSelection: true,
          showCheckbox: false,
          enableSearchFilter: false,
          text: this.translate.instant('resources.journeys.fields.next')
        },
        dataSource: this.journeyHistoriesService,
        selectedIds: selectedIds ? [selectedIds] : []
      })
    ];
    this.form = this.qcs.toFormGroup(this.inputs);
  }

  private loadJourney(journeyData) {
    this.journey = this.journeyService.initByType(journeyData);
  }

  public historyChangedHandler(value) {
    this.currentHistory = value;
  }

  public searchHistory() {
    if ( this.currentHistory ) {
      // Filter values by history id
      this.getJourneyHistory(this.journeyId, this.currentHistory.id);
    } else {
      // Next delivery
      this.getJourney(this.journeyId);
    }
  }

  public filterByHistory() {
    // this.currentHistory = this.historiesSelectorConfig.value[0];
    /* Treat id: -1 as next delivery of current c. journey */
    if ( this.currentHistory.id > -1 ) {
      this.getJourneyHistory(this.journeyId, this.currentHistory.id);
    } else {
      this.getJourney(this.journeyId);
    }
  }

  public editJourney() {
    if ( this.journey.type === 'custom' ) {
      this.router.navigate([{ outlets: { modal: `update/customer_journeys/custom/${this.journeyId}` }}]).catch(() => {});
    } else {
      this.router.navigate([{ outlets: { modal: `update/customer_journeys/${this.journeyId}` }}]).catch(() => {});
    }
  }

  public sendInfo() {

    const today = new Date();
    const pendingSteps = this.journey.steps.filter(step => !(['done', 'cancelled', 'running'].indexOf(step.status) >= 0));

    this.stepsToDeliver = {now: [], scheduled: []};

    this.stepsToDeliver.now = pendingSteps.filter(step => new Date(step.scheduled_at) <= today).map( step => {
      return {
        name: this.journey.getStepName(step)
      };
    });

    this.stepsToDeliver.scheduled = pendingSteps.filter(step => new Date(step.scheduled_at) > today).map( step => {
      return {
        name: this.journey.getStepName(step),
        date: this.dateService.parseDateWithFormat(step.scheduled_at, 'DD/MM/YYYY HH:mm')
      };
    });
  }

  public triggerJourneyDelivery() {
    const delivery$ = this.journeyService.deliverJourneyHistory(this.journeyId, this.currentHistory.id).subscribe(
      () => {
        this.confirmationService.displaySuccessAlert(
          this.journey.name,
          this.translate.instant('resources.journeys.warnings.successfully_scheduled')
        ).catch(() => {});
        this.historiesTable.getData();
      },
      err => {
        this.confirmationService.displayErrorAlert(
          this.translate.instant('resources.journeys.status.error'),
          `${this.translate.instant('resources.journeys.warnings.delivery_error')}: ${err}`
        );
    });
    this.subs$.push(delivery$);
  }

  private setHistoriesSelectorConfig(historiesList: {}[]) {

    const parsedHistories = this.parseHistoriesAsOptions(historiesList);
    if (this.currentHistory.id < 0 && !this.currentHistory.hasOwnProperty('name')) {
      this.currentHistory = parsedHistories.find( history => history.id === -1 );
    } else {
      this.currentHistory = parsedHistories[0];
    }

    this.historiesSelectorConfig = {
      options: parsedHistories,
      value: [this.currentHistory],
      settings: {
        text: this.translate.instant('resources.journeys.filters.select_history'),
        singleSelection: true,
        showCheckbox: true
      },
    };

    this.changeDetector.detectChanges();
  }

  private parseHistoriesAsOptions(histories) {
    /* Treat id: -1 as c. journey next delivery to mantain number type in id */
    const options = [{id: -1, name: this.translate.instant('resources.journeys.fields.next')}];
    histories.list.forEach(element => {
      let date;
      if (element.hasOwnProperty('sent_at') && element.sent_at) {
        date = new Date(element.sent_at);
      } else {
        date = new Date(element.scheduled_at);
      }
      options.push({
        id: element.id,
        name: moment(date).format('DD/MM/YYYY')
      });
    });
    return options;
  }

  private pauseHistory(historyId: number, redirectTo?) {

    const confirmationTitle = this.translate.instant('resources.journeys.confirms.continue');
    const confirmationMessage = this.translate.instant('resources.journeys.confirms.pause_history');

    this.confirmationService.displayConfirmationAlert(confirmationTitle, confirmationMessage).then(
      data => {
        if (data.hasOwnProperty('value') && data.value) {
          const cancel$ = this.journeyService.cancelJourneyHistorySchedule(this.journeyId, historyId).subscribe(
            () => {
              this.historiesTable.getData();
              if (redirectTo) {
                this.router.navigate( ([ redirectTo ]) ).catch(() => {});
              }
            },
            errorObject => {
              const title = this.translate.instant('resources.journeys.status.error');
              this.confirmationService.displayErrorAlert(title, errorObject.error.error);
            }
          );
          this.subs$.push(cancel$);
        }
      }
    ).catch(() => {});
  }

  private cancelHistory(historyId: number) {

    const confirmationTitle = this.translate.instant('resources.journeys.confirms.continue');
    const confirmationMessage = this.translate.instant('resources.journeys.confirms.cancel_history');

    this.confirmationService.displayConfirmationAlert(confirmationTitle, confirmationMessage).then(
      data => {
        if (data.hasOwnProperty('value') && data.value) {
          const cancel$ = this.journeyService.cancelJourneyHistory(this.journeyId, historyId).subscribe(
            () => {
              this.historiesTable.getData();
            },
            errorObject => {
              const title = this.translate.instant('resources.journeys.status.error');
              this.confirmationService.displayErrorAlert(title, errorObject.error.error);
            }
          );
          this.subs$.push(cancel$);
        }
      }
    ).catch(() => {});
  }

  private setTableCfg() {

    this.historiesTableConfig = {
      requiresCacheService: false,
      isMock: false,
      isActive: true,
      tableActions: [
        {
          name: this.translate.instant('components.table_actions.export_csv'),
          icon: 'ei-export',
          id: 'exportCSV'
        }
      ],
      rowActions: [
        {
          name: this.translate.instant('resources.journey_histories.row_actions.details'),
          icon: 'eye',
          id: 'details',
          customRoute: (element: {id: number}) => {
            return ['', { outlets: { modal: `show/customer_journeys/${this.journeyId}/histories/${element.id}/summary` }}];
          },
          show: () => this.journey.type !== 'custom'
        },
        {
          name: this.translate.instant('resources.journey_histories.row_actions.details'),
          icon: 'eye',
          id: 'details',
          customRoute: (element: {id: number}) => {
            return ['', { outlets: { modal: `show/customer_journeys/${this.journeyId}/histories/${element.id}/summary` }}];
          },
          show: () => this.journey.type === 'custom'
        },
        {
          name: this.translate.instant('resources.journey_histories.row_actions.edit'),
          icon: 'pencil',
          id: 'edit',
          callback: (element) => {
            this.pauseHistory( element.id, { outlets: { modal: `update/customer_journeys/${this.journeyId}/histories/${element.id}` } } );
          },
          show: () => this.journey.type !== 'custom' && this.journey.status !== 'done'
        },
        {
          name: this.translate.instant('resources.journey_histories.row_actions.pause'),
          icon: 'pause',
          id: 'pause',
          callback: (element) => this.pauseHistory(element.id),
          show: () => this.journey.type !== 'custom' && this.journey.status !== 'done'
        },
        {
          name: this.translate.instant('resources.journey_histories.row_actions.cancel'),
          icon: 'trash',
          id: 'cancel',
          callback: element => this.cancelHistory(element.id),
          show: () => this.journey.type !== 'custom' && this.journey.status !== 'done'
        }
      ],
      columns: [
        { name: this.translate.instant('resources.journey_histories.columns.scheduled_at'), prop: 'scheduled_at'},
        { name: this.translate.instant('resources.journey_histories.columns.status'), prop: 'status'},
        { name: this.translate.instant('resources.journey_histories.columns.best_sales_tag'), prop: 'best_sales_tag'},
        { name: this.translate.instant('resources.journey_histories.columns.location_term'), prop: 'location_term'},
      ],
      requestData: {
        translationResource: 'resources.journey_histories.columns.',
        apiEndPoint: `campaign_blocks/${this.journeyId}/histories`,
        pageNumber: 0,
        numberPerPage: 10
      },
      rows: [],
      tooltipText: {},
      tableMapping: [
        { prop: 'id', type: 'number', apiProp: 'id'},
        {
          prop: 'scheduled_at',
          type: 'date_time',
          apiProp: 'scheduled_at',
          getValue: (element) => element.sent_at || element.scheduled_at
        },
        { prop: 'status', type: 'badge', apiProp: 'status', badge: {dictionaryKey: 'journey_history_status'} },
        { prop: 'best_sales_tag', type: 'text', apiProp: 'pretty_configuration.best_sales_tag.name' },
        { prop: 'location_term', type: 'text', apiProp: 'pretty_configuration.location_terms' },
      ]
    };
  }
}
