import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { INTEGER_REGEX } from "../../../../constants/condition.constants";

export function setRequiredControlsValidators(ctrl: AbstractControl): void {
  setValidatorsErrorsMark(ctrl, [Validators.required], 'invalidRequired');
}

export function setRequiredIntegerControlValidators(ctrl: AbstractControl): void {
  setValidatorsErrorsMark(ctrl, [Validators.pattern(INTEGER_REGEX)], 'invalidInteger');
}

export function setValidatorsErrorsMark(ctrl: AbstractControl, validators: ValidatorFn[], errorKey: string): void {
  ctrl.setValidators(validators);
  ctrl.setErrors({[errorKey]: true});
  ctrl.markAsDirty();
}

export function checkValue2GreaterValue1Validation(valueCtrl: AbstractControl, value2Ctrl: AbstractControl, value: string | number, value2: string | number): ValidationErrors | null {
  if (parseFloat(value as string) >= parseFloat(value2 as string)) {
    valueCtrl.setErrors({'value2MustBeGreaterThanValue1': true});
    value2Ctrl.setErrors({'value2MustBeGreaterThanValue1': true});
    valueCtrl.markAsDirty();
    value2Ctrl.markAsDirty();
    if (value && value !== 0 || value2 && value2 !== 0) {
      valueCtrl.markAsTouched();
      value2Ctrl.markAsTouched();
    }
    return {['value2MustBeGreaterThanValue1']: true};
  }
}
