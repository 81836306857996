import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CampaignType } from './campaign-type';
import { PlansService } from '../plans.service';
import { ProfileService } from '../../../profiles/profile.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from '../../../shared/services/confirmation.service';

@Component({
  selector: 'app-campaign-selection',
  templateUrl: './campaign-selection.component.html',
  styleUrls: ['./campaign-selection.component.scss']
})
export class CampaignSelectionComponent implements OnInit {

  @Input() slug: string;
  @Output() typeEmitter: EventEmitter<CampaignType>;

  public planCampaignTypes: CampaignType[];
  public purchasedModules: string[];

  constructor(
    private plansService: PlansService,
    private profileService: ProfileService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService
  ) {
    this.typeEmitter = new EventEmitter();
  }

  ngOnInit() {
    this.purchasedModules = this.profileService.getStoredUser().company['purchased_modules'] || [];
    this.processCampaigns();
  }

  selectType(_type: CampaignType) {
    this.typeEmitter.emit(_type);
  }

  showNotAvailable() {
    const text = this.translate.instant('menu_dia.messages.unavailable');
    this.confirmationService.displayAlert('', text, 'warning').catch(() => {});
  }

  isPurchased(moduleSlug: string): boolean {
    if (this.purchasedModules.length === 0) { return true; }
    return this.purchasedModules.includes(moduleSlug);
  }

  private processCampaigns() {
    this.planCampaignTypes = this.plansService.getStaticTypes();
    if (!this.isPurchased('poe:cpg')) {
      this.planCampaignTypes.find(el => el.type === 'Plans::SupplierCampaign').enabled = false;
    }
  }
}
