import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MultiselectDataSource, MultiselectDataSourceable } from '../../../shared/components/multiselect/multiselect';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LocationTaxonomiesService extends ResourcesService implements MultiselectDataSourceable {
  inputs: any[];

  constructor(http: HttpClient, private translate: TranslateService) {
    super(http);
  }

  getAll() {
    const requestOptions = {
      apiEndPoint: '/location_taxonomies',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  getLocationsTaxonomiesById(id: number) {
    return this.getData({ apiEndPoint: `location_taxonomies/${id}` });
  }

  fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'location_taxonomies',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: string): Observable<object> {
    return this.getData({ apiEndPoint: `location_taxonomies?slug=${id}` });
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    return new MultiselectDataSource(element.slug, element.name);
  }

  getOriginOptions() {
    return [
      { id: 'internal', name: this.translate.instant('resources.location_taxonomies.origin.internal')},
      { id: 'external', name: this.translate.instant('resources.location_taxonomies.origin.external')}
    ];
  }

  getInputs(formValues: any): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 1,
        required: true
      }),
      new TextboxQuestion({
        key: 'slug',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 2,
        required: true
      }),
      new MultiSelectQuestion({
        key: 'origin',
        order: 3,
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false},
        options: this.getOriginOptions(),
      })
    ];
    
    Object.keys(formValues).forEach(key => {
      let input = inputsArray.find(input => input.key === key);
      if (input) {
        if (input instanceof MultiSelectQuestion && formValues[key]) {
            input.value = [ input.options.find( opt => opt.id === formValues[key]) ]
          } else {
            input.value = formValues[key]; 
          }
      }
    });

    if (Object.keys(formValues).length) {
      inputsArray.find(input => input.key === 'slug').disabled = true;
    }

    this.inputs = inputsArray;
    return this.inputs;
  }

  sendData(payload: {name: string, slug: string}) {
    return this.postResource(payload, 'location_taxonomies');
  }

  updateData(payload: {name: string, slug: string}, id: number) {
    return this.patchResource(payload, `location_taxonomies/${id}`);
  }

}
