import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { S3Service } from '../../../../shared/services/s3.service';
import { LocationsTaxonomyTermsService } from '../location-taxonomy-terms.service';

@Component({
  selector: 'app-import-location-taxonomy-terms',
  templateUrl: './import-location-taxonomy-terms.component.html',
  styleUrls: ['./import-location-taxonomy-terms.component.css']
})
export class ImportLocationTaxonomyTermsComponent implements OnInit {

  importForm: FormGroup;
  inputs: QuestionBase<any>[];

  private destroy$: Subject<void> = new Subject<void>();
  
  @Output() formValidity: EventEmitter<string> = new EventEmitter<string>() ;

  constructor(
    private qcs: QuestionControlService,
    private confirmationService: ConfirmationService,
    private modalStatusService: ModalStatusService,
    private translate: TranslateService,
    private locationsTaxonomyTermsService: LocationsTaxonomyTermsService,
    private s3Service: S3Service,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  sendData(): void {
    const files = this.importForm.value.csv;
    if (files?.length) {
      const file = files[0];
      this.s3Service.uploadFile(file).then(filePath => {
        this.locationsTaxonomyTermsService.importCsv(filePath).pipe(takeUntil(this.destroy$)).subscribe(
          () => {
            const import_title = this.translate.instant(`resources.location_taxonomy_terms.warnings.import_title`);
            const import_text = this.translate.instant(`resources.location_taxonomy_terms.warnings.import_text`);
            this.confirmationService.displaySuccessAlert(import_title, import_text).catch(() => {});
            this.modalStatusService.modalStatus.emit();
            this.closeModal();
        }, 
          (errorData: HttpErrorResponse) => {
          this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error);
          this.closeModal();
        });
      });
    }
  }

  private getParams(): void {
    this.inputs = this.locationsTaxonomyTermsService.getImportInputs();
    this.importForm = this.qcs.toFormGroup(this.inputs);
    this.formStatusChanges();
  }
  
  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.importForm.status));
    this.importForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
  }

  private closeModal(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }
}
