import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../shared/components/multiselect/multiselect';
import { Observable } from 'rxjs';

@Injectable()

/*
* This segment class service is used for nested segment condition.
* - It must filter from list the current segment id, if this is defined in initial configuration.  
* - The goal is to avoid selection of the same segment that it's being editted.
*/
export class SystemSegmentsService extends ResourcesService implements MultiselectDataSourceable {

  constructor( http: HttpClient ) {
    super(http);
  }

  public fetchMultiselect( searchValues?: string, page?: number, filters?: object ) {
    let requestOptions = {
      apiEndPoint: 'segments',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {
        order_by: 'segment_category_id'
      },
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if ( searchValues ) {
      const filterObj = { filtering: { name: searchValues, id: searchValues, filter_logic: 'or', order_by: 'segment_category_id' } };
      requestOptions = { ...requestOptions, ...filterObj };
    }

    /* Overwrite filter logic to also be able to filter by search term (name OR id) AND segment category */
    if (filters) {
      const filtering = Object.assign({}, {search_text: searchValues, filter_logic: 'and', order_by: 'segment_category_id'}, filters);
      const filterObj = { filtering: filtering};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  public fetchSelectedById(id: number): Observable<object> {
    return this.getSegmentById(id);
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`, element);
  }

  public getSegmentById(id: number) {
    return this.getData({ apiEndPoint: `segments/${id}` });
  }

  public createSystemSegments(): Observable<any> {
    return this.postResource({}, `segments/create_system_segments`);
  }

  public deleteSystemSegments(): Observable<any> {
    return this.postResource({}, `segments/delete_system_segments`); 
  }

  public deleteAllPeriods(): Observable<any> {
    return this.deleteResource({}, `periods`); 
  }
}
