<div class="row mrg-btm-15" *ngIf="inputs">

  <div class="col-md-6">
    <app-question [question]="getInputConfig('operator')" [form]="form"></app-question>
  </div>

  <div class="col-md-6">
    <app-question [question]="getInputConfig('name')" [form]="form"></app-question>
  </div>

</div>
