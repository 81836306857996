import { QuestionBase } from './question-base';

export class TextboxQuestion extends QuestionBase<string | number> {
  controlType = 'textbox';
  type: string;
  step: number;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || 'number';
    this.step = options['step'] || '';
    this.value = options['value'] ?? null;
  }
}
