import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { CustomerJourney} from '../../shared/models/customer-journeys/customer-journey';
import { MultiCouponJourney } from '../../shared/models/customer-journeys/multicoupon-journey';
import { CustomJourney } from '../../shared/models/customer-journeys/custom-journey';
import { RecuperationJourney } from '../../shared/models/customer-journeys/recuperation-journey';
import { PotentialJourney } from '../../shared/models/customer-journeys/potential-journey';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CampaignBlockReportDetail } from '../../shared/models/incremental/campaignBlockReportDetail';
import { UntypedFormGroup } from '@angular/forms';
import { CustomerJourneyStep } from '../../shared/models/customer-journeys/customer-journey-step';
import { PotentialJourneyService } from '../../shared/services/potential-journey.service';
import { MulticouponJourneyService } from '../../shared/services/multicoupon-journey.service';
import { RecuperationJourneyService } from '../../shared/services/recuperation-journey.service';
import { MultiselectDataSource, MultiselectDataSourceable } from '../../shared/components/multiselect/multiselect';

@Injectable()
export class CustomerJourneysService extends ResourcesService implements MultiselectDataSourceable {

  datesAreRequired = false;
  date_from: string;
  date_to: string;
  optsSource = new BehaviorSubject<{dateFrom?: string, dateTo?: string}>(null);
  optsSource$ = this.optsSource.asObservable();

  constructor(
    http: HttpClient,
    private translate: TranslateService,
    private resourceService: ResourcesService,
    private potentialService: PotentialJourneyService,
    private multicouponService: MulticouponJourneyService,
    private recuperationService: RecuperationJourneyService
  ){
    super(http);
  }

  getTypes() {
    return [
      {id: 'CampaignBlock::Custom', name: this.translate.instant('resources.journeys.type.custom')},
      {id: 'CampaignBlock::Potencial', name: this.translate.instant('resources.journeys.type.potential')},
      {id: 'CampaignBlock::Recuperation', name: this.translate.instant('resources.journeys.type.recuperation')},
      {id: 'CampaignBlock::MultiCoupon', name: this.translate.instant('resources.journeys.type.multicoupon')}
    ];
  }

  getCreationTypeList() {
    return [
      {
        id: 'potential',
        name: this.translate.instant('resources.journeys.type.potential'),
        desc: this.translate.instant('resources.journeys.type.potential_desc'),
        icon: 'glyphicon glyphicon-screenshot',
        className: 'CampaignBlock::Potencial'
      },
      {
        id: 'recuperation',
        name: this.translate.instant('resources.journeys.type.recuperation'),
        desc: this.translate.instant('resources.journeys.type.recuperation_desc'),
        icon: 'glyphicon glyphicon-new-window',
        className: 'CampaignBlock::Recuperation'
      },
      {
        id: 'multi_coupon',
        name: this.translate.instant('resources.journeys.type.multicoupon'),
        desc: this.translate.instant('resources.journeys.type.multi_coupon_desc'),
        icon: 'glyphicon glyphicon-tags',
        className: 'CampaignBlock::MultiCoupon'
      },
      {
        id: 'custom',
        name: this.translate.instant('resources.journeys.type.custom'),
        desc: this.translate.instant('resources.journeys.type.custom_desc'),
        icon: 'glyphicon glyphicon-user',
        className: 'CampaignBlock::Custom'
      }
    ];
  }

  createCustomerJourney(params): Observable<any> {
    return this.resourceService.postResource(params, 'campaign_blocks');
  }

  getCustomerJourneyById(id: number): Observable<any> {
    return this.getData({ apiEndPoint: `campaign_blocks/${id}` });
  }

  getCustomerJourneyHistory(id: number, historyId: number): Observable<any> {
    return this.getData({ apiEndPoint: `campaign_blocks/${id}/histories/${historyId}` });
  }

  getCustomerJourneyHistoriesByJourneyId(id: number): Observable<any> {
    return this.getData({ apiEndPoint: `campaign_blocks/${id}/histories?paginate=false` });
  }

  /**
   * Return to create/update component the initialized C. Journey type
   * Will make requests in order to populate selectors
   */
  initByType(journeyApiData): CustomerJourney {

    // Store origin type
    journeyApiData.origin_type = journeyApiData.type;
    // Convert type from 'CampaignBlock::MultiCoupon' to 'multicoupon'
    if (journeyApiData.type.indexOf('::') >= 0) {
      journeyApiData.type = String(journeyApiData.type.split('::')[1]).toLowerCase();
    }
    // Remove history from type in order to initialize properly
    journeyApiData.type = journeyApiData.type.replace('history', '');
    // Naming type from 'potencial' to 'potential'
    journeyApiData.type = journeyApiData.type === 'potencial' ? 'potential' : journeyApiData.type;

    switch (journeyApiData.type) {
      case 'custom':
        return new CustomJourney(journeyApiData);
      case 'potential':
        return new PotentialJourney(journeyApiData);
      case 'recuperation':
        return new RecuperationJourney(journeyApiData);
      case 'multicoupon':
        // To be able to select correctly because of translate issue in multiselect value of type: [{id: int, name: string}]
        if ( journeyApiData.configuration.hasOwnProperty('via') &&
             journeyApiData.configuration['via']) {
          const via = journeyApiData.configuration['via'];
          journeyApiData.configuration.via = {
            id: via,
            name: this.translate.instant(`resources.campaigns.channels.${via}`)
          };
        }
        return new MultiCouponJourney(journeyApiData);
    }
  }

  getMultiselectOptionsByEndpoint(requestData) {
    return this.getData(requestData);
  }

  getCouponDeliveryOptions() {
    return [
      {id: 'email', name: this.translate.instant('resources.campaigns.channels.email')},
      {id: 'sms', name: this.translate.instant('resources.campaigns.channels.sms')},
      {id: 'none', name: this.translate.instant('resources.campaigns.channels.none')},
      {id: 'push', name: this.translate.instant('resources.campaigns.channels.push')}
    ];
  }

  saveJourney(journey: any, form: UntypedFormGroup) {
    let journeyToSave;
    if ( Object.keys(form.controls).length ) {
      if (journey.type === 'multicoupon') {
        journeyToSave = this.multicouponService.prepareToSave(journey, form);
      } else if ( journey.type === 'potential' ) {
        journeyToSave = this.potentialService.prepareToSave(journey, form);
      } else if (journey.type === 'recuperation') {
        journeyToSave = this.recuperationService.prepareToSave(journey, form);
      }
    } else {
      journeyToSave = journey.prepareToSave();
    }
    return this.resourceService.patchResource(journeyToSave, `campaign_blocks/${journey.id}` );
  }

  saveJourneyHistory(journeyId: number, historyId: number, journey: any, form: UntypedFormGroup) {
    let journeyHistoryToSave;
    if ( Object.keys(form.controls).length ) {
      if (journey.type === 'multicoupon') {
        journeyHistoryToSave = this.multicouponService.prepareToSave(journey, form);
      } else if (journey.type === 'potential') {
        journeyHistoryToSave = this.potentialService.prepareToSave(journey, form);
      } else if (journey.type === 'recuperation') {
        journeyHistoryToSave = this.recuperationService.prepareToSave(journey, form);
      }
    } else {
      journeyHistoryToSave = journey.prepareHistoryToSave();
    }

    if ( journeyHistoryToSave.hasOwnProperty('steps') ) {
      journeyHistoryToSave['history_steps'] = journeyHistoryToSave['steps'];
      delete journeyHistoryToSave['steps'];
    }

    return this.resourceService.patchResource(journeyHistoryToSave, `campaign_blocks/${journeyId}/histories/${historyId}` );
  }

  saveCustomJourneyWithFormValues(journey: CustomerJourney, formValues: any) {

    let campaignCategoryId = null;
    if (formValues.campaign_category && formValues.campaign_category.length > 0) {
      campaignCategoryId = formValues.campaign_category[0].id;
    }

    let campaigns = this.processCampaignsValues(formValues);
        campaigns = this.filterAddedCampaigns(journey, campaigns);

    let percentageRedemption = formValues.prediction_percentage_redemption;
    if ( percentageRedemption ) {
      percentageRedemption = parseFloat(percentageRedemption);
    }

    const patchPayLoad = {
      name: formValues.name,
      campaign_category_id: campaignCategoryId,
      report_configuration: { reward: { prediction_percentage_redemption: percentageRedemption } },
      reportable: formValues.reportable,
      steps: campaigns
    };

    return this.resourceService.patchResource(patchPayLoad, `campaign_blocks/${journey.id}` );
  }

  deliverJourney(journeyId: number) {
    return this.resourceService.postResource({}, `campaign_blocks/${journeyId}/send` );
  }

  deliverJourneyHistory(journeyId: number, historyId: number) {
    return this.resourceService.postResource({}, `campaign_blocks/${journeyId}/histories/${historyId}/send` );
  }

  deleteJourney(journeyId: number) {
    return this.resourceService.deleteResource({}, `campaign_blocks/${journeyId}` );
  }

  cancelJourneyHistorySchedule(journeyId: number, historyId: number) {
    return this.resourceService.postResource({}, `campaign_blocks/${journeyId}/histories/${historyId}/cancel_schedule`);
  }

  cancelJourneyHistory(journeyId: number, historyId: number) {
    return this.resourceService.postResource({}, `campaign_blocks/${journeyId}/histories/${historyId}/cancel`);
  }

  campaignBlockReport(campaignBlockReportId: number) {
    return this.resourceService.getData({
      apiEndPoint: 'campaign_blocks_report_details?campaign_block_report_id=' + campaignBlockReportId
    });
  }

  campaignBlockReportId(requestData: object) {
    requestData['apiEndPoint'] =  'campaign_blocks_reports';
    return this.resourceService.getData(requestData);
  }

  campaignBlockLocation(year: number, month: number) {
    return this.getData({apiEndPoint: `campaign_block_location_rois/${year}/${month}`});
  }

  updateBlockReportDetails( campaignBlockReportDetails: CampaignBlockReportDetail[], campaignBlockReportId: number ) {
    const objToSend = { id: this.campaignBlockReportId, report_details: campaignBlockReportDetails};
    return this.resourceService.patchResource( objToSend, 'campaign_blocks_reports/' + campaignBlockReportId );
  }

  getStepName(journey: CustomerJourney, step: CustomerJourneyStep) {
    if (journey.type === 'multicoupon' || journey.type === 'potential') {
      return `${journey.name} ${journey.getNameSuffix(step)} ${journey.getNameWithAttributes()}`;
    } else {
      return journey.getStepName(step);
    }
  }

  campaignBlockCostByCategory(year: number, month: number) {
    return this.getData({apiEndPoint: `campaign_block_cost_by_category/${year}/${month}`});
  }

  fetchMultiselect( searchValues?: string, page?: number, filters?: object ) {
    let requestOptions = {
      apiEndPoint: 'campaign_blocks',
      pageNumber: page || 1,
      filtering: {},
      sorting: {}
    };

    if (searchValues) {
      requestOptions.filtering = { name: searchValues };
    }

    if (filters) {
      const mergedFilters = { ...filters };
      requestOptions.filtering = { ...requestOptions.filtering, ...mergedFilters };
    }

    if (this.date_from && this.date_to) {
      const {date_from, date_to} = this;
      if (date_from && date_to) {
        requestOptions.filtering = { ...requestOptions.filtering, ...{date_from: date_from, date_to: date_to}};
      } else {
        return of({});
      }
    }

    return this.getData(requestOptions);
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getCustomerJourneyById(id);
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`, element);
  }

  setDatesRequired() {
    this.datesAreRequired = true;
  }

  setDates(dateFrom: string, dateTo: string) {
    this.date_from = dateFrom;
    this.date_to = dateTo;
    this.optsSource.next({dateFrom, dateTo});
  }

  private processCampaignsValues(formValues: any): any {
    return Object.keys(formValues)
                 .filter(key => key.indexOf('step.campaign_') >= 0 )
                 .filter(filteredKey => (formValues[filteredKey] && formValues[filteredKey].length > 0))
                 .map(filteredKey => {
                    const campaignObj = { campaign_id: formValues[filteredKey][0].id };
                    if ( formValues[filteredKey].hasOwnProperty('id') && formValues[filteredKey].id ) {
                      campaignObj['id'] = formValues[filteredKey]['id'];
                    }
                    if ( formValues[filteredKey].hasOwnProperty('_destroy') && formValues[filteredKey]._destroy ) {
                      campaignObj['_destroy'] = true;
                    }
                    return campaignObj;
                 });
  }

  /* Prevent already existing campaigns to be resent to c. journey patch */
  private filterAddedCampaigns(journey: CustomerJourney, campaigns: any[]) {
    const existingIds = journey.steps.map( step => step.campaign_id );
    const filteredCampaigns = [];
    campaigns.forEach( campaign => {
      if ( !existingIds.includes(campaign.campaign_id) || campaign.hasOwnProperty('_destroy') ) {
        filteredCampaigns.push(campaign);
      }
    });
    return filteredCampaigns;
  }
}
