<ng-container *ngIf="childForm">
  <!-- Row checkbox -->
  <td class="align-bottom no-border-right" *ngIf="!item.loading && item.errors.length === 0">
    <app-question *ngIf="!item.loading && item.errors.length === 0"
                  [question]="inputs[0]"
                  [form]="childForm"></app-question>
  </td>
  <!-- Row warning with errors on icon hover -->
  <td class="align-middle" *ngIf="!item.loading && item.errors.length > 0">
    <span><i class="fa fa-exclamation-circle text-danger"
             tooltipPosition="right"
             pTooltip="{{'resources.campaign_plans.errors.audience_generic_error' | translate}}"></i></span>
  </td>
  <td *ngIf="item.loading">
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  </td>
  <td class="align-middle text-left">
    <a class="cursor-pointer" (click)="openSegment()"><b><u>{{item.name}}</u></b></a>
  </td>
  <td class="align-middle text-left" *ngIf="this.flags.showPlanCampaignAudienceChannel">
    <a class="cursor-pointer" (click)="openCampaignEdition()"><b><u>
      {{'resources.campaigns.channels.' + item.channel | translate}}
    </u></b></a>
  </td>
  <td class="align-middle text-right">
    <span class="label font-size-12 label-default" *ngIf="!item.loading">{{item.audience.totalCustomers | number: '1.0-0'}}</span>
  </td>
  <td class="align-middle text-right">
    <span class="label font-size-12 label-default" *ngIf="!item.loading">{{item.audience.averageExpenditure | lgcurrency: '1.2-2'}}</span>
  </td>
  <td class="align-middle text-right">
    <span class="label font-size-12 label-default" *ngIf="!item.loading">{{item.audience.averageTicket | lgcurrency: '1.2-2'}}</span>
  </td>
  <td class="align-middle text-right">
    <span class="label font-size-12 label-default" *ngIf="!item.loading">{{item.audience.units | number: '1.2-2'}}</span>
  </td>
  <td class="align-middle text-right">
    <span class="label font-size-12 label-default" *ngIf="!item.loading">{{item.audience.frequency | number: '1.2-2'}}</span>
  </td>
  <td class="align-middle text-right">
    <span class="label font-size-12 label-default" *ngIf="!item.loading">{{item.audience.pvp | lgcurrency: '1.2-2'}}</span>
  </td>
  <td class="align-middle text-right">
    <span class="label font-size-12 label-default" *ngIf="!item.loading">{{item.audience.affectationSales | lgcurrency: '1.2-2'}}</span>
  </td>
  <td class="align-middle text-right">
    <span class="label font-size-12 label-default" *ngIf="!item.loading">{{item.audience.seasonality | number: '1.2-2'}}</span>
  </td>
  <td class="align-middle text-right">
    <span class="label font-size-12 label-default" *ngIf="!item.loading">{{item.audience.controlGroup | number: '1.0-0'}}</span>
  </td>
  <td class="align-middle text-right">
    <span class="label font-size-12 label-primary" *ngIf="!item.loading">{{item.audience.minMoney | number: '1.0-0' }}</span>
  </td>
  <td class="align-middle text-right">
    <span class="label font-size-12 label-primary" *ngIf="!item.loading">{{item.audience.minUnits | number: '1.0-0' }}</span>
  </td>

  <!-- estimatedCustomerRedemption -->
  <td class="align-middle text-right" [hidden]="inputsVisibility.estimatedCustomerRedemption">
    <span *ngIf="!item.loading" class="label font-size-12 label-primary" (click)="toggleInputDisplay('estimatedCustomerRedemption')">
      <u>{{ item.estimatedCustomerRedemption | number: '1.2-2' }}</u>
    </span>
  </td>
  <td class="align-middle input-width pdd-top-20" [hidden]="!inputsVisibility.estimatedCustomerRedemption">
    <app-question [question]="inputs[1]" [form]="childForm"></app-question>
  </td>
  <!-- -->

  <!-- estimatedRedemption -->
  <td class="align-middle text-right" [hidden]="inputsVisibility.estimatedRedemption">
    <span *ngIf="!item.loading" class="label font-size-12 label-primary" (click)="toggleInputDisplay('estimatedRedemption')">
      <u>{{ item.estimatedRedemption | number: '1.2-2' }}</u>
    </span>
  </td>
  <td class="align-middle input-width pdd-top-20" [hidden]="!inputsVisibility.estimatedRedemption">
    <app-question [question]="inputs[2]" [form]="childForm"></app-question>
  </td>
  <!-- -->

  <!-- estimatedDelivery -->
  <td class="align-middle text-right" [hidden]="inputsVisibility.estimatedDelivery">
    <span *ngIf="!item.loading" class="label font-size-12 label-primary" (click)="toggleInputDisplay('estimatedDelivery')">
      <u>{{ item.estimatedDelivery | number: '1.2-2' }}</u>
    </span>
  </td>
  <td class="align-middle input-width pdd-top-20" [hidden]="!inputsVisibility.estimatedDelivery">
    <app-question [question]="inputs[3]" [form]="childForm"></app-question>
  </td>
  <!-- -->

  <td class="align-middle input-width pdd-top-20 no-border-right">
    <app-question [question]="getInputConfig('coupon_id')"
                  (multiselectChanged)="handleCouponSelected($event)"
                  (selectedLoadedEmitter)="handlePreSelectedCoupon($event)"
                  [form]="childForm"
                  [disabled]="item.errors.length > 0"></app-question>
  </td>
  <td class="align-middle pdd-top-15 no-mrg-left no-pdd-left text-center">
    <div class="btn-group">
      <button class="btn btn-default btn-sm"
              (click)="handleShowEdit()"
              [disabled]="!isCouponSelected">
        <i class="fas fa-pencil"></i>
      </button>
      <button class="btn btn-default btn-sm"
              (click)="handleShowCoupon()"
              [disabled]="!isCouponSelected">
        <i class="far fa-eye"></i>
      </button>
      <button class="btn btn-default btn-sm"
              (click)="handleCreateCoupon()">
        <i class="fal fa-plus"></i>
      </button>
      <button class="btn btn-default btn-sm"
              (click)="handleRecommendCoupon()">
        <i class="fal fa-lightbulb-on"></i>
      </button>
    </div>
  </td>
</ng-container>
