import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourcesService } from '../../../shared/services/resources.service';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../../shared/components/multiselect/multiselect';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { LocationsService } from '../../data-warehouse/locations/locations.service';
import { MessageCategoriesService } from './message-categories.service';

@Injectable()
export class MessagesService extends ResourcesService implements MultiselectDataSourceable {
  inputs: (TextboxQuestion | MultiSelectQuestion)[];

  constructor(http: HttpClient,
              private locations: LocationsService,
              private messageCategories: MessageCategoriesService) {
    super(http);
  }

  public fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'messages',
      numberPerPage: 10,
      filtering: {},
      pageNumber: 1,
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  public fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `messages?id=${id}&order_field=id&order_sense=asc` });
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`);
  }

  public getMessagesById(id: number): Observable<any> {
    return this.getData({apiEndPoint: `messages/${id}`});
  }

  public getInputs(formValues: any): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 1
      }),
      new TextboxQuestion({
        key: 'description',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 2
      }),
      new TextboxQuestion({
        key: 'priority',
        type: 'number',
        cssClasses: 'form-control input-default',
        order: 3
      }),
      new TextboxQuestion({
        key: 'available_days',
        type: 'number',
        cssClasses: 'form-control input-default',
        order: 4
      }),
      new TextboxQuestion({
        key: 'available_from',
        type: 'date',
        cssClasses: 'form-control input-default',
        order: 5
      }),
      new TextboxQuestion({
        key: 'available_to',
        type: 'date',
        cssClasses: 'form-control input-default',
        order: 6
      }),
      new MultiSelectQuestion({
        key: 'message_category_id',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.messageCategories,
        order: 7
      }),
      new MultiSelectQuestion({
        key: 'location_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locations,
        order: 8
      })
    ];

    Object.keys(formValues).map(
      key => {
        if (key === 'name') {
          inputsArray.find( input => input.key === 'name' ).value = formValues[key];
        } else if (key === 'description') {
          inputsArray.find( input => input.key === 'description' ).value = formValues[key];
        } else if (key === 'priority') {
          inputsArray.find( input => input.key === 'priority' ).value = formValues[key];
        } else if (key === 'available_days') {
          inputsArray.find( input => input.key === 'available_days' ).value = formValues[key];
        } else if (key === 'available_from') {
          inputsArray.find( input => input.key === 'available_from' ).value = formValues[key];
        } else if (key === 'available_to') {
          inputsArray.find( input => input.key === 'available_to' ).value = formValues[key];
        } else if (key === 'message_category') {
          const messageCategory = formValues[key] ? [formValues[key]] : [];
          inputsArray.find( input => input.key === 'message_category_id' ).selectedIds = messageCategory;
        } else if (key === 'locations') {
          inputsArray.find( input => input.key === 'location_ids' ).selectedIds = formValues[key].map(item => item.id);
        }
      }
    );

    if (formValues['available_days'] && formValues['available_days'] > 0) {
      inputsArray.find(input => input.key === 'available_from').disabled = true;
      inputsArray.find(input => input.key === 'available_to').disabled = true;
    }

    this.inputs = inputsArray;
    return this.inputs;
  }

  public sendData(data) {
    return this.postResource(data, 'messages');
  }

  public updateData(data: any, _id: number) {
    return this.patchResource(data, `messages/${_id}`);
  }
}
