import { Component, OnInit } from '@angular/core';
import { SegmentsService } from '../../../resources/segments/segments.service';
import { Router } from '@angular/router';
import { ProfileService } from '../../../profiles/profile.service';
import { LegendService } from './legend.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-analytics-legend',
  templateUrl: './analytics-legend.component.html',
  styleUrls: ['./analytics-legend.component.scss'],
  providers: [ LegendService ]
})

export class AnalyticsLegendComponent implements OnInit {

  public activitySegment: Object;
  public segmentSales: Object;
  public userData: Object;
  public activity: number;
  public sales: number;
  public companyData;
  public companyId: any;
  public periods: Object;
  public legendEvaluation: any;

  constructor(private router: Router,
              private currentUserProfile: ProfileService,
              private legendService: LegendService,
              private translate: TranslateService,
              private segmentService: SegmentsService) { }

  ngOnInit() {
    this.getUserData();
  }

  getUserData() {
    this.currentUserProfile.getRawUserProfile().subscribe((reqResponse) => {
      this.userData = reqResponse;
      this.companyId = reqResponse['company_id'];
      this.activity = this.userData['company'].system_segment_categories.activity;
      this.sales = this.userData['company'].system_segment_categories.sales;

      this.getCompanyData();
      this.getActivitySegment();
      this.getSalesSegments();

      switch ( this.userData['company'].evaluation_period ) {
        case 'monthly': this.legendEvaluation = this.translate.instant('resources.legend.fields.periods.fields.evaluation.monthly');
        break;
        case 'quarterly': this.legendEvaluation = this.translate.instant('resources.legend.fields.periods.fields.evaluation.quarterly');
        break;
        case 'biannually': this.legendEvaluation = this.translate.instant('resources.legend.fields.periods.fields.evaluation.biannually');
        break;
      }
    });
  }

  getCompanyData() {
    this.legendService.getPeriodsByCompany(this.companyId).subscribe(
      response => {
        const period_dates = response;
        this.periods = period_dates;
      }
    );
  }

  getActivitySegment() {
    this.segmentService.getSegments({
      filtering: {'segment_category_id': this.activity},
      sorting: {
        byProp: 'sort',
        direction: 'asc'
      }
      }).subscribe(
      resp => {
        this.activitySegment = resp;
      }
    );
  }

  getSalesSegments() {
    this.segmentService.getSegments({
      filtering: {'segment_category_id': this.sales},
      sorting: {
        byProp: 'sort',
        direction: 'asc'
      }
      }).subscribe(
      resp => {
        this.segmentSales = resp;
      }
    );
  }

  public closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

}
