<td class="align-middle text-center">
  <span *ngIf="!item.loading && item.errors.length > 0">
    <i class="fa fa-exclamation-circle text-danger"
       tooltipPosition="right"
       pTooltip="{{'resources.campaign_plans.errors.forecast_generic_error' | translate}}"></i>
  </span>
  <div class="lds-ellipsis" *ngIf="item.loading && item.errors.length === 0"><div></div><div></div><div></div><div></div></div>
  <span class="" *ngIf="!item.loading">
    <a class="cursor-pointer" (click)="openSegment()"><b><u>{{item.name}}</u></b></a>
  </span>
</td>
<td class="align-middle text-center">
  <span>{{'resources.campaigns.channels.' + item.channel | translate}}</span>
</td>
<td class="align-middle text-right">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.customers | number: '1.0-0'}}</span>
</td>
<td class="align-middle text-right">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.maxRedemptionsPerCoupon | number: '1.0-0'}}</span>
</td>
<td class="align-middle text-right">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.delivered | number: '1.0-0'}}</span>
</td>
<td class="align-middle text-right" *ngIf="flags.showPlanDetailCreditBalance">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.deliveryCost | lgcurrency: '1.2-2'}}</span>
</td>
<td class="align-middle text-right">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.estimatedDelivery | number: '1.2-2'}}%</span>
</td>
<td class="align-middle text-right">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.totalRedemptions | number: '1.0-0'}}</span>
</td>
<td class="align-middle text-right">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.estimatedRedemption | number: '1.2-2'}}%</span>
  <span class="label label-info" *ngIf="!item.loading && item.forecast.eurecatEstimatedRedemption && !hideEurecatRedemption">
    ({{item.forecast.eurecatEstimatedRedemption | number: '1.2-2'}}%)
  </span>
  <i *ngIf="!item.loading && item.forecast.eurecatEstimatedRedemptionErr && !hideEurecatRedemption"
     class="fa fa-warning text-danger text-bold"
     pTooltip="{{item.forecast.eurecatEstimatedRedemptionErr}}"></i>
</td>
<td class="align-middle text-right">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.redemptionsPerCustomer | number: '1.2-2'}}</span>
</td>
<td class="align-middle text-right">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.uniqueRedemptions | number: '1.0-0'}}</span>
</td>
<td class="align-middle text-right">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.discount | number: '1.2-2'}}<span *ngIf="item.forecast.discountType === 'cash'">{{currencySymbol}}</span><span *ngIf="item.forecast.discountType === 'percent'">%</span></span>
</td>
<td class="align-middle text-right">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.minPurchase | number: '1.2-2'}}<span *ngIf="item.forecast.minPurchaseType === 'money'">{{currencySymbol}}</span><span *ngIf="item.forecast.minPurchaseType === 'units'">u</span></span>
</td>
<td class="align-middle text-right">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.discountOverPurchase | percent: '1.2-2'}}</span>
</td>
<td class="align-middle text-right">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.budgetExpense | lgcurrency: '1.2-2'}}</span>
</td>
<td class="align-middle text-right" [hidden]="planCampaign.type !== 'Plans::SupplierCampaign'">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.lgCost | lgcurrency: '1.2-2'}}</span>
</td>
<td class="align-middle text-right">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.incremental | lgcurrency: '1.2-2'}}</span>
</td>
<td class="align-middle text-right">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.incrementalRatio | number: '1.2-2'}}</span>
</td>
<td class="align-middle text-right">
  <span class="label label-default" *ngIf="!item.loading">{{item.forecast.incrementalOverSales | number: '1.2-2'}}%</span>
</td>
