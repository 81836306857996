import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../../shared/services/question-control.service';

@Component({
  selector: 'app-calculation-section',
  templateUrl: './calculation-section.component.html',
  styleUrls: ['./calculation-section.component.scss']
})
export class CalculationSectionComponent {

  get dataPeriodTypeInput(): QuestionBase<unknown> {
    return this.getInputConfig('data_period_type');
  }

  get recurrenceInput(): QuestionBase<unknown> {
    return this.getInputConfig('recurrence');
  }

  @Input() customerAttributesForm: UntypedFormGroup;
  @Input() inputs: QuestionBase<unknown>[];

  constructor(
    private qcs: QuestionControlService
  ) {}

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: unknown): boolean {
    return this.qcs.hasFormKeyWithValue(this.customerAttributesForm, formKey, value);
  }

  /**
   * To mark with scss the radio inputs that have extra required fields
   */
  isRadioInputRequiredField(radioKey: string, radioValue: string, fieldKeyRequired: string): boolean {
    return !!(this.hasFormKeyWithValue(radioKey, radioValue) && this.customerAttributesForm.get(fieldKeyRequired).invalid);
  }

  isMultiselectRequired(keyField: string): boolean {
    return !!this.customerAttributesForm.get(keyField)?.errors?.required;
  }
}
