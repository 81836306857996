<div class="container-fluid mrg-top-15">
  <div class="page-title">
    <div class="row">
      <div class="col-md-9">
        <h4 class="mrg-top-10" [translate]="'resources.campaigns_landings.title'"></h4>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <app-loader *ngIf="loadingTemplate" />
        <div class="card-block">
          <div class="mrg-top-15">
            <div class="form-group row">
              <label for="form-1-1"
                     class="col-md-2 control-label text-semibold text-dark">
                {{'resources.campaigns_landings.fields.landing_name' | translate}}
                <span class="text-danger">*</span>
              </label>
              <input type="text"
                     [(ngModel)]="landingName"
                     name="landingName"
                     class="form-control mrg-horizon-15"
                     id="form-1-1">
            </div>
          </div>
          <button class="btn btn-sm btn-info"
                  *ngIf="hasSchema"
                  (click)="goToEditModal()"
                  [translate]="'resources.campaigns_landings.buttons.edit'"></button>
          <p class="text-dark text-semibold" *ngIf="!hasSchema">
            {{'resources.campaigns_landings.fields.start_with' | translate}}:
          </p>
          <div *ngIf="!hasSchema">
            <div class="row" *ngIf="landingsData">
              <div class="col-md-4" *ngFor="let item of landingsData.list">
                <div class="card" *ngIf="!hasSchema">
                  <div class="card-block text-center padding-15">
                    <h4 class="mrg-btm-20 no-mrg-top text-center font-size-16">{{item.name}}</h4>
                    <div class="dropdown inline-block">
                      <button class="btn btn-info"
                              [translate]="'resources.campaigns_landings.modal.buttons.select'"
                              (click)="handleTemplateSelected(item.id)"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button *ngIf="displaySaveBtn"
                  class="btn btn-sm btn-secondary"
                  [translate]="'resources.campaigns_landings.buttons.save'"
                  (click)="sendCampaignData()"></button>
        </div>
      </div>
    </div>
  </div>
</div>
