<div class="modal-backdrop fade in" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h4 class="mrg-left-15 mrg-btm-15" [translate]="'resources.campaigns.modal.clone_campaigns'"></h4>
        <button class="modal-close" [attr.data-cy]="'button_closeCloneCampaign'" (click)="closeModal()">
          <i class="ti-close"></i>
        </button>
      </div>

      <div class="modal-body background-gray">
        <div class="row">
          <div class="col-md-12">
            <div class="container-fluid">
              <div class="row">
                <div class="mrg-top-10 col-md-12">
                  <div class="card-block no-pdd">
                    <div class="row">
                      <div class="col-md-8 ml-auto mr-auto">

                        <form [formGroup]="cloneCampaignForm" *ngIf="cloneCampaignForm" >
                          <div class="row">
                            <div class="col-md-12">

                              <div class="form-group">
                                <label for="name" [translate]="'resources.campaigns.modal.fields.name'"></label>
                                <app-question [question]="getInputConfig('name')" [form]="cloneCampaignForm"></app-question>
                              </div>

                              <div class="form-group">
                                <label for="plan" [translate]="'resources.campaigns.modal.fields.clone_to_plan'"></label>
                                <app-question [question]="getInputConfig('plan')" [form]="cloneCampaignForm"></app-question>
                              </div>

                            </div>
                          </div>
                        </form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer background-white">
        <span>
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        </span>
        <div class="pull-right">
          <button
            class="pull-right btn btn-info"
            [attr.data-cy]="'button_CloneCampaignConfirm'"
            [translate]="'common.buttons.clone'"
            [disabled]="!name?.value || !plan?.value || loading"
            (click)="cloneCampaign()"
          ></button>
          <button
            class="pull-right btn btn-secondary"
            [attr.data-cy]="'button_closeCloneCampaign'"
            (click)="closeModal()"
            [translate]="'common.buttons.cancel'"
          ></button>
        </div>
      </div>

    </div>

  </div>
</div>
