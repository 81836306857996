import { AbstractControl, UntypedFormGroup, ValidatorFn } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationService } from "../../../../../shared/services/confirmation.service";

export const getValidations = (form: UntypedFormGroup): ValidatorFn[] => {

  const setErrorAndReturn = (control: AbstractControl, errorHash: object): null => {
    control.markAsDirty();
    control.setErrors(errorHash);
    return null;
  };

  const setError = (control: AbstractControl, errorHash: object): object => {
    control.setErrors(errorHash);
    return errorHash;
  }

  const isMinUnitsCouponType = (): boolean => {
    const couponType = form.get('coupon_type').value;
    return couponType?.[0]?.id === 'min_units';
  };

  const handleValidation = (controlName: string, condition: () => boolean): null => {
    const control = form.get(controlName);
    if (condition() && !control.value) {
      return setErrorAndReturn(control, { invalid: true });
    }
    form.setErrors(null);
    return null;
  };

  const handlePointsValidation = (controlName: string): null => {
    const isRewardPoints = form.get('reward_points').value;
    const control = form.get(controlName);
    if (!isRewardPoints) {
      setError(control, null);
    } else if (!control.value) {
      return setErrorAndReturn(control, { invalid: true });
    }
    form.setErrors(null);
    return null;
  };

  const handleNoCouponTypeValidation = (): null => {
    const type = form.get('coupon_type');
    if (!type.value) {
      ['discount', 'min_units'].forEach((controlName) => {
        form.controls[controlName].markAsPristine();
        form.controls[controlName].setErrors(null);
      });
      form.setErrors(null);
    }
    return null;
  };

  const handlePercentDiscountValidation = (): null => {
    const discountType = form.get('discount_type')?.value?.[0]?.id;
    const discount = form.get('discount');
    if (discountType === 'percent') {
      if (discount.value < 0 || discount.value > 100) {
        return setErrorAndReturn(discount, { invalid: true });
      }
      form.setErrors(null);
    }
    return null;
  };

  return [
    (): null => handleValidation('discount', isMinUnitsCouponType),
    (): null => handleValidation('min_units', isMinUnitsCouponType),
    (): null => handleValidation('points', () => form.get('reward_points').value),
    (): null => handleValidation('points_money', () => form.get('reward_points').value),
    (): null => handlePointsValidation('points'),
    (): null => handlePointsValidation('points_money'),
    handleNoCouponTypeValidation,
    handlePercentDiscountValidation,
  ];
};