<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-10">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form [formGroup]="walletsForm" *ngIf="walletsForm">

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name" [translate]="'resources.scores.wallets.form.name'"></label>
                      <app-question [question]="getInputConfig('name')" [form]="walletsForm"></app-question>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="points" [translate]="'resources.scores.wallets.form.points'"></label>
                      <app-question [question]="getInputConfig('points')" [form]="walletsForm"></app-question>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="reward_id" [translate]="'resources.scores.wallets.form.reward_id'"></label>
                      <app-question [question]="getInputConfig('reward_id')" [form]="walletsForm" [filters]="{ status: 'active', is_coupon: true }"></app-question>
                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
