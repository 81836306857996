import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MultiselectDataSource } from '../../../../../shared/components/multiselect/multiselect';
import { ResourcesService } from '../../../../../shared/services/resources.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerAttributesService extends ResourcesService {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  fetchMultiselect( searchValues?: string, page?: number, filters?: object ) {
    let requestOptions = {
      apiEndPoint: 'customer_attribute_definitions',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {},
      sorting: {},
    };

    requestOptions.pageNumber = page ?? requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    if (filters) {
      const filtering = Object.assign({}, requestOptions.filtering, filters);
      const filterObj = { filtering: filtering };
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `customer_attribute_definitions/${id}` });
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    return new MultiselectDataSource(element.id, element.name, element);
  }

  getCustomerAttributesById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `customer_attribute_definitions/${id}` });
  }

  runCustomerAttribute(id:number) {
    return this.postResource({}, `customer_attribute_definitions/${id}/run`);
  }

  createCustomerAttribute(payload: object) {
    return this.postResource(payload, 'customer_attribute_definitions');
  }

  updateCustomerAttribute(payload: object, id: number) {
    return this.patchResource(payload, `customer_attribute_definitions/${id}`);
  }

  deleteCustomerAttribute(id: number) {
    return this.deleteResource({}, `customer_attribute_definitions/${id}`);
  }
}
