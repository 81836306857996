<a #modalToggleBtn
   data-toggle="modal"
   data-target="#email-template-view-modal"
   data-backdrop="false"
   hidden>
</a>

<div class="modal fade show" id="email-template-view-modal" [attr.data-backdrop]="mode !== 'reminder_plan' && mode !== 'received_reminder'">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header border bottom">
        <h4 class="mrg-btm-15 mrg-top-8">{{ 'resources.campaign_templates.email_title_view' | translate }}</h4>
        <button class="modal-close" [attr.data-cy]="'button_closeSmsModal'" data-dismiss="modal" (click)="close()">
          <em class="ti-close"></em>
        </button>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="hasHtml && srcdoc">
          <iframe title="iframe" [attr.srcDoc]="srcdoc"></iframe>
        </div>
        <div class="row" *ngIf="!hasHtml">
          <div class="col-12">
            <p [translate]="'resources.campaigns.messages.email_template_empty'"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
