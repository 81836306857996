<h4 *ngIf="mode === 'edit'">
  {{'resources.jobs.titles.edit_step' | translate}}: {{currentStep.step.sort}}
</h4>
<h4 *ngIf="mode === 'new'">
  {{'resources.jobs.titles.new_step' | translate}}
</h4>

<div class="row">
  <div class="col-md-3">
    <div class="form-group">
      <app-question [question]="getInputConfig('category')" [form]="stepsForm"></app-question>
    </div>
  </div>

  <div class="col-md-3" *ngIf="hasFormKeyWithValue('category', 'custom')">
    <div class="form-group">
      <app-question [question]="getInputConfig('custom')" [form]="stepsForm"></app-question>
    </div>
  </div>

  <div class="col-md-3" *ngIf="hasFormKeyWithValue('category', 'segment')">
    <div class="form-group">
      <app-question [question]="getInputConfig('segment')" [form]="stepsForm"></app-question>
    </div>
  </div>

  <div class="col-md-3" *ngIf="hasFormKeyWithValue('category', 'segment_category')">
    <div class="form-group">
      <app-question [question]="getInputConfig('segment_category')" [form]="stepsForm"></app-question>
    </div>
  </div>

  <div class="col-md-3" *ngIf="hasFormKeyWithValue('category', 'pump')">
    <div class="form-group">
      <app-question [question]="getInputConfig('pump')" [form]="stepsForm"></app-question>
    </div>
  </div>

  <div class="col-md-3" *ngIf="hasFormKeyWithValue('category', 'pump')">
    <div class="form-group">
      <app-question [question]="getInputConfig('pump_cut_condition')" [form]="stepsForm"></app-question>
    </div>
  </div>

  <div class="col-md-3" *ngIf="hasFormKeyWithValue('category', 'grant_scores')">
    <div class="form-group">
      <app-question [question]="getInputConfig('loyalty_score')"
        [form]="stepsForm"
        [filters]="{active: true, not_when: 'after_create_activity', date_from: todayDate, date_to: todayDate }"></app-question>
    </div>
  </div>

  <div class="col-md-3" *ngIf="hasFormKeyWithValue('category', 'grant_scores')">
    <div class="form-group">
      <app-question [question]="getInputConfig('segment')" [form]="stepsForm" (multiselectChanged)="segmentSelectorChange($event)"></app-question>
    </div>
  </div>

  <div class="col-md-3" *ngIf="hasFormKeyWithValue('category', 'grant_scores')">
    <div class="form-group">
      <app-question [question]="getInputConfig('comments')" [form]="stepsForm"></app-question>
    </div>
  </div>

  <div class="col-md-3" *ngIf="hasFormKeyWithValue('category', 'dump')">
    <div class="form-group">
      <app-question [question]="getInputConfig('dump')" [form]="stepsForm"></app-question>
    </div>
  </div>
</div>

<div class="row" *ngIf="hasFormKeyWithValue('category', 'grant_scores')">
  <div class="col-md-12">
    <!-- REPORT MESSAGES -->
    <div class="alert alert-info" role="alert" *ngIf="segmentSelected && !segmentReport">
      <app-loader styleClass="mrg-btm-15" noWrapper *ngIf="disableAddStepBtn"/>
      <span class="center-content" *ngIf="disableAddStepBtn">{{'resources.scores.loyalty_scores.warnings.calculating' | translate}}</span>
    </div>

    <div class="alert alert-info" role="alert" *ngIf="segmentReport">
      <span [innerHTML]="'resources.scores.loyalty_scores.warnings.assigning_points' | translate:{
            numberOfCustomers: segmentReport?.['count'],
            policyId: stepsForm?.value?.loyalty_score?.[0]?.id,
            policyName: stepsForm?.value?.loyalty_score?.[0]?.name}">
      </span>><br>
      <button type="button"
              class="btn btn-default btn-icon mrg-top-15"
              (click)="openSegment()">
        <i class="ei-show"></i>
        <span [translate]="'resources.scores.loyalty_scores.form.buttons.show_segment'"></span>
      </button>
    </div>
    <!-- ############### -->
  </div>
</div>

<button class="btn btn-info mrg-top-25 pull-right no-mrg-right no-mrg-btm"
        (click)="saveStep()"
        [disabled]="stepsForm.invalid || disableAddStepBtn">
  <span *ngIf="mode === 'edit'">{{'resources.jobs.buttons.save_step' | translate}}</span>
  <span *ngIf="mode === 'new'">{{'resources.jobs.buttons.add_step' | translate}}</span>
</button>

<button class="btn btn-default mrg-top-25 pull-right no-mrg-btm" (click)="resetForm()">
  <span>{{'common.buttons.cancel' | translate}}</span>
</button>
