import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IFormItem, IFormSection } from '../../models/coupon-form.model';

@Component({
  selector: 'app-coupon-form-section-rows',
  templateUrl: './coupon-form-section-rows.component.html'
})
export class CouponFormSectionRowsComponent {
  @Input() currency: string;
  @Input() form: FormGroup;
  @Input() inputConfig: Function;
  @Input() section: IFormSection;

  @Output() onFileChange = new EventEmitter<Event>();
  @Output() onMultiselectChange = new EventEmitter<any>();

  translateBase = 'resources.coupons.form.';

  handleFileChange(event: Event, item: IFormItem) {
    if (item.key === 'image') {
      this.onFileChange.emit(event);
    }
  }

  handleMultiselectChange(item: IFormItem, event: Event) {
    const { key, selectChange } = item;
    if (selectChange) {
      this.onMultiselectChange.emit({ key, event });
    }
  }
}
