<div class="page-title background-white">
  <div class="row pdd-horizon-15">
    <div class="col-md-9 d-flex align-items-center min-height-60px">
      <h4 class="no-mrg-vertical" [translate]="'resources.cashback_threshold.loyalty.title'"></h4>
    </div>
  </div>
</div>

<div class="mrg-top-15 container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="position-relative">
        <app-tab-cashback></app-tab-cashback>
      </div>
    </div>    
  </div>
</div>
