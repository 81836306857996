import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MultiselectDataSource, MultiselectDataSourceable } from '../../../shared/components/multiselect/multiselect';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LocationsService extends ResourcesService implements MultiselectDataSourceable {

  inputs: QuestionBase<any>[];
  countriesIds: string[] = [];
  locationTaxonomyTermIds: string = null;
  optsSource = new BehaviorSubject<string[] | string>(null);
  optsSource$ = this.optsSource.asObservable();
  selectedOptsSource = new BehaviorSubject<{selectedIds: string[], key: string}>(null);
  selectedOptsSource$ = this.selectedOptsSource.asObservable();

  constructor(
    http: HttpClient,
    private translate: TranslateService
  ) {
    super(http);
  }

  getLocations() {
    const requestOptions = {
      apiEndPoint: '/locations',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  getTaxonomyTerms() {
    const requestOptions = {
      apiEndPoint: '/location_taxonomy_terms',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  getTaxonomies() {
    const requestOptions = {
      apiEndPoint: '/location_taxonomies',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {

    let requestOptions = {
      apiEndPoint: 'locations',
      numberPerPage: 300,
      pageNumber: 1,
      sorting: {
        byProp: 'id',
        direction: 'asc'
      },
      filtering: {
        status: 'active'
      }
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { status: 'active', search_text: searchValues, filter_logic: 'or' }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    if (this.countriesIds?.length) {
      requestOptions.filtering['countries'] = this.countriesIds;
    }

    if (this.locationTaxonomyTermIds?.length) {
      requestOptions.filtering['location_taxonomy_term_ids'] = this.locationTaxonomyTermIds;  
    }

    return this.getData(requestOptions);
  }

  setCountries(countriesIds: string[]) {
    this.countriesIds = countriesIds;
    this.optsSource.next(countriesIds);
  }

  setLocationTaxonomyTermIds(locationTaxonomyTermIds: string) {
    this.locationTaxonomyTermIds = locationTaxonomyTermIds;
    this.optsSource.next(locationTaxonomyTermIds);
  }

  getNameWithTemplate(_element: any, _id): MultiselectDataSource {
    const element = _element.hasOwnProperty('list') ? _element.list.find( el => el.id === _id ) : _element;
    return new MultiselectDataSource(element.id, element.full_name, element);
  }

  fetchSelectedById(id: number | string): Observable<object> {
    return this.getLocationsById(id);
  }

  getLocationsById(id: number | string): Observable<any> {
    return this.getData({apiEndPoint: `locations/${id}`});
  }

  getLocationsByCountryId(id: number): Observable<any> {
    return this.getData({apiEndPoint: `locations?countries=${id}`});
  }

  getStatusOptions() {
    return [
      { id: 'active', name: this.translate.instant('resources.locations.fields.active') },
      { id: 'inactive', name: this.translate.instant('resources.locations.fields.inactive') }
    ];
  }

  sendData(creditsData) {
    return this.postResource(creditsData, 'locations');
  }

  updateData(creditsData: any, _id: number | string) {
    return this.patchResource(creditsData, `locations/${_id}`);
  }

  refreshSelectedOptsSource(selectedIds: string[], key: string) {
    this.selectedOptsSource.next({selectedIds, key});
  }
}
