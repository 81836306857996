import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PlanCampaign } from '../../plan-campaign';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { FeaturesService } from '../../../../data-warehouse/products/features.service';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';

@Component({
  selector: 'app-white-label-inputs',
  templateUrl: './white-label-inputs.component.html',
  styleUrls: ['./white-label-inputs.component.css'],
  providers: [FeaturesService]
})
export class WhiteLabelInputsComponent implements OnInit, OnDestroy {

  @Input('generalInputsForm') generalInputsForm: UntypedFormGroup;
  @Input('planCampaign') planCampaign: PlanCampaign;
  @Input('inputs') inputs: QuestionBase<any>[];

  constructor(
    private qcs: QuestionControlService,
    private featureService: FeaturesService
  ) { }

  ngOnInit() {
    this.featureService.treatPkAsId = true;
    this.setInputs(this.planCampaign);
  }

  ngOnDestroy() {
    this.qcs.removeFromFormGroup(this.generalInputsForm, 'white_label_id');
    const index = this.inputs.findIndex(input => input.key === 'white_label_id');
    this.inputs = this.inputs.slice(index, 1);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  private setInputs(planCampaign?: PlanCampaign) {

    const whiteLabelFeaturePresent = planCampaign && planCampaign.config.hasOwnProperty('white_label_id');

    const inputs = [
      new MultiSelectQuestion({
        key: 'white_label_id',
        label: 'resources.campaign_plans.types.supplier.fields.white_label_buyers',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        required: true,
        dataSource: this.featureService,
        selectedIds: whiteLabelFeaturePresent ? [planCampaign.config['white_label_id']] : []
      })
    ];

    this.inputs.push(inputs[0]);
    this.qcs.addToFormGroup(this.generalInputsForm, inputs[0]);
  }
}
