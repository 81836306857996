<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title" [translate]="'dashboards.sales.labels.following'"></h4>
      </div>
      <div>
        <i class="tooltip-position ti-info-alt mrg-top-8 mrg-left-5 positioning"
           tooltipPosition="left"
           pTooltip="{{ 'dashboards.sales.tooltips.sales_text' | translate }}"></i>
      </div>

      <app-loader *ngIf="loaders.salesDetails" />

      <table class="table table-hover table-monitoring" aria-hidden="true">
        <thead>
          <tr class="highlight-header">
            <th scope="auto" colspan="2">
              <i class="ei-push-pin"></i>
            </th>
            <th scope="auto" colspan="3">
              {{ currencySymbol }}
              <i class="tooltip-position ti-info-alt mrg-left-5" tooltipPosition="left" pTooltip="{{ 'dashboards.sales.tooltips.total_sales_text' | translate }}"></i>
            </th>
            <th scope="auto" colspan="3">
              #
            </th>
            <th scope="auto" colspan="2">
              <i class="ei-dollar-money"></i>
            </th>
            <th scope="auto" colspan="2">
              <i class="ei-employees"></i>&nbsp;
            </th>
            <th scope="auto" colspan="2">
              <i class="ei-employees"></i> {{'dashboards.sales.labels.highs_clients' | translate}}
            </th>
          </tr>
          <tr class="highlight-header">
            <th scope="auto" translate>dashboards.sales.labels.store</th>
            <th scope="auto" translate>dashboards.sales.labels.franchise</th>
            <th scope="auto" translate>dashboards.sales.labels.totals</th>
            <th scope="auto" translate>dashboards.sales.labels.identified_masculine</th>
            <th scope="auto">%</th>
            <th scope="auto" translate>dashboards.sales.labels.totals</th>
            <th scope="auto" translate>dashboards.sales.labels.identified_masculine</th>
            <th scope="auto">%</th>
            <th scope="auto">{{ currencySymbol }} {{'dashboards.sales.labels.average' | translate}}</th>
            <th scope="auto" translate>dashboards.sales.labels.items_average</th>
            <th scope="auto" translate>dashboards.sales.labels.active_customers</th>
            <th scope="auto">{{ currencySymbol }} {{'dashboards.sales.labels.average' | translate}}</th>
            <th scope="auto" translate>dashboards.sales.labels.period</th>
            <th scope="auto" translate>dashboards.sales.labels.totals</th>
          </tr>
        </thead>
        <tbody *ngFor="let location of salesDetails">
          <tr>
            <td>
              <a [routerLink]="['', { outlets: { modal: ['show', 'locations', location.id] }}]">
                <strong class="text-semibold"><u>{{location.name}} ({{location.id}})</u></strong>
              </a>
            </td>
            <td>
              <span *ngIf="location.franchise" translate>common.yes</span>
              <span *ngIf="!location.franchise" translate>common.no</span>
            </td>
            <td><span class="label label-info">{{location.revenue | lgcurrency: '1.0-0' }}</span></td>
            <td>{{location.revenue_id | lgcurrency: '1.0-0' }}</td>
            <td>
              <span class="label" [ngClass]="{ 'label-success': isGreen(location.revenue_perc),
                                                'label-warning': isYellow(location.revenue_perc),
                                                'label-danger': isRed(location.revenue_perc) }">
                {{location.revenue_perc | number: '1.2-2':'es' }}%
              </span>
            </td>
            <td>{{location.tickets | number: '1.0-0':'es' }}</td>
            <td>{{location.tickets_id | number: '1.0-0':'es' }}</td>
            <td>
              <span class="label" [ngClass]="{ 'label-success': isGreen(location.tickets_perc),
                                                'label-warning': isYellow(location.tickets_perc),
                                                'label-danger': isRed(location.tickets_perc) }">
                {{location.tickets_perc | number: '1.2-2':'es' }}%
              </span>
            </td>
            <td>{{location.average_ticket | lgcurrency: '1.2-2'  }}</td>
            <td>{{location.products_ticket | number: '1.2-2':'es' }}</td>
            <td>{{location.customers | number: '1.0-0':'es' }}</td>
            <td>{{location.customers_ticket | number: '1.2-2':'es' }}</td>
            <td>{{location.signups | number: '1.0-0':'es' }}</td>
            <td>{{location.total_signups | number: '1.0-0':'es' }}</td>
          </tr>
        </tbody>
        <tbody *ngIf="totals">
          <td><strong translate>common.total</strong></td>
          <td></td>
          <td><span class="label label-info">
            {{totals.revenue | lgcurrency: '1.0-0' }}</span>
          </td>
          <td>{{totals.revenue_id | lgcurrency: '1.0-0' }}</td>
          <td>
            <span class="label" [ngClass]="{ 'label-success': isGreen(totals.revenue_perc),
                                              'label-warning': isYellow(totals.revenue_perc),
                                              'label-danger': isRed(totals.revenue_perc) }">
              {{totals.revenue_perc | number: '1.2-2':'es' }}%
            </span>
          </td>
          <td>{{totals.tickets | number: '1.0-0':'es' }}</td>
          <td>{{totals.tickets_id | number: '1.0-0':'es' }}</td>
          <td>
            <span class="label" [ngClass]="{ 'label-success': isGreen(totals.tickets_perc),
                                              'label-warning': isYellow(totals.tickets_perc),
                                              'label-danger': isRed(totals.tickets_perc) }">
              {{totals.tickets_perc | number: '1.2-2':'es' }}%
            </span>
          </td>
          <td>{{totals.revenue_ticket | lgcurrency: '1.2-2' }}</td>
          <td>{{totals.products_ticket | number: '1.2-2':'es' }}</td>
          <td>{{totals.customers | number: '1.0-0':'es' }}</td>
          <td>{{totals.customers_ticket | number: '1.2-2':'es' }}</td>
          <td>{{totals.signups | number: '1.0-0':'es' }}</td>
          <td>{{totals.total_signups | number: '1.0-0':'es' }}</td>
        </tbody>
      </table>
    </div>
  </div>
</div>
