<a #modalToggleBtn
   data-toggle="modal"
   data-target="#sms-template-edit-modal"
   data-keyboard="false"
   [attr.data-backdrop]="modeReminder !== 'reminder_plan' && modeReminder !== 'received_reminder' && modeReminder !== 'plan_manual_campaign' && mode === 'edit'"
   hidden>
</a>
<form [formGroup]="form">
<div class="modal fade show" id="sms-template-edit-modal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header border bottom">
        <h4 class="mrg-btm-15 mrg-top-8">{{ title }}</h4>
        <button class="modal-close" [attr.data-cy]="'button_closeSmsModal'" data-dismiss="modal" (click)="closeWithoutSaving()">
          <em class="ti-close"></em>
        </button>
      </div>
      <div class="modal-body background-gray">
        <div class="row" *ngIf="inputs && companyData">
          <div class="col-md-12 ml-auto mr-auto">
              <div class="card no-mrg-btm">
                <div class="card-block pdd-15">
                  <div class="form-group">
                    <ng-container *ngIf="isInCrudMode">
                      <label for="title" [translate]="'resources.campaign_templates.fields.title'"></label>
                      <app-question [question]="getInputConfig('title')" [form]="form"></app-question>
                    </ng-container>
                    <div class="row">
                      <div class="col-md-8">
                        <label for="message" [translate]="'resources.campaign_templates.fields.message'"></label>
                      </div>
                      <div class="col-md-4 text-right pdd-btm-5" *ngIf="mode !== 'readonly' && mode !== 'show'">
                        <button class="btn btn-sm btn-default btn-inverse dropdown-toggle no-mrg-right"
                                data-toggle="dropdown">
                          <span [translate]="'resources.campaigns.smsAddVariable.addVariable'"></span>
                          <i class="ti-angle-down font-size-9"></i>
                        </button>
                        <ul class="dropdown-menu">
                          <li *ngFor="let couponVarObj of couponVariables" (click)="smsAddVariable(couponVarObj.id)">
                            <a *ngIf="couponVarObj.id !== '{GDPR}' || isCompanyGDPR">{{couponVarObj.name}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <app-question #messageInput [question]="getInputConfig('message')" [form]="form" (keyup)="detectTyping($event)"></app-question>
                    <p class="help-block" *ngIf="messageMetrics">
                      <span tooltip-placement="right"
                            class="fa fa-info-circle"
                            data-trigger="hover"
                            [title]="'resources.campaigns.warnings.character_max_tooltip' | translate: { smsMaxLength: messageData.smsMaxLength, smsMultiLength: messageData.smsMultiLength, smsMaxLengthDoubled: messageData.smsMaxLengthDoubled }">
                      </span>
                      <small class="font-size-12">
                        <span>
                          {{ 'resources.campaigns.warnings.character_count' | translate: { smsMessages: messageMetrics.concatenated_parts, smsRemainingLength: messageMetrics.remaining_characters, totalLength: messageMetrics.length} }}
                        </span>
                      </small>
                      <span class="text-danger" *ngIf="inputs && smsHasVariables()">*</span>
                    </p>
                    <p *ngIf="inputs && smsHasVariables()">
                      <span>
                        <span class="text-danger">*</span>
                        <small class="font-size-12">
                          {{ 'resources.campaigns.warnings.var_guideline' | translate }}
                        </small>
                      </span>
                    </p>
                    <ng-container *ngIf="flags.displaySmsUnsubscribeOption">
                      <span *ngIf="mode !== 'readonly' && mode !== 'show'">
                        <div class="alert alert-danger" *ngIf="isMissingGDPR" [innerHTML]="'resources.campaigns.warnings.gdpr.missing' | translate"></div>
                        <div class="alert alert-info font-size-12" *ngIf="!isCompanyGDPR" [translate]="'resources.campaigns.warnings.gdpr.disabled'"></div>
                      </span>
                    </ng-container>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      <div class="modal-footer" *ngIf="mode !== 'readonly' && mode !== 'show'">
        <button class="btn btn-default"
                [attr.data-cy]="'button_cancelSmsModal'"
                data-dismiss="modal"
                [translate]="'common.buttons.cancel'"
                (click)="closeWithoutSaving()">
        </button>
        <span><i class="fa fa-spinner fa-spin" *ngIf="loading"></i></span>
        <button id="saveBtn"
                type="button"
                class="btn btn-info no-mrg-right"
                [attr.data-cy]="'button_saveSmsModal'"
                (click)="save()"
                [disabled]="loading || isMissingGDPR"
                [translate]="'resources.campaign_templates.buttons.save'">
        </button>
      </div>

    </div>
  </div>
</div>
</form>
