import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBase } from '../models/forms/question-base';
import { MultiSelectQuestion } from '../models/forms/question-multiselect';
import { CouponsService } from '../../resources/coupons/coupons.service';
import { CustomerJourney } from '../models/customer-journeys/customer-journey';
import { UntypedFormGroup } from '@angular/forms';
import { DateService } from './date.service';
import { TextboxQuestion } from '../models/forms/question-textbox';
import { CheckboxQuestion } from '../models/forms/question-checkbox';
import { FloatQuestion } from '../models/forms/question-float';
import { TagSalesService } from './tag-sales.service';
import { LocationsTaxonomyTermsService } from '../../resources/data-warehouse/locations/location-taxonomy-terms.service';
import { MessagesService } from '../../resources/content-designer/campaign-messages/messages.service';
import { V1SegmentsService } from '../../resources/segments/v1-segments.service';

@Injectable()
export class PotentialJourneyService {

  public inputs: QuestionBase<any>[];

  constructor( private translate:                       TranslateService,
               private segmentService:                  V1SegmentsService,
               private couponsService:                  CouponsService,
               private messagesService:                 MessagesService,
               private dateService:                     DateService,
               private tagSalesService:                 TagSalesService,
               private locationsTaxonomiesTermsService: LocationsTaxonomyTermsService ) {}

  public getDefinitionStepFormCfg(journey: CustomerJourney): any[] {
    this.inputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'best_sales_tag',
        dataSource: this.tagSalesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
        order: 1,
        label: this.translate.instant('resources.journeys.fields.best_sales_tag'),
        getValue: (value) => ({ selectedIds: value })
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'location_terms',
        dataSource: this.locationsTaxonomiesTermsService,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        order: 2,
        label: this.translate.instant('resources.journeys.fields.location_taxonomy_terms'),
        getValue: (value) => value ? { selectedIds: value } : { selectedIds: [] }
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'extra_segment',
        dataSource: this.segmentService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
        order: 3,
        label: this.translate.instant('resources.journeys.fields.extra_segment'),
        getValue: (value) => value ? { selectedIds: value } : { selectedIds: [] }
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'reportable',
        label: this.translate.instant('resources.journeys.fields.include_in_roi'),
        order: 4,
        getValue: (value) => value
      }),
      new FloatQuestion({
        cssClasses: 'form-control input-md',
        key: 'prediction_percentage_redemption',
        label: this.translate.instant('resources.journeys.fields.redemption_percentage'),
        order: 5,
        type: 'number',
        min: 1,
        step: 0.1,
        getValue: (value) => value
      })
    ];

    journey.steps.forEach((step, index) => {

      let contentInput;
      const stepKeyName = `step${index + 1}`;
      const isEditableStep = journey.isEditableStep(step);

      if ( step.slug === 'reward' ) {
        contentInput = new MultiSelectQuestion({
          cssClasses: 'form-control input-md',
          key: `${stepKeyName}.voucheable`,
          disabled: !isEditableStep,
          dataSource: this.couponsService,
          settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
          getValue: (value) => value ? { selectedIds: value } : { selectedIds: null }
        });
      } else {
        contentInput = new MultiSelectQuestion({
          cssClasses: 'form-control input-md',
          key: `${stepKeyName}.voucheable`,
          disabled: !isEditableStep,
          dataSource: this.messagesService,
          settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
          getValue: (value) => value ? { selectedIds: value } : { selectedIds: null }
        });
      }

      this.inputs.push(
        new FloatQuestion({
          cssClasses: 'form-control input-md',
          key: `${stepKeyName}.cut`,
          disabled: index === 0 || !isEditableStep,
          type: 'number',
          min: 0,
          step: 1,
          getValue: (value) => value === 0 ? '0' : value
        }),
        new TextboxQuestion({
          cssClasses: 'form-control input-md',
          key: `${stepKeyName}.scheduled_at`,
          disabled: !isEditableStep,
          type: 'datetime-local',
          step: 60,
          getValue: (value) => value ? this.dateService.dateISOStringToDateTime(value) : null
        }),
        /* Common deliveries use message content, reward delivery uses coupon id */
        contentInput
      );
    });

    /* Definition input fields values */
    Object.keys(journey.cfg.configuration).forEach(
      (key) => {
        const tmpInput = this.inputs.find( input => input.key === key );
        if ( tmpInput.hasOwnProperty('getValue') ) {
          const value = tmpInput.getValue( journey.cfg.configuration[key] );
          if ( value.hasOwnProperty('selectedIds')) {
            tmpInput.selectedIds = value.selectedIds instanceof Array ? value.selectedIds : [value.selectedIds];
          } else {
            tmpInput.value = value;
          }
        }
      }
    );

    /* Steps schedule values */
    journey.steps.forEach(
      (step, index) => {

        const tmpInputCut = this.inputs.find( input => input.key === `step${index + 1}.cut` );
        const cutValue = tmpInputCut.getValue(step.configuration.cut);
        tmpInputCut.value = cutValue;

        const tmpInput = this.inputs.find( input => input.key === `step${index + 1}.scheduled_at` );
        const valueSch = tmpInput.getValue(step.scheduled_at);
        tmpInput.value = valueSch;

        let voucheableValue;
        const voucheableInput = this.inputs.find( input => input.key === `step${index + 1}.voucheable` );

        if (step.slug === 'reward') {
          voucheableValue = voucheableInput.getValue( step.configuration.coupon_id ).selectedIds;
          if ( voucheableValue ) { voucheableInput.selectedIds =  [voucheableValue]; }
        } else {
          voucheableValue = voucheableInput.getValue( step.configuration.message_id ).selectedIds;
          if ( voucheableValue ) { voucheableInput.selectedIds =  [voucheableValue]; }
        }
      }
    );

    /* Report values */
    const reportInput = this.inputs.find( _input => _input.key === 'reportable' );
    reportInput.value = reportInput.getValue(journey.reportable);

    const pctgInput = this.inputs.find( _input => _input.key === 'prediction_percentage_redemption' );
    pctgInput.value = pctgInput.getValue(journey.report_configuration.reward.prediction_percentage_redemption);

    /* Disable fields on edit */
    if ( !journey._original ) {
      // Disable fields on edit for potential journey
      this.inputs.find( _input => _input.key === 'best_sales_tag' ).disabled = true;
      this.inputs.find( _input => _input.key === 'location_terms' ).disabled = true;
      this.inputs.find( _input => _input.key === 'extra_segment' ).disabled = true;
    }

    return this.inputs;
  }

  public prepareToSave(journey: CustomerJourney, form: UntypedFormGroup) {

    // Definition
    const name = journey.name;
    const best_sales_tag = (form.value['best_sales_tag'] && form.value['best_sales_tag'].length) ? form.value['best_sales_tag'][0].id : null;
    const location_terms = (form.value['location_terms'] && form.value['location_terms'].length) ? form.value['location_terms'].map( el => el.id ) : null;
    const extra_segment = (form.value['extra_segment'] && form.value['extra_segment'].length) ? form.value['extra_segment'][0].id : null;

    const configuration = {
      best_sales_tag: best_sales_tag,
      location_terms: location_terms,
      extra_segment: extra_segment
    };

    const steps = journey.steps.map(
      (step, index) => {
        const stepCfg = step.configuration;

        const stepCutVal = form.value[`step${index + 1}.cut`];
        stepCfg.cut = stepCutVal ? parseInt(stepCutVal, 10) : 15 * index;

        if (form.value[`step${index + 1}.voucheable`] && form.value[`step${index + 1}.voucheable`].length) {
          if ( step.slug.indexOf('reward') >= 0 ) {
            stepCfg.coupon_id = parseInt(form.value[`step${index + 1}.voucheable`][0].id, 10);
          } else {
            stepCfg.message_id = parseInt(form.value[`step${index + 1}.voucheable`][0].id, 10);
          }
        } else {
          if ( step.slug.indexOf('reward') >= 0 ) {
            stepCfg.coupon_id = null;
          } else {
            stepCfg.message_id = null;
          }
        }

        return {
          id: step.id,
          status: step.status,
          position: step.position,
          scheduled_at: form.value[`step${index + 1}.scheduled_at`],
          configuration: stepCfg
        };
      }
    );

    // Summary
    const reportable = form.value[`reportable`] ? form.value[`reportable`] : false;
    const predictionPercentage = form.value.prediction_percentage_redemption ? parseFloat(form.value.prediction_percentage_redemption) : null;
    const reportCfg = predictionPercentage ? { reward: { prediction_percentage_redemption: predictionPercentage }} : {};

    const objToPost = {
      name: name,
      configuration: configuration,
      reportable: reportable,
      report_configuration: reportCfg,
      steps: steps
    };

    return objToPost;
  }

  public getJourneyName(journey: CustomerJourney) {
    return journey.name;
  }
}
