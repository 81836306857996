<div class="row">
  <div class="col-md-6">
    <div class="form-group">
      <label>{{'resources.campaigns.fields.segment' | translate}}</label>
      <app-question [question]="getInputConfig('segment')" (selectedLoadedEmitter)="handleSelectedSegment($event)" [form]="campaignForm"></app-question>
    </div>
  </div>
  <div class="col-md-2">
    <div class="form-group">
      <label>&nbsp;</label>
      <div>
        <button type="button"
                class="btn btn-default btn-icon"
                (click)="openSegment()"
                [disabled]="!campaignForm.value.segment?.length">
          <i class="ei-show"></i>
          {{'resources.campaigns.buttons.see_segment' | translate}}
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-4 mrg-top-5" *ngIf="shouldShowControlGroup()">
    <label>&nbsp;</label>
    <app-question [question]="getInputConfig('control_group_flag')" [form]="campaignForm"></app-question>
  </div>

  <div class="col-md-4 mrg-top-5"
    *ngIf="mode === 'plan_manual_campaign' && hasFormKeyWithValue('via', 'sms')">
    <label>&nbsp;</label>
    <app-question [question]="getInputConfig('include_phone_duplicated')" [form]="campaignForm"></app-question>
  </div>

  <div class="col-md-4 mrg-top-5" *ngIf="shouldShowContactPolicies()">
    <label>&nbsp;</label>
    <app-question [question]="getInputConfig('contact_policy_flag')" [form]="campaignForm"></app-question>
  </div>
</div>
