import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableComponent } from '../../../shared/components/data-table/data-table.component';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';

@Component({
  selector: 'app-tab-history',
  templateUrl: './tab-history.component.html'
})
export class TabHistoryComponent implements OnInit {
  public title = this.translate.instant('resources.coupons.title');
  public dataTableConfig = {
    isActive: true,
    tableActions: [
      {
        name: this.translate.instant('components.table_actions.export_csv'),
        icon: 'ei-export',
        id: 'exportCSV'
      }
    ],
    rowActions: [
      {
        name: this.translate.instant('resources.coupons.row_actions.details'),
        icon: 'eye',
        id: 'details',
        customRoute: (element: {parent_id: number, id: number}) => {
          return ['', { outlets: { modal: `show/coupons/${element.parent_id}/histories/${element.id}` }}];
        }
      }
    ],
    columns: [
      {
        name: this.translate.instant('resources.coupons.columns.parent_id'),
        prop: 'id',
        visible: true
      },
      {
        name: this.translate.instant('resources.coupons.columns.static_code'),
        prop: 'static_code',
        sortByField: 'static_code',
        visible: true
      },
      {
        name: this.translate.instant('resources.coupons.columns.key'),
        prop: 'key',
        sortByField: 'key',
        visible: true
      },
      {
        name: this.translate.instant('resources.coupons.columns.available_from'),
        prop: 'available_from',
        sortByField: 'available_from',
        visible: true
      },
      {
        name: this.translate.instant('resources.coupons.columns.available_to'),
        prop: 'available_to',
        sortByField: 'available_to',
        visible: true
      },
      {
        name: this.translate.instant('resources.coupons.columns.name'),
        prop: 'name',
        sortByField: 'name',
        visible: true
      },
      {
        name: this.translate.instant('resources.coupons.columns.priority'),
        prop: 'priority',
        sortByField: 'priority',
        visible: true
      },
      {
        name: this.translate.instant('resources.coupons.columns.location_number'),
        prop: 'location_number',
        sortByField: 'location_number',
        visible: true
      }
    ],
    requestData: {
      translationResource: 'resources.coupons.columns.',
      apiEndPoint: 'coupons/histories',
      pageNumber: 0,
      sorting: {
        byProp: 'available_from',
        direction: 'desc'
      },
      filtering: {},
      numberPerPage: 10
    },
    rows: [],
    tableMapping: [
      { prop: 'id', type: 'number', apiProp: 'parent_id' },
      { prop: 'static_code', type: 'number', apiProp: 'static_code' },
      { prop: 'key', type: 'number', apiProp: 'key' },
      { prop: 'available_from', type: 'date', apiProp: 'available_from' },
      { prop: 'available_to', type: 'date', apiProp: 'available_to' },
      { prop: 'name', type: 'text', apiProp: 'name' },
      { prop: 'priority', type: 'string', apiProp: 'priority' },
      { prop: 'location_number', type: 'string', apiProp: 'location_number' },
    ]
  };

  public filters: QuestionBase<any>[];

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  constructor( private translate: TranslateService ) { }

  ngOnInit() {
    this.getFiltersSelectOptions();
  }

  onFilterHandler(filterFormValue) {
    this.dataTable.onFilter(filterFormValue);
  }

  getFiltersSelectOptions() {
    this.setFilters();
  }

  setFilters() {
    this.filters = [
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'id',
        label: 'resources.coupons.filters.id',
        order: 1,
        type: 'number'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'name',
        label: 'resources.coupons.filters.name',
        order: 2,
        type: 'text'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'available_from',
        label: 'resources.coupons.filters.available_from',
        order: 3,
        type: 'date',
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'available_to',
        label: 'resources.coupons.filters.available_to',
        order: 4,
        type: 'date',
      }),
    ];
  }
}
