import { ActivatedRouteSnapshot, NavigationStart, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { LaunchDarklyService } from '../shared/services/launch-darkly/launchdarkly.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Location } from '@angular/common';

@Injectable()
export class AnalyticsGuard  implements OnDestroy {
  flags: object = {};
  router$: Subscription;
  navigationStartUrl: string;
  isHomeInitialNavigationUrl: boolean = false;

  constructor(
    private router: Router,
    private launchDarklyService: LaunchDarklyService,
    private authService: AuthService,
    private location: Location
  ) {
    this.router$ = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.navigationStartUrl = event.url;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.router$) { this.router$.unsubscribe(); }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {

    if (!this.authService.isAuthenticated()) {
      setTimeout(() => void this.router.navigate(['/signin']));
      return false;
    } else {
      const routeFeatureFlag = route.routeConfig?.data?.featureFlag ? route.routeConfig.data.featureFlag : null;
      if (!routeFeatureFlag) {
        return true;
      } else {
        return this.launchDarklyService.client.waitUntilReady().then(() => {
          this.flags = this.launchDarklyService.flags;
          const pathRoute = route.routeConfig.path;
          const isFeatureFlagActivated = this.flags[routeFeatureFlag];
          if (!isFeatureFlagActivated) {
            return this.handleNavigation(route, pathRoute, state.url);
          } else {
            return isFeatureFlagActivated;
          }
        });
      }
    }
  }

  private handleNavigation(route: ActivatedRouteSnapshot, pathRoute: string, url: string): boolean {
    const routes = route.parent.routeConfig.children;
    const parentUrl = url.slice(0, url.lastIndexOf('/'));
    const hasLocationState = !!this.location?.getState();
    let navigationUrl: string;
    if (this.navigationStartUrl) {
      navigationUrl = this.navigationStartUrl;
    } else {
      navigationUrl = this.getCurrentParentUrl() ? parentUrl : null;
    }
  
    /**
     * We check condition 1 (navigationUrl equals parentUrl) or condition 2 (!navigationUrl, isHomeInitialNavigationUrl and hasLocationState) so 
     * that user can navigate correctly through the menu to the first tab with FF activated and, also important,
     * so that when the user manually enters the url in the browser it doesn't automatically redirect to the next url with FF activated and 
     * can see that the specific url it entered is not available.
     *
     * Condition 1 applies when user has been previously navigating through known parent urls.
     * Condition 2 applies when initial url is home and when user refreshes home url.
     */
    if (routes?.length && routes.length > 1 && pathRoute === routes[0].path && (navigationUrl === parentUrl || (!navigationUrl && this.isHomeInitialNavigationUrl && hasLocationState))) {
      for (let i = 1; i < routes.length - 1; ++i) {
        if (routes[i].canActivate && routes[i].data && routes[i].data.featureFlag) {
          if (this.flags[routes[i].data.featureFlag]) {
            setTimeout(() => void this.router.navigate([routes[i].data.routerLink[0]]));
            return false;
          }

          if (i === (routes.length - 2) && !this.flags[routes[i].data.featureFlag]) {
            setTimeout(() => void this.router.navigate(['/error']));
            return false;
          }
        } else {
          setTimeout(() => void this.router.navigate([routes[i].data.routerLink[0]]));
          return false;
        }
      }
    } else {
      setTimeout(() => void this.router.navigate(['/error']));
      return false;
    }
  }

  private getCurrentParentUrl(): string {
    const url = this.router.url;
    this.isHomeInitialNavigationUrl = url === '/home';
    return url.slice(0, url.lastIndexOf('/'));
  }
}