import { BigqueryJobsStepsBase } from './bigquerystep-base';

export class DataStoreSynchroStep extends BigqueryJobsStepsBase {
  category = 'datastore_synchro';

  constructor(options: {} = {}) {
    super(options);
    this.category = options['category'] || 'datastore_synchro';
  }

  public getPayload() {
    return super.getPayload();
  }
}
