import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ResourcesService } from '../../../shared/services/resources.service';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { Subject } from 'rxjs';
import { DateService } from '../../../shared/services/date.service';

@Injectable()
export class DashPromotionsService extends ResourcesService{

  public inputs: QuestionBase<any>[];
  public applyFilters$ = new Subject<void>();

  public startSession = this.dateService.momentStartOfTypeAndFormat('month', 'YYYY-MM-DD');
  public finishSession= this.dateService.momentEndOfTypeAndFormat('month', 'YYYY-MM-DD');

  public filters = {
    date_from: this.startSession,
    date_to: this.finishSession
  };

  constructor(http: HttpClient,
              private dateService: DateService) {
    super(http);
  }

}
