<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-10">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form [formGroup]="companyPromotionsForm" *ngIf="companyPromotionsForm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name" [translate]="'resources.company_promotions.form.name'"></label>
                      <span *ngIf="getInputConfig('name').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('name')" [form]="companyPromotionsForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="discount">
                        <span>{{'resources.company_promotions.form.discount' | translate}}</span>
                        <em class="ti-info-alt pull-right mrg-top-3 mrg-left-5"
                           tooltipPosition="right"
                           pTooltip="{{ 'resources.company_promotions.form.tooltip_text' | translate }}">
                        </em>
                      </label>
                      <app-question [question]="getInputConfig('discount')" [form]="companyPromotionsForm"></app-question>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
