
<div class="row pdd-vertical-20 pdd-horizon-120" *ngIf="form">
  <div class="col-md-12">
    <app-loader *ngIf="loading" />
  </div>
  <div class="col-md-12">
    <div class="form-group" [hidden]="loading">
      <label for="name" 
        class="font-size-14 text-bold"
        [translate]="'resources.campaign_plans.types.custom.audience.recommended_coupons.fields.date'"></label>
      <app-question [question]="inputs[0]" [form]="form"></app-question>
    </div>
    <div *ngIf="!loading">
      <div class="success-ratio" *ngIf="flags.useIACouponRecommenderVisibility">
          <label class="font-size-14 text-bold"
            [translate]="'resources.campaign_plans.types.custom.audience.recommended_coupons.fields.use_ai'">
          </label>
        <app-question class="" [question]="inputs[1]" [form]="form"></app-question>
      </div>
      <label for="table"
        class="font-size-14 text-bold"
        [translate]="'resources.campaign_plans.types.custom.audience.recommended_coupons.fields.coupons'">
      </label>
      <i *ngIf="recommendedCoupons.length"
          class="tooltip-position ti-info-alt mrg-top-10 font-size-14"
          tooltipPosition="right"
          pTooltip="{{ 'resources.campaign_plans.types.custom.audience.recommended_coupons.messages.coupons_sort' | translate }}"
      ></i>
      <div class="card" *ngIf="recommendedCoupons.length">
        <table class="table table-hover table-sm table-bordered no-mrg-btm" aria-label="coupons-table">
          <thead>
            <tr class="highlight-header">
              <ng-container *ngIf="showSuccessRatio; else noSuccessRatioHeader">
                <th></th>
                <th>
                  {{'resources.campaign_plans.types.custom.audience.recommended_coupons.columns.coupon_name' | translate }}
                </th>
                <th>
                  {{'resources.campaign_plans.types.custom.audience.recommended_coupons.columns.success_ratio' | translate }}
                </th>
              </ng-container>
              <ng-template #noSuccessRatioHeader>
                <th></th>
                <th>
                  {{'resources.campaign_plans.types.custom.audience.recommended_coupons.columns.coupon_id' | translate }}
                </th>
                <th>
                  {{'resources.campaign_plans.types.custom.audience.recommended_coupons.columns.coupon_name' | translate }}
                </th>
                <th>
                  {{'resources.campaign_plans.types.custom.audience.recommended_coupons.columns.incremental_ratio' | translate }}
                </th>
                <th>
                  {{'resources.campaign_plans.types.custom.audience.recommended_coupons.columns.redemption_percentage' | translate }}
                </th>
              </ng-template>
            </tr>
          </thead>
          <tbody>
            <tr class="cursor-pointer" (click)="selectRow(i)" *ngFor="let coupon of recommendedCoupons; let i = index;">
              <td class="text-center">
                <input type='radio' [value]="i" name="recommendedCoupons" [checked]="i === couponSelectedIndex">
              </td>
              <ng-container *ngIf="showSuccessRatio; else noSuccessRatioRow">
                <td class="align-middle text-left">
                  <span class="label font-size-12 label-default">{{ coupon.name }}</span>
                </td>
                <td class="align-middle text-right">
                  <span class="label font-size-12 label-default">{{ coupon.successRatio | number: '1.2-2' }}%</span>
                </td>
              </ng-container>
              <ng-template #noSuccessRatioRow>
                <td class="align-middle text-right">
                  <span class="label font-size-12 label-default">{{ coupon.id }}</span>
                </td>
                <td class="align-middle text-left">
                  <span class="label font-size-12 label-default">{{ coupon.name }}</span>
                </td>
                <td class="align-middle text-right">
                  <span class="label font-size-12 label-default">{{ coupon.incrementalRatio | number: '1.2-2' }}</span>
                </td>
                <td class="align-middle text-right">
                  <span class="label font-size-12 label-default">{{ (coupon.redemptionPercentage * 100) | number: '1.2-2' }}%</span>
                </td>
              </ng-template>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card" *ngIf="recommendedCoupons.length === 0">
        <div class="padding-20 text-center">
          {{'components.data-table.messages.no_elements_found' | translate}}
        </div>
      </div>
    </div>
  </div>
</div>
