<div class="alert alert-info" role="alert" *ngIf="!isOnSearchSubmitted && filtersLoaded">
  <span>{{ 'components.looker-iframe.alerts.apply_filters' | translate }}</span>
</div>

<div class="alert alert-info alert-dismissible fade show mrg-btm-0" *ngIf="isOnSearchSubmitted && filtersLoaded && slug === 'segment_categories'">
  {{'components.looker-iframe.alerts.tagged_segment_categories' | translate}}
  <button type="button" class="close no-pdd-top pdd-top-10" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div [hidden]="!isOnSearchSubmitted && tabHasFilters">
  <app-loader *ngIf="loading"></app-loader>

  <div class="alert alert-success alert-dismissible fade show mrg-btm-0" *ngIf="flags.lookerIntegrationTestDashboardId">
    <b>Loading dashboard with URL:</b> {{flags.lookerIntegrationTestDashboardId}}
    <button type="button" class="close no-pdd-top pdd-top-10" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="row mrg-btm-15 badge-dates" *ngIf="slug === 'evolution' || slug === 'sales_evolution_supplier'">
    <div class="col" *ngIf="dateFrom && dateTo">
      <span class="badge badge-primary font-size-13">
        {{'components.looker-iframe.fields.selected_period' | translate}}: {{dateFrom | date: 'dd/MM/yyyy'}}
        ({{'components.looker-iframe.fields.launch_date' | translate}}) - {{dateTo | date: 'dd/MM/yyyy'}}
      </span>
    </div>
  </div>

  <ng-container *ngFor="let embedUrl of embedUrls; let i = index;">
    <div class="container-fluid" [hidden]="(dashboardPerUnitMeasure && ((i === 0 && !unitMeasureVisibility.units) || (i === 1 && !unitMeasureVisibility.variation))) ||
                                          (hideCurrentDashboard && i === 0) || (hideExtraDashboard && i === 1)">
      <div class="row">
        <div class="col-md-12 no-pdd">
          <div class="card">
            <div class="card-block padding-20">
              <div class="container" *ngIf="embedUrl">
                <iframe #iframes class="responsive-iframe" [src]="embedUrl" title="iframe"></iframe>
              </div>
              <code *ngIf="embedErrs[i]">
                {{embedErrs[i]}}
              </code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
