<ng-container *ngIf="inputs">
  <div class="row">
    <div class="col">
      <app-question [question]="getInputConfig('name')" [form]="form"></app-question>
    </div>
  </div>
  <div class="row mrg-btm-15">
    <div class="col">
      <app-question [question]="getInputConfig('sql_query')" [form]="form"></app-question>
      <span class="pull-right">
        <a href="https://cloud.google.com/bigquery/docs/reference/standard-sql/query-syntax" target="_blank" rel="noopener">
          {{'resources.segment_conditions.fields.sql_syntax' | translate}}
        </a>
      </span>
    </div>
  </div>
</ng-container>
