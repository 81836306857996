import { Modules, ModuleSlugs } from "../enums/modules.enums";
import { ADMIN_DATA_AUTH, ANALYTICS_NAV_ROLES_NOT_AUTH, CDP2_NAV_ROLES_NOT_AUTH, CDP_NAV_ROLES_NOT_AUTH, CONFIG_NAV_ROLES_NOT_AUTH, LOYALTY_AUDIENCES_NAV_ROLES_NOT_AUTH, OFFERS_OMNI_NAV_ROLES_NOT_AUTH, USERS_AUTH } from "./navigation-roles-not-authorized.constants";

/*********************************************/
/*    MODULE PERMISSIONS (PURCHASED/AUTH)    */
/*********************************************/

type Restrictions = {
    [key: string]: {
        /**
         * To check if module is purchased
         */
        slug?: string;
        authRoles?: string[];
        notAuthRoles?: string[];
    }
};

export const MODULE_PERMISSIONS: Restrictions = {
    [Modules.RetailGroupAnalytics]: { slug: ModuleSlugs.RetailGroupAnalytics, notAuthRoles: ANALYTICS_NAV_ROLES_NOT_AUTH },
    [Modules.BasicAnalytics]: { slug: ModuleSlugs.BasicAnalytics, notAuthRoles: ANALYTICS_NAV_ROLES_NOT_AUTH },
    [Modules.LoyaltyAnalytics]: { slug: ModuleSlugs.LoyaltyAnalytics, notAuthRoles: ANALYTICS_NAV_ROLES_NOT_AUTH },
    [Modules.RetailAnalytics]: { slug: ModuleSlugs.RetailAnalytics, notAuthRoles: ANALYTICS_NAV_ROLES_NOT_AUTH },
    [Modules.SupplierAnalytics]: { slug: ModuleSlugs.SupplierAnalytics, notAuthRoles: ANALYTICS_NAV_ROLES_NOT_AUTH },
    [Modules.OffersPersonalization]: { slug: ModuleSlugs.OffersPersonalization, notAuthRoles: OFFERS_OMNI_NAV_ROLES_NOT_AUTH },
    [Modules.Loyalty]: { slug: ModuleSlugs.Loyalty, notAuthRoles: LOYALTY_AUDIENCES_NAV_ROLES_NOT_AUTH },
    [Modules.Omnichannel]: { slug: ModuleSlugs.Omnichannel, notAuthRoles: OFFERS_OMNI_NAV_ROLES_NOT_AUTH },
    [Modules.Audiences]: { slug: ModuleSlugs.Audiences, notAuthRoles: LOYALTY_AUDIENCES_NAV_ROLES_NOT_AUTH },
    [Modules.CustomerDataPlatform]: { slug: ModuleSlugs.CustomerDataPlatform, notAuthRoles: CDP_NAV_ROLES_NOT_AUTH },
    [Modules.Configuration]: { notAuthRoles: CONFIG_NAV_ROLES_NOT_AUTH },
    [Modules.Users]: { authRoles: USERS_AUTH },
    [Modules.SystemStatus]: { authRoles: ADMIN_DATA_AUTH }
};

export const SUBMODULE_PERMISSIONS: Restrictions = {
    [`${Modules.Loyalty}.tiers-management.coupons`]: { slug: ModuleSlugs.CustomerDataPlatform },
    [`${Modules.Omnichannel}.campaigns`]: { notAuthRoles: ['content_creation'] },
    [`${Modules.CustomerDataPlatform}.dashboards`]: { notAuthRoles: CDP2_NAV_ROLES_NOT_AUTH },
    [`${Modules.CustomerDataPlatform}.collect`]: { notAuthRoles: CDP2_NAV_ROLES_NOT_AUTH },
    [`${Modules.CustomerDataPlatform}.connectors`]: { authRoles: ADMIN_DATA_AUTH },
    [`${Modules.CustomerDataPlatform}.custom-entities`]: { notAuthRoles: ['franchise', 'store_manager'] },
    [`${Modules.CustomerDataPlatform}.customer-attributes`]: { notAuthRoles: CDP2_NAV_ROLES_NOT_AUTH },
    [`${Modules.CustomerDataPlatform}.warehouse.purchases`]: { notAuthRoles: ['franchise'] },
    [`${Modules.CustomerDataPlatform}.warehouse.products`]: { notAuthRoles: CDP2_NAV_ROLES_NOT_AUTH },
    [`${Modules.CustomerDataPlatform}.warehouse.locations`]: { notAuthRoles: CDP2_NAV_ROLES_NOT_AUTH },
    [`${Modules.CustomerDataPlatform}.warehouse.coupons`]: { notAuthRoles: CDP2_NAV_ROLES_NOT_AUTH },
    [`${Modules.CustomerDataPlatform}.warehouse.promotions`]: { notAuthRoles: CDP2_NAV_ROLES_NOT_AUTH },
    [`${Modules.Configuration}.loyalty`]: { notAuthRoles: ['user_manager'] },
    [`${Modules.Configuration}.offers`]: { notAuthRoles: ['user_manager'] },
    [`${Modules.Configuration}.omnichannel`]: { notAuthRoles: ['user_manager'] },
    [`${Modules.Configuration}.scheduler`]: { notAuthRoles: ['user_manager'] },
    [`${Modules.Configuration}.consent-management`]: { notAuthRoles: ['user_manager'] },
    [`${Modules.Configuration}.company`]: { notAuthRoles: ['user_manager'] },
    [`${Modules.Configuration}.system`]: { notAuthRoles: ['user_manager'] },
    [`${Modules.Configuration}.payments`]: { notAuthRoles: ['user_manager'] }
};

export const PERMISSIONS = { ...MODULE_PERMISSIONS, ...SUBMODULE_PERMISSIONS };