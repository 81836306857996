import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MultiselectDataSource, MultiselectDataSourceable } from '../components/multiselect/multiselect';
import { ResourcesService } from './resources.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class CampaignsSenderService extends ResourcesService implements MultiselectDataSourceable {

  constructor(http: HttpClient) {
    super(http);
  }

  public fetchSelectedById(id: number): Observable<object> {
    return this.getData({apiEndPoint: `campaigns/senders?id=${id}`}).pipe(
      map( itemArray => itemArray['list'] ),
      map( element => {
        return element.filter( x => `${x.id}` === `${id}` )[0];
      }));
  }

  public fetchMultiselect(_, page?: number, filters?: object ) {
    let requestOptions = {
      apiEndPoint: 'campaigns/senders',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {},
      sorting: {}
    };
    
    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;
    
    if (filters) {
      const filterObj = { filtering: filters};
      requestOptions = {...requestOptions, ...filterObj};
    }
      
    return this.getData( requestOptions );
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.value, element);
  }
}