<div class="table-responsive" [ngClass]="{'min-height-500': forecastData && forecastData.length > 0}">
  <div class="row no-mrg">
    <div class="col-md-6 no-pdd-left">
      <div class="checkbox" *ngIf="flags.eurecatCheckboxPredictionVisibility && forecastData && forecastData.length > 0">
        <input id="includeEurecatPrediction"
               name="includeEurecatPrediction"
               type="checkbox"
               [(ngModel)]="includeEurecatPrediction"
               (ngModelChange)="handleEurecatPredictionChange($event)">
        <label for="includeEurecatPrediction" [translate]="'resources.campaign_plans.fields.include_eurecat_prediction'"></label>
      </div>
    </div>
    <ng-container *ngIf="flags.showPlanDetailCreditBalance && companyInfo">
      <div class="col-md-3">
        <app-credit-amount-info
          class="card card-default col pdd-btm-10 pdd-horizon-20"
          [icon]="'ei ei-speech-box-text'"
          [titleKey]="'resources.company.credits.titles.sms_credits'"
          [creditAmount]="companyInfo.smsCredits"
          [subtitle]="'resources.company.credits.titles.sms_credits_subtitle' | translate: {
            smsAproxDeliver: (companyInfo.smsAproxDeliverAmount | number: '1.0-0'),
            smsPrefix: companyInfo.smsCreditsPrice[0].prefix }">
        ></app-credit-amount-info>
      </div>
      <div class="col-md-3 no-pdd-right">
        <app-credit-amount-info
          class="card card-default col pdd-btm-10 pdd-horizon-20"
          [icon]="'ei ei-email'"
          [titleKey]="'resources.company.credits.titles.email_credits'"
          [creditAmount]="companyInfo.emailCredits"
          [subtitle]="'resources.company.credits.titles.email_credits_subtitle' | translate: {
            emailAproxDeliver: (companyInfo.emailAproxDeliverAmount | number: '1.0-0')}">
        ></app-credit-amount-info>
      </div>
    </ng-container>
  </div>
  <table *ngIf="(forecastData && forecastData.length > 0)" class="table table-sm table-hover table-bordered" aria-hidden="true">
    <thead>
      <tr class="highlight-header">
        <th *ngFor="let header of tableHeaders; let i = index;"
            class="text-center"
            [innerHTML]="header">
        </th>
      </tr>
    </thead>
    <tbody>
      <tr app-forecast-content *ngFor="let item of forecastData"
                               (actionEmitter)="handleEmittedAction()"
                               [plan]="plan"
                               [planCampaign]="planCampaign"
                               [item]="item"
                               class="totals-row"></tr>
      <tr class="totals-row highlight-header" *ngIf="totalsLoaded">
        <td class="text-center">
          <b>{{'common.total' | translate | uppercase}}</b>
        </td>
        <td></td>
        <td class="text-right pdd-right-15">
          {{totals.customers | number: '1.0-0'}}
        </td>
        <td class="text-right pdd-right-15">
          {{totals.maxRedemptionsPerCoupon | number: '1.0-0'}}
        </td>
        <td class="text-right pdd-right-15">
          {{totals.delivered | number: '1.0-0'}}
        </td>
        <td class="text-right pdd-right-15" *ngIf="flags.showPlanDetailCreditBalance">
          {{totals.deliveryCost | lgcurrency: '1.2-2'}}
        </td>
        <td class="text-right pdd-right-15">
          {{totals.estimatedDelivery | number: '1.2-2'}}%
        </td>
        <td class="text-right pdd-right-15">
          {{totals.totalRedemptions | number: '1.0-0'}}
        </td>
        <td class="text-right pdd-right-15">
          {{totals.estimatedRedemptionPerc | number: '1.2-2'}}%
        </td>
        <td class="text-right pdd-right-15">
          {{'common.fields.na' | translate}}
        </td>
        <td class="text-right pdd-right-15">
          {{totals.uniqueRedemptions | number: '1.0-0'}}
        </td>
        <td class="text-right pdd-right-15">
          {{'common.fields.na' | translate}}
        </td>
        <td class="text-right pdd-right-15">
          {{'common.fields.na' | translate}}
        </td>
        <td class="text-right pdd-right-15">
          {{'common.fields.na' | translate}}
        </td>
        <td class="text-right pdd-right-15">
          {{totals.discountCost | lgcurrency: '1.2-2'}}
        </td>
        <td class="text-right pdd-right-15" [hidden]="planCampaign.type !== 'Plans::SupplierCampaign'">
          {{totals.lgCost | lgcurrency: '1.2-2'}}
        </td>
        <td class="text-right pdd-right-15">
          {{totals.incremental | lgcurrency: '1.2-2'}}
        </td>
        <td class="text-right pdd-right-15">
          {{totals.incrementalRatio | number: '1.2-2'}}
        </td>
        <td class="text-right pdd-right-15">
          {{totals.incrementalOverSales | number: '1.2-2'}}%
        </td>
      </tr>
    </tbody>
  </table>
</div>

<table *ngIf="forecastData && forecastData.length === 0" class="table table-lg table-hover dataTable no-footer mrg-top-20" aria-hidden="true">
  <tbody>
    <tr class="odd no-border">
      <td class="dataTables_empty text-semibold text-center"
          style="vertical-align: top;"
          colspan="7"
          [innerHTML]="'resources.campaign_plans.messages.no_selected_audience' | translate">
      </td>
    </tr>
  </tbody>
</table>
