import { Modules } from "../../shared/enums/modules.enums";

export const homeModules: {
    id: string;
    route: string;
    text: string;
    // Permissions
    isPurchased?: boolean;
    isNotAuthorized?: boolean;
    isAuthorized?: boolean;
}[] = [
        { id: Modules.LoyaltyAnalytics, route: 'program-performance/club', text: 'analytics.loyalty_analytics' },
        { id: Modules.RetailAnalytics, route: 'sales', text: 'analytics.retail_analytics' },
        { id: Modules.SupplierAnalytics, route: 'sales', text: 'analytics.supplier_analytics' },
        { id: Modules.RetailGroupAnalytics, route: 'dashboards', text: 'analytics.retail_group' },
        { id: Modules.BasicAnalytics, route: 'sales', text: 'analytics.basic_analytics' },
        { id: Modules.Loyalty, route: 'rules-engine', text: 'loyalty_management' },
        { id: Modules.OffersPersonalization, route: 'campaigns', text: 'offers_personalization' },
        { id: Modules.Omnichannel, route: 'campaigns', text: 'omnichannel_manager' },
        { id: Modules.Audiences, route: 'segments', text: 'audiences' },
        { id: Modules.CustomerDataPlatform, route: 'warehouse/customers/profiles', text: 'customer_data_platform' }
    ];

export type HomeItem = typeof homeModules[number];