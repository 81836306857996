import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { LoyaltyScoresService } from '../../loyalty-scores.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-validity-points-block',
  templateUrl: './validity-points-block.component.html'
})
export class ValidityPointsBlockComponent implements OnDestroy {

  @Input('loyaltyScoresForm') loyaltyScoresForm: UntypedFormGroup;
  @Input('inputs') inputs;
  @Input('isUsed') isUsed;

  expirationDate: unknown;
  private destroy$ = new Subject<void>();

  constructor(
    private qcs: QuestionControlService,
    private loyaltyScoreService: LoyaltyScoresService,
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.loyaltyScoresForm, formKey, value);
  }

  displayExpirationDate(event) {
    if (!event) {
      this.expirationDate = null;
      return;
    }
    this.loyaltyScoreService.getExpirationDate(event.id).pipe(takeUntil(this.destroy$)).subscribe(
      (res: object) => {
        if (res.hasOwnProperty('available_to')) {
          this.expirationDate = res['available_to'].split('-').reverse().join('/');
        }
      }
    );
  }

}
