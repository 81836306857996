import { Component, Input } from '@angular/core';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-limits-block',
  templateUrl: './limits-block.component.html'
})

export class LimitsBlockComponent {

  @Input('loyaltyScoresForm') loyaltyScoresForm: UntypedFormGroup;
  @Input('inputs') inputs;
  @Input('isUsed') isUsed;

  constructor(
    private qcs: QuestionControlService,
  ) { }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  chancesToWinSelectorChange(event:Object) {
    const disableLimit = !event || (event && event['id'] === 'allways');
    this.inputs.find( input => input.key === 'once_limit').disabled = disableLimit;
    this.loyaltyScoresForm.get('once_limit').reset();
  }

}
