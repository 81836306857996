import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { LoyaltyScoresService } from '../../loyalty-scores.service';
import { MultiselectService } from '../../../../../shared/components/multiselect/multiselect.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-validity-loyalty-rule-block',
  templateUrl: './validity-loyalty-rule-block.component.html'
})

export class ValidityLoyaltyRuleBlockComponent implements OnInit, OnDestroy{

  @Input('loyaltyScoresForm') loyaltyScoresForm: UntypedFormGroup;
  @Input('inputs') inputs;

  subs$: Subscription[] = [];

  constructor(
    private qcs: QuestionControlService,
    private loyaltyScoresService: LoyaltyScoresService,
    private multiselectService: MultiselectService
  ) { }

  ngOnInit(): void {
    this.validateRecurrence();
  }

  ngOnDestroy(): void {
    this.subs$.forEach(sub => sub.unsubscribe());
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.loyaltyScoresForm, formKey, value);
  }

  private validateRecurrence(): void {
    const recurrenceDaysField = this.loyaltyScoresForm.get('recurrence_days');
    const recurrenceDaysInput = this.inputs.find(input => input.key === 'recurrence_days');
    const recurrenceTypeControl = this.loyaltyScoresForm.get('recurrence_type');

    const updateRecurrenceDays = (executionSelected: string | undefined) => {
      const shouldEnable = executionSelected === 'monthly' || executionSelected === 'weekly';

      recurrenceDaysInput.disabled = !shouldEnable;

      if (shouldEnable) {
        recurrenceDaysInput.required = true;
        recurrenceDaysField.enable();
        recurrenceDaysInput.options = this.loyaltyScoresService.getRecurrenceDaysOptions(executionSelected);
      } else {
        recurrenceDaysInput.required = false;
        recurrenceDaysField.disable();
        recurrenceDaysInput.options = [];
      }
      this.multiselectService.updateDropdownList.next('recurrence_days');
      recurrenceDaysField.updateValueAndValidity();
    };

    const initialExecutionSelected = recurrenceTypeControl.value?.[0]?.id;
    updateRecurrenceDays(initialExecutionSelected);

    const recurrenceType$ = recurrenceTypeControl.valueChanges.subscribe(value => {
      recurrenceDaysField.reset();
      recurrenceDaysInput.value = [];
      const executionSelected = value?.[0]?.id;
      updateRecurrenceDays(executionSelected);
    });

    this.subs$.push(recurrenceType$);
  }

}
