
export class ClubEvolutionRowData {

  public month: string;

  public yearSpentAverage: number;
  public comparedYearSpentAverage: number;
  public diffSpentAverage: number;

  public yearTicketAverage: number;
  public comparedYearTicketAverage: number;
  public diffTicketAverage: number;

  public yearFrequency: number;
  public comparedYearFrequency: number;
  public diffFrequency: number;

  public yearTotalIdentifiedSales: number;
  public comparedYearTotalIdentifiedSales: number;
  public diffIdentifiedSales: number;

  public pctgYearIdentifiedSales: number;
  public pctgComparedYearIdentifiedSales: number;
  public diffPctgIdentifiedSales: number;

  public yearTotalSales: number;
  public comparedYearTotalSales: number;
  public diffTotalSales: number;

  public yearTotalIdentifiedTickets: number;
  public comparedYearTotalIdentifiedTickets: number;

  public yearActiveCustomers: number;
  public comparedYearActiveCustomers: number;
  public diffActiveCustomers: number;

  constructor ( cfg ) {

    this.month = cfg.month;

    this.yearSpentAverage = cfg.yearSpentAverage;
    this.comparedYearSpentAverage = cfg.comparedYearSpentAverage;
    this.diffSpentAverage = cfg.diffSpentAverage;

    this.yearTicketAverage = cfg.yearTicketAverage;
    this.comparedYearTicketAverage = cfg.comparedYearTicketAverage;
    this.diffTicketAverage = cfg.diffTicketAverage;

    this.yearFrequency = cfg.yearFrequency;
    this.comparedYearFrequency = cfg.comparedYearFrequency;
    this.diffFrequency = cfg.diffFrequency;

    this.yearTotalIdentifiedSales = cfg.yearTotalIdentifiedSales;
    this.comparedYearTotalIdentifiedSales = cfg.comparedYearTotalIdentifiedSales;
    this.diffIdentifiedSales = cfg.diffIdentifiedSales;

    this.pctgYearIdentifiedSales = cfg.pctgYearIdentifiedSales;
    this.pctgComparedYearIdentifiedSales = cfg.pctgComparedYearIdentifiedSales;
    this.diffPctgIdentifiedSales = cfg.diffPctgIdentifiedSales;

    this.yearTotalSales = cfg.yearTotalSales;
    this.comparedYearTotalSales = cfg.comparedYearTotalSales;
    this.diffTotalSales = cfg.diffTotalSales;

    this.yearTotalIdentifiedTickets = cfg.yearTotalIdentifiedTickets;
    this.comparedYearTotalIdentifiedTickets = cfg.comparedYearTotalIdentifiedTickets;

    this.yearActiveCustomers = cfg.yearActiveCustomers;
    this.comparedYearActiveCustomers = cfg.comparedYearActiveCustomers;
    this.diffActiveCustomers = cfg.diffActiveCustomers;
  }
}

// Mock constant for testing
export const ClubEvolutionRowDataMock = {
  active_customers: {
      year: [
          191859,
          188628,
          195901,
          201537,
          207111,
          209954,
          206695,
          200897,
          223786,
          232712,
          226066,
          222565
      ],
      compared_year: [
          158014,
          159442,
          169837,
          166072,
          173913,
          173167,
          170948,
          162211,
          183731,
          186785,
          184305,
          182330
      ]
  },
  total_identified_sales: {
      year: [
          11267833.91,
          10949472.38,
          12180321.01,
          12272160.36,
          13845856.74,
          13849055.75,
          12074787.88,
          11149475.8,
          13665479.75,
          15265605.1,
          13721356.32,
          13153777.65
      ],
      compared_year: [
          9196383.11,
          8923922.43,
          10401792.16,
          9581422.84,
          10583167.02,
          10246399.88,
          9452704.61,
          8151273.89,
          10926819.86,
          11540754.25,
          10757836.93,
          11010200.07
      ]
  },
  total_identified_tickets: {
      year: [
          767600,
          712159,
          790730,
          805126,
          865781,
          878912,
          845234,
          754127,
          878984,
          982842,
          883538,
          825713
      ],
      compared_year: [
          632893,
          616002,
          723273,
          645526,
          743849,
          722776,
          685193,
          604497,
          745746,
          770661,
          736621,
          693483
      ]
  },
  total_sales: {
      year: [
          15504185.67,
          14885983.14,
          16573216.36,
          16627219.95,
          18753910.73,
          18855903.49,
          16822215.49,
          15658811.24,
          17988456.5,
          19919676.06,
          17716185.9,
          17077478.24
      ],
      compared_year: [
          13159095.95,
          12636578.53,
          14615243.31,
          13360952.38,
          14731594.48,
          14336462.13,
          13565017.42,
          12242300.6,
          15360807.31,
          15990961.7,
          14862672.67,
          15105398.19
      ]
  }
};

// export const ClubEvolutionRowDataMock = {
//   total_identified_sales: {
//     compared_year: [ 9196383, 8923922, 10401792, 9581423, 323, 12, 0, 2.0, 32.42, 349.86, 101.38, 0 ],
//     year: [ 11267834, 10949472, 12180321, 12272160, 525.08, 0, 0, 304.76, 4.64, 91.37, 173.18, 0 ]
//   },
//   total_sales: {
//     compared_year: [ 13159096, 50.13, 2377.29, 52.15, 43.58, 0, 0, 2.0, 32.42, 349.86, 101.38, 0],
//     year: [ 15504186, 104.75, 65.67, 139.47, 525.08, 0, 0, 304.76, 4.64, 91.37, 173.18, 0 ]
//   },
//   total_identified_tickets: {
//     compared_year: [ 14827, 16454, 16513, 17991, 13498, 0, 0, 12605, 12359, 14158, 19525, 0 ],
//     year: [ 19121, 17659, 17163, 11385, 18156, 0, 0, 10691, 17456, 13896, 13752, 0 ]
//   },
//   active_customers: {
//     compared_year: [ 158014, 5013, 2329, 5215, 4358, 0, 0, 20, 3242, 34986, 10138, 0 ],
//     year: [ 191859, 188628, 195901, 201537, 52508, 0, 0, 30476, 464, 9137, 17318, 0 ]
//   }
// };
