import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Role, RolesService } from '../../../profiles/roles.service';
import { ProfileService } from '../../../profiles/profile.service';

@Component({
  selector: 'app-data-warehouse-coupons',
  templateUrl: './data-warehouse-coupons.component.html'
})

export class DataWarehouseCouponsComponent implements OnInit, OnDestroy {

  public slug = 'coupons';
  public apiEndPoint = 'coupons';
  public router$: Subscription;
  public activeTab = {
    name: 'coupons',
    title: this.translate.instant('resources.coupons.title')
  };
  public apiEndPoints = {
    coupons: 'coupons',
    coupon_histories: 'coupon-histories',
  };
  public visibleTabs: string[];
  public role: Role;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private profileService: ProfileService
  ) {
    this.visibleTabs = [];
  }

  ngOnInit() {
    this.role = new Role(this.profileService.getStoredUser().role);
    this.refresh();
    this.visibleTabs = this.route.snapshot.data.visibleTabs;
    this.router$ = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.refresh();
      }
    });
  }

  ngOnDestroy() {
    if ( this.router$ ) { this.router$.unsubscribe(); }
  }

  refresh() {
    this.slug = this.route.snapshot.firstChild.data.title;
    this.selectTab(this.slug);
    this.changeTitle(this.slug);
  }

  selectTab(slug) {
    this.changeTitle(slug);
    this.slug = slug;
  }

  isTabVisibile(tabSlug: string):boolean {
    return this.visibleTabs.includes(tabSlug);
  }

  isAuthorized(roleSlugs: string[]): boolean {
    return this.rolesService.isAuthorized(roleSlugs, this.role);
  }

  isNotAuthorized(roleSlugs: string[]): boolean {
    return this.rolesService.isNotAuthorized(roleSlugs, this.role);
  }

  private changeTitle(slug) {
    this.activeTab.name = slug;
    this.activeTab.title = this.translate.instant(`resources.coupons.tabs.${slug}`);
  }
}
