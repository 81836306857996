import { Component, OnInit, Input } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../services/question-control.service';

@Component({
  selector: 'app-calculated-values-buyer-types-segment-condition',
  templateUrl: './calculated-values-buyer-types-segment-condition.component.html',
  styleUrls: ['./calculated-values-buyer-types-segment-condition.component.css']
})
export class CalculatedValuesBuyerTypesSegmentConditionComponent implements OnInit {

  @Input('item') item: Condition;
  @Input('inputs') inputs: QuestionBase<any>[];
  @Input('form') form: UntypedFormGroup;

  constructor(private qcs: QuestionControlService) { }

  ngOnInit() {}

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any) {
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

}
