import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { PlanCampaign } from '../../plan-campaign';
import { FeaturesService } from '../../../../data-warehouse/products/features.service';
import { SegmentsService } from '../../../../segments/segments.service';
import { ProductsService } from '../../../../data-warehouse/products/products.service';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { CurrentCompany } from '../../../../../shared/models/current-user';
import { ProfileService } from '../../../../../profiles/profile.service';
import { ImportProductsComponent } from '../../../../../shared/import-products/import-products.component';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-supplier-general-inputs',
  templateUrl: './supplier-general-inputs.component.html',
  styleUrls: ['./supplier-general-inputs.component.css'],
  providers: [FeaturesService, ProductsService]
})

export class SupplierGeneralInputsComponent implements OnInit {

  public commonInputs: QuestionBase<any>[];
  public company: CurrentCompany;

  @Input() planCampaign: PlanCampaign;
  @Input() generalInputsForm: UntypedFormGroup;
  @Output() selectedIdsLoaded: EventEmitter<any>;

  @ViewChild(ImportProductsComponent, {static: true}) importProductsModal: ImportProductsComponent;

  constructor(
    private featureService: FeaturesService,
    private segmentsService: SegmentsService,
    private productService: ProductsService,
    private profileService: ProfileService,
    private qcs: QuestionControlService,
  ) {
    this.selectedIdsLoaded = new EventEmitter();
  }

  ngOnInit() {
    this.featureService.treatPkAsId = true;
    this.productService.preventUnfilteredRequests = true;
    this.productService.fetchByBatch = true;
    this.getCompanyData();
    this.setInputs(this.planCampaign);
    if (this.planCampaign && this.planCampaign.id) {
      this.commonInputs.find(item => item.key === 'product_ids').disabled = false;
    }
    this.handleProductsOnInit();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.commonInputs, inputKey);
  }

  findProduct() {
    const supplier = this.generalInputsForm.value['supplier_id'];
    const productsInp = this.commonInputs.find(item => item.key === 'product_ids');
    productsInp.value = [];
    productsInp.selectedIds = [];
    productsInp.disabled = false;
    if (supplier?.length > 0) {
      this.productService.setFeatures(supplier.map(feature => feature.rawElement.pk).join(','));
    } else {
      this.productService.setFeatures(null);
    }
  }

  onSelectedIdsLoaded() {
    this.selectedIdsLoaded.emit();
  }

  openImportProductsModal() {
    this.importProductsModal.openModal();
  }

  handleProductIdList(productIds) {
    const selectedSupplier = this.commonInputs.find(item => item.key === 'supplier_id').value;
    const ids = productIds.split('\n').filter(el => !isNullOrUndefined(el) && el !== '');
    const productsInput = this.commonInputs.find(item => item.key === 'product_ids');
    productsInput.value = [];
    productsInput.selectedIds = [];
    productsInput.selectedIds = ids;
    setTimeout(() => {
      if (selectedSupplier && selectedSupplier.length > 0) {
        this.productService.setFeatures(selectedSupplier[0].id);
      } else {
        this.productService.setFeatures(null);
      }
    });
  }

  private handleProductsOnInit() {
    const supplier = this.commonInputs.find(item => item.key === 'supplier_id').selectedIds;
    if (supplier.length > 0) {
      this.productService.setFeatures(supplier.map(feature => feature).join(','));
    }
  }

  private getCompanyData() {
    this.company = this.profileService.getStoredUser().company;
    if (!isNullOrUndefined(this.company['plans_configuration'])) {
      const supplierTaxonomy = this.company['plans_configuration'].supplier_taxonomy;
      this.featureService.setTaxonomies(supplierTaxonomy);
    }
  }

  private getSelectedSupplier(planCampaign: PlanCampaign): number[] {
    const supplierId = planCampaign.config['supplier_id'];
    if (isNullOrUndefined(supplierId)) {return []; }
    return [supplierId];
  }

  private getSelectedSegment(planCampaign: PlanCampaign): number[] {
    const segmentId = planCampaign.config['segment_id'];
    if (isNullOrUndefined(segmentId)) {return []; }
    return [segmentId];
  }

  private setInputs(planCampaign?: PlanCampaign) {
    const inputs = [
      new TextboxQuestion({
        key: 'name',
        label: 'resources.campaign_plans.types.supplier.fields.name',
        type: 'text',
        cssClasses: 'form-control input-default',
        required: true,
        value: planCampaign ? planCampaign.name : null
      }),
      new MultiSelectQuestion({
        key: 'supplier_id',
        label: 'resources.campaign_plans.types.supplier.fields.supplier',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        required: true,
        dataSource: this.featureService,
        selectedIds: planCampaign ? this.getSelectedSupplier(planCampaign) : [],
      }),
      new MultiSelectQuestion({
        key: 'product_ids',
        label: 'resources.campaign_plans.types.supplier.fields.product',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.productService,
        useToken: true,
        disabled: true,
        selectedIds: planCampaign ? planCampaign.config['product_ids'] : []
      }),
      new MultiSelectQuestion({
        key: 'segment_id',
        label: 'resources.campaign_plans.types.supplier.fields.restricted_segment',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.segmentsService,
        selectedIds: planCampaign ? this.getSelectedSegment(planCampaign) : []
      })
    ];

    this.commonInputs = inputs;
    this.commonInputs.forEach(input => {
      this.qcs.addToFormGroup(this.generalInputsForm, input);
    });
  }
}
