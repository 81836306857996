<div class="modal-header background-white">
    <!-- MODAL HEADER TITLE -->
    <div class="row flex-grow-1">
        <div class="col-md-8 ml-auto mr-auto">
            <h1>{{ title | translate }}</h1>
        </div>
    </div>
    <!-- MODAL HEADER CLOSE BUTTON -->
    <div class="pdd-vertical-20-but-bottom">
        <button class="modal-close" (click)="closeModal()">
            <i class="ti-close"></i>
        </button>
    </div>
</div>