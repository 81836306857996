<div class="page-title background-white">
  <div class="row pdd-horizon-15">
    <div class="col-md-9 d-flex align-items-center min-height-60px">
      <h4 *ngIf="!title" class="no-mrg-vertical" [translate]="'dashboards.customers.title'"></h4>
      <h4 *ngIf="title" class="no-mrg-vertical" [innerText]="title"></h4>
    </div>
  </div>
</div>

<ul class="nav nav-tabs w-100" role="tablist" *ngIf="visibleTabs.length <= 1">
  <div>
    <a class="legend-positioning" [routerLink]="['', { outlets: { modal: ['show', 'legend'] }}]">
      <i style="margin-top: -20px;" class="ti-info-alt font-size-24 text-primary"></i>
    </a>
  </div>
</ul>

<ul class="nav nav-tabs w-100" role="tablist" *ngIf="visibleTabs.length > 1">
  <li class="nav-item" *ngIf="isTabVisible('summary')">
    <a (click)="selectTab('summary')"
        [routerLink]="['/customer-data-platform/dashboard/customers/summary']"
        class="nav-link {{activeClass('summary')}}"
        data-toggle="tab"
        href="#customers-tab-1"
        role="tab"
        aria-selected="true"
        [translate]="'dashboards.customers.tabs.customers.title'">
    </a>
  </li>
  <li class="nav-item" *ngIf="isTabVisible('details')">
    <a (click)="selectTab('details')"
        class="nav-link {{activeClass('details')}}"
        [routerLink]="['/customer-data-platform/dashboard/customers/details']"
        data-toggle="tab"
        href="#customers-tab-2"
        role="tab"
        aria-selected="false"
        [translate]="'dashboards.customers.tabs.consistence.title'">
    </a>
  </li>
  <li class="nav-item" *ngIf="isTabVisible('type-redemptions') && flags.redeemedVsNonRedeemedTabVisibility">
    <a (click)="selectTab('type-redemptions')"
        class="nav-link {{activeClass('type-redemptions')}}"
        [routerLink]="['/customer-data-platform/dashboard/customers/type-redemptions']"
        data-toggle="tab"
        href="#customers-tab-3"
        role="tab"
        aria-selected="false"
        [translate]="'dashboards.customers.tabs.type_redemptions.title'">
    </a>
  </li>
  <li class="nav-item" *ngIf="isTabVisible('contacts') && flags.customerContactsTabVisibility">
    <a (click)="selectTab('contacts')"
        class="nav-link {{activeClass('contacts')}}"
        [routerLink]="['/customer-data-platform/dashboard/customers/contacts']"
        data-toggle="tab"
        href="#customers-tab-4"
        role="tab"
        aria-selected="false"
        [translate]="'dashboards.customers.tabs.contacts.title'">
    </a>
  </li>
  <li class="nav-item" *ngIf="isTabVisible('customers-evolution') && flags.customersEvolutionVisibility">
    <a (click)="selectTab('customers-evolution')"
        class="nav-link {{activeClass('customers-evolution')}}"
        [routerLink]="['/customer-data-platform/dashboard/customers/customers-evolution']"
        data-toggle="tab"
        href="#customers-tab-5"
        role="tab"
        aria-selected="false"
        [translate]="'dashboards.customers.tabs.customers_evolution.title'">
    </a>
  </li>
  <li class="nav-item" *ngIf="isTabVisible('retention') && flags.customersRetentionVisibility">
    <a (click)="selectTab('retention')"
        [routerLink]="['/customer-data-platform/dashboard/customers/retention']"
        class="nav-link {{activeClass('retention')}}"
        data-toggle="tab"
        href="#customers-tab-6"
        role="tab"
        aria-selected="true"
        [translate]="'dashboards.customers.tabs.retention.title'">
    </a>
  </li>
  <div>
    <a class="legend-positioning" [routerLink]="['', { outlets: { modal: ['show', 'legend'] }}]">
      <i class="mrg-top-4 ti-info-alt font-size-24 text-info"></i>
    </a>
  </div>
</ul>

<div class="container-fluid mrg-top-15">
  <div class="row">
    <div class="col-md-12">

      <app-data-table-filter-analytics *ngIf="filters && enabledFilters()"
                              [slug]="filterSlug"
                              [questions]="filters"
                              [defaultQuestions]="defaultFilters"
                              (onSubmitEmitter)="onFilterHandler($event)"
                              [cfg]="filterConfig">
      </app-data-table-filter-analytics>

      <div class="tab-info">
        <div class="pdd-bottom-20 position-relative">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
