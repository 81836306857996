import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { ConfirmationService } from '../../shared/services/confirmation.service';
import { DomSanitizer } from '@angular/platform-browser';
import { getRandomNumber } from '../../shared/utils/common.utils';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { ProfileService } from '../../profiles/profile.service';
import { sha256 } from 'js-sha256';
import { Subscription, combineLatest, finalize } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '../../resources/users/users.service';
import { LAST_USER_SESSION_ACTIVITY_LOCAL_STORAGE } from '../../shared/constants/local-storage.constants';

enum MODE {
  Activation,
  ActivationError,
  LoadingHome,
  Login,
  RecoverPassword,
  ResetExpiredPassword,
  ResetPassword,
  SSOErrorSignIn,
  SSOSignIn
}

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  providers: [UsersService]
})

export class SigninComponent implements OnInit, OnDestroy {

  activeMode: MODE;
  currentYear = new Date().getFullYear();
  loading = false;
  signinRoute = '/signin';

  private activationToken: string;
  private companySlug: string;
  private subs$: Subscription[] = [];
  readonly MODE = MODE;

  constructor(
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private usersService: UsersService
  ) { }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      const userRoleSlug: string = this.profileService.getStoredUser().role.slug;
      this.authService.redirectToMainPage(userRoleSlug);
    } else {
      localStorage.removeItem(LAST_USER_SESSION_ACTIVITY_LOCAL_STORAGE);
      this.setPageMode();
      this.getParams();
    }
  }

  ngOnDestroy(): void {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  // <----------------------------------------------------------
  // Handlers

  onSignin(form: NgForm): void {
    this.loading = true;
    const codeChallenge = this.generateRandomString();
    const encodedChallenge = this.encodeString(codeChallenge);

    this.authService.authorizeUser(encodedChallenge).subscribe({
      next: response => {
        const { email, password } = form.value;
        const loginPayload = {
          email,
          password,
          authorization: response['authorization'],
          code_verifier: codeChallenge
        };
        this.login(loginPayload);
      },
      error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
    });
  }

  onSignInWithSSO(form: NgForm): void {
    this.loading = true;
    const codeChallenge = this.generateRandomString();
    const encodedChallenge = this.encodeString(codeChallenge);

    this.authService.authorizeUser(encodedChallenge).subscribe({
      next: response => {
        const { email } = form.value;
        const ssoSignInPayload = {
          email,
          authorization: response['authorization'],
          code_verifier: codeChallenge
        };
        this.signInWithSSO(ssoSignInPayload);
      },
      error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
    });
  }

  onValidateEmail(): void {
    this.activeMode = MODE.Activation;
    this.activateUser();
  }
  // Handlers
  // ---------------------------------------------------------->

  // <----------------------------------------------------------
  // Login

  private login(payload: { email: string }): void {
    this.authService.login(payload).subscribe({
      next: () => this.handleLoginSuccess(),
      error: (errorData: HttpErrorResponse) => {
        const errorValue = errorData?.error;
        if (errorValue?.reason === 'password_expired' && errorValue?.reset_password_token) {
          this.router.navigate([`/expired-password/${errorValue.reset_password_token}`]).catch(() => {});
        }
        else if(errorValue?.reason === 'blocked') {
          this.handleBlockedLoginError();
        } else {
          this.handleLoginError(errorData);
        }
      }
    });
  }

  private handleLoginSuccess(): void {
    this.loading = false;
    this.authService.setUserAuthToken();
    this.authService.afterLoginPath(this.profileService.getCurrentUserProfile());
  }

  private handleLoginError(err: HttpErrorResponse): void {
    this.loading = false;
    this.confirmationService.displayAlert(
      this.translateService.instant('components.signin.titles.login'),
      err.error.error, 'warning'
    ).catch(() => {});
  }

  private handleBlockedLoginError(): void {
    this.loading = false;
    this.confirmationService.displayAlert(
      this.translateService.instant('components.signin.titles.login'),
      this.translateService.instant('components.signin.errors.blocked'),
      'warning'
    ).catch(() => {});
  }
  // Login
  // ---------------------------------------------------------->

  // <----------------------------------------------------------
  // Sign in with SSO

  private signInWithSSO(payload: { email: string, authorization: string, code_verifier: string }): void {
    this.authService.ssoSignIn(payload).subscribe({
      next: (response: HttpResponse<object>) => {
        this.loading = false;
        window.location.href = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(response.url));
      },
      error: (errorData: HttpErrorResponse) => this.handleLoginError(errorData)
    });
  }
  // Sign in with SSO
  // ---------------------------------------------------------->

  // <----------------------------------------------------------
  // Init

  private setPageMode(): void {
    const url = this.router.url;
    switch (true) {
      case url.includes('recover-password'):
        this.activeMode = MODE.RecoverPassword;
        break;
      case url.includes('edit_password'):
        this.activeMode = MODE.ResetPassword;
        break;
      case url.includes('expired-password'):
        this.activeMode = MODE.ResetExpiredPassword;
        break;
      case url.includes('users/activate'):
        this.activeMode = MODE.Activation;
        break;
      case url.includes('sso-signin'):
        this.activeMode = MODE.SSOSignIn;
        break;
      case url.includes('sso-error-signin'):
        this.activeMode = MODE.SSOErrorSignIn;
        break;
      case url.includes('loading-home'):
        this.activeMode = MODE.LoadingHome;
        this.handleLoginSuccess();
        break;
      default:
        this.activeMode = MODE.Login;
        break;
    }
  }

  private getParams(): void {
    const combined$ = combineLatest([this.route.params, this.route.queryParams]);
    const subscription = combined$.subscribe(
      ([params, queryParams]) => {
        this.activationToken = params.activation_token;
        this.companySlug = queryParams['company'];
        this.checkIfUserActivable();
      }
    );
    this.subs$.push(subscription);
  }

  private checkIfUserActivable(): void {
    if (this.activeMode === MODE.Activation) {
      if (this.activationToken && this.companySlug) {
        this.activateUser();
      } else {
        this.redirectToLogin();
      }
    }
  }

  private activateUser(): void {
    this.loading = true;
    this.usersService.activateUser(this.activationToken, this.companySlug).pipe(finalize(() => this.loading = false)).subscribe({
      next: () => {
        this.confirmationService.displaySuccessAlert(
          this.translateService.instant('components.signin.messages.activation_success_title'),
          this.translateService.instant('components.signin.messages.activation_success_desc')
        ).catch(() => {});
        this.redirectToLogin();
      },
      error: (errorData: HttpErrorResponse) => {
        this.confirmationService.displayHttpErrorAlert(errorData);
        this.activeMode = MODE.ActivationError;
      }
    });
  }
  // Init
  // ---------------------------------------------------------->

  // <----------------------------------------------------------
  // Helpers
  private generateRandomString(): string {
    // generate 10 digit alphanumeric random string
    return getRandomNumber().toString(32).slice(2);
  }

  private encodeString(code: string): string {
    // encode string with SHA-256 pattern
    return sha256(code);
  }

  private redirectToLogin(): void {
    this.router.navigate(['/signin']).catch(() => {});
  }
  // Helpers
  // ---------------------------------------------------------->
}
