import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { map, switchMap, takeWhile } from 'rxjs/operators';

@Injectable()
export class RefreshCacheService {

  private interval = 4000;

  constructor(private http: HttpClient) {}

  public getData(endpoint) {
    return timer(0, this.interval).pipe(
           switchMap(() => this.http.get(endpoint)),
           takeWhile((data) => this.isRequestPending(data), true),
           map((data) => data));
  }

  public getExpensiveData(requestData): Observable<any> {

    const paramsObj = this.parseParams(requestData);

    return timer(0, this.interval).pipe(
           switchMap(() => this.http.get(requestData.apiEndPoint, { params: paramsObj })),
           takeWhile((data) => this.isRequestPending(data), true),
           map((data) => data));
  }

  public putExpensiveData(endpoint: string, payload: Object): Observable<any> {

    return timer(0, this.interval).pipe(
           switchMap(() => this.http.put(endpoint, payload)),
           takeWhile((data) => this.isRequestPending(data), true),
           map((data) => data));
  }

  public postExpensiveData(endpoint: string, requestData): Observable<any> {

    return timer(0, this.interval).pipe(
           switchMap(() => this.http.post(endpoint, requestData)),
           takeWhile((data) => this.isRequestPending(data), true),
           map((data) => data))
  }

  private parseParams(requestData): HttpParams {

    let paramsObj: HttpParams = new HttpParams();

    // Sorting
    if (requestData.sorting && requestData.sorting.byProp && requestData.sorting.direction) {
      paramsObj = paramsObj.append('order_by', requestData.sorting.byProp);
      paramsObj = paramsObj.append('order_sense', requestData.sorting.direction);
    }

    // Page
    if (requestData.pageNumber) {
      paramsObj = paramsObj.append('page', requestData.pageNumber.toString());
    }

    // Data per page
    if (requestData.numberPerPage) {
      paramsObj = paramsObj.append('per_page', requestData.numberPerPage.toString());
    }

    // Iterate filters to add to request.  Remove filters with null values.
    if (requestData.filtering && Object.keys(requestData.filtering).length) {
      Object.keys(requestData.filtering).forEach(function (key) {
        if (requestData.filtering[key] != null) {
          paramsObj = paramsObj.append(key, requestData.filtering[key]);
        }
      });
    }

    // Email notification
    if (requestData.hasOwnProperty('emailNotification')) {
      paramsObj = paramsObj.append('email_notification', requestData.emailNotification.toString());
    }

    return paramsObj;
  }

  public isRequestPending(reqResponse): boolean {
    if ( reqResponse.hasOwnProperty('list') && reqResponse.list.length === 0 ) { return false; }
    return reqResponse['status'] === 'data_pending' || ( reqResponse['list'] instanceof Array &&
                                                         reqResponse['list'][0] &&
                                                         reqResponse['list'][0].hasOwnProperty('status') &&
                                                         reqResponse['list'][0]['status'] === 'data_pending' );
  }

  public isRequestFailed(reqResponse): boolean {
    if ( reqResponse.hasOwnProperty('list') && reqResponse.list.length === 0 ) { return false; }
    return reqResponse['status'] === 'error';
  }
}
