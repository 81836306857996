import { Injectable } from '@angular/core';
import { ResourcesService } from './resources.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MultiselectDataSource } from '../components/multiselect/multiselect';
import { DictionaryService } from './dictionary.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CountriesService extends ResourcesService {

  parsedCountriesList: any;
  isAllCountriesOption: boolean = false;

  constructor(
    http: HttpClient,
    private dictionarySrv: DictionaryService,
    private translate: TranslateService
  ) {
    super(http);
  }

  getCountries(mode: 'default' | 'phone_prefixes' = 'default') {
    const list = this.dictionarySrv.getValuesByKey('countries');
    this.parsedCountriesList = list.map(element => {
      const translatedName = this.translate.instant(element.name);
      const name = mode === 'default' ? `(${element.id}) ${translatedName}` : `(+${element.dial_code}) ${translatedName}`;
      return {
        id: element.id,
        name: name,
        rawElement: { ...element, name: translatedName }
      };
    });
    
    this.parsedCountriesList.sort((a, b) => a.rawElement.name.localeCompare(b.rawElement.name));
    
    if (this.isAllCountriesOption) {
      this.parsedCountriesList.unshift({id: null, name: this.translate.instant('common.all')});
    }

    return this.parsedCountriesList;
  }

  getCountriesById(id: string) {
    return this.parsedCountriesList.find(country => country.id === id);
  }

  getCountriesByCode(code: string): Observable<any> {
    return this.getData({apiEndPoint: `countries/${code}`});
  }

  fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {

    let requestOptions = {
      apiEndPoint: 'countries',
      filtering: {}
    };

    if (searchValues) {
      const filterObj = { filtering: { search_text: searchValues, filter_logic: 'or' }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  getNameWithTemplate(_element: any, _id): MultiselectDataSource {
    const element = _element.hasOwnProperty('list') ? _element.list.find( el => el.code === _id ) : _element;
    return new MultiselectDataSource(element.code, `(${element.code}) ${element.name}`, element);
  }

  fetchSelectedById(code: string): Observable<object> {
    return this.getCountriesByCode(code);
  }
  
  getCountryName(id: string): string | null {
    const country = this.getCountries().find(country => country.id === id);
    return country ? country.name : null;
  }
  
  setIsAllCountriesOption(isAllCountriesOption: boolean) {
    this.isAllCountriesOption = isAllCountriesOption;
  }
}
