<div class="row">
  <div class="col-md-12">
    <app-data-table-filter *ngIf="filters" [questions]="filters" (onSubmitEmitter)="onFilterHandler($event)"></app-data-table-filter>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-block" *ngIf="resource && !loadingData; else loadingSynchroData">

        <label class="text-bold">{{'resources.synchros.show.execution_date' | translate}}</label>
        <p>
          {{resource.completed_at ? (resource.completed_at | dateTimeZone: 'DD/MM/YYYY HH:mm:ss') : emptyValue}}
        </p>

        <label class="text-bold">{{'resources.synchros.show.state' | translate}}</label>
        <p>
          <span *ngIf="!resource.status">{{emptyValue}}</span>
          <span class="label label-success" *ngIf="resource.status === 'completed'">{{'resources.synchros.filters.status_options.completed' | translate}}</span>
          <span class="label label-warning" *ngIf="resource.status === 'completed_with_errors'">{{'resources.synchros.filters.status_options.completed_with_errors' | translate}}</span>
          <span class="label label-warning" *ngIf="resource.status === 'started'">{{'resources.synchros.filters.status_options.started' | translate}}</span>
          <span class="label label-danger" *ngIf="resource.status === 'error'">{{'resources.synchros.filters.status_options.error' | translate}}</span>
          <span class="label label-info" *ngIf="resource.status === 'pending'">{{'resources.synchros.filters.status_options.pending' | translate}}</span>
        </p>

        <ng-container *ngIf="!loadingFilteredData; else loadingSynchroData">
          <div class="table-responsive">
            <table class="table" aria-hidden="true" *ngIf="resource?.fileList?.length > 0">
              <thead>
                <th class="pointer text-link" (click)="doSort('resource_name')">{{'resources.synchros.show.resource' | translate}}</th>
                <th>{{'resources.synchros.show.file' | translate}}</th>
                <th class="pointer text-link" (click)="doSort('inserts')">{{'resources.synchros.show.inserts' | translate}}</th>
                <th class="pointer text-link" (click)="doSort('updates')">{{'resources.synchros.show.updates' | translate}}</th>
                <th class="pointer text-link" (click)="doSort('status')">{{'resources.synchros.show.state' | translate}}</th>
                <th class="pointer text-link" (click)="doSort('errors_count')">{{'resources.synchros.show.errors' | translate}}</th>
                <th>
                  <div class="d-flex align-items-center mrg-right-30">
                    <span>{{'resources.synchros.show.report' | translate}}</span>
                    <em class="fa-light fa-circle-info mrg-left-5" tooltipPosition="top" pTooltip="{{ 'resources.synchros.show.report_tooltip' | translate }}"></em>
                  </div>
                </th>
              </thead>
              <tbody>
                <tr *ngFor="let synchro_file of resource.fileList">
                  <td>
                    <p>{{synchro_file.resource_name | emptyData}}</p>
                    <p>
                      <span class="label label-info" *ngIf="synchro_file.action == 'update'">{{'resources.synchros.show.update' | translate}}</span>
                      <span class="label label-info" *ngIf="synchro_file.action == 'create'">{{'resources.synchros.show.insert' | translate}}</span>
                      <p>
                  </td>
                  <td>
                    <strong>{{'resources.synchros.show.original_file' | translate}}:</strong><br>
                    <small>{{synchro_file.file_info | emptyData}}</small><br>
                    <strong>{{'resources.synchros.show.processed_file' | translate}}:</strong><br>
                    <small *ngIf="['completed', 'completed_with_errors'].indexOf(synchro_file.status)>=0">
                      <ng-container *ngIf="synchro_file.file_archived_path; else noFileArchivedPath">
                        <a href="{{synchro_file.file_download_url}}">{{synchro_file.file_archived_path}}</a>
                      </ng-container>
                      <ng-template #noFileArchivedPath>{{emptyValue}}</ng-template>
                    </small>
                    <small [hidden]="['completed', 'completed_with_errors'].indexOf(synchro_file.status)>=0">
                      {{synchro_file.file_archived_path | emptyData}}
                    </small>
                  </td>
                  <td>{{synchro_file.inserts | number: '1.0-0'}}</td>
                  <td>{{synchro_file.updates | number: '1.0-0'}}</td>
                  <td>
                    <span *ngIf="!synchro_file.status">{{emptyValue}}</span>
                    <span class="label label-success" *ngIf="synchro_file.status === 'completed'">
                      {{'resources.synchros.index.with_success' | translate}}
                    </span>
                    <span class="label label-warning" *ngIf="synchro_file.status === 'completed_with_errors'">
                      {{'resources.synchros.index.with_errors' | translate}}
                    </span>
                    <span class="label label-danger" *ngIf="synchro_file.status === 'invalid'">
                      {{'resources.synchros.show.invalid' | translate}} {{synchro_file.error_message}}
                    </span>
                    <span class="label label-warning" *ngIf="synchro_file.status === 'started'">
                      {{'resources.synchros.index.syncing' | translate}}
                    </span>
                    <span class="label label-info" *ngIf="synchro_file.status === 'pending'" translate>
                      {{'resources.synchros.index.pending' | translate}}
                    </span>
                    <span class="label label-default" *ngIf="synchro_file.status === 'duplicated'" translate>
                      {{'resources.synchros.index.duplicated' | translate}}
                    </span>
                  </td>
                  <td>{{synchro_file.errors_count | number: '1.0-0'}}</td>
                  <td>
                    <ng-container *ngIf="synchro_file?.errors_count > 0; else noErrorsCount">
                      <a *ngIf="synchro_file.action === 'create'" target="_blank"
                        [routerLink]="['/notifications/s3_files']"
                        [queryParams]="{file: synchro_file.csv_errors,
                                        bucket: 'loyalguru-logs',
                                        region: 'eu-west-1'}">{{'resources.synchros.show.download' | translate}}
                      </a>
                      <a *ngIf="synchro_file.action === 'update'" target="_blank"
                          [routerLink]="['/notifications/s3_files']"
                          [queryParams]="{file: synchro_file.csv_errors,
                                          bucket: 'loyal-guru-default',
                                          region: 'us-east-1'}">{{'resources.synchros.show.download' | translate}}
                      </a>
                    </ng-container>
                    <ng-template #noErrorsCount>
                      <span>{{emptyValue}}</span>
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
      </div>
      <ng-template #loadingSynchroData>
        <app-loader styleClass="pdd-vertical-10" noWrapper/>
      </ng-template>
    </div>
  </div>
</div>
