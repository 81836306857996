import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../services/question-control.service';
import { MultiselectDataSource } from '../../../multiselect/multiselect';
import { LocationsService } from '../../../../../resources/data-warehouse/locations/locations.service';
import { handleSelectedLocationTaxonomyTermIdsField, resetInputsForm } from '../../utils/common.utils';
import { Operator } from '../../../../../shared/models/segments/conditions/operator';
import { FeatureFlagsService } from '../../../../../shared/services/feature-flags.service';

@Component({
  selector: 'app-taxonomies-count-condition',
  templateUrl: './taxonomies-count-condition.component.html',
  styleUrls: ['./taxonomies-count-condition.component.css']
})

export class TaxonomiesCountConditionComponent {

  flags = this.featureFlags.flags;

  @Input() item: Condition;
  @Input() inputs: QuestionBase<unknown>[];
  @Input() form: UntypedFormGroup;

  constructor(
    private qcs: QuestionControlService,
    private locationsService: LocationsService,
    private changeDetector: ChangeDetectorRef,
    private featureFlags: FeatureFlagsService
  ) {}

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: unknown) {
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  handleOperatorChanged(operator: Operator): void {
    const defaultFieldsToReset = ['feature_count_bt', 'include_zero'];
    const fieldsToReset = operator ? defaultFieldsToReset : [...defaultFieldsToReset, 'operator'];
    resetInputsForm(this.form, this.inputs, fieldsToReset);
  }

  handleSelectedLocationTaxonomyTermIds(locationTaxonomyTermIdsSelected: MultiselectDataSource[]): void {
    handleSelectedLocationTaxonomyTermIdsField(
      locationTaxonomyTermIdsSelected,
      'location_ids',
      this.getInputConfig('location_ids'),
      this.form,
      this.changeDetector,
      this.locationsService
    );
  }
}
