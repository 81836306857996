<div class="alert alert-info" role="alert" *ngIf="!onSearch && !loading">
  <span [translate]="'components.looker-iframe.alerts.apply_filters'"></span>
</div>

<div class="row" *ngIf="onSearch">
  <div class="col-lg-12">
    <div class="card">

      <div class="card-heading">
        <h4 class="card-title d-inline" [translate]="'dashboards.club.tabs.constant_customers.title'"></h4>
        <i class="tooltip-position ti-info-alt mrg-top-10 font-size-14 pull-right"
            tooltipPosition="left"
            pTooltip="{{ 'dashboards.club.tabs.constant_customers.tooltip' | translate }}"></i>
      </div>

      <app-loader styleClass="mrg-btm-15" *ngIf="loading" />

      <div class="card-block no-pdd no-mrg pdd-horizon-20 pdd-btm-5">
        <table class="table table-hover table-sm table-bordered" aria-hidden="true" *ngIf="!loading">
          <thead>
            <tr class="highlight-header">
              <th scope="auto" colspan="1"></th>
              <th scope="auto" colspan="1" class="text-center">
                <i class="ei-employees"></i>
                {{ 'dashboards.club.tabs.constant_customers.columns.constant_clients' | translate }}
              </th>
              <th scope="auto" colspan="3" class="text-center">
                <i class="ei-euro"></i>
                {{ 'dashboards.club.tabs.constant_customers.columns.sales_constant_clients' | translate }}
              </th>
              <th scope="auto" colspan="3" class="text-center">
                <i class="ei-euro"></i>
                {{ 'dashboards.club.tabs.constant_customers.columns.sales_no_constant_clients' | translate }}
              </th>
            </tr>
            <tr class="highlight-header">
              <th scope="auto" class="text-center"></th>
              <th scope="auto" class="text-center" [innerText]="filter.year + ' - ' + filter.compared_year"></th>
              <th scope="auto" class="text-center" [innerText]="filter.year"></th>
              <th scope="auto" class="text-center" [innerText]="filter.compared_year"></th>
              <th scope="auto" class="text-center" [innerText]="filter.year + ' - ' + filter.compared_year"></th>
              <th scope="auto" class="text-center" [innerText]="filter.year"></th>
              <th scope="auto" class="text-center" [innerText]="filter.compared_year"></th>
              <th scope="auto" class="text-center" [innerText]="filter.year + ' - ' + filter.compared_year"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of clientsConstantData; let i = index;" [ngClass]="{'highlight-header': i === clientsConstantData.length - 1}">
              <td class="text-left pdd-left-20">
                <b>{{ row.month }}</b>
              </td>
              <td class="text-right">
                <span class="font-size-12 label label-info">
                  {{row.clientsCount | number: '1.0-0'}}
                </span>
              </td>
              <td class="text-right">
                <span class="label label-default font-size-12">{{row.yearSalesConstant | lgcurrency: '1.0-0'}}</span>
              </td>
              <td class="text-right">
                <span class="label label-default font-size-12">{{row.yearToCompareSalesConstant | lgcurrency: '1.0-0'}}</span>
              </td>
              <td class="text-right">
                <span class="label font-size-12"
                      *ngIf="row.yearSalesDiff != null"
                      [ngClass]="{ 'label-success': row.yearSalesDiff > 0.0,
                                   'label-warning': (row.yearSalesDiff | number: '1.2-2') === '0,00',
                                   'label-danger': row.yearSalesDiff < 0.0 }">
                  {{row.yearSalesDiff | number: '1.2-2'}} %
                </span>
              </td>
              <td class="text-right">
                <span class="label label-default font-size-12">{{row.yearSalesNoConstant | lgcurrency: '1.0-0'}}</span>
              </td>
              <td class="text-right">
                <span class="label label-default font-size-12">{{row.yearToCompareSalesNoConstant | lgcurrency: '1.0-0'}}</span>
              </td>
              <td class="text-right">
                <span class="label font-size-12"
                      *ngIf="row.yearSalesNoConstantDiff != null"
                      [ngClass]="{ 'label-success': row.yearSalesNoConstantDiff > 0.0,
                                   'label-warning': (row.yearSalesNoConstantDiff | number: '1.2-2') === '0,00',
                                   'label-danger': row.yearSalesNoConstantDiff < 0.0 }">
                  {{row.yearSalesNoConstantDiff | number: '1.2-2'}} %
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
