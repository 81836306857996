import { DateService } from '../../services/date.service';

export class Notification {

  date: string;
  priority: number;
  text: string;
  timestamp: number;
  title: string;
  type: string;

  constructor( public options , public dateService: DateService) {
    this.priority = parseInt(options.priority, 10);
    this.timestamp = parseInt(options.timestamp, 10) * 1000;
    this.date = dateService.parseNumericDateWithFormatFromNow(this.timestamp);
    this.text = options.text;
    this.title = options.title;
    this.type = options.type;
  }
}

export const NotificationMock = [
  {
    priority: 1578475843,
    timestamp: 1578475843,
    text: "\u003cp\u003eCampaña: Test enviada correctamente\u003c/p\u003e \u003cbr\u003e \u003ca class='btn btn-xs btn-default' target='_blank' href='/app/#/admin/campaigns/6373'\u003eVer la campaña\u003c/a\u003e",
    title: 'Exportación de segmento realizada',
    type: 'info'
  }, {
    priority: 1578474015,
    timestamp: 1578474015,
    text: "\u003cp\u003eCampaña: Test enviada correctamente\u003c/p\u003e \u003cbr\u003e \u003ca class='btn btn-xs btn-default' target='_blank' href='/app/#/admin/campaigns/6373'\u003eVer la campaña\u003c/a\u003e",
    title: 'Campaña programada',
    type: 'error'
  }, {
    priority: '1549271924',
    timestamp: 1549271924,
    text: "<p>Campaña: POT A SMS - D/E  S2 FEB se ha programado correctamente</p> <br> <a class='btn btn-xs btn-default' target='_blank' href='/#/admin/campaigns/2223'>Ver campaña</a>",
    title: 'Campaña programada',
    type: 'warning'
  }, {
    priority: '1549271924',
    timestamp: 1549271924,
    text: "<p>Campaña: POT A SMS - D/E  S2 FEB se ha programado correctamente</p> <br> <a class='btn btn-xs btn-default' target='_blank' href='/#/admin/campaigns/2223'>Ver campaña</a>",
    title: 'Campaña programada',
    type: 'success'
  }, {
    priority: '1549271924',
    timestamp: 1549271924,
    text: "<p>Campaña: POT A SMS - D/E  S2 FEB se ha programado correctamente</p> <br> <a class='btn btn-xs btn-default' target='_blank' href='/#/admin/campaigns/2223'>Ver campaña</a>",
    title: 'Campaña programada',
    type: 'info'
  }, {
    priority: '1549271924',
    timestamp: 1549271924,
    text: "<p>Campaña: POT A SMS - D/E  S2 FEB se ha programado correctamente</p> <br> <a class='btn btn-xs btn-default' target='_blank' href='/#/admin/campaigns/2223'>Ver campaña</a>",
    title: 'Campaña programada',
    type: 'info'
  }, {
    priority: '1549271924',
    timestamp: 1549271924,
    text: "<p>Campaña: POT A SMS - D/E  S2 FEB se ha programado correctamente</p> <br> <a class='btn btn-xs btn-default' target='_blank' href='/#/admin/campaigns/2223'>Ver campaña</a>",
    title: 'Campaña programada',
    type: 'info'
  }, {
    priority: '1549271924',
    timestamp: 1549271924,
    text: "<p>Campaña: POT A SMS - D/E  S2 FEB se ha programado correctamente</p> <br> <a class='btn btn-xs btn-default' target='_blank' href='/#/admin/campaigns/2223'>Ver campaña</a>",
    title: 'Campaña programada',
    type: 'info'
  }, {
    priority: '1549271924',
    timestamp: 1549271924,
    text: "<p>Campaña: POT A SMS - D/E  S2 FEB se ha programado correctamente</p> <br> <a class='btn btn-xs btn-default' target='_blank' href='/#/admin/campaigns/2223'>Ver campaña</a>",
    title: 'Campaña programada',
    type: 'info'
  }, {
    priority: '1549271924',
    timestamp: 1549271924,
    text: "<p>Campaña: POT A SMS - D/E  S2 FEB se ha programado correctamente</p> <br> <a class='btn btn-xs btn-default' target='_blank' href='/#/admin/campaigns/2223'>Ver campaña</a>",
    title: 'Campaña programada',
    type: 'info'
  }, {
    priority: '1549271924',
    timestamp: 1549271924,
    text: "<p>Campaña: POT A SMS - D/E  S2 FEB se ha programado correctamente</p> <br> <a class='btn btn-xs btn-default' target='_blank' href='/#/admin/campaigns/2223'>Ver campaña</a>",
    title: 'Campaña programada',
    type: 'info'
  }, {
    priority: '1549271924',
    timestamp: 1549271924,
    text: "<p>Campaña: POT A SMS - D/E  S2 FEB se ha programado correctamente</p> <br> <a class='btn btn-xs btn-default' target='_blank' href='/#/admin/campaigns/2223'>Ver campaña</a>",
    title: 'Campaña programada',
    type: 'info'
  }, {
    priority: '1549271924',
    timestamp: 1549271924,
    text: "<p>Campaña: POT A SMS - D/E  S2 FEB se ha programado correctamente</p> <br> <a class='btn btn-xs btn-default' target='_blank' href='/#/admin/campaigns/2223'>Ver campaña</a>",
    title: 'Campaña programada',
    type: 'info'
  }, {
    priority: '1549271924',
    timestamp: 1549271924,
    text: "<p>Campaña: POT A SMS - D/E  S2 FEB se ha programado correctamente</p> <br> <a class='btn btn-xs btn-default' target='_blank' href='/#/admin/campaigns/2223'>Ver campaña</a>",
    title: 'Campaña programada',
    type: 'info'
  }, {
    priority: '1549271924',
    timestamp: 1549271924,
    text: "<p>Campaña: POT A SMS - D/E  S2 FEB se ha programado correctamente</p> <br> <a class='btn btn-xs btn-default' target='_blank' href='/#/admin/campaigns/2223'>Ver campaña</a>",
    title: 'Campaña programada',
    type: 'info'
  }
];
