<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-body height-100">
				<div id='bee-plugin-container'></div>				
			</div>

			<div class="modal-footer">

				<div class="toLeft">
					<div class="form-inline" *ngIf="templateData">
						<label class="text-bold text-white">{{'resources.campaign_templates.name' | translate}}: </label>
						<input class="mrg-left-15 width-50-vh" type="text" [(ngModel)]="templateData.name">
					</div>
				</div>
				<button class="btn btn-info" (click)="confirmClose()" [translate]="'resources.campaign_templates.modal.buttons.close'"></button>
			</div>
		</div>
	</div>
</div>
