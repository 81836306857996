import { Component, Input } from "@angular/core";
import { TicketDetail, VoucherTicketDetail } from "../../../../../shared/models/purchases/ticket-detail";

@Component({
  selector: 'app-coupons-block',
  templateUrl: './coupons-block.component.html',
  styleUrls: ['./coupons-block.component.scss']
})

export class CouponsBlockComponent {

  @Input() ticketData: TicketDetail;
  @Input() currencySymbol: string;
  @Input() vouchersData: VoucherTicketDetail[];
  @Input() totalDiscount: number;

  constructor(
    //
  ) {}

  scroll(lineOrder: number) {
    const element = document.getElementById("line" + lineOrder);
    if(element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  }
}
