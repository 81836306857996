import { NumberSymbol } from "@angular/common";
import { isNullOrUndefined } from "util";

export class PlanCampaign {
  id: number;
  plan_id: number;
  name: string;
  category: string;
  config: Object;
  type: string;
  status: string;
  userAudienceStartDate: string;
  userAudienceEndDate: string;
  requiredEmailCredits: number;
  requiredSmsCredits: number;

  constructor(obj: object) {
    this.id = obj['id'];
    this.plan_id = obj['plan_id'];
    this.config = obj['config'];
    this.type = obj['type'];
    this.status = obj['status'];
    this.name = obj['name'];
    this.category = obj['type'];
    this.userAudienceStartDate = obj?.['user_audience_start_date'] ?? null;
    this.userAudienceEndDate = obj?.['user_audience_end_date'] ?? null;
    this.requiredEmailCredits = obj?.['required_email_credits'] ?? 0;
    this.requiredSmsCredits = obj?.['required_sms_credits'] ?? 0;
  }

  getFeatureIds(): string[] {
    if(this.config.hasOwnProperty('supplier_id') && !isNullOrUndefined(this.config['supplier_id'])) { return [this.config['supplier_id']]};
    if(this.config.hasOwnProperty('feature_ids') && !isNullOrUndefined(this.config['feature_ids'])) { return this.config['feature_ids']};
    return [];
  }
}

export const PlanCampaignsMock = [
  new PlanCampaign({
    id: 101,
    name: 'Campaign plan mock #101',
    type: 'Plans::CustomCampaign',
    status: 'draft',
    config: {
      segment_ids: [6988, 6989, 6990]
    }
  }),
  new PlanCampaign({
    id: 102,
    name: 'Campaign plan mock #102',
    type: 'Plans::CustomCampaign',
    status: 'error',
    config: {
      segment_ids: [6989]
    }
  }),
  new PlanCampaign({
    id: 103,
    name: 'Campaign plan mock #103',
    type: 'Plans::SupplierCampaign',
    status: 'sending',
    config: {}
  }),
  new PlanCampaign({
    id: 102,
    name: 'Campaign plan mock #102',
    type: 'Plans::CustomCampaign',
    status: 'error',
    config: {
      segment_ids: [6989]
    }
  }),
  new PlanCampaign({
    id: 102,
    name: 'Campaign plan mock #102',
    type: 'Plans::CustomCampaign',
    status: 'scheduled',
    config: {
      segment_ids: [6989]
    }
  }),
  new PlanCampaign({
    id: 102,
    name: 'Campaign plan mock #102',
    type: 'Plans::CustomCampaign',
    status: 'delivered',
    config: {
      segment_ids: [6989]
    }
  }),
  new PlanCampaign({
    id: 105,
    name: 'Campaign plan mock #105',
    type: 'Plans::UpCrossCampaign',
    status: 'draft',
    config: {
      segment_ids: [6989],
      supplier_type: 'up',
      period_date_from: '21/12/2020',
      period_date_to: '31/12/2020',
      periods: '%30_days_ago%',
      has_bought: true,
      active_clients: true
    }
  }),
];
