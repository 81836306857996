import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourcesService } from './resources.service';
import { MultiselectDataSource, MultiselectDataSourceable } from '../components/multiselect/multiselect';
import { of, BehaviorSubject } from 'rxjs';
import { DateService } from './date.service';
import { map } from 'rxjs/operators';

@Injectable()
export class TagHistoriesService extends ResourcesService implements MultiselectDataSourceable {

  public segmentId = null;
  public optsSource = new BehaviorSubject<number>(null);
  public optsSource$ = this.optsSource.asObservable();

  constructor( http: HttpClient,
               private dateService: DateService ) {
    super(http);
  }

  public setSegment(segmentId: number) {
    this.segmentId = segmentId;
    this.optsSource.next( segmentId );
  }

  public fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {

    if ( !this.segmentId ) { return of({}); }

    let requestOptions = {
      apiEndPoint: 'tag_histories',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {
        segment_id: this.segmentId
      }
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      requestOptions.filtering['id'] = searchValues;
    } else {
      delete requestOptions.filtering['id'];
    }

    return this.getData( requestOptions );
  }

  public fetchSelectedById(id: number | string): Observable<any> {
    return this.getData({apiEndPoint: `tag_histories?segment_id=${this.segmentId}`}).pipe(
            map( itemArray => itemArray['list'] ),
            map( element => {
              return element.filter( x => `${x.id}` === `${id}` )[0];
            }));
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    const dateLabel = this.dateService.parseDateWithFormat(element.created_at, 'DD/MM/YYYY');
    return new MultiselectDataSource(element.id, `(${element.id}) ${dateLabel}`, element);
  }

  public getTagHistoriesByTagId(tagId: number): Observable<object> {
    return this.getData( { apiEndPoint: `tag_histories?tag_id=${tagId}` } );
  }

}
