import { Component, OnInit } from '@angular/core';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { PaymentMethodsService } from '../payment-methods.service';
import { ModalStatusService } from '../../../../../shared/services/modal-status.service';

@Component({
  selector: 'app-form-payment-methods',
  templateUrl: './form-payment-methods.component.html',
  styleUrls: ['./form-payment-methods.component.css'],
  providers: [ PaymentMethodsService ]
})
export class FormPaymentMethodsComponent implements OnInit {

  public paymentInputs: QuestionBase<any>[];
  public paymentMethodsForm: UntypedFormGroup;
  public inputs: any;
  public id: number;
  public showButton: boolean;
  public loading: boolean;

  constructor(private qcs: QuestionControlService,
              private route: ActivatedRoute,
              private router: Router,
              private confirmationService: ConfirmationService,
              private paymentMethodsService: PaymentMethodsService,
              private modalStatusService: ModalStatusService,
              private translate: TranslateService) {}

  ngOnInit() {
    this.getParams();
    this.showBackButton();
  }

  showBackButton() {
    this.showButton = !(this.router.url.indexOf('(modal:') >= 0);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  private getParams() {
    this.route.params.subscribe(params => {
      this.inputs = this.paymentMethodsService.getInputs();
      this.paymentMethodsForm = this.qcs.toFormGroup(this.inputs);
    });
  }

  public sendData() {

    const paymentOptions = {
      slug: this.paymentMethodsForm.value.slug
    };

    const generateTitle = this.translate.instant('resources.company.payment_methods.warnings.generate_title');
    const generateText = this.translate.instant('resources.company.payment_methods.warnings.generate_text');

    this.confirmationService.displayConfirmationAlert(generateTitle, generateText).then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.paymentMethodsService.sendData(paymentOptions).subscribe(
          response => {
            const generate_confirmation_title = this.translate.instant('resources.company.payment_methods.warnings.success_title');
            const generate_confirmation_text = this.translate.instant('resources.company.payment_methods.warnings.success_text');
            this.confirmationService.displaySuccessAlert(generate_confirmation_title, generate_confirmation_text).catch(() => {});
            this.modalStatusService.modalStatus.emit();
            this.returnToList();
          },
          error => {
            this.confirmationService.displayErrorAlert('Error', JSON.stringify(error.error.error));
          }
        );
      }
    }).catch(() => {});
  }

  public returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/config/company/payment_methods']).catch(() => {});
    }
  }

}
