<div class="row" *ngIf="slug === 'new' || slug === 'step_1'">
  <div class="col mrg-btm-20" *ngFor="let type of planCampaignTypes">
    <div class="card height-100">
      <div class="card-body text-block no-pdd no-mrg padding-30 text-center">
        <h4 class="card-title mrg-btm-20 no-mrg-top">{{type.name}}</h4>
        <p class="card-text text-left">{{type.desc}}</p>
      </div>
      <div class="text-center mrg-top-50 mrg-btm-15">
        <button *ngIf="type.enabled"
                class="btn btn-blue-lg no-mrg-btm no-mrg-right"
                [translate]="'resources.campaign_plans.buttons.select'"
                (click)="selectType(type)">
        </button>
        <button *ngIf="!type.enabled"
                class="btn btn-default no-mrg-btm no-mrg-right"
                [translate]="'resources.campaign_plans.buttons.select'"
                (click)="showNotAvailable()">
        </button>
      </div>
    </div>
  </div>
</div>
