export const HTML_BASIC_TAGS = [
    '<br>',
    '<b>',
    '</b>',
    '<p>',
    '</p>',
    '<i>',
    '</i>',
    '<u>',
    '</u>',
    '&nbsp;',
    '<strong>',
    '</strong>',
    '<em>',
    '</em>',
    '<h1>',
    '</h1>',
    '<h2>',
    '</h2>',
    '<h3>',
    '</h3>',
    '<h4>',
    '</h4>',
    '<h5>',
    '</h5>',
    '<h6>',
    '</h6>',
    '<ul>',
    '</ul>',
    '<ol>',
    '</ol>',
    '<li>',
    '</li>',
    '<a>',
    '</a>',
    '<div>',
    '</div>',
    '<span>',
    '</span>',
    '<table>',
    '</table>',
    '<tr>',
    '</tr>',
    '<td>',
    '</td>',
    '<th>',
    '</th>',
    '<hr>'
];