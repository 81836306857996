<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">

    <div class="modal-content">
      <!-- MODAL HEADER -->
      <app-coupon-header-component [mode]="{ mode, formMode }" (closeCoupon)="closeCoupon.emit()" />

      <!-- MODAL BODY -->
      <div class="modal-body height-100 background-gray overflow-auto">

        <!-- MODAL WARNINGS -->
        <app-coupon-form-warnings *ngIf="couponForm" [isUpdateHistory]="formMode === 'update_history_id'">
        </app-coupon-form-warnings>

        <!-- MODAL FORM -->
        <div class="row mrg-top-10">
          <div class="col-md-8 ml-auto mr-auto">
            <form [formGroup]="couponForm" *ngIf="couponForm">

              <!-- DESCRIPTION / BASIC INFO -->
              <app-coupon-form-section key="basic_info">
                <app-coupon-form-section-rows [inputConfig]="getInputConfig" [form]="couponForm" [section]="couponSections.basic_info_1" />

                <div class="row" *ngIf="flags.couponsAvailableLanguagesVisibility">
                  <div class="col-md-12">
                    <div class="form-group">
                      <app-language-accordions [inputs]="inputs"
                                               [form]="couponForm"
                                               [mode]="mode"
                                               [filteredAvailableLanguages]="filteredAvailableLanguages">
                      </app-language-accordions>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="!flags.couponsAvailableLanguagesVisibility">
                  <app-coupon-form-section-rows [inputConfig]="getInputConfig" [form]="couponForm" [section]="couponSections.basic_info_2" />
                </ng-container>

                <app-coupon-form-section-rows
                  [inputConfig]="getInputConfig"
                  [form]="couponForm"
                  [section]="couponSections.basic_info_3"
                  (onFileChange)="onFileChange($event)" >
                </app-coupon-form-section-rows>
              </app-coupon-form-section>

              <!-- DISCOUNT -->
              <app-coupon-form-section key="discount">
                <app-coupon-form-section-rows
                  [inputConfig]="getInputConfig"
                  [form]="couponForm"
                  [section]="couponSections.discount_1"
                  (onMultiselectChange)="handleMultiselectChange($event)" >
                </app-coupon-form-section-rows>

                <!-- Affectation variants -->
                <ng-container *ngIf="!couponTypeSelected && hasFormKeyWithValue('affectation', 'attribute')">
                  <app-coupon-form-section-rows
                    [inputConfig]="getInputConfig"
                    [form]="couponForm"
                    [section]="couponSections.discount_2"
                    (onMultiselectChange)="handleMultiselectChange($event)" >
                  </app-coupon-form-section-rows>
                </ng-container>

                <ng-container *ngIf="couponTypeSelected && hasFormKeyWithValue('affectation', 'attribute')">
                  <app-coupon-form-section-rows [inputConfig]="getInputConfig" [form]="couponForm" [section]="couponSections.discount_3" />
                </ng-container>

                <ng-container *ngIf="hasFormKeyWithValue('affectation', 'product') && flags.showCouponProductAffectation">
                  <app-coupon-form-section-rows
                    [inputConfig]="getInputConfig"
                    [form]="couponForm"
                    [section]="couponSections.discount_4"
                    (onMultiselectChange)="handleMultiselectChange($event)"
                  >
                    <div class="col-md-1">
                      <button class="btn btn-default btn-icon import-product-btn"
                              type="button"
                              tooltipPosition="top"
                              pTooltip="{{'resources.coupons.form.import_product_ids' | translate}}"
                              (click)="importProductsModal.openModal()">
                        <i class="ei-import"></i>
                      </button>
                    </div>
                  </app-coupon-form-section-rows>
                </ng-container>
                <!-- End affectation variants -->
                <ng-container *ngIf="formMode !== 'update_history_id'">
                  <app-coupon-form-section-rows [inputConfig]="getInputConfig" [form]="couponForm" [section]="couponSections.discount_5" />
                </ng-container>
              </app-coupon-form-section>

              <!-- POINTS -->
              <app-coupon-form-section key="points" *ngIf="flags.showPointsOptionDemoSelex">
                <app-coupon-form-section-rows [inputConfig]="getInputConfig" [form]="couponForm" [section]="couponSections.points" />
              </app-coupon-form-section>

              <!-- AVAILABILITY -->
              <app-coupon-form-section key="availability">
                <app-coupon-form-section-rows [inputConfig]="getInputConfig" [form]="couponForm" [section]="couponSections.availability" />
              </app-coupon-form-section>

              <!-- PRINT CFG -->
              <app-coupon-form-section key="print_cfg">
                <app-coupon-form-section-rows [inputConfig]="getInputConfig" [form]="couponForm" [section]="couponSections.print_cfg" />
              </app-coupon-form-section>

              <!-- REDEMPTION -->
              <app-coupon-form-section key="redemption">
                <app-coupon-form-section-rows [inputConfig]="getInputConfig" [form]="couponForm" [section]="couponSections.redemption" [currency]="currencySymbol" />
              </app-coupon-form-section>

              <!-- PREDICTION -->
              <app-coupon-form-section key="prediction" *ngIf="formMode !== 'update_history_id'">
                <app-coupon-form-section-rows [inputConfig]="getInputConfig" [form]="couponForm" [section]="couponSections.prediction" />
              </app-coupon-form-section>

              <!-- 3RD PARTY -->
              <app-coupon-form-section key="third_party">
                <app-coupon-form-section-rows [inputConfig]="getInputConfig" [form]="couponForm" [section]="couponSections.third_party" />
              </app-coupon-form-section>

              <!-- INTEGRATION PARTY -->
              <app-coupon-form-section key="integration">
                <app-coupon-form-section-rows [inputConfig]="getInputConfig" [form]="couponForm" [section]="couponSections.integration" />
              </app-coupon-form-section>

            </form>
          </div>
        </div>
      </div>

      <!-- MODAL FOOTER -->
      <div class="row modal-footer background-white" *ngIf="couponForm">
        <div class="col-md-8 ml-auto mr-auto text-right">
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
					<button class="btn btn-info"
                  (click)="sendData()"
                  [translate]="'common.buttons.save'"
                  [disabled]="!couponForm.valid">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-import-products #importProductsModal (productIdsEmitter)="handleImportProductList($event)"></app-import-products>
