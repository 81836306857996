import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { PlanCampaign } from '../plan-campaign';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { CheckboxQuestion } from '../../../../shared/models/forms/question-checkbox';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { isNullOrUndefined } from 'util';
import { cleanUndefinedFormValues } from '../../../../shared/services/validations.service';
import { DynamicDateQuestion } from '../../../../shared/models/forms/question-dynamic-date';
import { DateService } from '../../../../shared/services/date.service';
import { CampaignCategoriesService } from '../../../campaigns/campaign-categories/campaign-categories.service';

@Component({
  selector: 'app-up-cross-campaign-definition',
  templateUrl: './up-cross-campaign-definition.component.html',
  styleUrls: ['./up-cross-campaign-definition.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpCrossCampaignDefinitionComponent implements OnInit, OnDestroy {

  public inputs: QuestionBase<any>[];
  public form: UntypedFormGroup;
  public subs$: Subscription[];

  @Input('planCampaign') planCampaign: PlanCampaign;
  @Output('formValidity') formValidity: EventEmitter<any>;

  constructor(
    private qcs: QuestionControlService,
    private translate: TranslateService,
    private dateService: DateService,
    private campaignCategoriesSrv: CampaignCategoriesService,
  ) {
    this.formValidity = new EventEmitter();
    this.subs$ = [];
  }

  ngOnInit() {
    this.setInputs(this.planCampaign);
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  markAsDirty(str: string) {
    this.form.patchValue({upselling: true}, {emitEvent: true});
    this.form.get(str).markAsDirty();
    this.form.get(str).markAsTouched();
  }

  handlePeriodChange(periodSelection) {
    if (periodSelection) {this.form.patchValue({cross: true}, {emitEvent: true}); }
  }

  handleCheckChanged() {
    this.form.patchValue({cross: true}, {emitEvent: true});
  }

  getPayload(): Object {

    let payload = Object.assign({}, this.form.value);

    const taxonomy = this.form.value['taxonomy'];
    const feature_ids = this.form.value['feature_ids'];
    const segment = this.form.value['segment_id'];
    const periodFrom = this.form.value['period_date_from'];
    const periodTo = this.form.value['period_date_to'];

    payload['type'] = 'UpCrossCampaign';
    payload['name'] = this.form.value['name'];
    payload['config'] = {};

    if (this.form.value.upselling && !this.form.value.cross) {
      payload['config'] = {
        mode: this.parseSupplierTypeValues(),
        up_campaign_plan_category_id: this.form.value['up_campaign_plan_category_id'] && this.form.value['up_campaign_plan_category_id'].map(el => el.id)[0]
      };
    }

    if (!this.form.value.upselling && this.form.value.cross) {
      payload['config'] = {
        periods: this.form.value['periods'] ? this.form.value['periods'].map(el => el.id) : null,
        has_bought: this.form.value['has_bought'] ? this.form.value['has_bought'] : false,
        active_clients: this.form.value['active_clients'] ? this.form.value['active_clients'] : false,
        cross_campaign_plan_category_id: this.form.value['cross_campaign_plan_category_id'] && this.form.value['cross_campaign_plan_category_id'].map(el => el.id)[0],
        mode: this.parseSupplierTypeValues()
      };
    }

    if (this.form.value.upselling && this.form.value.cross) {
      payload['config'] = {
        periods: this.form.value['periods'] ? this.form.value['periods'].map(el => el.id) : null,
        has_bought: this.form.value['has_bought'] ? this.form.value['has_bought'] : false,
        active_clients: this.form.value['active_clients'] ? this.form.value['active_clients'] : false,
        up_campaign_plan_category_id: this.form.value['up_campaign_plan_category_id'] && this.form.value['up_campaign_plan_category_id'].map(el => el.id)[0],
        cross_campaign_plan_category_id: this.form.value['cross_campaign_plan_category_id'] && this.form.value['cross_campaign_plan_category_id'].map(el => el.id)[0],
        mode: this.parseSupplierTypeValues()
      };
    }

    if (feature_ids && feature_ids.length > 0 && !isNullOrUndefined(feature_ids[0])) {
      payload['config']['feature_ids'] = feature_ids.map(el => el.id);
    }

    if (segment && segment.length > 0 && !isNullOrUndefined(segment[0])) {
      payload['config']['segment_id'] = segment[0].id;
    }

    if (this.form.value.upselling && !isNullOrUndefined(periodFrom) && Object.keys(periodFrom).length > 0) {
      payload['config']['period_date_from'] = periodFrom['id'];
    }

    if (this.form.value.upselling && !isNullOrUndefined(periodTo) && Object.keys(periodTo).length > 0) {
      payload['config']['period_date_to'] = periodTo['id'];
    }

    if (taxonomy && taxonomy.length > 0 && !isNullOrUndefined(taxonomy[0])) {
      payload['config']['taxonomy'] = taxonomy.map(el => el.id)[0];
    }

    [
      'segment_id', 'has_bought', 'active_clients', 'periods', 'cross',
      'upselling', 'period_date_from', 'period_date_to', 'taxonomy', 'feature_ids',
      'up_campaign_plan_category_id', 'cross_campaign_plan_category_id'
    ].forEach(el => {
        delete payload[el];
      });

    payload.config = cleanUndefinedFormValues(payload.config);
    payload = cleanUndefinedFormValues(payload);
    return payload;
  }

  private parseSupplierTypeValues(): 'up' | 'cross' | 'up_cross' {
    if (this.form.value.upselling && this.form.value.cross) {
      return 'up_cross';
    } else if (this.form.value.upselling && !this.form.value.cross) {
      return 'up';
    } else if (!this.form.value.upselling && this.form.value.cross) {
      return 'cross';
    }
    console.error('supplier type is not properly configured', this.form.value);
  }

  private dateOptions() {
    return [
      { id: '%30_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_1')},
      { id: '%90_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_3')},
      { id: '%180_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_6')},
      { id: '%365_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_12')},
      { id: 'never', name: this.translate.instant('resources.campaign_plans.types.supplier.fields.never')},
    ];
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.form.status));
    const formStatus$ = this.form.statusChanges.pipe(distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
    this.subs$.push(formStatus$);
  }

  private getValidations(): ValidatorFn[] {
    const ensureType = ((_ctrl: AbstractControl) => {
      const upsellingCheck = _ctrl.get('upselling');
      const crossCheck = _ctrl.get('cross');
      if (upsellingCheck.value !== true && crossCheck.value !== true) {
        return {typeRequired: true};
      }
      return null;
    });

    const upSellingVld = ((_ctrl: AbstractControl) => {
      const upsellingCheck = _ctrl.get('upselling');
      const campaignCategoryCheck = _ctrl.get('up_campaign_plan_category_id');
      const periodFromCtrl = _ctrl.get('period_date_from');
      const periodToCtrl = _ctrl.get('period_date_to');
      periodFromCtrl.setValidators([]);
      periodToCtrl.setValidators([]);
      const isPeriodFromPresent = periodFromCtrl.value && periodFromCtrl.value.hasOwnProperty('id') && !isNullOrUndefined(periodFromCtrl.value.id);
      const isPeriodToPresent = periodToCtrl.value  && periodToCtrl.value.hasOwnProperty('id') && !isNullOrUndefined(periodToCtrl.value.id);
      const isCampaignCategoryPresent = campaignCategoryCheck.value && campaignCategoryCheck.value.length > 0 && !isNullOrUndefined(campaignCategoryCheck.value[0]);

      if (upsellingCheck.value === true) {
        if (!isPeriodFromPresent || !isPeriodToPresent || !isCampaignCategoryPresent) {
          return {periodDatesRequired: true};
        }
        return null;
      }
      return null;
    });

    const crossSellingPeriodVld = ((_ctrl: AbstractControl) => {
      const crossSellingCheck = _ctrl.get('cross');
      const campaignCategoryCheck = _ctrl.get('cross_campaign_plan_category_id');
      const periodCtrl = _ctrl.get('periods');
      const isPeriodPresent = periodCtrl.value && periodCtrl.value.length > 0 && !isNullOrUndefined(periodCtrl.value[0]);
      const isCampaignCategoryPresent = campaignCategoryCheck.value && campaignCategoryCheck.value.length > 0 && !isNullOrUndefined(campaignCategoryCheck.value[0]);

      if (crossSellingCheck.value === true) {
        if (!isPeriodPresent || !isCampaignCategoryPresent) {
          return { periodRequired: true };
        }
        return null;
      }
      return null;
    });

    this.overrideRequiredInputs();
    return [
      ensureType,
      upSellingVld,
      crossSellingPeriodVld
    ];
  }

  private overrideRequiredInputs() {
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key).setValidators([]);
    });
  }

  private getModeValue(planCampaign: PlanCampaign, values: string[]): boolean {
    if (!planCampaign.config.hasOwnProperty('mode')) { return false; }
    return values.includes(planCampaign.config['mode']);
  }

  private getSelectedPeriods(planCampaign: PlanCampaign): {id: number, name: string}[] {
    const campaignPeriods = planCampaign.config['periods'];
    if (isNullOrUndefined(campaignPeriods)) {return []; }
    return planCampaign.config['periods'].map(period => this.dateOptions().find(opt => opt.id === period));
  }

  private setInputs(planCampaign?: PlanCampaign) {
    let upCategory;
    let crossCategory;
    if (planCampaign) {
      upCategory = this.getUpCampaignCategoryValue(planCampaign);
      crossCategory = this.getCrossCampaignCategoryValue(planCampaign);
    }
    const inputs = [
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        label: 'Upselling',
        key: 'upselling',
        value: planCampaign ? this.getModeValue(planCampaign, ['up', 'up_cross']) : false
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'period_date_from',
        label: 'resources.campaign_plans.types.supplier.fields.date_from',
        required: true,
        value: planCampaign ? this.dateService.dynamicDateToDateOption(planCampaign.config['period_date_from']) : null,
        hideOverlappingOpts: true
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'period_date_to',
        label: 'resources.campaign_plans.types.supplier.fields.date_to',
        required: true,
        value: planCampaign ? this.dateService.dynamicDateToDateOption(planCampaign.config['period_date_to']) : null,
        hideOverlappingOpts: true
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        label: 'Cross-Selling',
        key: 'cross',
        value: planCampaign ? this.getModeValue(planCampaign, ['cross', 'up_cross']) : false
      }),
      new MultiSelectQuestion({
        key: 'periods',
        label: 'resources.campaign_plans.types.supplier.fields.period',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        options: this.dateOptions(),
        value: planCampaign ? this.getSelectedPeriods(planCampaign) : [],
        required: true
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.campaign_plans.types.supplier.fields.bought'),
        key: 'has_bought',
        value: planCampaign ? planCampaign.config['has_bought'] : false
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.campaign_plans.types.supplier.fields.active_customers'),
        key: 'active_clients',
        value: planCampaign ? planCampaign.config['active_clients'] : false
      }),
      new MultiSelectQuestion({
        key: 'up_campaign_plan_category_id',
        label: 'resources.plans.filters.campaign_category',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.campaignCategoriesSrv,
        selectedIds: (upCategory && upCategory.length > 0 && upCategory[0] !== 0) ? upCategory : null,
        value: [{id: -1, name: this.translate.instant('resources.campaign_categories.messages.no_category')}],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'cross_campaign_plan_category_id',
        label: 'resources.plans.filters.campaign_category',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.campaignCategoriesSrv,
        selectedIds: (crossCategory && crossCategory.length > 0 && crossCategory[0] !== 0) ? crossCategory : null,
        value: [{id: -1, name: this.translate.instant('resources.campaign_categories.messages.no_category')}],
        required: true
      }),
    ];

    this.inputs = inputs;
    this.form = this.qcs.toFormGroup(this.inputs);
    this.form.setValidators(this.getValidations());
    this.formStatusChanges();
  }

  private getUpCampaignCategoryValue(planCampaign: PlanCampaign) {
    const upCampaignPlanCategoryId = planCampaign.config['up_campaign_plan_category_id'];
    if (isNullOrUndefined(upCampaignPlanCategoryId)) {return []; }
    return [upCampaignPlanCategoryId];
  }

  private getCrossCampaignCategoryValue(planCampaign: PlanCampaign) {
    const crossCampaignPlanCategoryId = planCampaign.config['cross_campaign_plan_category_id'];
    if (isNullOrUndefined(crossCampaignPlanCategoryId)) {return []; }
    return [crossCampaignPlanCategoryId];
  }

}
