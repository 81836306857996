import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { isNullOrUndefined } from '../../../../shared/utils/common.utils';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { Subject, takeUntil } from 'rxjs';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { TierDefinition } from '../tier';
import { TiersManagementService } from '../tiers-management.service';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-tiers-config',
  templateUrl: './tiers-config.component.html',
  styleUrls: ['./tiers-config.component.css']
})
export class TiersConfigComponent implements OnInit, OnDestroy {

  deletedTiers: TierDefinition[] = [];
  id: number;
  mode: string = this.route.snapshot.data.mode;
  tierDefinition: TierDefinition;
  tiersConfigForm: UntypedFormGroup;
  tiersInputs: QuestionBase<unknown>[] = [];
  type: string = this.route.snapshot.data.type;

  private destroy$: Subject<void> = new Subject<void>();

  get tiersArray(): UntypedFormArray {
    return this.tiersConfigForm.get('tiers') as UntypedFormArray;
  }

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private tierDefinitionService: TiersManagementService,
    private confirmationService: ConfirmationService
  ) {
    this.tiersConfigForm = new UntypedFormGroup({
      tiers: new UntypedFormArray([])
    });
  }

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.tiersInputs, inputKey);
  }

  addTier() {
    let validValues:boolean = false;
    const newTier:UntypedFormGroup = new UntypedFormGroup({
      name: new UntypedFormControl(),
      threshold_tier: new UntypedFormControl()
    });
    if (this.tiersArray.controls.length === 0) {
      newTier.controls['threshold_tier'].setValue(0);
      newTier.controls['threshold_tier'].disable();
      validValues = true
    }
    if (this.tiersArray.controls.length > 0 && parseInt(this.tiersArray.controls[this.tiersArray.controls.length - 1].get('threshold_tier').value) >= 0){
      validValues = true
    }
    if(validValues){
      this.tiersArray.push(newTier);
      this.disableTiers();
    }
  }

  deleteTier(index: number, tier) {
    this.tiersArray.removeAt(index);
    if(tier.value.hasOwnProperty('id')) {
      this.deletedTiers.push({...tier.value, _destroy: 1 });
    }
    this.enableLastExistingTier();
  }

  isChangeTiersAllowed(): boolean {
    return !this.tierDefinition ||
      !this.tierDefinition?.has_run &&
      this.tiersArray?.controls?.length < 3 &&
      this.mode !== 'show';
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getTierDefinitionById();
      } else {
        this.getTiersInputs({});
        this.addTier();
      }
    });
  }

  private disableTiers(){
    if(this.tiersArray.length > 0){
      for (let i = 0; i < this.tiersArray.length - 1; i++) {
        this.tiersArray.at(i).get('threshold_tier').disable();
      }
    }
    if(this.mode === 'show' || this.tierDefinition?.has_run){
      this.tiersInputs.forEach(input => input.disabled = true);
    }
  }

  private enableLastExistingTier(){
    if(this.tiersArray.controls.length > 1){
      this.tiersArray.controls[this.tiersArray.controls.length - 1].enable();
    } else if(this.tiersArray.controls.length === 1){
      this.tiersArray.controls[0].get('threshold_tier').disable();
    }
  }


  private getTierDefinitionById() {
    this.tierDefinitionService.getTierDefinitionById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: TierDefinition) => {
        this.tierDefinition = response;
        if(this.type === 'tier') {
          this.tiersInputs = this.getTiersInputs(response);
        }
        if(this.type === 'tier') {
          this.setTiersArray();
        }
        this.disableTiers();
      },
      error: (errorData) => {
        this.confirmationService.displayHttpErrorAlert(errorData)
      }
    });
  }

  private setTiersArray() {
    this.tierDefinition['achievement_definitions'].forEach(tier => {
      this.tiersArray.push(new UntypedFormGroup({
        id: new UntypedFormControl(tier.id),
        name: new UntypedFormControl(tier.name),
        threshold_tier: new UntypedFormControl(tier.threshold),
        condition: new UntypedFormControl(tier.condition),
        customer_attribute_definition_id: new UntypedFormControl(tier.customer_attribute_definition_id),
        kind: new UntypedFormControl(tier.kind),
        tier_id: new UntypedFormControl(tier.tier_id),
        tier_level: new UntypedFormControl(tier.tier_level)
      }));
    })
  }

  private getTiersInputs(values?) {
    const inputs = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        required: true,
        disabled: false,
        value: this.type === 'challenge' && !isNullOrUndefined(values) && this.mode !== 'create' ? values['achievement_definitions'][0].name : null
      }),
      new TextboxQuestion({
        key: 'threshold_tier',
        type: 'number',
        cssClasses: 'form-control input-default',
        required: true,
        disabled: false,
        value: this.type === 'challenge' && !isNullOrUndefined(values) && this.mode !== 'create' ? values['achievement_definitions'][0].threshold : null
      })
    ];

    inputs.forEach(input => {
      this.tiersInputs.push(input);
    });

    return inputs;

  }
}
