import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { AuthService } from '../../auth/auth.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class LgApiInterceptor implements HttpInterceptor {s

  private response: any;
  isChatGPT: boolean;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Add headers to all requests except project assets & remote assets (Amazon S3)
    const request$ = !this.isAssetsRequest(req) ? this.setReqHeaders(req) : req;

    return next.handle(request$).pipe(
      tap((_response: HttpEvent<any>) => {
        if (_response instanceof HttpResponse) {
          return this.parseResponse(request$, _response);
        }
      }),
      catchError(error => {
        if(request$.url.indexOf('/users/me') > 0 && error.status === 401) {
          // Force session close on unauthorized - Event dispatched cleans stored data and redirects to signin.
          this.authService.logout();
          this.router.navigate(['/signin']).then(() => window.location.reload()).catch(() => {});
        }
        return throwError(() => error);
      })
    );
  }

  private isAssetsRequest(req: HttpRequest<any>): boolean {
    return req.url.indexOf('/assets/') >= 0 || req.url.indexOf('s3.amazonaws.com') >= 0;
  }

  private setReqHeaders(req: HttpRequest<any>): HttpRequest<any> {
    let apiReq: HttpRequest<any>;

    apiReq = req.clone({url: `${environment.lgApiBaseUrl}/${req.url}`, withCredentials: true});

    let contentType = 'application/json';
    if (apiReq.headers.has('Content-Type')) {
      contentType = apiReq.headers.get('Content-Type')
    }

    apiReq = apiReq.clone({
      setHeaders: {
        'Access-Control-Allow-Origin': environment.clientBaseUrl,
        'Content-Type': contentType,
        'X-Client-Origin': 'owner'
      }
    });
    return apiReq;
  }

  private parseResponse(apiReq: HttpRequest<any>, _response: HttpResponse<any>): any {
    if (apiReq.method === 'GET' && _response['body'] && _response['body']['_embedded']) {
      this.response = this.walk(_response['body']);
      this.response['_links'] = _response['body']['_links'];
    } else {
      this.response = this.walk(_response['body']);
    }
    return this.response;
  }

  private walk(response) {
    if ( !response ) { return response; }
    if ( response.hasOwnProperty('list') && response.list instanceof Array ) {
      for ( const item of response.list ) {
        this.walk( item );
      }
    } else if (response?.hasOwnProperty('_embedded')) {
      for (const innerElement in response._embedded) {
        if (response._embedded.hasOwnProperty(innerElement)) {
          response[innerElement] = response._embedded[innerElement];
        }
      }
    }
    return response;
  }
}
