<div class="modal-backdrop fade in modal-fs" id='modal-fs' tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="pdd-vertical-20-but-bottom">
          <br>
          <h2 class="card-title" *ngIf="element">
            <span *ngIf="element.status as status" class="badge"
                  [ngClass]="{'badge-primary': status==='done', 'badge-warning': status==='pending', 'badge-danger': status==='error'}" 
                  [translate]="'resources.periods.status.'+status">
            </span>
            <span> {{'resources.periods.fields.period' | translate}} {{element.id}} ({{element.period_date | date: 'dd/MM/yyyy'}})</span>
          </h2>
        </div>
        <a class="modal-close" data-dismiss="modal" (click)="closeModal()">
          <i class="ti-close"></i>
        </a>
      </div>
      <div class="modal-body height-100">
        <div class="card" >
          <!-- Tabs -->
          <div class="tab-info">
            <!-- Tabs menu -->
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item">
                <a href="#default-tab-1"
                  (click)="refreshUrl('segments')"
                  [ngClass]="{'active': selectedTab === 'segments'}"
                  class="nav-link" role="tab" data-toggle="tab" [translate]="'resources.periods.tabs.details'">
                </a>
              </li>
              <li class="nav-item">
                <a href="#default-tab-2"
                  (click)="refreshUrl('report-segments')"
                  [ngClass]="{'active': selectedTab === 'report-segments'}"
                  class="nav-link" role="tab" data-toggle="tab" [translate]="'resources.periods.tabs.labels'">
                </a>
              </li>
              <li class="nav-item">
                <a href="#default-tab-3"
                  (click)="refreshUrl('report-attributes')"
                  [ngClass]="{'active': selectedTab === 'report-attributes'}"
                  class="nav-link" role="tab" data-toggle="tab" [translate]="'resources.periods.tabs.attributes'">
                </a>
              </li>
            </ul>
          </div>
            <!-- Tabs content -->
            <div class="tab-content">
              <!-- TAB DETAILS CONTENT-->
              <app-loader *ngIf="loading" />
              <ng-container *ngIf="element">
                <div role="tabpanel"
                  [ngClass]="{'in active': selectedTab === 'segments'}"
                  class="tab-pane fade"
                  id="default-tab-1">
                  <div class="pdd-horizon-15 pdd-vertical-20" *ngIf="element">
                    <div class="mrg-top-10 mrg-left-15" *ngIf="element.number">
                      <span><b class="text-dark font-size-18" [translate]="'resources.periods.fields.number'"></b><br></span>
                      <span>{{element.number}}</span><br>
                    </div>
                    <div class="mrg-top-10 mrg-left-15" *ngIf="element.date_from">
                      <span><b class="text-dark font-size-18" [translate]="'resources.periods.fields.period'"></b><br></span>
                      <span>{{element.date_from | date: 'dd/MM'}} - {{element.date_to | date: 'dd/MM'}}</span><br>
                    </div>
                    <div class="mrg-top-10 mrg-left-15" *ngIf="element.period_date">
                      <span><b class="text-dark font-size-18" [translate]="'resources.periods.fields.period_date'"></b><br></span>
                      <span>{{element.period_date | date: 'dd/MM/yyyy'}}</span><br>
                    </div>
                    <div class="mrg-top-10 mrg-left-15" *ngIf="element.started_at">
                      <span><b class="text-dark font-size-18" [translate]="'resources.periods.fields.started_at'"></b><br></span>
                      <span>{{element.started_at | dateTimeZone: 'DD/MM/YYYY HH:mm'}}</span><br>
                    </div>
                    <div class="mrg-top-10 mrg-left-15" *ngIf="element.ended_at">
                      <span><b class="text-dark font-size-18" [translate]="'resources.periods.fields.ended_at'"></b><br></span>
                      <span>{{element.ended_at | dateTimeZone: 'DD/MM/YYYY HH:mm'}}</span><br>
                    </div>

                    <div class="mrg-top-10">
                      <button *ngIf="inStatusFor('rollback')"
                              class="btn btn-danger mrg-left-10"
                              [translate]="'resources.periods.buttons.rollback'"
                              [disabled]="!canRunAction('rollback')"
                              (click)="rollback()">
                      </button>
                      <button *ngIf="inStatusFor('retry')"
                              class="btn btn-warning mrg-left-10"
                              [translate]="'resources.periods.buttons.retry'"
                              [disabled]="!canRunAction('retry')"
                              (click)="retry()">
                      </button>
                      <button *ngIf="inStatusFor('execute')"
                              class="btn btn-warning mrg-left-10"
                              [translate]="'resources.periods.buttons.execute'"
                              [disabled]="!canRunAction('execute')"
                              (click)="execute()">
                      </button>
                    </div>


                    <h5 class="mrg-top-15 card-title mrg-left-10" [translate]="'resources.periods.fields.process_history'"></h5>
                    <table class="table font-size-14 table-hover" aria-hidden="true">
                      <thead>
                        <tr>
                          <th [translate]="'resources.periods.fields.message'"></th>
                          <th [translate]="'resources.periods.fields.date'"></th>
                          <th [translate]="'resources.periods.fields.type'"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let message of messages | orderBy: ['time'] : false" [ngClass]="stateClass(message)">
                          <app-loader noWrapper *ngIf="!message" />
                          <td class="font-size-13">{{message.message}}</td>
                          <td class="font-size-13">{{message.time | dateTimeZone: 'DD/MM/YYYY HH:mm:ss'}}</td>
                          <td><span class="font-size-13 badge badge-{{message.class}}">{{message.type}}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-container>
            <!-- END TAB DETAILS -->

              <!-- TAB LABELS -->
              <app-loader *ngIf="loading" />
              <ng-container *ngIf="segmentCustomerData">
                <div role="tabpanel"
                  [ngClass]="{'in active': selectedTab === 'report-segments'}"
                  class="tab-pane fade"
                  id="default-tab-2">

                  <div class="pdd-horizon-15 pdd-vertical-20">
                    <table class="table table-striped table-bordered table-hover" aria-hidden="true">
                      <thead>
                        <tr>
                          <th [translate]="'resources.periods.columns.category'"></th>
                          <th [translate]="'resources.periods.columns.segment'"></th>
                          <th [translate]="'resources.periods.columns.customers'"></th>
                          <th [translate]="'resources.periods.columns.difference'"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let segment of segmentCustomerData; let i = index;">
                          <app-loader noWrapper *ngIf="!segmentCustomerData" />
                          <td>{{segment.table.segment_category.name}}</td>
                          <td>{{segment.table.name}}</td>
                          <td>{{segment.table.customers}}</td>
                          <td *ngIf="previousSegmentCustomerData">{{getDifference(i, segment.table.customers)}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-container>
              <!-- END TAB LABELS -->

              <!-- TAB BUYER CUTS-->
              <app-loader *ngIf="loading" />
              <ng-container *ngIf="element && buyerTypesCutsData">
                <div role="tabpanel"
                    [ngClass]="{'in active': selectedTab === 'report-attributes'}"
                    class="tab-pane fade"
                    id="default-tab-3">

                  <div class="pdd-vertical-20-but-bottom">
                    <br>
                    <h2 class="card-title mrg-left-15" *ngIf="element">
                      {{'resources.periods.fields.period' | translate}} {{element.id}} ({{element.period_date}}): <span [translate]="'resources.periods.tabs.attributes'"></span>
                    </h2>
                  </div>

                  <div class="pdd-horizon-15 pdd-vertical-20">
                    <table class="table table-striped table-bordered table-hover dataTables-example" aria-hidden="true">
                      <thead>
                        <tr>
                          <th [translate]="'resources.periods.fields.attribute'"></th>
                          <th [translate]="'resources.periods.fields.cuts'">{{currencySymbol}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of buyerTypesCutsData">
                          <td>{{item.table.name}}</td>
                          <td>{{item.table.cut | number: '1.2-2'}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-container>
              <!-- END TAB BUYER CUTS-->
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button  class="btn btn-info">
          <a (click)="closeModal()" [translate]="'common.buttons.cancel'"></a>
        </button>
      </div>

    </div>
  </div>
</div>
