import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { ProfileService } from '../../../../profiles/profile.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductsService } from '../products.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-show-products',
  templateUrl: './show-products.component.html',
  styleUrls: ['./show-products.component.css']
})
export class ShowProductsComponent implements OnInit, OnDestroy {

  public element: object;
  public apiEndPoint: string;
  public id: number;
  public isModal: boolean;
  public roleSlug: string;
  public subs$: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private productsService: ProductsService
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.roleSlug = this.profileService.getStoredUserRole();
    this.isModal = this.router.url.indexOf('(modal:show/products/') >= 0;
    this.getParams();
  }

  ngOnDestroy(): void {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getParams() {
    const route$ = this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'products';
        const product$ = this.productsService.getProductsById(this.id).subscribe(
          (res: HttpResponse<any>) => this.element = res,
          (errorData: HttpErrorResponse) => {
            this.confirmationService.displayErrorAlert(
              this.translateService.instant('common.error'),
              errorData.error.error
            );
            this.closeModal();
          }
        );
        this.subs$.push(product$);
      }
    });
    this.subs$.push(route$);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  goToCategory(id) {
    if (this.isModal) {
      window.open(`#/data-warehouse/products/features/${id}`);
    } else {
      this.router.navigate([{ outlets: { modal: `show/features/${id}`}}]).catch(() => {});
    }
  }
}
