import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerJourney } from '../../../models/customer-journeys/customer-journey';
import { CustomerJourneysService } from '../../../../resources/customer-journeys/customer-journeys.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomerJourneyStep } from '../../../models/customer-journeys/customer-journey-step';
import { MulticouponJourneyService } from '../../../services/multicoupon-journey.service';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../../models/forms/question-base';
import { QuestionControlService } from '../../../services/question-control.service';
import { MultiselectDataSource } from '../../multiselect/multiselect';
import { TemplatesService } from '../../../services/templates.service';
import { PotentialJourneyService } from '../../../services/potential-journey.service';
import { RecuperationJourneyService } from '../../../services/recuperation-journey.service';
import { ProfileService } from '../../../../profiles/profile.service';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-journey-campaigns-block',
  templateUrl: './journey-campaigns-block.component.html',
  styleUrls: ['./journey-campaigns-block.component.css']
})

export class JourneyCampaignsBlockComponent implements OnInit {

  public options = {};
  public inputs;
  public loading: boolean;
  public campaignsChanged = false;
  public currencySymbol: string;

  @Input()  journey:      CustomerJourney;
  @Input()  form:         UntypedFormGroup;
  @Output() onStatusEmit:     EventEmitter<any> = new EventEmitter();
  @Output() templateEmit:     EventEmitter<any> = new EventEmitter();
  @Output() templateSelected: EventEmitter<any> = new EventEmitter();

  constructor(
    private journeyService:      CustomerJourneysService,
    private translate:           TranslateService,
    private multicouponService:  MulticouponJourneyService,
    private potentialService:    PotentialJourneyService,
    private qcs:                 QuestionControlService,
    private templateService:     TemplatesService,
    private recuperationService: RecuperationJourneyService,
    private profileService:      ProfileService,
  ) {
  const currency = this.profileService.getProfileCompany().currency;
  this.currencySymbol = getCurrencySymbol(currency, 'wide');
  }

  ngOnInit() {
    this.loading = true;
    if ( this.journey.type === 'multicoupon' ) {
      this.inputs = this.multicouponService.inputs;
    } else if ( this.journey.type === 'potential' ) {
      this.inputs = this.potentialService.inputs;
    } else if ( this.journey.type === 'recuperation' ) {
      this.inputs = this.recuperationService.inputs;
    } else {
      this.inputs = this.journey.getCampaignsStepFormCfg();
    }

    if ( this.inputs.length === 0 ) {
      this.onStatusEmit.emit('finished_loading');
      this.loading = false;
    } else {
      this.onStatusEmit.emit('loading');
      this.getOptions();
    }
  }

  public handleCampaignsChanged(event) {
    this.campaignsChanged = true;
    this.onStatusEmit.emit('unsaved_changes');
  }

  public requestRemoteOptions() {
    const stepsWithRemoteOptions = this.getStepsWithRemoteOptions(this.inputs);
    stepsWithRemoteOptions.forEach(step => {
      this.journeyService.getMultiselectOptionsByEndpoint(step['requestData']).subscribe(
        response => {
          this.options[step['key']] = this.parseOptionsResponse(step, response);
          if ( !this.pendingRequests(Object.keys(this.options).length) ) {
            this.markSelectedOptions();
            this.requestLocalOptions();
            this.loading = false;
            this.onStatusEmit.emit('finished_loading');
          }
        }
      );
    });
    if (stepsWithRemoteOptions.length === 0) {
      this.requestLocalOptions();
      this.loading = false;
      this.onStatusEmit.emit('finished_loading');
    }
  }

  /**
   * Method to mark option as selected when available
   */
  public markSelectedOptions() {
    this.journey.steps.forEach(step => {
      this.getSelectedOptionFromList(step, 'coupon_id', 'coupons');
      this.getSelectedOptionFromList(step, 'message_id', 'messages');
    });
  }

  public changeStepStatus(step: CustomerJourneyStep, status: string) {
    step.status = status;
    this.campaignsChanged = true;
    this.onStatusEmit.emit('autosave');
  }

  public handleStepCutChanged(step: CustomerJourneyStep, value) {
    step.configuration.cut = value === '' ? 0 : parseInt(value, 10);
  }

  /**
   * Eval if multiselect should be disabled due to c. journey status || step status
   */
  public getMultiselectSettings(step: CustomerJourneyStep, settings: any) {
    return Object.assign(settings, {disabled: !this.journey.isEditableStep(step)});
  }

  public getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  public viewTemplate(step: CustomerJourneyStep) {
    this.templateEmit.emit({mode: 'readonly', step: step});
  }

  public editTemplate(step: CustomerJourneyStep) {
    this.templateEmit.emit({mode: 'edit', step: step});
  }

  public handleTemplateChanged(step: CustomerJourneyStep, template: MultiselectDataSource) {
    if (!template) { /* onDeselect */
      step.configuration.template = undefined;
      this.templateSelected.emit(step);
      return;
    }
    this.templateService.getTemplateById(template.id).subscribe(
      templateData => {
        if ( !step.configuration.hasOwnProperty('template') || step.configuration.template === undefined ) {
          step.configuration.template = {};
        }
        step.configuration.template.subject = templateData['name'];
        step.configuration.template.message = templateData['html'];
        step.configuration.template.schema  = templateData['schema'];
      },
      errorData => {
        console.error(JSON.stringify(errorData));
      }
    );
  }

  public getStepName( step: CustomerJourneyStep ) {
    return this.journeyService.getStepName(this.journey, step);
  }

  /* Private methods */

  private getOptions() {
    this.requestRemoteOptions();
  }

  /**
   * Method to retrieve option from list. i. e. optionKey: 'coupon_id', optionListKey: 'coupons'
   */
  private getSelectedOptionFromList(step, optionKey, optionListKey): any {
    if ( step.configuration.hasOwnProperty(optionKey) && step.configuration[optionKey] ) {
      step.configuration[optionListKey] = this.options[optionListKey].filter( option => {
        if ( String(option.id) === String(step.configuration[optionKey])) {
          return [{id: option.id, name: option.name}];
        }
      });
    }
  }

  // TODO: Move to customer jorney service the following methods
  /**
   * Method to filter steps that has to populate themselves with external http call
   */
  private getStepsWithRemoteOptions(definitionStepsCfg) {
    return definitionStepsCfg.filter(
      stepCfg => stepCfg.hasOwnProperty('requestData') &&
                 stepCfg['requestData'].hasOwnProperty('apiEndPoint') &&
                 stepCfg['requestData']['apiEndPoint'] != null );
  }

  /**
   * Method to filter steps that has static local options
   */
  private getStepsWithLocalOptions(definitionStepsCfg) {
    return definitionStepsCfg.filter( stepCfg => !stepCfg.hasOwnProperty('requestData') );
  }

  private parseOptionsResponse(step, response) {
    let list = response.hasOwnProperty('list') ? response['list'] : response;
        list = list.hasOwnProperty('values') ? list['values'] : list;
    let _response = [];
    list.forEach(element => {
      const optionName = this.interpolate(step, element);
      _response.push(optionName);
    });

    _response = this.journey.filterOptionsByKey(_response, step);
    return _response;
  }

  private interpolate(template, element) {
    return {
      id: element.id,
      name: element.name
    };
  }

  private pendingRequests(finishedRequests: number): boolean {
    const totalRequests = this.getStepsWithRemoteOptions(this.inputs).length;
    return finishedRequests < totalRequests;
  }

  private requestLocalOptions() {
    const localSteps = this.getStepsWithLocalOptions(this.inputs);
    localSteps.forEach(step => {
      if (step.translatable) {
        step.options.forEach(element => {
          element.name = this.translate.instant(element.name);
        });
      }
      this.options[step['key']] = step.options;
    });
  }

  public isDateValid(date): boolean {
    return !(date === undefined || date === '' );
  }
}
