<div class="container-fluid clearfix">
  <div class="row" *ngIf="locationTaxonomyTerm">
    <div class="col">
      <div class="card mrg-btm-15">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true">
              <tbody>
                <tr *ngIf="locationTaxonomyTerm.external_id">
                  <td class="no-border-top"><b [translate]="'resources.location_taxonomy_terms.fields.external_id'"></b></td>
                  <td class="no-border-top">{{locationTaxonomyTerm.external_id}}</td>
                </tr>
                <tr *ngIf="locationTaxonomyTerm.name">
                  <td><strong [translate]="'resources.location_taxonomy_terms.fields.name'"></strong></td>
                  <td>{{locationTaxonomyTerm.name}}</td>
                </tr>
                <tr *ngIf="locationTaxonomyTerm.slug">
                  <td><strong [translate]="'resources.location_taxonomy_terms.fields.slug'"></strong></td>
                  <td>{{locationTaxonomyTerm.slug}}</td>
                </tr>
                <tr *ngIf="locationTaxonomyTerm.taxonomy">
                  <td><strong [translate]="'resources.location_taxonomy_terms.fields.taxonomy_slug'"></strong></td>
                  <td>
                    <a class="pointer colorLink" [routerLink]="['', { outlets: { modal: ['show', 'location_taxonomies', locationTaxonomyTerm.taxonomy.id] } }]">
                      {{locationTaxonomyTerm.taxonomy.name}}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <app-data-table-filter *ngIf="filters"
        [questions]="filters"
        (onSubmitEmitter)="onFilterHandler($event)">
      </app-data-table-filter>

      <div class="card">
        <div class="card-block">
          <app-data-table [config]="locationsTableConfig"></app-data-table>
        </div>
      </div>
    </div>
  </div>
</div>
