<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-40">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form  [formGroup]="messageCategoriesForm" *ngIf="messageCategoriesForm"> 
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name" [translate]="'resources.message_categories.form.name'"></label>
                      <app-question [question]="getInputConfig('name')" [form]="messageCategoriesForm"></app-question>
                    </div>
                  </div>
                </div>                        
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> 
