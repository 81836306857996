import { Component, Input, OnInit } from '@angular/core';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-partners-block',
  templateUrl: './partners-block.component.html'
})
export class PartnersBlockComponent {

  @Input('loyaltyScoresForm') loyaltyScoresForm: UntypedFormGroup;
  @Input('inputs') inputs;

  constructor(
    private qcs: QuestionControlService,
  ) { }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

}
