import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { MultiselectDataSourceable } from '../../../../shared/components/multiselect/multiselect';
import { MultiselectDataSource } from '../../../../shared/components/multiselect/multiselect';
import { Observable } from 'rxjs';
import { RewardTaxonomy } from './reward-taxonomy';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { DictionaryService } from '../../../../shared/services/dictionary.service';

@Injectable()
export class RewardTaxonomiesService extends ResourcesService  implements MultiselectDataSourceable {

  constructor(
    http: HttpClient,
    private dictionary: DictionaryService
  ) {
    super(http);
  }

  getById(id: number) {
    return this.getData({ apiEndPoint: `reward_taxonomies/${id}` });
  }

  updateById(taxonomy: RewardTaxonomy, id: number) {
    return this.patchResource(taxonomy, `reward_taxonomies/${id}`);
  }

  create(taxonomy: RewardTaxonomy) {
    return this.postResource(taxonomy, `reward_taxonomies`);
  }

  deleteById(id: number) {
    return this.deleteResource({}, `reward_taxonomies/${id}`);
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getById(id);
  }

  getInputs(rewardTaxonomy?: RewardTaxonomy): QuestionBase<any>[] {
    const originOpts = this.dictionary.getValuesByKey('reward_taxonomies_origin');
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 1,
        required: true,
        value: rewardTaxonomy?.name
      }),
      new TextboxQuestion({
        key: 'slug',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 2,
        required: true,
        value: rewardTaxonomy?.slug,
        disabled: rewardTaxonomy?.id
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'origin',
        options: this.dictionary.getValuesByKey('reward_taxonomies_origin'),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 3,
        value: rewardTaxonomy?.origin ? [originOpts.find(el => el.id === rewardTaxonomy.origin)] : [originOpts[0]],
        required: true
      })
    ];

    return inputsArray;
  }

  fetchMultiselect( searchValues?: string, page?: number ) {
    let requestOptions = {
      apiEndPoint: 'reward_taxonomies',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    return new MultiselectDataSource(element.slug, `(${element.id}) ${element.name}`, element);
  }
}
