import { CurrentUser } from '../../models/current-user';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as LDClient from 'launchdarkly-js-client-sdk';

@Injectable()
export class LaunchDarklyService {

  client: LDClient.LDClient;
  flagChanges: Subject<LDClient.LDFlagSet> = new Subject<LDClient.LDFlagSet>();
  flags: LDClient.LDFlagSet;

  private initiated: boolean;
  private user: LDClient.LDContext;

  constructor() {
    this.initiated = false;
  }

  isInitiated() {
    return this.initiated;
  }

  initClient(user?: CurrentUser) {
    this.init(user);
    this.initiated = true;
  }

  setFlags() {
    this.flags = this.client.allFlags();
    this.flagChanges.next(this.flags);
  }

  changeUser(userData: {key: string, name?: string, email?: string, company?: string, role?: string}) {
    if (userData.key !== 'anonymous') {
      if (this.client.getContext().key !== userData.key) {
        this.identifyNewUser(userData);
      }
    } else {
      this.client.identify({key: 'anonymous', anonymous: true});
    }
  }

  // private
  private init(user?: CurrentUser) {
    if (user === undefined) {
      this.user = { anonymous: true };
    } else {
      this.user = {
        key: user.email,
        name: user.fullName,
        email: user.email,
        anonymous: false,
        custom: {
          company: user.company.slug,
          role: user.role.slug
        }
      };
    }
    this.client = LDClient.initialize(environment.launchDarklyClientId, this.user);
    this.client.on('ready', () => this.setFlags());
    this.client.on('change', (changes) => {
      this.flags = changes;
      this.flagChanges.next(this.flags);
    });
  }

  private identifyNewUser(userData: {key: string, name?: string, email?: string, company?: string, role?: string}) {
    this.client.identify({
      key: userData.email,
      name: userData.name,
      email: userData.email,
      anonymous: false,
      custom: {
        company: userData.company,
        role: userData.role
      }
    }).then(() => this.setFlags());
  }

}
