import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-show-news-taxonomy-terms',
  templateUrl: './show-news-taxonomy-terms.component.html',
  styleUrls: ['./show-news-taxonomy-terms.component.css']
})
export class ShowNewsTaxonomyTermsComponent implements OnInit {

  public element: any;
  public apiEndPoint: string;
  public id: number;
  public isModal: boolean;

  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private router: Router
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.isModal = this.router.url.indexOf('(modal:show/news_taxonomy_terms/') >= 0;
    this.getParams();
    this.resourcesService.getData({ apiEndPoint: this.apiEndPoint + '/' + this.id })
        .subscribe(res => {
          this.element = res;
          this.setImg();
        },
        error => {
          console.log('error:', error);
        });
  }

  getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'news_taxonomy_terms';
      }
    });
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  setImg() {
    if (this.element.thumbnail.indexOf('descarga.png') >= 0) {
      this.element.thumbnail = 'assets/images/avatars/no_image.png';
    }
  }

  goToTaxonomy() {
    if ( this.isModal ) {
      window.open(`#/content-designer/cms/news_taxonomy_terms/${this.element.taxonomy.id}`);
    } else {
      this.router.navigate([{ outlets: { modal: `show/news_taxonomies/${this.element.taxonomy.id}`}}]).catch(() => {});
    }
  }

}
