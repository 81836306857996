<app-data-table-filter-analytics #filter *ngIf="filters"
                        [slug]="filterSlug"
                        [questions]="filters"
                        [defaultQuestions]="defaultFilters"
                        (onSubmitEmitter)="onFilterHandler($event)">
</app-data-table-filter-analytics>

<app-loader noWrapper *ngIf="loading" />

<!-- TOTAL CUSTOMERS -->
<div class="row" *ngIf="totals && !loading">
  <div class="col-lg-6">
    <div class="card bg-info">
      <div class="card-block">
        <div class="row">
          <div class="col-md-7">
            <p class="text-uppercase text-white font-size-13"><b><i class="fa fa-users"></i>&nbsp;<span [translate]="'dashboards.migrations.repetition.menu.total_customers'"></span></b></p>
            <h1 class="no-mrg-top font-size-30" *ngIf="totals">{{totals[0] | number:'':'es' }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Average Purchases -->
  <div class="col-lg-6">
    <div class="card bg-info">
      <div class="card-block">
        <div class="row">
          <div class="col-md-7">
            <p class="text-uppercase text-white font-size-13"><b><i class="fa fa-users"></i>&nbsp;<span [translate]="'dashboards.migrations.repetition.menu.average'"></span></b></p>
            <h1 class="no-mrg-top font-size-30" *ngIf="totals && totals[0] !== 0">{{totals[1] / totals[0] | number: '1.2-2': 'es' }}</h1>
            <h1 class="no-mrg-top font-size-30" *ngIf="totals && totals[0] === 0">{{0.0 | number: '1.2-2': 'es' }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TABLE -->
<div class="row" *ngIf="total_customers && !loading">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title d-inline" [translate]="'dashboards.migrations.repetition.menu.frequency'"></h4>
        <i class="tooltip-position ti-info-alt mrg-top-10 font-size-14 pull-right"
            tooltipPosition="left"
            pTooltip="{{ 'dashboards.migrations.repetition.menu.tooltip_text' | translate }}">
        </i>
      </div>
      <div class="card-block no-pdd no-mrg pdd-horizon-20 pdd-btm-5">
        <table class="table table-bordered table-striped table-hover table-sm" aria-hidden="true">
          <tr>
            <th></th>
            <th class="text-center" [translate]="'dashboards.migrations.repetition.menu.persons'"></th>
          </tr>
          <tr *ngFor="let item of total_customers.values">
            <td *ngIf="item" class="text-center">
              <span [translate]="'dashboards.migrations.repetition.menu.coming'"></span>
              {{item[0]}} <span *ngIf="item[0] !== 1" [translate]="'dashboards.migrations.repetition.menu.times'"></span>
              <span *ngIf="item[0] === 1" [translate]="'dashboards.migrations.repetition.menu.time'"></span>
            </td>
            <td *ngIf="item" class="text-center">
              <span class="label label-default font-size-12">{{item[1] | number: '1.0-0'}}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- TABLE END -->
