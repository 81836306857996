import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Plan } from '../../../../resources/plans/plan';
import { PlanCampaign } from '../../../../resources/plans/campaigns/plan-campaign';
import { isNullOrUndefined } from 'util';
import { ProfileService } from '../../../../profiles/profile.service';

@Component({
  selector: 'app-steps-nav',
  templateUrl: './steps-nav.component.html',
  styleUrls: ['./steps-nav.component.css']
})
export class StepsNavComponent implements OnInit {

  roleSlug: string = this.profileService.getStoredUserRole();

  get forbbidenRoles(): boolean {
    return !['franchise', 'store_manager', 'customer_service', 'analytics', 'content_creation', 'integration'].includes(this.roleSlug);
  }

  public currentStep: number;
  public currentMode: string;
  public stepsNum: number;
  public loading: boolean;
  public preventSave: boolean;
  public disableSave: boolean;
  public disablePrev: boolean;
  public disableNext: boolean;
  public disableSubmit: boolean;

  @Input('plan') plan: Plan;
  @Output() navEvent: EventEmitter<string>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService
  ) {
    this.loading = false;
    this.stepsNum = 4;
    this.disableSave = false;
    this.disablePrev = false;
    this.disableNext = false;
    this.disableSubmit = false;
    this.preventSave = false;
    this.navEvent = new EventEmitter<string>();
  }

  ngOnInit() {
    this.currentStep = this.route.snapshot.data.step_num;
    this.currentMode = this.route.snapshot.data.mode;
  }

  gotoPreviousStep(planCampaign?: PlanCampaign) {
    if  (!isNullOrUndefined(planCampaign)) {
      this.router.navigate([`../../${planCampaign.id}/step_${this.currentStep - 1}`], {relativeTo: this.route}).catch(() => {});
      return;
    }
    this.router.navigate([`../../new/step_${this.currentStep - 1}`], {relativeTo: this.route}).catch(() => {});
  }

  gotoNextStep(planCampaign?: PlanCampaign) {
    if (!isNullOrUndefined(planCampaign)) {
      this.router.navigate([`../../${planCampaign.id}/step_${this.currentStep + 1}`], {relativeTo: this.route}).catch(() => {});
      return;
    }
    this.router.navigate([`../../new/step_${this.currentStep + 1}`], {relativeTo: this.route}).catch(() => {});
  }

  emitNavEvent(event: string) {
    this.navEvent.emit(event);
  }

}
