import { Component, Input } from "@angular/core";
import { TicketDetail } from "../../../../../shared/models/purchases/ticket-detail";

@Component({
  selector: 'app-returns-block',
  templateUrl: './returns-block.component.html',
  styleUrls: ['./returns-block.component.scss']
})

export class ReturnsBlockComponent {

  @Input() ticketData: TicketDetail;
  @Input() currencySymbol: string;

  getTicketLineAmount(from_line_order) {
    const result = this.ticketData?.lines?.find(line => from_line_order === line.line_order);
    return result ? Math.abs(result.total) : '-';
  }

  openTicketInNewTab(ticketId: string): void {
    window.open(`/#/customer-data-platform/warehouse/purchases(modal:show/activities/${ticketId})`, '_blank');
  }
}
