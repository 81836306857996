import { Component, OnInit } from '@angular/core';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { CampaignCategoriesService } from '../campaign-categories.service';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';

@Component({
  selector: 'app-form-campaigns-categories',
  templateUrl: './form-campaigns-categories.component.html',
  styleUrls: ['./form-campaigns-categories.component.css']
})
export class FormCampaignsCategoriesComponent implements OnInit {

  public campaignCategoriesForm: UntypedFormGroup;
  public inputs: QuestionBase<any>[];
  public id: number;
  public showButton: boolean;
  public loading: boolean;

  constructor(private qcs: QuestionControlService,
              private route: ActivatedRoute,
              private campaignCategoriesService: CampaignCategoriesService,
              private router: Router,
              private confirmationService: ConfirmationService,
              private translate: TranslateService) {}

  ngOnInit() {
    this.getParams();
    this.showBackButton();
  }

  showBackButton() {
    this.showButton = !(this.router.url.indexOf('(modal:') >= 0);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getCampaignCategoriesById(this.id);
      } else {
        this.inputs = this.getInputs({});
        this.campaignCategoriesForm = this.qcs.toFormGroup(this.inputs);
      }
    });
  }

  private getCampaignCategoriesById(id: number) {
    this.campaignCategoriesService.getCampaignCategoriesById(this.id).subscribe(
      data => {
        this.inputs = this.getInputs(data);
        this.campaignCategoriesForm = this.qcs.toFormGroup(this.inputs);
      }
    );
  }

  private getInputs(formValues: any): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 1
      }),
      new TextboxQuestion({
        key: 'priority',
        type: 'number',
        cssClasses: 'form-control input-default',
        order: 2
      })
    ];

    Object.keys(formValues).forEach(
      key => {
        if (key === 'name') {
          inputsArray.find( input => input.key === 'name' ).value = formValues[key];
        } else if (key === 'priority') {
          inputsArray.find( input => input.key === 'priority' ).value = formValues[key];
      }
    });

    this.inputs = inputsArray;
    return this.inputs;
  }

  public sendData() {

    const campaignCategoriesOptions = {

      name: this.campaignCategoriesForm.value.name,
      priority: this.campaignCategoriesForm.value.priority

    };

    if ( this.id ) {

      this.campaignCategoriesService.updateData(campaignCategoriesOptions, this.id).subscribe(
        response => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.campaign_categories.form.warnings.update_title'),
            this.translate.instant('resources.campaign_categories.form.warnings.update_text')
          ).catch(() => {});
          this.returnToList();
        }
      );
      } else {
        this.loading = false;
      this.campaignCategoriesService.sendData(campaignCategoriesOptions).subscribe(
        response => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.campaign_categories.form.warnings.success_title'),
            this.translate.instant('resources.campaign_categories.form.warnings.success_text')
          ).catch(() => {});
          this.loading = true;
          this.returnToList();
        }
      );
    }

  }

  public returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/omnichannel-manager/categories']).catch(() => {});
    }
  }


}

