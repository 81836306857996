import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';
import { FeaturesService } from '../../../../data-warehouse/products/features.service';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { TranslateService } from '@ngx-translate/core';
import { V1SegmentsService } from '../../../../segments/v1-segments.service';
import { isNullOrUndefined } from 'util';
import { FeatureTaxonomiesService } from '../../../../data-warehouse/products/feature-taxonomies.service';

@Component({
  selector: 'app-segment-inputs',
  templateUrl: './segment-inputs.component.html',
  styleUrls: ['./segment-inputs.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [V1SegmentsService, FeaturesService]
})
export class SegmentInputsComponent implements OnInit {

  public inputs: QuestionBase<any>[];
  public segmentForm: UntypedFormGroup;
  public disableInputs: boolean;

  @Input('form') form: UntypedFormGroup;
  @Input('key') key: string;
  @Input('length') length: number;
  @Input('segment') segment: {
    id: number,
    affectation: 'ticket' | 'feature',
    taxonomy_id?: string[],
    feature_ids?: string[],
    action_id: number
  };

  @Output('removeEmitter') removeEmitter: EventEmitter<string>;

  constructor(
    private segmentsService: V1SegmentsService,
    private qcs: QuestionControlService,
    private translate: TranslateService,
    private featuresService: FeaturesService,
    private featuresTaxonomiesService: FeatureTaxonomiesService,
  ) {
    this.removeEmitter = new EventEmitter();
  }

  ngOnInit() {
    this.featuresService.treatPkAsId = true;
    this.inputs = this.getInputs();
    this.segmentForm = this.qcs.toFormGroup(this.inputs);
    this.segmentForm.setValidators(this.getValidations());
    this.form.addControl(this.key, this.segmentForm);
    this.handleDisableInputs();
  }

  handleDisableInputs() {
    if (!isNullOrUndefined(this.segment)) {
      this.disableInputs = true;
    }
  }

  deleteSegment() {
    this.form.removeControl(this.key);
    this.removeEmitter.emit(this.key);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.segmentForm, formKey, value);
  }

  setProductsCategories() {
    this.inputs.find(item => item.key === 'feature_ids').selectedIds = [];
    this.inputs.find(item => item.key === 'feature_ids').value = [];
    const taxonomy = this.segmentForm.value['taxonomy_id'];

    if (taxonomy && taxonomy.length > 0) {
      this.featuresService.setTaxonomies(taxonomy[0].rawElement.slug);
    } else {
      this.featuresService.setTaxonomies(null);
    }
  }

  private getInputs() {
    return [
      new MultiSelectQuestion({
        key: 'id',
        label: 'resources.campaign_plans.types.custom.fields.audience',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.segmentsService,
        required: true,
        selectedIds: this.segment ? [this.segment.id] : []
      }),
      new MultiSelectQuestion({
        key: 'affectation',
        label: 'resources.campaign_plans.types.custom.fields.affectation',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.getAffectationOpts(),
        required: true,
        value: this.segment ? [this.getAffectationOpts().find(opt => opt.id === this.segment.affectation)] : [this.getAffectationOpts()[0]]
      }),
      new MultiSelectQuestion({
        key: 'taxonomy_id',
        label: 'resources.campaign_plans.types.up.fields.taxonomy',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.featuresTaxonomiesService,
        selectedIds: this.segment && this.segment.taxonomy_id && this.segment.taxonomy_id.length > 0 ? this.segment.taxonomy_id : []
      }),
      new MultiSelectQuestion({
        key: 'feature_ids',
        label: 'resources.campaign_plans.types.custom.fields.features',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.featuresService,
        required: true,
        selectedIds: this.segment && this.segment.feature_ids && this.segment.feature_ids.length > 0 ? this.segment.feature_ids : []
      }),
      new TextboxQuestion({
        key: 'action_id',
        type: 'hidden',
        label: 'resources.campaign_plans.types.custom.fields.action_id',
        cssClasses: 'form-control input-default',
        value: this.segment ? this.segment.action_id : null
      })
    ];
  }

  private getValidations(): ValidatorFn[] {
    const featuresPresentIfCategoryTypeVld = ((_ctrl: AbstractControl) => {
      const affectationCtrl = _ctrl.get('affectation');
      const featuresCtrl = _ctrl.get('feature_ids');
      const affectationPresent = affectationCtrl.value && affectationCtrl.value.length > 0 && !isNullOrUndefined(affectationCtrl.value[0]);
      const featuresPresent = featuresCtrl.value && featuresCtrl.value.length > 0 && !isNullOrUndefined(featuresCtrl.value[0]);
      if (affectationPresent && affectationCtrl.value[0].id === 'feature' && !featuresPresent) {
        return {featuresRequiredWhenCategoryMode: true};
      }
      return null;
    });

    this.segmentForm.get('feature_ids').setValidators([]);

    return [featuresPresentIfCategoryTypeVld];
  }

  private getAffectationOpts() {
    return [
      { id: 'ticket', name: this.translate.instant('resources.campaign_plans.types.custom.fields.ticket') },
      { id: 'feature', name: this.translate.instant('resources.campaign_plans.types.custom.fields.category') }
    ];
  }
}
