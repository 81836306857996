<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" *ngIf="element" aria-hidden="true">
              <tbody>
                <tr *ngIf="element.thumbnail">
                  <td class="no-border-top">
                    <img *ngIf="element.thumbnail" class="max-width-300" src="{{element.thumbnail}}" alt="thumbnail">
                  </td>
                </tr>
                <tr *ngIf="element.id">
                  <td class="no-border-top"><b [translate]="'resources.cms.fields.id'"></b></td>
                  <td class="no-border-top">{{element.id}}</td>
                </tr>
                <tr *ngIf="element.title">
                  <td><b [translate]="'resources.cms.fields.title'"></b></td>
                  <td>{{removeTags(element.title)}}</td>
                </tr>
                <tr *ngIf="element.body">
                  <td><b [translate]="'resources.cms.fields.body'"></b></td>
                  <td>{{removeTags(element.body)}}</td>
                </tr>
                <tr *ngIf="element.created_at">
                  <td><b [translate]="'resources.cms.fields.created_at'"></b></td>
                  <td>{{element.created_at | date: 'dd/MM/yyyy'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
