import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MultiselectDataSource } from '../../../../shared/components/multiselect/multiselect';

@Injectable()
export class PlanCampaignActionService extends ResourcesService {

  planCampaignId: number;
  optsSource = new BehaviorSubject<number>(null);
  optsSource$ = this.optsSource.asObservable();

  constructor(http: HttpClient) {
    super(http);
  }

  setCampaign(planCampaignId) {
    this.planCampaignId = planCampaignId;
    this.optsSource.next(planCampaignId);
  }

  fetchMultiselect( searchValues?: string, page?: number, filters?: object ) {

    if ( !this.planCampaignId ) { return of({}); }

    let requestOptions = {
      apiEndPoint: `/campaign_plans/${this.planCampaignId}/actions`,
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {},
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getData({apiEndPoint: `/actions/${id}`});
  }

  getCampaignActions(planCampaignId: number) {
    return this.getData({apiEndPoint: `/campaign_plans/${planCampaignId}/actions`});
  }

  patchCampaign(payload: object, id: number | string): Observable<any> {
    return this.patchResource(payload, `/actions/${id}`);
  }

  patchCampaignChannel(payload: object, id: number | string): Observable<any> {
    return this.patchResource(payload, `/actions/${id}/campaign`);
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`, element);
  }
}
