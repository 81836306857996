<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" *ngIf="action" aria-hidden="true">
              <tbody>
                <tr *ngIf="action.id">
                  <td class="no-border-top"><b [translate]="'resources.scores.massive_actions.columns.id'"></b></td>
                  <td class="no-border-top">{{action.id}}</td>
                </tr>
                <tr *ngIf="action.approved_at">
                  <td><b [translate]="'resources.scores.massive_actions.columns.approved_at'"></b></td>
                  <td>{{action.approved_at | dateTimeZone: 'DD/MM/YYYY HH:mm'}}</td>
                </tr>
                <tr *ngIf="action.customers">
                  <td><b [translate]="'resources.scores.massive_actions.columns.customers'"></b></td>
                  <td>{{action.customers}}</td>
                </tr>
                <tr *ngIf="action.total_score">
                  <td><b [translate]="'resources.scores.massive_actions.columns.total_score'"></b></td>
                  <td>{{action.total_score}}</td>
                </tr>
                <tr *ngIf="action.duration">
                  <td><b [translate]="'resources.scores.massive_actions.columns.duration'"></b></td>
                  <td>{{action.duration}}</td>
                </tr>
                <tr *ngIf="action.loyalty_score_id">
                  <td><b [translate]="'resources.scores.massive_actions.columns.loyalty_score_id'"></b></td>
                  <td>
                    <a [routerLink]="['', { outlets: { modal: ['show', 'loyalty_scores', action.loyalty_score_id] } }]">
                      {{action.loyalty_score_id}}
                    </a>
                  </td>
                </tr>
                <tr *ngIf="action.segment_id">
                  <td><b [translate]="'resources.scores.massive_actions.columns.segment_id'"></b></td>
                  <td>{{action.segment_id}}</td>
                </tr>
                <tr *ngIf="action.status">
                  <td><b [translate]="'resources.scores.massive_actions.columns.status'"></b></td>
                  <td>
                    <span class="text-bold font-size-12 label"
                          [ngClass]="statusClasses[action.status]">
                      {{'resources.scores.massive_actions.status.' + action.status | translate}}
                    </span>
                  </td>
                </tr>
                <tr *ngIf="action.error">
                  <td><b [translate]="'resources.scores.massive_actions.columns.error'"></b></td>
                  <td>{{action.error}}</td>
                </tr>
                <tr *ngIf="action.output_file">
                  <td><b [translate]="'resources.scores.massive_actions.columns.output_file'"></b></td>
                  <td>
                    <button class="btn btn-info btn-xs no-mrg-right"
                            (click)="downloadFile(action.output_file)">
                      <i class="fa fa-download" style="position: relative; bottom: 1px;"></i>
                      <span class="font-size-12 text-bold">{{'resources.scores.massive_actions.messages.download_file' | translate}}</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-loader *ngIf="loading" />
