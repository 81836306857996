import { Component, OnInit, OnDestroy } from '@angular/core';
import { DictionaryService } from '../../../../../shared/services/dictionary.service';
import { CustomersService } from '../../customers.service';
import { Subscription } from 'rxjs';
import { DataTableFilterService } from '../../../../../shared/components/data-table-filter/data-table-filter.service';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { TypeRedemptionsDataColumn, TypeRedemptionsDataMock } from './type-redemptions';

@Component({
  selector: 'app-type-redemptions',
  templateUrl: './type-redemptions.component.html',
  styleUrls: ['./type-redemptions.component.css'],
  providers: [DictionaryService]
})
export class TypeRedemptionsComponent implements OnInit, OnDestroy {

  activeMonths: number[];
  months: {id: number, name: string, key: string}[];
  subs$: Subscription[];
  debug: boolean;
  loading: boolean;
  typeRedemptionsData: TypeRedemptionsDataColumn[];
  averagesColumn: {loyal: number[], shared: number[], casual: number[]};
  onSearch: boolean;

  constructor(private dictionaryService: DictionaryService,
              private customersService: CustomersService,
              private filterService: DataTableFilterService,
              private refreshCacheService: RefreshCacheService,
              private confirmationService: ConfirmationService) {
    this.months = this.dictionaryService.dictionaries.months;
    this.activeMonths = this.months.map((_, index) => index);
    this.subs$ = [];
    this.debug = false;
    this.loading = false;
    this.averagesColumn = {
      loyal: [0, 0, 0],
      shared: [0, 0, 0],
      casual: [0, 0, 0]
    };
  }

  ngOnInit() {
    this.onSearch = false;
    const filterService$ = this.customersService.applyFilters$.subscribe(
      (filterValues) => { 
        this.getTypeRedemptionsData(filterValues);
        this.onSearch = true;
      }  
    );
    this.subs$.push(filterService$);
    setTimeout(() => this.filterService.loaderStatus.next(true));
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  monthArrayIndex(num: number): number[] {
    return new Array(num);
  }

  private getTypeRedemptionsData(filterValues: any) {
    const today = new Date();

    let filters = { ...filterValues };

    if ( Object.keys(filters).length === 0 ) {
      filters = { year: `${today.getFullYear()}` };
    } else {
      const { year_of_last_years, ...filtersPayload } = filters;
      const fltrPayload = { ...filtersPayload, year: filters.hasOwnProperty('year') ? filters['year'] : year_of_last_years };
      filters = fltrPayload;
    }

    if (parseInt(filters['year'], 10) === today.getFullYear()) {
      const activeMonthNum = (today.getMonth() > 0) ? today.getMonth() : 1;
      this.activeMonths = this.months.slice(0, activeMonthNum).map((_, index) => index);
    } else {
      this.activeMonths = this.months.slice(0, this.months.length).map((_, index) => index);
    }

    this.loading = true;
    this.filterService.loaderStatus.next(false);
    if (this.debug) {
      this.enqueueDebugRequest();
    } else {
      this.enqueuePollingRequest('typeRedemptionsData', filters);
    }
  }

  private enqueueDebugRequest() {
    setTimeout(() => {
      this.loading = false;
      this.filterService.loaderStatus.next(true);
      this.typeRedemptionsData = this.parseData(TypeRedemptionsDataMock);
      this.calculateAverages();
    }, 500 );
  }

  private enqueuePollingRequest(dataPropertyKey: string, filters) {
    const params = {apiEndPoint: 'analytics/customer_type_redemptions', filtering: filters};
    const typeRedemptions$ = this.refreshCacheService.getExpensiveData(params).subscribe(
      response => {
        if (!this.refreshCacheService.isRequestPending(response)) {
          typeRedemptions$.unsubscribe();
          this.loading = false;
          this.filterService.loaderStatus.next(true);
          this[dataPropertyKey] = this.parseData(response);
          this.calculateAverages();
        }
      },
      errorData => {
        typeRedemptions$.unsubscribe();
        this.loading = false;
        this.filterService.loaderStatus.next(true);
        this.typeRedemptionsData = [];
        this.confirmationService.displayErrorAlert('Error', errorData.error.error);
      }
    );
    this.subs$.push(typeRedemptions$);
  }

  private parseData(apiResponse: Object[]): TypeRedemptionsDataColumn[] {
    if (apiResponse.length === 0) {return []; }
    const elementsByMonth = this.months.map(month => apiResponse.filter(element => element['m'] === month.id));
    return elementsByMonth.map((monthArrayElement: Object[], i: number) => new TypeRedemptionsDataColumn(i, monthArrayElement));
  }

  private calculateAverages() {
    this.averagesColumn = {
      loyal: this.calculateAverageByType('loyal'),
      shared: this.calculateAverageByType('shared'),
      casual: this.calculateAverageByType('casual')
    };
  }

  private calculateAverageByType(key: string): number[] {
    const activeAverageGlobalAry = this.typeRedemptionsData.slice(0, this.activeMonths.length);
    const averageGlobal = (activeAverageGlobalAry.reduce((acc, obj) => acc + (obj.values[key][0] || 0), 0)) / this.activeMonths.length,
      averageRedeemed = (activeAverageGlobalAry.reduce((acc, obj) => acc + (obj.values[key][1] || 0), 0)) / this.activeMonths.length,
      averageNonRedeemed = (activeAverageGlobalAry.reduce((acc, obj) => acc + (obj.values[key][2] || 0), 0)) / this.activeMonths.length;
    return [averageGlobal, averageRedeemed, averageNonRedeemed];
  }
}
