import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from './condition.service';
import { QuestionBase } from '../../models/forms/question-base';
import { MultiSelectQuestion } from '../../models/forms/question-multiselect';
import { LocationsService } from '../../../resources/data-warehouse/locations/locations.service';
import { TranslateService } from '@ngx-translate/core';
import { formGroupEmptyValidator, multiselectPresenceValidator } from '../validations.service';
import { LocationsTaxonomyTermsService } from '../../../resources/data-warehouse/locations/location-taxonomy-terms.service';
import { LocationCondition } from '../../models/segments/conditions/location-condition';
import { Operator } from '../../models/segments/conditions/operator';
import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isNullOrUndefined } from '../../utils/common.utils';
import { getParseOperatorValue, getParseValue, setLocationsFiltering } from './utils/common.utils';
import { FeatureFlagsService } from '../feature-flags.service';

@Injectable()
export class ClientUsualLocationConditionService implements SegmentConditionProvider {

  flags = this.featureFlags.flags;
  inputs: QuestionBase<any>[];
  customValidators: ValidatorFn[];

  constructor(
    private locationsService: LocationsService,
    private locationsTaxonomyTermsService: LocationsTaxonomyTermsService,
    private translate: TranslateService,
    private featureFlags: FeatureFlagsService
  ) {
    setTimeout(() => {
      this.customValidators = this.flags.showLocationTaxonomyTermIds ? [(control: UntypedFormGroup) => this.getFormGroupValidations(control)] : formGroupEmptyValidator();
    }, 100);    
  }

  getInputs(params: LocationCondition): QuestionBase<any>[] {
    if (this.flags.showLocationTaxonomyTermIds) {
      setLocationsFiltering(params, this.locationsService);
    }
  
    const inputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'operator',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.getOperatorsValues(),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        getValue: getParseValue(),
        parseValue: (value) => (value?.length ? this.getOperatorsValues().find(operator => operator.id === value[0].id ).id : null),
        customValidators: [multiselectPresenceValidator]
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'location_taxonomy_term_ids',
        label: this.translate.instant('resources.segment_conditions.fields.location_taxonomy_term_ids'),
        dataSource: this.locationsTaxonomyTermsService,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        getValue: (value) => ({selectedIds: value ? value : []}),
        parseValue: (values) => (values?.length ? values.map(value => value.id) : []),
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'values',
        label: this.translate.instant('resources.segment_conditions.fields.location_ids'),
        dataSource: this.locationsService,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        required: !this.flags.showLocationTaxonomyTermIds,
        getValue: (value) => ({selectedIds: value ? value : []}),
        parseValue: (values) => ((values?.length) ? values.map(value => value.id) : []),
        customValidators: !this.flags.showLocationTaxonomyTermIds ? [multiselectPresenceValidator] : null
      })
    ];

    if (params) {
      Object.keys(params).forEach( key => {
        const input = inputs.find(_input => _input.key === key);
        const value = input.getValue(params[key]);
        if (value?.hasOwnProperty('selectedIds')) {
          input.selectedIds = value.selectedIds;
        } else {
          input.value = value;
        }
      });
    }

    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI(params: LocationCondition): LocationCondition {
    const parsedValuesObj = {};
    Object.keys(params).forEach(key => {
      const input = this.inputs.find(_input => _input.key === key);
      const parsedValue = input.parseValue(params[key]);
      parsedValuesObj[key] = parsedValue;
    });
    return parsedValuesObj;
  }

  prepareFormValuesFromAPI(params: LocationCondition): LocationCondition {
    const parseValues = {
      operator: getParseOperatorValue(this.getOperatorsValues()),
      location_taxonomy_term_ids: getParseValue(),
      values: getParseValue()
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach( key => { if (parseValues[key]) { parsedValuesObj[key] = parseValues[key](params[key]); } });
    return parsedValuesObj;
  }
  
  private getFormGroupValidations(control: UntypedFormGroup): ValidationErrors {
    const locationTaxonomiesTermIdsControl = control.get('location_taxonomy_term_ids');
    const valuesControl = control.get('values');
    
    const locationsControls = [locationTaxonomiesTermIdsControl, valuesControl];
    locationsControls.forEach(ctrl => ctrl.setValidators([]));
    
    const locationTaxonomiesTermIdsValue = locationTaxonomiesTermIdsControl?.value?.length ? locationTaxonomiesTermIdsControl.value[0].id : null;
    const valuesValue = valuesControl?.value?.length ? valuesControl.value[0].id : null;

    const hasNullLocationsValues = isNullOrUndefined(locationTaxonomiesTermIdsValue) && isNullOrUndefined(valuesValue);
    return hasNullLocationsValues ? {'locationTaxonomiesTermIdsValueRequired': true, 'valuesValueRequired': true } : null;
  }

  private getOperatorsValues(): Operator[] {
    return [
      { id: 'in', name: this.translate.instant('resources.segment_conditions.operators.in')},
      { id: 'not_in', name: this.translate.instant('resources.segment_conditions.operators.not_in')}
    ];
  }
}


