import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductsService } from '../products.service';
import { FeaturesService } from '../features.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DUPLICATE_ID_KEY_ERROR_MESSAGE } from './constants/errors.constants';
import { PRODUCTS_FORM_FIELDS_TEMPLATE } from './constants/products-fields-template.constants';

@Component({
  selector: 'app-form-products',
  templateUrl: './form-products.component.html',
  styleUrls: ['./form-products.component.scss'],
  providers: [FeaturesService]
})

export class FormProductsComponent implements OnInit, OnDestroy {

  productsForm: UntypedFormGroup;
  inputs: QuestionBase<unknown>[];
  id: number;
  productsFormFieldsTemplate: {key: string}[] = PRODUCTS_FORM_FIELDS_TEMPLATE;

  private destroy$ = new Subject<void>();

  private get isEditMode(): boolean {
    return !!this.id;
  }

  @Output() formValidity = new EventEmitter<string>();

  constructor(
    private qcs: QuestionControlService,
    private productsService: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private modalStatusService: ModalStatusService
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  showFieldTemplate(key: string): boolean {
    return (key === 'id' || key === 'features') && this.isEditMode ? false : true;
  }

  sendData(): void {
    const status = this.productsForm.value.status?.length ? this.productsForm.value.status[0].id : [];
    const features = this.productsForm.value.features?.length ? this.productsForm.value.features.map(item => item['rawElement']) : [];

    const payload = {
      id: this.productsForm.value.id,
      name: this.productsForm.value.name,
      code: this.productsForm.value.code,
      status: status,
      description: this.productsForm.value.description,
      price: this.productsForm.value.price,
      features: features
    };

    if (this.isEditMode) {
      delete payload.id;
      delete payload.features;
    }

    this.modalStatusService.formLoaderStatus.emit('loading');
    const request$ = this.isEditMode ? this.productsService.updateData(payload, this.id) : this.productsService.sendData(payload);
    request$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => this.handleSuccessRequest(),
      error: (errorData: HttpErrorResponse) => this.handleErrors(errorData)
    });
  }

  getInvalidFieldValue(key: string): { message: string} {
    return this.productsForm.get(key)?.errors?.invalidFieldValue;
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.modalStatusService.formLoaderStatus.emit('loading');
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getProductsById();
      } else {
        this.setInputsAndForm({});
      }
    });
  }

  private getProductsById(): void {
    this.productsService.getProductsById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (data: HttpResponse<object>) => this.setInputsAndForm(data),
      error: (errorData: HttpErrorResponse) => this.displayErrorAlert(errorData.error.error)
    });
  }

  private setInputsAndForm(requestData: object | HttpResponse<object>): void {
    this.inputs = this.productsService.getInputs(requestData);
    this.productsForm = this.qcs.toFormGroup(this.inputs);
    this.handleFormStatusChanges();
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
  }

  private handleFormStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.productsForm.status));
    this.productsForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
  }

  private handleSuccessRequest(): void {
    const successTitle = this.translate.instant(this.isEditMode ? 'resources.products.form.warnings.update_title' : 'resources.products.form.warnings.success_title');
    const successText = this.translate.instant(this.isEditMode ? 'resources.products.form.warnings.update_text' : 'resources.products.form.warnings.success_text');
    this.confirmationService.displaySuccessAlert(successTitle, successText).catch(() => {});
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    this.modalStatusService.modalStatus.emit();
    this.returnToList();
  }

  private handleErrors(errorData: HttpErrorResponse): void {
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    const errorValue = errorData?.error?.error ? errorData.error.error : null;
    if (errorData?.error?.errors?.length) {
      this.qcs.paintErrorsInForm(this.inputs, this.productsForm, errorData.error.errors);
      if (errorValue) { this.displayErrorAlert(errorValue); }
    } else if (errorValue) {
      this.handleErrorValue(errorValue);
    }
  }

  private handleErrorValue(error: string): void {
    if (error.includes(DUPLICATE_ID_KEY_ERROR_MESSAGE)) {
      this.setErrorsAndAlert('id', 'common.error_warnings.id_already_exists', 'common.error_warnings.duplicate_id_key');
    } else {
      this.displayErrorAlert(error);
    }
  }

  private setErrorsAndAlert(fieldKey: string, fieldError: string, alertError: string): void {
    this.productsForm.get(fieldKey).setErrors({'invalidFieldValue': { message: this.translate.instant(fieldError) }});
    this.productsForm.get(fieldKey).markAsDirty();
    this.displayErrorAlert(this.translate.instant(alertError), 'common.error_warnings.review_data_entered');
  }

  private displayErrorAlert(error: string, titleError?: string): void {
    this.confirmationService.displayErrorAlert(this.translate.instant(titleError ? titleError : 'common.error'), error);
  }

  private returnToList(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }
}
