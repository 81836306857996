<a #modalToggleBtn data-toggle="modal" data-target="#campaign-validate-modal" data-backdrop="false" hidden></a>

<div class="modal modal-backdrop fade in" id="campaign-validate-modal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header border bottom">
        <h4 class="mrg-btm-15" [translate]="translations.title"></h4>
        <button class="modal-close" [attr.data-cy]="'button_closeCampaignValidateModal'" data-dismiss="modal">
          <i class="ti-close"></i>
        </button>
      </div>
      <div class="modal-body background-gray">

        <div class="row">
          <div class="col-md-10 m-auto">

            <p class="text semibold text-dark mrg-vertical-20">
              <span class="alert-icon mrg-right-5">
                <i class="ti-alert font-size-24 text-warning"></i>
              </span>
              {{ translations.msg_title | translate }}
            </p>
            
            <span>{{ translations.msg_description1 | translate }}</span>
            <p>{{ translations.msg_description2 | translate }}</p>

            <form class="mrg-top-40" [formGroup]="campaignValidateModalForm">
              <div class="form-group">
                <div class="display-flex">
                  <i class="ei-email-check text-dark font-size-20 pdd-top-5 pdd-right-20"></i>
                  <app-question class="display-flex text-dark" [question]="getInputConfig('email_notification')" [form]="campaignValidateModalForm">
                  </app-question>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="text-right">
          <button class="btn btn-default" [attr.data-cy]="'button_closeCampaignValidateModal'" data-dismiss="modal"
            [translate]="'common.buttons.cancel'">
          </button>
          <button (click)="validate()" 
            class="btn btn-info"
            [attr.data-cy]="'button_confirmCampaignValidate'" 
            [translate]="translations.validate"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>