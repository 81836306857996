import { Component, OnInit, OnDestroy, EventEmitter, Output, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FeaturesService } from '../features.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureTaxonomiesService } from '../feature-taxonomies.service';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { ProfileService } from '../../../../profiles/profile.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FeatureTaxonomy } from '../../../../shared/models/products/feature-taxonomy';
import { POSITIVE_INTEGER_REGEX } from '../../../../shared/constants/validators.constants';

@Component({
  selector: 'app-form-feature-taxonomies',
  templateUrl: './form-feature-taxonomies.component.html',
  styleUrls: ['./form-feature-taxonomies.component.css'],
  providers: [FeaturesService]
})

export class FormFeatureTaxonomiesComponent implements OnInit, OnDestroy, AfterViewChecked {

  currentRole = this.profileService.getStoredUserRole();
  featureTaxonomiesForm: UntypedFormGroup;
  id: number;
  inputs: QuestionBase<unknown>[];

  get isKindLevelVisible(): boolean {
    return !!['admin', 'owner', 'integration'].includes(this.currentRole);
  }

  private destroy$ = new Subject<void>();

  private get isEditMode(): boolean {
    return !!this.id;
  }

  @Output() formValidity = new EventEmitter<string>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private qcs: QuestionControlService,
    private featuresTaxonomiesService: FeatureTaxonomiesService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private modalStatusService: ModalStatusService,
    private profileService: ProfileService
  ){}

  ngOnInit(): void {
    this.getParams();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  sendData(): void {
    const payload = this.getPayload();
    this.modalStatusService.formLoaderStatus.emit('loading');
    const request$ = this.isEditMode ? this.featuresTaxonomiesService.updateById(payload, this.id) : this.featuresTaxonomiesService.create(payload);
    request$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => this.handleSuccessRequest(),
      error: (errorData: HttpErrorResponse) => this.handleErrors(errorData)
    });
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.modalStatusService.formLoaderStatus.emit('loading');
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getFeatureTaxonomyById();
      } else {
        this.setInputsAndForm({});
      }
    });
  }

  private getFeatureTaxonomyById(): void {
    this.featuresTaxonomiesService.getFeatureById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (data: FeatureTaxonomy) => this.setInputsAndForm(data),
      error: (errorData: HttpErrorResponse) => this.displayHttpErrorAlert(errorData)
    });
  }

  private setInputsAndForm(requestData: FeatureTaxonomy): void {
    this.inputs = this.featuresTaxonomiesService.getInputs(requestData, this.isEditMode);
    this.featureTaxonomiesForm = this.qcs.toFormGroup(this.inputs);
    this.handleFormValueChanges();
    this.handleFormStatusChanges();
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
  }

  private handleFormValueChanges(): void {
    this.featureTaxonomiesForm.get('kind').valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((kindValue) => {
      const isLevelRequired = !!kindValue?.length;
      this.getInputConfig('level').required = isLevelRequired;
      this.featureTaxonomiesForm.get('level').setValidators([]);
      if (isLevelRequired) {
        this.featureTaxonomiesForm.get('level').setValidators([Validators.required, Validators.pattern(POSITIVE_INTEGER_REGEX)]);
      }
    });
  }

  private handleFormStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.featureTaxonomiesForm.status));
    this.featureTaxonomiesForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
  }

  private getPayload(): FeatureTaxonomy {
    const formValue = this.featureTaxonomiesForm.getRawValue();
    const payload = {
      ...formValue,
      origin: formValue.origin?.length ? formValue.origin[0].id : null,
      kind: formValue.kind?.length ? formValue.kind[0].id : null,
      level: formValue.level ?? null
    };

    if (!this.isKindLevelVisible) {
      delete payload.kind;
      delete payload.level;
    }

    return payload;
  }

  private handleSuccessRequest(): void {
    const successTitle = this.translate.instant(this.isEditMode ? 'resources.product_taxonomies.form.warnings.update_title' : 'resources.product_taxonomies.form.warnings.success_title');
    const successText = this.translate.instant(this.isEditMode ? 'resources.product_taxonomies.form.warnings.update_text' : 'resources.product_taxonomies.form.warnings.success_text');
    this.confirmationService.displaySuccessAlert(successTitle, successText).catch(() => {});
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    this.modalStatusService.modalStatus.emit();
    this.returnToList();
  }

  private handleErrors(errorData: HttpErrorResponse): void {
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    const errorValue = errorData?.error?.error ? errorData.error.error : null;
    if (errorData?.error?.errors?.length) {
      this.qcs.paintErrorsInForm(this.inputs, this.featureTaxonomiesForm, errorData.error.errors);
      if (errorValue) { this.displayHttpErrorAlert(errorData); }
    } else if (errorValue) {
      this.displayHttpErrorAlert(errorData);
    }
  }

  private displayHttpErrorAlert(error: HttpErrorResponse): void {
    this.confirmationService.displayHttpErrorAlert(error);
  }

  private returnToList(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }
}
