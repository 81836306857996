import { ActivatedRoute } from '@angular/router';
import { CampaignsService } from '../../campaigns/campaigns.service';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { CsvExportService } from '../../../shared/services/csv-export.service';
import { DataTableFilterService } from '../../../shared/components/data-table-filter/data-table-filter.service';
import { DateTimeZoneService } from '../../../shared/services/date-time-zone.service';
import { DecimalPipe, getCurrencySymbol } from '@angular/common';
import { FeatureFlagsService } from './../../../shared/services/feature-flags.service';
import { FilterConfiguration } from '../../../shared/components/data-table-filter/data-table-filter-cfg';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { isNullOrUndefined } from '../../../shared/utils/common.utils';
import { ModalStatusService } from '../../../shared/services/modal-status.service';
import { MultiselectDataSource } from '../../../shared/components/multiselect/multiselect';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { Plan } from '../plan';
import { PlanCampaignService } from '../campaigns/plan-campaign.service';
import { PlanForecastDetailActionsMock } from '../plan-forecast-detail/plan-forecast-campaign-group-mock';
import { PlanRoiCampaignDetailAction, PlanRoiCampaignGroup, PlanRoiGlobalTotal } from './plan-roi-campaign-group';
import { PlansService } from '../plans.service';
import { ProfileService } from '../../../profiles/profile.service';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { PlanRoiDetailService, TableColumn } from './services/plan-roi-detail-service';

@Component({
  selector: 'app-plan-roi-detail',
  templateUrl: './plan-roi-detail.component.html',
  styleUrls: ['./plan-roi-detail.component.scss'],
  providers: [ DecimalPipe, PlanRoiDetailService ]
})

export class PlanRoiDetailComponent implements OnInit, OnDestroy {
  actionRaw: PlanRoiCampaignDetailAction;
  currencySymbol: string;
  debug: boolean = false;
  filterCfg: FilterConfiguration = {
    disableOnSubmit: false,
    disableOnInit: false,
    disableSubmitBtn: false
  };
  filters: QuestionBase<any>[];
  flags = this.featureFlags.flags;
  hasSupplierCampaign: boolean;
  intervalDelay: number = 30000;

  intervalInstance: any = null;;
  loading: boolean = true;
  openCampaignForm: boolean;
  openReceivedReminder: boolean;
  openReminderModal: boolean;
  parsedRoiDate: string;
  plan: Plan;
  planId: string;
  planRoiData: PlanRoiCampaignGroup[];
  reminderMode: string = 'reminder_plan' || 'received_reminder';;
  roiDate: string;
  showSubtotal: boolean = false;
  subs$: Subscription[] = [];
  enabledColumns: TableColumn[];
  tableColumns: TableColumn[];
  totalValues: PlanRoiGlobalTotal;

  static readonly CHANNEL_COLUMNS_OFFSET = 31;
  channelColumnsOffset = PlanRoiDetailComponent.CHANNEL_COLUMNS_OFFSET;

  @ViewChild('toggleBtn') toggleBtn: ElementRef;

  constructor(
    private campaignsService: CampaignsService,
    private confirmationService: ConfirmationService,
    private csvExportService: CsvExportService,
    private decimalPipe: DecimalPipe,
    private featureFlags: FeatureFlagsService,
    private filterService: DataTableFilterService,
    private modalStatusService: ModalStatusService,
    private planCampaignsService: PlanCampaignService,
    private plansService: PlansService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private dateService: DateTimeZoneService,
    private planRoiDetailService: PlanRoiDetailService
  ) {
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
  }

  ngOnInit(): void {
    this.tableColumns = this.planRoiDetailService.getColumnList(this.currencySymbol, this.featureFlags.flags);
    this.enabledColumns = this.tableColumns.filter(el => !el.disabled);
    this.getParams();
    this.retrieveStoredColumnVisibility();
  }

  ngOnDestroy(): void {
    if (this.intervalInstance) { clearInterval(this.intervalInstance); }
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  onFilterHandler($event) {
    this.getPlanRoiData($event);
  }

  handleShowSubtotal() {
    this.showSubtotal = this.toggleBtn.nativeElement.checked;
  }

  closeModal(event) {
    this.openReminderModal = !event;
  }

  calculateROI() {
    this.filters = null;
    this.loading = true;
    const forecast$ = this.plansService.calculateROI(`${this.plan.id}`).subscribe(
      () => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.plans.messages.calc_roi_success_title'),
          this.translate.instant('resources.plans.messages.calc_roi_success_desc')
        ).catch(() => {});
        this.getPlan(`${this.plan.id}`);
      },
      (errorData: HttpErrorResponse) => {
        this.loading = false;
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error);
      }
    );
    this.subs$.push(forecast$);
  }

  exportROI() {
    const csvData = this.planRoiDetailService.dataToCSV(this.tableColumns, this.planRoiData, this.totalValues, this.flags);
    const blob = this.csvExportService.getCsvBlob(csvData);
    const url = window.URL.createObjectURL(blob);
    const filename = `plan_${this.plan.id}_roi_${moment().format('DDMMYYYY')}.csv`;

    if (navigator['msSaveOrOpenBlob']) {
      navigator['msSaveBlob'](blob, filename);
    } else {
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  isRoiInProgress() {
    return this.plan && this.plan.isRoiInProgress();
  }

  isPlanDelivered() {
    return this.plan && this.plan.isDelivered();
  }

  openReminderForm(action, mode) {
    this.actionRaw = action;
    this.openReminderModal = true;
    this.reminderMode = mode;
  }

  reprintCampaignOrWholePlan(campaignHistoryId?: number) {
    let campaign_history_ids;
    if (!isNullOrUndefined(campaignHistoryId)) {
      campaign_history_ids = [campaignHistoryId];
    } else {
      const parsedRoiData = this.planRoiData.map(el => el.actions.map(_el => _el.embeddedCampaign.campaignHistoryId));
      campaign_history_ids = parsedRoiData.reduce((prev, curr) => prev.concat(curr), []);
    }
    const printUndoWarning = this.translate.instant('common.messages.are_you_sure');
    const printUndoText = this.translate.instant('resources.plans.messages.reprint_vouchers');
    this.confirmationService.displayConfirmationAlert(printUndoWarning, printUndoText).then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.campaignsService.reprint({ campaign_history_ids }).subscribe(
          () => {
            this.confirmationService.displaySuccessAlert(
              this.translate.instant('resources.plans.messages.reprint_vouchers_title'),
              this.translate.instant('resources.plans.messages.reprint_campaign_success')
            ).catch(() => {});
            this.modalStatusService.modalStatus.emit();
          },
          errorData => this.confirmationService.displayErrorAlert('Error', JSON.stringify(errorData.error.error))
        );
      }
    }).catch(() => {});
  }

  tableCfgClickHandler(event: MouseEvent, colId: string) {
    // Prevent dropwdown from collapsing so the end-user can keep (de)selecting columns
    event.stopPropagation();
    const cbValue = (<HTMLInputElement>event.target).checked;
    if (!isNullOrUndefined(cbValue)){
      this.tableColumns.find(el => el.id === colId).visible = (<HTMLInputElement>event.target).checked;
    }
    localStorage.setItem('plan_roi_detail_visibility', JSON.stringify(this.tableColumns.map(el => el.visible)));
  }

  updateRemindersCount() {
    this.getPlanActions();
  }

  checkIncludedDates() {
    return this.roiDate >= this.plan.available_from && this.roiDate <= this.plan.available_to;
  }

  private retrieveStoredColumnVisibility() {
    const visibilityCfg = JSON.parse(localStorage.getItem('plan_roi_detail_visibility_w_coupon'));
    if (visibilityCfg) { this.tableColumns.forEach((el, i) => el.visible = visibilityCfg[i]); }
  }

  private getParams() {
    const routeParent$ = this.route.parent.params.subscribe(parentParams => {
      this.planId = parentParams.id;
      this.planCampaignsService.setPlan(parseInt(parentParams.id, 10));
      this.getPlan(this.planId);
    });
    this.subs$.push(routeParent$);
  }

  private getPlan(planId: string) {
    const plan$ = this.plansService.getPlanById(planId).subscribe(
      (planData: HttpResponse<Object>) => {
        this.plan = new Plan(planData);
        this.modalStatusService.resourceStatus.emit(this.plan);
        this.filters = this.getInputs();
        if ( this.plan.isRoiInProgress() ) {
          this.startIterativeCheckRequest();
        } else {
          if ( this.intervalInstance ) { clearInterval(this.intervalInstance); }
          this.getPlanRoiData();
        }
      },
      (errorData: HttpErrorResponse) => this.confirmationService.displayErrorAlert(
        this.translate.instant('common.error'),
        errorData.error.error
      )
    );
    this.subs$.push(plan$);
  }

  private startIterativeCheckRequest() {
    if ( isNullOrUndefined(this.intervalInstance )) {
      this.intervalInstance = setInterval(() => this.getPlan(`${this.plan.id}`), this.intervalDelay);
    }
  }

  public getInputs(): QuestionBase<any>[] {
    return [
      new MultiSelectQuestion({
        key: 'campaign_plan_type',
        label: this.translate.instant('resources.plans.filters.campaign_type'),
        cssClasses: 'form-control input-default',
        settings: {singleSelection: false, enableSearchFilter: false, enableCheckAll: true, showCheckbox: true},
        options: this.getPlanCampaignCategoryOptions()
      }),
      new MultiSelectQuestion({
        key: 'campaign_plan_ids',
        label: this.translate.instant('resources.plans.filters.campaign'),
        cssClasses: 'form-control input-default',
        settings: {singleSelection: false, enableSearchFilter: true, enableCheckAll: true, showCheckbox: true},
        dataSource: this.planCampaignsService
      })
    ];
  }

  private getPlanCampaignCategoryOptions(): MultiselectDataSource[] {
    return this.plansService.getStaticTypes().slice(0, 4).map(el => new MultiselectDataSource(el.type, el.name));
  }

  private getPlanRoiData(filters?: Object) {
    if (this.debug) {
      this.enqueueDebugRequest(filters);
    } else {
      this.getPlanActions(filters);
    }
  }

  private enqueueDebugRequest(filters?: Object) {
    this.loading = true;
    this.filterService.loaderStatus.next(false);
    setTimeout(() => {
      this.loading = false;
      this.filterService.loaderStatus.next(true);
      this.planRoiData = this.parseActions(PlanForecastDetailActionsMock);
      this.totalValues = new PlanRoiGlobalTotal(this.planRoiData);
    }, 500);
  }

  private getPlanActions(filters?: Object) {
    this.loading = true;
    const actions$ = this.plansService.getActions(`${this.plan.id}`, filters).subscribe(
      (reqResponse) => {
        this.planRoiData = this.parseActions(reqResponse);
        this.totalValues = new PlanRoiGlobalTotal(this.planRoiData);
        this.hasSupplierCampaign = this.planRoiData.some(campaign => campaign.type === 'Plans::SupplierCampaign');
        this.loading = false;
      },
      (errorResponse) => {
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorResponse.error.error);
        this.loading = false;
      }
    );
    this.subs$.push(actions$);
  }

  private parseActions(response: Object): PlanRoiCampaignGroup[] {
    const actionList = response['_embedded']['list'];
    const actionsWithCoupon = actionList.filter(this.onlyDelivered);
    const typesList = actionsWithCoupon.map((el: Object) => el['_embedded']['campaign_plan']['type']).filter(this.onlyUnique);
    this.roiDate = actionsWithCoupon.length > 0 ? actionsWithCoupon[0].roi.updated_at : null;
    this.parsedRoiDate = this.roiDate?.toString().split('T')[0];
    const planRoiCampaignGroups: PlanRoiCampaignGroup[] = typesList.map((type: string) => {
      const campaigns = actionsWithCoupon.filter((action: Object) => action['_embedded']['campaign_plan']['type'] === type);
      return new PlanRoiCampaignGroup({
        type: type,
        category: this.translate.instant(`resources.campaign_plans.types.dictionary.${type}`),
        campaigns: campaigns,
        daysPassed: this.dateService.getDatesDiffByTimeUnit(new Date(this.plan.available_from), new Date(this.roiDate), 'days', true),
        planDuration: this.dateService.getDatesDiffByTimeUnit(new Date(this.plan.available_from), new Date(this.plan.available_to), 'days', true)
      });
    });
    return planRoiCampaignGroups;
  }

  private onlyDelivered(action: Object, index: number, self: Object[]) {
    const couponId = action['_embedded'] && action['_embedded']['coupon'] && action['_embedded']['coupon']['id'] ? action['_embedded']['coupon']['id'] : null;
    return couponId && action['_embedded']['campaign_plan']['status'] === 'delivered';
  }

  private onlyUnique(value: any, index: number, self: any[]) {
    return self.indexOf(value) === index;
  }
}
