import { Component, Input, OnInit } from '@angular/core';
import { CurrentUser } from '../../../../models/current-user';
import { isNullOrUndefined } from '../../../../utils/common.utils';
import { ProfileService } from '../../../../../profiles/profile.service';
import { QuestionBase } from '../../../../models/forms/question-base';
import { QuestionControlService } from '../../../../services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';
import { FeatureFlagsService } from '../../../../services/feature-flags.service';

@Component({
  selector: 'app-campaign-segment-block',
  templateUrl: './campaign-segment-block.component.html',
  styleUrls: ['./campaign-segment-block.component.scss']
})

export class CampaignSegmentBlockComponent implements OnInit {

  @Input('campaignForm') campaignForm: UntypedFormGroup;
  @Input('inputs') inputs: QuestionBase<any>[];
  @Input() mode: 'default' | 'plan_manual_campaign' | 'reminder_plan' | 'received_reminder' = 'default';
  isControlGroupRatioPresent = false;
  flags = this.featureFlags.flags;

  constructor(
    private qcs: QuestionControlService,
    private profileService: ProfileService,
    private featureFlags: FeatureFlagsService
  ) {}

  ngOnInit(): void {
    this.isControlGroupRatioPresent = this.evalIfControlGroupRatioIsPresent();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  openSegment() {
    if ( this.campaignForm.get('segment').value && this.campaignForm.get('segment').value.length ) {
      const segmentId = this.campaignForm.get('segment').value[0].id;
      window.open(`#/segments/segments/${segmentId}`, '_blank');
    }
  }

  handleSelectedSegment(event) {
    if (event && this.mode === 'plan_manual_campaign') {
      this.campaignForm.get('include_phone_duplicated').setValue(event.include_phone_duplicated);
    }
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.campaignForm, formKey, value);
  }

  shouldShowControlGroup(): boolean {
    const isNotPlanManualCampaign = this.mode !== 'plan_manual_campaign';
    const isViaNone = this.campaignForm.value?.via?.[0]?.id === 'none';
    const hasControlGroupRatio = this.isControlGroupRatioPresent;

    return isNotPlanManualCampaign &&
      isViaNone &&
      hasControlGroupRatio;
  }

  shouldShowContactPolicies(): boolean {
    return this.flags.showContactPolicies &&
      this.mode !== 'plan_manual_campaign' &&
      (this.hasFormKeyWithValue('via', 'email') ||
      this.hasFormKeyWithValue('via', 'sms') ||
      this.hasFormKeyWithValue('via', 'push'));
  }

  private evalIfControlGroupRatioIsPresent(): boolean {
    const company = new CurrentUser(this.profileService.getStoredUser()).company;
    return !isNullOrUndefined(company.controlGroupRatio);
  }

}
