<div id="accordion-1" class="accordion panel-group" role="tablist" aria-multiselectable="true">
  <div class="card">
    <div class="card-heading border bottom" role="tab" id="headingOne">
      <h4 class="panel-title">
        <a data-toggle="collapse" data-parent="#accordion-1" href="#collapseOne" aria-expanded="true" class="show no-pdd">
          <span translate>resources.tickets.modal.cards.lines.title</span>
          <i class="icon ti-arrow-circle-down"></i>
        </a>
      </h4>
    </div>
    <div id="collapseOne" class="panel-collapse show">
      <div class="card-block no-pdd-top pdd-horizon-15">
        <table class="table table-hover table-sm" aria-hidden="true">
          <thead>
            <tr>
              <th [translate]="'resources.tickets.modal.cards.lines.table.order'"></th>
              <th [translate]="'resources.tickets.modal.cards.lines.table.product'"></th>
              <th [translate]="'resources.tickets.modal.cards.lines.table.quantity'"></th>
              <th [translate]="'resources.tickets.modal.cards.lines.table.total'">({{currencySymbol}})</th>
            </tr>
          </thead>
          <tbody *ngIf="ticketData">
            <tr *ngFor="let item of ticketData._embedded.lines">
              <td [id]="'line' + item.line_order">{{ item.line_order }}</td>
              <td *ngIf="item._embedded?.product?.name">
                <ng-container *ngIf="isProductNameText; else productNameLink">
                  <span>
                    {{item._embedded.product.name}}
                  </span>
                </ng-container>
                <ng-template #productNameLink>
                  <a class="product-name"
                    (click)="openProductInNewTab(item.product_id)">
                    {{item._embedded.product.name}}
                  </a>
                </ng-template>
              </td>
              <td *ngIf="!item._embedded?.product?.name">
                {{item.product_id}}
              </td>
              <td>{{ item.quantity | number: '1.0-2': 'es'}}</td>
              <td>{{ item.total | lgcurrency: '1.2-2': this.ticketData.currency }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- -->
</div>