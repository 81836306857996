import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-plan1',
  templateUrl: './card-plan1.component.html',
  styleUrls: ['./card-plan1.component.scss']
})
export class CardPlan1Component {
  @Input() title: string;
  @Input() text: string;
  @Input() icon: string;
  @Input() color: string;
}
