<a #modalToggleBtn 
   data-toggle="modal"
   data-target="#import-supplier-products-modal"
   data-backdrop="false"
   data-keyboard="false"
   hidden>
</a>

<form [formGroup]="form" *ngIf="form">
	<div class="modal fade show" id="import-supplier-products-modal">
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">

				<div class="modal-header">
					<h4 class="mrg-btm-5 first-letter-upper">
						{{'resources.campaign_plans.types.supplier.title_products_import' | translate}}
					</h4>
					<button class="modal-close" [disabled]="loading" (click)="closeModal()">
						<i class="ti-close"></i>
					</button>
				</div>

				<div class="modal-body background-gray">
					<div class="row">
						<div class="col-md-12">
							<p [translate]="'resources.campaign_plans.types.supplier.messages.import_products_instructions'"></p>
							<app-question [question]="inputs[0]" [form]="form"></app-question>
							<div class="text-danger" *ngIf="failedImportProducts">
								<span [innerHTML]="'resources.campaign_plans.types.supplier.messages.import_products_error' | translate: {ids: failedImportProducts}"></span>
							</div>
						</div>
					</div>
				</div>

				<div class="modal-footer background-white">
					<app-loader *ngIf="loading" />
					<div class="pull-right d-flex align-items-center">
						<button class="pull-right btn btn-secondary"
										(click)="closeModal()"
										[disabled]="loading"
										[translate]="'common.buttons.cancel'">
						</button>
						<button class="pull-right btn btn-info"
										(click)="save()"
										[translate]="'resources.campaign_plans.types.supplier.buttons.select'"
										[disabled]="form.invalid || loading">
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
