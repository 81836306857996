import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class S3Service {

  private S3Uri: string;

  constructor(private http: HttpClient) {
    this.S3Uri = '/s3_files/upload_csv';
  }

  async uploadFile(file: File): Promise<string> {
    const content = await file.text();
    return new Promise((resolve, reject) => {
      this.http.post(this.S3Uri, {filename: file.name, content: content}).subscribe(response => {
        resolve(response['filename']);
      }, err => {
        reject(err);
      })
     });
  }

}
