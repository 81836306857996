import { Injectable } from '@angular/core';
import { timeZones } from './time-zones.mock';
import { ResourcesService } from './resources.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { MultiselectDataSource } from '../components/multiselect/multiselect';

@Injectable()
export class TimeZonesService extends ResourcesService {

  constructor(http: HttpClient,
              private translate: TranslateService) {
    super(http);
  }

  public getTimeZones() {
    return timeZones;
  }

  public getTimeZoneByName(name: string) {
    return timeZones.find(timeZone => timeZone.name === name);
  }

  public filterTimeZonesByName(name: string) {
    return timeZones.filter(timeZone => timeZone.name.toLowerCase().search(name.toLowerCase()) !== -1);
  }

  public fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {

    if (searchValues) {
      return of(this.filterTimeZonesByName(searchValues));
    }

    return of(this.getTimeZones());
  }

  public getNameWithTemplate(_element: any, _id): MultiselectDataSource {
    const element = _element.hasOwnProperty('list') ? _element.list.find( el => el.name === _id ) : _element;
    const elementName = element.name + ' (' + element.offset + ')';
    return new MultiselectDataSource(element.name, elementName, element);
  }

  public fetchSelectedById(name: string): Observable<object> {
    return of(this.getTimeZoneByName(name));
  }
}
