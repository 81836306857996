<form>
  <app-up-cross-general-inputs [upCrossGeneralInputs]="form" [planCampaign]="planCampaign"></app-up-cross-general-inputs>
  <hr>

  <!-- UP SELLING -->
  <div class="row">
    <div class="col-md-3">
      <app-question [question]="getInputConfig('upselling')" [form]="form"></app-question>
    </div>
  </div>

  <div class="row mrg-left-15">
    <div class="col-md-3">
      <app-question [question]="getInputConfig('up_campaign_plan_category_id')" [form]="form"></app-question>
    </div>
  </div>

  <div class="row mrg-left-15">
    <div class="col-md-3">
      <app-question (click)="markAsDirty('period_date_from')" [question]="getInputConfig('period_date_from')" [form]="form"></app-question>
    </div>
    <div class="col-md-3">
      <app-question (click)="markAsDirty('period_date_to')" [question]="getInputConfig('period_date_to')" [form]="form"></app-question>
    </div>
  </div>

  <hr>

  <!-- CROSS-SELLING -->
  <div class="row">
    <div class="col-md-3">
      <app-question [question]="getInputConfig('cross')" [form]="form"></app-question>
    </div>
  </div>

  <div class="row mrg-left-15">
    <div class="col-md-3">
      <app-question [question]="getInputConfig('cross_campaign_plan_category_id')" [form]="form"></app-question>
    </div>
  </div>

  <div class="row mrg-left-15">
    <div class="col-md-3">
      <app-question [question]="getInputConfig('periods')" (multiselectChanged)="handlePeriodChange($event)" [form]="form"></app-question>
    </div>
  </div>

  <div class="row mrg-left-15">
    <div class="col-md-3">
      <app-question [question]="getInputConfig('has_bought')" (blur)="handleCheckChanged()" [form]="form"></app-question>
    </div>
  </div>

  <div class="row mrg-left-15">
    <div class="col-md-3">
      <app-question [question]="getInputConfig('active_clients')" (blur)="handleCheckChanged()" [form]="form"></app-question>
    </div>
  </div>

</form>



