<div class="page-title background-white">
  <div class="row pdd-horizon-15">
    <div class="col-md-9 d-flex align-items-center min-height-60px">
      <h4 class="no-mrg-vertical" [translate]="'menu_dia.customer_data_platform.sections.customer_attributes'"></h4>
    </div>
  </div>
</div>

<div class="mrg-top-15 container-fluid">
  <app-data-table-filter *ngIf="filters"
                          [questions]="filters"
                          (onSubmitEmitter)="onFilterHandler($event)">
  </app-data-table-filter>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="position-relative">
        <div class="card">
          <div class="pdd-horizon-15 pdd-vertical-15">
            <app-data-table [config]="dataTableConfig"></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
