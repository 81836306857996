<div class="container-fluid clearfix">
  <div class="row">
    <div class="col-md-8 ml-auto mr-auto">
      <div class="card">
        <div class="card-heading border bottom" *ngIf="element">
          <h4 class="card-title">{{element.voucheable.name}}</h4>
        </div>
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" *ngIf="element" aria-hidden="true">
              <tbody>
                <tr *ngIf="element.voucheable.name">
                  <td class="no-border-top" *ngIf="element.voucheable.type ==='coupon'"><b [translate]="'resources.vouchers.fields.original_voucher'"></b></td>
                  <td class="no-border-top" *ngIf="element.voucheable.type ==='message'"><b [translate]="'resources.vouchers.fields.motivator_name'"></b></td>
                  <td class="no-border-top">
                    <ng-container *ngIf="isModal && isStoreManagerRole; else couponNameLink">
                      <span>
                        {{element.voucheable.name}}
                      </span>
                    </ng-container>
                    <ng-template #couponNameLink>
                      <a class="pointer colorLink" (click)="goToMessage()">
                        {{element.voucheable.name}}
                      </a>
                    </ng-template>
                  </td>
                </tr>
                <tr *ngIf="element.code">
                  <td><b [translate]="'resources.vouchers.fields.code'"></b></td>
                  <td>{{element.code}}</td>
                </tr>
                <tr *ngIf="element.description">
                  <td><b [translate]="'resources.scores.loyalty_scores.fields.description'"></b></td>
                  <td>{{element.description}}</td>
                </tr>
                <tr *ngIf="element._embedded.customer.name">
                  <td><b [translate]="'resources.vouchers.fields.customer_name'"></b></td>
                  <td>
                    <a class="pointer colorLink" (click)="goToProfile()">{{element._embedded.customer.name}}</a>
                  </td>
                </tr>
                <tr *ngIf="element.created_at">
                  <td><b [translate]="'resources.vouchers.fields.created_at'"></b></td>
                  <td>{{element.created_at | date: 'dd/MM/yyyy'}}</td>
                </tr>
                <tr *ngIf="getVoucherStatus(element)">
                  <td><b [translate]="'resources.vouchers.fields.status'"></b></td>
                  <td><span class="label"
                            [ngClass]="getVoucherLabel(getVoucherStatus(element))"
                            [translate]="'resources.vouchers.states.' + getVoucherStatus(element)"></span></td>
                </tr>
                <tr *ngIf="element.max_redemptions">
                  <td><b [translate]="'resources.vouchers.fields.max_redemptions'"></b></td>
                  <td>{{ element.max_redemptions }}</td>
                </tr>
                <tr *ngIf="element.available_redemptions">
                  <td><b [translate]="'resources.vouchers.fields.available_redemptions'"></b></td>
                  <td>{{ element.available_redemptions }}</td>
                </tr>
                <tr *ngIf="flags.showVouchersFrozenRedemptions && element.frozen_redemptions !== null">
                  <td><b [translate]="'resources.vouchers.fields.frozen_redemptions'"></b></td>
                  <td>{{ element.frozen_redemptions }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- TICKETS -->
  <div class="row" *ngIf="element && element.activity_vouchers && element.activity_vouchers.length > 0">
    <div class="col-md-8 ml-auto mr-auto">
      <div class="card">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true">
              <thead >
                <th class="no-border-top" [translate]="'resources.vouchers.fields.id_ticket'"></th>
                <th class="no-border-top" [translate]="'resources.vouchers.fields.discount'"></th>
                <th class="no-border-top" [translate]="'resources.vouchers.fields.total_discount'"></th>
              </thead>
              <tbody>
                <tr *ngFor="let activity of element.activity_vouchers">
                  <td>
                    <a [routerLink]="['', { outlets: { modal: ['show', 'activities', activity.activity_id] }}]">
                      {{activity.activity_id}}
                    </a>
                  </td>
                  <td >{{activity.discount_count}}</td>
                  <td >{{activity.sum_discounts/100 | lgcurrency: '1.2-2'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- HISTORY -->
  <div class="row" *ngIf="element && element._embedded.voucher_histories && element._embedded.voucher_histories.length > 0">
    <div class="col-md-8 ml-auto mr-auto">
      <div class="card">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true">
              <thead>
                <th class="no-border-top" [translate]="'resources.vouchers.fields.status'"></th>
                <th class="no-border-top" [translate]="'resources.vouchers.fields.updated_date'"></th>
              </thead>
              <tbody>
                <tr *ngFor="let item of element._embedded.voucher_histories">
                  <td><span class="label label-info"
                            [ngClass]="{'label-success': item.status === 'redeemed',
                                        'label-danger': item.status === 'cancelled',
                                        'label-warning': item.status === 'printed',
                                        'label-info': item.status === 'pending',
                                        'label-info': item.status === 'freezed',
                                        'label-info': item.status === 'unfreezed'}"
                            [translate]="'resources.vouchers.states.' + item.status"></span></td>
                  <td><span class="label label-info">{{item.created_at | dateTimeZone: 'DD/MM/YYYY HH:mm:ss'}}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
