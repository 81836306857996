import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { QuestionControlService } from '../../../../../../../../shared/services/question-control.service';
import { ActionModalsService } from '../action-modals.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBase } from '../../../../../../../../shared/models/forms/question-base';
import { ModalStatusService } from '../../../../../../../../shared/services/modal-status.service';
import { CurrentCompany } from '../../../../../../../../shared/models/current-user';
import { ProfileService } from '../../../../../../../../profiles/profile.service';

@Component({
  selector: 'app-customer-fusion',
  templateUrl: './customer-fusion.component.html',
  providers: [ActionModalsService]
})
export class CustomerFusionComponent implements OnInit, OnDestroy {

  public customerFusionForm: UntypedFormGroup;
  public inputs: any;
  public id: string;
  public showButton: boolean;
  public customerFusion$: Subscription;
  public loading: boolean;
  public route$: Subscription;
  private currentCompany: CurrentCompany;

  constructor(private qcs: QuestionControlService,
              private actionModalService: ActionModalsService,
              private route: ActivatedRoute,
              private router: Router,
              private confirmationService: ConfirmationService,
              private translate: TranslateService,
              private modalStatusService: ModalStatusService,
              private profileService: ProfileService,) {}

  ngOnInit() {
    this.currentCompany = new CurrentCompany(this.profileService.getProfileCompany());
    this.getParams();
  }

  ngOnDestroy() {
    if (this.customerFusion$) { this.customerFusion$.unsubscribe(); }
    if (this.route$) { this.route$.unsubscribe(); }
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  newCustomerInputLabelKey(): string {
    let key = 'resources.customers.show.action_modals.new_card_number';

    if(this.currentCompany.isAccountsIdentifierByCode()) {
      key = 'resources.customers.show.action_modals.new_card_code';
    }
    return key;
  }

  sendData() {
    this.loading = true;
    this.customerFusion$ = this.actionModalService.customerFusion(this.id, this.customerFusionForm.value.new_customer_id_code).subscribe(
      response => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.customers.warnings.customer_fusion_title'),
          this.translate.instant('resources.customers.warnings.customer_fusion_text')
        ).catch(() => {});
        this.modalStatusService.modalStatus.emit();
        this.loading = false;
        this.closeModal();
      },
      error => {
        this.loading = false;
        this.confirmationService.displayErrorAlert('Error', error.error.error);
      });
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getParams() {
    this.route$ = this.route.params.subscribe(params => {
      this.id = params.id;
      this.inputs = this.actionModalService.getInputs();
      this.customerFusionForm = this.qcs.toFormGroup(this.inputs);
    });
  }
}
