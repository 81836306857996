export enum CustomerAttributeKey {
  DefinitionId = 'definition_id',
  DefinitionType = 'definition_type',
  StringOperator = 'string_operator',
  IntegerOperator = 'integer_operator',
  FloatOperator = 'float_operator',
  BooleanOperator = 'boolean_operator',
  DateOperator = 'date_operator',
  StringValue = 'string_value',
  IntegerValue = 'integer_value',
  IntegerValue2 = 'integer_value2',
  FloatValue = 'float_value',
  FloatValue2 = 'float_value2',
  BooleanValue = 'boolean_value',
  DateValue = 'date_value',
  DateValue2 = 'date_value2'
}