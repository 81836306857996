<div class="row">
  <div class="col-lg-12">
    <div role="alert"
          class="alert alert-warning alert-dismissible fade show mrg-top-5"
          *ngIf="isAnalysisInProgress()">
      {{'resources.plans.messages.calculating_analysis' | translate}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>

<app-data-table-filter *ngIf="displayFilter"
                        [slug]="'plan-analysis'"
                        [questions]="filters"
                        (onSubmitEmitter)="onFilterHandler($event)"
                        (onClearEmitter)="onFilterClearHandler()"
                        [cfg]="filterCfg">
</app-data-table-filter>

<ng-container>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block padding-20 pdd-btm-10">
          <app-loader styleClass="card" *ngIf="loading" />
          <div class="pull-left" *ngIf="analysisDate">
            <i class="ei-timer"></i>&nbsp;
            <b class="card-title">{{'resources.plans.fields.generated_at' | translate}}</b>: <b class="card-title">{{analysisDate | dateTimeZone: 'DD/MM/YYYY HH:mm'}}</b>
          </div>
          <div class="pull-left" *ngIf="!analysisDate">
            <i class="ei-timer"></i>&nbsp;
            <b class="card-title">{{'resources.plans.messages.analysis_not_calculated' | translate}}</b>
          </div>
          <div class="btn-group pull-right">
            <button id="planDetailRoiCalcBtn"
                    class="btn btn-sm btn-default btn-icon"
                    *ngIf="plan"
                    [disabled]="!isAbleToCalculateAnalysis()"
                    (click)="calculateAnalysis()"
                    tooltipPosition="left"
                    pTooltip="{{'resources.plans.buttons.calc_analysis' | translate}}">
              <i class="ei-refresh icon-align"></i>
            </button>
          </div>
          <div class="clearfix mrg-btm-10"></div>
          <app-looker-iframe-container
            #dashboard
            [dashboardIdsInpt]="[PLAN_ANALYSIS_DASHBOARD_ID]">
          </app-looker-iframe-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>