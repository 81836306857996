<div class="modal-backdrop fade in" [id]="title" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-lg" role="document" [ngClass]="{'max-width-85': apiEndPoint === 'streaming_api'}">
		<div class="modal-content">

			<div class="modal-header">
				<h1 class="mrg-left-15 mrg-btm-5 first-letter-upper">{{title}}</h1>
				<button class="modal-close" (click)="closeModal()">
					<i class="ti-close"></i>
				</button>
			</div>

			<div class="modal-body background-gray">
				<div class="row">
					<div class="col-md-12" *ngIf="mode !== 'show'">
						<app-form-locations (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'locations'"></app-form-locations>
						<app-form-locations-taxonomies (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'location_taxonomies' "></app-form-locations-taxonomies>
						<app-form-location-taxonomies-terms (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'location_taxonomy_terms'"></app-form-location-taxonomies-terms>
						<app-import-location-taxonomy-terms (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'import_location_taxonomy_terms'"></app-import-location-taxonomy-terms>
						<app-form-products-features (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'features'"></app-form-products-features>
						<app-form-products-internal-categories (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'feature_internals'"></app-form-products-internal-categories>
						<app-form-feature-taxonomies (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'feature_taxonomies'"></app-form-feature-taxonomies>
						<app-form-products (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'products'"></app-form-products>
						<app-form-promotional-discounts (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'promotional_discounts'"></app-form-promotional-discounts>
						<app-form-credit-transactions #form *ngIf="apiEndPoint === 'credit_transactions'"></app-form-credit-transactions>
						<app-form-segment-categories #form *ngIf="apiEndPoint === 'segment_categories'"></app-form-segment-categories>
						<app-form-loyalty-score-moments #form *ngIf="apiEndPoint === 'loyalty_score_moments'"></app-form-loyalty-score-moments>
						<app-form-reward-categories (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'reward_categories'"></app-form-reward-categories>
						<app-form-campaigns-categories #form *ngIf="apiEndPoint === 'campaign_categories'"></app-form-campaigns-categories>
						<app-form-message-categories #form *ngIf="apiEndPoint === 'message_categories'"></app-form-message-categories>
						<app-form-score-exchanges #form *ngIf="apiEndPoint === 'score_exchanges'"></app-form-score-exchanges>
						<app-form-wallets #form *ngIf="apiEndPoint === 'wallets'"></app-form-wallets>
						<app-form-messages #form *ngIf="apiEndPoint === 'messages' || apiEndPoint === 'message_histories'"></app-form-messages>
						<app-form-recipes #form *ngIf="apiEndPoint === 'recipes'"></app-form-recipes>
						<app-form-users (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'user'"></app-form-users>
						<app-form-execute-date (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'execute_date'"></app-form-execute-date>
						<app-form-payment-methods #form *ngIf="apiEndPoint === 'payment_methods'"></app-form-payment-methods>
						<app-form-news-taxonomy-terms (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'news_taxonomy_terms'"></app-form-news-taxonomy-terms>
						<app-form-news-taxonomies #form *ngIf="apiEndPoint === 'news_taxonomies'"></app-form-news-taxonomies>
						<app-form-currency-conversions #form *ngIf="apiEndPoint === 'currency_conversions'"></app-form-currency-conversions>
						<app-import-coupons #form *ngIf="apiEndPoint === 'import_coupons'"></app-import-coupons>
						<app-upload-images #form *ngIf="apiEndPoint === 'upload_images'"></app-upload-images>
						<app-import-product-categories (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'import_product_categories'"></app-import-product-categories>
						<app-form-bigquery-jobs #form *ngIf="apiEndPoint === 'bigquery_jobs'"></app-form-bigquery-jobs>
						<app-form-reserved-ids #form *ngIf="apiEndPoint === 'reserved_ids'"></app-form-reserved-ids>
						<app-form-custom-entities (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'custom_entities'"></app-form-custom-entities>
						<app-form-partners (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'partner'"></app-form-partners>
						<app-form-api-keys (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'api_key'"></app-form-api-keys>
						<app-form-reward-taxonomies (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'reward_taxonomies'"></app-form-reward-taxonomies>
						<app-form-contact-policies (formValidity)="handleFormStatus($event)" #form *ngIf="apiEndPoint === 'contact_policies'"></app-form-contact-policies>
					</div>

					<div class="col-md-12" *ngIf="mode === 'show'">
						<app-show-segment-categories *ngIf="apiEndPoint === 'segment_categories'"></app-show-segment-categories>
						<app-show-loyalty-score-moment *ngIf="apiEndPoint === 'loyalty_score_moments'"></app-show-loyalty-score-moment>
						<app-show-wallets *ngIf="apiEndPoint === 'wallets'"></app-show-wallets>
						<app-show-redemptions *ngIf="apiEndPoint === 'redemptions'"></app-show-redemptions>
						<app-show-rewards-categories *ngIf="apiEndPoint === 'reward_categories'"></app-show-rewards-categories>
						<app-show-score-exchanges *ngIf="apiEndPoint === 'score_exchanges'"></app-show-score-exchanges>
						<app-show-campaign-categories *ngIf="apiEndPoint === 'campaign_categories'"></app-show-campaign-categories>
						<app-show-messages (deliveryEmitter)="handleDelivery($event)" *ngIf="apiEndPoint === 'messages'"></app-show-messages>
						<app-show-news-list *ngIf="apiEndPoint === 'news'"></app-show-news-list>
						<app-show-news-taxonomies *ngIf="apiEndPoint === 'news_taxonomies'"></app-show-news-taxonomies>
						<app-show-news-taxonomy-terms *ngIf="apiEndPoint === 'news_taxonomy_terms'"></app-show-news-taxonomy-terms>
						<app-show-feature-internals *ngIf="apiEndPoint === 'feature_internals'"></app-show-feature-internals>
						<app-show-products-feature-taxonomies *ngIf="apiEndPoint === 'feature_taxonomies'"></app-show-products-feature-taxonomies>
						<app-show-location-taxonomies *ngIf="apiEndPoint === 'location_taxonomies'"></app-show-location-taxonomies>
						<app-show-products *ngIf="apiEndPoint === 'products'"></app-show-products>
						<app-show-recipes *ngIf="apiEndPoint === 'recipes'"></app-show-recipes>
						<app-show-locations *ngIf="apiEndPoint === 'locations'"></app-show-locations>
						<app-show-promotional-discounts *ngIf="apiEndPoint === 'promotional_discounts'"></app-show-promotional-discounts>
						<app-show-promotions (updateEmitter)="handleDelivery($event, true)" *ngIf="apiEndPoint === 'promotions'"></app-show-promotions>
						<app-show-credit-transactions *ngIf="apiEndPoint === 'credit_transactions'"></app-show-credit-transactions>
						<app-show-campaigns (campaignStatus)="handleCampaignStatus($event)" *ngIf="apiEndPoint === 'campaigns'"></app-show-campaigns>
						<app-show-campaigns-history *ngIf="apiEndPoint === 'campaigns_histories'"></app-show-campaigns-history>
						<app-show-users *ngIf="apiEndPoint === 'user'"></app-show-users>
						<app-show-versions *ngIf="apiEndPoint === 'versions'"></app-show-versions>
						<app-show-campaigns-content *ngIf="apiEndPoint === 'campaigns_histories_content'"></app-show-campaigns-content>
						<app-show-campaigns-content *ngIf="apiEndPoint === 'campaigns_content'"></app-show-campaigns-content>
						<app-show-logs *ngIf="apiEndPoint === 'logs'"></app-show-logs>
						<app-show-streaming-api *ngIf="apiEndPoint === 'streaming_api'"></app-show-streaming-api>
						<app-audience-selector *ngIf="apiEndPoint === 'audience_selector'"></app-audience-selector>
						<app-show-massive-actions *ngIf="apiEndPoint === 'massive_actions'"></app-show-massive-actions>
            <app-show-partners (updateEmitter)="handleDelivery($event)" *ngIf="apiEndPoint === 'partner'"></app-show-partners>
						<app-show-api-key (updateEmitter)="handleDelivery($event)" *ngIf="apiEndPoint === 'api_key'"></app-show-api-key>
						<app-show-reward-taxonomies (updateEmitter)="handleDelivery($event)" *ngIf="apiEndPoint === 'reward_taxonomies'"></app-show-reward-taxonomies>
					</div>
				</div>
			</div>

			<div class="modal-footer background-white align-content-center">
				<div class="pull-right d-flex align-items-center">
					<span>
						<i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          </span>
					<button *ngIf="mode !== 'show'"
									class="pull-right btn btn-info"
									(click)="sendData()"
									[translate]="isExecuteButton ? 'common.buttons.execute' : 'resources.products.buttons.send'"
									[disabled]="loading === true || !isValid">
          </button>
					<button *ngIf="!updateRedirect &&
								   mode === 'show' &&
								   apiEndPoint !== 'campaigns_histories' &&
								   apiEndPoint !== 'wallets' &&
								   apiEndPoint !== 'redemptions' &&
									 apiEndPoint !== 'scores' &&
									 apiEndPoint !== 'versions' &&
									 apiEndPoint !== 'logs' &&
									 apiEndPoint !== 'streaming_api' &&
									 apiEndPoint !== 'audience_selector' &&
									 apiEndPoint !== 'massive_actions' &&
									 apiEndPoint !== 'campaigns_histories_content'"
									 class="pull-right btn btn-info mrg-right-12"
									 [routerLink]="['', { outlets: { modal: ['update', apiEndPoint, id] } }]"
									 [hidden]="roleSlug === 'customer_service' || roleSlug === 'store_manager'"
									 [disabled]="campaignStatus === 'checking'">
						<span>{{'components.show.buttons.edit' | translate }}</span>
					</button>
					<button *ngIf="updateRedirect && mode === 'show'"
							class="pull-right btn btn-info mrg-right-20"
							(click)="handleUpdateRedirect()"
							[hidden]="roleSlug === 'customer_service' || roleSlug === 'store_manager'">
						<span>{{'components.show.buttons.edit' | translate }}</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
