<form [formGroup]="customerAttributesForm">
  <div class="card no-border">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="field-title field-badge align-items-baseline">
            <span>{{ 'resources.customer_attributes.fields.results_available_in_real_time' | translate }}</span>
            <div class="d-flex justiy-content-center align-items-center text-normal alert alert-info font-size-14 mrg-left-5">
              <span>{{ 'resources.customer_attributes.fields.results_available_in_real_time_charges' | translate }}</span>
              <em class="pi pi-question-circle icon-tooltip mrg-left-5"
                tooltipPosition="top"
                pTooltip="{{ 'resources.customer_attributes.tooltips.results_available_in_real_time' | translate }}">
              </em>
            </div>
          </label>
          <app-question [question]="getInputConfig('results_available_in_real_time')" [form]="customerAttributesForm"></app-question>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group pdd-btm-5">
          <label for="sql" class="field-title">
            <span>{{ 'resources.customer_attributes.fields.sql' | translate }}</span>
            <span *ngIf="getInputConfig('sql').required" class="text-danger">*</span>
          </label>
          <app-question [question]="getInputConfig('sql')" [form]="customerAttributesForm"></app-question>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="strict_profiling" class="field-title">
            <span>{{ 'resources.customer_attributes.fields.strict_profiling_consent' | translate }}</span>
          </label>
          <div class="checkbox-wrapper">
            <app-question [question]="getInputConfig('strict_profiling')" [form]="customerAttributesForm"></app-question>
            <span> {{ 'resources.customer_attributes.fields.strict_profiling' | translate }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label for="data_period_type" class="field-title">
          <span>{{ 'resources.customer_attributes.fields.data_period_type' | translate }}</span>
          <span *ngIf="getInputConfig('data_period_type').required" class="text-danger">*</span>
          <em class="pi pi-question-circle icon-tooltip mrg-left-5"
            tooltipPosition="right"
            pTooltip="{{ 'resources.customer_attributes.tooltips.data_period_type' | translate }}">
          </em>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="inline-block">
          <div class="alert alert-info d-flex align-items-center">
            <em class="fa-regular fa-exclamation-circle mrg-right-10 font-size-16"></em>
            <span class="font-size-14"> {{ 'resources.customer_attributes.messages.data_period_type' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row pdd-horizon-15 d-flex align-items-baseline radio-options radio-button-input mrg-btm-10">
      <ng-container *ngFor="let opt of dataPeriodTypeInput.options as dataPeriodTypeOptions; let i = index">
        <ng-container *ngIf="i !== dataPeriodTypeOptions.length - 1">
          <ng-container *ngTemplateOutlet="radioInput; context: { input: dataPeriodTypeInput, option: opt, i: i }"></ng-container>
        </ng-container>
        <ng-container *ngIf="i === dataPeriodTypeOptions.length - 1">
          <div class="d-flex align-items-baseline">
            <ng-container *ngTemplateOutlet="radioInput; context: { input: dataPeriodTypeInput, option: opt, i: i }"></ng-container>
            <div class="d-flex">
              <div class="form-group-radio-input mrg-right-5 input-size textbox-height-36">
                <app-question class="question-hint" [ngClass]="{'radio-required-input-field': isRadioInputRequiredField('data_period_type', 'custom', 'relative_value')}" [question]="getInputConfig('relative_value')" [form]="customerAttributesForm"></app-question>
                <div class="w-100 question-hint-info">
                  <span class="hint">{{ 'resources.customer_attributes.hints.minimum_value_must_be_1' | translate }}</span>
                </div>
              </div>
              <div class="form-group-radio-input d-flex align-items-baseline">
                <app-question class="flex-grow-1 input-size"
                  [ngClass]="{'multiselect-valid': !isMultiselectRequired('relative_custom_period'), 'radio-required-select-field': isRadioInputRequiredField('data_period_type', 'custom', 'relative_custom_period')}"
                  [question]="getInputConfig('relative_custom_period')"
                  [form]="customerAttributesForm"></app-question>
                <span class="mrg-left-5" [translate]="'resources.customer_attributes.fields.ago'"></span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="card no-border">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group-radio-input">
          <label for="execution_type" class="field-title">
            <span>{{ 'resources.customer_attributes.fields.execution_type' | translate }}</span>
            <span *ngIf="getInputConfig('execution_type').required" class="required-text-bold">*</span>
          </label>
          <app-question [question]="getInputConfig('execution_type')" [form]="customerAttributesForm"></app-question>
        </div>
      </div>
    </div>

    <ng-container *ngIf="hasFormKeyWithValue('execution_type', 'scheduled')">
      <div class="row mrg-btm-20">
        <div class="col-md-6">
          <div class="form-group">
            <label for="start_date" class="field-title">
              <span>{{ 'resources.customer_attributes.fields.start_date' | translate }}</span>
              <span *ngIf="getInputConfig('start_date').required" class="text-danger">*</span>
            </label>
            <app-question class="question-hint" [question]="getInputConfig('start_date')" [form]="customerAttributesForm"></app-question>
            <div class="question-hint-info">
              <span *ngIf="customerAttributesForm.get('start_date')?.errors?.invalidDate as invalidDate" class="error-message">
                <em class="fa-solid fa-triangle-exclamation"></em>
                {{ invalidDate?.message | translate }}
              </span>
              <span class="hint">{{ 'resources.customer_attributes.hints.start_date' | translate }}</span>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="start_time" class="field-title">
              <span>{{ 'resources.customer_attributes.fields.start_time' | translate }}</span>
              <span *ngIf="getInputConfig('start_time').required" class="text-danger">*</span>
            </label>
            <app-question [question]="getInputConfig('start_time')" [form]="customerAttributesForm"></app-question>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label for="recurrence" class="field-title">
            <span>{{ 'resources.customer_attributes.fields.recurrence' | translate }}</span>
            <span *ngIf="getInputConfig('recurrence').required" class="required-text-bold">*</span>
          </label>
        </div>
      </div>

      <div class="row pdd-horizon-15 d-flex align-items-baseline radio-options radio-button-input responsive-recurrence-radio-button-input">
        <ng-container *ngFor="let opt of recurrenceInput.options as recurrenceInputOptions let i = index">
          <ng-container *ngIf="opt.id === 'daily' || opt.id === 'monthly'; else customRecurrence">
            <ng-container *ngTemplateOutlet="radioInput; context: { input: recurrenceInput, option: opt, i: i }"></ng-container>
          </ng-container>
          <ng-template #customRecurrence>
            <ng-container *ngIf="opt.id === 'specific_months'">
              <div class="d-flex align-items-baseline mrg-right-15">
                <ng-container *ngTemplateOutlet="radioInput; context: { input: recurrenceInput, option: opt, i: i }"></ng-container>
                <div class="form-group-radio-input d-flex align-items-baseline multiselect-size">
                  <app-question class="multiselect-size"
                    [ngClass]="{'multiselect-valid': !isMultiselectRequired('execution_months'), 'radio-required-select-field': isRadioInputRequiredField('recurrence', 'specific_months', 'execution_months')}"
                    [question]="getInputConfig('execution_months')"
                    [form]="customerAttributesForm"></app-question>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="opt.id === 'hour_interval'">
              <div class="d-flex align-items-baseline">
                <ng-container *ngTemplateOutlet="radioInput; context: { input: recurrenceInput, option: opt, i: i }"></ng-container>
                <div class="form-group-radio-input multiselect-size textbox-height-36">
                  <app-question class="question-hint" [ngClass]="{'radio-required-input-field': isRadioInputRequiredField('recurrence', 'hour_interval', 'execution_hour_interval')}" [question]="getInputConfig('execution_hour_interval')" [form]="customerAttributesForm"></app-question>
                  <div class="w-100 question-hint-info">
                    <span class="hint">{{ 'resources.customer_attributes.hints.minimum_value_must_be_1' | translate }}</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <ng-template #radioInput let-input="input" let-option="option" let-i="i">
    <div class="form-group-radio-input">
      <div class="radio" [ngClass]="input.cssClasses">
        <input type="radio"
          [value]="option.id"
          [name]="input.key"
          [id]="input.key + i"
          [attr.data-cy]="'input_' + (input.key | camelCase) + i"
          [formControlName]="input.key"
          [attr.disabled]="input.disabled || null"
        >
        <label [ngClass]="{'disabled': input.disabled}" [attr.data-cy]="'label_' + (input.key | camelCase) + i" [attr.for]="input.key + i">{{option.name}}</label>
      </div>
    </div>
  </ng-template>
</form>
