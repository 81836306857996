import { Component, OnInit, ViewChildren, OnDestroy } from '@angular/core';
import { VersionsService } from '../versions.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import 'brace';
import 'brace/mode/json';
import 'brace/theme/monokai';

@Component({
  selector: 'app-show-versions',
  templateUrl: './show-versions.component.html',
  styleUrls: ['./show-versions.component.css'],
  providers: [ VersionsService ]
})
export class ShowVersionsComponent implements OnInit, OnDestroy {

  public id: number;
  public apiEndPoint: string;
  public element;
  public selectedTab = 'params';
  public subs$: Subscription[];

  @ViewChildren('editor') editor;

  constructor(private translate: TranslateService,
              private route: ActivatedRoute,
              private confirmationService: ConfirmationService,
              private versionsService: VersionsService ) {
    this.subs$ = [];
  }

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getParams() {
    const route$ = this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'versions';
        this.getVersionsById();
      }
    });
    this.subs$.push(route$);
  }

  getVersionsById() {
    const versions$ = this.versionsService.getVersionById(this.id).subscribe(
      resp => {
        versions$.unsubscribe();
        this.element = resp;
        this.element.params = JSON.stringify(resp.params, null, '\t');
        this.element.object_changes = JSON.stringify(resp.object_changes, null, '\t');
      },
      error => {
        this.confirmationService.displayErrorAlert('Error', error.error.error);
      }
    );
    this.subs$.push(versions$);
  }

  paramsLabel () {
    let label;
    switch (this.element.event) {
      case 'create':
        label = this.translate.instant('resources.versions.event.create');
        break;
      case 'update':
        label = this.translate.instant('resources.versions.event.update');
        break;
      case 'destroy':
        label = this.translate.instant('resources.versions.event.destroy');
        break;
      default:
        label = this.translate.instant('resources.versions.event.default');
    }
    return label;
  }

  changeTab (id) {
    this.selectedTab = id;
  }
}
