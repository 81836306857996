import { Component, OnInit } from '@angular/core';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { SynchrosService } from '../synchros.service';
import { ActivatedRoute } from '@angular/router';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { SynchroDetail, SynchroFile, SynchroFiltersDetail, SynchroResourceDetail } from '../../../../shared/models/synchros/synchro-detail';

@Component({
  selector: 'app-show-synchros',
  templateUrl: './show-synchros.component.html',
  providers: [ SynchrosService ]
})

export class ShowSynchrosComponent implements OnInit {

  filters: QuestionBase<unknown>[];
  id: number = this.route.snapshot.params.id;
  emptyValue = '-';
  resource: SynchroResourceDetail = {
    fileList: []
  };

  filterData = {
    multiselect: {
      values: this.synchrosService.getStatusOptions()
    },
    resource: null,
    filename: null
  };

  loadingData = false;
  loadingFilteredData = false;
  sorting = {fields: ['id'], reverse: false};

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private synchrosService: SynchrosService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.setFilterCfg();
    this.getSynchroById();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFilterHandler(filterFormValue: SynchroFiltersDetail): void {
    this.filterData.resource = filterFormValue;
    this.loadingFilteredData = true;
    this.handleGetSynchroData(true);
  }

  doSort(sortByValue: string): void {
    this.sorting.reverse = !this.sorting.reverse;
    const orderedList = this.resource.fileList.sort(( file1, file2 ) => {
      if ( file1[sortByValue] < file2[sortByValue] ) { return this.sorting.reverse ?  1 : -1; }
      if ( file1[sortByValue] > file2[sortByValue] ) { return this.sorting.reverse ? -1 : 1; }
      return 0;
    });
    this.resource.fileList = orderedList;
  }

  private getSynchroById(): void {
    this.loadingData = true;
    this.handleGetSynchroData();
  }

  private filterFile(file: SynchroFile): boolean {
    let bool = true;
    if (!!this.filterData.resource.resource) {
      bool = bool && this.filterData.resource.resource.indexOf(file.resource_name) >= 0;
    }
    if (!!this.filterData.resource.filename) {
      bool = bool && file.file_info.indexOf(this.filterData.resource.filename) >= 0;
    }
    if (!!this.filterData.resource['status']) {
      switch (this.filterData.resource['status']) {
        case 'completed': bool = bool && file.status === 'completed';
                          break;
        case 'completed_with_errors': bool = bool && file.status === 'completed_with_errors';
                          break;
        case 'error': bool = bool && file.status === 'invalid';
                          break;
        case 'pending': bool = bool && file.status === 'pending';
                          break;
      }
    }
    return bool;
  }

  private setFilterCfg(): void {
    this.filters = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'resource',
        label: 'resources.synchros.filters.resources',
        type: 'text',
        options: this.synchrosService.getResourcesList()
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'filename',
        label: 'resources.synchros.filters.file',
        type: 'text'
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'status',
        label: 'resources.synchros.filters.status',
        type: 'text',
        options: this.synchrosService.getStatusOptions()
      })
    ];
  }

  private handleGetSynchroData(filterData: boolean = false): void {
    this.synchrosService.getSynchroById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: SynchroDetail) => {
        this.resource = response;
        if (filterData) {
          this.resource['synchro_files'] = response.synchro_files;
          this.resource.fileList = this.resource['synchro_files'].filter(this.filterFile.bind(this));
        } else {
          this.resource.fileList = response.synchro_files;
        }
        this.setFinishLoadingData();
      },
      error: (errorData: HttpErrorResponse) => {
        this.confirmationService.displayHttpErrorAlert(errorData);
        this.setFinishLoadingData();
      }
    });
  }

  private setFinishLoadingData(): void {
    this.loadingData = false;
    this.loadingFilteredData = false;
  }

}
