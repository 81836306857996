import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../profiles/profile.service';
import { FeatureFlagsService } from '../shared/services/feature-flags.service';
import { HighchartsConfigService } from '../shared/services/highcharts-config.service';

@Component({
  selector: 'app-layout',
  templateUrl: './common-layout.component.html'
})

export class CommonLayoutComponent implements OnInit {

  public headerThemes: any;
  public changeHeader: any;
  public headerSelected: any;
  public sidenavSelected: any;
  public activeSync: boolean;
  public periodPending: boolean;
  public systemWarning: string;
  public app = {
    layout: {
      sidePanelOpen: false,
      isMenuOpened: true,
      isMenuCollapsed: false,
      themeConfigOpen: false,
      rtlActived: false
    }
  };

  constructor(
    private profileService: ProfileService,
    private chartsService: HighchartsConfigService,
    public flagsService: FeatureFlagsService
  ) { }

  ngOnInit() {
    this.activeSync = this.profileService.getStoredUser().company.synchro_active;
    this.periodPending = this.profileService.getStoredUser().company.period_pending;
    this.systemWarning = this.profileService.getStoredUser().company.system_warning;
  }

  changeSidenav(sidenavTheme) {
    this.sidenavSelected = sidenavTheme;
  }

  handleHeaderEvent(event) {
    this.chartsService.resizeEvent.next(event);
    switch (event) {
      case 'side-panel-toggle':
        this.app.layout.sidePanelOpen = !this.app.layout.sidePanelOpen;
        break;
        case 'side-nav-toggle':
        this.app.layout.isMenuCollapsed = !this.app.layout.isMenuCollapsed;
        break;
      default:
        break;
    }
  }
}
