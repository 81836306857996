export const LOCATIONS_FORM_FIELDS_TEMPLATE: {key: string, hasLabel?: boolean, translateKey?: string}[] = [
  { key: 'id' },             
  { key: 'code' },
  { key: 'name' },
  { key: 'address' },
  { key: 'state' },
  { key: 'city' },
  { key: 'postal_code' },
  { key: 'country' },
  { key: 'longitude' },
  { key: 'latitude' },
  { key: 'schedule' },
  { key: 'status' },
  { key: 'gift_card_available', hasLabel: false },
  { key: 'location_taxonomy_terms' },
  { key: 'score_exchange_id', translateKey: 'score_exchanges' },
  { key: 'phone' },
  { key: 'email' },
  { key: 'goals' }
]