import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Option } from '../../../../../shared/models/common/option';
import { DictionaryService } from '../../../../../shared/services/dictionary.service';
import { FeatureFlagsService } from '../../../../../shared/services/feature-flags.service';

@Injectable()
export class CustomerAttributesOptionsService {

  flags = this.featureFlags.flags;

  constructor(
    private translate: TranslateService,
    private dictionaryService: DictionaryService,
    private featureFlags: FeatureFlagsService
  ) {}

  getAttributeTypeOptions(): Option[] {
    const options = [
      { id: 'property', name: this.translate.instant('resources.customer_attributes.fields.property').toUpperCase() },
      { id: 'calculation', name: this.translate.instant('resources.customer_attributes.fields.calculation').toUpperCase() }
    ];

    return this.flags.showPropertyAttributeType ? options : options.filter(option => option.id !== 'property');
  }

  getDataTypeOptions(): Option[] {
    return [
      { id: 'float', name: this.translate.instant('resources.customer_attributes.fields.float') },
      { id: 'integer', name: this.translate.instant('resources.customer_attributes.fields.integer') },
      { id: 'string', name: this.translate.instant('resources.customer_attributes.fields.string') },
      { id: 'boolean', name: this.translate.instant('resources.customer_attributes.fields.boolean') },
      { id: 'datetime', name: this.translate.instant('resources.customer_attributes.fields.date_time') }
    ];
  }

  getDataPeriodTypeOptions(): Option[] {
    return [
      { id: 'current_week', name: this.translate.instant('resources.customer_attributes.fields.current_week') },
      { id: 'current_month', name: this.translate.instant('resources.customer_attributes.fields.current_month') },
      { id: 'current_quarter', name: this.translate.instant('resources.customer_attributes.fields.current_quarter') },
      { id: 'current_year', name: this.translate.instant('resources.customer_attributes.fields.current_year') },
      { id: 'last_month', name: this.translate.instant('resources.customer_attributes.fields.last_month') },
      { id: 'custom', name: this.translate.instant('resources.customer_attributes.fields.custom') }
    ];
  }

  getRelativeCustomOptions(): Option[] {
    return [
      { id: 'relative_days', name: this.translate.instant('resources.customer_attributes.fields.relative_days') },
      { id: 'relative_months', name: this.translate.instant('resources.customer_attributes.fields.relative_months') }
    ];
  }

  getExecutionTypeOptions(): Option[] {
    return [
      { id: 'manual', name: this.translate.instant('resources.customer_attributes.fields.manual') },
      { id: 'scheduled', name: this.translate.instant('resources.customer_attributes.fields.scheduled') }
    ];
  }

  getRecurrenceOptions(): Option[] {
    return [
      { id: 'daily', name: this.translate.instant('resources.customer_attributes.fields.daily') },
      { id: 'monthly', name: this.translate.instant('resources.customer_attributes.fields.monthly') },
      { id: 'specific_months', name: this.translate.instant('resources.customer_attributes.fields.specific_months') },
      { id: 'hour_interval', name: this.translate.instant('resources.customer_attributes.fields.hour_interval') }
    ];
  }

  getMonthsOptions(): Option[] {
    const options = this.dictionaryService.getValuesByKey('months');
    const months = options.map(option => {
      return {
        id: option.key,
        name: this.translate.instant(option.name)
      };
    });
    return months;
  }
}
