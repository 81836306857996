import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerJourney } from '../../models/customer-journeys/customer-journey';
import { CustomerJourneysService } from '../../../resources/customer-journeys/customer-journeys.service';
import { CouponsService } from '../../../resources/coupons/coupons.service';
import { JourneySummaryBlockComponent } from './journey-summary-block/journey-summary-block.component';
import { JourneyCampaignsBlockComponent } from './journey-campaigns-block/journey-campaigns-block.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalStatusService } from '../../services/modal-status.service';
import { JourneyDefinitionBlockComponent } from './journey-definition-block/journey-definition-block.component';
import { JourneyContentBlockComponent } from './journey-content-block/journey-content-block.component';
import { UntypedFormGroup } from '@angular/forms';
import { EmailTemplateViewComponent } from '../../../resources/content-designer/campaign-templates/email-template-view/email-template-view.component';
import { EmailTemplateEditComponent } from '../../../resources/content-designer/campaign-templates/email-template-edit/email-template-edit.component';
import { PushTemplateEditComponent } from '../../../resources/content-designer/campaign-templates/push-template-edit-component/push-template-edit.component';
import { SmsTemplateEditComponent } from '../../../resources/content-designer/campaign-templates/sms-template-edit-component/sms-template-edit.component';
import { CustomerJourneyStep } from '../../models/customer-journeys/customer-journey-step';
import { MulticouponJourneyService } from '../../services/multicoupon-journey.service';
import { ConfirmationService } from '../../services/confirmation.service';
import { PotentialJourneyService } from '../../services/potential-journey.service';
import { DateService } from '../../services/date.service';
import { RecuperationJourneyService } from '../../services/recuperation-journey.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-create-update-customer-journeys-dynamic-form',
  templateUrl: './create-update-customer-journeys-dynamic-form.component.html',
  styleUrls: ['./create-update-customer-journeys-dynamic-form.component.css'],
})

export class CreateUpdateCustomerJourneysDynamicFormComponent implements OnInit {

  @ViewChild('confirmationModal') confirmationModal: ElementRef;
  @ViewChild('emailTemplateView') emailTemplateView: EmailTemplateViewComponent;
  @ViewChild('emailTemplateEdit') emailTemplateEdit: EmailTemplateEditComponent;
  @ViewChild('pushTemplateEdit')  pushTemplateEdit: PushTemplateEditComponent;
  @ViewChild('smsTemplateEdit')   smsTemplateEdit: SmsTemplateEditComponent;
  @ViewChild('definition')        definition: JourneyDefinitionBlockComponent;
  @ViewChild('campaigns')         campaigns: JourneyCampaignsBlockComponent;
  @ViewChild('content')           content: JourneyContentBlockComponent;
  @ViewChild('summary')           summary: JourneySummaryBlockComponent;

  public journey: CustomerJourney;
  public definitionStatus: string;
  public campaignsStatus: string;
  public contentStatus: string;
  public summaryStatus: string;
  public stepsToDeliver: {};
  public dataLabel: string;
  public deliveringJourney = false;
  public journeyHasChanges = true;
  public journeyValidToDeliver = false;
  public journeyForm: UntypedFormGroup;
  public selectedStep: CustomerJourneyStep;
  public selectedStepTemplate: any;
  public loading = false;

  constructor(
    private router:               Router,
    private route:                ActivatedRoute,
    private journeyService:       CustomerJourneysService,
    private couponsService:       CouponsService,
    private translate:            TranslateService,
    private modalStatusService:   ModalStatusService,
    private changeDetector:       ChangeDetectorRef,
    private potentialService:     PotentialJourneyService,
    private multiCouponService:   MulticouponJourneyService,
    private confirmationService:  ConfirmationService,
    private dateService:          DateService,
    private recuperationService:  RecuperationJourneyService
  ) { }

  public ngOnInit() {
    this.getParams();
    this.journeyForm = new UntypedFormGroup({});
  }

  public handleCloseModal() {
    if ( this.journeyHasChanges ) {
      this.showExitConfimationMsg();
    } else {
      this.closeModal();
    }
  }

  public definitionEmitterHandler(event) {
    this.definitionStatus = event;
    if ( this.definitionStatus === 'unsaved_changes' ) {
      this.journeyHasChanges = true;
    }
    this.allStepsLoaded();
  }

  public campaignsEmitterHandler(event) {
    this.campaignsStatus = event;
    if ( this.campaignsStatus === 'autosave' ) {
      this.save({showSweetAlert: false});
    }
  }

  public contentEmitterHandler(event) {
    this.contentStatus = event;
    if ( this.contentStatus === 'unsaved_changes' ) {
      this.journeyHasChanges = true;
    }
    this.allStepsLoaded();
  }

  public summaryEmitterHandler(event) {
    this.summaryStatus = event;
    if ( this.summaryStatus === 'unsaved_changes' ) {
      this.journeyHasChanges = true;
    }
    this.allStepsLoaded();
  }

  public triggerJourneyDelivery() {
    this.loading = true;
    this.deliveringJourney = true;
    this.summary.showJourneyIsOnDeliver();
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('history_id') && params.history_id > 0) {
        this.sendJourneyHistory(params);
      } else {
        this.sendJourney(params);
      }
    });
  }

  // Options: {showSweetAlert: false}
  public save(options) {

    let showSweetAlert = true;
    if ( options && options.hasOwnProperty('showSweetAlert')) { showSweetAlert = options.showSweetAlert; }

    this.loading = true;
    this.journeyHasChanges = false;
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('history_id') && params.history_id > 0) {
        this.journeyService.saveJourneyHistory(params.id, params.history_id, this.journey, this.journeyForm).subscribe(
          savedJourneyHistory => {
            this.handleResourceSaved(savedJourneyHistory);
            if ( showSweetAlert ) { this.savedWithSuccessMsg(); }
          },
          error => {
            this.handleGlobalErrors(error);
            this.handlePatchErrors(error);
          }
        );
      } else {
        this.journeyService.saveJourney(this.journey, this.journeyForm).subscribe(
          savedJourney => {
            this.handleResourceSaved(savedJourney);
            if ( showSweetAlert ) { this.savedWithSuccessMsg(); }
          },
          error => {
            this.handleGlobalErrors(error);
            this.handlePatchErrors(error);
          }
        );
      }
    });
  }

  public formEmitterHandler(form) {
    this.journeyForm = form;
  }

  public closeConfirmationModal() {
    this.confirmationModal.nativeElement.click();
  }

  public prepareDelivery(event) {
    this.loading = true;
    this.journeyHasChanges = false;
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('history_id') && params.history_id > 0) {
        this.journeyService.saveJourneyHistory(params.id, params.history_id, this.journey, this.journeyForm).subscribe(
          savedJourneyHistory => {
            this.handleResourceSaved(savedJourneyHistory);
            this.displayStepsDeliveryInfo();
          },
          error => {
            this.handleGlobalErrors(error);
            this.handlePatchErrors(error);
          }
        );
      } else {
        this.journeyService.saveJourney(this.journey, this.journeyForm).subscribe(
          savedJourney => {
            this.handleResourceSaved(savedJourney);
            this.displayStepsDeliveryInfo();
          },
          error => {
            this.handleGlobalErrors(error);
            this.handlePatchErrors(error);
          }
        );
      }
    });
  }

  public journeyNameChanged(event) {
    this.journeyHasChanges = true;
    this.journey.nameHasChanged = true;
    this.journey.name = event.target.value;
  }

  public handleTemplateSelectedEmitter(step: CustomerJourneyStep) {
    this.selectedStep = step;
    this.selectedStepTemplate = step.configuration.template;
  }

  public handleTemplateEmitter(event: { mode: string, step: CustomerJourneyStep }) {

    this.handleTemplateSelectedEmitter(event.step);

    let templateCmp = null;
    let via = this.journey.configuration.via[0].id;

    if( via.hasOwnProperty('id') && via.id ) { via = via.id };

    if (via === 'email') {
      templateCmp = event.mode === 'readonly' ? this.emailTemplateView : this.emailTemplateEdit;
      templateCmp.openAndViewTemplate( event.step.configuration.template );
    } else if (via === 'sms') {
      const sms = event.step.configuration.template ? event.step.configuration.template.message : '';
      templateCmp = this.smsTemplateEdit;
      templateCmp.openAndViewTemplate(event.mode, sms);
    } else if (via === 'push') {
      const template = event.step.configuration.template ? event.step.configuration.template : {};
      template.schema = template.hasOwnProperty('schema') ? template.schema : {};
      templateCmp = this.pushTemplateEdit;
      templateCmp.openAndViewTemplate(event.mode, event.step.configuration.template);
    }
  }

  public handleEmailSaved(emailTemplate: any) {
    const template = {
      subject: this.selectedStep.name,
      message: emailTemplate.html,
      schema: JSON.parse(emailTemplate.json),
      values: {}
    };
    this.selectedStep.configuration.template = template;
  }

  public handlePushTemplateSaved(pushTemplate: any) {
    this.selectedStep.configuration.template = pushTemplate;
  }

  public handleSmsTemplateSaved(smsTemplate: any) {
    this.selectedStep.configuration.template = smsTemplate;
  }

  public getErrors(): { field: string, message: string }[] {
    return this.journeyForm.errors.map( error => {
      const field = error.field_name.indexOf('translation missing') >= 0 ? error.field : error.field_name;
      const message = error.message;
      return { field: field, message: message };
    });
  }

  public removeError(error: {field: string, message: string}) {
    const errorsArray = this.journeyForm.errors.filter( _error => _error.field !== error.field );
    this.journeyForm.setErrors(errorsArray);
  }

  public paintErrorsInForm(form: UntypedFormGroup, errorsArray: { field: string, field_name?: string, message: string }[]) {
    const errorsWithoutField = [];
    errorsArray.forEach(apiError => errorsWithoutField.push(apiError));
    if ( errorsWithoutField.length ) { form.setErrors(errorsWithoutField); }
  }

  public getName(): string {
    if (this.journey.type === 'potential') {
      return this.potentialService.getJourneyName(this.journey);
    } else if (this.journey.type === 'recuperation') {
      return this.recuperationService.getJourneyName(this.journey);
    } else {
      return this.journey.getName();
    }
  }

  // Private

  private displayStepsDeliveryInfo() {

    this.loading = false;
    this.confirmationModal.nativeElement.click();

    const today = new Date();
    const pendingSteps = this.journey.steps.filter(step => !(['done', 'cancelled', 'running'].indexOf(step.status) >= 0));

    this.stepsToDeliver = {};
    this.stepsToDeliver['now'] = pendingSteps.filter(step => new Date(step.scheduled_at) <= today).map( step => {
      return {
        name: this.journey.getStepName(step)
      };
    });

    this.stepsToDeliver['scheduled'] = pendingSteps.filter(step => new Date(step.scheduled_at) > today).map( step => {
      return {
        name: this.journey.getStepName(step),
        date: this.dateService.parseDateWithFormat(step.scheduled_at, 'DD/MM/YYYY HH:mm')
      };
    });
  }

  private handleGlobalErrors(errorData) {
    if ( this.journey.type === 'multicoupon' ||
         this.journey.type === 'potential' ||
         this.journey.type === 'recuperation' ) {
      if ( errorData.hasOwnProperty('error') && errorData.error) {
        const errorArray = [
          {
            field: 'Error',
            field_name: 'Error',
            message: errorData.error.error
          }
        ];
        this.paintErrorsInForm(this.journeyForm, errorArray);
      }
    }
  }

  private savedWithSuccessMsg() {
    this.confirmationService.displaySuccessAlert(
      this.translate.instant('resources.journeys.messages.operation_successful_title'),
      this.translate.instant('resources.journeys.messages.operation_successful_description'),
    ).then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.router.navigateByUrl(`/offers-personalization/customer-journeys`).catch(() => {});
        this.modalStatusService.modalStatus.emit();
      }
    }).catch(() => {});
  }

  private showExitConfimationMsg() {
    this.confirmationService.displayConfirmationAlert(
      this.translate.instant('resources.journeys.warnings.pending_changes'),
      this.translate.instant('resources.journeys.warnings.pending_changes_desc')
    ).then(data => {
      /* User clicks accept button in alert */
      if (data.hasOwnProperty('value') && data.value) {
        this.closeModal();
      }
    }).catch(() => {});
  }

  private closeModal() {
    this.router.navigateByUrl(`/offers-personalization/customer-journeys`).catch(() => {});
    this.modalStatusService.modalStatus.emit();
  }

  private allStepsLoaded() {
    const validStatusList = ['valid', 'finished_loading'];
    const blocksStatus = [this.definitionStatus, this.campaignsStatus, this.summaryStatus];
    const statusList = blocksStatus.every(sStatus => validStatusList.indexOf(sStatus) >= 0);

    if ( statusList ) { this.loading = false; }
  }

  private handleResourceSaved(savedObject) {
    this.loading = false;
    this.journeyHasChanges = false;

    if ( this.journey.type === 'multicoupon' ) { this.loadJourney(savedObject); }

    if ( ['potential', 'recuperation'].indexOf(this.journey.type) >= 0 ) {
      this.journey.steps.forEach( (step, index) => {
        step.scheduled_at = savedObject.steps[index].scheduled_at;
      });
    } else {
      this.loadJourney(savedObject);
      this.definition.requestRemoteOptions();
      this.campaigns.requestRemoteOptions();
    }
  }

  private handlePatchErrors(data) {
    this.loading = false;
    this.journeyValidToDeliver = false;
    if ( data.hasOwnProperty('error') && data.error.hasOwnProperty('errors') && data.error.errors[0] ) {
      this.definition.showErrors(data);
    }
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('history_id') && params.history_id > 0) {
        this.getJourneyHistory(params.id, params.history_id);
      } else {
        this.getJourney(params.id);
      }
    });
  }

  private getJourney(id) {
    this.journeyService.getCustomerJourneyById(id).subscribe(
      journeyApiData => {
        this.loadJourney(journeyApiData);
      }
    );
  }

  private getJourneyHistory(id, historyId) {
    this.journeyService.getCustomerJourneyHistory(id, historyId).subscribe(
      journeyHistoryApiData => {
        this.loadJourney(journeyHistoryApiData);
        this.journeyService.cancelJourneyHistorySchedule(id, historyId).subscribe(
          () => console.warn(`Schedule cancelled`),
          (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
        );
      }
    );
  }

  private loadJourney(journeyData) {
    this.journey = this.journeyService.initByType(journeyData);

    if ( this.journey._original ) {
      this.dataLabel = this.translate.instant('resources.journeys.fields.next');
    } else {
      this.dataLabel = this.dateService.parseDateWithFormat(this.journey.scheduled_at, 'DD/MM/YYYY');
    }

    this.getStepValidations();

    if ( this.definitionStatus === 'valid' ) {
      this.changeDetector.detectChanges();
    }

    this.journeyValidToDeliver = this.journey.validToDeliver();

    if ( journeyData.type !== 'multicoupon' &&
         journeyData.type !== 'potential' &&
         journeyData.type !== 'recuperation' ) {
      this.parseConfig(journeyData);
    } else {
      this.loading = false;
      if ( journeyData.type === 'multicoupon' ) {
        this.multiCouponService.refreshValues(this.journey, journeyData);
      }
    }
  }

  private getStepValidations() {
    this.definitionStatus = this.journey.definitionValid() ? 'valid' : null;
    this.campaignsStatus = this.journey.campaignsValid() ? 'valid' : null;
    this.summaryStatus = this.journey.summaryValid() ? 'valid' : null;
  }

  // Necessary to properly select multiselect (tuple: {id: int, name: string})
  private parseConfig(config) {
    // This chunk is to properly select coupons field (need a get XHR call)
    if ( config['configuration'].hasOwnProperty('coupon_id') &&
         config['configuration'].coupon_id.length > 0 ) {
      this.couponsService.getCouponById(config['configuration'].coupon_id[0]).subscribe(
        coupon => {
          this.journey.configuration.coupon = [{id: coupon['id'], name: coupon['name']}];
        }
      );
    }
  }

  private sendJourney(params) {
    this.journeyService.saveJourney(this.journey, this.journeyForm).subscribe(
      () => {
        this.loading = false;
        this.journeyService.deliverJourney(params.id).subscribe(
          () => {
            this.confirmationService.displaySuccessAlert(
              this.journey.name,
              this.translate.instant('resources.journeys.warnings.successfully_scheduled')
            ).catch(() => {});
            this.handleCloseModal();
          },
          error => {
            const errors = `${error.error.errors.map( e => e.message ).join('. ')}.`;
            this.confirmationService.displayErrorAlert(
              this.translate.instant('resources.journeys.status.error'),
              errors
            );
          }
        );
      },
      error => {
        this.loading = false;
        this.confirmationService.displayErrorAlert(
          this.translate.instant('resources.journeys.status.error'),
          `${this.translate.instant('resources.journeys.warnings.saving_error')}: ${error}`
        );
      }
    );
  }

  private sendJourneyHistory(params) {
    this.journeyService.saveJourneyHistory(params.id, params.history_id, this.journey, this.journeyForm).subscribe(
      () => {
        this.loading = false;
        this.journeyService.deliverJourneyHistory(params.id, params.history_id).subscribe(
          () => {
            this.confirmationService.displaySuccessAlert(
              this.journey.name,
              this.translate.instant('resources.journeys.warnings.successfully_scheduled')
            ).catch(() => {});
            this.handleCloseModal();
          },
          (errData: HttpErrorResponse) => {
            if(errData.error.errors && errData.error.errors.length > 0 ) {
              const errors = `${errData.error.errors.map( e => e.message ).join('. ')}.`;
              this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errors);
            } else {
              this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errData.error.error);
            }
          }
        );
      },
      error => {
        this.loading = false;
        this.confirmationService.displayErrorAlert(
          this.translate.instant('resources.journeys.status.error'),
          `${this.translate.instant('resources.journeys.warnings.saving_error')}: ${error}`,
        );
      }
    );
  }
}
