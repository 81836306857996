import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss']
})

export class InfoPanelComponent {
  @Input() titleKey: string;
  @Input() subtitleKey: string;
  @Input() imageUrl: string;
  @Input() learnMoreLink: string;

  @Output() alertClosed = new EventEmitter<void>();

  showAlert = true;

  closeAlert() {
    this.showAlert = false;
    this.alertClosed.emit();
  }
}
