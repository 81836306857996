import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../shared/components/multiselect/multiselect';
import { Observable } from 'rxjs';
import { LaunchDarklyService } from '../../shared/services/launch-darkly/launchdarkly.service';
import { isNullOrUndefined } from 'util';

/*
* This segment class service is used for nested segment condition.
* - It must filter from list the current segment id, if this is defined in initial configuration.
* - The goal is to avoid selection of the same segment that it's being editted.
*/
@Injectable()
export class V1SegmentsService extends ResourcesService implements MultiselectDataSourceable {

  public version = 'all';

  constructor( http: HttpClient,
               private launchDarklyService: LaunchDarklyService ) {
    super(http);
    this.processFeatureFlag();
  }

  public fetchMultiselect( searchValues?: string, page?: number ) {
    let requestOptions = {
      apiEndPoint: 'segments',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: { version: this.version },
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if ( searchValues ) {
      const filterObj = {
        filtering: {
          filter_logic: 'and',
          search_text: searchValues,
          version: this.version
        }
      };
      requestOptions = { ...requestOptions, ...filterObj };
    }

    if (this.version === 'all') { delete requestOptions.filtering.version; }
    return this.getData( requestOptions );
  }

  public fetchSelectedById(id: number): Observable<object> {
    return this.getSegmentById(id);
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`, element);
  }

  public getSegmentById(id: number) {
    return this.getData({ apiEndPoint: `segments/${id}` });
  }

  // private

  private processFeatureFlag() {
    const versionFlagValue = this.launchDarklyService.client.variation('segments.service.version', 'all');
    this.setFlagValue(versionFlagValue);
    this.launchDarklyService.flagChanges.subscribe((flags) => this.setFlagValue(flags['segments.service.version']));
  }

  private setFlagValue(flagValue?) {
    if(isNullOrUndefined(flagValue)){ return; }
    flagValue = flagValue.hasOwnProperty('current') ? flagValue.current : flagValue;
    this.version = flagValue;
  }
}
