import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { CustomerAttribute } from './models/customer-attribute.model';
import { CustomerAttributesOptionsService } from './services/customer-attributes-options/customer-attributes-options.service';
import { CustomerAttributesService } from './services/customer-attributes/customer-attributes.service';
import { DataTableComponent } from '../../../shared/components/data-table/data-table.component';
import { DataTableConfiguration } from '../../../shared/components/data-table/data-table-cfg';
import { getDataTableIconPropValue } from '../../../shared/utils/common.utils';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalStatusService } from '../../../shared/services/modal-status.service';
import { parseRecurrence, parseStatus } from './utils/parser.utils';
import { ProfileService } from '../../../profiles/profile.service';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-attributes',
  templateUrl: './customer-attributes.component.html',
  styleUrls: ['./customer-attributes.component.scss']
})

export class CustomerAttributesComponent implements OnInit, OnDestroy {

  currentRole = this.profileService.getStoredUserRole();
  dataTableConfig: DataTableConfiguration;
  filters: QuestionBase<unknown>[];

  get isAdminRole(): boolean {
    return this.currentRole === 'admin';
  }

  private destroy$ = new Subject<void>();

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  constructor(
    private confirmationService: ConfirmationService,
    private customerAttributesOptionsService: CustomerAttributesOptionsService,
    private customerAttributesService: CustomerAttributesService,
    private modalStatusService: ModalStatusService,
    private profileService: ProfileService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.setFilters();
    this.setDataTableConfig();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFilterHandler(filterFormValue) {
    this.dataTable.onFilter(filterFormValue);
  }

  private setDataTableConfig(): void {
    this.dataTableConfig = {
      isMock: false,
      isActive: true,
      renderRowCheckbox: false,
      createButton: {
        label: this.translate.instant('resources.customer_attributes.buttons.new_customer_attribute'),
        redirectTo: [`/customer-data-platform/customer-attributes/new`]
      },
      rows: [],
      tableActions: [],
      rowActions: [
        {
          name: this.translate.instant('common.buttons.execute'),
          icon: 'play',
          id: 'execute',
          show: () => this.isAdminRole,
          callback: (element) => this.executeCustomerAttribute(element.id)
        },
        {
          name: this.translate.instant('common.details'),
          icon: 'eye',
          id: 'details',
          customRoute: element => [`/customer-data-platform/customer-attributes/show/${element.id}`]
        },
        {
          name: this.translate.instant('common.edit'),
          icon: 'pencil',
          id: 'edit',
          show: () => this.isAdminRole,
          customRoute: element => [`/customer-data-platform/customer-attributes/edit/${element.id}`]
        },
        {
          name: this.translate.instant('common.buttons.delete'),
          icon: 'trash',
          id: 'delete',
          show: (element) => this.isAdminRole && !element.system,
          callback: (element) => this.deleteCustomerAttribute(element.id)
        }
      ],
      columns: [
        { name: this.translate.instant('common.id'), prop: 'id', sortByField: 'id'},
        { name: this.translate.instant('resources.customer_attributes.columns.name'), prop: 'name' },
        { name: this.translate.instant('resources.customer_attributes.columns.system'), prop: 'system' },
        { name: this.translate.instant('resources.customer_attributes.columns.recurrence'), prop: 'recurrence' },
        { name: this.translate.instant('resources.customer_attributes.columns.results_available_in_real_time'), prop: 'results_available_in_real_time' },
        { name: this.translate.instant('resources.customer_attributes.columns.last_execution_time'), prop: 'last_execution_time' },
        { name: this.translate.instant('resources.customer_attributes.columns.status'), prop: 'status' }
      ],
      requestData: {
        apiEndPoint: 'customer_attribute_definitions',
        pageNumber: 0,
        sorting: {
          byProp: null,
          direction: null
        },
        filtering: {
          attribute_type: 'calculation'
        },
        numberPerPage: 10
      },
      tableMapping: [
        { prop: 'id', type: 'text', apiProp: 'id' },
        { prop: 'name', type: 'text', apiProp: 'name' },
        { prop: 'system', type: 'badge', apiProp: 'system', getValue: (element) => element.system ?? false, badge: { dictionaryKey: 'customer_attribute_origin' }},
        { prop: 'recurrence', type: 'text', apiProp: 'recurrence', getValue: (element) => parseRecurrence(new CustomerAttribute(element), this.customerAttributesOptionsService) },
        { prop: 'results_available_in_real_time', type: 'icon', apiProp: 'results_available_in_real_time',
          getValue: getDataTableIconPropValue('results_available_in_real_time'),
          icon: { dictionaryKey: 'customer_attribute_results_available_in_real_time' }
        },
        { prop: 'last_execution_time', type: 'date_time', apiProp: 'last_execution_time' },
        { prop: 'status', type: 'text', apiProp: 'status', getValue: (element) => parseStatus(element.status), badge: { dictionaryKey: 'customer_attribute_status' } }
      ]
    };
  }

  private setFilters(): void {
    this.filters = [
      new TextboxQuestion({
        key: 'id',
        label: this.translate.instant('common.id'),
        cssClasses: 'form-control input-md',
        type: 'text'
      })
    ]
  }

  private executeCustomerAttribute(id: number): void {
    const confirmationTitle = this.translate.instant('resources.customer_attributes.messages.run_confirmation');
    const confirmationButton = this.translate.instant('resources.customer_attributes.buttons.run_confirmation');
    const denialButton = this.translate.instant('resources.customer_attributes.buttons.denial');
    this.confirmationService.displayConfirmationAlertWithButtons(confirmationTitle, '', confirmationButton, denialButton, 'question').then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.customerAttributesService.runCustomerAttribute(id).pipe(takeUntil(this.destroy$)).subscribe(
          () => {
            this.confirmationService.displaySuccessAlert(this.translate.instant('resources.customer_attributes.messages.run_confirmation_success'), '').catch(() => {});
            this.modalStatusService.modalStatus.emit();
          },
          (errorData: HttpErrorResponse) => this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error)
        );
      }
    }).catch(() => {});
  }

  private deleteCustomerAttribute(id: number): void {
    const confirmationTitle = this.translate.instant('resources.customer_attributes.messages.delete_confirmation');
    const confirmationButton = this.translate.instant('resources.customer_attributes.buttons.delete_confirmation');
    const denialButton = this.translate.instant('resources.customer_attributes.buttons.denial');
    this.confirmationService.displayConfirmationAlertWithButtons(confirmationTitle, '', confirmationButton, denialButton, 'question').then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.customerAttributesService.deleteCustomerAttribute(id).pipe(takeUntil(this.destroy$)).subscribe(
          () => {
            this.confirmationService.displaySuccessAlert(this.translate.instant('resources.customer_attributes.messages.delete_confirmation_success'), '').catch(() => {});
            this.modalStatusService.modalStatus.emit();
          },
          (errorData: HttpErrorResponse) => {
            this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error);
          }
        );
      }
    }).catch(() => {});
  }
}
