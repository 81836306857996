<app-loader *ngIf="loading" />

<!-- TABLE -->
<div class="row" [hidden]="showGraph">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-heading">
        <button *ngIf="contactsData.length" class="btn btn-sm btn-info no-mrg-right pull-right" (click)="showChart()">
          <span>{{'dashboards.customers.tabs.contacts.buttons.show_graph' | translate}}</span>
        </button>
      </div>
      <ng-container *ngIf="!loading">
        <ng-container *ngIf="contactsData.length; else showNoDataMessage">
          <div class="card-block no-pdd no-mrg pdd-horizon-20 pdd-btm-5">
            <div class="table-responsive">
              <table class="table table-hover table-sm table-bordered" aria-hidden="true">
                <thead>
                  <tr class="highlight-header border-highlight" >
                    <th></th>
                    <th class="text-right" *ngFor="let i of activeMonths">{{months[i].name | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let key of rows">
                    <td class="pdd-left-20 text-left text-bold">
                      <span class="text-bold text-dark" [translate]="'dashboards.customers.tabs.contacts.table.' + key"></span>
                    </td>
                    <td class="text-right" *ngFor="let month of activeMonths; let i = index;">
                      <span class="label label-default">{{contactsData[i][key] | number: '1.0-0'}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<!-- CHART -->
<div class="row" [hidden]="!showGraph">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-heading">
        <button *ngIf="contactsData.length" class="btn btn-sm btn-info no-mrg-right pull-right" (click)="showChart()">
          <span>{{'dashboards.customers.tabs.contacts.buttons.hide_graph' | translate}}</span>
        </button>
      </div>
      <div [hidden]="!contactsData.length" class="tab-info">
        <div #contactsChartElement></div>
      </div>
      <div [hidden]="contactsData.length">
        <ng-container *ngTemplateOutlet="showNoDataMessage"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #showNoDataMessage>
  <div class="card-block no-pdd no-mrg pdd-horizon-20 pdd-btm-5" *ngIf="!loading">
    <div class="alert alert-warning fade show mrg-btm-15">
      {{'components.data-table.messages.empty' | translate}}
    </div>
  </div>
</ng-template>
