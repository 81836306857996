import { TranslateService } from '@ngx-translate/core';
import { Injector } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { ProfileService } from '../../profiles/profile.service';
import * as moment from 'moment';
import { PrimeNGConfig } from 'primeng/api';
import { getDefaultNavigatorLanguage } from '../utils/languages.utils';

/**
 * This factory is to safely use synchronous .instant method for translations
 * More Info: https://github.com/ngx-translate/core/issues/517
 *
 * Basically it ensures the correct loading of jsons translations files using the asynchronous .use method.
 * After the promise is resolved the app component can initialize the rest of the components safely using the
 * TranslateService.instant method.
 */

export function translateInitializerFactory(translate: TranslateService,
                                            profileService: ProfileService,
                                            config: PrimeNGConfig,
                                            injector?: Injector) {
  return () => new Promise<any>((resolve: any) => {

    const setLang = function(defaultLanguage) {
      translate.use(defaultLanguage).subscribe({
        next: () => {
          console.info(`Successfully initialized '${defaultLanguage}' language`);
          moment.locale(defaultLanguage);
          translate.get('primeng').subscribe(res => config.setTranslation(res));
        },
        error: err => {
          console.error(`Problem with '${defaultLanguage}' language initialization. Error: '${err}'`);
        },
        complete: () => {
          resolve(null);
        }
      }
    )};

    const setLanguage = function() {
      const defaultLanguage = getDefaultNavigatorLanguage();
      if (localStorage.getItem('is_authenticated') === null) {
        translate.setDefaultLang(defaultLanguage);
        setLang(defaultLanguage);
      } else {
        profileService.getCurrentUserProfile().subscribe(
          currentUser => setLang(currentUser.userPreferredLanguage ?? currentUser.companyLanguage ?? defaultLanguage)
        );
      }
    };

    if (injector !== undefined) {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        setLanguage();
      });
    } else {
      setLanguage();
    }
  });
}
