<div *ngIf="!isLoading">
  <div class="row d-flex" [ngClass]="{ 'mrg-btm-15' : config.tokenPagination }">
    <div class="col">
      <div class="row mrg-left-15" *ngIf="config.tokenPagination !== true && config.rows !== undefined && config.rows.length > 0">
        <div class="col-xs-1 pdd-top-7">
          <span class="font-size-12" [translate]="'components.data-table.fields.show'"></span>
        </div>
        <div class="col-xs-1 mrg-horizon-5">
          <select class="form-control btn btn-default btn-xs font-size-12 x-elements"
                  ng-model="page.size"
                  (change)="changePageSize($event)"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="toolTipText.sel_result">
            <option *ngFor='let n of numberOfPages'
                    [selected]="page.size === n"
                    [value]="n">{{n}}</option>
          </select>
        </div>
        <div class="col-xs-1 pdd-top-7">
          <span class="font-size-12" [translate]="'components.data-table.fields.elements'"></span>
        </div>
      </div>
    </div>
    <div class="col mrg-right-15 d-flex align-items-center justify-content-end toggle">
      <!-- Table actions -->
      <button class="btn btn-default btn-sm dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              (click)="trackFrogedEvent('moreActions')"
              *ngIf="config.tableActions && config.tableActions.length > 0">
        <span class="font-size-10" [translate]="'components.data-table.fields.batch_actions'"></span>
        <i class="ti-angle-down font-size-9 mrg-left-4"></i>
      </button>
      <ul class="dropdown-menu dropdown-menu-right">
        <li *ngFor='let action of config.tableActions'
            class="cursor-pointer"
            [ngClass]="{ 'action-disabled': onDisabled(action) }">
          <a class="display-flex dropdown-item font-size-10"
            [ngClass]="{ 'disabled': onDisabled(action) }"
            *ngIf="action.show === undefined ? true: action.show"
            [id]="action.id"
            (click)="onTableAction(action)">
            <i class="font-size-12 mrg-right-5" [ngClass]="action.icon"></i> {{action.name}}
          </a>
        </li>
      </ul>
      <!-- END Table actions -->
      <!-- Create Button -->
      <button *ngIf="config.createButton"
              [hidden]="config.createButton.isHidden ? config.createButton.isHidden() : false"
              (click)="createNewResource()"
              id="newBtn"
              [attr.data-cy]="'button_createNew'"
              class="btn btn-info btn-sm dropdown-toggle no-mrg-right"
              type="button"
              [disabled]="!ableToCreate">
        <i class="fas fa-plus-circle font-size-10 mrg-left-4"></i>
        <span [attr.data-cy]="'span_createNew'" class="font-size-10 text-semibold">{{config.createButton.label}}</span>
      </button>
      <!-- END Create Button -->
    </div>
  </div>
  <div class="mrg-top-5" *ngIf="config.rows !== undefined && config.rows.length > 0">
    <ngx-datatable class="lg-datatable"
                    [rows]="config.rows"
                    [columns]="config.columns"
                    [columnMode]="'force'"
                    [headerHeight]="'auto'"
                    [footerHeight]="50"
                    [rowHeight]="'auto'"
                    [externalPaging]="true"
                    [externalSorting]="true"
                    [count]="page.totalElements"
                    [offset]="page.pageNumber"
                    [limit]="page.size"
                    (page)='setPage($event)'
                    (sort)="onSort($event)"
                    [selected]="selected"
                    (select)='onSelect($event)'
                    [selectionType]="'checkbox'"
                    [rowIdentity]="getId"
                    [selectAllRowsOnPage]="false"
                    [cssClasses]="customCssClasses"
                    [messages]="{emptyMessage: 'components.data-table.messages.empty' | translate,
                                totalMessage: 'components.data-table.messages.total' | translate,
                                selectedMessage: 'components.data-table.messages.selected' | translate}">
      <!-- Checkbox in row -->
      <ngx-datatable-column [width]="30"
                            [sortable]="false"
                            [canAutoResize]="false"
                            [draggable]="false"
                            [resizeable]="false"
                            *ngIf="config.renderRowCheckbox">
        <ng-template ngx-datatable-header-template
                    let-value="value"
                    let-allRowsSelected="allRowsSelected"
                    let-selectFn="selectFn">
          <input type="checkbox" [checked]="allRowsSelected" (change)="onSelectAll($event)"/>
        </ng-template>
        <ng-template ngx-datatable-cell-template
                    let-value="value"
                    let-isSelected="isSelected"
                    let-onCheckboxChangeFn="onCheckboxChangeFn">
          <label class="datatable-checkbox">
            <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)"/>
          </label>
        </ng-template>
      </ngx-datatable-column>
      <!-- -->
      <!-- Row actions -->
      <ngx-datatable-column *ngIf="config.rowActions && config.rowActions.length > 0"
                            [width]="(config.rowActions.length * 50) + 20"
                            [sortable]="false"
                            [canAutoResize]="false"
                            [draggable]="false"
                            [resizeable]="false">
        <ng-template ngx-datatable-cell-template
                      let-rowIndex="rowIndex"
                      let-value="value"
                      let-row="row">
          <div class="btn-group">
            <ng-container *ngFor="let action of config.rowActions">
              <a [routerLink]="getShowLink(action, rowIndex)"
                  [id]="config.requestData.apiEndPoint+'.'+action.id"
                  [attr.data-cy]="'link_' + (action.id + ' ' + config.requestData.apiEndPoint | camelCase)"
                  class="btn btn-default"
                  [ngClass]="{ 'action-disabled disabled': onDisabled(action, rowIndex) }"
                  *ngIf="onHide(action, row, rowIndex) && !actionHasCallback(action) && !actionHasCustomRoute(action)"
                  data-toggle="tooltip" data-placement="bottom" title="{{action.name}}">
                <i class="fa fa-{{action.icon}} mr-0 font-size-16" [id]="config.requestData.apiEndPoint+'.'+action.id"
                  [attr.data-cy]="'icon_' + (action.id + ' ' + config.requestData.apiEndPoint | camelCase)"></i>
              </a>
              <a [routerLink]="getCustomRoute(action, rowIndex)"
                  [id]="config.requestData.apiEndPoint+'.'+action.id"
                  [attr.data-cy]="'link_' + (action.id + ' ' + config.requestData.apiEndPoint | camelCase)"
                  class="btn btn-default"
                  [ngClass]="{ 'action-disabled disabled': onDisabled(action, rowIndex) }"
                  *ngIf="onHide(action, row, rowIndex) && actionHasCustomRoute(action)"
                  data-toggle="tooltip" data-placement="bottom" title="{{action.name}}">
                <i class="fa fa-{{action.icon}} mr-0 font-size-16" [id]="config.requestData.apiEndPoint+'.'+action.id"
                  [attr.data-cy]="'icon_' + (action.id + ' ' + config.requestData.apiEndPoint | camelCase)"></i>
              </a>
              <a (click)="onClick($event, action, rowIndex)"
                  [id]="config.requestData.apiEndPoint+'.'+action.id"
                  [attr.data-cy]="'link_' + (action.id + ' ' + config.requestData.apiEndPoint | camelCase)"
                  class="btn btn-default"
                  [ngClass]="{ 'action-disabled disabled': onDisabled(action, rowIndex) }"
                  *ngIf="onHide(action, row, rowIndex) && actionHasCallback(action)"
                  data-toggle="tooltip" data-placement="bottom" title="{{action.name}}">
                <i class="fa fa-{{action.icon}} mr-0 font-size-16" [id]="config.requestData.apiEndPoint+'.'+action.id"
                  [attr.data-cy]="'icon_' + (action.id + ' ' + config.requestData.apiEndPoint | camelCase)"></i>
              </a>
            </ng-container>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <!--  -->
      <!-- Data cells -->
      <ngx-datatable-column *ngFor='let column of config.columns'
                            [id]="column.name"
                            [prop]="column.prop"
                            [name]="column.name"
                            [sortable]="column.sortByField"
                            [canAutoResize]="true"
                            [cellClass]="getConfigColumnCellClass">
        <ng-template ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-row="row">

          <span *ngIf="!isObject(value)">{{value}}</span>

          <div *ngIf="isObjectAndHasKey(value, 'breakFullText')" class="break-full-text">
            <span>{{ value.breakFullText }}</span>
          </div>

          <span *ngIf="isObjectAndHasKey(value, 'src')">
            <img class="cell-image" [src]="value.src" alt="image">
          </span>

          <span *ngIf="isObjectAndHasKey(value, 'substr')"
                pTooltip="{{value.fullText}}"
                tooltipPosition="right">
            {{ value.substr }}
          </span>

          <!-- Render link in table -->
          <span *ngIf="isObjectAndHasKey(value, 'resourceLink')">
            <ng-container *ngIf="value?.parsedValue; else emptyValueResourceLink">
              <a [routerLink]="['', { outlets: { modal: ['show', value.resourceLink, value.resourceId] } }]">
                {{value.parsedValue}}
              </a>
            </ng-container>
            <ng-template #emptyValueResourceLink>{{ emptyValue }}</ng-template>
          </span>
          <!--  -->

          <!-- Render link with custom route and custom query params in table -->
          <span *ngIf="isObjectAndHasKey(value, 'customRouterLink')">
            <a [routerLink]="value.customRouterLink" [queryParams]="value.customQueryParams ? value.customQueryParams : null">
              {{value.parsedValue}}
            </a>
          </span>
          <!--  -->

          <!-- Render link with custom route in table -->
          <span *ngIf="isObjectAndHasKey(value, 'customExternalLink')">
            <a [href]="value.customExternalLink" target="_blank">
              {{value.parsedValue}}
            </a>
          </span>
          <!--  -->

          <!-- Render text as html -->
          <span *ngIf="isObjectAndHasKey(value, 'innerHTML')" class="font-size-11" [ngClass]="value.extraCSS ? value.extraCSS : ''" [innerHTML]="value.innerHTML"></span>
          <!--  -->

          <span *ngIf="isObjectAndHasKey(value, 'badge')">
            <h4><span class="{{value.css_class}}" [innerHTML]="value.badge"></span></h4>
          </span>

          <span *ngIf="isObjectAndHasKey(value, 'callback')">
            <a (click)="onClick($event, value, rowIndex)" href="javascript:void(0)">
              {{value.parsedValue}}
            </a>
          </span>

          <span *ngIf="isObjectAndHasKey(value, 'icons')">
            <div class="resource-icon">
              <span *ngFor="let iconItem of value.icons">
                <i
                  class="{{iconItem.icon}} font-size-20"
                  data-toggle="tooltip"
                  data-placement="top"
                ></i>
              </span>
            </div>
          </span>

          <!-- Render icon button with link (no label) -->
          <!-- Campaigns with via = none does not have any content to show -->
          <ng-container *ngIf="isObjectAndHasKey(value, 'resourceIcon')">
            <div class="resource-icon">
              <span>
                <a *ngIf="isIconRenderable(value)"
                  [routerLink]="['', { outlets: { modal: value.resourceIcon } }]">
                  <i class="{{value.icon}} mr-0 font-size-24"
                    data-toggle="tooltip"
                    data-placement="top"
                    [title]="value.name | translate">
                  </i>
                </a>
                <a *ngIf="value.parsedValue === 'none'">
                  <i class="{{value.icon}} mr-0 font-size-24"
                    data-toggle="tooltip"
                    data-placement="top"
                    [title]="value.name | translate">
                  </i>
                </a>
                <a *ngIf="value.parsedValue === 'true'">
                  <i class="{{value.icon}} mr-0 font-size-24"
                    data-toggle="tooltip"
                    data-placement="top"
                    [title]="value.name | translate">
                  </i>
                </a>
              </span>
            </div>
          </ng-container>
          <span *ngIf="isObjectAndHasKey(value, 'plainIcon')">
            {{value.value}}&nbsp;&nbsp;
            <i class="{{value.plainIcon}} mr-0 font-size-24"
              data-toggle="tooltip"
              data-placement="top"
              [title]="value.name"></i>
          </span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer *ngIf="!config.tokenPagination">
        <ng-template
          ngx-datatable-footer-template
          let-rowCount="rowCount"
          let-pageSize="pageSize"
          let-selectedCount="selectedCount"
          let-curPage="curPage"
          let-offset="offset">
          <datatable-pager
            #ngxDatatablePager
            [pagerLeftArrowIcon]="'fa-light fa-chevron-left'"
            [pagerRightArrowIcon]="'fa-light fa-chevron-right'"
            [pagerPreviousIcon]="'mrg-right-10 fa-light fa-chevrons-left'"
            [pagerNextIcon]="'mrg-left-10 fa-light fa-chevrons-right'"
            [page]="curPage"
            [size]="pageSize"
            [count]="rowCount"
            (change)="renderRowCheckboxPagination($event)">
          </datatable-pager>
        </ng-template>
      </ngx-datatable-footer>

    </ngx-datatable>
    <div class="text-right" *ngIf="config.tokenPagination">
      <button #loadMoreBtn
              class="btn btn-info btn-sm"
              *ngIf="nextTokenParam"
              [disabled]="this.tokensData.length === 0"
              (click)="nextTokenAppendPage()">
        {{'components.data-table.fields.load_more' | translate}}
      </button>
    </div>
  </div>
</div>

<div *ngIf="!isLoading && (config.rows === undefined || config.rows.length === 0)">
  <table id="dt-opt" class="table table-lg table-hover dataTable no-footer mrg-top-20" role="grid" aria-describedby="dt-opt_info"  aria-hidden="true">
    <tbody>
      <tr class="odd" *ngIf="!config.noElementsFoundCustomMessage">
        <td colspan="7" class="dataTables_empty text-semibold text-center" [translate]="'components.data-table.messages.no_elements_found'"></td>
      </tr>
      <tr class="odd" *ngIf="config.noElementsFoundCustomMessage">
        <td colspan="7" class="dataTables_empty text-semibold text-center" [innerHTML]="config.noElementsFoundCustomMessage"></td>
      </tr>
    </tbody>
  </table>
</div>

<div class="col-md-12 pdd-vertical-10" *ngIf="isLoading">
  <app-loader noWrapper />
</div>
