import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject, finalize, takeUntil } from 'rxjs';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { AuthService } from '../../../auth.service';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { getDefaultNavigatorLanguage } from '../../../../shared/utils/languages.utils';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {

  loading = false;
  recoverPasswordForm: UntypedFormGroup;
  recoverPasswordInputs: QuestionBase<unknown>[];

  private destroy$ = new Subject<void>();

  @Input() isResetPasswordTokenError = false;
  @Input() isResetExpiredPassword = false;

  constructor(
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private qcs: QuestionControlService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.setInputsAndForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.recoverPasswordInputs, inputKey);
  }

  recoverPassword(): void {
    this.loading = true;
    const payload = {
      email: this.recoverPasswordForm.getRawValue().email,
      language: getDefaultNavigatorLanguage()
    };
    this.authService.recoverPassword(payload).pipe(takeUntil(this.destroy$), finalize(() => this.loading = false)).subscribe({
      next: () => {
        this.confirmationService.displayOutsideUnclickableSuccessAlert(
          this.translate.instant('components.signin.titles.recover_password'),
          this.translate.instant('components.signin.messages.email_sent')
        ).catch(() => {});
      },
      error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
    });
  }

  private setInputsAndForm(): void {
    this.recoverPasswordInputs = this.getInputs();
    this.recoverPasswordForm = this.qcs.toFormGroup(this.recoverPasswordInputs);
  }

  private getInputs(): QuestionBase<unknown>[] {
    return [
      new TextboxQuestion({
        key: 'email',
        type: 'text',
        cssClasses: 'form-control input-default',
        placeholder: this.translate.instant('common.email'),
        required: true
      })
    ];
  }
}
