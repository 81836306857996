<div class="alert alert-success" *ngIf="campaign.report.status === 'ok'">
  <ul>
    <li>
      {{'resources.campaigns.messages.ready_to_send' | translate}}
    </li>
  </ul>
</div>
<div class="alert alert-danger" *ngIf="campaign.report.error">
  <ul>
    <li>{{campaign.report.error.error}}</li>
  </ul>
</div>
<div class="alert alert-danger" *ngIf="campaign.report.message && campaign.report.status === 'error' ">
  <ul>
    <li>{{campaign.report.message}}</li>
  </ul>
</div>
<div class="alert alert-warning" *ngIf="campaign?.report?.warning_motives?.length > 0">
  <ul>
    <li *ngFor="let motive of campaign.report.warning_motives">
      {{motive}}
    </li>
  </ul>
</div>
<div *ngIf="campaign.report.spending_info">
  <div class="row" *ngIf="hasFormKeyWithValue('via', 'sms') && (campaign.report.spending_info.sending_amount > campaign.report.count)">
    <div class="col-md-6">
      <label>&nbsp;</label>
      <app-question [question]="getInputConfig('skip_amount_error')" [form]="campaignForm"></app-question>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card bg-primary">
        <div class="card-block">
          <div class="row">
            <div class="col-md-7">
              <p class="text-uppercase font-size-13" *ngIf="hasFormKeyWithValue('via', 'sms')">
                <b>{{'resources.campaigns.messages.total_sms' | translate}}</b>
              </p>
              <p class="text-uppercase font-size-13" *ngIf="hasFormKeyWithValue('via', 'email')">
                <b>{{'resources.campaigns.messages.total_emails' | translate}}</b>
              </p>
              <h2 class="no-mrg-top font-size-24">
                {{campaign.report.spending_info.sending_amount}}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card bg-primary">
        <div class="card-block">
          <div class="row">
            <div class="col-md-7">
              <p class="text-uppercase font-size-13">
                <b>{{'resources.campaigns.messages.credits' | translate}}</b>
              </p>
              <h2 class="no-mrg-top font-size-24">
                {{campaign.report.spending_info.credits | lgcurrency: '1.2-2'}}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col" *ngIf="companyCreditsInfo">
      <div class="card bg-primary">
        <div class="card-block">
          <div class="row">
            <div class="col">
              <p class="text-uppercase font-size-13">
                <b>{{'resources.campaigns.messages.credits_available' | translate}}</b>
              </p>
              <h2 class="no-mrg-top font-size-24" *ngIf="hasFormKeyWithValue('via', 'sms')">
                {{ companyCreditsInfo.smsCredits | lgcurrency: '1.2-2' }}
              </h2>
              <h2 class="no-mrg-top font-size-24" *ngIf="hasFormKeyWithValue('via', 'email')">
                {{ companyCreditsInfo.emailCredits | lgcurrency: '1.2-2' }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
