import { CampaignCategoriesService } from '../../../campaigns/campaign-categories/campaign-categories.service';
import { CheckboxQuestion } from '../../../../shared/models/forms/question-checkbox';
import { cleanUndefinedFormValues } from '../../../../shared/services/validations.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { DateService } from '../../../../shared/services/date.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { DynamicDateQuestion } from '../../../../shared/models/forms/question-dynamic-date';
import { FeaturesService } from '../../../data-warehouse/products/features.service';
import { FeatureTaxonomiesService } from '../../../data-warehouse/products/feature-taxonomies.service';
import { ValidatorFn, AbstractControl, UntypedFormGroup } from '@angular/forms';
import { isNullOrUndefined } from '../../../../shared/utils/common.utils';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { PlanCampaign } from '../plan-campaign';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { RadioQuestion } from '../../../../shared/models/forms/question-radio';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-supplier-campaign-plan-definition',
  templateUrl: './supplier-campaign-plan-definition.component.html',
  styleUrls: ['./supplier-campaign-plan-definition.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ FeaturesService ]
})

export class SupplierCampaignPlanDefinitionComponent implements OnInit, OnDestroy {

  inputs: QuestionBase<any>[];
  form: UntypedFormGroup;
  subs$: Subscription[] = [];

  @Input('planCampaign') planCampaign: PlanCampaign;
  @Output('formValidity') formValidity: EventEmitter<any>;

  constructor(
    private featureService: FeaturesService,
    private featuresTaxonomiesService: FeatureTaxonomiesService,
    private campaignCategoriesSrv: CampaignCategoriesService,
    private qcs: QuestionControlService,
    private translate: TranslateService,
    private dateService: DateService
  ) {
    this.formValidity = new EventEmitter();
  }

  ngOnInit() {
    this.setInputs(this.planCampaign);
    this.setProductFeaturesOnInit();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  parseSupplierTypeValues(): 'up' | 'cross' | 'up_cross' {
    if (this.form.value.upselling && this.form.value.cross) {
      return 'up_cross';
    } else if (this.form.value.upselling && !this.form.value.cross) {
      return 'up';
    } else if (!this.form.value.upselling && this.form.value.cross) {
      return 'cross';
    }
    console.error('supplier type is not properly configured', this.form.value);
  }

  getPayload(): Object {

    let payload = Object.assign({}, this.form.value);

    const products = this.form.value['product_ids'];
    const segment = this.form.value['segment_id'];
    const periodFrom = this.form.value['period_date_from'];
    const periodTo = this.form.value['period_date_to'];
    const feature = this.form.value['feature_ids'];
    const taxonomy = this.form.value['taxonomy_slug'];
    const whiteLabelFeature = this.form.value['white_label_id'];
    const nonBuyersTaxonomy = this.form.value['non_buyers_taxonomy_slug'];
    const nonBuyersFeature = this.form.value['non_buyers_feature_id'];

    payload['type'] = 'SupplierCampaign';
    payload['name'] = this.form.value['name'];
    payload['config'] = {};

    if (this.form.value.upselling && !this.form.value.cross) {
      payload['config'] = {
        supplier_id: this.form.value['supplier_id'].map(el => el.rawElement.pk)[0],
        supplier_type: this.parseSupplierTypeValues(),
        up_campaign_plan_category_id: this.form.value['up_campaign_plan_category_id'] && this.form.value['up_campaign_plan_category_id'].map(el => el.id)[0]
      };
    }

    if (!this.form.value.upselling && this.form.value.cross) {
      payload['config'] = {
        scope: this.form.value['scope'],
        supplier_id: this.form.value['supplier_id'].map(el => el.rawElement.pk)[0],
        periods: this.form.value['periods'] ? this.form.value['periods'].map(el => el.id) : null,
        has_bought: this.form.value['has_bought'] ? this.form.value['has_bought'] : false,
        active_clients: this.form.value['active_clients'] ? this.form.value['active_clients'] : false,
        supplier_type: this.parseSupplierTypeValues(),
        cross_campaign_plan_category_id: this.form.value['cross_campaign_plan_category_id'] && this.form.value['cross_campaign_plan_category_id'][0].id
      };
    }

    if (this.form.value.upselling && this.form.value.cross) {
      payload['config'] = {
        scope: this.form.value['scope'],
        supplier_id: this.form.value.supplier_id?.map(el => el.rawElement.pk)[0],
        periods: this.form.value['periods'] ? this.form.value['periods'].map(el => el.id) : null,
        has_bought: this.form.value['has_bought'] ? this.form.value['has_bought'] : false,
        active_clients: this.form.value['active_clients'] ? this.form.value['active_clients'] : false,
        supplier_type: this.parseSupplierTypeValues(),
        up_campaign_plan_category_id: this.form.value['up_campaign_plan_category_id'] && this.form.value['up_campaign_plan_category_id'].map(el => el.id)[0],
        cross_campaign_plan_category_id: this.form.value['cross_campaign_plan_category_id'] && this.form.value['cross_campaign_plan_category_id'].map(el => el.id)[0]
      };
    }

    if (products && products.length > 0 && !isNullOrUndefined(products[0])) {
      payload['config']['product_ids'] = products.map(el => el.id);
    }

    if (segment && segment.length > 0 && !isNullOrUndefined(segment[0])) {
      payload['config']['segment_id'] = segment[0].id;
    }

    if (this.form.value.upselling && !isNullOrUndefined(periodFrom) && Object.keys(periodFrom).length > 0) {
      payload['config']['period_date_from'] = periodFrom['id'];
    }

    if (this.form.value.upselling && !isNullOrUndefined(periodTo) && Object.keys(periodTo).length > 0) {
      payload['config']['period_date_to'] = periodTo['id'];
    }

    if (feature && feature.length > 0 && !isNullOrUndefined(feature[0])) {
      payload['config']['feature_ids'] = feature.map(el => el.rawElement.pk);
    }

    if (taxonomy && taxonomy.length > 0 && !isNullOrUndefined(taxonomy[0])) {
      payload['config']['taxonomy_slug'] = taxonomy.map(el => el.rawElement.slug);
    }

    if (whiteLabelFeature && whiteLabelFeature.length > 0 && !isNullOrUndefined(whiteLabelFeature[0])) {
      payload['config']['white_label_id'] = whiteLabelFeature.map(el => el.rawElement.pk)[0];
    }

    if (nonBuyersTaxonomy && nonBuyersTaxonomy.length > 0 && !isNullOrUndefined(nonBuyersTaxonomy[0])) {
      payload['config']['non_buyers_taxonomy_slug'] = nonBuyersTaxonomy.map(el => el.rawElement.slug)[0];
    }

    if (nonBuyersFeature && nonBuyersFeature.length > 0 && !isNullOrUndefined(nonBuyersFeature[0])) {
      payload['config']['non_buyers_feature_id'] = nonBuyersFeature.map(el => el.rawElement.pk)[0];
    }

    ['supplier_id', 'product_ids', 'segment_id', 'has_bought', 'active_clients', 'periods', 'cross',
    'upselling', 'scope', 'period_date_from', 'period_date_to', 'taxonomy_slug', 'feature_ids',
    'white_label_id', 'non_buyers_taxonomy_slug', 'non_buyers_feature_id', 'up_campaign_plan_category_id',
    'cross_campaign_plan_category_id']
      .forEach(el => {
        delete payload[el];
      });

    payload.config = cleanUndefinedFormValues(payload.config);
    payload = cleanUndefinedFormValues(payload);
    return payload;
  }

  taxonomySelector() {
    this.inputs.find(item => item.key === 'feature_ids').selectedIds = [];
    this.inputs.find(item => item.key === 'feature_ids').value = [];
    const taxonomy = this.form.value['taxonomy_slug'];

    // TODO: Review why the setTimeout became mandatory to clean the selected ids (featureService).
    setTimeout(() => {
      if (taxonomy && taxonomy.length > 0) {
        this.inputs.find(item => item.key === 'feature_ids').disabled = false;
        this.featureService.setTaxonomies(taxonomy[0].rawElement.slug);
        this.form.get('feature_ids').reset();
      } else {
        this.featureService.setTaxonomies(null);
        this.inputs.find(item => item.key === 'feature_ids').disabled = true;
      }
    });
  }

  markAsDirty(str: string) {
    // auto select up type if dates are touched
    this.form.patchValue({upselling: true}, {emitEvent: true});
    this.form.get(str).markAsDirty();
    this.form.get(str).markAsTouched();
  }

  handlePeriodChange(periodSelection) {
    if (periodSelection) {this.form.patchValue({cross: true}, {emitEvent: true}); }
  }

  handleScope() {
    // auto select cross type if radio/scope is changed
    this.form.patchValue({cross: true}, {emitEvent: true});
    const scopeValue = this.form.value['scope'];
    if (scopeValue === 'category') {
      this.cleanWhiteLabelInputs();
    } else if (scopeValue === 'white_label') {
      this.cleanCategoryInputs();
    } else {
      this.cleanCategoryInputs();
      this.cleanWhiteLabelInputs();
    }
  }

  handleCheckChanged() {
    this.form.patchValue({cross: true}, {emitEvent: true});
  }

  onSelectedIdsLoaded() {
    this.formStatusChanges();
  }

  private setProductFeaturesOnInit() {
    const taxonomySlug = this.inputs.find(item => item.key === 'taxonomy_slug').selectedIds;
    if (taxonomySlug.length > 0) {
      this.inputs.find(item => item.key === 'feature_ids').disabled = false;
      this.featureService.setTaxonomies(taxonomySlug.map(feature => feature).join(','));
    }
  }

  private cleanCategoryInputs() {
    this.inputs.find(item => item.key === 'feature_ids').selectedIds = [];
    this.inputs.find(item => item.key === 'feature_ids').value = [];
    this.inputs.find(item => item.key === 'taxonomy_slug').selectedIds = [];
    this.inputs.find(item => item.key === 'taxonomy_slug').value = [];
  }

  private cleanWhiteLabelInputs() {
    const whiteLblInput = this.inputs.find(item => item.key === 'white_label_id');
    const nonBuyersTaxonomy = this.inputs.find(item => item.key === 'non_buyers_taxonomy_slug');
    const nonBuyersFeature = this.inputs.find(item => item.key === 'non_buyers_feature_id');

    if (whiteLblInput) {
      whiteLblInput.selectedIds = [];
      whiteLblInput.value = [];
    }

    if (nonBuyersTaxonomy) {
      nonBuyersTaxonomy.selectedIds = [];
      nonBuyersTaxonomy.value = [];
    }

    if (nonBuyersFeature) {
      nonBuyersFeature.selectedIds = [];
      nonBuyersFeature.value = [];
    }

    this.qcs.removeFromFormGroup(this.form, 'white_label_id');
    this.qcs.removeFromFormGroup(this.form, 'non_buyers_taxonomy_slug');
    this.qcs.removeFromFormGroup(this.form, 'non_buyers_feature_id');
  }

  private radioButtonOptions() {
    return [
      { id: 'product', name: this.translate.instant('resources.campaign_plans.types.supplier.fields.product')},
      { id: 'feature', name: this.translate.instant('resources.campaign_plans.types.supplier.fields.product_category')},
      { id: 'white_label', name: this.translate.instant('resources.campaign_plans.types.supplier.fields.white_label')}
    ];
  }

  private dateOptions() {
    return [
      { id: '%30_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_1')},
      { id: '%90_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_3')},
      { id: '%180_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_6')},
      { id: '%365_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_12')},
      { id: 'never', name: this.translate.instant('resources.campaign_plans.types.supplier.fields.never')},
    ];
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.form.status));
    const formStatus$ = this.form.statusChanges.pipe(distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
    this.subs$.push(formStatus$);
  }

  private getValidations(): ValidatorFn[] {
    const ensureType = ((_ctrl: AbstractControl) => {
      const upsellingCheck = _ctrl.get('upselling');
      const crossCheck = _ctrl.get('cross');
      if (upsellingCheck.value !== true && crossCheck.value !== true) {
        return {typeRequired: true};
      }
      return null;
    });

    const upSellingVld = ((_ctrl: AbstractControl) => {
      const upsellingCheck = _ctrl.get('upselling');
      const campaignCategoryCheck = _ctrl.get('up_campaign_plan_category_id');
      const periodFromCtrl = _ctrl.get('period_date_from');
      const periodToCtrl = _ctrl.get('period_date_to');
      periodFromCtrl.setValidators([]);
      periodToCtrl.setValidators([]);
      const isPeriodFromPresent = periodFromCtrl.value && periodFromCtrl.value.hasOwnProperty('id') && !isNullOrUndefined(periodFromCtrl.value.id);
      const isPeriodToPresent = periodToCtrl.value  && periodToCtrl.value.hasOwnProperty('id') && !isNullOrUndefined(periodToCtrl.value.id);
      const isCampaignCategoryPresent = campaignCategoryCheck.value && campaignCategoryCheck.value.length > 0 && !isNullOrUndefined(campaignCategoryCheck.value[0]);

      if (upsellingCheck.value === true) {
        if (!isPeriodFromPresent || !isPeriodToPresent || !isCampaignCategoryPresent) {
          return {periodDatesRequired: true};
        }
        return null;
      }
      return null;
    });

    const crossSellingPeriodVld = ((_ctrl: AbstractControl) => {
      const crossSellingCheck = _ctrl.get('cross');
      const periodCtrl = _ctrl.get('periods');
      const campaignCategoryCheck = _ctrl.get('cross_campaign_plan_category_id');
      const isPeriodPresent = periodCtrl.value && periodCtrl.value.length > 0 && !isNullOrUndefined(periodCtrl.value[0]);
      const isCampaignCategoryPresent = campaignCategoryCheck.value && campaignCategoryCheck.value.length > 0 && !isNullOrUndefined(campaignCategoryCheck.value[0]);

      if (crossSellingCheck.value === true) {
        if (!isPeriodPresent || !isCampaignCategoryPresent) {
          return {periodRequired: true};
        }
        return null;
      }
      return null;
    });

    const crossSellingCategoryVld = ((_ctrl: AbstractControl) => {
      const crossSellingCheck = _ctrl.get('cross');
      const scopeCtrl = _ctrl.get('scope');
      const featureCtrl = _ctrl.get('feature_ids');
      const isFeaturePresent = featureCtrl.value && featureCtrl.value.length > 0 && !isNullOrUndefined(featureCtrl.value[0]);
      if (crossSellingCheck.value === true) {
        if (scopeCtrl.value === 'feature' && !isFeaturePresent) {
          return {featureRequired: true};
        }
        return null;
      }
      return null;
    });

    const crossSellingWhiteLabelVld = ((_ctrl: AbstractControl) => {
      const crossSellingCheck = _ctrl.get('cross');
      const scopeCtrl = _ctrl.get('scope');
      if (crossSellingCheck.value === true) {
        if (scopeCtrl.value === 'white_label') {
          const whiteLabelFeature = _ctrl.get('white_label_id');
          const nonBuyersFeature = _ctrl.get('non_buyers_feature_id');
          if (whiteLabelFeature && nonBuyersFeature) {
            const isWhiteLabelFeaturePresent = whiteLabelFeature.value && whiteLabelFeature.value.length > 0 && !isNullOrUndefined(whiteLabelFeature.value[0]);
            const isNonBuyersFeaturePresent = nonBuyersFeature.value && nonBuyersFeature.value.length > 0 && !isNullOrUndefined(nonBuyersFeature.value[0]);
            if (!isWhiteLabelFeaturePresent) { return {whiteLabelFeatureRequired: true}; }
            if (!isNonBuyersFeaturePresent) { return {whiteLabelFeatureRequired: true}; }
            return null;
          }
          return null;
        }
        return null;
      }
      return null;
    });

    this.overrideRequiredInputs();
    return [
      ensureType,
      upSellingVld,
      crossSellingPeriodVld,
      crossSellingCategoryVld,
      crossSellingWhiteLabelVld
    ];
  }

  private overrideRequiredInputs() {
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key).setValidators([]);
    });
  }

  private setInputs(planCampaign?: PlanCampaign) {
    let upCategory;
    let crossCategory;
    if (planCampaign) {
      upCategory = this.getUpCampaignCategoryValue(planCampaign);
      crossCategory = this.getCrossCampaignCategoryValue(planCampaign);
    }
    const inputs = [
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        label: 'Upselling',
        key: 'upselling',
        value: planCampaign ? this.getSupplierValue(planCampaign, ['up', 'up_cross']) : false
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        label: 'Cross-Selling',
        key: 'cross',
        value: planCampaign ? this.getSupplierValue(planCampaign, ['cross', 'up_cross']) : false
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.campaign_plans.types.supplier.fields.bought'),
        key: 'has_bought',
        value: planCampaign ? planCampaign.config['has_bought'] : false
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.campaign_plans.types.supplier.fields.active_customers'),
        key: 'active_clients',
        value: planCampaign ? planCampaign.config['active_clients'] : false
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'period_date_from',
        label: this.translate.instant('resources.campaign_plans.types.supplier.fields.date_from'),
        value: planCampaign ? this.dateService.dynamicDateToDateOption(planCampaign.config['period_date_from']) : null,
        required: true,
        hideOverlappingOpts: true
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'period_date_to',
        label: this.translate.instant('resources.campaign_plans.types.supplier.fields.date_to'),
        value: planCampaign ? this.dateService.dynamicDateToDateOption(planCampaign.config['period_date_to']) : null,
        required: true,
        hideOverlappingOpts: true
      }),
      new RadioQuestion({
        key: 'scope',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.radioButtonOptions(),
        value: planCampaign && planCampaign.config.hasOwnProperty('scope') ? planCampaign.config['scope'] : 'product'
      }),
      new MultiSelectQuestion({
        key: 'periods',
        label: 'resources.campaign_plans.types.supplier.fields.period',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        options: this.dateOptions(),
        value: planCampaign ? this.getSelectedPeriods(planCampaign) : [],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'taxonomy_slug',
        label: 'dashboards.categories.filters.taxonomy_slug',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.featuresTaxonomiesService,
        selectedIds: planCampaign ? planCampaign.config['taxonomy_slug'] : []
      }),
      new MultiSelectQuestion({
        key: 'feature_ids',
        label: 'dashboards.categories.filters.features',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.featureService,
        disabled: true,
        selectedIds: planCampaign ? planCampaign.config['feature_ids'] : [],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'up_campaign_plan_category_id',
        label: 'resources.plans.filters.campaign_category',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.campaignCategoriesSrv,
        selectedIds: (upCategory && upCategory.length > 0 && upCategory[0] !== 0) ? upCategory : null,
        value: [{id: -1, name: this.translate.instant('resources.campaign_categories.messages.no_category')}],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'cross_campaign_plan_category_id',
        label: 'resources.plans.filters.campaign_category',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.campaignCategoriesSrv,
        selectedIds: (crossCategory && crossCategory.length > 0 && crossCategory[0] !== 0) ? crossCategory : null,
        value: [{id: -1, name: this.translate.instant('resources.campaign_categories.messages.no_category')}],
        required: true
      }),
    ];

    this.inputs = inputs;
    this.form = this.qcs.toFormGroup(this.inputs);
    this.form.setValidators(this.getValidations());

    if (isNullOrUndefined(planCampaign)) {
      this.formStatusChanges();
    } else {
      setTimeout(() => this.formValidity.emit('INVALID'));
    }
  }

  private getUpCampaignCategoryValue(planCampaign: PlanCampaign) {
    const upCampaignPlanCategoryId = planCampaign.config['up_campaign_plan_category_id'];
    if (isNullOrUndefined(upCampaignPlanCategoryId)) {return []; }
    return [upCampaignPlanCategoryId];
  }

  private getCrossCampaignCategoryValue(planCampaign: PlanCampaign) {
    const crossCampaignPlanCategoryId = planCampaign.config['cross_campaign_plan_category_id'];
    if (isNullOrUndefined(crossCampaignPlanCategoryId)) {return []; }
    return [crossCampaignPlanCategoryId];
  }

  private getSupplierValue(planCampaign: PlanCampaign, values: string[]): boolean {
    if (!planCampaign.config.hasOwnProperty('supplier_type')) { return false; }
    return values.includes(planCampaign.config['supplier_type']);
  }

  private getSelectedPeriods(planCampaign: PlanCampaign): {id: number, name: string}[] {
    const campaignPeriods = planCampaign.config['periods'];
    if (isNullOrUndefined(campaignPeriods)) {return []; }
    return planCampaign.config['periods'].map(period => this.dateOptions().find(opt => opt.id === period));
  }

}
