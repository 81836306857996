<div class="card">
  <div class="card__icon">
    <!-- Icon -->
    <em class="{{ icon }}" style="font-size: 108px" [style.color]="color"></em>
  </div>
  <div class="card__content">
    <!-- Title -->
    <div class="card__title text-bold" [innerHTML]="title"></div>
  </div>
  <div class="card__bottom-decor" [style.border-top-color]="color">
    <!-- Text -->
    <div class="text">{{ text }}</div>
  </div>
</div>