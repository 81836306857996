<div class="row">
  <div class="col-md-3">
    <div class="card min-height-325">
      <div class="card-block">
        <div class="row">
          <!-- Title summary block -->
          <h4 class="card-title mrg-left-15">
            <b [translate]="'resources.journeys.fields.summary'"></b>
          </h4>
        </div>
        <div class="row">
          <div class="col" *ngIf="journey.type !== 'multicoupon' && 
                                  journey.type !== 'potential' &&
                                  journey.type !== 'recuperation'">
            <!-- Report data for all except upselling -->
            <div class="checkbox">
              <input id="includeInRoi" name="includeInRoi" type="checkbox" [(ngModel)]="journey.reportable"
                (ngModelChange)="handleSummaryChanged($event)">
              <label for="includeInRoi" [translate]="'resources.journeys.fields.include_in_roi'"></label>
            </div>
            <div class="form-group">
              <label for="roi_percentage">
                <b>{{'resources.journeys.fields.roi_config' | translate}}:
                </b>{{'resources.journeys.fields.redemption_percentage' | translate}}
              </label>
              <input class="form-control" type="number"
                [(ngModel)]="journey.report_configuration.reward.prediction_percentage_redemption"
                (ngModelChange)="handleSummaryChanged($event)" min="0" max="100" step="0.1">
            </div>
          </div>

          <div class="col" *ngIf="journey.type === 'potential' || journey.type === 'recuperation'">
            <app-question [form]="form" [question]="getInputConfig('reportable')"></app-question>
            <app-question [form]="form" [question]="getInputConfig('prediction_percentage_redemption')"></app-question>
          </div>

          <div class="col" *ngIf="journey.type === 'multicoupon'">
            <app-question [form]="form" [question]="getInputConfig('reportable')"></app-question>
            <app-question [form]="form" [question]="getInputConfig('prediction_percentage_redemption')"></app-question>
            <app-question [form]="form" [question]="getInputConfig('campaign_category_id')"></app-question>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
