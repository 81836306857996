import { Injectable, NgZone } from "@angular/core";
import { ProfileService } from "../../profiles/profile.service";
import { isNullOrUndefined } from "../../shared/utils/common.utils";
import { Observable, Subject, filter, fromEvent, map, merge, switchMap, takeUntil, throttleTime, timer } from "rxjs";
import { LAST_USER_SESSION_ACTIVITY_LOCAL_STORAGE, USER_IDLE_SESSION_TIMER_LOCAL_STORAGE } from "../../shared/constants/local-storage.constants";
import { AuthService } from "../../auth/auth.service";
import { ConfirmationService } from "../../shared/services/confirmation.service";
import { TranslateService } from "@ngx-translate/core";

export const USER_IDLE_SESSION = 'user-idle-session';
export const USER_IDLE_SESSION_TIMER_MODAL = 'user-idle-session-timer-modal';
export const CONTINUE_SESSION_BUTTON_TIMER_MODAL = 'continueSession';
export const LOG_OUT_BUTTON_TIMER_MODAL = 'logOut';

@Injectable({
  providedIn: 'root'
})

export class UserIdleSessionService {

  bc = new BroadcastChannel(USER_IDLE_SESSION);
  bcTimerModal = new BroadcastChannel(USER_IDLE_SESSION_TIMER_MODAL);
  private activityEvents$: Observable<unknown>;
  private destroyUserIdleSession$ = new Subject<void>();
  private isEventsListenersOn = false;
  private lastUserSessionActivity: number;
  private logoutCallBack: Function;
  private maxIdleSessionTimeoutMiliSecs: number | null;
  private maxIdleSessionTimeoutMins = this.profileService.getProfileCompany()?.company_configurations?.user_security?.session_idle_timeout_mins;
  private resetIdleSessionTimeout$ = new Subject<void>();
  private stopTimer$ = new Subject<void>();
  private timer$: Observable<object>;
  private userIdleSessionDocumentEvents = ['click', 'contextmenu', 'keyup', 'mousemove', 'scroll', 'wheel', 'touchmove'] as const;
  private userIdleSessionWindowEvents = ['popstate'] as const;

  constructor(
    private ngZone: NgZone,
    private profileService: ProfileService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {
    this.maxIdleSessionTimeoutMiliSecs = this.maxIdleSessionTimeoutMins ? this.maxIdleSessionTimeoutMins * 60 * 1000 : null;
  }

  setupUserIdleSession(logoutCallBack?: Function): void {
    this.logoutCallBack = logoutCallBack ?? (() => this.authService.logout());
    const isAuthenticated = this.authService.isAuthenticated();
    const isInactiveUserSession = this.isInactiveUserSession();
    if (isAuthenticated && isInactiveUserSession) {
      this.logoutCallBack();
    } else {
      if (this.maxIdleSessionTimeoutMiliSecs) {
        this.setupBroadcastUserIdleSession();
        this.startUserIdleSession();
        this.resetIdleSessionTimeout();
        this.setupUserActivityEvents();
        const userIdleSessionTimer = localStorage.getItem(USER_IDLE_SESSION_TIMER_LOCAL_STORAGE);
        if (userIdleSessionTimer === 'true') {
          this.bcTimerModal.postMessage(CONTINUE_SESSION_BUTTON_TIMER_MODAL);
          localStorage.removeItem(USER_IDLE_SESSION_TIMER_LOCAL_STORAGE);
        }
      }
    }
  }

  destroyUserIdleSession(): void {
    this.stopTimer();
    this.destroyUserIdleSession$.next();
    this.destroyUserIdleSession$.complete();
  }

  private isInactiveUserSession(): boolean {
    const lastUserSessionActivity = localStorage.getItem(LAST_USER_SESSION_ACTIVITY_LOCAL_STORAGE);
    if (this.maxIdleSessionTimeoutMiliSecs && !isNullOrUndefined(lastUserSessionActivity)) {
      const nowMiliseconds = new Date().getTime();
      const isExceededIdleSession = (nowMiliseconds - parseInt(lastUserSessionActivity)) > this.maxIdleSessionTimeoutMiliSecs;
      return isExceededIdleSession;
    }
    return false;
  }

  private setupUserActivityEvents(): void {
    const documentEvents$ = this.userIdleSessionDocumentEvents.map(event => fromEvent(document, event));
    const windowEvents$ = this.userIdleSessionWindowEvents.map(event => fromEvent(window, event));
    this.activityEvents$ = merge(
      ...documentEvents$,
      ...windowEvents$
    );

    this.isEventsListenersOn = true;
    this.activityEvents$.pipe(filter(event => !!event && this.isEventsListenersOn), throttleTime(1000), takeUntil(this.destroyUserIdleSession$)).subscribe((event) => {
      this.resetIdleSessionTimeout();
    });
  }

  private startUserIdleSession(): void {
    this.ngZone.runOutsideAngular(() => {
      this.setLastUserSessionActivity();
      this.timer$ = this.resetIdleSessionTimeout$.pipe(
        switchMap(() => timer(0, 1000).pipe(
          takeUntil(this.stopTimer$ || this.destroyUserIdleSession$),
          map(() => this.handleIdleSessionTime())
        ))
      );

      this.timer$.pipe(takeUntil(this.destroyUserIdleSession$)).subscribe({
        next: (time) => {
          const remainingTimeInSeconds = Math.round(time['remainingIdleSessionTime'] / 1000);
          const remainingSecondsToShowTimerModal = 60;
          if (this.maxIdleSessionTimeoutMiliSecs && remainingTimeInSeconds <= remainingSecondsToShowTimerModal) {
            this.stopTimer();
            this.isEventsListenersOn = false;
            this.displayUserIdleSessionTimerModal();
          }
        }
      });
    });
  }

  private stopTimer(): void {
    this.stopTimer$.next();
  }

  private handleIdleSessionTime(): { remainingIdleSessionTime: number } {
    const nowMiliseconds = new Date().getTime();
    const remainingIdleSessionTime = this.maxIdleSessionTimeoutMiliSecs - (nowMiliseconds - this.lastUserSessionActivity);
    return { remainingIdleSessionTime: remainingIdleSessionTime };
  }

  private resetIdleSessionTimeout(): void {
    this.isEventsListenersOn = true;
    this.setLastUserSessionActivity();
    this.bc.postMessage(this.lastUserSessionActivity);
    this.resetIdleSessionTimeout$.next();
  }

  private setLastUserSessionActivity(): void {
    this.lastUserSessionActivity = new Date().getTime();
    localStorage.setItem(LAST_USER_SESSION_ACTIVITY_LOCAL_STORAGE, this.lastUserSessionActivity.toString());
  }

  private displayUserIdleSessionTimerModal(): void {
    const timerCountdownValue = 60000;
    const timerTitle = this.translate.instant('resources.user_idle_session.timer_modal.title');
    const timerDescription = this.translate.instant('resources.user_idle_session.timer_modal.description');
    const timerContinueSessionBtn = this.translate.instant('resources.user_idle_session.timer_modal.buttons.continue_session');
    const timerLogOutBtn = this.translate.instant('resources.user_idle_session.timer_modal.buttons.log_out');
    this.confirmationService.displayTimerAlertWithButtons(
      timerTitle, timerDescription, USER_IDLE_SESSION_TIMER_MODAL, timerCountdownValue, timerContinueSessionBtn, timerLogOutBtn
    ).then(data => {
      if (data?.value) {
        this.resetIdleSessionTimeout();
        this.bcTimerModal.postMessage(CONTINUE_SESSION_BUTTON_TIMER_MODAL);
        localStorage.removeItem(USER_IDLE_SESSION_TIMER_LOCAL_STORAGE);
      } else {
        this.bcTimerModal.postMessage(LOG_OUT_BUTTON_TIMER_MODAL);
        localStorage.removeItem(USER_IDLE_SESSION_TIMER_LOCAL_STORAGE);
        this.stopTimer();
        this.logoutCallBack();
      };
    }).catch(() => {});
    localStorage.setItem(USER_IDLE_SESSION_TIMER_LOCAL_STORAGE, 'true');
  }

  private setupBroadcastUserIdleSession(): void {
    this.bc.onmessage = ((event: MessageEvent) => {
      if (event) { this.lastUserSessionActivity = event.data; }
    });

    this.bcTimerModal.onmessage = ((event: MessageEvent) => {
      if (event?.data) {
        const userIdleSessionTimerModal = document?.getElementById(USER_IDLE_SESSION_TIMER_MODAL);
        if (userIdleSessionTimerModal) {
          const actionClass = event.data === CONTINUE_SESSION_BUTTON_TIMER_MODAL ? '.swal2-actions .swal2-confirm' : '.swal2-actions .swal2-cancel';
          const action = userIdleSessionTimerModal?.querySelector(actionClass) as HTMLButtonElement;
          if (action) {
            action.click();
          }
        }
      }
    });
  }
}
