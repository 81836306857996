import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from '../../condition.service';
import { QuestionBase } from '../../../../models/forms/question-base';
import { MultiSelectQuestion } from '../../../../models/forms/question-multiselect';
import { TranslateService } from '@ngx-translate/core';
import { multiselectPresenceValidator } from '../../../validations.service';
import { UntypedFormGroup } from '@angular/forms';
import { OperatorsService } from '../../common/operators.service';
import { defaultParseFormValuesToAPI, getParseIntValue, getParseOperatorValue, getParseSingleSelectOperatorIdValue, getParseValue, parseParamsValues } from '../../utils/common.utils';
import { TextboxQuestion } from '../../../../models/forms/question-textbox';
import { AvailableScoreCondition, AvailableScoreConditionForm } from '../../../../models/segments/conditions/available-score-condition';
import { getFormGroupValidations } from './validations/form.validations';

@Injectable()
export class AvailableScoreConditionService  implements SegmentConditionProvider {

  inputs: QuestionBase<unknown>[];
  customValidators = [(control: UntypedFormGroup) => getFormGroupValidations(control)];

  private operatorOpts = this.operatorsService.getAllNumericOperators();

  constructor(
    private translate: TranslateService,
    private operatorsService: OperatorsService
  ) { }

  getInputs(params?: AvailableScoreConditionForm): QuestionBase<unknown>[] {
    const inputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'operator',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorOpts,
        required: true,
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        parseValue: getParseSingleSelectOperatorIdValue(this.operatorOpts),
        getValue: getParseValue(),
        customValidators: [multiselectPresenceValidator]
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'value_1',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        type: 'number',
        step: 1,
        required: true,
        parseValue: getParseIntValue(),
        getValue: getParseValue(false)
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'value_2',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        type: 'number',
        step: 1,
        required: true,
        parseValue: getParseIntValue(),
        getValue: getParseValue(false)
      })
    ];

    parseParamsValues(params, inputs);
    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI(params: AvailableScoreConditionForm): AvailableScoreCondition {
    const parsedValuesObj: AvailableScoreCondition = defaultParseFormValuesToAPI(params, this.inputs);
    if (parsedValuesObj?.operator !== 'bt') { delete parsedValuesObj.value_2; }
    return parsedValuesObj;
  }

  prepareFormValuesFromAPI(params: AvailableScoreCondition): AvailableScoreConditionForm {
    const parseValues = {
      operator: getParseOperatorValue(this.operatorOpts),
      value_1: getParseValue(false),
      value_2: getParseValue(false)
    };
    const parsedValuesObj = {};
    Object.keys(params).forEach( key => { parsedValuesObj[key] = parseValues[key](params[key]); });
    return parsedValuesObj;
  }
}
