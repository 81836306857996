import { Component, OnInit, OnDestroy } from '@angular/core';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { ReportIncrementalService } from './report-incremental.service';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { Subscription } from 'rxjs';
import { getCurrencySymbol } from '@angular/common';
import { ProfileService } from '../../../../../profiles/profile.service';

@Component({
  selector: 'app-edit-incremental',
  templateUrl: './edit-incremental.component.html',
  styleUrls: ['./edit-incremental.component.scss'],
  providers: [ReportIncrementalService]
})

export class EditIncrementalComponent implements OnInit, OnDestroy {

  public inputs: QuestionBase<any>[];
  public campaignFormsIds: string[] = [];
  public reportIncrementalForm: UntypedFormGroup;
  public id: number;
  public historyId: number;
  public route$: Subscription;
  public getReport$: Subscription;
  public sendData$: Subscription;
  public updateData$: Subscription;
  public campaignData: object;
  public formValues: any[] = [];
  public updateMode: boolean;
  public loading: boolean;
  public currencySymbol: string;

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private reportIncrementalService: ReportIncrementalService,
    private profileService: ProfileService
  ) {
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
  }

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy() {
    if (this.route$) { this.route$.unsubscribe(); }
    if (this.getReport$) { this.getReport$.unsubscribe(); }
    if (this.sendData$) { this.sendData$.unsubscribe(); }
    if (this.updateData$) { this.updateData$.unsubscribe(); }
  }

  public closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  public getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  public hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.reportIncrementalForm, formKey, value);
  }

  public hasFormKey(formKey: string): boolean {
    return this.qcs.hasFormKey(this.reportIncrementalForm, formKey);
  }

  public addCampaign(params) {
    const key = `campaign_${this.campaignFormsIds.length}`;
    this.campaignFormsIds.push(key);
    this.formValues.push(params);
  }

  public sendData() {
    const campaignToSend = this.campaignFormsIds ? this.campaignFormsIds.map( el => this.parseCampaignToSend(this.reportIncrementalForm.get(el).value) ) : [];

    const incrementalOptions = {
      name: this.reportIncrementalForm.value.name,
      report_id: this.id,
      type: 'ReportIncremental',
      configuration: {
        affectation: this.reportIncrementalForm.value.affectation && this.reportIncrementalForm.value.affectation.length > 0 ? this.reportIncrementalForm.value.affectation[0].id : null,
        after_date_from: this.reportIncrementalForm.value.after_date_from,
        before_date_from: this.reportIncrementalForm.value.before_date_from,
        after_date_to: this.reportIncrementalForm.value.after_date_to,
        before_date_to: this.reportIncrementalForm.value.before_date_to,
        analysis_type: this.reportIncrementalForm.value.analysis_type && this.reportIncrementalForm.value.analysis_type.length > 0 ? this.reportIncrementalForm.value.analysis_type[0].id : null,
        campaigns: campaignToSend,
        feature: this.reportIncrementalForm.value.affectation && this.reportIncrementalForm.value.affectation.length > 0 && this.reportIncrementalForm.value.affectation[0].id === 'attribute' && this.reportIncrementalForm.value.features ? this.reportIncrementalForm.value.features.map(item => item.rawElement.pk) : [],
        prediction_coupon_discount: this.reportIncrementalForm.value.prediction_coupon_discount,
        prediction_percentage_redemption: this.reportIncrementalForm.value.prediction_percentage_redemption
      }
    };

    if (campaignToSend) {
      incrementalOptions.configuration['campaigns'] = campaignToSend;
    }

    if ( this.historyId ) {
      this.loading = true;
      this.updateData$ = this.reportIncrementalService.updateData(incrementalOptions, this.id, this.historyId).subscribe(
        response => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.reports.form.warnings.report_update_title'),
            this.translate.instant('resources.reports.form.warnings.report_update_text')
          ).catch(() => {});
          this.loading = false;
          this.returnToList();
        },
        errorData => {
          if (errorData.error?.errors?.length > 0) {
            this.confirmationService.displayErrorAlert('Error', errorData.error.error);
            this.paintErrorsInForm(this.reportIncrementalForm, errorData.error.errors);
          }
          this.loading = false;
        }
      );
      } else {
        this.loading = true;
      this.sendData$ = this.reportIncrementalService.sendData(incrementalOptions, this.id).subscribe(
        response => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.reports.form.warnings.report_success_title'),
            this.translate.instant('resources.reports.form.warnings.report_success_text')
          ).catch(() => {});
          this.loading = false;
          this.returnToList();
        },
        errorData => {
          if (errorData.error?.errors?.length > 0) {
            this.confirmationService.displayErrorAlert('Error', errorData.error.error);
            this.paintErrorsInForm(this.reportIncrementalForm, errorData.error.errors);
          }
          this.loading = false;
        }
      );
    }
  }

  public setMode() {
    if (this.router.url.indexOf('(modal:update') >= 0 ) {
      this.updateMode = true;
    }
  }

  public returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: `update/reports/${this.id}` } }]).catch(() => {});
    } else {
      this.router.navigate(['/offers-personalization/dashboard/reports']).catch(() => {});
    }
  }

  public deleteCampaignHandler(index) {
    this.campaignFormsIds.splice(index, 1);
  }

  private parseCampaignToSend(formCampaign): {campaign_id: number[], apply: number, id: string} {
    const campaignId = formCampaign && formCampaign.campaigns.length > 0 ? formCampaign.campaigns[0].id : [];
    const id = formCampaign.history && formCampaign.history.length > 0 ?  formCampaign.history[0].rawElement.id : 'next';

    return {
      campaign_id: [campaignId],
      apply: 0,
      id: id
    };
  }

  private getParams() {
    this.route$ = this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id') && params.hasOwnProperty('history_id')) {
        this.id = params.id;
        this.historyId = params.history_id;
        this.getReportDetailById();
      } else {
        this.id = params.id;
        this.inputs = this.reportIncrementalService.setInputs({});
        this.reportIncrementalForm = this.qcs.toFormGroup(this.inputs);
      }
    });
  }

  private getReportDetailById() {
    this.getReport$ = this.reportIncrementalService.fetchReportDetailById(this.id, this.historyId).subscribe(
      data => {
        this.campaignData = data;
        this.inputs = this.reportIncrementalService.setInputs(data);
        this.reportIncrementalForm = this.qcs.toFormGroup(this.inputs);
        this.readCampaignsFromApi();
      }
    );
  }

  private readCampaignsFromApi() {
    this.campaignData['configuration'].campaigns.map(el => {
      this.addCampaign(el);
    });
  }

  private paintErrorsInForm(form: UntypedFormGroup, errorsArray: {field: string}[]) {
    this.qcs.paintErrorsInForm(this.inputs, form, errorsArray);
  }

  public anyCampaignWithNextValue(): boolean {
    const arrayToPushDelivery =  [];
    let result: boolean;
    this.campaignFormsIds.forEach(el => {

      const a = this.reportIncrementalForm.get(el);

      if (a?.value) {
        arrayToPushDelivery.push(a.value['prediction_next_delivery_'+el]);
        result = arrayToPushDelivery.some(item => item === true);
      }
    });

    return result;
  }

}
