<div class="card" style="cursor: pointer;" [routerLink]="[link]">
  <!-- Icon -->
  <!-- <div class="card__icon" [style.borderColor]="iconColor">
      <em class="{{ icon }}" style="font-size: 36px" [style.color]="iconColor"></em>
  </div> -->
  <!-- Switch toggle -->
  <div class="card__toggle"></div>
  <div class="card__content">
      <!-- Title -->
      <h4 class="card__title text-bold" [ngStyle]="{'color': iconColor}">{{ title }}</h4>
      <!-- Description -->
      <p class="card__text text-dark">{{ text }}</p>
  </div>
  <div class="card__bottom-decor">
      <!-- Action -->
      <div class="action">
          <em class="pi pi-link" style="font-size: 20px; cursor: pointer;" [ngStyle]="{'color': iconColor}"></em>
      </div>
  </div>
</div>