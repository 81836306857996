import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessageHistoriesService } from '../message-histories.service';
import { MessagesService } from '../messages.service';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { TranslateService } from '@ngx-translate/core';
import { MultiselectDataSource } from '../../../../shared/components/multiselect/multiselect';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-show-messages',
  templateUrl: './show-messages.component.html',
  styleUrls: ['./show-messages.component.css'],
  providers: [MessageHistoriesService]
})
export class ShowMessagesComponent implements OnInit, OnDestroy {

  public motivator: any;
  public element: any;
  public apiEndPoint: string;
  public id: number;
  public isModal: boolean;
  public subs$: Subscription[];
  public form: UntypedFormGroup;
  public inputs: QuestionBase<any>[];

  @Output('deliveryEmitter') deliveryEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private messagesService: MessagesService,
    private messageHistoriesService: MessageHistoriesService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private qcs: QuestionControlService,
    private translate: TranslateService
  ) {
    this.subs$ = [];
  }

  ngOnInit() {
    registerLocaleData(es);
    this.isModal = this.router.url.indexOf('(modal:show/messages/') >= 0;
    this.getParams();
  }

  ngOnDestroy() {
    if (this.subs$.length > 0) { this.subs$.forEach(s$ => s$.unsubscribe()); }
  }

  getParams() {
    const route$ = this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'messages';
        this.messageHistoriesService.setMessage(this.id);
        this.getMessage();
        this.setForm();
      }
    });
    this.subs$.push(route$);
  }

  setForm() {
    const inputs = [
      new MultiSelectQuestion({
        key: 'message_histories',
        cssClasses: 'form-control input-default',
        label: this.translate.instant('resources.messages.fields.delivery'),
        settings: {
          text: this.translate.instant('resources.messages.fields.next_delivery'),
          singleSelection: true,
          showCheckbox: false,
          enableSearchFilter: false
        },
        dataSource: this.messageHistoriesService
      })
    ];
    this.form = this.qcs.toFormGroup(inputs);
    this.inputs = inputs;
  }

  getMessage() {
    const message$ = this.messagesService.getMessagesById(this.id).subscribe(
      messageData => {
        message$.unsubscribe();
        this.motivator = messageData;
        this.element = messageData;
      },
      errorData => {
        message$.unsubscribe();
        this.confirmationService.displayErrorAlert('Error', errorData.error.error);
      }
    );
    this.subs$.push(message$);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  goToLocation(id) {
    if ( this.isModal ) {
      window.open(`#/data-warehouse/locations/locations/${id}`);
    } else {
      this.router.navigate([{ outlets: { modal: `show/locations/${id}`}}]).catch(() => {});
    }
  }

  handleDeliveryChanged(selectedDelivery: MultiselectDataSource) {
    if (selectedDelivery) {
      this.element = selectedDelivery.rawElement;
      this.deliveryEmitter.emit(['', { outlets: { modal: ['update', 'messages', this.motivator.id, 'histories', this.element.id] } }]);
    } else {
      this.element = this.motivator;
      this.deliveryEmitter.emit(['', { outlets: { modal: ['update', 'messages', this.motivator.id] } }]);
    }
  }
}
