import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ProfileService } from '../../../../profiles/profile.service';
import { FeatureFlagsService } from './../../../../shared/services/feature-flags.service';
import { CustomersService } from '../../../../resources/analytics/customers/customers.service';
import { Customer } from '../../../../shared/models/customers/customer';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';

@Component({
  selector: 'app-show-profiles',
  templateUrl: './show-profiles.component.html',
  styleUrls: ['./show-profiles.component.css']
})

export class ShowProfilesComponent implements OnInit, OnDestroy {

  customer: Customer;
  customerHasAssociations = false;
  flags = this.featureFlags.flags;
  id: number;
  roleSlug = this.profileService.getStoredUserRole();
  slug = 'summary';

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService,
    private featureFlags: FeatureFlagsService,
    private customerService: CustomersService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.initialize();
    this.refreshComponentSubscription();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  refresh(): void {
    this.slug = this.route.firstChild.snapshot.data['title'];
    this.selectTab(this.slug);
  }

  getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.customerService.getCustomerById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
          next: (customer: HttpResponse<object>) => this.customer = new Customer(customer),
          error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData),
        });
        this.customerService.getAssociationsByCustomerId(this.id).pipe(takeUntil(this.destroy$)).subscribe({
          next: (associations: HttpResponse<object>) => this.customerHasAssociations = !!associations['list']?.length,
          error: () => this.customerHasAssociations = false
        });
      }
    });
  }

  isHiddenByRole(roleSlugsToHide: string[]): boolean {
    return !!roleSlugsToHide.includes(this.roleSlug);
  }

  selectTab(slug: string): void {
    this.slug = slug;
  }

  private initialize(): void {
    this.refresh();
    this.getParams();
    this.routerEventsSubscription();
  }

  private routerEventsSubscription(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.refresh();
      }
    });
  }

  private refreshComponentSubscription(): void {
    this.customerService.refreshComponent$.subscribe((keyCmp: string) => {
      if (keyCmp === 'customerSummaryTab') {
        this.initialize();
      }
    });
  }
}
