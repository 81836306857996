import { Injectable } from '@angular/core';
import { QuestionBase } from '../../../../models/forms/question-base';
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectQuestion } from '../../../../models/forms/question-multiselect';
import { DynamicDateQuestion } from '../../../../models/forms/question-dynamic-date';
import { DateService } from '../../../date.service';
import { SegmentConditionProvider } from '../../condition.service';
import { UntypedFormGroup } from '@angular/forms';
import { multiselectPresenceValidator } from '../../../validations.service';
import { defaultParseFormValuesToAPI, getParseDynamicDateValue, getParseObjectKeysIdValue, getParseOperatorValue, getParseSingleSelectOperatorIdValue, getParseValue, parseParamsValues } from '../../utils/common.utils';
import { OperatorsService } from '../../common/operators.service';
import { LastPurchaseCondition, LastPurchaseConditionForm } from '../../../../models/segments/conditions/last-purchase-condition';
import { getFormGroupValidations } from './validations/form.validations';

@Injectable()
export class LastPurchaseConditionService implements SegmentConditionProvider {

  inputs: QuestionBase<unknown>[];
  customValidators = [(control: UntypedFormGroup) => getFormGroupValidations(control)];

  private operatorOpts = this.operatorsService.getNumericOperators();

  constructor(
    private dateService: DateService,
    private translate: TranslateService,
    private operatorsService: OperatorsService
  ) {}

  getInputs(params?: LastPurchaseConditionForm): QuestionBase<unknown>[] {
    const inputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'operator',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorOpts,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        getValue: getParseValue(),
        parseValue: getParseSingleSelectOperatorIdValue(this.operatorOpts),
        customValidators: [multiselectPresenceValidator]
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_value',
        label: this.translate.instant('resources.segment_conditions.fields.date'),
        getValue: getParseDynamicDateValue(this.dateService),
        parseValue: getParseObjectKeysIdValue(),
        required: true,
        hideNoDateOpt: true
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_value_bt',
        label: this.translate.instant('resources.segment_conditions.fields.date_to'),
        getValue: getParseDynamicDateValue(this.dateService),
        parseValue: getParseObjectKeysIdValue(),
        required: true,
        hideNoDateOpt: true
      })
    ];

    parseParamsValues(params, inputs);
    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI(params: LastPurchaseConditionForm): LastPurchaseCondition {
    return defaultParseFormValuesToAPI(params, this.inputs);
  }

  prepareFormValuesFromAPI(params: LastPurchaseCondition): LastPurchaseConditionForm {
    const parseValues = {
      operator: getParseOperatorValue(this.operatorOpts),
      date_value: getParseValue(),
      date_value_bt: getParseValue()
    };
    const parsedValuesObj = {};
    Object.keys(params).forEach(_key => { parsedValuesObj[_key] = parseValues[_key](params[_key]); });
    return parsedValuesObj;
  }
}
