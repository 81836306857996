import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BigqueryjobsService } from '../bigqueryjobs.service';
import { BigqueryJobsStepsBase } from '../../../../shared/models/bigquery-steps/bigquerystep-base';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { Observable } from 'rxjs';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { ScheduledTask } from '../../../../shared/models/bigquery-steps/scheduled-task';
import { StepsComponent } from './steps/steps.component';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-bigquery-jobs',
  templateUrl: './form-bigquery-jobs.component.html',
  styleUrls: ['./form-bigquery-jobs.component.css']
})

export class FormBigqueryJobsComponent implements OnInit, OnDestroy {

  BigqueryJobValues = {
    by_id: this.translateSrv.instant('resources.jobs.pump_cut_conditions.by_id'),
    daily_hard: this.translateSrv.instant('resources.jobs.pump_cut_conditions.daily_hard'),
    daily: this.translateSrv.instant('resources.jobs.pump_cut_conditions.daily'),
    fortnightly: this.translateSrv.instant('resources.jobs.pump_cut_conditions.fornightly'),
    full: this.translateSrv.instant('resources.jobs.pump_cut_conditions.full')
  };

  deletedSteps: BigqueryJobsStepsBase[];
  id: number;
  jobsForm: UntypedFormGroup;
  loading: boolean;
  mapped;
  scheduledTask: ScheduledTask;
  scheduledTaskInputs: QuestionBase<any>[];
  sortedItem1: number;
  sortedItem2: number;
  sortedItemOver: number;
  steps: BigqueryJobsStepsBase[];
  subs$: Subscription[];

  @ViewChild('stepFormComponent') stepFormComponent: StepsComponent;

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private jobsSrv: BigqueryjobsService,
    private confirmationService: ConfirmationService,
    private translateSrv: TranslateService,
    private modalStatusService: ModalStatusService
  ) {
    this.subs$ = [];
    this.steps = [];
    this.mapped = {};
    this.deletedSteps = [];
  }

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.scheduledTaskInputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.jobsForm, formKey, value);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  handleStepSavedEmitter(stepData: {step: BigqueryJobsStepsBase, currentStep: BigqueryJobsStepsBase}) {
    if (stepData.currentStep) {
      this.scheduledTask.steps.splice(stepData.currentStep.sort - 1, 1);
      stepData.step.id = stepData.currentStep.id;
      stepData.step.sort = stepData.currentStep.sort;
      this.scheduledTask.steps.splice(stepData.step.sort - 1, 0, stepData.step);
    } else {
      let sort = 1;
      if (this.scheduledTask.steps.length > 0) {
        sort = this.scheduledTask.steps[this.scheduledTask.steps.length - 1].sort + 1;
      }
      stepData.step.sort = sort;
      this.scheduledTask.steps.push(stepData.step);
    }
  }

  translate(str) {
    return this.BigqueryJobValues[str];
  }

  dragStart(item: number) {
    this.sortedItem1 = item;
  }

  dragOver(item: number) {
    this.sortedItemOver = item;
  }

  dragLeave(item: number) {
    this.sortedItem2 = item;
  }

  dragEnd() {
    if (this.sortedItem1 !== this.sortedItem2) {
      this.onExchange(this.sortedItem1, this.sortedItem2);
    }
    this.sortedItemOver = null;
  }

  editStep(step: BigqueryJobsStepsBase, index: number, htmlElement: HTMLElement) {
    htmlElement.scrollTop = 0;
    this.stepFormComponent.editStep(step, index);
  }

  deleteStep(step: BigqueryJobsStepsBase) {
    this.scheduledTask.steps.splice(step.sort - 1, 1);
    if (!step.id)  {
      this.regenerateSortMap(step.sort - 1);
    } else {
      this.deletedSteps.push(step);
      this.regenerateSortMap();
    }
  }

  ableToSave(): boolean {
    return !this.loading && this.jobsForm.valid && !this.stepFormComponent?.disableAddStepBtn;
  }

  save() {
    let title: string;
    let desc: string;

    const payload = this.getPayload();
    const request = this.getRequestMethod(payload);
    const hasGrantScoresJob = this.scheduledTask.steps.some(step => step.category === 'grant_scores');

    this.loading = true;
    if (this.id) {
      title = this.translateSrv.instant('resources.jobs.messages.update_title');
      desc = this.translateSrv.instant('resources.jobs.messages.update_desc');
    } else {
      title = this.translateSrv.instant('resources.jobs.messages.create_title');
      desc = this.translateSrv.instant('resources.jobs.messages.create_desc');
    }

    if(hasGrantScoresJob) {
      this.confirmationService.displayConfirmationAlert(
        this.translateSrv.instant('common.messages.are_you_sure'),
        this.translateSrv.instant('resources.jobs.messages.grant_points_warning')
      ).then(data => {
        if (data.hasOwnProperty('value') && data.value) {
          this.triggerRequest(request, title, desc);
        } else {
          this.loading = false;
        }
      }).catch(() => {});
    } else {
      this.triggerRequest(request, title, desc);
    }
  }

  private triggerRequest(request: Observable<any>, successTitle: string, successDesc: string) {
    const save$ = request.subscribe(
      () => {
        this.loading = false;
        this.modalStatusService.modalStatus.emit();
        this.confirmationService.displaySuccessAlert(successTitle, successDesc).catch(() => {});
        this.closeModal();
      },
      (errData: HttpErrorResponse) => {
        this.loading = false;
        this.confirmationService.displayErrorAlert(this.translateSrv.instant('common.error'), errData.error.error);
      }
    );
    this.subs$.push(save$);
  }

  private getRequestMethod(payload: object): Observable<any> {
    if (this.id) {
      return this.jobsSrv.updateScheduledTask(payload, this.id);
    } else {
      return this.jobsSrv.createScheduledTask(payload);
    }
  }

  private getParams() {
    const route$ = this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getJobsById();
      } else {
        this.initScheduledTask({});
      }
    });
    this.subs$.push(route$);
  }

  private getJobsById() {
    const scheduledTask$ = this.jobsSrv.getBigqueryJobsById(this.id).subscribe(
      (data: HttpResponse<object>) => {
        this.initScheduledTask(data);
      },
      (errData: HttpErrorResponse) => this.confirmationService.displayErrorAlert(this.translate('common.error'), errData.error.error)
    );
    this.subs$.push(scheduledTask$);
  }

  private initScheduledTask(data: object) {
    this.scheduledTask = new ScheduledTask(data);
    this.scheduledTaskInputs = this.jobsSrv.getInputs(this.scheduledTask);
    this.jobsForm = this.qcs.toFormGroup(this.scheduledTaskInputs);
    this.jobsForm.setValidators(this.getValidators());
  }

  private getValidators(): ValidatorFn[] {
    const ensureExecutionDayIfVisible = ((_ctrl: AbstractControl) => {
      const executionCtrl = _ctrl.get('execution');
      const executionDayCtrl = _ctrl.get('execution_day');
      const executionValue = executionCtrl.value && executionCtrl.value.length > 0 && executionCtrl.value[0].id;
      const executionDayValue = executionDayCtrl.value;
      if (executionValue && ['monthly', 'quarterly', 'biannualy'].includes(executionValue) && !executionDayValue) {
        return {executionDayRequired: true};
      }
      return null;
    });

    const ensureExecutionHourIfVisible = ((_ctrl: AbstractControl) => {
      const executionCtrl = _ctrl.get('execution');
      const executionHourCtrl = _ctrl.get('execution_hour');
      const executionValue = executionCtrl.value && executionCtrl.value.length > 0 && executionCtrl.value[0].id;
      const executionHourValue = executionHourCtrl.value && executionHourCtrl.value.length > 0 && executionHourCtrl.value[0].id;
      if (executionValue && ['daily', 'weekly', 'fortnightly', 'monthly', 'quarterly', 'biannually'].includes(executionValue) && !executionHourValue) {
        return {executionHourRequired: true};
      }
      return null;
    });

    ['execution_day', 'execution_hour'].forEach((key) => this.jobsForm.get(key).setValidators([]));

    return [
      ensureExecutionDayIfVisible,
      ensureExecutionHourIfVisible
    ];
  }

  private regenerateSortMap(startIndex?) {
    if (!startIndex) { startIndex = 0; }
    let accumulatedIndex = 0;

    const ary = this.scheduledTask.steps;
    for (let idx = startIndex; idx < ary.length; idx++) {
      if (!ary[idx]['_destroy']) {
        ary[idx].sort = accumulatedIndex + 1;
        accumulatedIndex++;
        this.mapped[ary[idx].sort] = ary[idx];
        if (this.mapped[ary[idx].sort + 1]) {
          this.mapped[ary[idx].sort].collapsed = this.mapped[ary[idx].sort + 1].collapsed;
        }
      }
    }

    for (let idx = accumulatedIndex; idx < ary.length; idx++) {
      this.mapped[idx + 1] = {};
    }
  }

  private recalculatePositions() {
    for (const i in this.scheduledTask.steps) {
      if (i) {
        this.scheduledTask.steps[i]['sort'] = Number(i) + 1;
      }
    }
  }

  private getPayload(): Object {
    const name = this.jobsForm.value.name;
    const execution = this.jobsForm.value.execution && this.jobsForm.value.execution[0] ? this.jobsForm.value.execution[0].id : null;
    const executionDay = this.jobsForm.value.execution_day ? parseFloat(this.jobsForm.value.execution_day) : null;
    const executionHour = this.getExecutionHourValue();

    const payload = {
      name: name,
      execution: execution,
      execution_day: executionDay,
      execution_hour: executionHour,
      bigquery_job_steps: this.getScheduledTaskStepsPayload()
    };

    Object.keys(payload).forEach((key) => (payload[key] === null) && delete payload[key]);
    return payload;
  }

  private getScheduledTaskStepsPayload(): object[] {
    let bigqueryJobSteps = this.scheduledTask.steps.map(step => step.getPayload());
    if (this.deletedSteps.length > 0) {
      const processedDeletedSteps = this.deletedSteps.map(step => {
        const stepPayload = step.getPayload();
        stepPayload['_destroy'] = 1;
        return stepPayload;
      });
      bigqueryJobSteps = [...bigqueryJobSteps, ...processedDeletedSteps];
    }
    return bigqueryJobSteps;
  }

  private getExecutionHourValue() {
    if (this.jobsForm.value.execution_hour && this.jobsForm.value.execution_hour[0] &&  this.jobsForm.value.execution_hour[0].id) {
      return this.jobsForm.value.execution_hour[0].id;
    }
    return null;
  }

  private onExchange(id1: number, id2: number) {
    let item1;
    let item2;
    this.scheduledTask['steps'].map(item => {
      if (item.sort === id1) {
        item1 = item;
      }

      if (item.sort === id2) {
        item2 = item;
      }
    });

    for (const i in this.scheduledTask['steps']) {
      if (this.scheduledTask['steps'][i]) {
        if (this.scheduledTask['steps'][i].sort === id1) {
          this.scheduledTask['steps'][i] = item2;
        } else if (this.scheduledTask['steps'][i].sort === id2) {
          this.scheduledTask['steps'][i] = item1;
        }
      }
    }
    this.recalculatePositions();
  }
}
