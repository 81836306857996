<div class="modal-backdrop fade in" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="mrg-btm-5">{{'resources.legend.title' | translate}}</h4>
        <button class="modal-close" (click)="closeModal()">
          <i class="ti-close"></i>
        </button>
      </div>

      <div class="modal-body background-gray">
        <div class="row">
          <div class="col-md-6">
            <div class="card height-100">
              <div class="card-block">
                <div class="card-heading no-pdd">
                  <h4 class="text-dark">{{'resources.legend.fields.periods.title' | translate}}</h4>
                </div>
                <div class="table-overflow">
                  <table class="table table-hover" aria-hidden="true" *ngIf="periods">
                    <tbody>
                      <tr>
                        <th>{{'resources.legend.fields.periods.fields.actual' | translate}}</th>
                        <td>{{'resources.legend.fields.periods.fields.from' | translate}} {{periods['0'].period_dates[0] | date: 'dd/MM/yyyy'}} {{'resources.legend.fields.periods.fields.to' | translate}} {{periods['0'].period_dates[1] | date: 'dd/MM/yyyy'}}</td>
                      </tr>
                      <tr>
                        <th>{{'resources.legend.fields.periods.fields.last' | translate}}</th>
                        <td>{{'resources.legend.fields.periods.fields.from' | translate}}  {{periods['-1'].period_dates[0] | date: 'dd/MM/yyyy'}}  {{'resources.legend.fields.periods.fields.to' | translate}}  {{periods['-1'].period_dates[1] | date: 'dd/MM/yyyy'}}</td>
                      </tr>
                      <tr>
                        <th>{{'resources.legend.fields.periods.fields.prev' | translate}}</th>
                        <td>{{'resources.legend.fields.periods.fields.from' | translate}}  {{periods['-2'].period_dates[0] | date: 'dd/MM/yyyy'}}  {{'resources.legend.fields.periods.fields.to' | translate}}  {{periods['-2'].period_dates[1] | date: 'dd/MM/yyyy'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card height-100">
              <div class="card-block">
                <div class="card-heading no-pdd">
                  <h4 class="text-dark">{{'resources.legend.fields.activity' | translate}}</h4>
                </div>
                <div class="table-overflow" *ngIf="activitySegment">
                  <table class="table table-hover" aria-hidden="true">
                    <tbody>
                      <tr *ngFor="let segment of activitySegment.list">
                        <td class="text-bold">
                          {{segment.name}}
                          <span *ngIf="segment.description" class="pull-right">
                            <i class="font-size-14 ti-info-alt"
                              tooltipPosition="bottom"
                              pTooltip="{{segment.description}}">
                            </i>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card height-100">
              <div class="card-block">
                <div class="card-heading no-pdd">
                  <h4 class="text-dark">{{'resources.legend.fields.by_max_spent' | translate}}</h4>
                </div>
                <div class="table-overflow">
                  <p>{{'resources.legend.fields.max_spent_info' | translate}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row mrg-top-15">

          <div class="col-md-6">
            <div class="card height-100">
              <div class="card-block">
                <div class="card-heading no-pdd">
                  <h4 class="text-dark">{{'resources.legend.fields.title_segment_spend' | translate}}</h4>
                </div>
                <div class="table-overflow" *ngIf="segmentSales">
                  <table class="table table-hover" aria-hidden="true">
                    <tbody>
                      <tr *ngFor="let sale of segmentSales.list">
                        <td><strong>{{sale.name}}</strong></td>
                        <td>{{sale.description}}</td>
                        <td>{{legendEvaluation}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card height-100">
              <div class="card-block">
                <div class="card-heading no-pdd">
                  <h4 class="text-dark">{{'resources.legend.fields.buyers_types' | translate}}</h4>
                </div>
                <div class="table-overflow">
                  <p>{{'resources.legend.fields.buyers_types_info' | translate}}</p>
                </div>
                <table class="table table-hover" aria-hidden="true">
                  <tbody>
                    <tr>
                      <th>{{'resources.legend.fields.hb' | translate}}</th>
                      <td>{{'resources.legend.fields.hb_info' | translate}}</td>
                    </tr>
                    <tr>
                      <th>{{'resources.legend.fields.lb' | translate}}</th>
                      <td>{{'resources.legend.fields.lb_info' | translate}}</td>
                    </tr>
                    <tr>
                      <th>{{'resources.legend.fields.np' | translate}}</th>
                      <td>{{'resources.legend.fields.np_info' | translate}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
