import { Component, ViewChild, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableComponent } from '../../../shared/components/data-table/data-table.component';
import { DataTableConfiguration } from '../../../shared/components/data-table/data-table-cfg';

@Component({
  selector: 'app-tab-history',
  templateUrl: './tab-history.component.html',
  styleUrls: ['./tab-history.component.css']
})
export class TabHistoryComponent implements OnInit {

  public dataTableConfig: DataTableConfiguration;

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  constructor(public translate: TranslateService) { }

  ngOnInit() {
    this.setDataTableConfig();
  }

  onFilterHandler(filterFormValue) {
    this.dataTable.onFilter(filterFormValue);
  }

  setDataTableConfig() {
    this.dataTableConfig = {
      isActive: true,
      tokenPagination: false,
      rows: [],
      requestData: {
        apiEndPoint: 'synchros',
        pageNumber: 0,
        sorting: {
          byProp: null,
          direction: null
        },
        filtering: {},
        numberPerPage: 10
      },
      rowActions: [
        {
          name: this.translate.instant('resources.synchros.row_actions.details'),
          icon: 'eye',
          id: 'details',
          show: (el:object) => el['type'] === 'Synchro'
        }
      ],
      tableActions: [
        {
          name: this.translate.instant('components.table_actions.export_csv'),
          icon: 'ei-export',
          id: 'exportCSV'
        }
      ],
      columns: [
        {
          name: this.translate.instant('resources.synchros.columns.id'),
          prop: 'id'
        },
        {
          name: this.translate.instant('resources.synchros.columns.created_at'),
          prop: 'created_at'
        },
        {
          name: this.translate.instant('resources.synchros.columns.status'),
          prop: 'status'
        },
        {
          name: this.translate.instant('resources.synchros.columns.files_count'),
          prop: 'files_count'
        },
        {
          name: this.translate.instant('resources.synchros.columns.type'),
          prop: 'type'
        }
      ],
      tableMapping: [
        { prop: 'id', type: 'number', apiProp: 'id' },
        { prop: 'created_at', type: 'date', apiProp: 'created_at' },
        { prop: 'status', type: 'icon', apiProp: 'status', badge: {dictionaryKey: 'synchros_histories' }},
        { prop: 'files_count', type: 'number', apiProp: 'files_count' },
        { prop: 'type', type: 'text', apiProp: 'type', getValue: (el:object) => this.getTypeValue(el) }
      ]
    };
  }

  private getTypeValue(el: object) {
    if(el['type'] === 'Synchro') return this.translate.instant('resources.synchros.types.synchro');
    if(el['type'] === 'DatastoreLoad') return this.translate.instant('resources.synchros.types.datastore');
    return el['type'];
  }
}
