import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { MultiselectDataSource, MultiselectDataSourceable } from '../../../shared/components/multiselect/multiselect';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { LocationTaxonomiesService } from './location-taxonomies.service';
import { LocationsService } from './locations.service';
import { RadioQuestion } from '../../../shared/models/forms/question-radio';
import { TranslateService } from '@ngx-translate/core';
import { Option } from '../../../shared/models/common/option';
import { TextareaQuestion } from '../../../shared/models/forms/question-textarea';
import { FileQuestion } from '../../../shared/models/forms/question-file';

@Injectable()
export class LocationsTaxonomyTermsService extends ResourcesService implements MultiselectDataSourceable {

  inputs: QuestionBase<any>[];
  selectedOptsSource = new BehaviorSubject<{selectedIds: string[], key: string}>(null);
  selectedOptsSource$ = this.selectedOptsSource.asObservable();

  constructor(
    http: HttpClient,
    private locationTaxonomiesService: LocationTaxonomiesService,
    private locationsService: LocationsService,
    private translate: TranslateService 
  ) {
    super(http);
  }

  getAll() {
    const requestOptions = {
      apiEndPoint: '/location_taxonomy_terms',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  getLocationTaxonomyTermsById(id: number) {
    return this.getData({ apiEndPoint: `location_taxonomy_terms/${id}` });
  }

  fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'location_taxonomy_terms',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number | string): Observable<object> {
    if (id.toString().indexOf('|') >= 0) {
      return this.getData({ apiEndPoint: `location_taxonomy_terms?order_field=pk&order_sense=asc&pk=${id}` });
    } else {
      return this.getData({ apiEndPoint: `location_taxonomy_terms?order_field=id&order_sense=asc&id=${id}` });
    }
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    const taxonomyName = (element.taxonomy && element.taxonomy.name) ? `(${element.taxonomy.name}) ` : '';
    return new MultiselectDataSource(element.id, `${taxonomyName}${element.name}`, element);
  }

  getInputs(formValues: object): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        required: true
      }),
      new TextboxQuestion({
        key: 'external_id',
        type: 'string',
        cssClasses: 'form-control input-default',
        required: true
      }),
      new MultiSelectQuestion({
        key: 'taxonomy_slug',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        dataSource: this.locationTaxonomiesService,
        required: true
      }),
      new RadioQuestion({
        key: 'dynamic',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.getDynamicOptions(),
        value: !!(Object.keys(formValues)?.length && formValues['dynamic'])
      }),
      new MultiSelectQuestion({
        key: 'location_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locationsService
      }),
      new TextareaQuestion({
        key: 'sql',
        type: 'textarea',
        cssClasses: 'form-control input-default',
        placeholder: this.translate.instant('resources.location_taxonomy_terms.form.sql_placeholder')      
      })
    ];

    Object.keys(formValues).map(
      key => {
        const isDynamicLocations = inputsArray.find(input => input.key === 'dynamic').value;
        if (key === 'name') {
          inputsArray.find(input => input.key === 'name').value = formValues[key];
        } else if (key === 'external_id') {
          const externalIdInput = inputsArray.find(input => input.key === 'external_id');
          externalIdInput.value = formValues[key];
          externalIdInput.disabled = true;
        } else if (key === 'taxonomy_slug') {
          const taxonomySlugInput = inputsArray.find(input => input.key === 'taxonomy_slug');
          taxonomySlugInput.selectedIds = [formValues[key]];
          taxonomySlugInput.disabled = true;
        } else if (key === 'location_ids' && !isDynamicLocations) {
          inputsArray.find( input => input.key === 'location_ids').selectedIds = formValues[key];
        } else if (key === 'dynamic_options') {
          inputsArray.find(input => input.key === 'sql').value = formValues[key].hasOwnProperty('sql') ? formValues[key]['sql'] : null; 
        }
      }
    );

    this.inputs = inputsArray;
    return this.inputs;
  }

  getImportInputs(): QuestionBase<any>[] {
    return [
      new FileQuestion({
        key: 'csv',
        types: ['csv'],
        multiple: false,
        cssClasses: 'form-control input-default',
        getValue: (value) => value,
        required: true
      })
    ];
  }

  getDynamicOptions(): Option[] {
    return [
      { id: false, name: this.translate.instant('resources.location_taxonomy_terms.form.locations')},
      { id: true, name: this.translate.instant('resources.location_taxonomy_terms.form.advanced_criteria')}
    ];
  }

  sendData(payload: object) {
    return this.postResource(payload, 'location_taxonomy_terms');
  }

  updateData(payload: object, id: number) {
    return this.patchResource(payload, `location_taxonomy_terms/${id}`);
  }

  importCsv(filePath: string) {
    return this.postResource({file_path: filePath}, 'location_taxonomy_terms/import_csv');
  }

  refreshSelectedOptsSource(selectedIds: string[], key: string) {
    this.selectedOptsSource.next({selectedIds, key});
  }
}
