import { BigqueryJobsStepsBase } from './bigquerystep-base';

export class SegmentCategoryStep extends BigqueryJobsStepsBase {
  category = 'segment_category';
  segment_category_id: number;

  constructor(options: {} = {}) {
    super(options);
    this.category = options['category'] || 'segment_category';
    this.segment_category_id = options['segment_category_id'] || '';
  }

  public getPayload() {
    const payload = super.getPayload();
    const newValues = {segment_category_id: this.segment_category_id, name: this.name};
    return {...payload, ...newValues};
  }
}
