import { Component, Input } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { ProductsService } from '../../../../../resources/data-warehouse/products/products.service';

@Component({
  selector: 'app-client-categories-condition',
  templateUrl: './client-categories-condition.component.html',
  styleUrls: ['./client-categories-condition.component.css']
})
export class ClientCategoriesConditionComponent {

  @Input('item') item: Condition;
  @Input('inputs') inputs: QuestionBase<any>[] = [];
  @Input('form') form: UntypedFormGroup;

  constructor(private productsService: ProductsService) {}

  handleChanges() {
    const features = this.form.value['feature_ids'];
    if (features.length > 0) {
      this.productsService.setFeatures(features.map(feature => feature.rawElement.pk).join(','));
    } else {
      this.productsService.setFeatures(null);
    }
  }
}
