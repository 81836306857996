import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from '../../../shared/services/resources.service';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../../shared/components/multiselect/multiselect';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CreditTransactionsService extends ResourcesService {

  public inputs: (TextboxQuestion | MultiSelectQuestion)[];

  constructor(http: HttpClient,
              private translate: TranslateService) {
    super(http);
  }

  public getAll() {
    const requestOptions = {
      apiEndPoint: '/credit_transactions',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  public getCreditTransactionsById(id: number) {
    return this.getData({ apiEndPoint: `credit_transactions/${id}` });
  }

  public getActionOptions() {
    return [
      { id: 'in', name: this.translate.instant('resources.credits.fields.in')},
      { id: 'out', name: this.translate.instant('resources.credits.fields.out')}
    ];
  }

  public getKindOptions() {
    return [
      { id: 'sms', name: this.translate.instant('resources.credits.fields.sms')},
      { id: 'email', name: this.translate.instant('resources.credits.fields.email')}
    ];
  }

  public getInputs(formValues: any): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        cssClasses: 'form-control input-default',
        key: 'amount',
        order: 1,
        type: 'number',
      }),
      new MultiSelectQuestion({
        key: 'action',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: this.getActionOptions(),
        order: 2
      }),
      new MultiSelectQuestion({
        key: 'kind',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: this.getKindOptions(),
        order: 3
      }),
      new TextboxQuestion({
        key: 'notes',
        cssClasses: 'form-control input-default',
        order: 4,
        type: 'text',
      })
    ];

    Object.keys(formValues).map(
      key => {
        if (key === 'amount') {
          inputsArray.find( input => input.key === 'amount' ).value = formValues[key];
        } else if (key === 'action') {
          inputsArray.find( input => input.key === 'action' ).value = [
            this.getActionOptions().find( item => item.id === formValues[key])
          ];
        } else if (key === 'kind') {
          inputsArray.find( input => input.key === 'kind' ).value = [
            this.getKindOptions().find( item => item.id === formValues[key])
          ];
        } else if (key === 'notes') {
          inputsArray.find( input => input.key === 'notes' ).value = formValues[key];
        }
      }
    );

    if (Object.keys(formValues).length > 0) {
      inputsArray.find(input => input.key === 'amount').disabled = true;
      inputsArray.find(input => input.key === 'action').disabled = true;
      inputsArray.find(input => input.key === 'kind').disabled = true;
    }

    this.inputs = inputsArray;
    return this.inputs;
  }

  public sendData(creditsData) {
    return this.postResource(creditsData, 'credit_transactions');
  }

  public updateData(creditsData: any, _id: number) {
    return this.patchResource(creditsData, `credit_transactions/${_id}`);
  }


}
