<div class="row">
  <div class="col">
    <app-data-table-filter *ngIf="filters"
                           [questions]="filters"
                           (onSubmitEmitter)="onFilterHandler($event)"
                           [cfg]="{customInputsWidthClass: flags.showPlanDetailCreditBalance ? 'col-md-4' : 'col-md-2'}">
    </app-data-table-filter>
  </div>
  <ng-container *ngIf="flags.showPlanDetailCreditBalance && companyInfo">
    <div class="col-md-3" *ngIf="companyInfo">
      <app-credit-amount-info
        class="card card-default col pdd-btm-35 pdd-horizon-20"
        [icon]="'ei ei-speech-box-text'"
        [titleKey]="'resources.company.credits.titles.sms_credits'"
        [creditAmount]="companyInfo.smsCredits"
        [subtitle]="'resources.company.credits.titles.sms_credits_subtitle' | translate: {
          smsAproxDeliver: (companyInfo.smsAproxDeliverAmount | number: '1.0-0'),
          smsPrefix: companyInfo.smsCreditsPrice[0].prefix }">
      ></app-credit-amount-info>
    </div>
    <div class="col-md-3"*ngIf="companyInfo">
      <app-credit-amount-info
        class="card card-default col pdd-btm-35 pdd-horizon-20"
        [icon]="'ei ei-email'"
        [titleKey]="'resources.company.credits.titles.email_credits'"
        [creditAmount]="companyInfo.emailCredits"
        [subtitle]="'resources.company.credits.titles.email_credits_subtitle' | translate: {
          emailAproxDeliver: (companyInfo.emailAproxDeliverAmount | number: '1.0-0')}">
      ></app-credit-amount-info>
    </div>
  </ng-container>
</div>

<div class="card">
  <div class="pdd-horizon-15 pdd-vertical-15">
    <app-data-table *ngIf="dataTableConfig" [config]="dataTableConfig"></app-data-table>
  </div>
</div>

<router-outlet></router-outlet>
