export const INTEGER_REGEX = /^-?[0-9]+$/;
export const POSITIVE_INTEGER_REGEX = /^\d+$/;

export const DIGITS_REGEX = /\d+/g;
export const UPPERCASE_REGEX = /[A-Z]+/g;
export const SPECIAL_CHARACTERS_REGEX = /[~!@#$%^&*_+=`|\\(){}[\]:;"'<>,.?/-]+/g;
export const EMAIL_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

/**
 * Match string with no blank spaces between characters and start or end of string.
 * Examples invalid strings:
 * 1. ' test'
 * 2. 'test '
 * 3. 'my test works'
 * 4. '  '
 */
export const STRING_NO_BLANK_SPACES_REGEX = /^\S*$/;

/**
 * Match string with no consecutives commas and no comma at start or end.
 * Examples invalid strings:
 * 1. ',test'
 * 2. 'test,'
 * 3. 'test,,test1'
 */
export const STRING_NO_CONSECUTIVE_START_END_COMMA_REGEX = /^[^,]+(?:\,[^,]+)*$/;

/**
 * Match domain string maximum 255 total characters length and maximum 63 characters length per part (ex: loyal.guru).
 * Domain name: alphanumeric characters (ex: loyal).
 * Domain extension: letter characters (ex: guru).
 * No blank spaces, no consecutive dots.
 * No start or end with hyphen or dot.
 * No hyphens in domain extension.
 */
export const DOMAIN_REGEX = /^(?=.{0,255}$)(?!.* .*)(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.)+[a-z][a-z]{0,61}[a-z]$/;

/**
 * Match URL string with http(s) protocol, total domain with unlimited characters and maximum 63 characters per domain part.
 * Total domain: loyal.guru
 * Domain parts: loyal, guru
 */
export const URL_HTTPS_PROTOCOL_UNLIMITED_TOTAL_DOMAIN_REGEX = /^(https?):\/\/((?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.)+[a-z][a-z]{0,61}[a-z]|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/gim;
