import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-coupon-form-warnings',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './coupon-form-warnings.component.html',
})
export class CouponFormWarningsComponent {
  @Input() isUpdateHistory: boolean;

}
