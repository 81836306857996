import { TitleCasePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { DateService } from "../../../../shared/services/date.service";
import { CustomerAttribute } from "../models/customer-attribute.model";
import { CustomerAttributeDetail } from "../models/customer-attribute-detail.model";
import { CustomerAttributesOptionsService } from "../services/customer-attributes-options/customer-attributes-options.service";
import { parseAttributeType, parseBooleanParameter, parseDataPeriodType, parseExecutionType, parsePropertyByOptionName, parseRecurrence, parseSystem } from "../utils/parser.utils";
import { DictionaryService } from "../../../../shared/services/dictionary.service";

export function parseCustomerAttributesDetailData(
  customerAttributesData: CustomerAttribute,
  optionsService: CustomerAttributesOptionsService,
  dateService: DateService,
  titleCasePipe: TitleCasePipe,
  translate: TranslateService,
  dictionaryService: DictionaryService
  ): CustomerAttributeDetail {
    return {
      id: customerAttributesData.id,
      system: parseSystem(customerAttributesData.system, translate, dictionaryService),
      name: customerAttributesData.name,
      description: customerAttributesData.description,
      attributeType: parseAttributeType(customerAttributesData.attributeType, optionsService, titleCasePipe),
      dataType: parsePropertyByOptionName(optionsService.getDataTypeOptions(), customerAttributesData.dataType),
      resultsAvailableInRealTime: parseBooleanParameter(customerAttributesData.resultsAvailableInRealTime, translate),
      ignoreStrictProfiling: parseBooleanParameter(customerAttributesData.ignoreStrictProfiling, translate),
      dataPeriodType: parseDataPeriodType(customerAttributesData, optionsService, translate),
      executionType: parseExecutionType(customerAttributesData.recurrence, optionsService),
      startDate: customerAttributesData.startDate ? dateService.parseDateWithFormat(customerAttributesData.startDate, 'DD/MM/YYYY') : null,
      startTime: customerAttributesData.startTime,
      recurrence: parseRecurrence(customerAttributesData, optionsService),
      sql: customerAttributesData.sql
    }
}
