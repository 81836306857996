import { storedUserStub } from "../../mocks/current-user-mock";

export class CompanyConfigurations {
  accountsIdentifier: string;
  sso: SSO;
  userSecurity: UserSecurity;
  pushRedirections: PushRedirection[]
  alertsConfig: AlertsConfig;

  constructor(jsonConfig: object) {
    this.accountsIdentifier = jsonConfig['accounts_identifier'];
    this.sso = jsonConfig['sso'] ? new SSO(jsonConfig['sso']) : null;
    this.userSecurity = jsonConfig['user_security'] ? new UserSecurity(jsonConfig['user_security']) : null;
    this.pushRedirections = jsonConfig['push_redirections']?.['list'] ? jsonConfig['push_redirections']['list'].map((pushRedirection: object) => new PushRedirection(pushRedirection)) : [];
    this.alertsConfig = jsonConfig['alerts_config'] ? new AlertsConfig(jsonConfig['alerts_config']) : null;
  }
}

export class SSO {
  saml2: SAML2;

  constructor(jsonConfig: object) {
    this.saml2 = jsonConfig['saml2'] ? new SAML2(jsonConfig['saml2']) : null;
  }
}

export class SAML2 {
  enabled: boolean;
  settings: SAML2Settings;
  idpMetadataUrl: string;
  userInfoMapping: object;

  constructor(jsonConfig: object) {
    this.enabled = jsonConfig['enabled'] ?? false;
    this.settings = jsonConfig['settings'] ? new SAML2Settings(jsonConfig['settings']) : null;
    this.idpMetadataUrl = jsonConfig['idp_metadata_url'] || null;
    this.userInfoMapping = jsonConfig['user_info_mapping'] || null;
  }
}

export class SAML2Settings {
  soft: boolean;
  security: SAML2SettingsSecurity;
  compressRequest: boolean;
  compressResponse: boolean;
  messageMaxBytesize: number;
  singleLogoutServiceBinding: string;
  idpCertFingerprintAlgorithm: string;
  doubleQuoteXmlAttributeValues: boolean;
  assertionConsumerServiceBinding: string;

  constructor(jsonConfig: object) {
    this.soft = jsonConfig['soft'] ?? true;
    this.security = jsonConfig['security'] ? new SAML2SettingsSecurity(jsonConfig['security']) : null;
    this.compressRequest = jsonConfig['compress_request'] ?? true;
    this.compressResponse = jsonConfig['compress_response'] ?? true;
    this.messageMaxBytesize = jsonConfig['message_max_bytesize'] ?? null;
    this.singleLogoutServiceBinding = jsonConfig['single_logout_service_binding'] || null;
    this.idpCertFingerprintAlgorithm = jsonConfig['idp_cert_fingerprint_algorithm'] || null;
    this.doubleQuoteXmlAttributeValues = jsonConfig['double_quote_xml_attribute_values'] ?? false;
    this.assertionConsumerServiceBinding = jsonConfig['assertion_consumer_service_binding'] || null;
  }
}

export class SAML2SettingsSecurity {
  wantNameID: boolean;
  digestMethod: string;
  metadataSigned: boolean;
  signatureMethod: string;
  authnRequestsSigned: boolean;
  logoutRequestsSigned: boolean;
  lowercaseUrlEncoding: boolean;
  wantAssertionsSigned: boolean;
  checkSpCertExpiration: boolean;
  checkIdpCertExpiration: boolean;
  wantAssertionsEncrypted: boolean;
  logoutResponsesSigned: boolean;
  strictAudienceValidation: boolean;

  constructor(jsonConfig: object) {
    this.wantNameID = jsonConfig['want_name_id'] ?? false;
    this.digestMethod = jsonConfig['digest_method'] || null;
    this.metadataSigned = jsonConfig['metadata_signed'] ?? false;
    this.signatureMethod = jsonConfig['signature_method'] || null;
    this.authnRequestsSigned = jsonConfig['authn_requests_signed'] ?? false;
    this.logoutRequestsSigned = jsonConfig['logout_requests_signed'] ?? false;
    this.lowercaseUrlEncoding = jsonConfig['lowercase_url_encoding'] ?? false;
    this.wantAssertionsSigned = jsonConfig['want_assertions_signed'] ?? false;
    this.logoutResponsesSigned = jsonConfig['logout_responses_signed'] ?? false;
    this.checkSpCertExpiration = jsonConfig['check_sp_cert_expiration'] ?? false;
    this.checkIdpCertExpiration = jsonConfig['check_idp_cert_expiration'] ?? false;
    this.wantAssertionsEncrypted = jsonConfig['want_assertions_encrypted'] ?? false;
    this.strictAudienceValidation = jsonConfig['strict_audience_validation'] ?? false;
  }
}

export class UserSecurity {
  loginMaxFailures: number;
  passwordMinDigits: number;
  passwordMinLength: number;
  passwordMinUppercase: number;
  sessionIdleTimeoutMins: number;
  passwordExpirationMonths: number;
  passwordPreviousUnrepeated: number;
  passwordMinSpecialCharacters: number;
  accountInactivityBlockageDays: number;

  constructor(jsonConfig: object) {
    this.loginMaxFailures = jsonConfig['login_max_failures'] ?? null;
    this.passwordMinDigits = jsonConfig['password_min_digits'] ?? null;
    this.passwordMinLength = jsonConfig['password_min_length'] ?? null;
    this.passwordMinUppercase = jsonConfig['password_min_uppercase'] ?? null;
    this.sessionIdleTimeoutMins = jsonConfig['session_idle_timeout_mins'] ?? null;
    this.passwordExpirationMonths = jsonConfig['password_expiration_months'] ?? null;
    this.passwordPreviousUnrepeated = jsonConfig['password_previous_unrepeated'] ?? null;
    this.passwordMinSpecialCharacters = jsonConfig['password_min_special_characters'] ?? null;
    this.accountInactivityBlockageDays = jsonConfig['account_inactivity_blockage_days'] ?? null;
  }
}

export class PushRedirection {
  name: string;
  id: string;
  params?: object;

  constructor (jsonConfig: object) {
    this.name = jsonConfig['name'];
    this.id = jsonConfig['value'];
    this.params = jsonConfig['params'] || null;
  }
}
export class AlertsConfig {
  vouchers?: { noRedemptions?: NoRedemptions } = {};
  constructor(jsonConfig: object) {
    this.vouchers.noRedemptions = new NoRedemptions(jsonConfig['vouchers']?.['no_redemptions']);
  }
}

export class NoRedemptions {
  active: boolean;
  redemptionCheckHours: number;
  emailRecipients: string;

  constructor(jsonConfig: object) {
    this.active = jsonConfig?.['active'] ?? false;
    this.redemptionCheckHours = jsonConfig?.['redemptions_check_hours'] ?? null;
    this.emailRecipients = jsonConfig?.['email_recipients'] ?? null;
  }
}
