import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';
import { Observable } from 'rxjs';
import { DynamicDateQuestion } from '../../../../../shared/models/forms/question-dynamic-date';
import { DateService } from '../../../../../shared/services/date.service';
import { DictionaryService } from '../../../../../shared/services/dictionary.service';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { SegmentsService } from '../../../../segments/segments.service';

@Injectable()
export class ReportSegmentEvoService extends ResourcesService {

  public inputs: QuestionBase<any>[];
  public formValues = {};

  constructor(http: HttpClient,
              private translate: TranslateService,
              private dateService: DateService,
              private segmentsService: SegmentsService ) {
    super(http);
  }

  public fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `reports/${id}/report` });
  }

  public fetchReportDetailById(id: number, history_id: number): Observable<object> {
    return this.getData({ apiEndPoint: `reports/${id}/details/${history_id}` });
  }

  public setInputs(formValues: any) {

    let config = null;
    if ( formValues && formValues ) {
      config = formValues.configuration;
    }

    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: formValues.name
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_from',
        label: this.translate.instant('resources.reports.form.fields.before_date_from'),
        required: true,
        value: (config) ? this.dateService.dynamicDateToDateOption(config.date_from) : null
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_to',
        label: this.translate.instant('resources.reports.form.fields.before_date_to'),
        required: true,
        value: (config) ? this.dateService.dynamicDateToDateOption(config.date_to) : null
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'segment_ids',
        dataSource: this.segmentsService,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: false, enableSearchFilter: true },
        label: this.translate.instant('resources.reports.form.fields.segments'),
        required: true,
        selectedIds: (config && config.segment_ids && config.segment_ids.length > 0) ? config.segment_ids : []
      }),
    ];

    this.inputs = inputsArray;
    return this.inputs;
  }

  public sendData(data, id) {
    return this.postResource(data, `reports/${id}/details`);
  }

  public updateData(data: any, id: number, history_id) {
    return this.patchResource(data, `reports/${id}/details/${history_id}`);
  }

}
