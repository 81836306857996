<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true" *ngIf="element">
              <tbody>
                <tr *ngIf="element.id">
                  <td class="no-border-top"><b [translate]="'resources.locations.fields.id'"></b></td>
                  <td class="no-border-top">{{element.id}}</td>
                </tr>
                <tr *ngIf="element.code">
                  <td><b [translate]="'resources.locations.fields.code'"></b></td>
                  <td>{{element.code}}</td>
                </tr>
                <tr *ngIf="element.status">
                  <td><b [translate]="'resources.locations.fields.status'"></b></td>
                  <td *ngIf="element.status === 'active'"><span class="label label-success font-size-12"[translate]="'resources.locations.fields.active'"></span></td>
                  <td *ngIf="element.status === 'inactive'"><span class="label label-danger font-size-12"[translate]="'resources.locations.fields.inactive'"></span></td>
                </tr>
                <tr *ngIf="element.name">
                  <td><b [translate]="'resources.locations.fields.name'"></b></td>
                  <td>{{element.name}}</td>
                </tr>
                <tr *ngIf="element.phone">
                  <td><b [translate]="'resources.locations.fields.phone'"></b></td>
                  <td>{{element.phone}}</td>
                </tr>
                <tr *ngIf="element.email">
                  <td><b [translate]="'resources.locations.fields.email'"></b></td>
                  <td>{{element.email}}</td>
                </tr>
                <tr *ngIf="element.address">
                  <td><b [translate]="'resources.locations.fields.address'"></b></td>
                  <td>{{element.address}}</td>
                </tr>
                <tr *ngIf="element.schedule">
                  <td><b [translate]="'resources.locations.fields.schedule'"></b></td>
                  <td>{{element.schedule}}</td>
                </tr>
                <tr *ngIf="element.postalCode">
                  <td><b [translate]="'resources.locations.fields.postal_code'"></b></td>
                  <td>{{element.postalCode}}</td>
                </tr>
                <tr *ngIf="element.state">
                  <td><b [translate]="'resources.locations.fields.state'"></b></td>
                  <td>{{element.state}}</td>
                </tr>
                <tr *ngIf="element.city">
                  <td><b [translate]="'resources.locations.fields.city'"></b></td>
                  <td>{{element.city}}</td>
                </tr>
                <tr *ngIf="element.country">
                  <td><b [translate]="'resources.locations.fields.country'"></b></td>
                  <td>{{element.country}}</td>
                </tr>
                <tr *ngIf="element.goals && element.goals.signups && element.goals.signups.week">
                  <td><b [translate]="'resources.locations.fields.goals.signups'"></b></td>
                  <td>{{element.goals.signups.week}}</td>
                </tr>
                <tr *ngIf="element.scoreExchange">
                  <td><b [translate]="'resources.locations.fields.score_exchanges'"></b></td>
                  <td>{{element.scoreExchange.name}}</td>
                </tr>
                <tr>
                  <td><b [translate]="'resources.locations.fields.gift_card_available'"></b></td>
                  <td *ngIf="element.giftCardAvailable"><span><i class="fa fa-check text-success"></i></span></td>
                  <td *ngIf="!element.giftCardAvailable"><span><i class="fa fa-times text-danger"></i></span></td>
                </tr>
                <tr *ngIf="element.locationTaxonomyTerms.length > 0">
                  <td><b [translate]="'resources.locations.fields.location_taxonomy_terms'"></b></td>
                  <td>
                    <ul class="list arrow bullet-success">
                      <li *ngFor="let term of element.locationTaxonomyTerms | orderBy:['name','value']">
                        <span>{{term.name}}: {{term.value}}</span>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
