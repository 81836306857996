import { Component, OnInit, Input } from '@angular/core';
import { QuestionBase } from '../../../../../../shared/models/forms/question-base';
import { CurrentCompany } from '../../../../../../shared/models/current-user';
import { PlanCampaign } from '../../../plan-campaign';
import { UntypedFormGroup } from '@angular/forms';
import { SegmentsService } from '../../../../../segments/segments.service';
import { QuestionControlService } from '../../../../../../shared/services/question-control.service';
import { TextboxQuestion } from '../../../../../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../../../../../shared/models/forms/question-multiselect';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-recovery-campaign-general-inputs',
  templateUrl: './recovery-campaign-general-inputs.component.html'
})
export class RecoveryCampaignGeneralInputsComponent implements OnInit {

  public commonInputs: QuestionBase<any>[];
  public company: CurrentCompany;

  @Input('planCampaign') planCampaign: PlanCampaign;
  @Input('recoveryGeneralInputs') recoveryGeneralInputs: UntypedFormGroup;

  constructor(
    private segmentsService: SegmentsService,
    private qcs: QuestionControlService
  ) { }

  ngOnInit() {
    this.setInputs(this.planCampaign);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.commonInputs, inputKey);
  }

  private getSelectedSegment(planCampaign: PlanCampaign): number[] {
    const segmentId = planCampaign.config['segment_id'];
    if (isNullOrUndefined(segmentId)) {return []; }
    return [segmentId];
  }

  private setInputs(planCampaign?: PlanCampaign) {
    const inputs = [
      new TextboxQuestion({
        key: 'name',
        label: 'resources.campaign_plans.types.recovery.fields.name',
        type: 'text',
        cssClasses: 'form-control input-default',
        required: true,
        value: planCampaign ? planCampaign.name : null
      }),
      new MultiSelectQuestion({
        key: 'segment_id',
        label: 'resources.campaign_plans.types.recovery.fields.restricted_segment',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.segmentsService,
        selectedIds: planCampaign ? this.getSelectedSegment(planCampaign) : []
      })
    ];

    this.commonInputs = inputs;
    this.commonInputs.forEach(input => {
      this.qcs.addToFormGroup(this.recoveryGeneralInputs, input);
    });
  }

}
