import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';

@Injectable()
export class UsersService extends ResourcesService {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  getRolesList() {
    const requestOptions = {
      apiEndPoint: 'roles',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  getUserById(id: number) {
    return this.getData({ apiEndPoint: `users/${id}?with_authentication_token=true` });
  }

  createUser(userData: any) {
    return this.postResource(userData, 'users');
  }

  activateUser(token: string, company: string) {
    return this.postResource({activation_token: token, company: company}, 'users/activation');
  }

  updateUser(userData: any, id: number) {
    return this.patchResource(userData, `users/${id}`);
  }

  passRecoveryUser(id: number) {
    return this.patchResource({}, `users/${id}/password_recovery`);
  }

  deleteUser(id: number) {
    return this.deleteResource({}, `users/${id}`);
  }

  patchLanguage(userId: string | number, language: string) {
    const params = {
      preferred_language: language
    };
    return this.patchResource(params, `users/${userId}`);
  }

  getResetTokenValidationPasswordPolicy(resetToken: string) {
    return this.getData({ apiEndPoint: `users/${resetToken}/password_policy` });
  }
}
