<div class="modal-backdrop fade in overflow-auto" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="mrg-left-15 mrg-btm-5" [translate]="'resources.customers.show.actions.give_points'"></h4>
        <button [attr.data-cy]="'button_closeGrantScore'"  class="modal-close" style="top: 8px;" (click)="closeModal()">
          <i class="ti-close"></i>
        </button>
      </div>

      <app-loader *ngIf="loading"></app-loader>

      <div class="modal-body background-gray">
        <div class="row">
          <div class="col-md-12">
            <div class="container-fluid">
              <div class="row">
                <div class="mrg-top-10 col-md-12">
                  <div class="card-block no-pdd">
                    <div class="row">
                      <div class="col-md-8 ml-auto mr-auto">
                        <form [formGroup]="grantPointsForm" *ngIf="grantPointsForm">

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="score" [translate]="'resources.customers.show.action_modals.score'"></label>
                                <span *ngIf="getInputConfig('score').required" class="text-danger">*</span>
                                <app-question [question]="getInputConfig('score')" [form]="grantPointsForm"></app-question>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="country"
                                       [translate]="'resources.customers.show.action_modals.country'">
                                </label>
                                <span *ngIf="getInputConfig('country').required" class="text-danger">*</span>
                                <app-question [question]="getInputConfig('country')"
                                              [form]="grantPointsForm"
                                              (multiselectChanged)="countrySelector($event)"s>
                                </app-question>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="location_id"
                                       [translate]="'resources.customers.show.action_modals.location'">
                                </label>
                                <span *ngIf="getInputConfig('location_id').required" class="text-danger">*</span>
                                <app-question [question]="getInputConfig('location_id')"
                                              [form]="grantPointsForm"
                                              (multiselectChanged)="countrySelector($event)">
                                </app-question>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="loyalty_score_id" [translate]="'resources.customers.show.action_modals.loyalty_score'"></label>
                                <span *ngIf="getInputConfig('loyalty_score_id').required" class="text-danger">*</span>
                                <app-question [question]="getInputConfig('loyalty_score_id')"
                                              [form]="grantPointsForm"
                                              [filters]="{ when: 'manual', active: true }">
                                </app-question>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="comments"
                                       [translate]="'resources.customers.show.action_modals.comments'">
                                </label>
                                <span class="text-danger">*</span>

                                <ng-container>
                                  <!-- IF COMMENTS TABLE IS EMPTY DISPLAY TEXTBOXQUESTION -->
                                  <app-question *ngIf="!hasComments" [question]="getInputConfig('comments')" [form]="grantPointsForm"></app-question>
                                  <!-- IF COMMENTS TABLE HAS VALUES DISPLAY MULTISELECTOR -->
                                  <app-question *ngIf="hasComments" [question]="getInputConfig('multi_comments')" [form]="grantPointsForm"></app-question>
                                </ng-container>

                              </div>
                            </div>
                          </div>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer background-white">
        <span>
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        </span>
        <div class="pull-right">
          <button class="pull-right btn btn-info"
                  [attr.data-cy]="'button_customerGrantScoreConfirm'"
                  [translate]="'resources.customers.show.actions.give_points'"
                  [disabled]="!grantPointsForm?.valid || loading"
                  (click)="sendData()">
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
