<form>
  <app-recovery-campaign-general-inputs [recoveryGeneralInputs]="form" [planCampaign]="planCampaign"></app-recovery-campaign-general-inputs>
  <hr>
  <div class="row">
    <div class="col-md-3">
      <app-question [question]="getInputConfig('campaign_plan_category_id')" [form]="form"></app-question>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3">
      <app-question [question]="getInputConfig('periods')" [form]="form"></app-question>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3">
      <app-question [question]="getInputConfig('has_bought')" [form]="form"></app-question>
    </div>
  </div>
</form>
