import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { CustomerJourney } from '../../../models/customer-journeys/customer-journey';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-journey-content-block',
  templateUrl: './journey-content-block.component.html',
  styleUrls: ['./journey-content-block.component.css']
})
export class JourneyContentBlockComponent implements OnInit {

  @Input()  journey:      CustomerJourney;
  @Input()  form:         UntypedFormGroup;
  @Output() onStatusEmit: EventEmitter<any> = new EventEmitter();
  @ViewChild('messageInput') message: ElementRef;

  public contentChanged = false;
  public messageData: {
    smsMaxLength: number,
    smsMultiLength: number,
    smsMessages: number,
    hasNoPubli: boolean,
    smsRemainingLength: number,
    length: number,
    smsMaxLengthDoubled: number,
    totalLengthText: number
  };
  private specialCharacters = ['|', '^', '€', '{', '}', '[', ']', '~', '\\'];
  public open = false;

  constructor() { }

  ngOnInit() {
    this.messageData = {
      smsMaxLength: 160,
      smsMultiLength: 153,
      smsMessages: 1,
      hasNoPubli: false,
      smsRemainingLength: undefined,
      length: undefined,
      smsMaxLengthDoubled: 160 * 2,
      totalLengthText: undefined
    };
    this.writingSms(this.journey.configuration.message);
  }

  writingSms(message) {
    let count_stranger = 0;

    if ( this.journey.hasMessage() ) {
      this.specialCharacters.forEach(char => {
        count_stranger = count_stranger + this.journey.configuration.message.split( char ).length - 1;
      });

      this.messageData.totalLengthText = this.journey.configuration.message.length + count_stranger;

      if (this.messageData.totalLengthText <= this.messageData.smsMaxLength ) {
        this.messageData.smsMessages = 1;
        this.messageData.smsRemainingLength = (this.messageData.smsMaxLength - this.journey.configuration.message.length) - count_stranger;
      } else {
        this.messageData.smsMessages = Math.ceil(this.messageData.totalLengthText / this.messageData.smsMultiLength);
        this.messageData.smsRemainingLength = (this.messageData.smsMessages * this.messageData.smsMultiLength - this.messageData.totalLengthText);
      }
    }
  }

  smsAddVariable(couponVar) {
    const selectionStart = this.message.nativeElement.selectionStart;
    const selectionEnd = this.message.nativeElement.selectionEnd;
    const pre = this.journey.configuration.message.substr(0, selectionStart);
    const pos = this.journey.configuration.message.substr(selectionEnd);

    this.journey.configuration.message = `${pre}${couponVar}${pos}`;
    this.writingSms(this.journey.configuration.message);
  }

  handleContentChanged(event) {
    this.contentChanged = true;
    this.onStatusEmit.emit('unsaved_changes');
  }

  smsHasVariables(): boolean {
    let hasVariables = false;
    const couponVars = ['{name}', '{surname_1}', '{surname_2}', '{coupon}', '{score_available}', '{location}', '{couponCode}'];
    couponVars.forEach(couponVar => {
      if ( this.journey.configuration.message.indexOf(couponVar) >= 0 ) {
        hasVariables = true;
      }
    });
    return hasVariables;
  }

  /*
  * TODO: Implement ( Each step campaign ¿?  )
  hasStaticCode() {
    return $scope.model.motive == 'coupon' &&
          ($scope.model.coupons[0].static_code == '' || $scope.model.coupons[0].static_code == null);
  }
  */
}
