import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from './condition.service';
import { QuestionBase } from '../../models/forms/question-base';
import { MultiSelectQuestion } from '../../models/forms/question-multiselect';
import { TranslateService } from '@ngx-translate/core';
import { TextboxQuestion } from '../../models/forms/question-textbox';
import { FloatQuestion } from '../../models/forms/question-float';
import { ProfileService } from '../../../profiles/profile.service';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { multiselectPresenceValidator, checkControlValuePresence, checkValue1GreaterThanValue2 } from '../validations.service';

@Injectable()
export class CustomConditionService implements SegmentConditionProvider {

  public inputs: QuestionBase<any>[];
  public customValidators = [(control: UntypedFormGroup) => this.getFormGroupValidations(control)];

  constructor( private translate: TranslateService,
               private profileService: ProfileService ) {}

  public getInputs(params?: any): QuestionBase<any>[] {

    const scopes = this.getScopes();

    let scope = [];
    let operators = [];
    let selectedOperator = [];
    let extraValues = [];
    let selectedExtraValues = [];
    let selectedValue = null;
    let selectedBtValue = null;

    if (params && params.hasOwnProperty('scope') && params.scope) {
      scope = this.getSelectedScope(scopes, params.scope);
      operators = this.getScopeOperators(scopes, params.scope);
      selectedOperator = this.getSelectedOperator(operators, params);
      extraValues = this.getExtraValues(scopes, params.scope);
      selectedExtraValues = this.getSelectedExtraValue(extraValues, params);
      selectedValue = this.getSelectedValue(params);
      selectedBtValue = this.getSelectedBtValue(params);
    }

    const inputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'scope',
        required: true,
        label: this.translate.instant('resources.segment_conditions.fields.scope'),
        value: scope,
        options: scopes,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        customValidators: [multiselectPresenceValidator],
        parseValue: (value) => {
          return (value && value.length) ? scopes.find( _scope => _scope.id === value[0].id ).id : null;
        }
      }),

      // Start: KeyField: 'custom' //
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'operator',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: operators,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        getValue: (value) => (value && value.length) ? value : null,
        parseValue: (values) => ((values && values.length > 0) ? values[0].id : null),
        value: selectedOperator,
        customValidators: [multiselectPresenceValidator]
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'value',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        type: 'text',
        required: true,
        getValue: (value) => value,
        parseValue: (value) => value,
        value: selectedValue
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'value_array',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        options: extraValues,
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        getValue: (value) => (value && value.length) ? value : null,
        parseValue: (values) => ((values && values.length > 0) ? values[0].id : null),
        value: selectedExtraValues,
        customValidators: [multiselectPresenceValidator]
      }),
      // End: custom string inputs //

      // Start: KeyField 'customf' //
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'operatorF',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.getNumericOperators(),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        getValue: (value) => (value && value.length) ? value : null,
        parseValue: (values) => ((values && values.length > 0) ? values[0].id : null),
        value: selectedOperator
      }),
      new FloatQuestion({
        cssClasses: 'form-control input-md',
        key: 'valueF',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        required: true,
        step: 0.1,
        getValue: (value) => value,
        parseValue: (value) => value,
        value: selectedValue
      }),
      new FloatQuestion({
        cssClasses: 'form-control input-md',
        key: 'value_bt',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        required: true,
        step: 0.1,
        getValue: (value) => value,
        parseValue: (value) => value,
        value: selectedBtValue
      })
      // End: customF //
    ];
    this.inputs = inputs;
    return this.inputs;
  }

  public prepareFormValuesToAPI(params: any): any {

    const scope = (params.scope && params.scope.length > 0) ? params.scope[0].id : undefined;

    let operator;
    const _operator = (params.operator && params.operator.length > 0) ? params.operator[0].id : undefined;
    const _operatorF = (params.operatorF && params.operatorF.length > 0) ? params.operatorF[0].id : undefined;

    let value;
    const _value = (params.value) ? params.value : undefined;
    const _valueF = (params.valueF) ? parseFloat(params.valueF) : undefined;
    const _valueArray = (params.value_array && params.value_array.length > 0) ? params.value_array.map( _v => _v.id) : undefined;

    if (scope.endsWith('f')) {
      operator = _operatorF;
      value = _valueF;
    } else {
      operator = _operator;
      if ( ['in', 'not_in'].indexOf(operator) >= 0 ) {
        value = _valueArray;
      } else {
        value = _value;
      }
    }

    const parsedValuesObj = { scope: scope, operator: operator, value: value };

    if (operator === 'bt') {
      const valueBt = ( params.value_bt ) ? parseFloat(params.value_bt) : undefined;
      parsedValuesObj['value_bt'] = valueBt;
    }

    return parsedValuesObj;
  }

  public prepareFormValuesFromAPI(params: any): any {
    const parsedValuesObj = {};
    const scopes = this.getScopes();
    const scope = (params && params.scope) ? scopes.find( _scope => _scope.id === params.scope) : null;

    parsedValuesObj['scope'] = [scope];

    const operator = params.operator;
    const isCustomF = scope && scope.hasOwnProperty('id') && scope.id && scope.id.endsWith('f');

    if (isCustomF) {
      if (operator) {
        parsedValuesObj['operatorF'] = [{id: operator, name: this.getNumericOperators().find( _op => _op.id === operator).name}];
      } else {
        parsedValuesObj['operatorF'] = [];
      }
      parsedValuesObj['valueF'] = (params.value) ? params.value : null;
      if (params.value_bt) {
        parsedValuesObj['value_bt'] = params.value_bt;
      }
    } else {
      if (params.operator && ['in', 'not_in'].indexOf(params.operator) >= 0) {
        if (operator) {
          parsedValuesObj['operator'] = [{id: operator, name: this.getArrayOperators().find( _op => _op.id === operator).name}];
        } else {
          parsedValuesObj['operator'] = [];
        }
        if (params.value) {
          const selectedOptions = scope.options.filter( opt => params.value.indexOf(opt.id) >= 0);
          parsedValuesObj['value_array'] = (selectedOptions && selectedOptions.length > 0) ? selectedOptions : [];
        } else {
          parsedValuesObj['value_array'] = [];
        }
      } else {
        if (operator) {
          parsedValuesObj['operator'] = [{id: operator, name: this.getStringOperators().find( _op => _op.id === operator).name}];
        } else {
          parsedValuesObj['operator'] = [];
        }
        parsedValuesObj['value'] = (params.value) ? params.value : null;
      }
    }

    return parsedValuesObj;
  }

  public getArrayOperators(): {id: string, name: string}[] {
    return [
      { id: 'in', name: this.translate.instant('resources.segment_conditions.operators.belongs_to') },
      { id: 'not_in', name: this.translate.instant('resources.segment_conditions.operators.does_not_belongs') }
    ];
  }

  public getStringOperators(): {id: string, name: string}[] {
    return [
      { id: 'eq', name: this.translate.instant('resources.segment_conditions.operators.eq') },
      { id: 'not_eq', name: this.translate.instant('resources.segment_conditions.operators.not_eq') },
      { id: 'starts_with', name: this.translate.instant('resources.segment_conditions.operators.starts_with') },
      { id: 'ends_with', name: this.translate.instant('resources.segment_conditions.operators.ends_with') },
      { id: 'contains', name: this.translate.instant('resources.segment_conditions.operators.contains') }
    ];
  }

  private getNumericOperators(): {id: string, name: string}[] {
    return [
      { id: 'eq', name: this.translate.instant('resources.segment_conditions.operators.eq') },
      { id: 'not_eq', name: this.translate.instant('resources.segment_conditions.operators.not_eq') },
      { id: 'lteq', name: this.translate.instant('resources.segment_conditions.operators.lteq') },
      { id: 'lt', name: this.translate.instant('resources.segment_conditions.operators.lt') },
      { id: 'gt', name: this.translate.instant('resources.segment_conditions.operators.gt') },
      { id: 'gteq', name: this.translate.instant('resources.segment_conditions.operators.gteq') },
      { id: 'bt', name: this.translate.instant('resources.segment_conditions.operators.bt') }
    ];
  }

  private getScopes(): { id: any, name: string, options?: {id: any, name: string}[] }[] {
    let scopes: {id: string, name: string, options?: any[] }[] = [];
    const company = this.profileService.getStoredUser().company;
    if ( company.customs && company.customs.hasOwnProperty('Profile') && Object.keys(company.customs.Profile).length > 0 ) {
      const profileCustoms = company.customs.Profile;
      scopes = Object.keys(profileCustoms).map( customKey => {
        let options = profileCustoms[customKey]['values'];
        const parsedCustom = { id: customKey, name: profileCustoms[customKey]['name'] };
        if ( options && options.length > 0 ) {
          options = options.map( opt => ({ id: opt.value, name: opt.name }));
          parsedCustom['options'] = options;
        }
        return parsedCustom;
      });
    }
    return scopes;
  }


  private getSelectedScope(_scopes, _scope) {
    if (_scope.length > 0 && _scope[0] && _scope[0].hasOwnProperty('id')) {
      return _scope;
    }
  }

  private getSelectedOperator(_scopes, _params) {
    const _operator = (_params.operator && _params.operator.length > 0) ? _params.operator : _params.operatorF;
    if (_operator && _operator.length > 0 && _operator[0] && _operator[0].hasOwnProperty('id')) {
      return _operator;
    } else {
      return [];
    }
  }

  private getScopeOperators(_scopes, _scope) {
    if (_scope.length > 0 && _scope[0] && _scope[0].hasOwnProperty('id')) {
      const scopeId = _scope[0].id;
      if (scopeId.endsWith('f')) {
        return this.getNumericOperators();
      } else {
        const scopeRawElement = _scopes.find( s => s.id === _scope[0].id );
        if ( scopeRawElement.hasOwnProperty('options') ) {
          return this.getArrayOperators();
        } else {
          return this.getStringOperators();
        }
      }
    }
  }

  private getExtraValues(_scopes, _scope) {
    if (_scope.length > 0 && _scope[0] && _scope[0].hasOwnProperty('id')) {
      const scopeRawElement = _scopes.find( s => s.id === _scope[0].id );
      if ( scopeRawElement.hasOwnProperty('options') ) {
        return scopeRawElement.options;
      } else {
        return [];
      }
    }
  }

  private getSelectedValue(_params) {
    const _value = _params.value ? _params.value : _params.valueF;
    return _value;
  }

  private getSelectedBtValue(_params) {
    const _value = _params.value_bt ? _params.value_bt : null;
    return _value;
  }

  private getSelectedExtraValue(_values, _params) {
    const _extraValues = (_params.value_array && _params.value_array.length > 0) ? _params.value_array : [];
    if (_extraValues && _extraValues.length > 0 && _extraValues[0] && _extraValues[0].hasOwnProperty('id')) {
      return _extraValues;
    } else {
      return [];
    }
  }

  private getFormGroupValidations(control: UntypedFormGroup): ValidationErrors | null {

    // Form Controls
    const scopeCtrl = control.get('scope');
    const operator = control.get('operator');
    const valueCtrl = control.get('value');
    const valueArrayCtrl = control.get('value_array');
    const operatorF = control.get('operatorF');
    const valueFloatCtrl = control.get('valueF');
    const valueBtCtrl = control.get('value_bt');

    // CFG required for validation rules
    const scopeValue = (scopeCtrl.value && scopeCtrl.value.length > 0) ? scopeCtrl.value[0] : null;
    const mode = (scopeValue && scopeValue.id.endsWith('f')) ? 'customf' : 'custom';
    const extraValue = (scopeValue && scopeValue.options && scopeValue.options.length > 0) ? true : false;

    // clean validators
    operator.setValidators([]);
    valueCtrl.setValidators([]);
    valueArrayCtrl.setValidators([]);
    operatorF.setValidators([]);
    valueFloatCtrl.setValidators([]);
    valueBtCtrl.setValidators([]);

    // Extract values
    const operatorValue = (operator.value && operator.value.length > 0) ? operator.value[0].id : null;
    const customValue = (valueCtrl.value && valueCtrl.value.length > 0) ? valueCtrl.value : null;
    const operatorFValue = (operatorF.value && operatorF.value.length > 0) ? operatorF.value[0].id : null;

    // Validations logic
    if (mode === 'custom') {
      if (!operatorValue) { return {operatorValue: true}; }
      if (extraValue) {
        return checkControlValuePresence(valueArrayCtrl, 'invalidExtraValuesValue');
      } else {
        if (!customValue) { return {customValue: true}; }
      }
    } else if (mode === 'customf') {
      if (!operatorFValue) { return {operatorFValue: true}; }
      if (operatorFValue === 'bt') {
        if (!valueFloatCtrl.value) { return {customValueFloat: true}; }
        if (!valueBtCtrl.value) { return {customValueFloatBt: true}; }
        return checkValue1GreaterThanValue2(valueFloatCtrl, valueBtCtrl);
      } else {
        if (!valueFloatCtrl.value) { return {customValueFloat: true}; }
      }
    }

    return null;
  }
}
