<ng-container *ngIf="section">
  <div class="row" *ngFor="let row of section.rows">
    <ng-container *ngFor="let item of row.items">
      <div [class]="'col-md-' + item.colSize" *ngIf="!inputConfig(item.key).hidden">
        <div class="form-group" [ngClass]="{ 'mrg-top-32': item.noLabel }">
          <label for="{{item.key}}" class="font-size-14 text-bold text-no-wrap" *ngIf="!item.noLabel">
            {{ (translateBase + (item.altLabel ?? item.key)) | translate }}{{ item.currency ? '(' + currency + ')' : '' }}
            <em class="ti-info-alt pull-right mrg-top-3 mrg-left-5" tooltipPosition="top" *ngIf="item.toolTip"
              pTooltip="{{ (translateBase + 'tooltip.' + item.key) | translate }}">
            </em>
          </label>
          <app-question [question]="inputConfig(item.key)" [form]="form" [useToken]="item.useToken"
            (change)="handleFileChange($event, item)"
            (multiselectChanged)="handleMultiselectChange(item, $event)" >
          </app-question>
        </div>
      </div>
    </ng-container>
    <ng-content></ng-content>
  </div>
</ng-container>