import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'emptyData'
})
export class EmptyDataPipe implements PipeTransform {
  transform(value: any): any {
    if (value === null || value === undefined) {
      return '-';
    } else {
      return value;
    }
  }
}