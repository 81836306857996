import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { QuestionBase } from '../../../models/forms/question-base';
import { QuestionControlService } from '../../../services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';
import { ConditionService, SegmentConditionProvider } from '../../../services/conditions/condition.service';
import { Condition } from '../../../models/segments/condition';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from '../../../../shared/utils/common.utils';
import { CampaignHistoriesService } from '../../../../resources/campaigns/campaign-histories.service';
import { CouponHistoriesService } from '../../../../resources/coupons/coupon-histories.service';
import { ProfileService } from '../../../../profiles/profile.service';
import { SystemTagHistoriesService } from '../../../services/system-tag-histories.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ProductsService } from '../../../../resources/data-warehouse/products/products.service';
import { LocationsService } from '../../../../resources/data-warehouse/locations/locations.service';
import { SystemCategories } from '../../../../shared/models/segments/system-categories';

@Component({
  selector: 'app-segment-condition',
  templateUrl: './segment-condition.component.html',
  styleUrls: ['./segment-condition.component.css'],
  providers: [
    CampaignHistoriesService,
    CouponHistoriesService,
    SystemTagHistoriesService,
    ProductsService,
    LocationsService,
    TranslateService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SegmentConditionComponent implements OnInit, OnDestroy {

  inputs: QuestionBase<any>[] = [];
  form: UntypedFormGroup;
  rawConditionValues: object;
  service: SegmentConditionProvider;
  systemCategories: SystemCategories;
  subs$: Subscription[] = [];

  private dataSourceableServices = [
    {
      className: CampaignHistoriesService,
      instance: this.campaignHistories,
      getter: 'campaignId',
      setter: (id: any) => this.campaignHistories.setCampaign(id)
    },
    {
      className: CouponHistoriesService,
      instance: this.couponHistories,
      getter: 'couponId',
      setter: (id: any) => this.couponHistories.setCoupon(id)
    },
    {
      className: SystemTagHistoriesService,
      instance: this.systemTagHistories,
      getter: 'segmentId',
      setter: (id: any) => this.systemTagHistories.setSegment(id)
    },
    {
      className: ProductsService,
      instance: this.productsService,
      getter: 'featurePks',
      setter: (pks: any) => this.productsService.setFeatures(pks)
    },
    {
      className: LocationsService,
      instance: this.locationsService,
      getter: 'locationTaxonomyTermIds',
      setter: (locationTaxonomyTermIds: any) => this.locationsService.setLocationTaxonomyTermIds(locationTaxonomyTermIds)
    }
  ];

  @Input('item') item: Condition;
  @Output('formValidity') formValidity: EventEmitter<any> = new EventEmitter();

  constructor(
    private qcs: QuestionControlService,
    private conditionService: ConditionService,
    private campaignHistories: CampaignHistoriesService,
    private couponHistories: CouponHistoriesService,
    private profileService: ProfileService,
    private systemTagHistories: SystemTagHistoriesService,
    private productsService: ProductsService,
    private locationsService: LocationsService
  ) {}

  ngOnInit(): void {
    this.systemCategories = this.getSystemCategories();
    this.service = this.conditionService.getServiceByKey(this.item.content.key);
    this.rawConditionValues = this.conditionService.rawConditionValues[this.item.content.formControlId];
    this.inputs = this.defineInputs();
    this.form = this.qcs.toFormGroup(this.inputs);
    this.handleFormValuesChanged(this.form.value);
    this.setFormValueChangesSub();
    this.setFormGroupCustomValidators();
    this.formStatusChanges();
  }

  ngOnDestroy(): void {
    this.formValidity.emit('VALID');
    this.handleFormValuesChanged(this.form.value);
    this.locationsService.setLocationTaxonomyTermIds(null);
    if (this.subs$.length) { this.subs$.forEach(s$ => s$.unsubscribe()); }
  }

  getSystemCategories(): SystemCategories {
    return this.profileService.getStoredUser().company.system_segment_categories;
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  private setFormValueChangesSub(): void {
    const formChanges$ = this.form.valueChanges.subscribe(
      formValues => {
        this.handleFormValuesChanged(formValues);
      }
    );
    this.subs$.push(formChanges$);
  }

  private setFormGroupCustomValidators(): void {
    if (this.service?.customValidators?.length) {
      this.form.setValidators(this.service.customValidators);
    } else {
      // If there are no custom validations over global FormGroup override FormControl validations to bypass and be able to save
      this.form.clearValidators();
      Object.keys(this.form.controls).forEach((_ctrlKey, _) => this.form.get(_ctrlKey).setValidators([]));
    }
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.form.status));
    const formStatus$ = this.form.statusChanges.pipe(distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
    this.subs$.push(formStatus$);
  }

  private handleFormValuesChanged(formValues: any): void {
    this.conditionService.formValues[this.item.content.formControlId] = formValues;
  }

  private defineInputs(): QuestionBase<any>[] {
    const inputs = this.service.getInputs(this.conditionService.formValues[this.item.content.formControlId]);
    inputs.filter(input => !isNullOrUndefined(input.dataSource)).forEach(input => {
      this.dataSourceableServices.forEach( dataSourceMapping => {
        if (input.dataSource instanceof dataSourceMapping.className) {
          const sourceMappingId: any = input.dataSource[dataSourceMapping.getter];
          dataSourceMapping.setter(sourceMappingId);
          input.dataSource = dataSourceMapping.instance;
        }
      });
    });
    return inputs;
  }
}
