<ul class="nav nav-pills nav-fill mrg-vertical-15">
  <li class="nav-item" *ngFor="let step of steps; let i = index"
    [ngClass]="{'active': step.isActive, 'enabled': !step.isDisabled, 'disabled': step.isDisabled, 'completed': step.isCompleted, 'step-not-clickable': i === 0}">
    <ng-container *ngIf="plan && planCampaign">
      <a [routerLink]="['', { outlets: { modal: ['show', 'plans', plan.id, 'campaigns', planCampaign.id, 'step_'+(i+1)] }}]">
        <span class="step" [ngClass]="{'completed active': (i+1) === currentStep, 'completed': (i+1)<=currentStep}">{{(i + 1)}}</span>
        <span class="title" [ngClass]="{'active': (i+1) === currentStep}">{{step.title}}</span>
      </a>
    </ng-container>
    <ng-container *ngIf="plan && !planCampaign">
      <a>
        <span class="step" [ngClass]="{'completed active': (i+1) === currentStep, 'completed': (i+1)<=currentStep}">{{(i + 1)}}</span>
        <span class="title" [ngClass]="{'active': (i+1) === currentStep}">{{step.title}}</span>
      </a>
    </ng-container>
  </li>
</ul>

<div id="bar" class="progress progress-info steps-progress-bar">
  <div class="progress-bar" 
        role="progressbar"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="100"
        [style.width]="progressBarWidthPercentage">
  </div>
</div>
