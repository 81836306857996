import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { MassiveAction } from '../model/massive-action.model';
import { MassiveActionsService } from '../service/massive-actions.service';
import { NotificationsService } from '../../../../shared/services/notifications.service';

@Component({
  selector: 'app-show-massive-actions',
  templateUrl: './show-massive-actions.component.html'
})

export class ShowMassiveActionsComponent implements OnInit {

  action: MassiveAction;
  actionId: number;
  loading: boolean = true;

  statusClasses: { [key: string]: string } = {
    'approved': 'label-success',
    'data_pending': 'label-info',
    'failed': 'label-danger',
    'fixed': 'label-success',
    'not_valid': 'label-danger',
    'processed': 'label-success',
    'rejected': 'label-danger',
    'running': 'label-info',
    'success_with_errors': 'label-warning',
    'success': 'label-success',
    'support': 'label-warning',
    'validated': 'label-info'
  };

  constructor(
    private confirmationService: ConfirmationService,
    private massiveActionsService: MassiveActionsService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute
  ) {
    this.actionId = this.route.snapshot.params.id;
  }

  ngOnInit(): void {
    this.getActionById(this.actionId);
  }

  downloadFile(file: string) {
    this.notificationsService.getGCSfile(file).subscribe({
      next: (data) => {
        const url = data.url;
        window.open(url);
      },
      error: (error) => {
        this.confirmationService.displayHttpErrorAlert(error);
      }
    });
  }

  private getActionById(id: number | string) {
    this.massiveActionsService.getActionById(id).subscribe({
      next: (data: MassiveAction) => {
        this.loading = false;
        this.action = data;
      },
      error: (error) => {
        this.loading = false;
        this.confirmationService.displayHttpErrorAlert(error);
      }
    });
  }

}