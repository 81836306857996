<div class="row mrg-btm-15" *ngIf="inputs">

  <div class="col">
    <app-question [question]="getInputConfig('operator')" [form]="form"></app-question>
  </div>

  <div class="col">
    <app-question [question]="getInputConfig('values')" [form]="form"></app-question>
  </div>

</div>
