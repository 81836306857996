<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table" aria-hidden="true" *ngIf="apiKey">
              <tbody>
                <tr *ngIf="apiKey.id">
                  <td class="no-border-top"><b [translate]="'resources.api_keys.columns.id'"></b></td>
                  <td class="no-border-top">{{apiKey.id}}</td>
                </tr>
                <tr>
                  <td><b [translate]="'resources.api_keys.columns.status'"></b></td>
                  <td *ngIf="apiKey.enabled">
                    <span class="font-size-12 label label-success">
                      {{'resources.api_keys.status.enabled' | translate}}
                    </span>
                  </td>
                  <td *ngIf="!apiKey.enabled">
                    <span class="font-size-12 label label-danger">
                      {{'resources.api_keys.status.disabled' | translate}}
                    </span>
                  </td>
                </tr>
                <tr *ngIf="apiKey.description">
                  <td><b [translate]="'resources.api_keys.columns.description'"></b></td>
                  <td>{{apiKey.description}}</td>
                </tr>
                <tr>
                  <td><strong [translate]="'resources.api_keys.columns.system'"></strong></td>
                  <td *ngIf="apiKey.system"><span><i class="fa fa-check text-success"></i></span></td>
                  <td *ngIf="!apiKey.system"><span><i class="fa fa-times text-danger"></i></span></td>
                </tr>
                <tr *ngIf="apiKey.secret_key">
                  <td><b [translate]="'resources.api_keys.fields.secret'"></b></td>
                  <td>{{apiKey.secret_key}}</td>
                </tr>
                <tr *ngIf="apiKey.value">
                  <td><b [translate]="'resources.api_keys.fields.value'"></b></td>
                  <td>{{apiKey.value}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
