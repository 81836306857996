import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { Condition } from '../../../../../shared/models/segments/condition';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { MultiselectDataSourceNumeric } from '../../../../models/common/option';
import { Operator } from '../../../../models/segments/conditions/operator';
import { CustomerAttributeKey } from '../../../../services/conditions/services/customer-attribute-condition/enums/customer-attribute-key.enums';
import { CustomerAttributeType } from '../../../../services/conditions/services/customer-attribute-condition/enums/customer-attribute-type.enums';
import { resetInputsForm } from '../../utils/common.utils';

@Component({
  selector: 'app-customer-attribute-condition',
  templateUrl: './customer-attribute-condition.component.html',
  styleUrls: ['./customer-attribute-condition.component.css']
})
export class CustomerAttributeConditionComponent implements OnInit {
  
  wideLayout = 'col-md-6 col-lg-6 col-xl-3' as const;
  narrowLayout = 'col-md-4 col-xl-3' as const;
  customerAttributeKey = CustomerAttributeKey;
  customerAttributeType = CustomerAttributeType;

  get isStringAttribute(): boolean {
    return this.hasFormKeyWithValue(this.customerAttributeKey.DefinitionType, this.customerAttributeType.String);
  }
  
  get isIntegerAttribute(): boolean {
    return this.hasFormKeyWithValue(this.customerAttributeKey.DefinitionType, this.customerAttributeType.Integer);
  }
  
  get isIntegerBtOperator(): boolean {
    return this.isBtOperator(this.customerAttributeKey.IntegerOperator);
  }
  
  get isFloatAttribute(): boolean {
    return this.hasFormKeyWithValue(this.customerAttributeKey.DefinitionType, this.customerAttributeType.Float);
  }
  
  get isFloatBtOperator(): boolean {
    return this.isBtOperator(this.customerAttributeKey.FloatOperator);
  }
  
  get isBooleanAttribute(): boolean {
    return this.hasFormKeyWithValue(this.customerAttributeKey.DefinitionType, this.customerAttributeType.Boolean);
  }
  
  get isDatetimeAttribute(): boolean {
    return this.hasFormKeyWithValue(this.customerAttributeKey.DefinitionType, this.customerAttributeType.Datetime);
  }
  
  get isDateBtOperator(): boolean {
    return this.isBtOperator(this.customerAttributeKey.DateOperator);
  }
  
  get isAttributeWithBtOperator(): boolean {
    return this.isIntegerBtOperator || this.isFloatBtOperator || this.isDateBtOperator;
  }

  private get keyInputs(): string[] {
    return this.inputs.map(input => input.key);
  }
  
  @Input() item: Condition;
  @Input() inputs: QuestionBase<unknown>[];
  @Input() form: UntypedFormGroup;

  constructor(
    private qcs: QuestionControlService
  ) {}
  
  ngOnInit(): void {
    this.setInitialRequiredInputs(); // Visual purposes to show asterisk (validation controlled by customValidators)
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }
  
  hasFormKeyWithValue(formKey: string, value: unknown): boolean {
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  handleAttributeSelected(selectedAttribute: MultiselectDataSourceNumeric): void {
    if (selectedAttribute) {
      this.handleOperatorAndValueFields(selectedAttribute);
    } else {
      resetInputsForm(this.form, this.inputs, this.keyInputs);
    }
  }
  
  handleOperatorChanges(operator: Operator, keyValue2: string) {
    const op = operator?.hasOwnProperty('id') ? operator.id : null;
    if (!op || op !== 'bt') {
      resetInputsForm(this.form, this.inputs, [keyValue2]);
    }
  }
  
  private isBtOperator(operator: string): boolean {
    return this.hasFormKeyWithValue(operator, 'bt');
  }

  private handleOperatorAndValueFields(attribute: MultiselectDataSourceNumeric): void {
    const dataType = attribute['rawElement'] && attribute['rawElement']['data_type'] ? attribute['rawElement']['data_type'] : null;
    const definitionTypeInput = this.getInputConfig(this.customerAttributeKey.DefinitionType);
    definitionTypeInput.value = dataType;
    this.form.get(this.customerAttributeKey.DefinitionType).patchValue(dataType);
    const keyFieldsToReset = this.keyInputs.filter(key => key !== this.customerAttributeKey.DefinitionId && key !== this.customerAttributeKey.DefinitionType);
    resetInputsForm(this.form, this.inputs, keyFieldsToReset);
  }

  private setInitialRequiredInputs(): void {
    this.inputs.forEach(input => {
      if (input.key !== this.customerAttributeKey.DefinitionType) {
        input.required = true;
      }
    });
  }
}
