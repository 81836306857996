export class BigqueryJobsStepsBase {
  category: string;
  id: number;
  _id: number;
  _destroy: number;
  name: string;
  sort: number;
  details: boolean;

  constructor(options: {
    category?: string,
    id?: number,
    _id?: number,
    _destroy?: number,
    name?: string,
    sort?: number,
    details?: boolean;
  } = {}) {
    this.category = options.category || '';
    this.id = options.id || null;
    this._id = options._id || null;
    this._destroy = options._destroy || null;
    this.name = options.name || '';
    this.sort = options.sort || null;
    this.details = options.details || false;
  }

  public getPayload() {
    return {
      id: this.id,
      _id: this.id,
      sort: this.sort,
      category: this.category
    };
  }
}
