import { Injectable } from '@angular/core';
import { QuestionBase } from '../../models/forms/question-base';
import { MultiSelectQuestion } from '../../models/forms/question-multiselect';
import { SegmentsService } from '../../../resources/segments/segments.service';
import { FloatQuestion } from '../../models/forms/question-float';
import { TranslateService } from '@ngx-translate/core';
import { SegmentConditionProvider } from './condition.service';
import { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Validators } from '@angular/forms';
import { multiselectPresenceValidator } from '../validations.service';
import { SystemTagHistoriesService } from '../system-tag-histories.service';
import { MultiselectDataSource } from '../../components/multiselect/multiselect';
import { isNumber } from 'util';

@Injectable()
export class MaxSalesConditionService implements SegmentConditionProvider {

  inputs: QuestionBase<any>[];
  profile$: Subscription;
  segments$: Subscription;

  valueSource = new BehaviorSubject<any>(null);
  valueSource$ = this.valueSource.asObservable();

  customValidators = [() => null];

  constructor( private segmentService: SegmentsService,
               private tagHistoresService: SystemTagHistoriesService,
               private translate: TranslateService ) { }

  getInputs(params?: any): QuestionBase<any>[] {

    const paramsAreDefined = ( params && Object.keys(params).length > 0 );
    const segment = paramsAreDefined ? this.getSegmentFromParams(params) : [];
    const applies = paramsAreDefined ? this.getAppliesFromParams(params) : [];
    const indexApplies = paramsAreDefined ? this.getApplyIndexesFromParams(params) : [];

    if( segment && segment.length > 0 ) { this.tagHistoresService.setSegment( segment[0].id) }

    const inputs = [
      new MultiSelectQuestion({
        label: this.translate.instant('resources.segment_conditions.fields.best_sales_segment'),
        cssClasses: 'form-control input-md',
        key: 'segment_id',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        parseValue: (values) => ((values && values.length > 0) ? values[0].id : []),
        dataSource: this.segmentService,
        selectedIds: ( segment && segment.length > 0 ) ? [segment[0].id] : [],
        required: true,
        customValidators: [multiselectPresenceValidator]
      }),
      new FloatQuestion({
        label: this.translate.instant('resources.segment_conditions.fields.number_of_times'),
        key: 'number_of_times',
        cssClasses: 'form-control input-md',
        type: 'number',
        step: 1,
        value: ( params && params.number_of_times ) ? params.number_of_times : [],
        parseValue: (value) => value ? parseInt(value, 10) : null,
        required: true,
        customValidators: [Validators.min(1)]
      }),
      new MultiSelectQuestion({
        label: this.translate.instant('resources.segment_conditions.fields.static_tags'),
        cssClasses: 'form-control input-md',
        key: 'applies',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: false },
        parseValue: (values) => (values && values.length > 0) ? values.map( value => value.id ) : null,
        dataSource: this.tagHistoresService,
        selectedIds: applies
      }),
      new MultiSelectQuestion({
        label: this.translate.instant('resources.segment_conditions.fields.dynamic_tags'),
        cssClasses: 'form-control input-md',
        key: 'index_applies',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: false },
        options: this.getStaticTagList(),
        value: indexApplies,
        parseValue: (values) => ((values && values.length > 0) ? values.map( value => value.id ) : null)
      })
    ];

    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI( params: any ): any {
    const parsedValuesObj = {};
    Object.keys(params).forEach( key => {
      const input = this.inputs.find( _input => _input.key === key );
      const parsedValue = input.parseValue( params[key], parsedValuesObj );
      parsedValuesObj[key] = parsedValue;
    });
    return parsedValuesObj;
  }

  prepareFormValuesFromAPI( params: any ): any {
    const parseValues = {
      segment_id: (value) => (value) ? value : null,
      applies_ids: (value) => (value && value.length > 0) ? value : null,
      applies: (value) => (value && value.length > 0) ? value : null,
      index_applies: (value) => (value && value.length > 0) ? value : null,
      number_of_times: (value) => (value) ? value : null
    };
    const parsedValuesObj = {};
    Object.keys(params).forEach( key => { parsedValuesObj[key] = parseValues[key](params[key]); });
    return parsedValuesObj;
  }

  private getSegmentFromParams(params?){
    const segment = params.segment_id;
    if( segment && typeof(segment) === 'number' ){
      return [{ id: segment, name: segment }];
    } else if ( segment && segment.length > 0 && typeof(segment) === 'object' ){
      return segment;
    } else {
      return [];
    }
  }

  private getAppliesFromParams(params?) {
    if ( params.applies && params.applies.length > 0 && isNumber(params.applies[0]) ) {
      return params.applies;
    } else if ( params.applies && params.applies.length > 0 && params.applies[0] instanceof MultiselectDataSource ) {
      return params.applies.map(apply => apply.id);
    }
  }

  private getApplyIndexesFromParams(params?) {
    const indexApplies = params.index_applies;
    if ( indexApplies && indexApplies.length > 0 && typeof indexApplies[0] === 'number' ) {
      const selectedList = [];
      const totalElements = this.getStaticTagList();
      indexApplies.forEach((el) => {
        const value = totalElements.find( element => element.id === el );
        selectedList.push(value);
      });
      return selectedList;
    } else if ( indexApplies && indexApplies.length > 0 && typeof (indexApplies[0]) !== 'number' ) {
      return indexApplies;
    } else {
      return [];
    }
  }

  private getStaticTagList(): {id: number, name: string}[] {
    const tagList = [{id: 0, name: this.translate.instant('resources.segment_conditions.fields.last_tag')}];
    for (let index = 1; index <= 13; index++) {
      tagList.push({id: index, name: this.translate.instant('resources.segment_conditions.fields.last_tag') + ` - ${index}` })
    }
    return tagList;
  }
}
