<div class="row" *ngIf="inputs">

  <div class="col-md-4">
    <div class="form-group">
      <app-question [question]="getInputConfig('scope')" 
                    [form]="form"
                    (multiselectChanged)="handleScopeChanged($event)"></app-question>
    </div>
  </div>

  <ng-container *ngIf="mode === 'custom'">
    <div class="col-md-4">
      <div class="form-group">
        <app-question [question]="getInputConfig('operator')"
                      [form]="form"
                      (multiselectChanged)="handleOperatorChanged($event)"></app-question>
      </div>
    </div>
    <div class="col-md-4" *ngIf="!extraValues">
      <div class="form-group">
        <app-question [question]="getInputConfig('value')" [form]="form"></app-question>
      </div>
    </div>
    <div class="col-md-4" *ngIf="extraValues">
      <div class="form-group">
        <app-question [question]="getInputConfig('value_array')" [form]="form"></app-question>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="mode === 'customf'">
    <div class="col-md-4">
      <div class="form-group">
        <app-question [question]="getInputConfig('operatorF')"
                      [form]="form"
                      (multiselectChanged)="handleOperatorChanged($event)"></app-question>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <app-question [question]="getInputConfig('valueF')" [form]="form"></app-question>
      </div>
    </div>
    <div class="col-md-2" *ngIf="hasFormKeyWithValue('operatorF', 'bt')">
      <div class="form-group">
        <app-question [question]="getInputConfig('value_bt')" [form]="form"></app-question>
      </div>
    </div>
  </ng-container>
</div>
