import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CustomerJourney } from '../../../models/customer-journeys/customer-journey';
import { CustomerJourneysService } from '../../../../resources/customer-journeys/customer-journeys.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateService } from '../../../services/date.service';
import { CampaignCategoriesService } from '../../../../resources/campaigns/campaign-categories/campaign-categories.service';
import { ConfirmationService } from '../../../services/confirmation.service';
import { ModalStatusService } from '../../../services/modal-status.service';
import { CampaignsService } from '../../../../resources/campaigns/campaigns.service';
import { QuestionBase } from '../../../models/forms/question-base';
import { MultiSelectQuestion } from '../../../models/forms/question-multiselect';
import { QuestionControlService } from '../../../services/question-control.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CheckboxQuestion } from '../../../models/forms/question-checkbox';
import { TextboxQuestion } from '../../../models/forms/question-textbox';
import { FloatQuestion } from '../../../models/forms/question-float';
import { MultiselectDataSource } from '../../multiselect/multiselect';

@Component({
  selector: 'app-custom-journey',
  templateUrl: './custom-journey.component.html',
  styleUrls: ['./custom-journey.component.css']
})

export class CreateUpdateCustomCustomerJourneyComponent implements OnInit {

  @Output() onStatusEmit:   EventEmitter<any> = new EventEmitter();
  public saving             = false;

  public journey:           CustomerJourney;
  public dataLabel:         string;
  public journeyHasChanges: boolean;
  public loading:           boolean;
  public inputs:            QuestionBase<any>[] = [];
  public campaignInputs:    QuestionBase<any>[] = [];
  public form:              UntypedFormGroup;
  public payLoad:           object = {};
  public errors:            {field: string, message: string}[];

  public defaultInputNum = 4;

  constructor( private journeyService:            CustomerJourneysService,
               private translate:                 TranslateService,
               private route:                     ActivatedRoute,
               private dateService:               DateService,
               private campaignCategoriesService: CampaignCategoriesService,
               private confirmationService:       ConfirmationService,
               private router:                    Router,
               private modalStatusService:        ModalStatusService,
               private campaignsService:          CampaignsService,
               private qcs:                       QuestionControlService ) { }

  public ngOnInit() {
    this.getParams();
    this.loading = true;
    this.form = this.qcs.toFormGroup([]);
  }

  public journeyNameChanged(event) {
    this.journeyHasChanges = true;
    this.journey.nameHasChanged = true;
    this.journey.name = event.target.value;
  }

  public handleCloseModal() {
    if ( this.journeyHasChanges ) {
      this.showExitConfimationMsg();
    } else {
      this.closeModal();
    }
  }

  public addCampaign() {
    const key = `step.campaign_${this.campaignInputs.length}`;
    const newInput = this.createCampaignInput(key, []);
    const newFormControl = new UntypedFormControl(newInput.value);

    this.form.addControl(key, newFormControl);
    this.campaignInputs.push(newInput);
  }

  public removeCampaign(campaignInput: QuestionBase<any>, _index: number) {

    const input = this.campaignInputs.find( _input => _input.key === campaignInput.key );
          input.hidden = true;

    if ( input.value && input.value.length ) {
      const step = this.journey.steps.find( _step => _step.campaign_id === input.value[0].id );
      if (step) {
        input.value.id = step.id;
        input.value._destroy = true;
      } else {
        this.form.removeControl(input.key);
      }
    } else {
      this.form.removeControl(input.key);
    }
  }

  public onSubmit() {
    this.errors = null;
    this.saving = true;
    this.journeyService.saveCustomJourneyWithFormValues(this.journey, this.form.value).subscribe(
      successData => {
        this.saving = false;
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.journeys.messages.operation_successful_title'),
          this.translate.instant('resources.journeys.messages.operation_successful_description'),
        ).then(data => {
          if (data.hasOwnProperty('value') && data.value) {
            this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
            this.modalStatusService.modalStatus.emit();
          }
        }).catch(() => {});
      },
      errorData => {
        this.saving = false;
        this.parseErrors(errorData.error.errors);
      }
    );
  }

  public handleMultiselectChanged( input: QuestionBase<any>, value: MultiselectDataSource ) {
    if ( input.selectedIds.length ) {
      const previousValue = input.selectedIds[0];
      const stepCampaignIds = this.journey.steps.map( step => step.campaign_id );
      if ( stepCampaignIds.includes(previousValue) ) {
        const step = this.journey.steps.find( _step => _step.campaign_id === previousValue );
        input.value.id = step.id;
        input.selectedIds = [parseInt(value.id, 10)];
      }
    }
  }

  public isLastCampaignEmpty(): boolean {
    const visibleInputs = this.campaignInputs.filter( _input => !_input.hidden );
    if ( !visibleInputs.length ) { return false; }
    return visibleInputs[visibleInputs.length - 1].value === undefined ||
           visibleInputs[visibleInputs.length - 1].value.length <= 0;
  }

  private showExitConfimationMsg() {
    this.confirmationService.displayConfirmationAlert(
      this.translate.instant('resources.journeys.warnings.pending_changes'),
      this.translate.instant('resources.journeys.warnings.pending_changes_desc')).then(
        data => {
          if (data.hasOwnProperty('value') && data.value) {
            this.closeModal();
          }
        }
    ).catch(() => {});
  }

  private parseErrors(errorsArray) {
    this.errors = errorsArray.map( errorItem => {
      const field = errorItem.field_name.indexOf('translation missing') >= 0 ? '' : errorItem.field;
      const message = errorItem.message;
      return { field: field, message: message };
    });
  }

  private closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    this.modalStatusService.modalStatus.emit();
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('history_id') && params.history_id > 0) {
        this.getJourneyHistory(params.id, params.history_id);
      } else {
        this.getJourney(params.id);
      }
    });
  }

  private getJourney(id: number) {
    this.journeyService.getCustomerJourneyById(id).subscribe(
      journeyApiData => {
        this.loadJourney(journeyApiData);
      }
    );
  }

  private getJourneyHistory(id: number, historyId: number) {
    this.journeyService.getCustomerJourneyHistory(id, historyId).subscribe(
      journeyHistoryApiData => {
        this.loadJourney(journeyHistoryApiData);
      }
    );
  }

  private loadJourney(journeyData) {
    this.loading = false;
    this.journey = this.journeyService.initByType(journeyData);
    if ( this.journey._original ) {
      this.dataLabel = this.translate.instant('resources.journeys.fields.next');
    } else {
      this.dataLabel = this.dateService.parseDateWithFormat(this.journey.scheduled_at, 'DD/MM/YYYY');
    }
    this.defineDefaultInputs();
    this.form = this.qcs.toFormGroup(this.inputs);
    this.defineStepsInput();
  }

  private defineStepsInput() {
    this.campaignInputs = this.journey.steps.map(
      (step, index) => (this.createCampaignInput(`step.campaign_${index}`, [step.campaign_id]))
    );
    this.campaignInputs.forEach( campaignInput => this.qcs.addToFormGroup(this.form, campaignInput));
  }

  private createCampaignInput(key: string, selectedIds: number[]) {
    return new MultiSelectQuestion({
      cssClasses: 'form-control input-md',
      key: key,
      dataSource: this.campaignsService,
      settings: {
        singleSelection: true,
        enableCheckAll: false,
        showCheckbox: false,
        enableSearchFilter: true,
        text: this.translate.instant('resources.journeys.messages.select_campaign')
      },
      selectedIds: selectedIds
    });
  }

  private defineDefaultInputs() {
    this.inputs = [
      new TextboxQuestion({
        cssClasses: 'form-control font-size-22',
        key: 'name',
        order: 1,
        type: 'text',
        value: this.journey.name
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'reportable',
        label: this.translate.instant('resources.journeys.fields.include_in_reports'),
        order: 2,
        value: this.journey.reportable
      }),
      new FloatQuestion({
        cssClasses: 'form-control input-md',
        key: 'prediction_percentage_redemption',
        label: this.translate.instant('resources.journeys.fields.redemption_percentage'),
        order: 3,
        type: 'number',
        min: 1,
        step: 0.1,
        value: this.journey.report_configuration.reward.prediction_percentage_redemption
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'campaign_category',
        label: 'resources.journeys.fields.campaign_category',
        dataSource: this.campaignCategoriesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 4,
        selectedIds: this.journey.campaign_category_id
      })
    ];
  }
}
