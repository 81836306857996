import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class VersionsService extends ResourcesService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getVersionById(id: number): Observable<any> {
    return this.getData({apiEndPoint: `versions/${id}`});
  }

}
