import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { LocationsTaxonomyTermsService } from '../location-taxonomy-terms.service';
import { Subject } from 'rxjs';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-form-location-taxonomies-terms',
  templateUrl: './form-location-taxonomies-terms.component.html',
  styleUrls: ['./form-location-taxonomies-terms.component.css']
})

export class FormLocationTaxonomiesTermsComponent implements OnInit, OnDestroy {

  locationTaxonomiesTermsForm: UntypedFormGroup;
  inputs: QuestionBase<any>[];
  id: number;

  private destroy$: Subject<void> = new Subject<void>();
  
  private get isEditMode(): boolean {
    return !!this.id;
  }

  @Output() formValidity: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private modalStatusService: ModalStatusService,
    private qcs: QuestionControlService,
    private locationsTaxonomyTermsService: LocationsTaxonomyTermsService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getParams();
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }
  
  hasFormKeyWithValue(formKey: string, value: boolean): boolean {
    return this.qcs.hasFormKeyWithValue(this.locationTaxonomiesTermsForm, formKey, value);
  }

  sendData(): void {
    const payload = this.handlePayload();
    this.modalStatusService.formLoaderStatus.emit('loading');
    const request$ = this.isEditMode ? this.locationsTaxonomyTermsService.updateData(payload, this.id) : this.locationsTaxonomyTermsService.sendData(payload);
    request$.pipe(takeUntil(this.destroy$)).subscribe(
      () => this.handleSuccessRequest(),
      (errorData: HttpErrorResponse) => this.handleErrors(errorData)
    );
  }
  
  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getLocationTaxonomiesTermsById();
      } else {
        this.setInputsAndForm({});
      }
    });
  }

  private getLocationTaxonomiesTermsById(): void {
    this.locationsTaxonomyTermsService.getLocationTaxonomyTermsById(this.id).pipe(takeUntil(this.destroy$)).subscribe(
      (data: HttpResponse<object>) => this.setInputsAndForm(data),
      (errorData: HttpErrorResponse) => this.displayErrorAlert(errorData.error.error)
    );
  }
  
  private setInputsAndForm(requestData: object | HttpResponse<object>): void {
    this.inputs = this.locationsTaxonomyTermsService.getInputs(requestData);
    this.locationTaxonomiesTermsForm = this.qcs.toFormGroup(this.inputs);
    this.formStatusChanges();
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
  }
  
  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.locationTaxonomiesTermsForm.status));
    this.locationTaxonomiesTermsForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
  }

  private handlePayload(): object {
    const dynamic = this.locationTaxonomiesTermsForm.value.dynamic;    
    const payload = {
      name: this.locationTaxonomiesTermsForm.value.name,
      external_id: this.locationTaxonomiesTermsForm.value.external_id,
      taxonomy_slug: this.locationTaxonomiesTermsForm.value.taxonomy_slug?.length ? this.locationTaxonomiesTermsForm.value.taxonomy_slug[0].id : null,
      dynamic
    };

    if (dynamic) {
      payload['dynamic_options'] = { sql: this.locationTaxonomiesTermsForm.value.sql };
    } else {
      payload['location_ids'] = this.locationTaxonomiesTermsForm.value.location_ids?.length ? this.locationTaxonomiesTermsForm.value.location_ids.map(location => location.id) : [];
    }

    return payload;
  }

  private handleSuccessRequest(): void {
    const successTitle = this.translate.instant(this.isEditMode ? 'resources.location_taxonomy_terms.warnings.update_title' : 'resources.location_taxonomy_terms.warnings.success_title');
    const successText = this.translate.instant(this.isEditMode ? 'resources.location_taxonomy_terms.warnings.update_text' : 'resources.location_taxonomy_terms.warnings.success_text');
    this.confirmationService.displaySuccessAlert(successTitle, successText).catch(() => {});
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    this.modalStatusService.modalStatus.emit();
    this.returnToList();
  }
  
  private handleErrors(errorData: HttpErrorResponse): void {
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    const errorValue = errorData?.error?.error ? errorData.error.error : null;
    if (errorData?.error?.errors?.length) {
      this.qcs.paintErrorsInForm(this.inputs, this.locationTaxonomiesTermsForm, errorData.error.errors);
      if (errorValue) { this.displayErrorAlert(errorValue); }
    } else if (errorValue) {
      this.displayErrorAlert(errorValue);
    }
  }

  private displayErrorAlert(error: string): void {
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), error);
  }

  private returnToList(): void {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/data-warehouse/locations/location_taxonomy_terms']).catch(() => {});
    }
  }
}
