import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class ContentDesignerService extends ResourcesService {

  constructor(http: HttpClient,
              private translate: TranslateService) {
    super(http);
  }

  public getMessageMotivators() {
    const requestOptions = {
      apiEndPoint: 'messages',
      sorting: {
        byProp: 'created_at',
        direction: 'desc'
      },
      filtering: {},
      numberPerPage: 300
    };

    return this.getData(requestOptions);
  }

  public getMessagesStatusList() {
    return [
      { id: 'all', name: this.translate.instant('common.all') },
      { id: 'deleted', name: this.translate.instant('resources.messages.states.deleted') },
      { id: 'expired', name: this.translate.instant('resources.messages.states.expired') },
      { id: 'pending', name: this.translate.instant('resources.messages.states.pending') },
      { id: 'printed', name: this.translate.instant('resources.messages.states.printed') },
      { id: 'redeemed', name: this.translate.instant('resources.messages.states.redeemed') },
      { id: 'selected', name: this.translate.instant('resources.messages.states.selected') },
      { id: 'view', name: this.translate.instant('resources.messages.states.viewed') },
      { id: 'cancelled', name: this.translate.instant('resources.messages.states.cancelled') }
    ];
  }

  public getMessageCategories() {

    const requestOptions = {
      apiEndPoint: 'message_categories',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };

    return this.getData(requestOptions);
  }

  public duplicateTemplate(id: number) {
    return this.putResource({id: id}, 'campaign_templates/' + id );
  }

  public deleteTemplate(id: number) {
    return this.deleteResource({id: id}, 'campaign_templates/' + id );
  }

  public loadMessagesWithCsv(csv_text: string) {
    return this.postResource({messages_csv: csv_text}, 'messages/csv');
  }

  public createCampaignTemplate(data) {
    return this.postResource(data, 'campaign_templates');
  }

  public updateCampaignTemplateById(data: any, _id: number) {
    return this.patchResource(data, `campaign_templates/${_id}`);
  }

  public calculateSmsMetrics(data): Observable<any> {
    return this.postResource({message: data}, 'campaign_templates/sms_metrics');
  }
}
