import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-retail-demo',
  templateUrl: './card-retail-demo.component.html',
  styleUrls: ['./card-retail-demo.component.scss']
})
export class CardRetailDemoComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() icon: string;
  @Input() iconColor: string;
  @Input() checked = false;
  @Input() link: string;
}
