import { CustomerJourneyStep, CustomerJourneyStepCfg } from './customer-journey-step';

export interface CustomerJourneysCfg {
  className?: string;
  coupon?: boolean;
  channels?: boolean;
  message?: boolean;
  period?: boolean;
  activity_tag?: boolean;
  sales_tags?: boolean;
  best_sales_tag?: boolean;
  best_sales_tags?: boolean;
  feature_id?: boolean;
  feature_ids?: boolean;
  location_taxonomy_term_id?: boolean;
  location_taxonomy_term_ids?: boolean;
  location_ids?: boolean;
  extra_segment?: true;
  steps_number?: boolean;
  coupons?: number;
  messages?: number;
  tabs?: string[];
  defaultName?: string;
  multiselectOptions?: any;
  steps?: CustomerJourneyStepCfg[];
  id?: number;
  name?: string;
  origin_type?: string;
  type?: string;
  configuration?: any;
  reportable?: boolean;
  nameHasChanged?: boolean;
  pretty_configuration: {};
  report_configuration: {
    reward?: {
      prediction_percentage_redemption?: number;
    },
    reward1?: {
      prediction_percentage_redemption?: number;
    },
    reward2?: {
      prediction_percentage_redemption?: number;
    }
  };
  status?: string;
  scheduled_at?: string;
  campaign_category_id?: any[];
  _original: boolean;
}

export class CustomerJourney {

  public name: string;
  public id: number;
  public type: string;
  public origin_type: string;
  public configuration: any;
  public steps: CustomerJourneyStep[] = [];
  public reportable: boolean;
  public status: string;
  public _original: boolean;
  public scheduled_at: string;
  public nameHasChanged: boolean;
  public campaignsBlockHidden = false;
  public contentBlockHidden = true;
  public summaryBlockHidden = false;
  public campaign_category_id: any[];
  public pretty_configuration: {};
  public report_configuration = {
    reward: {
      prediction_percentage_redemption: 0.0
    },
    reward1: {
      prediction_percentage_redemption: 0.0
    },
    reward2: {
      prediction_percentage_redemption: 0.0
    }
  };

  constructor(public cfg: CustomerJourneysCfg ) {
    this.initJourney(cfg);
    this.initJorneySteps(cfg.steps);
  }

  private initJourney(cfg) {
    this.origin_type = cfg.origin_type;
    this.type = cfg.type;
    this.name = cfg.name;
    this.id = cfg.id;
    this.reportable = cfg.reportable || false;
    this.report_configuration = cfg.report_configuration || {};
    this.pretty_configuration = cfg.pretty_configuration;

    this.configuration = {};
    this.configuration.best_sales_tag = this.getSelectedItemByKeyId(cfg, 'best_sales_tag');
    this.configuration.best_sales_tags = this.getSelectedArrayByKeyId(cfg, 'best_sales_tags');
    this.configuration.extra_segment = this.getSelectedItemByKeyId(cfg, 'extra_segment');
    this.configuration.location_terms = this.getSelectedArrayByKeyId(cfg, 'location_terms');
    this.configuration.sales_tags = this.getSelectedArrayByKeyId(cfg, 'sales_tags');
    this.configuration.features = this.getSelectedArrayByKeyId(cfg, 'features');
    this.configuration.coupon = this.getSelectedArrayByKeyId(cfg, 'coupon');
    // this.configuration.coupon_id = cfg.configuration.coupon_id.length ? cfg.configuration.coupon_id[0] : [];

    if ( cfg.configuration.hasOwnProperty('message') && cfg.configuration.message ) {
      this.configuration.message = cfg.configuration.message;
    }

    if ( cfg.configuration.hasOwnProperty('activity_tag') && cfg.configuration.activity_tag ) {
      this.configuration.activity_tag = [{id: cfg.configuration.activity_tag}];
    } else {
      this.configuration.activity_tag = [];
    }

    this.status = cfg.status;
    this.scheduled_at = cfg.scheduled_at;
    this.nameHasChanged = false;

    if ( cfg.hasOwnProperty('report_configuration') &&
         cfg.report_configuration.hasOwnProperty('reward') &&
         cfg.report_configuration.reward.hasOwnProperty('prediction_percentage_redemption')) {
      const percentage = cfg.report_configuration.reward.prediction_percentage_redemption;
      this.report_configuration.reward.prediction_percentage_redemption = percentage;
    } else {
      this.report_configuration.reward = {
        prediction_percentage_redemption: 0.0
      };
    }

    if ( cfg.hasOwnProperty('report_configuration') &&
         cfg.report_configuration.hasOwnProperty('reward1') &&
         cfg.report_configuration.reward1.hasOwnProperty('prediction_percentage_redemption')) {
      const percentage = cfg.report_configuration.reward1.prediction_percentage_redemption;
      this.report_configuration.reward1.prediction_percentage_redemption = percentage;
    } else {
      this.report_configuration.reward1 = {
        prediction_percentage_redemption: 0.0
      };
    }

    if ( cfg.hasOwnProperty('report_configuration') &&
         cfg.report_configuration.hasOwnProperty('reward2') &&
         cfg.report_configuration.reward2.hasOwnProperty('prediction_percentage_redemption')) {
      const percentage = cfg.report_configuration.reward2.prediction_percentage_redemption;
      this.report_configuration.reward2.prediction_percentage_redemption = percentage;
    } else {
      this.report_configuration.reward2 = {
        prediction_percentage_redemption: 0.0
      };
    }

    this._original = !cfg.hasOwnProperty('status');

    if ( cfg.name === '') {
      this.name = this.getName();
    } else if (cfg.name !== this.getName()) {
      this.name = cfg.name;
      this.nameHasChanged = true;
    } else {
      this.name = this.getName();
      this.nameHasChanged = false;
    }
  }

  public initJorneySteps(steps) {
    steps.forEach(stepData => {
      stepData.customer_journey_type = this.type;
      this.steps.push(new CustomerJourneyStep(stepData));
    });
  }

  public getDefinitionStepFormCfg(): {}[] {
    console.error('CustomerJourney - getDefinitionStepFormCfg()', 'This method should be defined by each C. Journey type');
    return [];
  }

  public getCampaignsStepFormCfg(): {}[] {
    console.error('CustomerJourney - getCampaignsStepFormCfg()', 'This method should be defined by each C. Journey type');
    return [];
  }

  public filterOptionsByKey(options: Array<any>, step: any): Array<any> {
    // Exclude tag 0 for best sales momentos (Álgido)
    if (step.key === 'best_sales_tag') {
      options = options.filter(opt => opt.name !== '0');
    }
    return options;
  }

  public getExtraSegment(cfg) {
    if ( cfg.pretty_configuration.hasOwnProperty('extra_segment') &&
         cfg.pretty_configuration.extra_segment !== null &&
         cfg.pretty_configuration.extra_segment !== undefined ) {
      return [{
        id: cfg.pretty_configuration.extra_segment.id,
        name: cfg.pretty_configuration.extra_segment.name
      }];
    } else {
      return [];
    }
  }

  public getSelectedItemByKeyId(cfg, keyId) {
    if ( cfg.pretty_configuration.hasOwnProperty(keyId) &&
         cfg.pretty_configuration[keyId] !== null &&
         cfg.pretty_configuration[keyId] !== undefined ) {
      return [{
        id: cfg.pretty_configuration[keyId].id,
        name: cfg.pretty_configuration[keyId].name
      }];
    } else {
      return [];
    }
  }

  public getSelectedArrayByKeyId(cfg, keyId) {
    if ( cfg.pretty_configuration.hasOwnProperty(keyId) &&
         cfg.pretty_configuration[keyId].length > 0) {
      const idNameArrObject = cfg.pretty_configuration[keyId].map(
        element => Object.assign({id: element.id, name: element.name}, {})
      );
      return idNameArrObject;
    } else {
      return [];
    }
  }

  /**
   * Get in real time c. journey / c. journey step name regarding its definition step.
   */
  public getStepName(step: CustomerJourneyStep): string {
    const prefix = this.nameHasChanged ? this.name : this.getNameWithAttributes();
    const suffix = this.getNameSuffix(step);
    return `${prefix} ${suffix}`;
  }

  public isEditableStep(step: CustomerJourneyStep): boolean {
    const steps = [...this.steps];
    const latestStepDone = steps.sort((_step) => _step.position).reverse().find((_step) => _step.status === 'done');
    const byBlockStatus = (!latestStepDone || latestStepDone.order < step.order);
    const bySelfStatus = (['done', 'enqueued', 'running'].indexOf(step.status) === -1);

    return byBlockStatus && bySelfStatus;
  }

  public getNameSuffix(step: CustomerJourneyStep): string {
    console.error('Method not implemented');
    return '';
  }

  public definitionValid(): boolean {
    console.error('Method not implemented');
    return false;
  }

  public campaignsValid(): boolean {
    console.error('Method not implemented');
    return false;
  }

  public contentValid(): boolean {
    console.error('Method not implemented');
    return false;
  }

  public summaryValid(): boolean {
    console.error('Method not implemented');
    return false;
  }

  public prepareToSave() {
    console.error('Method not implemented');
  }

  public pendingSteps() {
    return this.steps.filter( step => step.status === 'pending');
  }

  public showableSteps() {
    return this.steps.filter( step => step.status !== 'cancelled');
  }

  public finalPendingStep() {
    return this.pendingSteps().length === 1 ? true : false;
  }

  public prepareHistoryToSave() {
    const config = this.prepareToSave();
    config['history_steps'] = config['steps'];
    delete config['steps'];
    return config;
  }

  public validToDeliver() {
    return this.definitionValid() && this.campaignsValid() && this.summaryValid() && this.contentValid();
  }

  public getName(): string {
    return this.nameHasChanged ? this.name : this.getNameWithAttributes();
  }

  public getNameWithAttributes(): string {
    console.error('Method not implemented');
    return '';
  }

  public hasMessage(): boolean {
    return this.configuration.hasOwnProperty('message') && this.configuration.message.length > 0;
  }
}
