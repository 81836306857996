import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CouponHistoriesService } from '../../../coupons/coupon-histories.service';
import { CouponsService } from '../../../coupons/coupons.service';
import { DataTableComponent } from '../../../../shared/components/data-table/data-table.component';
import { UntypedFormGroup } from '@angular/forms';
import { getCurrencySymbol } from '@angular/common';
import { getTranslationInputs } from '../form-coupons/coupon-utils/coupon-utils';
import { LocationsService } from '../../locations/locations.service';
import { MultiselectDataSource } from '../../../../shared/components/multiselect/multiselect';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { ProfileService } from '../../../../profiles/profile.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { registerLocaleData } from '@angular/common';
import { ShowStatisticsComponent } from './show-tab-statistics/show-statistics.component';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import es from '@angular/common/locales/es';
import { Modules } from '../../../../shared/enums/modules.enums';
import { CDP_ROUTES } from '../../../../shared/constants/routes.constants';

@Component({
  selector: 'app-show-coupons',
  templateUrl: './show-coupons.component.html',
  styleUrls: ['./show-coupons.component.css']
})

export class ShowCouponsComponent implements OnInit, OnDestroy {

  activeTab = {
    name: 'details',
    title: this.translate.instant('resources.coupons.show.modal.title')
  };
  apiEndPoint: string;
  apply: number;
  companyId: number = this.profileService.getStoredUser().company.id;
  couponData: any;
  couponHistoryData: any;
  currency: string;
  currencySymbol: string;
  currentRole = this.profileService.getStoredUserRole();
  dataTableConfig: any;
  element: any;
  filteredAvailableLanguages: any[];
  history_id: number;
  historyId: any;
  id: number;
  isMissingImg: any;
  isModal: boolean;
  loading: boolean;
  location_id: number | string;
  mode: string;
  roleSlug: string;
  selectedMotivator: any;
  selectedTab: string;
  showCouponInputs: QuestionBase<any>[];
  showCouponsForm: UntypedFormGroup;
  slug = 'coupons';
  subs$: Subscription[] = [];

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;
  @ViewChild('statisticsComponent') statisticsComponent: ShowStatisticsComponent;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private couponHistoriesService: CouponHistoriesService,
    private couponsService: CouponsService,
    private locations: LocationsService,
    private profileService: ProfileService,
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
    registerLocaleData(es);
    this.isModal = this.router.url.indexOf('(modal:show/coupons/') >= 0;
    this.roleSlug = this.profileService.getStoredUserRole();
    this.getParams();
    this.loading = true;
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  refreshUrl(tabId) {
    this.selectedTab = tabId;
    this.changeDetector.detectChanges();
  }

  selectTab(slug) {
    this.changeTitle(slug);
    this.slug = slug;
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.showCouponInputs, inputKey);
  }

  handleHistoryChange(motivator: MultiselectDataSource) {
    this.selectedMotivator = motivator;
    if (!motivator) {
      this.couponsService.getCouponById(this.id).subscribe(couponData => {
        this.loading = false;
        this.element = couponData;
        this.filteredAvailableLanguages = this.element.available_languages;
      });
      this.historyId = null;
      return;
    }

    if (motivator && motivator.rawElement['campaign_history_id']) {
      this.apply = motivator.rawElement['campaign_history_id'];
      this.historyId = motivator.id;
      const historySub$ = this.couponsService.getData({ apiEndPoint: `coupons/${this.id}/histories/${motivator.id}` }).subscribe(resp => {
        historySub$.unsubscribe();
        this.element = resp;
        this.addCouponFields();
        this.filteredAvailableLanguages = this.element.available_languages;
        if (this.statisticsComponent) {
          this.statisticsComponent.getCouponData(this.apply);
          this.statisticsComponent.dataTable.getData({refreshRows: true});
        }
      }, () => historySub$.unsubscribe());
      this.subs$.push(historySub$);
    }
  }

  handleLocationsChange(locations) {
    if (locations) {
      this.location_id = locations.id;
      const endpoint = `coupons/${this.id}/status?apply=${this.apply}&field=status&location_ids=${this.location_id}`;
      this.statisticsComponent.getCouponDataByEndpoint(endpoint);
      this.statisticsComponent.getLocationId(this.location_id);
    }
  }

  closeModal(): void {
    const url = this.router.url;
    if (this.currentRole === 'customer_service' && url.includes(Modules.CustomerDataPlatform)) {
      this.router.navigate([CDP_ROUTES.CustomersProfiles]).then(() => {
        if (this.isModal) { this.noModalRouterNavigation(); }
      }).catch(() => {});
    } else if (this.isModal) {
      this.noModalRouterNavigation();
    } else {
      this.router.navigate(['/coupons/coupons']).catch(() => {});
    }
  }

  private getParams() {
    const routeSubscription = this.route.params.subscribe(params => {
      if (params.id) {
        if (this.router.url.indexOf(`(modal:`) >= 0) {
          this.mode = 'show';
          this.historyId = params.history_id || null;
        }
        this.id = params.id;
        this.apiEndPoint = 'coupons';
        this.couponHistoriesService.setCoupon(this.id);
        this.getSelectedTab();
        this.setDataTableConfig();
        this.getCouponById(params);
      }
    });
    this.subs$.push(routeSubscription);
  }

  private getCouponById(params) {
    this.couponsService.getCouponById(this.id).toPromise().then(couponData => {
      this.loading = false;
      this.couponData = couponData;
      this.element = couponData;
      this.currency = couponData['discount_type'] === 'cash' ? `${this.currencySymbol}` : '%';
      this.selectedMotivator = null;

      if (this.element.thumbnail === 'images/thumbnail/missing.png') {
        this.isMissingImg = this.element.thumbnail;
      }

      if (params.history_id) {
        this.history_id = params.history_id;
        this.getCouponHistoryById(this.id, this.history_id);
      }

      if (!this.showCouponsForm) {
        this.setShowCouponsInputs();
        this.filteredAvailableLanguages = this.couponData.available_languages;
        this.showCouponsForm = this.qcs.toFormGroup(this.showCouponInputs);
        getTranslationInputs(this.couponData, this.qcs, this.showCouponsForm, this.showCouponInputs, this.mode);
      } else {
        const couponInp = this.showCouponInputs.find( input => input.key === 'coupon');
        couponInp.value = [];
        couponInp.selectedIds = [];
        this.dataTable.getData();
      }
    });
  }

  private setDataTableConfig() {
    this.dataTableConfig = {
      isActive: true,
      tableActions: [
        {
          name: this.translate.instant('components.table_actions.export_csv'),
          icon: 'ei-export',
          id: 'exportCSV'
        }
      ],
      columns: [
        {
          name: this.translate.instant('resources.coupons.modal.show.details.table.columns.campaign_name'),
          prop: 'campaign_name',
          sortByField: 'campaign_name'
        },
        {
          name: this.translate.instant('resources.coupons.modal.show.details.table.columns.sent_at'),
          prop: 'sent_at',
          sortByField: 'date'
        },
        {
          name: this.translate.instant('resources.coupons.modal.show.details.table.columns.available_from'),
          prop: 'available_from',
          sortByField: 'name'
        },
        {
          name: this.translate.instant('resources.coupons.modal.show.details.table.columns.available_to'),
          prop: 'available_to',
          sortByField: 'sent_at'
        },
        {
          name: this.translate.instant('resources.coupons.modal.show.details.table.columns.static_code'),
          prop: 'static_code'
        },
        {
          name: this.translate.instant('resources.coupons.modal.show.details.table.columns.locations'),
          prop: 'locations'
        }
      ],
      requestData: {
        translationResource: 'resources.coupons.modal.show.details.table.columns.',
        apiEndPoint: `coupons/${this.id}/histories`,
        pageNumber: 0,
        sorting: {
          byProp: '',
          direction: ''
        },
        filtering: {},
        numberPerPage: 10
      },
      tableMapping: [
        { prop: 'id', type: 'number', apiProp: 'id' },
        { prop: 'campaign_name', type: 'text', apiProp: '_embedded.campaign.name' },
        { prop: 'sent_at', type: 'date_time', apiProp: 'sent_at' },
        { prop: 'available_from', type: 'date', apiProp: 'available_from' },
        { prop: 'available_to', type: 'date', apiProp: 'available_to' },
        { prop: 'static_code', type: 'number', apiProp: 'static_code' },
        { prop: 'locations', type: 'text', apiProp: 'locations' }
      ]
    };
  }

  private getSelectedTab() {
    if ( this.router.url.indexOf(`modal:show/coupons/${this.id}/statistics`) >= 0 ) {
      this.selectedTab = 'statistics';
    } else {
      this.selectedTab = 'details';
    }
    this.changeDetector.detectChanges();
  }

  private assignFormInputValues() {
    this.couponHistoriesService.setCoupon(this.id);
    if ( this.history_id ) {
      this.showCouponInputs.find(input => input.key === 'coupon' ).selectedIds = [this.history_id];
    }
  }

  private getCouponHistoryById(id: number, history_id) {
    this.couponHistoriesService.getCouponHistoryById(id, history_id).toPromise().then(
      couponHistoryData => {
        this.apply = couponHistoryData['campaign_history_id'];
        couponHistoryData['id'] = id;
        this.element = couponHistoryData;
        this.addCouponFields();
        if (this.statisticsComponent) {
          this.statisticsComponent.getCouponData(this.apply);
        }
      }
    );
  }

  private changeTitle(slug) {
    this.activeTab.name = slug;
    this.activeTab.title = this.translate.instant(`resources.coupons.show.modal.tabs.${slug}`);
  }

  private setShowCouponsInputs() {
    this.showCouponInputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'coupon',
        dataSource: this.couponHistoriesService,
        settings: {
          singleSelection: true,
          enableCheckAll: false,
          showCheckbox: false,
          enableSearchFilter: true
        },
        order: 1,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'locations',
        dataSource: this.locations,
        settings: {
          singleSelection: true,
          enableCheckAll: false,
          showCheckbox: false,
          enableSearchFilter: true
        },
        order: 2
      })
    ];
    this.assignFormInputValues();
  }

  private addCouponFields() {
    this.element['funded'] = this.couponData.funded;
    this.element['provider_id'] = this.couponData.provider_id;
  }

  private noModalRouterNavigation(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }
}
