import { CustomerAttributeCondition, CustomerAttributeConditionForm } from "../../../../../models/segments/conditions/customer-attribute-condition";
import { CustomerAttributeKey } from "../enums/customer-attribute-key.enums";
import { CustomerAttributeType } from "../enums/customer-attribute-type.enums";

export function parseFormValuesToAPI(params: CustomerAttributeConditionForm): CustomerAttributeCondition {
  let condition: CustomerAttributeCondition =  { 
    definition_id: params?.definition_id?.length ? params?.definition_id[0].id : null as number,
    definition_type: params.definition_type ?? null,
  }

  switch(params.definition_type){
    case CustomerAttributeType.String:
      getOperatorValuesPayload(condition, params, CustomerAttributeKey.StringOperator, CustomerAttributeKey.StringValue);
      break;
    case CustomerAttributeType.Integer:
      getOperatorValuesPayload(condition, params, CustomerAttributeKey.IntegerOperator, CustomerAttributeKey.IntegerValue, CustomerAttributeKey.IntegerValue2);
      break;
    case CustomerAttributeType.Float:
      getOperatorValuesPayload(condition, params, CustomerAttributeKey.FloatOperator, CustomerAttributeKey.FloatValue, CustomerAttributeKey.FloatValue2);
      break;
    case CustomerAttributeType.Boolean:
      getOperatorValuesPayload(condition, params, CustomerAttributeKey.BooleanOperator, CustomerAttributeKey.BooleanValue);
      break;
    case CustomerAttributeType.Datetime:
      getOperatorValuesPayload(condition, params, CustomerAttributeKey.DateOperator, CustomerAttributeKey.DateValue, CustomerAttributeKey.DateValue2);
      break;
  }

  return condition;
}

function getOperatorValuesPayload(cond: CustomerAttributeCondition, params: CustomerAttributeConditionForm, keyOperator: string, keyValue: string, keyValue2?: string): void {
  cond.operator = params && params[keyOperator]?.length ? params[keyOperator][0].id : null;
  if (cond.operator !== 'bt') {
    if (params[keyValue] && (params[keyValue] instanceof Array)) {
      cond.value = params[keyValue].length ? params[keyValue][0].id : null;
    } else if (params[keyValue] && (params[keyValue] instanceof Object)) {
      cond.value = params[keyValue]?.id ?? null;
    } else {
      cond.value = params[keyValue] ?? null;
    }
  } else {
    cond.value1 = parseValuesBtOperator(params, keyValue);
    cond.value2 = parseValuesBtOperator(params, keyValue2);
  }
}

function parseValuesBtOperator(params: CustomerAttributeConditionForm, keyValue: string): string | number {
  return params[keyValue] && params[keyValue] instanceof Object ? params[keyValue]?.id ?? null : params[keyValue] ?? null;
}