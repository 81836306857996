<!-- Page global title -->
<div class="page-title background-white">
  <div class="row pdd-horizon-15">
    <div class="col-md-9 d-flex align-items-center min-height-60px">
      <h4 class="no-mrg-vertical" [innerHTML]="title"></h4>
    </div>
  </div>
</div>
<!--  -->

<!-- Dynamic tabs -->
<ul class="nav nav-tabs w-100" role="tablist">
  <ng-container *ngFor="let tab of tabs; let i = index">
    <li class="nav-item" *ngIf="!tab.featureFlag || flags[tab.featureFlag]">
      <a (click)="selectTab(tab.slug)"
        [routerLink]="tab.routerLink"
        class="nav-link {{currentSlug === tab.slug ? 'active' : ''}}"
        data-toggle="tab"
        href="#sales-tab-1"
        role="tab"
        aria-selected="true">
        {{tab.name}}
      </a>
    </li>
  </ng-container>
  <div *ngIf="config && config['showAnalyticsLegend']">
    <a class="legend-positioning" [routerLink]="['', { outlets: { modal: ['show', 'legend'] }}]">
      <i class="mrg-top-4 ti-info-alt font-size-24 text-info"></i>
    </a>
  </div>
</ul>
<!--  -->

<div class="container-fluid mrg-top-15" *ngIf="tabs?.length">
  <div class="row">
    <div class="col-md-12">
      <!-- Dynamic filters per tab -->
      <ng-container *ngFor="let tab of tabs; let i = index;">
        <app-data-table-filter-analytics *ngIf="currentSlug === tab.slug && tabsFilters[tab.slug].inputs.length && (!tabsFilters[tab.slug].config || !tabsFilters[tab.slug].config.useLookerDashboardFilter)"
                                [slug]="tab.slug+'_filter'"
                                [questions]="tabsFilters[tab.slug].inputs"
                                [defaultQuestions]="tabsFilters[tab.slug].defaultInputs"
                                [cfg]="tabsFilters[tab.slug].config"
                                [isDynamicTabData]="true"
                                (onSubmitEmitter)="onFilterHandler(tab.slug, $event)"
                                (onFiltersChangeValue)="onFiltersChangeValue(tab.slug, $event)"
                                >
        </app-data-table-filter-analytics>
        <app-looker-dashboard-filter *ngIf="currentSlug === tab.slug && tabsFilters[tab.slug].config && tabsFilters[tab.slug].config.useLookerDashboardFilter" 
          [slug]="tab.slug+'_filter'"
          [cfg]="tabsFilters[tab.slug].config"
          [questions]="tabsFilters[tab.slug].inputs">
        </app-looker-dashboard-filter>
      </ng-container>
      <div class="tab-info">
        <div class="pdd-bottom-20 position-relative">
          <!-- Page container -->
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
