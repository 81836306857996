import { BigqueryJobsStepsBase } from './bigquerystep-base';

export class SynchroStep extends BigqueryJobsStepsBase {
  category = 'synchro';

  constructor(options: {} = {}) {
    super(options);
    this.category = options['category'] || 'synchro';
  }

  public getPayload() {
    const payload = super.getPayload();
    delete payload['name'];
    return payload;
  }
}
