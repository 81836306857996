<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true" *ngIf="element">
              <tbody>
                <tr *ngIf="element.id">
                  <td class="no-border-top"><b [translate]="'resources.scores.score_exchanges.show.fields.id'"></b></td>
                  <td class="no-border-top">{{element.id}}</td>
                </tr>
                <tr *ngIf="element.name">
                  <td><b [translate]="'resources.scores.score_exchanges.show.fields.name'"></b></td>
                  <td>{{element.name}}</td>
                </tr>
                <tr *ngIf="element.description">
                  <td><b [translate]="'resources.scores.score_exchanges.show.fields.description'"></b></td>
                  <td>{{element.description}}</td>
                </tr>
                <tr *ngIf="element.currency_amount">
                  <td><b [translate]="'resources.scores.score_exchanges.show.fields.currency_amount'"></b></td>
                  <td>{{element.currency_amount}}</td>
                </tr>
                <tr *ngIf="element.currency">
                  <td><b [translate]="'resources.scores.score_exchanges.show.fields.currency'"></b></td>
                  <td>{{element.currency}}</td>
                </tr>
                <tr *ngIf="element.country_codes">
                  <td><b [translate]="'resources.scores.score_exchanges.show.fields.country'"></b>&nbsp;<span class="text-semibold text-dark">({{element.country_codes.length}})</span></td>
                  <td>
                    <ul class="list tick bullet-success ul-style" *ngIf="element.country_codes.length > 0">
                      <li *ngFor="let item of element.country_codes">
                        {{item.name}}
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
