import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MultiselectDataSource } from '../../components/multiselect/multiselect';
import { ResourcesService } from '../resources.service';

@Injectable({
  providedIn: 'root'
})
export class DataExtensionsService extends ResourcesService {

  connectorId: number;

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  fetchMultiselect( searchValues?: string, page?: number): Observable<object> {
    let requestOptions = {
      apiEndPoint: `connectors/${this.connectorId}/salesforce/data-extensions`,
      numberPerPage: 500,
      pageNumber: 1,
      filtering: {},
      sorting: {}
    };

    requestOptions.pageNumber = page ?? requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { search: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
      return this.getData(requestOptions);
    } else {
      return  of([]);
    }
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.name, element);
  }
}
