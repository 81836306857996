import { AbstractControl, ValidationErrors, Validators } from "@angular/forms";
import { setValidatorsErrorsMark } from "./common.validations.utils";
import { INTEGER_REGEX, POSITIVE_INTEGER_REGEX } from "../../../../constants/condition.constants";

export function getIsValueInteger(value: string | number): boolean {
  return !!((value || value === 0) && INTEGER_REGEX.exec(value.toString()));
}

export function getIsValuePositiveInteger(value: string | number): boolean {
  return integerMatchRegexPattern(value, POSITIVE_INTEGER_REGEX);
}

export function checkIntegerValuesBtOperatorValidation (
  valueCtrl: AbstractControl,
  value2Ctrl: AbstractControl,
  value: number | string,
  value2: number | string,
  regexPattern: RegExp,
  errorKey: string
): ValidationErrors | null {
  const isValueInteger = integerMatchRegexPattern(value, regexPattern);
  const isValue2Integer = integerMatchRegexPattern(value2, regexPattern);
  if (!isValueInteger && !isValue2Integer) {
    setValidatorsErrorsMark(valueCtrl, [Validators.pattern(regexPattern)], errorKey);
    setValidatorsErrorsMark(value2Ctrl, [Validators.pattern(regexPattern)], errorKey);
    return {[errorKey]: true};
  } else if (!isValueInteger && isValue2Integer) {
    setValidatorsErrorsMark(valueCtrl, [Validators.pattern(regexPattern)], errorKey);
    return {[errorKey]: true};
  } else if (isValueInteger && !isValue2Integer) {
    setValidatorsErrorsMark(value2Ctrl, [Validators.pattern(regexPattern)], errorKey);
    return {[errorKey]: true};
  }
}

function integerMatchRegexPattern(value: string | number, regexPattern: RegExp): boolean {
  return !!((value || value === 0) && regexPattern.exec(value.toString()));
}