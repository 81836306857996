import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { CustomEntityKey } from '../../../../../../services/conditions/services/custom-entity-condition/enums/custom-entity-key.enums';
import { CustomEntityAttributeType } from '../../../../../../services/conditions/services/custom-entity-condition/enums/custom-entity-attribute-type.enums';
import { AttributesConfigColumnsList } from '../../enums/attributes-configuration-list.enums';
import { QuestionControlService } from '../../../../../../services/question-control.service';
import { DecimalPipe } from '@angular/common';
import { parseFloatAttributeValue } from '../../parsers/attributes.parser';

@Component({
  selector: 'app-attributes-list-custom-entity-condition',
  templateUrl: './attributes-list-custom-entity-condition.component.html',
  styleUrls: ['./attributes-list-custom-entity-condition.component.scss']
})
export class AttributesListCustomEntityConditionComponent {

  attributesConfigColumnList = AttributesConfigColumnsList;
  customEntityKey = CustomEntityKey;
  customEntityAttributeType = CustomEntityAttributeType;

  @Input() attributesConfigArray: UntypedFormArray;

  constructor(
    private qcs: QuestionControlService,
    private decimalPipe: DecimalPipe
  ) {}

  attributeConfigItemList(attributeConfigCtrl: UntypedFormGroup, columnKey: string): string | number {
    let attributeType = attributeConfigCtrl.get(this.customEntityKey.AttributeType)?.value;
    const isFloatAttribute = this.isFloatAttribute(attributeConfigCtrl);
    if (isFloatAttribute) { attributeType = this.customEntityAttributeType.Float };
    if (this.isDateOrDatetimeAttribute(attributeConfigCtrl)) { attributeType = this.customEntityAttributeType.Date };

    const operatorKey = `${attributeType}_operator`;
    switch (columnKey) {
      case this.attributesConfigColumnList.AttributeName:
        return attributeConfigCtrl.get(this.customEntityKey.Attribute)?.value?.[0]?.name;
      case this.attributesConfigColumnList.Operator:
        return attributeConfigCtrl.get(operatorKey)?.value?.[0]?.name;
      case this.attributesConfigColumnList.Value:
        return this.getItemListValue(operatorKey, attributeConfigCtrl, attributeType, isFloatAttribute);
      case this.attributesConfigColumnList.Value2:
        return this.getItemListValue(operatorKey, attributeConfigCtrl, attributeType, isFloatAttribute, true);
      default:
        return null;
    }
  }

  deleteAttribute(index: number): void {
    this.attributesConfigArray.removeAt(index);
  }

  private getItemListValue(operatorKey: string, attributeConfigCtrl: UntypedFormGroup, attributeType: string, isFloatAttribute: boolean, isValue2?: boolean): string | number {
    const valueKey = `${attributeType}_value`;
    const valueCtrlValue = attributeConfigCtrl.get(valueKey)?.value;
    if (this.isBtOperator(operatorKey, attributeConfigCtrl)) {
      const value2Key = `${attributeType}_value2`;
      const value2CtrlValue = attributeConfigCtrl.get(value2Key)?.value;
      return this.parseValuesBtOperator(isValue2 ? value2CtrlValue : valueCtrlValue, isFloatAttribute);
    } else {
      return isValue2 ? null : this.getValueSimpleOperator(attributeConfigCtrl, valueCtrlValue);
    }
  }

  private getValueSimpleOperator(attributeConfigCtrl: UntypedFormGroup, valueCtrlValue: unknown): string | number {
    let value = null;
    if (valueCtrlValue && valueCtrlValue instanceof Array) {
      value =  valueCtrlValue.length ? valueCtrlValue[0]['name'] : null;
    } else if (valueCtrlValue && valueCtrlValue instanceof Object) {
      value = valueCtrlValue?.['name'] ?? null;
    } else {
      value = (valueCtrlValue ?? null) as string | number;
      if (this.isFloatAttribute(attributeConfigCtrl)) {
        value = parseFloatAttributeValue(value as number, this.decimalPipe);
      }
    }
    return value;
  }

  private parseValuesBtOperator(valueCtrlValue: unknown, isFloatAttribute: boolean): string | number {
    let value = valueCtrlValue && valueCtrlValue instanceof Object ? valueCtrlValue?.['name'] ?? null : valueCtrlValue ?? null;
    if (isFloatAttribute) {
      value = parseFloatAttributeValue(value as number, this.decimalPipe);
    }
    return value;
  }

  private isBtOperator(operatorKey: string, attributeConfig: UntypedFormGroup): boolean {
    return this.qcs.hasFormKeyWithValue(attributeConfig, operatorKey, 'bt');
  }

  private isDateOrDatetimeAttribute(attributeConfig: UntypedFormGroup): boolean {
    return !!(this.isSelectedAttributeType(this.customEntityAttributeType.Datetime, attributeConfig) || this.isSelectedAttributeType(this.customEntityAttributeType.Date, attributeConfig));
  }

  private isFloatAttribute(attributeConfig: UntypedFormGroup): boolean {
    return !!(this.isSelectedAttributeType(this.customEntityAttributeType.Float, attributeConfig) || this.isSelectedAttributeType(this.customEntityAttributeType.Number, attributeConfig));
  }

  private isSelectedAttributeType(attributeType: string, attributeConfig: UntypedFormGroup): boolean {
    return attributeConfig?.get(this.customEntityKey.AttributeType)?.value === attributeType;
  }
}
