import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { FeatureFlagsService } from './../../../../shared/services/feature-flags.service';
import { ProfileService } from '../../../../profiles/profile.service';

@Component({
  selector: 'app-show-vouchers',
  templateUrl: './show-vouchers.component.html',
  styleUrls: ['./show-vouchers.component.css']
})
export class ShowVouchersComponent implements OnInit {

  element;
  apiEndPoint: string;
  currentRole: string = this.profileService.getStoredUserRole();
  id: number;
  isModal: boolean;
  flags = this.featureFlags.flags;

  get isStoreManagerRole(): boolean {
    return this.currentRole === 'store_manager';
  }

  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private router: Router,
    private featureFlags: FeatureFlagsService,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    registerLocaleData(es);
    this.isModal = this.router.url.indexOf('(modal:show/vouchers/') >= 0;
    this.getParams();
  }

  getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'vouchers';
        this.resourcesService.getData({
          apiEndPoint: this.apiEndPoint + '/' + this.id + '/?representer=Element' }).subscribe(res => {
            this.element = res;
            this.isExpired();
            this.repeatedValues(this.element.activity_vouchers);
        });
      }
    });
  }

  isExpired() {
    const available_to = new Date(this.element.voucheable.available_to);
    const date = new Date();
    const today = new Date(date.getFullYear(), date.getMonth(), date.getDay());

    if (this.element.status === 'pending' && available_to < today) {
      this.element.timed_out = true;
    }
  }

  isVoucherStatus(voucher, status){
    return voucher.status === status;
  }

  repeatedValues(list) {
    let count = 1;
    let sum_discounts;
    if (list.length) {
      sum_discounts = list[0].discount;
      for (let i = 0; i <= list.length - 1; i++) {
        if (list[i + 1] && list[i].activity_id === list[i + 1].activity_id) {
          count++;
          sum_discounts = sum_discounts + list[i + 1].discount;
          list.splice(i + 1, 1);
          i--;
        } else {
          this.element.activity_vouchers[i].discount_count = count;
          this.element.activity_vouchers[i].sum_discounts = sum_discounts;
          count = 1;
          if (list[i + 1]) {
            sum_discounts = list[i + 1].discount;
          } else {
            sum_discounts = list[i].discount;
          }
        }
      }
    }
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  goBack() {
    this.router.navigate(['/omnichannel-manager/ticket-messages/vouchers']).catch(() => {});
  }

  goToMessage() {
    if ( this.isModal ) {
      window.open(`/#/customer-data-platform/warehouse/coupons(modal:show/coupons/${this.element.voucheable.database_id})`);
    } else {
      this.router.navigate([{ outlets: { modal: `show/vouchers/${this.element.voucheable.database_id}`}}]).catch(() => {});
    }
  }

  goToProfile() {
    window.open(`#/data-warehouse/profiles/${this.element.profile_id}/summary`);
  }

  getVoucherStatus(element) {
    if ( element.max_redemptions !== element.available_redemptions && element.status !== 'cancelled') {
      return element.available_redemptions > 0 ? 'redeemed' : 'soldout';
    }

    return element.status;
  }

  getVoucherLabel(status) {
    let label = 'label-default';
    switch (status) {
      case 'soldout':
      case 'redeemed': label = 'label-success';
        break;
      case 'cancelled': label = 'label-danger';
        break;
      case 'printed': label = 'label-warning';
        break;
      case 'pending': label = 'label-info';
        break;
    }
    return label;
  }

}
