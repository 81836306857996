<ng-container *ngIf="validateResetPasswordToken; else tokenValidationTemplate">
  <em class="fa fa-spinner fa-spin font-size-15 align-text-top"></em>
  <h5 class="d-inline text-bold no-mrg mrg-left-5">{{ 'components.signin.titles.validating_password_reset_token' | translate }}</h5>
</ng-container>

<ng-template #tokenValidationTemplate>
  <ng-container *ngIf="tokenValidationError; else resetPasswordTemplate">
    <app-recover-password [isResetPasswordTokenError]="true" [isResetExpiredPassword]="isResetExpiredPassword"></app-recover-password>
  </ng-container>

  <ng-template #resetPasswordTemplate>
    <h5 class="mrg-vertical-15 text-bold">{{ (isResetExpiredPassword ? 'components.signin.titles.reset_expired_password' : 'components.signin.titles.reset_password') | translate }}</h5>
    <p class="mrg-vertical-15 font-size-13">{{ (isResetExpiredPassword ? 'components.signin.messages.reset_expired_password_desc' : 'components.signin.messages.reset_password_desc') | translate }}</p>

    <form [formGroup]="resetPasswordForm" *ngIf="resetPasswordForm">
      <div class="form-group">
        <label for="new_password" class="text-bold">
          {{ 'components.signin.fields.new_password' | translate }}
          <span class="text-danger">*</span>
        </label>
        <app-question class="question-hint" [question]="getInputConfig('new_password')" [form]="resetPasswordForm"></app-question>
        <ng-container *ngTemplateOutlet="passwordVisibilityTemplate; context: { key: 'new_password'}"></ng-container>
        <ng-container *ngIf="userSecurity">
          <app-user-password-validation [passwordCtrl]="resetPasswordForm?.get('new_password')" [userSecurity]="userSecurity"></app-user-password-validation>
        </ng-container>
      </div>

      <div class="form-group">
        <div class="mrg-btm-30">
          <label for="new_password_confirmation" class="text-bold">
            {{ 'components.signin.fields.new_password_confirmation' | translate }}
            <span class="text-danger">*</span>
          </label>
          <app-question class="question-hint" [question]="getInputConfig('new_password_confirmation')" [form]="resetPasswordForm"></app-question>
          <ng-container *ngTemplateOutlet="passwordVisibilityTemplate; context: { key: 'new_password_confirmation'}"></ng-container>
        </div>
      </div>

      <div>
        <em class="fa fa-spinner fa-spin" *ngIf="loading"></em>
        <button class="btn btn-info no-mrg" [disabled]="loading || resetPasswordForm.invalid" (click)="resetPassword()">
          {{'components.signin.buttons.reset_password' | translate}}
        </button>
      </div>
    </form>
  </ng-template>
</ng-template>

<ng-template #passwordVisibilityTemplate let-key="key">
  <div class="password-hint-wrapper">
    <a (click)="handlePasswordVisibility(key)" class="password-hint">
      <span>{{ 'resources.users.hints.' + (showPasswordVisibility[key] ? 'hide_password' : 'show_password') | translate }}</span>
      <em class="fa-regular mrg-left-5" [ngClass]="showPasswordVisibility[key] ? 'fa-eye-slash' : 'fa-eye'"></em>
    </a>
  </div>
</ng-template>
