import { CustomerJourney, CustomerJourneysCfg } from './customer-journey';
import { CustomerJourneyStep } from './customer-journey-step';

export class PotentialJourney extends CustomerJourney {

  public campaignsBlockHidden = false;
  public summaryBlockHidden = false;

  constructor(apiObject: CustomerJourneysCfg) {
    super(apiObject);
  }

  public getDefinitionStepFormCfg(): any[] {
    return [
      {
        key: 'best_sales_tag',
        label: 'resources.journeys.fields.best_sales_tag',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
        requestData: {
          apiEndPoint: 'segment_conditions/tag_sales'
        },
        required: true,
        type: 'multiselect'
      },
      {
        key: 'location_terms',
        label: 'resources.journeys.fields.location_taxonomy_terms',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        requestData: {
          apiEndPoint: 'location_taxonomy_terms',
          filtering: { per_page: 300 }
        },
        template: '({{dataItem.taxonomy.name}}) {{dataItem.name}}',
        type: 'multiselect'
      },
      {
        key: 'extra_segment',
        label: 'resources.journeys.fields.extra_segment',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
        requestData: {
          apiEndPoint: 'segments',
          filtering: { per_page: 300 }
        },
        type: 'multiselect'
      }
    ];
  }

  public getCampaignsStepFormCfg(): {}[] {
    return [
      {
        key: 'messages',
        label: 'resources.journeys.fields.message',
        settings: {
          singleSelection: true,
          enableCheckAll: false,
          showCheckbox: true,
          enableSearchFilter: true,
          text: 'Seleccionar mensaje...'
        },
        requestData: {
          apiEndPoint: 'messages',
          filtering: { per_page: 300, order_by: 'created_at', order_sense: 'desc' }
        },
        template: '({{dataItem.id}}) {{dataItem.name}}',
        type: 'multiselect'
      },
      {
        key: 'coupons',
        label: 'resources.journeys.fields.coupon',
        settings: {
          singleSelection: true,
          enableCheckAll: false,
          showCheckbox: true,
          enableSearchFilter: true,
          text: 'Seleccionar cupón...'
        },
        requestData: {
          apiEndPoint: 'coupons',
          filtering: { per_page: 300, order_by: 'created_at', order_sense: 'desc' }
        },
        template: '({{dataItem.id}}) {{dataItem.name}}',
        type: 'multiselect'
      }
    ];
  }

  public definitionValid(): boolean {
    /*
    const bestSalesTagDefined = this.configuration.hasOwnProperty('best_sales_tag') &&
                                this.configuration['best_sales_tag'] &&
                                this.configuration['best_sales_tag'][0] &&
                                this.configuration['best_sales_tag'][0].hasOwnProperty('id');

    return bestSalesTagDefined;
    */
    return true;
  }

  /**
   * All steps must have its cut / date defined
   */
  public campaignsValid(): boolean {
    /*
    const pendingSteps = this.steps.filter( step => step.status === 'pending');
    const cutValidations = pendingSteps.map( step => step.hasCutDefined());
    const datesValidations = pendingSteps.map( step => step.hasScheduleDefined());

    return cutValidations.every(function(item){ return item; }) &&
           datesValidations.every(function(item){ return item; });
    */
    return true;
  }

  /**
   * All steps but the last one should have cfg.message_id
   * last step (reward) has to have cfg.coupon_id
   */
  public contentValid(): boolean {
    /*
    const pendingSteps = this.steps.filter( step => step.status === 'pending');

    const rewardSteps = pendingSteps.filter( step => ['reward'].indexOf(step.slug) >= 0);
    const reminderSteps = pendingSteps.filter( step =>
      step.slug.indexOf('first') >= 0 || step.slug.indexOf('rem') >= 0
    );

    const reminderValidations = reminderSteps.map(
      step => step.hasKeyInConfiguration('messages') || step.hasKeyInConfiguration('message_id')
    );

    const rewardsValidations = rewardSteps.map(
      step => step.hasKeyInConfiguration('coupons') || step.hasKeyInConfiguration('coupon_id')
    );

    const allRemindersValid = reminderValidations.every(function(item){ return item; });
    const allRewardsValid = rewardsValidations.every(function(item){ return item; });

    return allRemindersValid && allRewardsValid;
    */

    return true;
  }

  /**
   * Check Only if name is present for potential C. Journeys
   */
  public summaryValid(): boolean {
    /*
      return this.name && this.name !== null && this.name !== '' && this.name !== undefined;
    */
    return true;
  }

  /**
   * Prepare object values to PATCH object in API
   */
  public prepareToSave() {

    const config = JSON.parse(JSON.stringify(this.cfg));

    config.name = config.name === '' ? this.getName() : config.name;
    config.name = this.nameHasChanged ? this.name : config.name;

    // Reinstate C. Journey type as API expects it
    config.type = config.origin_type;
    delete config.origin_type;

    // Definition block
    if ( this.configuration.hasOwnProperty('best_sales_tag') &&
         this.configuration.best_sales_tag[0] &&
         this.configuration.best_sales_tag[0].hasOwnProperty('id')) {
      config.configuration.best_sales_tag = this.configuration.best_sales_tag[0].id;
    } else {
      config.configuration.best_sales_tag = null;
    }

    if ( this.configuration.hasOwnProperty('location_terms') &&
         this.configuration.location_terms[0] &&
         this.configuration.location_terms[0].hasOwnProperty('id')) {
      config.configuration.location_terms = this.configuration.location_terms.map(term => term.id);
    } else {
      config.configuration.location_terms = null;
    }

    if ( this.configuration.hasOwnProperty('extra_segment') &&
         this.configuration.extra_segment[0] &&
         this.configuration.extra_segment[0].hasOwnProperty('id')) {
      config.configuration.extra_segment = this.configuration.extra_segment[0].id;
    } else {
      config.configuration.extra_segment = null;
    }

    // Campaigns block
    for (let index = 0; index < this.steps.length; index++) {
      config.steps[index].configuration.cut = this.steps[index].configuration.cut ? this.steps[index].configuration.cut : 0;
      config.steps[index].scheduled_at = this.steps[index].scheduled_at ? this.steps[index].scheduled_at : null;
      config.steps[index].status = this.steps[index].status;
      this.steps[index].copyKeyToStepConfig(config.steps[index].configuration, 'messages', 'message_id');
      this.steps[index].copyKeyToStepConfig(config.steps[index].configuration, 'coupons', 'coupon_id');
    }

    config.reportable = this.reportable || false;

    return config;
  }

  public getName(): string {
    return this.name ? this.name : '';
  }

  public getNameSuffix(step: CustomerJourneyStep): string {
    switch (step.slug) {
      case 'first':
        return `Inicio >= ${step.configuration.cut}`;
      case 'reward':
        return `Premio > ${step.configuration.cut}`;
      default:
        return `Envío >= ${step.configuration.cut}`;
    }
  }

  public getNameWithAttributes(): string {
    const bestSalesTag = this.configuration.best_sales_tag.map(term => term.name);
    const limitedLocationTerms = this.configuration.location_terms.slice(0, 3);
    const locationTerms = limitedLocationTerms.map(term => term.name).join(' ');

    return `${this.name} ${bestSalesTag} ${locationTerms}`;
  }
}
