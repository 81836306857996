import { Injectable } from '@angular/core';
import { NavigationEnd, ActivatedRoute } from '@angular/router';

@Injectable()
export class RedirectionService {

  private redirectableUrlSubstring = [
    { url: 'modal:show/segments/', redirectTo: '/segments/segments/:id'},
    { url: 'modal:show/scores/', redirectTo: '/scores/scores/scores/:id'},
    { url: 'modal:show/wallets/', redirectTo: '/scores/wallets/:id'},
    { url: 'modal:show/redemptions/', redirectTo: '/scores/redemptions/:id'},
    { url: 'modal:show/score_exchanges/', redirectTo: '/scores/score_exchanges/:id'},
    { url: 'modal:show/campaign_categories/', redirectTo: '/campaigns/campaign_categories/:id'},
    { url: 'modal:show/messages/', redirectTo: '/content-designer/messages/messages/:id'},
    { url: 'modal:show/news_taxonomies/', redirectTo: '/content-designer/cms/news_taxonomies/:id'},
    { url: 'modal:show/message_categories/', redirectTo: '/content-designer/messages/message_categories/:id'},
    { url: 'modal:show/news_taxonomy_terms/', redirectTo: '/content-designer/cms/news_taxonomy_terms/:id'},
    { url: 'modal:show/news/', redirectTo: '/content-designer/cms/news-list/:id'},
    { url: 'modal:show/feature_internals/', redirectTo: 'data-warehouse/products/feature_internals/:id'},
    { url: 'modal:show/feature_taxonomies/', redirectTo: 'data-warehouse/products/feature_taxonomies/:id'},
    { url: 'modal:show/recipes/', redirectTo: '/recipes/recipes/:id'}
  ];

  constructor( public route: ActivatedRoute ) { }

  public isRedirectable(_location: NavigationEnd): boolean {
    if ( _location.id === 1 ) {
      if ( this.isLocationUrlInRedirectables(_location.url) ) {
        return true;
      }
    } else {
      return false;
    }
  }

  public getInternalUrl(_location: NavigationEnd): string {
    const filter = this.redirectableUrlSubstring.filter( item => _location.url.indexOf(item.url) >= 0);
    const id = _location.url.split(filter[0].url)[1].split('/')[0];
    return filter[0].redirectTo.replace(':id', id);
  }

  private isLocationUrlInRedirectables( url: string ): boolean {
    let response = false;
    this.redirectableUrlSubstring.forEach( urlItem => {
      if ( url.indexOf( urlItem.url ) >= 0) {
        response = true;
      }
    });
    return response;
  }
}
