import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule, APP_INITIALIZER, Injector, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';

// Routing Module
import { AppRoutes } from './app.routing';

// App Components
import { AppComponent } from './app.component';
import { SigninComponent } from './auth/signin/signin.component';
import { ResetPasswordComponent } from './auth/signin/components/reset-password/reset-password.component';
import { RecoverPasswordComponent } from './auth/signin/components/recover-password/recover-password.component';

// App Services
import { AnalyticsGuard } from './guards/analytics-guard.service';
import { AuthGuard } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { CompanyService } from './resources/config/company/company.service';
import { ConfirmationService } from './shared/services/confirmation.service';
import { CountriesService } from './shared/services/countries.service';
import { CurrenciesService } from './shared/services/currencies.service';
import { DictionaryService } from './shared/services/dictionary.service';
import { HeaderInterceptor } from './shared/config/header.interceptor';
import { LgApiInterceptor } from './shared/config/lg-api-interceptor';
import { LocationsService } from './resources/data-warehouse/locations/locations.service';
import { LoyaltyScoresService } from './resources/scores/loyalty_scores/loyalty-scores.service';
import { ModulesGuard } from './guards/modules-guard.service';
import { ProductsService } from './resources/data-warehouse/products/products.service';
import { ProfileService } from './profiles/profile.service';
import { ProfilesService } from './resources/data-warehouse/profiles/profiles.service';
import { PromotionsService } from './resources/data-warehouse/promotions/service/promotions.service';
import { ResourcesService } from './shared/services/resources.service';
import { RolesService } from './profiles/roles.service';
import { SectionsGuard } from './guards/sections-guard.service';
import { TicketsService } from './resources/data-warehouse/tickets/tickets.service';
import { UserIdleSessionService } from './services/user-idle-session/user-idle-session.service';

// Config
import { translateInitializerFactory } from './shared/config/translate-intitializer.factory';

// i18n
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// App Modules
import { InitialModule } from './initial.module';
import { ModalsModule } from './shared/modals/modals.module';
import { SharedModule } from './shared/shared.module';

// Dynamic Forms
import { DictionaryShowFieldsService } from './shared/services/dictionary-show-fields.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalStatusService } from './shared/services/modal-status.service';
import { NgSelectModule } from '@ng-select/ng-select';

// Credit card payments
import { NgxStripeModule } from 'ngx-stripe';


// LaunchDarkly Service
import { LaunchDarklyService } from './shared/services/launch-darkly/launchdarkly.service';

// Sentry: error handling
import { init } from '@sentry/browser';
import { environment } from '../environments/environment';

// PrimeNG
import { PrimeNGConfig } from 'primeng/api';


if (environment.name !== 'Development') {
  init({
    dsn: 'https://b4a69c66f35840e6be711b607fe41096@o152066.ingest.sentry.io/5227912',
    debug: false,
    release: 'owner_release',
    environment: environment.name
  });
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    NgSelectModule,
    ModalsModule,
    SharedModule,
    InitialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxStripeModule.forRoot(),
    RouterModule.forRoot(AppRoutes, { useHash: true, enableTracing: false })
  ],
  declarations: [
    AppComponent,
    SigninComponent,
    ResetPasswordComponent,
    RecoverPasswordComponent
  ],
  providers: [
    AnalyticsGuard,
    AuthGuard,
    AuthService,
    CompanyService,
    ConfirmationService,
    CountriesService,
    CurrenciesService,
    DictionaryService,
    DictionaryShowFieldsService,
    LaunchDarklyService,
    LgApiInterceptor,
    LocationsService,
    LoyaltyScoresService,
    ModalStatusService,
    ModulesGuard,
    ProductsService,
    ProfileService,
    ProfilesService,
    PromotionsService,
    ResourcesService,
    RolesService,
    SectionsGuard,
    TicketsService,
    UserIdleSessionService,
    { provide: HTTP_INTERCEPTORS, useClass: LgApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: APP_INITIALIZER,
      useFactory: translateInitializerFactory,
      deps: [TranslateService, ProfileService, PrimeNGConfig, Injector],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'es-ES'
    }
  ],
  bootstrap: [AppComponent],
  exports: [SigninComponent]
})

export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
