<ng-container *ngIf="!isResetPasswordTokenError; else resetPasswordTokenErrorTemplate">
  <h5 class="mrg-vertical-15 text-bold">{{ 'components.signin.titles.recover_password' | translate }}</h5>
  <p class="mrg-vertical-15 font-size-13">{{ 'components.signin.messages.recover_password' | translate }}</p>
</ng-container>

<form [formGroup]="recoverPasswordForm" *ngIf="recoverPasswordForm">
  <app-question [question]="getInputConfig('email')" [form]="recoverPasswordForm"></app-question>
  <div>
    <em class="fa fa-spinner fa-spin" *ngIf="loading"></em>
    <button class="btn btn-info no-mrg mrg-top-5" [disabled]="loading || recoverPasswordForm.invalid" (click)="recoverPassword()">
      {{'components.signin.buttons.recover_password' | translate}}
    </button>
  </div>
  <div class="redirect-info">
    <a [class.disabled]="loading" [routerLink]="['/signin']"><u>{{ 'components.signin.buttons.back_to_login' | translate }}</u></a>
  </div>
</form>

<ng-template #resetPasswordTokenErrorTemplate>
  <div class="mrg-vertical-15">
    <em class="fa-solid fa-triangle-exclamation text-danger font-size-16 mrg-right-5"></em>
    <h5 class="d-inline text-bold no-mrg">{{ 'components.signin.titles.validating_password_reset_token_error' | translate }}</h5>
  </div>
  <p class="mrg-vertical-15 font-size-13">{{ (isResetExpiredPassword ? 'components.signin.messages.validating_expired_password_reset_token_error_desc' : 'components.signin.messages.validating_password_reset_token_error_desc') | translate }}</p>
</ng-template>
