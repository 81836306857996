import { AuthService } from '../../auth/auth.service';
import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { CurrentUser } from '../../shared/models/current-user';
import { DateService } from '../../shared/services/date.service';
import { environment } from '../../../environments/environment';
import { FeatureFlagsService } from './../../shared/services/feature-flags.service';
import { MessageService } from 'primeng/api';
import { Notification, NotificationMock } from '../../shared/models/company/notification';
import { NotificationsService } from '../../shared/services/notifications.service';
import { ProfileService } from '../../profiles/profile.service';
import { registerLocaleData } from '@angular/common';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsComponent } from '../../shared/components/user-settings/user-settings.component';
import { UserIdleSessionService } from '../../services/user-idle-session/user-idle-session.service';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})

export class ContentHeaderComponent implements OnInit, OnDestroy {

  @Input() isCollapsed: boolean;
  @Output() onEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild(UserSettingsComponent) userSettingsModal: UserSettingsComponent;

  currentUser: CurrentUser;
  debug = false;
  flags = this.featureFlags.flags;
  headerSelected: string;
  headerThemes = ['header-default', 'header-primary', 'header-info', 'header-success', 'header-danger', 'header-dark'];
  initialNotifications: Notification[];
  interval;
  isFirstLoad = true;
  isLocalToProduction: boolean;
  notifications: Notification[];
  roleSlug: string;
  sidePanelOpen: boolean;
  subs$: Subscription[] = [];

  private notificationsRefreshTimer = 30000;

  constructor(
    private authService: AuthService,
    private dateService: DateService,
    private featureFlags: FeatureFlagsService,
    private messageService: MessageService,
    private notificationsService: NotificationsService,
    private profileService: ProfileService,
    private translate: TranslateService,
    private userIdleSessionService: UserIdleSessionService,
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.isLocalToProduction = (window.location.hostname === 'localhost' && environment.production);
    this.roleSlug = this.profileService.getStoredUserRole();
    this.getProfile();
    this.initUserIdleSession();
    if ( ['admin', 'owner', 'manager'].includes(this.roleSlug)) {
      this.getNotifications();
      this.interval = setInterval( () => this.refreshNotifications(), this.notificationsRefreshTimer );
    }
  }

  initUserIdleSession(): void {
    this.userIdleSessionService.setupUserIdleSession(() => this.logout());
  }

  ngOnDestroy() {
    if ( this.interval ) { clearInterval( this.interval ); }
    if ( this.currentUser ) { this.currentUser = null; }
    if ( this.subs$.length ) { this.subs$.forEach( s$ => s$.unsubscribe()); }
    this.userIdleSessionService.destroyUserIdleSession();
  }


  logout() {
    const logoutPayload = {user: this.currentUser.email};
    const logout$ = this.authService.logoutReq(logoutPayload).subscribe({
      next: () => this.authService.logout(),
      error: (errData) => {
        console.warn(errData.error.error);
        this.authService.logout();
      }
    })
    this.subs$.push(logout$);
  }

  changeHeader(headerTheme) {
    this.headerSelected = headerTheme;
  }

  triggerEvent(event) {
    this.onEvent.emit(event);
  }

  toggleSidePanelOpen() {
    this.sidePanelOpen = !this.sidePanelOpen;
  }

  openModal() {
    this.userSettingsModal.openModal();
  }

  private getProfile() {
    this.currentUser = new CurrentUser(this.profileService.getStoredUser());
    const platformLanguage = this.currentUser.userPreferredLanguage ?? this.currentUser.companyLanguage;
    this.translate.use(platformLanguage);
    if ( this.currentUser.company.logo.indexOf('missing') >= 0 ) { this.setFallbackImageRoute(); }
  }

  private getNotifications() {
    if ( this.debug ) {
      setTimeout(() => {
        this.notifications = this.parseNotifications(NotificationMock);
      }, 1000);
    } else {
      const notifications$ = this.notificationsService.getNotifications().subscribe(
        ( notificationsData: { list: any[] } ) => {
          notifications$.unsubscribe();
          this.notifications = this.parseNotifications( notificationsData.list );
          this.updateNotifications(this.notifications);
        }
      );
      this.subs$.push( notifications$ );
    }
  }

  private parseNotificationText(text:string) {
    const companyLang = this.currentUser.companyLanguage;
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    const element = doc.querySelector(companyLang);

    if (element) {
      return element.textContent;
    } else {
      return text
    }
  }

  private shownNotificationTimestamps: Set<number> = new Set<number>();

  private updateNotifications(newNotifications: any[]) {
    if (this.isFirstLoad) {
        this.isFirstLoad = false;
    } else {
        const recentNotifications = this.checkForRecentNotifications(newNotifications);
        const newUniqueNotifications = recentNotifications.filter(notification =>
            !this.shownNotificationTimestamps.has(notification.timestamp)
        );

        if (newUniqueNotifications.length > 0) {
            const messages = newUniqueNotifications.map(notification => ({
                severity: notification.type === 'info' ? 'success' : notification.type,
                summary: notification.title,
                detail: this.parseNotificationText(notification.text),
            }));
            this.messageService.addAll(messages);

            newUniqueNotifications.forEach(notification => this.shownNotificationTimestamps.add(notification.timestamp));
        }
    }
    this.notifications = newNotifications;
  }

  private checkForRecentNotifications(currentNotifications: any[]): any[] {
    return currentNotifications.filter(notification => notification.date === 'a few seconds ago' || notification.date === 'hace unos segundos' || notification.date === 'alcuni secondi fa');
  }

  private parseNotifications( notifications ): Notification[] {
    let parsedNotifications = notifications.map( (rawNotification) => {
      const description = this.notificationsService.processNotificationText(rawNotification.text);
      rawNotification.text = description;
      return new Notification(rawNotification, this.dateService);
    });
    parsedNotifications = this.sortNotificationsByTimestamp( parsedNotifications );
    return parsedNotifications;
  }

  private refreshNotifications() {
    this.getNotifications();
    const profile$ = this.profileService.getCurrentUserProfile().subscribe(
      () => {
        profile$.unsubscribe();
        this.getProfile();
      }
    );
  }

  private setFallbackImageRoute() {
    this.currentUser.company.logo = 'assets/images/logo/lg_logo_short.png';
  }

  private sortNotificationsByTimestamp(notifications: Notification[]): Notification[] {
    return notifications.sort(( notificationA, notificationB ) => {
      if ( notificationA.timestamp < notificationB.timestamp ) { return 1; }
      if ( notificationA.timestamp > notificationB.timestamp ) { return -1; }
      return 0;
    });
  }
}
