<form [formGroup]="campaignInputForm" *ngIf="campaignInputForm">
  <div class="row">

    <div class="col-md-3">
      <div class="form-group">
        <label for="campaigns" class="mrg-top-10 text-semibold control-label"
          [translate]="'resources.reports.form.fields.campaigns'"></label>
        <app-question [question]="getInputConfig('campaigns')" [form]="campaignInputForm" (multiselectChanged)="handleCampaignSelected($event)"></app-question>
      </div>
    </div>

    <div class="col-md-3">
      <div class="form-group">
        <label for="history" class="mrg-top-10 text-semibold control-label"
          [translate]="'resources.reports.form.fields.history'"></label>
        <app-question [question]="getInputConfig('history')" [form]="campaignInputForm"></app-question>
      </div>
    </div>

    <div class="col-md-4 mrg-top-40" *ngIf="hasFormKey('campaigns')">
      <div class="form-group">
        <app-question [question]="inputs[2]" [form]="campaignInputForm"></app-question>
      </div>
    </div>

    <div class="col-md-1">
      <div class="btn-group">
        <button class="btn btn-default mrg-top-35" type="button" (click)="removeCampaign($event)">
          <i class="ei-garbage-alt font-size-16"></i>
        </button>
      </div>
    </div>

  </div>
</form>
