import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import * as moment from "moment";
import { QuestionBase } from "../../../../shared/models/forms/question-base";
import { DEFAULT_FIELD_CALCULATION_CUSTOM_VALIDATORS, INVALID_START_DATE_DAYS } from "../constants/customer-attributes.constants";

export function lastMonthDaysValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const day = control.value ? moment(control.value).date() : null;
    if (day) {control.markAsDirty()};
    const isLastMonthDays = !!INVALID_START_DATE_DAYS.includes(day);
    return isLastMonthDays ? {'invalidDate': {message: 'resources.customer_attributes.errors.wrong_date', value: control.value}} : null;
  };
}

export function handleCustomValidators(
    inputs: QuestionBase<unknown>[],
    customerAttributesForm: UntypedFormGroup,
    handleCustomDateValidator: boolean
  ): void {

  const calculationAttributeType = customerAttributesForm.get('attribute_type').value;
  const executionTypeValue = customerAttributesForm.get('execution_type').value;
  const isCalculationAttributeType = !!(calculationAttributeType && calculationAttributeType === 'calculation');
  const isScheduledExecutionType = !!(executionTypeValue && executionTypeValue === 'scheduled');

  if (isCalculationAttributeType) {
    setDefaultCalculationCustomValidators(inputs, customerAttributesForm, isScheduledExecutionType);
  }

  if (handleCustomDateValidator) {
    setCustomDateValidator(inputs, customerAttributesForm, isCalculationAttributeType, isScheduledExecutionType);
  }
}

function setDefaultCalculationCustomValidators(inputs: QuestionBase<unknown>[], customerAttributesForm: UntypedFormGroup, isScheduledExecutionType: boolean): void {
  const defaultCustomValidators = !isScheduledExecutionType ?
    DEFAULT_FIELD_CALCULATION_CUSTOM_VALIDATORS.filter(validator => !validator.isScheduledExecutionType) : DEFAULT_FIELD_CALCULATION_CUSTOM_VALIDATORS;

  defaultCustomValidators.forEach(({key, customValidators}) => {
    const formField = customerAttributesForm.get(key);
    const input =  inputs.find(input => input.key === key);
    formField.addValidators(customValidators);
    input.customValidators = customValidators;
    formField.updateValueAndValidity();
  });
}

function setCustomDateValidator(inputs: QuestionBase<unknown>[], customerAttributesForm: UntypedFormGroup, isCalculationAttributeType: boolean, isScheduledExecutionType: boolean): void {
  const recurrenceValue = customerAttributesForm.get('recurrence').value;
  const isMonthRecurrence = !!(recurrenceValue && (recurrenceValue === 'monthly' || recurrenceValue === 'specific_months'));
  const startDateFormField = customerAttributesForm.get('start_date');
  const startDateInput = inputs.find(input => input.key === 'start_date');

  if (isCalculationAttributeType && isScheduledExecutionType) {
    let validators = [Validators.required];
    validators = isMonthRecurrence ? [...validators, lastMonthDaysValidator()] : validators;
    startDateFormField.setValidators(validators);
    startDateInput.customValidators = isMonthRecurrence ? [lastMonthDaysValidator()] : [];
  } else {
    startDateFormField.clearValidators();
    startDateInput.customValidators = [];
  }

  startDateFormField.updateValueAndValidity();
}