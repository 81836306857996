import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from '../../condition.service';
import { QuestionBase } from '../../../../models/forms/question-base';
import { MultiSelectQuestion } from '../../../../models/forms/question-multiselect';
import { TranslateService } from '@ngx-translate/core';
import { RadioQuestion } from '../../../../models/forms/question-radio';
import { DynamicDateQuestion } from '../../../../models/forms/question-dynamic-date';
import { LocationsService } from '../../../../../resources/data-warehouse/locations/locations.service';
import { DateService } from '../../../date.service';
import { FloatQuestion } from '../../../../models/forms/question-float';
import { CheckboxQuestion } from '../../../../models/forms/question-checkbox';
import { ProductsService } from '../../../../../resources/data-warehouse/products/products.service';
import { FeaturesService } from '../../../../../resources/data-warehouse/products/features.service';
import { MultiselectDataSource } from '../../../../components/multiselect/multiselect';
import { UntypedFormGroup } from '@angular/forms';
import { multiselectPresenceValidator } from '../../../validations.service';
import { getParseBooleanValue, getParseDynamicDateValue, getParseFloatValue, getParseIntValue, getParseMultiselectIdValues, getParseObjectKeysIdValue, getParseOperatorValue, getParseSelectedIdsValue, getParseSingleSelectOperatorIdValue, getParseValue, setLocationsFiltering } from '../../utils/common.utils';
import { LocationsTaxonomyTermsService } from '../../../../../resources/data-warehouse/locations/location-taxonomy-terms.service';
import { TicketsCondition } from '../../../../models/segments/conditions/tickets-condition';
import { FeatureFlagsService } from '../../../feature-flags.service';
import { Option } from '../../../../models/common/option';
import { getFormGroupValidations } from './validations/form.validations';
import { OperatorsService } from '../../common/operators.service';

@Injectable()
export class TicketsConditionService implements SegmentConditionProvider {

  flags = this.featureFlags.flags;
  inputs: QuestionBase<unknown>[];
  customValidators = [(control: UntypedFormGroup) => getFormGroupValidations(control)];

  private numericOperatorOpts = this.operatorsService.getNumericOperators();
  private hasBoughtOperatorOpts = this.operatorsService.getHasBoughtOperators();

  constructor(
    private translate: TranslateService,
    private locationsTaxonomyTermsService: LocationsTaxonomyTermsService,
    private locationsService: LocationsService,
    private productsService: ProductsService,
    private featuresService: FeaturesService,
    private dateService: DateService,
    private featureFlags: FeatureFlagsService,
    private operatorsService: OperatorsService
  ) {}

  getInputs(params?: TicketsCondition): QuestionBase<unknown>[] {
    if (this.flags.showLocationTaxonomyTermIds) {
      setLocationsFiltering(params, this.locationsService);
    }

    /* Check boolean to modify features selection */
    this.featuresService.treatPkAsId = true;

    /* Milliseconds to define a unique key for radios / checkboxes */
    const milliseconds = new Date().getMilliseconds();

    const inputs = [
      new RadioQuestion({
        id: `scope_${milliseconds}`, /* Radios must have unique identifier in DOM */
        cssClasses: 'radio-inline radio-info',
        key: `scope_${milliseconds}`,
        type: 'radio',
        options: this.getConditionTypes(),
        value: 'activity',
        getValue: getParseValue(),
        parseValue: (value) => {
          return (value?.length) ? this.getConditionTypes().find(type => type.id === value).id : null;
        }
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: `total_sum_open_to_nonpositive_tickets_${milliseconds}`,
        label: this.translate.instant('resources.segment_conditions.fields.total_sum_open_to_nonpositive_tickets'),
        getValue: getParseBooleanValue(),
        parseValue: getParseBooleanValue(),
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: `activity_count_open_to_anyone_${milliseconds}`,
        label: this.translate.instant('resources.segment_conditions.fields.activity_count_open_to_anyone'),
        getValue: getParseBooleanValue(),
        parseValue: getParseBooleanValue(),
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'operator',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.hasBoughtOperatorOpts,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        getValue: getParseValue(),
        parseValue: getParseSingleSelectOperatorIdValue(this.hasBoughtOperatorOpts),
        customValidators: [multiselectPresenceValidator]
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_from',
        label: this.translate.instant('resources.segment_conditions.fields.date_from'),
        getValue: getParseDynamicDateValue(this.dateService),
        parseValue: getParseObjectKeysIdValue()
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_to',
        label: this.translate.instant('resources.segment_conditions.fields.date_to'),
        getValue: getParseDynamicDateValue(this.dateService),
        parseValue: getParseObjectKeysIdValue()
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'location_taxonomy_term_ids',
        label: this.translate.instant('resources.segment_conditions.fields.location_taxonomy_term_ids'),
        dataSource: this.locationsTaxonomyTermsService,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        getValue: getParseSelectedIdsValue(),
        parseValue: getParseMultiselectIdValues()
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'location_ids',
        label: this.translate.instant('resources.segment_conditions.fields.location_ids'),
        dataSource: this.locationsService,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        getValue: getParseSelectedIdsValue(),
        parseValue: getParseMultiselectIdValues()
      }),
      /* Required if scope is product */
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'product_ids',
        required: true,
        label: this.translate.instant('resources.segment_conditions.fields.product_ids'),
        dataSource: this.productsService,
        useToken: true,
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
        getValue: getParseSelectedIdsValue(),
        parseValue: getParseMultiselectIdValues()
      }),
      /* Required if scope is feature. Values to API ara (taxonomy_slug | external_id) feature.pk */
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'feature_ids',
        required: true,
        label: this.translate.instant('resources.segment_conditions.fields.feature_ids'),
        dataSource: this.featuresService,
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
        /* "amarketing|8", "familiy_1545657|6", "Claclave|7" */
        /* "[MultiselectDataSource, MultiselectDataSource...]" */
        getValue: (values) =>  {
          if (!values) { return ({selectedIds: []}); }
          if (values?.length && values[0] instanceof MultiselectDataSource) {
            values = values.map( val => val.rawElement.pk );
          }
          return { selectedIds: values ? values : [] };
        },
        parseValue: (values) => ((values?.length) ? values.map( value => value.rawElement.pk ) : [])
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'total_sum_operator',
        label: this.translate.instant('resources.segment_conditions.fields.total_sum_operator'),
        options: this.numericOperatorOpts,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        getValue: getParseValue(),
        parseValue: getParseSingleSelectOperatorIdValue(this.numericOperatorOpts)
      }),
      new FloatQuestion({
        key: 'total_sum_value',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.total_sum_value'),
        type: 'number',
        step: 0.1,
        getValue: getParseValue(false),
        parseValue: getParseFloatValue()
      }),
      new FloatQuestion({
        key: 'total_sum_value_1',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.total_sum_value_1'),
        type: 'number',
        step: 0.1,
        getValue: getParseValue(false),
        parseValue: getParseFloatValue()
      }),
      new FloatQuestion({
        key: 'total_sum_value_2',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.total_sum_value_2'),
        type: 'number',
        step: 0.1,
        getValue: getParseValue(false),
        parseValue: getParseFloatValue()
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'activity_count_operator',
        label: this.translate.instant('resources.segment_conditions.fields.activity_count_operator'),
        options: this.numericOperatorOpts,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        getValue: getParseValue(),
        parseValue: getParseSingleSelectOperatorIdValue(this.numericOperatorOpts)
      }),
      new FloatQuestion({
        key: 'activity_count_value',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.activity_count_value'),
        type: 'number',
        min: 0,
        step: 1,
        getValue: getParseValue(false),
        parseValue: getParseIntValue()
      }),
      new FloatQuestion({
        key: 'activity_count_value_1',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.activity_count_value_1'),
        type: 'number',
        min: 0,
        step: 1,
        getValue: getParseValue(false),
        parseValue: getParseIntValue()
      }),
      new FloatQuestion({
        key: 'activity_count_value_2',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.activity_count_value_2'),
        type: 'number',
        min: 1,
        step: 1,
        getValue: getParseValue(false),
        parseValue: getParseIntValue()
      })
    ];

    if (params) { this.prepareInputValuesFromParams(inputs, params); }

    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI(params: TicketsCondition): TicketsCondition {
    const parsedValuesObj = {};

    Object.keys(params).forEach( _key => {
      const input = this.getInputByKey(this.inputs, _key);
      const parsedValue = input.parseValue(params[_key]);
      const key = this.getKeyWithoutDateId(_key);
      parsedValuesObj[key] = parsedValue;
    });

    if (parsedValuesObj['scope'] !== 'feature') {
      delete parsedValuesObj['feature_ids'];
    }

    if (parsedValuesObj['scope'] !== 'product') {
      delete parsedValuesObj['product_ids'];
    }

    if (parsedValuesObj['total_sum_operator'] !== 'bt') {
      delete parsedValuesObj['total_sum_value_1'];
      delete parsedValuesObj['total_sum_value_2'];
    } else {
      delete parsedValuesObj['total_sum_value'];
    }

    if (parsedValuesObj['activity_count_operator'] !== 'bt') {
      delete parsedValuesObj['activity_count_value_1'];
      delete parsedValuesObj['activity_count_value_2'];
    } else {
      delete parsedValuesObj['activity_count_value'];
    }

    return parsedValuesObj;
  }

  prepareFormValuesFromAPI(params: TicketsCondition): TicketsCondition {
    const parseValues = {
      scope: getParseValue(false),
      total_sum_open_to_nonpositive_tickets: getParseBooleanValue(),
      activity_count_open_to_anyone: getParseBooleanValue(),
      operator: getParseOperatorValue(this.hasBoughtOperatorOpts),
      date_from: getParseValue(),
      date_to: getParseValue(),
      location_taxonomy_term_ids: getParseValue(),
      location_ids: getParseValue(),
      product_ids: getParseValue(),
      feature_ids: getParseValue(),
      total_sum_operator: getParseOperatorValue(this.numericOperatorOpts),
      total_sum_value: (value) => value,
      total_sum_value_1: (value) => value,
      total_sum_value_2: (value) => value,
      activity_count_operator: getParseOperatorValue(this.numericOperatorOpts),
      activity_count_value: (value) => value,
      activity_count_value_1: (value) => value,
      activity_count_value_2: (value) => value
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach( _key => {
      const key: string = this.getKeyWithoutDateId(_key);
      if (parseValues[key]) { parsedValuesObj[key] = parseValues[key](params[key]); }
    });

    return parsedValuesObj;
  }

  private prepareInputValuesFromParams(inputs: QuestionBase<unknown>[], params: TicketsCondition): void {
    Object.keys(params).forEach(_key => {
      const input = this.getInputByKey(inputs, _key);
      const value = input.getValue(params[_key]);
      if (value?.hasOwnProperty('selectedIds')) {
        input.selectedIds = value.selectedIds;
      } else {
        input.value = value;
      }
    });
  }

  private getInputByKey(_inputs: QuestionBase<unknown>[], _key: string ): QuestionBase<unknown> {
    let input: QuestionBase<unknown>;
    if (_key.indexOf('scope') >= 0) {
      input = _inputs[0];
    } else if (_key.indexOf('total_sum_open_to_nonpositive_tickets') >= 0) {
      input = _inputs[1];
    } else if (_key.indexOf('activity_count_open_to_anyone') >= 0) {
      input = _inputs[2];
    } else {
      input = _inputs.find( _input => _input.key === _key);
    }
    return input;
  }

  private getKeyWithoutDateId(_key: string): string {
    let key: string;
    if (_key.indexOf('scope') >= 0) {
      key = 'scope';
    } else if (_key.indexOf('total_sum_open_to_nonpositive_tickets') >= 0) {
      key = 'total_sum_open_to_nonpositive_tickets';
    } else if (_key.indexOf('activity_count_open_to_anyone') >= 0) {
      key = 'activity_count_open_to_anyone';
    } else {
      key = _key;
    }
    return key;
  }

  private getConditionTypes(): Option[] {
    return [
      { id: 'activity', name: this.translate.instant('resources.segment_conditions.types.sales_by_activity') },
      { id: 'feature', name: this.translate.instant('resources.segment_conditions.types.sales_by_feature') },
      { id: 'product', name: this.translate.instant('resources.segment_conditions.types.sales_by_product') },
    ];
  }
}