import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResourcesService } from './resources.service';
import { MultiselectDataSource, MultiselectDataSourceable } from '../components/multiselect/multiselect';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TagSalesExtraValuesService extends ResourcesService implements MultiselectDataSourceable {

  tagSales: Subject<any> = new Subject<any>();
  tagSales$ = this.tagSales.asObservable();
  selectedOptsSource = new BehaviorSubject<{selectedIds: string[], key: string}>(null);
  selectedOptsSource$ = this.selectedOptsSource.asObservable();

  constructor(http: HttpClient) {
    super(http);
  }

  getAll() {
    const requestOptions = {
      apiEndPoint: '/segment_conditions/tag_sales',
      sorting: {},
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  fetchMultiselect(searchValues?: string, page?: number): Observable<object> {
    let requestOptions = {
      apiEndPoint: '/segment_conditions/tag_sales',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues } };
      requestOptions = { ...requestOptions, ...filterObj };
    }

    return this.getData(requestOptions).pipe(map(data => {
      let parsedData = {
        list: data['extra_values'].filter(item => typeof item.id === 'number')
      }
      this.tagSales.next(parsedData);
      return parsedData;
    }));
  }

  fetchSelectedById?(id: number | string): Observable<any> {
    return this.getData({apiEndPoint: `/segment_conditions/tag_sales?id=${id}`}).pipe(
      map(item => item['extra_values']),
      map(memberArray => {
        id = parseInt(id.toString());
        return memberArray.filter(x => x.id === id)[0];
      }));
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.name, element);
  }

  refreshSelectedOptsSource(selectedIds: string[], key: string) {
    this.selectedOptsSource.next({selectedIds, key});
  }
}
