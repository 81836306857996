import { ReportCouponsAppAnalysisHeader, ReportCouponsAppAnalysisCalculatedData } from "../model/report-coupons-in-app-analysis.model";

export const ReportCouponsAppAnalysisBaseHeaders: ReportCouponsAppAnalysisHeader[] = [
  {
    key: 'campaign_category',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.campaign_category',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): string => {
      return element.campaign_category;
    },
    type: 'string'
  },
  {
    key: 'campaign_name',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.campaign_name',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): string => {
      return element.campaign_name;
    },
    type: 'string'
  },
  {
    key: 'origin_type',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.origin_type',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): string => {
      return element.origin_type;
    },
    type: 'string'
  },
  {
    key: 'campaign_id',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.campaign_id',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): string => {
      return element.campaign_id;
    },
    type: 'string'
  },
  {
    key: 'plan_id',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.plan_id',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): string => {
      return element.plan_id;
    },
    type: 'string'
  },
  {
    key: 'date_from',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.start_end_date',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): string => {
      return `${element.date_from} - ${element.date_to}`;
    },
    type: 'string'
  },
  {
    key: 'channel',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.channel',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): string => {
      return element.channel;
    },
    type: 'string'
  },
  {
    key: 'coupon_id',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupon_id',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): string => {
      return element.coupon_id;
    },
    type: 'string'
  },
  {
    key: 'coupon_name',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupon_name',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): string => {
      return element.coupon_name;
    },
    type: 'string'
  },
  {
    key: 'coupon_discount',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupon_discount',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return parseFloat(element.coupon_discount);
    },
    type: 'float'
  },
  {
    key: 'auto_redeem',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.auto_redeem',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): boolean => {
      return element.auto_redeem;
    },
    type: 'boolean'
  },
  {
    key: 'coupon_max_redemptions',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupon_max_redemptions',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.coupon_max_redemptions;
    },
    type: 'integer'
  },
  {
    key: 'customers_impacted_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.customers_impacted_without_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.customers_impacted_without_app;
    },
    type: 'integer'
  },
  {
    key: 'customers_impacted_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.customers_impacted_with_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.customers_impacted_with_app;
    },
    type: 'integer'
  },
  {
    key: 'coupons_issued_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupons_issued_without_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.coupons_issued_without_app;
    },
    type: 'integer'
  },
  {
    key: 'coupons_issued_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupons_issued_with_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.coupons_issued_with_app;
    },
    type: 'integer'
  },
  {
    key: 'coupon_printing_customers_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupon_printing_customers_without_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.coupon_printing_customers_without_app;
    },
    type: 'integer'
  },
  {
    key: 'coupon_printing_customers_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupon_printing_customers_with_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.coupon_printing_customers_with_app;
    },
    type: 'integer'
  },
  {
    key: 'activating_customers_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.activating_customers_with_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.activating_customers_with_app;
    },
    type: 'integer'
  },
  {
    key: 'coupons_activated_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupons_activated_with_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.coupons_activated_with_app;
    },
    type: 'integer'
  },
  {
    key: 'redeemer_customers_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.redeemer_customers_without_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.redeemer_customers_without_app;
    },
    type: 'integer'
  },
  {
    key: 'redeemer_customers_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.redeemer_customers_with_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.redeemer_customers_with_app;
    },
    type: 'integer'
  },
  {
    key: 'redeemer_customers_with_app_after_selection',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.redeemer_customers_with_app_after_selection',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.redeemer_customers_with_app_after_selection;
    },
    type: 'integer'
  },
  {
    key: 'total_voucher_redemptions_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.total_voucher_redemptions_without_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.total_voucher_redemptions_without_app;
    },
    type: 'integer'
  },
  {
    key: 'total_voucher_redemptions_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.total_voucher_redemptions_with_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.total_voucher_redemptions_with_app;
    },
    type: 'integer'
  },
  {
    key: 'coupons_redemptions_per_customer_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupons_redemptions_per_customer_without_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.coupons_redemptions_per_customer_without_app;
    },
    type: 'integer'
  },
  {
    key: 'coupons_redemptions_per_customer_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupons_redemptions_per_customer_with_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.coupons_redemptions_per_customer_with_app;
    },
    type: 'integer'
  },
  {
    key: 'sum_discount_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.sum_discount_without_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.sum_discount_without_app;
    },
    type: 'currency_float'
  },
  {
    key: 'sum_discount_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.sum_discount_with_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.sum_discount_with_app;
    },
    type: 'currency_float'
  },
  {
    key: 'average_discount_per_customer_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.average_discount_per_customer_without_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.average_discount_per_customer_without_app;
    },
    type: 'currency_float'
  },
  {
    key: 'average_discount_per_customer_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.average_discount_per_customer_with_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.average_discount_per_customer_with_app;
    },
    type: 'currency_float'
  },
  {
    key: 'percentage_printing_customers_without_app_ratio',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.percentage_printing_customers_without_app_ratio',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.percentage_printing_customers_without_app_ratio;
    },
    type: 'percentage'
  },
  {
    key: 'percentage_printing_customers_with_app_ratio',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.percentage_printing_customers_with_app_ratio',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.percentage_printing_customers_with_app_ratio;
    },
    type: 'percentage'
  },
  {
    key: 'percentage_customers_engaged_with_app_ratio',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.percentage_customers_engaged_with_app_ratio',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.percentage_customers_engaged_with_app_ratio;
    },
    type: 'percentage'
  },
  {
    key: 'percentage_redeemer_customers_without_app_ratio',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.percentage_redeemer_customers_without_app_ratio',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.percentage_redeemer_customers_without_app_ratio;
    },
    type: 'percentage'
  },
  {
    key: 'percentage_redeemer_customers_with_app_ratio',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.percentage_redeemer_customers_with_app_ratio',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.percentage_redeemer_customers_with_app_ratio;
    },
    type: 'percentage'
  },
  {
    key: 'percentage_redeemer_customers_with_app_after_selection_ratio',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.percentage_redeemer_customers_with_app_after_selection_ratio',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.percentage_redeemer_customers_with_app_after_selection_ratio;
    },
    type: 'percentage'
  },
  {
    key: 'percentage_redemption_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.percentage_redemption_without_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.percentage_redemption_without_app;
    },
    type: 'percentage'
  },
  {
    key: 'percentage_redemption_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.percentage_redemption_with_app',
    display: true,
    calculateValueFn: (element: ReportCouponsAppAnalysisCalculatedData): number => {
      return element.percentage_redemption_with_app;
    },
    type: 'percentage'
  }
];

export const ReportCouponsAppAnalysisTableHeaders = [
  {
    key: 'campaign_category',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.campaign_category'
  },
  {
    key: 'campaign_name',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.campaign_name'
  },
  {
    key: 'origin_type',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.origin_type'
  },
  {
    key: 'campaign_id',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.campaign_id'
  },
  {
    key: 'plan_id',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.plan_id'
  },
  {
    key: 'start_end_date',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.start_end_date'
  },
  {
    key: 'channel',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.channel'
  },
  {
    key: 'coupon_id',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupon_id'
  },
  {
    key: 'coupon_name',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupon_name'
  },
  {
    key: 'coupon_discount',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupon_discount'
  },
  {
    key: 'auto_redeem',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.auto_redeem'
  },
  {
    key: 'coupon_max_redemptions',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupon_max_redemptions'
  },
  {
    key: 'customers_impacted_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.customers_impacted_without_app'
  },
  {
    key: 'customers_impacted_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.customers_impacted_with_app'
  },
  {
    key: 'coupons_issued_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupons_issued_without_app'
  },
  {
    key: 'coupons_issued_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupons_issued_with_app'
  },
  {
    key: 'coupon_printing_customers_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupon_printing_customers_without_app'
  },
  {
    key: 'coupon_printing_customers_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupon_printing_customers_with_app'
  },
  {
    key: 'activating_customers_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.activating_customers_with_app'
  },
  {
    key: 'coupons_activated_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupons_activated_with_app'
  },
  {
    key: 'redeemer_customers_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.redeemer_customers_without_app'
  },
  {
    key: 'redeemer_customers_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.redeemer_customers_with_app'
  },
  {
    key: 'redeemer_customers_with_app_after_selection',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.redeemer_customers_with_app_after_selection'
  },
  {
    key: 'total_voucher_redemptions_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.total_voucher_redemptions_without_app'
  },
  {
    key: 'total_voucher_redemptions_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.total_voucher_redemptions_with_app'
  },
  {
    key: 'coupons_redemptions_per_customer_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupons_redemptions_per_customer_without_app'
  },
  {
    key: 'coupons_redemptions_per_customer_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.coupons_redemptions_per_customer_with_app'
  },
  {
    key: 'sum_discount_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.sum_discount_without_app'
  },
  {
    key: 'sum_discount_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.sum_discount_with_app'
  },
  {
    key: 'average_discount_per_customer_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.average_discount_per_customer_without_app'
  },
  {
    key: 'average_discount_per_customer_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.average_discount_per_customer_with_app'
  },
  {
    key: 'percentage_printing_customers_without_app_ratio',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.percentage_printing_customers_without_app_ratio'
  },
  {
    key: 'percentage_printing_customers_with_app_ratio',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.percentage_printing_customers_with_app_ratio'
  },
  {
    key: 'percentage_customers_engaged_with_app_ratio',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.percentage_customers_engaged_with_app_ratio'
  },
  {
    key: 'percentage_redeemer_customers_without_app_ratio',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.percentage_redeemer_customers_without_app_ratio'
  },
  {
    key: 'percentage_redeemer_customers_with_app_ratio',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.percentage_redeemer_customers_with_app_ratio'
  },
  {
    key: 'percentage_redeemer_customers_with_app_after_selection_ratio',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.percentage_redeemer_customers_with_app_after_selection_ratio'
  },
  {
    key: 'percentage_redemption_without_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.percentage_redemption_without_app'
  },
  {
    key: 'percentage_redemption_with_app',
    translateKey: 'resources.reports.coupons_in_app_analysis.columns.percentage_redemption_with_app'
  }
];
