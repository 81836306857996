import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MultiselectDataSource, MultiselectDataSourceable } from '../../../shared/components/multiselect/multiselect';
import { BehaviorSubject } from 'rxjs';
import { of } from 'rxjs';

@Injectable()
export class PlanCampaignService extends ResourcesService implements MultiselectDataSourceable {

  planId: number;
  optsSource = new BehaviorSubject<number>(null);
  optsSource$ = this.optsSource.asObservable();

  constructor(http: HttpClient) {
    super(http);
  }

  getOne(id: string): Observable<any> {
    return this.getData({apiEndPoint: `campaign_plans/${id}`});
  }

  patchCampaign(payload: Object, id: string): Observable<any> {
    return this.patchResource(payload, `/campaign_plans/${id}`);
  }

  createCampaign(payload: Object): Observable<any> {
    return this.postResource(payload, 'campaign_plans');
  }

  cloneCampaign(payload: Object, id: string): Observable<any> {
    return this.postResource(payload, `campaign_plans/${id}/clone`);
  }

  restoreStatus(id: string): Observable<any> {
    return this.postResource({}, `/campaign_plans/${id}/restore`);
  }

  submit(id: string): Observable<any> {
    return this.postResource({}, `/campaign_plans/${id}/complete`);
  }

  completeAll(payload): Observable<any> {
    return this.postResource({campaign_plan_ids: payload}, `/campaign_plans/complete_all`);
  }

  reject(id: string): Observable<any> {
    return this.postResource({}, `/campaign_plans/${id}/reject`);
  }

  setPlan(id: number) {
    this.planId = id;
    this.optsSource.next(this.planId);
  }

  // TBD: Should we be able to filter by campaign plan name?
  fetchMultiselect( searchValues?: string, page?: number, filters?: object ) {

    if ( !this.planId ) { return of({}); }

    let requestOptions = {
      apiEndPoint: `plans/${this.planId}/campaign_plans`,
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {},
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getOne(`${id}`);
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`, element);
  }

  deleteCampaignPlan(id: number) {
    return this.deleteResource({}, `/campaign_plans/${id}`);
  }

}
