<div class="alert alert-danger fade show mrg-btm-10" *ngFor="let error of errors;" [innerText]="error"></div>
<div *ngIf="form">
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="row">
      <div class="col-md-12">
        <div id="accordion-1" class="accordion panel-group" role="tablist" aria-multiselectable="true">
          <div class="panel panel-default border-radius-4">
            <div class="panel-heading" role="tab" id="headingOne">
              <div class="row justify-content-end collapse-filters-header">
                <h4 class="panel-title">
                  <a (click)="handleCollapse()"
                    class="d-flex align-items-center no-mrg no-pdd mrg-right-15 mrg-top-10 mrg-bottom-10 font-size-14"
                    role="button"
                    data-toggle="collapse"
                    [attr.data-target]="'#'+slug"
                    href="#{{slug}}"
                    aria-expanded="true"
                  >
                    <span class="mrg-right-15">{{ (isCollapsed ? 'components.data_table_filter_analytics.toggle_button.show_filters' : 'components.data_table_filter_analytics.toggle_button.hide_filters') | translate }}</span>
                    <em title="{{(isCollapsed ? 'components.data_table_filter_analytics.toggle_button.show_filters' : 'components.data_table_filter_analytics.toggle_button.hide_filters') | translate}}"
                      class="icon icon-up-down ti-angle-up font-size-12 no-mrg"></em>
                  </a>
                </h4>
              </div>
            </div>

            <div id="{{slug}}" class="collapse show">
              <div class="panel-body no-pdd-vertical mrg-horizon-20 mrg-top-10">

                <div *ngIf="cfg.comparableDatesFilters" class="row align-items-end">
                  <div class="col-md-2 form-group">
                    <app-question [question]="getInputConfig('date_from')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group">
                    <app-question [question]="getInputConfig('date_to')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group">
                    <app-question [question]="getInputConfig('compare_with')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="!getInputConfig('date_from_compared').hidden">
                    <app-question [question]="getInputConfig('date_from_compared')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="!getInputConfig('date_to_compared').hidden">
                    <app-question [question]="getInputConfig('date_to_compared')"
                      [form]="form">
                    </app-question>
                  </div>
                </div>

                <div class="row">
                  <ng-container *ngIf="!cfg.comparableDatesFilters">
                    <div class="col-md-2 form-group">
                      <app-question [question]="getInputConfig('date_from')"
                        [form]="form">
                      </app-question>
                    </div>
                    <div class="col-md-2 form-group">
                      <app-question [question]="getInputConfig('date_to')"
                        [form]="form">
                      </app-question>
                    </div>
                  </ng-container>
                  <div class="col-md-2 form-group" *ngIf="cfg.segmentIdFilter">
                    <app-question [question]="getInputConfig('segment_id')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.dayOfWeekFilter">
                    <app-question [question]="getInputConfig('day_of_week')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.measureTypeFilter">
                    <app-question [question]="getInputConfig('param_measure_type')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.dashboardPerUnitMeasure">
                    <app-question [question]="getInputConfig('unit_measure')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.dashboardPerUnitMeasureQuantity">
                    <app-question [question]="getInputConfig('unit_measure_quantity')"
                      [form]="form">
                    </app-question>
                  </div>
                  <ng-container *ngFor="let filter of nielsenFilters; let i = index;">
                    <div class="col-md-2 form-group">
                      <app-single-input [form]="form"
                        [filter]="filter"
                        [nielsenTaxonomy]="nielsenTaxonomies[i]"
                        (onFilterEmitter)="onNielsenFilterChange($event)">
                      </app-single-input>
                    </div>
                  </ng-container>
                  <div class="col-md-2 form-group" *ngIf="!cfg.hideBrandSupplierFilters">
                    <app-question [question]="getInputConfig('supplier')"
                      [form]="form"
                      [filters]="globalFilters[2].filters">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.brandPkFilter">
                    <app-question [question]="getInputConfig('brand_pk')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="!cfg.hideBrandSupplierFilters">
                    <app-question [question]="getInputConfig('brand')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group">
                    <app-question [question]="getInputConfig('location_taxonomy_term_ids')"
                      [form]="form"
                      (multiselectChanged)="handleLocationCategoriesValueChanges($event)">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group">
                    <app-question [question]="getInputConfig('location_ids')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.ticketsFilter">
                    <app-question [question]="getInputConfig('tickets')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.supplierBrandFilter">
                    <app-question [question]="getInputConfig('supplier_brand')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.viewAndTopBrandSupplierFilters">
                    <app-question [question]="getInputConfig('view_brand_supplier')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.viewAndTopBrandSupplierFilters">
                    <app-question [question]="getInputConfig('top_brand_supplier')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.chartMetricFilter">
                    <app-question [question]="getInputConfig('chart_metric')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.chartMetricSupplierBrandFilter">
                    <app-question [question]="getInputConfig('chart_metric_supplier_brand')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.chartMetricasFilter">
                    <app-question [question]="getInputConfig('chart_metricas')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.chartMetricsWithOwnBrandFilter">
                    <app-question [question]="getInputConfig('chart_metrics_with_own_brand')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.chartGranularityFilter">
                    <app-question [question]="getInputConfig('chart_granularity')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.countryFilter">
                    <app-question [question]="getInputConfig('country')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.segmentCategoryIdFilter">
                    <app-question [question]="getInputConfig('segment_category_id')"
                      [form]="form">
                    </app-question>
                  </div>
                  <div class="col-md-2 form-group" *ngIf="cfg.idEanProductFilter">
                    <app-question [question]="getInputConfig('id_ean_product')"
                      [form]="form">
                    </app-question>
                  </div>
                </div>

                <div class="row" *ngIf="cfg.onlyComparableStoresFilter">
                  <div class="col-xl-4 col-lg-6 col-md-6 form-group flex align-items-center">
                    <app-question [question]="getInputConfig('only_comparable_stores')"
                      [form]="form">
                    </app-question>
                  </div>
                </div>
              </div>


              <div *ngIf="!isCollapsed" class="panel-body mrg-horizon-20 mrg-btm-10">
                <button id="searchBtn"
                  [attr.data-cy]="'button_search'"
                  class="btn btn-info btn-icon btn-sm"
                  type="submit"
                  data-toggle="collapse"
                  [attr.data-target]="'#'+slug"
                  [attr.aria-controls]="''+slug"
                  aria-expaned="true"
                  [disabled]="form.invalid">
                    <i class="ei-search-alt font-size-14"></i>
                    <span [translate]="'components.data-table-filter.buttons.search'"></span>
                </button>
                <button id="clearBtn"
                  [attr.data-cy]="'button_clear'"
                  class="btn btn-default btn-icon btn-sm"
                  type="button"
                  (click)="resetAllFilters()">
                    <i class="ei-eraser-alt font-size-14"></i>
                    <span [translate]="'components.data-table-filter.buttons.reset'"></span>
                </button>
                <app-save-filter
                  *ngIf="flags.saveFiltersVisibility"
                  #saveFilter
                  [filterForm]="form"
                  (filterSelectedValues)="handleFilterSelectedValues($event)">
                </app-save-filter>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
