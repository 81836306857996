<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-20">
          <div class="row">
            <div class="col-md-12 ml-auto mr-auto">
              <span class="text-bold" [translate]="'resources.reserved_ids.fields.example'"></span><br>
              <div class="mrg-top-10">
                <span class="text-bold" [translate]="'resources.reserved_ids.fields.example1'"></span><br>
                <span class="text-bold" [translate]="'resources.reserved_ids.fields.example2'"></span><br>
              </div>
              <form  [formGroup]="reservedIdsForm" *ngIf="reservedIdsForm">
                <div class="row mrg-top-15">
                  <div class="col-md-12">
                    <div class="form-group">
                      <app-question [question]="getInputConfig('codes')" [form]="reservedIdsForm"></app-question>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
