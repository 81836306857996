<div class="modal-backdrop fade in" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="plan">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="mrg-left-15 mrg-btm-5 first-letter-upper">{{'resources.plans.messages.modal_schedule_title' | translate}}:&nbsp;<span>{{plan.name}}</span></h4>
        <button class="modal-close" (click)="closeModal()">
          <i class="ti-close"></i>
        </button>
      </div>

      <div class="modal-body background-gray">
        <div class="row">
          <div class="col-md-12">

            <div class="container-fluid">
              <div class="row">
                <div class="mrg-top-10 col-md-12">

                  <div class="card-block no-pdd">

                    <div class="row">
                      <div class="col-md-8 ml-auto mr-auto">
                        <label class="text-bold">{{'resources.plans.messages.schedule_title' | translate}}</label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-8 ml-auto mr-auto">
                        <form [formGroup]="schedulePlanForm">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group no-mrg-btm">
                                <app-question [question]="getInputConfig('schedule')" 
                                              [form]="schedulePlanForm"
                                              (blur)="handleSchedule($event)">
                                </app-question>
                              </div>
                            </div>
                          </div>

                          <div class="row" *ngIf="hasFormKeyWithValue('schedule', 'schedule')">
                            <div class="col-md-12">
                              <div class="form-group no-mrg-btm">
                                <label for="schedule_date" class="text-bold">{{'resources.plans.fields.date' | translate}}<span class="text-bold text-danger">&nbsp;*</span></label>
                                <app-question [question]="getInputConfig('schedule_date')" 
                                              [form]="schedulePlanForm">
                                </app-question>
                              </div>
                            </div>
                          </div>
                        </form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer background-white">
        <div class="pull-right d-flex align-items-center">
          <span class="mrg-right-10">
            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          </span>
          <button class="btn btn-info pull-right" (click)="handleFormSelection()"
            [disabled]="schedulePlanForm.invalid">{{'common.buttons.confirm' | translate}}
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
