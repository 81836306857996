export class LinkedCampaign {
  via: string;
  id: number;
  motive: string;
  name: string;
  segment: {
    operator: string;
    name: string;
  };

  constructor(cfg: object) {
    this.via = cfg['via'];
    this.id = cfg['id'];
    this.motive = cfg['motive'];
    this.name = cfg['name'];
  }
}

export class LinkedMessages {
  parent_id: number;
  name: string;
}

export class LinkedVoucheables {
  database_id: number;
}

export class LinkedCoupons {
  parent_id: number;
  name: string;
}

export class CampaignHistory {
  apply: number;
  campaign: LinkedCampaign;
  coupons: LinkedCoupons;
  customers: number;
  distribution: string;
  id: number;  
  message: string;
  messages: LinkedMessages;
  segment: {
    id: number;
    name: string;
    operator: string;
  };
  segment_id: number;
  sent_at: string;
  sent_number: number;
  status: string;
  voucheables: LinkedVoucheables;

  constructor(cfg: object) {
    this.apply = cfg['apply'];
    this.campaign = cfg['campaign'] ? new LinkedCampaign(cfg['campaign']) : null;
    this.coupons = cfg['coupons'];
    this.customers = cfg['customers'];
    this.id = cfg['id'];
    this.messages = cfg['messages'];
    this.segment = cfg['segment'];
    this.segment_id = cfg['segment_id'];
    this.sent_at = cfg['sent_at'];
    this.sent_number = cfg['sent_number'];
    this.status = cfg['status'];
    this.voucheables = cfg['voucheables'];
  }

}
