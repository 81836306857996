import { Component, OnInit, Input } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../services/question-control.service';
import { CampaignHistoriesService } from '../../../../../resources/campaigns/campaign-histories.service';
import { MailConditionService } from '../../../../services/conditions/mail-condition.service';
import { MultiselectService } from '../../../multiselect/multiselect.service';

@Component({
  selector: 'app-email-impact-condition',
  templateUrl: './email-impact-condition.component.html',
  styleUrls: ['./email-impact-condition.component.css']
})
export class EmailImpactConditionComponent implements OnInit {
  
  campaignsSelectedLength: number;
  
  private get campaingIdsInput(): QuestionBase<any> {
    return this.getInputConfig('campaign_ids');
  }
  
  private get deliveryTypeInput(): QuestionBase<any> {
    return this.getInputConfig('delivery_type');
  }
  
  private get campaingHistoryIdsInput(): QuestionBase<any> {
    return this.getInputConfig('campaign_history_ids');
  }
  
  @Input('item') item: Condition;
  @Input('inputs') inputs: QuestionBase<any>[] = [];
  @Input('form') form: UntypedFormGroup;

  constructor( 
    private qcs: QuestionControlService,
    private emailImpactedConditionService: MailConditionService,
    private campaignHistoriesService: CampaignHistoriesService,
    private multiselectService: MultiselectService
  ) {}

  ngOnInit(): void {
    // Prevent early execution before inputs are set
    setTimeout(() => {
      this.campaignsSelectedLength = this.campaingIdsInput.selectedIds?.length ?? 0;
      this.refreshDeliveryTypeOptions();
    });
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    if ( !value ) { return false; }
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }
  
  handleCampaignsSelected(campaignIds: {id: number, name: string, rawElement: Object}[]): void {
    this.campaingIdsInput.selectedIds = campaignIds.map(campaignId => campaignId.id);
    this.campaignsSelectedLength = campaignIds.length ?? 0;
    if (!this.campaignsSelectedLength) {
      this.resetDeliveryType();
    } else if (this.campaignsSelectedLength === 1) {
      this.campaignHistoriesService.setCampaign(campaignIds[0].id);
    }
    this.resetCampaignHistoryIds();
    this.refreshDeliveryTypeOptions();
  }
  
  handleActionSelected(): void {
    this.resetCampaignHistoryIds();
    this.resetDeliveryType();
    this.refreshDeliveryTypeOptions();
  }

  handleDeliveryTypeSelected(): void {
    this.resetCampaignHistoryIds();
  }

  showApplies(): boolean {
    const deliveryTypeAllOrAny = this.hasFormKeyWithValue('delivery_type', 'all') ||
                                 this.hasFormKeyWithValue('delivery_type', 'any');
    return this.campaignsSelectedLength === 1 && deliveryTypeAllOrAny;
  }
  
  private resetDeliveryType(): void {
    this.deliveryTypeInput.value = [];
    this.form.get('delivery_type').patchValue([]);
  }

  private resetCampaignHistoryIds(): void {
    this.campaingHistoryIdsInput.selectedIds = [];
    this.campaingHistoryIdsInput.value = [];
    this.form.get('campaign_history_ids').patchValue([]);
  }

  private refreshDeliveryTypeOptions(): void {
    let action = this.form.get('action').value;
    if (action && action.length === 0) { action = undefined; }

    this.deliveryTypeInput.disabled = !!(action === undefined || (this.campaignsSelectedLength === 0 || this.campaignsSelectedLength === undefined));

    let deliveryTypes: { id: string, name: string }[] = [];
    if (this.campaignsSelectedLength === 1) {
      deliveryTypes = this.emailImpactedConditionService.singleCampaignTypeList();
    } else if ( this.campaignsSelectedLength > 1 ) {
      deliveryTypes = this.emailImpactedConditionService.multiCampaignTypeList();
    }

    if (this.deliveryTypeInput?.value?.length && deliveryTypes?.length) {
      const isSelectedInOptions = deliveryTypes.some(item => item.id === this.deliveryTypeInput.value[0].id && item.name === this.deliveryTypeInput.value[0].name);
      if (!isSelectedInOptions) {
        this.resetDeliveryType();
      }
    }

    this.deliveryTypeInput.options = deliveryTypes;
    this.multiselectService.updateDropdownList.next('delivery_type');
  }

}
