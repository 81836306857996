import {Component, OnInit, ViewChild, Input, OnDestroy} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { DataTableComponent } from '../../../../../shared/components/data-table/data-table.component';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { PercentPipe } from '@angular/common';
import { DataTableConfiguration } from '../../../../../shared/components/data-table/data-table-cfg';
import { Subscription } from 'rxjs';
import { FeatureFlagsService } from './../../../../../shared/services/feature-flags.service';
import { ProfileService } from '../../../../../profiles/profile.service';
import { USERS_PERSONAL_DATA_AUTH } from '../../../../../shared/constants/navigation-roles-not-authorized.constants';

@Component({
  selector: 'app-show-statistics',
  templateUrl: './show-statistics.component.html',
  styleUrls: ['./show-statistics.component.css'],
  providers: [ PercentPipe ]
})

export class ShowStatisticsComponent implements OnInit, OnDestroy {
  id: number;
  apiEndPoint: string;
  couponData;
  loading: boolean;
  dataTableConfigStatistics: DataTableConfiguration;
  couponStatus: string;
  locationIds: any;
  couponsTableTitle: string;
  currentCouponsTag: string;
  currentRole: string;
  flags = this.featureFlags.flags;
  private subs$: Subscription[];

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;
  @Input() apply;
  @Input() couponDetail;

  constructor(
    private translate: TranslateService,
    private refreshCacheService: RefreshCacheService,
    private percentPipe: PercentPipe,
    private route: ActivatedRoute,
    private featureFlags: FeatureFlagsService,
    private profileService: ProfileService,
  ) { }

  ngOnInit() {
    this.subs$ = [];
    this.getParams();
    this.getCouponData(this.apply);
    this.currentRole = this.profileService.getStoredUserRole();
  }

  ngOnDestroy() {
    this.subs$.forEach(sub$ => sub$.unsubscribe());
  }

  getParams() {
    this.setDataTableStatisticsConfig();
    const route$ = this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'vouchers';
      }
    });
    this.subs$.push(route$);
  }

  public statusFilterHandler(filterFormValue) {
    Object.assign(filterFormValue, { apply: this.apply, status: this.couponStatus, location_ids: this.locationIds});
    this.dataTable.onFilter(filterFormValue);
  }

  public getCoupons(status, title) {
    this.couponsTableTitle = this.translate.instant(`resources.campaigns.statistics.title_${title}`);
    this.currentCouponsTag = title;
    const params = {
      apply: this.apply,
      motivator_id: this.couponDetail.parent_id,
      location_ids: this.locationIds,
      status: status
    };
    this.dataTable.onFilter(params);
  }

  public isCouponsTagSelected(tagName) {
    return tagName === this.currentCouponsTag;
  }

  public getCouponDataByEndpoint(endpoint: string) {
    this.loading = true;
    const subscriptionData$ = this.refreshCacheService.getExpensiveData({apiEndPoint: endpoint }).subscribe(reqResponse => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this.couponData = reqResponse;
        subscriptionData$.unsubscribe();
        this.loading = false;
      }
    }, () => subscriptionData$.unsubscribe());
    this.subs$.push(subscriptionData$);
  }

  public getLocationId(location) {
    this.locationIds = location;
  }

  public getCouponData(apply) {
    const params = {
      apiEndPoint: `coupons/${this.id}/status?apply=${apply}&field=status`
    };

    this.loading = true;
    const subscriptionData$ = this.refreshCacheService.getExpensiveData(params).subscribe(reqResponse => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this.couponData = reqResponse;
        subscriptionData$.unsubscribe();
        this.loading = false;
      }
    }, () => subscriptionData$.unsubscribe());
    this.subs$.push(subscriptionData$);
  }

  isNanValue(value, total ) {
    if (total === 0) {
      return 0;
    }
    return this.percentPipe.transform(value / total, '1.1-2', 'es');
  }

  public filterByStatus(couponStatus) {
    this.couponStatus = couponStatus;
    this.statusFilterHandler({});
  }

  setDataTableStatisticsConfig() {

    const endpoint = `vouchers?apply=${this.apply}&motivator_id=${this.couponDetail.parent_id}&type=Coupon`;

    this.dataTableConfigStatistics = {
      tokenPagination: true,
      isActive: true,
      tableActions: [
        {
          name: this.translate.instant('components.table_actions.export_csv'),
          icon: 'ei-export',
          id: 'exportCSV',
          disabled: () => !this.showPersonalData()
        }
      ],
      rowActions: [
        {
          name: this.translate.instant('resources.vouchers.row_actions.details'),
          icon: 'eye',
          id: 'details',
          show: () => this.showPersonalData(),
          callback: (element: {customer_id: number}) => {
            window.open(`#/data-warehouse/profiles/${element.customer_id}/summary`);
          }
        }
      ],
      columns: [
        {
          name: this.translate.instant(
            'resources.coupons.modal.show.statistics.table.columns.name'
          ),
          prop: 'name',
          sortByField: 'name'
        },
        {
          name: this.translate.instant(
            'resources.coupons.modal.show.statistics.table.columns.email'
          ),
          prop: 'email',
          sortByField: 'text'
        },
        {
          name: this.translate.instant(
            'resources.coupons.modal.show.statistics.table.columns.phone'
          ),
          prop: 'phone',
          sortByField: 'number'
        },
        {
          name: this.translate.instant(
            'resources.coupons.modal.show.statistics.table.columns.dni'
          ),
          prop: 'dni',
          sortByField: 'text'
        },
        {
          name: this.translate.instant(
            'resources.coupons.modal.show.statistics.table.columns.updated_at'
          ),
          prop: 'updated_at'
        }
      ],
      requestData: {
        translationResource: 'resources.coupons.modal.show.statistics.table.columns.',
        apiEndPoint: endpoint
      },
      tableMapping: [
        { prop: 'id', type: 'number', apiProp: 'id' },
        { prop: 'name', type: 'text', apiProp: '_embedded.customer.name' },
        { prop: 'email', type: 'text', apiProp: '_embedded.customer.email' },
        { prop: 'phone', type: 'number', apiProp: '_embedded.customer.phone' },
        { prop: 'dni', type: 'text', apiProp: '_embedded.customer._embedded.profile.dni' },
        { prop: 'updated_at', type: 'date', apiProp: 'updated_at' }
      ]
    };
  }

  private showPersonalData() {
    return this.flags.showCustomerPersonalData || USERS_PERSONAL_DATA_AUTH.includes(this.currentRole);
  }
}
