import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DynamicFormQuestionComponent } from '../../../../shared/components/dynamic-form-question/dynamic-form-question.component';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { TextareaQuestion } from '../../../../shared/models/forms/question-textarea';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { SmsTemplatesService } from '../../../../shared/services/sms-templates.service';
import { CampaignsService } from '../../../campaigns/campaigns.service';
import { ContentDesignerService } from '../../content-designer.service';
import { ProfileService } from './../../../../profiles/profile.service';
import { FeatureFlagsService } from './../../../../shared/services/feature-flags.service';

@Component({
  selector: 'app-sms-template-edit',
  templateUrl: './sms-template-edit.component.html',
  styleUrls: ['./sms-template-edit.component.scss'],
  providers: [ContentDesignerService]
})

export class SmsTemplateEditComponent implements OnInit, OnDestroy, AfterViewInit {

  static CRUD_MODES = ['create', 'update', 'show'];

  companyData = this.profileService.getStoredUser().company;
  form: UntypedFormGroup;
  inputs: QuestionBase<any>[];
  messageChanged$: Subscription;
  couponVariables: { id: string, name: string }[];
  title = '';
  id: number;
  mode: string;
  routeParam$: Subscription;
  userType$ = new Subject<string | null>();
  subs$: Subscription[] = [];
  isInCrudMode: boolean;
  loading = false;
  flags = this.featureFlags.flags;

  private specialCharacters = ['|', '^', '€', '{', '}', '[', ']', '~', '\\'];

  @ViewChild('modalToggleBtn', { static: true }) modalToggleBtn: ElementRef;
  @ViewChild('messageInput') messageInput: DynamicFormQuestionComponent;

  @Output() messageEmitter: EventEmitter<any> = new EventEmitter();
  @Input() message: string;
  @Input() modeReminder: string;

  messageData: {
    smsMaxLength: number,
    smsMultiLength: number,
    smsMessages: number,
    hasNoPubli: boolean,
    smsRemainingLength: number,
    length: number,
    smsMaxLengthDoubled: number,
    totalLengthText: number
  };

  messageMetrics: {
    concatenated_parts: number,
    encoding: string,
    length: number,
    remaining_characters: number
  };

  // GDPR
  get isCompanyGDPR(): boolean {
    return this.companyData['gdpr_configuration']?.show_sms_unsubscription_link || false;
  }
  get messageIncludesGDPR(): boolean { return this.form.get('message')?.value?.includes('{GDPR}') }
  get isMissingGDPR() { return this.isCompanyGDPR && !this.messageIncludesGDPR }

  constructor(
    private qcs: QuestionControlService,
    private campaignService: CampaignsService,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private smsService: SmsTemplatesService,
    private modalService: ModalStatusService,
    private confirmationService: ConfirmationService,
    private contentDesignerService: ContentDesignerService,
    private featureFlags: FeatureFlagsService,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
    this.messageData = {
      smsMaxLength: 160,
      smsMultiLength: 153,
      smsMessages: 1,
      hasNoPubli: false,
      smsRemainingLength: undefined,
      length: undefined,
      smsMaxLengthDoubled: 160 * 2,
      totalLengthText: undefined
    };
    this.message = !this.message ? '' : this.message;
    this.couponVariables = this.campaignService.getCouponVariables();
    this.form = new UntypedFormGroup({});
    this.getMode();
    if (this.isInCrudMode && this.modeReminder !== 'plan_manual_campaign') {
      this.getParams();
    } else {
      this.inputs = this.setInputs({});
      this.form = this.qcs.toFormGroup(this.inputs);
    }
  }

  ngAfterViewInit(): void {
    this.userType$.pipe(debounceTime(700))
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        this.calculateSMS(data);
      });
  }

  ngOnDestroy() {
    if (this.routeParam$) {
      this.routeParam$.unsubscribe();
    }
  }

  detectTyping(event) {
    this.userType$.next(event.target.value);
  }

  openAndViewTemplate(mode: string, message: string) {
    this.message = message ? message : '';
    this.ngOnInit();
    this.open(mode);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  smsHasVariables(): boolean {
    let hasVariables = false;
    const couponVars = ['{name}', '{surname_1}', '{surname_2}', '{coupon}', '{score_available}', '{location}', '{couponCode}'];
    couponVars.forEach(couponVar => {
      const messageValue = this.form.get('message').value;
      if (messageValue && messageValue.indexOf(couponVar) >= 0) {
        hasVariables = true;
      }
    });
    return hasVariables;
  }

  smsAddVariable(couponVar) {

    this.changeDetector.detectChanges();

    const inputElement = this.messageInput.getElement();
    const selectionStart = inputElement.nativeElement.selectionStart;
    const selectionEnd = inputElement.nativeElement.selectionEnd;
    const messageCtrlValue = this.form.get('message').value;
    const pre = messageCtrlValue ? messageCtrlValue.substr(0, selectionStart) : '';
    const pos = messageCtrlValue ? messageCtrlValue.substr(selectionEnd) : '';
    const message = `${pre}${couponVar}${pos}`;

    this.form.patchValue({ 'message': message }, { emitEvent: false });
    this.calculateSMS(message);
    this.writingSms(message);
  }

  save() {
    if (this.isInCrudMode) {
      this.handleRequestsIfCrud();
    } else {
      this.messageEmitter.emit(this.smsService.parseSmsForm(this.form.value));
      this.close();
    }
  }

  closeWithoutSaving() {
    if (this.isInCrudMode) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.modalToggleBtn.nativeElement.click();
    }

    if (this.messageChanged$) { this.messageChanged$.unsubscribe(); }
    this.form.patchValue({ 'message': this.message }, { emitEvent: false });
  }

  refreshTemplate(template) {
    this.message = template.message;
    this.form.patchValue({ 'message': template.message }, { emitEvent: false });
    this.writingSms(this.message);
  }

  open(mode: string) {
    this.setMode(mode);
    if (mode === 'readonly' || mode === 'show') {
      this.title = this.translate.instant('resources.campaign_templates.sms_title_view');
      this.inputs.forEach(input => input.disabled = true);
    } else {
      this.title = this.translate.instant('resources.campaign_templates.sms_title');
      this.inputs.forEach(input => input.disabled = false);
      this.setGDPR();
      this.messageChanged$ = this.form.controls['message'].valueChanges.subscribe(
        () => {
          this.handleMessageValueChanged(this.form.controls['message'].value);
        }
      );
    }
    this.calculateSMS(this.form.get('message').value);
    this.modalToggleBtn.nativeElement.click();
    this.writingSms(this.message);
  }

  private getParams() {
    this.routeParam$ = this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getSmsTemplateById();
      } else {
        this.inputs = this.setInputs({});
        this.form = this.qcs.toFormGroup(this.inputs);
        this.open(this.mode);
      }
    });
  }

  private getMode() {
    this.setMode(this.route.snapshot.data.mode);
  }

  private setMode(mode: string) {
    this.mode = mode;
    this.isInCrudMode = SmsTemplateEditComponent.CRUD_MODES.indexOf(mode) >= 0;
  }

  private close() {
    this.modalToggleBtn.nativeElement.click();
    if (this.messageChanged$) { this.messageChanged$.unsubscribe(); }
  }

  private getSmsTemplateById() {
    this.campaignService.getCampaignTemplateById(this.id).subscribe(
      data => {
        this.inputs = this.setInputs(data);
        this.form = this.qcs.toFormGroup(this.inputs);
        this.open(this.mode);
      }
    );
  }

  private calculateSMS(data) {
    const calculateMetrics$ = this.contentDesignerService.calculateSmsMetrics(data ?? '').subscribe(
      (resp) => {
        calculateMetrics$.unsubscribe();
        this.messageMetrics = resp;
      },
      (errorRespone) => {
        this.confirmationService.displayErrorAlert('error', errorRespone.error.error);
      }
    );
    this.subs$.push(calculateMetrics$);
  }

  private handleRequestsIfCrud() {
    const dataToSend = {
      name: this.form.get('title').value ? this.form.get('title').value : null,
      html: this.form.get('message').value ? this.form.get('message').value : null,
      via: 'sms',
      schema: {}
    };

    if (this.mode === 'create') {
      if (this.form.value.message.length === 0 || this.form.value.title.length === 0) {
        this.confirmationService.displayErrorAlert('Error', this.translate.instant('resources.push-templates.warnings.push_error_text'));
      } else {
        this.contentDesignerService.createCampaignTemplate(dataToSend).subscribe(
          () => {
            this.displayAlert();
          },
          error => {
            console.error(error);
          }
        );
      }
    } else if (this.mode === 'update') {
      this.contentDesignerService.updateCampaignTemplateById(dataToSend, this.id).subscribe(
        () => {
          this.displayAlert();
        },
        error => {
          console.error(error);
        }
      );
    }
  }

  private displayAlert() {
    this.confirmationService.displaySuccessAlert(
      this.translate.instant('resources.campaign_templates.modal.warnings.sms_success_title'),
      this.translate.instant('resources.campaign_templates.modal.warnings.sms_success_text')
    ).catch(() => {});
    this.modalToggleBtn.nativeElement.click();
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    this.modalService.modalStatus.emit();
  }

  private writingSms(message) {
    let count_stranger = 0;

    this.specialCharacters.forEach(char => {
      count_stranger = count_stranger + message.split(char).length - 1
    });

    this.messageData.totalLengthText = message.length + count_stranger;

    if (this.messageData.totalLengthText <= this.messageData.smsMaxLength) {
      this.messageData.smsMessages = 1;
      this.messageData.smsRemainingLength = (this.messageData.smsMaxLength - message.length) - count_stranger;
    } else {
      this.messageData.smsMessages = Math.ceil(this.messageData.totalLengthText / this.messageData.smsMultiLength);
      this.messageData.smsRemainingLength = (
        this.messageData.smsMessages *
        this.messageData.smsMultiLength -
        this.messageData.totalLengthText
      );
    }
  }

  private setInputs(formValue) {
    const inputs = [
      new TextboxQuestion({
        key: 'title',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value,
        order: 1
      }),
      new TextareaQuestion({
        cssClasses: 'form-control input-md',
        key: 'message',
        order: 2,
        type: 'text',
        getValue: (value) => value
      })
    ];

    if (formValue.hasOwnProperty('name')) {
      inputs.find(input => input.key === 'title').value = formValue['name'];
    }

    if (formValue.hasOwnProperty('html')) {
      this.message = formValue['html'];
    }

    if (this.message) { inputs[1].value = this.message; }

    return inputs;
  }

  private handleMessageValueChanged(message: string) {
    this.writingSms(message);
  }

  private setGDPR() {
    if (this.isMissingGDPR) {
      this.smsAddVariable('{GDPR}');
    }
  }

}
