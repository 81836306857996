export function getNewFormData(prev: unknown, next: unknown): object {
  const prevKeys = Object.keys(prev);
  const nextKeys = Object.keys(next);
  const newData = prevKeys.reduce((list, key) => {
    if (JSON.stringify(next[key]) !== JSON.stringify(prev[key])) {
      list[key] = next[key];
    }
    return list;
  }, {});

  nextKeys.forEach(nextKey => {
    if (!prevKeys.includes(nextKey)) {
      newData[nextKey] = next[nextKey];
    }
  });
  return newData;
}
