
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, SecurityContext } from '@angular/core';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FeaturesService } from '../../../products/features.service';
import { getCurrencySymbol } from '@angular/common';
import { ModalStatusService } from '../../../../../shared/services/modal-status.service';
import { ProfileService } from '../../../../../profiles/profile.service';
import { Promotion } from '../../../../../shared/models/promotions/promotion';
import { PromotionsService } from '../../service/promotions.service';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-form-promotions',
  templateUrl: './form-promotions.component.html',
  styleUrls: ['./form-promotions.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FormPromotionsComponent implements OnInit, OnDestroy {

  @ViewChild('toggleBtn') toggleBtn: ElementRef;
  @Output() fileChanged: EventEmitter<any> = new EventEmitter<any>();

  config = {
    height: 200,
    toolbar: [
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['insert', ['link']],
      ['wrapEsBtn', ['esBtn']],
      ['wrapCaBtn', ['caBtn']],
      ['wrapEnBtn', ['enBtn']],
      ['wrapDeBtn', ['deBtn']],
      ['wrapFrBtn', ['frBtn']],
      ['edit', ['undo', 'redo']],
      ['view', ['codeview']]
    ],
    buttons: {
      esBtn: this.esBtnWrapper.bind(this),
      caBtn: this.caBtnWrapper.bind(this),
      deBtn: this.enBtnWrapper.bind(this),
      enBtn: this.deBtnWrapper.bind(this),
      frBtn: this.frBtnWrapper.bind(this)
    }
  };
  currencySymbol: string;
  descriptionData: any;
  id: number;
  imageData: { filename: any; filesize: any; filetype: any; base64: string; };
  inputs: QuestionBase<any>[];
  isMissingImg: boolean;
  loading: boolean;
  promotionsData: Promotion;
  promotionsForm: UntypedFormGroup;
  subs$: Subscription[] = [];
  thumbnail: any;

  constructor(
    private confirmationService: ConfirmationService,
    private featureService: FeaturesService,
    private modalStatusService: ModalStatusService,
    private profileService: ProfileService,
    private promotionsService: PromotionsService,
    private qcs: QuestionControlService,
    private questionCtrlSrv: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
    this.promotionsData = {
      active: true,
      availableFrom: '',
      availableTo: '',
      createdAt: '',
      description: '',
      discount: '',
      location_ids: [],
      marketing_cost: '',
      thumbnail: '',
    };
  }

  ngOnInit() {
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
    this.getParams();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.promotionsForm, formKey, value);
  }

  goBack() {
    this.router.navigate(['customer-data-platform/warehouse/promotions']);
  }

  handleDescriptionValue(event) {
    const isValid = event === '' ? this.promotionsForm.setErrors({ 'emptyString': true }) : this.promotionsForm.setErrors(null);
    return isValid;
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length > 0) {

      const file = event.target.files[0];
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        this.imageData = {
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
          base64: reader.result.toString().split(',')[1]
        };
        this.getInputConfig('image').value = `data:${this.imageData.filetype};base64,${this.imageData.base64}`;
      };
    } else {
      this.imageData = null;
      this.getInputConfig('image').value = '';
    }
  }

  setProductsCategories() {
    this.inputs.find(item => item.key === 'taxonomy').selectedIds = [];
    const taxonomy = this.promotionsForm.getRawValue().taxonomy;

    setTimeout(() => {
      if (taxonomy && taxonomy.length > 0) {
        this.featureService.setTaxonomies(taxonomy[0].rawElement.slug);
      } else {
        this.featureService.setTaxonomies(null);
      }
    });
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  handleAffectationChanges() {
    const featurePksInput = this.inputs.find((item => item.key === 'feature_pks'));
    const productInput = this.inputs.find((item => item.key === 'product_ids'));

    const affectation$ = this.promotionsForm.get('affectation').valueChanges.subscribe(
      affectation => {
        if (affectation && affectation === 'attribute') {
          featurePksInput.required = true;
          this.promotionsForm.get('feature_pks').setValidators(Validators.required);
          this.promotionsForm.get('product_ids').reset();
          productInput.selectedIds = null;
          this.promotionsForm.get('product_ids').clearValidators();
        } else if (affectation && affectation === 'product') {
          productInput.required = true;
          this.promotionsForm.get('product_ids').setValidators(Validators.required);
          this.promotionsForm.get('feature_pks').reset();
          featurePksInput.selectedIds = null;
          this.promotionsForm.get('feature_pks').clearValidators();
        } else if(affectation && affectation === 'activity'){
          this.promotionsForm.get('product_ids').clearValidators();
          this.promotionsForm.get('feature_pks').clearValidators();
          this.promotionsForm.get('product_ids').updateValueAndValidity({ onlySelf: true });
          this.promotionsForm.get('feature_pks').updateValueAndValidity({ onlySelf: true });
          this.promotionsForm.updateValueAndValidity();
          featurePksInput.required = false;
          productInput.required = false;
          this.promotionsForm.get('feature_pks').reset();
          featurePksInput.selectedIds = null;
          this.promotionsForm.get('product_ids').reset();
          productInput.selectedIds = null;
        }
      }
    );
    this.subs$.push(affectation$);
  }

  sendData() {
    const promotionsOptions = this.setPayload();

    if (this.imageData) { promotionsOptions['image'] = this.imageData; }
    this.loading = true;
    if (this.id) {
      const promotions$ = this.promotionsService.updateData(promotionsOptions, this.id).subscribe(
        response => {
          promotions$.unsubscribe();
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.promotions.form.warnings.update_title'),
            this.translate.instant('resources.promotions.form.warnings.update_text')
          ).catch(() => {});
          this.modalStatusService.modalStatus.emit();
          this.returnToList();
        },
        errorData => {
          promotions$.unsubscribe();
          this.loading = false;
          if (errorData?.error?.errors?.length > 0) {
            this.questionCtrlSrv.paintErrorsInForm(this.inputs, this.promotionsForm, errorData.error.errors);
            this.confirmationService.displayErrorAlert('Error', errorData.error.error);
          }
        }
      );
      this.subs$.push(promotions$);
      } else {
        const promotions$ = this.promotionsService.sendData(promotionsOptions).subscribe(
        (response) => {
          promotions$.unsubscribe();
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.promotions.form.warnings.success_title'),
            this.translate.instant('resources.promotions.form.warnings.success_text')
          ).catch(() => {});
          this.loading = false;
          this.modalStatusService.modalStatus.emit();
          this.returnToList();
        },
        (errorData) => {
          promotions$.unsubscribe();
          this.loading = false;
          if (errorData?.error?.errors?.length > 0) {
            this.questionCtrlSrv.paintErrorsInForm(this.inputs, this.promotionsForm, errorData.error.errors);
            this.confirmationService.displayErrorAlert('Error', errorData.error.error);
          }
        }
      );
      this.subs$.push(promotions$);
    }
  }

  private returnToList() {
    this.router.navigate(['/customer-data-platform/warehouse/promotions']).catch(() => {});
  }

  private setPayload() {
    const location_ids = this.promotionsForm?.getRawValue().location_ids?.map(location => (location.id));
    const feature_location_ids = this.promotionsForm?.getRawValue().feature_location_ids?.map(feature_location => (feature_location.id));
    const affectation =  this.promotionsForm?.getRawValue().affectation;

    const feature_pks =  this.promotionsForm?.getRawValue().feature_pks?.map(feature => feature.rawElement.pk);
    const product_ids =  this.promotionsForm?.getRawValue().product_ids?.map(product => product.id) || null;

    const payload = {
      image: this.imageData,
      name: this.promotionsForm.getRawValue().name,
      description: this.sanitizer.sanitize(SecurityContext.HTML, this.promotionsData.description),
      available_from: this.promotionsForm.getRawValue().available_from,
      available_to: this.promotionsForm.getRawValue().available_to,
      cumulative: this.promotionsForm.getRawValue().cumulative ? this.promotionsForm.getRawValue().cumulative : false,
      feature_location_ids: feature_location_ids,
      location_ids: location_ids,
      discount_type: this.promotionsForm.getRawValue().discount_type,
      discount: this.promotionsForm.getRawValue().discount,
      min_money: this.promotionsForm.getRawValue().min_money,
      max_money: this.promotionsForm.getRawValue().max_money,
      min_units: this.promotionsForm.getRawValue().min_units,
      max_units: this.promotionsForm.getRawValue().max_units,
      affectation: affectation,
      feature_pks: feature_pks,
      product_ids: product_ids
    }

    if (payload.location_ids && payload.location_ids.length >= 0 && payload.feature_location_ids && payload.feature_location_ids.length === 0) { delete payload.feature_location_ids; }
    if (payload.feature_location_ids && payload.feature_location_ids.length >= 0 && payload.location_ids && payload.location_ids.length === 0) { delete payload.location_ids; }

    if (payload.feature_pks && payload.feature_pks.length >= 0 ) { delete payload.product_ids; }
    if (payload.product_ids && payload.product_ids.length >= 0 ) { delete payload.feature_pks; }

    return payload;
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getPromotionsById(this.id);
      } else {
        this.inputs = this.promotionsService.getInputs({});
        this.promotionsForm = this.qcs.toFormGroup(this.inputs);
        this.handleMutuallyExclusiveFields();
        this.handleAffectationChanges();
      }
    });
  }

  private getPromotionsById(id: number) {
    this.loading = true;
    const promosById$ = this.promotionsService.getPromotionsById(this.id).subscribe(
      data => {
        this.loading = false;
        promosById$.unsubscribe();
        this.promotionsData = data;
        this.inputs = this.promotionsService.getInputs(data);
        this.promotionsForm = this.qcs.toFormGroup(this.inputs);
        this.handleMutuallyExclusiveFields();
        this.handleAffectationChanges();
        this.ensureRequiredFields();
      }
    );
    this.subs$.push(promosById$);
  }

  private ensureRequiredFields() {
    if(this.promotionsForm.getRawValue().affectation === 'attribute' ) {
      this.inputs.find(input => input.key === 'feature_pks').required = true;
      this.promotionsForm.get('feature_pks').setValidators(Validators.required);
    } else if (this.promotionsForm.getRawValue().affectation === 'product') {
      this.inputs.find(input => input.key === 'product_ids').required = true;
      this.promotionsForm.get('product_ids').setValidators(Validators.required);
    }
  }

  private handleMutuallyExclusiveFields() {

    const locationTaxonomyTermIds$ = this.promotionsForm.get('feature_location_ids').valueChanges.subscribe(
      locationTaxonomyTermIds => {
        const locationsField = this.promotionsForm.get('location_ids');
        const locationsInput = this.inputs.find((item => item.key === 'location_ids'));
        if (locationTaxonomyTermIds && locationTaxonomyTermIds.length > 0) {
          locationsField.setValue(null);
          locationsInput.value = [];
          locationsInput.selectedIds = [];
        }
      }
    );

    const locationId$ = this.promotionsForm.get('location_ids').valueChanges.subscribe(
      locationIds => {
        const locationTermsField = this.promotionsForm.get('feature_location_ids');
        const locationTermsInput = this.inputs.find((item => item.key === 'feature_location_ids'));
        if (locationIds && locationIds.length > 0) {
          locationTermsField.setValue(null);
          locationTermsInput.value = [];
          locationTermsInput.selectedIds = [];
        }
      }
    );

    const featurePks$ = this.promotionsForm.get('feature_pks').valueChanges.subscribe(
      featurePks => {
        const productField = this.promotionsForm.get('product_ids');
        const productsInput = this.inputs.find((item => item.key === 'product_ids'));
        if (featurePks && featurePks.length > 0) {
          productField.setValue(null);
          productsInput.value = [];
          productsInput.selectedIds = [];
        }
      }
    );

    const productIds$ = this.promotionsForm.get('product_ids').valueChanges.subscribe(
      locationIds => {
        const featurePksField = this.promotionsForm.get('feature_pks');
        const featurePksInput = this.inputs.find((item => item.key === 'feature_pks'));
        if (locationIds && locationIds.length > 0) {
          featurePksField.setValue(null);
          featurePksInput.value = [];
          featurePksInput.selectedIds = [];
        }
      }
    );

    this.subs$.push(locationId$);
    this.subs$.push(locationTaxonomyTermIds$);
    this.subs$.push(featurePks$);
    this.subs$.push(productIds$);
  }

  // Summernote
  private h2BtnWrapper (context) {
    return this.headerWrapper('h2').render();
  }

  private h3BtnWrapper (context) {
    return this.headerWrapper('h3').render();
  }

  private caBtnWrapper (context) {
    return this.btnWrapper('es-CA').render();
  }

  private esBtnWrapper (context) {
    return this.btnWrapper('es-ES').render();
  }

  private deBtnWrapper (context) {
    return this.btnWrapper('de-DE').render();
  }

  private enBtnWrapper (context) {
    return this.btnWrapper('en-EN').render();
  }

  private frBtnWrapper (context) {
    return this.btnWrapper('fr-FR').render();
  }

  private headerWrapper(header) {
    const ui = $.summernote.ui;
    const button = ui.button({
      container: false,
      contents: header,
      tooltip: 'Encapsular contenido para tag <' + header + '>',
      click: function () {
        const range = window.getSelection().getRangeAt(0);
        const el = document.createElement(header);
        el.appendChild(range.extractContents());
        range.insertNode(el);
      }
    });
    return button;
  }

  private btnWrapper (lang) {
    const ui = $.summernote.ui;
    const button = ui.button({
      container: false,
      contents: '<i class="fa fa-globe"/> ' + lang,
      tooltip: 'Encapsular contenido para idioma ' + lang,
      click: function () {
        const range = window.getSelection().getRangeAt(0);
        const div = document.createElement('div');
        div.setAttribute('lang', lang);
        div.appendChild(range.extractContents());
        range.insertNode(div);
      }
    });
    return button;
  }

}
