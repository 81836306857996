import { CustomerJourney, CustomerJourneysCfg } from './customer-journey';
import { CustomerJourneyStep } from './customer-journey-step';

export class RecuperationJourney extends CustomerJourney {

  public template: {
    message?: string;
  };

  constructor(apiObject: CustomerJourneysCfg) {
    super(apiObject);
    this.configuration.coupon = this.getCoupon(apiObject);
    this.contentBlockHidden = false;
    if ( !this.configuration.message ) {
      this.configuration.message = '¡Enhorabuena! <<NOMBRE_COMPAÑÍA>> te regala <<EUROS>> euros esta semana con una compra minima de <<EUROS>>. Cupon: {couponCode} valido {CouponAvailableTo} + <<URL>>' 
    }
  }

  public getDefinitionStepFormCfg(): any[] {
    return [
      {
        key: 'activity_tag',
        label: 'resources.journeys.fields.activity_tag',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: false },
        requestData: {
          apiEndPoint: 'segment_conditions/tag_activity'
        },
        type: 'multiselect',
        cssClass: 'col-md-1',
        required: true
      },
      {
        key: 'best_sales_tags',
        label: 'resources.journeys.fields.best_sales_tag',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: false },
        requestData: {
          apiEndPoint: 'segment_conditions/tag_sales'
        },
        type: 'multiselect',
        cssClass: 'col-md-1'
      },
      {
        key: 'location_terms',
        label: 'resources.journeys.fields.location_taxonomy_terms',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        requestData: {
          apiEndPoint: 'location_taxonomy_terms',
          filtering: { per_page: 300 }
        },
        template: '({{dataItem.taxonomy.name}}) {{dataItem.name}}',
        type: 'multiselect'
      },
      {
        key: 'extra_segment',
        label: 'resources.journeys.fields.extra_segment',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
        requestData: {
          apiEndPoint: 'segments',
          filtering: { per_page: 300 }
        },
        type: 'multiselect'
      },
      {
        key: 'coupon',
        label: 'resources.journeys.fields.coupon',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
        requestData: {
          apiEndPoint: 'coupons',
          filtering: { per_page: 300, order_by: 'created_at', order_sense: 'desc' }
        },
        type: 'multiselect',
        cssClass: 'col-md-4',
        required: true
      }
    ];
  }

  public getCampaignsStepFormCfg() {
    return [];
  }

  public definitionValid(): boolean {
    const activityTagDefined = this.configuration.hasOwnProperty('activity_tag') &&
                               this.configuration['activity_tag'] &&
                               this.configuration['activity_tag'][0] &&
                               this.configuration['activity_tag'][0].hasOwnProperty('id');

    return activityTagDefined;
  }

  public campaignsValid(): boolean {
    const pendingSteps = this.steps.filter( step => step.status === 'pending');
    const datesValidations = pendingSteps.map( step => step.hasScheduleDefined());

    return datesValidations.every(function(item){ return item; });
  }

  // TODO: Ensure selected coupon_id in definition has been copied to all steps coupon_id
  public contentValid(): boolean {
    const messageFilled = this.configuration.hasOwnProperty('message') &&
                          this.configuration.message.length > 0;

    const couponSelected = this.steps[0].configuration.hasOwnProperty('coupon_id') &&
                           this.steps[0].configuration.coupon_id;

    return messageFilled && couponSelected;
  }

  public summaryValid(): boolean {
    return this.name && this.name !== null && this.name !== '' && this.name !== undefined;
  }

  /**
   * Prepare object values to PATCH object in API
   */
  public prepareToSave() {

    const config = JSON.parse( JSON.stringify(this.cfg) );

    config.name = config.name === '' ? this.getName() : config.name;
    config.name = this.nameHasChanged ? this.name : config.name;

    config.type = config.origin_type;
    delete config.origin_type;

    // Definition block
    if ( this.configuration.hasOwnProperty('activity_tag') &&
         this.configuration.activity_tag[0] &&
         this.configuration.activity_tag[0].hasOwnProperty('id')) {
      config.configuration.activity_tag = this.configuration.activity_tag[0].id;
    } else {
      config.configuration.activity_tag = [];
    }

    if ( this.configuration.hasOwnProperty('best_sales_tags') &&
         this.configuration.best_sales_tags[0] &&
         this.configuration.best_sales_tags[0].hasOwnProperty('id') ) {
      config.configuration.best_sales_tags = this.configuration.best_sales_tags.map(term => term.id);
    } else {
      config.configuration.best_sales_tags = [];
    }

    if ( this.configuration.hasOwnProperty('location_terms') &&
         this.configuration.location_terms[0] &&
         this.configuration.location_terms[0].hasOwnProperty('id') ) {
      config.configuration.location_terms = this.configuration.location_terms.map(term => term.id);
    } else {
      config.configuration.location_terms = [];
    }

    if ( this.configuration.hasOwnProperty('extra_segment') &&
         this.configuration.extra_segment[0] &&
         this.configuration.extra_segment[0].hasOwnProperty('id') ) {
      config.configuration.extra_segment = this.configuration.extra_segment[0].id;
    } else {
      config.configuration.extra_segment = [];
    }

    if ( this.configuration.hasOwnProperty('message') && this.configuration.message ) {
      config.configuration.message = this.configuration.message;
    } else {
      config.configuration.message = [];
    }

    // Campaigns block
    for (let index = 0; index < this.steps.length; index++) {

      config.steps[index].scheduled_at = this.steps[index].scheduled_at ? this.steps[index].scheduled_at : null;
      config.steps[index].status = this.steps[index].status;

      if ( this.configuration.hasOwnProperty('coupon') &&
           this.configuration.coupon &&
           this.configuration.coupon[0] &&
           this.configuration.coupon[0].hasOwnProperty('id') ) {
        config.steps[index].configuration.coupon_id = parseInt(this.configuration.coupon[0].id, 10);
      } else {
        config.steps[index].configuration.coupon_id = null;
      }
    }

    config.reportable = this.reportable || false;

    return config;
  }

  public filterOptionsByKey(options: Array<any>, step: any): Array<any> {
    options = super.filterOptionsByKey(options, step);
    if (step.key === 'activity_tag') {
      options = options.filter(opt => opt.name !== 'Activos');
    }
    return options;
  }

  public getNameSuffix(step: CustomerJourneyStep): string {
    return `Envío ${step.position}`;
  }

  public getNameWithAttributes(): string {
    const prefix = 'REC';
    const activityTag = this.configuration.activity_tag.map( tag => tag.name ).join(' ');
    const bestSalesTag = this.configuration.best_sales_tags.map( tag => tag.name ).join(' ');
    const locationTerms = this.configuration.location_terms.map( term => term.name).join(' ');

    return `${prefix} ${activityTag} ${bestSalesTag} ${locationTerms}`;
  }

  private getCoupon(apiObject): any {
    if ( apiObject.steps[0].configuration.hasOwnProperty('coupon_id') &&
         apiObject.steps[0].configuration.coupon_id ) {
      return [{id: apiObject.steps[0].configuration.coupon_id}];
    } else {
      return [];
    }
  }
}
