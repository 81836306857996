<div class="file-upload">
  <ngx-dropzone [multiple]="multiple" [accept]="getFilteredTypes()" (change)="onSelect($event)">
    <ngx-dropzone-label>
      <i class="ei ei-upload-1"></i>
      <br/>{{ 'components.upload.drag_here' | translate }}
      <br/>{{ 'components.upload.or' | translate }}<br/>
      <p class="file-upload-link">{{ 'components.upload.browse_files' | translate }}</p>
    </ngx-dropzone-label>
    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
      <ngx-dropzone-label><span class="file-name">{{ f.name }}</span> ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ngx-dropzone>
  <p class="accepted-types" *ngIf="showFilteredTypes() != ''">{{ 'components.upload.accepted_types' | translate:{ types: showFilteredTypes() } }}
    <span *ngIf="isCsv()">{{ 'components.upload.utf8' | translate }}</span>
  </p>
</div>
