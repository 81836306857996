import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from '../../../shared/services/resources.service';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyService extends ResourcesService {

  constructor( http: HttpClient ) {
    super(http);
  }

  patchLanguage(company_id: string | number, language: string, localizedContent?: string[]) {
    let lng: string;
    switch (language) {
      case 'es':
        lng = 'es_ES';
        break;
      case 'en':
        lng = 'en_EN';
        break;
      case 'it':
        lng = 'it_IT';
        break;
      default:
        break;
    }

    const params = {
      locale: lng,
      available_languages: localizedContent
    };
    return this.patchResource(params, `companies/${company_id}`);
  }

  patchLocalizedContent(company_id: string | number, localizedContent: string[]) {
    const params = {
      available_languages: localizedContent
    };
    return this.patchResource(params, `companies/${company_id}`);
  }

  getCompanyWithRepresenterType(id: number, representer: string) {
    return this.getData({ apiEndPoint: `companies/${id}`, filtering: { representer: representer } });
  }

  updateCompanySegmentsAndCusAttributes(companyId: number) {
    return this.postResource({}, `companies/${companyId}/apply_strict_profiling`);
  }

  calculateCredits(params) {
    return this.getData({ apiEndPoint: `credits/calculate_amount`, filtering: params });
  }

  getCompanyCardById(id) {
    return this.getData({ apiEndPoint: `companies/${id}/card` });
  }

  loadCredits(params) {
    return this.postResource(params, `credits`);
  }

  validateCreditById(id) {
    return this.postResource({}, `credits/${id}/validate`);
  }

  updateCreditCardToken(companyId: string, token: string) {
    return this.patchResource({token: token}, `companies/${companyId}/card`);
  }

  createCreditCardToken(companyId: string, token: string) {
    return this.postResource({token: token}, `companies/${companyId}/card`);
  }

  patchCompany(companyId: string | number, params): Observable<any> {
    return this.patchResource(params, `companies/${companyId}`);
  }

  getTermsBySlug(termsSlug: string, companySlug: string): Observable<any> {
    return this.getData({apiEndPoint: `terms/${termsSlug}?company=${companySlug}`});
  }

  saveTerm(payload: object) {
    return this.postResource(payload, 'terms');
  }

  updateTerm(payload: object, id: number) {
    return this.patchResource(payload, `terms/${id}`);
  }

  cancelAllGdpr(payload: object): Observable<any> {
    return this.postResource(payload, `customers/cancel_all_gdpr`);
  }

}
