<div class="row">
  <div class="col-md-3">
    <app-question [question]="getInputConfig('name')" [form]="upCrossGeneralInputs"></app-question>
  </div>
  <div class="col-md-3">
    <app-question [question]="getInputConfig('taxonomy')" [form]="upCrossGeneralInputs" (multiselectChanged)="setProductsCategories($event)"></app-question>
  </div>
  <div class="col-md-3">
    <app-question [question]="getInputConfig('feature_ids')" [form]="upCrossGeneralInputs" [useToken]="true"></app-question>
  </div>
  <div class="col-md-3">
    <app-question [question]="getInputConfig('segment_id')" [form]="upCrossGeneralInputs"></app-question>
  </div>
</div>
