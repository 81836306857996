<form [formGroup]="journeyForm">
  <div class="modal-backdrop fade in modal-fs" id='modal-fs' tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header background-white" *ngIf="journey">
          <div class="row card-heading flex-grow-1 no-pdd-btm">
            <div class="col-md-12">
              <div class="row mrg-left-10 align-items">
                <label>
                  <h2>
                    <b [translate]="'resources.journeys.title_singular'"></b> 
                    <span>{{'resources.journeys.type.' + journey.type | translate}}:</span>
                  </h2>
                </label>
                <div class="col-md-9 max-width-73">
                  <input title="name" 
                          class="form-control font-size-22" 
                          type="text"
                          autocomplete="off"
                          [value]="getName()"
                          [disabled]="!journey._original"
                          (input)="journeyNameChanged($event)">
                </div>                
              </div>
            </div>
              <a class="modal-close" data-dismiss="modal" (click)="handleCloseModal($event)">
                <i class="ti-close"></i>
              </a>
          </div>
        </div>

        <div class="modal-body background-gray height-100">
          <div class="card background-gray no-border mrg-left-10 mrg-right-10" *ngIf="journey">            
            
            <div class="alert alert-warning">
              <ul class="no-pdd mrg-left-10">
                <li>
                  <span>{{'resources.journeys.warnings.edit_sent_on' | translate}}:</span>
                  <strong [innerText]="dataLabel"></strong>
                </li>
                <li *ngIf="!journey._original" translate>resources.journeys.warnings.editing_history</li>
                <li *ngIf="journey._original" translate>resources.journeys.warnings.editing_parent</li>
              </ul>
            </div>

            <div *ngIf="journeyForm.errors">
              <div class="alert alert-danger alert-dismissible fade show"
                    *ngFor="let error of getErrors(journeyForm)"
                    role="alert">
                <b>{{error.field}}:</b> {{error.message}}.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="removeError(error)">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            
            <app-journey-definition-block #definition
                                          [journey]="journey"
                                          (onStatusEmit)="definitionEmitterHandler($event)"
                                          (onFormEmit)="formEmitterHandler($event)"
                                          (onFormSubmit)="save({showSweetAlert: false})"
                                          [form]="journeyForm"></app-journey-definition-block>

            <app-journey-campaigns-block #campaigns 
                                         *ngIf="!journey.campaignsBlockHidden"
                                         [journey]="journey"
                                         (onStatusEmit)="campaignsEmitterHandler($event)"
                                         (templateEmit)="handleTemplateEmitter($event)"
                                         (templateSelected)="handleTemplateSelectedEmitter($event)"
                                         [form]="journeyForm"></app-journey-campaigns-block>

            <app-journey-content-block #content 
                                       *ngIf="!journey.contentBlockHidden"
                                       (onStatusEmit)="contentEmitterHandler($event)"
                                       [journey]="journey"
                                       [form]="journeyForm"></app-journey-content-block>

            <app-journey-summary-block #summary
                                       *ngIf="!journey.summaryBlockHidden"
                                       [journey]="journey"
                                       (onStatusEmit)="summaryEmitterHandler($event)"
                                       [form]="journeyForm"
                                       [hidden]="!journey._original"></app-journey-summary-block>

          </div>
          
        </div>

        <div class="modal-footer background-white" *ngIf="journey">
          <span *ngIf="loading">
            <i class="fa fa-spinner fa-spin"></i>
          </span>
          <button type="button"
                  class="btn btn-info"
                  (click)="save()"
                  [disabled]="loading">
            <span [translate]="'resources.journeys.buttons.save'"></span>
          </button>
          <button type="button"
                  class="btn btn-info mrg-right-10"
                  (click)="prepareDelivery()"
                  [disabled]="loading">
            <span [translate]="'resources.journeys.buttons.schedule_delivery'"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- Steps information/confirm modals -->
<button #confirmationModal 
        type="button"
        data-toggle="modal"
        data-target="#confirmation-modal"
        class="btn btn-info" 
        hidden></button>
<div class="modal fade" id="confirmation-modal" *ngIf="journey">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body" 
           *ngIf="stepsToDeliver && (stepsToDeliver['now'] || stepsToDeliver['scheduled'])">
        <h4 class="card-title border bottom">
          {{'resources.journeys.buttons.confirm_delivery' | translate}}
          <a class="modal-close pull-right" data-dismiss="modal" (click)="closeConfirmationModal()">
            <i class="ti-close"></i>
          </a>
        </h4>
        <div *ngIf="stepsToDeliver['now'].length > 0">
          <h5>{{stepsToDeliver['now'].length}} {{'resources.journeys.warnings.steps_to_deliver' | translate}}:</h5>
          <ul class="mrg-vertical-10">
            <li *ngFor="let step of stepsToDeliver['now']">
              <b>{{step.name}}</b>
            </li>
          </ul>
        </div>
        <div *ngIf="stepsToDeliver['scheduled'].length > 0">
          <h5>{{stepsToDeliver['scheduled'].length}} {{'resources.journeys.warnings.steps_to_schedule' | translate}}:</h5>
          <ul class="mrg-vertical-10">
            <li *ngFor="let step of stepsToDeliver['scheduled']">
              <b>{{step.date}}:</b><br>{{step.name}}
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <div class="text-right">
          <button type="button"
                  class="btn btn-info" 
                  data-dismiss="modal"
                  [disabled]="!journeyValidToDeliver && journeyHasChanges"
                  (click)="triggerJourneyDelivery()"
                  [translate]="'resources.journeys.buttons.confirm'"></button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end confirmation modal -->

<!-- Email template quick view -->
<app-email-template-view #emailTemplateView></app-email-template-view>
<!--  -->

<!-- Email template edit -->
<app-email-template-edit #emailTemplateEdit
                         [template]="selectedStepTemplate"
                         (emailTemplateEmitter)="handleEmailSaved($event)"></app-email-template-edit>
<!--  -->

<!-- Push template edition modal -->
<app-push-template-edit #pushTemplateEdit
                        [template]="selectedStepTemplate"
                        (pushEmitter)="handlePushTemplateSaved($event)"></app-push-template-edit>
<!--  -->

<!-- SMS template edition modal -->
<app-sms-template-edit #smsTemplateEdit 
                       *ngIf="journey && journey.type !== 'recuperation'"
                       (messageEmitter)="handleSmsTemplateSaved($event)"></app-sms-template-edit>
<!--  -->
