import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocationsService } from '../../../../data-warehouse/locations/locations.service';
import { SegmentsService } from '../../../../segments/segments.service';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { DictionaryService } from '../../../../../shared/services/dictionary.service';
import { DataTableFilterService } from '../../../../../shared/components/data-table-filter/data-table-filter.service';
import { Subscription } from 'rxjs';
import { CustomersService } from '../../../customers/customers.service';
import { registerLocaleData, getCurrencySymbol } from '@angular/common';
import es from '@angular/common/locales/es';
import * as moment from 'moment';
import { ProfileService } from '../../../../../profiles/profile.service';

@Component({
  selector: 'app-pl-tab',
  templateUrl: './pl-tab.component.html',
  styleUrls: ['./pl-tab.component.css'],
  providers: [RefreshCacheService, LocationsService, SegmentsService, DictionaryService]
})

export class PlTabComponent implements OnInit, OnDestroy {

  loading: boolean;
  plLoading: boolean;
  yearCountData: any[];
  yearData: any;
  currentYear: number;
  previousYear: number;
  activeMonths: number;
  currencySymbol: string;
  onSearch: boolean;
  private subs$: Subscription[] = [];

  constructor(
    private refreshCacheService: RefreshCacheService,
    private dictionaryService: DictionaryService,
    private filterService: DataTableFilterService,
    private customersService: CustomersService,
    private profileService: ProfileService,
  ) {
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
    this.subs$ = [];
    this.yearCountData = [];
    this.activeMonths = this.dictionaryService.dictionaries.months.length;
    this.loading = false;
    this.plLoading = false;
  }

  ngOnInit() {
    registerLocaleData(es);
    this.onSearch = false;
    const filterChange$ = this.customersService.applyFilters$.subscribe(
      (filterValues) => {
        this.getYearByMonth(filterValues);
        this.onSearch = true;
      }
    );
    this.subs$.push(filterChange$);
    setTimeout(() => this.filterService.loaderStatus.next(true));
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  filterDataHandler(filterFormValue) {
    if (filterFormValue && filterFormValue.hasOwnProperty('year')) {
      this.currentYear = filterFormValue['year'];
      this.previousYear = this.currentYear - 1;
    } else {
      const today = new Date();
      this.currentYear = today.getFullYear();
      this.previousYear = today.getFullYear() - 1;
    }
  }

  private getYearByMonth(filterFormValue?) {

    const filters = {...filterFormValue};

    this.filterDataHandler(filters);

    const currentYear = moment().year();
    let filtering = { country: 'ES', year: currentYear };

    if ( filters ) {
      filtering = { country: 'ES', year: filters.year };
      filtering = {...filtering, ...filters};
    } else {
      filtering = { country: 'ES', year: moment().year() };
    }

    if (!filtering.year) {filtering.year = currentYear; }

    if (`${filtering.year}` === `${currentYear}`) {
      const today = new Date();
      this.activeMonths = today.getMonth() > 0 ? today.getMonth() : 1;
    } else {
      this.activeMonths = this.dictionaryService.dictionaries.months.length;
    }

    const params = { apiEndPoint: 'analytics/year_by_month', filtering: filtering };

    this.loading = true;
    this.filterService.loaderStatus.next(false);

    const yearByMonth$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if( !this.refreshCacheService.isRequestPending(reqResponse) ) {
        this.loading = false;
        this.filterService.loaderStatus.next(true);
        this.yearData = reqResponse;
        this.parseYearData();
        yearByMonth$.unsubscribe();
      }
    });

    this.subs$.push(yearByMonth$);
  }

  private parseYearData() {

    this.yearCountData = [];
    const yearCountDataPair = [];
    const yearCountDataOdd = [];

    for ( let i = 0; i < this.yearData.values.length; i++) {
      if (i % 2 === 0) {
        yearCountDataPair.push(this.yearData.values[i]);
      } else if (i % 2 > 0) {
        yearCountDataOdd.push(this.yearData.values[i]);
      }
    }

    for (let i = 0; i < this.activeMonths; i++) {
      const variation = Math.round((yearCountDataPair[i] - yearCountDataOdd[i]) / yearCountDataOdd[i] * 100 * 10 / 10);
      this.yearCountData.push({
        month: this.dictionaryService.dictionaries.months[i].name,
        lastYear: yearCountDataOdd[i],
        actualYear: yearCountDataPair[i],
        diff: (yearCountDataPair[i]) - (yearCountDataOdd[i]),
        variation: !variation ? '0' : variation
      });
    }
  }
}
