<ng-container *ngIf="plan && !isPlanDelivered()">
  <div class="row">
    <div class="col-lg-12">
      <div class="alert alert-warning alert-dismissible fade show mrg-top-5" role="alert">
        <span>{{'resources.plans.messages.roi_hidden' | translate}}</span>
      </div>
    </div>
  </div>
</ng-container>

<div class="row">
  <div class="col-lg-12">
    <div role="alert"
        class="alert alert-warning alert-dismissible fade show mrg-top-5"
        *ngIf="isRoiInProgress()">
        {{'resources.plans.messages.calculating_roi' | translate}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
  </div>
</div>

<app-data-table-filter *ngIf="filters && isPlanDelivered()"
                      [slug]="'plan-roi-detail'"
                      [questions]="filters"
                      (onSubmitEmitter)="onFilterHandler($event)"
                      [cfg]="filterCfg">
</app-data-table-filter>

<ng-container *ngIf="isPlanDelivered()">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block padding-20 pdd-btm-10">
          <app-loader styleClass="card" *ngIf="loading" />
          <div class="pull-left" *ngIf="roiDate">
            <i class="ei-timer"></i>&nbsp;<b class="card-title">{{'resources.plans.fields.generated_at' | translate}}</b>: <b class="card-title">{{roiDate | dateTimeZone: 'DD/MM/YYYY HH:mm'}}</b>
          </div>
          <div class="btn-group pull-right">
            <button id="planDetailROIConfigTableBtn"
                    class="btn btn-sm btn-default btn-icon mrg-btm-10"
                    type="button"
                    data-toggle="dropdown"
                    tooltipPosition="left"
                    pTooltip="{{'resources.plans.buttons.configure_table' | translate}}">
              <i class="ei-table icon-align"></i>
            </button>
            <ul class="dropdown-menu">
              <li *ngFor="let column of enabledColumns; let i = index;">
                <div class="checkbox pdd-horizon-10" (click)="tableCfgClickHandler($event, column.id)">
                  <input type="checkbox"
                         [id]="'column_'+i"
                         [name]="'column_'+i"
                         [attr.checked]="column.visible ? '' : null"
                         [attr.disabled]="column.disabled ? '' : null">
                  <label class="font-size-12" [attr.for]="'column_'+i" [ngClass]="{'disabled': column.disabled}">
                    {{column.plainTitle}}
                  </label>
                </div>
              </li>
            </ul>
            <button id="planDetailRoiReprintVouchersBtn"
                    class="btn btn-sm btn-default btn-icon"
                    *ngIf="plan"
                    [disabled]="isRoiInProgress()"
                    (click)="reprintCampaignOrWholePlan()"
                    tooltipPosition="left"
                    pTooltip="{{'resources.plans.buttons.undo_coupon_status' | translate}}">
                    <i class="ei-print icon-align"></i>
            </button>
            <button id="planDetailRoiExportBtn"
                    class="btn btn-sm btn-default btn-icon"
                    *ngIf="plan"
                    [disabled]="isRoiInProgress()"
                    (click)="exportROI()"
                    tooltipPosition="left"
                    pTooltip="{{'resources.plans.buttons.export_roi' | translate}}">
              <i class="ei-cloud-download icon-align"></i>
            </button>
            <button id="planDetailRoiCalcBtn"
                    class="btn btn-sm btn-default btn-icon"
                    *ngIf="plan"
                    [disabled]="isRoiInProgress()"
                    (click)="calculateROI()"
                    tooltipPosition="left"
                    pTooltip="{{'resources.plans.buttons.calc_roi' | translate}}">
              <i class="ei-refresh icon-align"></i>
            </button>
          </div>
          <br>
          <div class="row">
            <div class="pull-left">
              <div class="toggle-checkbox checkbox-inline toggle-sm mrg-top-10">
                <input #toggleBtn
                  (click)="handleShowSubtotal()"
                  type="checkbox"
                  [id]="'toggle-subtotals-visibility-'+ !showSubtotal"
                  [name]="'toggle-subtotals-visibility-'+ !showSubtotal">
                <label [for]="'toggle-subtotals-visibility-'+ !showSubtotal"></label>
                <span class="toggle-label-align card-title text-dark">{{'resources.plans.buttons.show_subtotal' | translate}}</span>
              </div>
            </div>
          </div>

          <div id="roiTable">
            <table *ngIf="!loading && planRoiData.length > 0" class="table table-sm table-bordered" aria-hidden="true">
              <thead>
                <tr class="highlight-header">
                  <ng-container *ngFor="let header of tableColumns; let i = index;">
                    <th class="text-center"
                        [hidden]="!header.visible"
                        [innerHTML]="header.headerTitle"
                        [ngClass]="{'width-200': header.id === 'campaign_type' || header.id === 'campaign_name' || header.id === 'segment_name'}">
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let element of planRoiData; let i = index;">
                  <ng-container *ngFor="let campaign of element.campaigns; let j = index;">
                    <ng-container *ngIf="!showSubtotal">
                      <ng-container *ngFor="let action of campaign.actions; let k = index;">
                        <tr>
                          <!-- FIXED COLUMNS -->
                          <td *ngIf="j === 0 && k === 0"
                            class="text-center align-middle text-bold category-sticky"
                            [hidden]="!tableColumns[0].visible"
                            [attr.rowSpan]="element.rowsPerCategory"
                            [ngClass]="{'bg-custom': element.type === 'Plans::CustomCampaign',
                                        'bg-supplier': element.type === 'Plans::SupplierCampaign',
                                        'bg-up-cross': element.type === 'Plans::UpCrossCampaign',
                                        'bg-recovery': element.type === 'Plans::RecoveryCampaign',
                                        'bg-random': element.type === 'random_1',
                                        'bg-random': element.type === 'random_2',
                                        'bg-random': element.type === 'random_3'}">
                            {{element.category}}
                          </td>
                          <td *ngIf="k === 0"
                            class="text-left align-middle td-stick2"
                            [attr.rowSpan]="campaign.actions.length"
                            [hidden]="!tableColumns[1].visible">
                            <span class="overflow-text" >{{campaign.name}}</span>
                          </td>
                          <td class="text-left td-stick3" [hidden]="!tableColumns[2].visible">
                            {{action.campaignId}}
                            <i *ngIf="action.errors && action.errors.length > 0"
                              class="fa fa-warning text-danger text-bold"
                              tooltipPosition="top"
                              container="body"
                              pTooltip="{{action.errors}}">
                            </i>
                          </td>
                          <td class="text-left td-stick4" [hidden]="!tableColumns[3].visible">
                            <span class="overflow-text">{{action.segmentName}}</span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[4].visible">
                            <span class="label label-default">
                              <a [ngClass]="{'pointer': action.reminderIds.length > 0, 'no-pointer-event': action.reminderIds.length === 0}" (click)="openReminderForm(action, 'received_reminder')">{{action.reminderIds.length}}</a>
                            </span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[5].visible">
                            <span class="label label-default">{{action.customersCount | number: '1.0-0'}}</span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[6].visible">
                            <span class="label label-default">
                              <a [routerLink]="['', { outlets: { modal: ['show', 'campaigns', 'histories', action.campaignId, 'statistics', 'customers'] }}]"
                                  target="_blank"><b><u>{{action.controlGroup | number: '1.0-0'}}</u></b>
                              </a>
                            </span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[7].visible">
                            <span class="label label-default">{{action.deliveredCoupons | number: '1.0-0'}}</span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[8].visible">
                            <span class="label label-default">{{action.deliveredPercentage | percent: '1.2-2'}}</span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[9].visible">
                            <span class="label label-default">{{action.redemptions | number: '1.0-0'}}</span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[10].visible">
                            <span class="label label-default">{{action.customerRedemptions | number: '1.2-2'}}</span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[11].visible">
                            <span class="label label-default">{{action.redemptionsPercentage | percent: '1.2-2'}}</span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[12].visible">
                            <span class="label label-default">{{action.uniqueRedemptionsPercentage | percent: '1.2-2'}}</span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[13].visible">
                            <span class="label label-default">{{action.budgetExpense | lgcurrency: '1.2-2'}}</span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[14].visible">
                            <span class="label label-default">{{action.costVsBudget | number: '1.2-2'}} %</span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[15].visible">
                            <span class="label label-default">{{action.discountCost | lgcurrency: '1.2-2'}}</span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[16].visible">
                            <span class="label label-default">
                              <a class="pointer" [routerLink]="['', { outlets: { modal: ['show', 'coupons', action.embeddedCampaign.couponId] }}]" target="_blank">
                                <b><u>{{action.embeddedCampaign.couponId}}</u></b>
                              </a>
                            </span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[17].visible">
                            <span class="label label-default">{{action.budgetConsumed | percent: '1.2-2'}}</span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[18].visible">
                            <span class="label label-default">{{action.forecastBudgetConsumed | lgcurrency: '1.2-2'}}</span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[19].visible">
                            <span *ngIf="parsedRoiDate >= this.plan.available_from && parsedRoiDate <= this.plan.available_to" class="label label-default">{{action.budgetDeliveredVsForecasted | percent: '1.2-2'}}</span>
                            <span *ngIf="parsedRoiDate > plan.available_to" class="label label-default">-</span>
                          </td>
                          <td class="text-right" *ngIf="element.type === 'Plans::SupplierCampaign'" [hidden]="!tableColumns[20].visible">
                            <span class="label label-default">{{action.lgCost | lgcurrency: '1.2-2'}}</span>
                          </td>
                          <td class="text-right" *ngIf="element.type !== 'Plans::SupplierCampaign'" [hidden]="!tableColumns[20].visible">
                            <span class="label label-default">{{'common.fields.na' | translate}}</span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[21].visible">
                            <span class="label label-default">{{action.incSales | lgcurrency: '1.2-2'}}</span>
                          </td>
                          <td class="text-right" [hidden]="!tableColumns[22].visible">
                            <span class="label label-default">{{action.incRatio | number: '1.2-2'}}</span>
                          </td>
                          <!-- CAMPAIGN RELATED COLUMNS -->
                          <ng-container *ngIf="flags.showPlanROIChannelColumns">
                            <td class="text-right" [hidden]="!tableColumns[23].visible">
                              <span class="label label-default">{{action.duration | number: '1.2-2'}}</span>
                            </td>
                            <td class="text-center" [hidden]="!tableColumns[24].visible">
                              <span class="text-success" *ngIf="action.significance"><i class="fa-solid fa-check"></i></span>
                              <span class="text-danger" *ngIf="!action.significance"><i class="fa-solid fa-x"></i></span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[25].visible">
                              <span class="label label-default">{{action.exhaustedVouchers | number: '1.2-2'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[26].visible">
                              <span class="label label-default">{{action.exhaustedVouchersRatio | percent: '1.2-2'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[27].visible">
                              <span class="label label-default">{{action.totalVouchersDelivered | number: '1.0-0'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[28].visible">
                              <span class="label label-default">{{action.redemptionsFromTotalVouchersDelivered | percent: '1.2-2'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[29].visible">
                              <span class="label label-default">{{action.forecastedIncrementalSales | number: '1.2-2'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[30].visible">
                              <span class="label label-default">{{action.forecastedIncrementalRatio | number: '1.2-2'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[31].visible">
                              <span class="label label-default">{{action.incrementalSalesVsEstimation | number: '1.0-0'}} %</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[32].visible">
                              <span class="label label-default">{{action.incrementalRatioVsEstimation | number: '1.0-0'}} %</span>
                            </td>
                            <!-- END OF CAMPAIGN RELATED COLUMNS -->
                            <!-- CHANNEL RELATED COLUMNS -->
                            <td class="text-center" [hidden]="!tableColumns[33].visible">
                              {{'resources.campaigns.channels.' + action.channel | translate}}
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[34].visible">
                              <span class="label label-default">{{action.deliveryCost | number: '1.2-2'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[35].visible">
                              <span class="label label-default">{{action.emailDelivered | number: '1.0-0'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[36].visible">
                              <span class="label label-default">{{action.emailOrTotal | percent: '1.2-2'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[37].visible">
                              <span class="label label-default">{{action.emailClicks | number: '1.0-0'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[38].visible">
                              <span class="label label-default">{{action.emailErrors | number: '1.0-0'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[39].visible">
                              <span class="label label-default">{{action.redemptionsVsOr | percent: '1.2-2'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[40].visible">
                              <span class="label label-default">{{action.smsDelivered | number: '1.0-0'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[41].visible">
                              <span class="label label-default">{{action.redemptionsVsSmsDelivered | percent : '1.2-2'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[42].visible">
                              <span class="label label-default">{{action.smsErrors | number: '1.0-0'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[43].visible">
                              <span class="label label-default">{{action.smsUnsubscribe | number: '1.0-0'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[44].visible">
                              <span class="label label-default">{{action.netIncrementalRatio | number: '1.2-2'}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[45].visible">
                              <span class="label label-default">{{action.reminderCost | number: '1.2-2'}}</span>
                            </td>
                            <!-- END CHANNEL RELATED COLUMNS -->
                            <!-- COUPON RELATED COLUMNS -->
                            <td class="text-center" [hidden]="!tableColumns[46].visible">
                              <span class="label label-default">
                                {{action.embeddedCampaign.couponRaw.discount | number: '1.2-2'}}
                                <span *ngIf="action.embeddedCampaign.couponRaw.discount_type == 'cash'">{{currencySymbol}}</span>
                                <span *ngIf="action.embeddedCampaign.couponRaw.discount_type == 'percent'">%</span>
                              </span>
                            </td>
                            <td class="text-center" [hidden]="!tableColumns[47].visible">
                              <span class="label label-default" *ngIf="action.embeddedCampaign.couponRaw.max_units">{{action.embeddedCampaign.couponRaw.max_units | number: '1.2-2'}}</span>
                              <span *ngIf="!action.embeddedCampaign.couponRaw.max_units">{{'common.fields.na' | translate}}</span>
                            </td>
                            <td class="text-center" [hidden]="!tableColumns[48].visible">
                              <span class="label label-default" *ngIf="action.embeddedCampaign.couponRaw.min_units">{{action.embeddedCampaign.couponRaw.min_units | number: '1.2-2'}}</span>
                              <span *ngIf="!action.embeddedCampaign.couponRaw.min_units">{{'common.fields.na' | translate}}</span>
                            </td>
                            <td class="text-center" [hidden]="!tableColumns[49].visible">
                              <span class="label label-default" *ngIf="action.embeddedCampaign.couponRaw.max_money">{{action.embeddedCampaign.couponRaw.max_money | number: '1.2-2'}}</span>
                              <span *ngIf="!action.embeddedCampaign.couponRaw.max_money">{{'common.fields.na' | translate}}</span>
                            </td>
                            <td class="text-center" [hidden]="!tableColumns[50].visible">
                              <span class="label label-default" *ngIf="action.embeddedCampaign.couponRaw.min_money">{{action.embeddedCampaign.couponRaw.min_money | number: '1.2-2'}}</span>
                              <span *ngIf="!action.embeddedCampaign.couponRaw.min_money">{{'common.fields.na' | translate}}</span>
                            </td>
                            <td class="text-center" [hidden]="!tableColumns[51].visible">
                              <span>{{'resources.coupons.fields.' + action.embeddedCampaign.couponRaw.affectation | translate}}</span>
                            </td>
                            <td class="text-right" [hidden]="!tableColumns[52].visible">
                              <span class="label label-default" *ngIf="action.embeddedCampaign.couponRaw.max_redemptions">{{action.embeddedCampaign.couponRaw.max_redemptions | number: '1.0-0'}}</span>
                              <span *ngIf="!action.embeddedCampaign.couponRaw.max_redemptions">{{'common.fields.na' | translate}}</span>
                            </td>
                            <td class="text-center" [hidden]="!tableColumns[53].visible">
                              <span class="text-success" *ngIf="action.embeddedCampaign.couponRaw.cumulative"><i class="fa-solid fa-check"></i></span>
                              <span class="text-danger" *ngIf="!action.embeddedCampaign.couponRaw.cumulative"><i class="fa-solid fa-x"></i></span>
                            </td>
                            <td class="text-center" [hidden]="!tableColumns[54].visible">
                              <span class="text-success" *ngIf="action.embeddedCampaign.couponRaw.auto_redeem"><i class="fa-solid fa-check"></i></span>
                              <span class="text-danger" *ngIf="!action.embeddedCampaign.couponRaw.auto_redeem"><i class="fa-solid fa-x"></i></span>
                            </td>
                            <!-- END OF COUPON RELATED COLUMNS -->
                          </ng-container>
                          <!-- ACTIONS -->
                          <td [hidden]="!tableColumns[tableColumns.length - 1].visible">
                            <div class="btn-group">
                              <button id="planDetailRoiReprintVouchersCampaignBtn"
                                      class="btn btn-sm btn-default btn-icon no-mrg-btm"
                                      (click)="reprintCampaignOrWholePlan(action.embeddedCampaign.campaignHistoryId)"
                                      tooltipPosition="left"
                                      pTooltip="{{'resources.plans.buttons.undo_coupon_status' | translate}}"
                                      [disabled]="action.embeddedCampaign.campaignHistoryId === null">
                                <i class="ei-print icon-align"></i>
                              </button>
                              <button *ngIf="flags.reminderButtonVisibility"
                                      class="btn btn-sm btn-default btn-icon no-mrg-btm"
                                      (click)="openReminderForm(action, 'reminder_plan')"
                                      tooltipPosition="left"
                                      pTooltip="{{'resources.plans.buttons.set_reminder' | translate}}">
                                <i class="ei-light-bulb icon-align"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    <!-- Campaign Total Calculations -->
                    <tr class="highlight-header">
                      <td class="text-left align-middle td-stick2" [hidden]="!tableColumns[0].visible">
                        {{'resources.campaign_plans.types.custom.forecast.table.campaign_total' | translate}}
                      </td>
                      <td class="td-stick3" [hidden]="!tableColumns[1].visible"></td>
                      <td class="td-stick4" [hidden]="!tableColumns[2].visible"></td>
                      <td class="text-right" [hidden]="!tableColumns[4].visible">
                        <span class="label label-default">{{'common.fields.na' | translate}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[5].visible">
                        <span class="label label-default">{{campaign.customersCount | number: '1.0-0'}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[6].visible">
                        <span class="label label-default">{{'common.fields.na' | translate}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[7].visible">
                        <span class="label label-default">{{campaign.deliveredCoupons | number: '1.0-0'}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[8].visible">
                        <span class="label label-default">{{campaign.deliveredPercentage | percent: '1.2-2'}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[9].visible">
                        <span class="label label-default">{{campaign.redemptions | number: '1.0-0'}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[10].visible">
                        <span class="label label-default">{{campaign.customerRedemptions | number: '1.2-2'}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[11].visible">
                        <span class="label label-default">{{campaign.redemptionsPercentage | percent: '1.2-2'}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[12].visible">
                        <span class="label label-default">{{campaign.uniqueRedemptionsPercentage | percent: '1.2-2'}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[13].visible">
                        <span class="label label-default">{{campaign.budgetExpense | lgcurrency: '1.2-2'}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[14].visible">
                        <span class="label label-default">{{campaign.costVsBudget | number: '1.2-2'}} %</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[15].visible">
                        <span class="label label-default">{{campaign.discountCost | lgcurrency: '1.2-2'}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[16].visible">
                        <span class="label label-default">{{'common.fields.na' | translate}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[17].visible">
                        <span class="label label-default">{{campaign.budgetConsumed | percent: '1.2-2'}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[18].visible">
                        <span class="label label-default">{{campaign.forecastBudgetConsumed | lgcurrency: '1.2-2'}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[19].visible">
                        <span *ngIf="parsedRoiDate >= this.plan.available_from && parsedRoiDate <= this.plan.available_to" class="label label-default">{{campaign.budgetDeliveredVsForecasted | percent: '1.2-2'}}</span>
                        <span *ngIf="parsedRoiDate > plan.available_to" class="label label-default">-</span>
                      </td>
                      <td class="text-right" *ngIf="element.type === 'Plans::SupplierCampaign'" [hidden]="!tableColumns[20].visible">
                        <span class="label label-default">{{campaign.lgCost | lgcurrency: '1.2-2'}}</span>
                      </td>
                      <td class="text-right" *ngIf="element.type !== 'Plans::SupplierCampaign'" [hidden]="!tableColumns[20].visible">
                        <span class="label label-default">{{'common.fields.na' | translate}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[21].visible">
                        <span class="label label-default">{{campaign.incSales | lgcurrency: '1.2-2'}}</span>
                      </td>
                      <td class="text-right" [hidden]="!tableColumns[22].visible">
                        <span class="label label-default">{{campaign.incRatio | number: '1.2-2'}}</span>
                      </td>
                      <!-- CAMPAIGN RELATED COLUMNS (EMPTY)-->
                      <ng-container *ngIf="flags.showPlanROIChannelColumns">
                        <td *ngFor="let _ of [].constructor(channelColumnsOffset)"></td>
                      </ng-container>
                      <!-- END CAMPAIGN RELATED COLUMNS (EMPTY)-->
                      <td></td>
                    </tr>
                  </ng-container>
                </ng-container>

                  <!-- SUBTOTAL / CAMPAIGN TYPE -->
                  <tr class="highlight-header">
                    <td *ngIf="showSubtotal"
                        [hidden]="!tableColumns[0].visible"
                        class="text-center align-middle text-bold category-sticky"
                        [ngClass]="{'bg-custom': element.type === 'Plans::CustomCampaign',
                                  'bg-supplier': element.type === 'Plans::SupplierCampaign',
                                  'bg-up-cross': element.type === 'Plans::UpCrossCampaign',
                                  'bg-recovery': element.type === 'Plans::RecoveryCampaign',
                                  'bg-random': element.type === 'random_1',
                                  'bg-random': element.type === 'random_2',
                                  'bg-random': element.type === 'random_3'}">
                      {{element.category}}
                    </td>
                    <td class="text-left text-bold td-stick2" [hidden]="!tableColumns[1].visible">{{'common.subtotal' | translate}}</td>
                    <td class="td-stick3" [hidden]="!tableColumns[2].visible"></td>
                    <td class="td-stick4" [hidden]="!tableColumns[3].visible"></td>
                    <td class="text-right" [hidden]="!tableColumns[4].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[5].visible">
                      <span class="label label-default">{{element.subtotal.customersCount | number: '1.0-0'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[6].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[7].visible">
                      <span class="label label-default">{{element.subtotal.deliveredCoupons | number: '1.0-0'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[8].visible">
                      <span class="label label-default">{{element.subtotal.deliveredPercentage | percent: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[9].visible">
                      <span class="label label-default">{{element.subtotal.redemptions | number: '1.0-0'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[10].visible">
                      <span class="label label-default">{{element.subtotal.customerRedemptions | number: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[11].visible">
                      <span class="label label-default">{{element.subtotal.redemptionsPercentage | percent: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[12].visible">
                      <span class="label label-default">{{element.subtotal.uniqueRedemptions | percent: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[13].visible">
                      <span class="label label-default">{{element.subtotal.budgetExpense | lgcurrency: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[14].visible">
                      <span class="label label-default">{{element.subtotal.costVsBudget | number: '1.2-2'}} %</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[15].visible">
                      <span class="label label-default">{{element.subtotal.discountCost | lgcurrency: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[16].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[17].visible">
                      <span class="label label-default">{{element.subtotal.budgetConsumed | percent: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[18].visible">
                      <span class="label label-default">{{element.subtotal.forecastBudgetConsumed | lgcurrency: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[19].visible">
                      <span *ngIf="parsedRoiDate >= this.plan.available_from && parsedRoiDate <= this.plan.available_to" class="label label-default">{{element.subtotal.budgetDeliveredVsForecasted | percent: '1.2-2'}}</span>
                      <span *ngIf="parsedRoiDate > plan.available_to" class="label label-default">-</span>
                    </td>
                    <td class="text-right" *ngIf="element.type === 'Plans::SupplierCampaign'" [hidden]="!tableColumns[20].visible">
                      <span class="label label-default">{{element.subtotal.lgCost | lgcurrency: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" *ngIf="element.type !== 'Plans::SupplierCampaign'" [hidden]="!tableColumns[20].visible">
                      <span class="label label-default">{{'common.fields.na' | translate}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[21].visible">
                      <span class="label label-default">{{element.subtotal.incSales | lgcurrency: '1.2-2'}}</span>
                    </td>
                    <td class="text-right" [hidden]="!tableColumns[22].visible">
                      <span class="label label-default">{{element.subtotal.incRatio | number: '1.2-2'}}</span>
                    </td>
                    <!-- CAMPAIGN RELATED COLUMNS (EMPTY)-->
                    <ng-container *ngIf="flags.showPlanROIChannelColumns">
                      <td *ngFor="let _ of [].constructor(channelColumnsOffset)"></td>
                    </ng-container>
                    <!-- END CAMPAIGN RELATED COLUMNS (EMPTY)-->
                    <td></td>
                  </tr>
                  <!-- END SUBTOTAL / CAMPAIGN TYPE -->
                </ng-container>
                <!-- TOTAL ROW -->
                <tr class="highlight-header">
                  <td class="td-stick1" [hidden]="!tableColumns[0].visible"></td>
                  <td class="text-left text-bold td-stick2" [hidden]="!tableColumns[1].visible">{{'common.total' | translate}}</td>
                  <td class="td-stick3" [hidden]="!tableColumns[2].visible"></td>
                  <td class="td-stick4" [hidden]="!tableColumns[3].visible"></td>
                  <td class="text-right" [hidden]="!tableColumns[4].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[5].visible">
                    <span class="label label-default">{{totalValues.totalCustomersCount | number: '1.0-0'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[6].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[7].visible">
                    <span class="label label-default">{{totalValues.totalDeliveredCoupons | number: '1.0-0'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[8].visible">
                    <span class="label label-default">{{totalValues.totalDeliveredPercentage | percent: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[9].visible">
                    <span class="label label-default">{{totalValues.totalRedemptions | number: '1.0-0'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[10].visible">
                    <span class="label label-default">{{totalValues.totalCustomerRedemptions | number: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[11].visible">
                    <span class="label label-default">{{totalValues.totalRedemptionsPercentage | percent: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[12].visible">
                    <span class="label label-default">{{totalValues.totalUniqueRedemptions | percent: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[13].visible">
                    <span class="label label-default">{{totalValues.totalBudgetExpense | lgcurrency: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[14].visible">
                    <span class="label label-default">{{totalValues.totalCostVsBudget | number: '1.2-2'}} %</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[15].visible">
                    <span class="label label-default">{{totalValues.totalDiscountCost | lgcurrency: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[16].visible">
                    <span class="label label-default">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[17].visible">
                    <span class="label label-default">{{totalValues.totalBudgetConsumed | percent: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[18].visible">
                    <span class="label label-default">{{totalValues.totalForecastBudgetConsumed | lgcurrency: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[19].visible">
                    <span *ngIf="parsedRoiDate >= this.plan.available_from && parsedRoiDate <= this.plan.available_to" class="label label-default">{{totalValues.totalBudgetDeliveredVsForecasted | percent: '1.2-2'}}</span>
                    <span *ngIf="parsedRoiDate > plan.available_to" class="label label-default">-</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[20].visible">
                    <span class="label label-default" *ngIf="hasSupplierCampaign">{{totalValues.totalLgCost | lgcurrency: '1.2-2'}}</span>
                    <span class="label label-default" *ngIf="!hasSupplierCampaign">{{'common.fields.na' | translate}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[21].visible">
                    <span class="label label-default">{{totalValues.totalIncSales | lgcurrency: '1.2-2'}}</span>
                  </td>
                  <td class="text-right" [hidden]="!tableColumns[22].visible">
                    <span class="label label-default">{{totalValues.totalIncRatio | number: '1.2-2'}}</span>
                  </td>
                  <!-- CAMPAIGN RELATED COLUMNS (EMPTY)-->
                  <ng-container *ngIf="flags.showPlanROIChannelColumns">
                    <td *ngFor="let _ of [].constructor(channelColumnsOffset)"></td>
                  </ng-container>
                  <!-- END CAMPAIGN RELATED COLUMNS (EMPTY)-->
                  <td></td>
                </tr>
              </tbody>
            </table>
            <table *ngIf="!loading && planRoiData.length === 0" class="table table-lg table-hover dataTable no-footer mrg-top-20" aria-hidden="true">
              <tbody>
                <tr class="odd no-border">
                  <td style="vertical-align: top;"
                      class="dataTables_empty text-semibold text-center">
                    {{'components.data-table.messages.no_elements_found' | translate}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div *ngIf="openReminderModal">
  <app-create-update-campaigns-dynamic-form (closeModal)="closeModal(true)"
                                            [reminderIds]="actionRaw.reminderIds"
                                            [reminderStatus]="actionRaw.reminderStatus"
                                            [actionRaw]="actionRaw"
                                            [mode]="reminderMode"
                                            (updateRemindersCount)="updateRemindersCount($event)">
  </app-create-update-campaigns-dynamic-form>
</div>
