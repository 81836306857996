<div class="row">
  <div class="col">
    <div class="card">
      <div class="card-block">
        <h4 class="card-title">
          <b [translate]="'resources.journeys.fields.campaigns'"></b>
        </h4>

        <div>
          <div *ngIf="journey.type === 'potential'">
            <!-- Steps headers -->
            <div class="row">
              <div class="col-md-3">
                <h6></h6>
              </div>
              <div class="col-md-1">
                <h6>
                  {{'resources.journeys.fields.status' | translate}}
                </h6>
              </div>
              <div class="col-md-1">
                <h6>
                  {{'resources.journeys.fields.cut' | translate}}({{currencySymbol}})
                </h6>
              </div>
              <div class="col-md-3">
                <h6>
                  {{'resources.journeys.fields.delivery_date' | translate}}
                </h6>
              </div>
              <div class="col-md-3">
                <h6>
                  {{'resources.journeys.fields.content' | translate}}
                </h6>
              </div>
              <div class="col-md-1">
                <h6></h6>
              </div>
            </div>

            <div *ngFor="let step of journey.steps | orderBy: 'position'; let i = index;">
              <div class="row mrg-bottom-5" *ngIf="step.status !== 'cancelled'">
                <div class="col-md-3">
                  <ul class="list-unstyled list-info">
                    <li>
                      <div class="">
                        <div class="thumb-img img-circle">
                          <i *ngIf="i === 0" class="font-size-30 ti-envelope"></i>
                          <i *ngIf="i > 0 && i < journey.steps.length - 1" class="font-size-30 ti-timer"></i>
                          <i *ngIf="i >= journey.steps.length - 1" class="font-size-30 ti-gift"></i>
                        </div>
                        <div class="info">
                          <span class="sub-title">
                            <span *ngIf="i === 0"
                              [translate]="'resources.journeys.warnings.first_message_delivery'"></span>
                            <span
                              *ngIf="i > 0 && i < journey.steps.length - 1">{{'resources.journeys.fields.reminder' | translate}}
                              {{i}}</span>
                            <span *ngIf="i >= journey.steps.length - 1"
                              [translate]="'resources.journeys.warnings.reward_coupon'"></span>
                          </span>
                          <span class="title">{{journey.getStepName(step)}}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="col-md-1">
                  <h3 class="mrg-right-5 display-inline">
                    <span class="font-size-12 badge" [ngClass]="step.getStepLabelClass()">
                      {{ 'resources.journey_steps.status.' + step.status | translate }}
                      <span *ngIf="step.status !== step.cfg.status">*</span>
                    </span>
                  </h3>
                </div>

                <div class="col-md-1">
                  <app-question [form]="form" [question]="getInputConfig('step' + step.position + '.cut')">
                  </app-question>
                </div>

                <div class="col-md-3">
                  <app-question [form]="form" [question]="getInputConfig('step' + step.position + '.scheduled_at')">
                  </app-question>
                </div>

                <div class="col-md-3">
                  <app-question [form]="form" [question]="getInputConfig('step' + step.position + '.voucheable')">
                  </app-question>
                </div>

                <div class="col-md-1 flex-baseline">
                  <div class="btn-group">
                    <button *ngIf="step.cancelable()" class="btn btn-default btn-icon" type="button"
                      (ngModelChange)="handleCampaignsChanged($event)" (click)="changeStepStatus(step, 'cancelled')"
                      tooltipPosition="top" pTooltip="{{ 'resources.journeys.messages.step_cancel' | translate }}">
                      <i class="ei-garbage"></i>
                    </button>
                    <button *ngIf="step.activable()" class="btn btn-default btn-icon" type="button"
                      (ngModelChange)="handleCampaignsChanged($event)" (click)="changeStepStatus(step, 'pending')"
                      tooltipPosition="top" pTooltip="{{ 'resources.journeys.messages.step_resume' | translate }}">
                      <i class="ei-play-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="journey.type === 'recuperation'">
            <!-- Steps headers -->
            <div class="row">
              <div class="col-md-4">
                <h6></h6>
              </div>
              <div class="col-md-2">
                <h6>
                  {{'resources.journeys.fields.status' | translate}}
                </h6>
              </div>
              <div class="col-md-4">
                <h6>
                  {{'resources.journeys.fields.delivery_date' | translate}}
                  <span class="required">*</span>
                </h6>
              </div>
              <div class="col-md-2">
                <h6></h6>
              </div>
            </div>

            <div *ngFor="let step of journey.steps | orderBy: 'position'; let i = index;">
              <div class="row mrg-bottom-5" *ngIf="step.status !== 'cancelled'">
                <div class="col-md-4">
                  <ul class="list-unstyled list-info">
                    <li>
                      <div class="">
                        <div class="thumb-img img-circle">
                          <i class="font-size-30 ti-layout-media-right"></i>
                        </div>
                        <div class="info">
                          <span class="sub-title">
                            <span>{{'resources.journeys.fields.delivery' | translate}} #{{i + 1}}</span>
                          </span>
                          <span class="title">{{journey.getStepName(step)}}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="col-md-2">
                  <h3 class="mrg-right-5 display-inline">
                    <span class="font-size-12 badge" [ngClass]="step.getStepLabelClass()"
                      [translate]="'resources.journey_steps.status.' + step.status"></span>
                  </h3>
                </div>

                <div class="col-md-4">
                  <app-question [form]="form" [question]="getInputConfig('step' + step.position + '.scheduled_at')">
                  </app-question>
                </div>
                <div class="col-md-2 flex-baseline">
                  <div class="btn-group">
                    <button *ngIf="!journey.finalPendingStep() && step.cancelable()" class="btn btn-default btn-icon"
                      type="button" (ngModelChange)="handleCampaignsChanged($event)"
                      (click)="changeStepStatus(step, 'cancelled')" tooltipPosition="top"
                      pTooltip="{{ 'resources.journeys.messages.step_cancel' | translate }}">
                      <i class="ei-garbage"></i>
                    </button>
                    <button *ngIf="step.activable()" class="btn btn-default btn-icon" type="button"
                      (ngModelChange)="handleCampaignsChanged($event)" (click)="changeStepStatus(step, 'pending')"
                      tooltipPosition="top" pTooltip="{{ 'resources.journeys.messages.step_resume' | translate }}">
                      <i class="ei-play-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="journey.type === 'multicoupon'">
            <!-- Steps headers -->
            <div class="row">
              <div class="col-md-3">
                <h6></h6>
              </div>
              <div class="col-md-1">
                <h6>
                  {{'resources.journeys.fields.status' | translate}}
                </h6>
              </div>
              <div class="col-md-3">
                <h6>
                  {{'resources.journeys.fields.delivery_date' | translate}}
                  <span class="required">*</span>
                </h6>
              </div>
              <div class="col-md-3">
              </div>
              <div class="col-md-2">
                <h6></h6>
              </div>
            </div>
            <div *ngFor="let step of journey.steps | orderBy: 'position'; let i = index;">
              <div class="row mrg-bottom-5" *ngIf="step.status !== 'cancelled'">
                <div class="col-md-3">
                  <ul class="list-unstyled list-info">
                    <li>
                      <div class="">
                        <div class="thumb-img img-circle">
                          <i class="font-size-30 ti-envelope"></i>
                        </div>
                        <div class="info">
                          <span class="sub-title">
                            <i class="ti-envelope pdd-right-5"></i>
                            <span [translate]="'resources.journeys.fields.delivery'"></span>
                          </span>
                          <span class="title">{{getStepName(step)}}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-md-1">
                  <h3 class="mrg-right-5 display-inline">
                    <span class="font-size-12 badge" [ngClass]="step.getStepLabelClass()"
                      [translate]="'resources.journey_steps.status.' + step.status"></span>
                  </h3>
                </div>

                <div class="col-md-3">
                  <app-question [question]="getInputConfig('step' + step.position + '.scheduled_at')"
                    [disabled]="!journey.isEditableStep(step)" [form]="form"></app-question>
                </div>

                <!-- Message selection for all but reward step -->
                <div class="col-md-4">
                  <ng-container *ngIf="step.hasEditableContent()">
                    <div class="row">
                      <div class="col-md-7">
                        <app-question [form]="form"
                          [question]="getInputConfig('step' + step.position + '.' + step.campaign.via)"
                          (multiselectChanged)="handleTemplateChanged(step, $event)"></app-question>
                      </div>
                      <div class="col-md-5">
                        <ng-container *ngIf="form && form.controls['step' + step.position + '.' + step.campaign.via].value && form.controls['step' + step.position + '.' + step.campaign.via].value.length > 0">
                          <button (click)="viewTemplate(step)" type="button" class="btn btn-default btn-icon"
                            tooltipPosition="top" pTooltip="{{ 'resources.journeys.messages.template_detail' | translate }}">
                            <i class="ei-show"></i>
                          </button>
                          <button (click)="editTemplate(step)" type="button" class="btn btn-default btn-icon"
                            tooltipPosition="top" pTooltip="{{ 'resources.journeys.messages.template_edit' | translate }}">
                            <i class="ei-pencil"></i>
                          </button>
                        </ng-container>

                        <ng-container *ngIf="i !== 0 && journey.isEditableStep(step)">
                         <button *ngIf="step.cancelable()" (click)="changeStepStatus(step, 'cancelled')" type="button"
                          (ngModelChange)="handleCampaignsChanged($event)" class="btn btn-default btn-icon"
                          tooltipPosition="top" pTooltip="{{ 'resources.journeys.messages.step_cancel' | translate }}">
                          <i class="ei-garbage"></i>
                        </button>
                        <button *ngIf="step.activable()" (click)="changeStepStatus(step, 'pending')" type="button"
                          (ngModelChange)="handleCampaignsChanged($event)" class="btn btn-default btn-icon"
                          tooltipPosition="top" pTooltip="{{ 'resources.journeys.messages.step_resume' | translate }}">
                          <i class="ei-play-2"></i>
                        </button>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
