import { getResourceProp } from "../../../../shared/utils/common.utils";

/**
 * A given plan can have multiple campaign plans.  Each campaign plan possess N actions.  In rails API & DDBB one action is 1 manual campaign.
 * This class represents that manual campaign inherited from a campaign plan inside a plan.
 */
export class PlanCampaignAction {
  audience: PlanCampaignActionAudience;
  calculatedDataLoaded: boolean;
  campaignHistoryId: any;
  campaignId: number;
  campaignType?: string;
  channel: "none" | "sms" | "email";
  configuration: object;
  couponAffectation: string;
  coupon_id: number;
  couponName: string;
  couponPriority: number;
  errors: string[];
  estimatedCustomerRedemption: number;
  estimatedDelivery: number;
  estimatedRedemption: number;
  forecast: PlanCampaignActionForecast;
  formGroupKey: string;
  hasCustomEstimationFields: boolean;
  hasEditedFields = false;
  id: number;
  isCouponPrintable: boolean;
  loading: boolean;
  name: string;
  planIds?: number[];
  roi: PlanCampaignActionRoi;
  saved: boolean;
  segment_id: number;
  type?: string;

  /**
   * Corresponding manual campaign embedded into the action.
   */
  embeddedCampaign: {
    id: number,
    name: string,
    status: string
  };

  /**
   * Object that stores end-user editted confiration at the plan forecast level.  Values are populated manually by the end-user to recalculate and adjust forecast.
   */
  forecastConfig: {
    delivered_percentage: number,
    total_redemptions_percentage: number
  }

  /**
   * @param apiObject The raw object of the action return by LG rails api in /actions endpoint
   */
  constructor(apiObject: object) {
    if (apiObject && apiObject['_embedded'] && apiObject['_embedded']['campaign_plan']) {
      this.embeddedCampaign = {
        id: apiObject['_embedded']['campaign_plan']['id'],
        name: apiObject['_embedded']['campaign_plan']['name'],
        status: apiObject['_embedded']['campaign_plan']['status']
      };
    }
    if(apiObject && apiObject['forecast_config']) {
      this.forecastConfig = {
        delivered_percentage: apiObject['forecast_config']['delivered_percentage'],
        total_redemptions_percentage: apiObject['forecast_config']['total_redemptions_percentage'],
      }
    }

    this.id = apiObject['id'];
    this.loading = false;
    this.saved = false;
    this.name = apiObject['name'];
    this.channel = getResourceProp(apiObject, 'configuration', 'campaign_via', 'none');
    this.campaignId = apiObject['campaign_id'];
    this.campaignType = apiObject['campaign_type'];
    this.campaignHistoryId = this.getEmbeddedResourceProp(apiObject, 'campaign_history', 'id');
    this.configuration = apiObject['configuration'];
    this.couponAffectation = this.getEmbeddedResourceProp(apiObject, 'coupon', 'affectation') ;
    this.coupon_id = this.getEmbeddedResourceProp(apiObject, 'coupon', 'id') ;
    this.couponName = this.getEmbeddedResourceProp(apiObject, 'coupon', 'name') ;
    this.couponPriority = this.getEmbeddedResourceProp(apiObject, 'coupon', 'priority') ;
    this.isCouponPrintable = this.getEmbeddedResourceProp(apiObject, 'coupon', 'printable') ;
    this.segment_id = this.getEmbeddedResourceProp(apiObject, 'segment', 'id') ;
    this.audience = new PlanCampaignActionAudience();
    this.forecast = new PlanCampaignActionForecast();
    this.roi = new PlanCampaignActionRoi();
    this.errors = [];
    this.calculatedDataLoaded = false;
    this.estimatedCustomerRedemption = getResourceProp(apiObject, 'audience_config', 'estimated_customer_redemption', 0);
    this.estimatedRedemption = getResourceProp(apiObject, 'audience_config', 'estimated_redemption', 0);
    this.estimatedDelivery = getResourceProp(apiObject, 'audience_config', 'estimated_delivery', 0);
    this.hasCustomEstimationFields = apiObject['audience_config'] && Object.keys(apiObject['audience_config']).length > 0;
    this.planIds = apiObject['plan_ids'] ? apiObject['plan_ids'] : [];
    this.type = apiObject['campaign_type'] ? apiObject['campaign_type'] : null;
  }

  /**
   * @param calculatedData The raw object (data) returned by FQS (throught rails API /calculate_data) to populate audience fields.
   */
  processAudienceData(calculatedData: object) {
    this.calculatedDataLoaded = true;
    if (calculatedData && Object.keys(calculatedData).length > 0) {
      this.audience.populate(calculatedData);
    }
  }

  /**
   * @param calculatedData The raw object (data) returned by FQS (throught rails API /calculate_data) to populate forecast.
   */
  processForecastData(calculatedData: object, hasError?: boolean) {
    this.calculatedDataLoaded = true;
    if (calculatedData && Object.keys(calculatedData).length > 0) {
      this.forecast.populate(calculatedData);
    }
  }

  /**
   * @param calculatedData The raw object (data) returned by FQS (throught rails API /calculate_data) to populate ROI.
   */
  processROIData(calculatedData: object) {
    this.calculatedDataLoaded = true;
    if (calculatedData && Object.keys(calculatedData).length > 0) {
      this.roi.populate(calculatedData);
    }
  }

  /**
   * @param errorData fills errors field with object array returned from rails API.
   */
  processErrorsData(errorData: unknown[]) {
    if (errorData && errorData.length > 0 ) {
      this.forecast['errors'] = errorData;
    }
  }

  /**
   * @returns a boolean value regarding if the actual action has errors or not.
   */
  hasErrors(): boolean {
    return this.errors.length > 0;
  }

  private getEmbeddedResourceProp(apiObject: object, resource: string, prop: string): any {
    if (!apiObject.hasOwnProperty('_embedded')) { return null; }
    if (!apiObject['_embedded'].hasOwnProperty(resource)) { return null; }
    if (apiObject['_embedded'].hasOwnProperty(resource) && !apiObject['_embedded'][resource]) { return null; }
    return apiObject['_embedded'][resource][prop];
  }
}

export class PlanCampaignActionAudience {
  affectationSales: number = 0;
  averageExpenditure: number = 0;
  averageTicket: number = 0;
  controlGroup: number = 0;
  frequency: number = 0;
  pvp: number = 0;
  salesMpaa: number = 0;
  salesPeriodMpaa: number = 0;
  seasonality: number = 0;
  totalCustomers: number = 0;
  units: number = 0;
  minMoney: number = 0;
  minUnits: number = 0;

  constructor() {}

  populate(calculatedData: Object) {
    this.totalCustomers = (calculatedData && calculatedData['total_customers']) ? calculatedData['total_customers'] : 0;
    this.affectationSales = this.setValueIfAnyCustomers(calculatedData['affectation_sales']);
    this.averageExpenditure = this.setValueIfAnyCustomers(calculatedData['average_expenditure']);
    this.averageTicket = this.setValueIfAnyCustomers(calculatedData['average_ticket']);
    this.controlGroup = this.setValueIfAnyCustomers(calculatedData['control_group']);
    this.frequency = this.setValueIfAnyCustomers(calculatedData['frequency']);
    this.pvp = this.setValueIfAnyCustomers(calculatedData['pvp']);
    this.salesMpaa = this.setValueIfAnyCustomers(calculatedData['sales_mpaa']);
    this.salesPeriodMpaa = this.setValueIfAnyCustomers(calculatedData['sales_period_mpaa']);
    this.seasonality = this.setValueIfAnyCustomers(calculatedData['seasonability']);
    this.units = this.setValueIfAnyCustomers(calculatedData['units_per_ticket']);
    const minMoney = this.setValueIfAnyCustomers(calculatedData['minimum_money']);
    this.minMoney = minMoney ? Math.ceil(minMoney) : 0;
    const minUnits = this.setValueIfAnyCustomers(calculatedData['minimum_units']);
    this.minUnits = minUnits ? Math.ceil(minUnits) : 0;
  }

  private setValueIfAnyCustomers(val: number): number {
    if (val === undefined || val === null) { return 0; }
    if (this.totalCustomers === 0) { return 0; }
    return val;
  }
}
/*
* For further information please visit:
* https://docs.google.com/spreadsheets/d/18ejM9BYUvF-R3akPP9KU6VVNuMB-Hsnc21OhSdETl-I/edit?ts=5e9efb46#gid=0
*/
export class PlanCampaignActionForecast {
  customers = 0; // B, Impacted customers
  maxRedemptionsPerCoupon = 0; // C, Maximum redemption per coupon
  estimatedDelivery = 0; // E, % Estimated Delivery
  delivered = 0; // AE, Delivered coupons (without multiredemption)
  eurecatEstimatedRedemption = 0;
  eurecatEstimatedRedemptionErr: string;
  estimatedRedemption = 0; // G, % estimated redemption
  uniqueRedemptions = 0; // H, Estimated Customers who redeem
  redemptionsPerCustomer = 0; // I, Redemption per customer
  totalRedemptions = 0; // L, Estimated redemptions (total redemptions)
  discount = 0; // P, Discount
  discountType: string = null; // O, Coupon units. Possible values: cash, percent
  minPurchase = 0; // Q, Minimum purchase € / units
  minPurchaseType: string = null; // possible values: money, units, none
  discountOverPurchase = 0; // R, % Discount
  budgetExpense = 0; // S, Discount cost €
  lgCost = 0; // T, Loyal Guru Cost
  incremental = 0; // Z, Incremental sales
  incrementalRatio = 0; // AA, Incremental ratio
  incrementalOverSales = 0; // AD, % Increase total sales
  affectationSales = 0;
  budgetRatio = 0;
  incSales = 0;
  incRatio = 0;
  redemptionsPercentage = 0;

  // Fields for grouped plans with forecast
  deliveredPercentage? = 0;
  totalRedemptionsPercentage? = 0;
  budget? = 0;
  incrementalOverSalesPercentage? = 0;

  //Fields channel
  deliveryCost = 0;
  netIncrementalRatio = 0;

  constructor() {}

  populate(calculatedData: object) {
    this.customers = calculatedData['customers'] || 0;
    this.maxRedemptionsPerCoupon = calculatedData['max_redemption_per_coupon'] || 0;
    this.estimatedDelivery = calculatedData['estimated_delivery'] || 0;
    this.delivered = calculatedData['delivered'] || 0;
    this.estimatedRedemption = calculatedData['estimated_redemption'] || 0;
    this.uniqueRedemptions = calculatedData['unique_redemptions'] || 0;
    this.redemptionsPerCustomer = calculatedData['redemption_per_customer'] || 0;
    this.totalRedemptions = calculatedData['total_redemptions'] || 0;
    this.discount = calculatedData['discount'] || 0;
    this.discountType = calculatedData['discount_type'] || 0;
    this.minPurchase = calculatedData['min_purchase'] || 0;
    this.minPurchaseType = calculatedData['min_purchase_type'] || 0;
    this.discountOverPurchase = (calculatedData['discount_over_purchase'] || 0);
    this.budgetExpense = calculatedData['discount_cost'] || 0;
    this.budgetRatio = calculatedData['budget_ratio'] || 0;
    this.lgCost = calculatedData['loyal_guru_cost'] || 0;
    this.incremental = calculatedData['incremental'] || 0;
    this.incrementalRatio = calculatedData['incremental_ratio'] || 0;
    this.incrementalOverSales = (calculatedData['incremental_over_sales'] || 0) * 100;
    this.affectationSales = calculatedData['affectation_sales'] || 0;
    this.incSales = calculatedData['incremental'] || 0;
    this.incRatio = calculatedData['incremental_ratio'] || 0;
    this.redemptionsPercentage = this.delivered === 0 ? 0 : (this.totalRedemptions / this.delivered) * 100;
    switch (calculatedData['channel']) {
      case 'email':
        this.deliveryCost = parseFloat(calculatedData['required_email_credits']) || 0;
        break;
      case 'sms':
        this.deliveryCost = parseFloat(calculatedData['required_sms_credits']) || 0;
        break;
      default:
        this.deliveryCost = parseFloat(calculatedData['delivery_cost']) || 0;
        break;
    }
    this.netIncrementalRatio = calculatedData['net_incremental_ratio'] || 0;
  }
}

export class PlanCampaignActionRoi {
  customers = 0; // B, Impacted customers
  deliveredVouchers = 0;
  deliveredRatio = 0;
  redemptions = 0;
  customerRedemptions = 0;
  redemptionsPercentage = 0;
  uniqueRedemptions = 0;
  uniqueRedemptionsPercentage = 0;
  discountCost = 0;
  lgCost = 0;
  incSales = 0;
  incRatio = 0;

  constructor() {}

  public populate(calculatedData: object) {
    this.customers = calculatedData['customers'] || 0;
    this.deliveredVouchers = calculatedData['delivered_vouchers'] || 0;
    this.deliveredRatio = calculatedData['delivered_percentage'] || 0;
    this.redemptions = calculatedData['total_redemptions'] || 0;
    this.customerRedemptions = calculatedData['redemptions_per_customer'] || 0;
    this.redemptionsPercentage = calculatedData['total_redemptions_percentage'] || 0;
    this.uniqueRedemptions = calculatedData['unique_redemptions'] || 0;
    this.uniqueRedemptionsPercentage = calculatedData['unique_redemptions_percentage'] || 0;
    this.discountCost = calculatedData['discount_cost'] || 0;
    this.lgCost = calculatedData['loyal_guru_cost'] || 0;
    this.incSales = calculatedData['incremental'] || 0;
    this.incRatio = calculatedData['incremental_ratio'] || 0;
  }
}
