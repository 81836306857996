
<div class="row mrg-top-15">
  <app-loader styleClass="col-md-12" noWrapper *ngIf="loading" />

  <div class="col">
    <div class="card bg-primary height-86 pointer" *ngIf="couponData" (click)="getCoupons(null, 'clients_assigned')" [ngClass]="{'bg-primary' : isCouponsTagSelected('clients_assigned'),
                                                      'bg-info': !isCouponsTagSelected('clients_assigned')}">
      <div class="card-block">
        <div class="row">
          <div class="col-md-12">
            <p class="text-uppercase font-size-13 text-center text-white"><b [translate]="'resources.coupons.modal.show.statistics.fields.total'"></b></p>
            <h1 class="no-mrg-top font-size-30 text-center">{{couponData.total}}</h1><span class="pull-right"><b></b></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="card bg-primary height-86 pointer" *ngIf="couponData" (click)="getCoupons('have_been_printed', 'clients_printed')" [ngClass]="{'bg-primary' : isCouponsTagSelected('clients_printed'),
                                                      'bg-info': !isCouponsTagSelected('clients_printed')}">
      <div class="card-block">
        <div class="row">
          <div class="col-md-12">
            <p class="text-uppercase font-size-13 text-center text-white"><b [translate]="'resources.coupons.modal.show.statistics.fields.printed'"></b></p>
            <h1 class="no-mrg-top font-size-30 text-center">{{couponData.printed}}</h1><span class="text-white pull-right">{{isNanValue(couponData.printed, couponData.total)}}<b></b></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="card bg-primary height-86 pointer" *ngIf="couponData" (click)="getCoupons('pending', 'clients_pending')" [ngClass]="{'bg-primary' : isCouponsTagSelected('clients_pending'),
                                                      'bg-info': !isCouponsTagSelected('clients_pending')}">
      <div class="card-block">
        <div class="row">
          <div class="col-md-12">
            <p class="text-uppercase font-size-13 text-center text-white"><b [translate]="'resources.coupons.modal.show.statistics.fields.pending'"></b></p>
            <h1 class="no-mrg-top font-size-30 text-center">{{couponData.pending}}</h1><span class="text-white pull-right">{{isNanValue(couponData.pending, couponData.total)}}<b></b></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="card bg-primary height-86 pointer" *ngIf="couponData" (click)="getCoupons('redeemed', 'clients_redeemed')" [ngClass]="{'bg-primary' : isCouponsTagSelected('clients_redeemed'),
                                                      'bg-info': !isCouponsTagSelected('clients_redeemed')}">
      <div class="card-block">
        <div class="row">
          <div class="col-md-12">
            <p class="text-uppercase font-size-13 text-center text-white"><b [translate]="'resources.coupons.modal.show.statistics.fields.redeemed'"></b></p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <h1 class="no-mrg-top font-size-30 text-center">{{couponData.redeemed}}</h1>
          </div>
          <div class="col-md-6">
            <h1 class="no-mrg-top font-size-30 text-center">{{couponData.unique_redeemed}}</h1>
            <p class="text-uppercase font-size-11 text-center text-white no-mrg-btm"><b [translate]="'resources.coupons.modal.show.statistics.fields.unique_redeemed'"></b></p>
            <p class="text-white text-center font-size-11">({{isNanValue(couponData.unique_redeemed, couponData.total)}})<b></b></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="card bg-primary height-86 pointer" *ngIf="couponData" (click)="getCoupons('sold_out', 'clients_sold_out')" [ngClass]="{'bg-primary' : isCouponsTagSelected('clients_sold_out'),
                                                      'bg-info': !isCouponsTagSelected('clients_sold_out')}">
      <div class="card-block">
        <div class="row">
          <div class="col-md-12">
            <p class="text-uppercase font-size-13 text-center text-white"><b [translate]="'resources.coupons.modal.show.statistics.fields.sold_out'"></b></p>
            <h1 class="no-mrg-top font-size-30 text-center">{{couponData.sold_out}}</h1><span class="text-white pull-right">{{isNanValue(couponData.sold_out, couponData.total)}}<b></b></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="card bg-primary height-86 pointer" *ngIf="couponData" (click)="getCoupons('unavailable', 'clients_cancelled')" [ngClass]="{'bg-primary' : isCouponsTagSelected('clients_cancelled'),
                                                      'bg-info': !isCouponsTagSelected('clients_cancelled')}">
      <div class="card-block">
        <div class="row">
          <div class="col-md-12">
            <p class="text-uppercase font-size-13 text-center text-white"><b [translate]="'resources.coupons.modal.show.statistics.fields.canceled'"></b></p>
            <h1 class="no-mrg-top font-size-30 text-center">{{couponData.canceled + couponData.expired}}</h1><span class="text-white pull-right">{{isNanValue(couponData.canceled + couponData.expired, couponData.total)}}<b></b></span>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- CUSTOMERS TABLE -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-block">
        <div class="card-heading no-pdd border bottom">
          <h4 [translate]="'resources.coupons.modal.show.statistics.table.title'"></h4>
        </div>
        <br>
        <app-data-table [config]="dataTableConfigStatistics"></app-data-table>
      </div>
    </div>
  </div>
</div>
