import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from './condition.service';
import { QuestionBase } from '../../models/forms/question-base';

@Injectable()
export class GroupConditionService implements SegmentConditionProvider {

  public inputs: QuestionBase<any>[];

  constructor( ) { }

  public getInputs( params: any ): QuestionBase<any>[] {
    const inputs = [];
    this.inputs = inputs;
    return this.inputs;
  }

  public prepareFormValuesToAPI( params: any ): any {}

  public prepareFormValuesFromAPI( params: any ): any {}
}
