export class Plan {

  id: number;
  name: string;
  available_from: string;
  available_to: string;
  print_from: string;
  print_to: string;
  print_disabled: string;
  status: string;
  planForecastDate: any;
  forecast_status: string;
  roi_status: string;
  created_by_csv: boolean;
  csv_path: string;
  pre_post_status: string;
  pre_post_updated_at: string;

  constructor(cfg) {
    this.id = cfg.id;
    this.name = cfg.name;
    this.available_from = cfg.available_from;
    this.available_to = cfg.available_to;
    this.print_from = cfg.print_from;
    this.print_to = cfg.print_to;
    this.print_disabled = cfg.print_disabled;
    this.status = cfg.status;
    this.planForecastDate = cfg.forecast_updated_at;
    this.forecast_status = cfg.forecast_status;
    this.roi_status = cfg.roi_status;
    this.created_by_csv = cfg.created_by_csv;
    this.csv_path = cfg.csv_path;
    this.pre_post_status = cfg.pre_post_status;
    this.pre_post_updated_at = cfg.pre_post_updated_at;
  }

  isForecastInProgress() {
    return this.forecast_status === 'refreshing';
  }

  isForecastWithErrors() {
    return this.forecast_status === 'error';
  }

  isRoiInProgress() {
    return this.roi_status === 'refreshing';
  }

  isRoiWithErrors() {
    return this.roi_status === 'error';
  }

  isDelivered() {
    return this.status === 'delivered';
  }

  isAnalysisInProgress() {
    return this.pre_post_status === 'running';
  }

  isAnalysisDone() {
    return this.pre_post_status === 'done';
  }
}

export const PlansMock = [
  new Plan({
    id: 1,
    name: 'Plan mock test 1',
    available_from: '2019-12-12',
    available_to: '2021-01-02',
    print_from: '2019-06-12',
    print_to: '2021-01-02',
    status: 'draft',
    forecast_updated_at: '2020-09-08'

  }),
  new Plan({
    id: 2,
    name: 'Plan mock test 2',
    available_from: '2019-12-12',
    available_to: '2021-01-02',
    print_from: '2019-06-12',
    print_to: '2021-01-02',
    status: 'scheduled',
    forecast_updated_at: '2020-09-08'

  }),
  new Plan({
    id: 3,
    name: 'Plan mock test 3',
    available_from: '2019-12-12',
    available_to: '2021-01-02',
    print_from: '2019-06-12',
    print_to: '2021-01-02',
    status: 'delivered',
    forecast_updated_at: '2020-09-08'

  }),
  new Plan({
    id: 4,
    name: 'Plan mock test 4',
    available_from: '2019-12-12',
    available_to: '2021-01-02',
    print_from: '2019-06-12',
    print_to: '2021-01-02',
    status: 'error',
    forecast_updated_at: '2020-09-08'

  }),
  new Plan({
    id: 5,
    name: 'Plan mock test 5',
    available_from: '2019-12-12',
    available_to: '2021-01-02',
    print_from: '2019-06-12',
    print_to: '2021-01-02',
    status: 'sending',
    forecast_updated_at: '2020-09-08'

  })
];
