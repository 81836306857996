<div class="alert alert-info">
  <b>{{campaign.report.count}}</b> {{'resources.campaigns.messages.clients_will_be_impacted' | translate}}
</div>
<div class="alert alert-warning" *ngIf="campaign.report.contact_policy_count !== 0">
  <b>{{campaign.report.contact_policy_count}}</b> {{'resources.campaigns.messages.clients_excluded_by_contact_policy' | translate}}
</div>
<div *ngIf="campaign.report">
  <p>
    <span *ngIf="campaign.report.email_error_count > 0" >
      <span class="label label-danger font-size-14">
        {{campaign.report.email_error_count}} {{'resources.campaigns.report.wrong_emails' | translate}}
      </span>
    </span>

    <span *ngIf="campaign.report.email_empty_count > 0">
      <span class="label label-danger font-size-14">
        {{campaign.report.email_empty_count}} {{'resources.campaigns.report.empty_emails' | translate}}
      </span>
    </span>

    <span *ngIf="campaign.report.email_pending_count > 0">
      <span class="label label-warning font-size-14">
        {{campaign.report.email_pending_count}} {{'resources.campaigns.report.pending_emails' | translate}}
      </span>
    </span>

    <span *ngIf="campaign.report.phone_error_count > 0">
      <span class="label label-danger font-size-14">
        {{campaign.report.phone_error_count}} {{'resources.campaigns.report.wrong_phones' | translate}}
      </span>
    </span>

    <span *ngIf="campaign.report.phone_empty_count > 0">
      <span class="label label-danger font-size-14">
        {{campaign.report.phone_empty_count}} {{'resources.campaigns.report.empty_phones' | translate}}
      </span>
    </span>

    <span *ngIf="campaign.report.phone_prefix_empty_count > 0">
      <span class="label label-danger font-size-14">
        {{campaign.report.phone_prefix_empty_count}} {{'resources.campaigns.report.empty_phone_prefixes' | translate}}
      </span>
    </span>

    <span *ngIf="campaign.report.phone_duplicated_count > 0">
      <span class="label label-danger font-size-14">
        {{campaign.report.phone_duplicated_count}} {{'resources.campaigns.report.duplicated_phones' | translate}}
      </span>
    </span>

    <span *ngIf="campaign.report.phone_pending_check_count > 0">
      <span class="label label-warning font-size-14">
        {{campaign.report.phone_pending_check_count}} {{'resources.campaigns.report.pending_phones' | translate}}
      </span>
    </span>
  </p>
</div>
<div *ngIf="displayControlGroupRoundedWarning">
  <div class="alert alert-warning">
    <ul><li>{{'resources.campaigns.warnings.rounded_control_group' | translate}}</li></ul>
  </div>
</div>
