import { BigqueryJobsStepsBase } from './bigquerystep-base';

export class PumpStep extends BigqueryJobsStepsBase {

  category = 'pump';
  fileName: string;
  pumpCutCondition: string;
  tableName: string;
  append: boolean;

  constructor(options: {} = {}) {
    super(options);
    this.category = options['category'] || 'pump';
    this.pumpCutCondition = options['pump_cut_condition'] || '';
    this.tableName = options['table_name'] || '';
    this.fileName = options['file_name'] || '';
    this.append = options['append'] || false;
  }

  public getPayload() {
    const payload = super.getPayload();
    const newValues = {
      pump_cut_condition: this.pumpCutCondition,
      file_name: this.fileName,
      table_name: this.tableName,
      name: this.tableName
    };
    return {...payload, ...newValues};
  }
}
