import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeZoneService } from '../services/date-time-zone.service';

@Pipe({
  name: 'dateTimeZone'
})
export class DateTimeZonePipe implements PipeTransform {

  constructor(
    private dateService: DateTimeZoneService
  ) {}

  transform(value: any, args?: any, unixTime?): any {
    let format = 'DD/MM/YYYY HH:mm';
    if (args) {
      format = args;
      if (unixTime) { return this.dateService.parseDateWithFormat(value, format, true); }
    }
    return this.dateService.parseDateWithFormat(value, format);
  }

}
