export const CUSTOMERS_ENDPOINT_V2 = `customers/v2`;
export const CUSTOMERS_ENDPOINT_V3 = `customers/v3`;

export function setProfileCustomsList(rawCurrentUser: object, rawCustomerProfile: object): {name: string, value: unknown}[] {
  const customs = rawCurrentUser['company'].customs ? (rawCurrentUser['company'].customs['Profile'] || []) : [];
  const customsList = [];

  for (const key in customs) {
    const customItem = {
      name: customs[key].name, 
      value: null
    };

    if (rawCustomerProfile.hasOwnProperty(key)) {
      if (customs[key].hasOwnProperty('values') && customs[key].values.length !== 0) {
        const value = customs[key].values.find((_value) => _value.value === rawCustomerProfile[key]);
        customItem.value = value ? value.name : null; 
      } else {
        customItem.value = rawCustomerProfile[key];
      }
    }

    customsList.push(customItem);
  }

  return customsList;
}