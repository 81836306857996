import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { FeatureTaxonomiesService } from '../../../resources/data-warehouse/products/feature-taxonomies.service';
import { FeaturesService } from '../../../resources/data-warehouse/products/features.service';
import { QuestionBase } from '../../models/forms/question-base';
import { areArraysEquals } from '../../services/array.service';
import { ConfirmationService } from '../../services/confirmation.service';
import { QuestionControlService } from '../../services/question-control.service';
import { ProductTaxonomy } from './product-taxonomy';
import { SingleInputComponent } from './single-input/single-input.component';
import { DateService } from '../../services/date.service';
import { SalesService } from '../../../resources/analytics/sales/sales.service';
import { FilterConfiguration } from '../data-table-filter/data-table-filter-cfg';
import { CurrentCompany } from '../../models/current-user';
import { ProfileService } from '../../../profiles/profile.service';
import { getCurrencySymbol } from '@angular/common';
import { LocationsService } from '../../../resources/data-warehouse/locations/locations.service';
import * as moment from 'moment';
import { DataTableFilterService } from '../data-table-filter/data-table-filter.service';
import { take, takeUntil } from 'rxjs/operators';
import { LookerNielsenFiltersService } from './services/looker-nielsen-filters/looker-nielsen-filters.service';
import { LookerGlobalFiltersService } from './services/looker-global-filters/looker-global-filters.service';
import { SegmentCategoriesService } from '../../../resources/segments/segment-categories.service';
import { DEFAULT_PRINCIPAL_CATEGORY } from '../../constants/looker.constants';
import { FeatureFlagsService } from '../../services/feature-flags.service';
import { FiltersService } from '../save-filter/filters.service';

@Component({
  selector: 'app-looker-dashboard-filter',
  templateUrl: './looker-dashboard-filter.component.html',
  styleUrls: ['../data-table-filter/data-table-filter-analytics/data-table-filter-analytics.component.scss'],
  providers: [
    { provide: 'supplierFeaturesService', useClass: FeaturesService },
    { provide: 'brandFeaturesService', useClass: FeaturesService }
  ]
})

export class LookerDashboardFilterComponent implements OnInit {
  nielsenTaxonomies: ProductTaxonomy[];
  subs$: Subscription[] = [];
  errors: string[] = [];
  form: UntypedFormGroup;
  nielsenFilters: QuestionBase<any>[];
  nielsenDefaultFilters: QuestionBase<any>[];
  globalFilters: QuestionBase<any>[];
  globalDefaultFilters: QuestionBase<any>[];
  company: CurrentCompany;
  currencySymbol: string;
  isCollapsed = false;
  principalCategorization: string;
  flags = this.featureFlags.flags;

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChildren(SingleInputComponent) singleInputs: QueryList<SingleInputComponent>;

  @Input() slug: string;
  @Input() cfg: FilterConfiguration = {};
  @Input() questions: QuestionBase<any>[] = [];

  constructor(
    @Inject('supplierFeaturesService') private supplierFeaturesService: FeaturesService,
    @Inject('brandFeaturesService') private brandFeaturesService: FeaturesService,
    private translate: TranslateService,
    private productTaxonomiesService: FeatureTaxonomiesService,
    private confirmationService: ConfirmationService,
    private qcs: QuestionControlService,
    private dateService: DateService,
    private salesService: SalesService,
    private profileService: ProfileService,
    private locationsService: LocationsService,
    private filterService: DataTableFilterService,
    private lookerNielsenFiltersService: LookerNielsenFiltersService,
    private lookerGlobalFiltersService: LookerGlobalFiltersService,
    private changeDetector: ChangeDetectorRef,
    private segmentCategoriesService: SegmentCategoriesService,
    private featureFlags: FeatureFlagsService,
    private filtersService: FiltersService
  ) {}

  ngOnInit(): void {
    this.company = new CurrentCompany(this.profileService.getProfileCompany());
    this.currencySymbol = getCurrencySymbol(this.company.currency, 'wide');
    this.principalCategorization = this.profileService.getStoredUser().company.plans_configuration?.principal_categorization || DEFAULT_PRINCIPAL_CATEGORY;
    this.getNielsenTaxonomies();
  }

  ngOnDestroy(): void {
    this.brandFeaturesService.setTaxonomies(null);
    this.segmentCategoriesService.onlyCategoriesWithSegments = false;
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  onNielsenFilterChange(filter: QuestionBase<any>): void {

    if (!this.nielsenTaxonomies[filter.order]) {
      return;
    }

    const nextFilter = this.nielsenFilters.find(_filter => _filter.order === filter.order + 1);
    const nextFilters = this.nielsenFilters.filter(_filter => _filter.order > filter.order);
    const parentFeaturePks = filter.value && filter.value.length > 0 ? filter.value.map(val => val.rawElement.pk).join(',') : null;
    const filters = {taxonomy_slug: this.nielsenTaxonomies[filter.order].slug};

    if (parentFeaturePks) {
      filters['parent_feature_pks'] = parentFeaturePks;
    }

    this.nielsenTaxonomies[filter.order].filterOpts = {preventInitialPayload: false};
    this.nielsenTaxonomies[filter.order].filters = filters;

    const filterValue = this.form.get(filter.key).value;
    if (filterValue && filterValue.length > 0 && nextFilter) {
      nextFilter.disabled = false;
      const nextSingleInp = this.singleInputs.find((_, index) => index === filter.order);
      setTimeout(() => nextSingleInp.refreshOptions());
    } else if ((!filterValue || filterValue.length === 0) && nextFilter) {
      nextFilters.forEach(filter => {
        filter.disabled = true;
        filter.options = [];
        filter.value = [];
        this.form.get(filter.key).setValue([]);
      });
    }
  }

  onSubmit(): void {
    this.isCollapsed = true;
    const dateFromValue = this.form.value['date_from'];
    const dateToValue = this.form.value['date_to'];
    const parseDateTo = this.getParseDateTo('date_to');

    if (dateFromValue && dateToValue && !this.compareDates(this.form.value, 'date_from', 'date_to')) {
      return;
    }

    let calculatedComparedDates = null;
    if (this.form.controls['date_from_compared'] && this.form.controls['date_to_compared']) {
      const dateFromComparedValue = this.form.value['date_from_compared'];
      const dateToComparedValue = this.form.value['date_to_compared'];
      const parseDateToCompared = this.getParseDateTo('date_to_compared');

      if (dateFromComparedValue && dateToComparedValue && !this.compareDates(this.form.value, 'date_from_compared', 'date_to_compared')) {
        return;
      }

      const dateFromCompared = this.dateService.parseUTCDateWithFormat(this.form.value.date_from_compared, 'YYYY/MM/DD');
      calculatedComparedDates = `${dateFromCompared} to ${parseDateToCompared}`;
    }

    const payload = {};
    const dateFrom = this.dateService.parseUTCDateWithFormat(this.form.value.date_from, 'YYYY/MM/DD');
    const calculatedDates = `${dateFrom} to ${parseDateTo}`;
    const higherFeaturePk = this.getHigherFeatureLevelPks();
    const brand = this.form.value.brand && this.form.value.brand.length > 0 ? this.form.value.brand[0].id : null;
    const brandPk = this.getMultiselectedData('brand_pk');
    const supplierPk = this.form.value.supplier && this.form.value.supplier.length > 0 ? this.form.value.supplier[0].id : null;
    const unitMeasure = this.getUnitMeasure();
    const measureType = this.form.value.param_measure_type && this.form.value.param_measure_type.length > 0 ? this.form.value.param_measure_type[0].id : null;
    const locationCategories = this.getMultiselectedData('location_taxonomy_term_ids');
    const locations = this.getMultiselectedData('location_ids');
    const tickets = this.form.value.tickets && this.form.value.tickets.length > 0 ? this.form.value.tickets[0].id : null;
    const viewBrandSupplier = this.form.value.view_brand_supplier && this.form.value.view_brand_supplier.length > 0 ? this.form.value.view_brand_supplier[0].id : null;
    const topBrandSupplier = this.form.value.top_brand_supplier && this.form.value.top_brand_supplier.length > 0 ? this.form.value.top_brand_supplier[0].id : null;
    const chartMetric = this.form.value.chart_metric && this.form.value.chart_metric.length > 0 ? this.form.value.chart_metric[0].id : null;
    const dayOfWeek = this.getMultiselectedData('day_of_week');
    const chartGranularity =  this.form.value.chart_granularity && this.form.value.chart_granularity.length > 0 ? this.form.value.chart_granularity[0].id : null;
    const country = this.getMultiselectedData('country');
    const chartMetricas = this.form.value.chart_metricas && this.form.value.chart_metricas.length > 0 ? this.form.value.chart_metricas[0].id : null;
    const onlyComparableStores = this.form.value.only_comparable_stores ? '1' : null;
    const chartMetricsWithOwnBrand = this.form.value.chart_metrics_with_own_brand && this.form.value.chart_metrics_with_own_brand.length > 0 ? this.form.value.chart_metrics_with_own_brand[0].id : null;
    const segmentCategoryId = this.form.value.segment_category_id && this.form.value.segment_category_id.length > 0 ? this.form.value.segment_category_id[0].id.toString() : null;
    const segmentId = this.form.value.segment_id && this.form.value.segment_id.length > 0 ? this.form.value.segment_id[0].id.toString() : null;
    const supplierBrand = this.form.value.supplier_brand && this.form.value.supplier_brand.length > 0 ? this.form.value.supplier_brand[0].id : null;
    const chartMetricSupplierBrand = this.form.value.chart_metric_supplier_brand && this.form.value.chart_metric_supplier_brand.length > 0 ? this.form.value.chart_metric_supplier_brand[0].id : null;
    const idEanProduct = this.form.value?.id_ean_product?.[0]?.id ?? null;

    payload['Periodo'] = calculatedDates;
    payload['Periodo Comparado'] = calculatedComparedDates;
    if (higherFeaturePk) { payload['Feature Pk'] = higherFeaturePk; }
    payload['Marca'] = brand;
    payload['Brand Pk'] = brandPk;
    payload['Supplier Pk'] = supplierPk;
    if (unitMeasure) { payload['unit_measure'] = unitMeasure; }
    if (measureType) { payload['Metric Selector ME'] = measureType; }
    payload['Location ID'] = locations ? locations : null;
    payload['Tickets'] = tickets && tickets !== 'null' ? tickets : null;
    payload['Proveedor/Marca'] = supplierBrand;
    payload['View By Brand/Supplier'] = viewBrandSupplier;
    payload['Top Brands/Supplier'] = topBrandSupplier;
    payload['Selector Metrica'] = chartMetric;
    payload['Selector Metrica SB'] = chartMetricSupplierBrand;
    payload['Day of Week'] = dayOfWeek;
    payload['Date Granularity'] = chartGranularity;
    payload['Country'] = country;
    payload['Metricas'] = chartMetricas;
    payload['Homogeneous Location'] = onlyComparableStores;
    payload['Homogeneous Location Parameter'] = onlyComparableStores;
    payload['Metric Selector 7'] = chartMetricsWithOwnBrand;
    payload['Segment Category ID'] = segmentCategoryId;
    payload['Segment'] = segmentId;
    payload['In Segment'] = segmentId ? 'Yes' : null;
    payload['ID/EAN Product'] = idEanProduct;

    if (locationCategories && !locations) {
      this.locationsService.fetchMultiselect().pipe(takeUntil(this.destroy$)).subscribe(data => {
        const locationsData = data['list'].reduce((string, item, index) => string + `${index ? ',' : ''}${item.id}`, '');
        payload['Location ID'] = locationsData ? locationsData : null;
        this.setAndApplySalesServiceFilters(payload);
      });

    } else {
      this.setAndApplySalesServiceFilters(payload);
    }
  }

  resetAllFilters(): void {
    this.nielsenFilters = this.cloneFilters(this.nielsenDefaultFilters);
    this.globalFilters = this.cloneFilters(this.globalDefaultFilters);

    this.form = null;
    this.changeDetector.detectChanges();

    [...this.nielsenFilters, ...this.globalFilters].forEach(q => {
      if (q.dataSource && q.dataSource.refreshSelectedOptsSource instanceof Function) {
        q.dataSource.refreshSelectedOptsSource([], q.key);
      }
    });

    this.handleSetForm();

    this.nielsenFilters.forEach(nf => {
      this.form.get(nf.key).patchValue(nf.value);
      this.onNielsenFilterChange(nf);
    });

    this.globalFilters.forEach(gf => {
      this.form.get(gf.key).patchValue(gf.value);
    });
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.globalFilters, inputKey);
  }

  handleFilterSelectedValues(values: unknown) {
    const questions = [...this.globalFilters, ...this.nielsenFilters];
    this.form.reset();
    this.filtersService.assignValues(this.form, questions, values);
  }

  handleLocationCategoriesValueChanges(locationTaxonomyTermIds): void {

    const locationInput = this.getInputConfig('location_ids');
    locationInput.selectedIds = [];

    setTimeout(() => {
      if (locationTaxonomyTermIds?.length > 0) {
        this.form.patchValue({location_ids: []}, {emitEvent: false});
        const locationCategoriesValues = locationTaxonomyTermIds.map(locationCategory => locationCategory.id).join(',');
        this.locationsService.setLocationTaxonomyTermIds(locationCategoriesValues);
      } else {
        this.locationsService.setLocationTaxonomyTermIds(null);
      }
    });
  }

  private cloneFilters(filters: QuestionBase<any>[]): QuestionBase<any>[] {
    return filters.map(fltr => Object.assign({}, fltr));
  }

  private setAndApplySalesServiceFilters(payload: object): void {
    this.salesService.filters = {...payload};
    this.salesService.applyFilters$.next({...payload});
  }

  private getParseDateTo(dateToControlName: string): string {
    if (this.cfg && this.cfg.addExtraDayToSelectorDate) {
      return this.dateService.calculateUTCDate('add', 1, 'days', this.form.value[dateToControlName], 'YYYY/MM/DD');
    } else {
      return this.dateService.parseUTCDateWithFormat(this.form.value[dateToControlName], 'YYYY/MM/DD');
    }
  }

  private compareDates(filterFormValue: object, dateFromControlName: string, dateToControlName: string): boolean {
    if (moment(filterFormValue[dateFromControlName]).isAfter(filterFormValue[dateToControlName])) {
      this.alertDatesMessage(false);
      return false;
    }
    if (moment(filterFormValue[dateToControlName]).diff(filterFormValue[dateFromControlName], 'days') > 365) {
      this.alertDatesMessage(true);
      return false;
    }
    return true;
  }

  private alertDatesMessage(isBiggerYearAlert: boolean): void {
    const warningText = isBiggerYearAlert ? this.translate.instant('components.data-table.errors.year_bigger') : this.translate.instant('components.data-table.errors.date_bigger_than');
    this.confirmationService.displayAlert(
      this.translate.instant('components.data-table.errors.title_error_date'),
      warningText,
      'warning').catch(() => {});
  }

  private getHigherFeatureLevelPks(): string {
    const formValues = this.form.value;
    const nielsenSlugs = ['product_segment', 'sub_family', 'family', 'categorization', 'section', 'sector'];
    for (let index = 0; index < nielsenSlugs.length; index++) {
      if (formValues[nielsenSlugs[index]] && formValues[nielsenSlugs[index]].length > 0) {
        return formValues[nielsenSlugs[index]].map(el => el.rawElement.pk).join(',');
      }
    }
  }

  private getUnitMeasure(): string {
    let unitMeasure = null;
    if (this.form.value.unit_measure && this.form.value.unit_measure.length > 0) {
      unitMeasure = this.form.value.unit_measure[0].id;
    } else if (this.form.value.unit_measure_quantity && this.form.value.unit_measure_quantity.length > 0) {
      unitMeasure = this.form.value.unit_measure_quantity[0].id;
    }
    return unitMeasure;
  }

  private getMultiselectedData(field: string): string {
    let multiselectedData = null;
    if (this.form.value[field] && this.form.value[field].length > 0) {
      multiselectedData = this.form.value[field].map(el => {
        return el.id.toString();
      }).join(',');
    }
    return multiselectedData;
  }

  private getNielsenTaxonomies(): void {
    const nielsenTaxonomies$ = this.productTaxonomiesService.fetchMultiselect(null, null, {kind: this.principalCategorization}).subscribe(
      (response: HttpResponse<object>) => this.evaluateTaxonomies(response),
      (err: HttpErrorResponse) => this.handleError(err.error.error)
    );
    this.subs$.push(nielsenTaxonomies$);
  }

  private getTaxonomies(): void {
    const supplierTaxonomies$ = this.productTaxonomiesService.fetchMultiselect(null, null, {kind: 'supplier'});
    const brandTaxonomies$ = this.productTaxonomiesService.fetchMultiselect(null, null, {kind: 'brand'});
    const taxonomies$ = combineLatest([supplierTaxonomies$, brandTaxonomies$]).pipe(take(1)).subscribe(
      ([supplierTaxonomies, brandTaxonomies]) => {
        this.nielsenFilters = this.lookerNielsenFiltersService.getNielsenFilters();
        this.globalFilters = this.lookerGlobalFiltersService.getGlobalFilters(
          this.supplierFeaturesService, this.brandFeaturesService, this.cfg, this.currencySymbol, supplierTaxonomies, brandTaxonomies
        );

        this.nielsenDefaultFilters = this.cloneFilters(this.nielsenFilters);
        this.globalDefaultFilters = this.cloneFilters(this.globalFilters);

        this.handleSetForm();
        this.resetAllFilters();
      },
      (err: HttpErrorResponse) => this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), err.error.error)
    );
    this.subs$.push(taxonomies$);
  }

  private handleSetForm(): void {
    this.form = this.qcs.toFormGroup([...this.globalFilters, ...this.nielsenFilters]);
    this.setFormSubscriptions();
    this.filterService.filtersLoaderStatus.next(true);
  }

  private evaluateTaxonomies(apiTaxonomies: object): void {
    if (!apiTaxonomies['list']) {
      this.errors.push('Feature taxonomies with kind Nielsen not found in curreny company.');
    }

    if (apiTaxonomies['list'] && apiTaxonomies['list'].filter(el => !!el.level).length !== 6) {
      this.errors.push('Probable data misconfiguration: Nielsen taxonomies should be 6 in total and only 6.');
    }

    this.nielsenTaxonomies = this.setNielsenTaxonomies(apiTaxonomies);

    const taxonomiesLevelMap = this.nielsenTaxonomies.map(el => el.level);
    if (!areArraysEquals(taxonomiesLevelMap, [1, 2, 3, 4, 5, 6])) {
      this.errors.push('Probable data misconfiguration: Nielsen taxonomies should be 1 and only 1 taxonomy per each Nielsen level.');
    }

    if (this.errors.length === 0) {
      this.getTaxonomies();
    }
  }

  private setNielsenTaxonomies(apiTaxonomies: object): ProductTaxonomy[] {
    const taxonomies = apiTaxonomies['list'].filter(el => !!el.level).sort((tax1: object, tax2: object) => tax1['level'] >= tax2['level'] ? 1 : -1);
    taxonomies.forEach((el: ProductTaxonomy, i: number) => {
      el.filterOpts = {preventInitialRequest: true};
      el.filters = {taxonomy_slug: el.slug};
      if (i === 0) {
        el.filterOpts = {
          preventInitialRequest: false
        };
      }
    });
    return taxonomies;
  }

  private handleError(errorMsg: string): void {
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorMsg);
  }

  private setFormSubscriptions(): void {
    this.handleComparableDatesValueChanges();
  }

  private handleComparableDatesValueChanges(): void {
    if (this.cfg && this.cfg.comparableDatesFilters) {
      const compareWithValue = this.form.get('compare_with').value;
      this.getInputConfig('date_from_compared').hidden = !compareWithValue;
      this.getInputConfig('date_to_compared').hidden = !compareWithValue;

      this.form.get('date_from').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((dateFrom) => {
        if (!this.form.get('compare_with').value) {
          this.form.get('date_from_compared').patchValue(this.dateService.calculateDate('substract', 1, 'year', dateFrom));
        }
      });

      this.form.get('date_to').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((dateTo) => {
        if (!this.form.get('compare_with').value) {
          this.form.get('date_to_compared').patchValue(this.dateService.calculateDate('substract', 1, 'year', dateTo));
        }
      });

      this.form.get('compare_with').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((compareWith) => {
        this.getInputConfig('date_from_compared').hidden = !compareWith;
        this.getInputConfig('date_to_compared').hidden = !compareWith;

        const dateFrom = this.form.get('date_from').value;
        const dateTo = this.form.get('date_to').value;

        this.form.get('date_from_compared').patchValue(this.dateService.calculateDate('substract', 1, 'year', dateFrom));
        this.form.get('date_to_compared').patchValue(this.dateService.calculateDate('substract', 1, 'year', dateTo));
      });
    }
  }
}