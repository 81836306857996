import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { getNameLangInputs } from '../../../resources/data-warehouse/data-warehouse-coupons/form-coupons/coupon-utils/coupon-utils';
import { QuestionBase } from '../../models/forms/question-base';
import { FeatureFlagsService } from '../../services/feature-flags.service';
import { FrogedService } from '../../services/froged/froged.service';
import { LanguagesService } from '../../services/languages.service';
import { QuestionControlService } from '../../services/question-control.service';

@Component({
  selector: 'app-language-accordions',
  templateUrl: './language-accordions.component.html',
  styleUrls: ['./language-accordions.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LanguageAccordionsComponent {

  @Input() inputs: QuestionBase<any>[];
  @Input() form: UntypedFormGroup;
  @Input() mode: string;
  @Input() set filteredAvailableLanguages(value) {
    if (value) {
      this._filteredAvailableLanguages = value;
      this.initialLanguages();
    }
  }
  get filteredAvailableLanguages() {
    return this._filteredAvailableLanguages;
  }

  _filteredAvailableLanguages: any;
  parsedLangKeys: any[];
  flags = this.featureFlags.flags;

  constructor(
    private featureFlags: FeatureFlagsService,
    private frogedService: FrogedService,
    private languagesService: LanguagesService,
    private qcs: QuestionControlService,
    private router: Router
  ) {}

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  handleLanguageSelection(langOptions) {
    let langIds = this.parsedLangKeys = [];
    if (langOptions?.length) {
      langIds = langOptions.map(item => item.id);
      this.parseLanguageSelection(langIds);
    }
    this.handleFormSelection(langOptions);

    // FROGED event
    const url = this.router.url;
    if (url === '/offers-personalization/coupons(modal:new/coupon)') {
      this.frogedService.track('OFFERS_coupons_newCoupon_languages', { languages: String(langIds) });
    }
  }

  private parseLanguageSelection(langOptions) {
    this.languagesService.getLocalizedLanguages().forEach(item => {
      if (langOptions.includes(item.id)) {
        const newObject = {
          lang: item.id,
          label: item.name,
        };
        getNameLangInputs(item.id, null, this.qcs, this.form, this.inputs, this.mode);
        this.parsedLangKeys.push(newObject);
      }
    });
  }

  private handleFormSelection(langOptions) {
    const parsedFormName = Object.keys(this.form.value).filter(item => /name_/.test(item)).map(element => element.split('_')[1]);
    const comparedOptions = langOptions.map(item => item.id);
    const keysToRemove = parsedFormName.filter((item) => !comparedOptions.includes(item));

    keysToRemove.forEach(key => {
      this.form.removeControl(`name_${key}`);
      this.form.removeControl(`description_${key}`);
    });
  }

  private initialLanguages() {
    this.parsedLangKeys = [];
    const parsedLanguageId = this.mode === 'update' || this.mode === 'show' ? this._filteredAvailableLanguages : this._filteredAvailableLanguages.map(x => x.id);
    this.parseLanguageSelection(parsedLanguageId);
  }

}
