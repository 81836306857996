import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from './condition.service';
import { QuestionBase } from '../../models/forms/question-base';
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectQuestion } from '../../models/forms/question-multiselect';
import { CollectivesService } from '../../../resources/data-warehouse/profiles/collectives.service';
import { multiselectPresenceValidator, formGroupEmptyValidator } from '../validations.service';

@Injectable()
export class CollectiveConditionService implements SegmentConditionProvider {

  public inputs: QuestionBase<any>[];
  public customValidators = formGroupEmptyValidator();

  constructor( private translate: TranslateService,
               private collectiveService: CollectivesService ) { }

  public getInputs( params: any ): QuestionBase<any>[] {

    const inputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'operator',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.getOperatorsValues(),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        getValue: (value) => (value && value.length) ? value : null,
        parseValue: (value) => {
          return (value && value.length) ? this.getOperatorsValues().find( operator => operator.id === value[0].id ).id : null;
        },
        customValidators: [multiselectPresenceValidator]
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-default',
        key: 'values',
        label: this.translate.instant('resources.segment_conditions.fields.collective_ids'),
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.collectiveService,
        required: true,
        getValue: (value) => value ? ({selectedIds: value}) : ({selectedIds: []}),
        parseValue: (values) => ((values && values.length) ? values.map( value => value.id ) : []),
        customValidators: [multiselectPresenceValidator]
      }),
    ];

    if ( params ) {
      Object.keys(params).forEach( key => {
        const input = inputs.find( _input => _input.key === key );
        const value = input.getValue(params[key]);
        if ( value && value.hasOwnProperty('selectedIds')) {
          input.selectedIds = value.selectedIds;
        } else {
          input.value = value;
        }
      });
    }

    this.inputs = inputs;
    return this.inputs;
  }

  public prepareFormValuesToAPI( params: any ): any {
    const parsedValuesObj = {};
    Object.keys(params).forEach( key => {
      const input = this.inputs.find( _input => _input.key === key );
      const parsedValue = input.parseValue( params[key] );
      parsedValuesObj[key] = parsedValue;
    });
    return parsedValuesObj;
  }

  public prepareFormValuesFromAPI( params: any ): any {
    const parseValues = {
      operator: (value) => (value && value.length) ? [this.getOperatorsValues().find( operator => operator.id === value )] : null,
      values: (value) => (value && value.length) ? value : null
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach( key => { parsedValuesObj[key] = parseValues[key](params[key]); });
    return parsedValuesObj;
  }

  private getOperatorsValues(): {id: string, name: string}[] {
    return [
      { id: 'in', name: this.translate.instant('resources.segment_conditions.operators.in')},
      { id: 'not_in', name: this.translate.instant('resources.segment_conditions.operators.not_in')}
    ];
  }
}
