<a #switchCouponsModal
    data-toggle="modal"
    data-target="#switch-coupons-modal"
    data-backdrop="false"
    data-keyboard="false"
    hidden>
</a>

<form [formGroup]="form" *ngIf="form">
  <div class="modal fade show" id="switch-coupons-modal">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">

        <div class="modal-header">
          <h4 class="mrg-btm-5 first-letter-upper">
            {{'resources.plans.messages.title_switch_coupons' | translate}}
          </h4>
          <button class="modal-close" (click)="closeModal()">
            <i class="ti-close"></i>
          </button>
        </div>

        <div class="modal-body background-gray">
          <div class="row">
            <div class="col-md-12">
              <p [translate]="'resources.plans.messages.desc_switch_coupons'"></p>
              <app-question [question]="inputs[0]" [form]="form"></app-question>
            </div>
          </div>
        </div>

        <div class="modal-footer background-white">
          <div class="pull-right d-flex align-items-center">
            <button class="pull-right btn btn-info"
                    (click)="save()"
                    [translate]="'common.buttons.confirm'"
                    [disabled]="form.invalid">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
