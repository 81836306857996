import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from './condition.service';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBase } from '../../models/forms/question-base';
import { MultiSelectQuestion } from '../../models/forms/question-multiselect';
import { TextboxQuestion } from '../../models/forms/question-textbox';
import { formGroupEmptyValidator, multiselectPresenceValidator } from '../validations.service';

@Injectable()
export class ClientEmailConditionService implements SegmentConditionProvider {

  public inputs: QuestionBase<any>[];
  public customValidators = formGroupEmptyValidator();

  constructor( private translate: TranslateService ) { }

  public getInputs( params: any ): QuestionBase<any>[] {
    const inputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'operator',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.getOperatorsValues(),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        getValue: (value) => (value && value.length) ? value : null,
        parseValue: (value) => {
          return (value && value.length) ? this.getOperatorsValues().find( operator => operator.id === value[0].id ).id : null;
        },
        customValidators: [multiselectPresenceValidator]
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'value',
        label: this.translate.instant('resources.segment_conditions.fields.email'),
        type: 'text',
        required: true,
        getValue: (value) => value,
        parseValue: (value) => value
      }),
    ];

    if ( params ) {
      Object.keys(params).forEach( key => {
        const input = inputs.find( _input => _input.key === key );
        const value = input.getValue(params[key]);
        input.value = value;
      });
    }

    this.inputs = inputs;
    return this.inputs;
  }

  public prepareFormValuesToAPI( params: any ): any {
    const parsedValuesObj = {};
    Object.keys(params).forEach( key => {
      const input = this.inputs.find( _input => _input.key === key );
      const parsedValue = input.parseValue( params[key] );
      parsedValuesObj[key] = parsedValue;
    });
    return parsedValuesObj;
  }

  public prepareFormValuesFromAPI( params: any ): any {
    const parseValues = {
      operator: (value) => (value && value.length) ? [this.getOperatorsValues().find( operator => operator.id === value )] : null,
      value: (value) => (value && value.length) ? value : null
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach( key => { parsedValuesObj[key] = parseValues[key](params[key]); });
    return parsedValuesObj;
  }

  private getOperatorsValues(): {id: string, name: string}[] {
    return [
      { id: 'eq', name: this.translate.instant('resources.segment_conditions.operators.eq')},
      { id: 'not_eq', name: this.translate.instant('resources.segment_conditions.operators.not_eq')},
      { id: 'contains', name: this.translate.instant('resources.segment_conditions.operators.contains')},
      { id: 'starts_with', name: this.translate.instant('resources.segment_conditions.operators.starts_with')},
      { id: 'ends_with', name: this.translate.instant('resources.segment_conditions.operators.ends_with')}
    ];
  }
}
