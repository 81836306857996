import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportsService } from '../reports.service';
import { ActivatedRoute, Router } from '@angular/router';
import { registerLocaleData, PercentPipe } from '@angular/common';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import es from '@angular/common/locales/es';
import { ShowReportData } from '../report.model';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';

@Component({
  selector: 'app-show-reports',
  templateUrl: './show-reports.component.html',
  styleUrls: ['./show-reports.component.css'],
  providers: [ReportsService, PercentPipe]
})
export class ShowReportsComponent implements OnInit, OnDestroy {

  id: number;
  element: ShowReportData;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private reportService: ReportsService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private modalStatusSrv: ModalStatusService
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.getParams();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getReportById() {
    this.reportService.getReportById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (resp: ShowReportData) => {
        this.element = resp;
        this.setTotalRatio();
      },
      error: (error: HttpErrorResponse) => {
        this.confirmationService.displayHttpErrorAlert(error);
        this.closeModal();
      }
    });
  }

  getParams() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe({
      next: params => {
        if (params.id) {
          this.id = params.id;
          this.getReportById();
        }
      }
    });
  }

  getType() {
    return this.element['report_details'] ? this.element['report_details'][0].type : null;
  }

  setTotalRatio() {
    if (this.element['total_ratio'] === null) {
      this.element['total_ratio'] = 0;
    }
  }

  generateReport() {
    const reportData = {};
    const generateTitle = this.translate.instant('resources.reports.warnings.generate_title');
    const generateText = this.translate.instant('resources.reports.warnings.generate_text');

    this.confirmationService.displayConfirmationAlert(generateTitle, generateText).then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.reportService.generateReport(reportData, this.id).pipe(takeUntil(this.destroy$)).subscribe({
          next: () => {
            const generate_confirmation_title = this.translate.instant('resources.reports.warnings.generate_confirmation_title');
            const generate_confirmation_text = this.translate.instant('resources.reports.warnings.generate_confirmation_text');
            this.confirmationService.displaySuccessAlert(generate_confirmation_title, generate_confirmation_text).catch(() => {});
            this.modalStatusSrv.modalStatus.emit();
            this.closeModal();
          },
          error: (error: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(error)
        });
      }
    }).catch(() => {});
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  isNotSpecificReportType(): boolean {
    const reportDetail = this.element?.report_details[0];
    return reportDetail?.type !== 'ReportRoiByStore' && reportDetail?.type !== 'ReportCouponsAppAnalysis';
  }
}
