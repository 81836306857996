import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormGroup } from '@angular/forms';
import { DictionaryService } from '../../services/dictionary.service';
import * as moment from 'moment-timezone';
import { Calendar } from 'primeng/calendar';
import { FeatureFlagsService } from '../../services/feature-flags.service';

@Component({
  selector: 'app-dynamic-date-input',
  templateUrl: './dynamic-date-input.component.html',
  styleUrls: ['../../../../assets/scss/plugins/_datepicker.scss', './dynamic-date-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DynamicDateInputComponent),
    },
    DictionaryService
  ]
})

/**
 * Specific dynamic date input component for Loyal Guru:
 * > Calendar date picker
 * > Dropdown with given values parsed by API to stablish dynamic dates
 * For more details about ControlValueAccesor implementation please refer to:
 * https://stackoverflow.com/questions/45659742/angular4-no-value-accessor-for-form-control/45659791
 */
export class DynamicDateInputComponent implements OnInit, ControlValueAccessor {

  dynamicDateValues: { id: string, name: string, separatorAfter?: boolean, timeFrame?: string }[];
  maxDate: Date;
  minDate: Date;
  flags = this.featureFlags.flags;

  @Input() form: UntypedFormGroup;
  @Input() formControlName: string;
  @Input() id: string;
  @Input() hidden: boolean;
  @Input() value: { id: string, name: string };
  @Input() class: string;
  @Input() required: boolean;
  @Input() doDisable: boolean;
  @Input() placeholder: string;
  @Input() hideNoDateOpt = false;
  @Input() hideOverlappingOpts: boolean;
  @Input() mode: 'only_past' | null;

  constructor(
    private dictionary: DictionaryService,
    private featureFlags: FeatureFlagsService,
  ) {
    this.dynamicDateValues = this.dictionary.getValuesByKey('dynamic_dates');
  }

  ngOnInit() {
    if (this.hideOverlappingOpts) {
      this.addSeparator();
      this.dynamicDateValues = this.filteredKeys();
    }

    if (this.mode === 'only_past') {
      this.dynamicDateValues = this.dynamicDateValues.filter(opt => opt.timeFrame === 'past');
      const todayDate = new Date();
      this.minDate = new Date(todayDate.getFullYear() - 1, todayDate.getMonth(), todayDate.getDate());
      this.maxDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 1);
    }

     /**
     * Filter dynamicDates by removing option with id: null
     */
     if (this.hideNoDateOpt) {
      this.dynamicDateValues = this.dynamicDateValues.filter(opt => opt.id);
    }

    /**
     * Add new separator columns for minutes and days regarding a feature flag value
     */
    if (this.flags.showMinutesInDynamicDates) {
      this.dynamicDateValues = [...this.dictionary.getValuesByKey('dynamic_dates_minutes'), ...this.dynamicDateValues];
    }
  }

  /**
   * Tells Angular how to write value from model into view
   * Using setValue method to patch form values
   * Has to be implemented when using ControlValueAccessor
   */
  writeValue() { }

  /**
   * Registers a handler function that is called when the view changes
   * Has to be implemented when using ControlValueAccessor
   */
  registerOnChange() { }

  /**
   * Registers a handler to be called when the component receives a touch event,
   * useful for knowing if the component has been focused
   * Has to be implemented when using ControlValueAccessor
   */
  registerOnTouched() { }

  /**
   * Registers a handler to be called when the component receives a touch event,
   * useful for knowing if the component has been focused
   */
  onDateSelected(event: Date) {
    const date = moment.tz(event, 'UTC');
    const timeZone = moment.tz.guess();
    const localDate = date.clone().tz(timeZone);

    this.setValue({
      id: localDate.format('YYYY-MM-DD'),
      name: localDate.format('DD/MM/YYYY')
    });
  }

  setValue(value: { id: string, name: string }) {
    this.value = { id: value.id, name: value.name };
    const formValue = {};
    formValue[this.id] = this.value;
    this.form.patchValue(formValue);
  }

  toggleCalendar(event: Event, calendar: Calendar): void {

    const targetClassList = event.target['classList'];

    if (targetClassList.contains('btn-calendar') || targetClassList.contains('ei-calendar')) {

      const element = calendar.inputfieldViewChild.nativeElement;

      // This is a workaround to avoid the calendar to be closed when scrolling the page.
      calendar.scrollHandler = {
        bindScrollListener: () => { },
        unbindScrollListener: () => { },
        destroy: () => { },
        element,
        listener: () => { },
        scrollableParents: []
      };

      const button = element.nextElementSibling as HTMLButtonElement;
      button.click();
    }
  }

  private addSeparator(): void {
    const keyIndex = this.dynamicDateValues.findIndex(opt => opt.id === '%366_days_ago%');
    this.dynamicDateValues[keyIndex - 1].separatorAfter = true;
  }

  private filteredKeys(): { id: string, name: string, separatorAfter?: boolean }[] {
    const overlappingOptKeys = ['%31_days_ago%', '%91_days_ago%', '%181_days_ago%', '%366_days_ago%'];
    return this.dynamicDateValues.filter(dateVal => !overlappingOptKeys.includes(dateVal.id));
  }
}
