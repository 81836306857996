import { Injectable } from '@angular/core';

@Injectable()
export class RolesService {

  constructor() {}

  public isAuthorized = function(permittedRoles: string[], currentRole: Role): boolean {
    if (!permittedRoles) { return true };
    return (permittedRoles.indexOf(currentRole.slug) >= 0);
  };
  
  public isNotAuthorized = function(bannedRoles: string[], currentRole: Role): boolean {
    if (!bannedRoles){ return false; }
    return (bannedRoles.indexOf(currentRole.slug) >= 0);
  };

  public hasPermission = function(state: string, action: string, currentRole: Role): boolean{
    const selectedPermission = state + '@' + action;
    const permissions = currentRole.permissions;
    return permissions.includes(selectedPermission);
  };
}

export class Role {

  public slug: string;
  public permissions: string[];

  constructor (options) {
    this.slug = options.slug;
    this.permissions = options.permissions;
  }
}
