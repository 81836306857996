import { Component, Input } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../services/question-control.service';

@Component({
  selector: 'app-available-score-segment-condition',
  templateUrl: './available-score-segment-condition.component.html',
  styleUrls: ['./available-score-segment-condition.component.css']
})
export class AvailableScoreSegmentConditionComponent {

  @Input() item: Condition;
  @Input() inputs: QuestionBase<unknown>[] = [];
  @Input() form: UntypedFormGroup;

  constructor( private qcs: QuestionControlService ) { }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  handleOperatorChanges() {
    this.inputs.find(_input => _input.key === 'value_2').value = null;
    this.form.patchValue({value_2: null}, {emitEvent: true});
  }
}
