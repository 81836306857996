import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomersTypologyRowData, TypologyDataMock } from '../../../../../shared/models/analytics/customers-typology-row-data';
import { CustomersService } from '../../../customers/customers.service';
import { DataTableFilterService } from '../../../../../shared/components/data-table-filter/data-table-filter.service';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { ProfileService } from '../../../../../profiles/profile.service';
import { getCurrencySymbol } from '@angular/common';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';
import { LocationsService } from '../../../../data-warehouse/locations/locations.service';
import { SegmentsService } from '../../../../segments/segments.service';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { DateService } from '../../../../../shared/services/date.service';

@Component({
  selector: 'app-customers-typology',
  templateUrl: './customers-typology.component.html',
  styleUrls: ['./../club-evolution/club-evolution.component.css']
})

export class CustomersTypologyComponent implements OnInit, OnDestroy {

  debug: boolean;
  loading: boolean;
  subs$: Subscription[];
  typologyData: CustomersTypologyRowData[];
  currencySymbol: string;
  filters: QuestionBase<any>[];
  dateFrom: any;
  dateTo: any;
  onSearch: boolean;
  private systemProfileGroups: number[][];

  constructor(
    private customersService: CustomersService,
    private filterService: DataTableFilterService,
    private refreshCacheService: RefreshCacheService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private profileService: ProfileService,
    private locationsService: LocationsService,
    private segmentsService: SegmentsService,
    private dateService: DateService
  ) {
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
    this.debug = false;
    this.loading = false;
    this.subs$ = [];
    this.typologyData = [];
  }

  ngOnInit() {
    this.setDefaultDates()
    this.getsystemProfileCfg();
    this.setFilters();
    this.onSearch = false;
    if (this.systemProfileGroups?.length) {
      const filterChange$ = this.customersService.applyFilters$.subscribe(
        filterValues => {
        this.getCustomersTypologyData(filterValues);
        this.onSearch = true;
      });
      this.subs$.push(filterChange$);
    }
    setTimeout(() => this.filterService.loaderStatus.next(true));  
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach(s$ => s$.unsubscribe()); }
  }

  private setDefaultDates() {
    this.dateFrom = this.dateService.momentStartOfPreviousPeriodTypeAndFormat(1, 'months', 'month', 'YYYY-MM-DD');
    this.dateTo = this.dateService.momentEndOfPreviousPeriodTypeAndFormat(1, 'months', 'month', 'YYYY-MM-DD');

    if (!this.customersService.filters.hasOwnProperty('typology')) {
      this.customersService.filters['typology'] = {};
    }
    this.customersService.filters['typology']['date_from'] = this.dateFrom;
    this.customersService.filters['typology']['date_to'] = this.dateTo;
  }

  private getsystemProfileCfg() {
    const company = this.profileService.getProfileCompany();
    if (!company.hasOwnProperty('system_profile_groups') || !company.system_profile_groups.hasOwnProperty('customer_types')) {
      console.warn('Missing company system profile groups / typology configuration');
      return;
    }
    const customerTypes = company.system_profile_groups.customer_types,
      casualType = customerTypes.find(_type => _type.key === 'casual'),
      sharedType = customerTypes.find(_type => _type.key === 'shared'),
      loyalType = customerTypes.find(_type => _type.key === 'loyal');
    this.systemProfileGroups = [loyalType.range, sharedType.range, casualType.range];
  }

  private getCustomersTypologyData(filterValues) {
    const filters = { ...filterValues };
    if (this.debug) {
      this.loading = true;
      this.filterService.loaderStatus.next(false);
      setTimeout(() => {
        this.loading = false;
        this.filterService.loaderStatus.next(true);
        this.typologyData = this.parseData( TypologyDataMock );
        this.calculateAverages();
      }, 1000 );
    } else {
      const params = { apiEndPoint: 'analytics/customer_type_sales', filtering: filters };
      this.loading = true;
      this.filterService.loaderStatus.next(false);
      const clubEvolution$ = this.refreshCacheService.getExpensiveData(params).subscribe(
        data => {
          if (!this.refreshCacheService.isRequestPending(data)) {
            this.loading = false;
            this.filterService.loaderStatus.next(true);
            this.typologyData = this.parseData(data);
            this.calculateAverages();
            clubEvolution$.unsubscribe();
          }
        },
        errorData => {
          clubEvolution$.unsubscribe();
          this.loading = false;
          this.filterService.loaderStatus.next(true);
          this.typologyData = null;
          this.confirmationService.displayErrorAlert(
            this.translateService.instant('common.error'),
            JSON.stringify(errorData.error.error)
          );
        }
      );
      this.subs$.push(clubEvolution$);
    }
  }

  private parseData(rawData): CustomersTypologyRowData[] {
    const rowData = rawData.map(_rawRowData => new CustomersTypologyRowData(_rawRowData)),
      loyalRow = rowData.find(_row => _row.typologyKey === 'loyal'),
      sharedRow = rowData.find(_row => _row.typologyKey === 'shared'),
      casualRow = rowData.find(_row => _row.typologyKey === 'casual');

    loyalRow.typologySpec = this.translateService.instant(
      'dashboards.club.tabs.typology.messages.x_or_more',
      {num: this.systemProfileGroups[0][0]}
    );
    sharedRow.typologySpec = this.translateService.instant(
      'dashboards.club.tabs.typology.messages.between_x_and_y',
      {num1: this.systemProfileGroups[1][0], num2: this.systemProfileGroups[1][1]}
    );
    casualRow.typologySpec = this.translateService.instant(
      'dashboards.club.tabs.typology.messages.less_than_x',
      {num: this.systemProfileGroups[2][1]}
    );

    return [loyalRow, sharedRow, casualRow];
  }

  private calculateAverages() {
    const totalRow = new CustomersTypologyRowData({});

    totalRow.typologyName = this.translateService.instant('common.total_and_average').toUpperCase();

    totalRow.customersCount = this.sum(this.typologyData, 'customersCount');
    totalRow.activitiesSum = this.sum(this.typologyData, 'activitiesSum');
    totalRow.activitiesCount = this.sum(this.typologyData, 'activitiesCount');

    totalRow.sections = this.getWeightedAverageByKey('sections');
    totalRow.frequency = this.getWeightedAverageByKey('frequency');
    totalRow.eurosByCustomer = this.getWeightedAverageByKey('eurosByCustomer');

    this.typologyData.push(totalRow);
    this.typologyData.forEach(dataRow => dataRow.calculateRowAverages(totalRow));
  }

  private getWeightedAverageByKey(key: string) {
    const totalCustomers = this.sum(this.typologyData, 'customersCount');
    if (totalCustomers === 0) { return 0; }

    const loyal = this.typologyData.find(_row => _row.typologyKey === 'loyal'),
      shared = this.typologyData.find(_row => _row.typologyKey === 'shared'),
      casual = this.typologyData.find(_row => _row.typologyKey === 'casual');

    return  ((loyal[key]*loyal.customersCount) + (shared[key]*shared.customersCount) + (casual[key]*casual.customersCount)) / totalCustomers;
  }

  private sum(ary: any[], key: string): number {
    return ary.reduce((acc, obj) => acc + (obj[key] || 0), 0);
  }

  private setFilters() {
    this.filters = [
      new MultiSelectQuestion({
        key: 'location_ids',
        label: 'dashboards.migrations.repetition.filters.locations',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locationsService,
        order: 1
      }),
      new MultiSelectQuestion({
        key: 'segment_id',
        label: 'dashboards.migrations.repetition.filters.segments',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: true},
        dataSource: this.segmentsService,
        order: 2
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_from',
        label: 'dashboards.migrations.repetition.filters.available_from',
        order: 3,
        type: 'date',
        value: this.dateFrom,
        required: true
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_to',
        label: 'dashboards.migrations.repetition.filters.available_to',
        order: 4,
        type: 'date',
        value: this.dateTo,
        required: true
      })
    ];
  }
}