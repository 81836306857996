import { Component, ElementRef, ViewChild, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CampaignsService } from '../../campaigns.service';
import { DictionaryService } from '../../../../shared/services/dictionary.service';
import { RefreshCacheService } from '../../../../shared/services/refresh-cache.service';
import { Location, registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { DataTableComponent } from '../../../../shared/components/data-table/data-table.component';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { CampaignHistoriesService } from '../../campaign-histories.service';
import { DataTableConfiguration } from '../../../../shared/components/data-table/data-table-cfg';
import { TagHistoriesService } from '../../../../shared/services/tag-histories.service';
import { Subscription } from 'rxjs';
import { zip } from 'rxjs';
import es from '@angular/common/locales/es';
import { FeatureFlagsService } from './../../../../shared/services/feature-flags.service';
import { ProfileService } from '../../../../profiles/profile.service';
import { USERS_PERSONAL_DATA_AUTH } from '../../../../shared/constants/navigation-roles-not-authorized.constants';

// Charts
import { chart } from 'highcharts';
import * as Highcharts from 'highcharts';
import { HighchartsConfigService } from '../../../../shared/services/highcharts-config.service';
import { DateTimeZoneService } from '../../../../shared/services/date-time-zone.service';

@Component({
  selector: 'app-show-campaigns-statistics',
  templateUrl: './show-campaigns-statistics.component.html',
  styleUrls: ['./show-campaigns-statistics.component.css'],
  providers: [RefreshCacheService, HighchartsConfigService, DictionaryService]
})

export class ShowCampaignsStatisticsComponent implements OnInit, OnDestroy {

  @ViewChild('hiddenModalBtn') hiddenModalBtn: ElementRef;
  @ViewChild('customersDataTable') customersDataTable: DataTableComponent;
  @ViewChild('controlGroupDataTable') controlGroupDataTable: DataTableComponent;
  @ViewChild('couponsDataTable') couponsDataTable: DataTableComponent;
  @ViewChild('messagesDataTable') messagesDataTable: DataTableComponent;
  @ViewChild('vouchersChart') vouchersChart: ElementRef;
  @ViewChild('messagesChart') messagesChart: ElementRef;

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  apiEndPoint = 'campaigns';
  routerSubscription: any;

  // Campaign info
  campaign;
  campaignIcon;
  campaignStats;
  campaignStatus;
  chart: Highcharts.Chart;
  controlGroupTagId: number;
  currentApply;
  currentHistory;
  currentTag = 'tag_id';
  currentTagId;
  customersTagId: number;
  hasControlGroup: boolean;
  histories;
  statusLabel;
  voucheable;
  voucheableType;

  // Tabs
  selectedTab = 'customers';

  // Statistics tab
  controlGroupStats: any;
  controlGroupTableConfig: DataTableConfiguration;
  currentRole: string;
  customersFilterConfig;
  customersTableConfig: DataTableConfiguration;
  flags = this.featureFlags.flags;

  // Coupons tab
  campaignCoupon;
  campaignCouponStats;
  couponsHistoryStats;
  couponsTableConfig: DataTableConfiguration;
  couponsTableTitle: string;
  currentCouponsTag;
  graphData = {redeemed: [], printed: []};
  points;

  // Messages tab
  campaignMessage;
  campaignMessageStats;
  currentMessagesTag;
  messagesHistoryStats;
  messagesTableConfig: DataTableConfiguration;
  messagesTableTitle;

  // Histories selector
  inputs: QuestionBase<any>[];
  form: UntypedFormGroup;

  // Subscriptions
  subs$: Subscription[] = [];

  constructor(
    private campaignHistoriesService: CampaignHistoriesService,
    private campaignService: CampaignsService,
    private changeDetector: ChangeDetectorRef,
    private chartsCfgService: HighchartsConfigService,
    private confirmationService: ConfirmationService,
    private dateService: DateTimeZoneService,
    private dictionary: DictionaryService,
    private featureFlags: FeatureFlagsService,
    private location: Location,
    private profileService: ProfileService,
    private qcs: QuestionControlService,
    private refreshCacheService: RefreshCacheService,
    private route: ActivatedRoute,
    private router: Router,
    private tagHistoriesSrv: TagHistoriesService,
    private translateSrv: TranslateService
  ) {
  }

  ngOnInit() {
    registerLocaleData(es);
    this.getParams();
    this.currentRole = this.profileService.getStoredUserRole();
  }

  ngOnDestroy() {
    if ( this.subs$.length > 0 ) { this.subs$.forEach( s$ => s$.unsubscribe() ) };
  }

  handleCloseModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  refreshUrl(tabId: string) {
    this.selectedTab = tabId;
    const baseUrl = this.router.url.split('(modal')[0];
    const modalString = `show/campaigns/${this.campaign.id}/statistics/${tabId}`;
    this.location.go(`${baseUrl}(modal:${modalString})`);
    if (tabId === 'customers') {
      if (this.customersDataTable) {
        this.dataTable = this.customersDataTable;
      } else {
        this.setTableCfg();
      }
      if (!this.controlGroupTableConfig) {
        this.setControlGroupTableCfg();
      }
      this.getCustomersStats();
    } else if (tabId === 'coupons') {
      this.dataTable = this.couponsDataTable;
      this.campaignCoupon = this.campaign['coupons']['0']['id'];
      this.getCouponsStats();
      this.getCouponsHistoryStats();
      this.setCouponsTableCfg();
    } else if (tabId === 'messages') {
      this.dataTable = this.messagesDataTable;
      this.campaignMessage = this.campaign['messages']['0']['id'];
      this.getMessagesStats();
      this.getMessagesHistoryStats();
      this.setMessagesTableCfg();
    }
  }

  statisticsUrlContains(stringId) {
    return this.router.url.indexOf(`/statistics/${stringId}`) > 0;
  }

  loadTag(tagName) {
    this.currentTag = tagName;
    if (tagName === 'email_opens_unique') {
      tagName = 'email_open_tag_id';
    } else if (tagName === 'impacted') {
      tagName = 'tag_id';
    } else {
      tagName += '_tag_id';
    }

    if (this.currentTag === 'not_impacted') {
      this.currentTagId = this.controlGroupTagId;
      const element = document.getElementById('controlGroupTable');
      element.scrollIntoView({
        behavior: 'auto',
        block: 'start',
        inline: 'nearest'
      });
    } else {
      this.currentTagId = this.campaignStatus[tagName];
    }
    this.onFilterHandler({});
  }

  isTagSelected(tagName) {
    if (tagName === 'impacted' && this.currentTag === 'tag_id') { return true; }
    if (tagName === 'not_impacted' && this.currentTag === 'tag_id') { return true; }
    return tagName === this.currentTag;
  }

  isCouponsTagSelected(tagName) {
    return tagName === this.currentCouponsTag;
  }

  isMessagesTagSelected(tagName) {
    return tagName === this.currentMessagesTag;
  }

  filterByHistory(selectedHistory) {
    this.currentCouponsTag = null;
    this.currentMessagesTag = null;
    // Refresh selected history value from multiselect component
    this.currentHistory = selectedHistory.rawElement;
    this.currentHistory.name = this.dateService.parseDateWithFormat(this.currentHistory.sent_at, 'DD/MM/YYYY HH:mm');
    // Request campaign history to get its apply value
    const history$ = this.campaignService.getCampaignHistoryByIdAndCampaignId(this.currentHistory.id, this.campaign.id).subscribe(
      history => {
        history$.unsubscribe();
        // Refresh data table with new apply
        this.currentApply = history['apply'];
        // Refresh voucheable
        this.voucheable = history['voucheables'][0];
        // Refresh campaign stats with new apply
        if (this.selectedTab === 'customers') {
          this.getCustomersStats();
          this.dataTable.onFilter({apply: this.currentApply, tag_id: this.currentTagId });
          if (this.controlGroupDataTable) {
            this.controlGroupDataTable.onFilter({apply: this.currentApply, tag_id: this.controlGroupTagId});
          }
        } else if ( this.selectedTab === 'coupons' ) {
          this.getCouponsStats();
          this.getCouponsHistoryStats();
        } else if ( this.selectedTab === 'messages' ) {
          this.getMessagesStats();
          this.getMessagesHistoryStats();
        }
      }
    );
    this.subs$.push( history$ );
  }

  onFilterHandler(filterFormValue) {
    if (this.currentTag !== 'not_impacted') {
      Object.assign(filterFormValue, { apply: this.currentApply, tag_id: this.currentTagId });
      this.dataTable.onFilter(filterFormValue);
    } else {
      Object.assign(filterFormValue, { apply: this.currentApply, tag_id: this.controlGroupTagId });
      this.controlGroupDataTable.onFilter(filterFormValue);
    }
  }

  getVouchers(status, title, redeemed_history?: boolean) {
    this.couponsTableTitle = this.translateSrv.instant(`resources.campaigns.statistics.title_${title}`);
    this.currentCouponsTag = title;
    const params = {
      apply: this.currentHistory.id,
      campaign_id: this.campaign.id,
      motivator_id: this.campaignCoupon,
      type: 'Coupon',
      status: status
    };
    if(redeemed_history) {
      params['redeemed_history'] = redeemed_history;
    }
    this.couponsDataTable.onFilter(params);
  }

  getMessages(status, title) {
    this.messagesTableTitle = this.translateSrv.instant(`resources.campaigns.statistics.title_message_${title}`);
    this.currentMessagesTag = title;
    const params = {
      apply: this.currentHistory.id,
      campaign_id: this.campaign.id,
      motivator_id: this.campaignMessage,
      type: 'Message',
      status: status
    };
    this.messagesDataTable.onFilter(params);
  }

  // Private methods

  private getParams() {
    const route$ = this.route.params.subscribe(params => {
      this.getCampaignData(params.id);
      this.setFilterCfg();
      if (this.router.url.indexOf('/statistics/coupons)') >= 0) {
        this.selectedTab = 'coupons';
      } else if (this.router.url.indexOf('/statistics/messages)') >= 0) {
        this.selectedTab = 'messages';
      }
    });
    this.subs$.push( route$ );
  }

  private setHistoriesSelectorCfg(campaignId: number, lastSentHistoryId: number) {
    this.campaignHistoriesService.setCampaign(campaignId);
    this.inputs = [
      new MultiSelectQuestion({
        key: 'campaign_history_id',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: false, showCheckbox: false },
        dataSource: this.campaignHistoriesService,
        selectedIds: [lastSentHistoryId]
      })
    ];
    this.form = this.qcs.toFormGroup(this.inputs);
  }

  private setFilterCfg() {
    this.customersFilterConfig = {
      filterSlug: 'campaign_customers',
      filters: [
        new TextboxQuestion({
          cssClasses: 'form-control input-md',
          key: 'customer_id',
          label: 'resources.profiles.filters.id',
          order: 1,
          type: 'number',
        }),
        new TextboxQuestion({
          cssClasses: 'form-control input-md',
          key: 'name',
          label: 'resources.profiles.filters.name',
          order: 2,
          type: 'text',
        }),
        new TextboxQuestion({
          cssClasses: 'form-control input-md',
          key: 'surname_1',
          label: 'resources.profiles.filters.surname_1',
          order: 3,
          type: 'text',
        }),
        new TextboxQuestion({
          cssClasses: 'form-control input-md',
          key: 'email',
          label: 'resources.profiles.filters.email',
          order: 4,
          type: 'text',
        }),
        new TextboxQuestion({
          cssClasses: 'form-control input-md',
          key: 'phone',
          label: 'resources.profiles.filters.phone',
          order: 5,
          type: 'number',
        }),
        new TextboxQuestion({
          cssClasses: 'form-control input-md',
          key: 'dni',
          label: 'resources.profiles.filters.dni',
          order: 6,
          type: 'text',
        })
      ]
    };
  }

  private setTableCfg() {
    this.customersTableConfig = {
      requiresCacheService: true,
      isActive: true,
      rows: [],
      tableActions: [
        {
          name: this.translateSrv.instant('components.table_actions.export_selected'),
          icon: 'ei-export',
          id: 'exportCSV',
          disabled: () => !this.showPersonalData()
        },
        {
          name: this.translateSrv.instant('components.table_actions.export_customers'),
          icon: 'ei-users-1',
          id: 'exportCustomers',
          disabled: () => !this.showPersonalData(),
          onClick: () => this.exportCustomers(this.customersTagId)
        }
      ],
      rowActions: [
        {
          name: this.translateSrv.instant('resources.profiles.row_actions.details'),
          icon: 'eye',
          id: 'details',
          show: () => this.showPersonalData(),
          callback: element => window.open(`#/data-warehouse/profiles/${element.id}/summary`, '_blank')
        }
      ],
      columns: [
        { name: this.translateSrv.instant('resources.profiles.columns.name'), prop: 'name', sortByField: 'fullName', visible: true},
        { name: this.translateSrv.instant('resources.profiles.columns.email'), prop: 'email', sortByField: 'customer.email', visible: true},
        { name: this.translateSrv.instant('resources.profiles.columns.phone'), prop: 'phone', sortByField: 'customer.phone', visible: true },
        { name: this.translateSrv.instant('resources.profiles.columns.dni'), prop: 'dni', sortByField: 'dni', visible: true },
        { name: this.translateSrv.instant('resources.profiles.columns.created_at'), prop: 'created_at', sortByField: 'created_at', visible: true },
        { name: this.translateSrv.instant('resources.profiles.columns.synchro'), prop: 'synchro', sortByField: 'synchro.created_at', visible: true }
      ],
      requestData: {
        apiEndPoint: `campaigns/${this.campaign.id}/customers`,
        translationResource: 'resources.profiles.columns.',
        pageNumber: 0,
        sorting: {},
        filtering: {
          apply: this.currentApply,
          tag_id: this.campaignStatus[this.currentTag]
        },
        numberPerPage: 10
      },
      tableMapping: [
        { prop: 'id', type: 'number', apiProp: 'id' },
        { prop: 'name', type: 'text', apiProp: 'name' },
        { prop: 'email', type: 'text', apiProp: 'email' },
        { prop: 'phone', type: 'number', apiProp: 'phone' },
        { prop: 'dni', type: 'text', apiProp: 'dni' },
        { prop: 'created_at', type: 'date', apiProp: 'created_at' },
        { prop: 'synchro', type: 'date', apiProp: '_embedded.synchro.created_at' }
      ]
    };
  }
  private setControlGroupTableCfg() {
    this.controlGroupTableConfig = {
      requiresCacheService: true,
      isActive: true,
      rows: [],
      tableActions: [
        {
          name: this.translateSrv.instant('components.table_actions.export_selected'),
          icon: 'ei-export',
          id: 'exportCSV',
          disabled: () => !this.showPersonalData()
        },
        {
          name: this.translateSrv.instant('components.table_actions.export_customers'),
          icon: 'ei-users-1',
          id: 'exportCustomers',
          disabled: () => !this.showPersonalData(),
          onClick: () => this.exportCustomers(this.controlGroupTagId)
        }
      ],
      rowActions: [
        {
          name: this.translateSrv.instant('resources.profiles.row_actions.details'),
          icon: 'eye',
          id: 'details',
          show: () => this.showPersonalData(),
          callback: element => window.open(`#/data-warehouse/profiles/${element.id}/summary`, '_blank')
        }
      ],
      columns: [
        { name: this.translateSrv.instant('resources.profiles.columns.name'), prop: 'name', sortByField: 'fullName', visible: true},
        { name: this.translateSrv.instant('resources.profiles.columns.email'), prop: 'email', sortByField: 'customer.email', visible: true},
        { name: this.translateSrv.instant('resources.profiles.columns.phone'), prop: 'phone', sortByField: 'customer.phone', visible: true },
        { name: this.translateSrv.instant('resources.profiles.columns.dni'), prop: 'dni', sortByField: 'dni', visible: true },
        { name: this.translateSrv.instant('resources.profiles.columns.created_at'), prop: 'created_at', sortByField: 'created_at', visible: true },
        { name: this.translateSrv.instant('resources.profiles.columns.synchro'), prop: 'synchro', sortByField: 'synchro.created_at', visible: true }
      ],
      requestData: {
        apiEndPoint: `campaigns/${this.campaign.id}/customers`,
        translationResource: 'resources.profiles.columns.',
        pageNumber: 0,
        sorting: {},
        filtering: {
          apply: this.currentApply,
          tag_id: this.controlGroupTagId
        },
        numberPerPage: 10
      },
      tableMapping: [
        { prop: 'id', type: 'number', apiProp: 'id' },
        { prop: 'name', type: 'text', apiProp: 'name' },
        { prop: 'email', type: 'text', apiProp: 'email' },
        { prop: 'phone', type: 'number', apiProp: 'phone' },
        { prop: 'dni', type: 'text', apiProp: 'dni' },
        { prop: 'created_at', type: 'date', apiProp: 'created_at' },
        { prop: 'synchro', type: 'date', apiProp: '_embedded.synchro.created_at' }
      ]
    };
  }

  private setCouponsTableCfg() {
    this.couponsTableConfig = {
      requiresCacheService: false,
      tokenPagination: true,
      isMock: false,
      isActive: false,
      rowActions: [],
      tableActions: [],
      columns: [
        { name: this.translateSrv.instant('resources.profiles.columns.name'), prop: 'name'},
        { name: this.translateSrv.instant('resources.profiles.columns.email'), prop: 'email'},
        { name: this.translateSrv.instant('resources.profiles.columns.phone'), prop: 'phone' },
        { name: this.translateSrv.instant('resources.profiles.columns.dni'), prop: 'dni' },
        { name: this.translateSrv.instant('resources.profiles.columns.created_at'), prop: 'created_at', }
      ],
      requestData: {
        apiEndPoint: 'vouchers',
        sorting: {},
        filtering: {
          apply: this.currentHistory.id,
          campaign_id: this.campaign.id,
          motivator_id: this.campaignCoupon,
          type: 'Coupon'
        }
      },
      rows: [],
      tableMapping: [
        { prop: 'name', type: 'text', apiProp: '_embedded.customer.name' },
        { prop: 'email', type: 'text', apiProp: '_embedded.customer.email' },
        { prop: 'phone', type: 'number', apiProp: '_embedded.customer.phone' },
        { prop: 'dni', type: 'text', apiProp: '_embedded.customer._embedded.profile.dni' },
        { prop: 'created_at', type: 'date', apiProp: 'created_at' }
      ]
    };
  }

  private setMessagesTableCfg() {
    this.messagesTableConfig = {
      requiresCacheService: false,
      tokenPagination: true,
      isMock: false,
      isActive: false,
      tableActions: [],
      rowActions: [],
      columns: [
        { name: this.translateSrv.instant('resources.profiles.columns.name'), prop: 'name'},
        { name: this.translateSrv.instant('resources.profiles.columns.email'), prop: 'email'},
        { name: this.translateSrv.instant('resources.profiles.columns.phone'), prop: 'phone' },
        { name: this.translateSrv.instant('resources.profiles.columns.dni'), prop: 'dni' },
        { name: this.translateSrv.instant('resources.vouchers.columns.updated_at_date'), prop: 'created_at'}
      ],
      requestData: {
        apiEndPoint: 'vouchers',
        sorting: {},
        filtering: {
          apply: this.currentHistory.id,
          campaign_id: this.campaign.id,
          motivator_id: this.campaignMessage,
          type: 'Message'
        }
      },
      rows: [],
      tableMapping: [
        { prop: 'name', type: 'text', apiProp: '_embedded.customer.name' },
        { prop: 'email', type: 'text', apiProp: '_embedded.customer.email' },
        { prop: 'phone', type: 'number', apiProp: '_embedded.customer.phone' },
        { prop: 'dni', type: 'text', apiProp: '_embedded.customer._embedded.profile.dni' },
        { prop: 'created_at', type: 'date', apiProp: 'created_at' }
      ]
    };
  }

  private getTagStats() {
    this.tagHistoriesSrv.getTagHistoriesByTagId(this.controlGroupTagId).subscribe(
      resp => {
        this.controlGroupStats = resp['_embedded'].list[0].customers;
      }
    );
  }

  private getCampaignData(campaignId) {
    const campaign$       = this.campaignService.getCampaignById(campaignId);
    const campaignStatus$ = this.campaignService.getCampaignStatusById(campaignId);
    const campaignSubs$ = zip(campaign$, campaignStatus$, (campaign, campaignStatus) => ({ campaign, campaignStatus }))
      .subscribe(
        response => {
          this.campaign = response.campaign;
          this.campaignStatus = response.campaignStatus;
          this.setHistoriesSelectorCfg(this.campaign.id, this.campaign.last_send_history.id);
          this.handleRequestCompleted();
          this.currentHistory = this.campaign.last_send_history;
          this.hasControlGroup = this.campaign.last_send_history._embedded.campaign_info.control_group_flag;
          this.customersTagId = this.campaign.last_send_history._embedded.campaign_info.tag_id;
          this.controlGroupTagId = this.campaign.last_send_history._embedded.campaign_info.control_group_tag_id;
          if (this.controlGroupTagId) {this.getTagStats(); }
          this.currentHistory.name = this.dateService.parseDateWithFormat(this.currentHistory.sent_at, 'DD/MM/YYYY HH:mm');
          // Refactor to be able to change when tabs are switched
          if ( this.selectedTab === 'customers') {
            this.dataTable = this.customersDataTable;
            this.getCustomersStats();
            this.setTableCfg();
            this.setControlGroupTableCfg();
          } else if ( this.selectedTab === 'coupons') {
            this.dataTable = this.couponsDataTable;
            this.campaignCoupon = this.campaign['coupons']['0']['id'];
            this.getCouponsStats();
            this.getCouponsHistoryStats();
            this.setCouponsTableCfg();
          } else if ( this.selectedTab === 'messages') {
            this.dataTable = this.messagesDataTable;
            this.campaignMessage = this.campaign['messages']['0']['id'];
            this.getMessagesStats();
            this.getMessagesHistoryStats();
            this.setMessagesTableCfg();
          }
        }
      );
    this.subs$.push( campaignSubs$ );
  }

  private handleRequestCompleted() {
    this.statusLabel = this.dictionary.getObjectByKeyAndId('campaign_status', this.campaign.status);
    this.campaignIcon = this.dictionary.getObjectByKeyAndId('campaigns_vias', this.campaign.via);
    this.currentApply = this.campaign['last_send_history']['apply'];
    this.currentTagId = this.campaignStatus[this.currentTag];
    this.voucheableType = this.campaign.voucheables[0] ? this.campaign.voucheables[0].type : null;
    this.voucheable = this.campaign.last_send_history.voucheables[0];
    this.campaign.last_send_history.name = this.dateService.parseDateWithFormat(this.campaign.last_send_history.sent_at, 'DD/MM/YYYY HH:mm');
  }

  private getCustomersStats() {
    this.campaignStats = null;
    const params = {
      apiEndPoint: 'analytics/campaign_stats',
      filtering: { apply: this.currentApply, campaign_id: this.campaign.id }
    };
    const campaignStats$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this.campaignStats = reqResponse;
        campaignStats$.unsubscribe();
      }
    });
    this.subs$.push( campaignStats$ );
  }

  private getCouponsStats() {
    this.campaignCouponStats = null;
    const params = {
      apiEndPoint: `coupons/${this.campaignCoupon}/status`,
      filtering: {
        apply: this.currentHistory.id,
        campaign_id: this.campaign.id,
        field: 'status'
      }
    };
    const couponStats$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this.couponsTableTitle = this.translateSrv.instant('resources.campaigns.statistics.title_clients_assigned');
        this.campaignCouponStats = reqResponse;
        couponStats$.unsubscribe();
      }
    });
    this.subs$.push( couponStats$ );
  }

  private getCouponsHistoryStats() {
    this.couponsHistoryStats = null;
    const params = {
      apiEndPoint: `coupons/${this.campaignCoupon}/history`,
      filtering: {
        apply: this.currentHistory.id,
        campaign_id: this.campaign.id,
        field: 'status'
      }
    };
    const historyStats$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        historyStats$.unsubscribe();
        this.graphData = this.setGraphData(this.voucheable, reqResponse);
        this.plot(this.voucheable);
      }
    });
    this.subs$.push( historyStats$ );
  }

  private getMessagesHistoryStats() {
    this.couponsHistoryStats = null;
    const params = {
      apiEndPoint: `messages/${this.campaignMessage}/history`,
      filtering: {
        apply: this.currentHistory.id,
        campaign_id: this.campaign.id,
        field: 'status'
      }
    };
    const historyStats$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        historyStats$.unsubscribe();
        this.graphData = this.setGraphData(this.voucheable, reqResponse);
        this.plot(this.voucheable);
      }
    });
    this.subs$.push( historyStats$ );
  }

  private getMessagesStats() {
    this.campaignMessageStats = null;
    const params = {
      apiEndPoint: `messages/${this.campaignMessage}/status`,
      filtering: {
        apply: this.currentHistory.id,
        campaign_id: this.campaign.id,
        field: 'status'
      }
    };
    const messageStats$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this.messagesTableTitle = this.translateSrv.instant('resources.campaigns.statistics.title_message_clients_assigned');
        this.campaignMessageStats = reqResponse;
        messageStats$.unsubscribe();
      }
    });
  }

  private exportCustomers(tag: number) {
    const exportCustomers$ = this.campaignService.exportCustomers(this.campaign.id, this.currentApply, tag).subscribe(
      () => {
        exportCustomers$.unsubscribe();
        this.confirmationService.displaySuccessAlert(
          this.translateSrv.instant('resources.campaigns.messages.export_enqueued_title'),
          this.translateSrv.instant('resources.campaigns.messages.export_enqueued_desc'),
        ).catch(() => {});
      },
      errData => {
        exportCustomers$.unsubscribe();
        this.confirmationService.displayErrorAlert( 'Error', errData.error.error );
      }
    );
    this.subs$.push( exportCustomers$ );
  }

  private setGraphData(voucheableHistory, data) {

    const registeredDates = {redeemed: [], printed: []};
    const graphData = {redeemed: [], printed: []};
    let month;
    let day;

    if ( data.length ) {
      data.forEach(item => {
        month = (item[1] < 10 ? '0' + item[1].toString() : item[1].toString());
        day = (item[0] < 10 ? '0' + item[0].toString() : item[0].toString());

        if (item[4] > 0) {
          registeredDates.redeemed.push(new Date(item[2] + '-' + month + '-' + day).getTime());
          graphData.redeemed.push([new Date(item[2] + '-' + month + '-' + day).getTime(), item[4]]);
        }

        if (item[3] > 0) {
          registeredDates.printed.push(new Date(item[2] + '-' + month + '-' + day).getTime());
          graphData.printed.push([new Date(item[2] + '-' + month + '-' + day).getTime(), item[3]]);
        }
      });

      const last = data.length - 1;

      month = (data[0][1] < 10 ? '0' + data[0][1].toString() : data[0][1].toString());
      day = (data[0][0] < 10 ? '0' + data[0][0].toString() : data[0][0].toString());

      let minDate = Math.min(
        ( new Date( voucheableHistory.available_from ) ).getTime(),
        ( new Date( data[0][2] + '-' + month + '-' + day )).getTime()
      );
      minDate = (voucheableHistory.available_from ? minDate : ( new Date(data[0][2] + '-' + month + '-' + day)).getTime());

      month = (data[last][1] < 10 ? '0' + data[last][1].toString() : data[last][1].toString());
      day = (data[last][0] < 10 ? '0' + data[last][0].toString() : data[last][0].toString());

      let maxDate = (voucheableHistory.available_to ? new Date(voucheableHistory.available_to) : new Date(data[last][2] + '-' + month + '-' + day)).getTime();

      const interval = 24 * 3600 * 1000;
      let currDate = minDate;

      while (currDate <= maxDate) {
        if (registeredDates.redeemed.indexOf(currDate) === -1) {
          this.chartsCfgService.interpolateDatePoint(graphData.redeemed, currDate, 0);
        }
        if (registeredDates.printed.indexOf(currDate) === -1) {
          this.chartsCfgService.interpolateDatePoint(graphData.printed, currDate, 0);
        }
        currDate += interval;
      }
    }

    return graphData;
  }

  private couponsChartFormatter() {
    let tooltip = '';
    if (this.points) {
      tooltip += Highcharts.dateFormat('%e/%m/%Y', this.points[0].x) + '<br>';
      const index = 0;
      for (let idx = index; idx < this.points.length; idx++) {
        tooltip += this.points[idx].series.name + ': ' + this.points[idx].y + '<br>';
      }
    }
    return tooltip;
  }

  private plot(resource) {
    // This method should be called to have access to an ElementRef nested in at least ngIf.
    // Not rendered from the beginning
    this.changeDetector.detectChanges();

    // Chart default configuration for coupons / messages
    const couponsChartCustomCfg = {
      title: {
        text: this.translateSrv.instant('resources.campaigns.statistics.voucheables_chart_title')
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: { // don't display the dummy year
          month: '%e %b %Y',
          year: '%b'
        },
        title: {
          text: this.translateSrv.instant('resources.campaigns.statistics.date')
        }
      },
      yAxis: [
        {
          title: {
            text: this.translateSrv.instant('resources.campaigns.statistics.quantity')
          },
          min: 0
        },
      ],
      tooltip: {
        shared: true,
        formatter: this.couponsChartFormatter()
      },
      series: [
        {
          name: this.translateSrv.instant('resources.campaigns.statistics.redemptions'),
          data: this.graphData.redeemed
        },
        {
          name: this.translateSrv.instant('resources.campaigns.statistics.impressions'),
          data: this.graphData.printed
        }
      ],
      accessibility: {
        enabled: false
      }
    };

    const chartCfg = Object.assign(this.chartsCfgService.getConfig('line'), couponsChartCustomCfg);

    this.chart = chart(this.vouchersChart.nativeElement, chartCfg);

    if ( resource.available_from ) {
      this.chart.addSeries({
        name: this.translateSrv.instant('resources.campaigns.statistics.activation'),
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.x:%m-%Y}: {point.name}'
        },
        type: 'scatter',
        data: [[(new Date(resource.available_from)).getTime(), 0]],
        lineWidth : 0,
        marker: {
          radius: 4,
          symbol: 'diamond'
        }
      }, false);
    }

    if ( resource.available_to ) {
      this.chart.addSeries({
        name: this.translateSrv.instant('resources.campaigns.statistics.deactivation'),
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.x:%m-%Y}: {point.name}'
        },
        type: 'scatter',
        data: [[(new Date(resource.available_to)).getTime(), 0]],
        lineWidth : 0,
        marker: {
          radius: 4,
          symbol: 'diamond'
        }
      }, false);
    }

    this.chart.redraw();
  }

  private showPersonalData() {
    return this.flags.showCustomerPersonalData || USERS_PERSONAL_DATA_AUTH.includes(this.currentRole);
  }
}
