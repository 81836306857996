import { Component, Input } from '@angular/core';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-rules-details-block',
  templateUrl: './rules-details-block.component.html'
})
export class RulesDetailsBlockComponent {

  @Input('loyaltyScoresForm') loyaltyScoresForm: UntypedFormGroup;
  @Input('inputs') inputs;
  @Input('mode') mode;
  @Input('flags') flags;
  @Input('filteredAvailableLanguages') filteredAvailableLanguages;


  constructor(
    private qcs: QuestionControlService,
  ) { }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

}
