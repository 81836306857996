import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { Subscription } from 'rxjs';
import { LocationsService } from '../locations.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '../../../../shared/models/locations/location';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-show-locations',
  templateUrl: './show-locations.component.html',
  styleUrls: ['./show-locations.component.css']
})
export class ShowLocationsComponent implements OnInit, OnDestroy {

  public element: any;
  public id: number | string;
  public isModal: boolean;
  private subs$: Subscription[];

  constructor(
    private route: ActivatedRoute,
    private locationsService: LocationsService,
    private router: Router,
    private confirmService: ConfirmationService,
    private translate: TranslateService
  ) {
    this.subs$ = [];
  }

  ngOnInit() {
    registerLocaleData(es);
    this.isModal = this.router.url.indexOf('(modal:show/locations/') >= 0;
    this.getParams();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getParams() {
    const route$ = this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.getLocation();
      }
    });
    this.subs$.push(route$);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getLocation() {
    const location$ = this.locationsService.getLocationsById(this.id).subscribe(
      (locationData: HttpResponse<object>) => {
        this.element = new Location(locationData);
      },
      (errData: HttpErrorResponse) => {
        this.confirmService.displayErrorAlert(this.translate.instant('common.error'), errData.error.error);
      }
    );
    this.subs$.push(location$);
  }
}
