import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { QuestionBase } from '../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../shared/models/forms/question-multiselect';
import { RewardsService } from './rewards/rewards.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class WalletsService extends ResourcesService {

  public inputs: any;

  constructor(
    http: HttpClient,
    private translate: TranslateService,
    private rewardService: RewardsService
  ) {
    super(http);
  }


  public getWalletById(id: number) {
    return this.getData({ apiEndPoint: `reward_ranges/${id}` });
  }

  public getInputs(formValues: any): QuestionBase<any>[] {
    this.inputs = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
      }),
      new TextboxQuestion({
        key: 'points',
        type: 'number',
        cssClasses: 'form-control input-default',
      }),
      new MultiSelectQuestion({
        key: 'reward_id',
        cssClasses: 'form-control input-default',
        settings: {singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true},
        dataSource: this.rewardService
      })
    ];
    return this.inputs;
  }

  public createWallet(data: any) {
    return this.postResource(data, `reward_ranges`);
  }

  public deleteWallet(_id: number) {
    return this.deleteResource({}, `reward_ranges/${_id}`);
  }

  public assignCoupons() {
    return this.postResource({}, `reward_ranges/assign`);
  }
}
