import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomersService } from '../../../customers/customers.service';
import { Subscription } from 'rxjs';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { DictionaryService } from '../../../../../shared/services/dictionary.service';
import { TranslateService } from '@ngx-translate/core';
import { DataTableFilterService } from '../../../../../shared/components/data-table-filter/data-table-filter.service';
import { ClientsConstantData, ClientsConstantDataMock } from '../../../../../shared/models/analytics/client-constant-data';
import * as moment from 'moment';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';

@Component({
  selector: 'app-clients-constant-tab',
  templateUrl: './clients-constant-tab.component.html',
  styleUrls: ['./clients-constant-tab.component.css'],
  providers: [DictionaryService]
})
export class ClientsConstantTabComponent implements OnInit, OnDestroy {

  loading: boolean;
  months: {id: number, name: string, key: string}[];
  activeMonths: number;
  clientsConstantData: ClientsConstantData[];
  filter: { year: number, compared_year: number };
  debug: boolean;
  subs$: Subscription[];
  onSearch: boolean;

  constructor(
    private customersService: CustomersService,
    private refreshCacheService: RefreshCacheService,
    private dictionary: DictionaryService,
    private translate: TranslateService,
    private filterService: DataTableFilterService,
    private confirmationService: ConfirmationService,
  ) {
    this.debug = false;
    this.subs$ = [];
    this.months = this.dictionary.dictionaries.months;
    this.activeMonths = this.months.length;
  }

  ngOnInit() {
    this.onSearch = false;
    const filterChange$ = this.customersService.applyFilters$.subscribe((filterValues) => {
      this.getClientsConstant(filterValues);
      this.onSearch = true;
    });
    this.subs$.push(filterChange$);
    setTimeout(() => this.filterService.loaderStatus.next(true));
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach(s$ => s$.unsubscribe()); }
  }

  onFilterHandler(filterFormValue) {
    this.loading = true;
    this.filterService.loaderStatus.next(false);
    this.getClientsConstant(filterFormValue);
  }

  private getClientsConstant(filterValues) {
   
    let filters = { ...filterValues };

    if ( Object.keys(filters).length === 0 ) {
      filters = { year: moment().year(), compared_year: moment().year() - 1 };
    } else {
      const { year_of_last_years, ...filtersPayload } = filters;
      const fltrPayload = {...filtersPayload, year: year_of_last_years };
      filters = fltrPayload;
    }

    this.filter = {year: filters.year, compared_year: filters.compared_year};

    const highestYear = (filters.year > filters.compared_year) ? filters.year : filters.compared_year;
    const lowestYear = (filters.year <= filters.compared_year) ? filters.year : filters.compared_year;

    filters.year = highestYear;
    filters.compared_year = lowestYear;
    this.filter = {year: highestYear, compared_year: lowestYear};
    this.activeMonths = this.calculateActiveMonths(filters);

    if (this.debug) {
      setTimeout(() => {
        this.loading = false;
        this.filterService.loaderStatus.next(true);
        this.clientsConstantData = this.toConstantData( ClientsConstantDataMock );
        this.calculateTotals();
      }, 500 );
    } else {
      const params = {
        apiEndPoint: 'analytics/yearly_clients_constant',
        filtering: filters
      };
      this.loading = true;
      this.filterService.loaderStatus.next(false);
      const clientsConstant$ = this.refreshCacheService.getExpensiveData(params).subscribe((data) => {
        if (!this.refreshCacheService.isRequestPending(data)) {
          this.loading = false;
          this.filterService.loaderStatus.next(true);
          this.clientsConstantData = this.toConstantData(data);
          this.calculateTotals();
          clientsConstant$.unsubscribe();
        }
      },
      errorResponse => {
        clientsConstant$.unsubscribe();
        this.loading = false;
        this.filterService.loaderStatus.next(true);
        this.confirmationService.displayErrorAlert(
          this.translate.instant('common.error'),
          JSON.stringify(errorResponse.error.error)
        );
      }
      );
      this.subs$.push(clientsConstant$);
    }
  }

  private calculateActiveMonths(filters): number {
    const today = new Date();
    if ( parseInt(filters.year, 10) === today.getFullYear()) {
      return (today.getMonth() > 0) ? today.getMonth() : 1;
    }
    return this.months.length;
  }

  private toConstantData(rawData): ClientsConstantData[] {
    const result = [];
    for (let index = 0; index < this.activeMonths; index++) {

      const clientsCount = rawData.constant_customers[index];
      const yearSalesConstant = rawData.constant_sales.year[index];
      const yearToCompareSalesConstant = rawData.constant_sales.compared_year[index];
      const yearSalesNoConstant = rawData.no_constant_sales.year[index];
      const yearToCompareSalesNoConstant = rawData.no_constant_sales.compared_year[index];

      let yearSalesDiff = (( yearSalesConstant - yearToCompareSalesConstant ) / yearToCompareSalesConstant) * 100;
      if ( isNaN(yearSalesDiff) || !isFinite(yearSalesDiff) ) {
        yearSalesDiff = null;
      }

      let yearSalesNoConstantDiff = (( yearSalesNoConstant - yearToCompareSalesNoConstant ) / yearToCompareSalesNoConstant ) * 100;
      if ( isNaN(yearSalesNoConstantDiff) || !isFinite(yearSalesNoConstantDiff) ) {
        yearSalesNoConstantDiff = null;
      }

      const constantDataRawObj = {
        month: this.months[index].name,
        clientsCount: clientsCount,
        yearSalesConstant: yearSalesConstant,
        yearToCompareSalesConstant: yearToCompareSalesConstant,
        yearSalesDiff: yearSalesDiff,
        yearSalesNoConstant: yearSalesNoConstant,
        yearToCompareSalesNoConstant: yearToCompareSalesNoConstant,
        yearSalesNoConstantDiff: yearSalesNoConstantDiff
      };

      const clientsConstantRow = new ClientsConstantData(constantDataRawObj);
      result.push(clientsConstantRow);
    }

    return result;
  }

  private calculateTotals() {

    let averageClientsCount = 0;
    let yearSalesConstantSum = 0;
    let yearToCompareSalesConstantSum = 0;
    let totalYearSalesDiff = 0;
    let yearSalesNoConstantSum = 0;
    let yearToCompareSalesNoConstantSum = 0;
    let totalYearToCompareSalesDiff = 0;

    this.clientsConstantData.forEach((dataRow) => {
      averageClientsCount = averageClientsCount + dataRow.clientsCount;
      yearSalesConstantSum = yearSalesConstantSum + dataRow.yearSalesConstant;
      yearToCompareSalesConstantSum = yearToCompareSalesConstantSum + dataRow.yearToCompareSalesConstant;
      yearSalesNoConstantSum = yearSalesNoConstantSum + dataRow.yearSalesNoConstant;
      yearToCompareSalesNoConstantSum = yearToCompareSalesNoConstantSum + dataRow.yearToCompareSalesNoConstant;
    });

    averageClientsCount = averageClientsCount / this.activeMonths;

    totalYearSalesDiff = ((yearSalesConstantSum - yearToCompareSalesConstantSum) / yearToCompareSalesConstantSum) * 100;
    if (isNaN( totalYearSalesDiff) || !isFinite(totalYearSalesDiff)) {
      totalYearSalesDiff = null;
    }

    totalYearToCompareSalesDiff = ((yearSalesNoConstantSum - yearToCompareSalesNoConstantSum) / yearToCompareSalesNoConstantSum) * 100;
    if (isNaN(totalYearToCompareSalesDiff) || !isFinite( totalYearToCompareSalesDiff)) {
      totalYearToCompareSalesDiff = null;
    }

    const totals = new ClientsConstantData({
      month: this.translate.instant('common.total'),
      clientsCount: averageClientsCount,
      yearSalesConstant: yearSalesConstantSum,
      yearToCompareSalesConstant: yearToCompareSalesConstantSum,
      yearSalesDiff: totalYearSalesDiff,
      yearSalesNoConstant: yearSalesNoConstantSum,
      yearToCompareSalesNoConstant: yearToCompareSalesNoConstantSum,
      yearSalesNoConstantDiff: totalYearToCompareSalesDiff
    });

    this.clientsConstantData.push( totals);
  }
}
