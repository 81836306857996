<!-- Partners -->
<div class="card">
  <div class="card-heading border bottom">
    <h4 class="text-bold card-title" [translate]="'resources.scores.partners.title'"></h4>
  </div>
  <div class="card-block">
    <div class="alert alert-info" role="alert">
      <span class="alert-text">{{'resources.scores.loyalty_scores.descriptions.partners' | translate}}</span>
    </div>
    <div class="row mrg-top-15">
      <div class="col-md-6">
        <div class="form-group">
          <label class="text-bold" for="partner_id"
            [translate]="'resources.scores.loyalty_scores.fields.partner_id'"></label>
          <app-question [question]="getInputConfig('partner_id')" [form]="loyaltyScoresForm"></app-question>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
