import { Component, OnInit } from '@angular/core';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../shared/services/question-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { ModalStatusService } from '../../../shared/services/modal-status.service';
import { TranslateService } from '@ngx-translate/core';
import { RecipesService } from '../recipes.service';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-form-recipes',
  templateUrl: './form-recipes.component.html',
  styleUrls: ['./form-recipes.component.css'],
  providers: [RecipesService]
})
export class FormRecipesComponent implements OnInit {

  public recipesForm: UntypedFormGroup;
  public inputs: QuestionBase<any>[];
  public id: number;
  public showButton: boolean;
  public errorData: any;
  public loading: boolean;
  element: any;

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private recipesService: RecipesService,
    private confirmationService: ConfirmationService,
    private modalStatusService: ModalStatusService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    registerLocaleData(es);
    this.getParams();
    this.showBackButton();
  }

  showBackButton() {
    this.showButton = !(this.router.url.indexOf('(modal:') >= 0);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getRecipesById(this.id);
      } else {
        this.inputs = this.recipesService.getInputs({});
        this.recipesForm = this.qcs.toFormGroup(this.inputs);
      }
    });
  }

  private getRecipesById(id: number) {
    this.recipesService.getRecipesById(this.id).subscribe(data => {
      this.inputs = this.recipesService.getInputs(data);
      this.recipesForm = this.qcs.toFormGroup(this.inputs);
      this.recipesForm.get('coupon_id').valueChanges.subscribe(value => {
        this.element = value;
      });
    });
  }

  paintErrorsInForm(form: UntypedFormGroup, errorObject: any) {
    const errorsArray = [];
    if (
      errorObject.hasOwnProperty('error') &&
      errorObject.error.hasOwnProperty('error') &&
      errorObject.error.error
    ) {
      errorsArray.push({
        field: 'Global',
        field_name: 'Global',
        message: errorObject.error.error
      });
    }
    this.qcs.paintErrorsInForm(this.inputs, form, errorsArray);
  }

  getErrors(): { field: string; message: string }[] {
    return this.recipesForm.errors.map(error => {
      const field =
        error.field_name.indexOf('translation missing') >= 0
          ? error.field
          : error.field_name;
      const message = error.message;
      return { field: field, message: message };
    });
  }

  removeError(error: { field: string; message: string }) {
    const errorsArray = this.recipesForm.errors.filter(
      _error => _error.field !== error.field
    );
    this.recipesForm.setErrors(errorsArray);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.recipesForm, formKey, value);
  }

  handleTriggerChange(event) {
    if (event.id === 'after_create_customer') {
      this.inputs.find(input => input.key === 'action')['options'] = [{ id: 'coupon', name: this.translate.instant('resources.marketing_automation.actions.coupon')}];
    }
  }

  public sendData() {

    const trigger = this.recipesForm.value.trigger ? this.recipesForm.value.trigger[0].id : [];
    const action = this.recipesForm.value.action ? this.recipesForm.value.action[0].id : [];
    const coupon_id = this.recipesForm.value.coupon_id ? this.recipesForm.value.coupon_id.map(item => item.id) : [];

    const recipesOptions = {
      trigger: trigger,
      action: action,
      configuration: {
        subject: this.recipesForm.value.subject,
        message: this.recipesForm.value.message,
        coupon_id: coupon_id
      }
    };

    if (this.id) {
      this.recipesService.updateData(recipesOptions, this.id).subscribe(
        response => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.marketing_automation.form.warnings.update_title'),
            this.translate.instant('resources.marketing_automation.form.warnings.update_text')
          ).catch(() => {});
          this.modalStatusService.modalStatus.emit();
          this.returnToList();
        },
        errorData => {
          if ( errorData.error && errorData.error.errors &&  errorData.error.errors.length > 0 ) {
              this.paintErrorsInForm(this.recipesForm, errorData);
            }
        }
      );
    } else {
      this.recipesService.sendData(recipesOptions).subscribe(
        response => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.marketing_automation.form.warnings.success_title'),
            this.translate.instant('resources.marketing_automation.form.warnings.success_text')
          ).catch(() => {});
          this.modalStatusService.modalStatus.emit();
          this.returnToList();
        },
        errorData => {
          if ( errorData.error && errorData.error.errors && errorData.error.errors.length > 0 ) {
            this.paintErrorsInForm(this.recipesForm, errorData);
          }
        }
      );
    }
  }

  public returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/recipes/recipes']).catch(() => {});
    }
  }

}
