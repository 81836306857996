import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { LocationsService } from '../../../../resources/data-warehouse/locations/locations.service';
import { Operator } from '../../../../shared/models/segments/conditions/operator';
import { Option } from '../../../../shared/models/common/option';
import { DateService } from '../../date.service';
import { isNullOrUndefined } from '../../../utils/common.utils';

export function setLocationsFiltering(params: object, locationsService: LocationsService): void {
  const hasLocationTaxonomyTermIds = !!(params && Object.keys(params).length && params['location_taxonomy_term_ids']?.length);
  const locationTaxonomyTermIds = hasLocationTaxonomyTermIds ? params['location_taxonomy_term_ids'].join(',') : null;
  locationsService.setLocationTaxonomyTermIds(locationTaxonomyTermIds);
}

export function getParseValue(isArrayValue = true): unknown {
  return isArrayValue ? (value) => value?.length ? value : null : (value) => value ?? null;
}

// i. e. { id: "2019-07-01", name: "01/07/2019" }
export function getParseObjectKeysIdValue(): unknown {
  return (value) => value && Object.keys(value).length ? value.id : null;
}

export function getParseBooleanValue(): unknown {
  return (value) => value ?? false;
}

export function getParseIntValue(): unknown {
  return (value) => !isNullOrUndefined(value) ? parseInt(value, 10) : null;
}

export function getParseFloatValue(): unknown {
  return (value) => !isNullOrUndefined(value) ? parseFloat(value) : null;
}

export function getParseSelectedIdsValue(): unknown {
  return (value) => ({ selectedIds: value ? value : [] });
}

export function getParseOperatorValue(operators: Operator[]): unknown {
  return (value) => value?.length ? [operators.find(operator => operator.id === value)] : null;
}

export function getParseSingleSelectOperatorIdValue(operators: Operator[]): unknown {
  return (value) => value?.length ? operators.find(operator => operator.id === value[0].id).id : null;
}

export function getParseMultiselectIdValues(): unknown {
  return (value) => value?.length ? value.map(value => value.id) : null;
}

export function getParseMultiselectValueInOptions(options: Option[]): unknown {
  return (value) => value?.length ? options.filter(item => value.includes(item.id)) : null;
}

// i. e. 2019-07-01 or { id: 2019-07-01, name: 01/07/2019 }
export function getParseDynamicDateValue(dateService: DateService): unknown {
  return (value) => {
    value = (value?.hasOwnProperty('id')) ? value.id : value;
    if (!value) { return {id: null, name: null}; }
    return dateService.dynamicDateToDateOption(value);
  }
}

export function defaultParseFormValuesToAPI(params: object, inputs: QuestionBase<unknown>[]): object {
  const parsedValuesObj = {};
  Object.keys(params).forEach(key => {
    const input = inputs.find(input => input.key === key);
    const parsedValue = input.parseValue(params[key]);
    parsedValuesObj[key] = parsedValue;
  });
  return parsedValuesObj;
}

export function parseParamsValues(params: object, inputs: QuestionBase<unknown>[]): void {
  if (params) {
    Object.keys(params).forEach(key => {
      const input = inputs.find(input => input.key === key);
      const value = input.getValue(params[key]);
      input.value = value;
    });
  }
}