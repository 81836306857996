<div class="modal-backdrop fade in modal-fs" id='modal-fs' tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body height-100">
        <div id='bee-plugin-container'></div>
      </div>

      <div class="modal-footer">

        <div class="toLeft">
					<div class="form-inline">
						<label class="text-bold text-white">{{'resources.campaign_templates.name' | translate}}: </label>
						<input class="mrg-left-15 width-50-vh" type="text" [(ngModel)]="campaignName">
					</div>
				</div>
        <button class="btn btn-info"
                (click)="confirmClose()"
                data-dismiss="modal"
                [translate]="'resources.campaigns_landings.modal.buttons.cancel'">
        </button>
      </div>
    </div>
  </div>
</div>

