import { ActionModalsService } from '../action-modals.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService } from '../../../../../../../../shared/services/confirmation.service';
import { Customer } from '../../../../../../../../shared/models/customers/customer';
import { CustomersService } from '../../../../../../../analytics/customers/customers.service';
import { finalize, Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ModalStatusService } from '../../../../../../../../shared/services/modal-status.service';
import { QuestionBase } from '../../../../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../../../../shared/services/question-control.service';
import { ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { RewardsCatalogService } from '../../../../../../../scores/rewards-catalog/rewards-catalog.service';
import { RewardsService } from '../../../../../../../scores/rewards/rewards.service';
import { SharedModule } from '../../../../../../../../shared/shared.module';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-redeem-reward',
  standalone: true,
  imports: [CommonModule, SharedModule, ReactiveFormsModule],
  templateUrl: './redeem-reward.component.html',
  styleUrls: ['./redeem-reward.component.scss']
})

export class RedeemRewardComponent implements OnInit, OnDestroy {

  customerData: Customer;
  customerId: number;
  loading: boolean;
  redeemRewardForm: UntypedFormGroup;
  redeemRewardInputs: QuestionBase<unknown>[];
  rewardId: number;
  rewardWithFlexibleScoreAllowed: boolean;

  private destroy$ = new Subject<void>();

  constructor(
    private actionModalService: ActionModalsService,
    private confirmationService: ConfirmationService,
    private customersService: CustomersService,
    private modalStatusService: ModalStatusService,
    private qcs: QuestionControlService,
    private rewardsCatalogService: RewardsCatalogService,
    private rewardsService: RewardsService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {
    this.customerId = this.route.snapshot.params.id;
    this.rewardsCatalogService.getCustomerId(this.customerId);
  }

  ngOnInit() {
    this.getCustomerData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.redeemRewardInputs, inputKey);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  setPayload() {
    this.rewardId = this.redeemRewardForm?.value?.reward_id?.[0]?.id || null;
    const payload = {
      customer_id: this.customerId,
      location_id: this.customerData?.registrationLocationId,
      score_overwrite: parseInt(this.redeemRewardForm?.value?.score_overwrite, 10)
    };

    this.loading = true;
    this.getConfirmationAlert(payload);
  }

  handleRewardSelection(reward) {
    this.rewardWithFlexibleScoreAllowed = !!reward?.rawElement?.flexible_score_allowed;
    this.validateRedeemForm(this.rewardWithFlexibleScoreAllowed);
  }

  private validateRedeemForm(rewardWithFlexibleScoreAllowed) {
    const scoreOverwriteControl = this.redeemRewardForm.get('score_overwrite');
    scoreOverwriteControl.clearValidators();
    this.redeemRewardForm.get('reward_id').valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        if (rewardWithFlexibleScoreAllowed) {
          scoreOverwriteControl.setValidators([Validators.required]);
        } else {
          this.redeemRewardForm.get('score_overwrite').reset();
          this.rewardWithFlexibleScoreAllowed = false;
          scoreOverwriteControl.clearValidators();
        }
        scoreOverwriteControl.updateValueAndValidity();
      }
    });
    scoreOverwriteControl.updateValueAndValidity();
  }

  private sendRequest(payload) {
    this.rewardsService.redeemReward(payload, this.rewardId).pipe(takeUntil(this.destroy$), finalize(() => this.loading = false)) .subscribe({
      next: () => {
        this.modalStatusService.modalStatus.emit();
        this.closeModal();
        this.confirmationService.displaySuccessAlert('',
          this.translate.instant('resources.customers.show.action_modals.messages.redeem_reward.redeem_reward_success_text')
        ).catch(() => {});
      },
      error: (errorData: HttpErrorResponse) => {
        this.confirmationService.displayHttpErrorAlert(errorData);
      }
    });
  }

  private getRedeemRewardInputs() {
    this.redeemRewardInputs = this.actionModalService.getInputs().filter(
      input =>
        input.key === 'reward_id' ||
        input.key === 'score_overwrite'
      );
  }

  private getParams() {
    this.route.params.pipe(takeUntil(this.destroy$), finalize(() => this.loading = false)).subscribe({
      next: (params) => {
        this.customerId = params.id;
        this.getRedeemRewardInputs();
        this.redeemRewardForm = this.qcs.toFormGroup(this.redeemRewardInputs);
      },
      error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
    });
  }

  private getCustomerData(): void {
    this.loading = true;
    this.customersService.getCustomerById(this.customerId, {with_accumulated: true}).pipe(takeUntil(this.destroy$), finalize(() => this.loading = false)).subscribe({
      next: (customer: HttpResponse<object>) => {
        this.customerData = new Customer(customer);
        this.getParams();
      },
      error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData),
    });
  }

  private getConfirmationAlert(payload: object) {
    const titleMessage = this.translate.instant('common.messages.are_you_sure');
    const confirmationMessage = this.translate.instant('resources.customers.show.action_modals.messages.confirm_redeem_reward');

    this.confirmationService.displayConfirmationAlert( titleMessage, confirmationMessage ).then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.sendRequest(payload);
      } else {
        this.loading = false;
      }
    }).catch(() => {});
  }

}
