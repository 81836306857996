import { BigqueryJobsStepsBase } from './bigquerystep-base'
import { CustomStep } from './bigquerystep-custom';
import { PumpStep } from './bigquerystep-pump';
import { DumpStep } from './bigquerystep-dump';
import { SegmentStep } from './bigquerystep-segment';
import { SegmentCategoryStep } from './bigquerystep-segmentcategory';
import { PeriodStep } from './bigquerystep-period';
import { SynchroStep } from './bigquerystep-synchro';
import { DataStoreSynchroStep } from './bigquerystep-data-store-synchro';
import * as moment from 'moment';
import { GrantScoresStep } from './bigquerystep-grant-scores';

export class ScheduledTask {

  id: number;
  name: string;
  steps: BigqueryJobsStepsBase[];
  averageString: string;
  averageDuration: number;
  execution: string;
  executionDay: number;
  executionHour: string;

  constructor(config: object) {
    this.id = config['id'];
    this.name = config['name'];
    this.execution = config['execution'];
    this.executionDay = config['execution_day'];
    this.executionHour = config['execution_hour'];
    this.averageDuration = config['average_duration'];
    this.averageString = (this.averageDuration ? moment.duration(this.averageDuration, 'seconds').humanize() : '');
    this.steps = this.parseSteps(config);
  }

  private parseSteps(config: object): BigqueryJobsStepsBase[] {

    if(!config.hasOwnProperty('bigquery_job_steps') || !config['bigquery_job_steps']) {
      return [];
    };

    return config['bigquery_job_steps'].map((el: object) => {
      switch (el['category']) {
        case 'custom':
          return new CustomStep(el);
        case 'segment':
          return new SegmentStep(el);
        case 'segment_category':
          return new SegmentCategoryStep(el);
        case 'synchro':
          return new SynchroStep(el);
        case 'datastore_synchro':
          return new DataStoreSynchroStep(el);
        case 'pump':
          return new PumpStep(el);
        case 'period':
          return new PeriodStep(el);
        case 'grant_scores':
          return new GrantScoresStep(el);
        case 'dump':
          return new DumpStep(el);
      }
    });
  }
}
