import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ProfileService } from '../../../../profiles/profile.service';
import { CampaignTemplatesService } from '../../../../shared/components/create-update-templates/service/campaign-templates-service.service';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { DictionaryService } from '../../../../shared/services/dictionary.service';
import { CurrentUser } from '../../../../shared/models/current-user';
import { FeatureFlagsService } from './../../../../shared/services/feature-flags.service';

@Component({
  selector: 'app-email-template-edit',
  templateUrl: './email-template-edit.component.html',
  styleUrls: ['./email-template-edit.component.css'],
  providers: [DictionaryService]
})
export class EmailTemplateEditComponent implements OnInit {

  userData: any;
  flags = this.featureFlags.flags;
  initialized = false;
  beeCustomConfig = {
    onSave: (json: JSON, html: string) => this.onSave(json, html)
  };

  @ViewChild('modalToggleBtn') modalToggleBtn: ElementRef;
  @Input('template') template: any;
  @Input() mode: string;
  @Output() emailTemplateEmitter: EventEmitter<any> = new EventEmitter();

  private mergeTags: {name:string, value:string}[];

  constructor(
    private currentUserProfile: ProfileService,
    private campaignTemplatesService: CampaignTemplatesService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private dictionary: DictionaryService,
    private featureFlags: FeatureFlagsService
  ) { }

  ngOnInit() {
    this.mergeTags = this.dictionary.getValuesByKey('campaign_templates_custom_variables');
    if(!this.flags.showCustom1DynamicVariable){
      this.mergeTags = this.mergeTags.filter(tag => tag.value !== '{custom1}');
    }
    this.getUserData();
  }

  open() {
    if (!this.initialized) {
      this.requestInitBee(this.template['schema']);
    }
    this.modalToggleBtn.nativeElement.click();
  }

  openAndViewTemplate(template) {
    template.schema = JSON.stringify(template.schema);
    this.template = template;
    this.refreshTemplate(template);
    this.open();
  }

  close() {
    this.modalToggleBtn.nativeElement.click();
  }

  closeWithConfirmation() {
    this.confirmationService.displayAlert(
      this.translateService.instant('resources.campaign_templates.modal.warnings.continue'),
      this.translateService.instant('resources.campaign_templates.modal.warnings.cancel_message'),
      'warning'
    ).then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.close();
      }
    }).catch(() => {});
  }

  onSave(json, html) {
    this.emailTemplateEmitter.emit({ json: json, html: html });
    this.close();
  }

  refreshTemplate(template) {
    const schema = JSON.parse(template.schema);
    if ( this.initialized ) {
      this.campaignTemplatesService.load(schema);
    } else {
      this.requestInitBee(schema);
    }
  }

  private getUserData() {
    this.currentUserProfile.getRawUserProfile().subscribe((reqResponse) => {
      this.userData = reqResponse;
    });
  }

  private requestInitBee(schema: any) {
    const user = new CurrentUser(this.currentUserProfile.getStoredUser());
    const language = this.flags.newLanguageMenuVisibility ? user.userPreferredLanguage : user.companyLanguage;

    this.initialized = true;
    this.campaignTemplatesService.initBee(this.userData, this.beeCustomConfig, schema, language, this.mergeTags);
  }
}
