import { BehaviorSubject, throwError } from 'rxjs';
import { CheckboxQuestion } from '../../shared/models/forms/question-checkbox';
import { FeatureFlagsService } from '../../shared/services/feature-flags.service';
import { FeaturesService } from '../data-warehouse/products/features.service';
import { FileQuestion } from '../../shared/models/forms/question-file';
import { getCurrencySymbol } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ImageQuestion } from '../../shared/models/forms/question-image';
import { Injectable } from '@angular/core';
import { LanguagesService } from '../../shared/services/languages.service';
import { LocationsService } from '../data-warehouse/locations/locations.service';
import { LocationsTaxonomyTermsService } from '../data-warehouse/locations/location-taxonomy-terms.service';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../shared/components/multiselect/multiselect';
import { MultiSelectQuestion } from '../../shared/models/forms/question-multiselect';
import { Observable } from 'rxjs';
import { ProductsService } from '../data-warehouse/products/products.service';
import { ProfileService } from '../../profiles/profile.service';
import { QuestionBase } from '../../shared/models/forms/question-base';
import { RadioQuestion } from '../../shared/models/forms/question-radio';
import { ResourcesService } from '../../shared/services/resources.service';
import { TextboxQuestion } from '../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';
import { PartnersService } from '../scores/partners/partners.service';
import { FeatureTaxonomiesService } from '../data-warehouse/products/feature-taxonomies.service';
import { isNullOrUndefined } from '../../shared/utils/common.utils';

@Injectable()
export class CouponsService extends ResourcesService implements MultiselectDataSourceable {

  currencySymbol: any;
  flags = this.featureFlags.flags;
  inputs: QuestionBase<any>[];
  optsSource = new BehaviorSubject<boolean>(null);
  optsSource$ = this.optsSource.asObservable();

  constructor(
    http: HttpClient,
    private featureFlags: FeatureFlagsService,
    private features: FeaturesService,
    private languagesService: LanguagesService,
    private locations: LocationsService,
    private locationTaxonomyTermsService: LocationsTaxonomyTermsService,
    private products: ProductsService,
    private profileService: ProfileService,
    private translate: TranslateService,
    private featuresTaxonomiesService: FeatureTaxonomiesService,
    private partners: PartnersService
  ) {
    super(http);
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
  }

  /* This method is necessary to automatically assign a recently generated coupon */
  refresh() {
    this.optsSource.next(true);
  }

  sendData(data) {
    const error = this.validateProductData(data);
    return error ? error : this.postResource(data, 'coupons');
  }

  importData(file: string) {
    return this.postResource({file}, 'coupons/synchro');
  }

  updateData(data: any, _id: number) {
    const error = this.validateProductData(data);
    return error ? error : this.patchResource(data, `coupons/${_id}`);
  }

  updateHistoryData(data: any, _id: number, history_id: number) {
    const error = this.validateProductData(data);
    return error ? error : this.patchResource(data, `coupons/${_id}/histories/${history_id}`);
  }

  exportData(data): Observable<any> {
    return this.postResource(data, 'coupons/export');
  }

  uploadImages(data): Observable<any> {
    return this.postResource(data, 'images/create_multiple');
  }

  getUploadedImages(): Observable<any> {
    return this.getData({
      apiEndPoint: 'images',
      numberPerPage: 300,
      sorting: {
        byProp: 'updated_at',
        direction: 'desc',
      }
    });
  }

  getCoupons(options?: object) {
    let requestOptions: object = {
      apiEndPoint: 'coupons',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 100
    };

    if (options) {
      requestOptions = Object.assign(requestOptions, options);
    }

    return this.getData(requestOptions);
  }

  getHistories(couponId: number, options?: object) {
    let requestOptions: object = {
      apiEndPoint: 'coupons/' + couponId + '/histories',
    };

    if (options) {
      requestOptions = Object.assign(requestOptions, options);
    }

    return this.getData(requestOptions);
  }

  getCouponById(id: number) {
    return this.getData({apiEndPoint: `coupons/${id}`});
  }

  deliverCouponToCustomer(selectedCouponId: number,  customer_id: number) {
    return this.postResource({coupon_id: selectedCouponId}, 'customers/' + customer_id + '/manual_coupon_deliver');
  }

  printUndoByVoucherId(voucherId: number): Observable<any> {
    return this.patchResource({}, `vouchers/${voucherId}/print_undo`);
  }

  cancelVoucherById(voucher_id): Observable<any> {
    return this.patchResource({}, `vouchers/${voucher_id}/cancel`);
  }

  cloneCoupons(ids: string[]): Observable<any> {
    return this.postResource({ ids }, 'coupons/clone');
  }

  archiveCoupons(coupon_ids: string[]): Observable<any> {
    return this.postResource({ coupon_ids }, 'coupons/archive');
  }

  unarchiveCoupons(coupon_ids: string[]): Observable<any> {
    return this.postResource({ coupon_ids }, 'coupons/unarchive');
  }

  fetchMultiselect( searchValues?: string, page?: number, filters?: object ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'coupons',
      numberPerPage: 10,
      filtering: {
        active: true
      },
      pageNumber: 1,
      sorting: {
        byProp: 'id',
        direction: 'asc'
      }
    };
    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;
    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { search_text: searchValues, active: true}};
      requestOptions = {...requestOptions, ...filterObj};
    }
    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getCouponById(id);
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`, element);
  }

  getCouponsById(id: number): Observable<any> {
    return this.getData({apiEndPoint: `coupons/${id}`});
  }
  getCouponsByHistoryId(id: number, history_id: number): Observable<any> {
    return this.getData({apiEndPoint: `coupons/${id}/histories/${history_id}`});
  }

  getCouponStatusList() {
    return [
      { id: 'deleted', name: this.translate.instant('resources.vouchers.states.deleted') },
      { id: 'expired', name: this.translate.instant('resources.vouchers.states.expired') },
      { id: 'pending', name: this.translate.instant('resources.vouchers.states.pending') },
      { id: 'printed', name: this.translate.instant('resources.vouchers.states.printed') },
      { id: 'redeemed', name: this.translate.instant('resources.vouchers.states.redeemed') },
      { id: 'selected', name: this.translate.instant('resources.vouchers.states.selected') },
      { id: 'view', name: this.translate.instant('resources.vouchers.states.viewed') },
      { id: 'cancelled', name: this.translate.instant('resources.vouchers.states.cancelled') }
    ];
  }

  getDiscountType() {
    const list = [
      { id: 'cash', name: this.translate.instant('resources.coupons.fields.cash', {currencySymbol: this.currencySymbol})},
      { id: 'percent', name: this.translate.instant('resources.coupons.fields.percent')},
    ];
    if(this.flags.showPointsOptionDemoSelex) {
      list.push({ id: 'Punti', name: this.translate.instant('resources.coupons.fields.points')},)
    }
    return list;
  }

  getCouponType() {
    return [
      { id: '2x1', name: this.translate.instant('resources.coupons.fields.2x1')},
      { id: '3x2', name: this.translate.instant('resources.coupons.fields.3x2')},
      { id: '2nd_unit', name: this.translate.instant('resources.coupons.fields.second_unit')},
      { id: 'min_units', name: this.translate.instant('resources.coupons.fields.min_units')}
    ];
  }

  getAppliesOptions() {
    return [
      { id: 'itself', name: this.translate.instant('resources.coupons.fields.itself')},
      { id: 'purchased', name: this.translate.instant('resources.coupons.fields.purchased')}
    ];
  }

  getAffectation(withProduct: boolean = false) {
    const elements = [
      { id: 'activity', name: this.translate.instant('resources.coupons.fields.activity')},
      { id: 'attribute', name: this.translate.instant('resources.coupons.fields.attribute')}
    ];
    if (withProduct) {
      elements.push({ id: 'product', name: this.translate.instant('resources.coupons.fields.product')});
    }
    return elements;
  }

  getInputs(formValues: any): QuestionBase<any>[] {
    const inputsArray = [
      new ImageQuestion({
        key: 'image',
        type: 'file',
        cssClasses: 'input-default',
        getValue: (value) => {
          return value.indexOf('missing') > -1 ? '' : value;
        }
      }),
      new TextboxQuestion({
        key: 'id',
        type: 'number',
        cssClasses: 'form-control input-default',
        getValue: (value) => value,
      }),
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'description',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'key',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'static_code',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new CheckboxQuestion({
        key: 'printable',
        label: this.translate.instant('resources.coupons.form.printable'),
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new CheckboxQuestion({
        key: 'cumulative',
        label: this.translate.instant('resources.coupons.form.cumulative'),
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        tooltipKey: this.translate.instant('resources.coupons.form.tooltip.cumulative'),
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'priority',
        type: 'number',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'available_days',
        type: 'number',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'available_from',
        type: 'date',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'available_to',
        type: 'date',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'print_from',
        type: 'date',
        cssClasses: 'form-control input-default',
        disabled: formValues && !formValues.printable ? true : false,
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'print_to',
        type: 'date',
        cssClasses: 'form-control input-default',
        disabled: formValues && !formValues.printable ? true : false,
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'discount',
        type: 'number',
        step: '0.01',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'discount_type',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        disabled: formValues && formValues.coupon_type ? true : false,
        options: this.getDiscountType()
      }),
      new TextboxQuestion({
        key: 'min_money',
        type: 'number',
        step: '0.01',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'min_units',
        type: 'number',
        step: '1',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'max_money',
        type: 'number',
        step: '0.01',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'max_units',
        type: 'number',
        step: '1',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'affectation',
        formKey: 'affectation',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: this.getAffectation()
      }),
      new MultiSelectQuestion({
        key: 'coupon_type',
        formKey: 'coupon_type',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: this.getCouponType()
      }),
      new MultiSelectQuestion({
        key: 'feature_pks',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.features,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        key: 'product_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.products,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        key: 'location_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locations,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        key: 'feature_location_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locationTaxonomyTermsService,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new TextboxQuestion({
        key: 'max_redemptions',
        type: 'number',
        step: '1',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'estimated_customer_redemption',
        type: 'number',
        step: '0.01',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'estimated_redemption',
        type: 'number',
        step: '0.01',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'estimated_delivery',
        type: 'number',
        step: '0.01',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'external_id',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new FileQuestion({
        key: 'csv',
        types: ['csv'],
        multiple: false,
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new CheckboxQuestion({
        key: 'funded',
        label: this.translate.instant('resources.coupons.form.funded'),
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new CheckboxQuestion({
        key: 'auto_select',
        label: this.translate.instant('resources.coupons.form.auto_select'),
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        tooltipKey: this.translate.instant('resources.coupons.form.auto_select_tooltip'),
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'provider_id',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new RadioQuestion({
        key: 'applies_to',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.getAppliesOptions(),
        value: formValues && formValues.applies_to ? formValues.applies_to : 'itself',
      }),
      new MultiSelectQuestion({
        key: 'purchased_feature_pks',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.features,
      }),
      new MultiSelectQuestion({
        key: 'purchased_affectation',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.features,
      }),
      new MultiSelectQuestion({
        key: 'available_languages',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: true, enableSearchFilter: false },
        options: this.languagesService.getLocalizedLanguages(),
      }),
      new CheckboxQuestion({
        key: 'reward_points',
        label: this.translate.instant('resources.coupons.form.reward_points'),
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        value: formValues.points && formValues.points_money ? true : false,
      }),
      new TextboxQuestion({
        key: 'points',
        type: 'number',
        step: '1',
        cssClasses: 'form-control input-default',
        getValue: (value) => value,
        disabled: !formValues.points && !formValues.points_money
      }),
      new TextboxQuestion({
        key: 'points_money',
        type: 'number',
        step: '0.01',
        cssClasses: 'form-control input-default',
        getValue: (value) => value,
        disabled: !formValues.points && !formValues.points_money
      }),
      new MultiSelectQuestion({
        key: 'partner_id',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        dataSource: this.partners,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        key: 'taxonomy',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.featuresTaxonomiesService,
        value: formValues ? [formValues?._embedded?.features_list[0]?.taxonomy?.name] : []
      })
    ];

    this.inputs = inputsArray;
    this.assignFormInputValues(formValues);

    Object.keys(formValues).map(
      key => {
        if (key === 'discount_type') {
          const discountTypeValue = this.getDiscountType().find( item => item.id === formValues[key]);
          const discountTypeSelected = discountTypeValue === undefined ? null : [discountTypeValue];
          this.inputs.find( input => input.key === 'discount_type' ).value = discountTypeSelected;
        }
        if (key === 'coupon_type') {
          const couponTypeValue = this.getCouponType().find( item => item.id === formValues[key]);
          const couponTypeSelected = couponTypeValue === undefined ? null : [couponTypeValue];
          this.inputs.find( input => input.key === 'coupon_type' ).value = couponTypeSelected;
        }
        if (key === 'available_languages') {
          const langValue = this.languagesService.getLocalizedLanguages().filter( item => formValues[key].includes(item.id));
          this.inputs.find( input => input.key === 'available_languages').value = langValue;
        }
        if (key === 'feature_pks') {
          if (formValues.coupon_type && formValues.applies_to === 'itself') {
            this.inputs.find( input => input.key === 'feature_pks' ).selectedIds = JSON.parse(formValues[key]);
          }
          if (!formValues.coupon_type && formValues[key]) {
            this.inputs.find( input => input.key === 'feature_pks' ).selectedIds = JSON.parse(formValues[key]);
          }
        }
        if (key === 'purchased_feature_pks') {
          if (formValues[key]) {
            if (formValues.applies_to === 'purchased') {
              this.inputs.find( input => input.key === 'feature_pks' ).settings['singleSelection'] = true;
              this.inputs.find( input => input.key === 'feature_pks' ).selectedIds = JSON.parse(formValues.purchased_feature_pks);
              this.inputs.find( input => input.key === 'purchased_feature_pks' ).selectedIds = JSON.parse(formValues.feature_pks);
            }
          }
        }
        if (key === 'feature_location_ids') {
          this.inputs.find( input => input.key === 'feature_location_ids' ).selectedIds = formValues[key];
        }
        if (key === 'location_ids') {
          this.inputs.find( input => input.key === 'location_ids' ).selectedIds = formValues[key];
        }
        if (key === 'product_ids') {
          this.inputs.find( input => input.key === 'product_ids' ).selectedIds = formValues[key];
        }
        if (key === 'affectation') {
          const affectationValue = this.getAffectation(true).find( item => item.id === formValues[key]);
          const affectationSelected = affectationValue === undefined ? null : [affectationValue];
          this.inputs.find( input => input.key === 'affectation' ).value = affectationSelected;
        }
      }
    );

    if (formValues['available_days'] && formValues['available_days'] > 0) {
      this.inputs.find(input => input.key === 'available_from').disabled = true;
      this.inputs.find(input => input.key === 'available_to').disabled = true;
    }

    if (Object.keys(formValues).length > 0) {
      this.inputs.find(input => input.key === 'id').hidden = true;
      this.inputs.find(input => input.key === 'key').disabled = true;
    }
    return this.inputs;
  }

  private assignFormInputValues(formValues) {
    Object.keys(formValues).map(
      key => {
        const tmpInput = this.inputs.find( input => input.key === key);
        if (tmpInput && tmpInput.getValue ) {
          const value = tmpInput.getValue(formValues[key]);
          if (value && value.hasOwnProperty('selectedIds')) {
            tmpInput.selectedIds = value.selectedIds;
          } else {
            tmpInput.value = value;
          }
        }
      }
    );
  }

  private validateProductData(data: {affectation: string, product_ids: number[]}) {
    if(data.affectation == 'product' && (!data.product_ids || data.product_ids.length == 0)) {
      return throwError(() => new HttpErrorResponse({
        status: 400,
        statusText: 'Bad Request',
        error: {
          error: this.translate.instant('resources.coupons.form.errors.product_ids_validation'),
          errors: [{
            field: 'product_ids',
            field_name: 'product_ids',
            message: this.translate.instant('resources.coupons.form.errors.product_ids_validation')
          }]
        }
      }));
    }
    return null;
  }
}
