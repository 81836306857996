<div class="container-fluid mrg-top-15">
  <div class="row">
    <div class="col-md-12">
      <app-loader *ngIf="!element" />
      <div class="card" *ngIf="element">
        <div class="card-heading">
          <h4 class="card-title d-inline">{{element.name}}</h4>
          <div>
            <small class="font-size-12">
              <b>{{'resources.segments.fields.id' | translate}}:</b> {{element.id}}
              <span *ngIf="element.segment_category && element.segment_category.name" class="font-size-12">
                <b>{{'resources.segments.fields.segment_category' | translate}}:</b> {{element.segment_category.name}}
              </span>
            </small>
          </div>
        </div>
        <div class="tab-info">
          <!-- Tabs menu -->
          <ul class="nav navigation" role="tablist">
            <li class="nav-item">
              <a href="#default-tab-1"
                (click)="refreshUrl('details')"
                [ngClass]="{'active': selectedTab === 'details'}"
                class="nav-link" role="tab" data-toggle="tab" [translate]="'resources.customers.tabs.details'">
              </a>
            </li>
            <li class="nav-item">
              <a href="#default-tab-2"
                (click)="refreshUrl('customers')"
                [ngClass]="{'active': selectedTab === 'customers'}"
                class="nav-link" role="tab" data-toggle="tab" [translate]="'resources.customers.tabs.customers'">
              </a>
            </li>

            <div class="col-xs-3" *ngIf="selectedTab === 'details' && !element.created_from_csv">
              <button class="btn btn-default btn-sm dropdown-toggle position" data-toggle="dropdown" type="button">
                <span class="font-size-10">{{'resources.segments.buttons.actions' | translate}}</span>
                <i class="ti-angle-down font-size-9"></i>
              </button>
              <ul class="dropdown-menu dropdown-menu-right font-size-10">
                <li class="cursor-pointer" *ngIf="element.applies > 0 && !actualStatus"><a (click)="getStatus()">{{'resources.segments.buttons.actual_status' | translate}}</a></li>
                <li class="cursor-pointer" *ngIf="element.applies > 0 && actualStatus"><a (click)="getLastView()">{{'resources.segments.buttons.last_apply' | translate}}</a></li>
                <li class="cursor-pointer" *ngIf="selectedTab === 'details'"><a (click)="refreshCache()">{{'resources.segments.buttons.cache' | translate}}</a></li>
                <li class="cursor-pointer" *ngIf="selectedTab === 'details' && !element.created_from_csv && !element.protected">
                  <a class="inherit" href="javascript:void(0)" [routerLink]="['', { outlets: { modal: ['show', 'segments', id, 'retro_tag'] } }]">
                    {{'resources.segments.buttons.retro_tag' | translate}}
                  </a>
                </li>
                <li class="cursor-pointer" *ngIf="!element.protected && element.applies > 0"><a (click)="removeLastTag()">{{'resources.segments.buttons.remove_last_tag' | translate}}</a></li>
              </ul>
            </div>
          </ul>
          <!-- Tabs content -->
        </div>
      </div>

      <div class="card" *ngIf="selectedTab === 'details'">
        <div class="tab-content" [ngClass]="{'mrg-top-15': selectedTab === 'customers'}">
          <!-- TAB DETAILS CONTENT-->
          <app-loader *ngIf="loadingReport && selectedTab === 'details'" />

          <div role="tabpanel"
                *ngIf="reportData"
                [ngClass]="{'in active': selectedTab === 'details'}"
                class="tab-pane fade"
                id="default-tab-1">
          </div>
          <div class="pdd-horizon-15 pdd-vertical-20" *ngIf="selectedTab === 'details'">

            <div *ngIf="reportData && reportData.count >= 0">
              <span class="label font-size-16">
                <b class="font-size-40">{{reportData.count | number:'':'es'}}</b>
              </span>
            </div>

            <div class="row pdd-horizon-15" *ngIf="element">
              <span *ngIf="element.last_execution" class="label label-info font-size-16 margin-5 no-mrg-left">
                <b>{{'resources.segments.fields.viewing' | translate}} {{element.last_execution | date: 'dd/MM/yyyy'}}</b>
              </span>
              <span *ngIf="element.created_from_csv" class="label label-info font-size-16 margin-5 no-mrg-left">
                <b>{{'resources.segments.fields.created_from_csv' | translate}}</b>
              </span>
            </div>

            <p *ngIf="element && element.description" class="mrg-left-15 mrg-top-10">
              {{element.description}}
            </p>

            <div class="row pdd-horizon-15" *ngIf="reportData">
              <span class="label label-danger font-size-14 margin-5 no-mrg-left">
                {{reportData.email_error_count}} <b [translate]="'resources.customers.tags.email_error_count'"></b>
              </span>
              <span class="label label-danger font-size-14 margin-5 no-mrg-left">
                {{reportData.email_empty_count}} <b [translate]="'resources.customers.tags.email_empty_count'"></b>
              </span>
              <span class="label label-info font-size-14 margin-5 no-mrg-left">
                {{reportData.email_pending_count}} <b [translate]="'resources.customers.tags.email_pending_count'"></b>
              </span>
              <span class="label label-danger font-size-14 margin-5 no-mrg-left">
                {{reportData.phone_error_count}} <b [translate]="'resources.customers.tags.phone_error_count'"></b>
              </span>
              <span class="label label-danger font-size-14 margin-5 no-mrg-left">
                {{reportData.phone_empty_count}} <b [translate]="'resources.customers.tags.phone_empty_count'"></b>
              </span>
              <span class="label label-danger font-size-14 margin-5 no-mrg-left">
                {{reportData.phone_prefix_empty_count}} <b [translate]="'resources.customers.tags.phone_prefix_empty_count'"></b>
              </span>
              <span class="label label-danger font-size-14 margin-5 no-mrg-left">
                {{reportData.phone_duplicated_count}} <b [translate]="'resources.customers.tags.phone_duplicated_count'"></b>
              </span>
              <span class="label label-danger font-size-14 margin-5 no-mrg-left">
                {{reportData.push_token_empty_count}} <b [translate]="'resources.customers.tags.push_token_empty_count'"></b>
              </span>
              <span class="label label-info font-size-14 margin-5 no-mrg-left">
                {{reportData.phone_pending_check_count}} <b [translate]="'resources.customers.tags.phone_pending_check_count'"></b>
              </span>
            </div>

          </div>
          <!-- END TAB DETAILS -->

          <!-- TAB CUSTOMERS -->
          <div role="tabpanel"
                [ngClass]="{'in active': selectedTab === 'customers'}"
                class="tab-pane fade"
                id="default-tab-2">
          </div>
        </div>
        <div class="row mrg-top-20 details-link mrg-btm-10 mrg-right-20" *ngIf="element && !element.created_from_csv">
          <a class="btn btn-default btn-md" href="#/show/{{element.id}}/segmentsV2" target="_blank" rel="noopener">
            {{'resources.segments.buttons.see_conditions' | translate}}
          </a>
        </div>
      </div>

      <div class="card padding-15" *ngIf="selectedTab === 'details' && element && element.applies > 0">
        <h4 class="card-title d-inline" [translate]="'resources.segments.fields.tag_history'"></h4>
        <app-data-table [config]="historyDataTableConfig"></app-data-table>
      </div>

      <app-data-table-filter *ngIf="filters && selectedTab === 'customers'"
                              [questions]="filters"
                              (onSubmitEmitter)="onFilterHandler($event)"
                              (onClearEmitter)="onFilterClearHandler()">
      </app-data-table-filter>

      <div class="card padding-15" *ngIf="element && selectedTab === 'customers'">
        <app-data-table [config]="customersTableConfig"></app-data-table>
      </div>

      <div class="modal-footer background-white" *ngIf="element && !isInModal" [ngClass]="{bordering: !isInModal}">
        <button *ngIf="element.operator && !element.created_from_csv" class="btn btn-info" (click)="handleCustomRedirect()">
          <span>{{ 'components.show.buttons.edit' | translate }}</span>
        </button>
        <button *ngIf="element.created_from_csv" class="btn btn-info" (click)="handleCustomCSVRedirect()">
          <span>{{ 'components.show.buttons.edit' | translate }}</span>
        </button>
        <button *ngIf="!element.operator && !element.created_from_csv" class="btn btn-info" [routerLink]="['', { outlets: { modal: ['update', 'segments', element.id] } }]">
          <span>{{ 'components.show.buttons.edit' | translate }}</span>
        </button>
			</div>
    </div>
  </div>
</div>

<!-- Export Sales Modal -->
<a #exportSalesModalBtn data-toggle="modal" data-target="#exportSales-modal" hidden></a>
<form [formGroup]="exportSalesForm" *ngIf="exportSalesForm">
  <div class="modal fade show" id="exportSales-modal">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content" *ngIf="exportSalesForm">
        <div class="modal-body">
          <a class="modal-close pull-right" data-dismiss="modal">
            <i class="ti-close"></i>
          </a>            
          <h4 class="card-title border bottom mrg-top-20" [translate]="'resources.segments.modal.export_sales.title'"></h4>
          
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <app-question [question]="getInputConfig('session_from')" [form]="exportSalesForm"></app-question>
              </div>
              <div class="form-group">
                <app-question [question]="getInputConfig('session_to')" [form]="exportSalesForm"></app-question>
              </div>
              <div class="form-group">
                <app-question [question]="getInputConfig('affectation')" [form]="exportSalesForm"></app-question>
              </div>             
              <div class="form-group" *ngIf="hasFormKeyWithValue('affectation', 'feature')">
                <app-question [question]="getInputConfig('features')" [form]="exportSalesForm"></app-question>
              </div>
            </div>
          </div>              
        </div>

        <div class="modal-footer">
          <div>
            <span>
              <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
            </span>
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="exportSales()" [disabled]="loading || !exportSalesForm.value.session_from || !exportSalesForm.value.session_to" [translate]="'resources.segments.buttons.export'"></button>
          </div>
        </div>

      </div>
    </div>
  </div>
</form>
