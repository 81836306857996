import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ConfigService {
  constructor(private http: HttpClient) {}

  getAppHost() {
    return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
  }
}
