import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MultiselectDataSource, MultiselectDataSourceable } from '../components/multiselect/multiselect';
import { ResourcesService } from './resources.service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DumpTablesService extends ResourcesService implements MultiselectDataSourceable {

  keyListName = 'tables';

  public optsSource = new BehaviorSubject<string>(null);
  public optsSource$ = this.optsSource.asObservable();

  constructor(
    http: HttpClient
  ){
    super(http);
  }

  public fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'bigquery_jobs/dump_tables',
      numberPerPage: 10,
      filtering: {},
      pageNumber: 1,
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  public fetchSelectedById?(tableNameId: number | string): Observable<any> {
    return this.getData({apiEndPoint: `bigquery_jobs/dump_tables`}).pipe(
      map((responseData: object) => responseData['tables']),
      map((tableNames: string[]) => tableNames.find(tableName => tableName === tableNameId)));
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element, element);
  }
}
