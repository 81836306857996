<div class="modal-backdrop fade in"
    id="reward-codes-batch-detail-modal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header border bottom">
        <h4 class="mrg-btm-15 mrg-top-8">
          {{'resources.scores.reward_code_batches.titles.detail' | translate}}
        </h4>
        <button class="modal-close" [attr.data-cy]="'button_closeRewardCodesBatchDetailModal'" data-dismiss="modal" (click)="closeModal()">
          <em class="ti-close"></em>
        </button>
      </div>
      <div class="modal-body background-gray">
        <div class="row">
          <div class="col">
            <div class="card no-pdd-btm" [ngClass]="batch?.status === 'error' ? '' : 'height-100'">
              <div class="card-block">
                <div class="row">
                  <div class="col-md-12">
                    <div class="alert alert-danger" *ngIf="batch?.status === 'error'">
                      {{batch.error_message}}
                    </div>
                    <app-data-table *ngIf="dataTableConfig" [config]="dataTableConfig"></app-data-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer background-white align-content-center">
				<div class="pull-right d-flex align-items-center">
					<button class="pull-right btn btn-info no-mrg-right"
									[translate]="'common.buttons.accept'"
                  (click)="closeModal()">
          </button>
				</div>
			</div>
    </div>
  </div>
</div>
