
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MODULE_HELP_CENTER_LINKS } from '../../shared/constants/module-helpcenter-links.constants';
import { MODULE_ICONS } from '../../shared/constants/module-icons.constants';
import { Modules } from '../../shared/enums/modules.enums';
import { LaunchDarklyService } from '../../shared/services/launch-darkly/launchdarkly.service';
import { SideNavService } from '../side-nav/side-nav.service';
import { ProfileService } from './../../profiles/profile.service';
import { FeatureFlagsService } from './../../shared/services/feature-flags.service';
import { HomeItem, homeModules } from './home-modules.constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  modules: HomeItem[] = [];
  storedUser = this.profileService.getStoredUser();
  flags = this.featureFlags.flags;
  helpCenterLinks = MODULE_HELP_CENTER_LINKS;
  icons = MODULE_ICONS;

  constructor(
    private router: Router,
    private featureFlags: FeatureFlagsService,
    private profileService: ProfileService,
    private sideNavService: SideNavService,
    private launchDarklyService: LaunchDarklyService
  ) { }

  ngOnInit(): void {
    this.setModules().catch(() => {});
  }

  private async setModules(): Promise<void> {
    const defaultHomeModules = [...homeModules];
    return this.launchDarklyService.client.waitUntilReady().then(() => {
      if (!this.flags.loyaltyAnalyticsProgramPerformanceVisibility) {
        const loyaltyAnalyticsModule = defaultHomeModules.find(module => module.id === Modules.LoyaltyAnalytics);
        loyaltyAnalyticsModule.route = 'points-rewards';
      }
      this.modules = this.sideNavService.setModulePermissions([...defaultHomeModules]);
    });
  }

  navigate(module: HomeItem): void {
    const url = `/${module.id}/${module.route}`;
    this.router.navigateByUrl(url).catch(() => {});
  }

  isNotAuth(module: HomeItem): boolean {
    return this.sideNavService.isNotAuth(module);
  }

  showNotAvailable(): void {
    this.sideNavService.showNotAvailable();
  }

  openHelpCenter(moduleId: string, event: Event): void {
    event.stopPropagation();
    window.open(this.helpCenterLinks[moduleId], '_blank');
  }

}
