<div class="alert alert-info" role="alert" *ngIf="!onSearch && !loading">
  <span>{{ 'components.looker-iframe.alerts.apply_filters' | translate }}</span>
</div>

<div class="row" *ngIf="onSearch">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title d-inline" [translate]="'dashboards.club.tabs.evolution.title'"></h4>
      </div>

      <app-loader styleClass="mrg-btm-15" *ngIf="loading" />

      <div class="card-block no-pdd no-mrg pdd-horizon-20 pdd-btm-5">
        <table class="table table-hover table-sm table-bordered" aria-hidden="true" *ngIf="!loading">
          <thead>
            <tr class="highlight-header">
              <th scope="auto" colspan="1" class="text-center">
                {{ 'dashboards.club.tabs.evolution.columns.month' | translate }}
              </th>
              <th scope="auto" colspan="3" class="text-center">
                {{ 'dashboards.club.tabs.evolution.columns.spent_average' | translate }}
              </th>
              <th scope="auto" colspan="3" class="text-center">
                {{ 'dashboards.club.tabs.evolution.columns.ticket_average' | translate }}
              </th>
              <th scope="auto" colspan="3" class="text-center">
                {{ 'dashboards.club.tabs.evolution.columns.frequency' | translate }}
              </th>
              <th scope="auto" colspan="3" class="text-center">
                {{ 'dashboards.club.tabs.evolution.columns.total_sales' | translate }}
                <i class="tooltip-position ti-info-alt mrg-left-5 total-sales-position" tooltipPosition="left" pTooltip="{{ 'dashboards.sales.tooltips.total_sales_text' | translate }}"></i>
              </th>
              <th scope="auto" colspan="3" class="text-center">
                {{ 'dashboards.club.tabs.evolution.columns.identified_sales' | translate }}
              </th>
              <th scope="auto" colspan="3" class="text-center">
                {{ 'dashboards.club.tabs.evolution.columns.percentage_sales' | translate }}
              </th>
              <th scope="auto" colspan="3" class="text-center">
                {{ 'dashboards.club.tabs.evolution.columns.active_customers' | translate }}
              </th>
            </tr>
            <tr class="highlight-header">
              <th scope="auto"></th>
              <!-- spend average -->
              <th scope="auto" class="text-center" [innerText]="filter.year"></th>
              <th scope="auto" class="text-center" [innerText]="filter.compared_year"></th>
              <th scope="auto" class="text-center">
                {{ 'dashboards.club.tabs.evolution.columns.diff' | translate }}
              </th>
              <!--  -->
              <th scope="auto" class="text-center" [innerText]="filter.year"></th>
              <th scope="auto" class="text-center" [innerText]="filter.compared_year"></th>
              <th scope="auto" class="text-center">
                {{ 'dashboards.club.tabs.evolution.columns.diff' | translate }}
              </th>
              <!--  -->
              <th scope="auto" class="text-center" [innerText]="filter.year"></th>
              <th scope="auto" class="text-center" [innerText]="filter.compared_year"></th>
              <th scope="auto" class="text-center">
                {{ 'dashboards.club.tabs.evolution.columns.diff' | translate }}
              </th>
              <!--  -->
              <th scope="auto" class="text-center" [innerText]="filter.year"></th>
              <th scope="auto" class="text-center" [innerText]="filter.compared_year"></th>
              <th scope="auto" class="text-center">
                {{ 'dashboards.club.tabs.evolution.columns.diff' | translate }}
              </th>
              <!--  -->
              <th scope="auto" class="text-center" [innerText]="filter.year"></th>
              <th scope="auto" class="text-center" [innerText]="filter.compared_year"></th>
              <th scope="auto" class="text-center">
                {{ 'dashboards.club.tabs.evolution.columns.diff' | translate }}
              </th>
              <!--  -->
              <th scope="auto" class="text-center" [innerText]="filter.year"></th>
              <th scope="auto" class="text-center" [innerText]="filter.compared_year"></th>
              <th scope="auto" class="text-center">
                {{ 'dashboards.club.tabs.evolution.columns.diff' | translate }}
              </th>
              <!--  -->
              <th scope="auto" class="text-center" [innerText]="filter.year"></th>
              <th scope="auto" class="text-center" [innerText]="filter.compared_year"></th>
              <th scope="auto" class="text-center">
                {{ 'dashboards.club.tabs.evolution.columns.diff' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr [ngClass]="{'highlight-header': i === clubEvolutionData.length - 1 }"
                *ngFor="let row of clubEvolutionData; let i = index;">

              <!-- Month name -->
              <td class="text-left">
                <span class="font-size-12 text-dark">
                  <b>{{row.month}}</b>
                </span>
              </td>

              <!-- Spending average -->
              <td class="text-right">
                <span class="font-size-10">
                  {{ row.yearSpentAverage | lgcurrency: '1.2-2' }}
                </span>
              </td>
              <td class="text-right">
                <span class="font-size-10">
                  {{ row.comparedYearSpentAverage | lgcurrency: '1.2-2' }}
                </span>
              </td>
              <td class="text-right">
                <span class="font-size-10 label"
                      [ngClass]="{ 'label-success': row.diffSpentAverage > 0.0,
                                   'label-danger': row.diffSpentAverage < 0.0,
                                   'label-warning': row.diffSpentAverage === 0.0} ">
                  {{ row.diffSpentAverage | number: '1.2-2' }}%
                </span>
              </td>

              <!-- Average ticket -->
              <td class="text-right">
                <span class="font-size-10">
                  {{ row.yearTicketAverage | lgcurrency: '1.2-2' }}
                </span>
              </td>
              <td class="text-right">
                <span class="font-size-10">
                  {{ row.comparedYearTicketAverage | lgcurrency: '1.2-2' }}
                </span>
              </td>
              <td class="text-right">
                <span class="font-size-10 label"
                      [ngClass]="{ 'label-success': row.diffTicketAverage > 0.0,
                                   'label-danger': row.diffTicketAverage < 0.0,
                                   'label-warning': row.diffTicketAverage === 0.0 } ">
                  {{ row.diffTicketAverage | number: '1.2-2' }}%
                </span>
              </td>

              <!-- Freq -->
              <td class="text-right">
                <span class="font-size-10">
                  {{ row.yearFrequency | number: '1.2-2' }}
                </span>
              </td>
              <td class="text-right">
                <span class="font-size-10">
                  {{ row.comparedYearFrequency | number: '1.2-2' }}
                </span>
              </td>
              <td class="text-right">
                <span class="font-size-10 label"
                      [ngClass]="{ 'label-success': row.diffFrequency > 0.0,
                                   'label-danger': row.diffFrequency < 0.0,
                                   'label-warning': row.diffFrequency === 0.0 } ">
                  {{ row.diffFrequency | number: '1.2-2' }}%
                </span>
              </td>

              <!-- is not filtering by segment -->
              <!-- Total sales -->
              <ng-container *ngIf="!isFilteringBySegment">
                <td class="text-right">
                  <span class="font-size-10">
                    {{ row.yearTotalSales | lgcurrency: '1.0-0' }}
                  </span>
                </td>
                <td class="text-right">
                  <span class="font-size-10">
                    {{ row.comparedYearTotalSales | lgcurrency: '1.0-0' }}
                  </span>
                </td>
                <td class="text-right">
                  <span class="font-size-10 label"
                        [ngClass]="{ 'label-success': row.diffTotalSales > 0.0,
                                     'label-danger': row.diffTotalSales < 0.0,
                                     'label-warning': row.diffTotalSales === 0.0 } ">
                    {{ row.diffTotalSales | number: '1.2-2' }}%
                  </span>
                </td>

                <!-- Identified sales -->
                <td class="text-right">
                  <span class="font-size-10">
                    {{ row.yearTotalIdentifiedSales | lgcurrency: '1.0-0' }}
                  </span>
                </td>
                <td class="text-right">
                  <span class="font-size-10">
                    {{ row.comparedYearTotalIdentifiedSales | lgcurrency: '1.0-0' }}
                  </span>
                </td>
                <td class="text-right">
                  <span class="font-size-10 label"
                        [ngClass]="{ 'label-success': row.diffIdentifiedSales > 0.0,
                                     'label-danger': row.diffIdentifiedSales < 0.0,
                                     'label-warning': row.diffIdentifiedSales === 0.0 } ">
                    {{ row.diffIdentifiedSales | number: '1.2-2' }}%
                  </span>
                </td>

                <!-- % Identified sales -->
                <td class="text-right">
                  <span class="font-size-10">
                    {{ row.pctgYearIdentifiedSales | number: '1.2-2' }}%
                  </span>
                </td>
                <td class="text-right">
                  <span class="font-size-10">
                    {{ row.pctgComparedYearIdentifiedSales | number: '1.2-2' }}%
                  </span>
                </td>
                <td class="text-right">
                  <span class="font-size-10 label"
                        [ngClass]="{ 'label-success': row.diffPctgIdentifiedSales > 0.0,
                                     'label-danger': row.diffPctgIdentifiedSales < 0.0,
                                     'label-warning': row.diffPctgIdentifiedSales === 0.0 } ">
                    {{ row.diffPctgIdentifiedSales | number: '1.2-2' }}
                  </span>
                </td>
              </ng-container>
              <!-- END is not filtering by segment -->

              <!-- is filtering by segment -->
              <!-- Total sales -->
              <ng-container *ngIf="isFilteringBySegment">
                <td class="text-right">
                  <span class="font-size-10">
                    {{ clubEvolutionTotalSalesData[i].yearTotalSales | lgcurrency: '1.0-0' }}
                  </span>
                </td>
                <td class="text-right">
                  <span class="font-size-10">
                    {{ clubEvolutionTotalSalesData[i].comparedYearTotalSales | lgcurrency: '1.0-0' }}
                  </span>
                </td>
                <td class="text-right">
                  <span class="font-size-10 label"
                        [ngClass]="{ 'label-success': clubEvolutionTotalSalesData[i].diffTotalSales > 0.0,
                                     'label-danger': clubEvolutionTotalSalesData[i].diffTotalSales < 0.0,
                                     'label-warning': clubEvolutionTotalSalesData[i].diffTotalSales === 0.0 } ">
                    {{ clubEvolutionTotalSalesData[i].diffTotalSales | number: '1.2-2' }}%
                  </span>
                </td>

                <!-- Identified sales -->
                <td class="text-right">
                  <span class="font-size-10">
                    {{ clubEvolutionTotalSalesData[i].yearTotalIdentifiedSales | lgcurrency: '1.0-0' }}
                  </span>
                </td>
                <td class="text-right">
                  <span class="font-size-10">
                    {{ clubEvolutionTotalSalesData[i].comparedYearTotalIdentifiedSales | lgcurrency: '1.0-0' }}
                  </span>
                </td>
                <td class="text-right">
                  <span class="font-size-10 label"
                        [ngClass]="{ 'label-success': clubEvolutionTotalSalesData[i].diffIdentifiedSales > 0.0,
                                     'label-danger': clubEvolutionTotalSalesData[i].diffIdentifiedSales < 0.0,
                                     'label-warning': clubEvolutionTotalSalesData[i].diffIdentifiedSales === 0.0 } ">
                    {{ clubEvolutionTotalSalesData[i].diffIdentifiedSales | number: '1.2-2' }}%
                  </span>
                </td>

                <!-- % Identified sales -->
                <td class="text-right">
                  <span class="font-size-10">
                    {{ clubEvolutionTotalSalesData[i].pctgYearIdentifiedSales | number: '1.2-2' }}%
                  </span>
                </td>
                <td class="text-right">
                  <span class="font-size-10">
                    {{ clubEvolutionTotalSalesData[i].pctgComparedYearIdentifiedSales | number: '1.2-2' }}%
                  </span>
                </td>
                <td class="text-right">
                  <span class="font-size-10 label"
                        [ngClass]="{ 'label-success': clubEvolutionTotalSalesData[i].diffPctgIdentifiedSales > 0.0,
                                     'label-danger': clubEvolutionTotalSalesData[i].diffPctgIdentifiedSales < 0.0,
                                     'label-warning': clubEvolutionTotalSalesData[i].diffPctgIdentifiedSales === 0.0 } ">
                    {{ clubEvolutionTotalSalesData[i].diffPctgIdentifiedSales | number: '1.2-2' }}
                  </span>
                </td>
              </ng-container>
              <!-- END is filtering by segment -->

              <!-- Active customers -->
              <td class="text-right">
                <span class="font-size-10">
                  {{ row.yearActiveCustomers | number: '1.0-0'}}
                </span>
              </td>
              <td class="text-right">
                <span class="font-size-10">
                  {{ row.comparedYearActiveCustomers | number: '1.0-0'}}
                </span>
              </td>
              <td class="text-right">
                <span class="font-size-10 label"
                      [ngClass]="{ 'label-success': row.diffActiveCustomers > 0.0,
                                   'label-danger': row.diffActiveCustomers < 0.0,
                                   'label-warning': row.diffActiveCustomers === 0.0 } ">
                  {{ row.diffActiveCustomers | number: '1.2-2' }}%
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
