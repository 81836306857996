<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true" *ngIf="redemptionsData">
              <tbody>
                <tr *ngIf="redemptionsData.created_at">
                  <td class="no-border-top"><b [translate]="'resources.scores.redemptions.show.fields.date'"></b></td>
                  <td class="no-border-top">{{redemptionsData.created_at | dateTimeZone: 'DD/MM/YYYY HH:mm:ss'}}</td>
                </tr>
                <tr *ngIf="redemptionsData?.location?.name">
                  <td><b [translate]="'resources.scores.redemptions.show.fields.location_name'"></b></td>
                  <td><a class="pointer colorLink" (click)="goToLocation()">{{redemptionsData.location.name}}</a></td>
                </tr>
                <tr *ngIf="redemptionsData?.reward?.name">
                  <td><b [translate]="'resources.scores.redemptions.show.fields.reward'"></b></td>
                  <td>
                    <a class="pointer colorLink" (click)="goToReward()">
                      {{redemptionsData.reward.name}}
                    </a>
                  </td>
                </tr>
                <tr *ngIf="redemptionsData?.profile?._embedded?.customer?.name">
                  <td><b [translate]="'resources.scores.redemptions.show.fields.profile'"></b></td>
                  <td>
                    <a class="pointer colorLink" (click)="goToProfile(redemptionsData.profile._embedded.customer.id)">
                      {{getFullName()}}
                    </a>
                  </td>
                </tr>
                <tr *ngIf="redemptionsData?._embedded?.score_expense?.points">
                  <td><b [translate]="'resources.scores.redemptions.show.fields.score'"></b></td>
                  <td>{{redemptionsData._embedded.score_expense.points}}</td>
                </tr>
                <tr *ngIf="redemptionsData?.code">
                  <td><b [translate]="'resources.scores.redemptions.show.fields.code'"></b></td>
                  <td>{{redemptionsData.code}}</td>
                </tr>
                <tr *ngIf="redemptionsData?.origin_type">
                  <td><b [translate]="'resources.scores.redemptions.columns.origin_type'"></b></td>
                  <td>{{redemptionsData.origin_type.charAt(0).toUpperCase() + redemptionsData.origin_type.slice(1).toLowerCase()}}</td>
                </tr>
                <tr *ngIf="!redemptionsData?.origin_type">
                  <td><b [translate]="'resources.scores.redemptions.columns.origin_type'"></b></td>
                  <td>{{'resources.scores.loyalty_scores.fields.manual' | translate}}</td>
                </tr>
                <tr *ngIf="redemptionsData?.origin_id">
                  <td><b [translate]="'resources.scores.redemptions.columns.origin_id'"></b></td>
                  <td>{{redemptionsData.origin_id}}</td>
                </tr>
                <tr *ngIf="redemptionsData?.loyalty_rule_id">
                  <td><b [translate]="'resources.scores.redemptions.columns.loyalty_rule_id'"></b></td>
                  <td><a class="pointer colorLink" (click)="goToLoyaltyRule()">{{redemptionsData.loyalty_rule_id}}</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
