import { Injectable } from '@angular/core';
import { LocationsTaxonomyTermsService } from '../../../../../resources/data-warehouse/locations/location-taxonomy-terms.service';
import { LocationsService } from '../../../../../resources/data-warehouse/locations/locations.service';
import { FeatureTaxonomiesService } from '../../../../../resources/data-warehouse/products/feature-taxonomies.service';
import { AnalyticsService } from '../../../../../shared/services/analytics/analytics.service';
import { DateService } from '../../../../../shared/services/date.service';
import { FeaturesService } from '../../../../../resources/data-warehouse/products/features.service';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { FilterConfiguration } from '../../../data-table-filter/data-table-filter-cfg';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';
import { CfgFilters } from '../../models/cfg-filters.model';
import { LookerCfgGlobalFiltersService } from '../looker-cfg-global-filters/looker-cfg-global-filters.service';
import { ProfileService } from '../../../../../profiles/profile.service';

@Injectable()
export class LookerGlobalFiltersService {

  constructor(
    private analyticsService: AnalyticsService,
    private dateService: DateService,
    private locationTaxonomyTerms: LocationsTaxonomyTermsService,
    private locationsService: LocationsService,
    private lookerCfgGlobalFilters: LookerCfgGlobalFiltersService,
    private productTaxonomiesService: FeatureTaxonomiesService,
    private profileService: ProfileService
  ) {}

  getGlobalFilters(
    supplierFeaturesService: FeaturesService, brandFeaturesService: FeaturesService, cfg: FilterConfiguration, 
    currencySymbol: string, supplierTaxonomies: object, brandTaxonomies: object
  ): QuestionBase<any>[] {

    supplierFeaturesService.treatPkAsId = true;
    brandFeaturesService.treatPkAsId = true;

    const supplierTaxonomySlug = this.getSupplierInputFilter() || this.productTaxonomiesService.getTaxonomySlug(supplierTaxonomies);
    const brandTaxonomySlug = this.productTaxonomiesService.getTaxonomySlug(brandTaxonomies);
    brandFeaturesService.setTaxonomies(brandTaxonomySlug);

    const dateFrom = this.dateService.momentStartOfPreviousPeriodTypeAndFormat(1, 'months', 'month', 'YYYY-MM-DD');
    const dateTo = this.dateService.momentEndOfPreviousPeriodTypeAndFormat(1, 'months', 'month', 'YYYY-MM-DD');

    let globalFilters: QuestionBase<any>[] = this.getDefaultGlobalFilters(dateFrom, dateTo, supplierFeaturesService, supplierTaxonomySlug);

    if (cfg) {
      const cfgGlobalFilters = this.lookerCfgGlobalFilters.getCfgGlobalFilters(dateFrom, dateTo, currencySymbol, brandFeaturesService);
      this.addCfgFilters(cfg, cfgGlobalFilters, globalFilters);
    }

    globalFilters = this.filterGlobalFilters(globalFilters, cfg);
    return globalFilters;
  }

  private getDefaultGlobalFilters(dateFrom: any, dateTo: any, supplierFeaturesService: FeaturesService, supplierTaxonomySlug: string): QuestionBase<any>[] {
    return [
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_from',
        label: 'dashboards.sales.filters.available_from',
        type: 'date',
        value: dateFrom,
        required: true
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_to',
        label: 'dashboards.sales.filters.available_to',
        type: 'date',
        value: dateTo,
        required: true
      }),
      new MultiSelectQuestion({
        key: 'supplier',
        label: 'components.looker-dashboard-filter.filters.supplier',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: true},
        dataSource: supplierFeaturesService,
        filters: {taxonomy_slug: supplierTaxonomySlug ? supplierTaxonomySlug : 'none'}
      }),
      new MultiSelectQuestion({
        key: 'brand',
        label: 'components.looker-dashboard-filter.filters.brand_type',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.analyticsService.getOwnBrandOptions()
      }),
      new MultiSelectQuestion({
        key: 'location_taxonomy_term_ids',
        label: 'dashboards.sales.filters.location_taxonomy_terms',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locationTaxonomyTerms
      }),
      new MultiSelectQuestion({
        key: 'location_ids',
        label: 'dashboards.sales.filters.locations',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locationsService,
      })
    ];
  }

  private addCfgFilters(cfg: FilterConfiguration, cfgGlobalFilters: CfgFilters[], globalFilters: QuestionBase<any>[]): void {
    Object.keys(cfg).forEach(key => {
      const cfgFilterItem = cfgGlobalFilters.find(cfgFltr => cfgFltr.cfgKey === key);
      if (cfgFilterItem && cfg[key]) {
        if (Array.isArray(cfgFilterItem.cfgFilters)) {
          cfgFilterItem.cfgFilters.forEach(item => globalFilters.push(item));
        } else {
          globalFilters.push(cfgFilterItem.cfgFilters);
        }
      }
    });
  }

  private filterGlobalFilters(globalFilters: QuestionBase<any>[], cfg: FilterConfiguration): QuestionBase<any>[] {
    if (cfg?.hideBrandSupplierFilters) {
      const filtersToRemove = ['supplier', 'brand'];
      globalFilters = globalFilters.filter(fltr => !filtersToRemove.includes(fltr.key));
    }
    return globalFilters;
  }

  private getSupplierInputFilter(): string {
    return this.profileService.getStoredUser().company.plans_configuration?.analytics_supplier_taxonomy;
  }
}