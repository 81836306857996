<!-- BLOQUE1 -->
  <div class="widget-profile-1 card height-100" *ngIf="couponDetail">
    <div class="profile border bottom">
      <img *ngIf="!isMissingImg" class="mrg-top-30 width-150" src="{{couponDetail.thumbnail_medium}}" alt="thumbnail">
      <img *ngIf="isMissingImg" class="mrg-top-30 width-150" src="../../../../../../assets/images/others/no_image.png" alt="thumbnail">
      <h4 class="mrg-top-20 no-mrg-btm text-semibold"
        [translate]="'resources.coupons.modal.show.details.fields.coupon_id'"></h4>{{couponDetail.id}}
      <p class="mrg-top-20 no-mrg-btm text-semibold text-dark"
        [translate]="'resources.coupons.modal.show.details.fields.coupon_key'"></p>
      <p>{{couponDetail.key}}</p>
      <p class="mrg-top-20 no-mrg-btm text-semibold text-dark"
         [translate]="'resources.coupons.modal.show.details.fields.external_id'" *ngIf="couponDetail.external_id"></p>
      <p>{{couponDetail.external_id}}</p>
    </div>
    <div class="mrg-left-15 mrg-top-20">
      <h5 class="text-semibold mrg-btm-5 mrg-left-15" [translate]="'resources.coupons.modal.show.details.fields.name'">
      </h5>
      <p class="mrg-left-15">{{couponDetail.name}}</p>
    </div>
    <div class="mrg-left-15" *ngIf="couponDetail.description">
      <h5 class="text-semibold mrg-btm-5 mrg-left-15"
        [translate]="'resources.coupons.modal.show.details.fields.description'"></h5>
      <p class="mrg-left-15">{{couponDetail.description}}</p>
    </div>
  </div>
<!-- END BLOQUE1 -->
