import { CustomerJourney, CustomerJourneysCfg } from './customer-journey';

export class CustomJourney extends CustomerJourney {

  constructor(apiObject: CustomerJourneysCfg) {
    super(apiObject);
    this.campaign_category_id = apiObject.campaign_category_id ? [apiObject.campaign_category_id] : [];
  }

  public getNameWithAttributes(): string {
    return `Custom`;
  }
}
