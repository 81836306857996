import { Component, OnInit, SecurityContext } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { CmsService } from '../cms.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { DateService } from '../../../../shared/services/date.service';
import { Cms } from '../../../../shared/models/cms/cms';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-form-cms',
  templateUrl: './form-cms.component.html',
  styleUrls: ['./form-cms.component.css'],
  providers: [CmsService]
})
export class FormCmsComponent implements OnInit {

  public cmsForm: UntypedFormGroup;
  public cmsData: Cms;
  public inputs: QuestionBase<any>[];
  public id: number;
  public loading: boolean;
  public imageData: { filename: any; filesize: any; filetype: any; base64: string; };
  public thumbnail: string;
  public isMissingImg: string;
  public config = {
    height: 200,
    tabDisable: true,
    toolbar: [
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['h2', ['h2btn']],
      ['h3', ['h3btn']],
      ['wrapEsBtn', ['esBtn']],
      ['wrapCaBtn', ['caBtn']],
      ['wrapEnBtn', ['enBtn']],
      ['wrapDeBtn', ['deBtn']],
      ['wrapFrBtn', ['frBtn']],
      ['edit', ['undo' , 'redo']],
      ['view', ['codeview']]
    ],
    buttons: {
      h2btn: this.h2BtnWrapper.bind(this),
      h3btn: this.h3BtnWrapper.bind(this),
      esBtn: this.esBtnWrapper.bind(this),
      caBtn: this.caBtnWrapper.bind(this),
      deBtn: this.enBtnWrapper.bind(this),
      enBtn: this.deBtnWrapper.bind(this),
      frBtn: this.frBtnWrapper.bind(this)
    }
  };

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private cmsService: CmsService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private dateService: DateService,
    private translate: TranslateService,
    private modalStatusService: ModalStatusService,
    private sanitizer: DomSanitizer
  ) {
    this.cmsData = {
      availableTo: '',
      body:  '',
      createdAt:  '',
      publishAt:  '',
      thumbnail:  '',
      title:  ''
    };
  }

  ngOnInit() {
    this.getParams();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.imageData = {
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
          base64: reader.result.toString().split(',')[1]
        };
      };
    }
  }

  sendData() {
    const cmsOptions = {
      publish_at: this.cmsForm.value.publish_at ? this.cmsForm.value.publish_at : null,
      available_to: this.cmsForm.value.available_to ? new Date(this.cmsForm.value.available_to) : null,
      news_taxonomy_terms: this.cmsForm.value.news_taxonomy_terms ? this.cmsForm.value.news_taxonomy_terms.map(el => el.rawElement) : [],
      id: this.id,
      title: this.getHtmlContent('#newsTitle'),
      body: this.getHtmlContent('#newsBody'),
    };

    if (this.imageData) { cmsOptions['image'] = this.imageData; }

    if (this.cmsForm.value.publish_at && this.cmsForm.value.available_to) {
      cmsOptions['available_period'] = 'Del ' + this.dateService.momentFormat(this.cmsForm.value.publish_at, 'DD/MM/YYYY' ) + ' al ' + this.dateService.momentFormat(this.cmsForm.value.available_to, 'DD/MM/YYYY');
    } else if (this.cmsForm.value.publish_at) {
      cmsOptions['available_period'] = 'A partir del ' + this.dateService.momentFormat(this.cmsForm.value.publish_at, 'DD/MM/YYYY');
    } else if (this.cmsForm.value.available_to) {
      cmsOptions['available_period'] = 'Hasta el ' + this.dateService.momentFormat(this.cmsForm.value.available_to, 'DD/MM/YYYY');
    } else {
      cmsOptions['available_period'] = 'Siempre';
    }

    if (this.id) {
      this.loading = true;
      this.thumbnail = cmsOptions['thumbnail'] = this.thumbnail;

      this.cmsService.updateData(cmsOptions, this.id).subscribe(response => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.cms.form.warnings.update_title'),
          this.translate.instant('resources.cms.form.warnings.update_text')
        ).catch(() => {});
        this.loading = false;
        this.modalStatusService.modalStatus.emit();
        this.returnToList();
      },
        errorData => {
          this.loading = false;
          this.confirmationService.displayErrorAlert('Error', errorData.error.error);
        });
    } else {
      this.loading = true;
      this.cmsService.sendData(cmsOptions).subscribe(response => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.cms.form.warnings.success_title'),
          this.translate.instant('resources.cms.form.warnings.success_text')
        ).catch(() => {});
        this.loading = false;
        this.modalStatusService.modalStatus.emit();
        this.returnToList();
      },
        errorData => {
          this.loading = false;
          this.confirmationService.displayErrorAlert('Error', errorData.error.error);
        });
    }
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getCmsById(this.id);
      } else {
        this.inputs = this.cmsService.setInputs({});
        this.cmsForm = this.qcs.toFormGroup(this.inputs);
      }
    });
  }

  private getCmsById(id: number) {
    this.cmsService.getById(this.id).subscribe(
      data => {
        this.cmsData = data;
        this.thumbnail = data['thumbnail'];
        if (this.thumbnail === 'images/thumbnail/missing.png') {
          this.isMissingImg = this.thumbnail;
        }
        this.inputs = this.cmsService.setInputs(data);
        this.cmsForm = this.qcs.toFormGroup(this.inputs);
      }
    );
  }

  private returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/omnichannel-manager/cms']).catch(() => {});
    }
  }

  // Summernote
  private h2BtnWrapper (context) {
    return this.headerWrapper('h2').render();
  }

  private h3BtnWrapper (context) {
    return this.headerWrapper('h3').render();
  }

  private caBtnWrapper (context) {
    return this.btnWrapper('es-CA').render();
  }

  private esBtnWrapper (context) {
    return this.btnWrapper('es-ES').render();
  }

  private deBtnWrapper (context) {
    return this.btnWrapper('de-DE').render();
  }

  private enBtnWrapper (context) {
    return this.btnWrapper('en-EN').render();
  }

  private frBtnWrapper (context) {
    return this.btnWrapper('fr-FR').render();
  }

  private headerWrapper(header) {
    const ui = $.summernote.ui;
    const button = ui.button({
      container: false,
      contents: header,
      tooltip: 'Encapsular contenido para tag <' + header + '>',
      click: function () {
        const range = window.getSelection().getRangeAt(0);
        const el = document.createElement(header);
        el.appendChild(range.extractContents());
        range.insertNode(el);
      }
    });
    return button;
  }

  private btnWrapper (lang) {
    const ui = $.summernote.ui;
    const button = ui.button({
      container: false,
      contents: '<i class="fa fa-globe"/> ' + lang,
      tooltip: 'Encapsular contenido para idioma ' + lang,
      click: function () {
        const range = window.getSelection().getRangeAt(0);
        const div = document.createElement('div');
        div.setAttribute('lang', lang);
        div.appendChild(range.extractContents());
        range.insertNode(div);
      }
    });
    return button;
  }

  private getHtmlContent(id: string): string {
    let htmlContent: string;
    const codeviewActive = $(id).find('.btn-codeview').hasClass('active');

    if (codeviewActive) {
      htmlContent = $(id).find('.note-codable').val();
    } else {
      htmlContent = $(id).find('.note-editable').html();
    }

    return this.sanitizer.sanitize(SecurityContext.HTML, htmlContent);
  }

}
