import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { QuestionBase } from "../../../../../shared/models/forms/question-base";
import { CsvExportService } from "../../../../../shared/services/csv-export.service";
import { ModalStatusService } from "../../../../../shared/services/modal-status.service";
import { QuestionControlService } from "../../../../../shared/services/question-control.service";
import { S3Service } from "../../../../../shared/services/s3.service";
import { CouponsService } from "../../../../coupons/coupons.service";
import { ConfirmationService } from "../../../../../shared/services/confirmation.service";
import { RewardsService } from "../../rewards.service";
import { FileQuestion } from "../../../../../shared/models/forms/question-file";

@Component({
  selector: 'app-import-reward-codes',
  templateUrl: './import-reward-codes.component.html',
  styleUrls: ['./import-reward-codes.component.scss'],
  providers: [CouponsService]
})

export class ImportRewardCodesComponent implements OnInit {

  rewardId = this.route.snapshot.parent.parent.params.id;
  rewardCodesForm: UntypedFormGroup;
  inputs: QuestionBase<any>[];
  mode: string;
  apiEndPoint: string;
  loading = false;

  @ViewChild('modalToggleBtn', {static: true}) modalToggleBtn: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private qcs: QuestionControlService,
    private confirmationService: ConfirmationService,
    private modalStatusService: ModalStatusService,
    private translate: TranslateService,
    private rewardsService: RewardsService,
    private s3Service: S3Service,
    private router: Router,
    private csvExportService: CsvExportService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.getParams();
  }

  getParams() {
    this.route.params.subscribe(() => {
      this.inputs = [
        new FileQuestion({
          key: 'csv',
          types: ['csv'],
          multiple: false,
          cssClasses: 'form-control input-default'
        })
      ]
      this.rewardCodesForm = this.qcs.toFormGroup(this.inputs);
    });
  }

  closeModal() {
    this.router.navigate([{outlets: { modal: `show/rewards/${this.rewardId}/codes` }}]).catch(() => {});
  }

  downloadTemplate() {
    this.http.get('/rewards/csv_template', { responseType: 'text' as 'json'}).subscribe(data => {
      this.downloadCSV(data);
    });
  }

  downloadCSV(data) {
    const blob = this.csvExportService.getCsvBlob(data);
    const url = window.URL.createObjectURL(blob);
    const filename = `csv_template.csv`;

    if (navigator['msSaveOrOpenBlob']) {
      navigator['msSaveBlob'](blob, filename);
    } else {
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  sendData() {
    this.loading = true;
    const files = this.rewardCodesForm.value.csv;
    if (files && files.length > 0) {
      const file = files[0];
      this.s3Service.uploadFile(file).then(
        filename => {
          this.rewardsService.importCodesBatch(this.rewardId, filename).subscribe(
            () => {
              this.confirmationService.displaySuccessAlert(
                this.translate.instant('resources.scores.reward_code_batches.messages.import_title'),
                this.translate.instant('resources.scores.reward_code_batches.messages.import_text')
              ).catch(() => {});
              this.modalStatusService.modalStatus.emit();
              this.closeModal();
              this.loading = false;
            }, (err: HttpErrorResponse) => {
              this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), err.error.error);
              this.closeModal();
              this.loading = false;
            }
          );
        }
      ).catch((reason) => {
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), reason.message);
        this.loading = false;
      });
    }
  }
}

