import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService } from "../../../../../shared/services/confirmation.service";
import { DataTableConfiguration } from "../../../../../shared/components/data-table/data-table-cfg";
import { HttpErrorResponse } from "@angular/common/http";
import { ImportRewardCodesComponent } from "../import-reward-codes/import-reward-codes.component";
import { ModalStatusService } from "../../../../../shared/services/modal-status.service";
import { Reward } from "../../../../../shared/models/reward/reward";
import { RewardCodeBatchesMock } from "../../../../../shared/mocks/reward-code-batches-mock";
import { RewardsService } from "../../rewards.service";
import { Subject, takeUntil } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-reward-codes',
  templateUrl: './reward-codes.component.html',
  styleUrls: ['./reward-codes.component.scss']
})

export class RewardCodesComponent implements OnInit, OnDestroy {

  dataTableConfig: DataTableConfiguration;
  mode: string;
  reward: Reward;
  rewardId: number;

  private destroy$: Subject<void> = new Subject<void>();
  @ViewChild(ImportRewardCodesComponent) importRewardCodes: ImportRewardCodesComponent;

  constructor(
    private confirmationService: ConfirmationService,
    private modalStatusSrv: ModalStatusService,
    private rewardsService: RewardsService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getParams() {
    this.route.parent.params.subscribe(params => {
      if (params.id) {
        this.rewardId = params.id;
        if (this.router.url.indexOf(`(modal:`) >= 0) {
          this.mode = 'show';
        }
        this.getRewardById();
      }
    });
  }

  private getRewardById() {
    this.rewardsService.getRewardsById(this.rewardId).pipe(takeUntil(this.destroy$)).subscribe({
      next: (resp: Reward) => {
        this.reward = resp;
        this.setDataTableConfig();
      },
      error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
    });
  }

  private confirmDelete(id: number) {
    this.confirmationService.displayConfirmationAlert(
      this.translate.instant('common.messages.are_you_sure'),
      this.translate.instant('resources.scores.reward_code_batches.warnings.confirm_delete'),
      'question')
    .then(
      data => {
        if (data.hasOwnProperty('value') && data.value) {
          this.rewardsService.deleteRewardCodeBatch(this.rewardId, id).subscribe({
            next: () => {
              this.confirmationService.displaySuccessAlert('', this.translate.instant('resources.scores.reward_code_batches.warnings.delete_success')).catch(() => {});
              this.modalStatusSrv.modalStatus.emit();
            },
            error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
          });
        }
      }
    ).catch(() => {});
  }

  private setDataTableConfig() {
    this.dataTableConfig = {
      isActive: true,
      isMock: false,
      mockConstant: RewardCodeBatchesMock,
      tokenPagination: false,
      createButton: {
        label: this.translate.instant('resources.scores.reward_code_batches.buttons.reward_code_batch'),
        redirectTo: [{outlets: { modal: `show/rewards/${this.rewardId}/codes/import` }}]
      },
      rows: [],
      rowActions: [
        {
          name: this.translate.instant('common.buttons.detail'),
          icon: 'eye',
          id: 'details',
          customRoute: (element: {id: number}) => {
            return ['', { outlets: { modal: `show/rewards/${this.rewardId}/codes/batch/${element.id}` }}];
          }
        },
        {
          name: this.translate.instant('common.delete'),
          icon: 'trash',
          id: 'delete',
          callback: (element: { id: number }) => this.confirmDelete(element.id)
        }
      ],
      tableActions: [
        {
          name: this.translate.instant('resources.scores.reward_code_batches.table_actions.export_csv'),
          icon: 'ei-export',
          id: 'exportCSV'
        }
      ],
      columns: [
        {
          name: this.translate.instant('resources.scores.reward_code_batches.columns.id'),
          prop: 'id'
        },
        {
          name: this.translate.instant('resources.scores.reward_code_batches.columns.status'),
          prop: 'status'
        },
        {
          name: this.translate.instant('resources.scores.reward_code_batches.columns.created_at'),
          prop: 'created_at'
        },
        {
          name: this.translate.instant('resources.scores.reward_code_batches.columns.num_codes'),
          prop: 'num_codes'
        },
        {
          name: this.translate.instant('resources.scores.rewards.columns.remaining_stock'),
          prop: 'remaining_stock'
        }
      ],
      requestData: {
        translationResource: 'resources.scores.reward_code_batches.columns.',
        apiEndPoint: `/rewards/${this.rewardId}/code_batches`,
        pageNumber: 0,
        sorting: {},
        filtering: {},
        numberPerPage: 10
      },
      tableMapping: [
        {prop: 'id', type: 'number', apiProp: 'id'},
        {prop: 'status', type: 'badge', apiProp: 'status', badge: {dictionaryKey: 'reward_code_batches_status'} },
        {prop: 'created_at', type: 'date_time', apiProp: 'created_at'},
        {prop: 'num_codes', type: 'text', apiProp: 'num_codes'},
        { prop: 'remaining_stock', type: 'number', apiProp: 'remaining_stock'}
      ]
    };
  }

}
