import { Injectable } from '@angular/core';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { HttpClient } from '@angular/common/http';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { ResourcesService } from '../../../../shared/services/resources.service';

@Injectable()
export class RetroTagService extends ResourcesService {
  inputs: TextboxQuestion[];

  constructor(http: HttpClient) {
    super(http);
  }

  getInputs(): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'iterations',
        type: 'number',
        cssClasses: 'form-control input-default',
        required: true
      }),
      new TextboxQuestion({
        key: 'tag_date',
        type: 'date',
        cssClasses: 'form-control input-default',
        required: true
      })
    ];

    this.inputs = inputsArray;
    return this.inputs;
  }

  sendSegmentRetroTagData(payload: object, id: number) {
    return this.postResource(payload, `segments/${id}/retro_tag`);
  }

  sendPeriodsRetroTagData(payload: object) {
    return this.postResource(payload, `periods/retro_tag`);
  }

}
