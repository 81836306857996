import { UntypedFormGroup, Validators } from "@angular/forms";
import { QuestionBase } from "../../../../shared/models/forms/question-base";
import { 
  CALCULATION_REQUIRED_FORM_FIELDS,
  CUSTOM_DATA_PERIOD_TYPE_REQUIRED_FORM_FIELDS,
  HOUR_INTERVAL_RECURRENCE_REQUIRED_FORM_FIELDS,
  SCHEDULED_REQUIRED_FORM_FIELDS,
  SPECIFIC_MONTHS_RECURRENCE_REQUIRED_FORM_FIELDS
} from "../constants/customer-attributes.constants";
import { handleCustomValidators } from "./form-validators.utils";

export function handleFieldValueChanges(
    fieldKey: string,
    fieldValue: string,
    inputs: QuestionBase<unknown>[],
    customerAttributesForm: UntypedFormGroup
  ): void {

  let isConditionApplied = false;
  let requiredFieldsKeys = [];
  let resetDisableField = false;
  let fieldsToResetDisableByConditionApplied = [];
  let handleCustomDateValidator = true;

  switch (fieldKey) {
    case 'attribute_type':
      isConditionApplied = fieldValue === 'calculation';
      requiredFieldsKeys = getRequiredFieldsKey(customerAttributesForm, CALCULATION_REQUIRED_FORM_FIELDS, true, true);
      break;

    case 'execution_type':
      isConditionApplied = fieldValue === 'scheduled';
      requiredFieldsKeys = getRequiredFieldsKey(customerAttributesForm, SCHEDULED_REQUIRED_FORM_FIELDS, false, true);
      break;

    case 'data_period_type':
      isConditionApplied = fieldValue === 'custom';
      requiredFieldsKeys = CUSTOM_DATA_PERIOD_TYPE_REQUIRED_FORM_FIELDS;
      resetDisableField = true;
      handleCustomDateValidator = false;
      break;

    case 'recurrence':
      isConditionApplied = fieldValue === 'specific_months' || fieldValue === 'hour_interval';
      requiredFieldsKeys = [...SPECIFIC_MONTHS_RECURRENCE_REQUIRED_FORM_FIELDS, ...HOUR_INTERVAL_RECURRENCE_REQUIRED_FORM_FIELDS];
      resetDisableField = true;
      fieldsToResetDisableByConditionApplied = fieldValue === 'specific_months' ? HOUR_INTERVAL_RECURRENCE_REQUIRED_FORM_FIELDS : SPECIFIC_MONTHS_RECURRENCE_REQUIRED_FORM_FIELDS;
      break;
  }

  handleFormFieldsRequiredValueAndValidity(inputs, customerAttributesForm, requiredFieldsKeys, isConditionApplied, resetDisableField, fieldsToResetDisableByConditionApplied);
  handleCustomValidators(inputs, customerAttributesForm, handleCustomDateValidator);
}

function getRequiredFieldsKey(
  customerAttributesForm: UntypedFormGroup,
  requiredFieldsKeys: string[],
  applyRequiredCustomDataPeriodTypeFields: boolean,
  applyRequiredCustomRecurrenceFields: boolean
): string[] {

  const hasCustomDataPeriodTypeValue = hasFieldWithValue(customerAttributesForm, 'data_period_type', 'custom');
  const hasSpecificMonthsRecurrenceValue = hasFieldWithValue(customerAttributesForm, 'recurrence', 'specific_months');
  const hasHourIntervalRecurrenceValue = hasFieldWithValue(customerAttributesForm, 'recurrence', 'hour_interval');
  let requiredFields = requiredFieldsKeys;

  if (applyRequiredCustomDataPeriodTypeFields && hasCustomDataPeriodTypeValue) {
    requiredFields = [...requiredFields, ...CUSTOM_DATA_PERIOD_TYPE_REQUIRED_FORM_FIELDS];
  }

  if (applyRequiredCustomRecurrenceFields && (hasSpecificMonthsRecurrenceValue || hasHourIntervalRecurrenceValue)) {
    const requiredCustomRecurrenceFields = hasSpecificMonthsRecurrenceValue ? SPECIFIC_MONTHS_RECURRENCE_REQUIRED_FORM_FIELDS : HOUR_INTERVAL_RECURRENCE_REQUIRED_FORM_FIELDS;
    requiredFields = [...requiredFields, ...requiredCustomRecurrenceFields];
  }

  return requiredFields;
}

function hasFieldWithValue(customerAttributesForm: UntypedFormGroup, fieldKey: string, fieldValue: string): boolean {
  return !!(customerAttributesForm.get(fieldKey).value === fieldValue);
}

function handleFormFieldsRequiredValueAndValidity(
    inputs: QuestionBase<unknown>[],
    customerAttributesForm: UntypedFormGroup,
    requiredFields: string[],
    isConditionApplied: boolean,
    resetDisableField: boolean,
    fieldsToResetDisableByConditionApplied: string[]
  ): void {

  requiredFields.forEach(key => {
    const input = inputs.find(input => input.key === key);
    input.required = !!isConditionApplied;
    input.disabled = false;
    customerAttributesForm.get(key).enable();

    if (isConditionApplied) {
      customerAttributesForm.get(key).setValidators([Validators.required]);
      if (fieldsToResetDisableByConditionApplied.length && fieldsToResetDisableByConditionApplied.includes(key)) {
        applyResetDisableField(input, customerAttributesForm, key);
      }
    } else {
      if (resetDisableField) {
        applyResetDisableField(input, customerAttributesForm, key);
      }
      customerAttributesForm.get(key).clearValidators();
    }
    customerAttributesForm.get(key).updateValueAndValidity();
  });
}

function applyResetDisableField(input: QuestionBase<unknown>, customerAttributesForm: UntypedFormGroup, key: string): void {
  customerAttributesForm.get(key).reset();
  customerAttributesForm.get(key).disable();
  input.value = null;
  input.disabled = true;
}
