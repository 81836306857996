import { Component, Input } from "@angular/core";
import { SummaryPointsTicketDetail, TicketDetail } from "../../../../../shared/models/purchases/ticket-detail";

@Component({
  selector: 'app-points-block',
  templateUrl: './points-block.component.html',
  styleUrls: ['./points-block.component.scss']
})

export class PointsBlockComponent {

  @Input() ticketData: TicketDetail;
  @Input() currencySymbol: string;
  @Input() pointsSummary: SummaryPointsTicketDetail[];

  scroll(lineOrder: number) {
    document.getElementById("line" + lineOrder).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
}
