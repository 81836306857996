import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { QuestionBase } from '../../models/forms/question-base';
import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DynamicFormQuestionComponent implements OnInit, OnDestroy {

  @Input() disabled: boolean;
  @Input() filters: object;
  @Input() form: UntypedFormGroup;
  @Input() multiselectOptions: object;
  @Input() question: QuestionBase<any>;
  @Input() useToken: boolean;
  @Input() displayInline: boolean;

  @Output() blur: EventEmitter<any> = new EventEmitter<any>();
  @Output() click: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() input: EventEmitter<any> = new EventEmitter<any>();
  @Output() multiselectChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() optionsLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedIdsLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedLoadedEmitter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('inputElement') inputElement: ElementRef;

  subs$: Subscription[] = [];

  thumbnail: string | ArrayBuffer;
  image: {
    base64: string | ArrayBuffer,
    filename: string,
    filesize: number,
    filetype: string
  };

  get isValid() { return this.form.controls[this.question.key].valid; }
  get isTouched() { return this.form.controls[this.question.key].touched; }
  get isDirty() { return this.form.controls[this.question.key].dirty; }

  ngOnInit() {
    if ( this.disabled ) { this.question.disabled = this.disabled; }
    const valueChanges$ = this.form.controls[this.question.key].valueChanges.subscribe(
      () => this.question.errorText = null
    );
    this.subs$.push(valueChanges$);
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  onMultiselectChange(selected) {
    this.multiselectChanged.emit(selected);
  }

  onMultiselectSelection(selected) {
    this.multiselectChanged.emit(selected);
    this.emitBlur(selected);
    this.question.hasErrors = false;
  }

  onMultiselectDeselect(deselected) {
    this.question.hasErrors = false;
    this.multiselectChanged.emit();
  }

  onSelectedItemLoadedEmitter(evt: any) {
    this.selectedLoadedEmitter.emit(evt);
  }

  onFileChange(selected) {
    this.fileChanged.emit(selected);
  }

  getElement() {
    return this.inputElement ? this.inputElement : null;
  }

  isBadInput(target: object): boolean {
    return (target as HTMLInputElement).validity.badInput;
  }

  emitBlur(event: Event): void {
    if (this.question.type === 'date') {
      if (this.isBadInput(event.target)) {
        this.form.controls[this.question.key].setErrors({ 'badInput': true });
      } else {
        const errors = this.form.controls[this.question.key].errors;
        if (errors && errors['badInput']) {
          delete errors['badInput'];
          this.form.controls[this.question.key].setErrors(errors.length ? errors : null);
        }
      }
    }
    this.blur.emit(event);
  }

  emitInput(event) {
    if (this.question.type === 'date' && this.isBadInput(event.target) && event.key === 'Enter') {
      event.preventDefault();
    }
    this.input.emit(event);
  }

  hasError(): boolean {
    if(this.question.type === 'date') {
      return this.form.controls[this.question.key].invalid && (this.form.controls[this.question.key].dirty || this.form.controls[this.question.key].touched);
    }
  }

  emitClick(event) {
    this.click.emit(event);
  }

  onSelectedIdsLoaded() {
    this.selectedIdsLoaded.emit();
  }

  onOptionsLoaded(event?) {
    this.optionsLoaded.emit(event);
  }
}
