<div class="modal-backdrop fade in"  tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="mrg-left-15 mrg-btm-5 first-letter-upper">
          {{'resources.jobs.title' | translate}}
        </h4>
				<button class="modal-close" (click)="closeModal()">
					<i class="ti-close"></i>
				</button>
			</div>
			<div class="modal-body background-gray">
				<div class="row">
					<div class="col-md-12">
            <div class="container-fluid clearfix" *ngIf="element">
              <div class="row">
                <div class="col">
                  <div class="card mrg-btm-5 height-100">
                    <div class="card-block">
                      <div class="table-overflow">
                        <table class="table table-hover" aria-hidden="true">
                          <tbody>
                            <tr>
                              <td class="no-border-top"><b [translate]="'resources.jobs.modal.fields.name'"></b></td>
                              <td class="no-border-top">{{element.name}}</td>
                            </tr>
                            <tr>
                              <td class="no-border-top"><b [translate]="'resources.jobs.modal.fields.execution'"></b></td>
                              <td class="no-border-top" *ngIf="!element.execution || element.execution === 'manual'">{{'resources.jobs.modal.fields.manual' | translate}}</td>
                              <td class="no-border-top" *ngIf="element.execution === 'hourly'">{{'resources.jobs.modal.fields.hourly' | translate}}</td>
                              <td class="no-border-top" *ngIf="element.execution === 'daily'">{{'resources.jobs.modal.fields.daily' | translate}}</td>
                              <td class="no-border-top" *ngIf="element.execution === 'weekly'">{{'resources.jobs.modal.fields.weekly' | translate}}</td>
                              <td class="no-border-top" *ngIf="element.execution === 'fortnightly'">{{'resources.jobs.modal.fields.fortnightly' | translate}}</td>
                              <td class="no-border-top" *ngIf="element.execution === 'monthly'">{{'resources.jobs.modal.fields.monthly' | translate}}</td>
                              <td class="no-border-top" *ngIf="element.execution === 'quarterly'">{{'resources.jobs.modal.fields.quarterly' | translate}}</td>
                              <td class="no-border-top" *ngIf="element.execution === 'biannually'">{{'resources.jobs.modal.fields.biannually' | translate}}</td>
                            </tr>
                            <tr [hidden]="['monthly','quarterly','biannually'].indexOf(element.execution) === -1">
                              <td class="no-border-top"><b [translate]="'resources.jobs.modal.fields.execution_day'"></b></td>
                              <td class="no-border-top">{{element.execution_day }}</td>
                            </tr>
                            <tr>
                              <td class="no-border-top"><b [translate]="'resources.jobs.modal.fields.execution_hour'"></b></td>
                              <td class="no-border-top">{{element.execution_hour }}</td>
                            </tr>
                            <tr>
                              <td class="no-border-top"><b [translate]="'resources.jobs.modal.fields.last_execution'"></b></td>
                              <td class="no-border-top">{{element.last_execution ? (element.last_execution | dateTimeZone: 'DD/MM/YYYY HH:mm') : '-'}}</td>
                            </tr>
                            <tr>
                              <td class="no-border-top"><b [translate]="'resources.jobs.modal.fields.average_duration'"></b></td>
                              <td class="no-border-top">{{element.averageString}}</td>
                            </tr>
                            <tr>
                              <td class="no-border-top"><b [translate]="'resources.jobs.modal.fields.status'"></b></td>
                              <td class="no-border-top" *ngIf="['success','error','processing'].indexOf(element.status) == -1">{{'resources.jobs.modal.fields.pending_execution' | translate}}</td>
                              <td class="no-border-top" *ngIf="element.status == 'success'">{{'resources.jobs.modal.fields.finalized_with_success' | translate}}</td>
                              <td class="no-border-top" *ngIf="element.status == 'error'">{{'resources.jobs.modal.fields.error' | translate}}</td>
                              <td class="no-border-top" *ngIf="element.status == 'processing'">{{'resources.jobs.modal.fields.executing' | translate}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mrg-top-15">
                <div class="col">
                  <div class="card">
                    <div class="card-block">
                      <div class="table-overflow">
                        <table class="table table-hover" aria-hidden="true">
                          <thead>
                            <tr>
                              <th class="no-border-top">{{'resources.jobs.modal.fields.order' | translate}}</th>
                              <th class="no-border-top">{{'resources.jobs.modal.fields.type' | translate}}</th>
                              <th class="no-border-top">{{'resources.jobs.modal.fields.status' | translate}}</th>
                              <th class="no-border-top"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let step of element.bigquery_job_steps; let i = index">
                              <td>{{step.sort}}</td>
                              <td>
                                <p *ngIf="step.category === 'pump'">
                                  {{'resources.jobs.modal.fields.pump' | translate}}
                                  <span *ngIf="step.name!== ''">({{step.name}})</span>
                                  <span class="label label-info">{{'resources.jobs.pump_cut_conditions.' + step.pump_cut_condition | translate}}</span>
                                  <span *ngIf="step.append" class="label label-danger">{{'resources.jobs.modal.fields.append' | translate}}</span>
                                </p>
                                <p *ngIf="step.category === 'dump'">
                                  {{'resources.jobs.modal.fields.dump' | translate}}
                                  <span *ngIf="step.name!== ''">({{step.table_name}})</span>
                                </p>
                                <p *ngIf="step.category === 'calculation'">
                                  {{'resources.jobs.modal.fields.calculation' | translate}}
                                  <span *ngIf="step.name!== ''">({{step.name}})</span>
                                </p>
                                <p *ngIf="step.category === 'custom'">
                                  {{'resources.jobs.modal.fields.step_categories.custom' | translate}}
                                  <span *ngIf="step.name === 'DashboardsClearCache'">({{'resources.jobs.modal.fields.step_categories.custom_type.clear_cache' | translate}})</span>
                                  <span *ngIf="step.name !== 'DashboardsClearCache'"></span>
                                </p>
                                <p *ngIf="step.category == 'segment'">
                                  {{'resources.jobs.modal.fields.tag_segment' | translate}}
                                  <span *ngIf="step.name!== ''">({{step.name}})</span>
                                </p>
                                <p *ngIf="step.category == 'segment_category'">
                                  {{'resources.jobs.modal.fields.tag_segment_by_category' | translate}}
                                  <span *ngIf="step.name!== ''">({{step.name}})</span>
                                </p>
                                <p *ngIf="step.category == 'synchro'">
                                  {{'resources.jobs.modal.fields.synchronization' | translate}}
                                  <span *ngIf="step.name!== ''">({{step.name}})</span>
                                </p>
                                <p *ngIf="step.category == 'datastore_synchro'">
                                  {{'resources.jobs.modal.fields.datastore_synchro' | translate}}
                                  <span *ngIf="step.name!== ''">({{step.name}})</span>
                                </p>
                                <p *ngIf="step.category == 'period'">
                                    {{'resources.jobs.modal.fields.step_categories.period' | translate}}
                                    <span *ngIf="step.name">({{step.name}})</span>
                                </p>
                                <p *ngIf="step.category == 'grant_scores'">
                                    {{'resources.jobs.modal.fields.grant_scores' | translate}}
                                    <span *ngIf="step.name">({{step.name}})</span>
                                </p>
                              </td>
                              <td>
                                <p>
                                  {{translateStatus(step.status)}}
                                </p>
                              </td>
                              <td>
                                <div class="dropdown inline-block">
                                  <button class="btn btn-default dropdown-toggle"
                                          type="button"
                                          data-toggle="dropdown"
                                          aria-expanded="true"
                                          [disabled]="['creating_csv','uploading','creating_job','processing', '1'].indexOf(step.status) > -1">
                                          {{'resources.jobs.modal.fields.execute_step' | translate}}
                                    <i class="ti-angle-down font-size-9"></i>
                                  </button>
                                  <ul class="dropdown-menu"
                                      x-placement="bottom-start"
                                      style="position: absolute; transform: translate3d(0px, 41px, 0px); top: 0px; left: 0px; will-change: transform;">
                                    <li><a class="pointer" (click)="executeStep(step)">{{'resources.jobs.modal.fields.execute_step' | translate}}</a></li>
                                    <ng-container *ngIf="step.category === 'segment' || step.category === 'segment_category'">
                                      <li><a class="pointer" (click)="openExecuteDate(step)">{{'resources.jobs.modal.fields.execute_with_date' | translate}}</a></li>
                                    </ng-container>
                                    <li><a class="pointer"(click)="executeFromStep(i)">{{'resources.jobs.modal.fields.execute_from_here' | translate}}</a></li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer background-white" style="margin: -4px 0px 0px 0px;">
        <div class="pull-right d-flex align-items-center no-mrg mrg-right-2">
          <div class="dropdown inline-block">
            <button class="btn btn-info dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="true"
                    [disabled]="stepExecuting">{{'resources.jobs.modal.fields.execute' | translate}}
              <i class="ti-angle-down font-size-9"></i>
            </button>
            <ul class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 41px, 0px); top: 0px; left: 0px; will-change: transform;">
              <li><a class="pointer" (click)="execute()">{{'resources.jobs.modal.fields.execute' | translate}}</a></li>
              <ng-container *ngIf="taskStepsSegments">
                <li><a class="pointer" (click)="openExecuteDate()">{{'resources.jobs.modal.fields.execute_with_date' | translate}}</a></li>
              </ng-container>
            </ul>
          </div>
          <button class="pull-right btn btn-info mrg-right-15"
                  [routerLink]="['', { outlets: { modal: ['update', apiEndPoint, id] } }]"
                  [disabled]="element && element.slug === 'exports'">
            <span>{{ 'components.show.buttons.edit' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
