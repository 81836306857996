import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { HttpClient } from '@angular/common/http';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { CountriesService } from '../../../../shared/services/countries.service';
import { FloatQuestion } from '../../../../shared/models/forms/question-float';

@Injectable()
export class CashBackService extends ResourcesService {

  inputs: QuestionBase<any>[];
  APIENDPOINT = 'cashback_thresholds'
  elementToEdit: unknown;

  constructor(
    http: HttpClient,
    private countriesService: CountriesService,
  ) {
    super(http);
  }

  public getInputs(input?): QuestionBase<any>[] {
    const inputsArray = [
      new MultiSelectQuestion({
        key: 'country',
        label: 'resources.cashback_threshold.loyalty.form.country',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        required: true,
        options: this.countriesService.getCountries(),
        value: input.country ? [this.countriesService.getCountries().find( item => item.id === input.country)] : []
      }),
      new TextboxQuestion({
        key: 'score',
        cssClasses: 'form-control input-default',
        label: 'resources.cashback_threshold.loyalty.form.min_points',
        type: 'number',
        required: true,
        value: input.score ? input.score : null
      }),
      new FloatQuestion({
        key: 'money_representation',
        cssClasses: 'form-control input-default',
        label: 'resources.cashback_threshold.loyalty.form.min_money_spent',
        type: 'number',
        required: true,
        min: 0,
        value: input.money_representation ? input.money_representation : null
      }),
      new TextboxQuestion({
        key: 'currency',
        label: 'resources.cashback_threshold.loyalty.form.currency',
        cssClasses: 'form-control input-default',
        type: 'text',
        required: false,
        disabled: true,
        value: input.currency ? input.currency : null
      })
    ];

    this.inputs = inputsArray;
    return this.inputs;
  }

  createCashback(data:unknown) {
    return this.postResource(data, this.APIENDPOINT);
  }

  updateCashback(data:unknown) {
    return this.patchResource(data, this.APIENDPOINT);
  }

  deleteCashback(country:string) {
    return this.deleteResource(country, `${this.APIENDPOINT}/${country}` );
  }

  getElementByCountry(country:string){
    return this.getData({apiEndPoint: `${this.APIENDPOINT}/${country}`});
  }

}
