<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header background-white">
        <div class="row flex-grow-1">
          <div class="col-md-8 ml-auto mr-auto">
            <h4>New Milestone</h4>
          </div>
        </div>


        <div class="pdd-vertical-20-but-bottom">
          <button class="modal-close" (click)="returnToList()">
            <i class="ti-close"></i>
          </button>
        </div>
      </div>

      <div class="modal-body back-color">
        <div class="card-block no-pdd">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form [formGroup]="milestonesForm" *ngIf="milestonesForm">
                <!-- BLOQUE DEFINITION -->
                <div class="card">
                  <div class="card-block">
                    <h4>Definition</h4>

                    <div class="row mrg-top-15">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="name" [translate]="'resources.scores.loyalty_scores.fields.name'"></label>
                          <app-question [question]="getInputConfig('name')" [form]="milestonesForm"></app-question>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="description"
                            [translate]="'resources.scores.loyalty_scores.fields.description'"></label>
                          <app-question [question]="getInputConfig('description')" [form]="milestonesForm"></app-question>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="image">Image</label>
                          <app-question [question]="getInputConfig('image')" [form]="milestonesForm"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- BLOQUE MECHANIC -->
                <div class="card">
                  <div class="card-block">
                    <h4>Mechanic</h4>

                    <div class="row mrg-top-15">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Type</label>
                          <app-question [question]="getInputConfig('mechanic_options')" [form]="milestonesForm"></app-question>
                        </div>
                      </div>
                    </div>

                    <ng-container *ngIf="hasFormKeyWithValue('mechanic_options', 'stamps')">
                      <div class="row mrg-top-15">
                        <div class="col">
                          <div class="form-group">
                            <label>Period</label>
                            <app-question [question]="getInputConfig('period')" [form]="milestonesForm"></app-question>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label>Stamps</label>
                            <app-question [question]="getInputConfig('stamps')" [form]="milestonesForm"></app-question>
                          </div>
                        </div>                        
                      </div>
                    </ng-container>

                    <ng-container *ngIf="hasFormKeyWithValue('mechanic_options', 'goal')">
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label>Goal</label>
                            <app-question [question]="getInputConfig('goal')" [form]="milestonesForm"></app-question>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label>Period</label>
                            <app-question [question]="getInputConfig('period')" [form]="milestonesForm"></app-question>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="hasFormKeyWithValue('mechanic_options', 'stamps')">
                      <h5>Filter transaction by:</h5>
                      <div class="row mrg-top-15">
                        <div class="col">
                          <div class="form-group">
                            <label>Taxonomy</label>
                            <app-question [question]="getInputConfig('taxonomy_slug')" [form]="milestonesForm"></app-question>
                          </div>
                        </div>
  
                        <div class="col">
                          <div class="form-group">
                            <label>Feature</label>
                            <app-question [question]="getInputConfig('feature_ids')" [form]="milestonesForm"></app-question>
                          </div>
                        </div>
  
                        <div class="col">
                          <div class="form-group">
                            <label>Product</label>
                            <app-question [question]="getInputConfig('product_ids')" [form]="milestonesForm" [useToken]="true"></app-question>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="hasFormKeyWithValue('mechanic_options', 'goal')">
                      <h5>Filter transaction by:</h5>
                      <div class="row mrg-top-15">
                        <div class="col">
                          <div class="form-group">
                            <label>Taxonomy</label>
                            <app-question [question]="getInputConfig('taxonomy_slug')" [form]="milestonesForm"></app-question>
                          </div>
                        </div>
  
                        <div class="col">
                          <div class="form-group">
                            <label>Feature</label>
                            <app-question [question]="getInputConfig('feature_ids')" [form]="milestonesForm"></app-question>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <!-- BLOQUE REWARDS -->
                <div class="card">
                  <div class="card-block">
                    <h4>Rewards</h4>

                    <div class="row mrg-top-15">
                      <div class="col">
                        <div class="form-group">
                          <label>Loyalty engine</label>
                          <app-question [question]="getInputConfig('loyalty_engine')" [form]="milestonesForm"></app-question>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Coupons</label>
                          <app-question [question]="getInputConfig('coupon_ids')" [form]="milestonesForm"></app-question>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="image">Badge name</label>
                          <app-question [question]="getInputConfig('badge')" [form]="milestonesForm"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-block">
                    <h4>Target</h4>

                    <div class="row mrg-top-15">
                      <div class="col-md-12">
                        <div class="form-group">
                          <app-question [question]="getInputConfig('target')" [form]="milestonesForm"></app-question>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Audience</label>
                          <app-question [question]="getInputConfig('segment_ids')" [form]="milestonesForm"></app-question>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="row modal-footer background-white">
        <div class="col-md-8 ml-auto mr-auto text-right">
          
          <button class="btn btn-info no-margin-right"
            [translate]="'common.buttons.save'">
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
