import { AbstractControl, UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";
import { CustomEntityAttributeType } from "../enums/custom-entity-attribute-type.enums";
import { CustomEntityKey } from "../enums/custom-entity-key.enums";
import { handleQuantityControlsValidators } from "./controls-validators/quantity-controls-validators.validations";
import { handleAttributeControlsValidators } from "./controls-validators/attribute-controls-validators.validations";

export function getCustomEntitiesConditionFormGroupValidations(control: UntypedFormGroup): ValidationErrors | null {
  const attributesConfiguration = control.get(CustomEntityKey.AttributesConfiguration);
  attributesConfiguration?.setValidators([]);
  if (attributesConfiguration && !attributesConfiguration?.['controls']?.length) {
    attributesConfiguration?.setValidators([Validators.required]);
    attributesConfiguration.setErrors({['requiredAttributes']: true});
  }

  handleQuantityControlsValidators(control);

  return null;
}

export function setAttributeConfigCtrlsValidations(attributeConfigCtrl: AbstractControl): ValidationErrors | null {
  const attributeCtrl = attributeConfigCtrl.get(CustomEntityKey.Attribute);
  const attributeTypeCtrl = attributeConfigCtrl.get(CustomEntityKey.AttributeType);
  const stringOperatorCtrl = attributeConfigCtrl.get(CustomEntityKey.StringOperator);
  const integerOperatorCtrl = attributeConfigCtrl.get(CustomEntityKey.IntegerOperator);
  const floatOperatorCtrl = attributeConfigCtrl.get(CustomEntityKey.FloatOperator);
  const booleanOperatorCtrl = attributeConfigCtrl.get(CustomEntityKey.BooleanOperator);
  const dateOperatorCtrl = attributeConfigCtrl.get(CustomEntityKey.DateOperator);
  const stringValueCtrl = attributeConfigCtrl.get(CustomEntityKey.StringValue);
  const integerValueCtrl = attributeConfigCtrl.get(CustomEntityKey.IntegerValue);
  const integerValue2Ctrl = attributeConfigCtrl.get(CustomEntityKey.IntegerValue2);
  const floatValueCtrl = attributeConfigCtrl.get(CustomEntityKey.FloatValue);
  const floatValue2Ctrl = attributeConfigCtrl.get(CustomEntityKey.FloatValue2);
  const booleanValueCtrl = attributeConfigCtrl.get(CustomEntityKey.BooleanValue);
  const dateValueCtrl = attributeConfigCtrl.get(CustomEntityKey.DateValue);
  const dateValue2Ctrl = attributeConfigCtrl.get(CustomEntityKey.DateValue2);

  const controls = [
    attributeCtrl, stringOperatorCtrl, integerOperatorCtrl, floatOperatorCtrl, booleanOperatorCtrl, dateOperatorCtrl,
    stringValueCtrl, integerValueCtrl, integerValue2Ctrl, floatValueCtrl, floatValue2Ctrl, booleanValueCtrl,
    dateValueCtrl, dateValue2Ctrl
  ];
  controls.forEach(ctrl => ctrl.setValidators([]));

  attributeCtrl.setValidators([Validators.required]);
  if (!attributeCtrl?.value?.length) {
    attributeCtrl.setErrors({['invalidRequired']: true});
    attributeCtrl.markAsDirty();
  }

  const attributeTypeValue = attributeTypeCtrl?.value ?? null;
  switch(attributeTypeValue) {
    case CustomEntityAttributeType.String:
      return handleAttributeControlsValidators(stringOperatorCtrl, stringValueCtrl);
    case CustomEntityAttributeType.Integer:
      return handleAttributeControlsValidators(integerOperatorCtrl, integerValueCtrl, integerValue2Ctrl, attributeTypeCtrl);
    case CustomEntityAttributeType.Float:
    case CustomEntityAttributeType.Number:
      return handleAttributeControlsValidators(floatOperatorCtrl, floatValueCtrl, floatValue2Ctrl);
    case CustomEntityAttributeType.Boolean:
      return handleAttributeControlsValidators(booleanOperatorCtrl, booleanValueCtrl);
    case CustomEntityAttributeType.Datetime:
    case CustomEntityAttributeType.Date:
      return handleAttributeControlsValidators(dateOperatorCtrl, dateValueCtrl, dateValue2Ctrl, attributeTypeCtrl);
    default:
      return null;
  }
}