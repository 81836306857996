<div class="row" *ngIf="inputs">
  <div class="col-md-3">
    <div class="form-group">
      <app-question [question]="getInputConfig('operator')" 
                    [form]="form"></app-question>
    </div>
  </div>

  <div class="col-md-3">
    <div class="form-group">
      <app-question [question]="getInputConfig('segment_id')"
                    [form]="form"
                    (multiselectChanged)="handleSegmentSelected($event)"
                    (selectedLoadedEmitter)="handleSegmentApplies($event)"
                    [filters]="filters"></app-question>
    </div>
  </div>

  <div class="col-md-3">
    <div class="form-group">
      <app-question [question]="getInputConfig('apply')" 
                    (multiselectChanged)="handleApplySelected($event)"
                    [form]="form"></app-question>
    </div>
  </div>

  <div class="col-md-3">
    <div class="form-group">
      <app-question [question]="getInputConfig('index_apply')"
                    (multiselectChanged)="handleApplyIndexSelected($event)" [form]="form"></app-question>
    </div>
  </div>
</div>
