import { Component, OnInit, OnDestroy } from '@angular/core';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { LoyaltyScoreMomentsService } from '../loyalty-score-moments.service';
import { ModalStatusService } from '../../../../../shared/services/modal-status.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-form-loyalty-score-moments',
  templateUrl: './form-loyalty-score-moments.component.html',
  styleUrls: ['./form-loyalty-score-moments.component.css'],
  providers: [LoyaltyScoreMomentsService]
})
export class FormLoyaltyScoreMomentsComponent implements OnInit, OnDestroy {

  public scoreMomentsInputs: QuestionBase<any>[];
  public scoremomentsForm: UntypedFormGroup;
  public inputs: any;
  public id: number;
  public loading: boolean;
  public momentsById$: Subscription;
  public updateScores$: Subscription;
  public sendScores$: Subscription;
  public route$: Subscription;

  constructor(private qcs: QuestionControlService,
              private route: ActivatedRoute,
              private scoreMomentsService: LoyaltyScoreMomentsService,
              private router: Router,
              private confirmationService: ConfirmationService,
              private modalStatusService: ModalStatusService,
              private translate: TranslateService) {}

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy() {
    if ( this.momentsById$ ) { this.momentsById$.unsubscribe(); }
    if ( this.updateScores$ ) { this.updateScores$.unsubscribe(); }
    if ( this.sendScores$ ) { this.sendScores$.unsubscribe(); }
    if ( this.route$ ) { this.route$.unsubscribe(); }
  }

  public getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  public sendData() {

    const rewardCategoriesOptions = {
      name: this.scoremomentsForm.value.name,
      slug: this.scoremomentsForm.value.slug
    };

    if ( this.id ) {
      this.updateScores$ = this.scoreMomentsService.updateData(rewardCategoriesOptions, this.id).subscribe(
        response => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.scores.loyalty_score_moments.form.warnings.update_title'),
            this.translate.instant('resources.scores.loyalty_score_moments.form.warnings.update_text')
          ).catch(() => {});
          this.modalStatusService.modalStatus.emit();
          this.returnToList();
        },
        errorData => {
          this.confirmationService.displayErrorAlert('Error', errorData.error.error);
        }
      );
      } else {
        this.loading = false;
        this.sendScores$ = this.scoreMomentsService.sendData(rewardCategoriesOptions).subscribe(
          response => {
            this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.scores.loyalty_score_moments.form.warnings.success_title'),
            this.translate.instant('resources.scores.loyalty_score_moments.form.warnings.success_text')
          ).catch(() => {});
          this.loading = true;
          this.modalStatusService.modalStatus.emit();
          this.returnToList();
        },
        errorData => {
          this.confirmationService.displayErrorAlert('Error', errorData.error.error);
        }
      );
    }

  }

  public returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/scores/loyalty_scores/loyalty_score_moments']).catch(() => {});
    }
  }

  private getParams() {
    this.route$ = this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getScoreMomentsById(this.id);
      } else {
        this.inputs = this.scoreMomentsService.getInputs({});
        this.scoremomentsForm = this.qcs.toFormGroup(this.inputs);
      }
    });
  }

  private getScoreMomentsById(id: number) {
    this.momentsById$ = this.scoreMomentsService.getScoreMomentsById(this.id).subscribe(
      data => {
        this.inputs = this.scoreMomentsService.getInputs(data);
        this.scoremomentsForm = this.qcs.toFormGroup(this.inputs);
      }
    );
  }

}
