<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true" *ngIf="element">
              <tbody>
                <tr *ngIf="element.id">
                  <td class="no-border-top"><b [translate]="'resources.product_internal_categories.fields.id'"></b></td>
                  <td class="no-border-top">{{element.id}}</td>
                </tr>
                <tr *ngIf="element.name">
                  <td><b [translate]="'resources.product_internal_categories.fields.name'"></b></td>
                  <td>{{element.name}}</td>
                </tr>
                <tr *ngIf="element.slug">
                  <td><b [translate]="'resources.product_internal_categories.fields.slug'"></b></td>
                  <td>{{element.slug }}</td>
                </tr>
                <tr *ngIf="element.taxonomy">
                  <td><b [translate]="'resources.product_internal_categories.fields.taxonomy_slug'"></b></td>
                  <td>
                    <a class="pointer colorLink" (click)="goToTaxonomy()">
                      {{element.taxonomy.name}}
                    </a>
                  </td>
                </tr>
                <tr *ngIf="element._embedded.feature_objects">
                  <td><b [translate]="'resources.product_internal_categories.fields.features'"></b></td>
                  <td>
                    <ul class="list arrow bullet-success">
                      <li *ngFor="let item of element._embedded.feature_objects">
                        <a class="pointer colorLink" (click)="goToCategory(item.id)">{{item.pk}} - {{item.name}}</a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
