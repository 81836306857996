import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CreateUpdateLandingsService } from './service/create-update-landings.service';
import { ConfirmationService } from '../../services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-update-landings',
  templateUrl: './selection-landings.component.html',
  styleUrls: ['./selection-landings.component.scss']
})

export class CreateUpdateLandingsComponent implements OnInit, OnDestroy {

  public landingName: string;
  public landingsData: any;
  public userData: any;
  public id: number;
  public schema: any;
  public hasSchema: boolean;

  campaignData: Object;
  templateId: any;

  public displaySaveBtn: boolean;
  public subs$: Subscription[];
  public loadingTemplate: boolean;

  constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private createUpdateLandingsService: CreateUpdateLandingsService
  ){
    this.displaySaveBtn = false;
    this.subs$ = [];
    this.loadingTemplate = false;
  }

  ngOnInit() {
    this.getCampaignTemplates();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  goToEditModal() {
    this.router.navigate([{ outlets: { modal: `update/landings/${this.id}`}}]).catch(() => {});
  }

  onSave(json, html) {
    const save$ = this.createUpdateLandingsService.updateTemplateById(this.id, json, html).subscribe();
    this.subs$.push(save$);
  }

  getCampaignTemplates() {
    const template$ = this.createUpdateLandingsService.getTemplatesData().subscribe((reqResponse) => {
      this.landingsData = reqResponse;
    });
    this.subs$.push(template$);
  }

  getCampaignId() {
    const campaign$ = this.createUpdateLandingsService.getById(this.id).subscribe(
      campaignData => {
        this.campaignData = campaignData;
        this.landingName = campaignData['name'];
        this.hasSchema = this.checkIfSchema(campaignData);
        if (this.hasSchema) {
          this.schema = campaignData['template']['schema'];
        } else {
          this.getCampaignTemplates();
        }
      }
    );
    this.subs$.push(campaign$);
  }

  private checkIfSchema(campaignData: any): boolean {
    if (campaignData.template && campaignData.template.schema ) {
      return true;
    } else {
      return false;
    }
  }

  sendCampaignData() {
    this.campaignData['name'] = this.landingName;
    this.campaignData['template'].id = this.templateId;
    const landing$ = this.createUpdateLandingsService.sendCampaignData(this.campaignData, this.id).subscribe(
      response => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.campaigns_landings.modal.warnings.success_title'),
          this.translate.instant('resources.campaigns_landings.modal.warnings.success_text')
        ).catch(() => {});
      }
    );
    this.subs$.push(landing$);
  }

  handleTemplateSelected(templateId) {
    this.loadingTemplate = true;
    const campaignTemplate$ = this.createUpdateLandingsService.getTemplateById(templateId).subscribe(
      successData => {
        this.saveLanding(successData['schema'], successData['html']);
      },
      errorData => {
        this.loadingTemplate = false;
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error);
      }
    )
    this.subs$.push(campaignTemplate$);
  }

  saveLanding(json: Object, html: Object) {
    this.loadingTemplate = true;
    const save$ = this.createUpdateLandingsService.saveLanding(this.landingName, html, json).subscribe(
      response => {
        this.loadingTemplate = false;
        this.id = response.id;
        this.router.navigate([{ outlets: { modal: `update/landings/${this.id}` } }]).catch(() => {});
      },
      errorData => {
        this.loadingTemplate = false;
        if (!this.landingName) {
          this.confirmationService.displayErrorAlert(
            this.translate.instant('resources.campaigns_landings.modal.warnings.error_title'),
            this.translate.instant('resources.campaigns_landings.modal.warnings.error_message')
          );
        }
      }
    );
    this.subs$.push(save$);
  }
}
