import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { TranslateService } from '@ngx-translate/core';
import { Location, getCurrencySymbol } from '@angular/common';
import { RefreshCacheService } from '../../../../shared/services/refresh-cache.service';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Subscription } from 'rxjs';
import { ProfileService } from '../../../../profiles/profile.service';

@Component({
  selector: 'app-show-periods',
  templateUrl: './show-periods.component.html',
  styleUrls: ['./show-periods.component.css'],
  providers: [RefreshCacheService]
})

export class ShowPeriodsComponent implements OnInit, OnDestroy {

  public element;
  public user;
  public apiEndPoint: string;
  public id: number;
  public selectedTab: string;
  public segmentCustomerData: any;
  public buyerTypesCutsData: any;
  public previousSegmentCustomerData: any;
  public messages: any[] = [];
  public slug = 'segments';
  public loading: boolean;
  private subs$: Subscription[];
  public currencySymbol: string;

  public activeTab = {
    name: 'segments',
    title: this.translate.instant('resources.periods.title')
  };

  public apiEndPoints = {
    segments: 'segments',
    segment_customers: 'segment_customers',
    buyer_types_cuts: 'buyer_types_cuts'
  };

  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private refreshCacheService: RefreshCacheService,
    private location: Location,
    private translate: TranslateService,
    private router: Router,
    private profileService: ProfileService
  ) {
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
    this.subs$ = [];
  }

  ngOnInit() {
    registerLocaleData(es);
    this.getParams();
    this.getMeUserData();
    this.getData();
    const router$ = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.setRouterURL();
        this.selectTab(this.slug);
        this.changeTitle(this.slug);
      }
    });
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getData() {
    this.loading = true;
    const resource$ = this.resourcesService.getData({ apiEndPoint: this.apiEndPoint + '/' + this.id }).subscribe(
      res => {
        this.element = res;
        this.getFullHistoryData();
        this.loading = false;
      }
    );
    this.subs$.push(resource$);
  }

  refreshUrl(tabId) {
    this.selectedTab = tabId;
    const baseUrl = this.router.url.substr(0, this.router.url.indexOf('(modal'));
    const modalString = `show/periods/${this.element.id}/${tabId}`;
    this.location.go(`${baseUrl}(modal:${modalString})`);

    if (tabId === 'report-segments') {
      this.getPreviousCustomerData();
      this.getSegmentCustomers();
    } else if (tabId === 'report-attributes') {
      this.getBuyerTypesCuts();
    }
  }

  getDifference(index: number, currentValue: number) {
    return +currentValue - this.previousSegmentCustomerData[index].table.customers;
  }

  selectTab(slug) {
    this.changeTitle(slug);
    this.slug = slug;
  }

  execute() {
    this.resourcesService.postResource([], 'periods/' + this.id + '/execute').subscribe();
  }

  retry() {
    this.resourcesService.postResource([], 'periods/' + this.id + '/repeat').subscribe();
  }

  rollback() {
    this.resourcesService.postResource({}, 'periods/' + this.id + '/rollback').subscribe();
  }

  inStatusFor(action: string) {
    let validStatus = [];
    switch (action) {
      case 'execute':
        validStatus = ['pending'];
        break;
      case 'retry':
        validStatus = ['error', 'warning'];
        break;
      case 'rollback':
        validStatus = ['error', 'warning', 'done'];
        break;
      default: break;
    }

    return validStatus.indexOf(this.element.status) >= 0;
  }

  canRunAction(action: string): boolean {
    const lastExpectedNumber = (action == 'rollback' ? this.element.number : this.element.number-1)
    return this.inStatusFor(action) && this.user.company.last_period_number_done === lastExpectedNumber;
  }

  stateClass(object) {
    return (object.level === 'error' ? 'label label-danger' : object.class);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'periods';
        this.getSelectedTab();
      }
    });
  }

  private getSegmentCustomers() {
    const params = {
      apiEndPoint: 'periods/' + this.id + '/segment_customers',
      filtering: {}
    };

    const subscription_segmentCustomers = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this.segmentCustomerData = reqResponse;
        subscription_segmentCustomers.unsubscribe();
      }
    });
  }

  private getPreviousCustomerData() {
    const params = {
      apiEndPoint: 'periods/' + (this.id - 1) + '/segment_customers',
      filtering: {}
    };

    this.loading = true;
    const subscription_PreviousSegmentCustomers = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this.loading = false;
        this.previousSegmentCustomerData = reqResponse;
        subscription_PreviousSegmentCustomers.unsubscribe();
      }
    });
  }

  private getBuyerTypesCuts() {
    const params = {
      apiEndPoint: 'periods/' + (this.id - 1) + '/buyer_types_cuts',
      filtering: {}
    };
    this.loading = true;
    const subscription_buyer_types_cuts = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this.loading = false;
        this.buyerTypesCutsData = reqResponse;
        subscription_buyer_types_cuts.unsubscribe();
      }
    });
  }

  private getMeUserData() {
    const userData$ = this.profileService.getCurrentUserProfile().subscribe(
      response => {
        this.user = response;
      }
    );
  }

  private changeTitle(slug) {
    this.activeTab.name = slug;
    this.activeTab.title = this.translate.instant(`resources.periods.tabs.${slug}`);
  }

  private setRouterURL() {
    this.apiEndPoint = this.apiEndPoints[this.slug];
  }

  private getSelectedTab() {
    if (this.router.url.indexOf(`modal:show/periods/${this.id}`) >= 0) {
      this.selectedTab = 'segments';
    } else if (this.router.url.indexOf(`modal:show/periods/${this.id}/segment_customers`) >= 0 ||
                this.router.url.indexOf(`modal:show/periods/${this.id}`) >= 0 ) {
      this.selectedTab = 'segments';
    }
  }

  private getFullHistoryData() {

    if (this.element.messages) {
      if (this.element.messages.validation.length > 0) {
        for (let i = 0; i < this.element.messages.validation.length; i++) {
          this.element.messages.validation[i].type = this.translate.instant('resources.periods.status.validation');
          this.element.messages.validation[i].class = 'label label-warning';
          this.messages.push(this.element.messages.validation[i]);
        }
      }

      if (this.element.messages.process.length > 0) {
        for (let i = 0; i < this.element.messages.process.length; i++) {
          this.element.messages.process[i].type = this.translate.instant('resources.periods.status.process');
          this.element.messages.process[i].class = 'label label-info';
          this.messages.push(this.element.messages.process[i]);
        }
      }

      if (this.element.messages.check.length > 0) {
        for (let i = 0; i < this.element.messages.check.length; i++) {
          this.element.messages.check[i].type = this.translate.instant('resources.periods.status.check');
          this.element.messages.check[i].class = 'label label-primary';
          this.messages.push(this.element.messages.check[i]);
        }
      }
    }
  }

}
