<div *ngIf="entityAttrsForm">
  <div class="row">
    <label class="mrg-left-15"
      [innerHTML]="'resources.custom_entities.fields.attrs_title' | translate: { name: entity.name }">
    </label>
  </div>
  <table class="table" aria-describedby="Custom entities attributes definition list">
    <thead>
      <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
      </tr>
    </thead>
    <tr *ngFor="let item of definedEntityAttrs; let i = index">
      <td class="col-xs-2">{{decorateAttrName(item.field)}}</td>
      <td class="col-xs-1 text-center">{{decorateAttrOperator(item.condition)}}</td>
      <td class="col-xs-8 text-right">
        <span class="label">{{item.value}}</span>
      </td>
      <td class="col-xs-1 text-right">
        <em class="fa fa-times pointer" (click)="deleteLine(i)">
        </em>
      </td>
    </tr>
  </table>
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <app-question [question]="inputs[0]" 
          (multiselectChanged)="entityAttributeChange($event)" 
          [form]="entityAttrsForm">
        </app-question>
      </div>
    </div>
    <div class="col" *ngIf="!currentSelectedType"></div>
    <ng-container *ngIf="currentSelectedType === 'number'">
      <div class="col-md-4">
        <div class="form-group">
          <app-question [question]="getInputConfig('number_operator')" [form]="entityAttrsForm"></app-question>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <app-question [question]="getInputConfig('number_value')" [form]="entityAttrsForm"></app-question>
        </div>
      </div> 
    </ng-container>
    <ng-container *ngIf="currentSelectedType === 'string'">
      <div class="col-md-4">
        <div class="form-group">
          <app-question [question]="getInputConfig('string_operator')" [form]="entityAttrsForm"></app-question>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <app-question [question]="getInputConfig('string_value')" [form]="entityAttrsForm"></app-question>
        </div>
      </div> 
    </ng-container>
    <div class="col-md-1">
      <button class="btn btn-success btn-icon no-margin-right pull-right"
        (click)="addEntityAttribute()"><span class="fa fa-plus"></span></button>
    </div>
  </div>
</div>
