import { Injectable } from '@angular/core';
import { QuestionBase } from '../../../../models/forms/question-base';
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectQuestion } from '../../../../models/forms/question-multiselect';
import { DynamicDateQuestion } from '../../../../models/forms/question-dynamic-date';
import { DateService } from '../../../date.service';
import { CheckboxQuestion } from '../../../../models/forms/question-checkbox';
import { UntypedFormGroup } from '@angular/forms';
import { multiselectPresenceValidator } from '../../../validations.service';
import { OperatorsService } from '../../common/operators.service';
import { getParseBooleanValue, getParseDynamicDateValue, getParseIntValue, getParseMultiselectIdValues, getParseMultiselectValueInOptions, getParseObjectKeysIdValue, getParseOperatorValue, getParseSingleSelectOperatorIdValue, getParseValue } from '../../utils/common.utils';
import { TextboxQuestion } from '../../../../models/forms/question-textbox';
import { getFormGroupValidations } from './validations/form.validations';
import { VoucherCountCondition, VoucherCountConditionForm } from '../../../../models/segments/conditions/voucher-count-condition';
import { Option } from '../../../../models/common/option';

@Injectable()
export class VoucherCountConditionService {

  inputs: QuestionBase<unknown>[];
  customValidators = [(control: UntypedFormGroup) => getFormGroupValidations(control)];

  private operatorOpts = this.operatorsService.getNumericOperators();

  constructor(
    private translate: TranslateService,
    private dateService: DateService,
    private operatorsService: OperatorsService
  ) {}

  getInputs(params?: VoucherCountConditionForm): QuestionBase<unknown>[] {
    const miliseconds = new Date().getMilliseconds();
    const inputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'operator',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorOpts,
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        required: true,
        parseValue: getParseSingleSelectOperatorIdValue(this.operatorOpts),
        getValue: getParseValue(),
        customValidators: [multiselectPresenceValidator]
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'value',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        type: 'number',
        step: 1,
        required: true,
        parseValue: getParseIntValue(),
        getValue: getParseValue(false)
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'value_1',
        label: this.translate.instant('common.fields.min'),
        type: 'number',
        step: 1,
        required: true,
        parseValue: getParseIntValue(),
        getValue: getParseValue(false)
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'value_2',
        label: this.translate.instant('common.fields.max'),
        type: 'number',
        step: 1,
        required: true,
        parseValue: getParseIntValue(),
        getValue: getParseValue(false)
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'status',
        label: this.translate.instant('resources.segment_conditions.fields.status'),
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        options: this.getCouponStatusList(),
        parseValue: getParseMultiselectIdValues(),
        getValue: getParseValue()
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_from',
        label: this.translate.instant('resources.segment_conditions.fields.date_from'),
        parseValue: getParseObjectKeysIdValue(),
        getValue: getParseDynamicDateValue(this.dateService)
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_to',
        label: this.translate.instant('resources.segment_conditions.fields.date_to'),
        parseValue: getParseObjectKeysIdValue(),
        getValue: getParseDynamicDateValue(this.dateService)
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: `open_to_anyone_${miliseconds}`,
        label: this.translate.instant('resources.segment_conditions.fields.open_to_anyone'),
        parseValue: getParseBooleanValue(),
        getValue: getParseBooleanValue()
      }),
    ];

    if (params) { this.prepareInputValuesFromParams(inputs, params); }
    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI(params: VoucherCountConditionForm): VoucherCountCondition {
    const parsedValuesObj = {};
    Object.keys(params).forEach( _key => {
      const input = this.getInputByKey(this.inputs, _key);
      const parsedValue = input.parseValue( params[_key] );
      const key = this.getKeyWithoutDateId(_key);
      parsedValuesObj[key] = parsedValue;
    });
    return parsedValuesObj;
  }

  prepareFormValuesFromAPI(params: VoucherCountCondition): VoucherCountConditionForm {
    const parseValues = {
      operator: getParseOperatorValue(this.operatorOpts),
      value: getParseValue(false),
      value_1: getParseValue(false),
      value_2: getParseValue(false),
      status: getParseMultiselectValueInOptions(this.getCouponStatusList()),
      date_from: getParseValue(),
      date_to:  getParseValue(),
      open_to_anyone: getParseBooleanValue()
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach( _key => {
      const key: string = this.getKeyWithoutDateId(_key);
      parsedValuesObj[key] = parseValues[key](params[key]);
    });
    return parsedValuesObj;
  }

  private getInputByKey(_inputs: QuestionBase<unknown>[], _key: string ): QuestionBase<unknown> {
    return _key.indexOf('open_to_anyone') >= 0 ? _inputs[7] : _inputs.find( _input => _input.key === _key);
  }

  private prepareInputValuesFromParams(inputs: QuestionBase<unknown>[], params: VoucherCountConditionForm) {
    Object.keys(params).forEach( _key => {
      const input = this.getInputByKey(inputs, _key);
      const value = input.getValue(params[_key]);
      if (value && value.hasOwnProperty('selectedIds')) {
        input.selectedIds = value.selectedIds;
      } else {
        input.value = value;
      }
    });
  }

  private getKeyWithoutDateId(_key: string): string {
    return _key.indexOf('open_to_anyone') >= 0 ? 'open_to_anyone' : _key;
  }

  private getCouponStatusList(): Option[] {
    return [
      { id: 'pending', name: this.translate.instant('resources.vouchers.states.pending') },
      { id: 'printed', name: this.translate.instant('resources.vouchers.states.printed') },
      { id: 'redeemed', name: this.translate.instant('resources.vouchers.states.redeemed') },
      { id: 'exhausted', name: this.translate.instant('resources.vouchers.states.soldout') },
      { id: 'cancelled', name: this.translate.instant('resources.vouchers.states.cancelled') }
    ];
  }
}
