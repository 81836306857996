<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content ">

      <div class="modal-header background-white">
        <h1 class="mrg-btm-5 first-letter-upper">
          <span *ngIf="!id">{{'resources.jobs.modal.new_bigquery_jobs' | translate}}</span>
          <span *ngIf="id">{{'resources.jobs.modal.update_bigquery_jobs' | translate}}</span>
        </h1>
        <button class="modal-close" (click)="closeModal()">
          <i class="ti-close"></i>
        </button>
      </div>

      <div #body class="modal-body back-color">
        <div class="card-block no-pdd">
          <form [formGroup]="jobsForm" *ngIf="jobsForm">
            <div class="row">
              <div class="col-md-4 ml-auto mr-auto">
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="card-title" [translate]="'resources.jobs.modal.fields.details'"></h4>
                  </div>
                  <div class="card-block padding-20">

                    <div class="row mrg-top-15">
                      <div class="col-md-12">
                        <div class="form-group">
                          <app-question [question]="getInputConfig('name')" [form]="jobsForm"></app-question>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <app-question [question]="getInputConfig('execution')" [form]="jobsForm"></app-question>
                        </div>
                      </div>
                    </div>

                    <div class="row" *ngIf="hasFormKeyWithValue('execution', 'monthly') ||
                                            hasFormKeyWithValue('execution', 'quarterly') ||
                                            hasFormKeyWithValue('execution', 'biannually')">
                      <div class="col-md-12">
                        <div class="form-group">
                          <app-question [question]="getInputConfig('execution_day')" [form]="jobsForm"></app-question>
                        </div>
                      </div>
                    </div>

                    <div class="row" *ngIf="hasFormKeyWithValue('execution', 'daily') ||
                                            hasFormKeyWithValue('execution', 'weekly') ||
                                            hasFormKeyWithValue('execution', 'fortnightly') ||
                                            hasFormKeyWithValue('execution', 'monthly') ||
                                            hasFormKeyWithValue('execution', 'quarterly') ||
                                            hasFormKeyWithValue('execution', 'biannually')">
                      <div class="col-md-12">
                        <div class="form-group">
                          <app-question [question]="getInputConfig('execution_hour')" [form]="jobsForm"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8 ml-auto mr-auto">
                <div class="card">
                  <div class="card-block padding-20">
                    <app-steps #stepFormComponent
                               (removeEmitter)="deleteStep($event)"
                               (saveEmitter)="handleStepSavedEmitter($event)">
                    </app-steps>
                  </div>
                </div>
                <div class="card mrg-top-15">
                  <div class="card-heading border bottom">
                    <h4 class="cart-title" [translate]="'resources.jobs.modal.fields.saved_steps'"></h4>
                  </div>
                  <div class="card-block padding-20">
                    <table *ngIf="scheduledTask.steps.length > 0" class="table table-striped table-overflow table-hover" aria-hidden="true">
                      <thead>
                        <th class="no-border-top"></th>
                        <th class="no-border-top">{{'resources.jobs.modal.fields.order' | translate}}</th>
                        <th class="no-border-top">{{'resources.jobs.modal.fields.id' | translate}}</th>
                        <th class="no-border-top">{{'resources.jobs.modal.fields.type' | translate}}</th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let step of scheduledTask.steps | orderBy: 'sort'; let i = index" [ngClass]="{ 'dotted-over': step.sort === sortedItemOver}">
                          <td>
                            <div class="btn-group move mrg-top-10" *ngIf="step">
                              <span class="btn btn-calm"
                                    draggable="true"
                                    (dragstart)="dragStart(step.sort)"
                                    (dragover)="dragOver(step.sort)"
                                    (dragleave)="dragLeave(step.sort)"
                                    (dragend)="dragEnd(step.sort)"
                                    [id]="step.id">
                                    <i class="ei-crossroads-alt"></i>
                              </span>
                            </div>
                            <div class="btn-group mrg-top-10">
                              <button class="btn btn-default" (click)="editStep(step, i, body)"><i class="fa fa-pencil mr-0 font-size-16 text-dark"></i></button>
                              <button class="btn btn-default" (click)="deleteStep(step)"><i class="fa fa-trash mr-0 font-size-16 text-dark"></i></button>
                            </div>
                          </td>
                          <td class="vertical-align-center">
                            <span>{{step.sort}}</span>
                          </td>
                          <td class="vertical-align-center">
                            <span>{{step.id}}</span>
                          </td>
                          <td class="vertical-align-center">
                            <span *ngIf="step && step.category === 'custom'">
                              {{'resources.jobs.modal.fields.custom' | translate}}
                              <span *ngIf="step.custom === 'DashboardsClearCache'">
                                ({{'resources.jobs.modal.fields.step_categories.custom_type.clear_cache' | translate}})
                              </span>
                              <span *ngIf="step.custom === 'ExpensiveQueryClearCache'">
                                ({{'resources.jobs.modal.fields.step_categories.custom_type.expensive_query_clear_cache' | translate}})
                              </span>
                            </span>

                            <span *ngIf="step && step.category == 'segment'">
                              {{'resources.jobs.modal.fields.tag_segment' | translate}}
                              <span>({{step.name}})</span>
                            </span>

                            <span *ngIf="step && step.category == 'segment_category'">
                              {{'resources.jobs.modal.fields.tag_segment_by_category' | translate}}
                              <span *ngIf="step.name!== ''">({{step.name}})</span>
                            </span>

                            <span *ngIf="step && step.category === 'pump'">
                              {{'resources.jobs.modal.fields.pump' | translate}}
                              <span *ngIf="step.name !== ''">({{step.name}})</span>
                              <span class="label label-info">{{translate(step.pumpCutCondition)}}</span>
                              <span *ngIf="step.append" class="label label-danger">{{'resources.jobs.modal.fields.append' | translate}}</span>
                            </span>

                            <span *ngIf="step && step.category == 'synchro'">
                              {{'resources.jobs.modal.fields.synchronization' | translate}}
                              <span *ngIf="step.name!== ''">({{step.name}})</span>
                            </span>

                            <span *ngIf="step && step.category == 'datastore_synchro'">
                              {{'resources.jobs.modal.fields.datastore_synchro' | translate}}
                              <span *ngIf="step.name!== ''">({{step.name}})</span>
                            </span>

                            <span *ngIf="step && step.category == 'period'">
                              {{'resources.jobs.modal.fields.step_categories.period' | translate}}
                              <span *ngIf="step.name">({{step.name}})</span>
                            </span>

                            <span *ngIf="step && step.category == 'grant_scores'">
                              {{'resources.jobs.modal.fields.step_categories.grant_scores' | translate}}
                              <span *ngIf="step.name">({{step.name}})</span>
                            </span>

                            <span *ngIf="step && step.category == 'dump'">
                              {{'resources.jobs.modal.fields.step_categories.dump' | translate}}
                              <span *ngIf="step.name">({{step.name}})</span>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table *ngIf="scheduledTask.steps.length === 0" class="table table-striped table-overflow table-hover" aria-hidden="true">
                      <tbody>
                        <tr class="odd">
                          <td class="dataTables_empty text-semibold text-center" [translate]="'components.data-table.messages.title_empty_table'"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="modal-footer background-white align-content-center" *ngIf="scheduledTask && jobsForm">
        <div class="pull-right d-flex align-items-center">
          <span class="mrg-right-15">
            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          </span>
          <button class="pull-right btn btn-info mrg-right-2"
                  (click)="save()"
                  [translate]="'common.buttons.save'"
                  [disabled]="!ableToSave()">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
