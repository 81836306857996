import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../shared/components/multiselect/multiselect';
import { BehaviorSubject } from 'rxjs';
import { of } from 'rxjs';
import { DateTimeZoneService } from '../../shared/services/date-time-zone.service';

@Injectable()
export class CampaignHistoriesService extends ResourcesService implements MultiselectDataSourceable {

  public campaignId: number;
  public optsSource = new BehaviorSubject<number>(null);
  public optsSource$ = this.optsSource.asObservable();

  constructor(http: HttpClient,
              private dateService: DateTimeZoneService) {
    super(http);
  }

  public setCampaign(id: number) {
    this.campaignId = id;
    this.optsSource.next( this.campaignId );
  }

  public fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {

    if ( !this.campaignId ) { return of({}); }

    let requestOptions = {
      apiEndPoint: `campaigns/${this.campaignId}/histories`,
      numberPerPage: 100,
      filtering: {},
      pageNumber: 1,
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  public fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `campaigns/${this.campaignId}/histories/${id}` });
  }

  public getCampaignHistoryById(id: number, historyId: number): Observable<any> {
    return this.getData({apiEndPoint: `campaigns/${id}/histories/${historyId}`});
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    const date = this.dateService.parseDateWithFormat(element.sent_at, 'DD/MM/YYYY HH:mm');
    return new MultiselectDataSource(element.id, `${date}`, element);
  }
}
