import { TranslateService } from "@ngx-translate/core";
import { Option } from "../../../../../models/common/option";

export function decorateAttrName(rawName: string): string {
  rawName = rawName.replace(/-/g, ' ');
  rawName = rawName.replace(/_/g, ' ');
  rawName = rawName.charAt(0).toUpperCase() + rawName.slice(1);
  return rawName;
}

export function getBooleanValueOptions(translate: TranslateService): Option[] {
  return [
    { id: false, name: translate.instant('resources.segment_conditions.options.false')},
    { id: true, name: translate.instant('resources.segment_conditions.options.true')}
  ];
}