export const PlanForecastDetailActionsMock = {
  _embedded: {
    list: [
      {
        id: 253,
        name: 'Bronze',
        campaign_id: 7624,
        coupon_id: null,
        audience: {},
        forecast: {
          data: {
            affectation_sales: 38.873120633333336,
            average_expenditure: 3.725858207667732,
            average_ticket: 3.1950510109589043,
            control_group: 2125,
            customers: 68717,
            delivered: 10307.55,
            discount: 25,
            discount_cost: 288.03352326334146,
            discount_over_purchase: 0,
            discount_type: 'percent',
            estimated_delivery: 15,
            estimated_redemption: 1.5,
            incremental: 1152.1340930533659,
            incremental_over_sales: 1,
            incremental_ratio: 3.4782608695652173,
            loyal_guru_cost: 43.20502848950122,
            max_redemption_per_coupon: 4,
            min_purchase: 0,
            min_purchase_type: 'none',
            old_customers: 0,
            pvp: 1.338913454649828,
            redemption_per_customer: 2,
            seasonability: 0.9824092139132841,
            total_customers: 70842,
            total_redemptions: 309.2265,
            unique_redemptions: 154.61325
          }
        },
        forecast_config: {},
        roi: {},
        _embedded: {
          coupon: {
            affectation: 'purchase',
            id: 16192,
            name: '*10% DTE*. FRUITS SECS AO 400G',
            description: null,
            available_to: null,
            available_from: null,
            static_code: null,
            key: 'https://prod-ca-products.herokuapp.com/promotions/5468602019632',
            active: true,
            availability: 'available',
            priority: null,
            available_days: 12,
            times_used: 3,
            _embedded: {
              synchro: {
                id: 1215,
                created_at: '2020-07-07T00:00:15.375+02:00',
                type: 'Streaming'
              }
            },
            printable: true,
            parent_id: 16192,
            _links: {}
          },
          campaign_history: {
            id: 2791,
            sent_at: '2020-11-18T15:37:00.081+01:00',
            sent_number: 1,
            apply: 1,
            status: 'success',
            segment_id: 7133,
          },
          campaign_plan: {
            id: 30,
            name: 'test3',
            type: 'Plans::SupplierCampaign',
            config: {
              supplier_id: 1880,
              supplier_type: 'up',
              segment_id: 107,
              period_date_from: '2020-06-16',
              period_date_to: '2020-06-20'
            },
            status: 'draft'
          }
        }
      },
      {
        id: 252,
        name: 'Silver',
        campaign_id: 7624,
        coupon_id: null,
        audience: {},
        forecast: {
          data:{
            affectation_sales: 143.5193336451611,
            customers: 465,
            delivered: 418.5,
            discount: 15,
            discount_cost: 197.74124999999998,
            discount_over_purchase: 0,
            discount_type: 'percent',
            estimated_delivery: 90,
            estimated_redemption: 15,
            incremental: 706.8506033073395,
            incremental_over_sales: 0.15887498768025157,
            incremental_ratio: 3.1083686308654435,
            loyal_guru_cost: 29.661187499999997,
            max_redemption_per_coupon: 4,
            min_purchase: 14,
            min_purchase_type: 'money',
            redemption_per_customer: 1.5,
            total_redemptions: 94.1625,
            unique_redemptions: 62.775
          }
        },
        roi: {},
        _embedded: {
          coupon: {
            affectation: 'purchase',
            id: 16192,
            name: '*10% DTE*. FRUITS SECS AO 400G',
            description: null,
            available_to: null,
            available_from: null,
            static_code: null,
            key: 'https://prod-ca-products.herokuapp.com/promotions/5468602019632',
            active: true,
            availability: 'available',
            priority: null,
            available_days: 12,
            times_used: 3,
            _embedded: {
              synchro: {
                id: 1215,
                created_at: '2020-07-07T00:00:15.375+02:00',
                type: 'Streaming'
              }
            },
            printable: true,
            parent_id: 16192,
            _links: {}
          },
          campaign_plan: {
            id: 30,
            name: 'test3',
            type: 'Plans::SupplierCampaign',
            config: {
              supplier_id: 1880,
              supplier_type: 'up',
              segment_id: 107,
              period_date_from: '2020-06-16',
              period_date_to: '2020-06-20'
            },
            status: 'draft'
          }
        }
      },
      {
        id: 251,
        name: 'Gold',
        campaign_id: 7624,
        coupon_id: null,
        audience: {},
        forecast: {
          data: {
            affectation_sales: 48.388731870967746,
            customers: 75663,
            delivered: 11349.449999999999,
            discount: 25,
            discount_cost:277.9551066272914,
            discount_over_purchase:0,
            discount_type: 'percent',
            estimated_delivery: 15,
            estimated_redemption: 1,
            incremental: 1085.939873616425,
            incremental_over_sales: 1,
            incremental_ratio: 3.3972951737898938,
            loyal_guru_cost: 41.69326599409371,
            max_redemption_per_coupon: 4,
            min_purchase: 0,
            min_purchase_type: 'none',
            redemption_per_customer:1.6,
            total_redemptions: 181.5912,
            unique_redemptions: 113.49449999999999
          }
        },
        roi: {},
        _embedded: {
          coupon: {
            affectation: 'purchase',
            id: 16192,
            name: '*10% DTE*. FRUITS SECS AO 400G',
            description: null,
            available_to: null,
            available_from: null,
            static_code: null,
            key: 'https://prod-ca-products.herokuapp.com/promotions/5468602019632',
            active: true,
            availability: 'available',
            priority: null,
            available_days: 12,
            times_used: 3,
            _embedded: {
              synchro: {
                id: 1215,
                created_at: '2020-07-07T00:00:15.375+02:00',
                type: 'Streaming'
              }
            },
            printable: true,
            parent_id: 16192,
            _links: {}
          },
          campaign_plan: {
            id: 30,
            name: 'test3',
            type: 'Plans::SupplierCampaign',
            config: {
              supplier_id: 1880,
              supplier_type: 'up',
              segment_id: 107,
              period_date_from: '2020-06-16',
              period_date_to: '2020-06-20'
            },
            status: 'draft'
          }
        }
      },
      {
        id: 250,
        name: 'Not Purch. 1m',
        campaign_id: 7624,
        coupon_id: null,
        audience: {},
        forecast: {
          data:{
            affectation_sales: 48.388731870967746,
            customers: 7707,
            delivered: 3853.5,
            discount: 25,
            discount_cost: 383.3968838811429,
            discount_over_purchase: 0,
            discount_type: 'percent',
            estimated_delivery: 50,
            estimated_redemption: 5,
            incremental: 1497.8892407438145,
            incremental_over_sales: 1,
            incremental_ratio: 3.397295173789893,
            loyal_guru_cost: 57.50953258217144,
            max_redemption_per_coupon: 4,
            min_purchase: 0,
            min_purchase_type: 'none',
            redemption_per_customer: 1.3,
            total_redemptions: 250.47750000000002,
            unique_redemptions: 192.675
          }
        },
        roi: {},
        _embedded: {
          coupon: {
            affectation: 'purchase',
            id: 16192,
            name: '*10% DTE*. FRUITS SECS AO 400G',
            description: null,
            available_to: null,
            available_from: null,
            static_code: null,
            key: 'https://prod-ca-products.herokuapp.com/promotions/5468602019632',
            active: true,
            availability: 'available',
            priority: null,
            available_days: 12,
            times_used: 3,
            _embedded: {
              synchro: {
                id: 1215,
                created_at: '2020-07-07T00:00:15.375+02:00',
                type: 'Streaming'
              }
            },
            printable: true,
            parent_id: 16192,
            _links: {}
          },
          campaign_plan: {
            id: 31,
            name: 'tes4',
            type: 'Plans::SupplierCampaign',
            config: {
              scope: 'product',
              supplier_id: 1869,
              periods: ['%30_days_ago%'],
              has_bought: true,
              active_clients: true,
              supplier_type: 'cross'
            },
            status: 'draft'
          }
        }
      },
      {
        id: 242,
        name: 'eee_action',
        campaign_id: 7624,
        coupon_id: 9,
        audience: {},
        forecast: {
          data: {
            discount_cost: 333,
            incremental: 35.23,
            incremental_ratio: 28.31,
            delivered: 100,
            total_redemptions: 100,
          }
        },
        roi: {},
        _embedded: {
          coupon: {
            affectation: 'purchase',
            id: 16192,
            name: '*10% DTE*. FRUITS SECS AO 400G',
            description: null,
            available_to: null,
            available_from: null,
            static_code: null,
            key: 'https://prod-ca-products.herokuapp.com/promotions/5468602019632',
            active: true,
            availability: 'available',
            priority: null,
            available_days: 12,
            times_used: 3,
            _embedded: {
              synchro: {
                id: 1215,
                created_at: '2020-07-07T00:00:15.375+02:00',
                type: 'Streaming'
              }
            },
            printable: true,
            parent_id: 16192,
            _links: {}
          },
          campaign_plan: {
            id: 29,
            name: 'test2',
            type: 'Plans::CustomCampaign',
            config: {
              segment_ids: [690]
            },
            status: 'completed'
          }
        }
      },
      {
        id: 241,
        name: 'id ends 9_action',
        campaign_id: 7624,
        coupon_id: 9,
        audience: {},
        forecast: {
          data: {
            discount_cost: 167,
            incremental: 15.34,
            incremental_ratio: 8.32,
            delivered: 500,
            total_redemptions: 100,
          }
        },
        roi: {},
        _embedded: {
          coupon: {
            affectation: 'purchase',
            id: 16192,
            name: '*10% DTE*. FRUITS SECS AO 400G',
            description: null,
            available_to: null,
            available_from: null,
            static_code: null,
            key: 'https://prod-ca-products.herokuapp.com/promotions/5468602019632',
            active: true,
            availability: 'available',
            priority: null,
            available_days: 12,
            times_used: 3,
            _embedded: {
              synchro: {
                id: 1215,
                created_at: '2020-07-07T00:00:15.375+02:00',
                type: 'Streaming'
              }
            },
            printable: true,
            parent_id: 16192,
            _links: {}
          },
          campaign_plan: {
            id: 28,
            name: 'test',
            type: 'Plans::CustomCampaign',
            config: {
              segment_ids: [5854]
            },
            status: 'completed'
          }
        }
      },
      {
        id: 254,
        name: 'test supplier 1',
        campaign_id: 7624,
        coupon_id: 9,
        audience: {},
        forecast: {
          data: {
            discount_cost: 109,
            incremental: 11.42,
            incremental_ratio: 12.23
          }
        },
        roi: {},
        _embedded: {
          coupon: {
            affectation: 'purchase',
            id: 16192,
            name: '*10% DTE*. FRUITS SECS AO 400G',
            description: null,
            available_to: null,
            available_from: null,
            static_code: null,
            key: 'https://prod-ca-products.herokuapp.com/promotions/5468602019632',
            active: true,
            availability: 'available',
            priority: null,
            available_days: 12,
            times_used: 3,
            _embedded: {
              synchro: {
                id: 1215,
                created_at: '2020-07-07T00:00:15.375+02:00',
                type: 'Streaming'
              }
            },
            printable: true,
            parent_id: 16192,
            _links: {}
          },
          campaign_plan: {
            id: 28,
            name: 'test',
            type: 'Plans::SupplierCampaign',
            config: {
              segment_ids: [5854]
            },
            status: 'completed'
          }
        }
      },
      {
        id: 255,
        name: 'test supplier 2',
        campaign_id: 7624,
        coupon_id: 9,
        audience: {},
        forecast: {
          data: {
            discount_cost: 120,
            incremental: 9.31,
            incremental_ratio: 2.18
          }
        },
        roi: {},
        _embedded: {
          coupon: {
            affectation: 'purchase',
            id: 16192,
            name: '*10% DTE*. FRUITS SECS AO 400G',
            description: null,
            available_to: null,
            available_from: null,
            static_code: null,
            key: 'https://prod-ca-products.herokuapp.com/promotions/5468602019632',
            active: true,
            availability: 'available',
            priority: null,
            available_days: 12,
            times_used: 3,
            _embedded: {
              synchro: {
                id: 1215,
                created_at: '2020-07-07T00:00:15.375+02:00',
                type: 'Streaming'
              }
            },
            printable: true,
            parent_id: 16192,
            _links: {}
          },
          campaign_plan: {
            id: 28,
            name: 'test',
            type: 'Plans::SupplierCampaign',
            config: {
              segment_ids: [5854]
            },
            status: 'rejected'
          }
        }
      },
      {
        id: 2411,
        name: 'roi test action',
        campaign_id: 7624,
        coupon_id: 9,
        audience: {},
        forecast: {
          data:{
            affectation_sales: 87.27331999999993,
            customers: 1360,
            delivered: 951.9999999999999,
            discount: 10,
            discount_cost: 191.92319999999995,
            discount_over_purchase: 0,
            discount_type: 'percent',
            estimated_delivery: 70,
            estimated_redemption: 6,
            incremental: 1621.2171299903644,
            incremental_over_sales: 0.619210670565516,
            incremental_ratio: 7.345407048645385,
            loyal_guru_cost: 28.788479999999993,
            max_redemption_per_coupon: 4,
            min_purchase: 28,
            min_purchase_type: 'money',
            redemption_per_customer: 1.2,
            total_redemptions: 68.54399999999998,
            unique_redemptions: 57.11999999999999
          }
        },
        roi: {
          updated_at: '2020-12-03T14:47:20.598258Z',
          data: {
            customers: 2366, // C, Nº clients
            delivered_vouchers: 1403, // D, Delivered coupons
            delivered_ratio: 0.5929839391377852, // E, % Delivered
            total_redemptions: 68, // F, Total Redemptions
            redemptions_per_customer: 1, // G, Redemptions per customer
            total_redemptions_ratio: 0.028740490278951817, // H, % Total redemption
            unique_redemptions: 68,
            unique_redemptions_ratio: 0.048467569493941556, // I, % Unique redemption
            discount_cost: 335.26, // N, Discount cost
            loyal_guru_cost: 50.288999999999994, // O, Loyal Guru cost
            incremental: -26.971534297555593, // P, Incremental sales
            incremental_ratio: -0.06995617754826389, // Q, Incremental ratio
            redeemers_sales_plan: 98.75,
            redeemers_sales_before: 162.76,
            control_sales_plan: 182.65,
            control_sales_before: 236.46
          }
        },
        _embedded: {
          coupon: {
            affectation: 'purchase',
            id: 16192,
            name: '*10% DTE*. FRUITS SECS AO 400G',
            description: null,
            available_to: null,
            available_from: null,
            static_code: null,
            key: 'https://prod-ca-products.herokuapp.com/promotions/5468602019632',
            active: true,
            availability: 'available',
            priority: null,
            available_days: 12,
            times_used: 3,
            _embedded: {
              synchro: {
                id: 1215,
                created_at: '2020-07-07T00:00:15.375+02:00',
                type: 'Streaming'
              }
            },
            printable: true,
            parent_id: 16192,
            _links: {}
          },
          campaign_plan: {
            id: 28,
            name: 'delivered campaign 1',
            type: 'Plans::CustomCampaign',
            config: {},
            status: 'delivered'
          }
        }
      },
      {
        id: 2413,
        name: 'roi test action 2',
        campaign_id: 7624,
        coupon_id: 9,
        audience: {},
        forecast: {},
        roi: {
          data: {
            customers: 1266,
            delivered_vouchers: 1313,
            delivered_ratio: 0.21929839391377852,
            total_redemptions: 12,
            redemptions_per_customer: 2,
            total_redemptions_ratio: 0.076234123,
            unique_redemptions: 32,
            unique_redemptions_ratio: 0.04123112341,
            discount_cost: 123.12312341,
            // loyal_guru_cost: null, // To test if key is not defined
            incremental: -22.971534297555593,
            incremental_ratio: null, // To test if key is null or undefined it should display a 0.
            redeemers_sales_plan: 98.75,
            redeemers_sales_before: 162.76,
            control_sales_plan: 182.65,
            control_sales_before: 236.46
          }
        },
        _embedded: {
          coupon: {
            affectation: 'purchase',
            id: 16192,
            name: '*10% DTE*. FRUITS SECS AO 400G',
            description: null,
            available_to: null,
            available_from: null,
            static_code: null,
            key: 'https://prod-ca-products.herokuapp.com/promotions/5468602019632',
            active: true,
            availability: 'available',
            priority: null,
            available_days: 12,
            times_used: 3,
            _embedded: {
              synchro: {
                id: 1215,
                created_at: '2020-07-07T00:00:15.375+02:00',
                type: 'Streaming'
              }
            },
            printable: true,
            parent_id: 16192,
            _links: {}
          },
          campaign_plan: {
            id: 28,
            name: 'delivered campaign 2',
            type: 'Plans::SupplierCampaign',
            config: {},
            status: 'delivered'
          }
        }
      },
      {
        id: 315,
        name: 'Bronze',
        campaign_id: 7624,
        _embedded: {
          coupon: {
            affectation: 'purchase',
            id: '847',
            name: '10% ACEITE OLIVA SUAVE ABRIL, GARRAFA 5 LITROS POR COMPRA MÍNIMA 2 UNIDADES',
            description: 'Hasta un máximo descuento de 20€. No acumulable con otros vales descuento',
            available_to: null,
            available_from: null,
            static_code: null,
            key: null,
            active: true,
            availability: 'available',
            priority: null,
            available_days: 6,
            times_used: 2,
            _embedded: {},
            printable: true,
            parent_id: 847,
            _links: {}
          },
          segment: {
            id: 7413,
            name: 'Plan 10|20|Bronze',
            description: null,
            action_lock: false,
            operator: 'and',
            created_from_csv: false
          },
          campaign_plan: {
            id: 20,
            name: 'ACEITES ABRIL,S.L.(00-09) - SUAVE',
            type: 'Plans::SupplierCampaign',
            config: {
              scope: 'feature',
              supplier_id: 'proveedor|313',
              periods: [
                'never',
                '%180_days_ago%'
              ],
              has_bought: false,
              active_clients: true,
              supplier_type: 'up_cross',
              product_ids: [
                10295855
              ],
              segment_id: 7062,
              period_date_from: '2019-07-01',
              period_date_to: '2019-09-30',
              feature_ids: [ 'categoria|20901' ],
              taxonomy_slug: [ 'categoria' ]
            },
            status: 'delivered',
            user_audience_start_date: '2019-08-01',
            user_audience_end_date: '2019-08-31'
          }
        },
        audience: {
          id: 'c3c2344d-f23c-51c9-b531-c4c6cdf9884a',
          updated_at: '2020-08-20T10:32:47.935517Z',
          errors: [],
          data: {
            affectation_sales: 36.67612903225809,
            average_expenditure: 12.358260869565225,
            average_ticket: 11.968000000000007,
            control_group: 12,
            frequency: 1.0326086956521738,
            pvp: 11.721237113402069,
            sales_mpaa: 322.0400000000001,
            sales_period_mpaa: 236.406,
            seasonability: 1.3622327690498548,
            total_customers: 240,
            units_per_ticket: 1.0210526315789474
          }
        },
        forecast: {
          id: 'a764de76-b6db-51eb-9d3f-083dd785ec57',
          updated_at: '2020-08-20T10:39:03.726526Z',
          errors: [],
          data: {
            affectation_sales: 36.67612903225809,
            average_expenditure: 12.358260869565225,
            average_ticket: 11.968000000000007,
            customers: 240,
            delivered: 132,
            discount: 10,
            discount_cost: 9.283219793814439,
            discount_over_purchase: 0,
            discount_type: 'percent',
            estimated_delivery: 55,
            estimated_redemption: 3,
            incremental: 68.14708913726544,
            incremental_over_sales: 0.23225968407587197,
            incremental_ratio: 6.383382026537346,
            loyal_guru_cost: 1.3924829690721658,
            max_redemption_per_coupon: 1,
            min_purchase: 2,
            min_purchase_type: 'units',
            old_customers: 0,
            pvp: 11.721237113402069,
            redemption_per_customer: 1,
            seasonability: 1.3622327690498548,
            total_customers: 240,
            total_redemptions: 13.96,
            unique_redemptions: 3.96
          }
        },
        roi: {
          id: 'aad675b1-d308-58fb-9084-85984cb94041',
          updated_at: '2020-09-18T09:00:48.515937Z',
          errors: [],
          data: {
            control_sales_before: 0,
            control_sales_plan: 0,
            customers: 228,
            delivered_ratio: 0.05701754385964912,
            delivered_vouchers: 13,
            discount_cost: 0,
            incremental: 0,
            incremental_ratio: 0,
            loyal_guru_cost: 0,
            redeemers_sales_before: 0,
            redeemers_sales_plan: 0,
            redemptions_per_customer: 0,
            total_redemptions: 0,
            total_redemptions_ratio: 0,
            unique_redemptions: 0,
            unique_redemptions_ratio: 0,
            control_group: 371
          }
        }
      }
    ]
  }
};
