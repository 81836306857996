<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-10">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form  [formGroup]="locationTaxonomiesTermsForm" *ngIf="locationTaxonomiesTermsForm">

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name" [translate]="'resources.location_taxonomy_terms.form.name'"></label>
                      <span *ngIf="getInputConfig('name').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('name')" [form]="locationTaxonomiesTermsForm"></app-question>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="external_id" [translate]="'resources.location_taxonomy_terms.form.external_id'"></label>
                      <span *ngIf="getInputConfig('external_id').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('external_id')" [form]="locationTaxonomiesTermsForm"></app-question>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="taxonomy_slug" [translate]="'resources.location_taxonomy_terms.form.taxonomy_slug'"></label>
                      <span *ngIf="getInputConfig('taxonomy_slug').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('taxonomy_slug')" [form]="locationTaxonomiesTermsForm"></app-question>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group mrg-btm-0">
                      <app-question [question]="getInputConfig('dynamic')" [form]="locationTaxonomiesTermsForm"></app-question>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <div [hidden]="hasFormKeyWithValue('dynamic', true)">
                        <app-question [question]="getInputConfig('location_ids')" [form]="locationTaxonomiesTermsForm"></app-question>
                      </div>

                      <div [hidden]="hasFormKeyWithValue('dynamic', false)">
                        <label for="sql">
                          <span>{{'resources.location_taxonomy_terms.form.sql' | translate}}</span>
                          <em class="ti-info-alt pull-right mrg-top-3 mrg-left-5"
                             tooltipPosition="right"
                             pTooltip="{{ 'resources.location_taxonomy_terms.form.sql_tooltip' | translate }}">
                          </em>
                        </label>
                        <app-question [question]="getInputConfig('sql')" [form]="locationTaxonomiesTermsForm"></app-question>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
