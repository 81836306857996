export class CampaignContent {

  public via: string;
  public template: object;
  public motive: string;

  constructor(cfg: object) {
    this.via = cfg['via'] || cfg['_embedded'].campaign.via || cfg['_embedded'].campaign_info.via;
    this.template = cfg['_embedded'].template || cfg['_embedded'].campaign.template || cfg['_embedded'].campaign_info.template;
    this.motive = cfg['motive'] || cfg['_embedded'].campaign.motive || cfg['_embedded'].campaign_info.motive;
  }

}
