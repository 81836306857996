<div class="page-title background-white">
  <div class="row pdd-horizon-15">
    <div class="col-md-9 d-flex align-items-center min-height-60px">
        <h4 class="no-mrg-vertical">{{ modeTitle }}</h4>
        <ng-container *ngIf="originBadge">
          <span class="label font-size-14 mrg-btm-0 mrg-left-5" [ngClass]="originBadge.className">{{ originBadge.name }}</span>
        </ng-container>
    </div>
  </div>
</div>
<form [formGroup]="customerAttributesForm" *ngIf="customerAttributesForm; else loadingFormData">
  <div class="mrg-top-15 container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading border bottom">
            <h4 class="card-title">
              {{ 'resources.customer_attributes.fields.description' | translate }}
            </h4>
          </div>
          <div class="card-block">
            <ng-container *ngIf="!isAdminRole">
              <div class="row mrg-btm-10">
                <div class="col-md-9 ml-auto mr-auto">
                  <div class="inline-block">
                    <div class="alert alert-info d-flex align-items-center">
                      <em class="fa-light fa-exclamation-circle mrg-right-10 font-size-16"></em>
                      <span class="font-size-14"> {{ 'resources.customer_attributes.messages.only_platform_administrator' | translate }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="row pdd-btm-10" [class.disable-form]="!isAdminRole">
              <div class="col-md-9 ml-auto mr-auto">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="name" class="field-title">
                        <span>{{ 'resources.customer_attributes.fields.name' | translate }}</span>
                        <span *ngIf="getInputConfig('name').required" class="text-danger">*</span>
                      </label>
                      <app-question [question]="getInputConfig('name')" [form]="customerAttributesForm"></app-question>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="description" class="field-title">
                        <span>{{ 'resources.customer_attributes.fields.description' | translate }}</span>
                        <span *ngIf="getInputConfig('description').required" class="text-danger">*</span>
                      </label>
                      <app-question [question]="getInputConfig('description')" [form]="customerAttributesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label for="attribute_type" class="field-title">
                      {{ 'resources.customer_attributes.fields.attribute_type' | translate }}
                      <span *ngIf="getInputConfig('attribute_type').required" class="text-danger">*</span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group-radio-input responsive-radio-input">
                      <app-question class="radio-button-rounded" [question]="getInputConfig('attribute_type')" [form]="customerAttributesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group-radio-input responsive-radio-input">
                      <label for="data_type" class="field-title">
                        <span>{{ 'resources.customer_attributes.fields.data_type' | translate }}</span>
                        <span *ngIf="getInputConfig('data_type').required" class="required-text-bold">*</span>
                      </label>
                      <app-question [question]="getInputConfig('data_type')" [form]="customerAttributesForm"></app-question>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="hasFormKeyWithValue('attribute_type', 'calculation')">
                  <app-calculation-section
                    [customerAttributesForm]="customerAttributesForm"
                    [inputs]="inputs">
                  </app-calculation-section>
                </ng-container>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-xl-9"></div>
                <div class="col-xl-3 buttons-section">
                  <div class="d-flex justify-content-start align-items-center">
                    <button
                      type="button"
                      class="btn btn-default"
                      (click)="back(true)"
                      [attr.data-cy]="'button_cancelCustomerAttributes'"
                    >
                      {{ 'common.buttons.cancel' | translate }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-info"
                      [class.disable-form]="!isAdminRole"
                      (click)="save()"
                      [disabled]="!customerAttributesForm.valid || loading"
                      [attr.data-cy]="'button_saveCustomerAttributes'"
                    >
                      <span *ngIf="loading">
                        <em class="fa fa-spinner fa-spin"></em>
                      </span>
                      {{ 'common.buttons.save' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #loadingFormData>
  <app-loader styleClass="mrg-top-20" noWrapper />
</ng-template>
