import { ResourcesService } from './resources.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MultiselectDataSource, MultiselectDataSourceable } from '../components/multiselect/multiselect';

@Injectable()
export class ContactPoliciesService extends ResourcesService implements MultiselectDataSourceable {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  fetchMultiselect( searchValues?: string, page?: number, filters?: object ): Observable<object> {

    let requestOptions = {
      apiEndPoint: 'contact_policies',
      numberPerPage: 10,
      pageNumber: 1,
      filtering: {},
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    if ( searchValues ) {
      const filterObj = { filtering: {name: searchValues} };
      requestOptions = {...requestOptions, ...filterObj};
    }

    if (filters) {
      const filtering = Object.assign({}, requestOptions.filtering, filters);
      const filterObj = { filtering: filtering};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: string): Observable<object> {
    return this.getData({ apiEndPoint: `contact_policies/${id}` });
  }

  deleteContactPolicy(id: string): Observable<any> {
    return this.deleteResource({}, `contact_policies/${id}`);
  }

  updateContactPolicy(contactPolicyPayload, contactPolicyId: string): Observable<any> {
    return this.patchResource(contactPolicyPayload, `contact_policies/${contactPolicyId}`);
  }

  createContactPolicy(contactPolicyPayload): Observable<any> {
    return this.postResource(contactPolicyPayload, 'contact_policies');
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.name, element);
  }
}