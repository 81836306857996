import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { ConditionService } from '../../services/conditions/condition.service';
import { ConfirmationService } from '../../services/confirmation.service';
import { Location } from '@angular/common';
import { QuestionBase } from '../../models/forms/question-base';
import { QuestionControlService } from '../../services/question-control.service';
import { SegmentsService } from '../../../resources/segments/segments.service';
import { Subject, finalize, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SegmentCSV } from '../../models/segments/segment-csv';
import { UserIdleSessionService } from '../../../services/user-idle-session/user-idle-session.service';

enum CSVSegmentMode {
  Create  = 'create',
  Update = 'update',
}

@Component({
  selector: 'app-create-update-segments-csv',
  templateUrl: './create-update-segments-csv.component.html',
  styleUrls: ['./create-update-segments-csv.component.css']
})

/**
 * Note that data is handled with JSON.stringify / JSON.parse
 * Only set simple data or disable as methods will be lost
 */
export class CreateUpdateSegmentsCSVComponent implements OnInit, OnDestroy {
  static DEFAULT_OPERATOR = 'and';

  form: UntypedFormGroup;
  hideCSVField = false;
  inputs: QuestionBase<unknown>[];
  loading = false;
  mode: string = this.route.snapshot.data.mode;

  get modeTitle(): string {
    return this.mode === CSVSegmentMode.Create ? 'resources.segments.new_csv_segment' : 'resources.segments.update_csv_segment';
  }

  private segmentId: number;
  private destroy$ = new Subject<void>();

  private get isEditMode(): boolean {
    return !!this.segmentId;
  }

  constructor(
    private router: Router,
    private qcs: QuestionControlService,
    private segmentService: SegmentsService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private conditionService: ConditionService,
    private route: ActivatedRoute,
    private location: Location,
    private userIdleSessionService: UserIdleSessionService
  ) { }

  ngOnInit(): void {
    this.conditionService.formValues = {};
    this.userIdleSessionService.setupUserIdleSession();
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.userIdleSessionService.destroyUserIdleSession();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  closeModal(): void {
    this.location.back();
  }

  ableToSaveSegment(): boolean {
    return !!(this.form.value.name && (this.mode === CSVSegmentMode.Create && this.form.value.create_from_csv_content || this.mode === CSVSegmentMode.Update));
  }

  save(): void {
    this.loading = true;
    const formValue = this.form.getRawValue();
    const payload = {
      create_from_csv_content: formValue.create_from_csv_content ?? false,
      description: formValue.description,
      name: formValue.name,
      segment_category_id: formValue.segment_category_id?.[0]?.id ?? null,
      conditions: []
    };

    const request$ = this.isEditMode ? this.segmentService.patchSegmentById(this.segmentId, payload) : this.segmentService.saveSegment(payload);
    request$.pipe(takeUntil(this.destroy$), finalize(() => this.loading = false)).subscribe({
      next: (successData: SegmentCSV) => this.handleSuccessRequest(successData),
      error: (errorData: HttpErrorResponse) => this.displayHttpErrorAlert(errorData)
    });
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.segmentId = params.id;
        this.getSegment();
      } else {
        this.setInputsAndForm({});
      }
    });
  }

  private getSegment(): void {
    this.loading = true;
    this.segmentService.getSegmentById(this.segmentId).pipe(takeUntil(this.destroy$), finalize(() => this.loading = false)).subscribe({
      next: (segmentData: SegmentCSV) => {
        this.hideCSVField = true;
        this.setInputsAndForm(segmentData);
      },
      error: (errorData: HttpErrorResponse) => this.displayHttpErrorAlert(errorData)
    });
  }

  private setInputsAndForm(requestData: SegmentCSV): void {
    this.inputs = this.segmentService.getInputs(requestData);
    this.setSegmentCategoryIdInput();
    this.form = this.qcs.toFormGroup(this.inputs);
  }

  private setSegmentCategoryIdInput(): void {
    const segmentCategoryIdInput = this.getInputConfig('segment_category_id');
    if (!segmentCategoryIdInput?.selectedIds?.length) {
      segmentCategoryIdInput.value = [{ id: -1, name: this.translate.instant('resources.segments.messages.no_category') }];
    }
  }

  private handleSuccessRequest(successData: SegmentCSV): void {
    const successTitle = this.translate.instant(this.isEditMode ? 'resources.segments.messages.update_success_title' : 'resources.segments.messages.create_success_title');
    const successText = this.translate.instant(this.isEditMode ? 'resources.segments.messages.update_success_desc' : 'resources.segments.messages.create_success_csv_desc');
    this.confirmationService.displaySuccessAlert(successTitle, successText).catch(() => {});
    this.router.navigateByUrl(`/segments/segments/${successData['id']}`).catch(() => {});
  }

  private displayHttpErrorAlert(error: HttpErrorResponse): void {
    this.confirmationService.displayHttpErrorAlert(error);
  }
}
