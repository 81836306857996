<div id="customer-detail-header" class="page-title background-white">
  <div class="row pdd-horizon-15" *ngIf="customer">
    <div class="d-flex align-items-center min-height-60px pdd-left-15 pdd-right-15">
      <h4 class="flex-1 no-mrg-vertical">
        {{ customer.fullName }}
        (ID: {{ customer.id }})
      </h4>
      <ng-container *ngIf="customer.fraudulent && flags.showFraudulent">
        <div class="mrg-left-10">
          <span  class="label label-critical font-size-14">
            {{ 'resources.profiles.show.fraudulent' | translate }}
          </span>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ul id="customer-detail-tabs" class="nav nav-tabs w-100" role="tablist" *ngIf="roleSlug">
  <li class="nav-item" [hidden]="isHiddenByRole(['integration'])">
    <a (click)="selectTab('summary')"
        [routerLink]="['summary']"
        class="nav-link {{slug === 'summary' ? 'active' : ''}}"
        data-toggle="tab"
        href="#profiles-tab-1"
        role="tab"
        aria-selected="true"
        [translate]="'resources.profiles.show.tabs.summary'">
    </a>
  </li>
  <li class="nav-item" [hidden]="isHiddenByRole(['integration'])">
    <a (click)="selectTab('scores')"
        class="nav-link {{slug === 'scores' ? 'active' : ''}}"
        [routerLink]="['scores']"
        data-toggle="tab"
        href="#profiles-tab-7"
        role="tab"
        aria-selected="false"
        [translate]="'resources.profiles.show.tabs.scores'">
    </a>
  </li>
  <li class="nav-item" [hidden]="isHiddenByRole(['integration'])">
    <a (click)="selectTab('rewards')"
        class="nav-link {{slug === 'rewards' ? 'active' : ''}}"
        [routerLink]="['rewards']"
        data-toggle="tab"
        href="#profiles-tab-5"
        role="tab"
        aria-selected="false"
        [translate]="'resources.profiles.show.tabs.rewards'">
    </a>
  </li>
  <li class="nav-item" [hidden]="isHiddenByRole(['customer_service_mango', 'customer_service_mango_limited'])">
    <a (click)="selectTab('vouchers')"
        class="nav-link {{slug === 'vouchers' ? 'active' : ''}}"
        [routerLink]="['vouchers']"
        data-toggle="tab"
        href="#profiles-tab-3"
        role="tab"
        aria-selected="false"
        [translate]="'resources.profiles.show.tabs.vouchers'">
    </a>
  </li>
  <li class="nav-item" [hidden]="isHiddenByRole(['integration', 'store_manager', 'customer_service', 'customer_service_mango', 'customer_service_mango_limited'])">
    <a (click)="selectTab(' campaigns')"
        class="nav-link {{slug === 'campaigns' ? 'active' : ''}}"
        [routerLink]="['campaigns']"
        data-toggle="tab"
        href="#profiles-tab-4"
        role="tab"
        aria-selected="false"
        [translate]="'resources.profiles.show.tabs.campaigns'">
    </a>
  </li>
  <li class="nav-item" *ngIf="customer && !customerHasAssociations" [hidden]="isHiddenByRole(['integration']) || !flags.associatedTabVisibility">
    <a (click)="selectTab('associates')"
        class="nav-link {{slug === 'associates' ? 'active' : ''}}"
        [routerLink]="['associates']"
        data-toggle="tab"
        href="#profiles-tab-8"
        role="tab"
        aria-selected="false"
        [translate]="'resources.profiles.show.tabs.associates'">
    </a>
  </li>
  <li class="nav-item" *ngIf="customer && customerHasAssociations" [hidden]="isHiddenByRole(['integration']) || !flags.associationsTabVisibility">
    <a (click)="selectTab('associations')"
        class="nav-link {{slug === 'associations' ? 'active' : ''}}"
        [routerLink]="['associations']"
        data-toggle="tab"
        href="#profiles-tab-9"
        role="tab"
        aria-selected="false"
        [translate]="'resources.profiles.show.tabs.associations'">
    </a>
  </li>
  <li class="nav-item">
    <a (click)="selectTab('customer_attributes')"
        class="nav-link {{slug === 'customer_attributes' ? 'active' : ''}}"
        [routerLink]="['customer-attributes']"
        data-toggle="tab"
        href="#profiles-tab-15"
        role="tab"
        aria-selected="false"
        [translate]="'resources.profiles.show.tabs.customer_attributes'">
    </a>
  </li>
  <li class="nav-item" [hidden]="!flags.customEntitiesVisibility || isHiddenByRole('store_manager')">
    <a (click)="selectTab('custom_entities')"
        class="nav-link {{slug === 'custom_entities' ? 'active' : ''}}"
        [routerLink]="['custom-entities']"
        data-toggle="tab"
        href="#profiles-tab-14"
        role="tab"
        aria-selected="false"
        [translate]="'resources.profiles.show.tabs.custom_entities'">
    </a>
  </li>
  <li class="nav-item" [hidden]="isHiddenByRole(['integration'])">
    <a (click)="selectTab('activities')"
        class="nav-link {{slug === 'activities' ? 'active' : ''}}"
        [routerLink]="['activities']"
        data-toggle="tab"
        href="#profiles-tab-2"
        role="tab"
        aria-selected="false"
        [translate]="'resources.profiles.show.tabs.activities'">
    </a>
  </li>
  <li class="nav-item" [hidden]="!flags.showGdprConsentHistory">
    <a (click)="selectTab('gdpr')"
        class="nav-link {{slug === 'gdpr' ? 'active' : ''}}"
        [routerLink]="['gdpr']"
        data-toggle="tab"
        href="#profiles-tab-12"
        role="tab"
        aria-selected="false"
        [translate]="'resources.customers.show.card_titles.privacy_policy'">
    </a>
  </li>
  <li class="nav-item" [hidden]="isHiddenByRole(['integration'])">
    <a (click)="selectTab('segments')"
        class="nav-link {{slug === 'segments' ? 'active' : ''}}"
        [routerLink]="['segments']"
        data-toggle="tab"
        href="#profiles-tab-11"
        role="tab"
        aria-selected="false"
        [translate]="'resources.profiles.show.tabs.segments'">
    </a>
  </li>
  <li class="nav-item" [hidden]="!flags.tagEvolutionTabVisibility">
    <a (click)="selectTab('tag_evolution')"
        class="nav-link {{slug === 'tag_evolution' ? 'active' : ''}}"
        [routerLink]="['tag-evolution']"
        data-toggle="tab"
        href="#profiles-tab-13"
        role="tab"
        aria-selected="false"
        [translate]="'resources.profiles.show.tabs.tag_evolution'">
    </a>
  </li>
  <li class="nav-item" [hidden]="!flags.activeChallengesTabVisibility">
    <a (click)="selectTab('active_challenges')"
        class="nav-link {{slug === 'active_challenges' ? 'active' : ''}}"
        [routerLink]="['active-challenges']"
        data-toggle="tab"
        href="#profiles-tab-14"
        role="tab"
        aria-selected="false"
        [translate]="'resources.profiles.show.tabs.challenges'">
    </a>
  </li>
</ul>

<div class="mrg-top-15 container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="position-relative">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
