export const RewardCodeBatchDetailMock = {
  "_links": {
    "total_count": {
      "href": 5
    },
    "self": {
      "href": "/rewards?page=1&per_page=10"
    },
    "next": {
      "href": "/rewards?page=2&per_page=10"
    },
    "last": {
      "href": "/rewards?page=151&per_page=10"
    }
  },
  "_embedded": {
    "list": [
      {
        "id": 123,
        "code": "QWERTY123",
        "available": true
      },
      {
        "id": 124,
        "code": "QWERTY124",
        "available": false,
        "redemption_id": 861
      },
      {
        "id": 125,
        "code": 31231431,
        "available": false,
        "redemption_id": 865
      },
      {
        "id": 126,
        "code": "QWERTY123",
        "available": "true"
      },
      {
        "id": 127,
        "code": "QWERTY123",
        "available": "false",
        "redemption_id": 856
      }
    ]
  }
};
