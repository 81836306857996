export class CampaignTemplate {
  id?:        number;
  subject?:   string;
  schema?:    string;
  message?:   string;
  values?:    any;
  constructor(template: {}) {
    this.id = template['id'];
    this.subject = template['subject'] || '';
    this.message = template['message'] || '';
    this.schema = template['schema'] || {};
    this.values = template['values'] || {};
  }
}

export class EmailTemplate extends CampaignTemplate {
  subject?: string;
  constructor(subject: string, data: { id: number, subject: string, schema: string, html: string }) {
    super(data);
    this.subject = subject;
    this.schema = JSON.stringify(data['schema']);
    this.message = data.html || '';
  }
}
