import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { FeatureInternalsService } from '../feature-internals.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FeatureInternal, FeatureItem } from '../../../../shared/models/products/feature-internal';
import { MultiselectDataSource } from '../../../../shared/components/multiselect/multiselect';

@Component({
  selector: 'app-form-products-internal-categories',
  templateUrl: './form-products-internal-categories.component.html',
  styleUrls: ['./form-products-internal-categories.component.css']
})

export class FormProductsInternalCategoriesComponent implements OnInit {

  id: number;
  inputs: QuestionBase<unknown>[];
  productsFeatureInternalForm: UntypedFormGroup;

  private destroy$ = new Subject<void>();

  private get isEditMode(): boolean {
    return !!this.id;
  }

  @Output() formValidity = new EventEmitter<string>();

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private productsFeatureInternalService: FeatureInternalsService,
    private confirmationService: ConfirmationService,
    private modalStatusService: ModalStatusService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  sendData(): void {
    const payload = this.getPayload();
    this.modalStatusService.formLoaderStatus.emit('loading');
    const request$ = this.isEditMode ? this.productsFeatureInternalService.updateData(payload, this.id) : this.productsFeatureInternalService.sendData(payload);
    request$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => this.handleSuccessRequest(),
      error: (errorData: HttpErrorResponse) => this.handleErrors(errorData)
    });
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getFeatureInternalsById();
      } else {
        this.setInputsAndForm({});
      }
    });
  }

  private getFeatureInternalsById() {
    this.productsFeatureInternalService.getFeatureInternalsById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (data: FeatureInternal) => this.setInputsAndForm(data),
      error: (errorData: HttpErrorResponse) => this.displayHttpErrorAlert(errorData)
    });
  }

  private setInputsAndForm(requestData: FeatureInternal): void {
    this.inputs = this.productsFeatureInternalService.getInputs(requestData, this.isEditMode);
    this.productsFeatureInternalForm = this.qcs.toFormGroup(this.inputs);
    this.handleFormStatusChanges();
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
  }

  private handleFormStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.productsFeatureInternalForm.status));
    this.productsFeatureInternalForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
  }

  private getPayload(): FeatureInternal {
    const formValue = this.productsFeatureInternalForm.getRawValue();
    const payload = {
      ...formValue,
      taxonomy_slug: formValue.taxonomy_slug?.length ? formValue.taxonomy_slug[0].id : null,
      features: formValue.features?.length ? this.parseFeaturesPayloadValue(formValue.features) : null
    }
    return payload;
  }

  private parseFeaturesPayloadValue(features: MultiselectDataSource[]): FeatureItem[] {
    return features.map(feature => {
      const featuresObj = {};
      const taxonomySlug = feature?.rawElement?.taxonomy_slug ?? null;
      if (taxonomySlug) {
        featuresObj[taxonomySlug] = feature?.rawElement?.external_id ?? null;
      }
      return featuresObj;
    });
  }

  private handleSuccessRequest(): void {
    const successTitle = this.translate.instant(this.isEditMode ? 'resources.product_internal_categories.form.warnings.update_title' : 'resources.product_internal_categories.form.warnings.success_title');
    const successText = this.translate.instant(this.isEditMode ? 'resources.product_internal_categories.form.warnings.update_text' : 'resources.product_internal_categories.form.warnings.success_text');
    this.confirmationService.displaySuccessAlert(successTitle, successText).catch(() => {});
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    this.modalStatusService.modalStatus.emit();
    this.returnToList();
  }

  private handleErrors(errorData: HttpErrorResponse): void {
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    const errorValue = errorData?.error?.error ? errorData.error.error : null;
    if (errorData?.error?.errors?.length) {
      this.qcs.paintErrorsInForm(this.inputs, this.productsFeatureInternalForm, errorData.error.errors);
      if (errorValue) { this.displayHttpErrorAlert(errorData); }
    } else if (errorValue) {
      this.displayHttpErrorAlert(errorData);
    }
  }

  private displayHttpErrorAlert(error: HttpErrorResponse): void {
    this.confirmationService.displayHttpErrorAlert(error);
  }

  private returnToList(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }
}
