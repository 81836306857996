import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { FeatureFlagsService } from '../../../shared/services/feature-flags.service';
import { Option } from '../../../shared/models/common/option';

@Injectable()
export class ReportsService extends ResourcesService {

  inputs: QuestionBase<unknown>[];
  flags = this.featureFlags.flags;

  constructor(
    http: HttpClient,
    private translate: TranslateService,
    private resourceService: ResourcesService,
    private featureFlags: FeatureFlagsService
  ) {
    super(http);
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `reports/${id}` });
  }

  getReportById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `reports/${id}/report` });
  }

  generateReport(data, id): Observable<object> {
    return this.postResource(data, `reports/${id}/generate`);
  }

  refreshCache(data, id: number): Observable<object> {
    return this.postResource(data, `reports/${id}/clear_cache`);
  }

  createReport(reportName): Observable<{id: number}> {
    return this.postResource(reportName, `reports`);
  }

  updateReportDetails(reportDetails, reportId): Observable<object> {
    return this.patchResource( reportDetails, 'reports/' + reportId );
  }

  deleteReport(id: number) {
    return this.deleteResource({}, `reports/${id}`);
  }

  deleteReportDetail(id: number, detailId: number) {
    return this.deleteResource({}, `reports/${id}/details/${detailId}`);
  }

  getDetailOptions(): Option[] {
    const opts = [
      { id: 'incremental', name: this.translate.instant('resources.reports.form.fields.incremental') },
      { id: 'segment_evo', name: this.translate.instant('resources.reports.form.fields.segment_evo') }
    ];
    if (this.flags.showRoiByStoresReportOption) {
      opts.push(
        { id: 'roi_by_store', name: this.translate.instant('resources.reports.form.fields.roi_by_store') }
      )
    }
    if (this.flags.showCouponsInAppAnalysisReportOption) {
      opts.push(
        { id: 'coupons_in_app_analysis', name: this.translate.instant('resources.reports.form.fields.coupons_in_app_analysis') }
      )
    }
    return opts;
  }

  setInputs(formValues: unknown) {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'detail',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false},
        options: this.getDetailOptions(),
      }),
    ];

    this.inputs = inputsArray;
    this.assignFormInputValues(formValues);
    return this.inputs;
  }

  private assignFormInputValues(formValues) {
    Object.keys(formValues).map(
      key => {
        const tmpInput = this.inputs.find( input => input.key === key);
        if (tmpInput ) {
          tmpInput.value = formValues[key];
        }
    });
  }
}
