import { ChallengeTypesService } from '../../../shared/services/challenge-types.service';
import { CheckboxQuestion } from '../../../shared/models/forms/question-checkbox';
import { CountriesService } from '../../../shared/services/countries.service';
import { CustomerAttributesService } from '../../data-warehouse/customer-attributes/services/customer-attributes/customer-attributes.service';
import { FeatureFlagsService } from '../../../shared/services/feature-flags.service';
import { HttpClient } from '@angular/common/http';
import { ImageQuestion } from '../../../shared/models/forms/question-image';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '../../../shared/utils/common.utils';
import { LanguagesService } from '../../../shared/services/languages.service';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { PartnersService } from '../partners/partners.service';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { RadioQuestion } from '../../../shared/models/forms/question-radio';
import { ResourcesService } from '../../../shared/services/resources.service';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';
import { SegmentsService } from '../../segments/segments.service';

@Injectable({
  providedIn: 'root'
})

export class TiersManagementService extends ResourcesService {

  flags = this.featureFlags.flags;
  inputs: QuestionBase<unknown>[];
  mode: string;
  type: string;

  constructor(
    http: HttpClient,
    private challengeTypesService: ChallengeTypesService,
    private countriesService: CountriesService,
    private customerAttributesService: CustomerAttributesService,
    private featureFlags: FeatureFlagsService,
    private languagesService: LanguagesService,
    private partnersService: PartnersService,
    private translate: TranslateService,
    private segmentsService: SegmentsService
  ) {
    super(http);
  }

  setVariables(mode: any, type: string) {
    this.mode = mode;
    this.type = type;
  }

  saveData(data) {
    return this.postResource(data, 'tier_definitions');
  }

  updateData(data, id:number) {
    return this.patchResource(data, `tier_definitions/${id}`);
  }

  getTierDefinitionById(id:number) {
    return this.getData({
      apiEndPoint: `tier_definitions/${id}`
    });
  }

  delete(id: number) {
    return this.deleteResource({}, `tier_definitions/${id}`);
  }

  getInputs(tierDefinition, type?: string, isTier = false): QuestionBase<any>[] {
    this.inputs = [
      new TextboxQuestion({
        key: 'name',
        cssClasses: 'form-control input-default',
        type: 'text',
        required: true,
        value: tierDefinition?.name,
        disabled: tierDefinition?.has_run
      }),
      new TextboxQuestion({
        key: 'description',
        cssClasses: 'form-control input-default',
        type: 'text',
        value: tierDefinition?.description,
        disabled: tierDefinition?.has_run
      }),
      new MultiSelectQuestion({
        key: 'customer_attribute',
        cssClasses: 'form-control input-default',
        settings: {singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true},
        required: true,
        dataSource: this.customerAttributesService,
        selectedIds: tierDefinition?.customer_attribute ? [tierDefinition.customer_attribute] : [],
        disabled: tierDefinition?.has_run
      }),
      new MultiSelectQuestion({
        key: 'expiration',
        cssClasses: 'form-control input-default',
        settings: {singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true},
        options: this.getExpirationOptions(),
        value: tierDefinition?.expiration ? [this.getExpirationOptions().find( item => item.id === tierDefinition.expiration)] : [],
        required: true,
        disabled: tierDefinition?.has_run
      }),
      new TextboxQuestion({
        key: 'expiration_relative_value',
        cssClasses: 'form-control input-default',
        type: 'number',
        min: 0,
        step:1,
        required: tierDefinition?.expiration === 'relative_months' || tierDefinition?.expiration === 'natural_months',
        value: tierDefinition?.expiration_relative_value,
        disabled: tierDefinition?.has_run
      }),
      new MultiSelectQuestion({
        key: 'countries',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: false, enableSearchFilter: true },
        options: this.countriesService.getCountries(),
        value: tierDefinition?.countries ? this.countriesService.getCountries().filter(item => tierDefinition.countries.includes(item.id)) : [],
        disabled: (type === 'tier' && tierDefinition?.has_run)
      }),
      new MultiSelectQuestion({
        key: 'available_languages',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: true, enableSearchFilter: false },
        options: this.languagesService.getLocalizedLanguages(),
      }),
      new TextboxQuestion({
        key: 'level_name',
        type: 'text',
        cssClasses: 'form-control input-default',
        required: isTier ? false : true,
        disabled: tierDefinition?.has_run,
        value: this.retrieveLevelValues(tierDefinition, 'level_name')
      }),
      new TextboxQuestion({
        key: 'threshold_challenge',
        type: 'number',
        cssClasses: 'form-control input-default',
        required: isTier ? false : true,
        disabled: tierDefinition?.has_run,
        value: this.retrieveLevelValues(tierDefinition, 'threshold'),
      }),
      new MultiSelectQuestion({
        key: 'challenge_type',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        dataSource: this.challengeTypesService,
        selectedIds: tierDefinition?.challenge_definition?.challenge_type ? [tierDefinition.challenge_definition.challenge_type] : [],
      }),
      new TextboxQuestion({
        key: 'available_from',
        type: 'date',
        cssClasses: 'form-control input-default',
        value: tierDefinition?.challenge_definition?.available_from,
      }),
      new TextboxQuestion({
        key: 'available_to',
        type: 'date',
        cssClasses: 'form-control input-default',
        value: tierDefinition?.challenge_definition?.available_to,
      }),
      new TextboxQuestion({
        key: 'display_from',
        type: 'date',
        cssClasses: 'form-control input-default',
        value: tierDefinition?.challenge_definition?.display_from,
      }),
      new TextboxQuestion({
        key: 'display_to',
        type: 'date',
        cssClasses: 'form-control input-default',
        value: tierDefinition?.challenge_definition?.display_to,
      }),
      new ImageQuestion({
        key: 'image',
        type: 'file',
        cssClasses: 'input-default'
      }),
      new MultiSelectQuestion({
        key: 'partner_id',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        dataSource: this.partnersService,
        selectedIds: tierDefinition?.challenge_definition?.partner_id ? [tierDefinition.challenge_definition.partner_id] : null,
      }),
      new CheckboxQuestion({
        label: this.translate.instant('resources.scores.challenges.fields.open_to_all'),
        key: 'open_to_all',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        value: this.getOpenToAllValue(tierDefinition),
        disabled: tierDefinition?.has_run
      }),
      new MultiSelectQuestion({
        key: 'enrollments',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: [
          { id: 'unlimited', name: this.translate.instant('resources.scores.challenges.enrollments.unlimited')},
          { id: 'max_enrollments', name: this.translate.instant('resources.scores.challenges.enrollments.max_enrollments')}
        ],
        disabled: tierDefinition?.has_run,
        value: this.mode !== 'create' ? this.getEnrollmentType(tierDefinition) : null
      }),
      new TextboxQuestion({
        key: 'max_enrollments',
        type: 'number',
        cssClasses: 'form-control input-default',
        value: tierDefinition?.challenge_definition?.max_enrollments,
      }),
      new RadioQuestion({
        key: 'active',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: [
          {id: true, name: this.translate.instant('common.enabled')},
          {id: false, name: this.translate.instant('common.disabled')},
        ],
        value: tierDefinition?.active ? tierDefinition?.active : false
      }),
      // ########### BON PREU DEMO - FAKE SELECTOR ###########
      new MultiSelectQuestion({
        key: 'segment_id',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.segmentsService,
        selectedIds: [],
        // ####################################################
      })
    ];

    Object.keys(tierDefinition).forEach(
      key => {
        if (key === 'available_languages') {
          const langValue = this.languagesService.getLocalizedLanguages().filter( item => tierDefinition[key].includes(item.id));
          this.inputs.find( input => input.key === 'available_languages').value = langValue;
        }
      }
    );
    return this.inputs;
  }

  getExpirationOptions(filer?:boolean) {

    let arrFullExpiration = [
      { id: 'relative_days', name: this.translate.instant('resources.scores.tiers-management.inputs.expiration.relative_days') },
      { id: 'relative_months', name: this.translate.instant('resources.scores.tiers-management.inputs.expiration.relative_months') },
      { id: 'natural_months', name: this.translate.instant('resources.scores.tiers-management.inputs.expiration.natural_months') },
      { id: 'after_end_current_period', name: this.translate.instant('resources.scores.tiers-management.inputs.expiration.after_end_current_period') },
      { id: 'after_end_next_period', name: this.translate.instant('resources.scores.tiers-management.inputs.expiration.after_end_next_period') }
    ];

    let arrPartialExpiration = [
      { id: 'relative_months', name: this.translate.instant('resources.scores.tiers-management.inputs.expiration.relative_months') },
      { id: 'relative_days', name: this.translate.instant('resources.scores.tiers-management.inputs.expiration.relative_days') },
    ];

    return filer ? arrPartialExpiration : arrFullExpiration;
  }

  private getOpenToAllValue(tierDefinition: object): unknown {
    if(this.mode !== 'create') {
      return tierDefinition?.['challenge_definition']?.['open_to_all'] ? false : true;
    }
  }

  private getEnrollmentType(tierDefinition: object): unknown {
    const hasMaxEnrollments = tierDefinition?.['challenge_definition']?.max_enrollments;
    const id = hasMaxEnrollments ? 'max_enrollments' : 'unlimited';
    const name = this.translate.instant(`resources.scores.challenges.enrollments.${id}`);
    return [{ id, name }];
  }

  private retrieveLevelValues(value: object, inputKey: string): unknown {
    if (this.type === 'challenge' && !isNullOrUndefined(value) && this.mode !== 'create') {
      if (inputKey === 'level_name' && value['achievement_definitions'][0]?.name) {
        return value['achievement_definitions'][0].name;
      } else if (inputKey === 'threshold' && value['achievement_definitions'][0]?.threshold) {
        return value['achievement_definitions'][0].threshold;
      }
    }
    return null;
  }

}
