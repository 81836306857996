import { UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";
import { POSITIVE_INTEGER_REGEX } from "../../../constants/condition.constants";
import { checkValue1GreaterThanValue2 } from "../../../../validations.service";
import { isCtrlPositiveInteger, setValidationPositiveIntegerErrorsMark } from "../../../validations/common.validations";

export function getFormGroupValidations(control: UntypedFormGroup): ValidationErrors | null {
  const operator = control.get('operator');
  const value1Ctrl = control.get('value_1');
  const value2Ctrl = control.get('value_2');

  value2Ctrl.setValidators([]);
  if (operator?.value?.length && operator.value[0].id === 'bt') {
    value1Ctrl.setValidators([Validators.required, Validators.pattern(POSITIVE_INTEGER_REGEX)]);
    value2Ctrl.setValidators([Validators.required, Validators.pattern(POSITIVE_INTEGER_REGEX)]);
    if (!isCtrlPositiveInteger(value1Ctrl)) {
      return setValidationPositiveIntegerErrorsMark(value1Ctrl);
    } else if (!isCtrlPositiveInteger(value2Ctrl)) {
      return setValidationPositiveIntegerErrorsMark(value2Ctrl);
    } else {
      return checkValue1GreaterThanValue2(value1Ctrl, value2Ctrl);
    }
  }
  return null;
}