import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from './condition.service';
import { QuestionBase } from '../../models/forms/question-base';
import { TranslateService } from '@ngx-translate/core';
import { TextareaQuestion } from '../../models/forms/question-textarea';

@Injectable()
export class PostalCodeConditionService implements SegmentConditionProvider {

  public inputs: QuestionBase<any>[];

  constructor( private translate: TranslateService ) { }

  public getInputs( params: any ): QuestionBase<any>[] {
    const inputs = [
      new TextareaQuestion({
        cssClasses: 'form-control input-md area-40',
        key: 'postal_codes',
        label: this.translate.instant('resources.segment_conditions.fields.postal_codes'),
        type: 'text',
        required: true,
        helpText: this.translate.instant('resources.segment_conditions.messages.postal_code_helper'),
        getValue: (value) => value,
        parseValue: (value) => value
      })
    ];

    if ( params ) {
      Object.keys(params).forEach( key => {
        const input = inputs.find( _input => _input.key === key );
        const value = input.getValue(params[key]);
        input.value = value;
      });
    }

    this.inputs = inputs;
    return this.inputs;
  }

  public prepareFormValuesToAPI( params: any ): any {
    const parsedValuesObj = {};
    Object.keys(params).forEach( key => {
      const input = this.inputs.find( _input => _input.key === key );
      const parsedValue = input.parseValue( params[key] );
      parsedValuesObj[key] = parsedValue;
    });
    return parsedValuesObj;
  }

  public prepareFormValuesFromAPI( params: any ): any {
    const parseValues = {
      postal_codes: (value) => (value && value.length) ? value : null
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach( key => { parsedValuesObj[key] = parseValues[key](params[key]); });
    return parsedValuesObj;
  }
}
