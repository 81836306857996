import { QuestionBase } from './question-base';

export class RadioQuestion extends QuestionBase<string> {

  controlType = 'radiobox';
  id: string;
  options: any[];
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.id = options['id'] || null;
    this.type = options['type'] || '';
    this.options = options['options'] || [];
  }
}
