import { Component, OnInit } from '@angular/core';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { TextareaQuestion } from '../../../../shared/models/forms/question-textarea';
import { ReservedIdsService } from '../reserved-ids.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-form-reserved-ids',
  templateUrl: './form-reserved-ids.component.html',
  styleUrls: ['./form-reserved-ids.component.css'],
  providers: [ReservedIdsService]
})
export class FormReservedIdsComponent implements OnInit {

  public reservedIdsInputs: QuestionBase<any>[];
  public reservedIdsForm: UntypedFormGroup;
  public inputs: any;
  public id: number;
  public showButton: boolean;
  public loading: boolean;

  constructor(
    private qcs: QuestionControlService,
    private router: Router,
    private reservedIdsService: ReservedIdsService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private modalStatusService: ModalStatusService
  ) {}

  ngOnInit() {
    this.inputs = this.setInputs();
    this.reservedIdsForm = this.qcs.toFormGroup(this.inputs);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  public sendData() {

    const reservedIds = {
      codes: this.reservedIdsForm.value.codes,
    };

    this.modalStatusService.formLoaderStatus.emit('loading');
    this.reservedIdsService.loadCSV(reservedIds).subscribe(
      () => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.reserved_ids.warnings.success_title'),
          this.translate.instant('resources.reserved_ids.warnings.success_text')
          ).catch(() => {});
          this.modalStatusService.modalStatus.emit();
          this.closeModal();
        },
        (errorResponse: HttpErrorResponse) => {
        this.modalStatusService.formLoaderStatus.emit('loading_finished');
        this.confirmationService.displayErrorAlert('Error', errorResponse.error.error);
      }
    );
  }

  public closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private setInputs() {
    const inputsArray = [
      new TextareaQuestion({
        cssClasses: 'form-control input-md',
        key: 'codes',
        placeholder: this.translate.instant('resources.reserved_ids.fields.placeholder_text')
      })
    ];
    this.inputs = inputsArray;
    return this.inputs;
  }

}
