<div class="row mrg-top-15">
  <div class="col-md-12">
    <h5 class="text-bold">{{ 'resources.segment_conditions.titles.attributes' | translate }}</h5>
  </div>
</div>

<ng-container *ngIf="form?.get(customEntityKey.DefinitionId)?.value?.length && !attributesConfigArray?.controls?.length">
  <div class="pdd-horizon-15">
    <div class="row pdd-horizon-30 mrg-top-5">
      <div class="alert alert-danger d-flex align-items-center">
      <em class="fa-light fa-exclamation-circle mrg-right-10 font-size-16"></em>
      <strong><span class="font-size-13"> {{ 'resources.segment_conditions.messages.required_attribute_configuration_alert' | translate }} </span></strong>
      </div>
    </div>
  </div>
</ng-container>

<app-attributes-list-custom-entity-condition [attributesConfigArray]="attributesConfigArray"></app-attributes-list-custom-entity-condition>

<ng-container *ngIf="form?.get(customEntityKey.DefinitionId)?.value?.length">
  <div class="pdd-horizon-15">
    <div class="row pdd-horizon-15 mrg-top-5">
      <div [ngClass]="wideLayout" class="no-invalid">
        <app-question [question]="getInputConfig(customEntityKey.Attribute)"
                      [form]="newAttributeForm"
                      (multiselectChanged)="handleSelectedAttribute($event)">
        </app-question>
      </div>
    </div>
    <ng-container *ngIf="newAttributeForm?.get(customEntityKey.Attribute)?.value?.length">
      <div class="row pdd-horizon-15 mrg-top-15">
        <ng-container *ngIf="isSelectedAttributeType(customEntityAttributeType.String)">
          <div [class]="wideLayout">
            <app-question [question]="getInputConfig(customEntityKey.StringOperator)" [form]="newAttributeForm"></app-question>
          </div>

          <div [class]="wideLayout">
            <app-question [question]="getInputConfig(customEntityKey.StringValue)" [form]="newAttributeForm"></app-question>
          </div>
        </ng-container>

        <ng-container *ngIf="isSelectedAttributeType(customEntityAttributeType.Integer)">
          <div [ngClass]="classByOperator(customEntityKey.IntegerOperator)">
            <app-question [question]="getInputConfig(customEntityKey.IntegerOperator)"
                          [form]="newAttributeForm"
                          (multiselectChanged)="handleOperatorChanges($event, customEntityKey.IntegerValue2)">
            </app-question>
          </div>

          <div [ngClass]="classByOperator(customEntityKey.IntegerOperator)">
            <app-question [question]="getInputConfig(customEntityKey.IntegerValue)" [form]="newAttributeForm"></app-question>
          </div>

          <div *ngIf="isBtOperator(customEntityKey.IntegerOperator)" [class]="narrowLayout">
            <app-question [question]="getInputConfig(customEntityKey.IntegerValue2)" [form]="newAttributeForm"></app-question>
          </div>
        </ng-container>

        <ng-container *ngIf="isFloatAttribute()">
            <div [ngClass]="classByOperator(customEntityKey.FloatOperator)">
              <app-question [question]="getInputConfig(customEntityKey.FloatOperator)"
                            [form]="newAttributeForm"
                            (multiselectChanged)="handleOperatorChanges($event, customEntityKey.FloatValue2)">
              </app-question>
            </div>

            <div [ngClass]="classByOperator(customEntityKey.FloatOperator)">
              <app-question [question]="getInputConfig(customEntityKey.FloatValue)" [form]="newAttributeForm"></app-question>
            </div>

            <div *ngIf="isBtOperator(customEntityKey.FloatOperator)" [class]="narrowLayout">
              <app-question [question]="getInputConfig(customEntityKey.FloatValue2)" [form]="newAttributeForm"></app-question>
            </div>
        </ng-container>

        <ng-container *ngIf="isSelectedAttributeType(customEntityAttributeType.Boolean)">
            <div [class]="wideLayout">
              <app-question [question]="getInputConfig(customEntityKey.BooleanOperator)" [form]="newAttributeForm"></app-question>
            </div>

            <div [class]="wideLayout">
              <app-question [question]="getInputConfig(customEntityKey.BooleanValue)" [form]="newAttributeForm"></app-question>
            </div>
        </ng-container>

        <ng-container *ngIf="isDateOrDatetimeAttribute()">
          <div [ngClass]="classByOperator(customEntityKey.DateOperator)">
            <app-question [question]="getInputConfig(customEntityKey.DateOperator)"
                          [form]="newAttributeForm"
                          (multiselectChanged)="handleOperatorChanges($event, customEntityKey.DateValue2)">
            </app-question>
          </div>

          <div [ngClass]="classByOperator(customEntityKey.DateOperator)">
            <ng-container *ngTemplateOutlet="dynamicDateInputTemplate; context: { key: customEntityKey.DateValue }"></ng-container>
          </div>

          <div *ngIf="isBtOperator(customEntityKey.DateOperator)" [class]="narrowLayout">
            <ng-container *ngTemplateOutlet="dynamicDateInputTemplate; context: { key: customEntityKey.DateValue2 }"></ng-container>
          </div>

          <ng-template #dynamicDateInputTemplate let-key="key">
            <div [formGroup]="newAttributeForm">
              <small *ngIf="getInputConfig(key).label">
                <label [attr.data-cy]="'label_' + (key | camelCase)" [attr.for]="key">
                    <span class="text-semibold" [innerHTML]="getInputConfig(key).label | translate"></span>
                    <span *ngIf="getInputConfig(key).required" class="text-danger">*</span>
                </label>
              </small>
              <div class="form-group field">
                <app-dynamic-date-input
                            [form]="newAttributeForm"
                            [formControlName]="key"
                            [id]="key"
                            [value]="newAttributeForm.get(key)?.value ? newAttributeForm.get(key)?.value : null"
                            [class]="getInputConfig(key).cssClasses"
                            [required]="getInputConfig(key).required"
                ></app-dynamic-date-input>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
    <div class="row">
      <div class="col pdd-horizon-30 mrg-top-15 mrg-btm-30">
          <button
            type="button"
            class="btn btn-success no-mrg"
            data-cy="button_addNewAttribute"
            [disabled]="newAttributeForm.invalid"
            (click)="addNewAttribute()">
            {{ 'resources.segment_conditions.buttons.add_attribute' | translate }}
          </button>
      </div>
    </div>
  </div>
</ng-container>
