import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MultiselectDataSource, MultiselectDataSourceable } from '../../shared/components/multiselect/multiselect';
import { ResourcesService } from '../../shared/services/resources.service';

@Injectable({
  providedIn: 'root'
})
export class CommentsService extends ResourcesService implements MultiselectDataSourceable{


  fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'comments',
      numberPerPage: 300,
      pageNumber: 1
    };

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    const name = element?.name;
    return new MultiselectDataSource(element.id, name);
  }

  getComments() {
    return this.getData({ apiEndPoint: `comments` });
  }

}



