import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RewardsService } from "../../rewards.service";
import { Reward } from "../../../../../shared/models/reward/reward";
import { UntypedFormGroup } from "@angular/forms";
import { QuestionBase } from "../../../../../shared/models/forms/question-base";
import { QuestionControlService } from "../../../../../shared/services/question-control.service";
import { ConfirmationService } from "../../../../../shared/services/confirmation.service";
import { getTranslationInputs } from "../../../../data-warehouse/data-warehouse-coupons/form-coupons/coupon-utils/coupon-utils";

@Component({
  selector: 'app-reward-localized-content',
  templateUrl: './reward-localized-content.component.html',
  styleUrls: ['./reward-localized-content.component.scss']
})

export class RewardLocalizedContentComponent implements OnInit {

  rewardData: Reward;
  rewardId = this.route.snapshot.parent.params.id;
  showRewardsInputs: QuestionBase<any>[];
  showRewardsForm: UntypedFormGroup;
  mode: string;

  constructor(
    private route: ActivatedRoute,
    private rewardsService: RewardsService,
    private qcs: QuestionControlService,
    private confirmationService: ConfirmationService,
    private router: Router,
  ){}

  ngOnInit() {
    this.getParams();
  }

  private getParams() {
    this.route.parent.params.subscribe(params => {
      if (params.id) {
        if (this.router.url.indexOf(`(modal:`) >= 0) {
          this.mode = 'show';
        }
        this.getRewardById();
      }
    });
  }

  private getRewardById() {
    this.rewardsService.getRewardsById(this.rewardId).subscribe(
      (resp: Reward) => {
        this.rewardData = resp;
        this.showRewardsInputs = this.rewardsService.getInputs({});
        this.showRewardsForm = this.qcs.toFormGroup(this.showRewardsInputs);
        getTranslationInputs(this.rewardData, this.qcs, this.showRewardsForm, [], this.mode);
      },
      error => {
        this.confirmationService.displayErrorAlert('Error', error.error.error)
      }
    );
  }
}