import { CustomerJourney, CustomerJourneysCfg } from './customer-journey';
import { CustomerJourneyStep } from './customer-journey-step';

export class MultiCouponJourney extends CustomerJourney {

  public campaignsBlockHidden = false;
  public summaryBlockHidden = false;

  constructor(apiObject: CustomerJourneysCfg) {
    super(apiObject);
    this.name = apiObject.name || 'MULTI_CUPÓN';
    this.id = apiObject.id;
    this.configuration.extra_segment = this.getSegment(apiObject);
    this.configuration.via = this.getVia(apiObject);
    this.configuration.deliveries = this.getDeliveries(apiObject);
    this.configuration.coupon_id = this.getCoupon(apiObject);
  }

  public getSegment(cfg) {
    if ( cfg.configuration.hasOwnProperty('extra_segment') && cfg.configuration.extra_segment) {
      return [cfg.configuration.extra_segment];
    } else {
      return [];
    }
  }

  public getDeliveries(cfg) {
    if ( cfg.configuration.hasOwnProperty('deliveries') &&
         cfg.configuration['deliveries']) {
      const deliveries = parseInt(cfg.configuration['deliveries'], 10);
      return [{
        id: deliveries,
        name: deliveries.toString()
      }];
    } else {
      return [];
    }
  }

  public getVia(cfg) {
    if ( cfg.configuration.hasOwnProperty('via') && cfg.configuration.via ) {
      return [{
        id: cfg.configuration['via'].id, name: cfg.configuration['via'].name
      }];
    } else {
      return [];
    }
  }

  public getCoupon(cfg) {
    if ( cfg.configuration.hasOwnProperty('coupon_id') &&
         cfg.configuration.coupon_id &&
         cfg.configuration.coupon_id.length ) {
      return cfg.configuration.coupon_id;
    } else {
      return [];
    }
  }

  public definitionValid(): boolean {
    return true;
  }

  public campaignsValid(): boolean {
    return true;
  }

  public contentValid(): boolean {
    return true;
  }

  public summaryValid(): boolean {
    return true;
  }

  public getName(): string {
    return this.name ? this.name : '';
  }

  public getNameSuffix(step: CustomerJourneyStep): string {
    return `Envío ${step.position}`;
  }

  public getNameWithAttributes(): string {
    const coupon = (this.cfg.configuration.coupon_id && this.cfg.configuration.coupon_id.length) ? ` Coupon: (${this.cfg.configuration.coupon_id[0]})` : '';
    const segment = this.cfg.configuration.extra_segment ? ` Segmento: (${this.cfg.configuration.extra_segment})` : '';
    let viaName: string;
    if ( this.cfg.configuration.via.id && this.cfg.configuration.via.id instanceof Object ) {
      viaName = this.cfg.configuration.via.id.name;
    } else {
      viaName = this.cfg.configuration.via.name;
    }
    const via = this.cfg.configuration.via ? ` Canal: ${viaName}` : '';
    return `${coupon}${segment}${via}`;
  }
}
