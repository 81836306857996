import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ProfileService } from '../../../profiles/profile.service';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html'
})

export class ProfilesComponent implements OnInit, OnDestroy {

  slug = 'profiles';
  roleSlug: string = this.profileService.getStoredUserRole();

  get isAllowedRoleSlug(): boolean {
    return !['store_manager', 'customer_service', 'customer_service_mango', 'customer_service_mango_limited', 'franchise'].includes(this.roleSlug);
  }

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private profileService: ProfileService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setRouterURL();
    this.selectTab(this.slug);
    this.routerEventsSubscription();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectTab(slug: string): void {
    this.slug = slug;
  }

  private setRouterURL(): void {
    const split = this.router.url.split('/');
    this.slug = split[split.length - 1];
  }
  
  private routerEventsSubscription(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.setRouterURL();
        this.selectTab(this.slug);
      }
    });
  }
}
