import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UserSecurity } from '../../models/company/company-configurations';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PrimeNgModule } from '../../primeNG/primeng/primeng.module';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, PrimeNgModule],
  selector: 'app-user-password-validation',
  templateUrl: './user-password-validation.component.html',
  styleUrls: ['./user-password-validation.component.scss']
})
export class UserPasswordValidationComponent {

  get hasUserSecurity(): boolean {
    return !!(this.userSecurity?.passwordMinLength || this.userSecurity?.passwordMinUppercase || this.userSecurity?.passwordMinDigits || this.userSecurity?.passwordMinSpecialCharacters);
  }

  @Input() passwordCtrl: AbstractControl;
  @Input() userSecurity: UserSecurity;

  validationConditionClass(invalidConditionKey: string): string {
    const isInvalidCondition = this.passwordCtrl?.errors?.invalidPassword?.[invalidConditionKey];
    return isInvalidCondition ? 'fa-circle-exclamation text-danger' : 'fa-circle-check text-success';
  }
}
