import { Validators } from "@angular/forms";
import { POSITIVE_INTEGER_REGEX } from "../../../../shared/constants/validators.constants";

export const CUSTOMER_ATTRIBUTES_LIST_ROUTERLINK = [`/customer-data-platform/customer-attributes`];

export const SQL_PLACEHOLDER_TITLE = 'resources.customer_attributes.fields.sql_placeholder_title';
export const SQL_PLACEHOLDER = `\nSELECT customer_id AS customer_id,\n`+
`ROUND(SUM(total),2) AS value\n`+
`FROM %<dataset>s.activities\n`+
`WHERE DATE(activities.created_at) BETWEEN DATE_TRUNC(current_date, YEAR) AND LAST_DAY(current_date, YEAR)\n`+
`GROUP BY customer_id;`;

export const CALCULATION_REQUIRED_FORM_FIELDS = ['sql', 'data_period_type', 'execution_type'];
export const SCHEDULED_REQUIRED_FORM_FIELDS = ['start_date', 'start_time', 'recurrence'];
export const CUSTOM_DATA_PERIOD_TYPE_REQUIRED_FORM_FIELDS = ['relative_value', 'relative_custom_period'];
export const SPECIFIC_MONTHS_RECURRENCE_REQUIRED_FORM_FIELDS =  ['execution_months'];
export const HOUR_INTERVAL_RECURRENCE_REQUIRED_FORM_FIELDS =  ['execution_hour_interval'];

export const DEFAULT_FIELD_CALCULATION_CUSTOM_VALIDATORS = [
  { key: 'relative_value', isScheduledExecutionType: false, customValidators: [Validators.min(1), Validators.pattern(POSITIVE_INTEGER_REGEX)] },
  { key: 'execution_hour_interval', isScheduledExecutionType: true, customValidators: [Validators.min(1), Validators.pattern(POSITIVE_INTEGER_REGEX)] }
];

export const INVALID_START_DATE_DAYS = [29, 30, 31];