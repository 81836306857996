import { Injectable } from '@angular/core';
import { HttpClient, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { of } from "rxjs";
import { map } from "rxjs/operators";


@Injectable()
export class ResourcesService {

  constructor(private http: HttpClient) {}

  private dataOptions = {
    apiEndPoint: undefined,
    status: undefined,
    type: undefined,
    useToken: false,
    nextToken: undefined,
    pageNumber: 0, // page
    numberPerPage: 10, // per_page
    sorting: {
      byProp: undefined, // order_by
      direction: undefined // order_sense
    },
    filtering: {}
  };

  public getData(dataOptions?: any) {

    this.dataOptions = dataOptions;
    let obj: HttpParams = new HttpParams({encoder: new CustomEncoder()});

    // Status
    if (this.dataOptions.status) {
      obj = obj.append('status', this.dataOptions.status);
    }
    // Type
    if (this.dataOptions.type) {
      obj = obj.append('type', this.dataOptions.type);
    }

    // Sorting
    if (this.dataOptions.sorting && this.dataOptions.sorting.byProp && this.dataOptions.sorting.direction) {
      obj = obj.append('order_by', this.dataOptions.sorting.byProp);
      obj = obj.append('order_sense', this.dataOptions.sorting.direction);
    }

    // Page
    if (this.dataOptions.pageNumber && !this.dataOptions.useToken) {
      obj = obj.append('page', this.dataOptions.pageNumber.toString());
    }

    // Page
    if (this.dataOptions.nextToken && this.dataOptions.useToken) {
      obj = obj.append('starting_after', this.dataOptions.nextToken.toString());
    }

    // Data per page
    if (this.dataOptions.numberPerPage) {
      obj = obj.append('per_page', this.dataOptions.numberPerPage.toString());
    }

    // Iterate filters to add to request.  Remove filters with null values.
    if (this.dataOptions.filtering && Object.keys(this.dataOptions.filtering).length) {
      Object.keys(this.dataOptions.filtering).forEach(function (key) {
        if (dataOptions.filtering[key] != null) {
          obj = obj.append(key, dataOptions.filtering[key]);
        }
      });
    }

    return this.http.get(this.dataOptions.apiEndPoint, { params: obj }).pipe(map(
      response => {
        return response;
      },
      error => {
        console.error('resources services error http get', JSON.stringify(error));
      }
    ));
  }


  public postResource(resource: any, resourceUrl: string): any {
    return this.http.post(resourceUrl, JSON.stringify(resource)).pipe(map(response => response, error => error ));
  }

  public patchResource(resource: any, resourceUrl: string): any {
    return this.http.patch(resourceUrl, JSON.stringify(resource)).pipe(map(response => response, error => error ));
  }

  public putResource(resource: any, resourceUrl: string): any {
    return this.http.put(resourceUrl, JSON.stringify(resource)).pipe(map(response => response, error => error ));
  }

  public deleteResource(resource: any, resourceUrl: string): any {
    return this.http.delete(resourceUrl, resource).pipe(map(response => response, error => error ));
  }

  public deleteResourceWithParams(url: string, params): any {
    const queryParams = new HttpParams({fromObject: params});
    return this.http.delete(url, {params: queryParams}).pipe(map(response => response, error => error ));
  }
}


class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
