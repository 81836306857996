<div class="row" *ngIf="isUpdateHistory">
  <div class="col-md-8 ml-auto mr-auto">
    <div class="alert alert-danger">
      {{'resources.coupons.form.warnings.warning_a' | translate}}
      <br>
      {{'resources.coupons.form.warnings.warning_b' | translate}}
      <br>
      {{'resources.coupons.form.warnings.warning_c' | translate}}
    </div>
  </div>
</div>