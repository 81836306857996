<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
       <div *ngIf="recipesForm && recipesForm.errors">
        <div class="alert alert-danger alert-dismissible fade show mrg-top-5" *ngFor="let error of getErrors(recipesForm)"
          role="alert">
          <b>{{error.field}}:</b> {{error.message}}.
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="removeError(error)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="card-block no-pdd">
        <div class="mrg-top-10">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form [formGroup]="recipesForm" *ngIf="recipesForm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="trigger" [translate]="'resources.marketing_automation.form.trigger'"></label>
                      <app-question [question]="getInputConfig('trigger')" (multiselectChanged)="handleTriggerChange($event)" [form]="recipesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="action" [translate]="'resources.marketing_automation.form.action'"></label>
                      <app-question [question]="getInputConfig('action')" [form]="recipesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="hasFormKeyWithValue('action', 'push')">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="subject" [translate]="'resources.marketing_automation.form.subject'"></label>
                      <app-question [question]="getInputConfig('subject')" [form]="recipesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="hasFormKeyWithValue('action', 'push')">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="message" [translate]="'resources.marketing_automation.form.message'"></label>
                      <app-question [question]="getInputConfig('message')" [form]="recipesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="hasFormKeyWithValue('action', 'coupon')">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="coupon_id" [translate]="'resources.marketing_automation.form.coupon_id'"></label>
                      <app-question [question]="getInputConfig('coupon_id')" [form]="recipesForm"></app-question>
                    </div>
                  </div>
                </div>
              </form>

              <div *ngIf="element">
                <ul class="list bullet bullet-primary">
                  <li *ngIf="element[0].rawElement.available_from"><span class="text-dark" [translate]="'resources.marketing_automation.form.available_from'"></span>:&nbsp;<span class="text-semibold">{{element[0].rawElement.available_from | date: 'dd/MM/yyyy'}}</span></li>
                  <li *ngIf="element[0].rawElement.available_to"><span class="text-dark" [translate]="'resources.marketing_automation.form.available_to'"></span>:&nbsp;<span class="text-semibold">{{element[0].rawElement.available_to | date: 'dd/MM/yyyy'}}</span></li>
                  <li *ngIf="element[0].rawElement.available_days"><span class="text-dark" [translate]="'resources.marketing_automation.form.available_days'"></span>:&nbsp;<span class="text-semibold">{{element[0].rawElement.available_days}}</span></li>
                  <li *ngIf="element[0].rawElement.printable"><span class="text-dark" [translate]="'resources.marketing_automation.form.printable'"></span>:&nbsp;<i class="ei-checked text-success"></i></li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
