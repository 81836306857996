import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-plan2',
  templateUrl: './card-plan2.component.html',
  styleUrls: ['./card-plan2.component.scss']
})
export class CardPlan2Component {
  @Input() title: string;
  @Input() text: string;
  @Input() icon: string;
  @Input() iconColor: string;
  @Input() checked = false;
}
