import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../shared/components/multiselect/multiselect';
import { DateService } from '../../shared/services/date.service';
import { BehaviorSubject } from 'rxjs';
import { of } from 'rxjs';
import { DateTimeZoneService } from '../../shared/services/date-time-zone.service';

@Injectable()
export class CouponHistoriesService extends ResourcesService implements MultiselectDataSourceable {

  public couponId: number;
  public optsSource = new BehaviorSubject<number>(null);
  public optsSource$ = this.optsSource.asObservable();

  constructor(http: HttpClient,
              private dateService: DateTimeZoneService) {
    super(http);
  }

  public setCoupon(id: number) {
    this.couponId = id;
    this.optsSource.next( this.couponId );
  }

  public fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {

    if ( !this.couponId ) { return of({}); }

    let requestOptions = {
      apiEndPoint: `coupons/${this.couponId}/histories`,
      numberPerPage: 10,
      filtering: {},
      pageNumber: 1,
      sorting: {
        byProp: 'created_at',
        direction: 'desc'
      }
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  public fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `coupons/${this.couponId}/histories/${id}` });
  }

  public getCouponHistoryById(id: number, historyId: number): Observable<any> {
    return this.getData({apiEndPoint: `coupons/${id}/histories/${historyId}`});
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    const name = element._embedded.campaign.name;
    const date = this.dateService.parseDateWithFormat(element.sent_at, 'DD/MM/YYYY');
    return new MultiselectDataSource(element.id, `${name} (${date})`, element);
  }
}
