import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { LocationsService } from '../../data-warehouse/locations/locations.service';
import { SegmentsService } from '../../segments/segments.service';
import { CustomersService } from './customers.service';
import { Subscription } from 'rxjs';
import { FeaturesService } from '../../data-warehouse/products/features.service';
import { FeatureTaxonomiesService } from '../../data-warehouse/products/feature-taxonomies.service';
import { FeatureFlagsService } from './../../../shared/services/feature-flags.service';
import es from '@angular/common/locales/es';
import * as moment from 'moment';
import { CurrentCompany } from './../../../shared/models/current-user';
import { ProfileService } from './../../../profiles/profile.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css'],
  providers: [CustomersService]
})

export class CustomersComponent implements OnInit, OnDestroy {

  company: CurrentCompany = new CurrentCompany(this.profileService.getProfileCompany());
  filters: QuestionBase<any>[];
  defaultFilters: QuestionBase<any>[];
  redemptionsFilters: QuestionBase<any>[];
  filterSlug = 'customers_summary_dashboard';
  slug = 'customers';
  activeTab = {name: 'customers', title: this.translate.instant('dashboards.customers.title')};
  subs$: Subscription[] = [];
  filterConfig: {disableOnInit: boolean, disableOnSubmit: boolean};
  redemptionsFilterCfg: {disableOnInit: boolean, disableOnSubmit: boolean};
  title: string;
  visibleTabs: string[] = [];
  flags = this.featureFlags.flags;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private locationsService: LocationsService,
    private segmentsService: SegmentsService,
    private customersService: CustomersService,
    private featuresService: FeaturesService,
    private taxonomiesService: FeatureTaxonomiesService,
    private featureFlags: FeatureFlagsService,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    if(this.route.snapshot.data.title && this.route.snapshot.data.title.indexOf('.') > 0) {
      this.title = this.translate.instant(this.route.snapshot.data.title);
    }
    this.featuresService.treatPkAsId = true;
    this.filterConfig = { disableOnInit: true, disableOnSubmit: true };
    this.redemptionsFilterCfg = { disableOnInit: true, disableOnSubmit: true };
    this.visibleTabs = this.route.snapshot.data.visibleTabs;
    this.setFilters();
    this.setRouterURL();
    this.checkYearOptions(this.filters);
    this.checkYearOptions(this.defaultFilters);
    const router$ = this.router.events.subscribe((val) => {
    this.subs$.push(router$);
      if (val instanceof NavigationEnd) {
        this.setRouterURL();
        this.selectTab(this.slug);
        this.changeTitle(this.slug);
        this.checkYearOptions(this.filters);
        this.checkYearOptions(this.defaultFilters);
      }
    });
  }

  ngOnDestroy() {
    if (this.subs$) { this.subs$.forEach( s$ => s$.unsubscribe()); }
  }

  selectTab(slug) {
    this.changeTitle(slug);
    this.slug = slug;
  }

  onFilterHandler(filterValues: Object) {
    this.customersService.filters = filterValues;
    this.customersService.applyFilters$.next(filterValues);
  }

  activeClass(tab: string): string {
    return this.slug === tab ? 'active' : '';
  }

  enabledFilters(): boolean {
    const hasSummaryTaggedSegments = !!(this.slug === 'summary' && this.company.isTagged());
    return this.slug !== 'customers-evolution' && this.slug !== 'retention' && this.slug !== 'summary' || hasSummaryTaggedSegments;
  }

  isTabVisible(tabSlug: string): boolean {
    return this.visibleTabs.includes(tabSlug);
  }

  private changeTitle(slug) {
    this.activeTab.name = slug;
    this.activeTab.title = this.translate.instant(`dashboards.customers.tabs.${slug}`);
  }

  private setRouterURL() {
    this.slug = this.route.firstChild.snapshot.data.title;
    this.controlFilters(this.filters);
    this.controlFilters(this.defaultFilters);
  }

  private checkYearOptions(filters: QuestionBase<any>[]) {
    const yearFilter = this.getFilter(filters, 'year');
    if (this.slug === 'contacts') {
      yearFilter.options = this.customersService.getLastYearsNumber(2);
      yearFilter.value = this.customersService.getLastYearsNumber(1);
      this.customersService.filters.year = moment().year();
    } else {
      yearFilter.options = this.customersService.getLastYearsNumber(11);
    }
  }

  private setFilters() {
    const yearOpts = this.customersService.getLastYearsNumber(11);
    const baseFilters = [
      new MultiSelectQuestion({
        key: 'location_ids',
        label: 'dashboards.sales.filters.locations',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locationsService
      }),
      new MultiSelectQuestion({
        key: 'segment_id',
        label: 'dashboards.sales.filters.segments',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.segmentsService
      }),
      new MultiSelectQuestion({
        key: 'taxonomy',
        label: 'dashboards.categories.filters.taxonomy_slug',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.taxonomiesService,
        showTooltip: true,
        tooltipKey: 'dashboards.customers.tabs.type_redemptions.messages.taxonomy_tooltip'
      }),
      new MultiSelectQuestion({
        key: 'feature_ids',
        label: 'dashboards.categories.filters.features',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.featuresService
      }),
      new MultiSelectQuestion({
        key: 'year',
        label: 'dashboards.customers.tabs.clients-constant.filters.year',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: yearOpts,
        required: true,
        value: [yearOpts[0]]
      })
    ];
    
    this.filters = baseFilters;
    this.defaultFilters = baseFilters.map(baseFltr => Object.assign({}, baseFltr));

    this.onFilterHandler({year: yearOpts[0].id});
  }

  private controlFilters(filters: QuestionBase<any>[]) {

    if ( !this.customersService.filters ) { this.customersService.filters = {}; }

    const featuresFilter = this.getFilter(filters, 'feature_ids');
    const yearFilter = this.getFilter(filters, 'year');
    const taxonomyFilter = this.getFilter(filters, 'taxonomy');

    switch (this.slug) {
      case 'type-redemptions':
        featuresFilter.hidden = false;
        yearFilter.hidden = false;
        taxonomyFilter.hidden = false;
        break;
      case 'contacts':
        featuresFilter.hidden = true;
        yearFilter.hidden = false;
        taxonomyFilter.hidden = true;
        break;
      default:
        featuresFilter.hidden = true;
        yearFilter.hidden = true;
        taxonomyFilter.hidden = true;
        break;
    }
  }
  
  private getFilter(filters: QuestionBase<any>[], filterKey: string): QuestionBase<any> {
    return filters.find(filter => filter.key === filterKey);
  }
}
