import { Injectable } from '@angular/core';
import { ResourcesService } from '../../services/resources.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LegendService extends ResourcesService {

  constructor(http: HttpClient) {
    super(http);
  }

  getPeriodsByCompany(company_id) {
    return this.getData({apiEndPoint: `companies/${company_id}/period_dates?offset=0,-1,-2`});
  }

}
