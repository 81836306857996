<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="row">
      <div class="col-md-12">
        <div id="accordion-1" class="accordion panel-group" role="tablist" aria-multiselectable="true">
          <div class="panel panel-default box-border border-radius-10" [ngClass]="{'pdd-top-15': advancedFilters.length <= 0, 'pdd-btm-15': advancedFilters.length <= 0}">
            <div class="panel-heading" role="tab" id="headingOne">
              <div class="row justify-content-end collapse-filters-header">
                <h4 class="panel-title ">
                  <a (click)="handleCollapse()"
                      class="d-flex align-items-center no-mrg no-pdd mrg-right-15 mrg-top-10 mrg-bottom-10 font-size-14"
                      role="button"
                      data-toggle="collapse"
                      [attr.data-target]="'#'+slug"
                      href="#{{slug}}"
                      aria-expanded="true"
                     >
                    <span class="mrg-right-15">{{ (isCollapsed ? 'components.data_table_filter_analytics.toggle_button.show_filters' : 'components.data_table_filter_analytics.toggle_button.hide_filters') | translate }}</span>
                    <em title="{{(isCollapsed ? 'components.data_table_filter_analytics.toggle_button.show_filters' : 'components.data_table_filter_analytics.toggle_button.hide_filters') | translate}}"
                      class="icon icon-up-down ti-angle-up font-size-12 no-mrg"></em>
                  </a>
                </h4>
              </div>
            </div>

            <div id="{{slug}}" class="collapse show">
              <div class="panel-body no-pdd-vertical mrg-horizon-20">
                  <div *ngIf="preStandardFilters && preStandardFilters.length > 0" class="row align-items-end">
                    <ng-container *ngFor="let filter of preStandardFilters">
                      <div class="col-md-2 form-group" *ngIf="!filter.hidden" [ngClass]="cfg.customInputsWidthClass ? cfg.customInputsWidthClass : ''">
                        <app-question [question]= "filter" [form]="form" [useToken]="filter.useToken" [filters]="filter.filters"></app-question>
                      </div>
                    </ng-container>
                  </div>

                  <div class="row">
                    <ng-container *ngFor="let filter of standardFilters">
                      <div class="col-md-2 form-group" *ngIf="!filter.hidden" [ngClass]="cfg.customInputsWidthClass ? cfg.customInputsWidthClass : ''">
                        <app-question [question]= "filter" [form]="form" (multiselectChanged)="handleFilterChanges($event, filter)" [useToken]="filter.useToken" [filters]="filter.filters"></app-question>
                      </div>
                    </ng-container>
                  </div>

                  <div class="row">
                    <ng-container *ngFor="let filter of advancedFilters" >
                      <div *ngIf="advancedFilters && advancedFilters.length > 0 || !filter.hidden" class="col-md-2 form-group" [ngClass]="cfg.customInputsWidthClass ? cfg.customInputsWidthClass : ''">
                        <app-question [question]="filter" [form]="form" [useToken]="filter.useToken"></app-question>
                      </div>
                    </ng-container>
                  </div>

                  <div class="row">
                    <ng-container *ngFor="let filter of checkboxRowFilters">
                      <div class="col-xl-4 col-lg-6 col-md-6 form-group flex align-items-center" *ngIf="!filter.hidden">
                        <app-question [question]= "filter" [form]="form" [filters]="filter.filters"></app-question>
                      </div>
                    </ng-container>
                  </div>

              </div>
            </div>

            <div *ngIf="!isCollapsed" class="panel-body mrg-horizon-20 mrg-btm-10">
              <button id="searchBtn"
                      [attr.data-cy]="'button_search'"
                      class="btn btn-info btn-icon btn-sm"
                      type="submit"
                      data-toggle="collapse"
                      [attr.data-target]="'#'+slug"
                      [attr.aria-controls]="''+slug"
                      [disabled]="disableBtns || cfg.disableSubmitBtn">
                <i class="ei-search-alt font-size-14"></i>
                <span [translate]="'components.data-table-filter.buttons.search'"></span>
              </button>
              <button id="clearBtn"
                      [attr.data-cy]="'button_clear'"
                      class="btn btn-default btn-icon btn-sm"
                      type="button"
                      (click)="clearAllFilters()">
                <i class="ei-eraser-alt font-size-14"></i>
                <span [translate]="'components.data-table-filter.buttons.clean'"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
