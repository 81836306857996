import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-show-news-list',
  templateUrl: './show-news-list.component.html',
  styleUrls: ['./show-news-list.component.css']
})
export class ShowNewsListComponent implements OnInit {

  public element: any;
  public apiEndPoint: string;
  public message_category_id = this.route.snapshot.params.id;
  public id: number;
  public isModal: boolean;

  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private router: Router
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.isModal = this.router.url.indexOf('(modal:show/news/') >= 0;
    this.getParams();
    this.resourcesService.getData({ apiEndPoint: this.apiEndPoint + '/' + this.id })
        .subscribe(res => {
          this.element = res;
          this.setImg();
        },
        error => {
          console.log('error:', error);
        });
  }

  getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'news';
      }
    });
  }

  setImg() {
    if (this.element.thumbnail.indexOf('missing.png') >= 0) {
      this.element.thumbnail = 'assets/images/avatars/no_image.png';
    }
  }

  removeTags(string) {
    return string.replace(/<[^>]*>/g, ' ')
                  .replace(/\s{2,}/g, ' ')
                  .trim();
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

}
