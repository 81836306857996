<div class="row" *ngIf="form">
  <div class="col-md-4">
    <div class="form-group">
      <app-question [question]="inputs[0]" [form]="form" (multiselectChanged)="handleOperatorChanges()"></app-question>
    </div>
  </div>

  <div class="col-md-4">
    <div class="form-group">
      <app-question [question]="inputs[1]" [form]="form"></app-question>
    </div>
  </div>

  <div class="col-md-4" *ngIf="hasFormKeyWithValue('operator', 'bt')">
    <div class="form-group">
      <app-question [question]="inputs[2]" [form]="form"></app-question>
    </div>
  </div>
</div>