<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-10">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form [formGroup]="locationsForm" *ngIf="locationsForm">
                <ng-container *ngFor="let field of locationsFormFieldsTemplate">
                  <ng-container *ngTemplateOutlet="fieldsRowsTemplate; context: { key: field.key, hasLabel: field.hasLabel ?? true, translateKey: field.translateKey ?? field.key}"></ng-container> 
                </ng-container>
                <ng-template #fieldsRowsTemplate let-key="key" let-hasLabel="hasLabel" let-translateKey="translateKey">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <ng-container *ngIf="hasLabel">
                          <label [for]="key" [translate]="'resources.locations.form.' +  translateKey"></label>
                        </ng-container>
                        <span *ngIf="getInputConfig(key).required" class="text-danger">*</span>
                        <app-question [class.field-warning]="getInvalidFieldValue(key)" [question]="getInputConfig(key)" [form]="locationsForm"></app-question>
                        <span *ngIf="getInvalidFieldValue(key) as invalidFieldValue" class="text-danger">
                          <em class="fa-solid fa-triangle-exclamation"></em>
                          {{ invalidFieldValue?.message | translate }}
                        </span>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
