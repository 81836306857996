import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ProfileService } from '../../../profiles/profile.service';
import { CurrentUser } from '../../models/current-user';
import { QuestionBase } from '../../models/forms/question-base';
import { ConfirmationService } from '../../services/confirmation.service';
import { DictionaryService } from '../../services/dictionary.service';
import { QuestionControlService } from '../../services/question-control.service';
import { UserSettingsService } from './service/user-settings.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  providers: [UserSettingsService, DictionaryService]
})

export class UserSettingsComponent implements OnInit, OnDestroy {

  @ViewChild('modalToggleBtn') modalToggleBtn: ElementRef;
  
  inputs: QuestionBase<any>[] = [];
  userSettingsForm: UntypedFormGroup;
  userData: CurrentUser;
  subs$: Subscription[] = [];

  constructor(
    private qcs:QuestionControlService,
    private userSettingsService: UserSettingsService,
    private profileService: ProfileService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ) { }
  
  ngOnInit(): void {
    this.userData = new CurrentUser(this.profileService.getStoredUser());
    this.inputs = this.userSettingsService.getInputs({});
    this.userSettingsForm = this.qcs.toFormGroup(this.inputs);
  }

  ngOnDestroy(): void {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  openModal() {
    this.modalToggleBtn.nativeElement.click();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  save() {
    const successTitle = this.translate.instant('resources.user_settings.messages.success_title');
    const payload = {
      preferred_language: this.userSettingsForm.value.user_preferred_language[0].id
    };

    const save$ = this.userSettingsService.updateUser(payload, this.userData.id).subscribe(
      () => {
        save$.unsubscribe();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        return this.confirmationService.displayAlert(successTitle, '', 'success', false, false).catch(() => {});
      },
      (errorResponse: HttpErrorResponse) => {
        save$.unsubscribe();
        return this.confirmationService.displayErrorAlert('Error', errorResponse.error.error);
      }
    );
    this.subs$.push(save$);
  }

}
